import { DataAccessLayerService } from '@services/Utils/CRUD/crud-service';
import { CrudFactory } from '@services/Utils/CRUD/crud-service.factory';
import { Comment, CommentInfo, CommentList } from '../..';
import { ObjectId, User } from '../../..';
import { DATABASE_SCHEMA } from '../../../core/database.config';
import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import {
  CommentsApiFactory,
  CommentsApiService,
  CommentsResourceType,
} from '../comments-api/comments-api.factory';

export type CommentsFactory = (
  resourceType: CommentsResourceType
) => CommentsService;

export class CommentsService {
  private commentsApiService: CommentsApiService;
  private crud: DataAccessLayerService<Comment>;

  constructor(
    resourceType: CommentsResourceType,
    private commentsApiFactory: CommentsApiFactory,
    private objectIdService: ObjectIdService,
    private crudFactory: CrudFactory<Comment>,
    private databaseSchema: typeof DATABASE_SCHEMA,
    private $q: ng.IQService
  ) {
    'ngInject';
    this.commentsApiService = this.commentsApiFactory(resourceType);

    const tableConfig = this.databaseSchema.tables.comments;
    this.crud = this.crudFactory('comments', {
      default_params: { mode: 'compact' },
      take_care_of_user_profile: true,
      backup: {
        indexed_fields: tableConfig.indexed_fields.map((field) => field.name),
      },
    });
  }

  list(resourceId: string, params = {}): ng.IPromise<CommentList> {
    return this.commentsApiService.list(resourceId, params);
  }

  create(
    resourceId: string,
    comment: Comment,
    isOffline?: boolean
  ): ng.IPromise<Comment> {
    if (isOffline) {
      return this.saveLocally(comment);
    }

    return this.commentsApiService.create(resourceId, comment);
  }

  edit(
    resourceId: string,
    comment: Comment,
    isOffline?: boolean
  ): ng.IPromise<Comment> {
    if (isOffline) {
      return this.updateLocally(comment);
    }

    if (comment?.contents?.files?.length) {
      comment.contents.files = comment.contents.files.map((file) => file._id);
    }

    return this.commentsApiService.edit(resourceId, comment);
  }

  delete(
    resourceId: string,
    commentId: string,
    isOffline?: boolean
  ): ng.IPromise<Comment> {
    if (isOffline) {
      return this.deleteLocally(commentId);
    }

    return this.commentsApiService.delete(resourceId, commentId);
  }

  getLocalList(resourceId?: string): ng.IPromise<Comment[]> {
    return this.crud.listLocal().then((list) => {
      return list.filter(
        (comment) => !resourceId || comment.contents.answer_id === resourceId
      );
    });
  }

  deleteAllLocalComments(resourceId?: string) {
    return this.getLocalList(resourceId).then((list) =>
      this.$q.all(
        list.map((comment: Comment) => this.deleteLocally(comment._id))
      )
    );
  }

  deleteLocally(commentId: string): ng.IPromise<Comment> {
    return this.crud.deleteLocal(commentId);
  }

  sendAllLocalComments(): ng.IPromise<Comment[]> {
    return this.getLocalList().then((list) =>
      this.$q.all(
        list.map((comment: Comment) =>
          this.create(comment.contents.answer_id!, comment)
        )
      )
    );
  }

  saveLocally(comment: Comment): ng.IPromise<Comment> {
    return this.crud.saveLocal(comment._id, {
      ...comment,
      created_date: new Date().toString(),
    });
  }

  sendAllAndClear(): ng.IPromise<Comment[]> {
    return this.sendAllLocalComments().then(() =>
      this.deleteAllLocalComments()
    );
  }

  updateLocally(comment: Comment) {
    return this.crud.updateLocal({
      ...comment,
      modified_date: new Date().toString(),
    });
  }

  buildComment(
    profile: User,
    content: string,
    info: CommentInfo,
    mediaFileId?: ObjectId,
    type?: string
  ) {
    const commentId = this.objectIdService.create();

    if (mediaFileId) {
      const mediaData = this.buildMediaData(mediaFileId, type);

      return {
        _id: commentId,
        contents: {
          author_id: profile._id,
          organisation_id: profile.contents.organisation_id,
          subject: '',
          content,
          ...info,
          ...mediaData,
        },
      };
    }

    return {
      _id: commentId,
      contents: {
        author_id: profile._id,
        organisation_id: profile.contents.organisation_id,
        subject: '',
        content,
        ...info,
      },
    };
  }

  buildMediaData(mediaFileId: ObjectId, type?: string) {
    switch (type) {
      case 'document':
        return {
          files: [mediaFileId],
        };
      default:
        return {
          picture_id: mediaFileId,
        };
    }
  }
}

// @ngInject
export const CommentsFactory =
  (
    commentsApiFactory: CommentsApiFactory,
    objectIdService: ObjectIdService,
    crudFactory: CrudFactory<Comment>,
    databaseSchema: typeof DATABASE_SCHEMA,
    $q: ng.IQService
  ) =>
  (resourceType: CommentsResourceType): CommentsService =>
    new CommentsService(
      resourceType,
      commentsApiFactory,
      objectIdService,
      crudFactory,
      databaseSchema,
      $q
    );
