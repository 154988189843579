"use strict";

var _createObjectId = _interopRequireDefault(require("../utils/createObjectId"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// @TODO: use import
module.exports = function () {
  return [{
    _id: (0, _createObjectId["default"])(),
    name: 'minimum',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: '',
      ean: '',
      brand: '',
      catalog_id: (0, _createObjectId["default"])('catalog'),
      price: {
        // Decimal field
        // see @https://docs.mongodb.com/manual/tutorial/model-monetary-data/
        value: 10,
        // ISO 4217
        currency: 'EUR'
      },
      organisation_id: (0, _createObjectId["default"])('orgId')
    }
  }, {
    _id: (0, _createObjectId["default"])(),
    name: 'brand',
    description: 'Product with brand',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'My Awesome product',
      ean: '',
      catalog_id: (0, _createObjectId["default"])('catalog'),
      brand: 'MyBrand',
      price: {
        value: 10,
        currency: 'EUR'
      },
      organisation_id: (0, _createObjectId["default"])('orgId')
    }
  }, {
    _id: (0, _createObjectId["default"])(),
    name: 'full',
    description: 'product with all properties',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'A product from catalog',
      ean: '123',
      brand: 'Apple',
      price: {
        value: 1029,
        currency: 'EUR'
      },
      organisation_id: (0, _createObjectId["default"])('organisation'),
      catalog_id: (0, _createObjectId["default"])('catalog'),
      category: 'phone',
      internalId: 'IPhone5,2',
      size: '5 inches',
      tags: [],
      version: '5'
    }
  }];
};