import ButtonBigModule from './button-big/button-big.module.js';
import ButtonFloatingModule from './button-floating/button-floating.module.js';
import { buttonFloatingAddModule } from './button-floating-add/button-floating-add.module';
import ButtonHeaderModule from './button-header/button-header.module.js';
import ButtonHeaderAddModule from './button-header-add/button-header-add.module.js';
import ButtonHeaderCloseModule from './button-header-close/button-header-close.module.js';
import ButtonHeaderSynchronizeModule from './button-header-synchronize/button-header-synchronize.module.js';
import ButtonLoaderModule from './button-loader/button-loader.module.js';
import ButtonLoaderDelayModule from './button-loader-delay/button-loader-delay.module.js';
import ButtonMoreModule from './button-more/button-more.module.js';
import ButtonItemActionsModule from './button-item-actions/button-item-actions.module.js';
import ButtonSelectorModule from './button-selector/button-selector.module';
import ButtonUploadModule from './button-upload/button-upload.module';
import ButtonDocumentModule from './button-document/button-document.module';
import ButtonHeaderSpinningModule from './button-header-spinning/button-header-spinning.module.js';
import { ButtonMenuBigModule } from './button-menu-big/button-menu-big.module';

export default angular.module('sfMobile.components.buttons', [
  ButtonBigModule,
  ButtonFloatingModule,
  buttonFloatingAddModule,
  ButtonHeaderModule,
  ButtonHeaderAddModule,
  ButtonHeaderCloseModule,
  ButtonHeaderSynchronizeModule,
  ButtonLoaderModule,
  ButtonLoaderDelayModule,
  ButtonMenuBigModule,
  ButtonMoreModule,
  ButtonItemActionsModule,
  ButtonSelectorModule,
  ButtonUploadModule,
  ButtonDocumentModule,
  ButtonHeaderSpinningModule,
]).name;
