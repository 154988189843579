import { StoreCompletionStatsController } from './store-completion-stats.controller';

export const StoreCompletionStatsComponent = {
  bindings: {
    campaign: '<?',
    store: '<',
    count: '<',
  },
  templateUrl:
    'legacy-campaigns/campaign/components/store-completion-stats/store-completion-stats.html',
  controller: StoreCompletionStatsController,
};
