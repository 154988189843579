import { RRule } from 'rrule';
import { pick, find, propEq, prop, pipe } from 'ramda';
import { ParsedOptions, Frequency, Options } from 'rrule/dist/esm/src/types';
import {
  RecurrenceService,
  MonthRecurrenceType,
  MonthRecurrenceTypeNames,
  MonthRecurrence,
} from '../recurrence.service';

type BuildRecurrenceFn = (recurrence: Partial<Options>) => Partial<Options>;
type RecurrenceRuleKeys = Partial<keyof ParsedOptions>;
type FreqOption = {
  label: string;
  value: Frequency;
  buildRecurrence: BuildRecurrenceFn;
};
const COMMOM_RECURRENCE_RULE_KEYS: RecurrenceRuleKeys[] = [
  'interval',
  'freq',
  'until',
  'dtstart',
];

export class CustomRecurrenceModalController
  implements ng.IComponentController
{
  private resolve: { options: ParsedOptions };
  private recurrence: Partial<Options>;
  private freqOptions: FreqOption[];
  private minEndRecurrenceDate: Date;
  private monthRecurrenceOptions: Record<MonthRecurrenceType, MonthRecurrence>;
  private monthRecurrence: MonthRecurrenceType;
  private onSave: () => (arg) => void;

  /* @ngInject */
  constructor(
    private recurrenceService: RecurrenceService,
    private $translate: ng.translate.ITranslateService
  ) {}

  $onInit(): void {
    this.recurrence = this.resolve.options;
    this.minEndRecurrenceDate = this.recurrenceService.getMinEndRecurrenceDate(
      this.recurrence.dtstart as Date
    );
    this.monthRecurrenceOptions =
      this.recurrenceService.generateMonthRequrrenceOptions(
        this.recurrence.dtstart as Date
      );
    this.monthRecurrence =
      this.recurrence[MonthRecurrenceTypeNames.BYNWEEKDAY] &&
      this.recurrence[MonthRecurrenceTypeNames.BYNWEEKDAY]?.length
        ? MonthRecurrenceTypeNames.BYNWEEKDAY
        : MonthRecurrenceTypeNames.BYMONTHDAY;
    this.freqOptions = [
      {
        value: RRule.DAILY,
        label: this.$translate.instant('RECURRENCE.FREQ.DAILY'),
        buildRecurrence: pick(COMMOM_RECURRENCE_RULE_KEYS),
      },
      {
        value: RRule.WEEKLY,
        label: this.$translate.instant('RECURRENCE.FREQ.WEEKLY'),
        buildRecurrence: pick([...COMMOM_RECURRENCE_RULE_KEYS, 'byweekday']),
      },
      {
        value: RRule.MONTHLY,
        label: this.$translate.instant('RECURRENCE.FREQ.MONTHLY'),
        buildRecurrence: pipe(
          (recurrence: Partial<Options>) =>
            this.monthRecurrenceOptions[this.monthRecurrence].create(
              recurrence
            ),
          pick([...COMMOM_RECURRENCE_RULE_KEYS, 'bymonthday', 'byweekday'])
        ),
      },
      {
        value: RRule.YEARLY,
        label: this.$translate.instant('RECURRENCE.FREQ.YEARLY'),
        buildRecurrence: pick(COMMOM_RECURRENCE_RULE_KEYS),
      },
    ];
  }

  isWeekly(): boolean {
    return this.recurrence.freq === RRule.WEEKLY;
  }

  isMonthly(): boolean {
    return this.recurrence.freq === RRule.MONTHLY;
  }

  apply(): void {
    const buildRecurrence: any = pipe<
      [FreqOption[]],
      FreqOption,
      BuildRecurrenceFn
    >(
      find<FreqOption>(
        propEq(this.recurrence.freq, 'value')
      ) as () => FreqOption,
      prop('buildRecurrence')
    )(this.freqOptions);

    this.onSave()(buildRecurrence(this.recurrence));
  }
}
