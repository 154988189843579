export const HAS_NEW_PARAM_FILTERS = 'newPlacesParamsFilters';
export const PLACES_SORTERS_KEY = 'placesSorters';
export const PLACES_FILTERS_KEY = 'placesFilters';
export const PLACESLISTS_FILTER_KEY = '__placesListFilter';
export const PLACES_CITY_FILTER_KEY = 'city';
export const PLACES_ZIPCODE_FILTER_KEY = 'zipcode';
export const PLACES_COUNTRY_FILTER_KEY = 'country';
export const STANDARD_FILTERS = [
  PLACESLISTS_FILTER_KEY,
  PLACES_CITY_FILTER_KEY,
  PLACES_ZIPCODE_FILTER_KEY,
  PLACES_COUNTRY_FILTER_KEY,
];
