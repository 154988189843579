declare let angular: ng.IAngularStatic;

// Components
import { ReactiveCampaignActivityDetailsComponent } from './components/reactive-campaign-activity-details/reactive-campaign-activity-details.component';
import { ReactiveCampaignDetailsComponent } from './components/reactive-campaign-details/reactive-campaign-details.component';
import { ReactiveCampaignInfoComponent } from './components/reactive-campaign-info/reactive-campaign-info.component';
import { ReactiveCampaignItemComponent } from './components/reactive-campaign-item/reactive-campaign-item.component';
import { ReactiveCampaignOverviewComponent } from './components/reactive-campaign-overview/reactive-campaign-overview.component';
import { ReactiveCampaignParamSelectorComponent } from './components/reactive-campaign-param-selector/reactive-campaign-param-selector.component';
import { ReactiveCampaignReportsComponent } from './components/reactive-campaign-reports/reactive-campaign-reports.component';
import { ReactiveCampaignStoreSelectorComponent } from './components/reactive-campaign-store-selector/reactive-campaign-store-selector.component';
import { ReactiveCampaignsComponent } from './components/reactive-campaigns/reactive-campaigns.component';

//Services
import { CampaignsUtilsService } from './services/campaigns-utils.service';
import { LogService } from '../services/Utils/log/log.service';

// Routes
import { ReactiveCampaignTasksComponent } from './components/reactive-campaign-tasks/reactive-campaign-tasks.component';
import reactiveCampaignsRouteConfig from './reactive-campaigns.routes';

export default angular
  .module('sfMobile.reactive-campaigns', [
    'sfMobile.services.geolocation',
    'sfMobile.services.pubsub',
  ])
  .component('sfReactiveCampaigns', ReactiveCampaignsComponent)
  .component('sfReactiveCampaignItem', ReactiveCampaignItemComponent)
  .component(
    'sfReactiveCampaignActivityDetails',
    ReactiveCampaignActivityDetailsComponent
  )
  .component('sfReactiveCampaignOverview', ReactiveCampaignOverviewComponent)
  .component('sfReactiveCampaignDetails', ReactiveCampaignDetailsComponent)
  .component('sfReactiveCampaignInfo', ReactiveCampaignInfoComponent)
  .component(
    'sfReactiveCampaignParamSelector',
    ReactiveCampaignParamSelectorComponent
  )
  .component(
    'sfReactiveCampaignStoreSelector',
    ReactiveCampaignStoreSelectorComponent
  )
  .component('sfReactiveCampaignReports', ReactiveCampaignReportsComponent)
  .component('sfReactiveCampaignTasks', ReactiveCampaignTasksComponent)
  .service('campaignsUtilsService', CampaignsUtilsService)
  .service('logService', LogService)
  .config(reactiveCampaignsRouteConfig).name;
