import { ObjectId } from '../../..';
import { PovService } from '../../API/POV/pov.service';
import { FILES_UPLOAD_OPTIONS } from '../../../constants/files-upload-options.constant';

const IMAGES_URL = '/digitalAssets/images/';
const IMAGES_ERROR_URL = 'img/icons/contextual_error.svg';
const NO_IMAGE_ID_ERROR = 'NO_IMAGE_ID_ERROR';
const URL_EXPIRATION_PARAM = 'expiry';
const EXPIRATION_SECONDS = 36000; // 10 hours

export type SizeUrlsHash = Record<string, string>;
export type IdUrlsHash = Record<ObjectId, string>;

export type ImageOptions = {
  width?: number;
  height?: number;
  resizeType?: 'auto' | 'fill' | 'fit';
  extend?: boolean;
  background?: string;
  devicePixelRatio?: number;
  imageType?: string;
  expiry?: number;
};

export class ImageService {
  private localRequestCache: Record<
    string,
    { url: ng.IPromise<string>; expiry?: number }
  > = {};

  /* @ngInject */
  constructor(
    private $window: ng.IWindowService,
    private sfPOVService: PovService,
    private $http: ng.IHttpService,
    private $q: ng.IQService,
    private SF_FILES_UPLOAD_OPTIONS: typeof FILES_UPLOAD_OPTIONS
  ) {}

  getUrl(id: ObjectId, overrideOrgId?: ObjectId): ng.IPromise<string> {
    if (!id) {
      this.$q.reject(NO_IMAGE_ID_ERROR);
    }
    return this.sfPOVService.pBuildURL(
      IMAGES_URL + id,
      overrideOrgId ? { pov: 'organisation' } : { pov: 'user' },
      overrideOrgId
    );
  }

  getImgAssetUrlById(
    id: ObjectId,
    params: ImageOptions = {}
  ): ng.IPromise<string> {
    return this.getUrl(id).then((url) => this.getImgAssetUrl(url, params));
  }

  generateHash(str: string): string {
    let hash = 0,
      i,
      chr;

    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }

    return hash.toString(16);
  }

  getHashForRequest(url: string, params: ImageOptions): string {
    const paramsString = Object.keys(params)
      .sort()
      .reduce(
        (str, keyParam) => str + keyParam + params[keyParam].toString(),
        ''
      );

    return this.generateHash(url + paramsString);
  }

  getExpiryFromUrl(url: string): number | undefined {
    try {
      const parsedUrl = new this.$window.URLSearchParams(url);
      const expiry = Number(parsedUrl.get(URL_EXPIRATION_PARAM));

      return expiry;
    } catch (e) {
      return undefined;
    }
  }

  getImgAssetUrl(url: string, params: ImageOptions = {}): ng.IPromise<string> {
    params.expiry = params.expiry ?? EXPIRATION_SECONDS;
    const hash = this.getHashForRequest(url, params);
    const expiry = this.localRequestCache[hash]?.expiry;
    const isUrlExpired = expiry ? expiry <= Date.now() : true;

    if (!this.localRequestCache[hash] || isUrlExpired) {
      this.localRequestCache[hash] = {
        url: this.$http
          .post<{ imageUrl: string }>(url, params)
          .then(({ data }) => {
            const expiry = this.getExpiryFromUrl(data.imageUrl);

            this.localRequestCache[hash].expiry = expiry;
            return data.imageUrl;
          })
          .catch(() => IMAGES_ERROR_URL),
      };
    }

    return this.localRequestCache[hash].url;
  }

  getSizedUrlFromId(
    id: ObjectId,
    widthxheight: string,
    overrideOrgId?: ObjectId
  ): ng.IPromise<string> {
    return this.getUrl(id, overrideOrgId).then((url) =>
      this.getSizedUrl(url, widthxheight)
    );
  }

  getSizedUrlsFromIds(
    ids: ObjectId[],
    widthxheight: string
  ): ng.IPromise<IdUrlsHash> {
    const idUrlsHash = {};

    return this.$q
      .all(
        ids.map((id) =>
          this.getUrl(id)
            .then((url) => this.getSizedUrl(url, widthxheight))
            .then((sizedUrl) => {
              idUrlsHash[id] = sizedUrl;
            })
        )
      )
      .then(() => idUrlsHash);
  }

  getSizesHashFromId(id: ObjectId, sizes: string[]): ng.IPromise<SizeUrlsHash> {
    const sizeUrlsHash = {};

    return this.$q
      .all(
        sizes.map((widthxheight) =>
          this.getUrl(id)
            .then((url) => this.getSizedUrl(url, widthxheight))
            .then((sizedUrl) => {
              sizeUrlsHash[widthxheight] = sizedUrl;
            })
        )
      )
      .then(() => sizeUrlsHash);
  }

  getSizedUrl(url: string, widthxheight: string): ng.IPromise<string> {
    const [width, height] = widthxheight.split('x').map(Number);
    const params: ImageOptions = { width, height };

    if (this.$window.devicePixelRatio) {
      params.devicePixelRatio = this.$window.devicePixelRatio;
    }

    return this.$q.when(this.getImgAssetUrl(url, params));
  }

  resizeBlobImage(blob: Blob): ng.IPromise<Blob> {
    const defer = this.$q.defer<Blob>();
    const blobUrl = this.$window.URL.createObjectURL(blob);

    const image = new Image();
    image.src = blobUrl;
    image.onload = () => {
      const resize = this._getResizedBlob(image);
      defer.resolve(resize);
    };

    return defer.promise;
  }

  private _getResizedBlob(image: HTMLImageElement) {
    const defer = this.$q.defer<Blob>();

    const canvas = this.$window.document.createElement('canvas');
    let { width, height } = image;
    const { maxWidth, maxHeight } = this.SF_FILES_UPLOAD_OPTIONS.IMAGE;

    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height *= maxWidth / width));
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width *= maxHeight / height));
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    context?.drawImage(image, 0, 0, width, height);

    canvas.toBlob((blob) => {
      if (!blob) {
        defer.reject();
        return;
      }
      defer.resolve(blob);
    });

    return defer.promise;
  }
}
