const APP_MESSAGE_TIME_DISPLAY = 3000;

export const AppMessageComponent = {
  bindings: {},
  templateUrl: 'components/Notifications/app-message/app-message.html',
  controller: class AppMessageController {
    constructor(pubSubService, $timeout) {
      'ngInject';
      Object.assign(this, {
        pubSubService,
        $timeout,
      });
    }

    $onInit() {
      this.isDisplay = false;
      this.timeout = null;
      this.messageListener = this.pubSubService.subscribe(
        'APP_MESSAGE:NEW',
        ({ message, type }) => this.display(message, type)
      );
    }

    $onDestroy() {
      this.messageListener();
    }

    display(message, type) {
      this.isDisplay = true;
      this.type = type;
      this.message = message;
      this.clearTimeout();
      this.timeout = this.$timeout(
        () => this.close(),
        APP_MESSAGE_TIME_DISPLAY
      );
    }

    close() {
      this.isDisplay = false;
      this.clearTimeout();
      return true;
    }

    clearTimeout() {
      if (this.timeout) {
        this.$timeout.cancel(this.timeout);
      }
    }
  },
};
