import { TSFixMe } from '../../..';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';

export type StepContent = {
  title: string;
  template: string;
  resolve: Record<string, TSFixMe>;
};

export class ModalStepperController {
  // bindings
  steps: StepContent[];
  onStepperClose: () => void;

  currentStepIndex = 0;
  currentStep: StepContent;
  resolve: Record<string, TSFixMe> | undefined;
  isRTLNeeded: boolean;

  constructor(private localizationService: LocalizationService) {
    'ngInject';
  }

  $onInit(): void {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.setStep(0);
  }

  nextStep(): void {
    const newStep =
      this.currentStepIndex < this.steps.length - 1
        ? this.currentStepIndex + 1
        : this.steps.length - 1;

    this.setStep(newStep);
  }

  prevStep(): void {
    const newStep = this.currentStepIndex > 0 ? this.currentStepIndex - 1 : 0;

    this.setStep(newStep);
  }

  setStep(index: number): void {
    this.currentStepIndex = index;
    this.currentStep = this.steps[this.currentStepIndex];
    this.resolve = this.currentStep.resolve;

    this.resolve.stepper = {
      nextStep: () => this.nextStep(),
      prevStep: () => this.prevStep(),
      currentStepIndex: this.currentStepIndex,
      stepsLength: this.steps.length,
    };
  }
}
