const NB_PLACES_LOADED = 20;

export const CampaignSelectorComponent = {
  bindings: {
    campaign: '<',
    reports: '<',
    todos: '<',
    drafts: '<',
    onClick: '&',
    onClose: '&',
  },
  templateUrl: 'missions/components/campaign-selector/campaign-selector.html',
  // eslint-disable-next-line max-params
  controller: function CampaignSelectorController(
    localizationService,
    $q,
    contentService,
    placesSourceAdapterService,
    geolocationService,
    RequestsPaginate
  ) {
    'ngInject';

    this.$onInit = () => {
      this.isRTLNeeded = localizationService.shouldActivateRTL();
      this.isLoading = true;
      this.searchError = false;
      this.networkError = false;
      this.infiniteLoadError = false;
      this.todos = this.todos || [];
      this.drafts = this.drafts || [];
      this.reports = this.reports || [];
      this.missionSelected = {};
      this.placesHash = {};

      this.permanentRequestPaginate = new RequestsPaginate(
        placesSourceAdapterService.getPlacesOfPermanentForm.bind(
          placesSourceAdapterService,
          this.campaign,
          this.campaign.contents.places_ids
        ),
        {
          limit: NB_PLACES_LOADED,
        }
      );

      this.punctualRequestPaginate = new RequestsPaginate(
        placesSourceAdapterService.getPlacesOfPunctualForm.bind(
          placesSourceAdapterService,
          this.campaign,
          this.todos.map((todo) => todo.contents.place_id),
          this.reports.map((report) => report.contents.place_id)
        ),
        {
          limit: NB_PLACES_LOADED,
        }
      );

      return $q
        .all([
          placesSourceAdapterService.queryFromDataSource({
            filters: {
              id: {
                value: this.drafts.map((draft) => draft.contents.place_id),
                operation: 'equality',
              },
            },
          }),
          this.reload(),
        ])
        .then(([places]) => {
          this.placesHash = {
            ...this.placesHash,
            ...places.entries.reduce((acc, entry) => {
              acc[entry._id] = entry;
              return acc;
            }, {}),
          };

          return this.placesHash;
        })
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.$onChanges = () => {
      this.todos = this.todos || [];
      this.drafts = this.drafts || [];
      this.reports = this.reports || [];
    };

    this.reload = () => {
      this.initPlaces();

      return geolocationService.getCurrentPosition().then((position) => {
        this.userPosition = position;
        return this.getPlaces();
      });
    };

    this.getPlaces = () => {
      this.infiniteLoadError = false;

      return $q
        .all([
          this.getPlacesWithPermanentMissions(),
          this.getPlacesWithPunctualMissions(),
        ])
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        })
        .finally(() => {
          if (this.onScrollComplete) {
            this.onScrollComplete();
          }
        });
    };

    this.initPlaces = () => {
      this.userPosition = null;
      this.infiniteLoadError = false;
      this.placesWithPermanentMissions = [];
      this.placesWithPunctualMissions = [];

      this.permanentRequestPaginate.reset();
      this.punctualRequestPaginate.reset();

      contentService.scrollTopById('campaignSelectorScroll');
    };

    this.getPlacesWithPermanentMissions = () => {
      if (!this.permanentRequestPaginate.canCallMore()) {
        return $q.when(this.placesWithPermanentMissions);
      }

      return this.permanentRequestPaginate
        .call({
          filters: {
            ...(this.searchField
              ? { name: { value: this.searchField, operation: 'regex' } }
              : {}),
          },
          sort: 'place_distance',
          ...(this.userPosition
            ? { near: `${this.userPosition.lng},${this.userPosition.lat}` }
            : {}),
        })
        .then((places) => {
          this.placesWithPermanentMissions = places.entities;
          return this.placesWithPermanentMissions;
        });
    };

    this.getPlacesWithPunctualMissions = () => {
      if (!this.punctualRequestPaginate.canCallMore()) {
        return $q.when(this.placesWithPunctualMissions);
      }

      return this.punctualRequestPaginate
        .call({
          filters: {
            ...(this.searchField
              ? { name: { value: this.searchField, operation: 'regex' } }
              : {}),
            //FIXME This should be done in the getPlacesOfPunctualForm function
            // (app/services/Utils/PlacesSourceAdapter/places-source-adapter.service.js#112)
            id: {
              value: this.todos.map((todo) => todo.contents.place_id),
              operation: 'equality',
            },
          },
          sort: 'place_distance',
          ...(this.userPosition
            ? { near: `${this.userPosition.lng},${this.userPosition.lat}` }
            : {}),
        })
        .then((places) => {
          this.placesWithPunctualMissions = places.entities;
          return this.placesWithPunctualMissions;
        });
    };

    this.onSearch = () => {
      this.searchError = false;
      this.isSearchLoading = true;

      return this.reload()
        .catch(() => {
          this.searchError = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    };

    this.getAssignedPlaceOf = (report) =>
      this.placesHash[report.contents.place_id];

    this.hasResult = () =>
      [
        this.drafts,
        this.placesWithPermanentMissions,
        this.placesWithPunctualMissions,
      ].some((list) => list && list.length);

    this.getPunctualMissionId = (placeId) => {
      const punctualMissions = this.todos.filter(
        (todo) => todo.contents.place_id === placeId
      );

      if (!punctualMissions || !punctualMissions.length) {
        return false;
      }

      return punctualMissions[0]._id;
    };

    this.selectPunctualMission = (placeId) => {
      const punctualMissionId = this.getPunctualMissionId(placeId);

      return punctualMissionId
        ? this.selectMission('todo', punctualMissionId)
        : false;
    };

    this.selectMission = (type, itemId) => {
      const paramsHash = {
        draft: { reportId: itemId },
        todo: { reportId: itemId, missionId: itemId },
        place: { locationId: itemId },
      };
      const elem = {
        id: itemId,
        params: paramsHash[type],
        type,
      };

      this.missionSelected = itemId === this.missionSelected.id ? {} : elem;
      return elem;
    };

    this.goToForm = () => {
      const params = this.missionSelected.params;

      this.onClick({ params: params });
      return true;
    };

    this.canCallMore = () =>
      this.punctualRequestPaginate.canCallMore() ||
      this.permanentRequestPaginate.canCallMore();

    this.resetInfiniteLoadErrorState = () => {
      this.infiniteLoadError = false;
    };
  },
};
