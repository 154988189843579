"use strict";

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Organisation',
  type: 'object',
  additionalProperties: false,
  required: ['name', 'owner_id'],
  properties: {
    name: {
      type: 'string'
    },
    owner_id: schemaUtils.oneToOne(),
    logo_id: schemaUtils.oneToOne()
  }
};