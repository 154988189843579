import { ReportsListComponent } from './reports-list.component';

export default angular
  .module('sfMobile.components.reports-list', [
    'pascalprecht.translate',
    'sfMobile.services.reports',
    'sfMobile.services.helpers',
    'sfMobile.services.api-utils',
    'sfMobile.services.requests-paginate',
    'sfMobile.components.content',
    'sfMobile.components.report-item',
    'sfMobile.components.search-field',
    'sfMobile.components.search-filter',
    'sfMobile.components.infinite-scroll',
    'sfMobile.components.view-states',
    'sfMobile.components.view-states-search',
    'sfMobile.components.divider-date',
    'sfMobile.services.pubsub',
  ])
  .component('sfReportsList', ReportsListComponent).name;
