import { ObjectId } from '../../../..';
import { Form } from '../../../../missions/form/types';
import { StateService } from '@uirouter/core';

const MAX_MISSIONS_IN_SECTION = 3;

export class PlaceMissionsSectionController {
  // bindings
  emptyConfig: {
    desc: string;
    svg: string;
  };
  missionsForms: Form[] = [];
  missionsFormsDisplay: Form[];
  missionType: string;
  placeId: ObjectId;
  title: string;
  getMissionsLength?: (data: { formId: ObjectId }) => number;

  constructor(private $state: StateService) {
    'ngInject';
  }

  $onInit(): void {
    this.setMissionsForms();
  }

  $onChanges(changesObj: {
    missionsForms: {
      currentValue: Form[];
      previousValue: Form[];
    };
  }): void {
    const { currentValue, previousValue } = changesObj.missionsForms;

    if (currentValue === previousValue) {
      this.setMissionsForms();
    }
  }

  setMissionsForms(): void {
    this.missionsFormsDisplay = this.missionsForms.slice(
      0,
      MAX_MISSIONS_IN_SECTION
    );
  }

  isSectionLoading(entities?: Form[]): boolean {
    return typeof entities === 'undefined';
  }

  goToView(stateLink: string): void {
    this.$state.go(stateLink, { missionsType: this.missionType });
  }
}
