import { CampaignComponent } from './campaign.component';
import { CampaignService } from './services/campaign.service';
import { CampaignCompletionComponent } from './components/campaign-completion/campaign-completion.component';
import { CampaignCompletionSearchFilterComponent } from './components/campaign-completion-search-filter/campaign-completion-search-filter.component';
import { CampaignCompletionFilterModalComponent } from './components/campaign-completion-filter-modal/campaign-completion-filter-modal.component';
import { CampaignCompletionFilterToggleComponent } from './components/campaign-completion-filter-toggle/campaign-completion-filter-toggle.component';
import { CampaignCompletionListComponent } from './components/campaign-completion-list/campaign-completion-list.component';
import { UserCompletionStatsComponent } from './components/user-completion-stats/user-completion-stats.component';
import { StoreCompletionStatsComponent } from './components/store-completion-stats/store-completion-stats.component';
import CampaignStatsModule from './components/campaign-stats/campaign-stats.module';
import CampaignRemainingModule from './components/campaign-remaining/campaign-remaining.module';
import { CampaignCompletionFilterService } from './services/campaign-completion-filter/campaign-completion-filter.service';

export default angular
  .module('sfMobile.components.campaign', [
    'pascalprecht.translate',
    'sfMobile.services.reports',
    'sfMobile.services.places',
    'sfMobile.services.users',
    'sfMobile.services.segment',
    'sfMobile.services.api-utils',
    'sfMobile.services.modal',
    'sfMobile.services.analytics-filter',
    'sfMobile.components.content',
    'sfMobile.components.gallery-list',
    'sfMobile.components.reports-list',
    'sfMobile.components.search-field',
    'sfMobile.components.search-filter-list',
    'sfMobile.components.input-checkbox',
    'sfMobile.components.select-or-text',
    'sfMobile.constants.image-sizes',
    'sfMobile.constants.campaign-completion',
    CampaignStatsModule,
    CampaignRemainingModule,
  ])
  .service('campaignService', CampaignService)
  .service('campaignCompletionFilterService', CampaignCompletionFilterService)
  .component('sfCampaignCompletion', CampaignCompletionComponent)
  .component('sfCampaignCompletionList', CampaignCompletionListComponent)
  .component('sfUserCompletionStats', UserCompletionStatsComponent)
  .component('sfStoreCompletionStats', StoreCompletionStatsComponent)
  .component(
    'sfCampaignCompletionSearchFilter',
    CampaignCompletionSearchFilterComponent
  )
  .component(
    'sfCampaignCompletionFilterModal',
    CampaignCompletionFilterModalComponent
  )
  .component(
    'sfCampaignCompletionFilterToggle',
    CampaignCompletionFilterToggleComponent
  )
  .component('sfCampaign', CampaignComponent).name;
