export const QuestionProductComponent = {
  bindings: {
    mode: '@',
    question: '<',
    hasError: '<',
    answers: '=',
    actionClickedFn: '=?',
    previousAnswers: '<',
    isDisabled: '<?',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-product/question-product.html',
  controller: function QuestionProductController(
    FORM_QUESTION_ACTIONS,
    SF_QUESTION_PRODUCT_TYPES,
    $timeout
  ) {
    'ngInject';
    const DEFAULT_DISCOUNT_VALUE = 0;
    const DEFAULT_QUANTITY_VALUE = 1;

    this.$onInit = () => {
      this.actionClickedFn = this.actionClicked;
    };

    this.addProduct = (product) => {
      let answerValues = [
        this.buildEanField(product),
        this.buildQuantityField(),
      ];

      if (this.mode === SF_QUESTION_PRODUCT_TYPES.SALES) {
        answerValues = answerValues.concat(this.buildDiscountField());
      }

      this.answers = this.sfQuestionForm.addAnswer(answerValues, this.answers, {
        metadata: this.buildAnswerMetadata(product),
        type: this.question.type,
      });

      $timeout(() => this.setDirty());
    };

    this.setDirty = () => {
      const form =
        this['form_product_' + this.mode + '_' + this.question._id + '_input'];

      if (form) {
        form.$setDirty();
      }
    };

    this.buildEanField = (product) => ({
      field_id: this.question.fields[0]._id,
      value: product.contents.ean,
    });

    this.buildQuantityField = () => ({
      field_id: this.question.fields[1]._id,
      value: DEFAULT_QUANTITY_VALUE,
    });

    this.buildDiscountField = () => ({
      field_id: this.question.fields[2]._id,
      value: DEFAULT_DISCOUNT_VALUE,
    });

    // eslint-disable-next-line complexity
    this.buildAnswerMetadata = (product) => {
      const answerMetadata = {
        ...(product._id && { product_id: product._id }),
        ...(product.contents.catalog_id && {
          catalog_id: product.contents.catalog_id,
        }),
        ...(product.contents.name && { name: product.contents.name }),
        ...(product.contents.brand && { brand: product.contents.brand }),
        ...(product.contents.category && {
          category: product.contents.category,
        }),
        ...(product.contents.catalog_name && {
          catalog_name: product.contents.catalog_name,
        }),
        ...(product.contents.internalId && {
          internalId: product.contents.internalId,
        }),
        ...(product.contents.version && {
          version: product.contents.version,
        }),
        ...(product.contents.size && {
          size: product.contents.size,
        }),
      };

      if (this.mode === SF_QUESTION_PRODUCT_TYPES.SALES) {
        answerMetadata.sellPrice = product.contents.price.value;
        answerMetadata.sellTotalPrice = product.contents.price.value;
      }

      return answerMetadata;
    };

    this.onUpdateAnswers = (answers) => {
      this.sfQuestionForm.updateProductAnswers(answers);
      return this.setDirty();
    };

    this.onRemoveProduct = (answer) => {
      this.answers = this.sfQuestionForm.removeAnswer(answer._id, this.answers);
      return this.setDirty();
    };

    this.actionClicked = (action) => {
      switch (action) {
        case FORM_QUESTION_ACTIONS.RESET:
          return this.resetAnswers();
        case FORM_QUESTION_ACTIONS.RESET_PREFILL:
          return this.resetToPrefillValue();
        default:
          return false;
      }
    };

    this.resetAnswers = () => {
      this.answers = this.answers.reduce((answers, answer) => {
        answers = this.sfQuestionForm.removeAnswer(answer._id, answers);
        return answers;
      }, this.answers);
      return this.setDirty();
    };

    this.resetToPrefillValue = () => {
      if (this.previousAnswers && this.previousAnswers.length) {
        this.resetAnswers();

        this.previousAnswers.forEach((answer) => {
          this.answers = this.sfQuestionForm.addAnswer(
            answer.values,
            this.answers,
            {
              metadata: answer.metadata,
              type: this.question.type,
            }
          );
        });
      }
      return this.setDirty();
    };
  },
};
