export const MissionsSentComponent = {
  templateUrl: 'missions/missions/components/missions-sent/missions-sent.html',
  controller: class MissionsSentCtrl {
    constructor(pubSubService, $state) {
      'ngInject';
      Object.assign(this, {
        pubSubService,
        $state,
      });
    }
    $onInit() {
      const { name } = this.$state.current;

      this.stateListener = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
        (params) => {
          if (name !== params.name || !this.reload) {
            return false;
          }
          this.reload();
          return true;
        }
      );
    }
    $onDestroy() {
      this.stateListener();
    }
  },
};
