import { StateService, TransitionPromise } from '@uirouter/core';

export class RouterService {
  // eslint-disable-next-line max-params
  constructor(
    private $state: StateService,
    private $ionicHistory: IonicV1.IonicHistoryService,
    private $ionicViewSwitcher: IonicV1.IonicViewSwitcher,
    private $stateParams: Record<string, any>
  ) {
    'ngInject';
  }

  goBack(fallbackBackLink?: string): TransitionPromise | void {
    const backView = this.$ionicHistory.backView();
    const currentView = this.$ionicHistory.currentView();

    if (backView && backView.stateId !== currentView.stateId) {
      return this.$ionicHistory.goBack();
    }

    const prevView = fallbackBackLink || this.getParentListName();

    if (!prevView || !this.$state.get(prevView)) {
      return this.$state.go('index.activity.details', { reload: true });
    }

    this.$ionicHistory.nextViewOptions({ disableBack: true });
    this.$ionicViewSwitcher.nextDirection('back');

    return this.$state.go(prevView, this.$stateParams, { reload: true });
  }

  getParentListName(): string | null {
    const currentState = this.$state.$current;
    const parent = currentState.parent;
    const parentListState = `${parent.self.name}.list`;

    return parent && currentState.self.name !== parentListState
      ? parentListState
      : null;
  }
  disableNextBack(): void {
    this.$ionicHistory.nextViewOptions({ disableBack: true });
  }
  removePreviousView(): void {
    this.$ionicHistory.removeBackView();
  }
  setPreviousHistory(): void {
    // Location replace issue: http://github.com/ionic-team/ionic/issues/1287
    this.$ionicHistory.currentView(this.$ionicHistory.backView());
  }

  isAPreviewState(): boolean {
    return this.$state.includes('preview');
  }
}
