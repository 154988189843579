import { ReportCommentsComponent } from './report-comments.component';

export default angular
  .module('sfMobile.components.report-comments', [
    'sfMobile.services.helpers',
    'sfMobile.services.dates',
    'sfMobile.services.modal',
    'sfMobile.services.objectId',
    'sfMobile.services.segment',
    'sfMobile.components.content',
    'sfMobile.components.footer-text-input',
  ])
  .component('sfReportComments', ReportCommentsComponent).name;
