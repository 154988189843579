import { CalendarEventsParamsService } from '@services/API/calendar-events-params/calendar-events-params.service';
import { ModalService } from '@services/Utils/Modal';

export const CustomParamsSelectorComponent: ng.IComponentOptions = {
  bindings: {
    title: '@',
    selected: '<',
    disabled: '<',
    onSave: '&',
    onReset: '&',
  },

  templateUrl: 'components/custom-params-selector/custom-params-selector.html',
  controller: class CustomParamsSelectorController {
    hasError = false;
    isLoading = false;
    title: string;
    disabled = false;
    placeholder: string;
    selected;

    onReset: () => void;
    onSave: (arg?: { params }) => void;

    constructor(
      private modalService: ModalService,
      private $translate: ng.translate.ITranslateService,
      private $q: ng.IQService,
      private calendarEventsParamsService: CalendarEventsParamsService
    ) {
      'ngInject';
    }

    $onInit() {
      this.updateLabel(this.selected?.length);
    }

    updateLabel(count = 0): void {
      this.placeholder = this.$translate.instant(
        'EVENT_FORM_SELECT_CUSTOM_FIELDS_PLACEHOLDER',
        {
          _nb: count,
        }
      );
    }

    resetParams() {
      this.updateLabel();
      this.onReset();
    }

    openSelectParamsModal() {
      let modal = { remove: () => {} };

      const template = `
          <sf-custom-params-selector-modal
            title="{{ $ctrl.title }}"
            selected="$ctrl.selected"
            on-close="$ctrl.modalClose(params)"
          ></sf-sf-custom-params-selector>
        `;

      const bindings = {
        title: this.title,
        selected: this.selected,
        modalClose: (params) => {
          modal.remove();
          this.updateLabel(params?.length || 0);

          if (params) {
            this.onSave({ params });
          }

          this.updateLabel(params?.length || 0);
        },
      };

      modal = this.modalService.open(template, bindings, {
        hardwareBackButtonClose: false,
      });
    }
  },
};
