import { TabService } from '.';

export class IonicTabService implements TabService {
  constructor(private $ionicTabsDelegate: IonicV1.IonicTabService) {
    'ngInject';
  }

  getTab(tabName: string): IonicV1.IonicTab | undefined {
    return this.$ionicTabsDelegate._instances.filter(
      (instance) => instance.$$delegateHandle === tabName
    )[0];
  }
  select(tabName: string, index = 0): void {
    const tab = this.getTab(tabName);

    if (!tab) {
      return;
    }
    tab.select(index);
  }
  getSelectedIndex(tabName: string): number {
    const tab = this.getTab(tabName);

    if (!tab) {
      throw new Error('Tab is not found');
    }
    return tab.selectedIndex();
  }
  showBar(tabName: string, hasToShow: boolean): void {
    const tab = this.getTab(tabName);

    if (tab) {
      tab.showBar(hasToShow);
    }
  }
}
