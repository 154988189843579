import { Task } from '../..';
import { APIGroupTask } from '../../../group-tasks/types';
import { User } from '../../../index';
import { UsersService } from '../../../services/API/users/users.service';

export class TaskAuthorController implements ng.IComponentController {
  // bindings
  task: Task | APIGroupTask;
  author: User;

  isMe = false;

  constructor(private usersService: UsersService) {
    'ngInject';
  }

  $onInit(): void {
    if (this.author) {
      this.isMe = this.usersService.isCurrentUser(this.author);
    }

    if (this.task) {
      this.usersService
        .getUser(this.task.contents.owner_id)
        .then((user: User): void => {
          this.author = user;

          this.isMe = this.usersService.isCurrentUser(this.author);
        });
    }
  }

  get fullname(): string {
    return this.author
      ? `${this.author.contents.firstName} ${this.author.contents.lastName}`
      : '';
  }
}
