import { uuidv4 } from '../helpers/utils';
export const setPreview = (dashboardComponent, iframe, preview) => {
    if (iframe) {
        const requestId = uuidv4();
        iframe.contentWindow.postMessage({
            action: 'setPreview',
            preview,
            requestId,
            _version: dashboardComponent.VERSION
        }, '*');
        return new Promise((resolve, reject) => {
            dashboardComponent.promises[requestId] = { resolve, reject };
            // Check if promise has been resolved after 2 seconds
            setTimeout(() => {
                if (dashboardComponent.promises[requestId]) {
                    const errorMessage = 'setPreview request timed out.';
                    dashboardComponent.promises[requestId].reject(new Error(errorMessage));
                    delete dashboardComponent.promises[requestId];
                }
            }, 2000);
        });
    }
    return Promise.reject(new Error('Cannot retrieve contentWindow.'));
};
