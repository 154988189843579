export const BarcodeButtonComponent = {
  bindings: {
    hasError: '<',
    onScan: '&',
    isDisabled: '<?',
  },
  templateUrl: 'components/Inputs/barcode-button/barcode-button.html',
  controller: function BarcodeButtonController($q, barcodeService) {
    'ngInject';

    this.scan = () => {
      this.hasScanError = false;

      return barcodeService.scan().then(
        (barcodeText) => {
          return $q.resolve(this.onScan({ ean: barcodeText }));
        },
        () => {
          this.hasScanError = true;
        }
      );
    };
  },
};
