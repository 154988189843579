import { CampaignCompletionFilterToggleController } from './campaign-completion-filter-toggle.controller';

export const CampaignCompletionFilterToggleComponent = {
  bindings: {
    data: '<',
    filter: '<',
    onToggle: '&',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  templateUrl:
    'legacy-campaigns/campaign/components/campaign-completion-filter-toggle/campaign-completion-filter-toggle.html',
  controller: CampaignCompletionFilterToggleController,
};
