import { CommentsResourceType } from '../../../comments/services/comments-api/comments-api.factory';

const REQUIRED_FIELDS = [
  'contents',
  'creator',
  'assignees',
  'originalEventType',
  'originalEventPlace',
  'requestedEventType',
  'requestedEventPlace',
];
const SORT_FIELD = '-created.seal_date';

export class EventChangeRequestsService {
  // eslint-disable-next-line max-params
  constructor(
    $http,
    $q,
    $translate,
    apiUtilsService,
    crudFactory,
    objectIdService,
    popupService,
    sfPOVService,
    userExperienceService,
    accessRightsService,
    CHANGE_REQUESTS_STATES,
    pubSubService,
    commentsFactory
  ) {
    'ngInject';
    this.$http = $http;
    this.$q = $q;
    this.$translate = $translate;
    this.apiUtilsService = apiUtilsService;
    this.objectIdService = objectIdService;
    this.crud = crudFactory('calendar_change_requests', {
      default_params: { mode: 'compact' },
      path_name: 'calendar-events/change-requests',
    });
    this.popupService = popupService;
    this.sfPOVService = sfPOVService;
    this.userExperienceService = userExperienceService;
    this.accessRightsService = accessRightsService;
    this.CHANGE_REQUESTS_STATES = CHANGE_REQUESTS_STATES;
    this.pubSubService = pubSubService;
    this.commentsService = commentsFactory(
      CommentsResourceType.CHANGE_REQUESTS
    );
  }

  getChangeRequests(params = {}) {
    const url = '/calendar-events/change-requests';

    return this.sfPOVService
      .pBuildURL(url, { pov: 'organisation' })
      .then((povUrl) => this.$http.get(povUrl, { params }))
      .then((res) => res.data);
  }

  getHttpRequestOptions(state) {
    return {
      fields: REQUIRED_FIELDS,
      sorts: SORT_FIELD,
      ...this.apiUtilsService.buildFilterParams([
        { name: 'state', value: state },
      ]),
    };
  }

  saveNew({ contents }) {
    return this.save({
      _id: this.objectIdService.create(),
      contents,
    });
  }

  save(changeRequest) {
    return this.crud.save(changeRequest._id, changeRequest, {
      pov: 'organisation',
    });
  }

  delete(changeRequestId) {
    return this.crud.delete(changeRequestId, {}, { pov: 'organisation' });
  }

  setState(changeRequestId, state) {
    const url = `/calendar-events/change-requests/${changeRequestId}`;

    return this.sfPOVService
      .pBuildURL(url, { pov: 'organisation' })
      .then((povUrl) =>
        this.$http.patch(povUrl, {
          contents: {
            state,
          },
        })
      )
      .then((res) => res.data);
  }

  approve(changeRequestId) {
    return this.setState(
      changeRequestId,
      this.CHANGE_REQUESTS_STATES.VALIDATED
    );
  }

  reject(changeRequestId) {
    return this.setState(changeRequestId, this.CHANGE_REQUESTS_STATES.REJECTED);
  }

  getStatesCounts() {
    const url = '/calendar-events/change-requests/counts';

    return this.sfPOVService
      .pBuildURL(url, { pov: 'organisation' })
      .then((povUrl) => this.$http.get(povUrl))
      .then((res) => {
        this.pubSubService.publish('CHANGE_REQUESTS_COUNT_UPDATED', res.data);
        return res.data;
      });
  }

  showDeleteAgreeModal() {
    return this.popupService.showConfirm({
      title: this.$translate.instant('CHANGE_REQUESTS_DELETE_CONFIRM_TITLE'),
      iconName: 'error',
      buttonText: this.$translate.instant(
        'CHANGE_REQUESTS_DELETE_CONFIRM_BUTTON'
      ),
    });
  }

  showCreateDeletionChangeRequestConfirmModal() {
    return this.popupService.showConfirm({
      title: this.$translate.instant(
        'CREATE_CHANGE_REQUEST_DELETION_CONFIRM_TITLE'
      ),
      iconName: 'error',
      description: this.$translate.instant(
        'CREATE_CHANGE_REQUEST_DELETION_CONFIRM_DESCR'
      ),
      buttonText: this.$translate.instant(
        'CREATE_CHANGE_REQUEST_DELETION_CONFIRM_BTN'
      ),
    });
  }

  canRequestChange() {
    return this.$q.when(
      this.userExperienceService.isAnimator() &&
        !this.accessRightsService.isAtLeastManager()
    );
  }

  canDelete() {
    return this.$q.when(this.accessRightsService.isAtLeastManager());
  }

  /*eslint class-methods-use-this: "off" */
  getCountByState({ state, counts, needFormat }) {
    const stateCounts = counts.filter((count) => count.state === state);

    const total = stateCounts.length ? stateCounts[0].total : 0;

    return needFormat ? formatBadgeCount(total) : total;
  }
}

export function formatBadgeCount(count) {
  return count < 100 ? count : '99+';
}
