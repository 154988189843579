"use strict";

/* eslint no-magic-numbers:[0], max-lines:[0] */
var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId(),
    name: 'marketting1-report',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'mission',
      state: 'empty',
      nodes: [],
      answers: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('form-1'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('report'),
    name: 'marketting2-report',
    description: 'Every data that can be set.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'mission',
      state: 'done',
      nodes: [{
        _id: createObjectId('node-1'),
        sections_ids: [createObjectId('section-1')],
        parents_ids: []
      }, {
        _id: createObjectId('node-2'),
        sections_ids: [createObjectId('section-2')],
        parents_ids: []
      }],
      answers: [{
        _id: createObjectId('marketting2-report-answer1'),
        question_id: createObjectId('question-1-1'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-1-1'),
          value: 'Jean Paul Belmondo'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-1-2'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-2-1'),
          value: 'jean-paul.belmondo@gmail.com'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-1-3'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-3-1'),
          value: 'Client presse.'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-1-4'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-4-1'),
          value: '2014-06-25T00:00:00.000Z'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-1'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-1-1'),
          value: 135000
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-2'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-2-1'),
          value: 130
        }, {
          field_id: createObjectId('field-2-2-2'),
          value: 'm2'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-3'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-3-1'),
          value: (1 * 24 + 30 * 3600 + 25 * 60) * 1000
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-4'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-4-1'),
          value: 'b0b0b0b0b0b0b0b0b0b0b0b0'
        }, {
          field_id: createObjectId('field-2-4-2'),
          value: 'facade.jpg'
        }, {
          field_id: createObjectId('field-2-4-3'),
          value: 'image/jpeg'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-5'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-5-1'),
          value: 'b1b1b1b1b1b1b1b1b1b1b1b1'
        }, {
          field_id: createObjectId('field-2-5-2'),
          value: 'facade.mpeg'
        }, {
          field_id: createObjectId('field-2-5-3'),
          value: 'video/mpeg'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-6'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-6-1'),
          value: 8
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('marketting2'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'unknow',
    description: 'Unknown contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_UNEXPECTED_CONTENT',
        params: ['.wadup']
      }]
    },
    partial: {
      valid: false
    },
    contents: {
      type: 'mission',
      state: 'empty',
      nodes: [],
      answers: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('organisation'),
      user_id: createObjectId('owner'),
      users_ids: [],
      wadup: {
        Kikoolol: true
      },
      trololol: 'facepalm'
    }
  }, {
    _id: createObjectId(),
    name: 'empty',
    description: 'Empty contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.type']
      }]
    },
    partial: {
      valid: true
    },
    contents: {}
  }, {
    _id: createObjectId(),
    name: 'noowner',
    description: 'No owner',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.owner_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'mission',
      state: 'empty',
      nodes: [],
      answers: [],
      organisation_id: createObjectId('organisation'),
      form_id: createObjectId('organisation'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('oneq-report'),
    name: 'onequestion-report',
    description: 'A report with only one answer to one question..',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'share',
      state: 'done',
      nodes: [{
        _id: createObjectId('oneq-report-node'),
        sections_ids: [createObjectId('oneq-section')],
        parents_ids: []
      }],
      answers: [{
        _id: createObjectId('oneq-report-answer'),
        question_id: createObjectId('oneq-question'),
        sections_ids: [createObjectId('oneq-section')],
        nodes_ids: [createObjectId('oneq-report-node')],
        type: 'generic',
        values: [{
          field_id: createObjectId('oneq-field'),
          value: 'Hello magical world!'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('oneq'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('inventory-report'),
    name: 'inventory-report',
    description: 'The SimpliField inventory.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'share',
      state: 'done',
      nodes: [{
        _id: createObjectId('inventory-officies-node-1'),
        sections_ids: [createObjectId('inventory-officies')],
        parents_ids: []
      }, {
        _id: createObjectId('inventory-rooms-node-1'),
        sections_ids: [createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        parents_ids: [createObjectId('inventory-officies-node-1')]
      }, {
        _id: createObjectId('inventory-furnitures-node-1'),
        sections_ids: [createObjectId('inventory-furnitures'), createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        parents_ids: [createObjectId('inventory-rooms-node-1'), createObjectId('inventory-officies-node-1')]
      }, {
        _id: createObjectId('inventory-employees-node-1'),
        sections_ids: [createObjectId('inventory-employees'), createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        parents_ids: [createObjectId('inventory-rooms-node-1'), createObjectId('inventory-officies-node-1')]
      }, {
        _id: createObjectId('inventory-corridors-node-1'),
        sections_ids: [createObjectId('inventory-corridors'), createObjectId('inventory-officies')],
        parents_ids: [createObjectId('inventory-officies-node-1')]
      }, {
        _id: createObjectId('inventory-furnitures2-node-1'),
        sections_ids: [createObjectId('inventory-furnitures2'), createObjectId('inventory-corridors'), createObjectId('inventory-officies')],
        parents_ids: [createObjectId('inventory-corridors-node-1'), createObjectId('inventory-officies-node-1')]
      }],
      answers: [{
        _id: createObjectId('inventory-officies-title-answer-1'),
        question_id: createObjectId('inventory-officies-title'),
        sections_ids: [createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-officies-title-field-1'),
          value: 'Bureau principal'
        }]
      }, {
        _id: createObjectId('inventory-officies-description-answer-1'),
        question_id: createObjectId('inventory-officies-description'),
        sections_ids: [createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-officies-description-field-1'),
          value: "L'endroit où tout le monde travaille."
        }]
      }, {
        _id: createObjectId('inventory-officies-surface-answer-1'),
        question_id: createObjectId('inventory-officies-surface'),
        sections_ids: [createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-officies-surface-field-1'),
          value: 70
        }, {
          field_id: createObjectId('inventory-officies-surface-field-2'),
          value: 'm2'
        }]
      }, {
        _id: createObjectId('inventory-officies-price-answer-1'),
        question_id: createObjectId('inventory-officies-price'),
        sections_ids: [createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-officies-price-field-1'),
          value: 80000.0
        }]
      }, {
        _id: createObjectId('inventory-officies-rating-answer-1'),
        question_id: createObjectId('inventory-officies-rating'),
        sections_ids: [createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-officies-rating-field-1'),
          value: 8
        }]
      }, {
        _id: createObjectId('inventory-officies-date-answer-1'),
        question_id: createObjectId('inventory-officies-date'),
        sections_ids: [createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-officies-date-field-1'),
          value: '2015-10-26T10:32:47.563Z'
        }]
      }, {
        _id: createObjectId('inventory-officies-hour-answer-1'),
        question_id: createObjectId('inventory-officies-hour'),
        sections_ids: [createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-officies-hour-field-1'),
          value: 36000000 * 12
        }]
      }, {
        _id: createObjectId('inventory-officies-duration-answer-1'),
        question_id: createObjectId('inventory-officies-duration'),
        sections_ids: [createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-officies-duration-field-1'),
          value: 36000000
        }]
      }, {
        _id: createObjectId('inventory-furnitures-barcode-answer-1'),
        question_id: createObjectId('inventory-furnitures-barcode'),
        sections_ids: [createObjectId('inventory-furnitures'), createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-furnitures-node-1'), createObjectId('inventory-rooms-node-1'), createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-furnitures-barcode-field-1'),
          value: 166416641664
        }]
      }, {
        _id: createObjectId('inventory-furnitures-state-answer-1'),
        question_id: createObjectId('inventory-furnitures-state'),
        sections_ids: [createObjectId('inventory-furnitures'), createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-furnitures-node-1'), createObjectId('inventory-rooms-node-1'), createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-furnitures-state-field-1'),
          value: 'Excellent'
        }]
      }, {
        _id: createObjectId('inventory-furnitures-color-answer-1.1'),
        question_id: createObjectId('inventory-furnitures-color'),
        sections_ids: [createObjectId('inventory-furnitures'), createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-furnitures-node-1'), createObjectId('inventory-rooms-node-1'), createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-furnitures-color-field-1'),
          value: 'Noir'
        }]
      }, {
        _id: createObjectId('inventory-furnitures-color-answer-1.2'),
        question_id: createObjectId('inventory-furnitures-color'),
        sections_ids: [createObjectId('inventory-furnitures'), createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-furnitures-node-1'), createObjectId('inventory-rooms-node-1'), createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-furnitures-color-field-1'),
          value: 'Blanc'
        }]
      }, {
        _id: createObjectId('inventory-furnitures-type-answer-1'),
        question_id: createObjectId('inventory-furnitures-type'),
        sections_ids: [createObjectId('inventory-furnitures'), createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-furnitures-node-1'), createObjectId('inventory-rooms-node-1'), createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-furnitures-type-field-1'),
          value: 'Bureau'
        }]
      }, {
        _id: createObjectId('inventory-furnitures-image-answer-1'),
        question_id: createObjectId('inventory-furnitures-image'),
        sections_ids: [createObjectId('inventory-furnitures'), createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-furnitures-node-1'), createObjectId('inventory-rooms-node-1'), createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-furnitures-image-field-1'),
          value: 'b0b0b0b0b0b0b0b0b0b0b0b0'
        }, {
          field_id: createObjectId('inventory-furnitures-image-field-2'),
          value: 'façade.jpg'
        }, {
          field_id: createObjectId('inventory-furnitures-image-field-3'),
          value: 'image/jpeg'
        }]
      }, {
        _id: createObjectId('inventory-employees-audio-answer-1'),
        question_id: createObjectId('inventory-employees-audio'),
        sections_ids: [createObjectId('inventory-employees'), createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-employees-node-1'), createObjectId('inventory-rooms-node-1'), createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-employees-audio-field-1'),
          value: 'b1b1b1b1b1b1b1b1b1b1b1b1'
        }, {
          field_id: createObjectId('inventory-employees-audio-field-2'),
          value: 'interview.wav'
        }, {
          field_id: createObjectId('inventory-employees-audio-field-3'),
          value: 'audio/wav'
        }, {
          field_id: createObjectId('inventory-employees-audio-field-4'),
          value: 150000
        }]
      }, {
        _id: createObjectId('inventory-rooms-video-answer-1'),
        question_id: createObjectId('inventory-rooms-video'),
        sections_ids: [createObjectId('inventory-rooms'), createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-rooms-node-1'), createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-rooms-video-field-1'),
          value: 'b1b1b1b1b1b1b1b1b1b1b1b1'
        }, {
          field_id: createObjectId('inventory-rooms-video-field-2'),
          value: 'showcase.mpeg'
        }, {
          field_id: createObjectId('inventory-rooms-video-field-3'),
          value: 'video/mpeg'
        }, {
          field_id: createObjectId('inventory-rooms-video-field-4'),
          value: 150000
        }]
      }, {
        _id: createObjectId('inventory-furnitures2-count-answer-1'),
        question_id: createObjectId('inventory-furnitures2-count'),
        sections_ids: [createObjectId('inventory-furnitures2'), createObjectId('inventory-corridors'), createObjectId('inventory-officies')],
        nodes_ids: [createObjectId('inventory-furnitures2-node-1'), createObjectId('inventory-corridors-node-1'), createObjectId('inventory-officies-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('inventory-furnitures2-count-field-1'),
          value: 3
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('oneq'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('report2'),
    name: 'marketting2-report2',
    description: 'Every data that can be set with several nodes.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'mission',
      state: 'done',
      nodes: [{
        _id: createObjectId('node-1.1'),
        sections_ids: [createObjectId('section-1')],
        parents_ids: []
      }, {
        _id: createObjectId('node-1.2'),
        sections_ids: [createObjectId('section-1')],
        parents_ids: []
      }, {
        _id: createObjectId('node-2'),
        sections_ids: [createObjectId('section-2')],
        parents_ids: []
      }],
      answers: [{
        _id: createObjectId('marketting2-report-answer1'),
        question_id: createObjectId('question-1-1'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1.1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-1-1'),
          value: 'Jean Paul Belmondo'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-1-2'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1.1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-2-1'),
          value: 'jean-paul.belmondo@gmail.com'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-1-3'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1.1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-3-1'),
          value: 'Client presse.'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-1-4'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1.1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-4-1'),
          value: '2014-06-25T00:00:00.000Z'
        }]
      }, {
        _id: createObjectId('marketting2-report-answer2'),
        question_id: createObjectId('question-1-1'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1.2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-1-1'),
          value: 'Marie'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-1-2'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1.2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-2-1'),
          value: 'marie@gmail.com'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-1-3'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1.2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-3-1'),
          value: 'Cliente pressee.'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-1-4'),
        sections_ids: [createObjectId('section-1')],
        nodes_ids: [createObjectId('node-1.2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-1-4-1'),
          value: '2014-06-25T00:00:00.000Z'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-1'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-1-1'),
          value: 135000
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-2'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-2-1'),
          value: 130
        }, {
          field_id: createObjectId('field-2-2-2'),
          value: 'm2'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-3'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-3-1'),
          value: (1 * 24 + 30 * 3600 + 25 * 60) * 1000
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-4'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-4-1'),
          value: 'b0b0b0b0b0b0b0b0b0b0b0b0'
        }, {
          field_id: createObjectId('field-2-4-2'),
          value: 'facade.jpg'
        }, {
          field_id: createObjectId('field-2-4-3'),
          value: 'image/jpeg'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-5'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-5-1'),
          value: 'b1b1b1b1b1b1b1b1b1b1b1b1'
        }, {
          field_id: createObjectId('field-2-5-2'),
          value: 'facade.mpeg'
        }, {
          field_id: createObjectId('field-2-5-3'),
          value: 'video/mpeg'
        }]
      }, {
        _id: createObjectId(),
        question_id: createObjectId('question-2-6'),
        sections_ids: [createObjectId('section-2')],
        nodes_ids: [createObjectId('node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('field-2-6-1'),
          value: 8
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('marketting2'),
      missionsGroup_id: createObjectId('missionsGroup1'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('tricky-report'),
    name: 'tricky-report',
    description: 'A tricky report with only one answer per question.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'share',
      state: 'done',
      nodes: [{
        _id: createObjectId('tricky-node-1#1'),
        sections_ids: [createObjectId('tricky-section-1')],
        parents_ids: []
      }, {
        _id: createObjectId('tricky-node-1.1#1.1'),
        sections_ids: [createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        parents_ids: [createObjectId('tricky-node-1#1')]
      }, {
        _id: createObjectId('tricky-node-1.1.1#1.1.1'),
        sections_ids: [createObjectId('tricky-section-1.1.1'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        parents_ids: [createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')]
      }, {
        _id: createObjectId('tricky-node-1.1.2#1.1.1'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        parents_ids: [createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')]
      }, {
        _id: createObjectId('tricky-node-2#1'),
        sections_ids: [createObjectId('tricky-section-2')],
        parents_ids: []
      }, {
        _id: createObjectId('tricky-node-2.1#1.1'),
        sections_ids: [createObjectId('tricky-section-2.1'), createObjectId('tricky-section-2')],
        parents_ids: [createObjectId('tricky-node-2#1')]
      }, {
        _id: createObjectId('tricky-node-2.2#1.1'),
        sections_ids: [createObjectId('tricky-section-2.2'), createObjectId('tricky-section-2')],
        parents_ids: [createObjectId('tricky-node-2#1')]
      }],
      answers: [{
        _id: createObjectId('tricky-answer-1#1'),
        question_id: createObjectId('tricky-question-1'),
        sections_ids: [createObjectId('tricky-section-1')],
        nodes_ids: [createObjectId('tricky-node-1#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-1'),
          value: 'A1#1'
        }]
      }, {
        _id: createObjectId('tricky-answer-1.1.1#1.1.1'),
        question_id: createObjectId('tricky-question-1.1.1'),
        sections_ids: [createObjectId('tricky-section-1.1.1'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        nodes_ids: [createObjectId('tricky-node-1.1.1#1.1.1'), createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-1.1.1'),
          value: 'A1.1.1#1.1.1'
        }]
      }, {
        _id: createObjectId('tricky-answer-1.1.2#1.1.1'),
        question_id: createObjectId('tricky-question-1.1.2'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        nodes_ids: [createObjectId('tricky-node-1.1.2#1.1.1'), createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-1.1.2'),
          value: 'A1.1.2#1.1.1'
        }]
      }, {
        _id: createObjectId('tricky-answer-2.1#1.1'),
        question_id: createObjectId('tricky-question-2.1'),
        sections_ids: [createObjectId('tricky-section-2.1'), createObjectId('tricky-section-2')],
        nodes_ids: [createObjectId('tricky-node-2.1#1.1'), createObjectId('tricky-node-2#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-2.1'),
          value: 'A2.1#1.1'
        }]
      }, {
        _id: createObjectId('tricky-answer-2.2#1.1'),
        question_id: createObjectId('tricky-question-2.2'),
        sections_ids: [createObjectId('tricky-section-2.2'), createObjectId('tricky-section-2')],
        nodes_ids: [createObjectId('tricky-node-2.2#1.1'), createObjectId('tricky-node-2#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-2.2'),
          value: 'A2.2#1.1'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('tricky-form'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('tricky-report-2'),
    name: 'tricky-report-2',
    description: 'A tricky report with answer/question hole.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'share',
      state: 'done',
      nodes: [{
        _id: createObjectId('tricky-node-1#1'),
        sections_ids: [createObjectId('tricky-section-1')],
        parents_ids: []
      }, {
        _id: createObjectId('tricky-node-1.1#1.1'),
        sections_ids: [createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        parents_ids: [createObjectId('tricky-node-1#1')]
      }, {
        _id: createObjectId('tricky-node-1.1.2#1.1.1'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        parents_ids: [createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')]
      }, {
        _id: createObjectId('tricky-node-2#1'),
        sections_ids: [createObjectId('tricky-section-2')],
        parents_ids: []
      }, {
        _id: createObjectId('tricky-node-2.1#1.1'),
        sections_ids: [createObjectId('tricky-section-2.1'), createObjectId('tricky-section-2')],
        parents_ids: [createObjectId('tricky-node-2#1')]
      }, {
        _id: createObjectId('tricky-node-2.2#1.1'),
        sections_ids: [createObjectId('tricky-section-2.2'), createObjectId('tricky-section-2')],
        parents_ids: [createObjectId('tricky-node-2#1')]
      }],
      answers: [{
        _id: createObjectId('tricky-answer-1#1'),
        question_id: createObjectId('tricky-question-1'),
        sections_ids: [createObjectId('tricky-section-1')],
        nodes_ids: [createObjectId('tricky-node-1#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-1'),
          value: 'A1#1'
        }]
      }, {
        _id: createObjectId('tricky-answer-1.1.2#1.1.1'),
        question_id: createObjectId('tricky-question-1.1.2'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        nodes_ids: [createObjectId('tricky-node-1.1.2#1.1.1'), createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-1.1.2'),
          value: 'A1.1.2#1.1.1'
        }]
      }, {
        _id: createObjectId('tricky-answer-2.1#1.1'),
        question_id: createObjectId('tricky-question-2.1'),
        sections_ids: [createObjectId('tricky-section-2.1'), createObjectId('tricky-section-2')],
        nodes_ids: [createObjectId('tricky-node-2.1#1.1'), createObjectId('tricky-node-2#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-2.1'),
          value: 'A2.1#1.1'
        }]
      }, {
        _id: createObjectId('tricky-answer-2.2#1.1'),
        question_id: createObjectId('tricky-question-2.2'),
        sections_ids: [createObjectId('tricky-section-2.2'), createObjectId('tricky-section-2')],
        nodes_ids: [createObjectId('tricky-node-2.2#1.1'), createObjectId('tricky-node-2#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-2.2'),
          value: 'A2.2#1.1'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('tricky-form'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('tricky-report-3'),
    name: 'tricky-report-3',
    description: 'A tricky report with variable answers/nodes per sections/questions.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'share',
      state: 'done',
      nodes: [{
        _id: createObjectId('tricky-node-1#1'),
        sections_ids: [createObjectId('tricky-section-1')],
        parents_ids: []
      }, {
        _id: createObjectId('tricky-node-1.1#1.1'),
        sections_ids: [createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        parents_ids: [createObjectId('tricky-node-1#1')]
      }, {
        _id: createObjectId('tricky-node-1.1.2#1.1.1'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        parents_ids: [createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')]
      }, {
        _id: createObjectId('tricky-node-1.1.2#1.1.2'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        parents_ids: [createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')]
      }, {
        _id: createObjectId('tricky-node-1.1.2#1.1.3'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        parents_ids: [createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')]
      }, {
        _id: createObjectId('tricky-node-2#1'),
        sections_ids: [createObjectId('tricky-section-2')],
        parents_ids: []
      }, {
        _id: createObjectId('tricky-node-2.1#1.1'),
        sections_ids: [createObjectId('tricky-section-2.1'), createObjectId('tricky-section-2')],
        parents_ids: [createObjectId('tricky-node-2#1')]
      }, {
        _id: createObjectId('tricky-node-2.2#1.1'),
        sections_ids: [createObjectId('tricky-section-2.2'), createObjectId('tricky-section-2')],
        parents_ids: [createObjectId('tricky-node-2#1')]
      }],
      answers: [{
        _id: createObjectId('tricky-answer-1#1'),
        question_id: createObjectId('tricky-question-1'),
        sections_ids: [createObjectId('tricky-section-1')],
        nodes_ids: [createObjectId('tricky-node-1#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-1'),
          value: 'A1#1'
        }]
      }, {
        _id: createObjectId('tricky-answer-1.1.2#1.1.1'),
        question_id: createObjectId('tricky-question-1.1.2'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        nodes_ids: [createObjectId('tricky-node-1.1.2#1.1.1'), createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-1.1.2'),
          value: 'A1.1.2#1.1.1'
        }]
      }, {
        _id: createObjectId('tricky-answer-1.1.2#1.1.2'),
        question_id: createObjectId('tricky-question-1.1.2'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        nodes_ids: [createObjectId('tricky-node-1.1.2#1.1.2'), createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-1.1.2'),
          value: 'A1.1.2#1.1.2'
        }]
      }, {
        _id: createObjectId('tricky-answer-1.1.2#1.1.3'),
        question_id: createObjectId('tricky-question-1.1.2'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        nodes_ids: [createObjectId('tricky-node-1.1.2#1.1.3'), createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-1.1.2'),
          value: 'A1.1.2#1.1.3'
        }]
      }, {
        _id: createObjectId('tricky-answer-1.1.2#1.1.3+'),
        question_id: createObjectId('tricky-question-1.1.2'),
        sections_ids: [createObjectId('tricky-section-1.1.2'), createObjectId('tricky-section-1.1'), createObjectId('tricky-section-1')],
        nodes_ids: [createObjectId('tricky-node-1.1.2#1.1.3'), createObjectId('tricky-node-1.1#1.1'), createObjectId('tricky-node-1#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-1.1.2'),
          value: 'A1.1.2#1.1.3+'
        }]
      }, {
        _id: createObjectId('tricky-answer-2.1#1.1'),
        question_id: createObjectId('tricky-question-2.1'),
        sections_ids: [createObjectId('tricky-section-2.1'), createObjectId('tricky-section-2')],
        nodes_ids: [createObjectId('tricky-node-2.1#1.1'), createObjectId('tricky-node-2#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-2.1'),
          value: 'A2.1#1.1'
        }]
      }, {
        _id: createObjectId('tricky-answer-2.2#1.1'),
        question_id: createObjectId('tricky-question-2.2'),
        sections_ids: [createObjectId('tricky-section-2.2'), createObjectId('tricky-section-2')],
        nodes_ids: [createObjectId('tricky-node-2.2#1.1'), createObjectId('tricky-node-2#1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('tricky-section-field-2.2'),
          value: 'A2.2#1.1'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('tricky-form'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('people-report-1'),
    name: 'people-report-1',
    description: 'A people health report filled up with only one node',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'mission',
      state: 'done',
      nodes: [{
        _id: createObjectId('people-report-1-node-1'),
        sections_ids: [createObjectId('people-section')],
        parents_ids: []
      }],
      answers: [{
        _id: createObjectId('people-report-1-node-1-answer-1'),
        question_id: createObjectId('people-height-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-1-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-height-field'),
          value: 1.82
        }]
      }, {
        _id: createObjectId('people-report-1-node-1-answer-2'),
        question_id: createObjectId('people-weight-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-1-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-weight-field'),
          value: 116
        }]
      }, {
        _id: createObjectId('people-report-1-node-1-answer-3'),
        question_id: createObjectId('people-bmi-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-1-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-bmi-field'),
          value: 116 / Math.pow(1.82, 2)
        }]
      }, {
        _id: createObjectId('people-report-1-node-1-answer-4'),
        question_id: createObjectId('people-sport-overweigthed-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-1-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-sport-overweigthed-field'),
          value: 'No sport at all'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('health-metrics'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('people-report-2'),
    name: 'people-report-2',
    description: 'A people health report filled up with several nodes',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'mission',
      state: 'done',
      nodes: [{
        _id: createObjectId('people-report-2-node-1'),
        sections_ids: [createObjectId('people-section')],
        parents_ids: []
      }, {
        _id: createObjectId('people-report-2-node-2'),
        sections_ids: [createObjectId('people-section')],
        parents_ids: []
      }, {
        _id: createObjectId('people-report-2-node-3'),
        sections_ids: [createObjectId('people-section')],
        parents_ids: []
      }],
      answers: [{
        _id: createObjectId('people-report-2-node-1-answer-1'),
        question_id: createObjectId('people-height-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-height-field'),
          value: 1.82
        }]
      }, {
        _id: createObjectId('people-report-2-node-1-answer-2'),
        question_id: createObjectId('people-weight-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-weight-field'),
          value: 40
        }]
      }, {
        _id: createObjectId('people-report-2-node-1-answer-3'),
        question_id: createObjectId('people-bmi-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-bmi-field'),
          value: 40 / Math.pow(1.82, 2)
        }]
      }, {
        _id: createObjectId('people-report-2-node-1-answer-4'),
        question_id: createObjectId('people-sport-underweigthed-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-sport-underweigthed-field'),
          value: '0-2 hours per week'
        }]
      }, {
        _id: createObjectId('people-report-2-node-2-answer-1'),
        question_id: createObjectId('people-height-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-height-field'),
          value: 1.82
        }]
      }, {
        _id: createObjectId('people-report-2-node-2-answer-2'),
        question_id: createObjectId('people-weight-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-weight-field'),
          value: 85
        }]
      }, {
        _id: createObjectId('people-report-2-node-2-answer-3'),
        question_id: createObjectId('people-bmi-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-bmi-field'),
          value: 85 / Math.pow(1.82, 2)
        }]
      }, {
        _id: createObjectId('people-report-2-node-2-answer-4'),
        question_id: createObjectId('people-sport-normal-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-2')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-sport-normal-field'),
          value: 'More than 4 hours per week'
        }]
      }, {
        _id: createObjectId('people-report-2-node-3-answer-1'),
        question_id: createObjectId('people-height-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-3')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-height-field'),
          value: 1.82
        }]
      }, {
        _id: createObjectId('people-report-2-node-3-answer-2'),
        question_id: createObjectId('people-weight-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-3')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-weight-field'),
          value: 116
        }]
      }, {
        _id: createObjectId('people-report-2-node-3-answer-3'),
        question_id: createObjectId('people-bmi-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-3')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-bmi-field'),
          value: 116 / Math.pow(1.82, 2)
        }]
      }, {
        _id: createObjectId('people-report-2-node-3-answer-4'),
        question_id: createObjectId('people-sport-overweigthed-question'),
        sections_ids: [createObjectId('people-section')],
        nodes_ids: [createObjectId('people-report-2-node-3')],
        type: 'generic',
        values: [{
          field_id: createObjectId('people-sport-overweigthed-field'),
          value: 'No sport at all'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('health-metrics'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('conditional-section-report-1'),
    name: 'conditional-section-report-1',
    description: 'A report with a section disabled due to an answer.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'share',
      state: 'done',
      nodes: [{
        _id: createObjectId('conditional-section-node-1'),
        sections_ids: [createObjectId('conditional-section-1')],
        parents_ids: []
      }],
      answers: [{
        _id: createObjectId('conditional-section-answer-1'),
        question_id: createObjectId('conditional-section-question-1'),
        sections_ids: [createObjectId('conditional-section-1')],
        nodes_ids: [createObjectId('conditional-section-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('conditional-section-field-1'),
          value: 'No'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('conditional-section-form'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }, {
    _id: createObjectId('conditional-section-report-2'),
    name: 'conditional-section-report-2',
    description: 'A report with a section enabled due to an answer.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      type: 'share',
      state: 'done',
      nodes: [{
        _id: createObjectId('conditional-section-node-1'),
        sections_ids: [createObjectId('conditional-section-1')],
        parents_ids: []
      }, {
        _id: createObjectId('conditional-section-node-2'),
        sections_ids: [createObjectId('conditional-section-2'), createObjectId('conditional-section-1')],
        parents_ids: [createObjectId('conditional-section-node-1')]
      }],
      answers: [{
        _id: createObjectId('conditional-section-answer-1'),
        question_id: createObjectId('conditional-section-question-1'),
        sections_ids: [createObjectId('conditional-section-1')],
        nodes_ids: [createObjectId('conditional-section-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('conditional-section-field-1'),
          value: 'Yes'
        }]
      }, {
        _id: createObjectId('conditional-section-answer-2'),
        question_id: createObjectId('conditional-section-question-2'),
        sections_ids: [createObjectId('conditional-section-2'), createObjectId('conditional-section-1')],
        nodes_ids: [createObjectId('conditional-section-node-2'), createObjectId('conditional-section-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('conditional-section-field-2'),
          value: 'Fire risks'
        }]
      }, {
        _id: createObjectId('conditional-section-answer-3'),
        question_id: createObjectId('conditional-section-question-3'),
        sections_ids: [createObjectId('conditional-section-2'), createObjectId('conditional-section-1')],
        nodes_ids: [createObjectId('conditional-section-node-2'), createObjectId('conditional-section-node-1')],
        type: 'generic',
        values: [{
          field_id: createObjectId('conditional-section-field-3'),
          value: 'This girl is on fiirreeee!'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      form_id: createObjectId('conditional-section-form'),
      user_id: createObjectId('owner'),
      users_ids: []
    }
  }];
};