import { ButtonHeaderSynchronizeComponent } from './button-header-synchronize.component';

export default angular
  .module('sfMobile.components.button-header-synchronize', [
    'sfMobile.components.button-header-spinning',
    'sfMobile.services.synchronize',
    'sfMobile.services.segment',
  ])
  .component('sfButtonHeaderSynchronize', ButtonHeaderSynchronizeComponent)
  .name;
