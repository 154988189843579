import { FILES_UPLOAD_OPTIONS } from '../../../constants/files-upload-options.constant';

export class FileChooserService {
  /* @ngInject */
  constructor(
    private $q: ng.IQService,
    private $window: ng.IWindowService,
    private SF_FILES_UPLOAD_OPTIONS: typeof FILES_UPLOAD_OPTIONS
  ) {}

  getFile(accept?: string): ng.IPromise<File> {
    const defer = this.$q.defer<File>();
    const input: HTMLInputElement =
      this.$window.document.createElement('input');

    input.type = 'file';
    if (accept) {
      input.accept = accept;
    }
    input.addEventListener('change', () => {
      const reader = new this.$window.FileReader();
      const file = input.files ? input.files[0] : null;

      if (!file) {
        defer.reject();
      }

      reader.onload = () => {
        defer.resolve(file as File);
        input.remove();
      };
      reader.readAsDataURL(file);
    });
    input.style.visibility = 'hidden';
    input.click();
    this.$window.document.body.appendChild(input);
    return defer.promise;
  }

  getImage(): ng.IPromise<File> {
    return this.getFile(this.SF_FILES_UPLOAD_OPTIONS.IMAGE.accept);
  }
  getVideo(): ng.IPromise<File> {
    return this.getFile(this.SF_FILES_UPLOAD_OPTIONS.VIDEO.accept);
  }
  getDocument(): ng.IPromise<File> {
    return this.getFile(this.SF_FILES_UPLOAD_OPTIONS.DOCUMENT.accept);
  }
  getImageOrDocument(): ng.IPromise<File> {
    const accept =
      this.SF_FILES_UPLOAD_OPTIONS.IMAGE.accept +
      ',' +
      this.SF_FILES_UPLOAD_OPTIONS.DOCUMENT.accept;
    return this.getFile(accept);
  }
}
