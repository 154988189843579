import {
  DateRange,
  CompletionFilterType,
} from '../../../constants/campaign-completion.constants';
import { FilterValue } from '../../../legacy-campaigns/campaign/types/campaign-completion';
import { ObjectId } from '../../..';
import { APIStatsFilters } from '../../../analytics/types/analytics';
import { DateService } from '../Dates/date.service';

export class AnalyticsFilterService {
  /* @ngInject */
  constructor(
    private dateService: DateService,
    private SF_COMPLETION_FILTER_TYPE: typeof CompletionFilterType,
    private SF_DATE_RANGE: typeof DateRange
  ) {}

  updateFilter(
    apiFilters: APIStatsFilters,
    filter: FilterValue
  ): APIStatsFilters {
    switch (filter.id) {
      case this.SF_COMPLETION_FILTER_TYPE.date_period:
        return this.updateDateFilter(apiFilters, filter.value);
      case this.SF_COMPLETION_FILTER_TYPE.no_report:
        return this.updateNoReport(apiFilters, filter.value);
      case this.SF_COMPLETION_FILTER_TYPE.team:
        return this.updateTeamFilter(apiFilters, filter.value);
      case this.SF_COMPLETION_FILTER_TYPE.store_group:
        return this.updateStoreGroupFilter(apiFilters, filter.value);
      default:
        return apiFilters;
    }
  }

  updateDateFilter(
    apiFilters: APIStatsFilters,
    value: DateRange
  ): APIStatsFilters {
    const newFilters = { ...apiFilters };

    switch (value) {
      case this.SF_DATE_RANGE.today:
        newFilters.from = this.dateService.getStartOfDate(new Date());
        newFilters.to = new Date();
        break;
      case this.SF_DATE_RANGE.yesterday:
        newFilters.from = this.dateService.getStartOfDate(
          this.dateService.getDateFrom('days', 1)
        );
        newFilters.to = this.dateService.getStartOfDate(new Date());
        break;
      case this.SF_DATE_RANGE.last_week:
        newFilters.from = this.dateService.getStartOfDate(
          this.dateService.getDateFrom('days', new Date().getDay() - 1 + 7)
        );
        newFilters.to = this.dateService.getStartOfDate(
          this.dateService.getDateFrom('days', new Date().getDay() - 1)
        );
        break;
      case this.SF_DATE_RANGE.week_to_date:
        newFilters.from = this.dateService.getStartOfDate(
          this.dateService.getDateFrom('days', new Date().getDay() - 1)
        );
        newFilters.to = new Date();
        break;
      case this.SF_DATE_RANGE.last_six_months:
      default:
        newFilters.from = this.dateService.getStartOfDate(
          this.dateService.getDateFrom('months', 6)
        );
        newFilters.to = new Date();
        break;
    }

    return newFilters;
  }

  updateNoReport(apiFilters: APIStatsFilters, value: boolean): APIStatsFilters {
    const newFilters = { ...apiFilters };

    if (value) {
      newFilters.min = 0;
      newFilters.max = 0;
    } else {
      newFilters.min = 0;
      delete newFilters.max;
    }

    return newFilters;
  }

  updateTeamFilter(
    apiFilters: APIStatsFilters,
    value: ObjectId | null
  ): APIStatsFilters {
    if (!value) {
      return {
        ...apiFilters,
        teams_ids: [],
      };
    }
    return {
      ...apiFilters,
      teams_ids: [value],
    };
  }

  updateStoreGroupFilter(
    apiFilters: APIStatsFilters,
    value: ObjectId | null
  ): APIStatsFilters {
    if (!value) {
      return {
        ...apiFilters,
        stores_groups_ids: [],
      };
    }
    return {
      ...apiFilters,
      stores_groups_ids: [value],
    };
  }
}
