import { StateProvider } from '@uirouter/angularjs';

export default function documentLibraryRouteConfig(
  $stateProvider: StateProvider
): void {
  'ngInject';

  $stateProvider
    .state('index.document-library', {
      url: '/document-library',
      abstract: true,
      views: {
        'tab-document-library': {
          template: '<ion-nav-view></ion-nav-view>',
        },
      },
    })
    .state('index.document-library.list', {
      url: '',
      template: `
      <ion-view
        cache-view="false"
        ng-class="{ 'sf_view__has_nav_bar': $ctrl.isInNavBar}">
        <sf-document-library organisation="$ctrl.organisation"></sf-document-library>
      </ion-view>
    `,
      resolve: {
        rsvpRedirect: redirectIfFlagIsDisabled,
      },
      controller: DocLibController,
      controllerAs: '$ctrl',
    });
}

// @ngInject
function redirectIfFlagIsDisabled(documentLibraryService, $state, $timeout) {
  if (!documentLibraryService.hasFeatureFlag()) {
    return $timeout(() => $state.go('index.activity.details'), 0);
  }
  return true;
}

// @ngInject
function DocLibController(rsvpOrganisation, userExperienceService) {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  this.organisation = rsvpOrganisation;
  // @ts-ignore
  this.isInNavBar = userExperienceService
    // @ts-ignore
    .getNav(this.organisation)
    .find((nav) => nav.key === 'documentLibrary');
}
