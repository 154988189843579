import { PictureDrawingComponent } from './picture-drawing.component';

export default angular
  .module('sfMobile.components.picture-drawing', [
    'sfMobile.services.canvas-edition',
    'sfMobile.components.drawing-color-picker',
    'sfMobile.components.drawing-size-picker',
    'sfMobile.services.screen-orientation',
  ])
  .component('sfPictureDrawing', PictureDrawingComponent).name;
