import { CalendarEventsParamsService } from './calendar-events-params.service';

export default angular
  .module('sfMobile.services.calendar-events-params', [
    'sfMobile.services.places',
    'sfMobile.services.pov',
    'sfMobile.services.crud',
    'sfMobile.config.database',
  ])
  .service('calendarEventsParamsService', CalendarEventsParamsService).name;
