const NB_PLACES_LOADED = 20;
const NB_PLACES_SYNC_MAX = 300;
const ERROR_MESSAGE_DISPLAY_DURATION = 3000;
const PLACES_FILTERS_KEY = 'placesFilters';
const PLACESLISTS_FILTER_KEY = 'pos_areas';
const PLACES_CITY_FILTER_KEY = 'pos_city';
const PLACES_ZIPCODE_FILTER_KEY = 'pos_zipcode';
const PLACES_COUNTRY_FILTER_KEY = 'pos_country';
const STANDARD_FILTERS = [
  PLACESLISTS_FILTER_KEY,
  PLACES_CITY_FILTER_KEY,
  PLACES_ZIPCODE_FILTER_KEY,
  PLACES_COUNTRY_FILTER_KEY,
];

export const PlacesSynchronizationComponent = {
  bindings: {
    onClose: '&',
  },
  templateUrl:
    'places/places/components/places-synchronization-modal/places-synchronization-modal.html',
  // eslint-disable-next-line max-params
  controller: function PlacesSynchronizationModalController(
    localizationService,
    $q,
    $translate,
    $timeout,
    pubSubService,
    contentService,
    appMessageService,
    placesService,
    placesParamsService,
    geolocationService,
    localStorageService,
    helpersService,
    placesListsService,
    apiUtilsService,
    RequestsPaginate
  ) {
    'ngInject';

    this.$onInit = () => {
      this.isRTLNeeded = localizationService.shouldActivateRTL();

      const getLocalData = (key, fallbackData) =>
        localStorageService.get(key) || fallbackData;
      const savedFilter = getLocalData(PLACES_FILTERS_KEY, []);

      this.places = [];
      this.selectedPlaces = [];
      this.localPlaces = [];
      this.placeSearch = '';
      this.filters = [];
      this.placesFilters = savedFilter;
      this.NB_PLACES_SYNC_MAX = NB_PLACES_SYNC_MAX;

      this.isLoading = true;
      this.isSearchLoading = false;
      this.networkError = false;
      this.infiniteLoadError = false;
      this.searchError = false;

      this.requestPaginate = new RequestsPaginate(
        placesService.simpleApiList.bind(placesService, undefined),
        {
          limit: NB_PLACES_LOADED,
        }
      );

      return $q
        .all([
          placesParamsService.crud.listLocal(),
          placesService.listLocal(),
          placesListsService.listLocal(),
        ])
        .then(([placesParams, localPlaces, placesLists]) => {
          const standardFilters = [
            {
              id: PLACES_CITY_FILTER_KEY,
              label: $translate.instant('PLACES_CITY_FILTER_LABEL'),
            },
            {
              id: PLACES_ZIPCODE_FILTER_KEY,
              label: $translate.instant('PLACES_ZIPCODE_FILTER_LABEL'),
            },
            {
              id: PLACES_COUNTRY_FILTER_KEY,
              label: $translate.instant('PLACES_COUNTRY_FILTER_LABEL'),
            },
            {
              id: PLACESLISTS_FILTER_KEY,
              label: $translate.instant('PLACESLIST_FILTER_LABEL'),
              values: placesLists.map((list) => list.contents.name),
            },
          ];
          const paramFilters = placesParams
            .map((param) => ({
              id: param.name,
              label: param.name,
            }))
            .sort((aFilter, bFilter) =>
              helpersService.sortNatural(aFilter.label, bFilter.label)
            );

          this.placesListsNameHash = placesLists.reduce((hash, list) => {
            hash[list.contents.name] = list;
            return hash;
          }, {});

          this.filters = [].concat(standardFilters, paramFilters);
          this.localPlaces = localPlaces;
          this.selectedPlaces = this.localPlaces;

          return this.reload();
        })
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.reload = () => {
      this.initPlaces();
      return geolocationService.getCurrentPosition().then((position) => {
        this.userPosition = position;
        return this.getPlaces();
      });
    };

    this.initPlaces = () => {
      this.places = [];
      this.userPosition = null;
      this.errorMessage = '';
      this.infiniteLoadError = false;
      this.isAllSelected = false;
      this.selectedPlacesIds = this.getSelectedPlacesIdsHash();

      this.requestPaginate.reset();

      $timeout(
        () => contentService.scrollTopById('placesSynchronizationScroll'),
        0
      );
    };

    this.getPlaces = () => {
      this.infiniteLoadError = false;

      const params = this.getParams();

      return this.requestPaginate
        .call(params, true)
        .then((places) => {
          this.places = places.entities;
          return this.places;
        })
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        })
        .finally(() => {
          if (this.onScrollComplete) {
            this.onScrollComplete();
          }
        });
    };

    this.getParams = () => {
      const searchFilters = [].concat(
        this.placeSearch
          ? [{ name: 'pos_name', operator: '$regex', value: this.placeSearch }]
          : []
      );
      const standardFilters = this.placesFilters
        .filter((filter) => STANDARD_FILTERS.includes(filter.id))
        .map((filter) => ({
          name: filter.id,
          operator: filter.id === PLACESLISTS_FILTER_KEY ? '$eq' : '$regex',
          value: filter.value,
        }));
      const customParamsFilters = this.placesFilters
        .filter((filter) => !STANDARD_FILTERS.includes(filter.id))
        .map((filter) => ({
          name: `pos_pos_customparam_${filter.id}`,
          operator: '$regex',
          value: [filter.id, filter.value],
        }));
      const sortQueryParam = this.userPosition
        ? { near: `${this.userPosition.lng},${this.userPosition.lat}` }
        : {};

      const { filters } = apiUtilsService.buildFilterParams(
        [].concat(searchFilters, standardFilters, customParamsFilters)
      );

      return {
        filters,
        ...sortQueryParam,
      };
    };

    this.resetInfiniteLoadErrorState = () => {
      this.infiniteLoadError = false;
    };

    this.onPlaceSearchChange = (search) => {
      this.placeSearch = search;
      this.isSearchLoading = true;
      this.searchError = false;

      return this.reload()
        .catch(() => {
          this.searchError = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    };

    this.onFilterChange = (filters) => {
      this.placesFilters = filters;
      this.isSearchLoading = true;
      this.searchError = false;

      localStorageService.set(PLACES_FILTERS_KEY, filters);
      return this.reload()
        .catch(() => {
          this.searchError = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    };

    this.onPlaceSelected = (place) => {
      if (this.selectedPlacesIds[place._id]) {
        this.selectedPlaces = this.selectedPlaces.concat(place);
        if (this.selectedPlaces.length > NB_PLACES_SYNC_MAX) {
          this.displayMaxSyncReachedError();
        }

        return place;
      }
      this.selectedPlaces = this.selectedPlaces.filter(
        (p) => p._id !== place._id
      );

      if (this.selectedPlaces.length > NB_PLACES_SYNC_MAX) {
        this.displayMaxSyncReachedError();
      }

      return place;
    };

    this.onSaveButtonClick = () => {
      this.pendingRequest = true;
      this.errorMessage = '';

      return placesService
        .syncLocal({ entries: this.selectedPlaces })
        .then(() => {
          pubSubService.publish('PLACES_SYNCHRONIZATION_UPDATED');
          appMessageService.display(
            $translate.instant('PLACES_SYNCHRONIZATION_SUCCESS'),
            'success'
          );
          return this.onClose();
        })
        .catch(() => {
          this.errorMessage = $translate.instant(
            'PLACES_SYNCHRONIZATION_ERROR'
          );

          return $timeout(() => {
            this.errorMessage = '';
          }, ERROR_MESSAGE_DISPLAY_DURATION);
        })
        .finally(() => {
          this.pendingRequest = false;
        });
    };

    this.onSelectAllChanged = () => {
      this.isSelectAllLoading = true;

      const params = this.getParams();

      return placesService
        .simpleApiList(undefined, params, true)
        .then((places) => {
          if (this.isAllSelected) {
            this.selectedPlaces = this.selectedPlaces.concat(
              places.entries.filter(
                (place) =>
                  !this.selectedPlaces.some(
                    (selectedPlace) => selectedPlace._id === place._id
                  )
              )
            );
          } else {
            this.selectedPlaces = this.selectedPlaces.filter(
              (selectedPlace) =>
                !places.entries.some((place) => place._id === selectedPlace._id)
            );
          }

          this.selectedPlacesIds = this.getSelectedPlacesIdsHash();

          if (this.selectedPlaces.length > NB_PLACES_SYNC_MAX) {
            this.displayMaxSyncReachedError();
          }

          return this.selectedPlaces;
        })
        .catch(() => {
          this.isAllSelected = !this.isAllSelected;
          this.errorMessage = $translate.instant(
            'PLACES_SYNCHRONIZATION_SELECT_ALL_ERROR'
          );

          $timeout(() => {
            this.errorMessage = '';
          }, ERROR_MESSAGE_DISPLAY_DURATION);

          return this.selectedPlaces;
        })
        .finally(() => {
          this.isSelectAllLoading = false;
        });
    };

    this.displayMaxSyncReachedError = () => {
      this.errorMessage = $translate.instant(
        'PLACES_SYNCHRONIZATION_MAX_REACHED_ERROR',
        { nb: this.NB_PLACES_SYNC_MAX }
      );

      $timeout(() => {
        this.errorMessage = '';
      }, ERROR_MESSAGE_DISPLAY_DURATION);
    };

    this.getSelectedPlacesIdsHash = () =>
      this.selectedPlaces.reduce((acc, place) => {
        acc[place._id] = true;
        return acc;
      }, {});
  },
};
