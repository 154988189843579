import { CorporateEventItemController } from './corporate-event-item.controller';

export const CorporateEventItemComponent: ng.IComponentOptions = {
  bindings: {
    corporateEvent: '<',
    currentDate: '<',
    theme: '<',
    onDisplayDetails: '&',
  },
  transclude: true,
  templateUrl:
    'calendar-events/components/calendar/corporate-events/corporate-event-item/corporate-event-item.html',
  controller: CorporateEventItemController,
};
