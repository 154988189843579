import { EanProductPickerModule } from '../ean-product-picker/ean-product-picker.module';
import { ProductPickerComponent } from './product-picker.component';

export default angular
  .module('sfMobile.components.product-picker', [
    'sfMobile.components.entity-selector-modal',
    'sfMobile.components.barcode-button',
    'sfMobile.constants.currencies',
    'sfMobile.services.barcode',
    'sfMobile.services.objectId',
    EanProductPickerModule,
  ])
  .component('sfProductPicker', ProductPickerComponent).name;
