import { CorporateEventsApiService } from '../corporate-events-api/corporate-events-api.service';
import { CorporateEvent } from '../../types';
import { SfFeatureFlags } from '@simplifield/feature-flags';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import { DateService } from '../../../services/Utils/Dates/date.service';

export class CorporateEventsService {
  constructor(
    private corporateEventsApiService: CorporateEventsApiService,
    private dateService: DateService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private $q: ng.IQService
  ) {
    'ngInject';
  }

  hasFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.CORPORATE_EVENTS
    );
  }

  getCorporateEvents(date: Date): ng.IPromise<CorporateEvent[]> {
    if (!this.hasFeatureFlag()) {
      return this.$q.resolve([]);
    }
    const utcDate = this.dateService.moment.utc(
      this.dateService.toMoment(date).toArray()
    );
    const startDate = utcDate
      .clone()
      .startOf('day')
      .subtract(1, 'day')
      .toISOString();
    const endOfDate = utcDate.clone().endOf('day').add(1, 'day').toISOString();
    const filters: { $and: Record<string, unknown>[] } = {
      $and: [
        {
          $or: [
            {
              $and: [
                { event_start_date: { $lte: startDate } },
                { event_end_date: { $gte: endOfDate } },
              ],
            },
            {
              event_start_date: { $between: [startDate, endOfDate] },
            },
            { event_end_date: { $between: [startDate, endOfDate] } },
          ],
        },
      ],
    };

    return this.corporateEventsApiService.getCorporateEventsList({ filters });
  }

  getPeriodCorporateEvents(
    startDate: Date,
    endDate: Date
  ): ng.IPromise<CorporateEvent[]> {
    if (!this.hasFeatureFlag()) {
      return this.$q.resolve([]);
    }

    const startDateFormatted = this.dateService.moment
      .utc(startDate)
      .clone()
      .startOf('day')
      .subtract(1, 'day')
      .toISOString();
    const endDateFormatted = this.dateService.moment
      .utc(endDate)
      .clone()
      .endOf('day')
      .add(1, 'day')
      .toISOString();
    const filters: { $and: Record<string, unknown>[] } = {
      $and: [
        {
          $or: [
            {
              $and: [
                { event_start_date: { $lte: startDateFormatted } },
                { event_end_date: { $gte: endDateFormatted } },
              ],
            },
            {
              event_start_date: {
                $between: [startDateFormatted, endDateFormatted],
              },
            },
            {
              event_end_date: {
                $between: [startDateFormatted, endDateFormatted],
              },
            },
          ],
        },
      ],
    };

    return this.corporateEventsApiService.getCorporateEventsList({ filters });
  }
}
