import sfReportsValidationTabs from './components/reports-validation-tabs/reports-validation-tabs.component';
import { sfFormReportsValidationList } from './components/form-reports-validation-list/form-reports-validation-list.component';
import { sfCampaignReportsValidationList } from './components/campaign-reports-validation-list/campaign-reports-validation-list.component';
import sfReportsValidationRoutes from './reports-validation.routes.js';

export default angular
  .module('sfMobile.reports.reports-validation', ['sfMobile.services.pubsub'])
  .config(sfReportsValidationRoutes)
  .component('sfReportsValidationTabs', sfReportsValidationTabs)
  .component('sfFormReportsValidationList', sfFormReportsValidationList)
  .component('sfCampaignReportsValidationList', sfCampaignReportsValidationList)
  .name;
