export const FormScoreDetailsComponent = {
  bindings: {
    form: '<',
    campaign: '<',
    report: '<',
    place: '<',
    scoreIsActivate: '<',
    eanIsActivate: '<',
    onClose: '&',
    onSave: '&',
  },
  templateUrl:
    'missions/form/components/form-score-details/form-score-details.html',
  controller: class FormScoreDetailsController {
    constructor(dateFormatService, $timeout, contentService, $translate) {
      'ngInject';
      this.dateFormatService = dateFormatService;
      this.$timeout = $timeout;
      this.contentService = contentService;
      this.$translate = $translate;
    }
    $onInit() {
      this.getDateAndTimeFormatted =
        this.dateFormatService.getDateAndTimeFormatted;
      this.buttonSelectorOptions = [
        {
          id: 1,
          type: 'list',
          label: this.$translate.instant('REPORT_SCORING_TOGGLE_LIST_VIEW'),
        },
        {
          id: 2,
          type: 'graph',
          label: this.$translate.instant('REPORT_SCORING_TOGGLE_GRAPH_VIEW'),
        },
      ];
    }
    onGraphSelect(option) {
      this.$timeout(() => {
        this.activeGraphView = option;
        this.contentService.resizeById('formScoreDetailsContent');
      }, 0);
    }
  },
};
