import type { ValidationError } from '../../../services/Utils/FileValidator/file-validator.service';
import type {
  Popup,
  PopupService,
} from '../../../services/Utils/Popup/popup.service';

export class NewsfeedPopupService {
  constructor(
    private $translate: ng.translate.ITranslateService,
    private popupService: PopupService,
    private popupRequestService
  ) {
    'ngInject';
  }

  // Progress popups
  showPostSendingProgress() {
    return this.popupRequestService.show({
      progress: {
        title: this.$translate.instant('NEWSFEED_POST_CREATE_PENDING'),
      },
      success: {
        title: this.$translate.instant('NEWSFEED_POST_SUCCESS_TITLE'),
        desc: this.$translate.instant('NEWSFEED_POST_SUCCESS_DESC'),
        iconName: 'thumbsup',
        actions: [{ text: 'OK', type: 'button-dark' }],
      },
      error: {
        title: this.$translate.instant('NEWSFEED_POST_CREATE_ERROR_TITLE'),
        desc: this.$translate.instant('NEWSFEED_POST_CREATE_ERROR_DESC'),
      },
    });
  }

  showPictureCommentSendingProgress(onCancel: () => void) {
    const popupConfig = {
      progress: {
        title: this.$translate.instant(
          'NEWSFEED_PICTURE_COMMENT_SENDING_TITLE'
        ),
        desc: this.$translate.instant('NEWSFEED_PICTURE_COMMENT_SENDING_DESC'),
      },
      success: {
        title: this.$translate.instant(
          'NEWSFEED_PICTURE_COMMENT_SUCCESS_TITLE'
        ),
        desc: this.$translate.instant('NEWSFEED_PICTURE_COMMENT_SUCCESS_DESC'),
        iconName: 'thumbsup',
      },
      error: {
        title: this.$translate.instant('NEWSFEED_PICTURE_COMMENT_ERROR_TITLE'),
        desc: this.$translate.instant('NEWSFEED_PICTURE_COMMENT_ERROR_DESC'),
      },
    };

    return this.popupRequestService.show(popupConfig, onCancel);
  }

  // Confirm popups
  showCloseCreateEditConfirm(): ng.IPromise<void> {
    return this.popupService.showConfirm({
      title: this.$translate.instant('NEWSFEED_CLOSE_POST_FORM_CONFIRM_TITLE'),
      iconName: 'item-danger',
      buttonText: this.$translate.instant(
        'NEWSFEED_CLOSE_POST_FORM_CONFIRM_BUTTON'
      ),
    });
  }

  showDeleteConfirm(title: string, text: string): ng.IPromise<void> {
    return this.popupService.showConfirm({
      title: this.$translate.instant(title),
      iconName: 'item-danger',
      buttonText: this.$translate.instant(text),
    });
  }

  // Error popups
  showPostNotExistsError(onCancel?: () => void): Popup {
    return this.showError('NEWSFEED_POST_NOT_EXIST_ERROR_TITLE', onCancel);
  }

  showError(title: string, onCancel?: () => void): Popup {
    return this.popupService.showError(
      {
        title: this.$translate.instant(title),
      },
      onCancel
    );
  }

  showFileErrors(resourceErrors: ValidationError[]): Popup {
    const template = `
      <sf-newsfeed-resources-errors-popup
       title="$ctrl.title"
       resource-errors="$ctrl.resourceErrors" 
       on-close="$ctrl.onClose()"
      >
      </sf-newsfeed-resources-errors-popup>
    `;
    const bindings = {
      title: this.$translate.instant('NEWSFEED_EDIT_POST_RESOURCE_ERROR_TITLE'),
      resourceErrors,
    };

    return this.popupService.showCustomTemplate({ template, bindings });
  }

  showCheckVideoProgress(): Popup {
    return this.popupRequestService.show({
      progress: {
        title: this.$translate.instant('NEWSFEED_VIDEO_PROCESSING_TITLE'),
        desc: this.$translate.instant('NEWSFEED_VIDEO_PROCESSING_DESC'),
      },
      error: {
        title: this.$translate.instant('NEWSFEED_VIDEO_PROCESSING_ERROR_TITLE'),
        desc: this.$translate.instant('NEWSFEED_VIDEO_PROCESSING_ERROR_DESC'),
      },
    });
  }
}
