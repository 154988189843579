import { ProfileService } from './profile.service';

export default angular
  .module('sfMobile.services.profile', [
    'sfMobile.services.angular-sql-query',
    'sf.sqlStorage',
    'LocalStorageModule',
    'pascalprecht.translate',
    'sfMobile.services.auth',
    'sfMobile.services.dates',
    'sfMobile.services.api-url',
    'sfMobile.services.ping',
  ])
  .factory('profileService', ProfileService).name;
