export function ScrollToTab() {
  'ngInject';
  return {
    restrict: 'A',
    link: function (scope, element) {
      element.on('click', ({ target }) => {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      });
    },
  };
}
