import { CampaignsComponent } from './campaigns.component';
import CampaignItemModule from './components/campaign-item.module';

export default angular
  .module('sfMobile.components.campaigns', [
    'sfMobile.components.view-states',
    'sfMobile.services.segment',
    'sfMobile.services.forms',
    'sfMobile.services.pubsub',
    CampaignItemModule,
  ])
  .component('sfCampaigns', CampaignsComponent).name;
