import { runFormula, buildFormula } from '@simplifield/formula';

export const QuestionSimpleComponent = {
  bindings: {
    question: '<',
    hasError: '<',
    questionOptions: '<',
    report: '<',
    form: '<',
    answers: '=',
    actionClickedFn: '=?',
    previousAnswers: '<',
    isDisabled: '<?',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-simple/question-simple.html',
  controller: function QuestionSimpleController(
    pubSubService,
    FORM_QUESTION_ACTIONS
  ) {
    'ngInject';
    // https://www.ietf.org/rfc/rfc5322.txt
    this.emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    let copyData = null;
    let updateEvent = null;

    this.runFormula = runFormula;
    this.buildFormula = buildFormula;

    const isFieldEditable = (field) => field.editable !== false;
    const isFieldChanged = (field, form) =>
      !isFieldEditable(field) || (form && form.$pristine);
    const isDefined = (value) =>
      typeof value !== 'undefined' && value !== null && value !== '';

    const getQuestionType = (field) => {
      if (field.type === 'string' && field.format === 'text') {
        return 'comment';
      }
      if (field.type === 'number') {
        return 'unit';
      }
      return 'simple';
    };
    const getInlineType = (field) => {
      const format = field.format;
      const convertType = {
        phone: 'tel',
        uri: 'url',
        inline: 'text',
        regexp: 'text',
      };

      return field.type === 'string'
        ? convertType[format] || format
        : field.type;
    };

    const getFieldFormula = (field) =>
      field.formula
        ? new Function(this.buildFormula(field.type, field.formula)) // eslint-disable-line
        : null;

    this.$onInit = () => {
      this.firstField = this.question.fields[0];
      this.valueFormula = getFieldFormula(this.firstField);
      this.questionType = getQuestionType(this.firstField);
      this.fieldIsEditable = isFieldEditable(this.firstField);
      this.format = getInlineType(this.firstField);
      this.value = this.getAnswerValue();
      this.actionClickedFn = this.actionClicked;

      this.$onDestroy();
      if (this.valueFormula) {
        updateEvent = pubSubService.subscribe(
          'FORM_VALUE_UPDATE',
          this.onUpateValue
        );
      }

      copyData = this.value;
    };

    this.getAnswerValue = () => {
      const curValue = this.answers.length
        ? this.answers[0].values[0].value
        : null;

      return isDefined(curValue)
        ? curValue
        : this.valueFormula
        ? this.computeValue(this.valueFormula)
        : null;
    };

    // Kill event listener.
    this.$onDestroy = () => {
      if (updateEvent) {
        updateEvent();
      }
      return true;
    };

    this.getQuestionType = getQuestionType;

    this.onUpateValue = () => {
      const form = this['simple_' + this.question._id];

      // Kill event if value change
      if (isFieldEditable(this.firstField) && form && form.$dirty) {
        this.$onDestroy();
      }
      // Update value if necessary
      if (isFieldChanged(this.firstField, form)) {
        this.value = this.computeValue();
        this.answers = this.updateAnswers(this.value);
      }
    };

    this.getFieldUnit = () =>
      this.question.fields.filter(
        (field) => field.type === 'string' && field.set
      )[0];

    // Value change
    this.register = (value) => {
      if (copyData !== value) {
        this.answers = this.updateAnswers(value);
        copyData = value;
        pubSubService.publish('FORM_VALUE_UPDATE');
      }
    };
    this.updateAnswers = (value) => {
      const values = isDefined(value) ? this.getValues(value) : null;
      const answer = this.answers[0] || null;

      return values
        ? this.sfQuestionForm.saveAnswer(values, this.answers)
        : answer
        ? this.sfQuestionForm.removeAnswer(answer._id, this.answers)
        : this.answers;
    };
    // Get answer values.
    this.getValues = (value) => {
      const values = [{ field_id: this.firstField._id, value: value }];
      const fieldUnit = this.getFieldUnit();

      if (fieldUnit) {
        return values.concat({
          field_id: fieldUnit._id,
          value: fieldUnit.set[0],
        });
      }
      return values;
    };
    this.computeValue = () =>
      this.runFormula(
        this.firstField.type,
        this.valueFormula,
        {
          form: this.form.contents,
          time: Date.now.bind(Date),
          startDate: new Date(),
        },
        {
          answers: this.report.contents.answers,
        }
      );

    this.actionClicked = (action) => {
      switch (action) {
        case FORM_QUESTION_ACTIONS.RESET_PREFILL:
          return this.resetToPrefillValue();
        default:
          return false;
      }
    };

    this.resetToPrefillValue = () => {
      if (this.previousAnswers && this.previousAnswers.length) {
        this.updateAnswers(this.previousAnswers[0].values[0].value);
        this.value = this.getAnswerValue();
      }
    };

    // --------------------
    //      Get types
    // --------------------
  },
};
