import { NotificationReminder } from '../../../tasks/index';
import { DateService } from '../Dates/date.service';

const MILISECONDS_IN_MINUTE = 60_000;
const MILISECONDS_IN_HOUR = MILISECONDS_IN_MINUTE * 60;
const MILISECONDS_IN_DAY = MILISECONDS_IN_HOUR * 24;
const MILISECONDS_IN_WEEK = MILISECONDS_IN_DAY * 7;

export enum NotificationReminderPeriod {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  WEEKS = 'weeks',
}

export class NotificationsUtilsService {
  constructor(private dateService: DateService) {
    'ngInject';
  }

  notificationReminderToMiliseconds({
    period,
    value,
  }: NotificationReminder): number {
    switch (period) {
      case 'minutes':
        return value * MILISECONDS_IN_MINUTE;
      case 'hours':
        return value * MILISECONDS_IN_HOUR;
      case 'days':
        return value * MILISECONDS_IN_DAY;
      case 'weeks':
        return value * MILISECONDS_IN_WEEK;
      default:
        return 0;
    }
  }

  validateNotification(dueDate, dueTime, notificationReminder) {
    if (!notificationReminder || !dueDate) {
      return true;
    }

    const notificationMiliseconds =
      this.notificationReminderToMiliseconds(notificationReminder);
    const starOfDueDateMs = this.dateService.getStartOfDate(dueDate).getTime();
    let dueTimeMs = dueTime
      ? dueTime
      : this.dateService.getMilisecondsFromMidnight(
          this.dateService.getEndOfDate()
        );

    if (!dueTime && ['days', 'weeks'].includes(notificationReminder.period)) {
      // get midday
      dueTimeMs /= 2;
    }

    const dueDateTime = starOfDueDateMs + dueTimeMs;

    const notificationTime = dueDateTime - notificationMiliseconds;
    const isValid = notificationTime > this.dateService.timestampMilliseconds();

    return isValid;
  }
}
