import FormEndingModule from './components/form-ending/form-ending.module';
import FormFieldErrorModule from './components/form-field-error/form-field-error.module';
import FormFilesModule from './components/form-files/form-files.module';
import FormNodeModule from './components/form-node/form-node.module';
import FormQuestionModule from './components/form-question/form-question.module';
import FormQuestionTasksModule from './components/form-question-tasks/form-question-tasks.module';
import FormQuestionAutotaskModule from './components/form-question-autotask/form-question-autotask.module';
import FormQuestionActionsModule from './components/form-question-actions/form-question-actions.module';
import FormQuestionFieldsModule from './components/form-question-fields/form-question-fields.module';
import FormScoreDetailsModule from './components/form-score-details/form-score-details.module';
import FormSectionModule from './components/form-section/form-section.module';
import FormSheetModule from './services/form-sheet.module';
import FormModule from './services/form.module';
import { FormComponent } from './form.component';

export default angular
  .module('sfMobile.components.form', [
    'sfMobile.services.localization',
    'ngMessages',
    'LocalStorageModule',
    'pascalprecht.translate',
    'sfMobile.questionsForm',
    FormSheetModule,
    FormModule,
    'sfMobile.services.reportErrors',
    'sfMobile.services.modal',
    'sfMobile.services.lifecycle',
    'sfMobile.services.popup',
    'sfMobile.services.segment',
    'sfMobile.services.keyboard',
    'sfMobile.services.tasks',
    'sfMobile.services.platform',
    'sfMobile.components.report-navigation-header',
    'sfMobile.components.report-navigation-modal',
    'sfMobile.components.status-selector',
    'sfMobile.reactive-campaigns',
    'sfMobile.services.pubsub',
    'simplifield.feature-flags',
    'sfMobile.constants.feature-flags',
    'sfMobile.services.reports',
    FormEndingModule,
    FormFieldErrorModule,
    FormFilesModule,
    FormNodeModule,
    FormQuestionModule,
    FormQuestionActionsModule,
    FormQuestionFieldsModule,
    FormQuestionTasksModule,
    FormQuestionAutotaskModule,
    FormScoreDetailsModule,
    FormSectionModule,
  ])
  .component('sfForm', FormComponent).name;
