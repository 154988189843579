import { Browser, OpenOptions } from '@capacitor/browser';

import type { PlatformService } from '../Platform';

function isOpenableUrl(string: string): boolean {
  let url: URL;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export class InAppBrowserService {
  Browser = Browser;
  // @ngInject
  constructor(
    private platformService: PlatformService,
    private $window: ng.IWindowService
  ) {}

  open(url: string, options?: Omit<OpenOptions, 'url'>): Promise<void> {
    if (!isOpenableUrl(url)) {
      throw new Error(`You need to pass a valid url ('${url}' is not)`);
    }

    const browserOptions: OpenOptions = { ...options, url };

    return this.Browser.open(browserOptions);
  }
}
