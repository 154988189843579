import { LayoutComponent } from './layout.component';

export default angular
  .module('sfMobile.components.layout', [
    'sfMobile.services.chat',
    'sfMobile.services.user-experience',
    'sfMobile.services.calendar-events',
    'sfMobile.components.app-message',
    'sfMobile.componnents.app-notification',
    'sfMobile.services.tasks',
    'sfMobile.services.pubsub',
  ])
  .component('sfLayout', LayoutComponent).name;
