const NA_ANSWER = 'isNA';

export const RatingComponent = {
  require: {
    modelCtrl: 'ngModel',
  },
  bindings: {
    options: '<?',
    legends: '<?',
    hasError: '<',
    resetValueFn: '=?',
    hideReset: '<',
    hasAnswer: '<?',
    isDisabled: '<?',
    notApplicable: '<?',
  },
  templateUrl: 'components/Inputs/rating/rating.html',
  controller: class RatingController {
    constructor($element, $translate) {
      'ngInject';
      Object.assign(this, {
        $element,
        $translate,
      });
    }

    focusInput() {
      const range = this.$element[0].querySelector('input[type=range]');

      if (range) {
        range.focus();
      }
    }

    $onInit() {
      this.options = this.options || {};
      this.hasAnswer = this.hasAnswer || null;
      this.legends = this.legends || {};
      this.step = this.options.step || 1;
      this.min = this.options.min || 0;
      this.max = this.options.max;
      this.isNA = this.notApplicable || false;

      this.modelCtrl.$validators.required = this.requiredValiditor.bind(this);
      this.modelCtrl.$render = () => {
        const viewValue = this.modelCtrl.$viewValue;

        this.inputRangeValue = this.isDefined(viewValue) ? viewValue : this.min;
        this.valueDisplay = this.getValueDisplay(viewValue);

        if (this.isNA && this.hasAnswer) {
          this.valueDisplay = this.$translate.instant('ABBR_NOT_APPLICABLE');
        }
      };
      this.resetValueFn = this.resetValue.bind(this);
    }

    toggleNA(value) {
      if (!value) {
        this.valueDisplay = null;
        this.modelCtrl.$setViewValue(null);
      } else {
        this.valueDisplay = this.$translate.instant('ABBR_NOT_APPLICABLE');
        this.modelCtrl.$setViewValue(NA_ANSWER);
      }
      this.inputRangeValue = this.min;
    }

    onRangeChange(value) {
      this.focusInput();
      this.isNA = false;
      this.hasAnswer = false;
      this.inputRangeValue =
        value === null || value === NA_ANSWER ? this.min : value;
      this.valueDisplay = this.getValueDisplay(value);
      this.modelCtrl.$setViewValue(value);
    }

    getValueDisplay(value) {
      return {}.undef === value || value === null ? '-' : value;
    }

    resetValue() {
      this.onRangeChange(null);
    }

    getRangeWidth() {
      const progress = this.getProgress();

      return `${progress}%`;
    }

    getProgress() {
      const max = this.max;
      const min = this.min;
      const value = this.modelCtrl.$viewValue;

      if (!max || !value || value === NA_ANSWER) return 0;

      return ((value - min) * 100) / (max - min);
    }

    requiredValiditor(modelValue, viewValue) {
      const value = modelValue || viewValue;

      if (this.hasAnswer) {
        return true;
      }

      return this.options.required ? value !== null : true;
    }

    isDefined(value) {
      return {}.undef !== value && value !== null;
    }
  },
};
