import { clone } from 'ramda';
import { CalendarEventsService } from '../../../services/API/calendar-events/calendar-events.service';
import { CalendarEvent } from '../../types';
import { PopupService } from '../../../services/Utils/Popup/popup.service';
import { ObjectId, User } from '../../..';
import { Modal, ModalService } from '../../../services/Utils/Modal';
import { APIStore } from '@places/index';

type DeleteEventCallback = (data: { eventId: ObjectId }) => void;
type UpdateEventCallback = (data: { event: CalendarEvent }) => void;

export class CalendarEventsModalService {
  /* @ngInject */
  constructor(
    private $translate: ng.translate.ITranslateService,
    private calendarEventsRecurrenceService,
    private calendarEventsService: CalendarEventsService,
    private eventChangeRequestsService,
    private modalService: ModalService,
    private popupService: PopupService,
    private popupRequestService
  ) {}

  deleteCalendarEvent(
    event: CalendarEvent,
    onDeleteCallback: DeleteEventCallback
  ): ng.IPromise<void> {
    const confirm = this.popupService.showConfirm({
      title: this.$translate.instant('EVENT_REMOVE_TITLE'),
      iconName: 'error',
      buttonText: this.$translate.instant('EVENT_REMOVE_BTN_CONFIRM'),
    });

    return confirm.then(
      () => {
        return this.calendarEventsRecurrenceService.isRecurrent(event)
          ? this.deleteRecurrentCalendarEvent(event, onDeleteCallback)
          : this.deleteNormalCalendarEvent(event, onDeleteCallback);
      },
      () => {}
    );
  }

  private deleteRecurrentCalendarEvent = (
    event: CalendarEvent,
    onDeleteCallback: DeleteEventCallback
  ): ng.IPromise<void> => {
    return this.calendarEventsRecurrenceService
      .displayDeleteRecurrentEventChoices()
      .then(
        (choice) => {
          const progressPopup = this.showProgressPopup();

          return this.calendarEventsRecurrenceService
            .handleDeleteEventChoice(choice, event)
            .then(
              () =>
                progressPopup
                  .onSuccess()
                  .then(() => onDeleteCallback({ eventId: event._id })),
              () => progressPopup.onError(this.deleteCalendarEvent)
            );
        },
        () => {}
      );
  };

  private deleteNormalCalendarEvent = (
    event: CalendarEvent,
    onDeleteCallback: DeleteEventCallback
  ): ng.IPromise<void> => {
    const progressPopup = this.showProgressPopup();

    return this.calendarEventsService
      .delete(event._id, {}, { pov: 'organisation' })
      .then(
        () =>
          progressPopup
            .onSuccess()
            .then(() => onDeleteCallback({ eventId: event._id })),
        () => progressPopup.onError(this.deleteCalendarEvent)
      );
  };

  private showProgressPopup = () =>
    this.popupRequestService.show({
      progress: {
        title: this.$translate.instant('EVENT_REMOVE_IN_PROGRESS'),
      },
      error: {
        title: this.$translate.instant('EVENT_REMOVE_ERROR_TITLE'),
        btnText: this.$translate.instant('EVENT_REMOVE_ERROR_POPUP_CLOSE'),
      },
    });

  editCalendarEvent(
    event: CalendarEvent,
    onUpdateCallback: UpdateEventCallback
  ): ng.IPromise<Modal> {
    const template = `
      <sf-edit-calendar-event-modal
        event="$ctrl.event"
        on-update="$ctrl.onUpdate(event)"
        on-save="$ctrl.onSave()"
        on-close="$ctrl.onClose()">
      </sf-edit-calendar-event-modal>
    `;

    return this.modalService.openAsPromise(
      template,
      {
        onUpdate: (updatedEvent) => onUpdateCallback({ event: updatedEvent }),
        event: this.formatCalendarEvent(event),
      },
      { hardwareBackButtonClose: false }
    );
  }

  private formatCalendarEvent(
    event: CalendarEvent & { contents: Record<string, unknown> }
  ): CalendarEvent {
    const newEvent = clone(event);
    delete newEvent.contents.start_date;
    delete newEvent.contents.end_date;
    return newEvent;
  }

  requestChanges(event: CalendarEvent): ng.IPromise<Modal> {
    const template = `
      <sf-request-event-changes-modal
        event="$ctrl.event"
        on-close="$ctrl.onClose()"
      ></sf-request-event-changes-modal>
    `;

    return this.modalService.openAsPromise(
      template,
      { event: this.formatCalendarEvent(clone(event)) },
      { hardwareBackButtonClose: false }
    );
  }

  requestDeletion(
    event: Pick<CalendarEvent, '_id' | 'contents'>
  ): ng.IPromise<void> {
    const { _id, contents } = this.formatCalendarEvent(
      clone(event as CalendarEvent)
    );
    const popupRequest = this.popupRequestService.show({
      progress: {
        title: this.$translate.instant('REQUEST_EVENT_CHANGE_PENDING'),
      },
      error: {
        title: this.$translate.instant('REQUEST_EVENT_CHANGE_ERROR_TITLE'),
        desc: this.$translate.instant('REQUEST_EVENT_CHANGE_ERROR_DESC'),
      },
    });

    return this.eventChangeRequestsService
      .saveNew({
        contents: {
          action: 'remove',
          state: 'pending',
          originalEvent: { _id, contents },
          requestedEvent: {},
          organisation_id: contents.organisation_id,
        },
      })
      .then(() => popupRequest.onSuccess())
      .catch(() =>
        popupRequest.onError(() => this.requestDeletion({ _id, contents }))
      );
  }

  openEventManagementModal(
    place: APIStore,
    eventDate: Date,
    onCalendarEventCreated: (event: CalendarEvent) => void
  ): ng.IPromise<{ event: CalendarEvent }> {
    const template = `
      <sf-create-calendar-event-modal
        event-date="$ctrl.eventDate"
        place="$ctrl.place"
        on-create="$ctrl.onCalendarEventCreated(event)"
        on-close="$ctrl.onClose()">
      </sf-create-calendar-event-modal>
    `;

    const bindings = {
      eventDate,
      place,
      onCalendarEventCreated,
    };

    return this.modalService.openAsPromise(template, bindings);
  }
}
