import { TSFixMe } from 'app';

interface Operator {
  [key: string]: {
    precedence: number;
    associativity: string;
  };
}

interface RPNAnswer {
  error: {
    divideByZero: boolean;
  };
  errorMessage?: string;
  value: number;
}

export class RPNService {
  constructor(private $translate: ng.translate.ITranslateService) {
    'ngInject';
  }
  public evaluateRPN(expression: string): RPNAnswer {
    const stack: TSFixMe = [];

    let answer: RPNAnswer = {
      error: {
        divideByZero: false,
      },
      value: 0,
    };

    expression = expression.replace(/[A-Z]/g, '0');
    const tokens: string[] = expression.split(' ');

    tokens.forEach((token) => {
      if (!isNaN(+token)) {
        stack.push(parseFloat(token));
      } else {
        const op2 = stack.pop();
        const op1 = stack.pop();
        switch (token) {
          case '+':
            stack.push(op1 + op2);
            break;
          case '-':
            stack.push(op1 - op2);
            break;
          case '*':
            stack.push(op1 * op2);
            break;
          case '/':
            if (op2 === 0) {
              answer = {
                ...answer,
                error: {
                  divideByZero: true,
                },
              };
              return;
            }
            stack.push(op1 / op2);
            break;
          case '^':
            stack.push(Math.pow(op1, op2));
            break;
        }
      }
    });

    if (answer.error.divideByZero) {
      const errorMessage = this.$translate.instant(
        'FORM_ERROR_DIVIDING_BY_ZERO'
      );
      return {
        ...answer,
        errorMessage,
      };
    }

    const value = parseFloat(stack.pop().toFixed(4));

    return {
      ...answer,
      value,
    };
  }

  public convertToRPN(formula) {
    const operators: Operator = {
      '+': { precedence: 2, associativity: 'left' },
      '-': { precedence: 2, associativity: 'left' },
      '*': { precedence: 3, associativity: 'left' },
      '/': { precedence: 3, associativity: 'left' },
    };

    const operatorStack: string[] = [];
    const outputStack: Array<string | undefined> = [];
    const tokens: string[] = formula.split(' ');

    tokens.forEach((token) => {
      if (!isNaN(+token) || token.match(/[a-zA-Z]/)) {
        outputStack.push(token);
      } else if (operators[token]) {
        const op1 = token;
        let op2 = operatorStack[operatorStack.length - 1];
        while (
          operators[op2] &&
          operators[op1].associativity === 'left' &&
          operators[op1].precedence <= operators[op2].precedence
        ) {
          outputStack.push(operatorStack.pop());
          op2 = operatorStack[operatorStack.length - 1];
        }
        operatorStack.push(op1);
      } else if (token === '(') {
        operatorStack.push(token);
      } else if (token === ')') {
        while (operatorStack[operatorStack.length - 1] !== '(') {
          outputStack.push(operatorStack.pop());
        }
        operatorStack.pop();
      }
    });

    while (operatorStack.length > 0) {
      outputStack.push(operatorStack.pop());
    }

    return outputStack.join(' ');
  }
}
