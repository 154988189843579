export type AddressFormat = typeof FormatAdress;
export type AddressContents = {
  street: string;
  city: string;
  country: string;
  zipcode: string;
  formatted_address?: string;
};

export const FormatAdress = (address: AddressContents): string => {
  address = address || {};
  const zipNcity = `${address.zipcode + ' ' ?? ''}${address.city ?? ''}`;

  return (
    address.formatted_address ||
    [address.street, zipNcity, address.country].join(', ')
  );
};
