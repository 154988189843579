import { AppLauncher } from '@capacitor/app-launcher';

export class AppLauncherService {
  AppLauncher = AppLauncher;

  canOpenUrl(url: string): ng.IPromise<boolean> {
    return this.AppLauncher.canOpenUrl({ url }).then(({ value }) => value);
  }

  openUrl(url: string): ng.IPromise<boolean> {
    return this.AppLauncher.openUrl({ url }).then(({ completed }) => completed);
  }
}
