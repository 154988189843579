export function OngoingSheetService($translate, actionSheetService, $q) {
  'ngInject';

  return {
    openDeleteConfirm,
  };

  function openDeleteConfirm() {
    const deletePromise = $q.defer();
    const actionSheetConfig = {
      title: $translate.instant('ONGOING_CONFIRM_DELETE_TITLE'),
      cancelText: $translate.instant('ONGOING_CONFIRM_DELETE_CANCEL'),
      destructiveText: $translate.instant('ONGOING_CONFIRM_DELETE_CONFIRM'),
      isValidation: true,
    };
    const onCancelClick = () => deletePromise.reject();
    const onDestructiveClick = () => deletePromise.resolve('delete');

    actionSheetService.open(
      null,
      actionSheetConfig,
      onCancelClick,
      onDestructiveClick
    );

    return deletePromise.promise;
  }
}
