const DEPARTMENT_TYPES = {
  SALES: {
    keyword: 'sales',
    i18nKey: 'DEPARTMENT_LABEL_SALES',
  },
  MARKETING: {
    keyword: 'marketing',
    i18nKey: 'DEPARTMENT_LABEL_MARKETING',
  },
  HR: {
    keyword: 'hr',
    i18nKey: 'DEPARTMENT_LABEL_HR',
  },
  RETAIL: {
    keyword: 'retail',
    i18nKey: 'DEPARTMENT_LABEL_RETAIL',
  },
  AUDIT: {
    keyword: 'audit',
    i18nKey: 'DEPARTMENT_LABEL_AUDIT',
  },
  MERCHANDISING: {
    keyword: 'merchandising',
    i18nKey: 'DEPARTMENT_LABEL_MERCHANDISING',
  },
  TRAVEL_RETAIL: {
    keyword: 'travelRetail',
    i18nKey: 'DEPARTMENT_LABEL_TRAVEL_RETAIL',
  },
  OPERATION: {
    keyword: 'operations',
    i18nKey: 'DEPARTMENT_LABEL_OPERATION',
  },
  TRAINING: {
    keyword: 'training',
    i18nKey: 'DEPARTMENT_LABEL_TRAINING',
  },
  IT: {
    keyword: 'it',
    i18nKey: 'DEPARTMENT_LABEL_IT',
  },
  MAINTENANCE: {
    keyword: 'maintenance',
    i18nKey: 'DEPARTMENT_LABEL_MAINTENANCE',
  },
};

export default angular
  .module('sfMobile.constants.department-types', [])
  .constant('SF_DEPARTMENT_TYPES', DEPARTMENT_TYPES).name;
