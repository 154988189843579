export function UserExperienceService(
  sfFeatureFlagsService,
  SF_FEATURE_FLAGS,
  SF_USER_EXPERIENCE
) {
  'ngInject';
  const factory = {
    _experience: null,
    init,
    reset,
    isField,
    isStore,
    isAnimator,
    getNav,
    getMenuMore,
  };

  return factory;

  function init(experience = SF_USER_EXPERIENCE.FIELD_KEYWORD) {
    factory._experience = experience;
  }

  function reset() {
    factory._experience = null;
  }

  function isField() {
    return factory._experience === SF_USER_EXPERIENCE.FIELD_KEYWORD;
  }

  function isStore() {
    return factory._experience === SF_USER_EXPERIENCE.STORE_KEYWORD;
  }

  function isAnimator() {
    return factory._experience === SF_USER_EXPERIENCE.ANIMATOR_KEYWORD;
  }

  function getNav(organisation) {
    const experienceNav =
      SF_USER_EXPERIENCE.NAV[factory._experience] ||
      SF_USER_EXPERIENCE.NAV.DEFAULT;
    const menuItems = buildMenuItem(organisation);

    return experienceNav
      .map((itemKey) => menuItems.find((menuItem) => menuItem.key == itemKey))
      .filter((item) => item && item.available);
  }

  function getMenuMore(organisation, useChinaConfiguration) {
    const experienceMenuMore =
      SF_USER_EXPERIENCE.MENU_MORE[factory._experience] ||
      SF_USER_EXPERIENCE.MENU_MORE.DEFAULT;
    const menuItems = buildMenuItem(organisation, useChinaConfiguration);

    return experienceMenuMore
      .map((itemKey) => menuItems.find((menuItem) => menuItem.key == itemKey))
      .filter((item) => item && item.available);
  }

  function buildMenuItem(organisation, useChinaConfiguration) {
    return [
      {
        key: 'newsfeed',
        available: sfFeatureFlagsService.hasFeature(SF_FEATURE_FLAGS.NEWSFEED),
      },
      {
        key: 'documentLibrary',
        available: sfFeatureFlagsService.hasFeature(
          SF_FEATURE_FLAGS.DOCUMENT_LIBRARY
        ),
      },
      {
        key: 'chat',
        available: sfFeatureFlagsService.hasFeature(SF_FEATURE_FLAGS.CHAT),
      },
      {
        key: 'calendarEvents',
        available: sfFeatureFlagsService.hasFeature(
          SF_FEATURE_FLAGS.CALENDAR_EVENTS
        ),
      },
      {
        key: 'stores',
        available: true,
      },
      {
        key: 'missions',
        available: !organisation.useNewCampaigns,
      },
      {
        key: 'campaigns',
        available: organisation.useNewCampaigns,
      },
      {
        key: 'legacyCampaigns',
        available: !organisation.useNewCampaigns,
      },
      {
        key: 'campaignsActivity',
        available: organisation.useNewCampaigns,
      },
      {
        key: 'salesDashboard',
        available: sfFeatureFlagsService.hasFeature(
          SF_FEATURE_FLAGS.SALES_DASHBOARD_MOBILE
        ),
      },
      {
        key: 'tasks',
        available: sfFeatureFlagsService.hasFeature(SF_FEATURE_FLAGS.TASKS),
      },
      {
        key: 'changeRequests',
        available: sfFeatureFlagsService.hasFeature(
          SF_FEATURE_FLAGS.EVENTS_VALIDATION
        ),
      },
      {
        key: 'merchandising',
        available: sfFeatureFlagsService.hasFeature(
          SF_FEATURE_FLAGS.MERCHANDISING
        ),
      },
      {
        key: 'reportsValidation',
        available: sfFeatureFlagsService.hasFeature(
          SF_FEATURE_FLAGS.REPORTS_VALIDATION
        ),
      },
      {
        key: 'products',
        available:
          sfFeatureFlagsService.hasFeature(SF_FEATURE_FLAGS.PRODUCT_CATALOG) &&
          sfFeatureFlagsService.hasFeature(SF_FEATURE_FLAGS.SCAN_PRODUCT),
      },
      {
        key: 'userAgreement',
        available: useChinaConfiguration,
      },
      {
        key: 'privacyPolicy',
        available: useChinaConfiguration,
      },
    ];
  }
}
