import {
  Product,
  ProductsService,
} from '@services/API/products/products.service';
import { PubSubService } from '@services/Utils/PubSub/pubsub.service';
import { IPromise } from 'angular';
import { TSFixMe } from 'app';

export const ProductsSelectModalComponent: ng.IComponentOptions = {
  bindings: {
    onClose: '&',
    onSelect: '<',
  },
  templateUrl: 'products/modals/products-select/products-select-modal.html',
  controller: class ProductsSelectController {
    onSelect: (ids: string[]) => void;

    products: Product[] = [];
    searchField = '';
    selectedEan: string;
    selectedId: string;
    offset = 0;
    limit = 20;
    hasMore = false;
    isOffline = false;
    isHugeProductsCount = false;
    isLoading = false;

    onScrollComplete: () => void;

    unsubscriber: () => void;

    constructor(
      private readonly $q: ng.IQService,
      private readonly productsService: ProductsService,
      private readonly pubSubService: PubSubService
    ) {
      'ngInject';

      this.unsubscriber = this.pubSubService.subscribe(
        'IS_HUGE_AND_OFFLINE',
        ({ isHugeCounts, isOffline }: TSFixMe) => {
          this.isOffline = isOffline;
          this.isHugeProductsCount = isHugeCounts;
        }
      );

      this.productsService.pingHugeAndOffline().then(({ isHugeCounts }) => {
        if (!isHugeCounts) {
          this.hasMore = true;
          this.getProducts();
        }
      });
    }

    $onDestroy(): void {
      this.unsubscriber();
    }

    getProducts(): IPromise<void> {
      this.isLoading = true;

      const checkSearchLength =
        this.searchField?.length < 3 && this.isHugeProductsCount;

      if (!this.hasMore || checkSearchLength) {
        this.isLoading = false;

        return this.$q.when();
      }

      const params = {
        ...(this.searchField ? { search: this.searchField } : {}),
      };

      const limitParams = {
        limit: this.limit,
        offset: this.offset,
      };

      const lastSearch = this.searchField;

      const fetchProducts = () => {
        return this.productsService
          .getProducts(params, limitParams)
          .then((products: Product[] = []) => {
            return this.$q.all(
              products.map((product) => {
                return this.productsService
                  .getProductImageUrls(product)
                  .then((imageUrls) => ({ ...product, imageUrls }));
              })
            );
          })
          .then((products) => {
            if (this.searchField !== lastSearch) {
              this.products = [];
              this.offset = 0;
              this.hasMore = false;

              return this.$q.when();
            }

            this.products.push(...products);

            this.offset += this.limit;
            this.hasMore =
              Boolean(products?.length) && products?.length >= this.limit;
          });
      };

      return this.productsService
        .pingHugeAndOffline()
        .then(({ isHugeCounts, isOffline }: TSFixMe) =>
          !(isHugeCounts && isOffline) ? fetchProducts() : this.$q.resolve()
        )
        .finally(() => {
          if (this.onScrollComplete) {
            this.onScrollComplete();
          }

          this.isLoading = false;
        });
    }

    onSearchChange(): IPromise<void> {
      this.selectedEan = '';

      this.offset = 0;
      this.products = [];
      this.hasMore = this.isHugeProductsCount
        ? this.searchField.length >= 3
        : true;

      return this.getProducts();
    }

    onSubmitSelect(): void {
      return this.onSelect([this.selectedId]);
    }
  },
};
