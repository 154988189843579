import { ProductsService } from '@services/API/products/products.service';
import { BarcodeService } from '@services/Utils/BarCode/barcode.service';
import { ModalService } from '@services/Utils/Modal';
import { PlatformService } from '@services/Utils/Platform';
import { PubSubService } from '@services/Utils/PubSub/pubsub.service';
import { TSFixMe } from 'app';

export const ProductsDashboardComponent: ng.IComponentOptions = {
  bindings: {},
  templateUrl: 'products/views/products-dashboard/products-dashboard.html',
  controller: class ProductsDashboardController implements ng.IController {
    barcodeText: string | boolean;
    ids: string[] = [];
    hasScanError: boolean;
    isOffline = false;
    isHugeAndOffline = false;

    unsubscriber: () => void;

    constructor(
      private $q: ng.IQService,
      private barcodeService: BarcodeService,
      private modalService: ModalService,
      private platformService: PlatformService,
      private productsService: ProductsService,
      private pubSubService: PubSubService
    ) {
      'ngInject';

      this.unsubscriber = this.pubSubService.subscribe(
        'IS_HUGE_AND_OFFLINE',
        ({ isHugeCounts, isOffline }: TSFixMe) => {
          this.isHugeAndOffline = isHugeCounts && isOffline;
        }
      );

      this.productsService.pingHugeAndOffline();
    }

    $onDestroy(): void {
      this.productsService.killPingInterval();
      this.unsubscriber();
    }

    scan() {
      this.hasScanError = false;
      this.productsService
        .pingHugeAndOffline()
        .then(({ isHugeCounts, isOffline }) => {
          if (isHugeCounts && isOffline) return;

          if (this.platformService.isBrowser()) {
            return this.barcodeService.promptBarCode().then((barcodeText) => {
              this.barcodeText = barcodeText;
            });
          }

          return this.$q.when(this.barcodeService.scan()).then(
            (barcodeText) => {
              this.barcodeText = barcodeText;
            },
            () => {
              this.hasScanError = true;
            }
          );
        });
    }

    prompt() {
      this.productsService
        .pingHugeAndOffline()
        .then(({ isHugeCounts, isOffline }) => {
          if (isHugeCounts && isOffline) return;

          const template = `
          <sf-products-select-modal
            on-close="$ctrl.onClose()"
            on-select="$ctrl.onSelect"
          ></sf-products-select-modal>`;

          const bindings: TSFixMe = {
            onSelect: (ids) => {
              this.ids = ids;

              bindings.onClose();
            },
          };

          this.modalService.open(template, bindings);
        });
    }
  },
};
