import { StateService } from '@uirouter/core';
import { IOnChangesObject, translate } from 'angular';
import { isNil, path } from 'ramda';
import { User } from '../../..';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import { ModalService } from '../../../services/Utils/Modal';
import { PopupService } from '../../../services/Utils/Popup/popup.service';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';
import { NewsfeedApiService } from '../../services/newsfeed-api/newsfeed-api.service';
import { NewsfeedPost } from '../../types';

export class NewsfeedPostFeedbackController implements ng.IComponentController {
  // Bindings
  post: NewsfeedPost;
  profile: User;
  onAddComment: () => void;
  isCommentsCountDisplayed: boolean;
  disableComments: boolean;
  isWidget: boolean;

  // privates
  isRTLNeeded: boolean;
  pending: boolean;
  isLiked: boolean;
  shouldDisplayComments: boolean;
  commentsCount?: number;
  likesAmount: number;
  // eslint-disable-next-line max-params
  constructor(
    private localizationService: LocalizationService,
    private pubSubService: PubSubService,
    private $translate: translate.ITranslateService,
    private $state: StateService,
    private newsfeedApiService: NewsfeedApiService,
    private popupService: PopupService,
    private modalService: ModalService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.pending = false;
    this.isLiked = false;

    this.setCommentsCount();
    this.setLikesAmount();
    this.setIsLiked();

    this.shouldDisplayComments =
      !this.disableComments &&
      this.isCommentsCountDisplayed &&
      !isNil(this.commentsCount);
  }

  $onChanges({ post }: IOnChangesObject): void {
    if (post) {
      this.setIsLiked();
      this.setLikesAmount();
      this.setCommentsCount();
    }
    this.shouldDisplayComments =
      !this.disableComments &&
      this.isCommentsCountDisplayed &&
      !isNil(this.commentsCount);
  }

  toggleLike(): boolean | ng.IPromise<boolean | void> {
    const isLiked = this.isLiked;

    if (this.pending) {
      return false;
    }

    this.pending = true;

    return (
      isLiked
        ? this.newsfeedApiService.unlikePost(this.post._id)
        : this.newsfeedApiService.likePost(this.post._id)
    )
      .then(({ data }) => {
        this.isLiked = !isLiked;
        if (!data) {
          return false;
        }
        this.emitPostUpdatedEvent(data as NewsfeedPost);
        return true;
      })
      .catch(() => this.onError())
      .finally(() => {
        this.pending = false;
      });
  }

  setIsLiked(): void {
    this.isLiked = !!path<boolean>(['contents', 'likes', 'hasLiked'])(
      this.post
    );
  }

  setLikesAmount(): void {
    this.likesAmount = this.post.contents.likes
      ? this.post.contents.likes.count
      : 0;
  }

  setCommentsCount(): void {
    this.commentsCount = this.post.contents.commentsCount;
  }

  onError(): void {
    this.popupService.showError({
      title: this.$translate.instant('NEWSFEED_POST_LIKE_ERROR_TITLE'),
      desc: this.$translate.instant('NEWSFEED_POST_LIKE_ERROR_DESC'),
    });
  }

  emitPostUpdatedEvent(post: NewsfeedPost): void {
    this.pubSubService.publish('NEWSFEED_POST_UPDATED', {
      post,
    });
  }

  showLikes(): void {
    const { likes: postLikes } = this.post.contents;

    if (!postLikes || postLikes.count === 0) {
      return;
    }
    const likes = {
      count: postLikes.count,
      users: postLikes.users.map(({ _id, ...contents }) => ({
        _id,
        contents,
      })),
    };

    const template = `
        <sf-newsfeed-post-likes-modal
          likes="$ctrl.likes"
          on-close="$ctrl.onClose()">
        </sf-newsfeed-post-likes-modal>`;

    this.modalService.open(
      template,
      { likes },
      { hardwareBackButtonClose: false }
    );
  }

  goToPost(): void {
    this.$state.go('index.newsfeed.details', {
      postId: this.post._id,
      isScrollToComments: 'true',
    });
  }
}
