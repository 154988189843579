import { EditCalendarEventModalController } from './edit-calendar-event-modal.controller';

export const EditCalendarEventModalComponent = {
  bindings: {
    event: '<',
    onUpdate: '&',
    onClose: '&',
    onSave: '&',
  },
  templateUrl:
    'calendar-events/components/calendar/edit-calendar-event-modal/edit-calendar-event-modal.html',
  controller: EditCalendarEventModalController,
};
