import { AppStateService } from './app-state.service';

export default angular
  .module('sfMobile.services.app-state', [
    'sfMobile.notifications',
    'sfMobile.services.config',
    'sfMobile.services.profile',
    'sfMobile.services.segment',
    'sfMobile.services.sentry',
    'sfMobile.services.datadog',
    'sfMobile.services.intercom',
    'sfMobile.services.satismeter',
    'sfMobile.services.api-url',
    'sfMobile.services.lifecycle',
    'sfMobile.services.synchronize',
    'sfMobile.services.chat',
    'sfMobile.services.keyboard',
    'sfMobile.services.firebase',
    'sfMobile.services.local-notifications',
    'sfMobile.services.user-experience',
    'sfMobile.services.theme',
    'sfMobile.services.data-store',
    'sfMobile.services.marketing',
    'sfMobile.services.external-dashboard',
    'sfMobile.services.external-injection',
  ])
  .factory('appStateService', AppStateService).name;
