export const SortModalComponent = {
  bindings: {
    value: '<',
    options: '<',
    onChange: '&',
    onClose: '&',
  },
  templateUrl: 'components/sort/sort-modal/sort-modal.html',
  controller: function SortModalController(localizationService) {
    'ngInject';
    this.$onInit = () => {
      this.isRTLNeeded = localizationService.shouldActivateRTL();

      this.selected = this.value
        ? this.options.filter((option) => option.key === this.value.key)[0]
        : null;

      this.fieldValue = this.value ? this.value.value : null;
    };

    this.onSelect = (option) => {
      this.selected = option;

      if (option.values && !this.fieldValue) {
        this.fieldValue = option.values[0];
      }

      return this.onChange({
        option: {
          key: option.key,
          sortBy: option.sortBy,
          value: option.values ? this.fieldValue : null,
        },
      });
    };
    this.onSelectValueChange = (option) => this.onSelect(option);
  },
};
