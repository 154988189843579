import { PubSubService } from '@services/Utils/PubSub/pubsub.service';
import { SegmentService } from '@services/Utils/Segment/segment.service';
import { StateService } from '@uirouter/angularjs';
import { NewsfeedPost } from 'app/newsfeed/types';
import { IAPIList, TSFixMe } from '../../..';
import { NewsfeedApiService } from '../../../newsfeed/services/newsfeed-api/newsfeed-api.service';

export enum ActivityWidget {
  NEWSFEED = 'newsfeed',
}

type WidgetOptions = {
  items: TSFixMe[];
  moreRoute?: string;
  sectionTitleKey?: string;
};

type Slider = {
  slideNext: () => void;
  slidePrev: () => void;
  update: () => void;
  slideTo: (slideId: number) => void;
};

type SliderOptions = {
  initialSlide: number;
  loop: boolean;
  autoplay: number;
  pager?: boolean;
  pagination?: boolean;
  autoHeight: boolean;
};

export const SliderWidgetComponent: ng.IComponentOptions = {
  bindings: {
    autoplay: '&',
    displayIfEmpty: '&',
    displayPager: '&',
    loop: '&',
    type: '<',
  },
  templateUrl: 'activity/components/slider-widget/slider-widget.html',
  controller: class SliderWidgetController {
    // bindings
    autoplay = 0;
    displayIfEmpty = false;
    displayPager = true;
    loop = false;
    type: ActivityWidget;

    // props
    onDataSyncListener: () => void;
    slider: Slider;
    sliderOptions = {
      initialSlide: 0,
      loop: this.loop ?? false,
      autoplay: this.autoplay ?? 0,
      pager: this.displayPager ?? true,
      autoHeight: true,
    } as SliderOptions;

    widgetOptions: WidgetOptions;

    private initData() {
      switch (this.type) {
        case ActivityWidget.NEWSFEED:
          return this.initNewsfeeds();
      }
    }

    private initNewsfeeds() {
      const NEWS_LIMIT = 5;

      return this.newsfeedApiService
        .getPosts({
          limit: NEWS_LIMIT,
          viewType: 'overview',
          mode: 'expanded',
        })
        .then(({ entries: items }: IAPIList<NewsfeedPost>) => {
          this.initSliderOptions(items);

          this.widgetOptions = {
            items,
            moreRoute: 'index.newsfeed.list',
            sectionTitleKey: 'NEWSFEED_TITLE',
          };
        });
    }

    private initSliderOptions(items) {
      const hasAutoplay = items.length > 1;

      this.sliderOptions = hasAutoplay
        ? this.sliderOptions
        : {
            initialSlide: 0,
            loop: false,
            autoplay: 0,
            pager: false,
            pagination: false,
            autoHeight: true,
          };
    }

    constructor(
      private $scope: ng.IScope,
      private $state: StateService,
      private $timeout: ng.ITimeoutService,
      private newsfeedApiService: NewsfeedApiService,
      private segmentService: SegmentService,
      private pubSubService: PubSubService
    ) {
      'ngInject';
    }

    $onInit() {
      this.initData();

      this.$scope.$on('$ionicSlides.sliderInitialized', (_, data) => {
        this.slider = data.slider;

        this.pubSubService.subscribe('SLIDER_MEDIA_LOADED', () => {
          this.updateHeight();
        });

        this.pubSubService.subscribe('NEWSFEED_POST_UPDATED', () => {
          this.updateHeight();
        });

        this.onDataSyncListener = this.pubSubService.subscribe(
          this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
          () => {
            this.initData().then(() => {
              this.slider.slideTo(0);
            });
          }
        );

        this.pubSubService.subscribe(
          this.pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
          () => this.slider.slideTo(0)
        );
      });

      this.$scope.$on('$ionicSlides.slideChangeEnd', () => {
        // resize slider for dynamic height
        this.updateHeight();
      });
    }

    $onDestroy(): void {
      this.onDataSyncListener();
    }

    goToView(stateLink: string, viewType: string) {
      this.$state.go(stateLink);
      this.segmentService.track('ACTIVITY', {
        action: 'tap',
        label: `More link ${viewType}`,
      });
    }

    isSectionLoading(datas) {
      return typeof datas === 'undefined';
    }

    slideNext() {
      this.slider.slideNext();
    }

    slidePrev() {
      this.slider.slidePrev();
    }

    updateHeight(delay = 100) {
      this.$timeout(() => {
        this.slider.update();
        this.$scope.$apply();
      }, delay);
    }
  },
};
