import { NewsfeedPostCreationModalController } from './newsfeed-post-creation-modal.controller';

export const NewsfeedPostCreationModalComponent = {
  bindings: {
    postToEdit: '<',
    profile: '<',
    isPublished: '<',
    onClose: '&',
    onSave: '&',
  },
  templateUrl:
    'newsfeed/components/newsfeed-post-creation-modal/newsfeed-post-creation-modal.html',
  controller: NewsfeedPostCreationModalController,
};
