import { Comment } from 'app/comments';
import { CommentsFileService } from 'app/comments/services/comments-file/comments-file.service';
import { NewsfeedPopupService } from 'app/newsfeed/services/newsfeed-popup/newsfeed-popup.service';
import { path } from 'ramda';

export const ReportCommentsComponent = {
  bindings: {
    report: '<',
    profile: '<',
  },
  templateUrl: 'reports/report/components/report-comments/report-comments.html',
  controller: class ReportCommentsController {
    report;
    profile;
    scrollName = 'reportCommentsScroll';
    comments: Comment[];
    commentsActions;
    commentsUser = {};
    commentField = '';
    isSending = false;
    editableComment;
    editCommentContent = '';
    isEditCommentFieldDisplayed = false;
    isAddCommentFieldDisplayed = false;
    isLoading = false;
    hasError = false;
    isCommentFieldFocused = false;
    onCommentFieldFocus: () => void;

    constructor(
      private $element,
      private $timeout: ng.ITimeoutService,
      private contentService,
      private reportsService,
      private objectIdService,
      private filesService,
      private taskCommentsPopupService,
      private newsfeedService,
      private newsfeedActionsService,
      private $q,
      private newsfeedPopupService: NewsfeedPopupService,
      private commentsFileService: CommentsFileService,
      private keyboardService
    ) {
      'ngInject';
    }

    $onInit() {
      this.commentField = '';

      return this.loadComments().then(() => {
        this.commentsActions = this.getCommentsActions();
      });
    }

    $onChanges({ comments }) {
      if (comments) {
        this.commentsActions = this.getCommentsActions();
      }
    }

    closeInputKeyboard() {
      this.keyboardService.hide();
    }

    loadComments() {
      this.isLoading = true;
      this.hasError = false;
      this.comments = [];
      this.commentsUser = {};

      return this.reportsService.commentsService
        .list(this.report._id, {
          sorts: 'saved.seal_date',
        })
        .then(({ entries, users }) => this.updateComments(entries, users))
        .catch((err) => {
          this.hasError = true;

          throw err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    // ------------------
    //
    //   UPDATE METHODS
    //
    // ------------------

    updateComment(): ng.IPromise<void> {
      const errorTitle = 'NEWSFEED_EDIT_ERROR_TITLE';

      if (!this.editableComment) {
        return this.newsfeedPopupService.showError(errorTitle);
      }
      const index = this.comments.findIndex(
        (el) => el._id === this.editableComment?._id
      );

      if (index === -1) {
        return this.newsfeedPopupService.showError(errorTitle);
      }

      const updatedComment = {
        ...this.editableComment,
        contents: {
          ...this.editableComment.contents,
          content: this.editCommentContent,
        },
      };

      return this.reportsService.commentsService
        .edit(this.report._id, updatedComment)
        .then((data) => {
          this.comments.splice(index, 1, updatedComment);
        })
        .catch((error) => {
          this.newsfeedPopupService.showError(errorTitle);
        })
        .finally(() => {
          this.editCommentContent = '';
          this.isEditCommentFieldDisplayed = false;
          this.editableComment = undefined;
        });
    }

    cancelEditComment() {
      this.editableComment = undefined;
      this.isEditCommentFieldDisplayed = false;

      this.closeInputKeyboard();
    }

    /**
     * Update view comments
     * @param  {Array} comments - comments
     * @return {Array}          - comments
     */
    updateComments(comments, users) {
      this.comments = this.comments.concat(comments);
      const newCommentUsers = Object.keys(users).reduce((obj, userId) => {
        obj[userId] = {
          _id: userId,
          contents: users[userId],
        };
        return obj;
      }, {});

      this.commentsUser = {
        ...this.commentsUser,
        ...newCommentUsers,
      };

      this.scrollBottom();
      this.commentsActions = this.getCommentsActions();

      return comments;
    }

    reload() {
      this.loadComments();
    }

    /**
     * Add a comment
     * @return {Promise} - Comment
     * @this ReportCommentsConstructor
     */
    postComment(mediaFileId, type) {
      const newComment = this.getNewComment(
        this.commentField,
        mediaFileId,
        type
      );

      this.isSending = true;
      // return;

      return this.reportsService.commentsService
        .create(this.report._id, newComment)
        .then((comment) => {
          if (!mediaFileId) {
            this.commentField = '';
          }
          this.closeInputKeyboard();
          this.updateComments([comment], comment.users);
        })
        .finally(() => {
          this.isSending = false;
        });
    }

    getNewComment(comment, mediaFileId, type) {
      return this.reportsService.commentsService.buildComment(
        this.profile,
        mediaFileId ? '' : comment,
        {
          report_id: this.report._id,
          form_id: this.report.contents.form_id,
        },
        mediaFileId,
        type
      );
    }

    onDeleteComment(commentId: string): ng.IPromise<void> {
      const title = 'NEWSFEED_DELETE_COMMENT_CONFIRM_TITLE';
      const text = 'NEWSFEED_DELETE_COMMENT_CONFIRM_BUTTON';
      const errorTitle = 'NEWSFEED_DELETE_ERROR_TITLE';

      if (!this.comments.find((comment) => comment._id === commentId)) {
        return this.newsfeedPopupService.showError(errorTitle);
      }

      const oldComments = [...this.comments];

      return this.newsfeedPopupService
        .showDeleteConfirm(title, text)
        .then(
          () => {
            this.comments = this.comments.filter(
              (comment) => comment._id !== commentId
            );

            return this.reportsService.commentsService.delete(
              this.report._id,
              commentId
            );
          },
          () => {} // catch Cancel event
        )
        .catch(() => {
          this.newsfeedPopupService.showError(errorTitle);
          this.comments = oldComments;
        });
    }

    onEditComment(commentId: string): void {
      const errorTitle = 'NEWSFEED_EDIT_ERROR_TITLE';

      this.editableComment = this.comments.find(
        (comment) => comment._id === commentId
      );
      if (!this.editableComment) {
        this.newsfeedPopupService.showError(errorTitle);
        return;
      }

      this.editCommentContent = this.editableComment.contents.content;
      this.commentField = '';
      this.isEditCommentFieldDisplayed = true;
      this.focusOnCommentInput();
    }

    focusOnCommentInput(): void {
      const selectors = this.isEditCommentFieldDisplayed
        ? '.sf_report_comments__footer-edit #sf_footer_text_input__input_id'
        : '.sf_report_comments__footer-add #sf_footer_text_input__input_id';

      const input = this.$element[0].querySelector(selectors) as HTMLElement;

      if (input) {
        this.$timeout(() => input.focus(), 0);
      }
    }

    postMediaComment(fileBlob): ng.IPromise<void> {
      const type = this.newsfeedService.getResourceTypeFromMimeType(
        fileBlob.type
      );

      const validFiles = this.commentsFileService.getValidFromSelectedFiles(
        [fileBlob],
        type
      );

      if (!validFiles.length) {
        return this.$q.resolve();
      }

      this.isAddCommentFieldDisplayed = false;
      const mediaFileId = this.objectIdService.create();
      const cancelPromise = this.$q.defer();
      const onSendCancel = () => {
        cancelPromise.resolve();
        popupSending.clear();
      };

      const popupSending =
        this.taskCommentsPopupService.showPictureCommentSendingProgress(
          onSendCancel
        );

      return this.filesService
        .upload(fileBlob, mediaFileId, {
          canceler: cancelPromise,
          fileNameOverride: type === 'document' ? fileBlob.name : null,
        })
        .then(() => this.postComment(mediaFileId, type))
        .then(() => popupSending.onSuccess())
        .catch(() => popupSending.onError());
    }

    getUser(userId) {
      return this.commentsUser[userId];
    }

    /**
     * Scroll content towards bottom
     */

    scrollBottom() {
      this.$timeout(() => {
        this.contentService.scrollBottomById(this.scrollName);
      }, 0);
    }

    getCommentsActions() {
      return this.comments.reduce((acc, comment) => {
        const { _id } = comment;

        acc[_id] = this.newsfeedActionsService.getAvailableActions({
          author_id: path<string>(['contents', 'author_id'])(comment),
          profile: this.profile,
          callbacks: {
            onDelete: () => this.onDeleteComment(_id),
            onEdit: () => this.onEditComment(_id),
          },
        });

        return acc;
      }, {});
    }
  },
};
