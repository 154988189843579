import { fabric } from 'fabric';
import { DRAWING_COLORS } from '../../../constants/colors.constants';
import { CanvasEditionService } from '../../../services/Utils/CanvasEdition/canvas-edition.service';
import { PopupService } from '../../../services/Utils/Popup/popup.service';
import { ScreenOrientationService } from '../../../services/Utils/ScreenOrientation/screen-orientation.service';

const MIN_VERTICAL_OFFSET = 170;
const TEXT_CONFIG = {
  fontFamily: 'SourceSansPro',
  fontSize: 20,
};
const TEXT_OBJECT_AVAILABLE_OPTIONS = ['color'];
const PEN_AVAILABLE_OPTIONS = ['size', 'color'];
const TEXT_CONTROLS_VISIBILITY = {
  mb: false,
  ml: false,
  mr: false,
  mt: false,
};
const EDITABLE_OBJECTS = [
  {
    type: 'i-text', // FabricJS type identifier
    options: TEXT_OBJECT_AVAILABLE_OPTIONS, // Array of option identifiers available when the selected object fits this type
  },
];
const CANVAS_EVENT_KEYS = [
  'object:added',
  'selection:created',
  'selection:updated',
  'selection:cleared',
];

type Tool = {
  name: string; // Tool identifier
  icon: string; // Icon to display in button
  initToolConfiguration: () => void; // Initialization requirements for this tool
  select: () => void; // Callback to call on button click
  unselect: () => void; // Callback to call on tool unselection
  onColorSelect: (color: string) => void; // Callback to call on color option click
  onSizeSelect: (size: { value: number }) => void; // Callback to call on size option click
  options: string[];
};

export const PictureDrawingComponent = {
  bindings: {
    pictureUrl: '<',
    componentWidth: '<',
    componentHeight: '<',
    getOriginalSizeDrawingFn: '=',
  },
  templateUrl: 'components/Drawing/picture-drawing/picture-drawing.html',
  // eslint-disable-next-line max-params
  controller: class PictureDrawingController {
    // bindings
    pictureUrl: string;
    componentWidth: number;
    componentHeight: number;
    getOriginalSizeDrawingFn: () => void;

    // class members
    tools: Tool[];
    DEFAULT_COLOR: typeof DRAWING_COLORS.RED;
    scaleRatio: number;
    selectedTool: Tool | null;

    constructor(
      private $timeout: ng.ITimeoutService,
      private $scope: ng.IScope,
      private $translate: ng.translate.ITranslateService,
      private canvasEditionService: CanvasEditionService,
      private popupService: PopupService,
      private screenOrientationService: ScreenOrientationService,
      private SF_DRAWING_COLORS: typeof DRAWING_COLORS
    ) {
      'ngInject';
      this.getOriginalSizeDrawing = this.getOriginalSizeDrawing.bind(this);
    }

    $onInit(): void {
      this.screenOrientationService.portraitLock();

      this.tools = [
        {
          name: 'pen', // Tool identifier
          icon: 'fa-light fa-pen', // Icon to display in button
          initToolConfiguration: () => this.initPenConfiguration(), // Initialization requirements for this tool
          select: () => this.selectPenTool(), // Callback to call on button click
          unselect: () => this.unselectPenTool(), // Callback to call on tool unselection
          onColorSelect: (color) => this.selectPenColor(color), // Callback to call on color option click
          onSizeSelect: (size) => this.selectPenSize(size), // Callback to call on size option click
          options: PEN_AVAILABLE_OPTIONS, // Array of options identifiers available when this tool is selected
        },
        {
          name: 'text',
          icon: 'fa-light fa-bold',
          initToolConfiguration: () => this.initTextConfiguration(),
          select: () => this.selectTextTool(),
          unselect: () => {},
          onColorSelect: (color) => this.selectTextColor(color),
          onSizeSelect: () => {},
          options: [],
        },
      ];
      this.DEFAULT_COLOR = this.SF_DRAWING_COLORS.RED;

      this.getOriginalSizeDrawingFn = this.getOriginalSizeDrawing;

      return this.initializeCanvas();
    }

    $onDestroy(): void {
      this.screenOrientationService.unlock();
      this.canvasEditionService.dispose();
      CANVAS_EVENT_KEYS.forEach((key) => {
        this.canvasEditionService.removeEventListener(key);
      });
    }

    /** Canvas configuration functions **/

    initializeCanvas(): void {
      this.$timeout(() => {
        // Need to wait for canvas to be loaded
        this.canvasEditionService.init('merch_picture_drawing_canvas');
        this.overrideCanvasObjectsConfiguration();
        // We need to inform angular that changes have been made on canvas
        CANVAS_EVENT_KEYS.forEach((key) => {
          this.canvasEditionService.addEventListener(key, () =>
            this.$timeout(() => this.$scope.$digest(), 0)
          );
        });

        return this.canvasEditionService.setBackgroundImage(
          this.pictureUrl,
          (img) => {
            this.resizeDrawingArea(
              this.componentWidth,
              this.componentHeight,
              img.width,
              img.height
            );
          }
        );
      });
    }

    overrideCanvasObjectsConfiguration(): void {
      this.canvasEditionService.enableCanvasMultipleSelection(false);
      this.tools.forEach((tool) => tool.initToolConfiguration());
    }

    resizeDrawingArea(
      componentWidth: number,
      componentHeight: number,
      imageWidth: number,
      imageHeight: number
    ): fabric.Canvas {
      const areaMaxHeight = componentHeight - MIN_VERTICAL_OFFSET;
      const horizontalRatio = componentWidth / imageWidth;
      const verticalRatio = areaMaxHeight / imageHeight;
      const verticallyScaledWidth = imageWidth * verticalRatio;
      const horizontallyScaledHeight = imageHeight * horizontalRatio;

      if (horizontallyScaledHeight > areaMaxHeight) {
        this.canvasEditionService.setCanvasDimensions(
          verticallyScaledWidth.toFixed(2),
          areaMaxHeight
        );
        this.canvasEditionService.setBackgroundImageHeight(areaMaxHeight);
        this.scaleRatio = verticalRatio;

        return this.canvasEditionService.renderCanvas();
      }

      this.canvasEditionService.setCanvasDimensions(
        componentWidth,
        horizontallyScaledHeight.toFixed(2)
      );
      this.canvasEditionService.setBackgroundImageWidth(componentWidth);
      this.scaleRatio = horizontalRatio;

      return this.canvasEditionService.renderCanvas();
    }

    /** Tools click functions **/

    undoLastAction(): void {
      this.canvasEditionService.removeLastAddedObject();
      this.canvasEditionService.unselectCanvasObject();
      this.canvasEditionService.renderCanvas();
    }

    onToolClicked(tool: Tool): void {
      this.canvasEditionService.unselectCanvasObject();

      if (this.isSelectedTool(tool)) {
        return this.unselectCurrentTool();
      }
      if (this.selectedTool) {
        this.unselectCurrentTool();
      }
      this.selectedTool = tool;
      this.selectColor(this.DEFAULT_COLOR);
      return tool.select();
    }

    unselectCurrentTool(): void {
      this.selectedTool?.unselect();
      this.selectedTool = null;
    }

    /** Pen tool functions **/

    initPenConfiguration(): void {
      this.canvasEditionService.enablePathSelection(false);
    }

    selectPenTool(): fabric.Canvas {
      this.canvasEditionService.enableCanvasDrawingMode(true);
      return this.canvasEditionService.renderCanvas();
    }

    unselectPenTool(): fabric.Canvas {
      this.canvasEditionService.enableCanvasDrawingMode(false);
      return this.canvasEditionService.renderCanvas();
    }

    selectPenColor(color: string): fabric.Canvas {
      this.canvasEditionService.setPenColor(color);
      return this.canvasEditionService.renderCanvas();
    }

    selectPenSize(size: { value: number }): fabric.Canvas {
      this.canvasEditionService.setPenSize(size.value);
      return this.canvasEditionService.renderCanvas();
    }

    /** Text tool functions **/

    initTextConfiguration(): void {
      this.canvasEditionService.setTextControlsVisibility(
        TEXT_CONTROLS_VISIBILITY
      );
      this.canvasEditionService.enableTextEdition(false);
    }

    selectTextTool(): ng.IPromise<fabric.Object | null> {
      return this.displayTextPromptPopup()
        .promptPromise.then((text) =>
          this.canvasEditionService.addText(text, TEXT_CONFIG)
        )
        .catch(() => null)
        .finally(() => this.unselectCurrentTool());
    }

    selectTextColor(color: string): fabric.Canvas {
      this.canvasEditionService.setTextColor(color);
      return this.canvasEditionService.renderCanvas();
    }

    /** Options click functions **/

    selectColor(color: string): fabric.Canvas | void {
      const selectedObject =
        this.canvasEditionService.getCanvasSelectedObject();

      if (selectedObject) {
        this.canvasEditionService.setSelectedObjectColor(color);
        return this.canvasEditionService.renderCanvas();
      }
      if (this.selectedTool) {
        return this.selectedTool.onColorSelect(color);
      }
      return;
    }

    selectSize(size: { value: number }): void {
      if (this.selectedTool) {
        return this.selectedTool.onSizeSelect(size);
      }
      return;
    }

    /** Options display conditions functions **/

    isAvailableSelectedObjectOption(option: string): boolean {
      const selectedObjectType =
        this.canvasEditionService.getSelectedObjectType();
      const editableObjectConfig =
        selectedObjectType && this.getEditableObjectConfig(selectedObjectType);

      return Boolean(
        editableObjectConfig &&
          (editableObjectConfig.options as string[]).includes(option)
      );
    }

    isAvailableToolOption(option: string): boolean {
      return Boolean(
        this.selectedTool && this.selectedTool.options.includes(option)
      );
    }

    /** Utils **/

    canUndoLastAction(): boolean {
      return this.canvasEditionService.hasCanvasObject();
    }

    isSelectedTool(tool: Tool): boolean {
      return this.selectedTool?.name === tool.name;
    }

    getSelectedObjectColor():
      | string
      | fabric.Gradient
      | fabric.Pattern
      | undefined {
      return this.canvasEditionService.getSelectedObjectColor();
    }

    getEditableObjectConfig(
      objectType: string
    ): typeof EDITABLE_OBJECTS[number] | { options: string[] } {
      const configs = EDITABLE_OBJECTS.filter(
        (editableObject) => editableObject.type === objectType
      );

      return configs.length ? configs[0] : { options: [] };
    }

    displayTextPromptPopup(): {
      promptPromise: ng.IPromise<string>;
      close: () => void;
    } {
      return this.popupService.prompt({
        title: this.$translate.instant('PICTURE_DRAWING_ADD_TEXT_TITLE'),
        placeholder: this.$translate.instant(
          'PICTURE_DRAWING_ADD_TEXT_PLACEHOLDER'
        ),
        submit: this.$translate.instant('PICTURE_DRAWING_ADD_TEXT_SUBMIT'),
        required: true,
        long: true,
      });
    }

    /* Get copy of canvas resized to image original size */
    getOriginalSizeDrawing(): string {
      return this.canvasEditionService.getCanvasAsDataUrl(this.scaleRatio);
    }
  },
};
