import { EventChecklistSelectorController } from './event-checklist-selector.controller';

export const EventChecklistSelectorComponent = {
  bindings: {
    emptyIcon: '@',
    placeholder: '@',
    searchPlaceholder: '@',
    title: '@',
    multiple: '<',
    value: '<',
    disabled: '<',
    selected: '<?',
    entities: '<?',
    searchFields: '<?',
    staticSearchFields: '<?',
    entityProvider: '<?',
    entityTitleGet: '<',
    entityDescriptionGet: '<?',
    customDataProvider: '<?',
    onChange: '&',
    onReset: '&',
    onReload: '&',
    onDelete: '&',
    filtersAvailable: '<',
    maxSelectedEntities: '<?',
  },
  templateUrl:
    'components/event-checklist-selector/event-checklist-selector.html',
  controller: EventChecklistSelectorController,
};
