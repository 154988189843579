import ChatModule from './chat/chat.module';
import chatRouteConfig from './chat.routes';

import { ChatMessageActionsService } from './services/chat-message-actions.service';
import { ChatPopupService } from './services/chat-popup.service';

import { ChatAvatarComponent } from './components/chat-avatar/chat-avatar.component';
import { ChatItemComponent } from './components/chat-item/chat-item.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { DeletedChatMessageComponent } from './components/deleted-chat-message/deleted-chat-message.component';

export default angular
  .module('sfMobile.chat', [
    'pascalprecht.translate',
    ChatModule,
    'sfMobile.services.popup',
    'sfMobile.services.popup-request',
    'sfMobile.services.action-sheet',
    'sfMobile.services.chat',
    'sfMobile.services.dates',
    'sfMobile.services.modal',
    'sfMobile.services.pubsub',
    'sfMobile.services.clipboard',
    'sfMobile.services.gestures',
  ])
  .service('chatMessageActionsService', ChatMessageActionsService)
  .factory('chatPopupService', ChatPopupService)
  .component('sfChatAvatar', ChatAvatarComponent)
  .component('sfChatItem', ChatItemComponent)
  .component('sfChatMessage', ChatMessageComponent)
  .component('sfDeletedChatMessage', DeletedChatMessageComponent)
  .config(chatRouteConfig).name;
