export const ContentStickyDirective = ($timeout) => {
  'ngInject';

  return {
    require: '^sfContent',
    transclude: true,
    templateUrl:
      'components/Layout/content/components/content-sticky/content-sticky.html',
    // eslint-disable-next-line max-params
    link: ($scope, $element, $attr, sfContentCtrl, $transclude) => {
      $transclude((clone) => {
        $timeout(() => {
          angular
            .element(sfContentCtrl.element)
            .append(clone.addClass('sf_content__sticky'));

          const nbIdentifier = sfContentCtrl.stickyElems.length + 1;
          const stickyDefinition = {
            name: $attr.delegateHandle || `sfContentSticky_${nbIdentifier}`,
            original: $element[0],
            clone: sfContentCtrl.element.lastElementChild,
          };

          $scope.$on('$destroy', () => {
            sfContentCtrl.deregisterSticky(stickyDefinition);
            stickyDefinition.clone.remove();
          });

          sfContentCtrl.registerSticky(stickyDefinition);
        });
      });
    },
  };
};
