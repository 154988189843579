import type { SfFeatureFlags } from '@simplifield/feature-flags';
import { intersection } from 'ramda';
import type { UserProfileType, UserRef } from '../../..';
import { CategorizableResourceType } from '../../../categories/services/categories-api/categories.api.factory';
import {
  CategoriesFactory,
  CategoriesService,
} from '../../../categories/services/categories/categories.factory';
import { CommentsResourceType } from '../../../comments/services/comments-api/comments-api.factory';
import {
  CommentsFactory,
  CommentsService,
} from '../../../comments/services/comments/comments.factory';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import type { FilesService } from '../../../services/API/files/files.service';
import { ImageService } from '../../../services/Utils/Image/image.service';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';
import {
  DefaultPostAuthor,
  NewsfeedPost,
  NewsfeedPostResource,
  NewsfeedPostResourceType,
  ResourcePreview,
} from '../../types';
import { NewsfeedApiService } from '../newsfeed-api/newsfeed-api.service';

export type LastSeenPostInfo = {
  postsCount: number;
  since: string;
};

const LAST_SEEN_POST_CREATED_DATE_KEY = 'last_seen_post_created_date';
const DEFAULT_ROLES_ALLOWED_TO_CREATE_POST = ['admin', 'contentManager'];
const ALLOWED_NEWSFEED_CREATORS = 'settings.allowed_newsfeed_creators';
const DISABLE_NEWSFEED_COMMENTS = 'settings.disable_newsfeed_comments';

export class NewsfeedService {
  private lastSeenPostInfo: LastSeenPostInfo | null;
  categoriesService: CategoriesService;
  commentsService: CommentsService;
  readonly ALLOWED_NEWSFEED_CREATORS_PREFERENCE = ALLOWED_NEWSFEED_CREATORS;
  readonly DISABLE_NEWSFEED_COMMENTS_PREFERENCE = DISABLE_NEWSFEED_COMMENTS;
  readonly DEFAULT_ROLES_ALLOWED_TO_CREATE_POST =
    DEFAULT_ROLES_ALLOWED_TO_CREATE_POST;

  // eslint-disable-next-line max-params
  constructor(
    private localStorageService: ng.local.storage.ILocalStorageService,
    private pubSubService: PubSubService,
    private categoriesFactory: CategoriesFactory,
    private commentsFactory: CommentsFactory,
    private newsfeedApiService: NewsfeedApiService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private imageService: ImageService,
    private filesService: FilesService,
    private $q: ng.IQService,
    private organisationsService,
    private profileService
  ) {
    'ngInject';

    this.categoriesService = this.categoriesFactory(
      CategorizableResourceType.NEWSFEED
    );
    this.commentsService = this.commentsFactory(CommentsResourceType.NEWSFEED);
  }

  pullLastSeenPostInfo(): LastSeenPostInfo | null {
    if (!this.lastSeenPostInfo) {
      return null;
    }
    this.broadcastUnseenPostsUpdated();
    return this.lastSeenPostInfo;
  }

  getLastSeenPostInfo(): ng.IPromise<LastSeenPostInfo> {
    const params = { start_date: this.getLastSeenPostDate() };

    return this.newsfeedApiService
      .getLastSeenPostInfo(params)
      .then((data: LastSeenPostInfo) => {
        this.lastSeenPostInfo = data;
        this.broadcastUnseenPostsUpdated();
        return this.lastSeenPostInfo;
      });
  }

  hasFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEWSFEED
    );
  }

  hasAlphaFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEWSFEED_ALPHA
    );
  }

  hasSliderWidgetFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEWSFEED_WIDGET
    );
  }

  setLastSeenPostInfo(post: NewsfeedPost): LastSeenPostInfo {
    const created_date =
      post.contents.publication_date ?? post.created_date ?? '';

    this.lastSeenPostInfo = {
      postsCount: 0,
      since: created_date,
    };
    this.saveLastSeenPostDate(created_date);
    this.broadcastUnseenPostsUpdated();
    return this.lastSeenPostInfo;
  }

  broadcastUnseenPostsUpdated(): void {
    this.pubSubService.publish(
      'LAST_SEEN_POST_INFO_UPDATED',
      this.lastSeenPostInfo
    );
  }

  reset(): void {
    this.lastSeenPostInfo = null;
    this.categoriesService.reset();
  }

  private saveLastSeenPostDate(date: string) {
    this.localStorageService.set(LAST_SEEN_POST_CREATED_DATE_KEY, date);
  }

  private getLastSeenPostDate() {
    const lastSeenPostDate = this.localStorageService.get(
      LAST_SEEN_POST_CREATED_DATE_KEY
    );

    return lastSeenPostDate || null;
  }

  buildAttachedResourcesObjects(
    attachedResources: NewsfeedPostResource[],
    imageSize: string
  ): ng.IPromise<{
    images: ResourcePreview[];
    documents: ResourcePreview[];
    videos: ResourcePreview[];
  }> {
    if (!attachedResources || attachedResources.length === 0) {
      return this.$q.resolve({ images: [], documents: [], videos: [] });
    }
    const resourceFilter = (filterType: NewsfeedPostResourceType) =>
      attachedResources.filter(({ type }) => type === filterType);
    const imageResources = resourceFilter('image');
    const documentResources = resourceFilter('document');
    const videoResources = resourceFilter('video');
    const imageIds = imageResources.map(({ file_id }) => file_id);
    const imagesUrls = this.imageService
      .getSizedUrlsFromIds(imageIds, imageSize)
      .then((urlMap) =>
        imageResources.map((resource) =>
          this.buildResourceObject(urlMap[resource.file_id], resource)
        )
      );
    const documentUrls = this.$q.all(
      documentResources.map((resource) =>
        this.filesService
          .getUrl(resource.file_id)
          .then((url) => this.buildResourceObject(url, resource))
      )
    );

    const videoUrls = this.$q.all(
      videoResources.map((resource) =>
        this.buildResourceObject(resource.file_id, resource)
      )
    );

    return this.$q
      .all([imagesUrls, documentUrls, videoUrls])
      .then(([images, documents, videos]) => ({ images, documents, videos }));
  }

  private buildResourceObject(
    url: string,
    { file_id, type, mimeType, name, size }: NewsfeedPostResource
  ): ResourcePreview {
    return {
      url,
      id: file_id,
      name,
      type,
      mimeType,
      size,
    };
  }

  isImageResource(resourceType: NewsfeedPostResourceType): boolean {
    return resourceType === 'image';
  }

  isDocumentResource(resourceType: NewsfeedPostResourceType): boolean {
    return resourceType === 'document';
  }

  isVideoResource(resourceType: NewsfeedPostResourceType): boolean {
    return resourceType === 'video';
  }

  getNewsfeedPreferences(): ng.IPromise<UserProfileType[]> {
    return this.organisationsService
      .getPreference(this.ALLOWED_NEWSFEED_CREATORS_PREFERENCE)
      .catch(() => []); // intentionally didn't throw an error
  }

  canAddPost(): ng.IPromise<boolean> {
    return this.$q
      .all([this.getNewsfeedPreferences(), this.profileService.getProfile()])
      .then(([newsfeedPreferences, profile]) => {
        const profiles = profile.contents?.profiles ?? [];
        const allowedRoles = newsfeedPreferences.length
          ? newsfeedPreferences
          : this.DEFAULT_ROLES_ALLOWED_TO_CREATE_POST;

        return intersection(allowedRoles, profiles).length > 0;
      })
      .catch(() => false); // intentionally didn't throw an error
  }

  getPostAuthorName(
    author: UserRef | DefaultPostAuthor,
    authorType: 'user' | 'organisation' = 'user'
  ): string {
    if (authorType === 'user' && 'firstName' in author) {
      return `${author.firstName} ${author.lastName}`;
    }
    if (authorType === 'organisation' && 'name' in author) {
      return author.name;
    }
    return '';
  }

  getResourceTypeFromMimeType(mimeType: string): NewsfeedPostResourceType {
    if (mimeType.startsWith('video')) {
      return 'video';
    }
    if (mimeType.startsWith('image')) {
      return 'image';
    }
    return 'document';
  }

  areCommentsDisabled(): ng.IPromise<boolean> {
    return this.organisationsService
      .getPreference(this.DISABLE_NEWSFEED_COMMENTS_PREFERENCE)
      .then((pref) => pref || false)
      .catch(() => {
        // Error happen, false is the default
        return false;
      });
  }
}
