const GEOLOC_PERMISSION_CODE_ERRORS = 1;
const GEOLOC_TIMEOUT_CODE_ERRORS = 3;
const GEOLOC_CODE_ERRORS = [
  GEOLOC_PERMISSION_CODE_ERRORS,
  GEOLOC_TIMEOUT_CODE_ERRORS,
];

export class LogService {
  constructor(datadogService) {
    'ngInject';
    this.datadogService = datadogService;
    this.isUserWhitelisted = () => {
      return this.datadogService.isUserWhitelisted();
    };
  }

  ignoreException(error) {
    if (!error || this.checkNetworkException(error)) {
      return true;
    }
    if (this.checkNetworkException(error)) {
      return true;
    }

    return !!this.checkGeolocTimeoutError(error);
  }

  checkNetworkException(error) {
    return {}.undef !== error.status;
  }

  checkGeolocTimeoutError(error) {
    return error.code && GEOLOC_CODE_ERRORS.indexOf(error.code) !== -1;
  }

  debug(msg, context, filterWhitelistedUsers = false) {
    const isUserWhitelisted = filterWhitelistedUsers
      ? this.isUserWhitelisted()
      : true;

    if (isUserWhitelisted) {
      this.datadogService.addDebug(msg, context);
    }
  }

  info(msg, context, filterWhitelistedUsers = false) {
    const isUserWhitelisted = filterWhitelistedUsers
      ? this.isUserWhitelisted()
      : true;

    if (isUserWhitelisted) {
      this.datadogService.addInfo(msg, context);
    }
  }

  warn(msg, context, filterWhitelistedUsers = false) {
    const isUserWhitelisted = filterWhitelistedUsers
      ? this.isUserWhitelisted()
      : true;

    if (isUserWhitelisted) {
      this.datadogService.addWarn(msg, context);
    }
  }

  error(msg, context, filterWhitelistedUsers = false) {
    const isUserWhitelisted = filterWhitelistedUsers
      ? this.isUserWhitelisted()
      : true;

    if (isUserWhitelisted) {
      this.datadogService.addError(msg, context);
    }
  }

  // IT-3253: temporary method dedicated to build common log context for investigations on reports.
  reportLog(msg, report, additionnalContext) {
    //TODO: add a try/catch to avoid any additional issue during the report processing?
    if (!report) {
      return;
    }

    let logDetails = {
      reportId: report.id,
      reportLocalStatus: report.localStatus,
      campaignId: report.contents.campaign_id,
      nodesCount: 0,
      answersCount: 0,
      answerIdsBySection: 'no answers',
      answerIdsByNode: 'no answers',
    };

    if (
      report.contents &&
      report.contents.nodes &&
      Array.isArray(report.contents.nodes)
    ) {
      logDetails = {
        ...logDetails,
        nodesCount: report.contents.nodes.length,
        nodeIds: report.contents.nodes.map((node) => node._id),
      };
    }

    if (
      report.contents &&
      report.contents.answers &&
      Array.isArray(report.contents.answers)
    ) {
      const answerIdsBySection = report.contents.answers.reduce(
        (answersBySection, answer) => {
          if (answer.sections_ids[0]) {
            if (!answersBySection[answer.sections_ids[0]]) {
              answersBySection[answer.sections_ids[0]] = [];
            }
            answersBySection[answer.sections_ids[0]].push(answer._id);
          }
          return answersBySection;
        },
        {}
      );
      const answerIdsByNode = report.contents.answers.reduce(
        (answersByNode, answer) => {
          if (answer.nodes_ids[0]) {
            if (!answersByNode[answer.nodes_ids[0]]) {
              answersByNode[answer.nodes_ids[0]] = [];
            }
            answersByNode[answer.nodes_ids[0]].push(answer._id);
          }
          return answersByNode;
        },
        {}
      );
      logDetails = {
        ...logDetails,
        answersCount: report.contents.answers.length,
        answerIdsBySection: JSON.stringify(answerIdsBySection),
        answerIdsByNode: JSON.stringify(answerIdsByNode),
      };
    }

    // should we add more info like campaignId, userId, nb of questions?
    if (logDetails.answersCount === 0 || logDetails.nodesCount === 0) {
      this.warn(msg, { ...logDetails, ...additionnalContext });
    } else {
      this.info(msg, { ...logDetails, ...additionnalContext });
    }
  }
}

export function LogExceptionHandler(
  $delegate,
  $log,
  sentryService,
  datadogService,
  logService
) {
  'ngInject';
  return (error, cause) => {
    if (!logService.ignoreException(error)) {
      try {
        sentryService.captureException(error);
        datadogService.addError(error, cause);
      } catch (e) {
        $log.error('Log Error:', e);
      }
    }
    return $delegate(error, cause);
  };
}
