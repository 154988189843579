export const PhotosPreviewComponent = {
  bindings: {
    photos: '<',
    onDelete: '&',
    hideShareButton: '<?',
    hideDownloadButton: '<?',
  },
  templateUrl: 'components/photos-preview/photos-preview.html',
  controller: function PhotoPreviewController(modalService) {
    'ngInject';

    this.viewPicture = (photo) => {
      const template = `
      <sf-images-viewer
        images="[$ctrl.photo]"
        hide-share-button="$ctrl.hideShareButton"
        hide-download-button="$ctrl.hideDownloadButton"
        on-close="$ctrl.onClose()">
      </sf-images-viewer>
    `;
      const modalBindings = {
        photo,
        hideShareButton: this.hideShareButton,
        hideDownloadButton: this.hideDownloadButton,
      };

      return modalService.open(template, modalBindings);
    };
  },
};
