import { GroupTasksService } from '../../../group-tasks/services/group-tasks.service';
import { APIGroupTask } from '../../../group-tasks/types';
import { User } from '../../../index';
import { TasksService } from '../../../services/API/tasks/tasks.service';
import { ActionSheetService } from '../../../services/Utils/ActionSheet/action-sheet.service';
import { AppMessageService } from '../../../services/Utils/AppMessage/app-message.service';
import { DateService } from '../../../services/Utils/Dates/date.service';
import { ErrorMessagesService } from '../../../services/Utils/ErrorMessages/error-messages.service';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import { PopupService } from '../../../services/Utils/Popup/popup.service';
import { TranslateNumbersService } from '../../../services/Utils/TranslateNumbers/translateNumbers.service';
import {
  ActionOptions,
  GroupTaskActionsService,
} from '../../services/group-task-actions.service';

export class GroupTaskItemController implements ng.IComponentController {
  // bindings
  profile: User;
  groupTask: APIGroupTask;
  onDeleted: () => void;

  // attributes
  isRTLNeeded = false;
  selected = false;
  offlineMode: boolean;
  subtasksCount = 0;
  progressValue: number;
  progressString: string;
  actions: ActionOptions[];

  constructor(
    private translateNumbersService: TranslateNumbersService,
    private localizationService: LocalizationService,
    private dateService: DateService,
    private tasksService: ReturnType<typeof TasksService>,
    private readonly groupTaskActionsService: GroupTaskActionsService,
    private readonly actionSheetService: ActionSheetService,
    private readonly popupService: PopupService,
    private readonly $translate: ng.translate.ITranslateService,
    private readonly groupTasksService: GroupTasksService,
    private readonly errorMessagesService: ErrorMessagesService,
    private readonly appMessageService: AppMessageService
  ) {
    'ngInject';
  }

  $onInit = (): void => {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.subtasksCount = this.groupTask.contents.template.subtasks?.length ?? 0;
    this.getProgression();

    this.actions = this.groupTaskActionsService.getGrouptaskActions({
      owner_id: this.groupTask.contents.owner_id,
      profile: this.profile,
      callbacks: {
        onDelete: () => this.onDeletePost(),
      },
    });
  };

  getProgression(): void {
    const total: number = this.groupTask.contents.progression?.total || 0;
    const done: number = this.groupTask.contents.progression?.done || 0;

    this.progressValue = (done / total) * 100 ?? 0;
    this.progressString = `${this.progressValue}%`;
  }

  onDeletePost(): ng.IPromise<void | ng.IHttpResponse<void>> {
    return this.popupService
      .showConfirm({
        title: this.$translate.instant('GROUP_TASK_CONFIRM_DELETE_TITLE'),
        description: this.$translate.instant(
          'GROUP_TASK_CONFIRM_DELETE_DESCRIPTION'
        ),
        iconName: 'item-danger',
        buttonText: this.$translate.instant('GROUP_TASK_ACTION_DELETE'),
      })
      .then(() => {
        return this.groupTasksService
          .delete(this.groupTask._id)
          .then(() => {
            this.onDeleted();
          })
          .catch((deletedError) => {
            if (deletedError.errorCode === 404) {
              return this.onDeleted();
            }

            this.errorMessagesService.display(deletedError, {
              inlineErrorMessageUpdater: () => {
                this.appMessageService.display(
                  'GROUP_TASK_DELETE_ERROR',
                  'fail'
                );
              },
            });
          });
      })
      .catch(() => {});
  }

  onItemClick(): IonicV1.IonicActionSheet {
    return this.actionSheetService.open(this.actions, {
      cancelText: this.$translate.instant('BUTTON_ITEM_ACTIONS_CANCEL'),
    });
  }
}
