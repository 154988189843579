import { RecurrenceSelector } from './recurrence-selector.component';
import { CustomRecurrenceModal } from './modal/custom-recurrence.modal.component';
import { WeekDaySelector } from './internal/weekday-selector/weekday-selector.component';
import { RecurrenceService } from './recurrence.service';

declare let angular: ng.IAngularStatic;

export default angular
  .module('sfMobile.components.recurrence-selector', [
    'sfMobile.services.localization',
  ])
  .service('recurrenceService', RecurrenceService)
  .component('sfWeekDaySelector', WeekDaySelector)
  .component('sfRecurrenceSelector', RecurrenceSelector)
  .component('sfCustomRecurrenceModal', CustomRecurrenceModal).name;
