import { IMAGE_SIZES } from '../../../../constants/image-sizes.constant';

export const UserCompletionStatsComponent = {
  bindings: {
    campaign: '<?',
    user: '<',
    count: '<',
  },
  templateUrl:
    'legacy-campaigns/campaign/components/user-completion-stats/user-completion-stats.html',
  controller: class UserCompletionStatsController {
    useNewCampaigns: boolean;
    userCompletionTranslation: string;
    /* @ngInject */
    constructor(
      private $translate: ng.translate.ITranslateService,
      private profileService,
      private organisationsService
    ) {}

    $onInit(): ng.IPromise<void> {
      return this.profileService
        .getProfile()
        .then((profile) =>
          this.organisationsService.getProfileOrganisation(profile)
        )
        .then((organisation) => {
          this.useNewCampaigns = organisation.useNewCampaigns;

          if (this.useNewCampaigns) {
            this.userCompletionTranslation = this.$translate.instant(
              'USER_COMPLETION_REPORTS_CHECKLISTS'
            );
          } else {
            this.userCompletionTranslation = this.$translate.instant(
              'USER_COMPLETION_REPORTS'
            );
          }
        });
    }
  },
};
