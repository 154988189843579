"use strict";

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Calendar Event',
  type: 'object',
  additionalProperties: false,
  required: ['start_dateTime', 'end_dateTime', 'timezone', 'timezoneSource', 'allDayLong', 'assignees_ids', 'calendarEventType_id', 'organisation_id'],
  properties: {
    start_dateTime: {
      type: 'string',
      format: 'date-time'
    },
    end_dateTime: {
      type: 'string',
      format: 'date-time'
    },
    timezone: schemaUtils.timezone(),
    timezoneSource: {
      type: 'string',
      "enum": ['manually_set', 'inherited_from_place'],
      "default": 'inherited_from_place'
    },
    allDayLong: {
      type: 'boolean',
      "default": false
    },
    description: {
      type: 'string'
    },
    assignees_ids: schemaUtils.oneToSeveral({
      minItems: 1
    }),
    calendarEventType_id: schemaUtils.oneToOne(),
    place_id: schemaUtils.oneToOne(),
    organisation_id: schemaUtils.oneToOne()
  }
};