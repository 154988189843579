export const SEGMENT_EVENTS = {
  NOTIFICATION: 'Notification',
  LOGIN: 'Login',
  ACTIVITY: 'Activity',
  MERCH: 'Merchandising',
  TASKS_LIST: 'Tasks List',
  TASK_ITEM: 'Task Item',
  TASK_FORM: 'Task Form',
  TASK_ACTIONS: 'Task Actions',
  TASKS_TABS: 'Tasks Tabs',
  PLACES: 'Places List',
  PLACE: 'Place detail',
  PLACE_INFO: 'Place detail info',
  PLACE_EDITION: 'Place edition',
  REPORT_COMMENTS: 'Report comments',
  MISSIONS_TAB: 'Missions tabs',
  PROFILE: 'Profile',
  DIALOG: 'Dialog',
  SYNCHRONIZE: 'Synchronization',
  SYNCHRONIZATION_DONE: 'Synchronization Done',
  CALENDAR_EVENTS: 'Calendar Events',
  // new nomenclature
  // see https://docs.google.com/spreadsheets/d/1e8HgaXPkvuG4lzD6FR93Q528aZz-XvGqww115UdM_9w/edit#gid=2105055590
  REPORT_SENT: 'Report Sent',
  PHOTO_ANSWER_APPROVED: 'Photo Answer Approved',
} as const;
