export default function routeConfig($stateProvider) {
  'ngInject';

  $stateProvider
    .state('index.menu-more.campaigns', {
      url: '/campaigns',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('index.menu-more.campaigns.list', {
      url: '',
      template: `
        <ion-view>
          <sf-campaigns>
          </sf-campaigns>
        </ion-view>
      `,
      controller: CampaignsViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Campaigns List',
    })
    .state('index.menu-more.campaigns.details', {
      url: '/:campaignId',
      template: `
        <ion-view cache-view="false">
          <sf-view sf-tab-bar-hide="true">
            <sf-nav-bar
              title="{{ $ctrl.campaign.contents.title | sfTranslation: $ctrl.campaign.i18n }}"
              display-back-button="true">
            </sf-nav-bar>
            <sf-campaign
              campaign="$ctrl.campaign">
            </sf-campaign>
          </sf-view>
        </ion-view>
      `,
      controller: CampaignViewController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpCampaign: getRsvpCampaign,
      },
      analyticsName: 'Campaign',
    });

  // @ngInject
  function CampaignsViewController() {}
  // @ngInject
  function CampaignViewController(rsvpCampaign) {
    this.campaign = rsvpCampaign;
  }

  // @ngInject
  function getRsvpCampaign($stateParams, formsService) {
    return formsService.get($stateParams.campaignId);
  }
}
