import type { PlatformService } from '../../../services/Utils/Platform';
import type { NavBarElement } from '../view/view.controller';

export type StickyElement = {
  name: string;
  original: HTMLElement;
  clone: HTMLElement;
  cloneDisplay: boolean;
};

const hideClone = (elem: StickyElement): void => {
  elem.original.style.opacity = '1';
  elem.clone.style.display = 'none';
  elem.cloneDisplay = false;
};
const getNavBarHeight = (navBar: NavBarElement | null): number =>
  navBar ? navBar.bar.clientHeight : 0;
const getHeaderHeight = (header: HTMLElement | null): number =>
  header ? header.clientHeight : 0;
const getStickyTopPosition = (
  elem: StickyElement,
  navBar: NavBarElement | null
): number => {
  const elemClientRect = elem.original.getBoundingClientRect();
  const navBarHeight = getNavBarHeight(navBar);
  const offsetTop = elemClientRect.top - navBarHeight;

  return offsetTop > 0 ? offsetTop : 0;
};
const getPercent = (number: number): number => Math.round(number * 100);

export class ContentController {
  //bindings
  key: string;
  parallaxImage: string;
  forceJsScroll: boolean;
  onRefresh: () => void;
  refresher: Record<string, string>;
  hasMultipleSticky: boolean;
  currentStickyHandle: string;
  onScroll: ({ scrollValue }: { scrollValue: number }) => void;
  //require
  viewController: {
    navBar: NavBarElement;
    initHeader: () => void;
    onContentScroll: (percentScrolled: number) => void;
  };
  //members
  isBrowser: boolean;
  header: HTMLElement;
  element: HTMLElement;
  navBar: NavBarElement | null;
  stickyElems: StickyElement[] = [];
  onParallaxScroll: (scrollValue: number) => void;
  constructor(
    private platformService: PlatformService,
    private $element: ng.IRootElementService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.isBrowser = this.platformService.isBrowser();
    this.navBar = this.viewController ? this.viewController.navBar : null;
    this.stickyElems = [];
    this.element = this.$element[0];
  }
  $onChanges(changes): void {
    if (changes.currentStickyHandle) {
      this.changeStickyDisplay(changes.currentStickyHandle.currentValue);
    }
  }
  registerHeader(header: HTMLElement): void {
    this.header = header;
    this.viewController.initHeader();
  }
  registerSticky(elemsSticky: StickyElement): void {
    elemsSticky.clone.style.display = 'none';
    elemsSticky.cloneDisplay = false;

    this.stickyElems = this.stickyElems.concat(elemsSticky);
  }
  deregisterSticky(elemsSticky: StickyElement): void {
    this.stickyElems = this.stickyElems.filter(
      (elem) => elemsSticky.name !== elem.name
    );
  }

  onContentScroll(event, scrollTop: number): void {
    const { navBar, header } = this;
    const headerHeight = getHeaderHeight(header);
    const navBarHeight = getNavBarHeight(navBar);
    const scrollValue = event?.target?.scrollTop || scrollTop;

    const percentScrolled = getPercent(
      scrollValue / (headerHeight - navBarHeight)
    );

    this.viewController.onContentScroll(percentScrolled);

    if (this.stickyElems.length) {
      this.displaySticky(scrollValue);
    }

    if (this.onParallaxScroll) {
      this.onParallaxScroll(scrollValue);
    }

    this.onScroll({ scrollValue });
  }
  displaySticky(scrollTop: number): void {
    const stickyElem = this.getRightSticky();
    const { navBar, header } = this;

    if (!stickyElem) {
      return;
    }

    const checkTitleScrolled = () =>
      scrollTop > 0 &&
      (!header || getStickyTopPosition(stickyElem, navBar) <= 0);
    const isTitleScrolled = checkTitleScrolled();
    const hasToDisplayClone = () => isTitleScrolled && !stickyElem.cloneDisplay;
    const hasToHideClone = () => !isTitleScrolled && stickyElem.cloneDisplay;

    if (hasToDisplayClone()) {
      this.displayClone(stickyElem);
      return;
    }
    if (hasToHideClone()) {
      hideClone(stickyElem);
    }
  }
  getStickyElemByName(name: string): StickyElement {
    const elem = this.stickyElems.filter(
      (elem: StickyElement) => elem.name === name
    )[0];

    return elem;
  }

  getRightSticky(): StickyElement {
    return this.hasMultipleSticky
      ? this.getStickyElemByName(this.currentStickyHandle)
      : this.stickyElems[0];
  }

  changeStickyDisplay(currentStickyDisplay: string): void {
    this.stickyElems.forEach((elem) => {
      if (elem.name !== currentStickyDisplay) {
        elem.clone.style.display = 'none';
      }
    });
  }
  displayClone(elem: StickyElement): void {
    if (!this.header && !this.navBar) {
      elem.clone.style.top = '0';
    }
    elem.original.style.opacity = '0';
    elem.clone.style.display = 'block';
    elem.cloneDisplay = true;
  }
}
