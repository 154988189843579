import type { SfFeatureFlags } from '@simplifield/feature-flags';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';

export class CampaignService {
  /* @ngInject */
  constructor(
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {}

  hasCompletionFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.CAMPAIGN_COMPLETION
    );
  }
}
