export const GEOLOCATION_PROPERTIES = {
  STREET_NUMBER: ['street_number'],
  STREET: ['route'],
  ZIPCODE: ['postal_code'],
  CITY: ['locality', 'postal_town', 'sublocality', 'sublocality_level_1'],
  ADMINISTRATIVE_AREA: ['administrative_area_level_1'],
  COUNTRY: ['country'],
};

export const GOOGLE_GEOCODE_URL =
  'https://maps.googleapis.com/maps/api/geocode/json';

export const GOOGLE_MAPS_SEARCH_URL =
  'https://www.google.com/maps/search/?api=1&query=';
