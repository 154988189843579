"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('validation1'),
    upstanding: {
      valid: true
    },
    contents: {
      state: 'pending',
      organisation_id: createObjectId('organisation'),
      report_id: createObjectId('report'),
      question_id: createObjectId('question')
    },
    metadata: {}
  }, {
    _id: createObjectId('validation2'),
    upstanding: {
      valid: true
    },
    contents: {
      state: 'rejected',
      organisation_id: createObjectId('organisation'),
      report_id: createObjectId('report'),
      question_id: createObjectId('question')
    },
    metadata: {}
  }, {
    _id: createObjectId('validation3'),
    upstanding: {
      valid: true
    },
    contents: {
      state: 'validated',
      organisation_id: createObjectId('organisation'),
      report_id: createObjectId('report'),
      question_id: createObjectId('question')
    },
    metadata: {}
  }, {
    _id: createObjectId('validation4'),
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.state']
      }]
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      report_id: createObjectId('report'),
      question_id: createObjectId('question')
    },
    metadata: {}
  }, {
    _id: createObjectId('validation5'),
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.organisation_id']
      }]
    },
    contents: {
      state: 'pending',
      report_id: createObjectId('report'),
      question_id: createObjectId('question')
    },
    metadata: {}
  }, {
    _id: createObjectId('validation6'),
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_UNEXPECTED_CONTENT',
        params: ['.additional']
      }]
    },
    contents: {
      additional: 'property',
      state: 'pending',
      organisation_id: createObjectId('organisation'),
      report_id: createObjectId('report'),
      question_id: createObjectId('question')
    },
    metadata: {}
  }, {
    _id: createObjectId('validation7'),
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.state', 'should be equal to one of values']
      }]
    },
    contents: {
      state: ['array instead of string'],
      organisation_id: createObjectId('organisation'),
      report_id: createObjectId('report'),
      question_id: createObjectId('question')
    },
    metadata: {}
  }];
};