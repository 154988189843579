import { ObjectId } from '../../..';
import { Campaign } from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';
import { Form } from '../../../services/API/forms/forms';
import { Mission } from '../../../services/API/Missions/missions';
import { Report } from '../../../services/API/reports/reports';
import { ReportsService } from '../../../services/API/reports/reports.service';

export class PlaceService {
  constructor(
    private $q: ng.IQService,
    private formsService,
    private missionsService,
    private reportsService: ReportsService,
    private campaignsService: CampaignsService
  ) {
    'ngInject';
  }

  getPlaceMissionsData(
    placeId: ObjectId,
    profileId: ObjectId
  ): ng.IPromise<{
    reports: Report[];
    missions: Mission[];
    forms: Form[];
  }> {
    const reportsRequestParams = {
      localStatus: ['ready', 'draft'],
      place_id: placeId,
      campaign_id: undefined,
    };
    const missionRequestParams = {
      place_id: placeId,
      user_id: profileId,
    };
    const formsRequestParams = {
      deleted: false,
    };
    const promises = [
      this.reportsService.crud.queryLocal(reportsRequestParams),
      this.missionsService.queryLocal(missionRequestParams),
      this.formsService.queryLocal(formsRequestParams),
    ];

    // Local datas
    return this.$q.all(promises).then(([reports, missions, forms]) => ({
      reports,
      missions,
      forms,
    }));
  }

  getPlaceCampaignsData(
    placeId: ObjectId,
    profileId: ObjectId,
    nameSearch = ''
  ): ng.IPromise<{
    reports: Report[];
    campaignsWithGoal: Campaign[];
    campaignsWithoutGoal: Campaign[];
  }> {
    const reportsRequestParams = {
      localStatus: ['ready', 'draft'],
      place_id: placeId,
      form_id: undefined,
    };
    const campaignRequestParams = {
      'contents.subjectType': 'store',
      'resolved.subject_ids': [placeId],
      'resolved.collector_ids': [profileId],
      ...(nameSearch ? { 'contents.name': new RegExp(nameSearch) } : {}),
    };
    const promises = [
      this.reportsService.crud.queryLocal(reportsRequestParams),
      this.campaignsService.crud.queryLocal({
        ...campaignRequestParams,
        contents: (val) =>
          Object.prototype.hasOwnProperty.call(val, 'objective'),
      }),
      this.campaignsService.crud.queryLocal({
        ...campaignRequestParams,
        contents: (val) =>
          !Object.prototype.hasOwnProperty.call(val, 'objective'),
      }),
    ];

    // Local datas
    return this.$q.all(promises).then(
      ([reports, campaignsWithGoal, campaignsWithoutGoal]) =>
        ({
          reports,
          campaignsWithGoal,
          campaignsWithoutGoal,
        } as {
          reports: Report[];
          campaignsWithGoal: Campaign[];
          campaignsWithoutGoal: Campaign[];
        })
    );
  }
}
