import { CalendarEventFormController } from './calendar-event-form.controller';

export const CalendarEventFormComponent = {
  bindings: {
    formTitle: '@',
    saveBtnLabel: '@',
    event: '<',
    disableStoreSelection: '<?',
    onSaveButtonClick: '&',
  },
  templateUrl:
    'calendar-events/components/calendar/calendar-event-form/calendar-event-form.html',
  controller: CalendarEventFormController,
};
