const CAMPAIGNS_REQUEST_PARAMS = {
  state: 'current',
  withDigests: true,
  fields: ['contents', 'i18n'],
};

export const CampaignsComponent = {
  templateUrl: 'legacy-campaigns/campaigns/campaigns.html',
  // eslint-disable-next-line max-params
  controller: function CampaignsController(
    $state,
    pubSubService,
    contentService,
    formsService
  ) {
    'ngInject';

    let stateChangeListener = null;

    this.$onInit = () => {
      const stateName = $state.current.name;

      stateChangeListener = pubSubService.subscribe(
        pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
        (params) => {
          if (stateName === params.name) {
            this.callForms();
          }
        }
      );

      this.forms = null;
      this.isLoading = true;
      this.networkError = false;

      return this.callForms();
    };

    this.$onDestroy = () => {
      stateChangeListener();
    };

    /**
     * Update datas to display
     *
     * @param  {Array} forms  - Forms datas
     * @return {Array} datas - Datas if request
     */
    this.updateDatas = (forms) => {
      this.forms = forms.entries;
      return forms;
    };

    this.callForms = () => {
      this.isLoading = true;

      contentService.scrollTopById('campaignsContent');

      return formsService
        .simpleApiList(undefined, CAMPAIGNS_REQUEST_PARAMS, true)
        .then(this.updateDatas)
        .then((data) => {
          this.networkError = false;
          return data;
        })
        .catch((err) => {
          this.networkError = true;
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.reload = () => this.callForms();
  },
};
