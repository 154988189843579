export function ChatService($http, apiUrlService) {
  'ngInject';
  return {
    deleteGroupChannel,
    deleteUsersFromGroupChannel,
    deleteMessage,
  };

  function deleteGroupChannel(channelUrl) {
    const baseApiUrl = apiUrlService.getApiUrl();
    const deleteUrl = `${baseApiUrl}/sendbird/group_channels/${channelUrl}`;

    return $http.delete(deleteUrl);
  }

  function deleteUsersFromGroupChannel(channelUrl, userIds) {
    const baseApiUrl = apiUrlService.getApiUrl();
    const deleteUsersUrl = `${baseApiUrl}/sendbird/group_channels/${channelUrl}/users`;
    const payload = {
      user_ids: userIds,
    };

    return $http.delete(deleteUsersUrl, {
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function deleteMessage(message) {
    const { messageId, channelUrl } = message;
    const baseApiUrl = apiUrlService.getApiUrl();

    return $http.delete(
      `${baseApiUrl}/sendbird/group_channels/${channelUrl}/messages/${messageId}`
    );
  }
}
