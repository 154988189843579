"use strict";

/* eslint-disable max-len */
var createObjectId = require('../utils/createObjectId');

var defaultEventType = {
  multipleAssignees: true,
  required: []
};

module.exports = function () {
  return [{
    _id: createObjectId('visit'),
    name: 'visit',
    upstanding: {
      valid: true
    },
    eventType: defaultEventType,
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      assignees_ids: [createObjectId('user1')],
      place_id: createObjectId('place'),
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('animation'),
    name: 'animation',
    upstanding: {
      valid: true
    },
    eventType: defaultEventType,
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      description: 'description',
      allDayLong: false,
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('no-start-date-time'),
    name: 'no start date time',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.start_dateTime']
      }]
    },
    eventType: defaultEventType,
    contents: {
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      description: 'description',
      allDayLong: false,
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('no-end-date-time'),
    name: 'no end date time',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.end_dateTime']
      }]
    },
    eventType: defaultEventType,
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      description: 'description',
      allDayLong: false,
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('no-timezone'),
    name: 'no timezone',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.timezone']
      }]
    },
    eventType: defaultEventType,
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezoneSource: 'inherited_from_place',
      description: 'description',
      allDayLong: false,
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('no-timezone-source'),
    name: 'no timezone source',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.timezoneSource']
      }]
    },
    eventType: defaultEventType,
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      description: 'description',
      allDayLong: false,
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('no-all-day-long'),
    name: 'no all day long',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.allDayLong']
      }]
    },
    eventType: defaultEventType,
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      description: 'description',
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('no-assignees'),
    name: 'no assignees',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.assignees_ids']
      }]
    },
    eventType: defaultEventType,
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      description: 'description',
      allDayLong: false,
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('no-organisation'),
    name: 'no organisation',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.organisation_id']
      }]
    },
    eventType: defaultEventType,
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      description: 'description',
      allDayLong: false,
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      calendarEventType_id: createObjectId('type')
    }
  }, {
    _id: createObjectId('no-calendar-event-type'),
    name: 'no calendar event type',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.calendarEventType_id']
      }]
    },
    eventType: defaultEventType,
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      description: 'description',
      allDayLong: false,
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      organisation_id: createObjectId('organisation')
    }
  }, // with additional validation rules
  {
    _id: createObjectId('no-place'),
    name: 'no place',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.place_id']
      }]
    },
    eventType: {
      multipleAssignees: true,
      requiredFields: ['place_id']
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      description: 'description',
      allDayLong: false,
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('no-description'),
    name: 'no description',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.description']
      }]
    },
    eventType: {
      multipleAssignees: true,
      requiredFields: ['description']
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('multiple-assignees-when-not-allowed'),
    name: 'multiple-assignees-when-not-allowed',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['should NOT have more than 1 items']
      }]
    },
    eventType: {
      multipleAssignees: false,
      requiredFields: []
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      assignees_ids: [createObjectId('user1'), createObjectId('user2')],
      calendarEventType_id: createObjectId('type'),
      organisation_id: createObjectId('organisation')
    }
  }];
};