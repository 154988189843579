import moment from 'moment-timezone';

export function startDate(DATE_FORMATTING_STRINGS) {
  'ngInject';
  return ({ start_dateTime, timezone }, multipleDays) =>
    moment
      .tz(start_dateTime, timezone.name)
      .format(
        multipleDays
          ? DATE_FORMATTING_STRINGS.date_month_and_year
          : DATE_FORMATTING_STRINGS.date
      );
}

export function startTime(DATE_FORMATTING_STRINGS) {
  'ngInject';
  return ({ start_dateTime, timezone }, multipleDays) =>
    moment
      .tz(start_dateTime, timezone.name)
      .format(
        multipleDays
          ? DATE_FORMATTING_STRINGS.date_month_and_year
          : DATE_FORMATTING_STRINGS.time
      );
}

export function endTime(DATE_FORMATTING_STRINGS) {
  'ngInject';
  // TODO: refactor it, since we have end time and don't use duration we don't need two methods: startTime and endTime
  return ({ end_dateTime, timezone }, multipleDays) =>
    moment
      .tz(end_dateTime, timezone.name)
      .format(
        multipleDays
          ? DATE_FORMATTING_STRINGS.date_month_and_year
          : DATE_FORMATTING_STRINGS.time
      );
}
