import { AVAILABLE_TRANSLATION_LANGUAGES } from './available-translation-languages.constant';
import sfTranslation from './sf-translation.filters';

export default angular
  .module('sfMobile.services.form-translations', [])
  .filter('sfTranslation', function (localizationService, profileService) {
    'ngInject';
    return sfTranslation(localizationService, profileService);
  })
  .constant('AVAILABLE_TRANSLATION_LANGUAGES', AVAILABLE_TRANSLATION_LANGUAGES)
  .name;
