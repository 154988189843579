export function FormValidationsService() {
  'ngInject';
  const methods = {
    hasToDisplay,
    getInputError,
  };

  /**
   * @param  {Object} form Expects an Angular Model Form.
   * @param  {string} fieldName Expects a string associated with the form control name.
   * @return  {boolean} Should return true when we want to show to User an error.
   */
  function hasToDisplay(form, fieldName) {
    return !!(
      form && // Make sure the form exist
      form[fieldName] && // Make sure the form control exist. It should be present in html as attribute name=""
      isInputDirty(form, fieldName) &&
      isInputInvalid(form, fieldName)
    );
  }

  /**
   * @param  {Object} form Expects an Angular Model Form
   * @param  {string} fieldName Expects a string associated with the form control name
   * @return  {Object} Should return the errors for the form control.
   */
  function getInputError(form, fieldName) {
    return form[fieldName] && form[fieldName].$error;
  }

  /**
   * @param  {Object} form Expects an Angular Model Form
   * @param  {string} fieldName Expects a string associated with the form control name
   * @return  {boolean} Should return true only when user starts typing.
   */
  function isInputDirty(form, fieldName) {
    return form[fieldName].$dirty;
  }

  /**
   * @param  {Object} form Expects an Angular Model Form
   * @param  {string} fieldName Expects a string associated with the form control name
   * @return  {boolean} Should return true only when angular control has errors.
   */
  function isInputInvalid(form, fieldName) {
    return form[fieldName].$invalid;
  }

  return methods;
}
