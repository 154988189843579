import { CampaignSelectorComponent } from './campaign-selector.component';

export default angular
  .module('sfMobile.components.campaignSelector', [
    'sfMobile.services.localization',
    'pascalprecht.translate',
    'sfMobile.components.content',
    'sfMobile.components.button-header-close',
  ])
  .component('sfCampaignSelector', CampaignSelectorComponent).name;
