"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint complexity:[0] */
var errorsLibrary = require('../utils/errors');

var reportsLibrary = {
  defaults: reportsLibraryDefaults,
  validate: reportsLibraryValidate,
  getSectionNodes: reportsLibraryGetSectionNodes,
  getNodeAnswers: reportsLibraryGetNodeAnswers,
  getQuestionAnswers: reportsLibraryGetQuestionAnswers,
  getAnswerHash: reportsLibraryGetAnswerHash,
  getEanAnswers: reportsLibraryGetEanAnswers,
  getSalesResultsByBrand: reportsLibraryGetSalesResultsByBrand,
  getTopLevelSectionIds: getTopLevelSectionIds,
  getMaximumFromFormula: getMaximumFromFormula,
  computeScoreForAnswer: computeScoreForAnswer,
  computeScoreForQuestion: computeScoreForQuestion,
  computeScoreForSection: computeScoreForSection,
  computeScore: computeScore,
  computeTotalForProducts: computeTotalForProducts
}; // One liner compilation to avoid lib functions scope pollution

reportsLibrary.validateSchema = new (require('ajv'))().compile(require('./report.schema')); // eslint-disable-line

var isQuestionType = function isQuestionType(type) {
  return function (q) {
    return (q.fields || []).some(function (f) {
      return f.type === type;
    });
  };
};

var isQuestionEan = isQuestionType('ean');
module.exports = reportsLibrary;

function reportsLibraryDefaults(report) {
  var defaultReport = {
    nodes: [],
    answers: [],
    users_ids: []
  };
  return report ? _objectSpread({}, defaultReport, {}, report) : defaultReport;
}

function reportsLibraryValidate(report) {
  return errorsLibrary.mapAjvErrors(reportsLibrary.validateSchema(report) ? [] : reportsLibrary.validateSchema.errors);
}

function reportsLibraryGetSectionNodes(report, sectionId) {
  return report.nodes.filter(function (node) {
    return node.sections_ids[0].toString() === sectionId.toString();
  });
}

function reportsLibraryGetNodeAnswers(report, nodeId) {
  return report.answers.filter(function (answer) {
    return answer.nodes_ids[0] === nodeId;
  });
}

function reportsLibraryGetQuestionAnswers(report, questionId) {
  return report.answers.filter(function (answer) {
    return answer.question_id === questionId;
  });
}

function reportsLibraryGetAnswerHash(answer) {
  return answer.values.reduce(function (hash, value) {
    return (hash ? "".concat(hash, ",") : '') + value.value;
  }, '');
}

function reportsLibraryGetEanAnswers(report, form) {
  var formQuestionsEanHash = form.contents.questions.filter(isQuestionEan).reduce(function (hash, question) {
    hash[question._id] = question;
    return hash;
  }, {});
  return report.contents.answers.filter(function (answer) {
    return formQuestionsEanHash[answer.question_id];
  });
}
/*
 * Compute total price and number of products sold by brand
 */


function reportsLibraryGetSalesResultsByBrand(eanAnswers, products, questions) {
  var OTHER_TAG = 'OTHER';
  var salesByBrandHash = eanAnswers.reduce(function (salesResult, answer) {
    var question = getEanQuestionById(questions, answer.question_id);
    var product = getAnswerProduct(answer, question, products);
    var lastPrice = getPriceWithDiscount(product, answer, question);
    var brandName = product.contents.brand || OTHER_TAG;

    if (salesResult[brandName]) {
      salesResult[brandName].price += lastPrice;
      salesResult[brandName].productsNumber += 1;
    } else {
      salesResult[brandName] = {
        brand: brandName,
        price: lastPrice,
        productsNumber: 1
      };
    }

    return salesResult;
  }, {});
  return Object.keys(salesByBrandHash).map(function (brand) {
    return salesByBrandHash[brand];
  });
} //
// ###### Compute score for a given question and an answer
//
// It takes 3 parameters
//   - questionId - the question's id as string
//   - form - the form containing the question
//   - answer - an answer for the question
//
// It should return ```null``` if the question can't be found
// It also should return ```null``` if the scoring functionality
// was not configured on this particular question
//
// Finally it should return the question's score for that answer
// or 0 if that particular answer is not covered.
//


function computeScoreForAnswer(questionId, form, answer) {
  var question = getQuestion(questionId, form);
  var answerValue = getAnswerValue(answer);

  if (!question) {
    return null;
  }

  var scoringField = getScoringField(question);

  if (!scoringField) {
    return null;
  }

  if (answerValue === null) {
    return 0;
  } //
  // Here we find an answer matching one condition of the 'scoreMap'
  //


  var scoreValueEntry = scoringField.scoreMap.filter(function (scoreEntry) {
    return (// We want cross types equality
      "".concat(answerValue) === scoreEntry.condition
    );
  })[0];
  return scoreValueEntry ? getScoreValue(scoreValueEntry.value) : 0;
}

function getScoreValue(scoreValue) {
  var parsedValue = parseFloat(scoreValue);
  return isNaN(parsedValue) ? scoreValue : parsedValue;
} //
// ###### Compute score for a given question...
//  ... taking all answers into account
//
// It takes 3 parameters
//   - questionId - the question's id as string
//   - form - the form containing the question
//   - answer - an answer for the question
//
// It should return ```null``` if the question can't be found
// It also should return ```null``` if the scoring functionality
// was not configured on this particular question
//
// Finally it should return an object representing the score for this particular question
// This object must have the following properties
//   - for_id - the id of the question
//   - for_type - the type of score (question / section)
//   - percent - the score value in percent
//   - numerator - numerator of the score ratio
//   - denominator - denominator of the score ratio
//


function computeScoreForQuestion(questionId, form, report) {
  var question = getQuestion(questionId, form);
  var answers = getAnswers(questionId, report);
  var maxValue = 0;

  if (!question) {
    return null;
  }

  var scoringField = getScoringField(question);

  if (!scoringField) {
    return null;
  }

  if (!answers || !answers.length) {
    return {
      for_id: questionId,
      for_type: 'question',
      numerator: 0,
      denominator: maxValue,
      percent: 0
    };
  }

  maxValue = getMaximumPossibleScore(scoringField, getMaximumFromFormula(question.maximum) > 1); //
  // Here we compute the sum of all score value for all answers for this question
  //

  var computedNumerator = answers.reduce(function (accu, answer) {
    if (accu === null) {
      return null;
    }

    var scoreValue = computeScoreForAnswer(questionId, form, answer);

    if (scoreValue === null) {
      return null;
    }

    accu += scoreValue;
    return accu;
  }, 0);
  return computedNumerator !== null ? {
    for_id: questionId,
    for_type: 'question',
    numerator: computedNumerator,
    denominator: maxValue,
    percent: maxValue ? computedNumerator / maxValue : 0
  } : null;
}

function computeScoreForSection(sectionId, form, report) {
  var section = getSection(sectionId, form);
  var childrenSectionsIds = getAllSectionIds(section, form);
  var questionIds = getQuestionIdsForSections(childrenSectionsIds, form);
  var score = addUpQuestionScores(questionIds, form, report);
  return score !== null ? {
    for_id: sectionId,
    for_type: 'section',
    numerator: score.numerator > 0 ? score.numerator : 0,
    denominator: score.denominator,
    percent: score.denominator ? score.numerator / score.denominator : 0
  } : null;
}

function computeScore(form, report) {
  var topLevelSectionIds = getTopLevelSectionIds(form);
  var score = topLevelSectionIds.map(function (id) {
    return computeScoreForSection(id, form, report);
  }).reduce(function (totalScore, sectionScore) {
    if (totalScore === null || sectionScore === null) {
      return totalScore || sectionScore;
    }

    return {
      numerator: sectionScore.numerator + totalScore.numerator,
      denominator: sectionScore.denominator + totalScore.denominator
    };
  }, null);
  return score ? {
    for_id: report._id,
    for_type: 'report',
    numerator: score.numerator > 0 ? score.numerator : 0,
    denominator: score.denominator,
    percent: score.denominator ? score.numerator / score.denominator : 0
  } : null;
}

function computeTotalForProducts(answersEan, products, questions) {
  return answersEan.reduce(function (total, answer) {
    var question = getEanQuestionById(questions, answer.question_id);
    var product = getAnswerProduct(answer, question, products);
    var lastPrice = getPriceWithDiscount(product, answer, question);
    return total + lastPrice;
  }, 0);
}

function addUpQuestionScores(questionIds, form, report) {
  var questionScores = questionIds.reduce(function (score, questionId) {
    var scoreForQuestion = computeScoreForQuestion(questionId, form, report);

    if (scoreForQuestion === null) {
      return score;
    }

    return score === null ? {
      numerator: scoreForQuestion.numerator,
      denominator: scoreForQuestion.denominator
    } : {
      numerator: score.numerator + scoreForQuestion.numerator,
      denominator: score.denominator + scoreForQuestion.denominator
    };
  }, null);

  if (questionScores && questionScores.numerator < 0) {
    questionScores.numerator = 0;
  }

  return questionScores;
}

function getAllSectionIds(section, form) {
  var contents = form ? form.sections ? form : form.contents ? form.contents : null : null;

  if (!contents || !section) {
    return [];
  }

  var childSections = contents.sections.reduce(function (stateMachine, thisSection) {
    if (stateMachine.stop === true) {
      return stateMachine;
    }

    if (thisSection._id === section._id) {
      stateMachine.start = true;
      return stateMachine;
    }

    if (stateMachine.start !== true) {
      return stateMachine;
    }

    if (thisSection.level <= section.level) {
      stateMachine.stop = true;
      return stateMachine;
    }

    stateMachine.ids.push(thisSection._id);
    return stateMachine;
  }, {
    start: false,
    stop: false,
    ids: []
  });
  return [section._id].concat(childSections.ids);
}

function getQuestionIdsForSections(sectionIds, form) {
  var contents = form ? form.questions ? form : form.contents ? form.contents : null : null;

  if (!contents) {
    return [];
  }

  return contents.questions.filter(function (question) {
    return sectionIds.indexOf(question.section_id) !== -1;
  }).map(function (question) {
    return question._id;
  });
}

function getQuestion(qId, form) {
  var contents = form ? form.questions ? form : form.contents ? form.contents : null : null;

  if (!contents) {
    return null;
  }

  return contents ? (contents.questions || []).filter(function (question) {
    return qId && question._id && qId === question._id;
  })[0] : null;
}

function getSection(sId, form) {
  var contents = form ? form.sections ? form : form.contents ? form.contents : null : null;

  if (!contents) {
    return null;
  }

  return contents ? (contents.sections || []).filter(function (section) {
    return sId && section._id && sId === section._id;
  })[0] : null;
}

function getTopLevelSectionIds(form) {
  return form ? (form.contents || form).sections.filter(function (s) {
    return s.level === 0;
  }).map(function (s) {
    return s._id;
  }) : [];
}

function getAnswerValue(answer) {
  return answer ? answer.values ? answer.values[0] ? answer.values[0].value : null : null : null;
}

function getAnswers(qId, report) {
  var contents = report ? report.answers ? report : report.contents ? report.contents : null : null;

  if (!contents) {
    return [];
  }

  return contents ? (contents.answers || []).filter(function (answer) {
    return qId && answer.question_id && qId === answer.question_id;
  }) : [];
}

function getScoringField(question) {
  return question.fields && question.fields.length ? question.fields.filter(function (field) {
    return field.type === 'score' && field.scoreMap;
  })[0] : null;
}

function getMaximumPossibleScore(scoringField, addAll) {
  var values = scoringField.scoreMap.filter(function (s) {
    return s.value !== null;
  }).filter(function (s) {
    return s.value >= 0;
  }).map(function (s) {
    return parseFloat(s.value);
  });
  return !addAll ? Math.max.apply(null, values.length ? values : [0]) : values.reduce(function (sum, val) {
    return sum + val;
  }, 0);
}

function getMaximumFromFormula(formula) {
  // Simple case, a number or Infinity, no formula
  return formula === 'Infinity' || formula > 1 ? formula : // A number inside a formula
  /^.*\?\s*([0-9]+)\s*:\s*[0-9]+\s*$/m.test(formula) ? parseInt(formula.replace(/^[^]*\?\s*([0-9]+)\s*:\s*[0-9]+\s*$/m, '$1'), 10) : // The Infinity inside a formula
  /^.*\?\s*(Infinity)\s*:\s*[0-9]+\s*$/m.test(formula) ? 'Infinity' : // Default to 1
  1;
}

function getValueByFieldId(values, field) {
  if (!field) {
    return null;
  }

  var fieldValue = values.filter(function (value) {
    return field._id === value.field_id;
  })[0] || {};
  return fieldValue.value;
}

function getFieldByType(fields, type) {
  return fields.filter(function (field) {
    return type === field.type;
  })[0];
}

function getAnswerProduct(answer, question, products) {
  var eanField = getFieldByType(question.fields, 'ean');
  var eanFieldValue = getValueByFieldId(answer.values, eanField);
  return products.filter(function (product) {
    return product.contents.ean === eanFieldValue;
  })[0];
}

function getDiscountValue(answer, question) {
  var discountField = getFieldByType(question.fields, 'number');
  return getValueByFieldId(answer.values, discountField);
}

function getPriceWithDiscount(product, answer, question) {
  var price = product && product.contents.price ? product.contents.price.value : 0;
  var discountValue = getDiscountValue(answer, question);
  return price && discountValue ? (100 - discountValue) * price / 100 : price;
}

function getEanQuestionById(questions, id) {
  return questions.filter(isQuestionEan).filter(function (question) {
    return question._id === id;
  })[0];
}