import { APIStoreContents } from '@places/';
import { APIPlacesList } from '@services/API/placesLists/placesLists';
import { APIUsersGroup } from '@services/API/users/users';
import { AccessRightsService } from '@services/Utils/AccessRights/access-rights.service';
import { ModalService } from '@services/Utils/Modal';
import { Tab } from '@simplifield/webcomponents/dist/types/services/tabs';
import { TaskService } from '@tasks/services/task.service';
import { StateParams, StateService } from '@uirouter/angularjs';
import moment from 'moment';
import {
  assoc,
  findIndex,
  groupBy,
  has,
  isNil,
  propEq,
  remove,
  update,
} from 'ramda';
import { APITask, ApiTaskMeta } from '../..';
import { GroupTasksService } from '../../../group-tasks/services/group-tasks.service';
import { ApiGroupTaskMeta } from '../../../group-tasks/types';
import { EntityType, ObjectId, User, UserRef } from '../../../index';
import { TASK_PRIORITIES } from '../../task-manage/services/task-manage.service';

type StoreItemMapped = {
  store: APIStoreContents;
  task: APITask;
  user?: { contents: UserRef; _id: string };
  entityType: EntityType;
};

type GroupTaskDetailTab = Tab & {
  translateKey: string;
  assigned: StoreItemMapped[];
  unassigned: StoreItemMapped[];
};

const TABS: GroupTaskDetailTab[] = [
  {
    label: 'Pending',
    translateKey: 'TASK_GROUP_DETAIL_TAB_PENDING',
    active: true,
    assigned: [],
    unassigned: [],
  },
  {
    label: 'Completed',
    translateKey: 'TASK_GROUP_DETAIL_TAB_COMPLETED',
    active: false,
    assigned: [],
    unassigned: [],
  },
];

type TranslationsKeysAssignedUnassigned = {
  assigned: string;
  unassigned: string;
};

export class GroupTaskDetailController implements ng.IComponentController {
  // bindings
  profile: User;

  // attributes
  // !hide SEND REMINDER BUTTON until IT2-1691 will be done
  displaySendReminderButton = false;

  isAdmin = false;
  isAuthor = false;
  isLoading = true;

  groupTaskId: ObjectId;
  groupStoresLabel: string;
  owner: { contents: UserRef; _id: string };
  priority: typeof TASK_PRIORITIES[0];

  apiGroupTaskMeta: ApiGroupTaskMeta;

  tabs: GroupTaskDetailTab[];
  groupedTasks: Partial<Record<'completed' | 'pending', APITask[]>>;

  translationsAssignedUnassigned: TranslationsKeysAssignedUnassigned;

  onTabClick: (index: number) => void;

  constructor(
    private $scope: ng.IScope,
    private readonly $stateParams: StateParams,
    private modalService: ModalService,
    private groupTasksService: GroupTasksService,
    private $state: StateService,
    private accessRightsService: AccessRightsService,
    private placesListsService,
    private usersService,
    private $translate: ng.translate.ITranslateService,
    private taskService: TaskService
  ) {
    'ngInject';

    this.groupTaskId = this.$stateParams.taskId as ObjectId;
    this.onTabClick = this.onTabStatusClick.bind(this);
  }

  $onInit(): void {
    this.groupTasksService.getGroupTasksById(this.groupTaskId).then((data) => {
      this.apiGroupTaskMeta = data;
      this.initGroupTask();
      this.initGroupStoresLabel();
      this.initGroupedTasks();
      this.initTabs();

      if (
        this.apiGroupTaskMeta.contents.progression?.total >
        this.apiGroupTaskMeta.entries.length
      ) {
        this.computeLocalProgression();
      }
      this.isLoading = false;
    });
  }

  private activateTabByIndex(index: number): void {
    this.tabs = this.tabs.map((tab, i) => ({
      ...tab,
      active: i === index,
    }));
  }

  private initGroupStoresLabel(): void {
    const groupId = this.apiGroupTaskMeta.contents.entityGroups[0];

    if (groupId) {
      this.getEntityGroupProvider(
        this.apiGroupTaskMeta.contents.entityType,
        groupId
      ).then((group: APIPlacesList | APIUsersGroup) => {
        this.groupStoresLabel = this.getGroupStoresLabel(group);

        this.$scope.$apply();
      });
    }
  }

  private getEntityGroupProvider(entityType: EntityType, groupId: string) {
    return entityType === 'places'
      ? this.placesListsService.get(groupId)
      : this.usersService.getUsersGroup(groupId);
  }

  getProgressionLabel() {
    return this.apiGroupTaskMeta.contents.entityType === 'places'
      ? 'TASK_GROUP_DETAIL_PROGRESSION_STORES_LABEL'
      : 'TASK_GROUP_DETAIL_PROGRESSION_USERS_LABEL';
  }

  private initGroupTask(): void {
    this.priority =
      TASK_PRIORITIES.find(
        (p) => this.apiGroupTaskMeta.contents?.template?.priority === p.value
      ) || TASK_PRIORITIES[0];

    this.owner = {
      _id: this.apiGroupTaskMeta.contents.owner_id,
      contents:
        this.apiGroupTaskMeta.users[this.apiGroupTaskMeta.contents.owner_id],
    };

    this.isAdmin = this.accessRightsService.isAdmin();
    this.isAuthor =
      this.apiGroupTaskMeta.contents.owner_id === this.profile._id;

    this.translationsAssignedUnassigned = {
      assigned: 'TASK_GROUP_DETAIL_ASSIGNED',
      unassigned: 'TASK_GROUP_DETAIL_UNASSIGNED',
    };
  }

  private initGroupedTasks(): void {
    const groupByStatus = groupBy((task: APITask) => {
      return task.contents.status === 'todo' ? 'pending' : 'completed';
    });
    this.groupedTasks = groupByStatus(this.apiGroupTaskMeta.entries);
  }

  private initTabs(): void {
    this.tabs = TABS.map((tab) => {
      const key = tab.label.toLowerCase();
      const list: APITask[] = this.groupedTasks[key] || [];

      const assigned: StoreItemMapped[] = [];
      const unassigned: StoreItemMapped[] = [];

      list.forEach((task) => {
        const store =
          this.apiGroupTaskMeta?.places[task.contents.place_id || ''];
        const entityType = this.apiGroupTaskMeta.contents.entityType;
        if (task.contents.assignee_id) {
          const user = {
            contents: this.apiGroupTaskMeta.users[task.contents.assignee_id],
            _id: task.contents.assignee_id,
          };

          assigned.push({ store, task, user, entityType });
        } else {
          unassigned.push({ store, task, entityType });
        }
      });

      const label = this.$translate.instant(tab.translateKey);

      return {
        ...tab,
        assigned,
        label: `${label} (${list.length})`,
        unassigned,
      };
    });
  }

  private onTabStatusClick(index: number): void {
    this.activateTabByIndex(index);

    this.$scope.$apply();
  }

  private getGroupStoresLabel(group: APIPlacesList | APIUsersGroup): string {
    const entitiesIds = this.apiGroupTaskMeta.contents.entityGroups;
    return `${group?.contents.name || ''}${
      entitiesIds.length > 1 ? ` (+${entitiesIds.length - 1})` : ''
    }`;
  }

  get dueToDate(): string {
    if (!this.apiGroupTaskMeta.contents) return '';

    return moment(this.apiGroupTaskMeta.contents.template.due_date).format(
      'MMM. DD, YYYY'
    );
  }

  get dueToTime(): string {
    if (isNil(this.apiGroupTaskMeta.contents?.template?.due_time)) return '';

    return moment
      .utc(this.apiGroupTaskMeta.contents.template.due_time)
      .format('hh:mm A');
  }

  get percentage(): number {
    if (!this.apiGroupTaskMeta.contents?.progression) return 0;

    const { done, total } = this.apiGroupTaskMeta.contents.progression || {
      done: 0,
      total: 0,
    };
    const percent = (100 * done) / total;

    return total ? Math.round(percent) : 0;
  }

  private computeLocalProgression(): void {
    this.apiGroupTaskMeta.contents.progression = {
      done: this.groupedTasks.completed?.length || 0,
      total: this.apiGroupTaskMeta.entries.length || 0,
    };
  }

  get showDeleteButton() {
    return this.isAdmin || this.isAuthor;
  }

  noTasks(tab): boolean {
    return !tab.assigned?.length && !tab.unassigned?.length;
  }

  onDelete(): void {
    this.taskService.showDeleteAgreeModal().then(() => {
      this.groupTasksService.delete(this.groupTaskId).then(() => {
        this.$state.go('index.menu-more.tasks.list');
      });
    });
  }

  onSendReminder(): void {
    console.log('clicked on send reminder');
  }

  openGroupsStoreModal(): void {
    const groupsIds = this.apiGroupTaskMeta.contents.entityGroups;
    const template = `
      <sf-group-entity-list-modal
        on-close="$ctrl.onClose()"
        groups-ids="$ctrl.groupsIds"
        entities="$ctrl.entities"
        entity-type="$ctrl.entityType"
      ></sf-group-entity-list-modal>
    `;
    const entityType = this.apiGroupTaskMeta.contents.entityType;
    const binding = {
      groupsIds,
      entityType,
      entities: this.apiGroupTaskMeta[entityType],
    };

    this.modalService.open(template, binding);
  }

  onTaskUpdate(updatedTask: ApiTaskMeta): void {
    this.updateTasksList(updatedTask);
    this.updateUsersList(updatedTask.assignee);

    this.initGroupedTasks();
    this.initTabs();
    this.computeLocalProgression();
  }

  private updateTasksList(updatedTask: APITask): void {
    if (updatedTask._id) {
      const findTaskIndexById = findIndex(propEq(updatedTask._id, '_id'));
      const updatedTaskIndex = findTaskIndexById(this.apiGroupTaskMeta.entries);
      this.apiGroupTaskMeta.entries = update(
        updatedTaskIndex,
        updatedTask,
        this.apiGroupTaskMeta.entries
      );
    }

    if (!updatedTask._id) {
      const findTaskIndexById = findIndex(
        propEq(this.taskService.lastActiveTaskId, '_id')
      );
      const updatedTaskIndex = findTaskIndexById(this.apiGroupTaskMeta.entries);
      this.apiGroupTaskMeta.entries = remove(
        updatedTaskIndex,
        1,
        this.apiGroupTaskMeta.entries
      );
    }
  }

  private updateUsersList(newUser: UserRef): void {
    const hasUser = has(newUser?._id);
    const doesUserAlreadyExists = hasUser(this.apiGroupTaskMeta.users);

    if (!doesUserAlreadyExists) {
      this.apiGroupTaskMeta.users = assoc(
        newUser?._id,
        newUser,
        this.apiGroupTaskMeta.users
      );
    }
  }
}
