const sfFormReportsValidationList = {
  bindings: {
    profile: '<',
    reloadFn: '=',
    state: '@',
    contentKey: '@',
  },
  controller: FormReportsValidationListController,
  templateUrl:
    'reports/' +
    'reports-validation/' +
    'components/' +
    'form-reports-validation-list/' +
    'form-reports-validation-list.html',
};
const NB_REPORT_TO_GET = 20;

// eslint-disable-next-line max-params
function FormReportsValidationListController(
  $q,
  $state,
  reportsValidationAPIService,
  helpersService,
  RequestsPaginate
) {
  'ngInject';

  const params = {
    limit: NB_REPORT_TO_GET,
    relatedEntitiesKeys: ['forms', 'users', 'places'],
  };

  this.requestPaginateHash = {
    torevise: reportsValidationAPIService.getReportsToRevise,
    pending: reportsValidationAPIService.getPendingValidationReports,
    validated: reportsValidationAPIService.getValidatedReports,
  };

  this.$onInit = () => {
    this.requestsPaginate = new RequestsPaginate(
      this.requestPaginateHash[this.state],
      params
    );
    this.reloadFn = this.reload;
    this.reload();
    this.contentKey = `${this.state}Content`;
  };

  this.resetRequests = () => {
    this.infiniteLoadError = false;

    this.requestsPaginate.reset();
  };

  this.reload = () => {
    this.loading = true;
    this.networkError = false;

    this.resetRequests();

    return this.getReports()
      .catch(() => {
        this.networkError = true;
      })
      .finally(() => {
        this.loading = false;
      });
  };

  this.resetInfiniteLoadErrorState = () => {
    this.infiniteLoadError = false;
  };

  this.getReports = () => {
    return (this.requestsPaginate ? this.requestsPaginate.call() : $q.reject())
      .then((data) => ({
        entries: data.entities,
        ...data.relatedEntities,
      }))
      .then((apiData) =>
        reportsValidationAPIService.transformAPIData(apiData, false)
      )
      .then((reports) => {
        this.reportsListElements = helpersService.addDividerDays(
          reports,
          'saved_date',
          true
        );
      })
      .finally(() => {
        if (this.onScrollComplete) {
          this.onScrollComplete();
        }
      });
  };

  this.edit = (report) => {
    if (this.state !== 'torevise') {
      return;
    }
    $state.go('index.menu-more.missions.report', {
      campaignId: report.contents.form_id,
      reportId: report._id,
      referer: $state.current.name,
      online: true,
    });
  };

  this.getPlace = (report) => {
    if (!report.contents || !report.contents.place_id) {
      return null;
    }
    const places = this.requestsPaginate.getRelatedEntities('places');

    return places[report.contents.place_id];
  };

  // type - for example 'owner' or 'user'
  this.getUser = (report, type) => {
    if (!report.contents || !report.contents.user_id) {
      return null;
    }
    const users = this.requestsPaginate.getRelatedEntities('users');

    return users[report.contents[`${type}_id`]];
  };
}

export { sfFormReportsValidationList, FormReportsValidationListController };
