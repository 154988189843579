import { Comment, CommentList } from '../..';
import { PovService } from '../../../services/API/POV/pov.service';

export enum CommentsResourceType {
  ANSWERS = 'answers',
  REPORTS = 'reports',
  DRAFT_REPORTS = 'draft-reports',
  VALIDATION = 'validations',
  NEWSFEED = 'newsfeedPost',
  TASK = 'task',
  CHANGE_REQUESTS = 'changerequests',
}

export const PATH_MAP = new Map([
  [CommentsResourceType.ANSWERS, '/answers'],
  [CommentsResourceType.REPORTS, '/reports'],
  [CommentsResourceType.VALIDATION, '/validations'],
  [CommentsResourceType.NEWSFEED, '/newsfeed/posts'],
  [CommentsResourceType.TASK, '/tasks'],
  [CommentsResourceType.CHANGE_REQUESTS, '/change-requests'],
]);

export class CommentsApiService {
  private baseUrl: string;

  constructor(
    resourceType: CommentsResourceType,
    private $http: ng.IHttpService,
    private sfPOVService: PovService
  ) {
    'ngInject';

    this.baseUrl = PATH_MAP.get(resourceType) ?? '';
  }

  list(resourceId: string, params = {}): ng.IPromise<CommentList> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/${resourceId}/comments`, {
        pov: 'organisation',
      })
      .then((url) => this.$http.get<CommentList>(url, { params }))
      .then(({ data }) => data);
  }
  create(resourceId: string, comment: Comment): ng.IPromise<Comment> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/${resourceId}/comments`, {
        pov: 'organisation',
      })
      .then((url) => this.$http.post<Comment>(url, comment))
      .then(({ data }) => data);
  }
  edit(resourceId: string, comment: Comment): ng.IPromise<Comment> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/${resourceId}/comments/${comment._id}`, {
        pov: 'organisation',
      })
      .then((url) => this.$http.patch<Comment>(url, comment))
      .then(({ data }) => data);
  }
  delete(resourceId: string, commentId: string): ng.IPromise<Comment> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/${resourceId}/comments/${commentId}`, {
        pov: 'organisation',
      })
      .then((url) => this.$http.delete<Comment>(url))
      .then((response) => response.data);
  }
}

export type CommentsApiFactory = (
  resourceType: CommentsResourceType
) => CommentsApiService;
// @ngInject
export const CommentsApiFactory =
  ($http: ng.IHttpService, sfPOVService: PovService) =>
  (resourceType: CommentsResourceType): CommentsApiService =>
    new CommentsApiService(resourceType, $http, sfPOVService);
