import { PlaceTasksTabsController } from './place-tasks-tabs.controller';

export const PlaceTasksTabsComponent: ng.IComponentOptions = {
  bindings: {
    place: '<',
    profile: '<',
  },
  templateUrl:
    'places/place/components/place-tasks/place-tasks-tabs/place-tasks-tabs.html',
  controller: PlaceTasksTabsController,
};
