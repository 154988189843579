import { Comment } from '../../comments';
import { PICTURE_SYSTEM_IDENTIFIERS } from '../../constants/picture-system.constants';
import { CameraService } from '../../services/Utils/Camera/camera.service';
import { FileChooserService } from '../../services/Utils/FileChooser/file-chooser.service';
import { FileSystemService } from '../../services/Utils/FileSystem/file-system.service';
import {
  ImgSourceIdentifier,
  PictureSystemService,
} from '../../services/Utils/PictureSystem/picture-system.service';
import { PlatformService } from '../../services/Utils/Platform';
import { PopupService } from '../../services/Utils/Popup/popup.service';

export class FooterTextInputController {
  // Bindings
  comment: Comment;
  placeholder: string;
  isButtonDisabled: boolean;
  fileSourceOption = { image: false, file: false };
  hasCameraButton: boolean;
  keyboardModeType: string;
  maxChar: number;
  closeKeyboardFn: () => void;
  onSubmit: () => void;
  onCancel: () => void;
  onMediaSelect: (blob: { blob: Blob }) => void;
  onKeyboardClose: () => void;
  onKeyboardShow: () => void;
  onBlur: () => void;

  private isBrowser: boolean;
  private listeners: (() => void)[];
  hasFileButton = false;

  /* @ngInject */
  constructor(
    private keyboardService,
    private platformService: PlatformService,
    private pictureSystemService: PictureSystemService,
    private cameraService: CameraService,
    private fileChooserService: FileChooserService,
    private filesSystemService: FileSystemService,
    private popupService: PopupService,
    private $translate: ng.translate.ITranslateService
  ) {}

  $onInit(): void {
    const onKeyboardHideCallback = () =>
      this.onKeyboardClose ? this.onKeyboardClose() : null;
    const onKeyboardShowCallback = () =>
      this.onKeyboardShow ? this.onKeyboardShow() : null;
    const isiOS = this.platformService.isiOS();

    this.isBrowser = this.platformService.isBrowser();
    this.hasFileButton = Boolean(
      this.fileSourceOption.image || this.fileSourceOption.file
    );
    this.closeKeyboardFn = () => this.closeKeyboard();

    this.listeners = [];
    this.listeners = [].concat(
      this.keyboardService.onKeyboardShowListener(onKeyboardShowCallback),
      this.keyboardService.onKeyboardHideListener(onKeyboardHideCallback)
    );

    if (!isiOS) {
      return;
    }

    this.keyboardService.registerStateMode(this.keyboardModeType);
  }

  onKeyDown(keyEvent: KeyboardEvent): void {
    if (keyEvent.key === 'Enter' && !keyEvent.shiftKey && this.isBrowser) {
      this.onSubmit();
    }
  }

  $onDestroy(): void {
    this.keyboardService.deRegisterStateMode();
    this.listeners = this.listeners.reduce((output, listener) => {
      listener();
      return output;
    }, []);
  }

  selectFile(): ng.IPromise<void> {
    this.closeKeyboard();

    if (this.isBrowser) {
      return this.fileChooserService
        .getImageOrDocument()
        .then((file) => this.filesSystemService.getBlobImageFromBrowser(file))
        .then((blob) => this.onDeviceFileSelect(blob));
    }

    return this.pictureSystemService
      .selectSourceType(
        (
          (this.fileSourceOption.image
            ? [
                PICTURE_SYSTEM_IDENTIFIERS.LIBRARY,
                PICTURE_SYSTEM_IDENTIFIERS.IMAGE,
              ]
            : []) as ImgSourceIdentifier[]
        ).concat(
          this.fileSourceOption.file ? [PICTURE_SYSTEM_IDENTIFIERS.FILE] : []
        ),
        { hasCancel: true, hideTitle: true }
      )
      .then((source) => this.pictureSystemService.getBlobFromSource(source))
      .then((blob) => this.onDeviceFileSelect(blob))
      .catch((e) => {
        if (e === 'The image file could not be opened.') {
          // specific image picker error
          this.popupService.showError({
            title: this.$translate.instant('IMPOSSIBLE_TO_PROCESS_FILE'),
          });
        } else {
          throw e;
        }
      });
  }

  takePicture(): ng.IPromise<void> {
    this.closeKeyboard();
    return this.cameraService
      .getPhotoAsFile()
      .then((blob) => this.onDeviceFileSelect(blob));
  }

  onDeviceFileSelect(blob: Blob): void {
    this.onMediaSelect({ blob });
  }

  closeKeyboard(): void {
    this.keyboardService.hide();
  }
}
