export const ScoringPrevStatsComponent = {
  bindings: {
    formStats: '<',
  },
  templateUrl:
    'missions/components/Scoring/scoring-prev-stats/scoring-prev-stats.html',
  controller: class ScoringPrevStatsController {
    getPercent(score) {
      return `${Math.round(score.percent * 100)}`;
    }
  },
};
