export const ReceiptLineProductComponent = {
  bindings: {
    thumbImageUrl: '<',
    fullImageUrl: '<',
    productName: '<',
    productEan: '<',
    catalogName: '<',
  },
  transclude: true,
  templateUrl:
    'missions/components/Receipt/receipt-line-product/receipt-line-product.html',
  controller: function ReceiptLineProductController(
    modalService,
    imageSourceService
  ) {
    'ngInject';

    this.viewPicture = () => {
      const template = `
      <sf-images-viewer
        images="[$ctrl.image]"
        on-close="$ctrl.onClose()">
        <div class="padding">
          <div class="sf_image_viewer__details">
            <p class="stable">{{ $ctrl.name }}</p>
            <p>{{ $ctrl.catalogName }}</p>
          </div>
        </div>
      </sf-images-viewer>
    `;
      const pictureViewerbindings = {
        image: imageSourceService.create({ url: this.fullImageUrl }, false),
        name: this.productName,
        catalogName: this.catalogName,
      };

      return (
        this.fullImageUrl && modalService.open(template, pictureViewerbindings)
      );
    };
  },
};
