import { SfFeatureFlags } from '@simplifield/feature-flags';
import {
  BUSINESS_FLAGS,
  FEATURE_FLAGS,
} from '../../../constants/feature-flags.constant';
import { UserProfileType } from '../../../index';
import { LocalizationService } from '../Localization/localization.service';
import { PlatformService } from '../Platform';

const ANNOUNCEKIT_WIDGET_URL = 'https://announcekit.app/widgets/v2/30fkLC';

type Widget = { close: () => void };
type UserSegmentData = {
  lang: string;
  user: {
    id: string;
  };
  data: {
    user_profile: UserProfileType | 'contributor';
    user_platform: string;
    organization_id: string;
    organization_name: string;
    'Module autoscheduling': string;
    'Module newsfeed': string;
    'Module chat': string;
    'Module product catalogs': string;
    'Module merchandising': string;
    'Module events validation': string;
    'Module Task': string;
    'Module schedule': string;
  };
};

export class MarketingService {
  private _promise: ng.IPromise<unknown>;
  private ANNOUNCEKIT_WIDGET_URL = ANNOUNCEKIT_WIDGET_URL;
  private removeBackBtnListener;
  private BACK_BTN_LISTENER_PRIORITY = 501; //https://ionicframework.com/docs/v1/api/service/$ionicPlatform/

  // eslint-disable-next-line max-params
  constructor(
    private $window: ng.IWindowService,
    private $document: ng.IDocumentService,
    private $q: ng.IQService,
    private platformService: PlatformService,
    private SF_BUSINESS_FLAGS: typeof BUSINESS_FLAGS,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private helpersService,
    private localizationService: LocalizationService,
    private organisationsService,
    private profileService,
    private sfFeatureFlagsService: SfFeatureFlags
  ) {
    'ngInject';
  }

  init(): ng.IPromise<unknown> {
    if (this._promise) {
      return this._promise;
    }
    const defer = this.$q.defer();

    this._promise = defer.promise;

    const onScriptLoad = () => {
      this.initAnnounceKit();
      defer.resolve();
    };
    const document = this.$document[0] as HTMLDocument;
    const script = document.createElement('script');

    script.onload = onScriptLoad;
    script.src = 'https://cdn.announcekit.app/widget-v2.js';
    document.body.appendChild(script);

    return this._promise;
  }

  initAnnounceKit() {
    this.$window.announcekit = this.$window.announcekit || {
      queue: [],
      on: (n, x) => {
        this.$window.announcekit.queue.push([n, x]);
      },
      push: (x) => {
        this.$window.announcekit.queue.push(x);
      },
    };
  }

  initProductChangelog(): ng.IPromise<unknown> {
    return this.$q
      .all([this.getUserSegmentData(), this.init()])
      .then(([userSegmentData]) => {
        this.$window.announcekit.push({
          widget: this.ANNOUNCEKIT_WIDGET_URL,
          selector: '.announcekit-widget',
          onInit: (_widget: Widget) => {
            this.$window.announcekit.on('widget-open', ({ widget }) => {
              if (widget === _widget) {
                this.onWidgetOpen(_widget);
              }
            });
          },
          ...userSegmentData,
        });
      });
  }

  formatFeatureFlags(featureFlags): Partial<UserSegmentData> {
    return featureFlags
      .filter(({ name }) => this.SF_BUSINESS_FLAGS[name])
      .reduce(
        (obj, { name, value }) => ({
          ...obj,
          [this.SF_BUSINESS_FLAGS[name]]: value,
        }),
        {}
      );
  }

  getUserSegmentData(): ng.IPromise<UserSegmentData> {
    return this.profileService
      .getProfile()
      .then((profile) =>
        this.$q.all([
          this.$q.resolve(profile),
          this.organisationsService.getProfileOrganisation(profile),
        ])
      )
      .then(([profile, organisation]) => {
        const formattedFlags = this.formatFeatureFlags(
          organisation.featureFlags
        );

        return {
          lang: this.localizationService.getPreferredLanguage(
            this.localizationService.getDeviceLang()
          ),
          user: {
            id: profile._id,
          },
          data: {
            user_profile: this.helpersService.profilesToString(
              profile.contents.profiles
            ),
            user_platform: 'mobile',
            organization_id: profile.contents.organisation_id,
            organization_name: organisation.contents.name,
            ...formattedFlags,
          },
        };
      });
  }

  onWidgetOpen(widget: Widget): void {
    const closeWidget = () => this.onBackBtnClick(widget);

    this.removeBackBtnListener = this.platformService.registerBackButtonAction(
      closeWidget,
      this.BACK_BTN_LISTENER_PRIORITY
    );
  }

  onBackBtnClick(widget: Widget): void {
    if (!this.removeBackBtnListener) {
      return;
    }
    this.removeBackBtnListener();
    widget.close();
  }

  hasFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.WHATS_NEW
    );
  }
}
