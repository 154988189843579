// eslint-disable-next-line max-params
export function DepartmentService(
  profileService,
  popupService,
  $translate,
  usersService,
  appMessageService,
  errorMessagesService,
  SF_DEPARTMENT_TYPES,
  sfFeatureFlagsService,
  SF_FEATURE_FLAGS
) {
  'ngInject';
  function hasDepartment(profile) {
    return !!(profile && profile.contents && profile.contents.department);
  }

  function getDepartmentList() {
    return Object.keys(SF_DEPARTMENT_TYPES)
      .map((departmentIdentifier) => ({
        label: $translate.instant(
          SF_DEPARTMENT_TYPES[departmentIdentifier].i18nKey
        ),
        value: SF_DEPARTMENT_TYPES[departmentIdentifier].keyword,
      }))
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        return -1;
      });
  }

  function saveDepartment(profile, initValues) {
    let user = {
      _id: profile._id,
      contents: {
        department: profile.contents.department,
      },
    };

    return usersService
      .update(user)
      .then(() => {
        const message = $translate.instant('DEPARTMENT_SAVED_SUCCESS');

        initValues.department = profile.contents.department;
        profileService.updateProfile(profile);
        appMessageService.display(message, 'success');
      })
      .catch((err) => {
        profile.contents.department = initValues.department;
        appMessageService.display(
          errorMessagesService.getMessage(err, {
            customUnknownErrorMessage: 'DEPARTMENT_SAVED_ERROR',
          }),
          'fail'
        );
      });
  }

  function showModalDepartment({ profile }) {
    const options = {
      title: 'POPUP_DEPARTMENT_HEADER',
      text: 'POPUP_DEPARTMENT_BODY_TEXT',
      selectLabel: 'DEPARTMENT',
      selectPlaceholder: 'SELECT_DEPARTMENT_PLACEHOLDER',
      okButton: 'POPUP_DEPARTMENT_ACCEPT_BTN',
      value: profile.contents.department,
      listOfValues: getDepartmentList(),
    };

    const initValues = {
      department: profile.contents.department,
    };

    return popupService.showSelect(options).selectPromise.then((res) => {
      profile.contents.department = res;
      return saveDepartment(profile, initValues);
    });
  }

  function hasFeatureFlag() {
    return sfFeatureFlagsService.hasFeature(SF_FEATURE_FLAGS.USER_DEPARTMENT);
  }

  return {
    hasDepartment,
    showModalDepartment,
    saveDepartment,
    getDepartmentList,
    hasFeatureFlag,
  };
}
