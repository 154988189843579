import { ExternalDashboardController } from './external-dashboard.controller';

export const ExternalDashboardComponent: ng.IComponentOptions = {
  bindings: {
    filterable: '<',
    defaultFilters: '<?',
    view: '@',
  },
  templateUrl:
    'analytics/components/external-dashboard/external-dashboard.html',
  controller: ExternalDashboardController,
};
