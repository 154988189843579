const ALLOWED_PROTOCOLS = ['https:', 'http:'];

export const InAppBrowserOpenerComponent = {
  transclude: true,
  templateUrl: 'components/in-app-browser-opener/in-app-browser-opener.html',
  controller: function InAppBrowserOpenerController(
    $element,
    $timeout,
    inAppBrowserService
  ) {
    'ngInject';

    this.$postLink = () => {
      $timeout(() => {
        $element.find('a').bind('click', this.onLinkClick);
      }, 0);
    };

    this.onLinkClick = (event) => {
      const isSupportedLink = (e) =>
        e.toElement && ALLOWED_PROTOCOLS.includes(e.toElement.protocol);
      const isSupportedEmbedLink = (e) =>
        e.toElement &&
        e.toElement.innerText &&
        ALLOWED_PROTOCOLS.some((protocol) =>
          event.toElement.innerText.includes(protocol)
        );

      if (event && (isSupportedLink(event) || isSupportedEmbedLink(event))) {
        inAppBrowserService.open(
          event.currentTarget.href || event.toElement.innerText
        );
      }
      event.preventDefault();
    };
  },
};
