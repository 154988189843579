import { SentryService } from '../../services/Utils/Sentry/sentry.service';
import { Severity } from '@sentry/types';
import type { PopupService } from '../../services/Utils/Popup/popup.service';
import { AppMessageService } from '../../services/Utils/AppMessage/app-message.service';

export class DiagnosisController implements ng.IComponentController {
  popupConfig: { title: string; buttonText: string; iconName: string };
  localStorageValues: { key: string; display: string }[];
  isSoftResetInProgress = false;
  isHardResetInProgress = false;
  isSynchronizationInProgress = false;

  // eslint-disable-next-line max-params
  constructor(
    private $translate: ng.translate.ITranslateService,
    private localStorageService,
    private synchronizeService,
    private authService,
    private popupService: PopupService,
    private appMessageService: AppMessageService,
    private sentryService: SentryService,
    private dataStoreService
  ) {
    'ngInject';

    this.popupConfig = {
      title: this.$translate.instant('DIAGNOSIS_RESET_CONFIRM_TITLE'),
      buttonText: this.$translate.instant('DIAGNOSIS_RESET_CONFIRM_BUTTON'),
      iconName: 'item-danger',
    };
  }

  $onInit() {
    this.localStorageValues = this.listLocalStorage();
  }

  listLocalStorage() {
    return this.localStorageService.keys().map((key) => ({
      key,
      value: this.localStorageService.get(key).toString(),
    }));
  }

  resetLocalDatabase(): ng.IPromise<void> {
    return this.popupService
      .showConfirm(this.popupConfig)
      .then(() => {
        this.isSoftResetInProgress = true;

        return this.dataStoreService
          .resetLocalDatabase(['profile', 'reports'])
          .then(() => {
            this.isSoftResetInProgress = false;
            this.isSynchronizationInProgress = true;

            return this.synchronizeService.synchronize();
          })
          .then(() => this.successCallback())
          .catch(() => this.errorCallback())
          .finally(() => {
            this.isSoftResetInProgress = false;
            this.isSynchronizationInProgress = false;
          });
      })
      .catch(() => null);
  }

  resetHardLocalDatabase(): ng.IPromise<void> {
    return this.popupService
      .showConfirm(this.popupConfig)
      .then(() => {
        this.isHardResetInProgress = true;

        return this.dataStoreService
          .resetLocalDatabase(['profile'])
          .then(() => this.authService.logout())
          .catch(() => this.errorCallback())
          .finally(() => {
            this.isHardResetInProgress = false;
          });
      })
      .catch(() => null);
  }

  sendLocalStorage(): void {
    this.sentryService.captureMessage('DIAGNOSIS', {
      level: Severity.Info,
      extra: {
        localStorage: this.localStorageValues,
      },
    });

    this.successCallback();
  }

  successCallback(): void {
    this.appMessageService.display(
      this.$translate.instant('DIAGNOSIS_ACTION_SUCCESS'),
      'success'
    );
  }

  errorCallback(): void {
    this.appMessageService.display(
      this.$translate.instant('DIAGNOSIS_ACTION_ERROR'),
      'fail'
    );
  }
}
