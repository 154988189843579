export function resolvePromise(dashboardComponent, requestId, data) {
    if (dashboardComponent.promises[requestId]) {
        dashboardComponent.promises[requestId].resolve(data);
        delete dashboardComponent.promises[requestId];
    }
}
export function rejectPromise(dashboardComponent, requestId, data) {
    if (dashboardComponent.promises[requestId]) {
        dashboardComponent.promises[requestId].reject(data);
        delete dashboardComponent.promises[requestId];
    }
}
