import { PositionOptions, Position, Geolocation } from '@capacitor/geolocation';
import { PlaceAddress } from '../../../places';
import { PopupService } from '../Popup/popup.service';

const GEOLOC_TIMEOUT_POSITION = 8000;
const GEOLOC_CURRENT_POSITION_CACHE = 3 * 60 * 1000;
const NO_RESULTS_STATUS = 'ZERO_RESULTS';
const DENIED_STATUS = 'REQUEST_DENIED';

type GoogleGeocodeAddressResponse = {
  street: string;
  zipcode: string;
  city: string;
  administrative_area: string;
  country: string;
  address_components: { types: string[]; long_name: string }[];
  geometry: { location: { lat: number; lng: number } };
  formatted_address: string;
};
type GoogleGeocodeResponse = {
  status: string;
  results: GoogleGeocodeAddressResponse[];
};
type GoogleGeocodeParams = {
  latlng: string;
  key: string;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export type AddressCheckResult = {
  match?: boolean;
  unavailable?: boolean;
  readable?: string;
  data?: PlaceAddress;
};

const GOOGLE_SUGGEST_READABLE = [
  'street',
  'zipcode',
  'city',
  'administrative_area',
  'country',
];

// eslint-disable-next-line max-params
export class GeolocationService {
  Geolocation = Geolocation;
  currentPositionPromise: ng.IPromise<Position> | undefined;
  placeAroundPromise: LatLng | undefined;
  lastLatLng = null;

  // eslint-disable-next-line max-params
  constructor(
    private $http: ng.IHttpService,
    private $q: ng.IQService,
    private $log: ng.ILogService,
    private $translate: ng.translate.ITranslateService,
    private ConfigServer: Record<string, unknown>,
    private GOOGLE_GEOCODE_URL: string,
    private helpersService,
    private localStorageService: ng.local.storage.ILocalStorageService,
    private popupService: PopupService,
    private SF_GEOLOCATION_PROPERTIES: Record<string, unknown>
  ) {
    'ngInject';
  }

  displayLocationPopupIfNeeded(): ng.IPromise<void> {
    if (this.localStorageService.get('location_gathering_popup_shown')) {
      return this.$q.resolve();
    }

    return this.popupService
      .alert(
        this.$translate.instant('GEOLOCATION_NOTIFICATION_MESSAGE_TITLE'),
        this.$translate.instant('GEOLOCATION_NOTIFICATION_MESSAGE')
      )
      .then(() => {
        this.localStorageService.set('location_gathering_popup_shown', true);
      })
      .catch(() => {});
  }

  /*
   * NOTE: We need to keep a cache of getCurrentPosition promise
   * because Android does not support concurrent calls to getCurrentPosition
   * while permissions are not granted, causing promise to fails unexpectedly
   */

  getCurrentPosition(): ng.IPromise<LatLng | null> {
    const params: PositionOptions = {
      timeout: GEOLOC_TIMEOUT_POSITION,
      maximumAge: GEOLOC_CURRENT_POSITION_CACHE,
      enableHighAccuracy: true,
    };

    if (!this.currentPositionPromise) {
      this.currentPositionPromise = this.$q.when(
        this.$q.resolve(this.Geolocation.getCurrentPosition(params))
      );
    }

    return this.currentPositionPromise
      .then(({ coords: { longitude, latitude } }) => ({
        lat: latitude,
        lng: longitude,
      }))
      .catch((err) => {
        this.$log.info(err);
        return null;
      })
      .finally(() => {
        this.currentPositionPromise = undefined;
      });
  }

  getAddressFromCoordinates(
    coords: LatLng
  ): ng.IPromise<GoogleGeocodeResponse> {
    const params: GoogleGeocodeParams = {
      latlng: [coords.lat, coords.lng].join(','),
      key: this.ConfigServer.GOOGLE_MAP_API_KEY as string,
    };

    return this.$http
      .get<GoogleGeocodeResponse>(this.GOOGLE_GEOCODE_URL, { params })
      .then(({ data }) => {
        if (data.status === DENIED_STATUS) {
          return this.$q.reject();
        }
        return data;
      });
  }

  googleCheck(address: string): ng.IPromise<AddressCheckResult> {
    const params = {
      address,
      key: this.ConfigServer.GOOGLE_MAP_API_KEY as string,
    };

    return this.$http
      .get<GoogleGeocodeResponse>(this.GOOGLE_GEOCODE_URL, { params })
      .then(({ data }) => {
        const { status, results } = data;

        if (status === NO_RESULTS_STATUS || !results.length) {
          return { match: false };
        }
        if (status === DENIED_STATUS) {
          return { unavailable: true };
        }

        const googleSuggestData = this.google2SimplifieldFormatter(results[0]);

        const googleSuggestReadable = GOOGLE_SUGGEST_READABLE.map(
          (key) => googleSuggestData[key]
        ).join(' ');

        return {
          match: this.helpersService.equalsIgnoringCase(
            address,
            googleSuggestReadable
          ),
          readable: googleSuggestReadable,
          data: googleSuggestData,
        };
      });
  }

  google2SimplifieldFormatter(
    googleResult: GoogleGeocodeAddressResponse
  ): PlaceAddress {
    const extract = (typeKey) => {
      const candidates = googleResult.address_components.filter((a_component) =>
        a_component.types.some((t) => typeKey === t)
      );

      return candidates.length && candidates[0].long_name;
    };
    const extractFromTypeKeys = (typeKeys) => {
      const candidates = typeKeys
        .filter((type) => extract(type))
        .map((type) => extract(type));

      return (candidates.length && candidates[0]) || '-';
    };

    const {
      location: { lat, lng },
    } = googleResult.geometry;

    return {
      formatted_address: googleResult?.formatted_address ?? '',
      street: [
        extractFromTypeKeys(this.SF_GEOLOCATION_PROPERTIES.STREET_NUMBER),
        extractFromTypeKeys(this.SF_GEOLOCATION_PROPERTIES.STREET),
      ].join(' '),
      zipcode: extractFromTypeKeys(this.SF_GEOLOCATION_PROPERTIES.ZIPCODE),
      city: extractFromTypeKeys(this.SF_GEOLOCATION_PROPERTIES.CITY),
      administrative_area: extractFromTypeKeys(
        this.SF_GEOLOCATION_PROPERTIES.ADMINISTRATIVE_AREA
      ),
      country: extractFromTypeKeys(this.SF_GEOLOCATION_PROPERTIES.COUNTRY),
      latLng: [lat, lng],
    };
  }
}
