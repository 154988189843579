export function StaticRequestPaginateService($q) {
  'ngInject';

  class StaticRequestPaginate {
    constructor(entities, itemsPerPage) {
      this.entities = entities;
      this.itemsPerPage = itemsPerPage;
      this.itemsLength = entities.length;
      this.displayedLength = 0;
    }

    canCallMore() {
      return this.itemsLength > this.displayedLength;
    }

    load() {
      this.displayedLength += this.itemsPerPage;

      return $q.resolve({
        entities: this.entities.slice(0, this.displayedLength),
      });
    }
  }

  return StaticRequestPaginate;
}
