export const ButtonLoaderComponent = {
  bindings: {
    state: '<',
    isDisabled: '<',
    btnClass: '@?',
    btnType: '@?',
  },
  transclude: true,
  templateUrl: 'components/Buttons/button-loader/button-loader.html',
};
