export const PlacesMapComponent = {
  bindings: {
    places: '<',
    filters: '<',
    filtersAvailable: '<',
    onClose: '&',
  },
  templateUrl:
    'places/places/components/places-map-modal/places-map-modal.html',
  controller: function PlacesMapModalController(
    localizationService,
    platformService,
    $state,
    $rootScope
  ) {
    'ngInject';

    this.isRTLNeeded = localizationService.shouldActivateRTL();

    const hardwareBackAction = platformService.registerBackButtonAction(() => {
      this.onClose();
      hardwareBackAction();
    }, 201);

    this.$onInit = () => {
      this.markers = this.places.map((place) => {
        const [lat, lng] = place.contents.latLng || [];

        return {
          id: place._id,
          position: { lat, lng },
          title: place.contents.name,
        };
      });
    };

    this.onMarkerClick = (marker) => {
      const placeSelected = marker
        ? this.places.filter((place) => marker.id === place._id)[0]
        : null;

      this.placeSelected = placeSelected;
    };

    this.onMapClick = () => {
      this.placeSelected = null;
    };

    this.close = () => {
      if (hardwareBackAction) {
        hardwareBackAction();
      }
      return this.onClose();
    };

    this.goToPlace = (place) => {
      this.close();
      return $state.go('index.places.details.main', {
        placeId: place._id,
      });
    };

    this.resetPosition = () => {
      $rootScope.$emit('RESET_GOOGLE_MAP_POSITION');
    };
  },
};
