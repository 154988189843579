import { pathOr } from 'ramda';

export const QuestionBarcodeComponent = {
  bindings: {
    question: '<',
    hasError: '<',
    questionOptions: '<',
    answers: '=',
    actionClickedFn: '=?',
    previousAnswers: '<',
    isDisabled: '<?',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-barcode/question-barcode.html',
  controller: function QuestionBarcodeController(FORM_QUESTION_ACTIONS) {
    'ngInject';
    this.$onInit = () => {
      this.error = null;
      this.value =
        pathOr('', [0, 'values', 0, 'value'], this.answers) ||
        pathOr('', [0, 'values', 0, 'value'], this.previousAnswers);
      this.actionClickedFn = this.actionClicked;
    };

    this.setDirty = () => {
      const form = this['form_barcode_' + this.question._id + '_input'];

      if (form) {
        form.$setDirty();
      }
    };

    this.getAnswer = () => this.answers[0];

    this.deleteAnswer = ($event, answer) => {
      $event.stopPropagation();
      this.answers = this.sfQuestionForm.removeAnswer(answer._id, this.answers);
      this.setDirty();

      return this.answers;
    };

    this.onBarcodeChange = (ean) => {
      this.setDirty();

      if (ean) {
        const values = this.getValues(ean);

        return this.sfQuestionForm.saveAnswer(values, this.answers);
      }
      if (this.answers.length) {
        return this.sfQuestionForm.removeAnswer(this.answers[0]._id);
      }
      return true;
    };

    this.saveAnswer = (ean) => {
      const values = this.getValues(ean);
      this.setDirty();

      if (ean) {
        this.answers = this.sfQuestionForm.saveAnswer(values, this.answers);
        this.value = ean;
      }
    };

    this.getValues = (ean) => [
      {
        field_id: this.question.fields[0]._id,
        value: ean,
      },
    ];

    this.actionClicked = (action) => {
      switch (action) {
        case FORM_QUESTION_ACTIONS.RESET_PREFILL:
          return this.resetToPrefillValue();
        default:
          return false;
      }
    };

    this.resetToPrefillValue = () => {
      this.setDirty();

      if (this.previousAnswers && this.previousAnswers.length) {
        const previousAnswer = this.previousAnswers[0];

        this.answers.forEach((answer) =>
          this.sfQuestionForm.removeAnswer(answer._id, this.answers)
        );
        this.saveAnswer(previousAnswer.values[0].value);
      }
    };
  },
};
