export function questionsFormService(reportsService) {
  'ngInject';
  const getAnswer = (question, nodeId) =>
    reportsService.getDefaultAnswer(
      question._id,
      question.section_id,
      nodeId,
      question.fields.map((field) => ({ field_id: field._id, value: {}.undef }))
    );

  return {
    getAnswer,
  };
}

export const unitSignFormater = (unit) =>
  unit ? (unit.match(/\((.*)\)/) || [])[1] || unit : '';
