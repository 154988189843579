export const ViewStatesSearchComponent = {
  bindings: {
    isLoading: '<',
    hasNoResult: '<',
    hasLoadError: '<',
    onReload: '&?',
    isFullScreen: '<?',
  },
  transclude: true,
  templateUrl: 'components/Layout/view-states-search/view-states-search.html',
  controller: class ViewStatesSearchController {
    isLoading: boolean;
    hasNoResult: boolean;
    hasLoadError: boolean;
    onReload: () => void;
    isFullScreen: boolean;
    viewClass: string;

    $onInit() {
      this.viewClass = this.isFullScreen ? 'pane' : 'padding-top';
    }
  },
};
