// old browsers and Android devices may not properly work with core-js polyfills
// such as all-settled
export const allSettled = (promises) =>
  Promise.all.call(
    Promise,
    promises.map((p) =>
      Promise.resolve(p)
        .then((value) => ({
          status: 'fulfilled',
          value,
        }))
        .catch((reason) => ({
          status: 'rejected',
          reason,
        }))
    )
  );
