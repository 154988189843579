"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var errorsLibrary = require('../utils/errors');

var validationsLibrary = {
  validate: validationsLibraryValidate,
  defaults: validationsLibraryDefaults
}; // One liner compilation to avoid lib functions scope pollution

validationsLibrary.validateSchema = new (require('ajv'))().compile(require('./validation.schema')); // eslint-disable-line

module.exports = validationsLibrary;

function validationsLibraryDefaults() {
  var validation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultValidation = {};
  return _objectSpread({}, defaultValidation, {}, validation);
}

function validationsLibraryValidate(validation) {
  return errorsLibrary.mapAjvErrors(validationsLibrary.validateSchema(validation) ? [] : validationsLibrary.validateSchema.errors);
}