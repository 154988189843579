export const ListItemsSkeletonComponent = {
  bindings: {
    nbItems: '<?',
    itemType: '@?',
  },
  transclude: true,
  templateUrl:
    'components/Skeletons/list-items-skeleton/list-items-skeleton.html',
  controller: class ListItemsSkeletonController {
    $onInit() {
      this.fakeItems = Array.apply(null, Array(this.nbItems || 1)).map(
        () => 'skeletoncard'
      );
    }
  },
};
