export const ChangeRequestsTabsComponent = {
  bindings: {
    profile: '<',
  },
  templateUrl:
    'change-requests/components/change-requests-tabs/change-requests-tabs.html',
  controller: class ChangeRequestsTabsController {
    // eslint-disable-next-line max-params
    constructor(
      $q,
      $timeout,
      contentService,
      eventChangeRequestsService,
      CHANGE_REQUESTS_STATES
    ) {
      'ngInject';
      this.$q = $q;
      this.$timeout = $timeout;
      this.contentService = contentService;
      this.eventChangeRequestsService = eventChangeRequestsService;
      this.CHANGE_REQUESTS_STATES = CHANGE_REQUESTS_STATES;
    }

    $onInit() {
      // TODO: refactor the way we get items count, since we need pending counts only we can simplify the code a lot
      this.pendingCount = 0;

      return this.reloadStateCounts();
    }

    refreshTabs() {
      this.contentService.scrollTopById('changeRequestsTabsContent');
      return this.reloadStateCounts();
    }

    setActiveState(state) {
      this.activeState = state;
    }

    reloadStateCounts() {
      return this.eventChangeRequestsService
        .getStatesCounts()
        .then((counts) => {
          this.pendingCount = this.eventChangeRequestsService.getCountByState({
            state: this.CHANGE_REQUESTS_STATES.PENDING,
            counts,
            needFormat: true,
          });

          return counts;
        })
        .catch(() => {
          this.pendingCount = 0;

          return false;
        });
    }
  },
};
