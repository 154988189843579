export const NewsfeedPostLikesModalComponent = {
  bindings: {
    likes: '<',
  },
  templateUrl:
    'newsfeed/components/newsfeed-post-likes-modal/newsfeed-post-likes-modal.html',
  controller: class NewsfeedPostLikesModalComponent {
    constructor($element, $document, $timeout) {
      'ngInject';
      Object.assign(this, {
        $element,
        $document,
        $timeout,
      });
      this.MODAL_BOTTOM_MAX_HEIGHT_PERCENT = 90;
    }

    $onInit() {
      this.modalStyle = {};

      this.$timeout(() => {
        this.modalStyle = this.getModalStyle();
      }, 0);
    }

    getModalStyle() {
      const { document, header, content } = this.getDomElements();

      const heightValue = this.getHeightValue(document, header, content);

      return {
        height: `${heightValue}%`,
      };
    }
    getDomElements() {
      const document = this.$document[0].documentElement;
      const header = this.$element[0].querySelector(
        '.sf_newsfeed_likes_modal__header'
      );
      const content = this.$element[0].querySelector('.sf_content__content');

      return { document, header, content };
    }

    getHeightValue(document, header, content) {
      if (!header || !content) {
        return 0;
      }

      const { clientHeight: documentHeight } = document;
      const { clientHeight: contentHeight } = content;
      const { clientHeight: headerHeight } = header;
      const heightValue =
        ((contentHeight + headerHeight) * 100) / documentHeight;

      return this.MODAL_BOTTOM_MAX_HEIGHT_PERCENT < heightValue
        ? this.MODAL_BOTTOM_MAX_HEIGHT_PERCENT
        : heightValue;
    }
  },
};
