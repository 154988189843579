export function MerchandisingModalService(modalService, imageSourceService) {
  'ngInject';
  const methods = {
    openPictureModal,
    openReplacerSelectorModal,
    openPictureReplacementModal,
    openDrawSelectorModal,
    openDrawOnPictureModal,
  };

  function openPictureModal(id, url) {
    const template = `
      <sf-images-viewer
        images="[$ctrl.image]"
        on-close="$ctrl.onClose()">
      </sf-images-viewer>
    `;
    const bindings = {
      image: imageSourceService.create({ id, url }, false),
    };

    return modalService.open(template, bindings);
  }

  function openReplacerSelectorModal(
    validation,
    profile,
    onRetakePhotoSuccess,
    onClose
  ) {
    const template = `
      <sf-merchandising-replacer-selector
        validation="$ctrl.validation"
        profile="$ctrl.profile"
        on-retake-photo-success="$ctrl.onRetakePhotoSuccess()"
        on-close="$ctrl.onClose()">
      </sf-merchandising-replacer-selector>
    `;
    const bindings = {
      validation,
      profile,
      onRetakePhotoSuccess,
      onClose,
    };

    return modalService.open(template, bindings);
  }

  // eslint-disable-next-line max-params
  function openPictureReplacementModal(
    validation,
    profile,
    selectedAnswers,
    onRetakePhotoSuccess,
    onClose
  ) {
    const template = `
      <sf-merchandising-photo-replacer
        validation="$ctrl.validation"
        profile="$ctrl.profile"
        selected-answers="$ctrl.selectedAnswers"
        on-retake-photo-success="$ctrl.onRetakePhotoSuccess()"
        on-close="$ctrl.onClose()">
      </sf-merchandising-photo-replacer>
    `;
    const bindings = {
      validation,
      profile,
      selectedAnswers,
      onRetakePhotoSuccess,
      onClose,
    };

    return modalService.open(template, bindings);
  }

  function openDrawSelectorModal(validation) {
    const template = `
      <sf-merchandising-draw-selector
        validation="$ctrl.validation"
        on-close="$ctrl.onClose()"
        on-save="$ctrl.onSave()">
      </sf-merchandising-draw-selector>
    `;
    const bindings = {
      validation,
    };

    return modalService.openAsPromise(template, bindings);
  }

  function openDrawOnPictureModal(validation, profile, answer) {
    const template = `
      <sf-merchandising-photo-drawing
        validation="$ctrl.validation"
        profile="$ctrl.profile"
        answer="$ctrl.answer"
        on-close="$ctrl.onClose()"
        on-save="$ctrl.onSave()">
      </sf-merchandising-photo-drawing>
    `;
    const bindings = {
      validation,
      profile,
      answer,
    };

    return modalService.openAsPromise(template, bindings);
  }

  return methods;
}
