declare let angular: ng.IAngularStatic;

// Views
import { DiagnosisComponent } from './views/diagnosis.component';

export const DiagnosisModule = angular
  .module('sfMobile.diagnosis', [
    'ui.router',
    'pascalprecht.translate',
    'sfMobile.services.auth',
    'sf.sqlStorage',
    'sfMobile.services.synchronize',
    'sfMobile.config.database',
  ])
  .component('sfDiagnosis', DiagnosisComponent).name;
