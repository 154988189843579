import { SortModalComponent } from './sort-modal.component';

export default angular
  .module('sfMobile.components.sort-modal', [
    'sfMobile.services.localization',
    'pascalprecht.translate',
    'sfMobile.components.input-radio',
    'sfMobile.components.input-select',
  ])
  .component('sfSortModal', SortModalComponent).name;
