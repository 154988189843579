import { QuestionCheckComponent } from './question-check.component';
import CheckChoicesModalModule from './components/check-choices-modal/check-choices-modal.module';

export default angular
  .module('sfMobile.components.question-check', [
    'sfMobile.constants.form-question-actions',
    'sfMobile.services.modal',
    'sfMobile.services.keyboard',
    'sfMobile.components.input-checkbox-item',
    CheckChoicesModalModule,
  ])
  .component('questionCheck', QuestionCheckComponent).name;
