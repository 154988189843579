export const SectionLoaderComponent = {
  bindings: {
    isLoading: '<',
    isEmpty: '<',
    emptyConfig: '<',
    networkError: '<',
    onReload: '&',
    loadingSkeletonConfig: '<?',
    onMoreButtonClick: '&?',
    sectionTitle: '@',
    newLabel: '@?',
    isBeta: '<?',
    hideIfEmpty: '<',
  },
  transclude: true,
  templateUrl: 'components/Sections/section-loader.html',
  controller: class SectionLoaderController implements ng.IComponentController {
    isLoading: boolean;
    hideIfEmpty: boolean;

    constructor() {
      'ngInject';
    }

    $onInit(): void {
      // in some cases setting isLoading = true
      // on $onInit hook causes infinite loading
      if (typeof this.isLoading === 'undefined') {
        this.isLoading = true;
      }
      if (typeof this.hideIfEmpty === 'undefined') {
        this.hideIfEmpty = false;
      }
    }
  },
};
