import { DateService } from '../../../services/Utils/Dates/date.service';
import { CalendarEvent, DateEvents } from '../../types';
import { ManagerCalendarApiService } from '../manager-calendar-api/manager-calendar-api.service';
import { CalendarEventsService } from '../../../services/API/calendar-events/calendar-events.service';

export class ManagerCalendarService {
  constructor(
    private managerCalendarApiService: ManagerCalendarApiService,
    private calendarEventsService: CalendarEventsService,
    private dateService: DateService,
    private profileService
  ) {
    'ngInject';
  }

  getManagerCalendarEvents(
    date: Date,
    filters: Record<string, unknown>
  ): ng.IPromise<DateEvents[]> {
    const duration = 'month';
    const momentDate = this.dateService.toMoment(date);
    const dateArray = Array.from(
      { length: momentDate.daysInMonth() },
      (_x, i) =>
        this.dateService.toDate(momentDate.startOf(duration).add(i, 'days'))
    );

    return this.managerCalendarApiService
      .getManagerCalendarEvents(date, duration, filters)
      .then((events) => this.unwindEventsAssignee(events))
      .then((events) =>
        this.calendarEventsService.groupEventsByDay(events, dateArray)
      );
  }
  // used for of to optimise huge events lists. do not use object or array destructuring here
  private unwindEventsAssignee(
    events: CalendarEvent[]
  ): ng.IPromise<CalendarEvent[]> {
    return this.profileService.getLocalProfile().then((profile) => {
      const unwindedEvents: CalendarEvent[] = [];
      for (const event of events) {
        for (const assignee of event.assignees) {
          if (assignee._id !== profile._id) {
            event.assignee = assignee;
          }
          unwindedEvents.push(event);
        }
      }
      return unwindedEvents;
    });
  }
}
