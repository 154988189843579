// Services
import { NotificationsApiService } from './services/notifications-api/notifications-api.service';
import { NotificationsService } from './services/notifications/notifications.service';
// Components
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';

export default angular
  .module('sfMobile.notifications', [
    'LocalStorageModule',
    'sfMobile.services.user-devices',
    'sfMobile.services.push-notifications',
    'sfMobile.services.preferences',
    'sfMobile.services.modal',
    'sfMobile.services.app-settings',
    'sfMobile.components.settings-alert',
    'sfMobile.services.synchronize',
    'sfMobile.components.view-states',
    'sfMobile.services.pubsub',
  ])
  .service('notificationsApiService', NotificationsApiService)
  .service('notificationsService', NotificationsService)
  .component('sfNotificationsList', NotificationsListComponent)
  .component('sfNotificationItem', NotificationItemComponent).name;
