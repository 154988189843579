import { GroupTaskItemController } from './group-task-item.controller';

export const GroupTaskItemComponent: ng.IComponentOptions = {
  bindings: {
    profile: '<',
    groupTask: '<',
    onDeleted: '&',
  },
  transclude: true,
  templateUrl: 'tasks/components/group-task-item/group-task-item.html',
  controller: GroupTaskItemController,
};
