"use strict";

var schemaUtils = require('../utils/schema');

var webhookSchema = require('../webhooks/webhook.schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Event',
  type: 'object',
  anyOf: [{
    additionalProperties: false,
    required: ['exchange', 'organisation_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_ORGANISATION_CREATED', 'A_ORGANISATION_UPDATED', 'A_ORGANISATION_DELETED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      owner_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'validation_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_VALIDATION_CREATED', 'A_VALIDATION_UPDATED', 'A_VALIDATION_DELETED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      validation_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'place_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_PLACE_CREATED', 'A_PLACE_UPDATED', 'A_PLACE_DELETED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      place_id: schemaUtils.oneToOne(),
      owner_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'user_id', 'locale'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_USER_SIGNED_UP', 'A_USER_RECONFIRMED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      user_id: schemaUtils.oneToOne(),
      locale: {
        type: 'string'
      }
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'user_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_USER_CREATED', 'A_USER_UPDATED', 'A_USER_DELETED', 'A_USER_UNARCHIVED', 'A_USER_REINVITED', 'A_USER_EMAIL_BOUNCED', 'A_USER_CONFIRMED', 'A_USER_LOGGED_IN', 'A_USER_LOGGED_IN_BUT_NOT_CONFIRMED', 'A_USER_PASSWORD_RESET', 'A_USER_RESET_PASSWORD', 'A_USER_PASSWORD_CHANGED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      user_id: schemaUtils.oneToOne(),
      owner_id: schemaUtils.oneToOne(),
      metadata: {
        // Is it really need or here just for tests?
        type: 'object'
      }
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'user_id', 'file_id', 'fileType'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_USERS_IMPORT_REQUESTED', 'A_PLACES_IMPORT_REQUESTED', 'A_PRODUCTS_IMPORT_REQUESTED', 'A_VISITS_IMPORT_REQUESTED', 'A_TASKS_IMPORT_REQUESTED', 'A_CALENDAR_EVENTS_IMPORT_REQUESTED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      user_id: schemaUtils.oneToOne(),
      import_id: schemaUtils.oneToOne(),
      file_id: schemaUtils.oneToOne(),
      catalog_id: schemaUtils.oneToOne(),
      fileType: {
        type: 'string'
      }
    }
  }, {
    additionalProperties: true,
    required: ['exchange', 'organisation_id', 'documents_ids'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_DOCUMENTS_CREATED', 'A_DOCUMENTS_DELETED']
      },
      documents_ids: schemaUtils.oneToSeveral(),
      organisation_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: true,
    required: ['exchange', 'organisation_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_INTEGRATION_SALESFORCE_ACCOUNTUSER_CHANGED', 'A_INTEGRATION_SALESFORCE_ACCOUNT_CHANGED', 'A_INTEGRATION_SALESFORCE_ACCOUNT_CUSTOM_PARAMS_CHANGED', 'A_INTEGRATION_SALESFORCE_SURVEY_CHANGED', 'A_INTEGRATION_SALESFORCE_USER_CHANGED', 'A_INTEGRATION_SALESFORCE_FORM_CHANGED', 'A_INTEGRATION_SALESFORCE_PUSH_REFRESH_TOKEN']
      },
      organisation_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'user_id', 'import_id', 'valueImport', 'typeImport'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_FILE_IMPORTED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      user_id: schemaUtils.oneToOne(),
      import_id: schemaUtils.oneToOne(),
      valueImport: {
        type: 'string'
      },
      typeImport: {
        type: 'string'
      }
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'placesList_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_PLACES_LIST_CREATED', 'A_PLACES_LIST_UPDATED', 'A_PLACES_LIST_DELETED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      placesList_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'usersGroup_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_USERS_GROUP_CREATED', 'A_USERS_GROUP_UPDATED', 'A_USERS_GROUP_DELETED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      usersGroup_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'form_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_FORM_CREATED', 'A_FORM_UPDATED', 'A_FORM_DELETED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      form_id: schemaUtils.oneToOne(),
      owner_id: schemaUtils.oneToOne(),
      newState: schemaUtils.formState(),
      newUsers_ids: schemaUtils.oneToSeveral()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'report_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_REPORT_CREATED', 'A_REPORT_UPDATED', 'A_REPORT_DELETED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      user_id: schemaUtils.oneToOne(),
      report_id: schemaUtils.oneToOne(),
      form_id: schemaUtils.oneToOne(),
      place_id: schemaUtils.oneToOne(),
      newState: {
        type: 'string'
      },
      oldState: {
        type: 'string'
      }
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'comment_id', 'author_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_COMMENT_CREATED', 'A_COMMENT_UPDATED', 'A_COMMENT_DELETED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      comment_id: schemaUtils.oneToOne(),
      author_id: schemaUtils.oneToOne(),
      report_id: schemaUtils.oneToOne()
    }
  }, {
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_REPORT_DAILY_RECEIVED', 'A_REPORT_DAILY_SENT', 'A_REPORT_MANAGER_DAILY_RECEIVED', 'A_REPORT_WEEKLY_RECEIVED', 'A_REPORT_WEEKLY_SENT', 'A_REPORT_MANAGER_WEEKLY_RECEIVED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      user_id: schemaUtils.oneToOne(),
      start_date: {
        type: 'string',
        format: 'date-time'
      },
      end_date: {
        type: 'string',
        format: 'date-time'
      },
      report_date: {
        type: 'string',
        format: 'date-time'
      }
    },
    additionalProperties: true,
    required: ['exchange', 'organisation_id', 'user_id', 'start_date', 'end_date', 'report_date']
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'missionsGroup_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_MISSIONSGROUP_CREATED', 'A_MISSIONSGROUP_STARTED', 'A_MISSIONSGROUP_UPDATED', 'A_MISSIONSGROUP_DELETED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      missionsGroup_id: schemaUtils.oneToOne(),
      owner_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'webhook'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_WEBHOOK_CALL_SCHEDULED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      webhook: {
        type: 'object',
        additionalProperties: false,
        properties: {
          _id: schemaUtils.oneToOne(),
          contents: webhookSchema
        }
      },
      payload: {
        type: 'object',
        additionalProperties: true,
        required: ['organisation_id', 'event'],
        properties: {
          organisation_id: {
            type: 'string'
          },
          event: {
            type: 'string'
          }
        }
      },
      organisation_id: schemaUtils.oneToOne()
    }
  }, {
    // Legacy events, needs cleanup
    additionalProperties: true,
    required: ['exchange', 'organisation_id', 'user_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_PAYMENT_ADDED_CREDIT_CARD', 'A_PAYMENT_SUBSCRIBED_MONTHLY', 'A_PAYMENT_SUBSCRIBED_YEARLY', 'A_PAYMENT_TRIAL_ENDING']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      user_id: schemaUtils.oneToOne(),
      owner_id: schemaUtils.oneToOne()
    }
  }, {
    // sendbird chat webhook events
    additionalProperties: false,
    required: ['exchange', 'sender_id', 'users_ids', 'channel', 'message', 'organisation_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_SENDBIRD_CHAT_MESSAGE_SENT']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      sender_id: schemaUtils.oneToOne(),
      users_ids: schemaUtils.oneToSeveral(),
      channel: {
        type: 'string'
      },
      message: {
        type: 'string'
      }
    }
  }, {
    // sendbird sync event
    additionalProperties: false,
    required: ['exchange', 'organisation_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_SENDBIRD_CONFIG_UPDATED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      user_id: schemaUtils.oneToOne(),
      payload: {
        type: 'object',
        additionalProperties: true,
        required: ['resultsRecipients'],
        properties: {
          resultsRecipients: {
            type: 'string'
          }
        }
      }
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'start_dateTime'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_CALENDAR_EVENT_RECAP_SCHEDULED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      start_dateTime: {
        type: 'string',
        format: 'date-time'
      }
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'created_dateTime'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_CALENDAR_EVENT_CHANGE_REQUEST_RECAP_SCHEDULED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      created_dateTime: {
        type: 'string',
        format: 'date-time'
      }
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'changeRequest_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_CALENDAR_EVENT_CHANGE_REQUEST_STATUS_CHANGED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      changeRequest_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'changedBy_id', 'calendarEvent_id', 'originalEvent', 'updatedEvent'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_CALENDAR_EVENT_CHANGED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      changedBy_id: schemaUtils.oneToOne(),
      calendarEvent_id: schemaUtils.oneToOne(),
      originalEvent: {
        type: 'object',
        additionalProperties: false,
        required: ['contents'],
        properties: {
          contents: {
            type: 'object'
          }
        }
      },
      updatedEvent: {
        type: 'object',
        additionalProperties: false,
        required: ['contents'],
        properties: {
          contents: {
            type: 'object'
          }
        }
      }
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'calendarEvent_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_CALENDAR_EVENT_DELETED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      calendarEvent_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'form_id'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_REMINDER_EMAIL']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      reminderContent: {
        type: 'number',
        minimum: 0,
        maximum: 100
      },
      organisation_id: schemaUtils.oneToOne(),
      form_id: schemaUtils.oneToOne(),
      owner_id: schemaUtils.oneToOne()
    }
  }, {
    additionalProperties: false,
    required: ['exchange', 'organisation_id', 'created_dateTime'],
    properties: {
      exchange: {
        type: 'string',
        "enum": ['A_CALENDAR_EVENT_CHANGE_REQUESTS_ACCEPTED_RECAP_SCHEDULED']
      },
      inhibWorkers: {
        type: 'array',
        items: {}
      },
      organisation_id: schemaUtils.oneToOne(),
      created_dateTime: {
        type: 'string',
        format: 'date-time'
      }
    }
  }]
};