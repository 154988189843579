import { clone } from 'ramda';
import { ModalService } from '../../../../services/Utils/Modal';
import { PopupService } from '../../../../services/Utils/Popup/popup.service';
import { SegmentService } from '../../../../services/Utils/Segment/segment.service';
import { SfFeatureFlags } from '@simplifield/feature-flags';
import { FEATURE_FLAGS } from '@constants/feature-flags.constant';

export class CalendarEventsListController {
  // Bindings
  events;
  userTimezone;
  displayActions?;
  disableStoreLink;
  displayAsCards;
  onUpdate;
  onDelete;
  // Props
  activeEventId = null;
  eventsList: any;
  isFullCalendar: boolean;
  openDetailsByClick: boolean;
  onItemClick: () => void;

  /* eslint-disable max-params */
  constructor(
    private $state,
    private $translate: ng.translate.ITranslateService,
    private modalService: ModalService,
    private popupRequestService,
    private popupService: PopupService,
    private segmentService: SegmentService,
    private calendarEventsService,
    private eventChangeRequestsService,
    private placesService,
    private calendarEventsRecurrenceService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {
    'ngInject';
  }

  $onInit() {
    this.eventsList = this.calendarEventsService.sortCalendarEvents(
      this.events
    );

    this.displayAsCards = this.displayAsCards ?? true;

    this.isFullCalendar = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.FULL_CALENDAR
    );

    return this.eventsList;
  }

  $onChanges(changes) {
    if (changes.events) {
      this.eventsList = this.calendarEventsService.sortCalendarEvents(
        this.events
      );
    }
  }

  onAccessStore(placeId) {
    if (!placeId) {
      return;
    }
    this.$state.go('index.places.details.main', {
      placeId: placeId,
    });
    this.segmentService.track('CALENDAR_EVENTS', {
      action: 'tap',
      label: 'Place item',
    });
  }

  toggle(event) {
    if (this.openDetailsByClick) {
      this.onItemClick && this.onItemClick();
      this.detailsEvent(event);
    } else if (this.displayActions) {
      this.activeEventId = this.activeEventId !== event._id ? event._id : null;
    } else if (!this.disableStoreLink) {
      this.onAccessStore(event.contents.place_id);
    }
  }

  deleteCalendarEvent(event) {
    const confirm = this.popupService.showConfirm({
      title: this.$translate.instant('EVENT_REMOVE_TITLE'),
      iconName: 'error',
      buttonText: this.$translate.instant('EVENT_REMOVE_BTN_CONFIRM'),
    });
    const showProgressPopup = () =>
      this.popupRequestService.show({
        progress: {
          title: this.$translate.instant('EVENT_REMOVE_IN_PROGRESS'),
        },
        error: {
          title: this.$translate.instant('EVENT_REMOVE_ERROR_TITLE'),
          btnText: this.$translate.instant('EVENT_REMOVE_ERROR_POPUP_CLOSE'),
        },
      });

    const abortHandler = () => {};
    const deleteRecurrentEvent = (event) => {
      return this.calendarEventsRecurrenceService
        .displayDeleteRecurrentEventChoices()
        .then((choice) => {
          const progressPopup = showProgressPopup();

          return this.calendarEventsRecurrenceService
            .handleDeleteEventChoice(choice, event)
            .then(
              () =>
                progressPopup
                  .onSuccess()
                  .then(() => this.onDelete({ eventId: event._id })),
              () => progressPopup.onError(this.deleteCalendarEvent.bind(this))
            );
        }, abortHandler);
    };
    const deleteNormalEvent = (event) => {
      const progressPopup = showProgressPopup();

      return this.calendarEventsService
        .delete(event._id, {}, { pov: 'organisation' })
        .then(
          () =>
            progressPopup
              .onSuccess()
              .then(() => this.onDelete({ eventId: event._id })),
          () => progressPopup.onError(this.deleteCalendarEvent.bind(this))
        );
    };

    return confirm.then(() => {
      return this.calendarEventsRecurrenceService.isRecurrent(event)
        ? deleteRecurrentEvent(event)
        : deleteNormalEvent(event);
    }, abortHandler);
  }

  editCalendarEvent(event) {
    const template = `
      <sf-edit-calendar-event-modal
        event="$ctrl.event"
        on-update="$ctrl.onUpdate(event)"
        on-save="$ctrl.onSave()"
        on-close="$ctrl.onClose()">
      </sf-edit-calendar-event-modal>
    `;

    return this.modalService.openAsPromise(
      template,
      {
        onUpdate: (updatedEvent) => this.onUpdate({ event: updatedEvent }),
        event: clone(event),
      },
      { hardwareBackButtonClose: false }
    );
  }

  detailsEvent(event) {
    const template = `
      <sf-details-event-modal
        event="$ctrl.event"
        on-edit="$ctrl.onEdit(event)"
        on-delete="$ctrl.onDelete(event)"
        on-close="$ctrl.onClose()"
        on-request-changes="$ctrl.onRequestChanges()"
        on-request-deletion="$ctrl.onRequestDeletion()">
      </sf-details-event-modal>
    `;

    const detailsModal = this.modalService.open(
      template,
      {
        onEdit: (updatedEvent) =>
          this.editCalendarEvent(updatedEvent).then(() =>
            detailsModal.remove()
          ),
        onDelete: (calendarEvent) =>
          this.deleteCalendarEvent(calendarEvent).then(() =>
            detailsModal.remove()
          ),
        onRequestChanges: () => this.requestChanges(event),
        onRequestDeletion: () => this.requestDeletion(event),
        event,
      },
      { hardwareBackButtonClose: false }
    );

    return detailsModal;
  }

  requestChanges(event) {
    const template = `
      <sf-request-event-changes-modal
        event="$ctrl.event"
        on-close="$ctrl.onClose()"
      ></sf-request-event-changes-modal>
    `;

    return this.modalService.open(
      template,
      { event: clone(event) },
      { hardwareBackButtonClose: false }
    );
  }

  requestDeletion({ _id, contents }) {
    const popupRequest = this.popupRequestService.show({
      progress: {
        title: this.$translate.instant('REQUEST_EVENT_CHANGE_PENDING'),
      },
      error: {
        title: this.$translate.instant('REQUEST_EVENT_CHANGE_ERROR_TITLE'),
        desc: this.$translate.instant('REQUEST_EVENT_CHANGE_ERROR_DESC'),
      },
    });

    return this.eventChangeRequestsService
      .saveNew({
        contents: {
          action: 'remove',
          state: 'pending',
          originalEvent: { _id, contents },
          requestedEvent: {},
          organisation_id: contents.organisation_id,
        },
      })
      .then(() => popupRequest.onSuccess())
      .catch(() =>
        popupRequest.onError(() => this.requestDeletion({ _id, contents }))
      );
  }
}
