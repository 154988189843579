export function ReportValidationService(SF_MISSION_PREFERENCES) {
  'ngInject';
  const factory = {
    isRevision,
    isPendingValidation,
    isValidated,
    isFormSubjectToValidation,
    isStatusEditable,
    VALIDATION_STATES: {
      TOREVISE: 'TOREVISE',
      TOREVIEW: 'TOREVIEW',
      DONE: 'DONE',
    },
  };

  return factory;

  function isRevision(report) {
    return (
      report.validity === false &&
      report.validationState === factory.VALIDATION_STATES.TOREVISE
    );
  }

  function isPendingValidation(report) {
    return (
      report.validity === false &&
      report.validationState === factory.VALIDATION_STATES.TOREVIEW
    );
  }

  function isValidated(report) {
    return (
      report.validity === true &&
      report.validationState === factory.VALIDATION_STATES.DONE
    );
  }

  function isFormSubjectToValidation(form) {
    return !!form.contents.reportsValidation;
  }

  function isStatusEditable(form) {
    let isFormEditable = false;
    const nbStatusMin = 2;

    if (form.contents.preferences) {
      const allowEditProp = form.contents.preferences.find(
        (prop) => prop.name === SF_MISSION_PREFERENCES.ALLOW_EDIT
      );

      isFormEditable = allowEditProp ? allowEditProp.value : false;
    }

    return (
      isFormEditable &&
      form.contents.statusMap &&
      nbStatusMin < form.contents.statusMap.length
    );
  }
}
