"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('comment1'),
    name: 'comment1',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      subject: 'Ça va jean ?',
      content: 'Comment vas-tu depuis le temps ?',
      organisation_id: createObjectId('organisation'),
      author_id: createObjectId('owner')
    }
  }, {
    _id: createObjectId('comment2'),
    name: 'comment2',
    description: 'Every data that can be set.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      subject: 'Ça va jean ?',
      content: 'Comment vas-tu depuis le temps ?',
      organisation_id: createObjectId('organisation'),
      author_id: createObjectId('owner'),
      picture_id: createObjectId('picture'),
      form_id: createObjectId('marketting2'),
      report_id: createObjectId('report')
    }
  }, {
    _id: createObjectId(),
    name: 'empty',
    description: 'Empty contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.content']
      }]
    },
    partial: {
      valid: true
    },
    contents: {}
  }, {
    _id: createObjectId(),
    name: 'noauthor',
    description: 'No author',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.author_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      subject: 'Ça va jean ?',
      content: 'Comment vas-tu depuis le temps ?',
      organisation_id: createObjectId('organisation'),
      form_id: createObjectId('marketting2'),
      report_id: createObjectId('report')
    }
  }, {
    _id: createObjectId(),
    name: 'with validation.statusSetter and without validation._id',
    description: 'with validation.statusSetter without validation._id',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['._id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      subject: 'Missing validation_id',
      content: 'Do he missing a validation_id ?',
      validation: {
        stateSetter: 'rejected'
      },
      organisation_id: createObjectId('organisation'),
      author_id: createObjectId('author'),
      form_id: createObjectId('marketting2'),
      report_id: createObjectId('report')
    }
  }, {
    _id: createObjectId(),
    name: 'comment on a validation without validation.stateSetter',
    description: 'We may comment about a validation without changing the state',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      subject: 'Good comment without valisationStateSetter',
      content: 'The comment is good',
      organisation_id: createObjectId('organisation'),
      form_id: createObjectId('marketting2'),
      report_id: createObjectId('report'),
      validation: {
        _id: createObjectId('validation')
      },
      author_id: createObjectId('owner')
    }
  }, {
    _id: createObjectId(),
    name: 'Validation and State',
    description: 'Good comment with all params',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      subject: 'Missing val',
      content: 'Do he missing a validation_id ?',
      validation: {
        stateSetter: 'rejected',
        _id: createObjectId('validation'),
        changedFiles_ids: [createObjectId('file1')]
      },
      organisation_id: createObjectId('organisation'),
      form_id: createObjectId('marketting2'),
      report_id: createObjectId('report'),
      author_id: createObjectId('owner')
    }
  }];
};