import { ObjectId } from '../../../..';
import { IMAGE_SIZES } from '../../../../constants/image-sizes.constant';
import { ImageService } from '../../../../services/Utils/Image/image.service';
import type { ImageSourceService } from '../../../../services/Utils/ImageSource/image-source.service';
import type { ModalService } from '../../../../services/Utils/Modal';
import type { ObjectIdService } from '../../../../services/Utils/Objectid/objectId.service';
import type { Form, FormFile } from '../../types';

export const FormFilesComponent: ng.IComponentOptions = {
  bindings: {
    form: '<',
    files: '<',
  },
  templateUrl: 'missions/form/components/form-files/form-files.html',
  controller: class FormFilesController implements ng.IController {
    //bindings
    form: Form;
    files: FormFile[];
    // members
    formId: ObjectId;
    formFilesPath: string;
    fullImageUrl: string;
    mediumImageUrl: string;
    imageFile: FormFile;
    documentFile: FormFile;

    // eslint-disable-next-line max-params
    constructor(
      private $q: ng.IQService,
      private formsService,
      private modalService: ModalService,
      private imageSourceService: ImageSourceService,
      private imageService: ImageService,
      private objectIdService: ObjectIdService,
      private SF_IMAGE_SIZES: typeof IMAGE_SIZES
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<void> {
      const files = this.files || [];
      const imageFile = files.filter((f) => this.isImageTypeFile(f))[0];
      const documentFile = files.filter((f) => this.isDocumentTypeFile(f))[0];

      this.formFilesPath = this.formsService.getFilesPath(this.formId);
      this.fullImageUrl = '';
      this.mediumImageUrl = '';
      this.imageFile = imageFile;
      this.documentFile = documentFile;

      if (!imageFile) {
        return this.$q.when();
      }
      return this.$q
        .all([
          this.imageService.getSizedUrlFromId(
            imageFile._id,
            this.SF_IMAGE_SIZES.SQUARE_BIG
          ),
          this.imageService.getSizedUrlFromId(
            imageFile._id,
            this.SF_IMAGE_SIZES.RECTANGLE_MEDIUM
          ),
        ])
        .then(([fullUrl, mediumUrl]) => {
          this.fullImageUrl = fullUrl;
          this.mediumImageUrl = mediumUrl;
        });
    }

    viewImage(url: string): void {
      const template = `
        <sf-images-viewer
          images="[$ctrl.image]"
          on-close="$ctrl.onClose()">
        </sf-images-viewer>
      `;
      const bindings = {
        image: this.imageSourceService.create(
          { id: this.objectIdService.create(), url },
          false
        ),
      };

      this.modalService.open(template, bindings);
    }

    isImageTypeFile(file: FormFile): boolean {
      return this.isFileType(file, 'image/');
    }

    isDocumentTypeFile(file: FormFile): boolean {
      return this.isFileType(file, 'application/pdf');
    }

    isFileType(file: FormFile, type: string): boolean {
      return Boolean(file.mime_type) && file.mime_type.indexOf(type) !== -1;
    }
  },
};
