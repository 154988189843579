export const FORM_QUESTION_ACTIONS = {
  RESET: 'resetValue',
  EDIT: 'editValue',
  RESET_PREFILL: 'resetPrefill',
} as const;

export type FORM_QUESTION_ACTIONS_TYPE =
  typeof FORM_QUESTION_ACTIONS[keyof typeof FORM_QUESTION_ACTIONS];

export default angular
  .module('sfMobile.constants.form-question-actions', [])
  .constant('FORM_QUESTION_ACTIONS', FORM_QUESTION_ACTIONS).name;
