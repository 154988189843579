export function ChatPopupService(
  $translate,
  popupService,
  popupRequestService
) {
  'ngInject';
  const methods = {
    showFileSendingProgress,
    showChannelDeletedError,
    showUserLeftError,
    showRemoveMemberConfirm,
    showDeleteChannelConfirm,
    showDeleteChannelProgress,
    showLeaveChannelConfirm,
    showUserLeaveFailError,
    showChannelNameEditingProgress,
    promptChannelName,
    showChannelCreatingProgress,
    showDeleteMessageConfirm,
  };

  // ChatRoom popups

  function showFileSendingProgress(onCancel) {
    return popupService.showDownloading(
      {
        title: $translate.instant('CHAT_ROOM_FILE_SENDING_TITLE'),
        desc: $translate.instant('CHAT_ROOM_FILE_SENDING_DESC'),
      },
      onCancel
    );
  }
  function showChannelDeletedError(onClick) {
    return popupService.showError(
      {
        title: $translate.instant('CHAT_ROOM_CHANNEL_DELETED_ERROR'),
        btnText: $translate.instant('CHAT_ROOM_CHANNEL_DELETED_BUTTON'),
        iconName: 'item-danger',
      },
      onClick
    );
  }
  function showUserLeftError(onClick) {
    return popupService.showError(
      {
        title: $translate.instant('CHAT_ROOM_USER_LEFT_ERROR'),
        btnText: $translate.instant('CHAT_ROOM_USER_LEFT_BUTTON'),
        iconName: 'user-leave',
      },
      onClick
    );
  }
  function showDeleteMessageConfirm() {
    return popupService.showConfirm({
      title: $translate.instant('CHAT_ROOM_DELETE_MESSAGE_CONFIRM_TITLE'),
      iconName: 'error',
      buttonText: $translate.instant('CHAT_ROOM_DELETE_MESSAGE_CONFIRM_BTN'),
    });
  }

  // ChatRoomInfo popups

  function showRemoveMemberConfirm() {
    return popupService.showConfirm({
      title: $translate.instant('CHAT_ROOM_INFO_REMOVE_MEMBER_TITLE'),
      iconName: 'error',
      buttonText: $translate.instant(
        'CHAT_ROOM_INFO_REMOVE_MEMBER_BTN_CONFIRM'
      ),
    });
  }
  function showDeleteChannelConfirm() {
    return popupService.showConfirm({
      title: $translate.instant('CHAT_ROOM_INFO_DELETE_CHANNEL_CONFIRM_TITLE'),
      iconName: 'error',
      buttonText: $translate.instant(
        'CHAT_ROOM_INFO_DELETE_CHANNEL_CONFIRM_BUTTON'
      ),
      hasCheckbox: true,
    });
  }
  function showDeleteChannelProgress() {
    const popupOptions = {
      progress: {
        title: $translate.instant('CHAT_ROOM_INFO_DELETE_IN_PROGRESS'),
      },
      error: {
        title: $translate.instant('CHAT_ROOM_INFO_DELETE_FAIL'),
        btnText: $translate.instant('CHAT_ROOM_INFO_ERROR_POPUP_CLOSE'),
      },
    };

    return popupRequestService.show(popupOptions);
  }
  function showLeaveChannelConfirm() {
    return popupService.showConfirm({
      title: $translate.instant('CHAT_ROOM_INFO_LEAVE_CHANNEL_CONFIRM_TITLE'),
      iconName: 'user-leave',
      buttonText: $translate.instant(
        'CHAT_ROOM_INFO_LEAVE_CHANNEL_CONFIRM_BUTTON'
      ),
      hasCheckbox: true,
    });
  }
  function showUserLeaveFailError() {
    return popupService.showError({
      title: $translate.instant('CHAT_ROOM_INFO_LEAVE_FAIL'),
      btnText: $translate.instant('CHAT_ROOM_INFO_ERROR_POPUP_CLOSE'),
    });
  }
  function showChannelNameEditingProgress() {
    const popupOptions = {
      progress: {
        title: $translate.instant('CHAT_ROOM_INFO_EDIT_NAME_PROGRESS'),
      },
      success: {
        title: $translate.instant('CHAT_ROOM_INFO_EDIT_NAME_SUCCESS'),
        iconName: 'thumbsup',
      },
      error: {
        title: $translate.instant('CHAT_ROOM_INFO_EDIT_NAME_ERROR'),
      },
    };

    return popupRequestService.show(popupOptions);
  }

  // ChatCreate popups

  function promptChannelName(title, titleMaxLength, inputValue) {
    return popupService.prompt({
      title,
      placeholder: $translate.instant('CHAT_NAME_CONVERSATION_PLACEHOLDER'),
      submit: $translate.instant('CHAT_NAME_CONVERSATION_SUBMIT'),
      required: true,
      maxLength: titleMaxLength,
      inputValue,
    });
  }

  function showChannelCreatingProgress() {
    const popupOptions = {
      progress: {
        title: $translate.instant('CHAT_CREATE_PROGRESS'),
      },
      error: {
        title: $translate.instant('CHAT_CREATE_ERROR'),
      },
    };

    return popupRequestService.show(popupOptions);
  }

  return methods;
}
