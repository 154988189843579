export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 4.4,
    method: updateMethod,
  });

  // @ngInject
  function updateMethod($q, $log, sqlStorageService, localStorageService) {
    const fields = [
      'id NVARCHAR(32) PRIMARY KEY UNIQUE',
      'payload TEXT',
      'needPlace INTEGER',
      'deleted INTEGER DEFAULT 0',
    ].join(',');
    const formFields = ['id', 'payload', 'needPlace'].join(',');
    const queries = [
      `CREATE TEMPORARY TABLE forms_backup(${formFields})`,
      `INSERT INTO forms_backup SELECT ${formFields} FROM forms`,
      'DROP TABLE forms',
      `CREATE TABLE forms(${fields})`,
      `INSERT INTO forms SELECT ${formFields}, 0 as deleted FROM forms_backup`,
      'DROP TABLE forms_backup',
    ];
    const update_date_keys = localStorageService
      .keys()
      .filter((key) => key.indexOf('_update_date') !== -1);

    return $q
      .all(queries.map((query) => sqlStorageService.execute(query)))
      .then(() =>
        localStorageService.remove.apply(localStorageService, update_date_keys)
      )
      .catch(() => null);
  }
}
