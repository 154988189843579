import { ObjectId } from '../../../../index';
import { APIStore } from '../../../';

export class PlaceReportsListController implements ng.IController {
  // bindings
  place: APIStore;

  // class members
  reportsListParams: { place_id: ObjectId };
  useNewCampaigns: boolean;

  constructor(private profileService, private organisationsService) {
    'ngInject';
  }

  $onInit(): void {
    this.reportsListParams = {
      place_id: this.place._id,
    };
    this.profileService
      .getProfile()
      .then((profile) =>
        this.organisationsService.getProfileOrganisation(profile)
      )
      .then((organisation) => {
        this.useNewCampaigns = organisation.useNewCampaigns;
      });
  }
}
