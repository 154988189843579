export default function routeConfig($stateProvider) {
  'ngInject';

  $stateProvider
    .state('index.calendar-events', {
      url: '/calendar-events',
      abstract: true,
      cache: false,
      views: {
        'tab-calendar-events': {
          template: '<ion-nav-view></ion-nav-view>',
        },
      },
    })
    .state('index.calendar-events.list', {
      url: '',
      params: {
        defaultDateToDisplay: new Date(),
        placeId: undefined,
        nextEvents: [],
      },
      template: `
        <ion-view ng-class="{ 'sf_view__has_nav_bar': $ctrl.isInNavBar}">
          <sf-calendar-events organisation="$ctrl.organisation" place="$ctrl.place"></sf-calendar-events>
        </ion-view>
      `,
      controller: CalendarEventsViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Calendar Events List',
      resolve: {
        localProfile: getLocalProfile,
        organisation: getOrganisation,
        place: getPlace,
      },
    })
    .state('index.calendar-events.manager-list', {
      url: '/manager',
      template: `
        <ion-view class="sf_view__has_nav_bar">
          <sf-manager-calendar></sf-manager-calendar>
        </ion-view>
      `,
      controller: CalendarEventsViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Manager Calendar Events List',
      resolve: {
        localProfile: getLocalProfile,
        organisation: getOrganisation,
        place: getPlace,
      },
    });

  function CalendarEventsViewController(
    userExperienceService,
    organisation,
    place
  ) {
    'ngInject';

    this.place = place;
    this.organisation = organisation;
    this.isInNavBar = userExperienceService
      .getNav(this.organisation)
      .find((nav) => nav.key === 'calendarEvents');
  }

  function getLocalProfile(profileService) {
    'ngInject';
    return profileService.getLocalProfile();
  }
  function getOrganisation(localProfile, organisationsService) {
    'ngInject';
    return organisationsService.getProfileOrganisation(localProfile);
  }

  function getPlace($stateParams, $q, $translate, placesService, popupService) {
    'ngInject';
    if (!$stateParams.placeId) {
      return;
    }

    const showError = () =>
      popupService.showError({
        title: $translate.instant('PLACE_DETAIL_INACCESSIBLE_ERROR_TITLE'),
        desc: $translate.instant('PLACE_DETAIL_INACCESSIBLE_ERROR_DESC'),
        iconName: 'item-danger',
      });

    return placesService.get($stateParams.placeId).catch(() => {
      showError();
      return $q.reject();
    });
  }
}
