import { ImagesViewerComponent } from './images-viewer.component';

export default angular
  .module('sfMobile.components.images-viewer', [
    'sfMobile.services.localization',
    'sfMobile.services.file-system',
    'sfMobile.services.documents-download',
    'sfMobile.components.button-header-close',
    'sfMobile.services.screen-orientation',
  ])
  .component('sfImagesViewer', ImagesViewerComponent).name;
