import { NotificationsListController } from './notifications-list.controller';

export const NotificationsListComponent: ng.IComponentOptions = {
  bindings: {
    onItemRedirect: '&',
  },
  templateUrl:
    'notifications/components/notifications-list/notifications-list.html',
  controller: NotificationsListController,
};
