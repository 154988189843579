import moment from 'moment';

export class EditCalendarEventModalController {
  constructor(
    localizationService,
    calendarEventsService,
    popupRequestService,
    $translate
  ) {
    'ngInject';

    this.localizationService = localizationService;
    this.calendarEventsService = calendarEventsService;
    this.popupRequestService = popupRequestService;
    this.$translate = $translate;
    this.moment = moment;
    this.isRTLNeeded = localizationService.shouldActivateRTL();
  }

  updateCalendarEvent(event, editMode) {
    const popupRequest = this.popupRequestService.show({
      progress: {
        title: this.$translate.instant('EVENT_EDIT_SAVE_PENDING'),
      },
      error: {
        title: this.$translate.instant('EVENT_SAVE_ERROR_TITLE'),
        desc: this.$translate.instant('EVENT_SAVE_ERROR_DESC'),
      },
    });
    const onRetry = () => this.updateCalendarEvent(event, editMode);

    return this.calendarEventsService
      .saveCalendarEvent(event, editMode)
      .then((updatedEvent) => this.onUpdate({ event: updatedEvent }))
      .then(() => popupRequest.onSuccess())
      .then(() => this.onSave()())
      .catch(() => popupRequest.onError(onRetry));
  }
}
