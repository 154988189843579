import { CorporateEventsListController } from './corporate-events-list.controller';

export const CorporateEventsListComponent: ng.IComponentOptions = {
  bindings: {
    events: '<',
    calendarCurrentDate: '<',
    theme: '<',
    onItemClick: '&',
  },
  transclude: true,
  templateUrl:
    'calendar-events/components/calendar/corporate-events/corporate-events-list/corporate-events-list.html',
  controller: CorporateEventsListController,
};
