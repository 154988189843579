import { LogService, LogExceptionHandler } from './log.service';

export default angular
  .module('sfMobile.services.log', [
    'sfMobile.services.sentry',
    'sfMobile.services.datadog',
    'sfMobile.config.server',
  ])
  .service('logService', LogService)
  .decorator('$exceptionHandler', LogExceptionHandler).name;
