import { PopupObjectiveAlreadyCompleteController } from './popup-objective-already-complete.controller';

export const PopupObjectiveAlreadyCompleteComponent: ng.IComponentOptions = {
  bindings: {
    onClose: '&',
    onSuccess: '&',
    errorMessage: '<?',
  },
  transclude: true,
  templateUrl:
    'components/Popup/popup-objective-already-complete/popup-objective-already-complete.html',
  controller: PopupObjectiveAlreadyCompleteController,
};
