import { clone } from 'ramda';
import { APITaskContents, AutoTask, Subtask, Task, TaskStatus } from '..';
import { ObjectId, User } from '../..';
import { CommentsResourceType } from '../../comments/services/comments-api/comments-api.factory';
import {
  CommentsFactory,
  CommentsService,
} from '../../comments/services/comments/comments.factory';
import { APIStore } from '../../places';
import { Form, FormQuestion } from '../../services/API/forms/forms';
import {
  Report,
  ReportCampaignContents,
} from '../../services/API/reports/reports';
import { AccessRightsService } from '../../services/Utils/AccessRights/access-rights.service';
import { DateService } from '../../services/Utils/Dates/date.service';
import { ModalService } from '../../services/Utils/Modal';
import { PopupService } from '../../services/Utils/Popup/popup.service';
import { SF_TASK_ASSIGNEES } from '../constants/task-assignees.constant';
import { SF_TASK_STATUSES } from '../constants/task-statuses.constant';

const ELIGIBLE_AUTOTASK_QUESTIONS_TYPES = [
  'simple',
  'rating',
  'questionRadio',
  'questionCheckbox',
  'formula',
]; // number, scale, multi choice, multi choice with multi answers, formula

export class TaskService {
  isStatus: (status: TaskStatus, typeKeyword: string) => boolean;
  commentsService: CommentsService;
  lastActiveTaskId: string;

  constructor(
    private modalService: ModalService,
    private dateService: DateService,
    private popupService: PopupService,
    private $translate: ng.translate.ITranslateService,
    private TASK_STATUSES: typeof SF_TASK_STATUSES,
    private TASK_ASSIGNEES: typeof SF_TASK_ASSIGNEES,
    commentsFactory: CommentsFactory,
    private accessRightsService: AccessRightsService
  ) {
    'ngInject';

    this.isStatus = (status, typeKeyword) => status === typeKeyword;
    this.commentsService = commentsFactory(CommentsResourceType.TASK);
  }

  /**
   * Show Popup to inform the user the task has been deleted and the action is not permitted
   * @param {Function} onClick - expected a callback when the user click on button
   * @param {Object<{title: string, button: string}>} translationKeys - optional object to change the title or the button translation key
   * @returns {void}
   */
  showTaskDeletedError(
    onClick: () => void,
    translationKeys: { title?: string; button?: string } = {}
  ): void {
    this.popupService.showError(
      {
        title: this.$translate.instant(
          translationKeys.title || 'TASK_DELETE_ERROR_POPUP_TITLE'
        ),
        btnText: this.$translate.instant(
          translationKeys.button || 'TASK_DELETE_ERROR_POPUP_BUTTON_OK'
        ),
        iconName: 'item-danger',
      },
      onClick
    );
  }

  /**
   * Return true if task is overdue and the status is 'todo'
   * @param {date} due_date - expected task object to compare due_date
   * @param {String<TASK_STATUS>} status - optional status to be verified instead of task status
   * @returns {Boolean} Expects to return true if due_date is in past.
   */
  showOverdueTask(due_date: Date, status: TaskStatus): boolean {
    return this.dateService.isOverdue(due_date) && this.isToDo(status);
  }

  /**
   * Return true if task owner_id is the same as the user profile
   * @param {Object<task>} task - expected task object to compare due_date
   * @param {Object<user>} user - expected the user profile
   * @returns {Boolean} Expects to return true if due_date is in past.
   */
  // eslint-disable-next-line class-methods-use-this
  isOwnerOfTask(task: Task, user: User): boolean {
    return user && task && user._id === task.contents.owner_id;
  }

  // eslint-disable-next-line class-methods-use-this
  isArchived(task: Task): boolean {
    return Boolean(task.archived_date);
  }

  /**
   * Return true if task owner_id is the same as the user profile
   * @param {Object<task>} task - expected task object to compare due_date
   * @param {Object<user>} user - expected the user profile
   * @returns {Boolean} Expects to return true if due_date is in past.
   */
  // eslint-disable-next-line class-methods-use-this
  isAssigneeOfTask(task: Task, user: User): boolean {
    return user && task && user._id === task.contents.assignee_id;
  }

  /**
   * It opens sf-task-details component inside a modal. The modal success should return the task saved and the changed status
   * @param {String} taskId - expected taskId in order for task details to know what task to retrieve
   * @param {Object<profile>} profile - expected the user profile
   * @param {Boolean} offlineMode - expected a boolean in case the task is stored local
   * @param {Boolean} isChecklistTab - flag for tasks tab in checklist
   * @returns {Promise<task>} Expects to return a promise with a task inside.
   */
  openTaskDetailsModal(
    taskId: ObjectId,
    profile: User,
    offlineMode: boolean,
    isChecklistTab = false
  ): ng.IPromise<{ task: Task; status: TaskStatus }> {
    const template = `
      <ion-modal-view class="sf_task_details_modal">
        <sf-task-details
          offline-mode="$ctrl.offlineMode"
          task-id="$ctrl.taskId"
          profile="$ctrl.profile"
          is-checklist-tab="$ctrl.isChecklistTab"
          
          on-close="$ctrl.onClose({task})"
          on-save="$ctrl.onSave()">
        </sf-task-details>
      </ion-modal-view>`;

    return this.modalService
      .openAsPromise<{ task: Task; status?: TaskStatus }>(template, {
        taskId,
        offlineMode,
        profile,
        isChecklistTab,
      })
      .catch((e) => {
        if (e.message === this.modalService.onCloseMsg) {
          return e.value;
        }
        throw e;
      });
  }

  /**
   * It opens sf-task-manage component inside a modal. The modal success should return the task saved
   * @param {Object<task>} task - expected task that will be used for Add/Edit
   * @param {Object<profile>} profile - expected user profile to be used for permissions and setting a default owner_id
   * @param {Object<{object1, object2}>} context - expected object that will be used to automatically fill some fields
   * @param {Object<{name, _id}>} context.object1 - an example of object (like place, user, etc...) that can be passed to context
   * @param {Boolean} offlineMode - expected a boolean in case the task should be stored local
   * @returns {Promise<task>} Expects to return a promise with a task inside.
   */
  openTaskManageModal(
    task: Task,
    profile: User,
    context: Record<string, any> = {},
    offlineMode = false
  ): ng.IPromise<{ managedTask: Task }> {
    task = clone(task);
    const template = `
    <ion-modal-view>
      <sf-task-manage
        task="$ctrl.task"
        context="$ctrl.context"
        profile="$ctrl.profile"
        offline-mode="$ctrl.offlineMode"
        on-close="$ctrl.onClose()"
        on-save="$ctrl.onSave()"
      >
      </sf-task-manage>
    </ion-modal-view>`;

    const bindings = { task, profile, context, offlineMode };

    return this.modalService.openAsPromise(template, bindings);
  }

  openTaskGroupTasksChoiceModal(profile: User): ng.IPromise<void> {
    const template = `
    <ion-modal-view>
      <sf-task-group-tasks-choice
        profile="$ctrl.profile"
        on-close="$ctrl.onClose()"
        on-save="$ctrl.onSave()"
      >
      </sf-task-group-tasks-choice>
    </ion-modal-view>`;

    const bindings = { profile };

    return this.modalService.openAsPromise(template, bindings);
  }

  openGroupTaskManageModal(profile: User): ng.IPromise<void> {
    const template = `
    <ion-modal-view>
      <sf-group-task-template
        on-close="$ctrl.onClose()"
        on-save="$ctrl.onSave()"
        profile="$ctrl.profile"
      >
      </sf-group-task-template>
    </ion-modal-view>`;

    const bindings = { profile };

    return this.modalService.openAsPromise(template, bindings);
  }

  /**
   * Show a popup for the user in order for him to agree the delete
   * @returns {Promise<boolean>} Expects to return a promise with a boolean.
   */
  showDeleteAgreeModal(): ng.IPromise<void> {
    return this.popupService.showConfirm({
      title: this.$translate.instant('TASK_DETAILS_ACTIONS_DELETE_POPUP_TITLE'),
      iconName: 'error',
      hasCheckbox: true,
      buttonText: this.$translate.instant(
        'TASK_DETAILS_ACTIONS_DELETE_POPUP_CONFIRM_BUTTON'
      ),
    });
  }

  getTaskStatusTranslationKey(statusKeyword: TaskStatus): string {
    const status = Object.values(this.TASK_STATUSES).filter(
      (s) => s.keyword === statusKeyword
    )[0];

    return status ? status.i18nKey : '';
  }
  isToDo(status: TaskStatus): boolean {
    return this.isStatus(status, this.TASK_STATUSES.TODO.keyword);
  }
  isDone(status: TaskStatus): boolean {
    return (
      this.isStatus(status, this.TASK_STATUSES.DONE_ON_TIME.keyword) ||
      this.isStatus(status, this.TASK_STATUSES.LATE_COMPLETION.keyword)
    );
  }
  isMy(assigneeKey: string): boolean {
    return assigneeKey === this.TASK_ASSIGNEES.MY_TASKS.keyword;
  }
  isOthers(assigneeKey: string): boolean {
    return assigneeKey === this.TASK_ASSIGNEES.OTHERS_TASKS.keyword;
  }
  isDeleted(status: TaskStatus): boolean {
    return this.isStatus(status, this.TASK_STATUSES.DELETED.keyword);
  }

  getFilesPath(taskId: ObjectId): string {
    return `tasks/${taskId}/files`;
  }

  metAutotaskCondition(value: string | number, autoTask: AutoTask): boolean {
    const TASK_OPERATORS = {
      eq: 'eq',
      ne: 'ne',
      lt: 'lt',
      gt: 'gt',
      between: 'between',
    };
    const conditionValue = autoTask?.condition?.value;
    if (value == null || conditionValue == null) {
      return false;
    }

    switch (autoTask.condition.operator) {
      case TASK_OPERATORS.eq:
        return value === autoTask.condition.value;
      case TASK_OPERATORS.ne:
        return value != autoTask.condition.value;
      case TASK_OPERATORS.lt:
        return value < autoTask.condition.value;
      case TASK_OPERATORS.gt:
        return value > autoTask.condition.value;
      case TASK_OPERATORS.between:
        return (
          autoTask.condition.value[0] <= value &&
          value <= autoTask.condition.value[1]
        );
      default:
        return false;
    }
  }

  isEligibleForAutotask(questionType: string): boolean {
    return ELIGIBLE_AUTOTASK_QUESTIONS_TYPES.includes(questionType);
  }

  getQuestionTaskObject(
    question: FormQuestion,
    form: Form,
    report: Report,
    place: APIStore = { contents: {} } as APIStore
  ): Task {
    return {
      question: question,
      form: { ...form.contents, i18n: form.i18n },
      place: {
        _id: place._id,
        ...place.contents,
      },
      contents: {
        report_id: report._id,
        question_id: question._id,
        place_id: report.contents.place_id,
        campaign_id: (report.contents as ReportCampaignContents).campaign_id,
      } as unknown as APITaskContents,
    } as unknown as Task;
  }

  prepareTaskDate(date: Date): string {
    date.setUTCHours(12);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);

    return date.toISOString();
  }

  isAllowedToChangeSubtaskStatus(
    task: Task,
    subtask: Subtask,
    profile: User
  ): boolean {
    // task owner or assignee are allowed
    if (
      this.isOwnerOfTask(task, profile) ||
      this.isAssigneeOfTask(task, profile) ||
      this.accessRightsService.isAdmin()
    ) {
      return true;
    }
    // subtasks without assignees status could be changed by anyone related to a task
    // maybe it worth to call plaxceService.checkPlaceUsersRelations here
    if (!subtask.assignee_id) {
      return true;
    }
    // subtask assignee is allowed
    if (subtask.assignee_id === profile._id) {
      return true;
    }
    // any subtask assignee is allowed to change any subtask status
    if (
      task.contents.subtasks?.find((sub) => sub.assignee_id === profile._id)
    ) {
      return true;
    }
    return false;
  }
  getTaskUpdatePopupText() {
    return {
      progress: {
        title: this.$translate.instant('MANAGE_TASK_EDIT_TITLE_PROGRESS'),
      },
      success: {
        title: this.$translate.instant('MANAGE_TASK_EDIT_TITLE_SUCCESS'),
        iconName: 'thumbsup',
      },
      error: {
        title: this.$translate.instant('MANAGE_TASK_TITLE_ERROR'),
        desc: this.$translate.instant('MANAGE_TASK_EDIT_DESC_ERROR'),
      },
    };
  }
}
