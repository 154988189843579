import { TasksComponent } from './tasks.component';

export default angular
  .module('sfMobile.components.tasks', [
    'sfMobile.services.task',
    'sfMobile.services.pubsub',
    'sfMobile.components.tasks-tabs',
    'sfMobile.components.button-selector',
    'sfMobile.components.message-toaster',
  ])
  .component('sfTasks', TasksComponent).name;
