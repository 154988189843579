export const QuestionFormComponent = {
  bindings: {
    question: '<',
    nodeId: '@',
    report: '=',
    form: '<',
    onValueChange: '&',
    unconfirmedPrefilledQuestions: '=',
    useNewCampaigns: '<',
  },
  templateUrl: 'missions/components/Form-questions/question-form.html',
  transclude: true,
  controller: function QuestionFormController(
    pubSubService,
    reportsService,
    reportScoringService
  ) {
    'ngInject';

    const isDefined = (data) =>
      typeof data !== 'undefined' && data !== null && data !== '';

    this.$onInit = () => {
      this.answers = this.report.contents.answers.filter(
        (answer) =>
          answer.nodes_ids &&
          answer.nodes_ids[0] === this.nodeId &&
          answer.question_id === this.question._id
      );

      this.questionScoreField = this.question.fields.filter(
        (field) => field.type === 'score'
      )[0];
    };

    this.updateProductAnswers = (answers) => {
      // There could be forms with more than  1000 questions so I'd prefer forEach instead of map
      this.report.contents.answers.forEach((answer, i) => {
        const updatedAnswer = answers.find(({ _id }) => _id === answer._id);
        if (!updatedAnswer) {
          return;
        }
        // and replace only a couple product count/sales answers
        this.report.contents.answers[i] = updatedAnswer;
      });
    };

    this.saveAnswer = (values, answers) =>
      this.answers && this.answers.length
        ? this.updateAnswer(values, answers)
        : this.addAnswer(values, answers);

    this.updateAnswer = (values, answers) => {
      const answer = answers[0];
      const setNewValue = (newAnswer) => {
        if (answer._id === newAnswer._id) {
          newAnswer.values = newAnswer.values.map(
            this.updateValues(newAnswer, values)
          );
        }

        return newAnswer;
      };

      this.report.contents.answers =
        this.report.contents.answers.map(setNewValue);
      this.answers = answers.map(setNewValue);
      this.valueChanged();
      return this.answers;
    };

    this.addAnswer = (values, answers, extendedData = {}) => {
      const newAnswer = this.getNewAnswer(values, extendedData);

      this.report.contents.answers.push(newAnswer);
      this.answers = [...answers, newAnswer];
      this.valueChanged();
      return this.answers;
    };

    this.removeAnswer = (answerId, answers) => {
      const cleanAnswer = (answer) => answer._id !== answerId;

      if (this.report.contents.answers) {
        this.report.contents.answers =
          this.report.contents.answers.filter(cleanAnswer);
      }
      this.answers = answers ? answers.filter(cleanAnswer) : [];
      this.valueChanged();
      return this.answers;
    };

    this.updateValues = (newAnswer, newValues) => (value) => {
      const fieldValue = newValues.filter(
        (data) => data.field_id === value.field_id
      )[0];
      const isScoreField =
        this.questionScoreField &&
        this.questionScoreField._id === value.field_id;

      value.value = !isScoreField
        ? isDefined(fieldValue)
          ? fieldValue.value
          : {}.undef
        : reportScoringService.computeScoreForAnswer(
            this.question._id,
            this.form,
            newAnswer
          );
      return value;
    };

    this.getNewAnswer = (values, extendedData) => {
      let newAnswer = this.getDefaultAnswer(this.question, this.nodeId);

      if (extendedData) {
        newAnswer = {
          ...newAnswer,
          ...extendedData,
        };
      }
      newAnswer.values = newAnswer.values.map((val) =>
        this.updateValues(newAnswer, values)(val)
      );
      return newAnswer;
    };

    this.getDefaultAnswer = (question, nodeId) =>
      reportsService.getDefaultAnswer(
        question._id,
        question.section_id,
        nodeId,
        question.fields.map((field) => ({
          field_id: field._id,
          value: {}.undef,
        }))
      );

    this.valueChanged = () => {
      this.unconfirmedPrefilledQuestions.ids =
        this.unconfirmedPrefilledQuestions.ids.filter(
          (id) => id !== this.question._id
        );
      this.onValueChange();
      pubSubService.publish('QUESTION_ANSWER_CHANGED', this.question);
      return true;
    };

    this.confirmPreviousAnswer = () => {
      const questionForm = this[`question_form_${this.question._id}`];

      this.unconfirmedPrefilledQuestions.ids =
        this.unconfirmedPrefilledQuestions.ids.filter(
          (id) => id !== this.question._id
        );
      this.onValueChange();
      pubSubService.publish('FORM_VALUE_UPDATE');
      pubSubService.publish('QUESTION_ANSWER_CHANGED', this.question);

      return questionForm ? questionForm.$setDirty(true) : false;
    };

    this.hasUnconfirmedPreviousAnswers = () => {
      return this.unconfirmedPrefilledQuestions.ids.some(
        (id) => id === this.question._id
      );
    };
  },
};
