export default function routeConfig($stateProvider) {
  'ngInject';

  // Routes.
  $stateProvider
    .state('index.menu-more.missions', {
      url: '/missions',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('index.menu-more.missions.lists', {
      url: '',
      abstract: true,
      template: `
        <ion-view cache-view="false">
          <sf-missions></sf-missions>
        </ion-view>
      `,
    })
    .state('index.menu-more.missions.lists.missions', {
      url: '/list',
      views: {
        'tab-missions-list': {
          template: `
            <sf-missions-list profile="$ctrl.profile"></sf-missions-list>
          `,
          analyticsName: 'Missions List',
          controller: MissionsListController,
          controllerAs: '$ctrl',
        },
      },
    })
    .state('index.menu-more.missions.lists.drafts', {
      url: '/drafts',
      views: {
        'tab-missions-drafts': {
          template: `
            <sf-missions-draft></sf-missions-draft>
          `,
          analyticsName: 'Missions Drafts',
        },
      },
    })
    .state('index.menu-more.missions.lists.ready', {
      url: '/ready',
      views: {
        'tab-missions-ready': {
          template: `
            <sf-missions-ready></sf-missions-ready>
          `,
          analyticsName: 'Missions Ready',
        },
      },
    })
    .state('index.menu-more.missions.lists.sent', {
      url: '/sent',
      views: {
        'tab-missions-sent': {
          template: `
            <sf-missions-sent></sf-missions-sent>
          `,
          analyticsName: 'Missions Sent',
        },
      },
    })
    .state('index.menu-more.missions.campaign', {
      url: '/{campaignId:[0-9a-fA-F]{24}}?reportId&locationId',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('index.menu-more.missions.campaign.details', {
      url: '?missionType',
      template: `
        <ion-view class="sf_mission" cache-view="false">
          <sf-mission
            form="$ctrl.form"
            profile="$ctrl.profile"
            place="$ctrl.place"
          >
          </sf-missions>
        </ion-view>
      `,
      controller: CampaignViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Campaign Detail',
      resolve: {
        rsvpForm: getRsvpForm,
        rsvpPlace: getRsvpPlaceCampaign,
      },
    })
    .state('index.menu-more.missions.campaign.form', {
      url: '/form/:reportId?missionId&questionId&online&viewRestored',
      params: {
        referer: null,
      },
      template: `
        <ion-view class="sf_form"
          cache-view="false"
          can-swipe-back="false">

          <sf-form
            report="$ctrl.report"
            form="$ctrl.form"
            place="$ctrl.place"
            user="$ctrl.user"
            referer="$ctrl.referer"
          >
          </sf-form>

        </ion-view>
      `,
      controller: FormViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Form',
      resolve: {
        rsvpCurReport: getRsvpCurReport,
        rsvpForm: getRsvpForm,
        rsvpReport: getRsvpReport,
        rsvpMission: getRsvpMission,
        rsvpPlace: getRsvpPlace,
        referer: getReferer,
      },
    })
    .state('index.menu-more.missions.report', {
      url: '/{campaignId:[0-9a-fA-F]{24}}/report/{reportId:[0-9a-fA-F]{24}}?online',
      params: {
        referer: null,
        targetTab: null,
      },
      template: `
        <ion-view class="sf_mission sf_report"
          cache-view="false">
          <sf-view sf-tab-bar-hide="true">
            <sf-nav-bar
              display-back-button="true"
              fallback-back-link="index.activity.details">

                <div class="buttons"
                     ng-class= "{'buttons-left': $ctrl.isRTLNeeded, 'buttons-right': !$ctrl.isRTLNeeded }"
                >
                  <button
                    ng-if="$ctrl.isReportEditable"
                    class="button button-clear button-small button-light button-header"
                    ui-sref="index.menu-more.missions.campaign.form({
                      campaignId: $ctrl.report.contents.form_id,
                      reportId: $ctrl.report._id,
                      online: $ctrl.online,
                      referer: $ctrl.referer,
                    })">
                    {{ ::'REPORT_INFOS_EDIT_LINK' | translate }}
                  </button>
                </div>
              </sf-nav-bar>
              <sf-report
                form="$ctrl.form"
                report="$ctrl.report"
                profile="$ctrl.profile"
                place="$ctrl.place"
                target-tab="$ctrl.targetTab">
              </sf-report>
            </sf-view>
          </ion-view>
        `,
      resolve: {
        rsvpReport: getReportRsvpReport,
        rsvpForm: getRsvpForm,
        rsvpPlace: getReportRsvpPlace,
        referer: getReferer,
      },
      controller: ReportInfosViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Report',
    });

  // @ngInject
  function MissionsListController(rsvpProfile) {
    this.profile = rsvpProfile;
  }

  // @ngInject
  function CampaignViewController(
    routerService,
    rsvpForm,
    rsvpProfile,
    rsvpPlace
  ) {
    this.form = rsvpForm;
    this.profile = rsvpProfile;
    this.place = rsvpPlace;
    this.goBack = () => routerService.goBack();
  }
  // @ngInject
  // eslint-disable-next-line max-params
  function FormViewController(
    rsvpForm,
    rsvpReport,
    rsvpPlace,
    rsvpProfile,
    referer
  ) {
    this.report = rsvpReport;
    this.form = rsvpForm;
    this.place = rsvpPlace;
    this.user = rsvpProfile;
    // Referer is an ui-state name where the user should be redirected after an edit of the report
    this.referer = referer;
  }
  // @ngInject
  // eslint-disable-next-line max-params
  function ReportInfosViewController(
    localizationService,
    $stateParams,
    rsvpForm,
    rsvpReport,
    rsvpProfile,
    rsvpPlace,
    routerService,
    referer,
    SF_MISSION_PREFERENCES,
    reportValidationService
  ) {
    this.localizationService = localizationService;
    this.online = !!$stateParams.online;
    this.form = rsvpForm;
    this.report = rsvpReport;
    this.profile = rsvpProfile;
    this.place = rsvpPlace;
    this.targetTab = $stateParams.targetTab;
    this.isRTLNeeded = localizationService.shouldActivateRTL();
    // Referer is an ui-state name where the user should be redirected after an edit of the report
    this.referer = referer;
    this.goBack = () => routerService.goBack();
    this.isReportEditable = true;

    if (this.form.contents.preferences) {
      const allowEditProp = this.form.contents.preferences.filter(
        (prop) => prop.name === SF_MISSION_PREFERENCES.ALLOW_EDIT
      )[0];

      this.isReportEditable = allowEditProp ? allowEditProp.value : true;
    }

    if (reportValidationService.isRevision(this.report)) {
      this.isReportEditable = true;
    }
  }

  // @ngInject
  function getRsvpForm(rsvpProfile, $stateParams, formsService) {
    return formsService.get($stateParams.campaignId);
  }

  // @ngInject
  function getRsvpCurReport(rsvpProfile, $stateParams, reportsService) {
    return $stateParams.online === 'true'
      ? reportsService.crud.getRemote($stateParams.reportId, {})
      : getReport($stateParams.reportId);

    function getReport(id) {
      return reportsService.crud
        .queryLocal({ _id: id })
        .then((reports) => (reports.length ? reports[0] : null))
        .catch(() => null);
    }
  }
  // @ngInject
  // eslint-disable-next-line max-params
  function getRsvpReport(
    $stateParams,
    rsvpCurReport,
    formService,
    rsvpForm,
    rsvpMission,
    rsvpPlace,
    rsvpProfile
  ) {
    return (
      rsvpCurReport ||
      formService.initReport($stateParams.reportId, {
        mission: rsvpMission,
        place: rsvpPlace,
        form: rsvpForm,
        profile: rsvpProfile,
      })
    );
  }
  // @ngInject
  function getRsvpMission($q, $stateParams, missionsService) {
    return $stateParams.missionId
      ? getMission($stateParams.missionId)
      : $q.when(null);

    function getMission(id) {
      return missionsService
        .queryLocal({ _id: id })
        .then((missions) => (missions.length ? missions[0] : null))
        .catch(() => null);
    }
  }

  // ------------------------------------------------
  // ⚠️ The rsvpProfile exist in the dependencies
  // to be able to load the views on the mobile
  // ------------------------------------------------

  // @ngInject
  // eslint-disable-next-line max-params
  function getRsvpPlace(
    rsvpProfile,
    $q,
    $stateParams,
    placesService,
    rsvpMission,
    rsvpCurReport,
    popupService,
    $translate
  ) {
    const placeId = getPlaceId($stateParams, rsvpMission, rsvpCurReport);
    const getPlace = (placeId) => {
      return placesService.get(placeId).catch((err) => {
        popupService.showError({
          title: $translate.instant('FORM_PLACE_UNAVAILABLE_ERROR_TITLE'),
          desc: $translate.instant('FORM_PLACE_UNAVAILABLE_ERROR_DESC'),
        });
        throw err;
      });
    };

    return placeId ? getPlace(placeId) : $q.when(null);
  }

  // @ngInject
  function getReferer($stateParams) {
    return $stateParams.referer;
  }

  function getPlaceId(params, mission, report) {
    return (
      params.locationId ||
      (mission && mission.contents.place_id) ||
      (report && report.contents.place_id)
    );
  }
  // @ngInject
  function getRsvpPlaceCampaign(rsvpProfile, $q, $stateParams, placesService) {
    const placeId = $stateParams.locationId;

    return placeId ? placesService.get(placeId) : $q.when(null);
  }
  // @ngInject
  function getReportRsvpReport(rsvpProfile, $stateParams, reportsService) {
    return $stateParams.online === 'true'
      ? reportsService.crud.getRemote($stateParams.reportId, {
          mode: 'expanded',
        })
      : reportsService.crud.get($stateParams.reportId);
  }

  // @ngInject
  function getReportRsvpPlace(rsvpProfile, $q, rsvpReport, placesService) {
    const placeId = rsvpReport.contents.place_id;

    return placeId
      ? placesService
          .queryLocal({ _id: placeId })
          .then((places) =>
            places.length
              ? places[0]
              : placesService.getRemote(placeId, {}.undef, true)
          )
      : $q.when(null);
  }
}
