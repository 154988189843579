const FIELD_KEYWORD = 'field';
const STORE_KEYWORD = 'store';
const ANIMATOR_KEYWORD = 'animator';

const FIELD_MENU_MORE = [
  'newsfeed',
  'documentLibrary',
  'missions',
  'campaigns',
  'legacyCampaigns',
  'campaignsActivity',
  'salesDashboard',
  'tasks',
  'changeRequests',
  'merchandising',
  'reportsValidation',
  'products',
  'userAgreement',
  'privacyPolicy',
];
const ANIMATOR_MENU_MORE = FIELD_MENU_MORE;
const STORE_MENU_MORE = [
  'missions',
  'campaigns',
  'legacyCampaigns',
  'campaignsActivity',
  'salesDashboard',
  'calendarEvents',
  'tasks',
  'changeRequests',
  'merchandising',
  'reportsValidation',
  'products',
  'userAgreement',
  'privacyPolicy',
];
const DEFAULT_MENU_MORE = STORE_MENU_MORE;

const USER_EXPERIENCE = {
  FIELD_KEYWORD,
  STORE_KEYWORD,
  ANIMATOR_KEYWORD,
  NAV: {
    [FIELD_KEYWORD]: ['calendarEvents', 'stores', 'chat'],
    [ANIMATOR_KEYWORD]: ['calendarEvents', 'stores', 'chat'],
    [STORE_KEYWORD]: ['newsfeed', 'documentLibrary', 'chat'],
    DEFAULT: ['newsfeed', 'documentLibrary', 'chat'],
  },
  MENU_MORE: {
    [FIELD_KEYWORD]: FIELD_MENU_MORE,
    [ANIMATOR_KEYWORD]: ANIMATOR_MENU_MORE,
    [STORE_KEYWORD]: STORE_MENU_MORE,
    DEFAULT: DEFAULT_MENU_MORE,
  },
};

export default angular
  .module('sfMobile.constants.user-experience', [])
  .constant('SF_USER_EXPERIENCE', USER_EXPERIENCE).name;
