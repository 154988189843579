import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import { PlatformService } from '../../../services/Utils/Platform';

export const DatePickerButtonComponent: ng.IComponentOptions = {
  bindings: {
    onChange: '&',
  },
  transclude: true,
  templateUrl: 'components/Inputs/date-picker-button/date-picker-button.html',
  controller: class DatePickerController implements ng.IController {
    // bindings
    onChange: (arg: { date: Date }) => void;

    // class fields
    date: Date;
    wrapId: string;
    inputId: string;
    transcludedId: string;
    isIos = false;

    constructor(
      private objectIdService: ObjectIdService,
      private $timeout: ng.ITimeoutService,
      private $element: ng.IRootElementService,
      private platformService: PlatformService
    ) {
      'ngInject';
    }

    $onInit(): void {
      // a unique id is a must have on Android devices (but not on MOTW or iOS)
      this.wrapId = `sf_datePicker_wrap${this.objectIdService.getUniqId()}`;
      this.inputId = `sf_datePicker_input${this.objectIdService.getUniqId()}`;
      this.transcludedId = `sf_datePicker_trans${this.objectIdService.getUniqId()}`;
      this.isIos = this.platformService.isiOS();
    }

    $postLink(): void {
      this.$timeout(() => {
        const wrapper = this.$element[0].querySelector(
          `#${this.wrapId}`
        ) as HTMLDivElement;
        const dateInput = this.$element[0].querySelector(
          `#${this.inputId}`
        ) as HTMLDivElement;
        if (this.isIos) {
          // done btn on iOS
          dateInput?.addEventListener('blur', (e) => this.onDateChange(e));
        } else {
          dateInput?.addEventListener('input', (e) => this.onDateChange(e));
        }
        this.setPickerSize(dateInput, wrapper);
      });
    }

    // ng-model and/or ng-change may produce buggy behavior on iOS
    // that's why I used addEventListener, boolean for iOS and data-tap disabled
    onDateChange(event: Event): void {
      const date = (event.target as HTMLInputElement).valueAsDate || new Date();
      this.onChange({ date });
    }

    setPickerSize(...elements: HTMLDivElement[]): void {
      const { width, height } = this.getTranscludedSize();

      elements.forEach((element) => {
        element.style.width = `${width}px`;
        element.style.height = `${height}px`;
      });
    }

    getTranscludedSize(): { width: number; height: number } {
      const transcludedElement = this.$element[0].querySelector(
        `#${this.transcludedId}`
      ) as HTMLSpanElement;
      return {
        width: transcludedElement?.offsetWidth,
        height: transcludedElement?.offsetHeight,
      };
    }
  },
};
