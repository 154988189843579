export const QuestionDateComponent = {
  bindings: {
    question: '<',
    hasError: '<',
    questionOptions: '<',
    answers: '=',
    actionClickedFn: '=?',
    previousAnswers: '<',
    isDisabled: '<?',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-date/question-date.html',
  controller: function QuestionDateController(FORM_QUESTION_ACTIONS) {
    'ngInject';

    this.$onInit = () => {
      const curValue = this.answers.length
        ? this.answers[0].values[0].value
        : null;

      // We set the reference hour to 0,0,0,0 to get the very begining of the day
      const today = new Date().setHours(0, 0, 0, 0);

      this.firstField = this.question.fields[0];
      this.value = curValue;
      this.minDate =
        this.firstField.anterior === false ? new Date(today) : null;

      this.actionClickedFn = this.actionClicked;
    };

    this.onValueChange = (value) => {
      var values = value ? this.getValues(value) : null;

      this.answers = this.updateAnswers(values, this.answers);
    };
    this.getValues = (value) => {
      var values = [{ field_id: this.firstField._id, value: value }];

      return values;
    };
    this.updateAnswers = (values, answers) => {
      var answer = this.answers[0] || null;

      return values
        ? this.sfQuestionForm.saveAnswer(values, answers)
        : answer
        ? this.sfQuestionForm.removeAnswer(answer._id, answers)
        : answers;
    };
    this.actionClicked = (action) => {
      switch (action) {
        case FORM_QUESTION_ACTIONS.RESET_PREFILL:
          return this.resetToPrefillValue();
        default:
          return false;
      }
    };
    this.resetToPrefillValue = () => {
      if (this.previousAnswers && this.previousAnswers.length) {
        this.value = this.previousAnswers[0].values[0].value;
        this.onValueChange(this.value);
      }
    };
  },
};
