"use strict";

var schemaUtils = require('../utils/schema');

var schema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Places list',
  type: 'object',
  additionalProperties: false,
  required: ['name', 'places_ids', 'managers_ids', 'owner_id', 'organisation_id'],
  properties: {
    name: {
      type: 'string'
    },
    description: {
      type: 'string'
    },
    places_ids: schemaUtils.oneToSeveral({
      minItems: 1
    }),
    managers_ids: schemaUtils.oneToSeveral(),
    organisation_id: schemaUtils.oneToOne(),
    owner_id: schemaUtils.oneToOne()
  }
};
module.exports = schema;