import { LayoutController } from './layout.controller';

export const LayoutComponent = {
  bindings: {
    organisation: '<',
  },
  transclude: true,
  templateUrl: 'layout/layout.html',
  controller: LayoutController,
};
