import { PovService } from '../../../services/API/POV/pov.service';
import {
  APIStatsFilters,
  AnalyticsAPICount,
  APISeriesFilters,
  APISeries,
} from '../../types/analytics';

export class AnalyticsApiService {
  private baseUrl = '/statistics';

  /* @ngInject */
  constructor(
    private $http: ng.IHttpService,
    private sfPOVService: PovService,
    private $translate: ng.translate.ITranslateService
  ) {}

  getCount(
    entity: string,
    filters: APIStatsFilters,
    params = {}
  ): ng.IPromise<AnalyticsAPICount> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/${entity}/count`, {
        pov: 'organisation',
      })
      .then((uri) =>
        this.$http.post<AnalyticsAPICount>(uri, filters, { params })
      )
      .then((res) => res.data)
      .then((res) => {
        return handleArchivedResources(res, filters, this.$translate);
      });
  }

  getSeries(
    entity: string,
    filters: APISeriesFilters,
    params = {}
  ): ng.IPromise<APISeries> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/${entity}/series`, {
        pov: 'organisation',
      })
      .then((uri) => this.$http.post<APISeries>(uri, filters, { params }))
      .then((res) => res.data)
      .then((res) => {
        return handleArchivedResources(res, filters, this.$translate);
      });
  }
}

function handleArchivedResources(res, filters, translateService) {
  if (filters.groupBy && res.meta[filters.groupBy]) {
    const parsedRes = { ...res };
    const keyToUpdate = filters.groupBy === 'users' ? 'lastName' : 'name';

    parsedRes.meta[filters.groupBy].forEach((metaEntry) => {
      if (metaEntry.archived) {
        metaEntry.contents = {
          ...metaEntry.contents,
          [keyToUpdate]:
            metaEntry.contents[keyToUpdate] +
            translateService.instant('ARCHIVED_SUFFIX'),
        };
      }
    });

    return parsedRes;
  }

  return res;
}
