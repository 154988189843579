export const SF_MANAGE_TASK_VALIDATORS = {
  name: {
    required: true,
    maxlength: 150,
  },
  description: {
    maxlength: 1000,
  },
  due_date: {
    required: true,
  },
  organisation: {
    required: true,
  },
  priority: {
    required: true,
  },
} as const;

export default angular
  .module('sfMobile.constants.task-manage-form', [])
  .constant('MANAGE_TASK_VALIDATORS', SF_MANAGE_TASK_VALIDATORS).name;
