import { PopupService } from '../../services/Utils/Popup/popup.service';

export class MerchandisingPopupService {
  constructor(
    private $translate: ng.translate.ITranslateService,
    private popupService: PopupService,
    private popupRequestService
  ) {
    'ngInject';
  }

  showRejectLongPrompt() {
    return this.popupService.prompt({
      title: this.$translate.instant('MERCHANDISING_REJECT_REASON_TITLE'),
      placeholder: this.$translate.instant(
        'MERCHANDISING_REJECT_REASON_PLACEHOLDER'
      ),
      submit: this.$translate.instant('MERCHANDISING_REJECT_REASON_SUBMIT'),
      required: true,
      long: true,
    });
  }

  showRetakeLeaveConfirm() {
    return this.popupService.showConfirm({
      title: this.$translate.instant(
        'MERCHANDISING_RETAKE_LEAVE_CONFIRM_TITLE'
      ),
      description: this.$translate.instant(
        'MERCHANDISING_RETAKE_LEAVE_CONFIRM_DESC'
      ),
      iconName: 'document_error',
      buttonText: this.$translate.instant(
        'MERCHANDISING_RETAKE_LEAVE_CONFIRM_BUTTON'
      ),
    });
  }

  showReportSendingRequest(onCancel) {
    const REPORT_SENDING_POPUP_CONFIG = {
      progress: {
        title: this.$translate.instant(
          'MERCHANDISING_PHOTO_REPLACER_SENDING_TITLE'
        ),
        desc: this.$translate.instant(
          'MERCHANDISING_PHOTO_REPLACER_SENDING_DESC'
        ),
      },
      success: {
        title: this.$translate.instant(
          'MERCHANDISING_PHOTO_REPLACER_SUCCEED_TITLE'
        ),
        desc: this.$translate.instant(
          'MERCHANDISING_PHOTO_REPLACER_SUCCEED_DESC'
        ),
        iconName: 'sync_reports_success',
      },
      error: {
        title: this.$translate.instant(
          'MERCHANDISING_PHOTO_REPLACER_ERROR_TITLE'
        ),
        desc: this.$translate.instant(
          'MERCHANDISING_PHOTO_REPLACER_ERROR_DESC'
        ),
      },
    };

    return this.popupRequestService.show(REPORT_SENDING_POPUP_CONFIG, onCancel);
  }

  showTakePictureError() {
    return this.popupService.showError({
      title: this.$translate.instant(
        'MERCHANDISING_PHOTO_REPLACER_TAKE_PICTURE_ERROR'
      ),
      btnText: this.$translate.instant(
        'MERCHANDISING_PHOTO_REPLACER_TAKE_PICTURE_BUTTON'
      ),
    });
  }

  showDrawLeaveConfirm() {
    return this.popupService.showConfirm({
      title: this.$translate.instant('MERCHANDISING_DRAW_LEAVE_CONFIRM_TITLE'),
      description: this.$translate.instant(
        'MERCHANDISING_DRAW_LEAVE_CONFIRM_DESC'
      ),
      iconName: 'document_error',
      buttonText: this.$translate.instant(
        'MERCHANDISING_DRAW_LEAVE_CONFIRM_BUTTON'
      ),
    });
  }

  showDrawingSendingProgress(onCancel) {
    const DRAWING_SENDING_POPUP_CONFIG = {
      progress: {
        title: this.$translate.instant('MERCHANDISING_DRAWING_SENDING_TITLE'),
        desc: this.$translate.instant('MERCHANDISING_DRAWING_SENDING_DESC'),
      },
      success: {
        title: this.$translate.instant('MERCHANDISING_DRAWING_SUCCEED_TITLE'),
        desc: this.$translate.instant('MERCHANDISING_DRAWING_SUCCEED_DESC'),
        iconName: 'sync_reports_success',
      },
      error: {
        title: this.$translate.instant('MERCHANDISING_DRAWING_ERROR_TITLE'),
        desc: this.$translate.instant('MERCHANDISING_DRAWING_ERROR_DESC'),
      },
    };

    return this.popupRequestService.show(
      DRAWING_SENDING_POPUP_CONFIG,
      onCancel
    );
  }

  showDrawingError() {
    return this.popupService.showError({
      title: this.$translate.instant('MERCHANDISING_DRAWING_ERROR_TITLE'),
      btnText: this.$translate.instant('MERCHANDISING_DRAWING_ERROR_DESC'),
    });
  }
}
