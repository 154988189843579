import { Moment, MomentInput } from 'moment-timezone';
import { APIStore } from '../../../../';
import { ObjectId } from '../../../../..';
import { CalendarEvent } from '../../../../../calendar-events/types';
import { DateService } from '../../../../../services/Utils/Dates/date.service';
import { ErrorMessagesService } from '../../../../../services/Utils/ErrorMessages/error-messages.service';
import { PubSubService } from '../../../../../services/Utils/PubSub/pubsub.service';

type LoadEventsPromise = ng.IPromise<void>;

export class PlaceCalendarEventsListController
  implements ng.IComponentController
{
  // bindings
  place: APIStore;

  // fields
  dataListener: () => void;
  getDateFormatted: (input?: MomentInput) => string;
  onClickOpenCreateCalendarEventModal: () => unknown;

  date: Moment;
  daysWithData = [];
  events: CalendarEvent[] = [];
  timezone: string;
  dateAsHTML5Date: string;
  lastUpdateDate: string;
  isLoading = false;
  isSyncFailed: boolean;
  canRequestChange: boolean;
  errorMessage: string;

  // eslint-disable-next-line max-params
  constructor(
    private $q: ng.IQService,
    private pubSubService: PubSubService,
    private dateService: DateService,
    private calendarEventsService,
    private preferencesService,
    private dateFormatService,
    private synchronizeService,
    private modalService,
    private calendarEventsNotificationsService,
    private errorMessagesService: ErrorMessagesService,
    private eventsTransformService,
    private eventChangeRequestsService
  ) {
    'ngInject';
    this.getDateFormatted = this.dateFormatService.getDateFormatted;
    this.dataListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
      () => {
        this.isSyncFailed = false;
        this.lastUpdateDate = this.synchronizeService.getLastUpdateDate();
        return this.loadCalendarEvents();
      }
    );
  }

  $onInit(): ng.IPromise<[void, void]> {
    this.setSelectedDate(this.dateService.toMoment(new Date()));

    return this.$q.all([
      this.eventChangeRequestsService
        .canRequestChange()
        .then((canRequestChange) => {
          this.canRequestChange = canRequestChange;
        }),
      this.preferencesService.getTimezone().then((timezone) => {
        this.timezone = timezone;
        return this.loadCalendarEvents({ online: true });
      }),
    ]);
  }

  $onDestroy(): void {
    this.dataListener();
  }

  setSelectedDate(date: MomentInput): void {
    this.date = this.dateService.toMoment(date);
    this.dateAsHTML5Date = this.dateFormatService.getHtml5DateFormatted(date);
  }

  onMonthChanged(momentDate: Moment): LoadEventsPromise {
    const sameDayNewMonth = momentDate.set('date', this.date.get('date'));

    this.setSelectedDate(sameDayNewMonth);
    return this.loadCalendarEvents({ online: true });
  }

  goToToday(): LoadEventsPromise {
    this.setSelectedDate(this.dateService.toMoment(new Date()));
    return this.loadCalendarEvents({ online: true });
  }

  goToPreviousWeek(): LoadEventsPromise {
    this.setSelectedDate(this.date.subtract(1, 'weeks'));
    return this.loadCalendarEvents({ online: true });
  }

  goToNextWeek(): LoadEventsPromise {
    this.setSelectedDate(this.date.add(1, 'weeks'));
    return this.loadCalendarEvents({ online: true });
  }

  goToPreviousDay(): LoadEventsPromise {
    const initialDate = this.dateService.toMoment(this.date);

    this.setSelectedDate(this.date.subtract(1, 'days'));
    return this.loadCalendarEvents({
      online: !this.dateService.isSameWeek(initialDate, this.date),
    });
  }

  goToNextDay(): LoadEventsPromise {
    const initialDate = this.dateService.toMoment(this.date);

    this.setSelectedDate(this.date.add(1, 'days'));
    return this.loadCalendarEvents({
      online: !this.dateService.isSameWeek(initialDate, this.date),
    });
  }

  onCalendarDateClick(date: MomentInput): LoadEventsPromise {
    this.setSelectedDate(date);
    return this.loadCalendarEvents();
  }

  loadCalendarEvents(options = { online: false }): LoadEventsPromise {
    const { date } = this;
    const api = this.calendarEventsService.getPeriodCalendarEventsList(
      date,
      this.place
    );

    return api
      .offline()
      .then(
        (events) => {
          Object.assign(
            this,
            this.eventsTransformService.transformCalendarEventData(
              this.getFormattedEvents(events),
              date
            )
          );
          this.isLoading = true;
          return options.online
            ? api.online().catch((e) => {
                this.errorMessagesService.display(e, {
                  inlineErrorMessageUpdater: (message) => {
                    this.errorMessage = message;
                  },
                });
                throw e;
              })
            : this.$q.when(events);
        },
        () => {
          this.daysWithData = [];
          this.events = [];
        }
      )
      .then((events) => {
        if (options.online && date === this.date) {
          Object.assign(
            this,
            this.eventsTransformService.transformCalendarEventData(
              this.getFormattedEvents(events),
              date
            )
          );
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getFormattedEvents(events: CalendarEvent[]): CalendarEvent[] {
    return events.map((event) => {
      delete event.place;
      return event;
    });
  }

  onCalendarEventCreate(event: CalendarEvent): LoadEventsPromise {
    this.calendarEventsNotificationsService.schedule(event);
    this.setSelectedDate(
      this.dateService.toMoment(
        this.dateService
          .getTimezoneDateFromUtc(
            event.contents.start_dateTime,
            event.contents.timezone.name
          )
          .toArray()
      )
    );

    return this.loadCalendarEvents({ online: true });
  }

  onCalendarEventUpdate(events: CalendarEvent[]): LoadEventsPromise {
    this.calendarEventsNotificationsService.unschedule(events);
    this.calendarEventsNotificationsService.schedule(events);

    return this.loadCalendarEvents({ online: true });
  }

  onCalendarEventDelete(eventId: ObjectId): LoadEventsPromise {
    this.calendarEventsNotificationsService.unschedule({ _id: eventId });

    return this.loadCalendarEvents({ online: true });
  }

  onAddButtonClick(): void {
    return this.canRequestChange
      ? this.openRequestEventCreationModal()
      : this.openAddCalendarEventModal();
  }

  openAddCalendarEventModal(): void {
    const template = `
      <sf-create-calendar-event-modal
        event-date="$ctrl.eventDate"
        place="$ctrl.place"
        on-create="$ctrl.onCalendarEventCreate(event)"
        on-close="$ctrl.onClose()">
      </sf-create-calendar-event-modal>
    `;

    this.modalService.open(
      template,
      {
        eventDate: this.date,
        place: this.place,
        onCalendarEventCreate: (event) => this.onCalendarEventCreate(event),
      },
      { hardwareBackButtonClose: false }
    );
  }

  openRequestEventCreationModal(): void {
    const template = `
      <sf-request-event-creation-modal
        event-date="$ctrl.eventDate"
        place="$ctrl.place"
        on-close="$ctrl.onClose()">
      </sf-request-event-creation-modal>
    `;

    this.modalService.open(
      template,
      {
        eventDate: this.date,
        place: this.place,
      },
      { hardwareBackButtonClose: false }
    );
  }

  onCalendarEventAdded(): LoadEventsPromise {
    return this.loadCalendarEvents({ online: true });
  }

  showLoader(): boolean {
    return this.events.length === 0 && this.isLoading;
  }

  reload(): LoadEventsPromise {
    return this.loadCalendarEvents({ online: true });
  }
}
