import { Store } from '../../types/campaign-completion';
import { IMAGE_SIZES } from '../../../../constants/image-sizes.constant';

export class StoreCompletionStatsController {
  placePicture: string | null = null;
  store: Store;
  useNewCampaigns: boolean;
  storeCompletionTranslation: string;

  /* @ngInject */
  constructor(
    private $translate: ng.translate.ITranslateService,
    private placesService,
    private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
    private profileService,
    private organisationsService
  ) {}

  $onInit(): ng.IPromise<string> {
    this.placePicture = null;
    this.profileService
      .getProfile()
      .then((profile) =>
        this.organisationsService.getProfileOrganisation(profile)
      )
      .then((organisation) => {
        this.useNewCampaigns = organisation.useNewCampaigns;

        if (this.useNewCampaigns) {
          this.storeCompletionTranslation = this.$translate.instant(
            'STORE_COMPLETION_REPORTS_CHECKLISTS'
          );
        } else {
          this.storeCompletionTranslation = this.$translate.instant(
            'STORE_COMPLETION_REPORTS'
          );
        }
      });

    return this.retrieveStoreImages();
  }

  retrieveStoreImages(): ng.IPromise<string> {
    return this.placesService
      .getPlacePicture(this.store, this.SF_IMAGE_SIZES.SQUARE_SMALL)
      .then((url: string) => {
        this.placePicture = url;
      });
  }
}
