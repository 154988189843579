import { _handleError } from '../helpers/error';
import { DEFAULT_PARAMETERS } from '../helpers/default-parameters';
import { defaultsDeep } from '../helpers/utils';
// 1. extend & test validity params + set itemEmbedding
export const _extendAndValidate = (dashboardContent) => {
    // remove null params before extend, not to let null override defaults remove not used params as well
    const defaultKeys = Object.keys(DEFAULT_PARAMETERS);
    const errorMessages = [];
    const requiredStringParams = [
        'appServer',
        'language',
        'apiHost'
    ];
    const requiredStringOrEmptyParams = [
        'key',
        'token',
        'screenMode',
        'timezoneId',
        'itemId',
        'container',
        'loaderBackground',
        'loaderFontColor',
        'loaderSpinnerColor',
        'loaderSpinnerBackground'
    ];
    const objectParams = ['itemDimensions'];
    const screenModes = [
        'mobile',
        'tablet',
        'desktop',
        'largeScreen',
        'fixed',
        'auto'
    ];
    for (const key of defaultKeys) {
        if (dashboardContent.params[key] === null) {
            delete dashboardContent.params[key];
        }
    }
    // extend with the default parameters before doing a check
    dashboardContent.params = defaultsDeep(dashboardContent.params, DEFAULT_PARAMETERS);
    if ((!dashboardContent.params.dashboardId
        || typeof dashboardContent.params.dashboardId !== 'string')
        && (!dashboardContent.params.dashboardSlug
            || typeof dashboardContent.params.dashboardSlug !== 'string')) {
        delete dashboardContent.params.dashboardId;
        delete dashboardContent.params.dashboardSlug;
    }
    // check strings that can not be empty
    for (const key of requiredStringParams) {
        if (typeof dashboardContent.params[key] !== 'string') {
            delete dashboardContent.params[key];
            errorMessages.push(`${key} needs to be of type string.`);
        }
    }
    // check strings or empty
    for (const key of requiredStringOrEmptyParams) {
        if (typeof dashboardContent.params[key] !== 'string'
            && dashboardContent.params[key] !== null
            && dashboardContent.params[key] !== undefined) {
            delete dashboardContent.params[key];
            errorMessages.push(`${key} needs to be of type string or empty.`);
        }
    }
    if (dashboardContent.params.screenMode
        && !screenModes.includes(dashboardContent.params.screenMode)) {
        const validTypesString = screenModes.map((mode) => `"${mode}"`).join(', ');
        errorMessages.push(`"screenMode" needs should be one of these types: ${validTypesString}`);
        dashboardContent.params.screenMode = 'auto';
    }
    // set itemEmbedding if item
    if (dashboardContent.params.itemId) {
        dashboardContent.params.itemEmbedding = true;
    }
    for (const key of objectParams) {
        if (typeof dashboardContent.params[key] !== 'object'
            && dashboardContent.params[key] !== null) {
            delete dashboardContent.params[key];
            errorMessages.push(`${key} needs to be of type object or empty.`);
        }
    }
    // check boolean
    if (typeof dashboardContent.params.switchScreenModeOnResize !== 'boolean') {
        errorMessages.push('switchScreenModeOnResize needs to be of type boolean.');
        dashboardContent.params.switchScreenModeOnResize = true;
    }
    // check itemDimensions
    if (dashboardContent.params.itemDimensions) {
        const itemDimensionsKeys = [
            'width',
            'height'
        ];
        for (const key of itemDimensionsKeys) {
            if (!['string', 'number'].includes(typeof dashboardContent.params.itemDimensions[key])
                && dashboardContent.params.itemDimensions[key] !== null) {
                errorMessages.push(`itemDimensions ${key} needs to be of type string, number or empty.`);
            }
        }
    }
    // extend again with default parameters to make up for the deleted invalid properties
    dashboardContent.params = defaultsDeep(dashboardContent.params, DEFAULT_PARAMETERS);
    // check screenMode in list & set itemDimensions if itemEmbedding
    if (dashboardContent.params.screenMode
        && dashboardContent.params.screenMode !== 'auto') {
        dashboardContent.params.switchScreenModeOnResize = false;
    }
    if (dashboardContent.params.itemDimensions.width !== 'auto') {
        dashboardContent.params.itemDimensions.width
            = Number.parseInt(`${dashboardContent.params.itemDimensions.width}`.replace('px', ''), 10) || 'auto';
    }
    if (dashboardContent.params.itemDimensions.height !== 'auto') {
        dashboardContent.params.itemDimensions.height
            = Number.parseInt(`${dashboardContent.params.itemDimensions.height}`.replace('px', ''), 10) || 'auto';
    }
    dashboardContent.params.itemEmbedding = !!dashboardContent.params.itemId;
    if (dashboardContent.params.error || errorMessages.length > 0) {
        _handleError(errorMessages.join('\n'), dashboardContent.params);
    }
};
