const COMPONENT_FILE_NAME = 'check-choices-modal';
const COMPONENT_PATH =
  'missions/components/Form-questions/question-check/components/';

export const CheckChoicesModalComponent = {
  bindings: {
    onClose: '&',
    choices: '<',
    question: '<',
    hasFreeAnswer: '<',
    freeChoice: '<',
    translations: '<',
  },
  templateUrl: `${COMPONENT_PATH}${COMPONENT_FILE_NAME}/${COMPONENT_FILE_NAME}.html`,
  controller: function CheckChoicesModalController(contentService) {
    'ngInject';

    this.CONTENT_KEY = 'checkChoicesModal';

    this.$onInit = () => {
      this.clonedChoices = this.choices.map((choice) => ({ ...choice }));
    };

    this.close = () => {
      this.onClose();
    };

    this.getAnswersNumber = () =>
      this.clonedChoices.filter((choice) => choice.checked).length +
      (this.hasFreeAnswer &&
      this.freeChoice.checked &&
      this.isAValidValue(this.freeChoice.value)
        ? 1
        : 0);

    this.saveAnswers = () => {
      this.onClose({
        choices: this.clonedChoices,
        freeAnswer: this.freeChoice,
      });
    };

    this.isValidationDisabled = () =>
      this.getAnswersNumber() < this.question.minimum ||
      (this.hasFreeAnswer &&
        this.freeChoice.checked &&
        !this.isAValidValue(this.freeChoice.value));

    this.isAValidValue = (value) => value && value !== '';

    this.onSearchFocus = () => {
      contentService.scrollTopById(this.CONTENT_KEY);
    };
  },
};
