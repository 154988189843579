export const InputRadioButtonsBarComponent = {
  bindings: {
    isRequired: '<',
    isDisabled: '<',
    hasError: '<',
    label: '@',
    inputName: '@',
    labelClassName: '@',
    options: '<',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  transclude: true,
  templateUrl:
    'components/Inputs/input-radio-buttons-bar/input-radio-buttons-bar.html',
  controller: function InputRadioButtonsBarController() {
    'ngInject';
    this.$onInit = () => {
      this.modelCtrl.$render = () => {
        this.selected = this.modelCtrl.$viewValue;
      };
    };

    this.changeValue = (event, value) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.selected = value;
      this.modelCtrl.$setViewValue(value);
    };

    this.getOptionClass = (option) =>
      this.selected === option.value
        ? 'button-positive'
        : 'button-outline button-calm';
  },
};
