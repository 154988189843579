export const ToggleComponent = {
  require: {
    modelCtrl: 'ngModel',
  },
  bindings: {
    isDisabled: '<',
  },
  templateUrl: 'components/Inputs/toggle/toggle.html',
  controller: class ToggleController {
    $onInit() {
      this.modelCtrl.$render = () => {
        this.toggleValue = this.modelCtrl.$viewValue;
      };
    }
    changeValue() {
      this.toggleValue = !this.toggleValue;
      this.modelCtrl.$setViewValue(this.toggleValue);
    }
  },
};
