import angularElasticModule from 'angular-elastic';

import { FooterTextInputComponent } from './footer-text-input.component';

export const FooterTextInputModule = angular
  .module('sfMobile.components.footer-text-input', [
    'sfMobile.services.keyboard',
    angularElasticModule,
  ])
  .component('sfFooterTextInput', FooterTextInputComponent).name;
