import LegacyCampaignsModule from './campaigns/campaigns.module';
import LegacyCampaignCampaignsModule from './campaign/campaign.module';
import campaignRouteConfig from './campaigns.routes';

export default angular
  .module('sfMobile.campaigns', [
    LegacyCampaignsModule,
    LegacyCampaignCampaignsModule,
    'sfMobile.components.view',
    'sfMobile.components.nav-bar',
  ])
  .config(campaignRouteConfig).name;
