import { TasksTabsController } from './tasks-tabs.controller';

export const TasksTabsComponent: ng.IComponentOptions = {
  bindings: {
    profile: '<?',
    isPlace: '<?',
    taskStatus: '<',
    requestFilters: '<?',
    currentStickyHandle: '=',
    reloadFn: '=?',
    tabsColor: '@',
    contentKey: '@',
    isAddBtnDisplayed: '<?',
  },
  templateUrl: 'tasks/components/tasks-tabs/tasks-tabs.html',
  controller: TasksTabsController,
};
