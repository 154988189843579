import { TextExpanderController } from './text-expander.controller';

export const TextExpander: ng.IComponentOptions = {
  bindings: {
    title: '@',
    text: '<',
    expanded: '<?',
  },
  templateUrl: 'components/text-expander/text-expander.html',
  controller: TextExpanderController,
};
