import PlacesModule from './places/places.module';
import PlaceModule from './place/place.module';
import placesRouteConfig from './places.routes';
import PlaceItemModule from './components/place-item/place-item.module';

export default angular
  .module('sfMobile.places', [
    PlacesModule,
    PlaceModule,
    PlaceItemModule,
    'sfMobile.services.log',
  ])
  .config(placesRouteConfig).name;
