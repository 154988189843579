import { pathOr } from 'ramda';
import { ObjectId, User } from '../../..';
import { APIStore } from '../../../places';
import { Modal, ModalService } from '../../../services/Utils/Modal';

type DataProvider = (query: Record<string, unknown>) => void;
type Selected = { _id: ObjectId } & Record<string, unknown>;
type SelectModalContext = {
  emptyIcon: string;
  title: string;
  searchPlaceholder: string;
  multiple: boolean;
  selected: Selected[] | Selected | null | undefined;
  onReload?: () => void;
  onSave: (arg: { result: Selected[] | Selected | null }) => void;
  entityTitleGet: (...args) => void;
  entityDescriptionGet?: null | ((...args: any) => any);
  searchFields: string[];
  staticSearchFields: string[];
  customDataProvider?: () => DataProvider;
  entities?: User[] | APIStore[] | null;
  dataProvider?: DataProvider;
  entityProvider: null | ((...args: any) => any);
};

const GET_LIMIT = 20;

export class EntitySelectorService {
  constructor(
    private modalService: ModalService,
    private RequestsPaginate,
    private StaticRequestsPaginate,
    private apiUtilsService,
    private $filter: ng.FilterFactory,
    private searchUtilsService
  ) {
    'ngInject';
  }

  openSelectEntityModal(ctx: SelectModalContext): Modal {
    const dataProvider = ctx.customDataProvider
      ? ctx.customDataProvider()
      : ctx.entities
      ? this.createStaticDataProvider(ctx)
      : this.createHttpDataProvider(ctx);

    const template = `
        <sf-entity-selector-modal
          empty-icon="{{ $ctrl.emptyIcon }}"
          search-placeholder="{{ $ctrl.searchPlaceholder }}"
          title="{{ $ctrl.title }}"
          multiple="$ctrl.multiple"
          selected="$ctrl.selected"
          entity-title-get="$ctrl.entityTitleGet"
          entity-description-get="$ctrl.entityDescriptionGet"
          filters-available="$ctrl.filtersAvailable"
          on-selection-change="$ctrl.onSelectionChange(selection)"
          on-save="$ctrl.onSave()"
          on-query-change="$ctrl.onQueryChange(query)"
          on-close="$ctrl.onClose()"
          on-reload="$ctrl.onReload()"
        ></sf-entity-selector-modal>
      `;

    let selectionResult = (
      ctx.multiple
        ? [...((ctx.selected as Selected[]) || [])]
        : { ...ctx.selected }
    ) as Selected[] | Selected;
    const onSingleSelectChange = ({ entity }) => {
      selectionResult = entity;
    };
    const onMultipleSelectionChange = ({ entity, selected }) => {
      selectionResult = selected
        ? (selectionResult as Selected[]).concat(entity)
        : (selectionResult as Selected[]).filter((el) => el._id !== entity._id);
    };

    return this.modalService.open(template, {
      entityTitleGet: ctx.entityTitleGet,
      entityDescriptionGet: ctx.entityDescriptionGet,
      emptyIcon: ctx.emptyIcon,
      title: ctx.title,
      searchPlaceholder: ctx.searchPlaceholder,
      multiple: ctx.multiple,
      selected: ctx.selected,
      filtersAvailable: {},
      onSelectionChange: ctx.multiple
        ? onMultipleSelectionChange
        : onSingleSelectChange,
      onSave: () => ctx.onSave({ result: selectionResult }),
      onQueryChange: (query) => dataProvider(query),
      onReload: () => {
        ctx.onReload && ctx.onReload();
      },
    });
  }

  createStaticDataProvider(ctx: SelectModalContext) {
    return ({ search }) => {
      const filteredEntities = ctx.staticSearchFields
        ? this.getStaticFilteredEntities(ctx, search)
        : this.$filter('filter')(ctx.entities, search);

      return new this.StaticRequestsPaginate(filteredEntities, GET_LIMIT);
    };
  }

  createHttpDataProvider(ctx: SelectModalContext) {
    const requestPaginate = new this.RequestsPaginate(ctx.entityProvider, {
      limit: GET_LIMIT,
    });

    return ({ search = '', filters = [] }) => {
      const requestFilters = this.apiUtilsService.buildFilterParams(filters, {
        search,
        criterias: ctx.searchFields,
      });

      requestPaginate.reset();

      return {
        load: () => requestPaginate.call(requestFilters),
        canCallMore: () => requestPaginate.canCallMore(),
      };
    };
  }

  getStaticFilteredEntities(ctx: SelectModalContext, search: string) {
    const filterFn = this.getFilterFn(search);
    const filteredEntities = ctx.entities
      ?.map((entity) => {
        const searchField = ctx.staticSearchFields
          .map((field) => pathOr('', field.split('.'), entity))
          .join(' ');

        return { ...entity, searchField };
      })
      .filter(({ searchField }) => filterFn(searchField));

    return filteredEntities;
  }

  getFilterFn(search: string): (search: string) => boolean {
    if (!search) {
      return () => true;
    }

    const regStr = this.searchUtilsService.getRegexStringWithDiacritics(search);
    const reg = this.searchUtilsService.getSearchRegex(regStr);

    return (entity) => reg.test(entity);
  }
}
