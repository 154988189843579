import activityRouteConfig from './activity.routes';
import { ActivityDraftsItemComponent } from './components/activity-drafts-item/activity-drafts-item.component';
import { ActivityNewMissionItemComponent } from './components/activity-new-mission-item/activity-new-mission-item.component';
import { ActivityFeedComponent } from './components/activity-feed/activity-feed.component';
import { ActivityComponent } from './views/activity/activity.component';
import { SliderWidgetComponent } from './components/slider-widget/slider-widget.component';

export default angular
  .module('sfMobile.activity', [
    'sfMobile.services.localization',
    'sfMobile.newsfeed',
    'sfMobile.notifications',
    'sfMobile.constants.task-statuses',
    'sfMobile.services.reports',
    'sfMobile.services.calendar-events',
    'sfMobile.services.helpers',
    'sfMobile.services.synchronize',
    'sfMobile.services.geolocation',
    'sfMobile.services.tasks',
    'sfMobile.services.preferences',
    'sfMobile.services.user-experience',
    'sfMobile.services.task',
    'sfMobile.services.events-transform',
    'sfMobile.services.weekNavigation',
    'sfMobile.services.dates',
    'sfMobile.components.button-header-synchronize',
    'sfMobile.components.button-more',
    'sfMobile.components.scroll-horizontal',
    'sfMobile.components.parallax',
    'sfMobile.components.information-display',
    'sfMobile.components.section-loader',
    'sfMobile.services.pubsub',
  ])
  .component('sfActivityDraftsItem', ActivityDraftsItemComponent)
  .component('sfActivityNewMissionItem', ActivityNewMissionItemComponent)
  .component('sfActivityFeed', ActivityFeedComponent)
  .component('sfSliderWidget', SliderWidgetComponent)
  .component('sfActivity', ActivityComponent)
  .config(activityRouteConfig).name;
