export const MerchandisingInfosComponent = {
  bindings: {
    user: '<',
    reportSavedDate: '<',
    posName: '<',
    campaignName: '@',
    questionTitle: '@',
    state: '@',
  },
  templateUrl:
    'merchandising/components/merchandising-infos/merchandising-infos.html',
  controller: function MerchandisingInfosController(dateFormatService) {
    'ngInject';
    this.getVerboseDateAndTimeFormatted =
      dateFormatService.getVerboseDateAndTimeFormatted;
  },
};
