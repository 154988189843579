import { GalleryListComponent } from './gallery-list.component';

export default angular
  .module('sfMobile.components.gallery-list', [
    'pascalprecht.translate',
    'sfMobile.constants.image-sizes',
    'sfMobile.services.reports',
    'sfMobile.services.files',
    'sfMobile.services.requests-paginate',
    'sfMobile.services.dates',
    'sfMobile.services.api-utils',
    'sfMobile.components.images-viewer',
    'sfMobile.components.infinite-scroll',
    'sfMobile.components.view-states',
    'sfMobile.components.view-states-search',
    'sfMobile.services.image-source',
    'sfMobile.services.tags',
    'sfMobile.tags-selector',
  ])
  .component('sfGalleryList', GalleryListComponent).name;
