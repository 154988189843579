import { TaskDetailsController } from './task-details.controller';

export const TaskDetailsComponent: ng.IComponentOptions = {
  bindings: {
    offlineMode: '<',
    profile: '<',
    taskId: '<',
    onSave: '&',
    onClose: '&',
    onTaskUpdate: '&',
    isChecklistTab: '<',
  },
  templateUrl: 'tasks/task-details/task-details.html',
  controller: TaskDetailsController,
};
