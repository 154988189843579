export const TaskManageComponent = {
  bindings: {
    task: '<',
    context: '<?',
    onClose: '&',
    onSave: '&',
    offlineMode: '<',
    profile: '<',
  },
  templateUrl: 'tasks/task-manage/task-manage.html',
  controller: function TaskManageController(localizationService) {
    'ngInject';
    this.$onInit = () => {
      this.isRTLNeeded = localizationService.shouldActivateRTL();
      const task = { ...this.task };

      this.type = task && task._id ? 'edit' : 'add';
    };

    this.onDone = (managedTask) => this.onSave()({ managedTask });

    this.getHeaderTitle = () =>
      this.type === 'edit'
        ? 'TASK_MANAGE_HEADER_TITLE_EDIT'
        : 'TASK_MANAGE_HEADER_TITLE_ADD';
  },
};
