const LOW_LIMIT = 33;
const HIGH_LIMIT = 67;

export const ProgressBarComponent = {
  bindings: {
    percent: '<',
  },
  templateUrl: 'components/Progress/progress-bar/progress-bar.html',
  controller: class ProgressBarController {
    constructor($timeout) {
      'ngInject';
      Object.assign(this, {
        $timeout,
      });
    }

    $onInit() {
      this.style = {};
      this.setTheValue();
      this.setColorClass();
    }

    $onChanges(changes) {
      if (changes.percent) {
        this.setTheValue();
        this.setColorClass();
      }
    }

    setColorClass() {
      const colorClass =
        LOW_LIMIT >= this.percent
          ? 'sf_progressBar__indicator_low'
          : HIGH_LIMIT <= this.percent
          ? 'sf_progressBar__indicator_high'
          : null;

      this.colorClass = colorClass;
    }

    setTheValue() {
      this.$timeout(() => {
        this.style.width = `${this.percent}%`;
      }, 100);
    }
  },
};
