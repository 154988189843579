export default function routeConfig($stateProvider) {
  'ngInject';

  // Routes.
  $stateProvider
    .state('index.menu-more.merchandising', {
      url: '/merchandising',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('index.menu-more.merchandising.tabs', {
      url: '/lists',
      template: `
        <ion-view>
          <sf-view sf-tab-bar-hide="true">
            <sf-nav-bar
              display-back-button="true"
              title="{{ ::'MERCHANDISING_TITLE' | translate }}">
            </sf-nav-bar>
            <sf-merchandising-tabs
              profile="$ctrl.profile">
            </sf-merchandising-tabs>
          </sf-view>
        </ion-view>
      `,
      controller: MerchandisingViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Merchandising Tabs',
    })
    .state('index.menu-more.merchandising.validation-details', {
      url: '/validations/:validationId',
      template: `
        <ion-view>
          <sf-view>
            <sf-nav-bar
              display-back-button="true"
              fallback-back-link="index.menu-more.merchandising.tabs"
              title="{{ ::'MERCHANDISING_DETAILS_TITLE' | translate }}">
            </sf-nav-bar>
            <sf-merchandising-details
              validation-id="$ctrl.validationId"
              profile="$ctrl.profile"
              on-save-success="$ctrl.onSaveSuccess()">
            </sf-merchandising-details>
          </sf-view>
        </ion-view>
      `,
      controller: MerchandisingDetailsViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Merchandising Answer Details',
    });

  // @ngInject
  function MerchandisingViewController(rsvpProfile) {
    this.profile = rsvpProfile;
  }

  // @ngInject
  function MerchandisingDetailsViewController(rsvpProfile, $stateParams) {
    this.profile = rsvpProfile;
    this.validationId = $stateParams.validationId;
  }
}
