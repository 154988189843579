export const PieChartComponent = {
  bindings: {
    score: '<',
  },
  templateUrl: 'components/Graphs/pie-chart/pie-chart.html',
  controller: function PieChartController() {
    'ngInject';

    this.$onInit = () => {};
  },
};
