import { NewsfeedResourcesSelectorController } from './newsfeed-resources-selector.controller';

export const NewsfeedResourcesSelector: ng.IComponentOptions = {
  bindings: {
    isDisabled: '<',
    readyImagesCount: '<',
    readyVideosCount: '<',
    isDocumentButtonDisabled: '<',
    resourceSelectEvent: '&',
    onResourcesChanged: '&',
  },
  templateUrl:
    'newsfeed/components/newsfeed-resources-selector/newsfeed-resources-selector.html',
  controller: NewsfeedResourcesSelectorController,
};
