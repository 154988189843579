import { PluginListenerHandle } from '@capacitor/core';
import {
  OrientationLockType,
  ScreenOrientation,
} from '@capacitor/screen-orientation';

export class ScreenOrientationService {
  portraitLock(): Promise<void> {
    return this.lock('portrait');
  }

  lock(orientation: OrientationLockType): Promise<void> {
    return ScreenOrientation.lock({ orientation });
  }

  unlock(): Promise<void> {
    return ScreenOrientation.unlock();
  }

  getScreenOrientation(): Promise<OrientationLockType> {
    return ScreenOrientation.orientation().then((result) => result.type);
  }

  screenOrientationChangeListener(
    callback: () => void
  ): Promise<PluginListenerHandle> {
    return ScreenOrientation.addListener('screenOrientationChange', callback);
  }
}
