"use strict";

var _createObjectId = require('objectid-stub')();

var identifiersHash = {};

module.exports = function createOrRetrieveObjectId(identifier) {
  if (identifier) {
    identifiersHash[identifier] = identifiersHash[identifier] || _createObjectId();
  }

  return identifier ? identifiersHash[identifier] : _createObjectId();
};