import { uuidv4 } from '../helpers/utils';
export const exportDashboard = (dashboardComponent, iframe, format) => {
    const requestId = uuidv4();
    if (iframe?.contentWindow) {
        iframe.contentWindow.postMessage({
            action: 'exportDashboard',
            format: format || 'png',
            _version: dashboardComponent.VERSION,
            requestId
        }, '*');
        return new Promise((resolve, reject) => {
            dashboardComponent.promises[requestId] = { resolve, reject };
            // Check if promise has been resolved after 2 seconds
            setTimeout(() => {
                if (dashboardComponent.promises[requestId]) {
                    const errorMessage = 'exportDashboard request timed out.';
                    dashboardComponent.promises[requestId].reject(new Error(errorMessage));
                    delete dashboardComponent.promises[requestId];
                }
            }, 1200000);
        });
    }
    return Promise.reject(new Error('Cannot retrieve contentWindow.'));
};
