import { LocalizationService } from '../../../services/Utils/Localization/localization.service';

export const InformationItemComponent = {
  bindings: {
    badge: '<?',
    text: '@',
  },
  templateUrl: 'components/Items/information-item/information-item.html',
  controller: class InformationItemController {
    isRTLNeeded: boolean;

    constructor(private localizationService: LocalizationService) {
      'ngInject';
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    }
  },
};
