export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 4.8,
    method: updateMethod,
  });
}
// @ngInject
export function updateMethod(sqlStorageService) {
  const fields = [
    'id NVARCHAR(32) PRIMARY KEY UNIQUE',
    'organisation_id NVARCHAR(32)',
    'owner_id NVARCHAR(32)',
    'payload TEXT',
  ].join(',');
  const addTasksTableQuery = `CREATE TABLE users(${fields})`;

  return sqlStorageService.execute(addTasksTableQuery).catch(() => null);
}
