import AvatarOrganisationModule from './Avatar/avatar-organisation/avatar-organisation.module';
import AvatarUserModule from './Avatar/avatar-user/avatar-user.module';
import AvatarModule from './Avatar/avatar/avatar.module';
import BannersModule from './Banners/banners.module';
import ButtonsModule from './Buttons/buttons.module';
import { ChecklistModule } from './Checklist/checklsit/checklist.module';
import DividerDateOverdueModule from './Divider/divider-date/divider-date-overdue.module';
import DividerDateModule from './Divider/divider-date/divider-date.module';
import DividerModule from './Divider/divider/divider.module';
import DrawingColorPickerModule from './Drawing/drawing-color-picker/drawing-color-picker.module';
import DrawingSizePickerModule from './Drawing/drawing-size-picker/drawing-size-picker.module';
import PictureDrawingModule from './Drawing/picture-drawing/picture-drawing.module';
import PieChartModule from './Graphs/pie-chart/pie-chart.module';
import RadarGraphModule from './Graphs/radar-graph/radar-graph.module';
import ScatterGraphModule from './Graphs/scatter-graph/scatter-graph.module';
import ImageCropperModule from './Images/image-cropper/image-cropper.module';
import { ImageFallbackModule } from './Images/image-fallback/image-fallback.module';
import ImagePreviewModule from './Images/image-preview/image-preview.module';
import ImagesViewerModule from './Images/images-viewer/images-viewer.module';
import InputsModule from './Inputs/inputs.module';
import InformationItemModule from './Items/information-item/information-item.module';
import ItemHeaderModule from './Items/item-header/item-header.module';
import ItemInlineModule from './Items/item-inline/item-inline.module';
import LayoutModule from './Layout/layout.module';
import LoaderModule from './Loaders/loader/loader.module';
import SpinnerFloatingModule from './Loaders/spinner-floating/spinner-floating.module';
import SpinnerModule from './Loaders/spinner/spinner.module';
import GoogleMapModalModule from './Map/google-map-modal/google-map-modal.module';
import GoogleMapModule from './Map/google-map/google-map.module';
import ModalBottomModule from './Modals/modal-bottom.module';
import ModalSlideUpModule from './Modals/modal-slide-up/modal-slide-up.module';
import ModalStepperModule from './Modals/modal-stepper/modal-stepper.module';
import AppMessageModule from './Notifications/app-message/app-message.module';
import AppNotificationModule from './Notifications/app-notification/app-notification.module';
import MessageModule from './Notifications/message/message.module';
import SettingsAlertModule from './Notifications/settings-alert/settings-alert.module';
import MessageToasterModule from './Notifications/toaster/message-toaster.module';
import PlaceSelectorModule from './PlaceSelector/place-selector.module';
import { PopupAgreementModule } from './Popup/popup-agreement/popup-agreement.module';
import PopupConfirmModule from './Popup/popup-confirm/popup-confirm.module';
import PopupDownloadModule from './Popup/popup-download/popup-download.module';
import PopupIconModule from './Popup/popup-icon/popup-icon.module';
import PopupProgressModule from './Popup/popup-progress/popup-progress.module';
import PopupPromptModule from './Popup/popup-prompt/popup-prompt.module';
import { PopupRedirectModule } from './Popup/popup-redirect/popup-redirect.module';
import PopupSelectModule from './Popup/popup-select/popup-select.module';
import { PopupSynchronizeModule } from './Popup/popup-synchronize/popup-synchronize.module';
import { PopupMovedToDraftModule } from './Popup/popup-moved-to-draft/popup-moved-to-draft.module';
import { PopupObjectiveAlreadyCompleteModule } from './Popup/popup-objective-already-complete/popup-objective-already-complete.module';
import { PopupTemplateModule } from './Popup/popup-template/popup-template.module';
import PopupModule from './Popup/popup/popup.module';
import ProgressBarAutoModule from './Progress/progress-bar-auto/progress-bar-auto.module';
import ProgressBarModule from './Progress/progress-bar/progress-bar.module';
import ProgressCircleModule from './Progress/progress-circle/progress-circle.module';
import ScenesModule from './Scenes/scenes.module';
import InfiniteScrollModule from './Scroll/infinite-scroll/infinite-scroll.module';
import ParallaxModule from './Scroll/parallax/parallax.module';
import ScrollHorizontalModule from './Scroll/scroll-horizontal/scroll-horizontal.module';
import FilterModalModule from './Search/filter-modal/filter-modal.module';
import SearchFieldModule from './Search/search-field/search-field.module';
import SearchFilterListModule from './Search/search-filter-list/search-filter-list.module';
import SearchFilterModule from './Search/search-filter/search-filter.module';
import { SectionLoaderModule } from './Sections/section-loader.module';
import SelectUsersModule from './Select/select-users/select-users.module';
import SkeletonsModule from './Skeletons/skeletons.module';
import VideoPlayerModule from './Video/video-player/video-player.module';
import AccordionModule from './accordion/accordion.module';
import BadgeBetaModule from './badge-beta/badge-beta.module';
import BadgeCircleModule from './badge-circle/badge-circle.module';
import ChipModule from './chip/chip.module';
import { CorporateEventsSelectorModule } from './corporate-events-selector/corporate-events-selector.module';
import CustomParamsSelectorModule from './custom-params-selector/custom-params-selector.module';
import { DropdownModule } from './dropdown/dropdown.module';
import DueDateTagModule from './due-date-tag/due-date-tag.module';
import EntitySelectorModule from './entity-selector/entity-selector.module';
import { EventChecklistSelectorModule } from './event-checklist-selector/event-checklist-selector.module';
import FileUploaderModule from './file-uploader/file-uploader.module';
import { FooterTextInputModule } from './footer-text-input/footer-text-input.module';
import InAppBrowserOpenerModule from './in-app-browser-opener/in-app-browser-opener.module';
import NamedResourceChipModule from './named-resource-chip/named-resource-chip.module';
import { NextEventsModule } from './next-events/next-events.module';
import PhotoPreviewModule from './photos-preview/photos-preview.module';
import ReminderSelectorModule from './reminder-selector/reminder-selector.module';
import RestrictionWarningModule from './restriction-warning/restriction-warning.module';
import SortButtonModule from './sort/sort-button/sort-button.module';
import SortModalModule from './sort/sort-modal/sort-modal.module';
import StatusBadgeModule from './status-badge/status-badge.module';
import StatusSelectorModule from './status-selector/status-selector.module';
import textExpanderModule from './text-expander/text-expander.module';
import TextSeeMoreModule from './text-see-more/text-see-more.module';
import UserModule from './user/user.module';
import UsersListModule from './users-list/users-list.module';
import WeekNavigationModule from './week-navigation/week-navigation.module';

export default angular.module('sfMobile.components', [
  AccordionModule,
  AvatarModule,
  AvatarOrganisationModule,
  AvatarUserModule,
  ButtonsModule,
  DividerModule,
  DividerDateOverdueModule,
  DividerDateModule,
  DrawingColorPickerModule,
  DrawingSizePickerModule,
  DropdownModule,
  PictureDrawingModule,
  PieChartModule,
  RadarGraphModule,
  ScatterGraphModule,
  ImageFallbackModule,
  InputsModule,
  ItemInlineModule,
  ItemHeaderModule,
  InformationItemModule,
  LayoutModule,
  LoaderModule,
  SpinnerModule,
  SpinnerFloatingModule,
  GoogleMapModule,
  GoogleMapModalModule,
  ModalBottomModule,
  ModalSlideUpModule,
  ModalStepperModule,
  AppMessageModule,
  AppNotificationModule,
  MessageModule,
  MessageToasterModule,
  SettingsAlertModule,
  PhotoPreviewModule,
  PlaceSelectorModule,
  PopupModule,
  PopupConfirmModule,
  PopupDownloadModule,
  PopupIconModule,
  PopupProgressModule,
  PopupPromptModule,
  PopupSelectModule,
  PopupTemplateModule,
  PopupAgreementModule,
  PopupSynchronizeModule,
  PopupMovedToDraftModule,
  PopupObjectiveAlreadyCompleteModule,
  PopupRedirectModule,
  ProgressBarModule,
  ProgressBarAutoModule,
  ProgressCircleModule,
  ScenesModule,
  InfiniteScrollModule,
  ParallaxModule,
  ScrollHorizontalModule,
  FilterModalModule,
  FileUploaderModule,
  SearchFieldModule,
  SearchFilterModule,
  SearchFilterListModule,
  SectionLoaderModule,
  SelectUsersModule,
  SkeletonsModule,
  BadgeBetaModule,
  BadgeCircleModule,
  ChipModule,
  EntitySelectorModule,
  CustomParamsSelectorModule,
  FooterTextInputModule,
  InAppBrowserOpenerModule,
  RestrictionWarningModule,
  SortButtonModule,
  SortModalModule,
  StatusBadgeModule,
  StatusSelectorModule,
  TextSeeMoreModule,
  UserModule,
  UsersListModule,
  WeekNavigationModule,
  ImagePreviewModule,
  ImagesViewerModule,
  NamedResourceChipModule,
  ImageCropperModule,
  VideoPlayerModule,
  BannersModule,
  ChecklistModule,
  NextEventsModule,
  EventChecklistSelectorModule,
  textExpanderModule,
  ReminderSelectorModule,
  DueDateTagModule,
  CorporateEventsSelectorModule,
]).name;
