import moment from 'moment-timezone';
import { SfEventsTypes } from '../../shared/enums/events-types.enum';

export class EventsTransformService {
  constructor(weekNavigationService, dateFormatService) {
    'ngInject';
    this.weekNavigationService = weekNavigationService;
    this.dateFormatService = dateFormatService;
    this.moment = moment;

    this.dateAsHTML5Date = '';
    this.week = [];
  }

  getDay(date, tz) {
    return this.dateFormatService.getHtml5DateFormatted(
      this.moment.tz(date, tz)
    );
  }

  /**
   * Group events by day on a hash, according to the event timezone time.
   *
   * @param {Array} events - Array of event objects
   * @return {Object} - Hash of event grouped by day
   */
  groupCalendarEventsByDay(events) {
    return events.reduce((hash, event) => {
      const startDay = this.getDay(
        event._type === SfEventsTypes.corporate
          ? event.contents.start_date
          : event.contents.start_dateTime,
        event.contents.timezone.name
      );
      const endDay = this.getDay(
        event._type === SfEventsTypes.corporate
          ? event.contents.end_date
          : event.contents.end_dateTime,
        event.contents.timezone.name
      );

      if (startDay !== endDay) {
        this.week.forEach((weekDay) => {
          if (
            weekDay.date.isAfter(startDay) &&
            weekDay.date.isSameOrBefore(endDay)
          ) {
            event.multipleDays = true;
            hash[weekDay.dayStr] = [...(hash[weekDay.dayStr] || []), event];
          }
        });
      }

      hash[startDay] = [...(hash[startDay] || []), event];
      return hash;
    }, {});
  }

  transformCalendarEventData(weekEvents, date) {
    this.updateWeek(date);
    this.updateHTML5Date(this.dateFormatService.getHtml5DateFormatted(date));
    const eventsGroupedByDay = this.groupCalendarEventsByDay(weekEvents);
    return {
      daysWithData: Object.keys(eventsGroupedByDay),
      events: eventsGroupedByDay[this.dateAsHTML5Date] || [],
    };
  }

  updateWeek(date) {
    this.week = this.weekNavigationService.getWeek(date);
  }

  updateHTML5Date(_dateAsHTML5Date) {
    this.dateAsHTML5Date = _dateAsHTML5Date;
  }

  isMultipleDaysEvent(event) {
    const startDay = this.getDay(
      event.contents.start_dateTime,
      event.contents.timezone.name
    );
    const endDay = this.getDay(
      event.contents.end_dateTime,
      event.contents.timezone.name
    );

    return startDay !== endDay;
  }
}
