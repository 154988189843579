import { ProfileComponent } from './profile.component';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';

export default angular
  .module('sfMobile.components.profile', [
    'pascalprecht.translate',
    'sfMobile.services.auth',
    'sfMobile.services.segment',
    'sfMobile.services.action-sheet',
    'sfMobile.services.preferences',
    'sfMobile.services.department',
    'sfMobile.services.remote-stores',
    'sfMobile.components.item-inline',
    'sfMobile.components.toggle',
    'sfMobile.components.select-timezone',
    'sfMobile.services.pubsub',
  ])
  .component('sfProfile', ProfileComponent)
  .component('sfProfilePicture', ProfilePictureComponent).name;
