import { ObjectId, User } from '../../../index';
import { ActionSheetAction } from '../../../services/Utils/ActionSheet/action-sheet.service';

export class NewsfeedActionsService {
  constructor(
    private $translate: ng.translate.ITranslateService,
    private accessRightsService
  ) {
    'ngInject';
  }

  getAvailableActions({
    author_id,
    profile,
    postContent,
    callbacks,
  }: {
    author_id: ObjectId;
    profile: User;
    postContent: string;
    callbacks: { onEdit: () => void; onDelete: () => void };
  }): ActionSheetAction[] {
    const { onEdit, onDelete } = callbacks;
    const isAdmin = this.accessRightsService.isAdmin();
    const isAuthor = this.isAuthor(author_id, profile);
    const hasHtmlTagRegex = /(<([^>]+)>)/gi;
    const matches = hasHtmlTagRegex.exec(postContent);

    const editAction =
      (isAdmin || isAuthor) && Boolean(onEdit) && !matches
        ? [
            {
              text: this.$translate.instant('NEWSFEED_POST_MENU_EDIT'),
              onClick: () => onEdit(),
            },
          ]
        : [];
    const deleteAction =
      (isAdmin || isAuthor) && onDelete
        ? [
            {
              text: this.$translate.instant('NEWSFEED_POST_MENU_DELETE'),
              onClick: () => onDelete(),
            },
          ]
        : [];

    return [...editAction, ...deleteAction];
  }

  isAuthor(author_id: string, profile: User): boolean {
    return author_id === profile?._id;
  }
}
