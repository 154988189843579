const DATE_STR_FORMAT = 'YYYY-MM-DD';

export const WeekNavigationComponent = {
  bindings: {
    date: '<',
    daysWithData: '<',
    onDateClick: '&',
    goToNextWeek: '&',
    goToPreviousWeek: '&',
  },
  templateUrl: 'components/week-navigation/week-navigation.html',
  controller: class WeekNavigationController {
    constructor(
      localizationService,
      dateFormatService,
      weekNavigationService,
      dateService
    ) {
      'ngInject';
      this.localizationService = localizationService;
      this.dateFormatService = dateFormatService;
      this.dateService = dateService;
      this.weekNavigationService = weekNavigationService;
      this.getHtml5DateFormatted = dateFormatService.getHtml5DateFormatted;
      this.getDayNumberFormatted = dateFormatService.getDayNumberFormatted;
      this.getDayOfMonthFormatted = dateFormatService.getDayOfMonthFormatted;
      this.todayAsHTML5Date = this.getHtml5DateFormatted();
    }

    $onInit() {
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
      this.updateWeek();
    }

    $onChanges(changeObj) {
      if (changeObj.date) {
        this.updateWeek();
      }
    }

    updateWeek() {
      const momentDate = this.dateService.toMoment(this.date);

      this.selectedDateStr = momentDate.format(DATE_STR_FORMAT);
      this.week = this.weekNavigationService.getWeek(momentDate);
    }
  },
};
