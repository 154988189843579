export enum DateRange {
  today = 'today',
  yesterday = 'yesterday',
  week_to_date = 'week_to_date',
  last_week = 'last_week',
  last_six_months = 'last_six_months',
}

export enum CompletionFilterType {
  date_period = 'date_period',
  no_report = 'no_report',
  team = 'team',
  store_group = 'store_group',
}

export enum FilterType {
  dropdown = 'dropdown',
  toggle = 'toggle',
}

export default angular
  .module('sfMobile.constants.campaign-completion', [])
  .constant('SF_DATE_RANGE', DateRange)
  .constant('SF_COMPLETION_FILTER_TYPE', CompletionFilterType)
  .constant('SF_FILTER_TYPE', FilterType).name;
