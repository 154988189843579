import TaskStatusesModule from './constants/task-statuses.constant';
import TaskAssigneesModule from './constants/task-assignees.constant';
import TaskDetailsTabsModule from './constants/task-details-tabs.constant';
import TaskModule from './services/task.module';
import TaskDetailsModule from './task-details/task-details.module';
import TaskManageModule from './task-manage/task-manage.module';
import TasksModule from './tasks/tasks.module';
import tasksRouteConfig from './tasks.routes';
import { TaskCommentsActionsService } from './services/task-comments-actions.service';
import { TaskCommentsPopupService } from './services/task-comments.popup.service';
import { TaskCommentsComponent } from './task-comments/task-comments.component';

import TaskItemModule from './components/task-item/task-item.module';
import { GroupTaskItemModule } from './components/group-task-item/group-task-item.module';
import { GroupTaskDetailModule } from './components/group-task-detail/group-task-detail.module';
import { TaskAuthorModule } from './components/task-author/task-author.module';
import { GroupTaskGroupNameModule } from './components/group-task-group-name/group-task-group-name.module';
import TasksListModule from './components/tasks-list/tasks-list.module';
import TasksListSimpleModule from './components/tasks-list-simple/tasks-list-simple.module';
import ReportTasksModule from './components/report-tasks/report-tasks.module';
import TaskPriorityLabelModule from './components/task-priority-label/task-priority-label.module';
import TasksTabsModule from './components/tasks-tabs/tasks-tabs.module';
import TasksFiltersModalModule from './components/tasks-filters-modal/tasks-filters-modal.module';
import TaskDetailsOverviewModule from './components/task-details-overview/task-details-overview.module';
import SubtasksModule from './subtasks/subtasks.module';
import { TaskGroupTasksChoiceModule } from './tasks-group-tasks-choice/task-group-tasks-choice.module';
import { GroupTasksModule } from '../group-tasks/group-tasks.module';

export default angular
  .module('sfMobile.tasks', [
    TaskStatusesModule,
    TaskAssigneesModule,
    TaskDetailsTabsModule,
    TaskModule,
    TaskDetailsModule,
    TaskManageModule,
    TasksModule,
    TaskItemModule,
    TasksListModule,
    TasksListSimpleModule,
    ReportTasksModule,
    TaskPriorityLabelModule,
    TasksTabsModule,
    TasksFiltersModalModule,
    TaskDetailsOverviewModule,
    SubtasksModule,
    TaskGroupTasksChoiceModule,
    GroupTasksModule,
    GroupTaskItemModule,
    GroupTaskDetailModule,
    TaskAuthorModule,
    GroupTaskGroupNameModule,
  ])
  .config(tasksRouteConfig)
  .service('taskCommentsActionsService', TaskCommentsActionsService)
  .service('taskCommentsPopupService', TaskCommentsPopupService)
  .component('sfTaskComments', TaskCommentsComponent).name;
