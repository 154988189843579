export const ReportNodeComponent = {
  bindings: {
    item: '<',
    form: '<',
    context: '<',
  },
  templateUrl:
    'reports/report/components/report-content/components/report-node/report-node.html',
  controller: function ReportNodeController(reportsService, formsService) {
    'ngInject';

    this.$onInit = () => {
      return this.setNodes();
    };

    this.$onChanges = (changes) => {
      if (changes && changes.item) {
        this.setNodes();
      }
    };

    this.setNodes = () => {
      const sectionNodes = this.context.report.contents.nodes.filter(
        this.getSectionNode
      );
      const nodes = !sectionNodes.length
        ? this.addNode(sectionNodes)
        : sectionNodes;

      this.nodes = nodes;
    };

    this.isSectionVisible = (section) => {
      const answers = this.context.report.contents.answers;

      return formsService.displayQuestion(
        section,
        this.form,
        answers,
        this.context
      );
    };

    this.getSectionNode = (node) => node.sections_ids[0] === this.item._id;

    this.addNode = (nodes) => {
      const sectionIds = [].concat(this.item._id);
      const newNode = reportsService.getDefaultNode(sectionIds);

      return nodes.concat(newNode);
    };
  },
};
