export const InputBarcodeComponent = {
  bindings: {
    isDisabled: '<?',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  templateUrl: 'components/Inputs/input-barcode/input-barcode.html',
  controller: function InputBarcodeController(barcodeService) {
    'ngInject';

    this.$onInit = () => {
      this.modelCtrl.$render = () => {
        const modelValue = this.modelCtrl.$viewValue;

        this.value = modelValue;
      };
      this.modelCtrl.$validators.pattern = (modelValue, viewValue) =>
        barcodeService.validateBarcode(modelValue || viewValue);
    };

    this.onButtonClick = () => {
      return barcodeService.promptBarCode().then((value) => {
        this.value = value;
        this.modelCtrl.$setViewValue(value);
        return value;
      });
    };
  },
};
