"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('missionsGroup1'),
    name: 'missionsGroup1',
    description: 'Sample missions group 1',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('org1'),
      owner_id: createObjectId('owner1'),
      form_id: createObjectId('form1'),
      type: 'perpetual',
      brief: '',
      needPlace: true,
      useAssociations: false,
      places_ids: [createObjectId('place2')],
      users_ids: [createObjectId('user1')],
      params: []
    }
  }, {
    _id: createObjectId('missionsGroup2'),
    name: 'missionsGroup2',
    description: 'Sample missions group 2',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      form_id: createObjectId('form1'),
      organisation_id: createObjectId('org1'),
      owner_id: createObjectId('owner1'),
      type: 'ephemeral',
      brief: 'Hello',
      needPlace: false,
      useAssociations: false,
      places_ids: [],
      users_ids: [createObjectId('user2')],
      params: [{
        name: 'param1',
        value: 1
      }, {
        name: 'param2',
        value: 2
      }]
    }
  }, {
    _id: createObjectId('missionsGroup3'),
    name: 'missionsGroup3',
    description: 'Missions group 3 with start_date and end_date',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      start_date: '2019-03-29T22:00:00.000Z',
      end_date: '2019-03-29T23:00:00.000Z',
      form_id: createObjectId('form1'),
      organisation_id: createObjectId('org1'),
      owner_id: createObjectId('owner1'),
      type: 'ephemeral',
      brief: 'Hello',
      needPlace: false,
      useAssociations: false,
      places_ids: [],
      users_ids: [createObjectId('user2')],
      params: [{
        name: 'param1',
        value: 1
      }, {
        name: 'param2',
        value: 2
      }]
    }
  }, {
    _id: createObjectId('missionsGroupReminder'),
    name: 'missionsGroup with reminder',
    description: 'Missions group 3 with start_date and end_date',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      reminder: true,
      reminderContents: [25, 50, 75],
      start_date: '2019-03-29T22:00:00.000Z',
      end_date: '2019-03-29T23:00:00.000Z',
      form_id: createObjectId('form1'),
      organisation_id: createObjectId('org1'),
      owner_id: createObjectId('owner1'),
      type: 'ephemeral',
      brief: 'Hello',
      needPlace: false,
      useAssociations: false,
      places_ids: [],
      users_ids: [createObjectId('user2')],
      params: [{
        name: 'param1',
        value: 1
      }, {
        name: 'param2',
        value: 2
      }]
    }
  }];
};