export default function routeConfig($stateProvider) {
  'ngInject';

  // Routes.
  $stateProvider
    .state('preview', {
      url: '/preview?jwt&url',
      abstract: true,
      params: {
        jwt: null,
        url: '',
      },
      template: '<ion-nav-view></ion-nav-view>',
      resolve: {
        rsvpProfile: getProfile,
      },
    })
    .state('preview.campaign', {
      url: '/campaign/:campaignId?onStore&asUser&questionId&isDebug',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
      resolve: {
        rsvpForm: () => null,
        rsvpCampaign: getRsvpCampaign,
        rsvpPlace: getRsvpPlace,
        rsvpUser: getRsvpUser,
      },
    })
    .state('preview.campaign.detail', {
      url: '',
      template: `
        <ion-view class="sf_form"
          cache-view="false"
          can-swipe-back="false">
          <sf-form
            campaign="$ctrl.campaign"
            profile="$ctrl.profile"
            place="$ctrl.place"
            is-preview="true"
          >
          </sf-forms>
        </ion-view>
      `,
      controller: CampaignViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Preview mission',
    })
    .state('preview.template', {
      url: '/template/:templateId?onStore&asUser&questionId&isDebug',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
      resolve: {
        rsvpTemplate: getRsvpTemplate,
        rsvpPlace: getRsvpPlace,
        rsvpUser: getRsvpUser,
        rsvpTemplateReport: getRsvpTemplateReport,
      },
    })
    .state('preview.template.detail', {
      url: '',
      template: `
        <ion-view class="sf_form"
          cache-view="false"
          can-swipe-back="false">
          <sf-form
            template="$ctrl.template"
            report="$ctrl.report"
            profile="$ctrl.profile"
            place="$ctrl.place"
            is-preview="true"
          >
          </sf-forms>
        </ion-view>
      `,
      controller: TemplateViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Preview template',
    })
    .state('preview.campaign.form', {
      url: '/form?viewRestored',
      template: `
        <ion-view class="sf_form"
          cache-view="false"
          can-swipe-back="false">
          <sf-form
            campaign="$ctrl.campaign"
            report="$ctrl.report"
            place="$ctrl.place"
            user="$ctrl.user"
            is-preview="true">
          </sf-form>

        </ion-view>
      `,
      controller: CampaignFormViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Preview form',
      resolve: {
        rsvpReport: getRsvpReport,
      },
    })
    .state('preview.mission', {
      url: '/mission/:formId?onStore&asUser&questionId&isDebug',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
      resolve: {
        rsvpCampaign: () => null,
        rsvpForm: getRsvpForm,
        rsvpPlace: getRsvpPlace,
        rsvpUser: getRsvpUser,
      },
    })
    .state('preview.mission.detail', {
      url: '',
      template: `
        <ion-view class="sf_mission"
          cache-view="false"
          can-swipe-back="false">
          <sf-mission
            form="$ctrl.form"
            profile="$ctrl.profile"
            place="$ctrl.place"
            is-preview="true"
          >
          </sf-missions>
        </ion-view>
      `,
      controller: CampaignViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Preview mission',
    })
    .state('preview.mission.form', {
      url: '/form?viewRestored',
      template: `
        <ion-view class="sf_form"
          cache-view="false"
          can-swipe-back="false">

          <sf-form
            form="$ctrl.form"
            report="$ctrl.report"
            place="$ctrl.place"
            user="$ctrl.user"
            is-preview="true">
          </sf-form>

        </ion-view>
      `,
      controller: FormViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Preview form',
      resolve: {
        rsvpReport: getRsvpReport,
      },
    })
    .state('preview.question', {
      url: '/question?question',
      template: `
        <ion-view class="sf_form"
          cache-view="false"
          can-swipe-back="false">

          <sf-form-question
            question="$ctrl.question"
            report="$ctrl.report"
            form="$ctrl.form"
            node-id="{{ ::$ctrl.nodeId }}"
            form-errors="$ctrl.formErrors"
            is-done="$ctrl.isDone"
            is-preview="$ctrl.isPreview"
            unconfirmed-prefilled-questions="{ ids: [] }"
            available-previous-answers="[]"
            is-debug="true"
            is-displayed="true"
          >
          </sf-form-question>

        </ion-view>
      `,
      controller: QuestionViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Preview question',
    });

  function getProfile($rootScope, $stateParams, profileService, apiUrlService) {
    'ngInject';
    const { jwt: paramJwt, url: backendUrl } = $stateParams;

    $rootScope.jwt = paramJwt;

    apiUrlService.setApiUrl(backendUrl);

    const headers = {
      Authorization: profileService.getAuthorizationHeader({
        contents: {
          token: null,
          jwt: paramJwt,
        },
      }),
    };
    // Weirdly I get a string array as audience (aud), first index is api locally
    // TODO: need to test if its still the case in staging/prod
    profileService._localProfilePromise =
      profileService.getConnectedUserProfile(
        {
          headers,
        },
        backendUrl
      );
    return profileService._localProfilePromise;
  }

  function CampaignViewController(
    rsvpProfile,
    rsvpForm,
    rsvpCampaign,
    rsvpPlace
  ) {
    'ngInject';
    this.form = rsvpForm;
    this.campaign = rsvpCampaign;
    this.profile = rsvpProfile;
    this.place = rsvpPlace;
  }
  function TemplateViewController(
    rsvpProfile,
    rsvpTemplate,
    rsvpTemplateReport,
    rsvpPlace
  ) {
    'ngInject';
    this.template = rsvpTemplate;
    this.report = rsvpTemplateReport;
    this.profile = rsvpProfile;
    this.place = rsvpPlace;
  }
  function FormViewController(rsvpUser, rsvpForm, rsvpReport, rsvpPlace) {
    'ngInject';
    this.form = rsvpForm;
    this.report = rsvpReport;
    this.place = rsvpPlace;
    this.user = rsvpUser;
  }
  function CampaignFormViewController(
    rsvpUser,
    rsvpCampaign,
    rsvpReport,
    rsvpPlace
  ) {
    'ngInject';
    this.campaign = rsvpCampaign;
    this.report = rsvpReport;
    this.place = rsvpPlace;
    this.user = rsvpUser;
  }
  function QuestionViewController(
    rsvpProfile,
    $stateParams,
    objectIdService,
    reportsService
  ) {
    'ngInject';
    const { question } = $stateParams;

    this.nodeId = objectIdService.create();
    this.formErrors = {};
    this.isPreview = true;
    this.profile = rsvpProfile;
    this.form = {
      _id: objectIdService.create(),
      contents: {
        section: [],
        questions: [],
        owner_id: this.profile._id,
      },
    };
    this.report = reportsService.getNewReport(
      objectIdService.create(),
      this.form,
      this.profile
    );
    this.availablePreviousAnswers = [];

    if (!question) {
      return false;
    }
    try {
      this.question = JSON.parse(question);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  function getRsvpForm(rsvpProfile, $stateParams, formsService) {
    'ngInject';
    return formsService.getRemote($stateParams.formId);
  }

  function getRsvpCampaign(rsvpProfile, $stateParams, campaignsService) {
    'ngInject';
    return campaignsService.crud.getRemote(
      $stateParams.campaignId,
      {},
      { pov: 'organisation' }
    );
  }

  function getRsvpTemplate(rsvpProfile, $stateParams, templatesService) {
    'ngInject';
    return templatesService.crud.getRemote(
      $stateParams.templateId,
      {},
      { pov: 'organisation' }
    );
  }

  // eslint-disable-next-line max-params
  function getRsvpReport(
    objectIdService,
    formService,
    campaignsUtilsService,
    rsvpCampaign,
    rsvpForm,
    rsvpPlace,
    rsvpProfile
  ) {
    'ngInject';
    if (rsvpForm) {
      return formService.initReport(objectIdService.create(), {
        place: rsvpPlace,
        profile: rsvpProfile,
        form: rsvpForm,
      });
    } else {
      return campaignsUtilsService.initReport(
        objectIdService.create(),
        rsvpCampaign,
        rsvpProfile,
        rsvpPlace ? rsvpPlace._id : undefined
      );
    }
  }

  function getRsvpTemplateReport(
    templatesService,
    objectIdService,
    rsvpTemplate,
    rsvpProfile,
    rsvpPlace
  ) {
    'ngInject';
    return templatesService.initReport(
      objectIdService.create(),
      rsvpTemplate,
      rsvpProfile,
      rsvpPlace ? rsvpPlace._id : undefined
    );
  }

  // eslint-disable-next-line max-params
  function getRsvpPlace(rsvpProfile, $q, $stateParams, placesService) {
    'ngInject';
    const { onStore } = $stateParams;

    return onStore ? placesService.getRemote(onStore) : $q.when(null);
  }
  function getRsvpUser(rsvpProfile, $q, $stateParams, usersService) {
    'ngInject';
    const { asUser } = $stateParams;

    return asUser ? usersService.getUser(asUser) : $q.when(rsvpProfile);
  }
}
