const LOCAL_STORE_INCOMPLETE_SYNC_KEY = 'is_places_sync_incomplete';

export class PlacesSourceAdapterService {
  // eslint-disable-next-line max-params
  constructor(
    $q,
    pubSubService,
    placesService,
    geolocationService,
    remoteStoresService,
    localStorageService,
    placesParamsService
  ) {
    'ngInject';

    this.$q = $q;
    this.pubSubService = pubSubService;
    this.placesService = placesService;
    this.geolocationService = geolocationService;
    this.remoteStoresService = remoteStoresService;
    this.localStorageService = localStorageService;
    this.placesParamsService = placesParamsService;

    /*
     * Note: We need to refresh this value on synchronization. Otherwise,
     * localStore data does not exist at first app opening and isRemoteSource
     * is wrongly falsy.
     */
    pubSubService.subscribe(pubSubService.GLOBAL_EVENTS.DATA_SYNCED, () => {
      this.setRemoteSource();
    });
    pubSubService.subscribe(
      pubSubService.GLOBAL_EVENTS.DATA_SYNCED_FAILED,
      () => {
        this.setRemoteSource();
      }
    );
  }

  setRemoteSource() {
    this.placesService.isRemoteSource =
      this.localStorageService.get(LOCAL_STORE_INCOMPLETE_SYNC_KEY) &&
      navigator.onLine;
  }

  /**
   * Use DataSourceAdapter to query from local or remote database
   *
   * @param {Object} params    - Request options
   * @param {String} forceUrl  - Url to override default endpoint
   * @return {Promise}         - Query result
   *
   */
  queryFromDataSource(params, forceUrl, config) {
    const getDistance = (from, place) =>
      from &&
      place.contents &&
      place.contents.latLng &&
      place.contents.latLng.length
        ? this.placesService.calculateDistance(from, {
            lat: place.contents.latLng[0],
            lng: place.contents.latLng[1],
          })
        : null;
    const localQueryParamsComparator =
      this.placesParamsService.filterLocalParams;

    return this.remoteStoresService
      .isPreferenceActivated()
      .then((preference) => {
        return this.placesService
          .query(
            preference,
            params,
            forceUrl,
            config,
            localQueryParamsComparator
          )
          .then((data) => {
            if (preference && this.placesService.isRemoteSource) {
              return this.geolocationService
                .getCurrentPosition('queryFromDataSource')
                .then((from) => ({
                  ...data,
                  entries: data.entries.map((place) => ({
                    ...place,
                    distance: getDistance(from, place),
                  })),
                }))
                .catch(() => data);
            }

            return data;
          });
      });
  }

  /**
   * Return the places associated to a form sent in permanent mode
   *
   * @param {Object} form     - Form places source
   * @param {Array} localIds  - Set of ids to include in local db
   * @param {Object} params   - Request params
   * @return {Promise}        - Places associated to the form
   */
  getPlacesOfPermanentForm(form, localIds, params) {
    if (!this.placesService.isRemoteSource && localIds && !localIds.length) {
      return this.$q.when({ entries: [] });
    }
    const filters = {
      ...(this.placesService.isRemoteSource
        ? {}
        : { id: { value: localIds, operation: 'equality' } }),
      ...params.filters,
    };

    return this.getPlacesOfForm(false, form, { ...params, filters });
  }

  /**
   * Return the places associated to a form sent in punctual mode
   *
   * @param {Object} form     - Form places source
   * @param {Array} localIds  - Set of ids to include in local db
   * @param {Array} remoteExludedIds  - Set of ids to exclude in remote db
   * @param {Object} params   - Request params
   * @return {Promise}        - Places associated to the form
   */
  getPlacesOfPunctualForm(form, localIds, remoteExludedIds, params) {
    if (!this.placesService.isRemoteSource && localIds && !localIds.length) {
      return this.$q.when({ entries: [] });
    }
    const filters = {
      ...(this.placesService.isRemoteSource
        ? { id: { value: remoteExludedIds, operation: 'inequality' } }
        : { id: { value: localIds, operation: 'equality' } }),
      ...params.filters,
    };

    return this.getPlacesOfForm(true, form, { ...params, filters });
  }

  /**
   * Return the places associated to a form
   *
   * @param {Boolean} hasPunctualMission - Should we fetch places associated to a ponctual mission
   * @param {Object} form                - Form places source
   * @param {Object} params              - Request params
   * @return {Promise}                   - Places associated to the form
   */
  getPlacesOfForm(hasPunctualMission, form, params) {
    const url = `/forms/${form._id}/places`;

    return this.queryFromDataSource({ ...params, hasPunctualMission }, url);
  }

  /**
   * Return the places associated to a campaign
   *
   * @param {Object} campaign            - Campaign places source
   * @param {Object} params              - Request params
   * @return {Promise}                   - Places associated to the campaign
   */
  getPlacesOfCampaign(campaign, params) {
    const url = `/campaigns/${campaign._id}/places`;

    const { id, ...rest } = params.filters;
    return this.remoteStoresService
      .isPreferenceActivated()
      .then((shouldFetchStoresOnline) => {
        const filters = {
          ...(this.placesService.isRemoteSource && shouldFetchStoresOnline
            ? {}
            : {
                id: id,
              }),
          ...rest,
        };

        return this.queryFromDataSource({ ...params, filters }, url, {
          pov: 'organisation',
        });
      });
  }

  getReportsPlacesHashByIds(reports) {
    const placesIds = reports
      .filter((report) => !!report.contents.place_id)
      .map((report) => report.contents.place_id);

    return this.queryFromDataSource({
      filters: {
        id: { operation: 'equality', value: placesIds },
      },
    }).then((places) => {
      return places.entries.reduce((acc, place) => {
        acc[place._id] = place;
        return acc;
      }, {});
    });
  }
}
