import { PopupService } from '../../../services/Utils/Popup/popup.service';
import type { AddressCheckResult } from '../../../services/Utils/Geolocation/geolocation.service';
import { PlaceAddress } from '../..';
import { ErrorMessagesService } from '../../../services/Utils/ErrorMessages/error-messages.service';

export class PlacePopupService {
  // eslint-disable-next-line max-params
  constructor(
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private popupService: PopupService,
    private errorMessagesService: ErrorMessagesService,
    private htmlService
  ) {
    'ngInject';
  }

  showCloseEditConfirm(): ng.IPromise<void> {
    return this.popupService.showConfirm({
      title: this.$translate.instant('PLACE_INFOS_EDIT_CANCEL_CONFIRM_TITLE'),
      iconName: 'save-danger',
      buttonText: this.$translate.instant(
        'PLACE_INFOS_EDIT_CANCEL_CONFIRM_YES'
      ),
    });
  }

  showSuggestPopup(
    inputedAddress: string,
    result: AddressCheckResult,
    afterClose: () => void
  ): ng.IPromise<PlaceAddress | undefined> {
    const defer = this.$q.defer<PlaceAddress | undefined>();

    const inputtedAddress = [
      this.$translate.instant('PLACE_ADD_AUTOSUGEST_L1'),
      this.htmlService.wrapper('strong')(inputedAddress),
    ].join('\n');
    const suggestedAddress = [
      this.$translate.instant('PLACE_ADD_AUTOSUGEST_L2'),
      this.htmlService.wrapper('strong')(result.readable),
    ].join('\n');

    const template = `
      <sf-popup-template title="::$ctrl.title" icon-name="'empty/no_pos'">
        <sf-place-address-suggest
          inputted-address="${inputtedAddress}",
          suggested-address="${suggestedAddress}"
          on-inputted-action="$ctrl.onInputAction"
          on-suggested-action="$ctrl.onSuggestedAction"
          after-close="$ctrl.afterClose"
          on-close="$ctrl.onClose"
        >
        </sf-place-address-suggest>
      </sf-popup-template>
    `;
    const bindings = {
      title: this.$translate.instant('PLACE_ADD_UNKNOWN_ADDRESS'),
      onInputAction: defer.resolve,
      onSuggestedAction: () => defer.resolve(result.data),
      afterClose,
    };

    this.popupService.showCustomTemplate({ template, bindings });

    return defer.promise;
  }

  showAddressErrorPopup(address: string): ng.IPromise<void> {
    const defer = this.$q.defer<void>();
    const message = [
      this.$translate.instant('PLACE_ADD_NOT_FOUND_ADDRESS_DESC'),
      this.htmlService.wrapper('strong')(address),
    ].join('\n');
    const options = {
      title: this.$translate.instant('PLACE_ADD_NOT_FOUND_ADDRESS_TITLE'),
      desc: message,
      iconName: 'empty/no_pos',
      btnFirstOption: this.$translate.instant(
        'PLACE_ADD_NOT_FOUND_ADDRESS_CONFIRM'
      ),
      btnSecondOption: this.$translate.instant(
        'PLACE_ADD_NOT_FOUND_ADDRESS_EDIT'
      ),
    };

    this.popupService.showOptions(options, defer.resolve, defer.reject);

    return defer.promise;
  }

  showServiceUnavailablePopup(): ng.IPromise<void> {
    const defer = this.$q.defer<void>();
    const message = this.$translate.instant(
      'PLACE_ADD_SERVICE_UNAVAILABLE_DESC'
    );
    const options = {
      title: this.$translate.instant('PLACE_ADD_SERVICE_UNAVAILABLE_TITLE'),
      desc: message,
      iconName: 'error',
      btnFirstOption: this.$translate.instant(
        'PLACE_ADD_SERVICE_UNAVAILABLE_CONFIRM'
      ),
      btnSecondOption: this.$translate.instant(
        'PLACE_ADD_SERVICE_UNAVAILABLE_EDIT'
      ),
    };

    this.popupService.showOptions(options, defer.resolve, defer.reject);

    return defer.promise;
  }

  showSaveErrorPopup(error): ng.IPromise<void> {
    const defer = this.$q.defer<void>();
    const errorMessage = this.errorMessagesService.getMessage(error, {
      customUnknownErrorMessage: 'PLACE_ADD_NO_NETWORK_DESC',
    });
    const options = {
      title: this.$translate.instant('PLACE_ADD_NO_NETWORK_TITLE'),
      desc: errorMessage,
    };

    this.popupService.showSaveError(options, defer.resolve, defer.reject);

    return defer.promise;
  }

  showParamsEditPrompt(): ng.IPromise<{ shouldEditParams: boolean }> {
    const defer = this.$q.defer<{ shouldEditParams: boolean }>();
    const template = `
      <sf-popup-template title="::$ctrl.title" icon-name="'success-checkmark'">
        <div class="padding">{{ ::$ctrl.description }}</div>
        <div class="padding">
          <button 
            type="button"
            class="button button-dark button-block"
            ng-click="$ctrl.openStoreParams($ctrl.onClose)"
          >
            {{ ::$ctrl.fillBtnTxt }}
          </button>
          <button
            type="button"
            class="button button-blank button-block button-bold-text"
            ng-click="$ctrl.openStoreDetails($ctrl.onClose)"
          >
            {{ ::$ctrl.laterBtnTxt }}
          </button>
        </div>
      </sf-popup-template>
    `;
    const shouldEditParamsFn = (shouldEditParams: boolean) => (close) => {
      close();
      defer.resolve({ shouldEditParams });
    };
    const bindings = {
      title: this.$translate.instant('PLACE_CREATED_MODAL_TITLE'),
      description: this.$translate.instant('PLACE_CREATED_MODAL_DESC'),
      fillBtnTxt: this.$translate.instant('PLACE_CREATED_MODAL_FILL'),
      laterBtnTxt: this.$translate.instant('PLACE_CREATED_MODAL_LATER'),
      openStoreParams: shouldEditParamsFn(true),
      openStoreDetails: shouldEditParamsFn(false),
    };

    this.popupService.showCustomTemplate({ template, bindings });

    return defer.promise;
  }
}
