import * as blobUtil from 'blob-util';
import * as Sentry from '@sentry/browser';
import { User } from '../../..';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { FilesService } from '../../../services/API/files/files.service';
import { ImageService } from '../../../services/Utils/Image/image.service';
import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import { SentryService } from '../../../services/Utils/Sentry/sentry.service';
import { MerchandisingPopupService } from '../../services/merchandising-popup.service';

export const MerchandisingPhotoDrawingComponent = {
  bindings: {
    profile: '<',
    validation: '<',
    answer: '<',
    onClose: '&',
    onSave: '&',
  },
  templateUrl:
    'merchandising/components/merchandising-photo-drawing/merchandising-photo-drawing.html',
  // eslint-disable-next-line max-params
  controller: class MerchandisingPhotoDrawingController {
    // bindings
    profile: User;
    validation: any;
    answer: any;
    onClose: () => () => void;
    onSave: () => () => void;
    getOriginalSizeDrawing: () => string;

    // class members
    blobUtil = blobUtil;
    isLoading: boolean;
    networkError: boolean;
    maxDrawingWidth: number;
    maxDrawingHeight: number;
    pictureUrl: string;

    constructor(
      private $window: ng.IWindowService,
      private $q: ng.IQService,
      private filesService: FilesService,
      private imageService: ImageService,
      private merchandisingApiService,
      private merchandisingPopupService: MerchandisingPopupService,
      private objectIdService: ObjectIdService,
      private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
      private sentryService: SentryService
    ) {
      'ngInject';
    }

    $onInit() {
      return this.reload();
    }

    reload() {
      this.isLoading = true;
      this.networkError = false;

      this.maxDrawingWidth = this.$window.innerWidth;
      this.maxDrawingHeight = this.$window.innerHeight;

      return this.imageService
        .getSizedUrlFromId(
          getPictureIdFromAnswer(this.answer),
          this.SF_IMAGE_SIZES.SQUARE_BIG
        )
        .then((url) => {
          this.pictureUrl = url;
          return url;
        })
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });

      function getPictureIdFromAnswer(answer) {
        return answer.values[0].value;
      }
    }

    close() {
      return this.merchandisingPopupService
        .showDrawLeaveConfirm()
        .then(() => this.onClose())
        .catch(() => null);
    }

    saveDrawing() {
      const drawingId = this.objectIdService.create();
      const cancelPromise = this.$q.defer<void>();
      const popupSending =
        this.merchandisingPopupService.showDrawingSendingProgress(() => {
          cancelPromise.resolve();
          popupSending.clear();
        });

      let blob;
      try {
        const canvasDrawing = this.getOriginalSizeDrawing();
        blob = this.blobUtil.dataURLToBlob(canvasDrawing);
        if (!blob || blob.size === 0) {
          throw new Error('An error occured when blob is empty after drawing.');
        }
      } catch (error) {
        this.sentryService.captureMessage('EMPTY DRAWN IMAGE', {
          level: Sentry.Severity.Error,
          extra: {
            error,
            originalPictureUrl: this.pictureUrl,
          },
          tags: {
            mobile: 'merchandising',
          },
        });
        return this.merchandisingPopupService.showDrawingError();
      }

      return this.filesService
        .upload(blob, drawingId, { canceler: cancelPromise })
        .then(() =>
          this.merchandisingApiService.sendPictureComment(
            this.validation,
            this.profile,
            drawingId
          )
        )
        .then(() => popupSending.onSuccess())
        .then(() => this.onSave()())
        .catch(() =>
          popupSending.onError(
            () => this.saveDrawing(),
            () => {}
          )
        );
    }
  },
};
