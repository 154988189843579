import { pick } from 'ramda';
import { AutoTask, Task } from '../..';
import { ObjectId, User, UserContents } from '../../..';
import { APIStore } from '../../../places';
import { Form, FormQuestion } from '../../../services/API/forms/forms';
import { Report } from '../../../services/API/reports/reports';
import { TasksService } from '../../../services/API/tasks/tasks.service';
import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import { SF_TASK_STATUSES } from '../../constants/task-statuses.constant';
import { TaskService } from '../../services/task.service';

export const TASK_PRIORITIES = [
  {
    label: 'MANAGE_TASK_PRIORITY_LOW_LABEL',
    value: 0,
  },
  {
    label: 'MANAGE_TASK_PRIORITY_MEDIUM_LABEL',
    value: 10,
  },
  {
    label: 'MANAGE_TASK_PRIORITY_HIGH_LABEL',
    value: 20,
  },
];
export class TaskManageService {
  constructor(
    private tasksService: ReturnType<typeof TasksService>,
    private TASK_STATUSES: typeof SF_TASK_STATUSES,
    private objectIdService: ObjectIdService,
    private taskService: TaskService
  ) {
    'ngInject';
  }

  saveTask(vmFormTask: Task, offlineMode: boolean): ng.IPromise<Task> {
    const task = {
      _id: vmFormTask._id,
      isAutotask: vmFormTask.isAutotask,
      isMandatory: vmFormTask.isMandatory,
      contents: {
        ...vmFormTask.contents,
        status: vmFormTask.contents.status || this.TASK_STATUSES.TODO.keyword,
      },
    } as unknown as Task;
    const subtasks = vmFormTask.contents.subtasks; // to preserve assignees for displaying

    return this.tasksService
      .saveTask(task, offlineMode)
      .then((task) => ({ ...task, contents: { ...task.contents, subtasks } }));
  }

  resolveTask(params: {
    profile: User;
    question: FormQuestion;
    form: Form;
    report: Report;
    place?: APIStore;
    assignee?: User;
    taskTemplate: AutoTask['template'];
    autoTask_id?: ObjectId;
  }): Task {
    const {
      profile,
      question,
      form,
      report,
      place,
      taskTemplate,
      autoTask_id,
    } = params;
    let task = this.taskService.getQuestionTaskObject(
      question,
      form,
      report,
      place
    );
    task.contents = {
      ...task.contents,
      ...pick(
        [
          'name',
          'priority',
          'description',
          'photos_ids',
          'files_ids',
          'subtasks',
        ],
        taskTemplate
      ),
    };
    task = this._resolveTask({
      task,
      autoTask_id,
      isMandatory: taskTemplate.isMandatory,
      profile: profile,
      // for users only checklists the place is missing, so the task assignee is mandatory
      assignee: place?._id
        ? undefined
        : { _id: profile._id, ...profile.contents },
    });

    return task;
  }

  private _resolveTask(props: {
    task: Partial<Task>;
    profile: User;
    autoTask_id?: ObjectId;
    isMandatory: boolean;
    assignee?: UserContents & { _id: ObjectId };
  }): Task {
    const { task, profile, assignee, autoTask_id, isMandatory } = props;
    const resolved: Task = {
      ...task,
      _id: task._id ?? this.objectIdService.create(),
      autoTask_id,
      isMandatory,
      contents: {
        name: 'edit name',
        description: '',
        status: 'todo',
        organisation_id: profile.contents.organisation_id,
        owner_id: profile._id,
        priority: TASK_PRIORITIES[0],
        assignee_id: assignee?._id,
        ...task.contents,
      },
      assignee: assignee || {},
    } as Task;

    return resolved;
  }
}
