import { PopupMovedToDraftController } from './popup-moved-to-draft.controller';

export const PopupMovedToDraftComponent: ng.IComponentOptions = {
  bindings: {
    onClose: '&',
    onSuccess: '&',
    errorMessage: '<?',
  },
  transclude: true,
  templateUrl:
    'components/Popup/popup-moved-to-draft/popup-moved-to-draft.html',
  controller: PopupMovedToDraftController,
};
