import { DateService } from './date.service';
import { DateFormatService } from './date-format.service';

export default angular
  .module('sfMobile.services.dates', [
    'pascalprecht.translate',
    'sfMobile.constants.dates-formating',
  ])
  .service('dateService', DateService)
  .service('dateFormatService', DateFormatService).name;
