import { TasksFiltersModalComponent } from './tasks-filters-modal.component';

export default angular
  .module('sfMobile.components.tasks-filters-modal', [
    'sfMobile.services.translateNumbers',
    'sfMobile.services.helpers',
    'sfMobile.services.localization',
    'sfMobile.services.profile',
    'sfMobile.services.tasks',
    'sfMobile.services.users',
  ])
  .component('sfTasksFiltersModal', TasksFiltersModalComponent).name;
