import { DebugService } from '@services/Utils/Debug/debug.service';
import { DataAccessLayerService } from '../../Utils/CRUD/crud-service';
import { CrudFactory } from '../../Utils/CRUD/crud-service.factory';
import { Report } from '../reports/reports';
import { ReportVersion, ReportVersionInfo } from './report-versions';
import { ObjectId } from 'app';
import { PubSubService } from '@services/Utils/PubSub/pubsub.service';
import { ObjectIdService } from '@services/Utils/Objectid/objectId.service';

const REPORT_VERSIONS_URL = '/report-versions';
const MAX_VERSIONS = 10;
export class ReportVersionsService {
  crud: DataAccessLayerService<ReportVersion>;

  constructor(
    private $q: ng.IQService,
    private $http: ng.IHttpService,
    private databaseSchema,
    private crudFactory: CrudFactory<ReportVersion>,
    private objectIdService: ObjectIdService,
    private pubSubService: PubSubService,
    private debugService: DebugService,
    private pingService,
    private SF_ERROR_CODES
  ) {
    'ngInject';
    const tableConfig = this.databaseSchema.tables.report_versions;
    this.crud = this.crudFactory(tableConfig.table_name, {
      take_care_of_user_profile: false,
      exclude_offline_params: ['states', 'date_field', 'start_date'],
      backup: {
        indexed_fields: tableConfig.indexed_fields.map((field) => field.name),
      },
      path_name: REPORT_VERSIONS_URL,
    }) as DataAccessLayerService<ReportVersion>;
    this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.NETWORK_CONNECTED,
      () => {
        this.flush();
      }
    );
  }

  create(report: Report, action_type: string): ng.IPromise<void> {
    return this.createRemote(report, {
      action_date: new Date(),
      action_type,
    }).catch((e) => {
      if (this.SF_ERROR_CODES.CODES_SERVER_NO_NETWORK.includes(e.status)) {
        return this.createLocal(report, {
          action_date: new Date(),
          action_type,
        });
      }
      this.debugService.log('Could not create remote report version', {
        report,
        e,
      });
      return this.$q.resolve();
    });
  }

  private createLocal(
    report: Report,
    info: ReportVersionInfo
  ): ng.IPromise<void> {
    return this.crud
      .countLocal()
      .then((count) => {
        if (count < MAX_VERSIONS) {
          const id = this.objectIdService.create();
          return this.crud.saveLocal(id, this.toLocal(id, report, info));
        }
        this.debugService.log(
          `Exceeded ${MAX_VERSIONS} local report version, skipping local save`,
          report
        );
        return this.$q.when(report);
      })
      .then(() => this.$q.resolve())
      .catch((e) => {
        this.debugService.log('Could not create local report version', {
          report,
          e,
        });
        return this.$q.resolve();
      });
  }

  private createRemote(
    report: Report,
    info: ReportVersionInfo
  ): ng.IPromise<void> {
    return this.pingService
      .ping()
      .then(() => this.crud.useOrgPOV(REPORT_VERSIONS_URL))
      .then((url) =>
        this.$http.post<void>(url, {
          contents: {
            report_id: report._id,
            report_json: JSON.stringify(report),
          },
          info: {
            ...info,
            action_storage: info.action_storage ?? 'remote',
          },
        })
      )
      .then(() => this.$q.resolve());
  }

  private flush(): ng.IPromise<void> {
    return this.pingService
      .ping()
      .then(() => this.crud.listLocal())
      .then((versions) => {
        this.debugService.log(
          `Attempting to flush ${versions?.length} local reports versions`,
          {}
        );
        return this.$q.all(versions.map((version) => this.flushLocal(version)));
      })
      .then(() => this.$q.resolve())
      .catch((e) => {
        this.debugService.log('Could not flush local reports versions', e);
        return this.$q.resolve();
      });
  }

  private flushLocal(version: ReportVersion): ng.IPromise<void> {
    return this.createRemote(version, version.info)
      .then(() => this.crud.deleteLocal(version.id))
      .then(() => this.$q.resolve());
  }

  private toLocal(
    id: ObjectId,
    report: Report,
    info: ReportVersionInfo
  ): ReportVersion {
    return {
      ...report,
      id,
      info: {
        ...info,
        action_storage: 'local',
      },
    };
  }
}
