const COLLAPSED_TEXT_LENGTH = 255;

export class TextExpanderController {
  title: string;
  text: string;
  expanded = false;

  showIcon = false;

  $onChanges() {
    this.showIcon = this.text?.length >= COLLAPSED_TEXT_LENGTH;
  }

  getText(): string {
    if (this.expanded) {
      return this.text;
    }

    const description = this.text?.substring(0, COLLAPSED_TEXT_LENGTH);

    return this.showIcon ? `${description}...` : description;
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }
}
