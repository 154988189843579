import type { SfFeatureFlags } from '@simplifield/feature-flags';
import { StateService } from '@uirouter/core';
import {
  LINKS_CONFIG,
  NOTIFICATION_METAEVENTS,
  NotificationConfig,
  NotificationEventName,
} from './notifications.constant';
import { MobileDevices, User } from '../../..';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';
import {
  DeviceNotification,
  PushNotificationsService,
} from '../../../services/Utils/PushNotifications/push-notifications.service';
import { NotificationsApiService } from '../notifications-api/notifications-api.service';

const ACTIVITY_TRACK: Record<NotificationEventName, string> = {
  NEW_MISSION: 'Mission',
  NEW_CHAT_MESSAGE: 'Chat message',
  VALIDATION_REJECTED: 'Validation rejected',
  NEW_TASK: 'Task Details',
  REPORT_APPROVED: 'To approved',
  REPORT_REJECTED: 'To revise',
  NEW_NEWSFEED_POST: 'Newsfeed posts',
  NEW_PLACE_DOCUMENT: 'Place document',
  REPORT_COMMENT_CREATED: 'Report comment',
  TASK_COMMENT_CREATED: 'Task comment',
  CAMPAIGN_ACTIVATED: 'Campaign activated',
  ANSWER_COMMENT_VIEW: 'Answer comment published',
  ROUTINE_CHECKLIST_ACTIVATION: 'Routine checklist was shared',
  ROUTINE_CHECKLIST_REMINDER: 'Routine checklist reminder',
};

export const STORAGE_LAST_NOTIFICATION_KEY = 'last_display_notification_date';

type NotificationLink = {
  name: string;
  params: Record<string, string>;
};
type NotificationTriggerEvent = `NOTIFICATION:TRIGGER:${NotificationEventName}`;
type NotificationMetaEvents = keyof typeof NOTIFICATION_METAEVENTS;
export class NotificationsService {
  // eslint-disable-next-line max-params
  #unsubscribe: () => void;

  constructor(
    private $q: ng.IQService,
    private pubSubService: PubSubService<
      NotificationMetaEvents | NotificationTriggerEvent
    >,
    private $state: StateService,
    private userDevicesService,
    private pushNotificationsService: PushNotificationsService,
    private segmentService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private localStorageService: ng.local.storage.ILocalStorageService,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private notificationsApiService: NotificationsApiService
  ) {
    'ngInject';

    this.#unsubscribe = pubSubService.subscribe(
      NOTIFICATION_METAEVENTS.NOTIFICATION_RECEIVED,
      (notif: DeviceNotification): void => {
        this.onNotificationReceived(notif);
      }
    );
  }

  $onDestroy(): void {
    this.#unsubscribe();
  }

  hasNotificationsViewFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NOTIFICATIONS_VIEW
    );
  }

  init(): ng.IPromise<string> {
    return this.pushNotificationsService.init();
  }

  reset(): void {
    this.removeLastDisplayNotificationDate();
  }

  registerMobileToken(): ng.IPromise<void> {
    return this.pushNotificationsService
      .isEnabled()
      .then((isEnabled: boolean): ng.IPromise<void> => {
        if (!isEnabled) {
          return this.$q.resolve();
        }
        return this.saveMobileToken();
      });
  }

  activate(): ng.IPromise<void> {
    this.segmentService.track('NOTIFICATION', {
      action: 'tap',
      label: 'Activate',
    });

    return this.pushNotificationsService
      .enable()
      .then(() => this.saveMobileToken());
  }

  deactivate(): ng.IPromise<User> {
    this.segmentService.track('NOTIFICATION', {
      action: 'tap',
      label: 'Deactivate',
    });

    return this.pushNotificationsService
      .disable()
      .then(() => this.userDevicesService.deleteMobileDevice());
  }

  saveMobileToken(): ng.IPromise<void> {
    return this.init().then((token) => {
      return this.userDevicesService.getMobileDevices().then((devices) => {
        const isTokenExist = devices.find((device) => token === device.token);

        return isTokenExist
          ? this.$q.when(null)
          : this.userDevicesService.addMobileDevice(token);
      });
    });
  }

  isRegistered(): ng.IPromise<boolean> {
    return this.$q
      .all([
        this.pushNotificationsService.isEnabled(),
        this.userDevicesService.getMobileDevices(),
      ])
      .then(
        ([isEnabled, devices]: [boolean, MobileDevices[]]) =>
          !!devices.length && isEnabled
      );
  }

  onNotificationReceived(notif: DeviceNotification): void {
    const notifLink = this.parseInfo(notif.data.link);
    const notifConfig = this.getNotifConfig(notifLink.name);

    // If the tap on the notification
    if (notif.tap && notifConfig) {
      this.trackNotificationTap(notifLink.name);
      this.redirectNotification(notifLink, notifConfig);
      return;
    }

    // If not tap, it's a foreground notification
    this.pubSubService.publish(
      NOTIFICATION_METAEVENTS.NOTIFICATION_DISPLAY,
      notif
    );
  }

  redirectNotification(
    notifLink: NotificationLink,
    notifConfig: NotificationConfig
  ): ng.IPromise<void> {
    const redirectState = this.getNotifState(notifLink, notifConfig);

    if (!redirectState) {
      return this.$q.resolve();
    }

    const { state, params = {} } = redirectState;

    return this.$state.go(state, params).then(() => {
      if (notifConfig.event) {
        const eventName = this.getEventName(notifConfig.event);

        this.pubSubService.publish(eventName, params);
      }
      if (notifConfig.reloadData) {
        this.pubSubService.publish(
          this.pubSubService.GLOBAL_EVENTS.SYNCHRONIZE_APP
        );
      }
    });
  }

  redirectNotificationOnClick({ data }: DeviceNotification): void {
    const notifInfo = this.parseInfo(data.link);
    const notifConfig = this.getNotifConfig(notifInfo.name);

    if (notifInfo && notifConfig) {
      this.redirectNotification(notifInfo, notifConfig);
    }
  }

  parseInfo(notifInfo?: string): NotificationLink {
    if (!notifInfo) {
      throw new Error('No information available');
    }
    return JSON.parse(notifInfo);
  }

  getNotifConfig(name: string): NotificationConfig | null {
    return LINKS_CONFIG[name] || null;
  }

  getNotifState(
    notifLink: NotificationLink,
    { state, getParams }: NotificationConfig
  ): { state: string; params: Record<string, string | boolean> } {
    if (!notifLink) {
      throw new Error('[Push notifications] notifLink parameter is missing');
    }

    const { params } = notifLink;

    return {
      state,
      params: params && getParams ? getParams(params) : {},
    };
  }

  getEventName(eventName: NotificationEventName): NotificationTriggerEvent {
    return `NOTIFICATION:TRIGGER:${eventName}` as NotificationTriggerEvent;
  }

  trackNotificationTap(name: string): void {
    const activity = ACTIVITY_TRACK[name];

    if (activity) {
      this.segmentService.track('NOTIFICATION', {
        action: 'OpenNotification',
        label: activity,
      });
    }
  }

  getLastDislayNotificationDate(): Date | null {
    const lastDisplayNotificationDate = this.localStorageService.get(
      STORAGE_LAST_NOTIFICATION_KEY
    );

    return lastDisplayNotificationDate
      ? new Date(lastDisplayNotificationDate)
      : null;
  }

  setLastDislayNotificationDate(notification): number {
    const lastDisplayNotificationDate = notification
      ? new Date(notification.created_date).getTime()
      : new Date().getTime();

    this.localStorageService.set(
      STORAGE_LAST_NOTIFICATION_KEY,
      lastDisplayNotificationDate
    );
    return lastDisplayNotificationDate;
  }

  removeLastDisplayNotificationDate(): void {
    this.localStorageService.remove(STORAGE_LAST_NOTIFICATION_KEY);
  }

  getLastSeenNotificationInfo(): ng.IPromise<void> {
    const params = { start_date: this.getLastDislayNotificationDate() };

    return this.notificationsApiService
      .getLastSeenNotificationsInfo(params)
      .then(({ count }) => this.broadcastLastSeenNotificationsInfo(count));
  }

  broadcastLastSeenNotificationsInfo(notificationsCount: number): void {
    this.pubSubService.publish(
      NOTIFICATION_METAEVENTS.LAST_SEEN_NOTIFICATIONS_UPDATED,
      notificationsCount
    );
  }
}
