import TRANSLATIONS from '../helpers/translations';
// Loader
export const getLoader = (dashboardComponent) => {
    // No loader if error or browser not supported
    let errorMsg;
    if (dashboardComponent.params.error
        || dashboardComponent.browserNotSupported.length > 0) {
        dashboardComponent.params.error = true;
        if (dashboardComponent.browserNotSupported) {
            errorMsg = 'Browser is not supported.';
        }
    }
    const loadingMsg = TRANSLATIONS.initializing[dashboardComponent.params.language]
        || TRANSLATIONS.initializing.en;
    return dashboardComponent.browserNotSupported
        || dashboardComponent.params.error
        ? { loadingMsg, errorMsg }
        : { loadingMsg, errorMsg: '' };
};
