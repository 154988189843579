import {
  isAnExpiredTokenCode,
  LOGOUT_AFTER_REFRESH_CODES,
} from './auth-helpers';

export default function refreshTokenInterceptor($q, $injector) {
  'ngInject';
  const $translate = $injector.get('$translate');

  return { responseError: errorRefreshToken };

  function errorRefreshToken(error) {
    if (!error.response || !error.response.config) {
      return $q.reject(error);
    }
    const config = error.response.config;
    const couldRetry =
      error.response.status === 401 && isAnExpiredTokenCode(error.response);

    if (couldRetry) {
      const popupService = $injector.get('popupService');
      const $http = $injector.get('$http');
      const authService = $injector.get('authService');
      // should retry
      return authService
        .setAuthenticationConfig(config, false)
        .then((config) => {
          return $http(config);
        })
        .catch((error) => {
          if (
            (error && error.logout) ||
            (!authService.logout.pending &&
              error.response.status === 401 &&
              LOGOUT_AFTER_REFRESH_CODES.includes(error.response.data.code))
          ) {
            popupService.alert(
              '',
              `${$translate.instant('POPUP_USER_DISCONNECTED_SECURITY')}`
            );

            return authService
              .logout({ removeDevice: false })
              .finally(() => $q.reject(error));
          }
          return $q.reject(error);
        });
    }
    // give up
    return $q.reject(error);
  }
}
