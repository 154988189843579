const MESSAGE_DISPLAY_DURATION = 3000;
const NB_PLACES_SYNC_MAX = 300;

export const PlaceSettingsModalComponent = {
  bindings: {
    place: '<',
    onClose: '&',
  },
  templateUrl:
    'places/place/components/place-settings-modal/place-settings-modal.html',
  // eslint-disable-next-line max-params
  controller: function PlaceSettingsModalController(
    localizationService,
    pubSubService,
    $translate,
    $timeout,
    placesService
  ) {
    'ngInject';

    this.$onInit = () => {
      this.isRTLNeeded = localizationService.shouldActivateRTL();
      this.isLoading = true;
      this.settingLoading = false;
      this.networkError = false;
      this.successMessage = null;
      this.errorMessage = null;
      this.NB_PLACES_SYNC_MAX = NB_PLACES_SYNC_MAX;

      return placesService
        .getLocal(this.place._id)
        .then((place) => {
          this.isPlaceSynced = !!place;
          return this.isPlaceSynced;
        })
        .catch(() => {
          this.isPlaceSynced = false;
          return this.isPlaceSynced;
        })
        .then(() => this.initSyncedPlaces())
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.initSyncedPlaces = () =>
      placesService
        .listLocal()
        .then((places) => {
          this.synchronizedPlacesNb = places.length;
          this.maxPlaceNbReached =
            this.synchronizedPlacesNb >= NB_PLACES_SYNC_MAX;

          return places;
        })
        .catch(() => {
          this.networkError = true;
        });

    this.onPlaceSyncedChange = (synced) => {
      this.successMessage = null;
      this.errorMessage = null;
      this.settingLoading = true;
      const promise = synced
        ? () => placesService.saveLocal(this.place._id, this.place)
        : () => placesService.deleteLocal(this.place._id);

      return promise()
        .then(() => {
          $timeout(() => {
            const translationKey = synced
              ? 'PLACE_SETTINGS_UPDATED_SUCCESS'
              : 'PLACE_SETTINGS_REMOVED_SUCCESS';

            this.successMessage = $translate.instant(translationKey);
            this.synchronizedPlacesNb += synced ? 1 : -1;

            this.maxPlaceNbReached =
              this.synchronizedPlacesNb >= NB_PLACES_SYNC_MAX;
          }, 0);

          $timeout(() => {
            this.successMessage = null;
          }, MESSAGE_DISPLAY_DURATION);

          return pubSubService.publish('PLACE_SYNC_UPDATED', this.place._id);
        })
        .catch(() => {
          $timeout(() => {
            this.isPlaceSynced = !this.isPlaceSynced;
            this.errorMessage = $translate.instant(
              'PLACE_SETTINGS_UPDATED_ERROR'
            );
          }, 0);

          return $timeout(() => {
            this.errorMessage = null;
          }, MESSAGE_DISPLAY_DURATION);
        })
        .finally(() => {
          this.settingLoading = false;
        });
    };
  },
};
