import { Chip } from '@simplifield/webcomponents/dist/types/services/chips';
import type { ModalService } from '../../../../services/Utils/Modal';
import { Filter, FilterValue } from '../../types/campaign-completion';

type ExtendedChip = Chip & { id: string };

export class CampaignCompletionSearchFilterController {
  filtersAvailable: Filter[];
  filterValues: FilterValue[];
  sortBy: 'asc' | 'desc';
  onFiltersChange: (filters: { filters: FilterValue[] }) => void;
  onSortByChange: (param: { sortBy: 'asc' | 'desc' }) => void;
  chips: ExtendedChip[];

  /* @ngInject */
  constructor(private modalService: ModalService) {
    this.openSortModal = this.openSortModal.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  $onInit(): void {
    this.chips = this.generateChips();
  }

  $onChanges(changes: ng.IOnChangesObject): void {
    if (changes.filterValues || changes.filtersAvailable) {
      this.chips = this.generateChips();
    }
  }

  generateChips(): ExtendedChip[] {
    return this.filtersAvailable.reduce((acc, filterAvailable) => {
      const filterValue = this.filterValues.find(
        (filter) => filter.id === filterAvailable.id
      );

      if (!filterValue || filterValue.value === filterAvailable.defaultValue) {
        return acc;
      }

      return acc.concat({
        id: filterAvailable.id,
        label:
          typeof filterValue.value === 'boolean'
            ? filterAvailable.label
            : `${filterAvailable.label}: ${filterValue.label}`,
        value: filterValue.value,
      });
    }, [] as ExtendedChip[]);
  }

  resetFilter(value: unknown, index: number): void {
    const id = this.chips[index].id;
    const newFilterValues = [...this.filterValues].filter(
      (value) => value.id !== id
    );

    this.onFiltersChange({
      filters: newFilterValues,
    });
  }

  openSortModal(): void {
    const template = `
      <sf-campaign-completion-filter-modal
        filters-available="$ctrl.filtersAvailable"
        filters="$ctrl.filters"
        sort-by="$ctrl.sortBy"
        on-filter-change="$ctrl.onFilterChange(values)"
        on-sort-by-change="$ctrl.onSortByChange(sortBy)"
        on-close="$ctrl.onClose()">
      </sf-campaign-completion-filter-modal>
    `;
    const bindings = {
      filters: this.filterValues,
      filtersAvailable: this.filtersAvailable,
      sortBy: this.sortBy,
      onFilterChange: (values: FilterValue[]): void => {
        this.onFiltersChange({ filters: values });
      },
      onSortByChange: (sortBy: 'asc' | 'desc'): void => {
        this.onSortByChange({ sortBy });
      },
    };

    const options = {
      animation: 'slide-in-top',
    };

    this.modalService.open(template, bindings, options);
  }
}
