"use strict";

/* eslint-disable max-len */
var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('visit1'),
    name: 'visit',
    description: 'Without description.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      duration: 3,
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      visitor_id: createObjectId('user'),
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit2'),
    name: 'visit2',
    description: 'With description',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      duration: 3,
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      description: 'description',
      allDayLong: false,
      visitor_id: createObjectId('user'),
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit3'),
    name: 'visit3',
    description: 'All day long visit',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: true,
      visitor_id: createObjectId('user'),
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit4'),
    name: 'empty-visit',
    description: 'Empty contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.start_dateTime']
      }]
    },
    partial: {
      valid: true
    },
    contents: {}
  }, {
    _id: createObjectId('visit5'),
    name: 'no-start-date-time',
    description: 'No start date and time',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.start_dateTime']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      duration: 3,
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      visitor_id: createObjectId('user'),
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit6'),
    name: 'no-duration-for-not-all-day-long',
    description: 'No duration for all day long',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.duration']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      visitor_id: createObjectId('user'),
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit6'),
    name: 'with-duration-for-all-day-long',
    description: 'with duration for all day long',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_UNEXPECTED_CONTENT',
        params: ['.duration']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      duration: 7,
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: true,
      visitor_id: createObjectId('user'),
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit8'),
    name: 'no-timezone',
    description: 'No timezone',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.timezone']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      duration: 3,
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      visitor_id: createObjectId('user'),
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit9'),
    name: 'no-timezone-source',
    description: 'No timezoneSource',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.timezoneSource']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      duration: 3,
      timezone: {
        name: 'Europe/Paris'
      },
      allDayLong: false,
      visitor_id: createObjectId('user'),
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit10'),
    name: 'no-all-day-long',
    description: 'No allDayLong',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.allDayLong']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      duration: 3,
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      visitor_id: createObjectId('user'),
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit11'),
    name: 'no-visitor',
    description: 'No visitor',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.visitor_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      duration: 3,
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit12'),
    name: 'no-place',
    description: 'No place',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.place_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      duration: 3,
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      visitor_id: createObjectId('visitor'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('visit13'),
    name: 'no-organisation',
    description: 'No organisation',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.organisation_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      duration: 3,
      timezone: {
        name: 'Europe/Paris'
      },
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      visitor_id: createObjectId('visitor'),
      place_id: createObjectId('place')
    }
  }, {
    _id: createObjectId('visit14'),
    name: 'no-timezone-name',
    description: 'No timezone name',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.name']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
      duration: 3,
      timezone: {},
      timezoneSource: 'inherited_from_place',
      allDayLong: false,
      visitor_id: createObjectId('user'),
      place_id: createObjectId('place'),
      organisation_id: createObjectId('organisation')
    }
  }];
};