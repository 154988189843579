import { Tab } from '@simplifield/webcomponents/dist/types/services/tabs';

const CAMPAIGN_COMPLETION_VIEW_ANALYTICS = [
  'Campaign completion user informations',
  'Campaign completion store informations',
];

type CompletionTab = Tab & { resource: 'users' | 'stores' };

export class CampaignCompletionController implements ng.IComponentController {
  values: CompletionTab[] = [];

  /* @ngInject */
  constructor(
    private $scope: ng.IScope,
    private segmentService,
    private $translate: ng.translate.ITranslateService
  ) {
    this.onTabInfoClick = this.onTabInfoClick.bind(this);
  }

  $onInit(): void {
    this.values = [
      {
        label: this.$translate.instant('CAMPAIGN_COMPLETION_USERS_TAB'),
        active: true,
        resource: 'users',
      },
      {
        label: this.$translate.instant('CAMPAIGN_COMPLETION_STORES_TAB'),
        active: false,
        resource: 'stores',
      },
    ];
  }

  onTabInfoClick(index: number): void {
    this.values = this.values.map((value, i) => ({
      ...value,
      active: i === index,
    }));

    this.segmentService.page(CAMPAIGN_COMPLETION_VIEW_ANALYTICS[index]);

    this.$scope.$apply();
  }
}
