import { StateService, TransitionPromise } from '@uirouter/core';
import type { ObjectId } from '../../..';
import type { APIStore } from '../../../places';
import {
  Campaign,
  PerStoreObjective,
} from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';
import type {
  GeolocationService,
  LatLng,
} from '../../../services/Utils/Geolocation/geolocation.service';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import { CampaignsUtilsService } from '../../services/campaigns-utils.service';
import { LogService } from '../../../services/Utils/log/log.service';

const NB_PLACES_TO_LOAD = 20;

export const ReactiveCampaignStoreSelectorComponent: ng.IComponentOptions = {
  bindings: {
    campaign: '<',
    referer: '<',
    onClose: '&',
  },
  templateUrl:
    'reactive-campaigns/components/reactive-campaign-store-selector/reactive-campaign-store-selector.html',
  controller: class ReactiveCampaignStoreSelectorController
    implements ng.IController
  {
    /** Bindings */
    campaign: Campaign;
    referer?: string;
    onClose: () => void;

    /** Fields */
    campaignPlaceSelectorScroll: { scrollTop: () => void };
    infiniteLoadError: boolean;
    isLoading: boolean;
    isSearchLoading: boolean;
    networkError: boolean;
    objectiveCompletionHash: Record<
      ObjectId,
      {
        percent: number;
        label: {
          title: string;
        };
      }
    >;
    objectiveGoal = 0;
    onScrollComplete: () => void;
    places: APIStore[];
    requestsPaginate: {
      call: (...any) => ng.IPromise<{ entities: APIStore[] }>;
      reset: () => void;
    };
    searchError: boolean;
    searchField = '';
    selectedPlaceId: ObjectId;
    userPosition: LatLng | null;
    isRTLNeeded: boolean;

    constructor(
      private localizationService: LocalizationService,
      private $ionicScrollDelegate,
      private $state: StateService,
      private campaignsService: CampaignsService,
      private campaignsUtilsService: CampaignsUtilsService,
      private geolocationService: GeolocationService,
      private objectIdService,
      private placesSourceAdapterService,
      private RequestsPaginate,
      private logService: LogService
    ) {
      'ngInject';
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    }

    $onInit(): ng.IPromise<
      | void
      | Record<
          string,
          {
            percent: number;
            label: {
              title: string;
            };
          }
        >
      | undefined
    > {
      this.isLoading = true;
      this.networkError = false;

      this.campaignPlaceSelectorScroll = this.$ionicScrollDelegate.$getByHandle(
        'campaignPlaceSelectorScroll'
      );

      this.requestsPaginate = new this.RequestsPaginate(
        this.placesSourceAdapterService.getPlacesOfCampaign.bind(
          this.placesSourceAdapterService,
          this.campaign
        ),
        {
          limit: NB_PLACES_TO_LOAD,
        }
      );
      return this.reload()
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    reload(): ng.IPromise<
      | Record<
          string,
          {
            percent: number;
            label: {
              title: string;
            };
          }
        >
      | undefined
    > {
      this.infiniteLoadError = false;
      this.places = [];
      this.requestsPaginate.reset();

      if (this.campaignPlaceSelectorScroll) {
        this.campaignPlaceSelectorScroll.scrollTop();
      }

      return this.campaignsService
        .getCampaignObjectiveCompletion(this.campaign._id)
        .then((completion) => {
          if (
            completion &&
            this.campaignsUtilsService.isPerStoreObjectiveSimplifiedCompletion(
              completion
            )
          ) {
            this.objectiveGoal = (
              this.campaign.contents.objective as PerStoreObjective
            ).perStore;
          }

          return this.geolocationService.getCurrentPosition();
        })
        .then((position) => {
          this.userPosition = position;
          return this.getPlaces();
        })
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        });
    }

    getPlaces(): ng.IPromise<
      | Record<
          string,
          {
            percent: number;
            label: {
              title: string;
            };
          }
        >
      | undefined
    > {
      this.logService.info(
        `[BUGS-2566] reactive-campaign-store-selector.component.ts | getPlaces`,
        {},
        true
      );
      const nearQueryParam = this.userPosition
        ? { near: `${this.userPosition.lng},${this.userPosition.lat}` }
        : {};
      const filters = {
        isAssigned: { value: true, operation: 'equality' },
        ...(this.searchField
          ? { name: { value: this.searchField, operation: 'regex' } }
          : {}),
        id: {
          value: this.getFilterResolvedIdsByReportedStoreIds(),
          operation: 'equality',
        },
      };

      return this.requestsPaginate
        .call({
          filters,
          sort: 'place_distance',
          ...nearQueryParam,
        })
        .then((places) => {
          this.logService.info(
            `[BUGS-2566] reactive-campaign-store-selector.component.ts | getPlaces: after requestsPaginate`,
            {
              placesCount: places.entities ? places.entities.length : 0,
              placesIds: places.entities
                ? places.entities.map((place) => place._id)
                : [],
            },
            true
          );

          this.places = places.entities;

          if (!this.objectiveGoal) {
            return undefined;
          }

          this.objectiveCompletionHash =
            this.campaignsUtilsService.getObjectiveCompletionPercentByStore(
              this.objectiveGoal,
              this.places,
              this.campaign?.statistics?.data?.reportsByStore ?? {}
            );

          return this.objectiveCompletionHash;
        })
        .finally(() => {
          if (this.onScrollComplete) {
            this.onScrollComplete();
          }
        });
    }

    onSearch(): ng.IPromise<
      | void
      | Record<
          string,
          {
            percent: number;
            label: {
              title: string;
            };
          }
        >
      | undefined
    > {
      this.searchError = false;
      this.isSearchLoading = true;

      return this.reload()
        .catch(() => {
          this.searchError = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    }

    resetInfiniteLoadErrorState(): void {
      this.infiniteLoadError = false;
    }

    goToNextStep(): ng.IPromise<void> | TransitionPromise {
      const stateParams = {
        reportId: this.objectIdService.create(),
        campaignId: this.campaign._id,
        locationId: this.selectedPlaceId,
        ...(this.referer ? { referer: this.referer } : {}),
      };

      const go = this.$state.go(
        'index.menu-more.reactive-campaigns.form',
        stateParams
      );

      go.then(() => {
        this.onClose();
      });

      return go;
    }

    private getFilterResolvedIdsByReportedStoreIds(): string[] {
      if (this.campaign.contents?.objective) {
        const reportsByStore =
          this.campaign.statistics?.[0]?.data?.reportsByStore ?? {};
        const sentReportsIds = new Set(Object.keys(reportsByStore));

        return this.campaign?.resolved?.subject_ids?.filter((id: string) => {
          return !sentReportsIds.has(id);
        });
      }

      return this.campaign?.resolved?.subject_ids;
    }
  },
};
