import { reports as reportsSchema } from '@simplifield/schema';

const REPORT_SCAN_STAT_CIRCLE_SIZE = 220;
const OTHER_TAG = 'OTHER';

export const ReportScanStatisticsComponent = {
  bindings: {
    report: '<',
    form: '<',
    place: '<',
    campaign: '<',
  },
  templateUrl:
    'missions/components/Scans/report-scan-statistics/report-scan-statistics.html',
  controller: class ReportScanStatisticsController {
    // eslint-disable-next-line max-params
    constructor($translate, productsService, placesService, SF_CURRENCIES) {
      'ngInject';
      Object.assign(this, {
        $translate,
        productsService,
        placesService,
        SF_CURRENCIES,
      });
      this.reportsSchema = reportsSchema;
    }

    isGoalBelongsToForm(goal) {
      if (this.form) {
        return this.form._id === goal.form_id;
      }

      return this.campaign._id === goal.campaign_id;
    }

    $onInit() {
      this.isLoading = true;
      this.todaySalesPercent = null;
      this.OTHER_TAG = OTHER_TAG;
      this.REPORT_SCAN_STAT_CIRCLE_SIZE = REPORT_SCAN_STAT_CIRCLE_SIZE;

      return this.getReportSales()
        .then(({ reportSalesByBrands, reportTotalSales }) => {
          this.reportSalesByBrands = reportSalesByBrands.map((saleByBrand) => ({
            ...saleByBrand,
            price: saleByBrand.price.toFixed(2),
          }));
          this.reportTotalSales = reportTotalSales.toFixed(2);

          return this.placesService.getGoals(this.place._id).then((goals) => {
            const formGoal = this.getFormGoal(goals);

            if (!formGoal) {
              return false;
            }

            const goalSales = formGoal.value;
            const todaySales = formGoal.sum;
            const ratio = 100 / goalSales;
            const reportSalesPercent = reportTotalSales * ratio;
            const todaySalesPercent = todaySales * ratio;
            const totalSalesPercent = reportSalesPercent + todaySalesPercent;

            this.totalPercent = totalSalesPercent;
            this.goalSales = goalSales;
            this.todaySales = todaySales + reportTotalSales;

            if (todaySalesPercent >= 100) {
              this.todaySalesPercent = 100;
              this.reportSalesPercent = 0;
            } else if (totalSalesPercent > 100) {
              this.todaySalesPercent = todaySalesPercent;
              this.reportSalesPercent = 100 - todaySalesPercent;
            } else {
              this.todaySalesPercent = todaySalesPercent;
              this.reportSalesPercent = reportSalesPercent;
            }

            this.todaySalesPercent = this.todaySalesPercent.toFixed(2);
            this.totalPercent = this.totalPercent.toFixed(2);
            this.goalsResult = this.computeGoalsResult(goals);
            this.reportSalesLabel = {
              desc: `${Math.trunc(this.totalPercent)} %`,
            };
            this.reportGraphSecondaryLabels = [
              {
                title: this.$translate.instant('REPORT_SCAN_STATS_TODAY_SALES'),
                desc: `${this.todaySales.toFixed(2)} ${this.getCurrency()}`,
              },
              {
                title: this.$translate.instant('REPORT_SCAN_STATS_GOAL_SALES'),
                desc: `${this.goalSales.toFixed(2)} ${this.getCurrency()}`,
              },
            ];

            this.displayedTotalPercent =
              Number(this.todaySalesPercent) + Number(this.reportSalesPercent);

            return formGoal;
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    getFormGoal(goals) {
      return goals.filter(
        (goal) => this.isGoalBelongsToForm(goal) && !goal.productBrand
      )[0];
    }

    computeGoalsResult(goals) {
      let brandGoals = goals
        .filter((goal) => this.isGoalBelongsToForm(goal) && goal.productBrand)
        .map((goal) => {
          let brandSales = this.reportSalesByBrands.filter(
            (sale) => sale.brand === goal.productBrand
          )[0];

          goal.sum +=
            brandSales && brandSales.price ? Number(brandSales.price) : 0;
          goal.sum = goal.sum.toFixed(2);

          return goal;
        });

      return brandGoals;
    }

    getReportSales() {
      const questions = this.form
        ? this.form.contents.questions
        : this.campaign.contents.form.questions;
      const eanAnswers = this.reportsSchema.getEanAnswers(this.report, {
        contents: { questions },
      });
      const uniqueEanValues = eanAnswers
        .map((eanAnswer) => eanAnswer.values[0].value)
        .filter((value, index, arr) => index === arr.indexOf(value));

      return this.productsService
        .getByEans(uniqueEanValues)
        .then((products) => ({
          reportTotalSales: this.reportsSchema.computeTotalForProducts(
            eanAnswers,
            products,
            questions
          ),
          reportSalesByBrands: this.reportsSchema.getSalesResultsByBrand(
            eanAnswers,
            products,
            questions
          ),
        }));
    }

    getCurrency() {
      return this.SF_CURRENCIES.EURO.symbol;
    }
  },
};
