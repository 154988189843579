export const ChatRoomInfoComponent = {
  bindings: {
    profile: '<',
    channel: '<',
    onClose: '&',
    onChannelDeletionStart: '&',
    onChannelDeletionFail: '&',
    onUserLeaveStart: '&',
    onUserLeaveFail: '&',
  },
  templateUrl: 'chat/chat/components/chat-room-info/chat-room-info.html',
  // eslint-disable-next-line max-params
  controller: function ChatRoomInfoController(
    localizationService,
    $state,
    $translate,
    routerService,
    chatService,
    chatApiService,
    dateFormatService,
    modalService,
    chatPopupService,
    accessRightsService
  ) {
    'ngInject';

    let channelChangeListener = null;

    this.$onInit = () => {
      this.isRTLNeeded = localizationService.shouldActivateRTL();
      this.channelName = chatService.getChannelName(this.channel, this.profile);
      this.is1to1Channel = chatService.is1to1Channel(this.channel);
      this.createdTranslationData = {
        date: dateFormatService.getDateFormatted(this.channel.createdAt),
        name: this.channel.inviter.nickname,
      };
      this.canAddMembers = this.canEditMembers();
      this.canDeleteMembers = this.canEditMembers();
      this.canEditName = this.hasChangeRight() && !this.is1to1Channel;
      this.canDeleteChannel = this.hasChangeRight() || this.is1to1Channel;
      this.canLeaveChannel = !this.isInviter() && !this.is1to1Channel;
      this.footerButtonsCount = [
        this.canAddMembers,
        this.canDeleteChannel,
        this.canLeaveChannel,
      ].filter(Boolean).length;

      channelChangeListener = chatService.onChannelChanged((channel) => {
        this.onChannelChange(channel);
      });
    };
    this.$onDestroy = () => {
      channelChangeListener();
      this.closeChildElements();
    };

    this.closeChildElements = () => {
      if (this.chatRoomAddUsersModal) {
        this.chatRoomAddUsersModal.remove();
      }
      if (this.progressPopup) {
        this.progressPopup.clear();
      }
      if (this.promptPopup) {
        this.promptPopup.close();
      }
    };

    this.onChannelChange = (channel) => {
      if (channel.url === this.channel.url) {
        this.channel = channel;
        this.channelName = chatService.getChannelName(
          this.channel,
          this.profile
        );
      }
    };

    this.addUsersToChannel = (usersIds) =>
      chatService.inviteChannelMembers(this.channel, usersIds);

    this.removeMemberFromChannel = (userId) =>
      chatPopupService
        .showRemoveMemberConfirm()
        .then(() =>
          chatApiService.deleteUsersFromGroupChannel(this.channel.url, [userId])
        );

    this.deleteChannel = () =>
      chatPopupService.showDeleteChannelConfirm().then(() => {
        /*
            Set the isChannelDeletedPopupEnable to false in chat-room, to enable or disable
            the channel deletion informative popup.
            The remover don't have to be notified of its own action.
            Need to be done before deleteGroupChannel because onChannelDeleted
            event is triggered before deleteGroupChannel resolution.
          */
        this.onChannelDeletionStart();

        const deleteChatChannel = () => {
          const progressPopup = chatPopupService.showDeleteChannelProgress();

          return chatApiService
            .deleteGroupChannel(this.channel.url)
            .then(() =>
              progressPopup.onSuccess().then(() => {
                this.onClose();
                routerService.disableNextBack();
                $state.go('index.chat.list');
              })
            )
            .catch(() => {
              progressPopup.onError(deleteChatChannel);

              /*
                  Set the isChannelDeletedPopupEnable to true in chat-room chat-room because
                  the remover needs to be notified again if its action fail.
                */
              this.onChannelDeletionFail();
            });
        };

        return deleteChatChannel();
      });

    this.leaveChannel = () =>
      chatPopupService.showLeaveChannelConfirm().then(() => {
        /*
            Set the isUserLeftPopupEnable to false in chat-room, to enable or disable
            the user kicked informative popup.
            The connected user don't have to be notified of its own action.
            Need to be done before leaveChannel because onChannelDeleted
            event is triggered before leaveChannel resolution.
          */
        this.onUserLeaveStart();
        return chatService
          .leaveChannel(this.channel)
          .then(() => {
            this.onClose();
            routerService.disableNextBack();
            $state.go('index.chat.list');
          })
          .catch(() => {
            /*
                Set the isUserLeftPopupEnable to true in chat-room chat-room because
                the user needs to be notified again if its action fail.
              */
            this.onUserLeaveFail();
            chatPopupService.showUserLeaveFailError();
          });
      });

    this.addMembers = () => {
      const modalTemplate = `
        <sf-chat-room-add-participants
          profile="$ctrl.profile"
          channel="$ctrl.channel"
          add-users-to-channel="$ctrl.addUsersToChannel(usersIds)"
          on-close="$ctrl.onClose()">
        </sf-chat-room-add-participants>
      `;

      const modalBindings = {
        profile: this.profile,
        channel: this.channel,
        addUsersToChannel: this.addUsersToChannel,
      };

      this.chatRoomAddUsersModal = modalService.open(
        modalTemplate,
        modalBindings
      );
      return this.chatRoomAddUsersModal;
    };

    this.openChatNameEdition = () => {
      this.promptPopup = chatPopupService.promptChannelName(
        $translate.instant('CHAT_NAME_CONVERSATION_TITLE_EDIT'),
        chatService.CONVERSATION_TITLE_MAX_LENGTH,
        this.channelName
      );

      return this.promptPopup.promptPromise.then(this.updateChannelName);
    };

    this.updateChannelName = (groupName) => {
      this.progressPopup = chatPopupService.showChannelNameEditingProgress();

      return chatService
        .updateChannelName(this.channel, groupName)
        .then((channel) =>
          this.progressPopup.onSuccess().then(() => {
            this.channel = channel;
            this.channelName = chatService.getChannelName(
              this.channel,
              this.profile
            );
          })
        )
        .catch(() => {
          this.progressPopup.onError(() => this.updateChannelName(groupName));
        });
    };

    this.hasChangeRight = () => this.isInviter() || this.isAdmin();
    this.canEditMembers = () => this.hasChangeRight() && !this.is1to1Channel;
    this.isAdmin = () => accessRightsService.isAdmin();
    this.isInviter = () =>
      this.channel.inviter.userId.toString() === this.profile._id.toString();
  },
};
