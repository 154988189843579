import { PlacesService } from '@services/API/places/places.service';
import moment from 'moment';
import { ObjectId, TSFixMe, User } from '../../..';
import {
  FilterCustom,
  FilterSelected,
} from '../../../components/Search/search';
import { APIStore, APIStoreContents } from '../../../places';
import { UsersService } from '../../../services/API/users/users.service';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';

type TaskUser = User & {
  _id?: ObjectId;
  label: string;
};

type TaskPlace = APIStoreContents & {
  _id?: ObjectId;
  label?: string;
};

type ValuesHashConfig = {
  place?: TaskPlace;
  user?: TaskUser;
  due_date?: string;
};

const PLACE_FILTER_TYPE = 'place';
const USER_FILTER_TYPE = 'user';
const DATE_FILTER_TYPE = 'date';
const TIME_FILTER_TYPE = 'time';
const PLACES_LIST_FILTER_TYPE = 'placesList';
const USERS_GROUP_FILTER_TYPE = 'usersGroup';

export class TasksFiltersModalController implements ng.IComponentController {
  // bindings
  profile: User;
  values: (FilterSelected & { value_id?: ObjectId })[];
  filtersAvailable: FilterCustom[];
  onClose: () => void;
  onChange: (arg: { values: FilterSelected[] }) => void;
  // attributes
  isRTLNeeded: boolean;
  valuesHash: ValuesHashConfig = {};
  users: User[] = [];
  placesLists: TSFixMe[] = [];
  usersGroup: TSFixMe[] = [];
  teams: TSFixMe[] = [];

  // constants
  searchUserFields = ['user_firstName', 'user_lastName'];
  searchPlacesListFields = ['name'];
  searchUsersGroupFields = ['name'];
  searchStaticUserFields = ['firstName', 'lastName'];

  constructor(
    private helpersService,
    private localizationService: LocalizationService,
    private profileService,
    private usersService: UsersService,
    private placesService: ReturnType<typeof PlacesService>
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.initValuesHash();
    this.placesService.getAllPlacesLists().then((placesLists: TSFixMe) => {
      this.placesLists = placesLists.entries;
    });
    this.usersService.getScopedUsersGroups().then((usersGroup: TSFixMe) => {
      this.usersGroup = usersGroup.entries;
    });
    this.usersService.crud
      .listLocal()
      .then((users) => {
        const usersSorted = this.helpersService.sortByProperty(
          users,
          'contents.firstName'
        );

        this.users = usersSorted.map((user) => ({
          _id: user._id,
          ...user.contents,
        }));
        return users;
      })
      .catch(() => []);
  }

  initValuesHash(): void {
    this.valuesHash = this.filtersAvailable.reduce((hash, filter) => {
      const filterValue = this.values.find((value) => value.id === filter.id);

      if (!filterValue) {
        hash[filter.id] = null;
        return hash;
      }

      switch (filter.type) {
        case PLACE_FILTER_TYPE:
        case USER_FILTER_TYPE:
        case PLACES_LIST_FILTER_TYPE:
        case USERS_GROUP_FILTER_TYPE:
          hash[filter.id] = { id: filterValue.id, label: filterValue.value };
          break;
        case DATE_FILTER_TYPE:
          hash[filter.id] = this.helpersService.getDueDateTimezonedHtml5(
            this.profile,
            filterValue.value_id
          );
          break;
        case TIME_FILTER_TYPE:
          hash[filter.id] = filterValue.value_id ?? null;
          break;
        default:
          hash[filter.id] = filterValue.value;
          break;
      }

      return hash;
    }, {});
  }

  onDateValueChange(date: string, filter: FilterCustom): FilterSelected[] {
    const checked = this.isDefined(date);
    const dateLabel = date ? this.getDateFormatted(date) : '';
    const newValues = this.updateValues(filter, checked, date, dateLabel);

    return this.setValues(newValues);
  }

  onTimeValueChange(filter: FilterCustom) {
    const time = this.valuesHash[filter.id];

    const checked = this.isDefined(time);
    const newValues = this.updateValues(
      filter,
      checked,
      time,
      moment.utc(time).format('hh:mm A')
    );

    return this.setValues(newValues);
  }

  onEntityValueChange(
    filter: FilterCustom,
    value?: APIStore | TaskUser
  ): FilterSelected[] {
    let valueLabel = '';

    if (value) {
      this.valuesHash[filter.id] = value;
      this.valuesHash[filter.id]._id = value._id;
      valueLabel = value._id ? this.getValueLabel(value, filter.type) : '';
      this.valuesHash[filter.id].label = valueLabel;
    } else {
      Reflect.deleteProperty(this.valuesHash, filter.id);
    }

    const checked = this.isDefined(value);
    const newValues = this.updateValues(
      filter,
      checked,
      value?._id,
      valueLabel
    );
    return this.setValues(newValues);
  }

  isDefined = (data: unknown): boolean =>
    data !== null && typeof data !== 'undefined' && data !== '';

  updateValues(
    filterChecked: FilterCustom,
    checked: boolean,
    newValue?: ObjectId | string,
    newValueLabel?: string
  ): FilterSelected[] {
    const filterValues = this.values.filter(
      (value) => value.id !== filterChecked.id
    );

    if (checked) {
      const filter: FilterSelected = {
        id: filterChecked.id,
        value: (newValueLabel ? newValueLabel : newValue) || null,
        name: filterChecked.label,
        ...(newValueLabel ? { value_id: newValue } : {}),
      };

      return filterValues.concat(filter);
    }

    return filterValues;
  }

  setValues(values: FilterSelected[]): FilterSelected[] {
    this.values = values;

    return values;
  }

  getValueLabel = (
    value: APIStore | TaskUser | string,
    filterType: string
  ): string => {
    switch (filterType) {
      case PLACE_FILTER_TYPE:
        return this.getPlaceName(value as APIStore);
      case USER_FILTER_TYPE:
        return this.getUserName(value as TaskUser);
      case PLACES_LIST_FILTER_TYPE:
        return this.getPlacesListName(value as TSFixMe);
      case USERS_GROUP_FILTER_TYPE:
        return this.getUsersGroupName(value as TSFixMe);
      case DATE_FILTER_TYPE:
        return this.getDateFormatted(value as string);
      default:
        return '';
    }
  };

  getPlaceName(place: APIStore | APIStoreContents): string {
    return (place as APIStore).contents
      ? (place as APIStore).contents.name
      : (place as APIStoreContents).name;
  }

  getPlaceAddress = (place: APIStore): string =>
    place.contents
      ? place.contents.street + ', ' + place.contents.city
      : place.street + ', ' + place.city;

  getUserName = (user: User): string =>
    this.profileService.getNameFromUser(user);

  getPlacesListName = (placesList: TSFixMe): string => placesList.contents.name;

  getUsersGroupName = (usersGroup: TSFixMe): string => usersGroup.contents.name;

  getDateFormatted = (date: string): string => {
    return this.helpersService.getDueDateDisplayValue(this.profile, date);
  };

  onSave(): void {
    this.onChange({ values: this.values });
    this.onClose();
  }
}
