import * as angular from 'angular';
import { ProductItemComponent, ProductListComponent } from './components';
import { ProductDetailsModalComponent } from './modals/product-details/product-details-modal.component';
import { ProductsSelectModalComponent } from './modals/products-select/products-select-modal.component';
import productsRouteConfig from './products.routes';
import { ProductsDashboardComponent } from './views/products-dashboard/products-dashboard.component';

export default angular
  .module('sfMobile.products', ['pascalprecht.translate'])
  .component('sfProductItem', ProductItemComponent)
  .component('sfProductList', ProductListComponent)
  .component('sfProductsDashboard', ProductsDashboardComponent)
  .component('sfProductDetailsModal', ProductDetailsModalComponent)
  .component('sfProductsSelectModal', ProductsSelectModalComponent)
  .config(productsRouteConfig).name;
