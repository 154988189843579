"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/* eslint max-params:0, array-callback-return:0, complexity:0, yoda: 0 */
var YError = require('yerror');

var formulasUtils = {
  buildFormula: formulasUtilsBuildFormula,
  runFormula: formulasUtilsRunFormula,
  computeFormula: formulasUtilsComputeFormula,
  getQuestionIdFromName: formulaUtilsGetQuestionIdFromName,
  COMPARISONS: {
    number: ['eq', 'ne', 'inf', 'infeq', 'sup', 'supeq'],
    date: ['eq', 'ne', 'inf', 'infeq', 'sup', 'supeq'],
    string: ['eq', 'ne', 'contains', 'startsWith', 'endsWith'],
    "boolean": ['eq', 'ne']
  },
  FUNCTIONS: {
    common: {
      cast: formulaCast,
      getValue: formulaGetValue,
      hasValue: formulaHasValue,
      getCustomValue: formulaGetCustomValue,
      hasCustomValue: formulaHasCustomValue,
      getAnswerCount: formulaGetAnswerCount,
      getObjProperty: formulaGetObjProperty,
      eq: formulaEq,
      ne: formulaNe,
      inf: formulaInf,
      infeq: formulaInfeq,
      sup: formulaSup,
      supeq: formulaSupeq,
      contains: formulaContains,
      startsWith: formulaStartsWith,
      endsWith: formulaEndsWith,
      hasAnswerValue: formulaHasAnswerValue,
      hasNotAnswerValue: formulaHasNotAnswerValue
    },
    number: {
      curTime: formulaCurTime
    },
    date: {
      curDate: formulaCurDate
    },
    string: {},
    "boolean": {}
  },
  CONSTANTS: {
    common: {},
    string: {},
    number: {
      E: Math.E,
      LN2: Math.LN2,
      LN10: Math.LN10,
      LOG2E: Math.LOG2E,
      LOG10E: Math.LOG10E,
      PI: Math.PI,
      SQRT1_2: Math.SQRT1_2,
      SQRT2: Math.SQRT2
    },
    date: {},
    "boolean": {}
  }
};
module.exports = formulasUtils;

function formulasUtilsBuildFormula(type, formula) {
  var constNames = Object.keys(formulasUtils.CONSTANTS[type]).concat(Object.keys(formulasUtils.CONSTANTS.common));
  var fnNames = Object.keys(formulasUtils.FUNCTIONS[type]).concat(Object.keys(formulasUtils.FUNCTIONS.common));
  return "".concat(constNames.map(function (constName) {
    return "var ".concat(constName, " = this.").concat(constName, ";");
  }).join('\r\n') + (constNames.length ? '\r\n' : '') + fnNames.map(function (fnName) {
    return "var ".concat(fnName, " = this.").concat(fnName, ".bind(null, this);");
  }).join('\r\n') + (fnNames.length ? '\r\n' : ''), "return (").concat(formula, ");");
}

function formulasUtilsComputeFormula(type, formula, environment, references) {
  return formulasUtilsRunFormula(type, new Function(formulasUtilsBuildFormula(type, formula)), // eslint-disable-line
  environment, references);
}

function formulasUtilsRunFormula(type, formulaFunction, environment, references) {
  var formulaContext = {
    environment: environment,
    references: references
  };
  Object.keys(formulasUtils.CONSTANTS[type]).forEach(function (constName) {
    formulaContext[constName] = 'function' === typeof formulasUtils.CONSTANTS[type][constName] ? formulasUtils.CONSTANTS[type][constName](formulaContext) : formulasUtils.CONSTANTS[type][constName];
  });
  Object.keys(formulasUtils.CONSTANTS.common).forEach(function (constName) {
    formulaContext[constName] = 'function' === typeof formulasUtils.CONSTANTS.common[constName] ? formulasUtils.CONSTANTS.common[constName](formulaContext) : formulasUtils.CONSTANTS.common[constName];
  });
  Object.keys(formulasUtils.FUNCTIONS[type]).map(function (fnName) {
    formulaContext[fnName] = formulasUtils.FUNCTIONS[type][fnName];
  });
  Object.keys(formulasUtils.FUNCTIONS.common).map(function (fnName) {
    formulaContext[fnName] = formulasUtils.FUNCTIONS.common[fnName];
  });
  return formulaFunction.call(formulaContext);
} // Search for question id by name


function formulaUtilsGetQuestionIdFromName(questions, nameOrId) {
  var question = questions.filter(function (item) {
    return item.name && nameOrId === item.name;
  })[0];

  if (question) {
    nameOrId = question._id;
  }

  return nameOrId;
}

function formulaUtilsGetAnswers(formulaContext, questionId) {
  // Search answer per question id
  return formulaContext.references.answers.filter(function (answer) {
    return answer.question_id.toString() === questionId;
  });
}

function formulaUtilsGetAnswerWithValue(formulaContext, questionId, value) {
  var answers = formulaUtilsGetAnswers(formulaContext, questionId);
  return answers.reduce(function (acc, el) {
    return acc.concat(el.values);
  }, []).find(function (answerValue) {
    return answerValue.value.toString() === value.toString();
  });
} // Common


function formulaGetValue(formulaContext, nameOrId, fieldIndex) {
  var questionId = formulaUtilsGetQuestionIdFromName(formulaContext.environment.form.questions, nameOrId);
  var answer = formulaUtilsGetAnswers(formulaContext, questionId)[0];

  if (!answer) {
    return {}.undef;
  }

  return answer.values[fieldIndex || 0].value;
}

function formulaHasValue(formulaContext, nameOrId, fieldIndex) {
  return 'undefined' !== typeof formulaGetValue(formulaContext, nameOrId, fieldIndex);
}

function formulaGetCustomValue(formulaContext, entity, key) {
  var envEntity = formulaContext.references[entity] || {}; // Search answer per question id

  var value = envEntity[key];
  return value ? value : {}.undef;
}

function formulaHasCustomValue(formulaContext, entity, key) {
  return 'undefined' !== typeof formulaGetCustomValue(formulaContext, entity, key);
}

function formulaGetAnswerCount(formulaContext, questionId) {
  var answers = formulaUtilsGetAnswers(formulaContext, questionId);
  return answers.length;
}

function formulaGetObjProperty(formulaContext, obj, path) {
  var valueAccessor = function valueAccessor(prop, item) {
    return item[prop];
  };

  var conditionValueAccessor = function conditionValueAccessor(match, item) {
    if (!match[1] || !match[2] || !match[3] || !Array.isArray(item[match[1]])) {
      return {}.undef;
    }

    return item[match[1]].find(function (el) {
      return 'undefined' !== typeof el[match[2]] && el[match[2]].toString() === match[3];
    });
  };

  obj = formulaContext[obj];

  if ('object' !== _typeof(obj) || null === obj) {
    return {}.undef;
  }

  var pathArr = path.toString().split('.').filter(function (p) {
    return !!p;
  });
  var length = pathArr.length;
  var conditionValueRegexp = /^([a-z0-9_]+)\[(.+)=(.+)\]$/i;
  var index = 0;

  while ('undefined' !== typeof obj && null !== obj && index < length) {
    var key = pathArr[index++];
    var match = key.match(conditionValueRegexp); // allow to use object path like: a.b[c=someValue].d

    obj = null === match ? valueAccessor(key, obj) : conditionValueAccessor(match, obj);
  }

  return index && index === length ? obj : {}.undef;
}

function formulaCast(formulaContext, outType, inType, value) {
  if ('date' !== inType && inType !== _typeof(value)) {
    throw new YError('E_UNEXPECTED_VALUE', 'cast', value);
  }

  if ('number' === outType) {
    if ('date' === inType) {
      return new Date(value).getTime();
    }

    return Number(value);
  }

  if ('string' === outType) {
    if ('date' === inType) {
      return new Date(value).toISOString();
    }

    return String(value);
  }

  if ('boolean' === outType) {
    if ('date' === inType) {
      return 0 !== new Date(value).getTime();
    }

    return Boolean(value);
  }

  if ('date' === outType) {
    if ('boolean' === inType) {
      return new Date(0).toISOString();
    }

    return new Date(value).toISOString();
  }

  return {}.undef;
}

function formulaEq(formulaContext, type, compareValue1, compareValue2) {
  if (compareValue1 === {}.undef || compareValue2 === {}.undef) {
    return false;
  }

  if ('date' === type) {
    return compareValue1.getTime() === compareValue2.getTime();
  }

  if ('string' === type) {
    return compareValue1.toLowerCase() === compareValue2.toLowerCase();
  }

  return compareValue1 === compareValue2;
}

function formulaNe(formulaContext, type, compareValue1, compareValue2) {
  if (compareValue1 === {}.undef || compareValue2 === {}.undef) {
    return true;
  }

  if ('date' === type) {
    return compareValue1.getTime() !== compareValue2.getTime();
  }

  if ('string' === type) {
    return compareValue1.toLowerCase() !== compareValue2.toLowerCase();
  }

  return compareValue1 !== compareValue2;
}

function formulaInf(formulaContext, type, compareValue1, compareValue2) {
  if ('string' === type || 'boolean' === type) {
    throw new YError('E_UNEXPECTED_TYPE', type);
  }

  if ('date' === type) {
    return compareValue1.getTime() < compareValue2.getTime();
  }

  return compareValue1 < compareValue2;
}

function formulaInfeq(formulaContext, type, compareValue1, compareValue2) {
  if ('string' === type || 'boolean' === type) {
    throw new YError('E_UNEXPECTED_TYPE', type);
  }

  if ('date' === type) {
    return compareValue1.getTime() <= compareValue2.getTime();
  }

  return compareValue1 <= compareValue2;
}

function formulaSup(formulaContext, type, compareValue1, compareValue2) {
  if ('string' === type || 'boolean' === type) {
    throw new YError('E_UNEXPECTED_TYPE', type);
  }

  if ('date' === type) {
    return compareValue1.getTime() > compareValue2.getTime();
  }

  return compareValue1 > compareValue2;
}

function formulaSupeq(formulaContext, type, compareValue1, compareValue2) {
  if ('string' === type || 'boolean' === type) {
    throw new YError('E_UNEXPECTED_TYPE', type);
  }

  if ('date' === type) {
    return compareValue1.getTime() >= compareValue2.getTime();
  }

  return compareValue1 >= compareValue2;
} // Date


function formulaCurDate(formulaContext) {
  return new Date(formulaContext.environment.time()).toISOString();
} // Number


function formulaCurTime(formulaContext) {
  return formulaCurDate(formulaContext).getTime();
} // String


function formulaContains(formulaContext, type, str, search) {
  if ('string' !== type) {
    throw new YError('E_UNEXPECTED_TYPE', type);
  }

  if (str === {}.undef || search === {}.undef) {
    return false;
  }

  return str && -1 !== str.toLowerCase().indexOf(search.toLowerCase());
}

function formulaStartsWith(formulaContext, type, str, search) {
  if ('string' !== type) {
    throw new YError('E_UNEXPECTED_TYPE', type);
  }

  if (str === {}.undef || search === {}.undef) {
    return false;
  }

  return str && 0 === str.toLowerCase().indexOf(search.toLowerCase());
}

function formulaEndsWith(formulaContext, type, str, search) {
  if ('string' !== type) {
    throw new YError('E_UNEXPECTED_TYPE', type);
  }

  if (str === {}.undef || search === {}.undef) {
    return false;
  }

  return str.length - search.length === str.toLowerCase().indexOf(search.toLowerCase());
}

function formulaHasAnswerValue(formulaContext, questionId, value) {
  var answer = formulaUtilsGetAnswerWithValue(formulaContext, questionId, value);
  return 'undefined' !== typeof answer;
}

function formulaHasNotAnswerValue(formulaContext, questionId, value) {
  var answer = formulaUtilsGetAnswerWithValue(formulaContext, questionId, value);
  return 'undefined' === typeof answer;
}