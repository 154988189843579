import { TaskDetailsOverviewController } from './task-details-overview.controller';

export const TaskDetailsOverviewComponent: ng.IComponentOptions = {
  bindings: {
    task: '<',
    profile: '<',
    offlineMode: '<',
    onDetailsClose: '<',
    isChecklistTab: '<',
  },
  templateUrl:
    'tasks/components/task-details-overview/task-details-overview.html',
  controller: TaskDetailsOverviewController,
};
