declare let angular: ng.IAngularStatic;

// Services
import { CommentsApiFactory } from './services/comments-api/comments-api.factory';
import { CommentsFactory } from './services/comments/comments.factory';

// Components
import { CommentComponent } from './components/comment/comment.component';

// Filters
import { hasItems } from './filters/has-items.filter';
import { CommentsFileService } from './services/comments-file/comments-file.service';

export default angular
  .module('sfMobile.comments', [
    'pascalprecht.translate',
    'sfMobile.services.pov',
  ])
  .factory('commentsFactory', CommentsFactory)
  .service('commentsApiFactory', CommentsApiFactory)
  .service('commentsFileService', CommentsFileService)
  .filter('hasItems', hasItems)
  .component('sfComment', CommentComponent).name;
