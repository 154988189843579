export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 4.5,
    method: createOrganisationTable,
  });
}

export function createOrganisationTable($q, $log, sqlStorageService) {
  'ngInject';
  // eslint-disable-line max-params
  const FIELD_DEFINITIONS = [
    { key: 'id', type: 'NVARCHAR(32) PRIMARY KEY UNIQUE' },
    { key: 'payload', type: 'TEXT' },
  ];
  const createTableQuery = `CREATE TABLE organisations(${FIELD_DEFINITIONS.map(
    (d) => `${d.key} ${d.type}`
  ).join(',')})`;

  return $q
    .when()
    .then(sqlStorageService.execute(createTableQuery))
    .then(() => $log.debug('[MIGRATION] Success: 4.5'))
    .catch(() => null);
}
