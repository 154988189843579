import { TasksService } from './tasks.service';

export default angular
  .module('sfMobile.services.tasks', [
    'sfMobile.services.translateNumbers',
    'simplifield.feature-flags',
    'sfMobile.constants.feature-flags',
    'sfMobile.services.crud',
    'sfMobile.services.pov',
    'sfMobile.services.profile',
    'sfMobile.services.dates',
    'sfMobile.services.helpers',
    'sfMobile.services.task',
    'sfMobile.services.api-utils',
  ])
  .factory('tasksService', TasksService).name;
