export const EVENT_ITEM_THEMES = {
  CALENDAR: 'CALENDAR',
  NEXT_EVENT: 'NEXT_EVENT',
  LINKED_OPERATIONS: 'LINKED_OPERATIONS',
} as const;

export type EventItemThemeEnum =
  typeof EVENT_ITEM_THEMES[keyof typeof EVENT_ITEM_THEMES];

export default angular
  .module('sfMobile.constants.calendar-events', [])
  .constant('SF_EVENT_ITEM_THEMES', EVENT_ITEM_THEMES).name;
