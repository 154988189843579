import { EntitySelectorModalComponent } from './entity-selector-modal.component';

export default angular
  .module('sfMobile.components.entity-selector-modal', [
    'sfMobile.services.requests-paginate',
    'sfMobile.services.static-requests-paginate',
    'sfMobile.components.button-header-close',
    'sfMobile.services.searchUtils',
  ])
  .component('sfEntitySelectorModal', EntitySelectorModalComponent).name;
