import { FormEndingComponent } from './form-ending.component';

export default angular
  .module('sfMobile.components.form-ending', [
    'pascalprecht.translate',
    'sfMobile.services.report-sending',
    'sfMobile.services.reportScoring',
    'sfMobile.services.awake',
    'sfMobile.services.popup-request',
    'sfMobile.services.form',
    'sfMobile.services.sentry',
    'sfMobile.services.dates',
    'sfMobile.services.reportQuestions',
    'sfMobile.services.reportValidation',
    'sfMobile.components.reportScoring',
    'sfMobile.components.report-scan-statistics',
    'sfMobile.services.tasks',
    'sfMobile.services.reports',
    'sfMobile.components.report-tasks',
    'sfMobile.components.scan-summary',
    'sfMobile.components.catalog-goals',
    'sfMobile.services.platform',
  ])
  .component('sfFormEnding', FormEndingComponent).name;
