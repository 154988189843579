import { CalendarEventListItemController } from './calendar-event-list-item.controller';

export const CalendarEventListItemComponent: ng.IComponentOptions = {
  bindings: {
    event: '<',
    displayTimezone: '<',
    isActive: '<',
    isFullCalendar: '<?',
    displayActions: '<?',
    disableStoreLink: '<?',
    onAccessStoreClick: '&',
    onEdit: '&',
    onDelete: '&',
    onDetails: '&',
    theme: '<',
    displayAsCards: '<?',
  },
  templateUrl:
    'calendar-events/components/calendar/calendar-event-list-item/calendar-event-list-item.html',
  controller: CalendarEventListItemController,
};
