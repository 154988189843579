import { PlaceReportsController } from './place-reports.controller';

export const PlaceReportsComponent: ng.IComponentOptions = {
  bindings: {
    place: '<',
    useNewCampaigns: '<',
  },
  templateUrl: 'places/place/components/place-reports/place-reports.html',
  controller: PlaceReportsController,
};
