import { clone } from 'ramda';
import { DateService } from '../../../../services/Utils/Dates/date.service';
import { Modal, ModalService } from '../../../../services/Utils/Modal';
import {
  CalendarEvent,
  CalendarEventsByDay,
  CorporateEvent,
} from '../../../types';
import { ObjectId } from '../../../..';
import { StateService } from '@uirouter/angularjs';
import { SegmentService } from '../../../../services/Utils/Segment/segment.service';
import { CalendarEventsModalService } from 'app/calendar-events/services/calendar-events-modal/calendar-events-modal.service';
import { SfFeatureFlags } from '@simplifield/feature-flags';
import { FEATURE_FLAGS } from '@constants/feature-flags.constant';

export class CalendarEventsMonthListController
  implements ng.IComponentController
{
  // bindings
  private events: CalendarEventsByDay[];
  onUpdate: (data: { event: CalendarEvent }) => void;
  onDelete: (data: { eventId: ObjectId }) => void;

  // props
  formattedEvents: {
    date: Date;
    isoWeek: number;
    formattedDate: string;
    individualEvents: CalendarEvent[];
  }[];
  activeEventId: string | null = null;
  isFullCalendar: boolean;

  constructor(
    private $state: StateService,
    private $translate: ng.translate.ITranslateService,
    private calendarEventsModalService: CalendarEventsModalService,
    private dateFormatService,
    private dateService: DateService,
    private eventChangeRequestsService,
    private modalService: ModalService,
    private popupRequestService,
    private segmentService: SegmentService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {
    'ngInject';
    this.deleteCalendarEvent = this.deleteCalendarEvent.bind(this);
  }

  $onInit(): void {
    this.isFullCalendar = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.FULL_CALENDAR
    );
  }

  $onChanges(): void {
    this.formattedEvents = this.events.map((event) => {
      const momentDate = this.dateService.toMoment(event.date);
      const { date, individual, corporate } = event;

      return {
        formattedDate:
          this.dateFormatService.getCompleteDateFormatted(momentDate),
        isoWeek: momentDate.isoWeek(),
        individualEvents: individual,
        corporateEvents: corporate,
        date,
      };
    });
  }

  toggle(event: CalendarEvent): void {
    this.activeEventId = this.activeEventId !== event._id ? event._id : null;
  }

  onAccessStore(placeId: ObjectId): void {
    if (!placeId) {
      return;
    }
    this.$state.go('index.places.details.main', {
      placeId: placeId,
    });
    this.segmentService.track('CALENDAR_EVENTS', {
      action: 'tap',
      label: 'Place item',
    });
  }

  detailsEvent(event: CalendarEvent): Modal {
    const template = `
      <sf-details-event-modal
        event="$ctrl.event"
        on-close="$ctrl.onClose()"
        on-edit="$ctrl.onEdit(event)"
        on-delete="$ctrl.onDelete(event)"
        on-request-changes="$ctrl.onRequestChanges()"
        on-request-deletion="$ctrl.onRequestDeletion()">
      </sf-details-event-modal>
    `;

    const detailsModal = this.modalService.open(
      template,
      {
        onEdit: (updatedEvent) =>
          this.editCalendarEvent(updatedEvent).then(() =>
            detailsModal.remove()
          ),
        onDelete: (calendarEvent) =>
          this.deleteCalendarEvent(calendarEvent).then(() =>
            detailsModal.remove()
          ),
        onRequestChanges: () => this.requestChanges(event),
        onRequestDeletion: () => this.requestDeletion(event),
        readonly: true,
        event,
      },
      { hardwareBackButtonClose: true }
    );

    return detailsModal;
  }

  editCalendarEvent(event: CalendarEvent): ng.IPromise<Modal> {
    return this.calendarEventsModalService.editCalendarEvent(event, (data) =>
      this.onUpdate(data)
    );
  }

  deleteCalendarEvent(event: CalendarEvent): ng.IPromise<void> {
    return this.calendarEventsModalService.deleteCalendarEvent(event, (data) =>
      this.onDelete(data)
    );
  }

  requestChanges(event: CalendarEvent): Modal {
    const template = `
      <sf-request-event-changes-modal
        event="$ctrl.event"
        on-close="$ctrl.onClose()"
      ></sf-request-event-changes-modal>
    `;

    return this.modalService.open(
      template,
      { event: clone(event) },
      { hardwareBackButtonClose: false }
    );
  }

  requestDeletion({ _id, contents }) {
    const popupRequest = this.popupRequestService.show({
      progress: {
        title: this.$translate.instant('REQUEST_EVENT_CHANGE_PENDING'),
      },
      error: {
        title: this.$translate.instant('REQUEST_EVENT_CHANGE_ERROR_TITLE'),
        desc: this.$translate.instant('REQUEST_EVENT_CHANGE_ERROR_DESC'),
      },
    });

    return this.eventChangeRequestsService
      .saveNew({
        contents: {
          action: 'remove',
          state: 'pending',
          originalEvent: { _id, contents },
          requestedEvent: {},
          organisation_id: contents.organisation_id,
        },
      })
      .then(() => popupRequest.onSuccess())
      .catch(() =>
        popupRequest.onError(() => this.requestDeletion({ _id, contents }))
      );
  }

  displayCorporateEventDetails(event: CorporateEvent): Modal {
    const eventDetailsTemplate = `
      <sf-corporate-event-details-modal
        event="$ctrl.event"      
        on-close="$ctrl.onClose()"
      </sf-corporate-event-details-modal>
    `;

    const detailsModal = this.modalService.open(eventDetailsTemplate, {
      event,
    });
    return detailsModal;
  }
}
