export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 4.3,
    method: updateMethod,
  });
}

export function updateMethod($q, $log, sqlStorageService) {
  'ngInject';
  const alterTableQuery = 'ALTER TABLE forms ADD COLUMN deleted INTEGER';
  const updateTableQuery = 'UPDATE forms SET deleted=0';

  return sqlStorageService
    .execute(alterTableQuery)
    .then(sqlStorageService.execute(updateTableQuery));
}
