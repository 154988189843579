import { Printer, PrintOptions } from '@awesome-cordova-plugins/printer';

export class PrintService {
  constructor(private $q: ng.IQService, private $log: ng.ILogService) {
    'ngInject';
  }

  isAvailable(): ng.IPromise<boolean> {
    return this.$q.resolve(Printer.isAvailable());
  }

  printHtmlContent(
    htmlContent: string,
    options?: PrintOptions
  ): ng.IPromise<unknown> {
    return this.isAvailable()
      .then(() => {
        return this.$q.resolve(Printer.print(htmlContent, options));
      })
      .catch(() => {
        this.$log.error('Printing is not available');
      });
  }

  printPdfUri(fileUri: string): ng.IPromise<unknown> {
    return this.printPdf(fileUri);
  }

  printPdfBase64(pdfBase64: string): ng.IPromise<unknown> {
    return this.printPdf(pdfBase64);
  }

  private printPdf(data: string): ng.IPromise<unknown> {
    const defer = this.$q.defer();

    this.isAvailable()
      .then(() => {
        Printer.print(data)
          .then(() => {
            defer.resolve();
          })
          .catch((err) => {
            this.$log.error('print plugin error: ', err);
            defer.reject(err);
          });
      })
      .catch(() => {
        this.$log.error('cordova-plugin-print is not available');
        defer.reject();
      });

    return defer.promise;
  }
}
