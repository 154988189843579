import { OrganisationsService } from './organisations.service';

export default angular
  .module('sfMobile.services.organisations', [
    'sfMobile.services.crud',
    'sfMobile.services.pov',
    'sfMobile.services.profile',
    'simplifield.feature-flags',
  ])
  .factory('organisationsService', OrganisationsService).name;
