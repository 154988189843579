import { StateService } from '@uirouter/angularjs';
import moment from 'moment';
import { ChecklistLink } from '../../../calendar-events/types';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { CampaignsUtilsService } from '../../../reactive-campaigns/services/campaigns-utils.service';
import {
  Campaign,
  SimplifiedCampaignObjectiveCompletion,
} from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';
import { DateService } from '../../../services/Utils/Dates/date.service';
import { ImageService } from '../../../services/Utils/Image/image.service';
import { PopupService } from '../../../services/Utils/Popup/popup.service';

type ObjectiveData = {
  colorClass?: string;
  label?: { title: string };
  labelColorClass?: string;
  nb?: number;
  percent?: number;
  total?: number;
};

// sf-checklist
export const ChecklistComponent = {
  bindings: {
    params: '<',
    onClose: '&',
  },
  templateUrl: 'components/Checklist/checklsit/checklist.html',
  controller: class ImageFallbackController implements ng.IComponentController {
    // bindings
    params: ChecklistLink = {} as ChecklistLink;
    onClose: () => void;

    checklist: Campaign;
    imageUrl: string;
    objectiveData: ObjectiveData = {};

    constructor(
      private dateService: DateService,
      private imageService: ImageService,
      private popupService: PopupService,
      private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
      private $state: StateService,
      private $translate: ng.translate.ITranslateService,
      private campaignsService: CampaignsService,
      private campaignsUtilsService: CampaignsUtilsService
    ) {
      'ngInject';
    }

    $onInit(): void {
      if (this.params.picture_id) {
        this.getPictureUrl(this.params.picture_id).then((url) => {
          this.imageUrl = url;
        });
      }

      this.campaignsService.getOne(this.params._id).then((data) => {
        this.checklist = data;
      });

      this.getObjectiveData();
    }

    get dateLabel(): string {
      const { activation_date, deactivation_date } = this.params;

      if (this.isNotBegin) {
        const today = new Date();
        const daysBefore = this.dateService.getTotalDaysBetweenTwoDates(
          today,
          activation_date
        );

        return this.$translate.instant('EVENT_CHECKLIST_START', {
          count: daysBefore,
        });
      }

      if (deactivation_date) {
        const endDate = moment(deactivation_date).format('DD/MM');

        return this.$translate.instant('EVENT_CHECKLIST_ENDS', {
          date: endDate,
        });
      }

      return '';
    }

    get isNotBegin(): boolean {
      return this.dateService.isGreaterThan(
        this.params.activation_date,
        new Date()
      );
    }

    private setObjectiveDesign(percent: number): void {
      if (percent === 100) {
        this.objectiveData.colorClass =
          'sf_progressCircle__percentCircle--sfGreen';
        this.objectiveData.labelColorClass =
          'sf_progressCircle__label--sfGreen';
      } else if (percent === 0) {
        this.objectiveData.colorClass =
          'sf_progressCircle__percentCircle--sfGrey';
        this.objectiveData.labelColorClass =
          'sf_progressCircle__percentCircle--sfGrey';
        this.objectiveData.percent = 100;
      } else {
        this.objectiveData.colorClass = 'sf_progressCircle__label--sfRed';
      }
    }

    getObjectiveData(): ng.IPromise<void> {
      return this.campaignsService
        .getCampaignObjectiveCompletion(this.params._id)
        .then((completion?: SimplifiedCampaignObjectiveCompletion) => {
          if (completion) {
            const { perStore, perUser } = completion;

            if (perStore?.storesIncluded) {
              this.objectiveData = {
                nb: perStore.storesCovered,
                total: perStore.storesIncluded,
              };
            }

            if (perUser?.reportsExpected) {
              this.objectiveData = {
                nb: perUser.reportsReceived,
                total: perUser.reportsExpected,
              };
            }

            this.objectiveData.percent =
              this.campaignsUtilsService.getObjectiveCompletionPercent(
                completion
              );

            this.objectiveData.label = {
              title: '<i class="fa-light fa-check"></i>',
            };

            this.setObjectiveDesign(this.objectiveData.percent);
          }
        });
    }

    getPictureUrl(picture_id): ng.IPromise<string> {
      const { SQUARE_MEDIUM } = this.SF_IMAGE_SIZES;

      return this.imageService
        .getUrl(picture_id)
        .then((url) => this.imageService.getSizedUrl(url, SQUARE_MEDIUM));
    }

    openProhibitedAlert(): void {
      this.popupService.alert(
        '',
        this.$translate.instant('EVENT_CHECKLIST_ALERT_MESSAGE')
      );
    }

    redirectToChecklist(campaignId: string): void {
      const hasAccess = Boolean(this.checklist);

      if (hasAccess) {
        this.onClose();
        this.$state.go('index.menu-more.reactive-campaigns.details', {
          campaignId,
        });
      } else {
        this.openProhibitedAlert();
      }
    }
  },
};
