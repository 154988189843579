import { APIStore } from '../../../';

export class PlaceGalleryController implements ng.IController {
  // bindings
  place: APIStore;

  // component members
  galleryListFilter: { name: string; value: string }[];

  constructor() {
    'ngInject';
  }

  $onInit(): void {
    this.galleryListFilter = [
      {
        name: 'pos_name',
        value: this.place.contents.name,
      },
    ];
  }
}
