import { ModalStepperController } from './modal-stepper.controller';

export const ModalStepperComponent = {
  bindings: {
    steps: '<',
    onStepperClose: '&',
  },
  templateUrl: 'components/Modals/modal-stepper/modal-stepper.html',
  controller: ModalStepperController,
};
