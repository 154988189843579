import { Box } from './typings/box';

export type BoxDownloadCallback = (
  url: string,
  filename: string,
  extension: string
) => ng.IPromise<void>;

export function addBoxDownloadCallback(
  box: Box,
  getDownloadCallback: () => BoxDownloadCallback | undefined
) {
  const boxDownload = box.Preview.prototype.download;
  box.Preview.prototype.download = function () {
    const downloadCallback = getDownloadCallback();

    if (downloadCallback) {
      this.api.reachability.downloadWithReachabilityCheck = (url) => {
        return downloadCallback(url, this.file.name, this.file.extension);
      };
    } else {
      delete this.api.reachability.downloadWithReachabilityCheck;
    }
    boxDownload.bind(this)();
  };
}
