// eslint-disable-next-line max-params
export function CampaignSelectorService(
  $state,
  $q,
  reportsService,
  formsService,
  helpersService,
  modalService,
  objectIdService
) {
  'ngInject';

  function CampaignSelector(campaign, profile) {
    const proto = {
      startPonctualMission,
      startPermanentMission,
      openMissionsModal,
      openPlacesModal,
      startMission,
      redirectToForm,
      campaign,
      profile,
      filterPromise: null,
    };

    const definition = Object.create(proto);

    return definition;
  }

  /**
   * Ponctual mission workflow
   *
   * @param {String} [placeId] - The place Id to start on
   * @return {Promise}  Promise of the view opening
   * @this campaignSelector
   */
  function startPonctualMission(placeId) {
    const _this = this;

    return placeId
      ? _this.startMission(placeId) // Start on the PoS
      : _this.campaign.contents.needPlace
      ? _this.openMissionsModal() // Chose a mission to start on
      : _this.startMission(); // Start a free mission without PoS
  }
  /**
   * Permanent mission workflow
   *
   * @param {String} [placeId] - The place Id  to start on
   * @return {Promise}  Promise of the view opening
   * @this campaignSelector
   */
  function startPermanentMission(placeId) {
    const _this = this;

    return placeId
      ? _this.redirectToForm({ locationId: placeId }) // Free report on the PoS
      : _this.campaign.contents.needPlace
      ? _this.openPlacesModal() // Chose a place to start on
      : _this.redirectToForm(); // Start a free report without PoS
  }
  /**
   * Start the mission existing in the database
   * The mission can not exist in the reports (as a draft or finish state)
   * If a place Id parameter exist, a mission is started on a mission related
   * to the PoS
   *
   * @param {String} [placeId] - The place Id  to start on
   * @return {Promise}  Promise of the view opening
   * @this campaignSelector
   */
  function startMission(placeId) {
    const _this = this;
    const promises = [
      getReports(_this.campaign._id, placeId),
      getMissions(_this.campaign._id, placeId),
    ];

    return $q
      .all(promises)
      .then((data) => ({
        reports: !placeId ? getFreePlaceEntities(data[0]) : data[0],
        missions: !placeId ? getFreePlaceEntities(data[1]) : data[1],
      }))
      .then((data) => {
        const reports = data.reports;
        const missions = data.missions;
        const missionsNotStarted = helpersService.getMissionsNotStarted(
          missions,
          reports
        );
        const missionAvailable = missionsNotStarted[0];
        const params = missionAvailable
          ? { missionId: missionAvailable._id }
          : {};

        return _this.redirectToForm(params);
      });
  }

  /**
   * Open the missions modal
   * @return {Promise} Promise of opening view
   * @this campaignSelector
   */
  function openMissionsModal() {
    const promise = composeMissionsData(this.campaign);

    return promise.then(openSelectorModal.bind(this));
  }
  /**
   * Open the places modal
   * @return {Promise} Promise of opening view
   * @this campaignSelector
   */
  function openPlacesModal() {
    const promise = composePlacesData(this.campaign);

    return promise.then(openSelectorModal.bind(this));
  }
  /**
   * Open the selector modal with datas binded
   *
   * @param {Object} data - Datas to display
   * @return {Promise} Promise of opening view
   * @this campaignSelector
   */
  function openSelectorModal(data) {
    const _this = this;
    const template = `
      <sf-campaign-selector
        reports="$ctrl.reports"
        campaign="$ctrl.campaign"
        drafts="$ctrl.drafts"
        todos="$ctrl.todos"
        on-close="$ctrl.onClose()"
        on-click="$ctrl.onItemClick(params)">
      </sf-campaign-selector>
    `;
    const bindings = {
      campaign: data.campaign,
      reports: data.reports,
      drafts: data.drafts,
      todos: data.missions,
      onItemClick,
    };
    const modal = modalService.open(template, bindings);

    function onItemClick(params) {
      return _this.redirectToForm(params).finally(() => modal.remove());
    }

    return modal;
  }

  /**
   * Get the drafts started on a given campaign
   *
   * @param  {String}  campaign   - Campaign datas
   * @return {Array} - Datas received
   */
  function composePlacesData(campaign) {
    return getReports(campaign._id, null, 'draft').then((data) => ({
      drafts: data.filter(isPermanentMission).filter(isMissionWithPlace),
      campaign,
    }));
  }
  /**
   * Get the missions available to do, drafts started and report
   * created on a PoS for a given campaign
   *
   * @param  {String}  campaign   - Campaign datas
   * @return {Array} - Datas received
   */
  function composeMissionsData(campaign) {
    const promises = [getReports(campaign._id), getMissions(campaign._id)];

    return $q
      .all(promises)
      .then((data) => ({
        reports: data[0]
          .filter((report) => report.contents.type === 'mission')
          .filter(isMissionWithPlace),
        missions: data[1].filter(isMissionWithPlace),
      }))
      .then(filterItemsToDisplay)
      .then((data) => ({
        ...data,
        campaign,
      }));

    function filterItemsToDisplay(data) {
      const reports = data.reports;
      const missions = data.missions;
      const reportsDraft = reports.filter(
        (report) => report.localStatus === 'draft'
      );
      const missionsNotStarted = helpersService.getMissionsNotStarted(
        missions,
        reports
      );
      const missionsPlaceId = missionsNotStarted.map(
        (mission) => mission.contents.place_id
      );
      const uniquePlaceMissions = missionsNotStarted.filter(
        (mission, index) =>
          index === missionsPlaceId.indexOf(mission.contents.place_id)
      );

      return {
        reports,
        drafts: reportsDraft,
        missions: uniquePlaceMissions,
      };
    }
  }

  /**
   * Go to form and close modal if opened.
   *
   * @param  {Object} params - List of params to add to request
   * @return {Boolean}       - If succeed
   * @this campaignSelector
   */
  function redirectToForm(params = {}, isPreview) {
    const state = isPreview
      ? 'preview.mission.form'
      : 'index.menu-more.missions.campaign.form';
    const stateParams = {
      ...params,
      campaignId: this.campaign._id,
      reportId: params.reportId || params.missionId || objectIdService.create(),
    };

    return $state.go(state, stateParams);
  }

  // ---------------
  //
  //    REQUEST
  //
  // ---------------
  function getReports(formId, placeId, localStatus) {
    var params = {
      localStatus: localStatus || ['draft', 'ready'],
      form_id: formId,
    };

    if (placeId) {
      params.place_id = placeId;
    }
    return reportsService.crud.queryLocal(params);
  }
  function getMissions(formId, placeId) {
    var params = { form_id: formId };

    if (placeId) {
      params.place_id = placeId;
    }
    return formsService.listMissions(params, {
      type: 'mission',
      states: 'empty',
    });
  }

  // ---------------
  //
  //    HELPERS
  //
  // ---------------
  function getFreePlaceEntities(entities) {
    return entities.filter((entity) => !getContentsPlaceId(entity));
  }
  function getContentsPlaceId(data) {
    return data.contents.place_id;
  }
  function isMissionWithPlace(data) {
    return !!getContentsPlaceId(data);
  }
  function isPermanentMission(report) {
    return report.contents.type === 'share';
  }

  return CampaignSelector;
}
