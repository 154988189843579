export const PopupTemplateComponent = {
  bindings: {
    title: '<',
    iconName: '<',
    onClose: '&',
    showCloseButton: '<',
  },
  transclude: true,
  templateUrl: 'components/Popup/popup-template/popup-template.html',
  controller: class PopupTemplateComponent {
    iconName: string;
    src: string;
    showCloseButton = false;

    onClose: () => void;

    $onInit() {
      this.src = `img/icons/${this.iconName}.svg`;
    }

    close() {
      this.onClose();
    }
  },
};
