'use strict';

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Comment',
  type: 'object',
  additionalProperties: false,
  required: ['content', 'organisation_id', 'author_id'],
  properties: {
    subject: {
      type: 'string'
    },
    content: {
      type: 'string'
    },
    organisation_id: schemaUtils.oneToOne(),
    author_id: schemaUtils.oneToOne(),
    form_id: schemaUtils.oneToOne(),
    picture_id: schemaUtils.oneToOne(),
    report_id: schemaUtils.oneToOne(),
    validation: {
      title: 'Validation',
      type: 'object',
      additionalProperties: false,
      required: ['_id'],
      properties: {
        _id: schemaUtils.oneToOne(),
        stateSetter: {
          type: 'string',
          "enum": ['pending', 'rejected', 'validated']
        },
        changedFiles_ids: schemaUtils.oneToSeveral()
      }
    }
  }
};