import { APIPlacesList } from '@places/index';
import { PlacesService } from '@services/API/places/places.service';
import { UsersGroup } from '@services/API/users/users';
import { UsersService } from '@services/API/users/users.service';
import { EntityType, IAPIList, IAPIResource, UserRef } from 'app';
import { ApiGroupTaskMeta } from 'app/group-tasks/types';

interface GroupItem {
  groupName: string;
  entities: string[];
}

export class GroupEntityListModalController implements ng.IComponentController {
  // bindings
  groupsIds: string[];
  entityType: EntityType;
  entities: Record<string, unknown>; // Users or Places
  onClose: () => void;

  groups: GroupItem[] = [];
  title = 'TASK_GROUP_DETAIL_MODAL_TITLE_STORE_GROUPS';

  constructor(
    private placesService: ReturnType<typeof PlacesService>,
    private usersService: UsersService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.getEntityGroupProvider(this.entityType, this.groupsIds).then(
      (groups) => {
        this.groups = groups.entries.map(({ _id, contents }) => ({
          id: _id,
          groupName: contents.name,
          entities: this.getEntityNames(
            this.entityType,
            this.entities,
            contents
          ),
        }));
      }
    );
  }

  getEntityNames(
    entityType: EntityType,
    entities: Record<string, unknown>,
    contents: any
  ): string[] {
    return contents[`${entityType}_ids`].map((entityId) => {
      const entity = entities[entityId];
      if (!entity) {
        console.error(`Missing ${entityType} for group id: ${entityId}`);
        return;
      }
      return entityType === 'places'
        ? (entity as ApiGroupTaskMeta['places']).name
        : `${(entity as UserRef).firstName} ${(entity as UserRef).lastName}`;
    });
  }

  getEntityGroupProvider(
    entityType: EntityType,
    groupsIds: string[]
  ): ng.IPromise<APIPlacesList | IAPIList<IAPIResource<UsersGroup>>> {
    const params = {
      filters: {
        [entityType === 'places' ? 'pos_list_id' : 'user_group_id']: {
          $in: groupsIds,
        },
      },
      mode: null,
    };
    return entityType === 'places'
      ? this.placesService.getAllPlacesLists({ ...params })
      : this.usersService.getAllUsersGroups({ ...params });
  }
}
