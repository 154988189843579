const TASK_PRIORITIES = {
  0: {
    level: 'low',
    label: 'TASKS_PRIORITY_LOW',
    className: 'chip-light',
  },
  10: {
    level: 'high',
    label: 'TASKS_PRIORITY_MEDIUM',
    className: 'chip-energized',
  },
  20: {
    level: 'urgent',
    label: 'TASKS_PRIORITY_HIGH',
    className: 'chip-assertive',
  },
};

export const TaskPriorityLabelComponent = {
  bindings: {
    task: '<',
  },
  templateUrl: 'tasks/components/task-priority-label/task-priority-label.html',
  controller: function TaskPriorityLabelController() {
    'ngInject';
    this.$onInit = () => {
      this.priority = TASK_PRIORITIES[this.task.contents.priority];
    };

    this.$onChanges = () => {
      this.priority = TASK_PRIORITIES[this.task.contents.priority];
    };
  },
};
