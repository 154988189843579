import { GroupTaskTemplateController } from './group-task-template.controller';

export const GroupTaskTemplateComponent: ng.IComponentOptions = {
  bindings: {
    onClose: '&',
    onSave: '&',
    profile: '<',
  },
  templateUrl: 'group-tasks/group-task-template/group-task-template.html',
  controller: GroupTaskTemplateController,
};
