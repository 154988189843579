import { ContentController } from '../../content.controller';

export const ContentHeaderComponent = {
  bindings: {
    hasOverlay: '<?',
    hasGradient: '<?',
  },
  require: {
    contentController: '?^sfContent',
  },
  transclude: true,
  templateUrl:
    'components/Layout/content/components/content-header/content-header.html',
  controller: class ContentHeaderController {
    // bindings & require
    hasOverlay = true;
    hasGradient: boolean;
    contentController: ContentController;

    // members
    hasImage: boolean;

    constructor(private $element: ng.IRootElementService) {
      'ngInject';
    }

    $postLink(): void {
      if (!this.contentController) {
        return;
      }
      this.contentController.registerHeader(
        this.$element[0].children[0] as HTMLElement
      );
      this.hasImage = Boolean(this.contentController.parallaxImage);
    }
  },
};
