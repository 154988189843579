const STATE_NAME = 'index.menu-more.merchandising.tabs';

export const MerchandisingTabsComponent = {
  bindings: {
    profile: '<',
  },
  templateUrl: 'merchandising/views/merchandising-tabs/merchandising-tabs.html',
  // eslint-disable-next-line max-params
  controller: function MerchandisingTabsController(
    $q,
    $translate,
    $timeout,
    tabsService,
    contentService,
    pubSubService,
    merchandisingApiService,
    appMessageService,
    SF_MERCHANDISING_STATES
  ) {
    'ngInject';
    const stateChangeListener = pubSubService.subscribe(
      pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
      (params) => {
        if (STATE_NAME === params.name) {
          this.reload();
        }
      }
    );

    this.$onInit = () => {
      this.validatedCount = 0;
      this.rejectedCount = 0;
      this.pendingCount = 0;

      return this.reloadStateCounts();
    };

    this.$onDestroy = () => {
      stateChangeListener();
    };

    this.reload = () =>
      this.reloadSelectedTab().then(() => this.reloadStateCounts());

    this.reloadSelectedTab = () => {
      const STATE_RELOAD_FUNCTIONS_ARRAY = [
        this.reloadPendingList,
        this.reloadRejectedList,
        this.reloadValidatedList,
      ];

      return $timeout(() => {
        const selectedTabIndex =
          tabsService.getSelectedIndex('merchandisingTabs');

        return STATE_RELOAD_FUNCTIONS_ARRAY[selectedTabIndex]
          ? STATE_RELOAD_FUNCTIONS_ARRAY[selectedTabIndex]()
          : $q.when();
      }, 0);
    };

    this.reloadStateCounts = () => {
      return merchandisingApiService
        .getStatesCounts()
        .then((counts) => {
          this.validatedCount = getCountByState(
            SF_MERCHANDISING_STATES.VALIDATED,
            counts
          );
          this.rejectedCount = getCountByState(
            SF_MERCHANDISING_STATES.REJECTED,
            counts
          );
          this.pendingCount = getCountByState(
            SF_MERCHANDISING_STATES.PENDING,
            counts
          );

          return counts;
        })
        .catch(() => {
          this.validatedCount = 0;
          this.rejectedCount = 0;
          this.pendingCount = 0;

          return false;
        });

      function getCountByState(state, counts) {
        const stateCounts = counts.filter((count) => count.state === state);

        return stateCounts.length ? stateCounts[0].total : 0;
      }
    };

    this.onSaveSuccess = () => {
      appMessageService.display(
        $translate.instant('MERCHANDISING_STATE_CHANGE_SUCCESS'),
        'success'
      );

      return this.reload();
    };

    this.onSaveFail = () => {
      appMessageService.display(
        $translate.instant('MERCHANDISING_STATE_CHANGE_FAIL'),
        'fail'
      );
    };
  },
};
