"use strict";

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Visits',
  type: 'object',
  additionalProperties: false,
  required: ['start_dateTime', 'timezone', 'timezoneSource', 'allDayLong', 'visitor_id', 'place_id', 'organisation_id'],
  properties: {
    start_dateTime: {
      type: 'string',
      format: 'date-time'
    },
    duration: {
      type: 'number'
    },
    timezone: schemaUtils.timezone(),
    timezoneSource: {
      type: 'string',
      "enum": ['manually_set', 'inherited_from_place'],
      "default": 'inherited_from_place'
    },
    allDayLong: {
      type: 'boolean',
      "default": false
    },
    description: {
      type: 'string'
    },
    visitor_id: schemaUtils.oneToOne(),
    place_id: schemaUtils.oneToOne(),
    organisation_id: schemaUtils.oneToOne()
  }
};