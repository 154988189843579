export function PlacesListsService(crudFactory) {
  'ngInject';

  const methods = crudFactory('places_lists', {
    path_name: 'placesLists',
    default_params: { mode: 'compact' },
    take_care_of_user_profile: true,
  });

  return methods;
}
