export const ReportScoringComponent = {
  bindings: {
    form: '<',
    report: '<',
    isGraphView: '<',
  },
  templateUrl: 'missions/components/Scoring/report-scoring/report-scoring.html',
  controller: function ReportScoringController(
    $translate,
    formsService,
    reportScoringService
  ) {
    'ngInject';

    const getPercent = (score) =>
      score ? `${Math.round(score.percent * 100)}` : null;

    this.$onInit = () => {
      const formStatsPromise = formsService.getReportsStatistics(this.form._id);
      const answers = this.report.contents.answers;

      this.isGraphView = false;
      this.sections = this.form.contents.sections.filter((section) =>
        formsService.displayQuestion(section, this.form, answers)
      );
      this.sectionsScore = this.sections
        .map((section) =>
          reportScoringService.computeScoreForSection(
            section._id,
            this.form,
            this.report
          )
        )
        .filter((sectionScore) => sectionScore);

      // Graph view
      this.graphSections = this.sections.filter(
        (section) => section.level === 0
      );
      this.graphSectionsScores = this.graphSections
        .map((section) => ({
          title: section.title,
          data: reportScoringService.computeScoreForSection(
            section._id,
            this.form,
            this.report
          ),
        }))
        .filter((sectionScore) => sectionScore.data);

      // List view
      this.wholeScore = reportScoringService.computeScore(
        this.form,
        this.report
      );

      // Circle view
      this.wholeScorePercent = getPercent(this.wholeScore);
      this.wholeScoreLabel = {
        title: $translate.instant('REPORT_SCORING_TOTAL_SCORE'),
        desc: `${this.wholeScorePercent}%`,
      };

      return formStatsPromise.then((formStats) => {
        this.formStatsLabel = formStats.score.whole
          ? [].concat({
              title: $translate.instant('REPORT_SCORING_FORM_PREV'),
              desc: `${getPercent(formStats.score.whole)}%`,
            })
          : [];
      });
    };
  },
};
