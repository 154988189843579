const CHAT_ITEM_MESSAGE_NG_CHAR_MAX = 60;

export const ChatItemComponent = {
  bindings: {
    channel: '<',
    profile: '<',
  },
  templateUrl: 'chat/components/chat-item/chat-item.html',
  controller: function ChatItemController(
    chatService,
    dateFormatService,
    dateService
  ) {
    'ngInject';
    const getMetadataFirstName = (user) =>
      user.metaData && user.metaData.firstName;
    const getSenderFirstname = (sender) =>
      sender && getMetadataFirstName(sender);
    const getSenderFirstnameFromMembers = (sender, members) => {
      const curMember = members.filter(
        (member) => sender.userId === member.userId
      )[0];

      return curMember ? getMetadataFirstName(curMember) : null;
    };

    this.$onInit = () => {
      this.is1to1channel = chatService.is1to1Channel(this.channel);
      this.channelName = chatService.getChannelName(this.channel, this.profile);
      this.lastMessage = this.getLastMessage(this.channel);
    };

    this.$onChanges = () => {
      this.channelName = chatService.getChannelName(this.channel, this.profile);
    };

    this.getChannelDate = (lastMessage) =>
      lastMessage ? this.displayTimeOrDate(lastMessage.createdAt) : null;

    this.getNbUnreadCount = () => {
      const unreadMessageCount = this.channel.unreadMessageCount;

      return chatService.getNbUnreadCount(unreadMessageCount);
    };
    this.isMessageFile = (channel) => {
      const lastMessage = this.getLastMessage(channel);

      return chatService.isMessageFile(lastMessage);
    };

    this.getLastMessage = (channel) => channel.lastMessage;
    this.getLastMessageSenderName = (channel) => {
      const lastMessage = this.getLastMessage(channel);
      const sender = lastMessage && lastMessage._sender;

      return sender
        ? getSenderFirstname(sender) ||
            getSenderFirstnameFromMembers(sender, channel.members)
        : null;
    };

    this.getTextTruncated = (message) =>
      CHAT_ITEM_MESSAGE_NG_CHAR_MAX < message.length
        ? message.slice(0, CHAT_ITEM_MESSAGE_NG_CHAR_MAX) + '...'
        : message;

    this.displayTimeOrDate = (date) =>
      dateService.isToday(date)
        ? dateFormatService.getTimeFormatted(date)
        : dateFormatService.getDateFormatted(date);
  },
};
