import { SynchronizeService } from './synchronize.service';

export default angular
  .module('sfMobile.services.synchronize', [
    'pascalprecht.translate',
    'sfMobile.services.campaigns',
    'sfMobile.services.missions',
    'sfMobile.services.forms',
    'sfMobile.services.places',
    'sfMobile.services.places-lists',
    'sfMobile.services.products',
    'sfMobile.services.awake',
    'sfMobile.services.app-message',
    'sfMobile.services.error-messages',
    'sfMobile.services.organisations',
    'sfMobile.services.segment',
    'sfMobile.services.calendar-events',
    'sfMobile.services.api-utils',
    'sfMobile.services.merchandising-api',
    'sfMobile.change-requests',
    'sfMobile.newsfeed',
    'sfMobile.services.places-params',
    'sfMobile.services.calendar-events-params',
    'sfMobile.services.geolocation',
    'sfMobile.services.pubsub',
    'sfMobile.services.upgrade',
    'sfMobile.services.draft-reports',
    'sfMobile.services.log',
  ])
  .factory('synchronizeService', SynchronizeService).name;
