import { Form } from '../../../missions/form/types';

export const MissionItemComponent: ng.IComponentOptions = {
  bindings: {
    form: '<',
    titleCharactersMax: '<',
    nbMissions: '<',
  },
  transclude: true,
  templateUrl: 'missions/components/mission-item/mission-item.html',
  controller: class MissionItemController implements ng.IController {
    //bindings
    titleCharactersMax: number;
    form: Form;
    // members
    hideImage: boolean;
    imageUrl: string | null;
    title: string;

    constructor(private formsService) {
      'ngInject';
    }
    $onInit(): ng.IPromise<void> | null {
      return this.initForm();
    }

    $onChanges(changes: { form?: Form }): ng.IPromise<void> | null {
      if (!changes.form || !this.form) {
        return null;
      }
      return this.initForm();
    }

    initForm(): ng.IPromise<void> | null {
      const formTitle = this.form.contents.title;

      this.imageUrl = null;
      this.title =
        this.titleCharactersMax && this.titleCharactersMax < formTitle.length
          ? formTitle.slice(0, this.titleCharactersMax) + '...'
          : formTitle;

      if (!this.hideImage) {
        return this.formsService
          .getFormImage(this.form, 'medium')
          .then((imageUrl) => {
            this.imageUrl = imageUrl;
          });
      }

      return null;
    }
  },
};
