import { Filter } from '../../types/campaign-completion';

export class CampaignCompletionFilterToggleController {
  modelCtrl: any;
  filter: Filter;
  onToggle: ({ filter }: { filter: Filter }) => void;
  value = false;

  $onInit(): void {
    this.modelCtrl.$render = () => {
      this.value = this.modelCtrl.$viewValue;
    };
  }
  toggleChange(): void {
    this.modelCtrl.$setViewValue(this.value);
    this.onToggle({
      filter: { ...this.filter, value: this.value },
    });
  }
}
