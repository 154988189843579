import {
  DOCUMENT_UPLOAD_OPTIONS,
  FILES_UPLOAD_OPTIONS,
  IMAGE_UPLOAD_OPTIONS,
} from '@constants/files-upload-options.constant';
import {
  FileValidatorService,
  NgfFile,
  ValidationError,
} from '@services/Utils/FileValidator/file-validator.service';
import { Popup, PopupService } from '@services/Utils/Popup/popup.service';

export class CommentsFileService {
  imageOptions: typeof IMAGE_UPLOAD_OPTIONS;
  documentOptions: typeof DOCUMENT_UPLOAD_OPTIONS;
  TYPE_OPTIONS: {
    image: typeof IMAGE_UPLOAD_OPTIONS;
    document: typeof DOCUMENT_UPLOAD_OPTIONS;
  };

  constructor(
    private SF_FILES_UPLOAD_OPTIONS: typeof FILES_UPLOAD_OPTIONS,
    private $translate: ng.translate.ITranslateService,
    private fileValidatorService: FileValidatorService,
    private popupService: PopupService
  ) {
    'ngInject';
    this.imageOptions = this.SF_FILES_UPLOAD_OPTIONS.IMAGE;
    this.documentOptions = this.SF_FILES_UPLOAD_OPTIONS.DOCUMENT;
    this.TYPE_OPTIONS = {
      image: this.imageOptions,
      document: this.documentOptions,
    };
  }

  getValidFromSelectedFiles(blobs: Blob[], type) {
    const maxFilesAllowed = type === 'image' ? this.imageOptions.maxFiles : 1;
    const { valid, invalid } = this.fileValidatorService.filterByValidity(
      blobs,
      type,
      maxFilesAllowed
    );

    if (invalid.length > 0) {
      this.showErrors(invalid, this.TYPE_OPTIONS[type].maxSize);
    }
    return valid;
  }

  showErrors(invalidFiles: NgfFile[], maxSize: string): void {
    const errorMessage = {
      maxSize: this.$translate.instant('NEWSFEED_EDIT_POST_RESOURCE_MAX_SIZE', {
        nb: maxSize,
      }),
      maxFiles: this.$translate.instant(
        'NEWSFEED_EDIT_POST_RESOURCE_MAX_FILES'
      ),
      pattern: this.$translate.instant('NEWSFEED_EDIT_POST_RESOURCE_PATTERN'),
    };
    const errors = this.fileValidatorService.getFilesGroupedByErrors(
      invalidFiles,
      errorMessage
    );

    this.showFileErrors(errors);
  }

  showFileErrors(errors: ValidationError[]): Popup {
    const template = `
    <sf-newsfeed-resources-errors-popup
     title="$ctrl.title"
     resource-errors="$ctrl.errors"
     on-close="$ctrl.onClose()"
    >
    </sf-newsfeed-resources-errors-popup>
  `;
    const bindings = {
      title: this.$translate.instant('COMMENTS_FILE_ERRORS_TITLE'),
      errors,
    };

    return this.popupService.showCustomTemplate({ template, bindings });
  }
}
