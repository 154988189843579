"use strict";

/* eslint-disable max-len */
var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('views1'),
    name: 'views1',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      label: 'Filter Users',
      definition: '{\'$or\':[{\'form_created_date\':{\'$between\':[\'2017-04-01T00:00:00.000Z\',\'2017-06-30T23:59:59.999Z\']}}]}',
      ressourceType: 'users',
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee')
    }
  }, {
    _id: createObjectId('views2'),
    name: 'views2',
    description: 'Every data that can be set',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      label: 'Filter Users',
      definition: '{\'$or\':[{\'form_created_date\':{\'$between\':[\'2017-04-01T00:00:00.000Z\',\'2017-06-30T23:59:59.999Z\']}}]}',
      ressourceType: 'reports',
      parentRessource: {
        ressourceType: 'forms',
        _id: createObjectId('abbacacaabbaefcaabbacaca')
      },
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee')
    }
  }, {
    _id: createObjectId(),
    name: 'empty',
    description: 'Empty contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.label']
      }]
    },
    partial: {
      valid: true
    },
    contents: {}
  }, {
    _id: createObjectId(),
    name: 'nolabel',
    description: 'No Label',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.label']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      definition: '{\'$or\':[{\'form_created_date\':{\'$between\':[\'2017-04-01T00:00:00.000Z\',\'2017-06-30T23:59:59.999Z\']}}]}',
      ressourceType: 'reports',
      parentRessource: {
        ressourceType: 'forms',
        _id: createObjectId('abbacacaabbaefcaabbacaca')
      },
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee')
    }
  }, {
    _id: createObjectId(),
    name: 'nodefinition',
    definition: 'No Definition',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.definition']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      label: 'filter',
      ressourceType: 'reports',
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee')
    }
  }, {
    _id: createObjectId(),
    name: 'noorganisation',
    definition: 'No Organisation',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.organisation_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      label: 'filter',
      definition: 'definition',
      ressourceType: 'reports',
      owner_id: createObjectId('bbbacacaabbacacaabbaceee')
    }
  }, {
    _id: createObjectId(),
    name: 'noressourceType',
    definition: 'No Ressource Type',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.ressourceType']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      label: 'filter',
      definition: 'definition',
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee')
    }
  }, {
    _id: createObjectId(),
    name: 'noowner',
    definition: 'No Owner',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.owner_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      label: 'filter',
      definition: 'defintion',
      ressourceType: 'reports',
      organisation_id: createObjectId('abbacacaabbacacaabbacaca')
    }
  }];
};