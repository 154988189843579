const SIZES = [
  { name: 'small', value: 5 },
  { name: 'medium', value: 10 },
  { name: 'large', value: 15 },
];
const DEFAULT_SELECTED_SIZE = SIZES.filter((size) => size.name === 'medium')[0];

export const DrawingSizePickerComponent = {
  bindings: {
    onSizeSelect: '&',
  },
  templateUrl:
    'components/Drawing/drawing-size-picker/drawing-size-picker.html',
  controller: function DrawingSizePickerController() {
    'ngInject';
    this.$onInit = () => {
      this.sizes = SIZES;

      return this.onSizeChanged(DEFAULT_SELECTED_SIZE);
    };

    this.onSizeChanged = (size) => {
      this.selectedSize = size;

      return this.onSizeSelect({ size: this.selectedSize });
    };

    this.isSelectedSize = (sizeName) => this.selectedSize.name === sizeName;
  },
};
