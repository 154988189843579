export const addTrailingSlash = (serverUrl) => {
    if (!serverUrl) {
        return '';
    }
    return serverUrl.endsWith('/') ? serverUrl : `${serverUrl}/`;
};
export const formatDashboardList = (dashboards, integrationId, user, dashboardComponent) => {
    /* Helper function: determine the maximum rights a Securable has by combining the User-, Group- and IntegrationAccessRights. */
    const combineRights = (securable, user) => {
        const matchingUser = securable.users.find((u) => u.id === user.id);
        // User rights
        const securableAccessRights = matchingUser && matchingUser.userAccessRight
            ? {
                flagRead: matchingUser.userAccessRight.flagRead,
                flagUse: matchingUser.userAccessRight.flagUse,
                flagModify: matchingUser.userAccessRight.flagModify,
                flagOwn: matchingUser.userAccessRight.flagOwn
            }
            : {
                flagRead: false,
                flagUse: false,
                flagModify: false,
                flagOwn: false
            };
        // Group rights
        const groups = securable.groups.filter((group) => user.userGroups.includes(group.id) || group.public === true);
        const groupsAccess = groups.map((group) => {
            if (group.groupAccessRight) {
                return group.groupAccessRight;
            }
        });
        for (const groupAccess of groupsAccess) {
            if (!securableAccessRights.flagRead && groupAccess.flagRead) {
                securableAccessRights.flagRead = groupAccess.flagRead;
            }
            if (!securableAccessRights.flagUse && groupAccess.flagUse) {
                securableAccessRights.flagUse = groupAccess.flagUse;
            }
            if (!securableAccessRights.flagModify && groupAccess.flagModify) {
                securableAccessRights.flagModify = groupAccess.flagModify;
            }
            if (!securableAccessRights.flagOwn && groupAccess.flagOwn) {
                securableAccessRights.flagOwn = groupAccess.flagOwn;
            }
        }
        // Integration rights (no flagOwn)
        const integrationsAccess = securable.integrations?.length > 0
            ? securable.integrations.map((integration) => {
                if (integration.integrationAccessRight) {
                    return integration.integrationAccessRight;
                }
            })
            : [];
        for (const integrationAccess of integrationsAccess) {
            if (!securableAccessRights.flagRead && integrationAccess.flagRead) {
                securableAccessRights.flagRead = integrationAccess.flagRead;
            }
            if (!securableAccessRights.flagUse && integrationAccess.flagUse) {
                securableAccessRights.flagUse = integrationAccess.flagUse;
            }
            if (!securableAccessRights.flagModify && integrationAccess.flagModify) {
                securableAccessRights.flagModify = integrationAccess.flagModify;
            }
        }
        return securableAccessRights;
    };
    /* Helper function: localize names */
    const localize = (item, locale) => {
        if (item === null || item === undefined) {
            return '';
        }
        if (typeof item !== 'object' || Object.keys(item).length === 0) {
            if (typeof item === 'object' && Object.keys(item).length === 0) {
                return '';
            }
            return item;
        }
        let localizedItem;
        localizedItem = locale && item[locale] ? item[locale] : item[Object.keys(item)[0]];
        if (localizedItem === undefined || localizedItem === null) {
            localizedItem = '';
        }
        return localizedItem;
    };
    const treatedDashboards = [];
    for (const dashboard of dashboards) {
        const treatedDashboard = {
            accessibleBy: [],
            accessRights: combineRights(dashboard, user),
            id: dashboard.id,
            modifiedAt: dashboard.modified_at,
            name: localize(dashboard.name, dashboardComponent.language),
            slug: null,
            tags: dashboard.tags
                .map((t) => t.tag)
                .sort((t1, t2) => t1.localeCompare(t2))
        };
        // Find slug
        const currentIntegration = dashboard.integrations.find((i) => i.id === integrationId);
        if (currentIntegration?.integrationAccessRight?.slug) {
            treatedDashboard.slug = currentIntegration.integrationAccessRight.slug;
        }
        // Set accessibleBy array
        const users = dashboard.users.map((u) => ({
            model: 'User',
            id: u.id,
            name: localize(u.name, user.locale_id)
        }));
        const groups = dashboard.groups.map((g) => ({
            model: 'Group',
            id: g.id,
            name: localize(g.name, user.locale_id) || {}
        }));
        const integrations = dashboard.integrations.map((i) => ({
            model: 'Integration',
            id: i.id,
            name: localize(i.name, user.locale_id) || {}
        }));
        treatedDashboard.accessibleBy = [...users, ...groups, ...integrations];
        treatedDashboards.push(treatedDashboard);
    }
    return treatedDashboards;
};
export const VERSION = '0.0.0-shared-luzmo';
export const sanitizeUrl = (url) => {
    const invalidPrototcolRegex = /^(%20|\s)*(javascript|data)/im;
    const ctrlCharactersRegex = /[^\u0020-\u007E]/gim;
    const urlSchemeRegex = /^([^:]+):/gm;
    const relativeFirstCharacters = new Set(['.', '/']);
    const isRelativeUrlWithoutProtocol = (relativeUrl) => relativeFirstCharacters.has(relativeUrl[0]);
    if (!url) {
        return 'about:blank';
    }
    const sanitizedUrl = url.replaceAll(ctrlCharactersRegex, '').trim();
    if (isRelativeUrlWithoutProtocol(sanitizedUrl)) {
        return sanitizedUrl;
    }
    const urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);
    if (!urlSchemeParseResults) {
        return sanitizedUrl;
    }
    const urlScheme = urlSchemeParseResults[0];
    if (invalidPrototcolRegex.test(urlScheme)) {
        return 'about:blank';
    }
    return sanitizedUrl;
};
export const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj));
const isObject = (o) => o && typeof o === 'object';
export const defaultsDeep = (target, defaults) => {
    Object.entries(defaults).forEach(([k, v]) => {
        if (k in target) {
            if (target[k] === undefined) {
                target[k] = v;
            }
            else {
                target[k] = isObject(v)
                    ? defaultsDeep(target[k], v)
                    : target[k];
            }
        }
        else {
            target[k] = v;
        }
    });
    return target;
};
export const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .slice(1);
// uuidv4 code - https://stackoverflow.com/a/2117523
// Old code which used createObjectURL failed to work on android (react-native).
export const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replaceAll(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
});
