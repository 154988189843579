export function uniqueItemsFilter() {
  // we will return a function which will take in a collection
  // and a keyname
  return function (collection: any[], keyname: string) {
    // we define our output and keys array;
    const output: unknown[] = [],
      keys: unknown[] = [];
    // this takes in our original collection and an iterator function
    collection.forEach((item) => {
      // we check to see whether our object exists
      const key = item[keyname];
      // if it's not already part of our keys array
      if (keys.indexOf(key) === -1) {
        // add it to our keys array
        keys.push(key);
        // push this item to our final output array
        output.push(item);
      }
    });
    // return our array which should be devoid of
    // any duplicates
    return output;
  };
}
