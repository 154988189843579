const PRODUCT_DISCOUNT_FIELD_INDEX = 1;

export const QuestionProductScanItemComponent = {
  bindings: {
    answer: '<',
    question: '<',
    product: '<',
    hasDiscount: '<',
    onDelete: '&',
  },
  templateUrl:
    'missions/components/Form-questions/question-product-scan/components/question-product-scan-item.html',
  controller: function Controller() {
    'ngInject';
    this.$onInit = () => {
      const discountValue = this.answer.values[1];

      this.discountValue = discountValue ? discountValue.value : 0;

      this.getProductInfos();
    };
    this.$onChanges = (changes) => {
      if (changes.answer || changes.product) {
        this.getProductInfos();
      }
    };
    this.getProductInfos = () => {
      const value = this.getValue();

      this.productInfos = this.product
        ? `${this.product.contents.name} - ${this.product.contents.price.value}€`
        : value;

      return this.product;
    };
    this.onDiscountChange = () => {
      const discountValue = this.discountValue || 0;

      this.answer.values[PRODUCT_DISCOUNT_FIELD_INDEX] = this.answer.values[
        PRODUCT_DISCOUNT_FIELD_INDEX
      ] || {
        field_id: this.question.fields[PRODUCT_DISCOUNT_FIELD_INDEX],
      };
      this.answer.values[PRODUCT_DISCOUNT_FIELD_INDEX].value = discountValue;
    };
    this.getValue = () => this.answer.values[0].value;
    this.onDiscountFocus = ($event) => {
      $event.target.select();
    };
    this.onDiscountBlur = () => {
      this.discountValue = this.discountValue || 0;
    };
    // Product field
    this.hasError = () => this.getQuestion().$invalid;
    this.getQuestionError = () => this.getQuestion().$error;
    this.getQuestion = () => this[`product_scan_answer_${this.answer._id}`];
  },
};
