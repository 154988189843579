import { ReportScoringComponent } from './report-scoring.component';

export default angular
  .module('sfMobile.components.reportScoring', [
    'sfMobile.components.progress-circle',
    'sfMobile.services.reportScoring',
    'sfMobile.components.scoring-stats',
    'sfMobile.components.scoring-sections',
    'sfMobile.services.forms',
    'sfMobile.components.graphScoring',
    'sfMobile.components.toggle-labels',
  ])
  .component('sfReportScoring', ReportScoringComponent).name;
