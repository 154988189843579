import moment, { Moment } from 'moment';

export class TranslateNumbersService {
  constructor() {
    'ngInject';
  }

  changeNumberEnglishToArabic(text): string {
    return `${text}`.replace(/\d/g, (numb) => '٠١٢٣٤٥٦٧٨٩'[numb]);
  }

  changeNumberArabicToEnglish(text): string {
    return text.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
  }

  normalizeDateTime(moment: Moment): Moment {
    return moment.locale('en');
  }

  normalizeDateTimeUTC(moment: Moment): string {
    return moment.locale('en').format();
  }
}
