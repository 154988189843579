/* eslint-disable global-require */
'use strict';

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  var valid = [{
    _id: createObjectId('webhook1'),
    name: 'webhook 1',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    contents: {
      label: 'Webhook 1',
      method: 'POST',
      url: 'http://example.com',
      event: 'A_REPORT_CREATED',
      headers: [],
      successStatus: 201,
      organisation_id: createObjectId('abbacacaabbacacaabbacaca')
    }
  }, {
    _id: createObjectId('webhook2'),
    name: 'webhook 2',
    description: 'With headers',
    upstanding: {
      valid: true
    },
    contents: {
      label: 'Webhook 1',
      method: 'POST',
      url: 'http://example.com',
      event: 'A_REPORT_CREATED',
      headers: [{
        name: 'X-HEADER-NAME',
        value: 'Header Value'
      }, {
        name: 'Authorization',
        value: 'Basic basic'
      }],
      successStatus: 201,
      organisation_id: createObjectId('abbacacaabbacacaabbacaca')
    }
  }];

  var invalid = require('./webhook.schema').required.map(function (property) {
    var label = "Missing property \"".concat(property, "\"");
    var proto = {
      _id: createObjectId(label),
      name: label,
      description: label,
      upstanding: {
        valid: false
      },
      contents: {
        label: label,
        method: 'POST',
        url: 'http://example.com',
        event: 'A_REPORT_CREATED',
        headers: [],
        successStatus: 201,
        organisation_id: createObjectId('abbacacaabbacacaabbacaca')
      }
    };
    delete proto.contents[property];
    proto.upstanding.errors = [{
      code: 'E_REQUIRED',
      params: [".".concat(property)]
    }];
    return proto;
  });

  invalid.push({
    _id: createObjectId('invalid url'),
    name: 'invalid url',
    description: 'invalid url',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.url', 'should match format uri']
      }]
    },
    contents: {
      label: 'invalid url',
      method: 'POST',
      url: 'invalid_url',
      event: 'A_REPORT_CREATED',
      headers: [],
      successStatus: 201,
      organisation_id: createObjectId('abbacacaabbacacaabbacaca')
    }
  }, {
    _id: createObjectId('invalid headers'),
    name: 'invalid headers',
    description: 'invalid headers',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_UNEXPECTED_CONTENT',
        params: ['.not_name']
      }]
    },
    contents: {
      label: 'invalid headers',
      method: 'POST',
      url: 'http://example.com',
      event: 'A_REPORT_CREATED',
      headers: [{
        not_name: 'X-HEADER-NAME',
        value: 'Header Value'
      }],
      successStatus: 201,
      organisation_id: createObjectId('abbacacaabbacacaabbacaca')
    }
  });
  return [].concat(valid, _toConsumableArray(invalid));
};