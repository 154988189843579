import { isEmpty } from 'ramda';

const CHANGE_REQUESTS_ITEMS_LIMIT = 20;
const STATE_NAME = 'index.menu-more.change-requests.tabs';

export const ChangeRequestsListComponent = {
  bindings: {
    state: '<',
    profile: '<',
    isActive: '<',
    onListReloaded: '&',
    contentKey: '@',
  },
  templateUrl:
    'change-requests/components/change-requests-list/change-requests-list.html',
  controller: class ChangeRequestsListController {
    // eslint-disable-next-line max-params
    constructor(
      $translate,
      pubSubService,
      appMessageService,
      eventChangeRequestsService,
      RequestsPaginate,
      helpersService
    ) {
      'ngInject';
      this.$translate = $translate;
      this.pubSubService = pubSubService;
      this.appMessageService = appMessageService;
      this.eventChangeRequestsService = eventChangeRequestsService;
      this.RequestsPaginate = RequestsPaginate;
      this.helpersService = helpersService;
    }

    $onInit() {
      this.httpRequestOption =
        this.eventChangeRequestsService.getHttpRequestOptions(this.state);
      this.requestPaginate = new this.RequestsPaginate(
        (params) =>
          this.eventChangeRequestsService.getChangeRequests({
            ...params,
            add_comments_count: true,
          }),
        {
          limit: CHANGE_REQUESTS_ITEMS_LIMIT,
        }
      );
      this.stateChangeListener = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
        (params) => {
          if (STATE_NAME === params.name && this.isActive) {
            this.reload();
          }
        }
      );

      return this.loadChangeRequests();
    }

    $onChanges({ isActive }) {
      if (!isActive.previousValue && isActive.currentValue) {
        this.reload();
      }
    }

    $onDestroy() {
      this.stateChangeListener();
    }

    reload() {
      this.requestPaginate.reset();

      return this.loadChangeRequests().then(() => this.onListReloaded());
    }

    loadChangeRequests() {
      this.isLoading = true;
      this.networkError = false;
      this.infiniteLoadError = false;

      return this.getChangeRequests()
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    getChangeRequests() {
      return this.requestPaginate
        .call(this.httpRequestOption)
        .then((changeRequests) => {
          this.changeRequests = this.helpersService.addDividerDays(
            changeRequests.entities,
            'created_date',
            true
          );
          this.isEmpty = isEmpty(this.changeRequests);

          return changeRequests;
        });
    }

    getNextChangeRequests() {
      this.infiniteLoadError = false;

      return this.getChangeRequests().catch(() => {
        this.infiniteLoadError = true;
      });
    }

    resetInfiniteLoadErrorState() {
      this.infiniteLoadError = false;
    }

    deleteChangeRequest({ changeRequestId }) {
      return this.eventChangeRequestsService.showDeleteAgreeModal().then(() =>
        this.eventChangeRequestsService
          .delete(changeRequestId)
          .then(() =>
            this.appMessageService.display(
              this.$translate.instant('CHANGE_REQUESTS_DELETE_SUCCESS'),
              'success'
            )
          )
          .then(() => this.reload())
          .catch(() =>
            this.appMessageService.display(
              this.$translate.instant('CHANGE_REQUESTS_DELETE_ERROR'),
              'fail'
            )
          )
      );
    }

    approveChangeRequest({ changeRequestId }) {
      return this.eventChangeRequestsService.approve(changeRequestId).then(
        () => {
          this.appMessageService.display(
            this.$translate.instant('CHANGE_REQUESTS_APPROVE_SUCCESS'),
            'success'
          );
          return this.reload();
        },
        () =>
          this.appMessageService.display(
            this.$translate.instant('CHANGE_REQUESTS_APPROVE_ERROR'),
            'fail'
          )
      );
    }

    rejectChangeRequest({ changeRequestId }) {
      return this.eventChangeRequestsService.reject(changeRequestId).then(
        () => {
          this.appMessageService.display(
            this.$translate.instant('CHANGE_REQUESTS_REJECT_SUCCESS'),
            'success'
          );
          return this.reload();
        },
        () =>
          this.appMessageService.display(
            this.$translate.instant('CHANGE_REQUESTS_REJECT_ERROR'),
            'fail'
          )
      );
    }
  },
};
