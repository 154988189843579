import { CalendarEvent } from '../../../types';

type DateFormatService = {
  getTimezoneFormatted: (timezone: string, date: string) => string;
};

export class ManagerCalendarEventListItemController {
  private dateFormatService: DateFormatService;
  private displayTimezone: string;
  private event: CalendarEvent;

  constructor(dateFormatService: DateFormatService) {
    'ngInject';
    this.dateFormatService = dateFormatService;
  }

  shouldDisplayTz(): boolean {
    return this.displayTimezone !== this.event.contents.timezone.name;
  }

  formatTimezone(): string {
    return this.dateFormatService.getTimezoneFormatted(
      this.event.contents.timezone.name,
      this.event.contents.start_dateTime
    );
  }
}
