import { ChinaConfigService } from '../../../services/Utils/Config/china-config.service';
import { InAppBrowserService } from '../../../services/Utils/InAppBrowser/in-app-browser.service';
import {
  Popup,
  PopupService,
} from '../../../services/Utils/Popup/popup.service';

export const PopupAgreementComponent = {
  bindings: {
    onClose: '&',
    hasDenied: '<',
  },
  templateUrl: 'components/Popup/popup-agreement/popup-agreement.html',
  controller: class PopupAgreementComponent {
    onClose: () => void;

    constructor(
      private $translate: ng.translate.ITranslateService,
      private chinaConfigService: ChinaConfigService,
      private localStorageService: ng.local.storage.ILocalStorageService,
      private inAppBrowserService: InAppBrowserService,
      private popupService: PopupService
    ) {
      'ngInject';
    }

    openUserAgreement(): ng.IPromise<void> {
      return this.chinaConfigService
        .getUserAgreementUrl()
        .then((url) => this.inAppBrowserService.open(url))
        .catch(() => this.openErrorPopup());
    }

    openPrivacyPolicy(): ng.IPromise<void> {
      return this.chinaConfigService
        .getPrivacyPolicyUrl()
        .then((url) => this.inAppBrowserService.open(url))
        .catch(() => this.openErrorPopup());
    }

    openErrorPopup(): Popup {
      return this.popupService.showError(
        {
          title: this.$translate.instant('POPUP_AGREEMENT_ERROR'),
        },
        () => {
          this.localStorageService.set('configuration_area', 'world');
          return window.location.reload();
        }
      );
    }
  },
};
