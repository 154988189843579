import type { APIStore } from '../../../';

export class PlaceActivityController implements ng.IController {
  // bindings
  place: APIStore;
  organisation;
  profile;

  //fields
  isAssociatedStore: boolean;
  hasTasksFeatureFlag: boolean;

  // class members
  constructor(private tasksService) {
    'ngInject';
  }

  $onInit(): void {
    this.isAssociatedStore = this.profile.contents.places_ids.includes(
      this.place._id
    );
    this.hasTasksFeatureFlag = this.tasksService.hasFeatureFlag();
  }
}
