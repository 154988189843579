import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { PICTURE_SYSTEM_IDENTIFIERS } from '../../../constants/picture-system.constants';
import { AwakeService } from '../../../services/Utils/Awake/awake.service';
import { ImageService } from '../../../services/Utils/Image/image.service';
import { PictureSystemService } from '../../../services/Utils/PictureSystem/picture-system.service';
import { PlatformService } from '../../../services/Utils/Platform';
import { SentryService } from '../../../services/Utils/Sentry/sentry.service';
import {
  FILES_UPLOAD_OPTIONS,
  IMAGE_UPLOAD_OPTIONS,
} from '../../../constants/files-upload-options.constant';
import { NgfFile } from '../../../services/Utils/FileValidator/file-validator.service';
import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import { ReportsService } from '../../../services/API/reports/reports.service';

const PICTURE_WIDTH_PERCENT = 50;

export const MerchandisingPhotoReplacerComponent = {
  bindings: {
    validation: '<',
    profile: '<',
    selectedAnswers: '<',
    onRetakePhotoSuccess: '&',
    onClose: '&',
  },
  templateUrl:
    'merchandising/components/merchandising-photo-replacer/merchandising-photo-replacer.html',
  controller: class MerchandisingPhotoReplacerComponent {
    // bindings
    validation;
    profile;
    selectedAnswers;
    onRetakePhotoSuccess: () => void;
    onClose: () => void;

    pictureIds: string[];
    isLoading = false;
    pendingPhoto = false;
    networkError = false;
    questionTitle: string;
    picturesPath: Record<string, string>;
    pendingReport = false;
    comments;
    imageOptions: typeof IMAGE_UPLOAD_OPTIONS;
    resizeOptions: Record<'width' | 'height' | 'quality', number>;
    isBrowser = false;

    private changedFilesIds: string[];
    private currentStep = 0;
    private isReplacementFull = false;
    private report;
    private useNewCampaigns: boolean;

    // eslint-disable-next-line max-params
    constructor(
      private $element: ng.IRootElementService,
      private $q: ng.IQService,
      private imageService: ImageService,
      private merchandisingService,
      private merchandisingApiService,
      private pictureSystemService: PictureSystemService,
      private merchandisingPopupService,
      private reportsService: ReportsService,
      private ReportSending,
      private sentryService: SentryService,
      private awakeFactory: AwakeService,
      private segmentService,
      private helpersService,
      private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
      private SF_FILES_UPLOAD_OPTIONS: typeof FILES_UPLOAD_OPTIONS,
      private platformService: PlatformService,
      private objectIdService: ObjectIdService,
      private organisationsService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<void> {
      this.isBrowser = this.platformService.isBrowser();
      this.imageOptions = this.SF_FILES_UPLOAD_OPTIONS.IMAGE;
      this.resizeOptions = {
        width: this.SF_FILES_UPLOAD_OPTIONS.IMAGE.maxWidth,
        height: this.SF_FILES_UPLOAD_OPTIONS.IMAGE.maxHeight,
        quality: this.SF_FILES_UPLOAD_OPTIONS.IMAGE.quality,
      };
      const validation = this.validation;

      this.selectedAnswers = this.selectedAnswers.sort(
        this.helpersService.getSorterByGetter((a) => a.index)
      );
      this.pictureIds = this.merchandisingService
        .getValidations(validation)
        .map((answer) => answer.values[0].value);

      this.organisationsService
        .getProfileOrganisation(this.profile)
        .then((organisation) => {
          this.useNewCampaigns = organisation.useNewCampaigns;
        });

      return this.reload();
    }

    reload(): ng.IPromise<void> {
      this.isLoading = true;
      this.isReplacementFull = false;
      this.networkError = false;
      this.changedFilesIds = [];
      this.currentStep = 0;
      this.questionTitle = this.merchandisingService.getQuestionTitle(
        this.validation.contents.question_id,
        this.validation.form.contents.questions
      );
      this.imageService
        .getSizedUrlsFromIds(
          this.selectedAnswers.map(
            (selectedAnswer) => selectedAnswer.answer.values[0].value
          ),
          this.SF_IMAGE_SIZES.SQUARE_LARGE
        )
        .then((urls) => {
          this.picturesPath = urls;
        });

      return this.$q
        .all([
          this.reportsService.crud.get(this.validation.report._id, {}),
          this.merchandisingApiService.getComments(this.validation._id),
        ])
        .then(([report, comments]) => {
          this.report = report;
          this.comments = comments;
        })
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    close(): ng.IPromise<void> {
      return this.merchandisingPopupService
        .showRetakeLeaveConfirm()
        .then(() => this.onClose())
        .catch(() => null);
    }

    goToNextStep(): boolean {
      if (this.currentStep < this.selectedAnswers.length - 1) {
        const picturesContainerElement = this.$element[0].querySelector(
          '.sf_merchandising_photo_replacer__pictures'
        ) as HTMLElement;

        this.currentStep += 1;
        picturesContainerElement.style.transform = `translate3d(-${
          this.currentStep * PICTURE_WIDTH_PERCENT
        }%, 0, 0)`;

        return true;
      }

      this.isReplacementFull = true;
      return false;
    }

    retakePicture(): ng.IPromise<boolean> {
      this.pendingPhoto = true;
      return this.pictureSystemService
        .selectSourceType([
          PICTURE_SYSTEM_IDENTIFIERS.LIBRARY,
          PICTURE_SYSTEM_IDENTIFIERS.IMAGE,
        ])
        .then((sourceIdentifier) =>
          this.pictureSystemService.getPictureFromSource(sourceIdentifier)
        )
        .then((paths) => {
          if (typeof paths[0] === 'string') {
            return this.replaceAnswerInReport(paths as string[]).then(() =>
              this.goToNextStep()
            );
          }
          return this.selectFilesOnBrowser(paths as File[], []);
        })
        .finally(() => {
          this.pendingPhoto = false;
        });
    }

    selectFilesOnBrowser(files: File[], invalidFiles: NgfFile[]): boolean {
      const answerIndex = this.report.contents.answers
        .map((answer) => answer._id)
        .indexOf(this.selectedAnswers[this.currentStep].answer._id);

      const newImgId = this.objectIdService.create();

      if (invalidFiles.length > 0) {
        this.merchandisingPopupService.showTakePictureError();
      }

      if (!files.length || files.length > 1) {
        return false;
      }

      const replacementFile = files[0];

      this.report.contents.answers[answerIndex].values[0].value = newImgId;
      this.report.contents.answers[answerIndex].values[1].value =
        replacementFile.name;
      this.report.contents.answers[answerIndex].temp = {
        fileType: 'blob',
        fileBlob: replacementFile,
        needSync: true,
      };

      this.changedFilesIds = this.changedFilesIds.concat(newImgId);
      this.pendingPhoto = false;

      return this.goToNextStep();
    }

    replaceAnswerInReport(paths: string[]): ng.IPromise<void> {
      const path = paths[0];

      const answerIndex = this.report.contents.answers
        .map((answer) => answer._id)
        .indexOf(this.selectedAnswers[this.currentStep].answer._id);

      const newImgId = this.objectIdService.create();
      const res = {
        fileId: newImgId,
        path: path,
      };

      return this.pictureSystemService
        .movePicture(path, this.validation.report._id, newImgId)
        .then((storeData: { path: string; name: string }) => ({
          ...res,
          ...storeData,
        }))
        .then((data) => {
          this.report.contents.answers[answerIndex].values[0].value =
            data.fileId;
          this.report.contents.answers[answerIndex].values[1].value = data.name;
          this.report.contents.answers[answerIndex].temp = { needSync: true };
          this.changedFilesIds = this.changedFilesIds.concat(newImgId);
        })
        .catch((err) => {
          const reportContents = this.report.contents || {};

          this.sentryService.captureMessage('Capture photo failed', {
            extra: {
              report_id: this.report._id,
              form_id: reportContents.form_id,
              place_id: reportContents.place_id,
              error: err,
            },
          });
          return this.merchandisingPopupService.showTakePictureError();
        });
    }

    isCurrentPicture(pictureIndex: number): boolean {
      return !this.isReplacementFull && pictureIndex === this.currentStep;
    }

    patchReport(): ng.IPromise<any> {
      const reportWithDate = {
        ...this.report,
        saved_date: new Date(),
      };
      const ctx = {
        report: reportWithDate,
        place: { contents: this.validation.report.place },
        user: this.profile,
      };
      const segmentService = this.segmentService;
      const sendPromise = this.$q.defer();
      const reportSending = new this.ReportSending(this.validation.form, ctx);

      this.pendingReport = true;

      const popupRequest =
        this.merchandisingPopupService.showReportSendingRequest(
          () => sendPromise.resolve(),
          this.useNewCampaigns
        );

      this.awakeFactory.keepAwake();

      return reportSending
        .send({ canceler: sendPromise })
        .then(() => trackSuccess(this.report, this.validation.form))
        .then(() =>
          this.merchandisingApiService.putAnswerInPending(
            this.validation,
            this.profile,
            this.changedFilesIds.length && this.changedFilesIds
          )
        )
        .then(() => popupRequest.onSuccess())
        .then(() => this.onClose())
        .then(() => this.onRetakePhotoSuccess())
        .catch((err) => {
          trackFail(err);
          return popupRequest.onError(
            () => this.patchReport(),
            () => this.onClose()
          );
        })
        .finally(() => {
          this.pendingReport = false;
          this.awakeFactory.allowSleepAgain();
        });

      function trackSuccess(report, form) {
        segmentService.track('MERCH', {
          action: 'send',
          label: 'succeed',
          report_id: report._id,
          campaign_id: form._id,
          campaign_title: form.contents.title,
        });
      }
      function trackFail(err) {
        segmentService.track('MERCH', {
          action: 'send',
          label: 'failed',
          value: err.status,
        });
      }
    }
  },
};
