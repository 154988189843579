import { _handleError } from './error';
// check browser support: svg, postMessage, flexbox
export const _browserCheck = (dashboardComponent) => {
    let hasError = false;
    let errorMessage = '';
    const document_ = document.body || document.documentElement;
    const style = document_.style;
    const browserCheck = {
        svg: !!('createElementNS' in document
            && document.createElementNS('http://www.w3.org/2000/svg', 'svg')
                .createSVGRect),
        flexbox: style.webkitFlexWrap === '' || style.flexWrap === '',
        postMessage: !!window.postMessage
    };
    const browserCheckKeys = ['svg', 'flexbox', 'postMessage'];
    for (const key of browserCheckKeys) {
        if (!browserCheck[key]) {
            hasError = true;
            dashboardComponent.browserNotSupported
                = dashboardComponent.browserNotSupported || [];
            dashboardComponent.browserNotSupported.push(key);
            errorMessage += `Your browser does not support: ${key}\n`;
        }
    }
    // Check browser for IE
    const ua = window.navigator.userAgent;
    const msie = ua.includes('MSIE');
    const trident = ua.includes('Trident/');
    if (msie || trident) {
        hasError = true;
        errorMessage = 'IE is not supported.';
    }
    if (hasError) {
        _handleError(errorMessage);
    }
};
