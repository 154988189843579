import createAuth0Client, { Auth0Client, User } from '@auth0/auth0-spa-js';
import { App } from '@capacitor/app';
import { Browser, OpenOptions } from '@capacitor/browser';
import { StateService } from '@uirouter/core';
import { IRequestConfig } from 'angular';
import { ObjectId, Organisation } from '../../../index';
import { ConfigService } from '../../Utils/Config/config.service';
import { InAppBrowserService } from '../../Utils/InAppBrowser/in-app-browser.service';
import { PlatformService } from '../../Utils/Platform';
import { PopupService } from '../../Utils/Popup/popup.service';
import { PubSubService, UnregisterFn } from '../../Utils/PubSub/pubsub.service';
import { RouterService } from '../../Utils/Router/router.service';
import { SimpleStorageService } from '../../Utils/SimpleStorage/simple-storage.service';
import { ApiUrlService } from '../apiUrl/api-url.service';
import { getContents } from './auth-helpers';
import { Auth0Faker } from './auth0-faker';

type LocaleAuthConfig = {
  web: {
    client_id: string;
    domain: string;
  };
  mobile: {
    client_id: string;
    domain: string;
    enabled: boolean;
  };
  api: { domain: string; audience: string; tokenRotation: number };
};
export type BackEndAuthConfig = {
  eu: LocaleAuthConfig;
  cn?: LocaleAuthConfig;
};

export type AuthConfig = {
  client_id: string;
  domain: string;
  audience: string;
  enabled: boolean;
  tokenRotation: number;
};

type UserProfile = {
  _id: ObjectId;
  contents: {
    firstName: string;
    lastName: string;
    organisation_id: string;
  };
};

// eslint-disable-next-line max-params
export class AuthService {
  auth0: Auth0Client;
  tokenRotation: number;
  loginPending = false;
  logoutPending = false;
  loggedIn = false;
  loggingInProgress = false;
  lastStateChangeDate: number | undefined;
  stateChangeStartListener: UnregisterFn | undefined;
  private auth0User?: User;
  private auth0AccessToken?: string;
  private auth0Faker?: Auth0Faker;
  private refreshCallPromise: ng.IPromise<string> | null;
  private org_target = '';

  constructor(
    private $http: ng.IHttpService,
    private $q: ng.IQService,
    private $rootScope: ng.IRootScopeService & { jwt?: string },
    private $state: StateService,
    private $translate: ng.translate.ITranslateService,
    private $window: ng.IWindowService,
    private apiUrlService: ApiUrlService,
    private appStateService,
    private dataStoreService,
    private organisationsService,
    private profileService,
    private routerService: RouterService,
    private userDevicesService,
    private platformService: PlatformService,
    private popupService: PopupService,
    private pubSubService: PubSubService,
    private inAppBrowserService: InAppBrowserService,
    private simpleStorageService: SimpleStorageService,
    private $log: ng.ILogService,
    private synchronizeService,
    private configService: ConfigService,
    private preferencesService
  ) {
    'ngInject';
    if (this.isDevMode()) {
      this.auth0Faker = new Auth0Faker({
        api: configService.BaseConfig.API_BASEURL,
      });
    }
    this.simpleStorageService.get('org_target').then((org_target) => {
      if (org_target) {
        this.org_target = org_target;
      }
      this.initializeAuthentication();
    });
  }

  isDevMode(): boolean {
    const isLocal = this.configService.BaseConfig.LOCAL;
    const isDevOrNativeEnv =
      this.configService.BaseConfig.ENV === 'development' ||
      this.configService.BaseConfig.ENV === 'native';

    return (
      isLocal &&
      isDevOrNativeEnv &&
      Boolean(this.configService.BaseConfig.AUTH0_DISABLED)
    );
  }

  set devModeEmail(email: string) {
    if (this.auth0Faker) {
      this.auth0Faker.email = email;
    }
  }

  /*
   * New Login (Auth0)
   */
  configureClient(
    force = false,
    config?: { org_target: ObjectId }
  ): ng.IPromise<Auth0Client> {
    if (!force && this.auth0) {
      return this.$q.resolve(this.auth0);
    }
    return this.configService
      .getAuth0Configuration()
      .then((authConfig) => {
        this.tokenRotation = authConfig.tokenRotation;
        if (this.isDevMode()) {
          if (config?.org_target) {
            (this.auth0Faker as Auth0Faker).setOrg(config.org_target);
          }
          return this.auth0Faker as Auth0Faker;
        }
        return createAuth0Client({
          domain: authConfig.domain,
          client_id: authConfig.client_id,
          audience: authConfig.audience,
          useRefreshTokens: true,
          cacheLocation: 'localstorage',
        });
      })
      .then((auth0) => {
        this.auth0 = auth0;
        return auth0;
      });
  }

  isAuthenticated(): ng.IPromise<boolean> {
    return this.$q
      .all([
        this.simpleStorageService.get('user'),
        this.simpleStorageService.get('token'),
      ])
      .then(([user, token]) => {
        return !!(user && token);
      });
  }

  loginWithAuth0(): ng.IPromise<unknown> {
    this.loggingInProgress = true;
    const redirect_uri = this.platformService.isBrowser()
      ? `${window.location.origin}/#!/activity`
      : 'com.simplifield.app://index.activity.details';

    return this.configureClient(true)
      .then((auth0) => auth0.buildAuthorizeUrl({ redirect_uri }))
      .then((url) => this.handleAuth0BrowserActions(url))
      .finally(() => {
        this.loggingInProgress = false;
      });
  }

  handleAuth0BrowserActions(url: string): ng.IPromise<unknown> {
    if (this.isDevMode()) {
      return this.handleAuthenticationInitialization({ url });
    }

    const options: Omit<OpenOptions, 'url'> = {};

    if (this.platformService.isBrowser()) {
      options.windowName = '_self';
    }

    return (
      this.platformService.isBrowser() ? this.$q.when() : Browser.close()
    ).finally(() => {
      return this.inAppBrowserService.open(url, options);
    });
  }

  treatLoginResponseWithAuth0(): ng.IPromise<UserProfile> {
    return this.getAuth0Config().then((auth0Config) => {
      if (!auth0Config) {
        return;
      }

      const { token, user } = auth0Config;

      this.$log.debug('User connected', user);
      const tokens = {
        token: null,
        jwt: token,
      };
      const headers = {
        Authorization: this.profileService.getAuthorizationHeader({
          contents: tokens,
        }),
      };

      // For function getConnectedUserProfile,
      // we will need to use auth0 metadata in prod but not in dev for sure
      // For staging, we need to make sure between envs but for devs:
      // - we do not need to use auth0 metadata
      // For any other env:
      // - we will need to use those metadata
      return this.profileService
        .getConnectedUserProfile(
          {
            headers,
          },
          user['http://url']
        )
        .then((profile) => {
          return this.treatLoginResponse({
            _id: profile._id,
            payload: {
              aud: user['http://url'],
              jwt: token,
            },
          });
        });
    });
  }

  getAuth0Config(): ng.IPromise<{ token: string; user: User }> {
    return this.simpleStorageService
      .get('user')
      .then((user) => {
        if (user) {
          return { user: JSON.parse(user) };
        }
        return this.configureClient()
          .then((auth0) => auth0.getUser())
          .then((user) => {
            if (!user) {
              return this.$q.reject('Auth0 - The user is not connected.');
            }

            return this.simpleStorageService
              .save('user', JSON.stringify(user))
              .then(() => ({ user }));
          });
      })
      .then(({ user }) => {
        if (user && this.auth0AccessToken) {
          return { user, token: this.auth0AccessToken };
        }
        return this.refreshAuth0Token().then((token) => {
          return { user, token };
        });
      });
  }

  setAuthenticationConfig(config, useStoredToken = true): ng.IPromise<any> {
    const setAuthorization = (auth) => {
      if (auth) {
        config.headers = config.headers || {};
        config.headers.Authorization = auth;
      }
      return config;
    };

    if (this.$rootScope.jwt) {
      return this.$q.when(setAuthorization(`Bearer ${this.$rootScope.jwt}`));
    }

    if (useStoredToken) {
      this.checkTokenRotation();
    }

    return this.refreshAuth0Token(useStoredToken).then((token) =>
      setAuthorization(`Bearer ${token}`)
    );
  }

  refreshAuth0Token(useStoredToken = true): ng.IPromise<string> {
    return this.simpleStorageService.get('token').then((storedToken) => {
      if (storedToken && useStoredToken) {
        this.auth0AccessToken = storedToken;
        return storedToken;
      }

      // We only need one call to getToken of auth0 API
      // In some cases, the refreshAuth0Token function can get called
      // multiple times before the get on Auth0 is finished.
      // We need to temporize with a buffer promise.
      if (!this.refreshCallPromise) {
        this.refreshCallPromise = this.getAuth0Token().finally(() => {
          this.refreshCallPromise = null;
        });
      }
      return this.refreshCallPromise;
    });
  }

  getAuth0Token(): ng.IPromise<string> {
    const isLocal = this.configService.BaseConfig.LOCAL;
    const isBrowser = this.platformService.isBrowser();

    return this.configureClient()
      .then((auth0) => {
        if (isLocal && !isBrowser) {
          return auth0.getTokenWithPopup({
            org_target: this.org_target,
            ignoreCache: true,
          }); // Developers in dev environments MOTW
        }
        return auth0.getTokenSilently({
          org_target: this.org_target,
          ignoreCache: true,
        });
      })
      .then((token) => {
        this.auth0AccessToken = token;
        return this.setToken(token);
      })
      .catch((err) => {
        this.$log.error('Auth0 - Error while refreshing token', err);
        // No token can be retrieved, either the user is logged out either the
        // refresh token is revoked
        return this.$q.reject({ logout: true });
      });
  }

  private setToken(
    token: string
  ): string | PromiseLike<never> | PromiseLike<string> {
    this.setTokenRotation();
    return this.simpleStorageService.save('token', token).then(() => token);
  }

  private setTokenRotation() {
    if (this.tokenRotation) {
      const deadline = new Date();
      deadline.setSeconds(deadline.getSeconds() + this.tokenRotation);
      this.simpleStorageService.saveDate('tokenRotationDate', deadline);
    }
  }

  private checkTokenRotation(): ng.IPromise<void> {
    return this.simpleStorageService
      .getDate('tokenRotationDate')
      .then((date) => {
        if (date && date < new Date()) {
          this.simpleStorageService.remove('tokenRotationDate').then(() => {
            this.refreshAuth0Token(false);
          });
        }
      });
  }

  switchToOrganisation(org_target: ObjectId): ng.IPromise<UserProfile> {
    this.auth0AccessToken = undefined;
    this.synchronizeService.reset();
    this.simpleStorageService.remove('token');
    this.simpleStorageService.save('org_target', org_target);
    this.org_target = org_target;

    if (this.isDevMode()) {
      this.auth0Faker?.setOrg(org_target);
    }

    return this.treatLoginResponseWithAuth0()
      .then(() => this.profileService.getLocalProfile())
      .then((profile) =>
        this.$q.all([
          this.$q.when(profile),
          this.organisationsService.getProfileOrganisation(profile),
        ])
      )
      .then(([profile, org]) => {
        this.appStateService.resetProfileServices(false);
        this.appStateService.initOrganisationServices(org);
        return this.appStateService.initProfileServices(profile, org);
      });
  }

  auth0Logout(): ng.IPromise<unknown> {
    this.loggingInProgress = false;
    const returnTo = this.platformService.isBrowser()
      ? `${window.location.origin}/#!/login`
      : 'com.simplifield.app://login.page';

    return this.configureClient()
      .then((auth0) => {
        this.auth0User = undefined;
        return auth0.buildLogoutUrl({ returnTo, federated: true });
      })
      .then((url) => {
        this.auth0.logout({ localOnly: true });
        return this.handleAuth0BrowserActions(url);
      });
  }

  initializeAuthentication(): ng.IPromise<unknown> {
    if (!this.platformService.isBrowser()) {
      App.addListener('appUrlOpen', (data) => {
        if (!data?.url?.includes('?code=')) {
          return;
        }
        return Browser.close().finally(() =>
          this.handleAuthenticationInitialization(data)
        );
      });
      return this.$q.resolve();
    }
    return this.handleAuthenticationInitialization();
  }

  handleAuthenticationInitialization(data?: any): ng.IPromise<unknown> {
    this.loggingInProgress = true;
    return this.configureClient(Boolean(data))
      .then((auth0) => {
        return this.isAuthenticated().then((isAuthenticated) => {
          const query = window.location.search;
          const hasAuth0QueryParams =
            query.includes('code=') && query.includes('state=');

          if (isAuthenticated) {
            return this.treatLoginResponseWithAuth0()
              .then(() => {
                return this.handleRedirection(data);
              })
              .then(() => {
                return this.updateTimezoneFromDevice();
              });
          }

          if (hasAuth0QueryParams || data?.url) {
            const search = data?.url.split('?').pop();
            const url =
              window.location.origin + '?' + (search || query.slice(1));

            return this.$q
              .resolve()
              .then(() => {
                if (!url.includes('login.page')) {
                  return auth0
                    .handleRedirectCallback(url)
                    .then(() => this.treatLoginResponseWithAuth0());
                }
              })
              .catch((err) => console.error(err))
              .then(() => {
                return this.handleRedirection(data);
              })
              .then(() => {
                return this.updateTimezoneFromDevice();
              });
          }
        });
      })
      .catch(() => {})
      .finally(() => {
        this.loggingInProgress = false;
      });
  }

  updateTimezoneFromDevice(): Promise<{
    name: string;
    value: string;
  }> {
    const deviceTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.preferencesService.save('settings.timeZone', deviceTz);
  }

  handleRedirection(data): void {
    const isLoginPage = window.location.hash === '#!/login';
    const isLocal = this.configService.BaseConfig.LOCAL;
    const isBrowser = this.platformService.isBrowser();
    const shouldRedirectByURL = isLoginPage && isLocal && isBrowser;

    const isPreview = this.routerService.isAPreviewState();

    if (isPreview) {
      return;
    }

    if (shouldRedirectByURL) {
      const hash = isLoginPage ? '#!/activity' : window.location.hash;

      window.location.href = `${window.location.origin}/${hash}`;
      return;
    }

    const state = data?.url.split('.app://').pop()?.split('?').shift();

    if (state) {
      this.$state.go(state, {}, { reload: true });
    }

    // in any case where a user is authenticated do not fall into any of above cases
    this.$state.go('index.activity.details');
  }

  registerAutomaticLogoutListener(organisation: Organisation): void {
    const logoutPeriodPreferenceValue =
      this.getLogoutPreferenceValue(organisation);

    if (this.stateChangeStartListener) {
      this.stateChangeStartListener();
      this.stateChangeStartListener = undefined;
    }

    if (logoutPeriodPreferenceValue) {
      this.stateChangeStartListener = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.CLICK,
        () => {
          if (!this.lastStateChangeDate) {
            this.lastStateChangeDate = Date.now();
            return;
          }
          if (this.isSessionExpired(logoutPeriodPreferenceValue)) {
            this.lastStateChangeDate = undefined;
            return this.showExpirationPopup(logoutPeriodPreferenceValue);
          }
          this.lastStateChangeDate = Date.now();
        }
      );
    }
  }

  showExpirationPopup(
    logoutPeriodPreferenceValue: number,
    proceedCallback = () => {}
  ): ng.IPromise<void> {
    return this.profileService.getLocalProfile().then((profile) => {
      this.popupService.showOptions(
        {
          title: this.$translate.instant('LOGOUT_EXPIRED_CONFIRM_INFO', {
            username: getFullName(profile),
            timeout: logoutPeriodPreferenceValue,
          }),
          iconName: 'item-danger',
          btnFirstOption: this.$translate.instant(
            'LOGOUT_EXPIRED_CONFIRM_BUTTON_CONTINUE'
          ),
          btnSecondOption: this.$translate.instant(
            'LOGOUT_EXPIRED_CONFIRM_BUTTON_LEAVE'
          ),
        },
        () => {
          this.lastStateChangeDate = Date.now();
          return proceedCallback();
        },
        () => this.logout()
      );
    });
  }

  isSessionExpired(logoutPeriodInSeconds: number): boolean {
    if (!this.lastStateChangeDate) {
      return false;
    }

    return Date.now() - this.lastStateChangeDate > logoutPeriodInSeconds * 1000;
  }

  getLogoutPreferenceValue(organisation: Organisation): number | undefined {
    const logoutPeriodPreference = organisation?.preferences?.find(
      ({ name }) => name === 'settings.logout_timeout'
    );

    return logoutPeriodPreference?.value as number;
  }

  treatLoginResponse(authData: {
    _id: string;
    payload: { aud: string; jwt: string };
  }): ng.IPromise<UserProfile> {
    return this.$q
      .resolve(this.clearDbIfNewUser(authData))
      .then(() => {
        this.apiUrlService.setApiUrl(authData.payload.aud);

        return this.constructUserDatas(authData);
      })
      .then(this.profileService.updateProfile)
      .then((profile) => {
        this.loggedIn = true;

        return this.organisationsService
          .getRemote((profile as UserProfile).contents.organisation_id)
          .then((org) => {
            this.registerAutomaticLogoutListener(org);
            return profile;
          });
      })
      .finally(() => {
        this.loggedIn = true;
      });
  }

  constructUserDatas(authData): ng.IPromise<unknown> {
    // API v1 and API v0 are slightly different
    // in v1, jwt is inside payload
    const jwt = authData.jwt
      ? authData.jwt
      : authData.payload
      ? authData.payload.jwt
      : null;
    const tokens = {
      jwt,
    };
    const headers = {
      Authorization: this.profileService.getAuthorizationHeader({
        contents: tokens,
      }),
    };

    return this.profileService
      .getApiProfile(authData._id, {
        headers,
      })
      .then((user) => this.setUserTokens(user, tokens));
  }

  // on old browsers (Chrome 66/Android 7/8) due to unknown reasons this method doesn't exist at all or context is lost if it's not an arrow method
  clearDbIfNewUser = (authData: { _id: string }): ng.IPromise<unknown> =>
    this.profileService
      .getFirstProfile()
      .then((profile) =>
        profile && authData._id !== profile._id
          ? this.dataStoreService.resetDataStores()
          : this.$q.when()
      )
      .then(() => authData);

  clearAuthMemory(): ng.IPromise<void[]> {
    this.auth0User = undefined;
    this.auth0AccessToken = undefined;
    this.$rootScope.jwt = undefined;
    this.org_target = '';
    return this.$q.all([
      this.clearProfileTokens(),
      this.appStateService.resetProfileServices(),
      this.routerService.disableNextBack(),
      this.appStateService.resetViews(),
      this.simpleStorageService.remove('token'),
      this.simpleStorageService.remove('user'),
      this.simpleStorageService.remove('org_target'),
    ]);
  }

  /**
   * Logout user
   * @params {Object} [options]
   * @params {Boolean} options.removeDevice - Should the device be removed from the user's
   * device array in DB
   * @return {Promise} Promise of a boolean that returns true if ok
   */
  logout(options = { removeDevice: true }): ng.IPromise<unknown> {
    this.$log.debug('Logging out');
    this.logoutPending = true;
    // Cancel all the requests
    this.$http.pendingRequests = this.$http.pendingRequests.map(
      (req: IRequestConfig & { cancel?: ng.IDeferred<void> }) => {
        if (req.cancel) {
          req.cancel.resolve();
        }
        return req;
      }
    );
    // Delete current token from DB
    return (
      options.removeDevice
        ? this.userDevicesService.deleteMobileDevice()
        : this.$q.resolve()
    )
      .finally(() => this.clearAuthMemory())
      .catch((e) => this.$log.error(e))
      .finally(() => this.auth0Logout())
      .catch((e) => this.$log.error(e))
      .finally(() => {
        this.logoutPending = false;
        this.loggedIn = false;
        if (this.stateChangeStartListener) {
          this.stateChangeStartListener();
          this.stateChangeStartListener = undefined;
        }
        return this.$state.go('login.page');
      });
  }

  clearProfileTokens(): ng.IPromise<unknown> {
    return this.profileService
      .getFirstProfile()
      .then((profile) =>
        getContents(profile)
          ? this.profileService.updateProfile(
              this.setUserTokens(profile, { jwt: null })
            )
          : null
      );
  }

  /**
   * Ask a new password
   * @param  {String} email - Email of applicant
   * @return {Promise}       - Request response
   */
  resetPassword(email: string): ng.IPromise<unknown> {
    const resetUrl = `${this.apiUrlService.getAuthUrl()}/password/reset`;

    return this.$http.post(resetUrl, { email: email }).then((res) => res.data);
  }

  // ------------------
  //
  //  HELPERS Methods
  //
  // ------------------
  // return methods;

  private setUserTokens(profileToAssign, tokens) {
    profileToAssign.contents.jwt = tokens.jwt;
    return profileToAssign;
  }
}

function getFullName(profile: UserProfile): string {
  return `${profile?.contents?.firstName} ${profile?.contents?.lastName}`;
}
