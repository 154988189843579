import { SfFeatureFlags } from '@simplifield/feature-flags';
import { CalendarEventsModalService } from 'app/calendar-events/services/calendar-events-modal/calendar-events-modal.service';
import { CalendarEvent } from 'app/calendar-events/types';
import { ObjectId, User } from '../../../..';
import { FEATURE_FLAGS } from '../../../../constants/feature-flags.constant';
import { APIStore } from '../../../../places';
import { Form, FormQuestion } from '../../../../services/API/forms/forms';
import { Report } from '../../../../services/API/reports/reports';
import { TasksService } from '../../../../services/API/tasks/tasks.service';
import { TaskService } from '../../../../tasks/services/task.service';
import { FormTasksService } from '../../services/form-tasks.service';

const EDITABLE_INPUTS = ['questionRadio', 'questionCheckbox'];
const RESETABLE_INPUTS = [
  'questionRadio',
  'questionCheckbox',
  'rating',
  'productSales',
  'productCount',
];
const MAX_DISPLAY_CHOICES = 7;

export const FormQuestionActionsComponent = {
  bindings: {
    question: '=',
    form: '<',
    report: '<',
    profile: '<',
    onActionClicked: '&',
    onCalendarEventCreated: '&?',
    place: '<',
    answers: '<',
    unconfirmedPrefilledQuestions: '=',
    canAnswerBeResetToPrevious: '<',
    useNewCampaigns: '<',
  },
  templateUrl:
    'missions/form/components/form-question-actions/form-question-actions.html',
  controller: class FormQuestionActionsController {
    // bindings
    question: FormQuestion;
    form: Form;
    report: Report;
    profile: User;
    place: APIStore;
    unconfirmedPrefilledQuestions: { ids: ObjectId[] };
    canAnswerBeResetToPrevious: boolean;
    useNewCampaigns: boolean;
    onActionClicked: (arg: { action: string }) => void;
    onCalendarEventCreated: (arg: { event: CalendarEvent }) => void;

    // members
    MAX_DISPLAY_CHOICES = MAX_DISPLAY_CHOICES;
    EDITABLE_INPUTS = EDITABLE_INPUTS;
    RESETABLE_INPUTS = RESETABLE_INPUTS;

    hasTasksFeature: boolean;
    hasEventFeature: boolean;
    offlineMode: boolean;
    questionType: string;
    actionSheetActions: { text: any; onClick: () => void }[];
    answers: any;

    constructor(
      private taskService: TaskService,
      private calendarEventsModalService: CalendarEventsModalService,
      private tasksService: ReturnType<typeof TasksService>,
      private reportQuestionsService,
      private formService,
      private keyboardService,
      private FORM_QUESTION_ACTIONS,
      private $timeout: ng.ITimeoutService,
      private $translate: ng.translate.ITranslateService,
      private formTasksService: FormTasksService,
      private sfFeatureFlagsService: SfFeatureFlags,
      private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
    ) {
      'ngInject';
    }

    $onInit() {
      this.hasTasksFeature = this.tasksService.hasFeatureFlag();
      this.hasEventFeature = this.sfFeatureFlagsService.hasFeature(
        this.SF_FEATURE_FLAGS.CALENDAR_EVENTS
      );

      this.offlineMode = true;
      this.questionType = this.reportQuestionsService.getQuestionTypeName(
        this.question,
        this.form,
        this.report
      );

      this.actionSheetActions = this.getActions();
    }

    $onChanges(changes) {
      if (changes.answers) {
        this.actionSheetActions = this.getActions();
      }
    }

    getActions() {
      return [
        ...(this.hasTasksFeature
          ? [
              {
                text: this.$translate.instant('FORM_QUESTION_ACTIONS_ADD_TASK'),
                onClick: () => this.openTaskManageModal(),
              },
            ]
          : []),
        ...(this.hasTasksFeature
          ? [
              {
                text: this.$translate.instant(
                  'FORM_QUESTION_ACTIONS_ADD_EVENT'
                ),
                onClick: () => this.openEventManagementModal(),
              },
            ]
          : []),
        ...(this.showEditButton()
          ? [
              {
                text: this.$translate.instant(
                  'FORM_QUESTION_ACTIONS_EDIT_ANSWER'
                ),
                onClick: () => this.editAnswer(),
              },
            ]
          : []),
        ...(this.showResetButton()
          ? [
              {
                text: this.$translate.instant(
                  'FORM_QUESTION_ACTIONS_RESET_ANSWER'
                ),
                onClick: () => this.resetAnswer(),
              },
            ]
          : []),
        ...(this.canAnswerBeResetToPrevious
          ? [
              {
                text: this.$translate.instant(
                  this.useNewCampaigns
                    ? 'FORM_QUESTION_ACTIONS_RESET_PREFILL_ANSWER_CHECKLISTS'
                    : 'FORM_QUESTION_ACTIONS_RESET_PREFILL_ANSWER'
                ),
                onClick: () => this.resetPrefillAnswer(),
              },
            ]
          : []),
      ];
    }

    openTaskManageModal() {
      this.$timeout(() =>
        this.taskService
          .openTaskManageModal(this.getTaskObject(), this.profile, {}, false)
          .then(
            (task) => this.formTasksService.addTask(task.managedTask),
            () => {} // close without creation reject
          )
      );
    }

    openEventManagementModal() {
      this.$timeout(() =>
        this.calendarEventsModalService
          .openEventManagementModal(
            this.place,
            new Date(),
            (event: CalendarEvent) => {
              this.onCalendarEventCreated({ event });
            }
          )
          .then(
            () => {},
            () => {} // close without creation reject
          )
      );
    }

    resetAnswer() {
      this.onActionClicked({ action: this.FORM_QUESTION_ACTIONS.RESET });
    }

    editAnswer() {
      this.onActionClicked({ action: this.FORM_QUESTION_ACTIONS.EDIT });
    }

    resetPrefillAnswer() {
      this.onActionClicked({
        action: this.FORM_QUESTION_ACTIONS.RESET_PREFILL,
      });
      this.unconfirmedPrefilledQuestions.ids =
        this.unconfirmedPrefilledQuestions.ids.concat(this.question._id);
    }

    showResetButton() {
      return (
        this.answers?.length &&
        this.RESETABLE_INPUTS.indexOf(this.questionType) !== -1
      );
    }

    showEditButton() {
      return this.answers?.length && this.isEditable();
    }

    isEditable() {
      if (this.EDITABLE_INPUTS.indexOf(this.questionType) === -1) {
        return false;
      }
      return (
        this.formService.getQuestionChoices(this.question).length >
        this.MAX_DISPLAY_CHOICES
      );
    }

    getTaskObject() {
      this.place = this.place || {};
      return this.taskService.getQuestionTaskObject(
        this.question,
        this.form,
        this.report,
        this.place
      );
    }

    onButtonClick() {
      this.keyboardService.hide();
    }

    show() {
      return (
        this.hasTasksFeature ||
        this.hasEventFeature ||
        this.showResetButton() ||
        this.showEditButton()
      );
    }
  },
};
