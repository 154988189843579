import { DateService } from '@services/Utils/Dates/date.service';
import { ObjectId } from '../../..';
import { Category } from '../../../categories';
import { ButtonSelectorOption } from '../../../components/Buttons/button-selector/button-selector.controller';
import { Targeting } from '../../../targeting/types';
import { NewsfeedService } from '../../services/newsfeed-service/newsfeed.service';
import { PostContents } from '../../types';

export class NewsfeedPostFormOptionsController {
  onSavePost: (value: { isDraft: boolean }) => ng.IPromise<void>;
  onSelectCategory: (value: { option: ButtonSelectorOption }) => void;
  onTargetingUpdated: (value: { targeting: Targeting | null }) => void;
  onPublishDateUpdated: (value: { date: string | null }) => void;
  newsfeedPost: Partial<PostContents>;
  postDefaultCategory: ButtonSelectorOption | null;
  newsfeedCategories: ButtonSelectorOption[] = [];
  newsfeedPostFormOptions: ng.IFormController;
  isPostBtnDisplayed = true;
  postId: ObjectId | null;
  publishDate = '';
  publishTime;
  isPublished;
  publishDateFormatted = '';

  constructor(
    private $translate: ng.translate.ITranslateService,
    private newsfeedService: NewsfeedService,
    private $scope: ng.IScope,
    private formValidationsService,
    private dateService: DateService,
    private $timeout: ng.ITimeoutService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.loadCategories();
    this.initWatchPristineState();

    this.$timeout(() => {
      if (this.newsfeedPost.publication_date) {
        this.publishDate = this.newsfeedPost.publication_date;
        this.publishTime = this.dateService.getTimeInMilliseconds(
          new Date(this.publishDate)
        );
        this.getFormattedDate();
        this.checkDateValidity();
        this.newsfeedPostFormOptions.publishDate.$dirty = true;
      }
    }, 0);
  }

  savePost(isDraft = false): void {
    this.onSavePost({ isDraft });
  }

  onDateChange(): void {
    if (!this.publishDate) {
      this.newsfeedPostFormOptions.publishDate.$setValidity('date', true);
      this.newsfeedPostFormOptions.publishDate.$setValidity(
        'publishDate',
        true
      );
      return this.onPublishDateUpdated({ date: null });
    }

    this.getFormattedDate();
    this.checkDateValidity();

    this.onPublishDateUpdated({ date: this.publishDateFormatted });
  }

  getFormattedDate(): void {
    const date = this.dateService.getStartOfDate(this.publishDate);

    this.publishDateFormatted = new Date(
      date.getTime() + (this.publishTime ?? 0)
    ).toISOString();
  }

  hasError(fieldName: string): boolean {
    return this.formValidationsService.hasToDisplay(
      this.newsfeedPostFormOptions,
      fieldName
    );
  }

  getError(fieldName: string): string {
    return this.formValidationsService.getInputError(
      this.newsfeedPostFormOptions,
      fieldName
    );
  }

  loadCategories(): ng.IPromise<void> {
    return this.newsfeedService.categoriesService
      .getCategories()
      .then((categories: Category[]): void => {
        this.newsfeedCategories = [
          {
            id: null as unknown as ObjectId, // this is a special case so unknown is ok
            label: this.$translate.instant('NEWSFEED_EMPTY_CATEGORY'),
          },
          ...categories.map(({ _id, name }) => ({
            id: _id,
            label: name,
          })),
        ];

        this.postDefaultCategory = this.newsfeedPost.category_id
          ? this.newsfeedCategories.find(
              ({ id }) => id === this.newsfeedPost.category_id
            ) ?? null
          : null;
      })
      .catch((err) => {
        this.newsfeedCategories = [];
        throw err;
      });
  }

  checkDateValidity(): void {
    const isValid = this.dateService.isAfter(
      new Date(this.publishDateFormatted)
    );

    this.newsfeedPostFormOptions.publishDate.$setValidity(
      'publishDate',
      isValid
    );
  }

  selectCategory(value: { option: ButtonSelectorOption }): void {
    this.unsetFormPristine();
    this.isPostBtnDisplayed = true;
    return this.onSelectCategory(value);
  }

  updateTargeting(targeting): void {
    this.onTargetingUpdated({
      targeting: targeting
        ? {
            links: targeting.targeting_ids.map((_id) => ({
              type: targeting.type,
              _id,
            })),
          }
        : null,
    });
  }

  startSelectCategories(): void {
    this.isPostBtnDisplayed = false;
  }

  cancelSelectCategories(): void {
    this.isPostBtnDisplayed = true;
  }

  private initWatchPristineState(): void {
    this.$scope.$watch(
      () =>
        this.newsfeedPostFormOptions
          ? this.newsfeedPostFormOptions.$pristine
          : false,
      () => {
        if (
          this.newsfeedPostFormOptions &&
          !this.newsfeedPostFormOptions.$pristine
        ) {
          this.$scope.$emit('newsfeedPostFormTouched');
        }
      }
    );
  }

  private unsetFormPristine(): void {
    if (this.newsfeedPostFormOptions) {
      this.newsfeedPostFormOptions.$pristine = false;
    }
  }
}
