import { forms as formsSchema } from '@simplifield/schema';
import { StateParams } from '@uirouter/angularjs';
import {
  Campaign,
  CampaignForm,
} from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';
import { TabService } from '../../../services/Utils/Tabs';

const PARALLAX_HEIGHT = 200;

export const ReactiveCampaignActivityDetailsComponent: ng.IComponentOptions = {
  bindings: {
    campaign: '<',
  },
  templateUrl:
    'reactive-campaigns/components/reactive-campaign-activity-details/reactive-campaign-activity-details.html',
  controller: class ReactiveCampaignActivityDetailsController
    implements ng.IController
  {
    /** Bindings */
    campaign: Campaign;

    /** Fields */
    coverImageUrl = 'img/background_medium.png';
    currentStickyHandle: string;
    formsSchema: {
      hasScore: (form: CampaignForm) => boolean;
    };
    galleryListFilter: { name: string; value: string }[];
    hideOverview: boolean;
    parallaxHeight = PARALLAX_HEIGHT;

    constructor(
      private $q: ng.IQService,
      private $timeout: ng.ITimeoutService,
      private campaignsService: CampaignsService,
      private tabsService: TabService,
      private $stateParams: StateParams
    ) {
      'ngInject';

      this.formsSchema = formsSchema;
      this.parallaxHeight = PARALLAX_HEIGHT;
      this.coverImageUrl = 'img/background_medium.png';
    }

    $onInit(): ng.IPromise<void> {
      this.hideOverview = !this.formsSchema.hasScore(
        this.campaign.contents.form
      );
      this.galleryListFilter = [
        {
          name: 'reactive_campaign_name',
          value: this.campaign.contents.name,
        },
      ];

      if (!this.campaign.contents.picture) {
        return this.$q.resolve();
      }

      return this.campaignsService
        .getCampaignCoverUrl(this.campaign, 'medium')
        .then((url) => {
          this.coverImageUrl = url;
        });
    }

    $postLink(): void {
      if (this.hideOverview) {
        this.$timeout(() => this.tabsService.select('campaignTabs', 1), 0);
      }

      if (this.$stateParams.targetTab === 'gallery') {
        this.$timeout(() => this.tabsService.select('campaignTabs', 3), 0);
      }
    }

    onTabClick(tabName: string): void {
      this.currentStickyHandle = tabName;
    }
  },
};
