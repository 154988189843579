import { TasksFiltersModalController } from './tasks-filters-modal.controller';

export const TasksFiltersModalComponent = {
  bindings: {
    profile: '<',
    values: '<',
    filtersAvailable: '<',
    onChange: '&',
    onClose: '&',
  },
  templateUrl: 'tasks/components/tasks-filters-modal/tasks-filters-modal.html',
  controller: TasksFiltersModalController,
};
