export const MerchandisingStateTagComponent = {
  bindings: {
    state: '@',
    isLight: '<',
  },
  templateUrl:
    'merchandising/components/merchandising-state-tag/merchandising-state-tag.html',
  controller: function MerchandisingStateTagController(
    SF_MERCHANDISING_STATES
  ) {
    'ngInject';
    const STATE_MAP = {
      [SF_MERCHANDISING_STATES.VALIDATED]: {
        color: 'balanced',
        translationKey: 'MERCHANDISING_PHOTO_SLIDER_STATE_VALIDATED',
      },
      [SF_MERCHANDISING_STATES.REJECTED]: {
        color: 'assertive',
        translationKey: 'MERCHANDISING_PHOTO_SLIDER_STATE_REJECTED',
      },
      [SF_MERCHANDISING_STATES.PENDING]: {
        color: 'energized',
        translationKey: 'MERCHANDISING_PHOTO_SLIDER_STATE_PENDING',
      },
    };

    this.$onInit = () => {
      const stateConfig = getStateConfig(STATE_MAP, this.state);
      const light = this.isLight ? '--light' : '';

      this.color = stateConfig.color;
      this.chipColor = `chip-${stateConfig.color}${light}`;
      this.stateTranslationKey = stateConfig.translationKey;
    };

    function getStateConfig(stateMap, state) {
      return state && stateMap && stateMap[state]
        ? stateMap[state]
        : { color: '', translationKey: '' };
    }
  },
};
