import { PovService } from '../../API/POV/pov.service';
import { ObjectId } from '../../..';

type AssetUrls = {
  base: { videoUrl: string; posterUrl: string };
  landscape: { videoUrl: string; posterUrl: string };
};

export class DigitalAssetsService {
  baseUrl = '/digitalAssets/';

  constructor(
    private sfPOVService: PovService,
    private $http: ng.IHttpService
  ) {
    'ngInject';
  }

  getVideoAndPosterUrl(videoId: string): ng.IPromise<{
    video: { transformed: string; base: string };
    poster: { transformed: string; base: string };
  }> {
    return this.getUrl(videoId, { pov: 'organisation' })
      .then((url) => this.$http.get<{ urls: AssetUrls }>(url))
      .then(({ data: { urls } }) => ({
        video: {
          transformed: urls.landscape.videoUrl,
          base: urls.base.videoUrl,
        },
        poster: {
          transformed: urls.landscape.posterUrl,
          base: urls.base.posterUrl,
        },
      }));
  }

  getUrl(videoId: ObjectId, config?: { pov: string }): ng.IPromise<string> {
    return this.sfPOVService.pBuildURL(this.baseUrl + videoId, config);
  }
}
