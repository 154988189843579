export const QuestionProductScanComponent = {
  bindings: {
    question: '<',
    hasError: '<',
    questionOptions: '<',
    answers: '=',
    isDisabled: '<?',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-product-scan/question-product-scan.html',
  controller: function QuestionProductScanController(
    $translate,
    productsService,
    popupService
  ) {
    'ngInject';

    this.$onInit = () => {
      const answersEanUnique = this.answers
        .map((eanAnswer) => eanAnswer.values[0].value)
        .filter((value, index, arr) => index === arr.indexOf(value));

      this.error = false;
      this.productsHash = {};

      return this.getProductsHash(answersEanUnique);
    };

    this.getProductsHash = (eanValues) =>
      productsService.getByEans(eanValues).then((products = []) => {
        this.productsHash = products.reduce((hash, product) => {
          hash[product.contents.ean] = product;
          return hash;
        }, {});
        return products;
      });

    this.addProductToHash = (ean, product) => {
      this.productsHash[ean] = this.productsHash[ean] || product;
      return this.productsHash[ean];
    };

    this.deleteAnswer = (answer) => {
      this.answers = this.sfQuestionForm.removeAnswer(answer._id, this.answers);
      this.setFormDirty();
      return this.answers;
    };

    this.addScanAnswer = (ean) =>
      ean
        ? productsService.getByEans([ean]).then((product) => {
            if (!product) {
              return this.showUnknownEanPopup(ean);
            }

            const fieldEan = this.question.fields.filter(
              (field) => field.type === 'ean'
            )[0];
            const fieldDiscount = this.getDiscountField();
            let values = [
              {
                field_id: fieldEan._id,
                value: ean,
              },
            ];

            if (fieldDiscount) {
              values = values.concat({
                field_id: fieldDiscount._id,
                value: 0,
              });
            }

            const answers = this.sfQuestionForm.addAnswer(values, this.answers);

            this.answers = [].concat(answers.slice(-1), answers.slice(0, -1));

            this.setFormDirty();

            return this.addProductToHash(ean, product);
          })
        : null;

    this.showUnknownEanPopup = () =>
      popupService.showError({
        title: $translate.instant('TASK_SCAN_EAN_ERROR_TITLE'),
        desc: $translate.instant('TASK_SCAN_EAN_ERROR_DESC'),
      });

    this.getDiscountField = () => {
      const fieldDiscount = this.question.fields.filter(
        (field) => field.type === 'number'
      )[0];

      return fieldDiscount;
    };

    this.setFormDirty = () => {
      const scanForm = this[`product_scan_${this.question._id}`];

      return scanForm ? scanForm.$setDirty(true) : false;
    };

    this.getProductByAnswer = (answer) =>
      this.productsHash[answer.values[0].value];
  },
};
