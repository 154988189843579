export const ReportStatusComponent = {
  bindings: {
    campaignStatus: '<',
    reportState: '<',
    translations: '<',
    color: '@?',
    onClick: '&?',
  },
  templateUrl: 'reports/components/report-status/report-status.html',
  controller: function ReportStatusController() {
    'ngInject';
    const getStatusMap = (statusMap, reportStatus) =>
      statusMap.filter((status) => status.key === reportStatus)[0];

    this.$onInit = () => {
      this.showStatusLabel = true;
      this.statusLabel = this.getReportStatusLabel();
      if (this.statusLabel == 'draft') {
        this.showStatusLabel = false;
      }
    };
    this.$onChanges = () => {
      this.statusLabel = this.getReportStatusLabel();
    };
    this.getReportStatusLabel = () => {
      return (
        (getStatusMap(this.campaignStatus, this.reportState) || {}).label ||
        this.reportState
      );
    };
  },
};
