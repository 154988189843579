import type { ResourcePreview } from '../../newsfeed/types';
import type { FilesService } from '../../services/API/files/files.service';
import type { DocumentsDownloadService } from '../../services/Utils/DocumentsDownload/documents-download.service';
import type { FileSystemService } from '../../services/Utils/FileSystem/file-system.service';
import type {
  ImageSource,
  ImageSourceService,
} from '../../services/Utils/ImageSource/image-source.service';
import type { PlatformService } from '../../services/Utils/Platform';

export class NamedResourceChipController implements ng.IComponentController {
  onNameClick?: () => void;
  onDelete?: () => void;
  document: ResourcePreview & { _id?: string };
  isDownloaded = false;
  loadingError = false;
  filePath: string;
  extension: string;
  FOLDER_PATH = 'newsfeed/files';

  // eslint-disable-next-line max-params
  constructor(
    private $translate: ng.translate.ITranslateService,
    private $window: ng.IWindowService,
    private documentViewerService,
    private documentDownloadService: DocumentsDownloadService,
    private filesService: FilesService,
    private filesSystemService: FileSystemService,
    private imageSourceService: ImageSourceService,
    private platformService: PlatformService
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> | null {
    if (!this.platformService.isBrowser()) {
      this.extension = this.document.name.split('.').pop() || 'pdf';
      const docId = this.document?.id || this.document?._id;

      return this.filesSystemService
        .computeFileDataPath(this.FOLDER_PATH, docId, this.extension)
        .then((filePath) => {
          this.filePath = filePath;

          return this.filesSystemService
            .checkFile(this.filePath)
            .then((result: boolean) => {
              this.isDownloaded = result;
            })
            .catch(() => {
              this.isDownloaded = false;
            });
        });
    }
    return null;
  }

  onDocumentDelete($event): void {
    $event.stopImmediatePropagation();
    if (this.onDelete) {
      this.onDelete();
    }
  }

  onClick($event): ng.IPromise<void> | void {
    $event.stopImmediatePropagation();

    if (this.onNameClick) {
      return this.onNameClick();
    }

    if (this.isDownloaded) {
      return this.openDocumentOnMobile();
    }

    const document = this.imageSourceService.create(
      {
        id: this.document.id,
        type: this.document.mimeType,
      },
      true
    );

    if (this.platformService.isBrowser()) {
      return this.openDocumentInBrowser(document);
    }

    return this.downloadFile(document);
  }

  openDocumentInBrowser(document: ImageSource): ng.IPromise<void> {
    return this.filesService
      .getFileBlob(document)
      .then((fileBlob) => {
        const tmpFileUrl = this.$window.URL.createObjectURL(fileBlob);

        this.$window.open(tmpFileUrl, '_system');
      })
      .catch(() => undefined); // prevent sentry polluting
  }

  openDocumentOnMobile(filePath?: string): ng.IPromise<void> {
    const finaleFilePath = filePath || this.filePath;

    return this.documentViewerService.openDocument(finaleFilePath);
  }

  downloadFile(document: ImageSource): ng.IPromise<void> {
    this.loadingError = false;
    const fileOptions = {
      directory: this.filesSystemService.getDeviceFilePath(),
      path: this.FOLDER_PATH,
      name: `${this.document.id}.${this.extension}`,
    };

    return this.documentDownloadService
      .download(fileOptions, document, {
        isPopupsShow: true,
        onSuccessMessage: this.$translate.instant(
          'DOCUMENT_CHIP_DOWNLOAD_SUCCESS'
        ),
      })
      .then((path: string) => {
        this.isDownloaded = true;
        return this.openDocumentOnMobile(path);
      })
      .catch((err) => {
        this.loadingError = true;
        throw err;
      });
  }
}
