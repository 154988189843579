const CONTENT_SCROLL_HANDLE_NAME = 'sfContentScroll';

export class ContentService {
  constructor(private $ionicScrollDelegate: IonicV1.IonicScrollService) {
    'ngInject';
  }

  getScroll(contentName: string): IonicV1.IonicContent | null {
    return this.$ionicScrollDelegate.$getByHandle(contentName);
  }
  scrollTop(contentName: string): void {
    const content = this.getScroll(contentName);

    if (!content) {
      return;
    }
    content.scrollTop();
  }

  getScrollsById(key: string): IonicV1.IonicContent[] {
    return this.$ionicScrollDelegate._instances.filter(
      (instance) =>
        instance.$$delegateHandle === CONTENT_SCROLL_HANDLE_NAME &&
        instance.$element[0].id === key
    );
  }
  scrollTopById(key: string, value = 0): void {
    this.getScrollsById(key).forEach((instance): void => {
      instance.scrollTo(0, value, true);
    });
  }
  scrollBottomById(key: string, value = 0): void {
    this.getScrollsById(key).forEach((instance) => {
      value ? instance.scrollTo(value, 0, true) : instance.scrollBottom();
    });
  }
  resizeById(key: string): void {
    this.getScrollsById(key).forEach((instance): void => {
      instance.resize(true);
    });
  }
  anchorScrollById(key: string): void {
    this.getScrollsById(key).forEach((instance): void => {
      instance.anchorScroll(true);
    });
  }
}
