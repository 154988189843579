import { DocumentsDownloadService } from './documents-download.service';

export default angular
  .module('sfMobile.services.documents-download', [
    'pascalprecht.translate',
    'sfMobile.services.files',
    'sfMobile.services.popup',
    'sfMobile.services.awake',
    'sfMobile.services.error-messages',
    'sfMobile.constants.error-codes',
  ])
  .service('documentDownloadService', DocumentsDownloadService).name;
