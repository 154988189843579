export const ReportAnswersComponent: ng.IComponentOptions = {
  bindings: {
    reportId: '<',
    question: '<',
    answers: '<',
    form: '<',
  },
  templateUrl:
    'reports/report/components/report-content/components/report-answers/report-answers.html',
  // eslint-disable-next-line max-params
  controller: class ReportAnswersController implements ng.IController {
    answers;
    form;
    reportId;
    question;
    formFilesPath;
    photoPathHash;
    documentAnswers = [];
    constructor(
      private $q,
      private imageService,
      private filesSystemService,
      private modalService,
      private dateFormatService,
      private dateService,
      private reportQuestionsService,
      private SF_IMAGE_SIZES,
      private SF_QUESTION_PRODUCT_TYPES,
      private imageSourceService,
      private formsService,
      private $translate: ng.translate.ITranslateService
    ) {
      'ngInject';
    }

    $onInit(): void {
      const answers = this.answers || [];
      const answersPicture = answers.filter((answer) =>
        this.isImageDisplay(answer.type)
      );

      if (this.form) {
        this.formFilesPath = this.formsService.getFilesPath(this.form._id);
      }
      this.documentAnswers = this.answers
        .filter((answer) => this.isDocumentDisplay(answer.type))
        .map((answer) => this.getDocument(answer));
      const getPicture = (answer) => {
        const pictureId = this.getValue(answer, 0);

        return answer.temp
          ? this.$q.when(
              this.filesSystemService.computeFileDataPath(
                this.reportId,
                pictureId
              )
            )
          : this.imageService.getSizedUrlFromId(
              pictureId,
              this.SF_IMAGE_SIZES.RECTANGLE_MEDIUM
            );
      };

      this.photoPathHash = {};

      return this.$q.all(
        answersPicture.map((answerPicture) =>
          getPicture(answerPicture).then((path) => {
            const pictureId = this.getValue(answerPicture, 0);

            this.photoPathHash[pictureId] = path;
          })
        )
      );
    }

    hasAnswers() {
      return this.answers && this.answers.length;
    }
    getValue(answer, valueIndex, naAllowed = false) {
      if (naAllowed) {
        return answer.values[valueIndex || 0].value ||
          answer.values[valueIndex || 0].value === 0
          ? answer.values[valueIndex || 0].value
          : this.$translate.instant('ABBR_NOT_APPLICABLE');
      }
      return answer.values[valueIndex || 0].value;
    }

    getTimeValue(answer) {
      return this.dateService.convertToUTCDate(answer.values[0].value);
    }

    getProductQuestionMode(question) {
      return question.type === 'product-sales'
        ? this.SF_QUESTION_PRODUCT_TYPES.SALES
        : this.SF_QUESTION_PRODUCT_TYPES.COUNT;
    }

    getUnit(answer) {
      const unitField = this.question.fields
        .slice(1)
        .filter((field) => field.type !== 'score')[0];
      const unitValue = unitField
        ? answer.values.filter((value) => unitField._id === value.field_id)[0]
        : null;

      return unitValue ? unitValue.value : null;
    }
    getImage(answer) {
      return this.photoPathHash[this.getPictureId(answer)];
    }

    getDocument(answer) {
      return {
        _id: answer.values[0].value,
        name: answer.values[1].value,
        mime_type: answer.values[2].value,
        size: answer.values[3].value,
      };
    }
    viewPicture(answer) {
      const template = `
        <sf-images-viewer
          images="[$ctrl.image]"
          on-close="$ctrl.onClose()">
        </sf-images-viewer>
      `;
      const bindings = {
        image: this.imageSourceService.create(
          {
            url: this.getImage(answer),
            id: this.getPictureId(answer),
          },
          false
        ),
      };

      return this.modalService.open(template, bindings);
    }
    getPictureId(answer) {
      return answer.values[0].value;
    }

    isImageDisplay(type) {
      return ['image', 'signature'].indexOf(type) !== -1;
    }

    isDocumentDisplay(type) {
      return ['document'].indexOf(type) !== -1;
    }

    getDateFormatted(date) {
      return this.dateFormatService.getDateFormatted(date);
    }

    isProductQuestion(question) {
      return this.reportQuestionsService.isProductQuestion(question);
    }
  },
};
