import AvatarSkeletonModule from './avatar-skeleton/avatar-skeleton.module';
import ItemAvatarSkeletonModule from './item-avatar-skeleton/item-avatar-skeleton.module';
import ItemSkeletonModule from './item-skeleton/item-skeleton.module';
import ListImagesSkeletonModule from './list-images-skeleton/list-images-skeleton.module';
import ListItemsSkeletonModule from './list-items-skeleton/list-items-skeleton.module';
import ListTasksSkeletonModule from './list-tasks-skeleton/list-tasks-skeleton.module';
import SearchSkeletonModule from './search-skeleton/search-skeleton.module';
import SectionSkeletonModule from './section-skeleton/section-skeleton.module';
import SkeletonDisplayModule from './skeleton-display/skeleton-display.module';
import TaskItemSkeletonModule from './task-item-skeleton/task-item-skeleton.module';
import TextSkeletonModule from './text-skeleton/text-skeleton.module';
import TitleSkeletonModule from './title-skeleton/title-skeleton.module';

export default angular.module('sfMobile.components.skeletons', [
  AvatarSkeletonModule,
  ItemAvatarSkeletonModule,
  ItemSkeletonModule,
  ListImagesSkeletonModule,
  ListItemsSkeletonModule,
  ListTasksSkeletonModule,
  SearchSkeletonModule,
  SectionSkeletonModule,
  SkeletonDisplayModule,
  TaskItemSkeletonModule,
  TextSkeletonModule,
  TitleSkeletonModule,
]).name;
