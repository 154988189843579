import { ObjectId } from '../../..';
import { PovService } from '../POV/pov.service';
import { EntityList, Dashboard, Metric, MetricValue, Query } from './types';

export class DashboardsApiService {
  private dashboardsUrl = '/dashboards';
  private metricsUrl = '/metrics';
  constructor(
    private $http: ng.IHttpService,
    private sfPOVService: PovService
  ) {
    'ngInject';
  }

  getDashboards(params = {}): ng.IPromise<EntityList<Dashboard>> {
    return this.sfPOVService
      .pBuildURL(this.dashboardsUrl, { pov: 'organisation' })
      .then((uri: string) =>
        this.$http.get<EntityList<Dashboard>>(uri, { params })
      )
      .then((res) => res.data);
  }

  getDashboard(dashboardId: ObjectId): ng.IPromise<Dashboard> {
    return this.sfPOVService
      .pBuildURL(this.dashboardsUrl + `/${dashboardId}`, {
        pov: 'organisation',
      })
      .then((uri: string) => this.$http.get<Dashboard>(uri))
      .then((res) => res.data);
  }

  getMetricValues(
    metricId: string,
    query: Query,
    params = {}
  ): ng.IPromise<MetricValue> {
    const metricUrl = this.metricsUrl + `/${metricId}/values`;

    return this.sfPOVService
      .pBuildURL(metricUrl, { pov: 'organisation' })
      .then((uri: string) =>
        this.$http.post<MetricValue>(uri, JSON.stringify(query), { params })
      )
      .then((res) => res.data);
  }

  getMetric(metricId: string, params = {}): ng.IPromise<Metric> {
    const metricUrl = this.metricsUrl + `/${metricId}`;

    return this.sfPOVService
      .pBuildURL(metricUrl, { pov: 'organisation' })
      .then((uri: string) => this.$http.get<Metric>(uri, { params }))
      .then((res) => res.data);
  }
}
