import { pipe, omit } from 'ramda';
import { Options } from 'rrule/dist/esm/src/types';
import RRule, { Weekday } from 'rrule';
import { DateService } from '../../../services/Utils/Dates/date.service';

export enum MonthRecurrenceTypeNames {
  BYMONTHDAY = 'bymonthday',
  BYNWEEKDAY = 'bynweekday',
}
export type MonthRecurrenceType =
  | MonthRecurrenceTypeNames.BYMONTHDAY
  | MonthRecurrenceTypeNames.BYNWEEKDAY;
export type MonthRecurrence = {
  label: string;
  create: (recurrence: Partial<Options>) => Partial<Options>;
};

export class RecurrenceService {
  /* @ngInject */
  constructor(
    private dateService: DateService,
    private $translate: ng.translate.ITranslateService
  ) {}

  getMinEndRecurrenceDate(recurrenceStartDate: Date): Date {
    return this.dateService.isPast(recurrenceStartDate)
      ? new Date()
      : recurrenceStartDate;
  }

  generateMonthRequrrenceOptions(
    recurrenceStartDate: Date
  ): Record<MonthRecurrenceType, MonthRecurrence> {
    const monthDayNumber: number = recurrenceStartDate.getDate();
    const weekDayNumber: number = this.getWeekDayNumber(recurrenceStartDate);
    const weekDays: string[] = this.getWeekdays();
    const weekNumber: number =
      this.dateService.getWeekNumberOfMonth(recurrenceStartDate);

    return {
      [MonthRecurrenceTypeNames.BYMONTHDAY]: {
        label: this.$translate.instant('RECURRENCE.MONTHLY_ON_DAY', {
          _n: monthDayNumber,
        }),
        create: pipe(
          omit(['byweekday', 'bynweekday']),
          (recurrence: Partial<Options>) => ({
            ...recurrence,
            [MonthRecurrenceTypeNames.BYMONTHDAY]: [monthDayNumber],
          })
        ),
      },
      [MonthRecurrenceTypeNames.BYNWEEKDAY]: {
        label: this.$translate.instant('RECURRENCE.MONTHLY_ON_WEEKDAY', {
          _n: weekNumber,
          weekday: weekDays[weekDayNumber],
        }),
        create: pipe(
          omit([MonthRecurrenceTypeNames.BYMONTHDAY]),
          (recurrence: Partial<Options>) => ({
            ...recurrence,
            byweekday: new Weekday(weekDayNumber).nth(weekNumber),
          })
        ),
      },
    };
  }

  // for RRule's 0 day = Monday
  getWeekdays(): string[] {
    const [su, mo, tu, we, th, fr, sa] = this.dateService.getWeekdays();

    return [mo, tu, we, th, fr, sa, su];
  }

  // RRules's Monday = 0...Sunday = 6
  getWeekDayNumber(date: Date): number {
    return this.dateService.getIsoWeekDayNumber(date) - 1;
  }

  changeRecurrenceStartDate(startDate: Date, recurrence: RRule): RRule {
    const options = recurrence.origOptions;

    if (options.freq !== RRule.MONTHLY) {
      // we have to recalculate for monthly recurrence only
      return new RRule({
        ...options,
        dtstart: startDate,
      });
    }

    return new RRule({
      ...options,
      ...(options.byweekday
        ? {
            byweekday: new Weekday(this.getWeekDayNumber(startDate)).nth(
              this.dateService.getWeekNumberOfMonth(startDate)
            ),
          }
        : { bymonthday: [startDate.getDate()] }),
      dtstart: startDate,
    });
  }
}
