import { Targeting } from '../../types';

export class ResourceTargetTypeController {
  targeting: Targeting;
  icon: string;

  constructor(private TARGETING_TYPES) {
    'ngInject';
  }

  $onInit(): void {
    const targetingType = this.targeting
      ? this.targeting.links[0]?.type ?? 'adminOnly'
      : null;

    this.icon = this.TARGETING_TYPES.find(
      ({ value }) => value === targetingType
    ).iconClass;
  }
}
