export const EventAssigneesComponent = {
  bindings: {
    assignees: '<',
  },
  templateUrl:
    'calendar-events/components/calendar/event-assignees/event-assignees.html',
  controller: class EventAssigneesController {
    constructor(imageService, SF_IMAGE_SIZES) {
      'ngInject';
      this.imageService = imageService;
      this.SF_IMAGE_SIZES = SF_IMAGE_SIZES;
    }

    $onInit() {
      const { _id, contents } = this.assignees[0];
      const { firstName, lastName } = contents;

      this.user = {
        _id,
        contents: { ...contents, firstName, lastName },
      };
      if (contents.avatar_id) {
        this.imageService
          .getSizedUrlFromId(
            contents.avatar_id,
            this.SF_IMAGE_SIZES.SQUARE_SMALL
          )
          .then((url) => {
            this.imageSrc = url;
            return url;
          });
      }
      this.restUsersLength = this.assignees.length - 1;
    }
  },
};
