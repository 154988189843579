import { PictureTagModel, PictureTagsResponseModel } from './tags';
import { ObjectId } from '../../../index';
import { FileMetadata } from '@services/API/files';

export class TagsService {
  constructor(private sfPOVService, private $http: ng.IHttpService) {
    'ngInject';
  }

  getTags(): ng.IPromise<PictureTagModel[]> {
    return this.sfPOVService
      .pBuildURL('/tags', {
        pov: 'organisation',
      })
      .then((url) => this.$http.get<PictureTagsResponseModel>(url))
      .then(({ data }) => data.entries);
  }

  editFileTags(fileId: ObjectId, tagsIds: ObjectId[]): ng.IPromise<void> {
    return this.sfPOVService
      .pBuildURL(`/digitalAssets/images/${fileId}/tags`, {
        pov: 'organisation',
      })
      .then((url) => this.$http.patch(url, { tags_ids: tagsIds }))
      .then();
  }

  getFileTags(file_id: ObjectId): ng.IPromise<FileMetadata> {
    return this.sfPOVService
      .pBuildURL(`/filesMetadata/${file_id}`)
      .then((url) => this.$http.get<FileMetadata>(url))
      .then(({ data }) => data.contents.tags_ids);
  }
}
