import Croppie from 'croppie';

export class ImageCropperService {
  cropperObject: { result: (arg: Record<string, unknown>) => Promise<any> };

  /* @ngInject */
  constructor(private $q: ng.IQService) {}

  initCropper(
    imageElement: Element,
    {
      width,
      height,
      type,
    }: { width: number; height: number; type: 'circle' | 'square' }
  ): void {
    this.cropperObject = new Croppie(imageElement, {
      viewport: { width, height, type },
      showZoomer: false,
    });
  }

  getCroppedImage<T>({
    type,
    width,
    height,
  }: {
    type: 'blob' | 'base64';
    width: number;
    height: number;
  }): ng.IPromise<T> {
    return this.$q.resolve(
      this.cropperObject.result({
        type,
        size: { width, height },
        circle: false,
      })
    );
  }
}
