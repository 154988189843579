export const InputCheckboxComponent = {
  bindings: {
    isRequired: '<',
    disabled: '<',
    hasError: '<',
    label: '@',
    inputName: '@',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  transclude: true,
  templateUrl: 'components/Inputs/input-checkbox/input-checkbox.html',
  controller: class InputCheckboxController {
    $onInit() {
      this.modelCtrl.$render = () => {
        this.isSelected = !!this.modelCtrl.$viewValue;
      };
    }
    select() {
      this.onChange();
    }
    onChange() {
      if (this.disabled) {
        return false;
      }
      this.isSelected = !this.isSelected;
      return this.modelCtrl.$setViewValue(this.isSelected);
    }
  },
};
