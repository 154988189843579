import { TargetingTypeObject } from '../types';

export const TARGETING_TYPES: TargetingTypeObject[] = [
  {
    key: 'users-groups',
    value: 'usersGroup',
    iconClass: 'fa-light fa-users',
    translationKey: 'TARGETING_OPTIONS_USERS_GROUPS',
  },
  {
    key: 'places-lists',
    value: 'placesList',
    iconClass: 'fa-light fa-store',
    translationKey: 'TARGETING_OPTIONS_PLACES_LISTS',
  },
  {
    key: 'everyone',
    value: 'all',
    iconClass: 'fa-light fa-earth-europe',
    translationKey: 'TARGETING_OPTIONS_EVERYONE',
    tooltip: 'TARGETING_OPTIONS_EVERYONE_TOOLTTP',
  },
  {
    key: 'adminOnly',
    value: 'adminOnly',
    iconClass: 'fa-light fa-eye-slash',
    hidden: true,
  },
];
