import { DateService } from '../../../../services/Utils/Dates/date.service';
import { ModalService } from '../../../../services/Utils/Modal';
import { CalendarEvent, DateEvents } from '../../../types';

export class ManagerCalendarEventsListController {
  private translations: Record<string, string>;
  private events: DateEvents[];
  formattedEvents: {
    date: Date;
    isoWeek: number;
    formattedDate: string;
    events: CalendarEvent[];
  }[];
  // eslint-disable-next-line max-params
  constructor(
    private $translate: ng.translate.ITranslateService,
    private modalService: ModalService,
    private dateFormatService,
    private dateService: DateService
  ) {
    'ngInject';
    this.translations = {
      goToStore: this.$translate.instant('EVENT_GO_TO_STORE'),
      detailsEvent: this.$translate.instant('EVENT_GO_TO_DETAILS'),
      cancel: this.$translate.instant('EVENT_CANCEL'),
    };
  }

  $onChanges(): void {
    this.formattedEvents = this.events.map((event) => {
      const momentDate = this.dateService.toMoment(event.date);

      return {
        formattedDate:
          this.dateFormatService.getCompleteDateFormatted(momentDate),
        isoWeek: momentDate.isoWeek(),
        ...event,
      };
    });
  }

  detailsEvent(event: CalendarEvent): void {
    const template = `
      <sf-details-event-modal
        event="$ctrl.event"
        on-close="$ctrl.onClose()"
        readonly="true"
      </sf-details-event-modal>
    `;

    this.modalService.open(
      template,
      {
        readonly: true,
        event,
      },
      { hardwareBackButtonClose: true }
    );
  }
}
