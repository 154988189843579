export const ReportsPendingSelectionComponent = {
  bindings: {
    reports: '<',
    formsHash: '<',
    placesHash: '<',
    onItemClick: '&',
    selectedReports: '=',
    selectName: '@',
  },
  templateUrl:
    'reports/components/reports-pending-selection/reports-pending-selection.html',
  controller: class ReportsPendingSelectionController {
    getPlace(report) {
      return this.placesHash[report.contents.place_id];
    }

    getForm(report) {
      return this.formsHash[report.contents.form_id];
    }

    selectAllReports() {
      this.selectedReports = this.reports.reduce((allReports, report) => {
        allReports[report.id] = this.isAllSelected;
        return allReports;
      }, {});
    }

    isAllReportsSelected() {
      return this.reports.every((report) => this.selectedReports[report.id]);
    }

    onReportSelectChange() {
      this.isAllSelected = this.isAllReportsSelected();
    }

    stopPropagation(ev) {
      ev.stopPropagation();
    }
  },
};
