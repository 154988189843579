import { SfFeatureFlags } from '@simplifield/feature-flags';
import { StateService } from '@uirouter/angularjs';
import { FEATURE_FLAGS } from '../../../../constants/feature-flags.constant';
import {
  Documents,
  ViewDocumentItem,
} from '../../../../services/API/documents/';
import { APIStore } from '../../../';
const PLACE_DOCS_LIMIT = 3;
const PLACE_IMAGES_LIMIT = 3;

export class PlaceAssetsController implements ng.IController {
  // bindings
  place: APIStore;

  // component members
  placeDocuments: ViewDocumentItem[];
  isLoadingDocuments: boolean;
  hasDocumentsError: boolean;
  hasDocumentsFeature: boolean;
  hasFieldMetricsFeature: boolean;
  galleryLimit = PLACE_IMAGES_LIMIT;
  galleryListFilter: { name: string; value: string }[];

  // eslint-disable-next-line max-params
  constructor(
    private $state: StateService,
    private documentsService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    this.hasDocumentsFeature = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.PLACE_DOCS
    );
    this.hasFieldMetricsFeature = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.FIELD_METRICS
    );
    this.galleryListFilter = [
      {
        name: 'pos_name',
        value: this.place.contents.name,
      },
    ];
    return this.getDocuments();
  }

  getDocuments(): ng.IPromise<void> {
    this.isLoadingDocuments = true;
    this.hasDocumentsError = false;

    return this.documentsService
      .getByPlaceId(this.place._id, PLACE_DOCS_LIMIT)
      .then((documents: Documents) => {
        this.placeDocuments = documents.entries.map((document) => ({
          _id: document.contents.file_id,
          name: documents.filesMetadata[document.contents.file_id].fileName,
          type: documents.filesMetadata[document.contents.file_id].fileType,
          size: documents.filesMetadata[document.contents.file_id].fileLength,
        }));
      })
      .catch(() => {
        this.hasDocumentsError = true;
      })
      .finally(() => {
        this.isLoadingDocuments = false;
      });
  }

  goToView(stateLink: string): void {
    this.$state.go(stateLink);
  }
}
