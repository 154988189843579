import FormModule from './form/form.module';
import MissionModule from './mission/mission.module';
import MissionsModule from './missions/missions.module';
import OngoingSheetModule from './services/ongoing-sheet.module';
import missionsRouteConfig from './missions.routes';

import MissionItemModule from './components/mission-item/mission-item.module';
import CatalogGoalsModule from './components/catalog-goals/catalog-goals.module';
import CampaignSelectorModule from './components/campaign-selector/campaign-selector.module';
import FormQuestionModule from './components/Form-questions/question-form.module';
import ReceiptModule from './components/Receipt/receipt.module';
import ScansModule from './components/Scans/scans.module';
import ScoringModule from './components/Scoring/scoring.module';

export default angular
  .module('sfMobile.missions', [
    'sfMobile.services.localization',
    'sfMobile.constants.user-experience',
    'sfMobile.comments',
    'sfMobile.components.view',
    'sfMobile.components.nav-bar',
    'sfMobile.components.button-header-close',
    'sfMobile.services.reportValidation',
    FormModule,
    MissionModule,
    MissionsModule,
    OngoingSheetModule,
    MissionItemModule,
    CatalogGoalsModule,
    CampaignSelectorModule,
    FormQuestionModule,
    ReceiptModule,
    ScansModule,
    ScoringModule,
  ])
  .config(missionsRouteConfig).name;
