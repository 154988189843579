export class EventTypesService {
  constructor(crudFactory) {
    'ngInject';
    this.crud = crudFactory('calendar_events_types', {
      default_params: { mode: 'compact' },
      path_name: 'calendar-event-types',
    });
  }

  listLocal(params = {}) {
    return this.crud.listLocal(params);
  }
}
