import { PovService } from '../../../services/API/POV/pov.service';
import { CorporateEvent } from '../../types';
import { IAPIList } from '../../../index';
import { SF_API_URL_CONFIG } from '../../../shared/configs/api-url.config';

export class CorporateEventsApiService {
  private baseUrl = SF_API_URL_CONFIG.EVENTS.CORPORATE;
  constructor(
    private $http: ng.IHttpService,
    private sfPOVService: PovService
  ) {
    'ngInject';
  }

  getCorporateEventsList(params = {}): ng.IPromise<CorporateEvent[]> {
    return this.sfPOVService
      .pBuildURL(`/${this.baseUrl}`, { pov: 'organisation' })
      .then((url) => this.$http.get<IAPIList<CorporateEvent>>(url, { params }))
      .then((response) => response.data.entries);
  }
}
