import { LocalizationService } from '../Localization/localization.service';

type PoliciesUrls = {
  PRIVACY_POLICY_URL: string;
  USER_AGREEMENT_URL: string;
};

type LolicalizedPoliciesUrls = {
  china: PoliciesUrls;
  world: PoliciesUrls;
};

export class ChinaConfigService {
  constructor(
    private ConfigServer,
    private $http: ng.IHttpService,
    private localizationService: LocalizationService
  ) {
    'ngInject';
  }

  // For China requirements
  getPoliciesUrls(): ng.IPromise<PoliciesUrls> {
    return this.$http
      .get<LolicalizedPoliciesUrls>(this.ConfigServer.POLICIES_URL)
      .then(({ data: policiesUrl }) => {
        return this.localizationService
          .shouldUseChinaConfiguration()
          .then((shouldUseChinaConfiguration) => {
            return policiesUrl[shouldUseChinaConfiguration ? 'china' : 'world'];
          });
      });
  }

  // For China requirements
  getUserAgreementUrl(): ng.IPromise<string> {
    return this.getPoliciesUrls().then((urls) => {
      return urls.USER_AGREEMENT_URL;
    });
  }

  // For China requirements
  getPrivacyPolicyUrl(): ng.IPromise<string> {
    return this.getPoliciesUrls().then((urls) => {
      return urls.PRIVACY_POLICY_URL;
    });
  }
}
