export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 4.9,
    method: updateMethod,
  });
}
// @ngInject
export function updateMethod($q, $log, sqlStorageService, localStorageService) {
  const fields = [
    'id NVARCHAR(32) PRIMARY KEY UNIQUE',
    'ean NVARCHAR(32)',
    'payload TEXT',
    'catalog_id NVARCHAR(32)',
  ].join(',');
  const productFields = ['id', 'ean', 'payload'].join(',');

  const createTempTableQuery = `CREATE TEMPORARY TABLE products_backup(${productFields})`;
  const insertInTempTableQuery = `INSERT INTO products_backup SELECT ${productFields} FROM products`;
  const dropTableQuery = 'DROP TABLE products';
  const createTableQuery = `CREATE TABLE products(${fields})`;
  // This line is buggy and was executed on some mobiles
  // I kept it for tracking purposes.
  // const insertInTableQuery =
  //   `INSERT INTO products SELECT ${productFields},catalog_id FROM products_backup`;
  const insertInTableQuery = `INSERT INTO products SELECT ${productFields},NULL FROM products_backup`; // fix SE-658
  const dropTempTableQuery = 'DROP TABLE products_backup';

  const update_date_keys = localStorageService
    .keys()
    .filter((key) => key.indexOf('_update_date') !== -1);

  return sqlStorageService
    .execute(createTempTableQuery)
    .then(() => sqlStorageService.execute(insertInTempTableQuery))
    .then(() => sqlStorageService.execute(dropTableQuery))
    .then(() => sqlStorageService.execute(createTableQuery))
    .then(() => sqlStorageService.execute(insertInTableQuery))
    .then(() => sqlStorageService.execute(dropTempTableQuery))
    .then(() =>
      localStorageService.remove.apply(localStorageService, update_date_keys)
    )
    .catch(() => null);
}
