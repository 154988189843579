import { PlaceCampaignsSectionController } from './place-campaigns-section.controller';

export const PlaceCampaignsSectionComponent = {
  bindings: {
    campaigns: '<',
    placeId: '<',
    title: '@',
  },
  templateUrl:
    'places/place/components/place-campaigns-section/place-campaigns-section.html',
  controller: PlaceCampaignsSectionController,
};
