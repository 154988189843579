import { LocalizationService } from '../Localization/localization.service';
import { TranslateNumbersService } from './translateNumbers.service';

export function toArabicNumber(
  localizationService: LocalizationService,
  translateNumbersService: TranslateNumbersService
): ng.IFilterFunction {
  const isRTLNeeded = localizationService.shouldActivateRTL();
  return (text): string => {
    return isRTLNeeded
      ? translateNumbersService.changeNumberEnglishToArabic(text)
      : text;
  };
}
