export const _handleError = (errorMessage, parameters) => {
    let message = 'The dashboard component encountered an error';
    if (typeof parameters?.dashboardId === 'string') {
        message += ` for dashboard with id ${parameters.dashboardId}`;
    }
    if (typeof parameters?.dashboardSlug === 'string') {
        message += ` for dashboard with slug ${parameters.dashboardSlug}`;
    }
    if (typeof parameters?.container === 'string') {
        message += ` in container ${parameters.container}`;
    }
    if (errorMessage) {
        message += `:\n${errorMessage}`;
        console.error(message);
    }
};
