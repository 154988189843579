export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 6,
    method: updateMethod,
  });

  // @ngInject
  function updateMethod(sqlStorageService, localStorageService) {
    const drop_original = 'DROP TABLE products;';
    const create_new_table =
      'CREATE TABLE products(id NVARCHAR(32) PRIMARY KEY UNIQUE, ean NVARCHAR(32), payload TEXT, catalog_id NVARCHAR(32));';
    const update_date_keys = localStorageService
      .keys()
      .filter((key) => key.indexOf('_update_date') !== -1);

    return sqlStorageService
      .execute(drop_original)
      .then(() => sqlStorageService.execute(create_new_table))
      .then(() =>
        localStorageService.remove.apply(localStorageService, update_date_keys)
      )
      .catch(() => null);
  }
}
