import BarcodeButtonModule from './barcode-button/barcode-button.module';
import DateTimeRangeTimezoneModule from './date-time-range-timezone/date-time-range-timezone.module';
import { DatePickerModule } from './date-picker/date-picker.module';
import { DatePickerButtonModule } from './date-picker-button/date-picker-button.module';
import InputBarcodeModule from './input-barcode/input-barcode.module';
import InputCheckbocModule from './input-checkbox/input-checkbox.module';
import InputCheckboxItemModule from './input-checkbox-item/input-checkbox-item.module';
import InputCheckboxItemFreeModule from './input-checkbox-item-free/input-checkbox-item-free.module';
import InputDateModule from './input-date/input-date.module';
import InputRadioModule from './input-radio/input-radio.module';
import InputRadioButtonsBarModule from './input-radio-buttons-bar/input-radio-buttons-bar.module';
import InputRadioFreeModule from './input-radio-free/input-radio-free.module';
import InputTextModule from './input-text/input-text.module';
import InputTimeModule from './input-time/input-time.module';
import InputFileUploadComponent from './input-file-upload/input-file-upload.module';
import RatingModule from './rating/rating.module';
import SelectModule from './select/select.module';
import SelectOrTextModule from './select-or-text/select-or-text.module';
import SelectTimezoneModule from './select-timezone/select-timezone.module';
import SignatureModule from './signature/signature.module';
import SignaturePadModule from './signature-pad/signature-pad.module';
import TextareaModule from './textarea/textarea.module';
import ToggleModule from './toggle/toggle.module';
import ToggleDisplayModule from './toggle-display/toggle-display.module';

export default angular.module('sfMobile.components.inputs', [
  BarcodeButtonModule,
  DateTimeRangeTimezoneModule,
  DatePickerModule,
  DatePickerButtonModule,
  InputBarcodeModule,
  InputCheckbocModule,
  InputCheckboxItemModule,
  InputCheckboxItemFreeModule,
  InputDateModule,
  InputRadioModule,
  InputRadioButtonsBarModule,
  InputRadioFreeModule,
  InputTextModule,
  InputTimeModule,
  InputFileUploadComponent,
  RatingModule,
  SelectModule,
  SelectOrTextModule,
  SelectTimezoneModule,
  SignatureModule,
  SignaturePadModule,
  TextareaModule,
  ToggleModule,
  ToggleDisplayModule,
]).name;
