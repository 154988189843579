"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  type: 'string',
  pattern: '^([0-9a-f]{24})$',
  minLength: 24,
  maxLength: 24
};
exports["default"] = _default;