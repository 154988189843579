"use strict";

/* eslint-disable max-len */
var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('request-1'),
    name: 'request-1',
    upstanding: {
      valid: true
    },
    contents: {
      originalEvent: {
        _id: createObjectId('event'),
        contents: {
          k: 'v'
        }
      },
      requestedEvent: {
        k: 'v1'
      },
      state: 'pending',
      action: 'edit',
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('request-2'),
    name: 'request-2',
    upstanding: {
      valid: true
    },
    contents: {
      originalEvent: {
        _id: createObjectId('event'),
        contents: {
          k: 'v'
        }
      },
      requestedEvent: {
        k: 'v1'
      },
      state: 'pending',
      action: 'edit',
      requestComment: 'please!!!11',
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('request-3'),
    name: 'request-3',
    upstanding: {
      valid: true
    },
    contents: {
      originalEvent: {
        _id: createObjectId('event'),
        contents: {
          k: 'v'
        }
      },
      requestedEvent: {
        k: 'v1'
      },
      state: 'rejected',
      action: 'remove',
      requestComment: 'please!!!11',
      stateChangedBy_id: createObjectId('manager'),
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('request-4'),
    name: 'request-4',
    upstanding: {
      valid: true
    },
    contents: {
      originalEvent: {
        _id: createObjectId('event'),
        contents: {
          k: 'v'
        }
      },
      requestedEvent: {},
      state: 'pending',
      action: 'remove',
      requestComment: 'Nooooo!',
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('request-5'),
    name: 'request-5',
    upstanding: {
      valid: true
    },
    contents: {
      originalEvent: {
        _id: createObjectId('event'),
        contents: {}
      },
      requestedEvent: {
        k: 'v'
      },
      state: 'pending',
      action: 'create',
      requestComment: 'I want it all',
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('original-event-missing'),
    name: 'original-event-missing',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.originalEvent']
      }]
    },
    contents: {
      requestedEvent: {
        k: 'v1'
      },
      state: 'pending',
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('requested-event-missing'),
    name: 'requested-event-missing',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.requestedEvent']
      }]
    },
    contents: {
      originalEvent: {
        _id: createObjectId('event'),
        contents: {
          k: 'v'
        }
      },
      state: 'pending',
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('original-event-id-missing'),
    name: 'original-event-id-missing',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['._id']
      }]
    },
    contents: {
      originalEvent: {
        contents: {}
      },
      requestedEvent: {
        k: 'v1'
      },
      state: 'pending',
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('original-event-contents-missing'),
    name: 'original-event-contents-missing',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.contents']
      }]
    },
    contents: {
      originalEvent: {
        _id: createObjectId('event')
      },
      requestedEvent: {
        k: 'v1'
      },
      state: 'pending',
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('org-id-missing'),
    name: 'org-id-missing',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.organisation_id']
      }]
    },
    contents: {
      originalEvent: {
        _id: createObjectId('event'),
        contents: {
          k: 'v'
        }
      },
      requestedEvent: {
        k: 'v1'
      },
      state: 'pending',
      action: 'edit'
    }
  }, {
    _id: createObjectId('missing-state-changed-by-id-when-state-no-pending'),
    name: 'missing-state-changed-by-id-when-state-no-pending',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.stateChangedBy_id']
      }]
    },
    contents: {
      originalEvent: {
        _id: createObjectId('event'),
        contents: {
          k: 'v'
        }
      },
      requestedEvent: {
        k: 'v1'
      },
      state: 'rejected',
      action: 'remove',
      requestComment: 'please!!!11',
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('missing-action'),
    name: 'missing-action',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.action']
      }]
    },
    contents: {
      originalEvent: {
        _id: createObjectId('event'),
        contents: {
          k: 'v'
        }
      },
      requestedEvent: {
        k: 'v1'
      },
      state: 'pending',
      organisation_id: createObjectId('organisation')
    }
  }];
};