import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngSanitize from 'angular-sanitize';
import uiRouter from 'angular-ui-router';
import 'ionic-sdk/release/js/ionic';
import 'ionic-sdk/release/js/ionic-angular';

import ngLocalStorage from 'angular-local-storage';
import ngMessage from 'angular-messages';
import ngSqlStorage from 'angular-sql-storage';
import ngTranslate from 'angular-translate';
import 'angular-translate-interpolation-messageformat';

import 'core-js/features/promise/all-settled';

import ActivityModule from './activity/activity.module';
import AnalyticsModule from './analytics/analytics.module';
import CalendarEventsModule from './calendar-events/calendar-events.module';
import CategoriesModule from './categories/categories.module';
import ChangeRequestsModule from './change-requests/change-requests.module';
import ChatModule from './chat/chat.module';
import CommentsModule from './comments/comments.module';
import ComponentsModule from './components/components.module';
import CoreModule from './core/core.module';
import { DiagnosisModule } from './diagnosis/diagnosis.module';
import { DigitaAssetsModule } from './digital-assets/digital-assets.module';
import DocumentLibraryModule from './document-library/document-library.module';
import LayoutModule from './layout/layout.module';
import LegacyCampaignsModule from './legacy-campaigns/campaigns.module';
import LoginModule from './login/login.module';
import MenuMoreModule from './menu-more/menu-more.module';
import MerchandisingModule from './merchandising/merchandising.module';
import MissionsModule from './missions/missions.module';
import MultiOrganisationModule from './multi-organisation/multi-organisation.module';
import NewsfeedModule from './newsfeed/newsfeed.module';
import NotificationsModule from './notifications/notifications.module';
import PlacesModule from './places/places.module';
import PreviewModule from './preview/preview.module';
import ProductsModule from './products/products.module';
import CampaignsModule from './reactive-campaigns/reactive-campaigns.module';
import ReportsModule from './reports/reports.module';
import TargetingModule from './targeting/targeting.module';
import TasksModule from './tasks/tasks.module';

import ConstantsModule from './constants/constants.module';
import { DirectivesModule } from './directives/directives.module';
import ApiServicesModule from './services/API/api.module';
import LibsServicesModule from './services/Libs/libs.module';
import UtilsServicesModule from './services/Utils/utils.module';
import TagsSelectorModule from './tags-selector/tags-selector.module';

angular.module('sfMobile', [
  ngTranslate,
  ngAnimate,
  ngSanitize,
  ngLocalStorage,
  ngMessage,
  uiRouter,

  ngSqlStorage,

  ComponentsModule,
  CoreModule,

  // Feature areas
  LoginModule,
  NotificationsModule,
  ActivityModule,
  CalendarEventsModule,
  LegacyCampaignsModule,
  CampaignsModule,
  CommentsModule,
  ChangeRequestsModule,
  DigitaAssetsModule,
  AnalyticsModule,
  MissionsModule,
  PlacesModule,
  PreviewModule,
  ReportsModule,
  ChatModule,
  MenuMoreModule,
  TasksModule,
  LayoutModule,
  MerchandisingModule,
  NewsfeedModule,
  ProductsModule,
  CategoriesModule,
  TargetingModule,
  ConstantsModule,
  ApiServicesModule,
  LibsServicesModule,
  UtilsServicesModule,
  DiagnosisModule,
  DocumentLibraryModule,
  MultiOrganisationModule,
  DirectivesModule,
  TagsSelectorModule,
]);
