import { CampaignCompletionController } from './campaign-completion.controller';

export const CampaignCompletionComponent = {
  bindings: {
    form: '<',
    campaign: '<',
  },
  templateUrl:
    'legacy-campaigns/campaign/components/campaign-completion/campaign-completion.html',
  controller: CampaignCompletionController,
};
