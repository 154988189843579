import { IAPIResource, ObjectId } from '../../..';
import { PovService } from '../POV/pov.service';

export type DashboardList = {
  count: number;
  rows: Dashboard[];
};

export type DashboardFilter = {
  name: string;
  availableValues?: string[];
};

export type Dashboard = {
  authorization: {
    key: string;
    token: string;
  };
  dashboardId: string;
  dashboardTitle: Record<string, string>;
  availableFilters: DashboardFilter[];
};

export type DashboardAPILink = IAPIResource<{
  dashboard: {
    name: Record<string, string>;
    description: string;
  };
}> & {
  is_standard_dashboard?: boolean;
};

export type Locale = 'en' | 'fr';

export class ExternalDashboardApiService {
  baseUrl = '/analytics';
  /* @ngInject */
  constructor(
    private $http: ng.IHttpService,
    private sfPOVService: PovService
  ) {}

  getDashboards(params = {}): ng.IPromise<DashboardAPILink[]> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/dashboards`, { pov: 'organisation' })
      .then((url) => this.$http.get<DashboardAPILink[]>(url, { params }))
      .then((response) => response.data);
  }

  getDashboard(dashboardId: ObjectId, params = {}): ng.IPromise<Dashboard> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/dashboard/${dashboardId}`, {
        pov: 'organisation',
      })
      .then((url) =>
        this.$http.get<Dashboard>(url, {
          params,
        })
      )
      .then((response) => response.data);
  }
}
