export const ReportItemComponent = {
  bindings: {
    onCommentsClick: '&',
    title: '@',
    campaignName: '@',
    report: '<',
    campaignStatus: '<',
    commentsNumber: '<',
    user: '<',
    place: '<?',
    translations: '<',
    reportType: '<',
    isObjectiveReached: '<',
    onDelete: '&?',
  },
  transclude: true,
  templateUrl: 'reports/components/report-item/report-item.html',
  controller: function ReportItemController(
    pubSubService,
    dateFormatService,
    sfFeatureFlagsService,
    SF_FEATURE_FLAGS
  ) {
    'ngInject';
    const getScore = (report) => {
      const wholeScore = report && report.score && report.score.whole;

      return wholeScore && Math.round(wholeScore.percent * 100);
    };

    this.$onInit = () => {
      this.shouldDisplayNoSendWarning = !!(
        this.reportType === 'draft' && this.isObjectiveReached
      );

      this.updateStateEvent = pubSubService.subscribe(
        'REPORT_STATE_UPDATED',
        (data) => this.onReportStateChanged(data)
      );

      this.userObject = this.user
        ? {
            contents: {
              firstName: this.user.firstName || '',
              lastName: this.user.lastName || '',
              avatar_id: this.user.avatar_id,
            },
          }
        : null;

      this.subtitles = [
        ...(this.place ? [this.place.name, this.place.formatted_address] : []),
        this.getSubtitleDate(this.report),
      ];

      if (this.place) {
        this.subtitles = [...this.subtitles];
      }

      this.hasBackendDraftsFlag = sfFeatureFlagsService.hasFeature(
        SF_FEATURE_FLAGS.DRAFT_REPORTS
      );

      this.isReportLocalOnly = ['draft', 'toBeSent'].includes(
        this.report.localStatus
      );

      this.isReportDeletable =
        this.reportType === 'draft' || this.isReportLocalOnly;

      this.computeScore();
    };

    this.$onChanges = (changes) => {
      if (changes.report) {
        this.computeScore();
      }
    };

    this.getUserName = (user) =>
      user ? `${user.firstName} ${user.lastName}` : null;

    this.getDateAndTimeFormatted = dateFormatService.getDateAndTimeFormatted;

    this.computeScore = () => {
      this.reportStatus = this.report && this.report.contents.state;
      this.score = getScore(this.report);
    };

    this.getHasScore = () => {
      return this.score || this.score === 0;
    };

    this.$onDestroy = () => {
      this.updateStateEvent();
      return true;
    };

    this.onReportStateChanged = (datas) => {
      if (datas.report_id === this.report._id) {
        this.reportStatus = datas.state;
        this.report.contents.state = datas.state;
      }
    };

    this.getSubtitleDate = (report) => {
      const date = report.modified_date || report.saved_date;
      const formattedDate = this.getDateAndTimeFormatted(date);

      return formattedDate;
    };

    this.deleteDraft = () => {
      this.onDelete();
    };
  },
};
