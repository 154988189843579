export const ButtonItemActionsComponent = {
  bindings: {
    actions: '<',
    isDisabled: '<',
    onClick: '&',
    onCancel: '&',
    buttonClasses: '<?',
    customIcon: '@?',
  },
  templateUrl:
    'components/Buttons/button-item-actions/button-item-actions.html',
  controller: class ButtonItemActionsComponent {
    constructor($translate, actionSheetService) {
      'ngInject';
      this.$translate = $translate;
      this.actionSheetService = actionSheetService;
    }

    $onInit() {
      this.icon = this.customIcon
        ? this.customIcon
        : 'fa-light fa-ellipsis sf-2x';
    }

    openActionsSheet(event) {
      if (this.onClick) {
        this.onClick();
      }

      event.stopPropagation();

      const actionSheetActions = this.actions;
      const onCancel = () => this.onCancel();
      const actionSheetConfig = {
        cancelText: this.$translate.instant('BUTTON_ITEM_ACTIONS_CANCEL'),
      };

      return this.actionSheetService.open(
        actionSheetActions,
        actionSheetConfig,
        onCancel,
        onCancel
      );
    }
  },
};
