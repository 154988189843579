import moment, { MomentInput } from 'moment-timezone';

const NB_SECONDS_IN_ONE_MINUTE = 60;
const NB_MILLI_IN_ON_SECOND = 1000;
const NB_DAYS_IN_A_WEEK = 7;

const DEFAULT_DIFF_TRANSLATION_KEYS = {
  years: 'DATE_DIFF_YEARS',
  months: 'DATE_DIFF_MONTHS',
  days: 'DATE_DIFF_DAYS',
  hours: 'DATE_DIFF_HOURS',
  minutes: 'DATE_DIFF_MINUTES',
};
const DEFAULT_DIFF_LIMITS = {
  years: 1,
  months: 3,
  days: 1,
  hours: 1,
};

export class DateService {
  moment;

  /* @ngInject */
  constructor(private $translate: ng.translate.ITranslateService) {
    this.moment = moment;
  }

  /*
   * Checkers
   */

  isToday(date: MomentInput): boolean {
    const TODAY = moment().startOf('day');

    return moment(date).isSame(TODAY, 'day');
  }
  isYesterday(date: MomentInput): boolean {
    const YESTERDAY = moment().startOf('day').subtract(1, 'd');

    return moment(date).isSame(YESTERDAY, 'day');
  }
  isTomorrow(date: MomentInput): boolean {
    const TOMORROW = moment().endOf('day').add(1, 'd');

    return moment(date).isSame(TOMORROW, 'day');
  }

  isThisWeek(date: MomentInput): boolean {
    const ENDOFWEEK = moment().endOf('week');

    return moment(date).isSameOrBefore(ENDOFWEEK);
  }
  isThisMonth(date: MomentInput): boolean {
    const ENDOFMONTH = moment().endOf('month');

    return moment(date).isSameOrBefore(ENDOFMONTH);
  }

  isOverdue(date: MomentInput): boolean {
    const TODAY = moment().startOf('day');

    return TODAY > moment(date);
  }

  isTodayInRange(startDate: MomentInput, endDate: MomentInput): boolean {
    return moment().isBetween(startDate, endDate, 'day', '[]');
  }

  // Return if delay is exceeded compared to the given numbers of hours.
  isDelayExceeded(nbHours: number, date: string | number | Date): boolean {
    const now = new Date().getTime();
    const maxTime =
      NB_SECONDS_IN_ONE_MINUTE * NB_SECONDS_IN_ONE_MINUTE * nbHours;
    const diff = date
      ? Math.floor(new Date(date).getTime() - now) / NB_MILLI_IN_ON_SECOND +
        maxTime
      : 0;

    return diff <= 0;
  }

  isGreaterThan(
    firstDate?: string | number | Date,
    secondDate?: string | number | Date
  ): boolean {
    const fDateTime = firstDate ? new Date(firstDate).getTime() : 0;
    const sDateTime = secondDate ? new Date(secondDate).getTime() : null;

    return sDateTime === null || fDateTime > sDateTime;
  }

  isSameDate(
    firstDate: string | number | Date,
    secondDate: string | number | Date
  ): boolean {
    const firstFormattedDate = new Date(this.getISOTime(firstDate));
    const secondFormattedDate = new Date(this.getISOTime(secondDate));

    return (
      firstFormattedDate.toDateString() === secondFormattedDate.toDateString()
    );
  }

  isSameDay(date1: MomentInput, date2: MomentInput): boolean {
    return moment(date1).isSame(date2, 'day');
  }

  isSameWeek(date1: MomentInput, date2: MomentInput): boolean {
    return moment(date1).isSame(date2, 'week');
  }

  isInRange(
    date: MomentInput,
    dateRange: MomentInput[],
    granularity?: moment.unitOfTime.StartOf
  ): boolean {
    return moment(date).isBetween(
      dateRange[0],
      dateRange[1],
      granularity,
      '[]'
    );
  }

  isSameOrBefore(startDate: MomentInput, endDate: MomentInput): boolean {
    return moment(startDate).isSameOrBefore(endDate);
  }

  isPast(date: MomentInput): boolean {
    return moment.utc().isAfter(date);
  }

  isAfter(date1: Date, date2 = new Date()): boolean {
    return moment(date1).isAfter(date2);
  }

  ISODateToDate(isodate: string): Date {
    return new Date(isodate);
  }

  /*
   * Formatters
   */

  toDate(momentDate: MomentInput): Date {
    return moment(momentDate).toDate();
  }

  toMoment(date: MomentInput): moment.Moment {
    return moment(date);
  }

  generateWeekDaysArray(monday: MomentInput): MomentInput[] {
    const dayOfWeek = moment(monday);
    const oneDay = moment.duration(1, 'days');
    const week = [monday];

    for (let i = 1; i < NB_DAYS_IN_A_WEEK; i++) {
      dayOfWeek.add(oneDay);
      week.push(moment(dayOfWeek));
    }

    return week;
  }

  formatDifference(
    startDate: MomentInput,
    endDate: MomentInput,
    translationsKeys = DEFAULT_DIFF_TRANSLATION_KEYS,
    limits = DEFAULT_DIFF_LIMITS
  ): string {
    const years = moment(endDate).diff(startDate, 'years');

    if (years >= limits.years) {
      return this.$translate.instant(translationsKeys.years, { time: years });
    }

    const months = moment(endDate).diff(startDate, 'months');

    if (months >= limits.months) {
      return this.$translate.instant(translationsKeys.months, { time: months });
    }

    const days = moment(endDate).diff(startDate, 'days');

    if (days >= limits.days) {
      return this.$translate.instant(translationsKeys.days, { time: days });
    }

    const hours = moment(endDate).diff(startDate, 'hours');

    if (hours >= limits.hours) {
      return this.$translate.instant(translationsKeys.hours, { time: hours });
    }

    const minutes = moment(endDate).diff(startDate, 'minutes');

    return this.$translate.instant(translationsKeys.minutes, { time: minutes });
  }

  /*
   * Date manipulation
   */

  getMilisecondsFromMidnight(date: Date): number {
    const midnight = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );

    return date.getTime() - midnight.getTime();
  }

  getTimeInMilliseconds(date: Date): number {
    return date.getHours() * 60 * 60 * 1000 + date.getMinutes() * 60 * 1000;
  }

  addADay(date: Date): Date {
    date.setDate(date.getDate() + 1);
    return date;
  }

  subtract(
    number: moment.DurationInputArg1,
    string: moment.unitOfTime.DurationConstructor,
    date: MomentInput
  ): Date {
    return moment(date).subtract(number, string).toDate();
  }

  compareDates(
    firstDate?: string | number | Date,
    secondDate?: string | number | Date
  ): number {
    return (
      new Date(secondDate || 0).getTime() - new Date(firstDate || 0).getTime()
    );
  }

  // Transform the date in parameter to get the same day at 00:00 in UTC TZ
  // browser in GMT+2 will get date == dd.mm.yyyy 02:00 GMT+2
  getUtcStartOfDate(date: Date): Date {
    const y = date.getFullYear();
    const m = date.getMonth();
    const d = date.getDate();

    const startUtcDay = new Date(Date.UTC(y, m, d, 0, 0, 0));
    return startUtcDay;
  }

  // Transform the date in parameter to get the same day at 23:59 in UTC TZ
  getUtcEndOfDate(date: Date): Date {
    const y = date.getFullYear();
    const m = date.getMonth();
    const d = date.getDate();
    const endUtcDay = new Date(Date.UTC(y, m, d, 23, 59, 59, 999));
    return endUtcDay;
  }

  convertToUTCDate(date: string | number | Date): Date {
    const newDate = new Date(date);

    return new Date(
      newDate.getUTCFullYear(),
      newDate.getUTCMonth(),
      newDate.getUTCDate(),
      newDate.getUTCHours(),
      newDate.getUTCMinutes(),
      newDate.getUTCSeconds()
    );
  }

  /*
   * Getters
   */

  getStartOfDate(date: MomentInput): Date {
    return moment(date).startOf('day').toDate();
  }

  getEndOfDate(date: Date = new Date()): Date {
    return moment(date).endOf('day').toDate();
  }

  getNextYear(): Date {
    return moment().add(1, 'year').toDate();
  }
  getIsoWeekDayNumber(date: MomentInput): number {
    return moment(date).isoWeekday();
  }
  getWeekNumberOfMonth(date: MomentInput): number {
    return moment(date).isoWeek() - moment(date).startOf('month').isoWeek() + 1;
  }

  getRangeOfDates(startDate: Date, endDate: Date): Date[] {
    const dates: Date[] = [];
    let currentDate = startDate;

    endDate = endDate || moment().endOf('day');

    for (; currentDate <= endDate; currentDate = this.addADay(currentDate)) {
      dates.push(new Date(currentDate));
    }

    return dates;
  }

  // Returns an UTC time corresponding to the time in timezone is in use
  getISOTime(date: string | number | Date): string {
    const convertedDate = date ? new Date(date) : new Date();

    convertedDate.setUTCHours(convertedDate.getHours());
    return convertedDate.toISOString();
  }

  // Returns the UTC time based on timezone. The user hour is set as the end of the day.
  getUTCFromTimezone(date: string, tzName: string, time = '23:59:59'): string {
    date = date + ' ' + time;

    const formattedDate = moment.tz(date, tzName).utc();
    return formattedDate.format();
  }

  getTimezoneDateFromUtc(date: string, tzName: string): moment.Moment {
    return moment.tz(date, tzName);
  }

  getWeekdays(): string[] {
    return moment.weekdays();
  }
  getDateFromString(date: MomentInput): Date {
    return moment(date).toDate();
  }
  getDateFrom(
    amount: moment.unitOfTime.DurationConstructor,
    unit: moment.DurationInputArg1
  ): Date {
    return this.getStartOfDate(moment().subtract(unit, amount).toDate());
  }
  getTotalDaysBetweenTwoDates(
    startDate: MomentInput,
    endDate: MomentInput
  ): number {
    return (
      moment
        .duration(moment(endDate).diff(moment(startDate), 'days'), 'days')
        .asDays() + 1
    );
  }

  getPeriodConstraints(
    date: MomentInput,
    period: moment.unitOfTime.Base
  ): { start: Date; end: Date } {
    return {
      start: moment(date).startOf(period).toDate(),
      end: moment(date).endOf(period).toDate(),
    };
  }

  timestampMilliseconds(date: Date = new Date()): number {
    return date.getTime();
  }
}
