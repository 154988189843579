import { Directory } from '@capacitor/filesystem';
import * as blobUtil from 'blob-util';
import type { ObjectId } from '../../..';
import type { FileSystemService } from '../../Utils/FileSystem/file-system.service';
import type { ImageSource } from '../../Utils/ImageSource/image-source.service';
import type { ObjectIdService } from '../../Utils/Objectid/objectId.service';
import type { PovService } from '../POV/pov.service';
import type { CampaignFile } from '../campaigns/campaigns';

const FILES_TIMEOUT_SEND = 180000;

export type DowloadProgression = { loaded: number; total: number };
export type DownloadFileOpts = {
  directory?: Directory;
  path: string;
  name: string;
};
// eslint-disable-next-line max-params
export class FilesService {
  blobUtil = blobUtil;
  // eslint-disable-next-line max-params
  constructor(
    private $http: ng.IHttpService,
    private $timeout: ng.ITimeoutService,
    private $q: ng.IQService,
    private $log: ng.ILogService,
    private profileService,
    private filesSystemService: FileSystemService,
    private objectIdService: ObjectIdService,
    private sfPOVService: PovService
  ) {
    'ngInject';
  }

  upload(
    file: Blob,
    fileId: ObjectId,
    options: {
      config?: { pov: string };
      canceler?: ng.IDeferred<void>;
      fileNameOverride?: string;
      tags_ids?: ObjectId[];
    } = {},
    isAsset?: boolean
  ): ng.IPromise<void> {
    const form = new FormData();
    let autoStop: ng.IPromise<void> | null = null;
    const requestConfig = {
      transformRequest: angular.identity,
      headers: { 'Content-Type': undefined },
      timeout: options.canceler ? options.canceler.promise : FILES_TIMEOUT_SEND,
    };

    if (options.canceler) {
      autoStop = this.$timeout(options.canceler.resolve, FILES_TIMEOUT_SEND);
    }

    form.append('file', file);
    form.append('fileNameOverride', options.fileNameOverride || '');
    form.append('tags_ids', JSON.stringify(options.tags_ids) || '[]');

    return this.getUrl(
      fileId || this.objectIdService.create(),
      isAsset,
      options.config
    )
      .then((url) => this.$http.put(url, form, requestConfig))
      .then(() => {
        if (autoStop) {
          this.$timeout.cancel(autoStop);
        }
      });
  }

  downloadBunch(
    files: (ImageSource & CampaignFile)[],
    folderPath: string
  ): ng.IPromise<string | void | (ImageSource & CampaignFile)> {
    const totalFilesSize = files.reduce(
      (size, file) => size + (typeof file.size === 'number' ? file.size : 0),
      0
    );

    return this.filesSystemService
      .checkDeviceFreeSpace(totalFilesSize)
      .then(() =>
        files.reduce(
          (promise, file) =>
            promise.then(() => {
              const ext = file.mime_type.split('/')[1];
              const finalExt = ext === 'png' ? 'jpg' : ext;
              const fileOptions = {
                directory: this.filesSystemService.getDeviceFilePath(),
                path: folderPath,
                name: `${file._id}.${finalExt}`,
              };

              return this.download(fileOptions, file);
            }),
          this.$q.resolve<string>('')
        )
      );
  }

  // eslint-disable-next-line max-params
  download(
    destPath: string | DownloadFileOpts,
    file: ImageSource,
    onProgress?: (progression: DowloadProgression) => void,
    cancelPromise?: ng.IPromise<void>
  ): ng.IPromise<string> {
    return this.getFileBlob(file, onProgress, cancelPromise)
      .then((blob) => this.blobUtil.blobToBase64String(blob))
      .then((base64) => {
        const { path, name } =
          typeof destPath === 'string'
            ? this.filesSystemService.getPathInfos(destPath)
            : destPath;
        const directory =
          typeof destPath !== 'string' ? destPath?.directory : undefined;

        return this.filesSystemService
          .createFile(path, name, base64, directory)
          .then(({ uri }) => {
            this.$log.debug(`File successfully downloaded at: ${uri}`);
            return uri;
          });
      });
  }

  saveTempFile(
    file: File,
    destPath: DownloadFileOpts
  ): ng.IPromise<{ uri: string; name: string; file: File }> {
    const directory =
      destPath.directory || this.filesSystemService.getDeviceFilePath();
    const newFileName = destPath.name || file.name;

    return this.blobUtil.blobToBase64String(file).then((base64) => {
      return this.filesSystemService
        .createFile(destPath.path, newFileName, base64, directory)
        .then(({ uri }) => {
          this.$log.debug(`File successfully downloaded at: ${uri}`);
          return { uri, name: newFileName, file };
        });
    });
  }

  /**
   * Get file url
   */
  getUrl(
    fileId: ObjectId,
    isAsset = false,
    config?: { pov: string }
  ): ng.IPromise<string> {
    const path = isAsset ? '/digitalAssets/' : '/files/';

    return this.sfPOVService.pBuildURL(path + fileId, config);
  }

  getFileBlob(
    file: ImageSource,
    onProgress?: (progression: DowloadProgression) => void,
    abortPromise?: ng.IPromise<void>
  ): ng.IPromise<Blob> {
    return this.$q
      .when(file.getDownloadUrl())
      .then((url) =>
        this.$http<Blob>({
          method: 'GET',
          url,
          responseType: 'blob',
          timeout: abortPromise ? abortPromise : FILES_TIMEOUT_SEND,
          eventHandlers: {
            progress: (event) => {
              const { loaded, total } = event as Event & {
                loaded: number;
                total: number;
              };

              if (onProgress) {
                onProgress({ loaded, total });
              }
            },
          },
        })
      )
      .then(({ data }) => data);
  }

  deleteFile(fileId: ObjectId, isAsset?: boolean): ng.IPromise<void> {
    return this.getUrl(fileId, isAsset).then((url) => {
      this.$http.delete(url);
    });
  }
}
