import hexRgba from 'hex-rgba';

const GRAPH_COLOR_OPACITY = 20;

export function ChartService(SF_NUMBERS_SCALE, SF_SCALE_COLORS) {
  'ngInject';
  const RADAR_EXCLUDE_MIN_LENGTH = 2;
  const RADAR_EXCLUDE_MAX_LENGTH = 10;
  const FIXED_VALUE = 2;
  const LABEL_MAX_LENGTH = 13;

  return {
    defineGraphType,
    dataToOnePointScatter,
    dataToRadar,
    getGridColor,
    getDefaultColor,
    getValueColor,
  };

  function defineGraphType(graphData) {
    return graphData.length > RADAR_EXCLUDE_MIN_LENGTH &&
      graphData.length < RADAR_EXCLUDE_MAX_LENGTH
      ? 'radar'
      : graphData.length === RADAR_EXCLUDE_MIN_LENGTH
      ? 'scatter'
      : 'other';
  }

  function dataToOnePointScatter(data) {
    const xData = data[1];
    const yData = data[0];

    return {
      points: [
        {
          x: xData.data.numerator,
          y: yData.data.numerator,
        },
      ],
      axes: {
        x: {
          max: xData.data.denominator,
          label: truncateLabel(xData.title),
        },
        y: {
          max: yData.data.denominator,
          label: truncateLabel(yData.title),
        },
      },
    };
  }

  function dataToRadar(data) {
    return {
      points: data.map((item) =>
        (100 * item.data.percent).toFixed(FIXED_VALUE)
      ),
      labels: data.map((item) => truncateLabel(item.title)),
    };
  }

  function getGridColor() {
    return hexRgba(SF_SCALE_COLORS.GRID, GRAPH_COLOR_OPACITY);
  }
  function getDefaultColor() {
    const DEFAULT_COLORS = [
      SF_SCALE_COLORS.BAD,
      SF_SCALE_COLORS.MEDIUM,
      SF_SCALE_COLORS.GOOD,
    ];

    return DEFAULT_COLORS.map((color) => hexRgba(color, GRAPH_COLOR_OPACITY));
  }

  function getValueColor(value) {
    return SF_NUMBERS_SCALE.LOW_LIMIT >= value
      ? SF_SCALE_COLORS.BAD
      : SF_NUMBERS_SCALE.HIGH_LIMIT <= value
      ? SF_SCALE_COLORS.GOOD
      : SF_SCALE_COLORS.MEDIUM;
  }

  function truncateLabel(label) {
    return label.length > LABEL_MAX_LENGTH
      ? label.substr(0, LABEL_MAX_LENGTH) + ' ...'
      : label;
  }
}
