import { SfFeatureFlags } from '@simplifield/feature-flags';
import { IAPIList, ObjectId, Subscription } from '../..';
import { PovService } from '../../services/API/POV/pov.service';
import { QueryParams } from '../../services/Utils/CRUD/crud-service';

export type Company = {
  id: ObjectId;
  name: string;
  logo_id?: ObjectId;
  subscription: Subscription;
};

export class MultiOrganisationService {
  /* @ngInject */
  constructor(
    private $http: ng.IHttpService,
    private sfPOVService: PovService
  ) {}

  getOrganisations(params: QueryParams): ng.IPromise<IAPIList<Company>> {
    return this.sfPOVService
      .pBuildURL('/organisations', { pov: 'user' })
      .then((url) =>
        this.$http
          .get<ng.IPromise<IAPIList<Company>>>(url, {
            params,
          })
          .then((result) => result.data)
      );
  }
}
