import { ButtonSelectorController } from './button-selector.controller';

export const ButtonSelectorComponent = {
  bindings: {
    options: '<',
    defaultActiveOption: '<?',
    hasCancel: '<?',
    emptyLabel: '@?',
    color: '@',
    onSelect: '&',
    onSelectorOpen: '&?',
    onSelectorCancel: '&?',
    isScrollable: '<?',
  },
  templateUrl: 'components/Buttons/button-selector/button-selector.html',
  controller: ButtonSelectorController,
};
