const COMPONENT_DIR = 'chat/chat/components/chat-room-info/components/';
const COMPONENT_FILE_NAME = 'chat-room-add-participants';

export const ChatRoomAddParticipantsComponent = {
  bindings: {
    profile: '<',
    channel: '<',
    onClose: '&',
    addUsersToChannel: '&',
  },
  templateUrl: `${COMPONENT_DIR}${COMPONENT_FILE_NAME}/${COMPONENT_FILE_NAME}.html`,
  controller: function ChatRoomAddParticipantsController(chatService) {
    'ngInject';

    this.$onInit = () => {
      this.usersIds = new Set();
      this.networkError = false;
      this.selectUsersFilters = [
        { name: 'user_id', value: this.getParticipantsIds(), operator: '$nin' },
      ];
    };

    this.getParticipantsIds = () =>
      this.channel.members.map((member) => member.userId);

    this.addMembers = () => {
      this.addUsersToChannel({
        usersIds: Array.from(this.usersIds),
      });
      this.onClose();
    };

    this.onUserSelect = (userId) => this.usersIds.add(userId);

    this.onUserUnselect = (userId) => this.usersIds.delete(userId);

    this.getUserNumberLimit = () =>
      chatService.CHAT_CHANNEL_USER_NUMBER_LIMIT - 1;

    this.getUserNumber = () =>
      this.usersIds.size + this.getParticipantsIds().length - 1;

    this.isUserNumberLimitExceeded = () =>
      this.getUserNumber() > this.getUserNumberLimit();

    this.onUserListLoadingSuccess = () => {
      this.networkError = false;
    };

    this.onUserListLoadingError = () => {
      this.networkError = true;
    };
  },
};
