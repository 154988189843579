import { User } from '../..';
import { AccessRightsService } from '../../services/Utils/AccessRights/access-rights.service';

export type ActionOptions = {
  text: string;
  onClick: () => void;
};

export class TaskCommentsActionsService {
  constructor(
    private $translate: ng.translate.ITranslateService,
    private accessRightsService: AccessRightsService
  ) {
    'ngInject';
  }

  // eslint-disable-next-line complexity
  getCommentActions({
    author_id,
    profile,
    callbacks,
    isPicture,
  }: {
    author_id?: string;
    profile: User;
    isPicture: boolean;
    callbacks: { onEdit?: () => void; onDelete?: () => void };
  }): ActionOptions[] {
    const { onEdit, onDelete } = callbacks;
    const isAdmin = this.accessRightsService.isAdmin();
    const isAuthor = this.isAuthor(author_id, profile);

    const editAction =
      isAuthor && onEdit
        ? [
            {
              text: this.$translate.instant('TASK_COMMENT_EDIT'),
              onClick: () => onEdit(),
            },
          ]
        : [];
    const deleteAction =
      (isAdmin || isAuthor) && onDelete
        ? [
            {
              text: this.$translate.instant('TASK_COMMENT_DELETE'),
              onClick: () => onDelete(),
            },
          ]
        : [];

    return [...editAction, ...deleteAction];
  }

  isAuthor(author_id: string | undefined, profile: User): boolean {
    return author_id === profile._id;
  }
}
