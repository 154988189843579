export const MerchandisingCommentComponent = {
  bindings: {
    pictureIds: '<',
    comment: '<',
    userContents: '<',
  },
  templateUrl:
    'merchandising/components/merchandising-comment/merchandising-comment.html',
  // eslint-disable-next-line max-params
  controller: function MerchandisingCommentController(
    $translate,
    $q,
    imageService,
    merchandisingCommentFactory,
    SF_MERCHANDISING_STATES,
    SF_IMAGE_SIZES
  ) {
    'ngInject';
    const STATE_CHANGE_TRANSLATION = {
      [SF_MERCHANDISING_STATES.VALIDATED]:
        'MERCHANDISING_COMMENT_STATE_CHANGE_VALIDATED',
      [SF_MERCHANDISING_STATES.PENDING]:
        'MERCHANDISING_COMMENT_STATE_CHANGE_PENDING',
    };

    this.$onInit = () => {
      this.commentUser = {
        _id: this.comment.contents.author_id,
        contents: this.userContents,
      };
      this.displayedContent = this.buildCommentContent(
        this.comment,
        this.pictureIds
      );
      this.picturePath = null;
      this.hasState = this.comment.contents.validation.stateSetter;

      return this.comment.contents.picture_id
        ? imageService
            .getSizesHashFromId(this.comment.contents.picture_id, [
              SF_IMAGE_SIZES.SQUARE_LARGE,
              SF_IMAGE_SIZES.SQUARE_BIG,
            ])
            .then((urls) => {
              this.largePicturePath = urls[SF_IMAGE_SIZES.SQUARE_LARGE];
              this.bigPicturePath = urls[SF_IMAGE_SIZES.SQUARE_BIG];
            })
            .catch(() => null)
        : $q.when();
    };

    this.isResponsibleOfValidationStateChange =
      merchandisingCommentFactory.isResponsibleOfValidationStateChange;

    this.buildCommentContent = (comment, pictureIds) => {
      /* Displays comment posted by user */
      if (comment.contents.content) {
        return comment.contents.content;
      }

      /* Displays pictures indexes edited by user */
      if (merchandisingCommentFactory.isWitnessOfPicturesReplacement(comment)) {
        const changedPicturesIndexes =
          comment.contents.validation.changedFiles_ids
            .map((changedFileId) => pictureIds.indexOf(changedFileId) + 1)
            .filter((changedFileIndex) => changedFileIndex > 0)
            .sort();

        return $translate.instant(
          'MERCHANDISING_COMMENT_CHANGED_FILES_INDEXES',
          { changedIndexes: changedPicturesIndexes.join(', ') }
        );
      }

      /* Display default state change message */
      const stateChangeTranslationKey = getStateChangeTranslationKey(
        STATE_CHANGE_TRANSLATION,
        comment.contents.validation.stateSetter
      );

      return $translate.instant(stateChangeTranslationKey);
    };

    function getStateChangeTranslationKey(
      stateChangeTranslationKeysMap,
      state
    ) {
      return state &&
        stateChangeTranslationKeysMap &&
        stateChangeTranslationKeysMap[state]
        ? stateChangeTranslationKeysMap[state]
        : '';
    }
  },
};
