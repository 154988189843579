export const CatalogGoalsComponent = {
  bindings: {
    form: '<',
    campaign: '<',
    report: '<',
    place: '<',
  },
  templateUrl: 'missions/components/catalog-goals/catalog-goals.html',
  controller: function CatalogGoalsController(placesService) {
    'ngInject';
    this.$onInit = () => {
      this.isLoading = true;
      this.reportSalesByCatalog = this.getReportSalesByCatalog();

      return placesService
        .getGoals(this.place._id)
        .then((goals) => {
          this.catalogsGoals = goals
            .filter((goal) =>
              this.report.contents.form_id
                ? goal.form_id === this.report.contents.form_id
                : goal.campaign_id === this.report.contents.campaign_id
            )
            .map((goal) => {
              const sales = this.roundTwoDecimalsNumber(
                this.computeGoalTotal(goal, this.reportSalesByCatalog)
              );

              return {
                sales,
                aim: goal.value,
                name: goal.catalog_name,
                percent: this.getPercentage(sales, goal.value),
              };
            });
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.getReportSalesByCatalog = () => {
      return this.report.contents.answers
        .filter((answer) => answer.type === 'product-sales')
        .reduce((sales, answer) => {
          if (!answer.metadata.catalog_id) {
            return sales;
          }
          if (!sales[answer.metadata.catalog_id]) {
            sales[answer.metadata.catalog_id] = answer.metadata.sellTotalPrice;
            return sales;
          }
          sales[answer.metadata.catalog_id] += answer.metadata.sellTotalPrice;
          return sales;
        }, {});
    };

    this.computeGoalTotal = (goal, reportSales) => {
      return goal.catalogs_ids.reduce(
        (total, catalogId) => total + (reportSales[catalogId] || 0),
        goal.sum
      );
    };

    this.getPercentage = (num, den) => Math.round((num * 100) / den);
    this.roundTwoDecimalsNumber = (value) => Math.round(value * 100) / 100;
  },
};
