export const TasksListSimpleComponent = {
  bindings: {
    profile: '<',
    requestFilters: '<?',
    reloadFn: '=?',
    onReload: '&?',
  },
  templateUrl: 'tasks/components/tasks-list-simple/tasks-list-simple.html',
  controller: function TasksListSimpleController(tasksService, taskService) {
    'ngInject';

    this.$onInit = () => {
      this.tasks = [];
      this.users = {};
      this.places = {};

      this.reloadFn = this.reload;
      return this.callData();
    };

    this.reload = () => {
      return this.callData();
    };

    this.callData = () => {
      this.isLoading = true;
      this.networkError = false;

      return this.getTasks()
        .then((res) => {
          this.tasks = res.entries;
          this.users = res.users;
          this.places = res.places;
          return res;
        })
        .catch((err) => {
          this.networkError = true;
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.getTasks = () => {
      let params = {
        requestFilters: this.requestFilters || [],
      };

      return tasksService.getTasks(params);
    };

    this.getTaskUser = (task) => this.users[task.contents.assignee_id];
    this.getTaskPlace = (task) => this.places[task.contents.place_id];

    this.isAssignee = (assignee_id) => assignee_id === this.profile.id;

    this.onTaskContentClick = (event, taskId) => {
      // the list is composed from item checkboxes
      // we need to prevent default behavior in order not to check/uncheck the checkbox
      event.preventDefault();

      return taskService
        .openTaskDetailsModal(taskId, this.profile, false)
        .then((status) => {
          this.reload();
          if (this.onReload) {
            this.onReload();
          }

          return status;
        });
    };
  },
};
