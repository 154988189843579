import { LocalizationService } from '@services/Utils/Localization/localization.service';
import { ModalService } from '@services/Utils/Modal';
import { pathOr } from 'ramda';

const GET_LIMIT = 20;

export class EventChecklistSelectorController {
  isRTLNeeded;
  dataProvider;
  customDataProvider;
  entities;
  multiple;
  selected;
  entityTitleGet;
  entityDescriptionGet;
  emptyIcon;
  title;
  searchPlaceholder;
  filtersAvailable;
  staticSearchFields;
  onChange;
  onDelete;
  onReload;
  entityProvider;
  searchFields;
  maxSelectedEntities;

  constructor(
    private localizationService: LocalizationService,
    private modalService: ModalService,
    private RequestsPaginate,
    private StaticRequestsPaginate,
    private apiUtilsService,
    private $filter: ng.IFilterService,
    private searchUtilsService
  ) {
    'ngInject';
  }

  $onInit() {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();

    this.dataProvider = this.customDataProvider
      ? this.customDataProvider()
      : this.entities
      ? this.createStaticDataProvider()
      : this.createHttpDataProvider();
  }

  openSelectEntityModal() {
    const template = `
      <sf-entity-selector-modal
        empty-icon="{{ $ctrl.emptyIcon }}"
        search-placeholder="{{ $ctrl.searchPlaceholder }}"
        title="{{ $ctrl.title }}"
        multiple="$ctrl.multiple"
        selected="$ctrl.selected"
        max-selected-entities="$ctrl.maxSelectedEntities"
        max-selected-entities-error="'EVENT_ATTACH_CHECKLIST_MAX_COUNT_ERROR'"
        entity-title-get="$ctrl.entityTitleGet"
        entity-description-get="$ctrl.entityDescriptionGet"
        filters-available="$ctrl.filtersAvailable"
        on-selection-change="$ctrl.onSelectionChange(selection)"
        on-save="$ctrl.onSave()"
        on-query-change="$ctrl.onQueryChange(query)"
        on-close="$ctrl.onClose()"
        on-reload="$ctrl.onReload()"
      ></sf-entity-selector-modal>
    `;

    let selectionResult = this.multiple
      ? [...(this.selected || [])]
      : { ...this.selected };
    const onSingleSelectChange = ({ entity }) => {
      selectionResult = entity;
    };
    const onMultipleSelectionChange = ({ entity, selected }) => {
      selectionResult = selected
        ? selectionResult.concat(entity)
        : selectionResult.filter((el) => el._id !== entity._id);
    };

    return this.modalService.open(template, {
      entityTitleGet: this.entityTitleGet,
      entityDescriptionGet: this.entityDescriptionGet,
      emptyIcon: this.emptyIcon,
      title: this.title,
      searchPlaceholder: this.searchPlaceholder,
      multiple: this.multiple,
      selected: this.selected,
      filtersAvailable: this.filtersAvailable || [],
      maxSelectedEntities: this.maxSelectedEntities,
      onSelectionChange: this.multiple
        ? onMultipleSelectionChange
        : onSingleSelectChange,
      onSave: () => this.onChange({ result: selectionResult }),
      onQueryChange: (query) => this.dataProvider(query),
      onReload: () => this.onReload(),
    });
  }

  deleteCampaign(campaignId) {
    this.onDelete({ result: campaignId });
  }

  createStaticDataProvider() {
    return ({ search }) => {
      const filteredEntities = this.staticSearchFields
        ? this.getStaticFilteredEntities(search)
        : this.$filter('filter')(this.entities, search);

      return new this.StaticRequestsPaginate(filteredEntities, GET_LIMIT);
    };
  }

  createHttpDataProvider() {
    const { buildFilterParams } = this.apiUtilsService;
    const { RequestsPaginate } = this;

    const requestPaginate = new RequestsPaginate(this.entityProvider, {
      limit: GET_LIMIT,
    });

    return ({ search = '', filters = [] }) => {
      const requestFilters = buildFilterParams(filters, {
        search,
        criterias: this.searchFields,
      });

      requestPaginate.reset();

      return {
        load: () => requestPaginate.call(requestFilters),
        canCallMore: () => requestPaginate.canCallMore(),
      };
    };
  }

  getStaticFilteredEntities(search) {
    const filterFn = this.getFilterFn(search);
    const filteredEntities = this.entities
      .map((entity) => {
        const searchField = this.staticSearchFields
          .map((field) => pathOr('', field.split('.'), entity))
          .join(' ');

        return { ...entity, searchField };
      })
      .filter(({ searchField }) => filterFn(searchField));

    return filteredEntities;
  }

  isMaxCampaignsSelected() {
    return this.selected.length >= 5;
  }

  getFilterFn(search) {
    if (!search) {
      return () => true;
    }

    const regStr = this.searchUtilsService.getRegexStringWithDiacritics(search);
    const reg = this.searchUtilsService.getSearchRegex(regStr);

    return (entity) => reg.test(entity);
  }
}
