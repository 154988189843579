import { CorporateEventsSelectorController } from './corporate-events-selector.controller';

export const CorporateEventsSelectorComponent = {
  bindings: {
    emptyIcon: '@',
    placeholder: '@',
    searchPlaceholder: '@',
    title: '@',
    multiple: '<',
    value: '<',
    disabled: '<',
    selected: '<?',
    entities: '<?',
    searchFields: '<?',
    staticSearchFields: '<?',
    entityProvider: '<?',
    entityTitleGet: '<',
    entityDescriptionGet: '<?',
    customDataProvider: '<?',
    onChange: '&',
    onReset: '&',
    onReload: '&',
    onDelete: '&',
    filtersAvailable: '<',
    maxSelectedEntities: '<?',
  },
  templateUrl:
    'components/corporate-events-selector/corporate-events-selector.html',
  controller: CorporateEventsSelectorController,
};
