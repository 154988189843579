"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var errorsLibrary = require('../utils/errors');

var tasksLibrary = {
  validate: tasksLibraryValidate,
  defaults: tasksLibraryDefaults,
  getPriorityValue: getPriorityValue
}; // One liner compilation to avoid lib functions scope pollution

tasksLibrary.validateSchema = new (require('ajv'))().compile(require('./task.schema')); // eslint-disable-line

module.exports = tasksLibrary;

function tasksLibraryDefaults() {
  var task = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultTask = {};
  return _objectSpread({}, defaultTask, {}, task);
}

function tasksLibraryValidate(task) {
  return errorsLibrary.mapAjvErrors(tasksLibrary.validateSchema(task) ? [] : tasksLibrary.validateSchema.errors);
}

function getPriorityValue(contents, _ref) {
  var map = _ref.map;
  var DEFAULT_PRIORITY = 0;

  if (!('priority' in contents)) {
    return DEFAULT_PRIORITY;
  }

  var priorityRegExp = new RegExp(contents.priority.trim(), 'i');
  return Object.keys(map).reduce(function (result, key) {
    return map[key].match(priorityRegExp) ? parseInt(key, 10) : result;
  }, DEFAULT_PRIORITY);
}