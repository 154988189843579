export const ButtonHeaderSpinningComponent = {
  bindings: {
    icon: '@',
    onClick: '&',
    isDisabled: '<',
    isSpinning: '<',
  },
  templateUrl:
    'components/Buttons/button-header-spinning/button-header-spinning.html',
};
