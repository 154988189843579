import { CampaignCompletionFilterModalController } from './campaign-completion-filter-modal.controller';

export const CampaignCompletionFilterModalComponent = {
  bindings: {
    filtersAvailable: '<',
    filters: '<',
    sortBy: '<',
    onFilterChange: '&',
    onSortByChange: '&',
    onClose: '&',
  },
  templateUrl:
    'legacy-campaigns/campaign/components/campaign-completion-filter-modal/campaign-completion-filter-modal.html',
  controller: CampaignCompletionFilterModalController,
};
