"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId(),
    name: 'POST',
    description: 'A POST based web hook.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Users events hook',
      url: 'https://api.mycompany.com/hooks/simplifield',
      method: 'POST',
      headers: [{
        name: 'Authentication',
        value: 'Bearer a_secret_access_token'
      }],
      expectedStatus: 201,
      eventsExchanges: ['A_USER_UPDATED', 'A_USER_CREATED', 'A_USER_DELETED'],
      organisation_id: createObjectId('owner'),
      owner_id: createObjectId('owner')
    }
  }, {
    _id: createObjectId(),
    name: 'PUT',
    description: 'A PUT based web hook.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Users events hook',
      url: 'https://api.mycompany.com/hooks/simplifield',
      method: 'PUT',
      headers: [{
        name: 'Authentication',
        value: 'Bearer a_secret_access_token'
      }],
      expectedStatus: 201,
      eventsExchanges: ['A_USER_UPDATED', 'A_USER_CREATED', 'A_USER_DELETED'],
      organisation_id: createObjectId('owner'),
      owner_id: createObjectId('owner')
    }
  }, {
    _id: createObjectId(),
    name: 'unknow',
    description: 'Unknown contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_UNEXPECTED_CONTENT',
        params: ['.wadup']
      }]
    },
    partial: {
      valid: false
    },
    contents: {
      name: 'Users events hook',
      url: 'https://api.mycompany.com/hooks/simplifield',
      method: 'PUT',
      headers: [{
        name: 'Authentication',
        value: 'Bearer a_secret_access_token'
      }],
      expectedStatus: 201,
      eventsExchanges: ['A_USER_UPDATED', 'A_USER_CREATED', 'A_USER_DELETED'],
      organisation_id: createObjectId('owner'),
      owner_id: createObjectId('owner'),
      wadup: {
        Kikoolol: true
      },
      trololol: 'facepalm'
    }
  }, {
    _id: createObjectId(),
    name: 'empty',
    description: 'Empty contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.name']
      }]
    },
    partial: {
      valid: true
    },
    contents: {}
  }, {
    _id: createObjectId(),
    name: 'noowner',
    description: 'No owner',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.owner_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Users events hook',
      url: 'https://api.mycompany.com/hooks/simplifield',
      method: 'PUT',
      headers: [],
      expectedStatus: 201,
      eventsExchanges: ['A_USER_UPDATED', 'A_USER_CREATED', 'A_USER_DELETED'],
      organisation_id: createObjectId('owner')
    }
  }];
};