export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 7,
    method: updateMethod,
  });

  // @ngInject
  function updateMethod($q, $log, sqlStorageService, localStorageService) {
    const fields = [
      'id NVARCHAR(32) PRIMARY KEY UNIQUE',
      'payload TEXT',
      'name TEXT',
      'distance DOUBLE',
    ].join(',');
    const placeFields = ['id', 'payload'].join(',');

    const createTempTableQuery = `CREATE TEMPORARY TABLE places_backup(${placeFields})`;
    const insertInTempTableQuery = `INSERT INTO places_backup SELECT ${placeFields} FROM places`;
    const dropTableQuery = 'DROP TABLE places';
    const createTableQuery = `CREATE TABLE places(${fields})`;
    const insertInTableQuery = `INSERT INTO places SELECT ${placeFields},NULL as name, NULL as distance FROM places_backup`;
    const dropTempTableQuery = 'DROP TABLE places_backup';

    const update_date_keys = localStorageService
      .keys()
      .filter((key) => key.indexOf('_update_date') !== -1);

    return sqlStorageService
      .execute(createTempTableQuery)
      .then(() => sqlStorageService.execute(insertInTempTableQuery))
      .then(() => sqlStorageService.execute(dropTableQuery))
      .then(() => sqlStorageService.execute(createTableQuery))
      .then(() => sqlStorageService.execute(insertInTableQuery))
      .then(() => sqlStorageService.execute(dropTempTableQuery))
      .then(() =>
        localStorageService.remove.apply(localStorageService, update_date_keys)
      )
      .catch(() => null);
  }
}
