export const ViewContentStatesComponent = {
  bindings: {
    isLoading: '<',
    isEmpty: '<',
    emptyConfig: '<',
    hasLoadError: '<?',
    onReload: '&',
    isFullScreen: '<',
  },
  transclude: true,
  templateUrl: 'components/Layout/view-content-states/view-content-states.html',
  controller: class ViewContentStatesController {
    isLoading: boolean;
    isEmpty: boolean;
    emptyConfig: {
      title: string;
      buttonText: string;
      svg: string;
      onButtonClick: () => void;
    };
    hasLoadError: boolean;
    isFullScreen: boolean;
    viewClass: string;

    $onInit(): void {
      this.viewClass = this.isFullScreen ? 'pane' : 'padding-top';
    }
  },
};
