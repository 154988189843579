import sfPreferences from 'sf-preferences';

// eslint-disable-next-line max-params
export function OrganisationsService(
  $q,
  $http,
  crudFactory,
  sfPOVService,
  profileService,
  sfFeatureFlagsService
) {
  'ngInject';
  const methods = crudFactory('organisations');

  methods.sfPreferences = sfPreferences;

  methods.getApi = getApi;
  methods.getProfileOrganisation = getProfileOrganisation;
  methods.getPreferencesApi = getPreferencesApi;
  methods.getPreference = getPreference;
  methods.setFeatureFlags = setFeatureFlags;

  // NOTE: We overide the getRemote method
  //  (inherited from crudFactory)
  //  that can be used by the crudFactory.get method
  methods.getRemote = getOrgAndPrefsAPI;

  function getOrgAndPrefsAPI(orgId) {
    const promises = [methods.getApi(orgId), methods.getPreferencesApi(orgId)];

    return $q
      .all(promises)
      .then(([organisation, preferences]) =>
        methods.dataStore.saveLocal(
          organisation._id,
          Object.assign(organisation, { preferences })
        )
      );
  }

  function getApi(orgId, params = {}) {
    params.mode = 'compact';

    return sfPOVService
      .pBuildURL(`/organisations/${orgId}`)
      .then((url) => $http.get(url, { params }))
      .then((response) => response.data)
      .then((organisation) => {
        organisation.id = organisation._id;
        return organisation;
      });
  }

  function getPreferencesApi(organisationId) {
    const preferencesUri = `/organisations/${organisationId}/preferences`;

    return sfPOVService
      .pBuildURLByProfile(preferencesUri)
      .then((url) => $http.get(url))
      .then((response) => response.data);
  }

  function getProfileOrganisation(profile) {
    const orgId = profile.contents.organisation_id;

    return methods
      .queryLocal({ _id: orgId })
      .then((organisations) =>
        organisations.length ? organisations[0] : $q.reject()
      );
  }

  function getPreference(key) {
    return profileService
      .getProfile()
      .then((profile) =>
        methods.queryLocal({ _id: profile.contents.organisation_id })
      )
      .then((org) => (org.length ? org[0] : $q.reject()))
      .then(({ preferences = [] }) => {
        const pref = methods.sfPreferences.get(preferences, key);

        return pref !== undefined
          ? pref
          : $q.reject(`Undefined value retrieving preference named ${key}`);
      });
  }

  function setFeatureFlags(organisation) {
    if (organisation) {
      sfFeatureFlagsService.setFlags(organisation.featureFlags);
    }

    return organisation;
  }

  return methods;
}
