"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId(),
    name: 'simplifield',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'SimpliField',
      owner_id: createObjectId('owner')
    }
  }, {
    _id: createObjectId('organisation'),
    name: 'igloosarl',
    description: 'Every data that can be set.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Igloo SARL',
      owner_id: createObjectId('owner'),
      logo_id: createObjectId('logo')
    }
  }, {
    _id: createObjectId(),
    name: 'unknow',
    description: 'Unknown contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_UNEXPECTED_CONTENT',
        params: ['.wadup']
      }]
    },
    partial: {
      valid: false
    },
    contents: {
      name: 'SimpliField',
      owner_id: createObjectId('owner'),
      wadup: {
        Kikoolol: true
      },
      trololol: 'facepalm'
    }
  }, {
    _id: createObjectId(),
    name: 'empty',
    description: 'Empty contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.name']
      }]
    },
    partial: {
      valid: true
    },
    contents: {}
  }, {
    _id: createObjectId(),
    name: 'noowner',
    description: 'No owner',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.owner_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Kikoolol Corp.'
    }
  }];
};