"use strict";

/* eslint no-magic-numbers:0, complexity:0, no-useless-escape:0 */
var jsub = require('jsub');

var esprima = require('esprima');

var esrecurse = require('esrecurse');

var YError = require('yerror');

var formulasUtils = require('./formulas');

var intRegexp = /^([0-9]+)$/;
var numberRegexp = /^([0-9\.+-e]+)$/;
var nameRegexp = /^('|")(([a-z][0-9a-z\-_]*)|([a-f0-9]{24}))('|")$/;
var booleanRegexp = /^(true|false)$/;
var stringRegexp = /^('|")(.*)('|")$/;
var dateRegexp = /^('|")[0-9]{4}\-[0-9]{2}\-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z('|")$/;
var _commonSyntax = [{
  type: 'Program'
}, {
  type: 'ExpressionStatement'
}, {
  type: 'LogicalExpression',
  operator: ['||', '&&']
}, {
  // ex: cast('string', '3') => 3
  type: 'CallExpression',
  $_: _checkCastExpression
}];

var _numberFormulaCheck = jsub.bind(null, {
  conditions: _commonSyntax.concat([{
    // ex: 3 * 4
    type: 'BinaryExpression',
    operator: ['+', '-', '*', '/', '%']
  }, {
    // ex: 3
    type: 'Literal',
    raw: numberRegexp
  }, {
    // ex: Infinity
    type: 'Identifier',
    name: 'Infinity'
  }, {
    // ex: NaN
    type: 'Identifier',
    name: 'NaN'
  }, {
    // ex: -Infinity
    type: 'UnaryExpression',
    operator: ['+', '-']
  }, {
    // ex: getAnswerCount('questionId') => 123
    type: 'CallExpression',
    $_: _checkGetAnswerCountExpression.bind(null, 'number')
  }, {
    // ex: getObjProperty('objName', 'prop1.prop2)
    // ex: getObjProperty('objName', 'prop1.prop2[value=someValue].prop3')
    type: 'CallExpression',
    $_: _checkGetObjPropertyExpression.bind(null, 'number')
  }, {
    // ex: getValue('abbacacaabbacacaabbacaca', 1) => 4
    type: 'CallExpression',
    $_: _checkGetValueExpression.bind(null, 'number')
  }, {
    // ex: getCutomValue('place_params', 'nb_store') => 100
    type: 'CallExpression',
    $_: _checkGetCustomValueExpression.bind(null, 'number')
  }, {
    // ex: contains('string', 'ab', 'abba') ? 1 : 2 => 1
    type: 'ConditionalExpression',
    $_: _checkConditionalExpression.bind(null, 'number')
  }, {
    // ex: hasAnswerValue('abbacacaabbacacaabbacaca', "1") => true
    type: 'CallExpression',
    $_: _checkHasAnswerValueExpression.bind(null, 'number')
  }, {
    // ex: hasNotAnswerValue('abbacacaabbacacaabbacaca', "1") => true
    type: 'CallExpression',
    $_: _checkHasNotAnswerValueExpression.bind(null, 'number')
  }])
});

var _stringFormulaCheck = jsub.bind(null, {
  conditions: _commonSyntax.concat([{
    // ex: "hello"
    type: 'Literal',
    raw: stringRegexp
  }, {
    // ex: 'hello' + ' ' + 'world!'
    type: 'BinaryExpression',
    operator: ['+']
  }, {
    // ex: getObjProperty('objName', 'prop1.prop2)
    // ex: getObjProperty('objName', 'prop1.prop2[value=someValue].prop3')
    type: 'CallExpression',
    $_: _checkGetObjPropertyExpression.bind(null, 'string')
  }, {
    // ex: getValue('abbacacaabbacacaabbacaca', 1) => 'plop'
    type: 'CallExpression',
    $_: _checkGetValueExpression.bind(null, 'string')
  }, {
    // ex: getCutomValue('place_params', 'name') => 'Magasino'
    type: 'CallExpression',
    $_: _checkGetCustomValueExpression.bind(null, 'number')
  }, {
    // ex: contains('string', 'ab', 'abba') ? 'yes' : 'no' => 'yes'
    type: 'ConditionalExpression',
    $_: _checkConditionalExpression.bind(null, 'string')
  }, {
    // ex: hasAnswerValue('abbacacaabbacacaabbacaca', "1") => true
    type: 'CallExpression',
    $_: _checkHasAnswerValueExpression.bind(null, 'string')
  }, {
    // ex: hasNotAnswerValue('abbacacaabbacacaabbacaca', "1") => true
    type: 'CallExpression',
    $_: _checkHasNotAnswerValueExpression.bind(null, 'string')
  }])
});

var _dateFormulaCheck = jsub.bind(null, {
  conditions: _commonSyntax.concat([{
    // ex: "2016-04-04T09:02:15.985Z"
    type: 'Literal',
    raw: dateRegexp
  }, {
    // ex: curDate()
    type: 'CallExpression',
    $_: _checkCurDateExpression
  }, {
    // ex: getObjProperty('objName', 'prop1.prop2)
    // ex: getObjProperty('objName', 'prop1.prop2[value=someValue].prop3')
    type: 'CallExpression',
    $_: _checkGetObjPropertyExpression.bind(null, 'date')
  }, {
    // ex: getValue('abbacacaabbacacaabbacaca', 1) => "2016-04-04T09:02:15.985Z"
    type: 'CallExpression',
    $_: _checkGetValueExpression.bind(null, 'date')
  }, {
    // ex: getCutomValue('place_params', 'date_opening') => "2016-04-04T09:02:15.985Z"
    type: 'CallExpression',
    $_: _checkGetCustomValueExpression.bind(null, 'number')
  }, {
    // ex: contains('string', 'ab', 'abba') ?
    //  "2016-04-04T09:02:15.985Z" :
    //  '1970-01-01T00:00:03.600Z'
    //  => '1970-01-01T00:00:03.600Z'
    type: 'ConditionalExpression',
    $_: _checkConditionalExpression.bind(null, 'date')
  }])
});

var _booleanFormulaCheck = jsub.bind(null, {
  conditions: _commonSyntax.concat([{
    // false
    type: 'Literal',
    raw: booleanRegexp
  }, {
    // ex: !true
    type: 'UnaryExpression',
    operator: ['!']
  }, {
    // ex: infeq('string', getValue('abbacacaabbacacaabbacaca', 1), 'plop') => true
    type: 'CallExpression',
    $_: _checkComparisonExpression
  }, {
    // ex: getObjProperty('objName', 'prop1.prop2)
    // ex: getObjProperty('objName', 'prop1.prop2[value=someValue].prop3')
    type: 'CallExpression',
    $_: _checkGetObjPropertyExpression.bind(null, 'boolean')
  }, {
    // ex: hasValue('abbacacaabbacacaabbacaca', 1) => true
    type: 'CallExpression',
    $_: _checkHasValueExpression.bind(null, 'boolean')
  }, {
    // ex: getValue('abbacacaabbacacaabbacaca', 1) => false
    type: 'CallExpression',
    $_: _checkGetValueExpression.bind(null, 'boolean')
  }, {
    // ex: eq('boolean', true, false) ? true : false => false
    type: 'ConditionalExpression',
    $_: _checkConditionalExpression.bind(null, 'boolean')
  }])
});

var FUNCTIONS = {
  number: _numberFormulaCheck,
  string: _stringFormulaCheck,
  date: _dateFormulaCheck,
  "boolean": _booleanFormulaCheck
};
var syntaxUtils = {
  numberFormulaCheck: _checkFormula.bind(null, 'number'),
  stringFormulaCheck: _checkFormula.bind(null, 'string'),
  dateFormulaCheck: _checkFormula.bind(null, 'date'),
  booleanFormulaCheck: _checkFormula.bind(null, 'boolean'),
  COMPARISONS: {
    number: ['inf', 'infeq', 'eq', 'ne', 'sup', 'supeq'],
    string: ['eq', 'ne', 'contains', 'startsWith', 'endsWith'],
    date: ['inf', 'infeq', 'eq', 'ne', 'sup', 'supeq'],
    "boolean": ['eq', 'ne']
  }
};
module.exports = syntaxUtils;

function _checkCurDateExpression(expression) {
  if (!expression.callee || 'Identifier' !== expression.callee.type || 'curDate' !== expression.callee.name) {
    return [new YError('E_BAD_CALLEE', 'curDate', expression.callee.name)];
  }

  if (0 !== expression.arguments.length) {
    return [new YError('E_BAD_ARGS', expression.arguments.length)];
  }

  return [];
}

function _checkConditionalExpression(type, expression) {
  if (!FUNCTIONS[type]) {
    return [new YError('E_NOT_A_CONDITION')];
  }

  return FUNCTIONS["boolean"](expression.test).concat(FUNCTIONS[type](expression.consequent)).concat(FUNCTIONS[type](expression.alternate));
} // Definition: comparison<type>(type, left<type>, right<type>) : value:Boolen


function _checkComparisonExpression(expression) {
  // Checking there is a comparison type
  if (!expression.arguments[0] || 'Literal' !== expression.arguments[0].type || 'string' !== typeof expression.arguments[0].value) {
    return [new YError('E_NO_TYPE', expression.arguments[0] && expression.arguments[0].type, expression.arguments[0] && expression.arguments[0].value)];
  } // Checking function name


  if (!expression.callee || 'Identifier' !== expression.callee.type || !syntaxUtils.COMPARISONS[expression.arguments[0].value] || -1 === syntaxUtils.COMPARISONS[expression.arguments[0].value].indexOf(expression.callee.name)) {
    return [new YError('E_BAD_CALLEE', syntaxUtils.COMPARISONS[expression.arguments[0].value], expression.callee.name)];
  }

  return FUNCTIONS[expression.arguments[0].value](expression.arguments[1]).concat(FUNCTIONS[expression.arguments[0].value](expression.arguments[2]));
} // Definition: hasValue(id, index) : value


function _checkHasValueExpression(type, expression) {
  // Checking function name
  if (!expression.callee || 'Identifier' !== expression.callee.type || 'hasValue' !== expression.callee.name) {
    return [new YError('E_BAD_CALLEE', 'hasValue', expression.callee.name)];
  } // Checking args count


  if (2 !== expression.arguments.length) {
    return [new YError('E_BAD_ARGS', expression.arguments.length)];
  } // Checking the first arg is a name ref or a question id


  if ('Literal' !== expression.arguments[0].type || !nameRegexp.test(expression.arguments[0].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[0].type, expression.arguments[0].raw)];
  } // Checking the second arg is a field index


  if ('Literal' !== expression.arguments[1].type || !intRegexp.test(expression.arguments[1].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[1].type, expression.arguments[1].raw)];
  }

  return [];
}

function _checkHasAnswerValueExpression(type, expression) {
  // Checking function name
  if (!expression.callee || 'Identifier' !== expression.callee.type || 'hasAnswerValue' !== expression.callee.name) {
    return [new YError('E_BAD_CALLEE', 'hasAnswerValue', expression.callee.name)];
  } // Checking args count


  if (2 !== expression.arguments.length) {
    return [new YError('E_BAD_ARGS', expression.arguments.length)];
  } // Checking the first arg is a question id


  if ('Literal' !== expression.arguments[0].type || !stringRegexp.test(expression.arguments[0].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[0].type, expression.arguments[0].raw)];
  } // Checking the second arg


  if ('Literal' !== expression.arguments[1].type || !stringRegexp.test(expression.arguments[1].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[1].type, expression.arguments[1].raw)];
  }

  return [];
}

function _checkHasNotAnswerValueExpression(type, expression) {
  // Checking function name
  if (!expression.callee || 'Identifier' !== expression.callee.type || 'hasNotAnswerValue' !== expression.callee.name) {
    return [new YError('E_BAD_CALLEE', 'hasNotAnswerValue', expression.callee.name)];
  } // Checking args count


  if (2 !== expression.arguments.length) {
    return [new YError('E_BAD_ARGS', expression.arguments.length)];
  } // Checking the first arg is a question id


  if ('Literal' !== expression.arguments[0].type || !stringRegexp.test(expression.arguments[0].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[0].type, expression.arguments[0].raw)];
  } // Checking the second arg


  if ('Literal' !== expression.arguments[1].type || !stringRegexp.test(expression.arguments[1].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[1].type, expression.arguments[1].raw)];
  }

  return [];
} // Definition: getValue(id, index, fallback<type>) : value<type>


function _checkGetValueExpression(type, expression) {
  // Checking function name
  if (!expression.callee || 'Identifier' !== expression.callee.type || 'getValue' !== expression.callee.name) {
    return [new YError('E_BAD_CALLEE', 'getValue', expression.callee.name)];
  } // Checking args count


  if (1 > expression.arguments.length || 3 < expression.arguments.length) {
    return [new YError('E_BAD_ARGS', expression.arguments.length)];
  } // Checking the first arg is a name ref or a question id


  if ('Literal' !== expression.arguments[0].type || !nameRegexp.test(expression.arguments[0].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[0].type, expression.arguments[0].raw)];
  } // Checking the second arg is a field index if it exists


  if (expression.arguments[1] && ('Literal' !== expression.arguments[1].type || !intRegexp.test(expression.arguments[1].raw))) {
    return [new YError('E_BAD_ARG', expression.arguments[1].type, expression.arguments[1].raw)];
  } // Checking the third arg is a default value of the right type if it exists


  if (expression.arguments[2]) {
    return FUNCTIONS[type](expression.arguments[2]);
  }

  return [];
} // Definition: getCustomValue(entity, name) : value<type>


function _checkGetCustomValueExpression(type, expression) {
  // Checking function name
  if (!expression.callee || 'Identifier' !== expression.callee.type || 'getCustomValue' !== expression.callee.name) {
    return [new YError('E_BAD_CALLEE', 'getCustomValue', expression.callee.name)];
  } // Checking args count


  if (1 > expression.arguments.length || 2 < expression.arguments.length) {
    return [new YError('E_BAD_ARGS', expression.arguments.length)];
  } // Checking the first arg is an entity string


  if ('Literal' !== expression.arguments[0].type || !nameRegexp.test(expression.arguments[0].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[0].type, expression.arguments[0].raw)];
  } // Checking the second arg is a name string


  if ('Literal' !== expression.arguments[1].type) {
    return [new YError('E_BAD_ARG', expression.arguments[1].type, expression.arguments[1].raw)];
  }

  return [];
} // Definition: getAnswerCount(questionId) : value<number>


function _checkGetAnswerCountExpression(type, expression) {
  // Checking function name
  if (!expression.callee || 'Identifier' !== expression.callee.type || 'getAnswerCount' !== expression.callee.name) {
    return [new YError('E_BAD_CALLEE', 'getAnswerCount', expression.callee.name)];
  } // Checking args count


  if (1 !== expression.arguments.length) {
    return [new YError('E_BAD_ARGS', expression.arguments.length)];
  } // Checking the first arg is a question id


  if ('Literal' !== expression.arguments[0].type || !stringRegexp.test(expression.arguments[0].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[0].type, expression.arguments[0].raw)];
  }

  return [];
} // Definition: getObjProperty(entity, name) : value<type>


function _checkGetObjPropertyExpression(type, expression) {
  // Checking function name
  if (!expression.callee || 'Identifier' !== expression.callee.type || 'getObjProperty' !== expression.callee.name) {
    return [new YError('E_BAD_CALLEE', 'getObjProperty', expression.callee.name)];
  } // Checking args count


  if (2 !== expression.arguments.length) {
    return [new YError('E_BAD_ARGS', expression.arguments.length)];
  } // Checking the first arg is a string


  if ('Literal' !== expression.arguments[0].type || !stringRegexp.test(expression.arguments[0].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[0].type, expression.arguments[0].raw)];
  } // Checking the 2nd arg is a string


  if ('Literal' !== expression.arguments[1].type || !stringRegexp.test(expression.arguments[1].raw)) {
    return [new YError('E_BAD_ARG', expression.arguments[1].type, expression.arguments[1].raw)];
  }

  return [];
} // Definition: cast(castedValueType, castedValue<castedValueType>) : value<type>


function _checkCastExpression(expression) {
  if (!expression.callee || 'Identifier' !== expression.callee.type || 'cast' !== expression.callee.name) {
    return [new YError('E_NOT_A_CAST')];
  }

  if (!expression.arguments[0] || 'Literal' !== expression.arguments[0].type || 'string' !== typeof expression.arguments[0].value || !FUNCTIONS[expression.arguments[0].value]) {
    return [new YError('E_BAD_CAST_OUTTYPE', expression.arguments[0])];
  }

  if (!expression.arguments[1] || 'Literal' !== expression.arguments[1].type || 'string' !== typeof expression.arguments[1].value || !FUNCTIONS[expression.arguments[1].value]) {
    return [new YError('E_BAD_CAST_INTYPE', expression.arguments[1])];
  }

  if (!expression.arguments[2]) {
    return [new YError('E_BAD_CAST_EXPRESSION', expression.arguments[2])];
  }

  return FUNCTIONS[expression.arguments[1].value](expression.arguments[2]);
}

function _checkFormula(type, script, questions) {
  var ast;
  var checkFunction = 'number' === type ? _numberFormulaCheck : 'string' === type ? _stringFormulaCheck : 'date' === type ? _dateFormulaCheck : _booleanFormulaCheck;

  try {
    ast = esprima.parse(script, {
      loc: true
    });
  } catch (err) {
    return [YError.wrap(err, 'E_BAD_SYNTAX')];
  }

  return _visit(type, ast, questions).concat(checkFunction(ast));
}

function _visit(type, ast, questions) {
  var errors = [];
  esrecurse.visit(ast, {
    ConditionalExpression: function ConditionalExpression(node) {
      this.visit(node.consequent);
      this.visit(node.alternate);
      errors = errors.concat(_visit('boolean', node.test, questions));
    },
    CallExpression: function CallExpression(node) {
      var questionId; // Only getValue/hasValue/getAnswerCount/hasAnswerValue/hasNotAnswerValue can dereference a question

      if (node.callee && 'Identifier' === node.callee.type && -1 !== ['getValue', 'hasValue'].indexOf(node.callee.name)) {
        questionId = formulasUtils.getQuestionIdFromName(questions, node.arguments[0].value); // Only getValue needs a typer checking

        if ('getValue' === node.callee.name) {
          errors = errors.concat(_checkFieldReference(type, questions, questionId, node.arguments[1] ? node.arguments[1].value : 0));
        } // Comparisons change the current type

      } else if (node.callee && 'Identifier' === node.callee.type && -1 !== ['getCustomValue', 'hasCustomValue'].indexOf(node.callee.name)) {
        // Only getValue needs a typer checking
        if ('getCustomValue' === node.callee.name) {
          errors = errors.concat(_checkCustomReference(type));
        } // Comparisons change the current type

      } else if (node.callee && 'Identifier' === node.callee.type && ('getAnswerCount' === node.callee.name || 'hasAnswerValue' === node.callee.name || 'hasNotAnswerValue' === node.callee.name)) {
        var questionRef = questions.find(function (question) {
          return question._id.toString() === node.arguments[0].value;
        });

        if (!questionRef) {
          errors.push(new YError('E_BAD_QUESTION_ID'));
        }
      } else if (node.arguments[0] && node.arguments[0].value && node.callee && 'Identifier' === node.callee.type && syntaxUtils.COMPARISONS[node.arguments[0].value] && -1 !== syntaxUtils.COMPARISONS[node.arguments[0].value].indexOf(node.callee.name)) {
        errors = errors.concat(_visit(node.arguments[0].value, node.arguments[1], questions));
        errors = errors.concat(_visit(node.arguments[0].value, node.arguments[2], questions)); // Casts change the current type
      } else if (node.callee && 'Identifier' === node.callee.type && 'cast' === node.callee.name) {
        if (!node.arguments[0] || !node.arguments[0].value || !FUNCTIONS[node.arguments[0].value]) {
          errors.push(new YError('E_BAD_CAST_INTYPE'));
        } else if (!node.arguments[1] || !node.arguments[1].value || !FUNCTIONS[node.arguments[1].value]) {
          errors.push(new YError('E_BAD_CAST_INTYPE'));
        } else {
          errors = errors.concat(_visit(node.arguments[1].value, node.arguments[2], questions));
        }
      } else {
        this.visitChildren(node);
      }
    }
  });
  return errors;
}

function _checkFieldReference(type, questions, id, fieldIndex) {
  var question = questions.reduce(function (theQuestion, aQuestion) {
    if (theQuestion) {
      return theQuestion;
    }

    if (aQuestion._id.toString() === id.toString()) {
      return aQuestion;
    }

    return theQuestion;
  }, null);

  if (null === question) {
    return [new YError('E_BAD_QUESTION_ID', id)];
  } // Currently we only accept required fields with no multiple answers


  if (1 !== question.minimum || 1 !== question.maximum) {
    return [new YError('E_NOT_UNIQUE_QUESTION', id)];
  }

  if (!question.fields[fieldIndex]) {
    return [new YError('E_UNEXISTING_FIELD', id, fieldIndex)];
  }

  if (question.fields[fieldIndex].type !== type) {
    return [new YError('E_BAD_FIELD_TYPE', id, fieldIndex, type)];
  }

  return [];
}

function _checkCustomReference(type) {
  return [];
}