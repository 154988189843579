import { PlaceCampaignsController } from './place-campaigns.controller';

export const PlaceCampaignsComponent = {
  bindings: {
    organisation: '<',
    profile: '<',
    place: '<',
  },
  templateUrl: 'places/place/components/place-campaigns/place-campaigns.html',
  controller: PlaceCampaignsController,
};
