import { EntitySelectorModalController } from './entity-selector-modal.controller';

export const EntitySelectorModalComponent: ng.IComponentOptions = {
  bindings: {
    emptyIcon: '@',
    searchPlaceholder: '@',
    title: '@',
    iconClass: '@',
    multiple: '<',
    selected: '<',
    filtersAvailable: '<',
    entityTitleGet: '<',
    entityDescriptionGet: '<',
    entityGetNumberOfEntities: '<?',
    entityImageGet: '<?',
    showEntityNumber: '<?',
    onImageClick: '<?',
    onClose: '&',
    onSave: '&',
    onReload: '&',
    onQueryChange: '&',
    onSelectionChange: '&',
    maxSelectedEntities: '<?',
    maxSelectedEntitiesError: '<?',
    minSearchLength: '<',
    offlineI18nMessage: '<',
    checkOfflineFn: '&?',
  },
  templateUrl:
    'components/entity-selector/components/entity-selector-modal/entity-selector-modal.html',
  controller: EntitySelectorModalController,
};
