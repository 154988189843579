import { SearchFilterComponent } from './search-filter.component';

export default angular
  .module('sfMobile.components.search-filter', [
    'sfMobile.services.modal',
    'sfMobile.components.search-field',
    'sfMobile.components.filter-modal',
    'sfMobile.components.search-filter-list',
  ])
  .component('sfSearchFilter', SearchFilterComponent).name;
