import { SfFeatureFlags } from '@simplifield/feature-flags';
import { intersection } from 'ramda';
import type {
  CalendarEventsEntity,
  CustomParam,
  CustomParamAPI,
  CustomParameter,
  IAPIList,
  ObjectId,
} from '../../..';
import { CategorizableResourceType } from '../../../categories/services/categories-api/categories.api.factory';
import {
  CategoriesFactory,
  CategoriesService,
} from '../../../categories/services/categories/categories.factory';
import { ButtonSelectorOption } from '../../../components/Buttons/button-selector/button-selector.controller';
import type { FilterCustomParam } from '../../../components/Search/search';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import { DataAccessLayerService } from '../../Utils/CRUD/crud-service';
import { CrudFactory } from '../../Utils/CRUD/crud-service.factory';
import { PovService } from '../POV/pov.service';
import { CUSTOM_PARAM_TYPES } from '../places-params/places-params.service';

type ParamWithFormattedValue = CustomParamAPI<CalendarEventsEntity> & {
  name: string;
  value: string;
};

type CategorizedParam = {
  label: string;
  params: ParamWithFormattedValue[];
};

export class CalendarEventsParamsService {
  crud: DataAccessLayerService<CustomParamAPI<CalendarEventsEntity>>;
  pathName = '/customParams/calendarEvents';
  categoriesService: CategoriesService;

  constructor(
    private $http: ng.IHttpService,
    crudFactory: CrudFactory<CustomParamAPI<CalendarEventsEntity>>,
    private sfPOVService: PovService,
    databaseSchema,
    private categoriesFactory: CategoriesFactory,
    private $translate: ng.translate.ITranslateService,
    private dateFormatService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {
    'ngInject';
    const tableConfig = databaseSchema.tables.calendar_events_params;

    this.crud = crudFactory(tableConfig.table_name, {
      path_name: this.pathName,
      take_care_of_user_profile: false,
    }) as DataAccessLayerService<CustomParamAPI<CalendarEventsEntity>>;

    this.crud.registerHook('listApi:after', this.setNameForIds);

    this.categoriesService = this.categoriesFactory(
      CategorizableResourceType.CALENDAR_EVENTS_PARAMS
    );
  }

  loadCategories(): ng.IPromise<ButtonSelectorOption[]> {
    return this.categoriesService
      .getCategories()
      .then((categories) => {
        const paramsCategories = [
          {
            id: null as unknown as ObjectId,
            label: this.$translate.instant('PLACE_PARAM_EMPTY_CATEGORY'),
          },
          ...categories.map(({ _id, name }) => ({
            id: _id,
            label: name,
          })),
        ];
        return paramsCategories;
      })
      .catch(() => {
        return [];
      });
  }

  getCategorizedParams(
    customParams: IAPIList<CustomParamAPI<CalendarEventsEntity>>,
    categories: ButtonSelectorOption[],
    calendarEventsParams: CustomParameter[]
  ): CategorizedParam[] {
    const sortViewPosition = (
      paramA: CustomParamAPI<CalendarEventsEntity>,
      paramB: CustomParamAPI<CalendarEventsEntity>
    ) => {
      return (
        (paramA.contents.viewPosition || 0) -
        (paramB.contents.viewPosition || 0)
      );
    };
    const addParamValue = this.addParamValue(calendarEventsParams);
    const paramsWithCategories: ParamWithFormattedValue[] = [];
    const paramsWithOutCategories: ParamWithFormattedValue[] = [];
    customParams.entries.forEach((param) => {
      const hasCategory =
        param.contents.category_id &&
        categories.some(({ id }) => id === param.contents.category_id);
      const paramWithValue = addParamValue(param);
      if (hasCategory) {
        paramsWithCategories.push(paramWithValue);
        return;
      }
      paramsWithOutCategories.push(paramWithValue);
    });

    const categorizedParams: CategorizedParam[] = [];

    categories.forEach((cat, i) => {
      if (!cat.id && i === 0) {
        categorizedParams.push({
          label: cat.label,
          params: paramsWithOutCategories.sort(sortViewPosition),
        });
        return;
      }
      const params = paramsWithCategories
        .filter((param) => param.contents.category_id === cat.id)
        .sort(sortViewPosition);

      categorizedParams.push({ label: cat.label, params });
    });
    return categorizedParams.filter(
      (categorizedParams) => categorizedParams.params.length !== 0
    );
  }

  private addParamValue(calendarEventsParams: CustomParameter[]) {
    return (customParam: CustomParamAPI<CalendarEventsEntity>) => {
      const calendarEventParam = calendarEventsParams?.find(
        (p) => p.param_id === customParam._id
      );
      const formattedValue = this.formatParamValueToString(
        customParam.contents,
        calendarEventParam?.value
      );
      return {
        ...customParam,
        name: customParam.contents.externalKey,
        value: formattedValue,
      };
    };
  }

  formatParamValueToString(
    param: CustomParam<CalendarEventsEntity>,
    paramValue?: CustomParameter['value']
  ): string {
    if (!paramValue || !param) return '';

    let formattedValue;
    switch (param.type) {
      case CUSTOM_PARAM_TYPES.SINGLE:
        formattedValue = param.predefinedValues?.find(
          ({ id }) => id === paramValue
        )?.value as string;
        break;
      case CUSTOM_PARAM_TYPES.MULTIPLE:
        formattedValue = param.predefinedValues
          ?.filter(({ id }) => (paramValue as string[]).includes(id))
          .map(({ value }) => value)
          .join(', ') as string;
        break;
      case CUSTOM_PARAM_TYPES.DATE:
        formattedValue = this.dateFormatService.getEventParamDateFormatted(
          new Date(paramValue as string)
        );
        break;
      case CUSTOM_PARAM_TYPES.NUMBER:
      case CUSTOM_PARAM_TYPES.STRING:
      default:
        formattedValue = paramValue;
        break;
    }

    return formattedValue;
  }

  hasFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEW_PARAMS_TYPES
    );
  }

  hasAlphaFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEW_PARAMS_TYPES_ALPHA
    );
  }

  areNewTypesAllowed(): boolean {
    return this.hasAlphaFeatureFlag() || this.hasFeatureFlag();
  }

  filterLocalParams(
    filter: { param_id: string; value?: string },
    value: { param_id: string; value: string }
  ): boolean {
    if (Array.isArray(filter.value) && Array.isArray(value.value)) {
      return (
        value.param_id === filter.param_id &&
        Boolean(intersection(filter.value, value.value).length)
      );
    }
    if (filter.value) {
      return (
        value.param_id === filter.param_id &&
        value?.value?.toLowerCase().includes(filter.value.toLowerCase())
      );
    }

    return value.param_id === filter.param_id;
  }

  setNameForIds(params) {
    params.entries = params.entries.map((refreshData) => {
      refreshData.id = refreshData.name;
      return refreshData;
    });
    return params;
  }

  listCalendarEventsParamsKeys(): ng.IPromise<
    IAPIList<CustomParamAPI<CalendarEventsEntity>>
  > {
    const url = this.pathName;
    const useUserProfile = false;
    const queryParams: Record<string, unknown> = {};
    queryParams['withNewTypes'] = true;

    return this.crud.simpleApiList(url, queryParams, useUserProfile, {
      pov: 'organisation',
    });
  }

  listFiltersParams(): ng.IPromise<FilterCustomParam[]> {
    return this.listCalendarEventsParamsKeys()
      .catch(() => this.crud.listLocal().then((list) => ({ entries: list })))
      .then((params) => {
        return params.entries.map((p) => {
          return {
            id: p._id || p.id,
            type: p.contents.type,
            label: p.contents.label.en,
            values: p.contents.predefinedValues,
          };
        });
      });
  }

  getParamById(idToFind: string): ng.IPromise<FilterCustomParam | undefined> {
    return this.listFiltersParams().then((params) => {
      return params.find(({ id }) => idToFind === id);
    });
  }

  apiListAsOrg() {
    const queryParams = {
      withNewTypes: true,
    };

    return this.crud
      .apiList(queryParams, this.pathName, {
        pov: 'organisation',
      })
      .then((res) => {
        return res;
      });
  }

  static buildCustomParamFilters(
    filters,
    calendarEventsParamsHash: Record<string, FilterCustomParam>
  ): Record<string, string> {
    return filters.reduce((output, filter) => {
      let value = filter.value;
      if (
        calendarEventsParamsHash[filter.id]?.values &&
        filter.paramType === 'SingleChoice'
      ) {
        const filteredPredefinedValue = calendarEventsParamsHash[
          filter.id
        ]?.values?.find(({ id }) => id === filter.value);
        value = filteredPredefinedValue?.id || '';
      }
      if (
        calendarEventsParamsHash[filter.id]?.values &&
        filter.paramType === 'MultipleChoice'
      ) {
        const filterPredefinedValues = calendarEventsParamsHash[
          filter.id
        ]?.values?.filter(
          ({ id }) =>
            id === filter.value.find((predefinedId) => predefinedId === id)
        );
        value = filterPredefinedValues?.map(({ id }) => id) || [];
      }
      output[filter.id] = value;
      return output;
    }, {});
  }
}
