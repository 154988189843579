import { forms as formsSchema } from '@simplifield/schema';

export const GraphScoringComponent = {
  bindings: {
    form: '<',
    report: '<',
  },
  templateUrl: 'missions/components/Scoring/graph-scoring/graph-scoring.html',
  controller: class GraphScoringController {
    constructor(reportScoringService) {
      'ngInject';
      Object.assign(this, {
        reportScoringService,
      });
      this.formsSchema = formsSchema;
    }

    $onInit() {
      this.formTree = this.formsSchema.formToTree(this.form.contents);
      this.scoringData = (this.formTree.sections || []).reduce(
        (acc, section) =>
          acc.concat(
            this.reportScoringService.computeScoring(
              this.form,
              this.report,
              section
            )
          ),
        []
      );
      this.scorePointsHash = this.buildScorePointsHash();
    }

    meanPercent(scoreArray) {
      const scoredArray = scoreArray.filter((score) => score.data.denominator);
      const percentsSum = scoredArray.reduce((sum, score) => {
        sum += score.data.percent;
        return sum;
      }, 0);

      return scoredArray.length
        ? Math.round((percentsSum * 100) / scoredArray.length)
        : 0;
    }

    buildScorePointsHash() {
      return this.scoringData
        .filter((score) => score.scoring && score.scoring.length)
        .reduce((hash, score) => {
          hash[score.section._id] = this.getScorePoints(score.scoring);
          return hash;
        }, {});
    }

    getScorePoints(scoring) {
      if (!scoring || !scoring.length) {
        return null;
      }

      const points = scoring.reduce((pointsSum, score) => {
        pointsSum += score.data.numerator;
        return pointsSum;
      }, 0);

      return this.formatPrecision(points, 2);
    }

    isValidValue(value) {
      return value !== {}.undef && value !== null;
    }

    formatPrecision(number, precision) {
      return (
        Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision)
      );
    }
  },
};
