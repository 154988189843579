import { APIStore } from '@places/index';
import { ObjectId, User } from 'app';
import hash from 'object-hash';
import { indexBy, isNil, prop } from 'ramda';
import { Campaign, CampaignForm } from '../campaigns/campaigns';
import { DraftReport } from './draft-reports';

async function getReportHash(context: {
  form: { id: string; contents: CampaignForm };
  place: APIStore;
  checklist: Campaign;
  user: User;
}): Promise<{ place: string; user: string; checklist: string }> {
  const { checklist, user, place, form } = context;

  const userHash = getUserHash(user);
  const placeHash = isNil(place) ? '' : getPlaceHash(place);
  const checklistHash = getChecklistHash(checklist, form.contents);

  return {
    user: userHash,
    place: placeHash,
    checklist: checklistHash,
  };
}

function getPlaceHash(place: APIStore): string {
  const contents = {
    name: place.contents.name,
    params: place.contents.params,
    deleted: place.deleted ?? false,
  };

  return hashObject(contents);
}

function getUserHash(user: User): string {
  const contents = {
    places_ids: user.contents.places_ids,
    params: user.contents.params,
    storeScope: {
      placesLists_ids: user.contents.storeScope?.placesLists_ids || [],
      places_ids: user.contents.storeScope?.places_ids || [],
    },
    firstName: user.contents.firstName,
    lastName: user.contents.lastName,
  };

  return hashObject(contents);
}

function serializeForm(form: CampaignForm): {
  questions: Record<string, unknown>;
  sections: Record<string, unknown>;
} {
  return {
    questions: indexById(form.questions),
    sections: indexById(form.sections),
  };
}

function getChecklistHash(checklist: Campaign, form: CampaignForm): string {
  const contents = {
    name: checklist.contents.name,
    subjects: checklist.contents.subjects || [],
    state: checklist.contents.state,
    disabled: checklist.contents.disabled ?? false,
    deleted: checklist.deleted ?? false,
    deactivation_date: checklist.contents.deactivation_date ?? null,
    form: serializeForm(form),
  };

  return hashObject(contents);
}

function indexById(array: { _id: ObjectId }[]): Record<string, unknown> {
  return indexBy(prop('_id'), array);
}

function hashObject(object: Record<string, unknown>): string {
  return hash(JSON.parse(JSON.stringify(object)), { respectType: false });
}

export function getHashedReport(
  report: DraftReport,
  context: {
    form: { id: string; contents: CampaignForm };
    place: APIStore;
    checklist: Campaign;
    user: User;
  }
): Promise<DraftReport> {
  return getReportHash(context).then((hash) => {
    report.contents.hash = hash;

    return report;
  });
}
