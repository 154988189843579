export const InputDateComponent = {
  bindings: {
    inputName: '@',
    hasError: '<',
    isRequired: '<',
    isDisabled: '<',
    min: '<?',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  templateUrl: 'components/Inputs/input-date/input-date.html',
  controller: function InputDateController(
    $document,
    dateService,
    gesturesService,
    platformService
  ) {
    'ngInject';
    const { convertToUTCDate } = dateService;
    const transformValue = (value) => {
      const second = value.getSeconds();
      const minute = value.getMinutes();
      const hour = value.getHours();
      const day = value.getDate();
      const month = value.getMonth();
      const year = value.getFullYear();
      const newDate = new Date(
        Date.UTC(year, month, day, hour, minute, second)
      );

      return newDate.toISOString();
    };

    this.$onInit = () => {
      if (platformService.isiOS()) {
        // This is required because iOS doesn't blur input date after selection,
        // resulting in the calendar being displayed inappropriately
        const body = $document[0].querySelector('body');

        if (body) {
          this.onTouchEventListener = gesturesService.createGesture({
            el: body,
            events: [
              {
                name: 'touch',
                cb: () => {
                  if (
                    $document[0].activeElement &&
                    $document[0].activeElement.name === this.inputName
                  ) {
                    $document[0].activeElement.blur();
                  }
                },
              },
            ],
          });
        }
      }

      this.modelCtrl.$render = () => {
        const modelValue = this.modelCtrl.$viewValue;

        this.value = modelValue ? convertToUTCDate(modelValue) : modelValue;
      };
      this.modelCtrl.$validators.minDate = this.minDateValidator;
    };
    this.$onDestroy = () => {
      if (this.onTouchEventListener) {
        this.onTouchEventListener();
      }
    };

    this.onValueChange = (value) => {
      this.modelCtrl.$setViewValue(value ? transformValue(value) : value);
    };

    this.isDateGreaterThanMin = (value) =>
      this.min ? new Date(value).getTime() > this.min.getTime() : true;

    this.minDateValidator = (modelValue, viewValue) => {
      const value = modelValue || viewValue;

      return this.isDateGreaterThanMin(value);
    };
  },
};
