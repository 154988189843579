const REPORTS_REQ_PARAMS = {
  localStatus: ['ready'],
};

export const MissionsReadyComponent = {
  templateUrl:
    'missions/missions/components/missions-ready/missions-ready.html',
  controller: class MissionsReadyController {
    // eslint-disable-next-line max-params
    constructor(
      $q,
      $translate,
      $state,
      pubSubService,
      dateService,
      ReportSending,
      popupRequestService,
      errorMessagesService,
      ongoingSheet,
      placesService,
      formsService,
      reportsService,
      tasksService,
      placesSourceAdapterService,
      profileService,
      organisationsService
    ) {
      'ngInject';
      Object.assign(this, {
        $q,
        $translate,
        $state,
        pubSubService,
        dateService,
        ReportSending,
        popupRequestService,
        errorMessagesService,
        ongoingSheet,
        placesService,
        formsService,
        reportsService,
        tasksService,
        placesSourceAdapterService,
        profileService,
        organisationsService,
      });
    }

    $onInit() {
      this.isLoading = true;
      this.placesHash = {};
      this.formsHash = {};
      this.reportsReady = [];
      this.readySelected = {};

      this.formListener = this.pubSubService.subscribe(
        'FORM_REPORT_UPDATED',
        () => this.callData()
      );

      this.profileService
        .getProfile()
        .then((profile) =>
          this.organisationsService.getProfileOrganisation(profile)
        )
        .then((organisation) => {
          this.useNewCampaigns = organisation.useNewCampaigns;
          this.READY_POPUP_SAVE_CONFIG = {
            progress: {
              title: this.$translate.instant('READY_SYNC_PENDING_TITLE'),
              desc: this.$translate.instant('READY_SYNC_PENDING_DESC'),
            },
            success: {
              title: this.$translate.instant('READY_SYNC_SUCCEED_TITLE'),
              desc: this.$translate.instant(
                this.useNewCampaigns
                  ? 'READY_SYNC_SUCCEED_DESC_CHECKLISTS'
                  : 'READY_SYNC_SUCCEED_DESC'
              ),
              iconName: 'sync_reports_success',
            },
            error: {
              title: this.$translate.instant(
                this.useNewCampaigns
                  ? 'READY_SYNC_FAIL_TITLE_CHECKLISTS'
                  : 'READY_SYNC_FAIL_TITLE'
              ),
              iconName: 'sync_reports_fail',
            },
          };
        });

      return this.callData().finally(() => {
        this.isLoading = false;
      });
    }

    $onDestroy() {
      this.formListener();
    }

    callData() {
      const reportsPromises =
        this.reportsService.crud.queryLocal(REPORTS_REQ_PARAMS);

      return reportsPromises
        .then((reports) => reports.filter((report) => report.form_id))
        .then((reports) => this.getPlacesHashByIds(reports))
        .then((reports) => this.getFormsHashByIds(reports))
        .then((reports) => this.updateDatas(reports));
    }

    getPlacesHashByIds(reports) {
      return this.placesSourceAdapterService
        .getReportsPlacesHashByIds(reports)
        .then((placesHash) => {
          this.placesHash = placesHash;
          return reports;
        });
    }

    getFormsHashByIds(reports) {
      return this.formsService
        .getHashWithContentKey('form_id', reports)
        .then((hash) => {
          this.formsHash = hash;
          return reports;
        });
    }

    updateDatas(reports) {
      this.reportsReady = reports.sort((reportA, reportB) =>
        this.dateService.compareDates(reportA.saved_date, reportB.saved_date)
      );
    }

    openDeleteConfirm(reportsIds) {
      return this.ongoingSheet.openDeleteConfirm().then(() => {
        this.deleteReports(reportsIds);
        return reportsIds;
      });
    }

    deleteReports(reportsId) {
      const promises = reportsId.map((reportId) =>
        this.reportsService
          .deleteLocally(reportId)
          .then(() => this.tasksService.deleteReportTasks(reportId))
          .then(() => {
            this.reportsReady = this.reportsReady.filter(
              (report) => reportId !== report._id
            );
          })
      );

      this.isDeleting = true;

      return this.$q.all(promises).finally(() => {
        this.isDeleting = false;
        this.readySelected = {};
        this.pubSubService.publish('ONGOING_REPORTS_UPDATED');
      });
    }

    sendReports(reportsId) {
      const reports = this.reportsReady.reduce(
        (output, report) =>
          reportsId.includes(report._id) ? output.concat(report) : output,
        []
      );
      let promise = null;
      const sendPromise = this.$q.defer();

      this.reportsSyncFailed = [];

      const popupRequest = this.popupRequestService.show(
        this.READY_POPUP_SAVE_CONFIG,
        () => sendPromise.resolve(),
        reports.length
      );

      promise = reports.reduce(
        (prevPromise, report) =>
          prevPromise
            .then(() =>
              this.formsService.get(report.contents.form_id).then((form) => {
                const reportSending = new this.ReportSending(form, { report });

                return reportSending.send({ canceler: sendPromise });
              })
            )
            .then(() => {
              this.reportsReady = this.reportsReady.filter(
                (reportReady) => report._id !== reportReady._id
              );
              return this.$q.when();
            })
            .catch((err) => {
              const isBlockingError =
                this.errorMessagesService.isNetworkError(err) ||
                this.errorMessagesService.isServerError(err);

              if (!isBlockingError) {
                this.reportsSyncFailed = this.reportsSyncFailed.concat(report);
              }

              return isBlockingError ? this.$q.reject(err) : this.$q.when();
            })
            .finally(() => {
              popupRequest.onTick();
              this.pubSubService.publish('ONGOING_REPORTS_UPDATED');
            }),
        this.$q.when()
      );

      this.isSending = true;

      return promise
        .then(() =>
          !this.reportsSyncFailed.length
            ? popupRequest.onSuccess()
            : this.$q.reject()
        )
        .catch((err) => {
          const nbFail = this.reportsSyncFailed.length;
          const errorMessage = err
            ? this.errorMessagesService.getMessage(err)
            : this.$translate.instant(
                this.useNewCampaigns
                  ? 'READY_SYNC_FAIL_DESC_CHECKLISTS'
                  : 'READY_SYNC_FAIL_DESC',
                { nbFail }
              );

          popupRequest.onError(
            () => {
              const reportsIds = this.reportsSyncFailed.map(
                (report) => report._id
              );

              return this.sendReports(reportsIds);
            },
            null,
            errorMessage
          );

          throw err;
        })
        .finally(() => {
          this.isSending = false;
          // Keep the failed selected
          this.readySelected = this.reportsSyncFailed.reduce((hash, report) => {
            hash[report._id] = true;
            return hash;
          }, {});
        });
    }

    getSelectedReadysId() {
      return Object.keys(this.readySelected).reduce((output, reportId) => {
        const isSelected = this.readySelected[reportId];

        return isSelected ? output.concat(reportId) : output;
      }, []);
    }

    displayReadyActionsButton() {
      return this.getSelectedReadysId().length;
    }

    edit(report) {
      this.$state.go('index.menu-more.missions.campaign.form', {
        campaignId: report.contents.form_id,
        reportId: report.id,
      });
    }
  },
};
