import { PrintService } from '../Print/print.service';
import { Box } from './typings/box';
import * as blobUtil from 'blob-util';
import { debounce } from '../Helpers/helpers';

async function downloadAsBase64(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return blobUtil.blobToBase64String(blob);
}

export function useMobilePrint(printService: PrintService, box: Box) {
  const boxPrint = box.Preview.prototype.print;

  box.Preview.prototype.print = function () {
    boxPrint.bind(this)();
    const viewer = this.viewer;

    if (viewer.pdfUrl) {
      return downloadAsBase64(this.viewer.pdfUrl).then((base64) => {
        return printService.printPdfBase64(base64).finally(() => {
          return viewer.printPopup.hide();
        });
      });
    } else if (viewer.printframe) {
      const html = viewer.printframe.contentDocument.body.innerHTML;
      const controller = new AbortController(); // it works on iOS Safari since 11.3, this app supports 12+
      const signal = controller.signal;

      const print = () => {
        printService.printHtmlContent(html);
        // it removes event listener and prevents flaky behavior after several taps on print btn
        controller.abort();
      };
      const printDebounced = debounce(print, 1000);

      viewer.printframe.addEventListener('load', printDebounced, { signal });
    }
  };
}
