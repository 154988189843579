import { ObjectId, UserRef } from '../../..';
import { NewsfeedService } from '../../services/newsfeed-service/newsfeed.service';
import { DefaultPostAuthor, NewsfeedPost } from '../../types';

export const ItemHeaderPostComponent = {
  bindings: {
    authorType: '@',
    post: '<',
    isWidget: '<',
  },
  templateUrl: 'newsfeed/components/item-header-post/item-header-post.html',
  controller: class ItemHeaderComponent {
    authorType: 'user' | 'organisation';
    isWidget: boolean;
    post: NewsfeedPost;

    postAuthor: { _id?: ObjectId; contents?: UserRef | DefaultPostAuthor };
    postAuthorName: string;
    subtitles: string[];

    constructor(
      private dateFormatService,
      private newsfeedService: NewsfeedService
    ) {
      'ngInject';
    }

    $onInit() {
      const { contents, category } = this.post;
      const { author } = contents;
      const formattedDate = this.dateFormatService.getNewsfeedDateFormatted(
        this.post.contents.publication_date ?? this.post.created_date
      );

      this.postAuthor = author
        ? { _id: (author as UserRef)?._id, contents: author }
        : {};
      this.postAuthorName = this.newsfeedService.getPostAuthorName(
        author,
        this.authorType
      );

      const categories = category && !this.isWidget ? [category.name] : [];

      this.subtitles = [formattedDate, ...categories];
    }
  },
};
