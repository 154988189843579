import { PubSubService } from '../PubSub/pubsub.service';

type MessageType = 'success' | 'fail';

export class AppMessageService {
  /* @ngInject */
  constructor(private pubSubService: PubSubService) {}

  display(message: string, type: MessageType): void {
    this.pubSubService.publish('APP_MESSAGE:NEW', { message, type });
  }
}
