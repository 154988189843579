import {
  Product,
  ProductsService,
} from '@services/API/products/products.service';
import { ModalService } from '@services/Utils/Modal';
import { Popup, PopupService } from '@services/Utils/Popup/popup.service';
import { IComponentController, IOnChangesObject } from 'angular';
import { IPromise } from 'q';

export const ProductListComponent = {
  bindings: {
    barcode: '<',
    ids: '<',
  },
  templateUrl: 'products/components/product-list/product-list.html',
  controller: class ProductListController implements IComponentController {
    barcode: string;
    ids: string[] = [];

    products: Product[] = [];

    constructor(
      private readonly $q: ng.IQService,
      private readonly $translate: ng.translate.ITranslateService,
      private readonly productsService: ProductsService,
      private readonly modalService: ModalService,
      private readonly popupService: PopupService
    ) {
      'ngInject';
    }

    $onChanges(changes: IOnChangesObject): IPromise<void> {
      if (!changes.barcode && !changes.ids) {
        return this.$q.when();
      }

      if (changes.ids && changes.ids.currentValue?.length) {
        return this.getProductsByIds();
      }

      return this.getProductsByEans();
    }

    openDetails(product: Product): void {
      const template = `
        <sf-product-details-modal
          product="$ctrl.product"
          on-close="$ctrl.onClose()"
        ></sf-product-details-modal>`;

      this.modalService.open(template, { product });
    }

    private getProductsByIds(): IPromise<void> {
      return this.$q
        .when(this.productsService.getByIds(this.ids))
        .then((products = []) => {
          return this.getProductImagesPromises(products);
        })
        .then((products: Product[]) => {
          this.handleProductsResponse(products);
        });
    }

    private getProductsByEans(): IPromise<void> {
      return this.$q
        .when(this.productsService.getByEans([this.barcode]))
        .then((products = []) => {
          return this.getProductImagesPromises(products);
        })
        .then((products: Product[]) => {
          this.handleProductsResponse(products);
        });
    }

    private returnErrorPopUp(): Popup {
      return this.popupService.showError({
        title: this.$translate.instant('FORM_ERROR_NAME'),
        desc: this.$translate.instant('PRODUCT_POPUP_ERROR_DESC'),
        btnText: this.$translate.instant('POPUP_OK'),
        iconName: 'contextual_error',
      });
    }

    private handleProductsResponse(products: Product[]) {
      this.products = products;

      if (products.length === 1) {
        return this.openDetails(products[0]);
      }

      if (!products.length) {
        return this.returnErrorPopUp();
      }
    }

    private getProductImagesPromises(products: Product[]): IPromise<Product[]> {
      return this.$q.all<Product>(
        products.map((product) => {
          return this.productsService
            .getProductImageUrls(product)
            .then((imageUrls) => ({ ...product, imageUrls }));
        })
      );
    }
  },
};
