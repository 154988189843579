import { Modal, ModalService } from '../../../services/Utils/Modal';
import type {
  FilterCustomParam,
  FilterStandard,
  FilterSelected,
} from '../search';

export const SearchFilterComponent = {
  bindings: {
    search: '=',
    searchPlaceholder: '@',
    onSearchChange: '&',
    filters: '=',
    filtersAvailable: '<',
    limit: '<',
    onFiltersChange: '&',
    openCustomModal: '&?',
  },
  templateUrl: 'components/Search/search-filter/search-filter.html',
  controller: class SearchFilterController implements ng.IController {
    // bindings
    filtersAvailable: (FilterStandard | FilterCustomParam)[];
    filters: (FilterStandard | FilterCustomParam)[];
    limit: number;
    onFiltersChange: (args: { filters: FilterSelected[] }) => void;
    openCustomModal?: () => Modal;

    // attributes
    openSortModal: () => Modal;

    constructor(private modalService: ModalService) {
      'ngInject';
    }

    $onInit(): void {
      this.openSortModal = this.openCustomModal || this.openDefaultModal;
    }

    filtersAreAvailable(): boolean {
      return Boolean(this.filtersAvailable && this.filtersAvailable.length);
    }

    openDefaultModal(): Modal {
      const template = `
        <sf-filter-modal
          values="$ctrl.values"
          filters="$ctrl.filters"
          limit="$ctrl.limit"
          on-change="$ctrl.onChange(values)"
          on-close="$ctrl.onClose()">
        </sf-filter-modal>
      `;
      const bindings = {
        values: this.filters,
        filters: this.filtersAvailable,
        limit: this.limit,
        onChange: (values: FilterSelected[]) => {
          this.onFiltersChange({ filters: values });
        },
      };
      const options = {
        animation: 'slide-in-top',
      };

      return this.modalService.open(template, bindings, options);
    }
  },
};
