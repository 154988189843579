import { CorporateEvent } from '../../../../types';
import { DateService } from '../../../../../services/Utils/Dates/date.service';

import { DateFormatService } from '../../../../../services/Utils/Dates/date-format.service';
import {
  EVENT_ITEM_THEMES,
  EventItemThemeEnum,
} from '../../../../constants/event-item-themes.constant';

export class CorporateEventItemController implements ng.IComponentController {
  /** Bindings */
  corporateEvent: CorporateEvent;
  currentDate: Date;
  onDisplayDetails: (data: { event: CorporateEvent }) => void;

  theme: EventItemThemeEnum;
  /** Fields */
  totalDays: number;
  currentDay: number;
  eventDateRangeMessage: string;
  constructor(
    private readonly dateService: DateService,
    private readonly dateFormatService: DateFormatService,
    private SF_EVENT_ITEM_THEMES: typeof EVENT_ITEM_THEMES
  ) {
    'ngInject';
  }

  $onInit(): void {
    if (!this.currentDate) {
      this.currentDate = new Date();
    }
    if (!this.theme) {
      this.theme = this.SF_EVENT_ITEM_THEMES.CALENDAR;
    }
    const { start_date, end_date } = this.corporateEvent.contents;
    this.eventDateRangeMessage = `${this.dateFormatService.getDateFormatted(
      start_date
    )} - ${this.dateFormatService.getDateFormatted(end_date)}`;
    this.computeEventProgress();
  }

  computeEventProgress(): void {
    const { start_date, end_date } = this.corporateEvent.contents;
    this.totalDays = this.dateService.getTotalDaysBetweenTwoDates(
      start_date,
      end_date
    );

    this.currentDay = this.dateService.getTotalDaysBetweenTwoDates(
      start_date,
      this.currentDate
    );
  }

  onItemClick(): void {
    if (
      this.theme === this.SF_EVENT_ITEM_THEMES.CALENDAR ||
      this.theme === this.SF_EVENT_ITEM_THEMES.LINKED_OPERATIONS
    ) {
      this.onDisplayDetails({ event: this.corporateEvent });
    }
  }
}
