import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import {
  Filter,
  FilterValue,
} from '../../services/sales-dashboard-filters-service/sales-dashboard-filters.service';

export class SalesDashboardFilterModalController {
  filters: Filter[];
  selectedValues: Record<string, FilterValue[]>;
  isRTLNeeded: boolean;
  onChange: (selectedValues: Record<string, FilterValue[]>) => void;
  onClose: () => void;

  constructor(
    private $translate: ng.translate.ITranslateService,
    private localizationService: LocalizationService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();

    this.filters = this.filters.map((filter) => {
      if (filter.values) {
        filter.values.sort();
      }
      return filter;
    });
    this.selectedValues = this.selectedValues || {};
  }

  getName(filterValue: FilterValue): string {
    return filterValue.label || '';
  }

  getFilterValue(filterId: string): string | null {
    const selectedValueLength = this.selectedValues[filterId]?.length;

    if (!selectedValueLength) {
      return null;
    }

    if (selectedValueLength === 1) {
      const selectedValue = this.selectedValues[filterId][0];

      return this.getName(selectedValue);
    }

    return this.$translate.instant('SALES_DASHBOARD_FILTER_SELECTED_VALUES', {
      count: selectedValueLength,
    });
  }

  updateFilterValues(result: FilterValue[], filterId: string): void {
    this.selectedValues[filterId] = result;
  }

  resetFilterValues(filterId: string): void {
    this.selectedValues[filterId] = [];
  }

  onSave() {
    this.onChange(this.selectedValues);
    this.onClose();
  }
}
