import { FooterTextInputController } from './footer-text-input.controller';

export const FooterTextInputComponent = {
  bindings: {
    comment: '=',
    placeholder: '@',
    isButtonDisabled: '<?',
    onSubmit: '&',
    onCancel: '&?',
    fileSourceOption: '<?',
    hasCameraButton: '<?',
    onMediaSelect: '&?',
    onKeyboardClose: '&?',
    onKeyboardShow: '&?',
    onBlur: '&?',
    keyboardModeType: '@?',
    closeKeyboardFn: '=?',
    maxChar: '<?',
  },
  transclude: true,
  templateUrl: 'components/footer-text-input/footer-text-input.html',
  controller: FooterTextInputController,
};
