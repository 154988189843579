import { findIndex } from 'ramda';

const CHAT_ROOM_SEND_ERROR_DURATION = 3000;
const CHAT_ROOM_CONNECTION_MESSAGE_DURATION = 3000;

export const ChatRoomComponent = {
  bindings: {
    profile: '<',
    channel: '<',
    onClose: '&',
  },
  templateUrl: 'chat/chat/components/chat-room/chat-room.html',
  // eslint-disable-next-line max-params
  controller: function ChatRoomController(
    localizationService,
    $window,
    $timeout,
    $scope,
    $translate,
    pubSubService,
    $state,
    errorMessagesService,
    dateService,
    modalService,
    networkService,
    chatPopupService,
    chatService,
    contentService,
    LIFECYCLE_EVENTS,
    keyboardService,
    platformService,
    sentryService
  ) {
    'ngInject';

    this.$onInit = () => {
      this.isRTLNeeded = localizationService.shouldActivateRTL();
      this.isChannelDeletedPopupEnable = true;
      this.isUserLeftPopupEnable = true;
      this.isSendingMessage = false;
      this.successMessage = null;
      this.errorMessage = null;
      this.isFileUploading = false;
      this.canBeRefreshed = false;
      this.eventListeners = [];
      this.toasterMessageKey = 'CLIPBOARD_COPY_SUCCESS';

      this.initializeConnectionListeners();
      this.initializeChannelListeners();
      this.initializeLifecycleListeners();
      this.initializeKeyboardListeners();

      this.is1to1Channel = chatService.is1to1Channel(this.channel);
      this.callMessages();
    };
    this.$onDestroy = () => {
      if (this.chatRoomInfoModal) {
        this.chatRoomInfoModal.remove();
      }
      this.unregisterListeners();
    };

    this.unregisterListeners = () => {
      while (this.eventListeners.length) {
        this.eventListeners.pop()();
      }
    };

    this.reload = () => this.callMessages();

    this.initializeConnectionListeners = () => {
      this.eventListeners = this.eventListeners.concat([
        networkService.onConnectionSuccess(this.onConnectionSuccess),
        networkService.onConnectionFail(this.onConnectionFail),
      ]);
    };

    this.initializeChannelListeners = () => {
      this.eventListeners = this.eventListeners.concat([
        chatService.onUserJoined((channel, user) => {
          if (this.channel && this.channel.channelUrl === channel.channelUrl) {
            this.channel.addMember(user);
            $scope.$apply();
          }
        }),
        chatService.onUserLeft((channel, user) => {
          if (this.channel && this.channel.channelUrl === channel.channelUrl) {
            this.channel.removeMember(user);
            $scope.$apply();
          }
        }),
        chatService.connectionListener(
          () => {},
          this.onConnectionSuccess,
          this.onConnectionFail
        ),
      ]);
    };

    this.initializeLifecycleListeners = () => {
      this.eventListeners = this.eventListeners.concat([
        pubSubService.subscribe(LIFECYCLE_EVENTS.RESUME_RESULT, (data) => {
          if (!data || !data.result) {
            return false;
          }
          return this.sendFile(data.result);
        }),
        pubSubService.subscribe(LIFECYCLE_EVENTS.RESUME, () =>
          this.refreshChatRoom()
        ),
      ]);
    };

    this.initializeKeyboardListeners = () => {
      $window.addEventListener('keyboardDidShow', scrollToBottom);

      this.eventListeners = this.eventListeners.concat([
        () => $window.removeEventListener('keyboardDidShow', scrollToBottom),
      ]);
    };

    // ------------------
    // Loading Datas
    // ------------------
    this.callMessages = () => {
      const channelEventKey = this.channel.url;

      this.eventListeners = this.eventListeners.concat([
        chatService.onChannelDeleted(this.onChannelDelete, channelEventKey),
        chatService.onLeave(
          this.profile._id,
          this.onUserLeave,
          channelEventKey
        ),
      ]);

      return this.fetchMessages().then(() => {
        this.eventListeners = this.eventListeners.concat([
          chatService.onChannelMessageReceived(
            this.channel,
            (message) => {
              this.channel.markAsRead();
              if (this.isMessageAuto(message)) {
                return null;
              }
              return $timeout(() => this.addMessageToFeed(message), 0);
            },
            channelEventKey
          ),

          chatService.onMessageDeleted(
            this.channel,
            this.onMessageDeletedHandler,
            channelEventKey
          ),
        ]);

        return true;
      });
    };

    this.onMessageDeletedHandler = (deletedId) => {
      // we use toString here because when sendbird returns a list of messages
      // they have ids with type number
      // but handler is called with a string
      const deletedMessageIndex = findIndex(
        ({ messageId }) => messageId.toString() === deletedId.toString()
      )(this.messages);

      if (deletedMessageIndex === -1) {
        return;
      }

      $timeout(() => {
        this.messages[deletedMessageIndex] = {
          ...this.messages[deletedMessageIndex],
          is_removed: true,
        };
      });
    };

    this.refreshChatRoom = () => {
      this.isLoading = true;
      this.hasLoadingError = false;

      if (chatService.isConnecting()) {
        // The connection listener will reload datas on sendbird reconnection.
        return null;
      }

      return chatService
        .refreshChannel(this.channel)
        .then((channel) => {
          this.channel = channel;
          return this.fetchMessages();
        })
        .catch((err) => {
          this.hasLoadingError = true;
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
          this.resizeScroll();
        });
    };

    this.fetchMessages = () => {
      this.isLoading = true;
      this.hasLoadingError = false;
      this.messages = [];
      this.connectionState = 'idle';

      return chatService
        .getChannelMessages(this.channel)
        .then((messageQuery) => {
          this.connectionState = 'connected';
          this.is1to1Channel = chatService.is1to1Channel(this.channel);
          this.messages = messageQuery.entities.filter(
            (message) => !this.isMessageAuto(message)
          );
          this.messageQuery = messageQuery;
          this.canBeRefreshed = this.messageQuery && this.messageQuery.hasNext;
          this.channel.markAsRead();

          $timeout(() => scrollToBottom(), 0);

          return true;
        })
        .catch((err) => {
          this.hasLoadingError = true;
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.getNextMessages = (hasToResize) => {
      this.infiniteLoadError = false;

      return this.messageQuery
        .next()
        .then((messageQuery) => {
          this.messages = [].concat(messageQuery.entities, this.messages);
          this.messageQuery = messageQuery;

          if (hasToResize) {
            this.resizeScroll();
          }
          return messageQuery.entities;
        })
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        });
    };
    this.isCurrentUser = (message) =>
      message._sender.userId === this.profile._id;
    this.isMessageAuto = (message) => chatService.isMessageAuto(message);
    this.isMessageFile = (message) => chatService.isMessageFile(message);

    // ------------------
    // Display
    // ------------------
    this.displaySender = (message) =>
      !this.isCurrentUser(message) && !this.is1to1Channel;

    this.displayDidiver = (message, index, messages) => {
      const prevMessage = messages[index - 1];

      return (
        !prevMessage ||
        !dateService.isSameDate(message.createdAt, prevMessage.createdAt)
      );
    };

    // ------------------
    // Message
    // ------------------
    this.sendMessage = () => {
      this.errorMessage = null;
      this.isSendingMessage = true;
      return chatService
        .sendChannelMessage(this.commentField, this.channel)
        .then((message) => {
          this.commentField = '';
          this.addMessageToFeed(message);
        })
        .catch((err) => {
          this.errorMessage = errorMessagesService.getMessage(err, {
            customUnknownErrorMessage: 'ERROR_MESSAGE_NETWORK_DESC',
          });

          sentryService.captureMessage('ERROR_MESSAGE_NETWORK', {
            level: 'error',
            extra: {
              origin: 'chat-room.component sendMessage',
              err,
            },
          });

          $timeout(() => {
            this.errorMessage = null;
          }, CHAT_ROOM_SEND_ERROR_DURATION);
        })
        .finally(() => {
          this.isSendingMessage = false;
          platformService.isiOS() && keyboardService.hide(); // close explicily to fix iOS half-black screen
        });
    };

    this.sendFile = (fileBlob) => {
      const fileMessageSending = chatService.sendChannelFileMessage(
        fileBlob,
        this.channel,
        (percent) => popupSending.onProgress(percent)
      );
      const onSendCancel = () =>
        fileMessageSending ? fileMessageSending.abort() : null;
      const popupSending =
        chatPopupService.showFileSendingProgress(onSendCancel);

      this.isFileUploading = true;

      return fileMessageSending.promise
        .then((message) => {
          this.addMessageToFeed(message);
          popupSending.onSuccess();
          return true;
        })
        .catch((err) => {
          const popupErrorOptions = {
            title: $translate.instant('CHAT_ROOM_FILE_SEND_ERROR_TITLE'),
            desc: $translate.instant('CHAT_ROOM_FILE_SEND_ERROR_DESC'),
          };

          popupSending.onError(popupErrorOptions);
          throw err;
        })
        .finally(() => {
          this.isFileUploading = false;
        });
    };

    this.addMessageToFeed = (message) => {
      this.messages = [].concat(this.messages, message);
      scrollToBottom();
    };
    this.canPost = () =>
      this.connectionState === 'connected' && !this.isSendingMessage;

    this.onConnectionSuccess = () => {
      $timeout(() => {
        this.successMessage = $translate.instant(
          'CHAT_ROOM_RECONNECTION_SUCCEEDED'
        );
      }, 0);
      $timeout(() => {
        this.successMessage = null;
      }, CHAT_ROOM_CONNECTION_MESSAGE_DURATION);
      return this.fetchMessages();
    };
    this.onConnectionFail = () => {
      $timeout(() => {
        this.errorMessage = $translate.instant('CHAT_ROOM_RECONNECTION_FAILED');
      }, 0);
      $timeout(() => {
        this.errorMessage = null;
      }, CHAT_ROOM_CONNECTION_MESSAGE_DURATION);
    };
    // ------------------
    // Modals
    // ------------------
    this.openChatRoomInfo = () => {
      const modalTemplate = `
        <sf-chat-room-info
          profile="$ctrl.profile"
          channel="$ctrl.channel"
          on-close="$ctrl.onClose()"
          on-channel-deletion-start="$ctrl.onChannelDeletionStart()"
          on-channel-deletion-fail="$ctrl.onChannelDeletionFail()"
          on-user-leave-start="$ctrl.onUserLeaveStart()"
          on-user-leave-fail="$ctrl.onUserLeaveFail()">
        </sf-chat-room-info>
      `;
      const modalBindings = {
        profile: this.profile,
        channel: this.channel,
        onChannelDeletionStart: this.onChannelDeletionStart,
        onChannelDeletionFail: this.onChannelDeletionFail,
        onUserLeaveStart: this.onUserLeaveStart,
        onUserLeaveFail: this.onUserLeaveFail,
      };

      this.chatRoomInfoModal = modalService.open(modalTemplate, modalBindings);
      return this.chatRoomInfoModal;
    };

    this.onChannelDeletionStart = () => {
      this.isChannelDeletedPopupEnable = false;
    };

    this.onChannelDeletionFail = () => {
      this.isChannelDeletedPopupEnable = true;
    };

    this.onUserLeaveStart = () => {
      this.isUserLeftPopupEnable = false;
    };

    this.onUserLeaveFail = () => {
      this.isUserLeftPopupEnable = true;
    };

    this.getChannelName = () =>
      chatService.getChannelName(this.channel, this.profile);

    this.resizeScroll = () => {
      contentService.resizeById('chatRoomScroll');
    };

    function scrollToBottom() {
      contentService.scrollBottomById('chatRoomScroll');
    }

    // ------------------
    // Events
    // ------------------

    this.onChannelDelete = (channelUrl) => {
      if (
        this.isCurrentChannel(channelUrl) &&
        this.isChannelDeletedPopupEnable
      ) {
        chatPopupService.showChannelDeletedError(() => {
          if (this.chatRoomInfoModal) {
            this.chatRoomInfoModal.remove();
          }
          $state.go('index.chat.list');
        });
      }
    };

    this.onUserLeave = (channel) => {
      if (this.isCurrentChannel(channel.url) && this.isUserLeftPopupEnable) {
        chatPopupService.showUserLeftError(() => {
          if (this.chatRoomInfoModal) {
            this.chatRoomInfoModal.remove();
          }
          $state.go('index.chat.list');
        });
      }
    };

    this.isCurrentChannel = (channelUrl) => this.channel.url === channelUrl;
  },
};
