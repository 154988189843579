import {
  LocalNotificationSchema,
  LocalNotifications,
} from '@capacitor/local-notifications';
import { NOTIFICATION_METAEVENTS } from '../../../notifications/services/notifications/notifications.constant';
import { PubSubService } from '../PubSub/pubsub.service';

export type LocalNotificationOptions = {
  id: number;
  title: string;
  body: string;
  schedule: { at: Date };
};

export class LocalNotificationsService {
  LocalNotifications = LocalNotifications;

  constructor(private pubSubService: PubSubService) {
    'ngInject';
  }

  init(): void {
    this.LocalNotifications.addListener(
      'localNotificationReceived',
      (notification: LocalNotificationSchema): void => {
        const { title, body } = notification;

        this.pubSubService.publish(
          NOTIFICATION_METAEVENTS.NOTIFICATION_DISPLAY,
          {
            title,
            body,
          }
        );
      }
    );
  }
  reset(): void {
    this.cancelAll();
  }

  schedule(notification: LocalNotificationOptions): void {
    const localNotification = {
      ...notification,
      smallIcon: 'res://notification_icon.png',
      iconColor: '#222222',
    };

    this.LocalNotifications.schedule({
      notifications: [localNotification],
    });
  }

  cancel(ids: number[]): void {
    ids?.length &&
      this.LocalNotifications.cancel({
        notifications: ids.map((id) => ({ id })),
      });
  }

  cancelAll(): Promise<void> {
    return this.LocalNotifications.getPending().then((pendingList) => {
      pendingList?.notifications?.length &&
        this.LocalNotifications.cancel(pendingList);
    });
  }
}
