import { QuestionDocumentComponent } from './question-document.component';

export const QuestionDocumentModule = angular
  .module('sfMobile.components.question-document', [
    'pascalprecht.translate',
    'sfMobile.services.objectId',
    'sfMobile.services.files',
    'sfMobile.components.input-file-upload',
  ])
  .component('questionDocument', QuestionDocumentComponent).name;
