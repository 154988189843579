import controller from './newsfeed-updates-info.controller';

export const NewsfeedUpdatesInfoComponent = {
  bindings: {
    unseenPostsCount: '<',
  },
  templateUrl:
    'newsfeed/components/newsfeed-updates-info/newsfeed-updates-info.html',
  controller,
};
