import { _handleError } from '../helpers/error';
import { EXTENTS } from '../helpers/extents';
import { cloneDeep } from '../helpers/utils';
// - setCalculatedDimensions: based on parameters & received dimensions per screenMode
// calculates the best screenMode & currentWidth & currentHeight
export const _setCalculatedDimensions = (dashboardComponent) => {
    if (!dashboardComponent.dimensions) {
        dashboardComponent.params.error = true;
        _handleError('Got no valid dimensions.', dashboardComponent.params);
        return;
    }
    const dashboardDimensionKeys = Object.keys(dashboardComponent.dimensions);
    const availableScreenModeExtents = [];
    let currentMode;
    let currentWidth = 0;
    let currentHeight = 0;
    if (!dashboardComponent.params.switchScreenModeOnResize
        && dashboardComponent.currentScreenMode) {
        dashboardComponent.params.screenMode = dashboardComponent.currentScreenMode;
    }
    // if fixed
    if (dashboardComponent.dimensions
        && dashboardDimensionKeys?.[0] === 'fixed') {
        currentMode = 'fixed';
        currentWidth = dashboardComponent.dimensions.fixed[0];
        currentHeight = dashboardComponent.dimensions.fixed[1];
    }
    // else calculate
    else {
        const screenModes = [
            'mobile',
            'tablet',
            'desktop',
            'largeScreen'
        ];
        // for item embedding, make sure all screenModes exist
        if (dashboardComponent.params.itemEmbedding) {
            const [firstMatch] = dashboardDimensionKeys;
            let match = cloneDeep(firstMatch);
            for (const screenMode of screenModes) {
                const found = dashboardDimensionKeys.find((key) => key === screenMode);
                if (found) {
                    match = cloneDeep(found);
                }
                else {
                    dashboardComponent.dimensions[screenMode]
                        = dashboardComponent.dimensions[match];
                }
            }
        }
        for (const key of dashboardDimensionKeys) {
            if (screenModes.includes(key)) {
                const screenMode = EXTENTS.find((extent) => extent.mode === key);
                availableScreenModeExtents.push({
                    ...screenMode,
                    height: dashboardComponent.dimensions[key][1] || 50
                });
            }
        }
        // check if specified is in available -> if not set to auto
        if (!availableScreenModeExtents
            .map((screenModeExtent) => screenModeExtent.mode)
            .includes(dashboardComponent.params.screenMode)) {
            dashboardComponent.params.screenMode = 'auto';
        }
        // auto calc screenMode that fits & set its width using the available width
        if (dashboardComponent.params.screenMode === 'auto') {
            availableScreenModeExtents.sort((a, b) => screenModes.indexOf(a.mode) - screenModes.indexOf(b.mode));
            for (const screenModeExtent of availableScreenModeExtents) {
                const [screenModeExtentMinWidth, screenModeExtentMaxWidth] = screenModeExtent.width;
                if (dashboardComponent.params.itemEmbedding
                    && dashboardComponent.params.itemDimensions
                    && dashboardComponent.params.itemDimensions.width !== 'auto'
                    && dashboardComponent.params.itemDimensions.height === 'auto') {
                    if (screenModeExtentMinWidth
                        < dashboardComponent.params.itemDimensions.width) {
                        currentMode = screenModeExtent.mode;
                        currentWidth = Number.parseInt(dashboardComponent.params.itemDimensions.width, 10);
                        currentHeight = dashboardComponent.dimensions[currentMode][1];
                    }
                }
                else {
                    if (screenModeExtentMinWidth < dashboardComponent.containerWidth) {
                        currentMode = screenModeExtent.mode;
                        if (dashboardComponent.params.itemEmbedding) {
                            // item embedding
                            currentWidth
                                = dashboardComponent.params.itemDimensions?.width
                                    && dashboardComponent.params.itemDimensions?.width !== 'auto'
                                    ? Number.parseInt(dashboardComponent.params.itemDimensions.width, 10)
                                    : Math.min(dashboardComponent.containerWidth, screenModeExtentMaxWidth);
                            currentHeight
                                = dashboardComponent.params.itemDimensions?.height
                                    && dashboardComponent.params.itemDimensions?.height !== 'auto'
                                    ? Number.parseInt(dashboardComponent.params.itemDimensions.height, 10)
                                    : dashboardComponent.dimensions[currentMode][1];
                            if (dashboardComponent.params.itemDimensions
                                && dashboardComponent.params.itemDimensions.width !== 'auto'
                                && dashboardComponent.params.itemDimensions.height === 'auto') {
                                currentWidth = Number.parseInt(dashboardComponent.params.itemDimensions.width, 10);
                                currentHeight = dashboardComponent.dimensions[currentMode][1];
                            }
                        }
                        else {
                            // normal dashboard embedding
                            currentWidth = Math.max(Math.min(screenModeExtentMaxWidth, dashboardComponent.containerWidth), screenModeExtentMinWidth);
                            currentHeight = screenModeExtent.height;
                        }
                    }
                }
            }
        }
        // set to screenMode and check if width fits in available width
        else {
            const { height: chosenModeHeight, width: [chosenModeMinWidth, chosenModeMaxWidth] } = availableScreenModeExtents.find((d) => d.mode === dashboardComponent.params.screenMode);
            currentMode = dashboardComponent.params.screenMode;
            currentWidth = Math.max(Math.min(chosenModeMaxWidth, dashboardComponent.containerWidth), chosenModeMinWidth);
            currentHeight = chosenModeHeight;
            if (dashboardComponent.params.itemEmbedding) {
                // item embedding
                if (dashboardComponent.params.itemDimensions?.width
                    && dashboardComponent.params.itemDimensions?.width !== 'auto') {
                    currentWidth = Number.parseInt(dashboardComponent.params.itemDimensions.width, 10);
                }
                if (dashboardComponent.params.itemDimensions?.height
                    && dashboardComponent.params.itemDimensions?.height !== 'auto') {
                    currentHeight = Number.parseInt(dashboardComponent.params.itemDimensions.height, 10);
                }
            }
        }
        // if no available mode is found, take the lowest one
        if (!currentMode && availableScreenModeExtents.length > 0) {
            const [lowestModeExtent] = availableScreenModeExtents;
            currentMode = lowestModeExtent.mode;
            currentWidth = lowestModeExtent.width[0];
            currentHeight = lowestModeExtent.height;
        }
    }
    dashboardComponent.currentScreenMode = currentMode;
    const style = {
        height: currentHeight === 0 ? '100%' : `${String(currentHeight)}px`,
        width: currentWidth === 0 ? '100%' : `${String(currentWidth)}px`,
        opacity: 1
    };
    const dashboardEditModes = ['editFull', 'editLimited'];
    // Set dimensions to 100% width and height of container if we're in edit mode
    if (dashboardEditModes.includes(dashboardComponent.params.editMode)) {
        style.height = '100%';
        style.width = '100%';
    }
    else if (dashboardComponent.params.itemEmbedding
        && dashboardComponent.params.itemDimensions) {
        style.height
            = dashboardComponent.params.itemDimensions.height === 'auto'
                ? style.height
                : `${String(dashboardComponent.params.itemDimensions.height).replace('px', '')}px`;
        style.width
            = dashboardComponent.params.itemDimensions.width === 'auto'
                ? style.width
                : `${String(dashboardComponent.params.itemDimensions.width).replace('px', '')}px`;
    }
    else {
        style.height = currentHeight === 0 ? '100%' : `${String(currentHeight)}px`;
        style.width = currentWidth === 0 ? '100%' : `${String(currentWidth)}px`;
    }
    dashboardComponent.currentScreenMode = currentMode;
    dashboardComponent.iframeStyle = style;
};
