export const DividerDateComponent = {
  bindings: {
    date: '<',
  },
  templateUrl: 'components/Divider/divider-date/divider-date.html',
  controller: function DividerDateController(
    $translate,
    dateService,
    dateFormatService
  ) {
    'ngInject';

    this.$onInit = () => {
      this.formatedDate = this.getDividerLabel();
    };

    this.getDividerLabel = () => {
      if (dateService.isToday(this.date)) {
        return $translate.instant('DIVIDER_DATE_TODAY');
      }
      if (dateService.isYesterday(this.date)) {
        return $translate.instant('DIVIDER_DATE_YESTERDAY');
      }
      return dateFormatService.getDateFormatted(this.date);
    };
  },
};
