import { GroupTaskOptionsController } from './group-task-options.controller';

export const GroupTaskOptionsComponent = {
  bindings: {
    onTargetingUpdated: '<',
    groupTask: '<',
  },
  templateUrl: 'group-tasks/group-task-options/group-task-options.html',
  controller: GroupTaskOptionsController,
};
