import ngFileUpload from 'ng-file-upload';
import newsfeedRouteConfig from './newsfeed.routes';

// Services
import { NewsfeedActionsService } from './services/newsfeed-actions/newsfeed-actions.service';
import { NewsfeedApiService } from './services/newsfeed-api/newsfeed-api.service';
import { NewsfeedPopupService } from './services/newsfeed-popup/newsfeed-popup.service';
import { NewsfeedResourcesSelectorService } from './services/newsfeed-resources-selector/newsfeed-resources-selector.service';
import { NewsfeedService } from './services/newsfeed-service/newsfeed.service';
// Components
import { ItemHeaderPostComponent } from './components/item-header-post/item-header-post.component';
import { NewsfeedDefaultPostComponent } from './components/newsfeed-default-post/newsfeed-default-post.component';
import { NewsfeedPostCommentComponent } from './components/newsfeed-post-comment/newsfeed-post-comment.component';
import { NewsfeedPostCommentsComponent } from './components/newsfeed-post-comments/newsfeed-post-comments.component';
import { NewsfeedPostCreationModalComponent } from './components/newsfeed-post-creation-modal/newsfeed-post-creation-modal.component';
import { NewsfeedPostFeedbackComponent } from './components/newsfeed-post-feedback-item/newsfeed-post-feedback.component';
import { NewsfeedPostFormOptionsComponent } from './components/newsfeed-post-form-options/newsfeed-post-form-options.component';
import { NewsfeedPostFormComponent } from './components/newsfeed-post-form/newsfeed-post-form.component';
import { NewsfeedPostItemComponent } from './components/newsfeed-post-item/newsfeed-post-item.component';
import { NewsfeedPostItemWidgetComponent } from './components/newsfeed-post-item/widget/newsfeed-post-item-widget.component';
import { NewsfeedPostLikesModalComponent } from './components/newsfeed-post-likes-modal/newsfeed-post-likes-modal.component';
import { NewsfeedPostSimpleItemComponent } from './components/newsfeed-post-simple-item/newsfeed-post-simple-item.component';
import { NewsfeedResourcesErrorsPopup } from './components/newsfeed-resources-errors-popup/newsfeed-resources-errors-popup';
import { NewsfeedResourcesSelector } from './components/newsfeed-resources-selector/newsfeed-resources-selector.component';
import { NewsfeedUpdatesInfoComponent } from './components/newsfeed-updates-info/newsfeed-updates-info.component';
// Pages
import { NewsfeedPostDetailsComponent } from './views/newsfeed-post-details/newsfeed-post-details.component';
import { NewsfeedComponent } from './views/newsfeed/newsfeed.component';

export default angular
  .module('sfMobile.newsfeed', [
    'sfMobile.services.localization',
    'pascalprecht.translate',
    'monospaced.elastic',
    'sfMobile.constants.files-upload-options',
    'sfMobile.categories',
    'sfMobile.comments',
    'sfMobile.targeting',
    'sfMobile.components.button-floating',
    'sfMobile.components.nav-bar',
    'sfMobile.components.user',
    'sfMobile.components.view-states',
    'sfMobile.components.infinite-scroll',
    'sfMobile.components.button-header-spinning',
    'sfMobile.components.button-item-actions',
    'sfMobile.components.item-header',
    'sfMobile.components.content',
    'sfMobile.components.footer-text-input',
    'sfMobile.components.information-item',
    'sfMobile.services.dates',
    'sfMobile.services.modal',
    'sfMobile.services.profile',
    'sfMobile.services.pov',
    'sfMobile.services.requests-paginate',
    'sfMobile.services.action-sheet',
    'sfMobile.services.popup',
    'sfMobile.services.popup-request',
    'sfMobile.services.helpers',
    'sfMobile.services.objectId',
    'sfMobile.services.segment',
    'sfMobile.services.access-rights',
    'sfMobile.services.file-validator',
    'sfMobile.services.digital-assets',
    'sfMobile.services.pubsub',
    ngFileUpload,
  ])
  .service('newsfeedApiService', NewsfeedApiService)
  .service('newsfeedPopupService', NewsfeedPopupService)
  .service('newsfeedActionsService', NewsfeedActionsService)
  .service('newsfeedService', NewsfeedService)
  .service('newsfeedResourcesSelectorService', NewsfeedResourcesSelectorService)
  .component('sfNewsfeedPostForm', NewsfeedPostFormComponent)
  .component('sfNewsfeedPostFormOptions', NewsfeedPostFormOptionsComponent)
  .component('sfNewsfeedPostCreationModal', NewsfeedPostCreationModalComponent)
  .component('sfNewsfeedPostLikesModal', NewsfeedPostLikesModalComponent)
  .component('sfNewsfeedPostItemHeader', ItemHeaderPostComponent)
  .component('sfNewsfeedPostItem', NewsfeedPostItemComponent)
  .component('sfNewsfeedPostSimpleItem', NewsfeedPostSimpleItemComponent)
  .component('sfNewsfeedPostFeedback', NewsfeedPostFeedbackComponent)
  .component('sfNewsfeed', NewsfeedComponent)
  .component('sfNewsfeedPostDetails', NewsfeedPostDetailsComponent)
  .component('sfNewsfeedPostComments', NewsfeedPostCommentsComponent)
  .component('sfNewsfeedPostComment', NewsfeedPostCommentComponent)
  .component('sfNewsfeedUpdatesInfo', NewsfeedUpdatesInfoComponent)
  .component('sfNewsfeedResourcesSelector', NewsfeedResourcesSelector)
  .component('sfNewsfeedResourcesErrorsPopup', NewsfeedResourcesErrorsPopup)
  .component('sfNewsfeedDefaultPost', NewsfeedDefaultPostComponent)
  .component('sfNewsfeedPostItemWidget', NewsfeedPostItemWidgetComponent)
  .config(newsfeedRouteConfig).name;
