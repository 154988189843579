import { StateService } from '@uirouter/core';
import { Category } from '../..';
import { ModalService } from '../../../services/Utils/Modal';

export class CategoriesNavController implements ng.IComponentController {
  categories: Category[];
  visibleCategories: Category[];
  visibleCategoriesMaxCount = 5;
  otherCategoriesCount = 0;

  constructor(
    private $state: StateService,
    private modalService: ModalService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.initCategories();
  }

  $onChanges(): void {
    this.initCategories();
  }

  initCategories(): void {
    if (!this.categories) {
      return;
    }

    this.otherCategoriesCount =
      this.categories.length - this.visibleCategoriesMaxCount;

    this.visibleCategories = this.getVisibleCategories();
  }

  getVisibleCategories(): Category[] {
    return this.otherCategoriesCount > 0
      ? this.categories.slice(0, this.visibleCategoriesMaxCount)
      : this.categories;
  }

  goToCategory(category_id: string): void {
    this.$state.go('index.newsfeed.category', { category_id });
  }

  openModal(): void {
    const template = `
      <sf-categories-modal
        categories="$ctrl.categories"
        on-close="$ctrl.onClose()"
        on-select="$ctrl.goToCategory(category_id)"
      ></sf-categories-modal>`;

    const modal = this.modalService.open(
      template,
      {
        categories: this.categories,
        goToCategory: (id) => {
          modal.remove();
          this.goToCategory(id);
        },
      },
      { hardwareBackButtonClose: false }
    );
  }
}
