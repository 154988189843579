export default function routeConfig($stateProvider) {
  'ngInject';

  $stateProvider
    .state('login', {
      url: '/login',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('login.page', {
      url: '',
      template: `
        <sf-view class="sf_login">
          <ion-view hide-back-button="true"
            cache-view="false">
            <sf-login>
            </sf-login>
          </ion-view>
        </sf-view>`,
      analyticsName: 'Login',
    });
}
