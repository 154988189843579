import { ConfigService } from '../../services/Utils/Config/config.service';
import { AuthService } from '../../services/API/auth/auth.service';
import { LocalizationService } from '../../services/Utils/Localization/localization.service';
import { PopupService } from '../../services/Utils/Popup/popup.service';

export const LoginComponent = {
  templateUrl: 'login/login/login.html',
  controller: class LoginController {
    isLoading: boolean;
    errorMessage: string | null = null;
    devMode = false;
    devModeEmail?: string;
    configurationArea: 'china' | 'world';
    configurationAreas: {
      label: string;
      iconClass: string;
      value: string;
    }[];

    constructor(
      private authService: AuthService,
      private $q: ng.IQService,
      private configService: ConfigService,
      private localStorageService: ng.local.storage.ILocalStorageService,
      private $window: ng.IWindowService,
      private popupService: PopupService,
      private $translate: ng.translate.ITranslateService,
      private localizationService: LocalizationService
    ) {
      'ngInject';
      this.devMode = authService.isDevMode();
    }

    handleBlockedUser(): void {
      if (this.$window.location.search.indexOf('user%20is%20blocked') > -1) {
        this.popupService.showError({
          title: this.$translate.instant('LOGIN_USER_BLOCKED_TITLE'),
          btnText: this.$translate.instant('LOGIN_USER_BLOCKED_BTN_TEXT'),
          iconName: 'item-danger',
        });
      }
    }

    $onInit(): ng.IPromise<unknown> {
      this.errorMessage = null;
      this.isLoading = true;
      this.configurationArea = 'world';

      this.configurationAreas = [
        {
          label: 'World',
          iconClass: 'fa-light fa-earth-europe sf_login__area_blue',
          value: 'world',
        },
        {
          label: '中国',
          iconClass: 'fa-light fa-earth-asia sf_login__area_red',
          value: 'china',
        },
      ];

      return this.$q
        .all([
          this.configService.shouldUseChinaConfiguration(),
          this.authService.clearAuthMemory(),
        ])
        .then(([shouldUseChinaConfiguration]) => {
          this.configurationArea = shouldUseChinaConfiguration
            ? 'china'
            : 'world';
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
          this.handleBlockedUser();
        });
    }

    /*
     * New Login (Auth0)
     */

    openLogin(): ng.IPromise<unknown> {
      if (this.devModeEmail) {
        this.authService.devModeEmail = this.devModeEmail;
      }
      return this.authService.loginWithAuth0();
    }

    logout(): ng.IPromise<unknown> {
      return this.authService.logout();
    }

    onConfigurationAreaChange(configurationArea: 'china' | 'world'): void {
      this.localStorageService.set('configuration_area', configurationArea);

      if (configurationArea === 'china') {
        this.localizationService.displayAgreementIfNeeded().then(() => {
          this.$window.location.reload();
        });
      }
    }

    inlineErrorMessageUpdater(message: string): void {
      this.errorMessage = message;
    }
  },
};
