export const StatusSelectorComponent = {
  bindings: {
    currentStatus: '<',
    status: '<',
    onClose: '&',
    onSave: '&',
    translations: '<',
  },
  templateUrl: 'components/status-selector/status-selector.html',
  controller: function StatusSelectorController() {
    'ngInject';
    this.$onInit = () => {
      this.selectedStatus = this.status.filter(
        (state) => state.key === this.currentStatus
      )[0];
    };

    this.getHeightStyle = (value) => ({
      height: `${value}%`,
      'min-height': `${value}%`,
    });

    this.validateStatus = () => {
      this.onSave()(this.selectedStatus);
    };
  },
};
