export const PlaceDocumentsComponent = {
  bindings: {
    place: '<',
    reloadFn: '=',
    isNavbarHidden: '<?',
  },
  templateUrl: 'places/place/components/place-documents/place-documents.html',
  controller: function PlaceDocumentsController(documentsService) {
    'ngInject';

    this.$onInit = () => {
      this.reloadFn = this.reload;
      return this.callDocuments();
    };

    this.callDocuments = () => {
      this.documents = [];
      this.isLoadingDocuments = true;
      this.hasError = false;

      return documentsService
        .getByPlaceId(this.place._id)
        .then((documents) => {
          this.documents = documents.entries.map((document) => ({
            _id: document.contents.file_id,
            name: documents.filesMetadata[document.contents.file_id].fileName,
            type: documents.filesMetadata[document.contents.file_id].fileType,
            size: documents.filesMetadata[document.contents.file_id].fileLength,
          }));
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.isLoadingDocuments = false;
        });
    };

    this.hasDocuments = () => !!(this.documents && this.documents.length);
  },
};
