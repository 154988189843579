import { ManagerCalendarEventListItemController } from './manager-calendar-event-list-item.controller';

export const ManagerCalendarEventListItemComponent = {
  bindings: {
    event: '<',
    displayTimezone: '<',
    isActive: '<',
    onClick: '&',
  },
  templateUrl:
    'calendar-events/components/manager-calendar/manager-calendar-event-list-item/manager-calendar-event-list-item.html',
  controller: ManagerCalendarEventListItemController,
};
