import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';

export class AppSettingsService {
  NativeSettings = NativeSettings;

  constructor(private $q: ng.IQService) {
    'ngInject';
  }

  open(): ng.IPromise<{ status: boolean }> {
    return this.$q.resolve(
      this.NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
      })
    );
  }
}
