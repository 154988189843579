import { ObjectId } from '../../..';
import {
  Campaign,
  CampaignFormSection,
} from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';

export const ReactiveCampaignOverviewComponent: ng.IComponentOptions = {
  bindings: {
    campaign: '<',
  },
  templateUrl:
    'reactive-campaigns/components/reactive-campaign-overview/reactive-campaign-overview.html',
  controller: class ReactiveCampaignOverviewController
    implements ng.IController
  {
    /** Bindings */
    campaign: Campaign;

    /** Fields */
    campaignSections: CampaignFormSection[];
    campaignSectionsScore: {
      count: number;
      for_id: ObjectId;
      percent: number;
    }[];
    campaignWholeScore: {
      count: number;
      numerator: number;
      denominator: number;
      percent: number;
    };
    campaignWholeScoreLabels: {
      title: string;
      desc: string;
    };
    campaignWholeScorePercent: string;
    isLoading: boolean;
    networkError: boolean;

    constructor(
      private $translate: ng.translate.ITranslateService,
      private campaignsService: CampaignsService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<void> {
      this.campaignSections = this.campaign.contents.form.sections;

      return this.reload();
    }

    reload(): ng.IPromise<void> {
      this.isLoading = true;
      this.networkError = false;

      return this.campaignsService
        .getReportsStatistics(this.campaign._id)
        .then((stats) => {
          const { whole, sections } = stats.score;

          this.campaignWholeScore = whole;
          this.campaignSectionsScore = sections;

          this.campaignWholeScorePercent = whole
            ? `${Math.round(whole.percent * 100)}`
            : '-';
          this.campaignWholeScoreLabels = {
            title: this.$translate.instant('REACTIVE_CAMPAIGN_AVERAGE_SCORE'),
            desc: `${this.campaignWholeScorePercent}%`,
          };
        })
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    hasScore(): boolean {
      return Boolean(this.campaignWholeScore);
    }
  },
};
