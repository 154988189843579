const DATABASE_CONFIG = {
  name: 'simplifield.db',
  version: 21,
} as const;
export const DATABASE_SCHEMA = {
  defaultFields: [
    {
      name: 'id',
      type: 'NVARCHAR(32)',
      primayKey: true,
      unique: true,
    },
    {
      name: 'payload',
      type: 'TEXT',
    },
  ],
  tables: {
    profile: {
      table_name: 'profile',
    },
    organisations: {
      table_name: 'organisations',
    },
    campaigns: {
      table_name: 'campaigns',
      indexed_fields: [
        {
          name: 'name',
          type: 'TEXT',
        },
        // {
        //   name: 'deactivation_date',
        //   type: 'TEXT',
        // },
      ],
    },
    forms: {
      table_name: 'forms',
      indexed_fields: [
        {
          name: 'needPlace',
          type: 'INTEGER',
        },
        {
          name: 'deleted',
          type: 'INTEGER',
        },
      ],
    },
    places: {
      table_name: 'places',
      indexed_fields: [
        {
          name: 'name',
          type: 'TEXT',
        },
        {
          name: 'distance',
          type: 'DOUBLE',
        },
        {
          name: 'isAssigned',
          type: 'INTEGER',
        },
        {
          name: 'isTemporaryVisible',
          type: 'INTEGER',
        },
        {
          name: 'isTerritoryScope',
          type: 'INTEGER',
        },
      ],
    },
    missions: {
      table_name: 'missions',
      indexed_fields: [
        {
          name: 'place_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'form_id',
          type: 'NVARCHAR(32)',
        },
      ],
    },
    reports: {
      table_name: 'reports',
      indexed_fields: [
        {
          name: 'localStatus',
          type: 'TEXT',
        },
        {
          name: 'form_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'place_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'campaign_id',
          type: 'NVARCHAR(32)',
        },
      ],
    },
    report_versions: {
      table_name: 'report_versions',
      indexed_fields: [
        {
          name: 'report_id',
          type: 'NVARCHAR(32)',
        },
      ],
    },
    draft_reports: {
      table_name: 'draft_reports',
      indexed_fields: [
        {
          name: 'localStatus',
          type: 'TEXT',
        },
        {
          name: 'form_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'place_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'campaign_id',
          type: 'NVARCHAR(32)',
        },
      ],
    },
    comments: {
      table_name: 'comments',
      indexed_fields: [
        {
          name: 'answer_id',
          type: 'NVARCHAR(32)',
        },
      ],
    },
    place_params: {
      table_name: 'place_params',
    },
    products: {
      table_name: 'products',
      indexed_fields: [
        {
          name: 'ean',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'catalog_id',
          type: 'NVARCHAR(32)',
        },
      ],
    },
    tasks: {
      table_name: 'tasks',
      indexed_fields: [
        {
          name: 'assignee_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'owner_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'question_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'form_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'report_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'place_id',
          type: 'NVARCHAR(32)',
        },
      ],
    },
    users: {
      table_name: 'users',
      indexed_fields: [
        {
          name: 'organisation_id',
          type: 'NVARCHAR(32)',
        },
        {
          name: 'owner_id',
          type: 'NVARCHAR(32)',
        },
      ],
    },
    calendar_events: {
      table_name: 'calendar_events',
    },
    calendar_events_params: {
      table_name: 'calendar_events_params',
    },
    calendar_events_types: {
      table_name: 'calendar_events_types',
    },
    calendar_change_requests: {
      table_name: 'calendar_change_requests',
    },
    places_lists: {
      table_name: 'places_lists',
    },
  },
} as const;

export type DB_CONFIG = typeof DATABASE_CONFIG;
export type DB_SCHEMA = typeof DATABASE_SCHEMA;

export default angular
  .module('sfMobile.config.database', [])
  .constant('databaseConfig', DATABASE_CONFIG)
  .constant('databaseSchema', DATABASE_SCHEMA).name;
