import { GroupTaskActionsService } from '../tasks/services/group-task-actions.service';
import { GroupTaskOptionsComponent } from './group-task-options/group-task-options.component';
import { GroupTaskTemplateComponent } from './group-task-template/group-task-template.component';
import { GroupTaskApiService } from './services/group-tasks-api.service';
import { GroupTasksService } from './services/group-tasks.service';

export const GroupTasksModule = angular
  .module('sfMobile.groupTasks', ['sfMobile.services.localization'])
  .service('groupTasksApiService', GroupTaskApiService)
  .service('groupTasksService', GroupTasksService)
  .service('groupTaskActionsService', GroupTaskActionsService)
  .component('sfGroupTaskTemplate', GroupTaskTemplateComponent)
  .component('sfGroupTaskOptions', GroupTaskOptionsComponent).name;
