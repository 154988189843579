export function DocumentsService($http, sfPOVService, apiUtilsService) {
  'ngInject';
  const { buildFilterParams } = apiUtilsService;

  /**
   * Get place documents
   * @param {String} placeId - Id of the place
   * @return {Promise}  - Documents
   */
  function getByPlaceId(placeId, limit) {
    const DOCUMENTS_URL = '/documents';
    const DOCUMENTS_REQ_FILTERS = buildFilterParams([
      { name: 'document_place', value: placeId },
    ]);
    const params = DOCUMENTS_REQ_FILTERS;

    if (limit) {
      params.limit = limit;
    }

    return sfPOVService
      .pBuildURL(DOCUMENTS_URL, { pov: 'organisation' })
      .then((povUrl) => $http.get(povUrl, { params }))
      .then((res) => res.data);
  }

  return {
    getByPlaceId,
  };
}
