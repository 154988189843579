import { CreateCalendarEventModalController } from './create-calendar-event-modal.controller';

export const CreateCalendarEventModalComponent = {
  bindings: {
    eventDate: '<',
    place: '<?',
    onClose: '&',
    onCreate: '&',
  },
  templateUrl:
    'calendar-events/components/calendar/create-calendar-event-modal/create-calendar-event-modal.html',
  controller: CreateCalendarEventModalController,
};
