"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('task1'),
    name: 'Task 1',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      assignee_id: createObjectId('assignee'),
      name: 'task1',
      status: 'todo',
      priority: 0,
      due_date: new Date().toISOString()
    }
  }, {
    _id: createObjectId('task2'),
    name: 'Task 2',
    description: 'Incorect priority value',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.priority', 'should be equal to one of values']
      }]
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      assignee_id: createObjectId('assignee'),
      name: 'task2',
      status: 'todo',
      priority: 3,
      due_date: new Date().toISOString()
    }
  }, {
    _id: createObjectId('task2.1'),
    name: 'Task 2.1',
    description: 'Incorect status value',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.status', 'should be equal to one of values']
      }]
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      assignee_id: createObjectId('assignee'),
      name: 'task2.1',
      status: 'someStatus',
      priority: 0,
      due_date: new Date().toISOString()
    }
  }, {
    _id: createObjectId('task3'),
    name: 'Task 3',
    description: 'Empty name not permitted',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.name', 'should NOT be shorter than 1 characters']
      }]
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      assignee_id: createObjectId('assignee'),
      name: '',
      status: 'todo',
      priority: 0,
      due_date: new Date().toISOString()
    }
  }, {
    _id: createObjectId('task4'),
    name: 'Task 4',
    description: 'Task name longer than 120 characters not permitted',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.name', 'should NOT be longer than 120 characters']
      }]
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      assignee_id: createObjectId('assignee'),
      name: 'a'.repeat(121),
      status: 'todo',
      priority: 0,
      due_date: new Date().toISOString()
    }
  }, {
    _id: createObjectId('task5'),
    name: 'Task 5',
    description: 'If description exists, should not be longer than 1000 chars',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.description', 'should NOT be longer than 1000 characters']
      }]
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      assignee_id: createObjectId('assignee'),
      name: 'title 5',
      description: 'a'.repeat(1001),
      status: 'todo',
      priority: 0,
      due_date: new Date().toISOString()
    }
  }, {
    _id: createObjectId('task6'),
    name: 'Task 6',
    description: 'If there is a place_id, it should be ObjectId',
    upstanding: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      assignee_id: createObjectId('assignee'),
      name: 'title 6',
      place_id: createObjectId('store'),
      status: 'todo',
      priority: 0,
      due_date: new Date().toISOString()
    }
  }, {
    _id: createObjectId('task7'),
    name: 'Task 7',
    description: 'If there is a form_id, it should be ObjectId',
    upstanding: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      assignee_id: createObjectId('assignee'),
      name: 'title 7',
      form_id: createObjectId('form'),
      status: 'todo',
      priority: 0,
      due_date: new Date().toISOString()
    }
  }, {
    _id: createObjectId('task8'),
    name: 'Task 8',
    description: 'If there is a question_id, it should be ObjectId',
    upstanding: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      assignee_id: createObjectId('assignee'),
      name: 'title 8',
      form_id: createObjectId('form'),
      question_id: createObjectId('question'),
      status: 'todo',
      priority: 0,
      due_date: new Date().toISOString()
    }
  }, {
    _id: createObjectId('task9'),
    name: 'Task 9',
    description: 'If there is a report_id, it should be ObjectId',
    upstanding: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      assignee_id: createObjectId('assignee'),
      name: 'title 8',
      report_id: createObjectId('report'),
      question_id: createObjectId('question'),
      status: 'todo',
      priority: 0,
      due_date: new Date().toISOString()
    }
  }];
};