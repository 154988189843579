import { NewsfeedService } from '../newsfeed/services/newsfeed-service/newsfeed.service';
import { AuthService } from '../services/API/auth/auth.service';
import {
  ActionSheetService,
  IActionSheet,
} from '../services/Utils/ActionSheet/action-sheet.service';
import { InAppBrowserService } from '../services/Utils/InAppBrowser/in-app-browser.service';
import { IntercomService } from '../services/Utils/Intercom/intercom.service';
import { LocalizationService } from '../services/Utils/Localization/localization.service';
import { MarketingService } from '../services/Utils/Marketing/marketing.service';
import { PopupService } from '../services/Utils/Popup/popup.service';
import {
  PubSubService,
  UnregisterFn,
} from '../services/Utils/PubSub/pubsub.service';
import { SegmentService } from '../services/Utils/Segment/segment.service';

export class MenuMoreController implements ng.IComponentController {
  // Bindings
  organisation;
  profile;

  // Properties
  appVersion: string;
  hasChangeRequests = false;
  hasHelp = false;
  hasMarketing = false;
  hasMerchandising = false;
  hasNewsfeed = false;
  hasProductChangelog = false;
  hasReportValidation = false;
  hasUnseenPosts = false;
  isRTLNeeded = false;
  menuMore = {};
  useChinaConfiguration: boolean;

  // Badges
  changeRequestsCount: number;
  merchandisingBadge: number;
  unseenPostsCount: number | '99+';
  toReviseCount: number;

  // Listeners
  changeRequestsCountUpdateListener: UnregisterFn;
  dataSyncedDestroyListener: UnregisterFn;
  lastSeenPostInfoUpdateListener: UnregisterFn;
  merchandisingPhotosCountUpdateListener: UnregisterFn;
  organisationSwitchListener: UnregisterFn;
  profileUpdateListener: UnregisterFn;
  reportToReviseListener: UnregisterFn;

  constructor(
    private $translate: ng.translate.ITranslateService,
    private actionSheetService: ActionSheetService,
    private authService: AuthService,
    private ConfigServer,
    private eventChangeRequestsService,
    private inAppBrowserService: InAppBrowserService,
    private intercomService: IntercomService,
    private localizationService: LocalizationService,
    private marketingService: MarketingService,
    private merchandisingApiService,
    private newsfeedService: NewsfeedService,
    private segmentService: SegmentService,
    private sfFeatureFlagsService,
    private organisationsService,
    private pubSubService: PubSubService,
    private userExperienceService,
    private CHANGE_REQUESTS_STATES,
    private SF_FEATURE_FLAGS,
    private popupService: PopupService
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    this.appVersion = this.ConfigServer.APP_VERSION;
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();

    this.registerFlags();
    this.registerListeners();

    this.getChangeRequestCount();
    if (this.hasMarketing) {
      this.marketingService.initProductChangelog();
    }

    return this.organisationsService
      .getProfileOrganisation(this.profile)
      .then((organisation) => {
        this.organisation = organisation;

        return this.localizationService.shouldUseChinaConfiguration();
      })
      .then((useChinaConfiguration) => {
        this.useChinaConfiguration = useChinaConfiguration;

        this.refreshMenu();

        return this.setMenuItemsUpdatesCount();
      });
  }

  $onDestroy(): void {
    if (this.dataSyncedDestroyListener) {
      this.dataSyncedDestroyListener();
    }
    if (this.organisationSwitchListener) {
      this.organisationSwitchListener();
    }

    this.unregisterListeners();
  }

  registerFlags(): void {
    this.hasChangeRequests = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.EVENTS_VALIDATION
    );
    this.hasHelp = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.INTERCOM_SUPPORT
    );
    this.hasMarketing = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.WHATS_NEW
    );
    this.hasMerchandising = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.MERCHANDISING
    );
    this.hasNewsfeed = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEWSFEED
    );
    this.hasProductChangelog = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.WHATS_NEW
    );
    this.hasReportValidation = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.REPORTS_VALIDATION
    );
  }

  registerListeners(): void {
    this.dataSyncedDestroyListener = this.pubSubService.subscribe(
      'DATA_SYNCED',
      () => {
        this.registerFlags();
        this.unregisterListeners();
        this.registerListeners();
        this.refreshMenu();
      }
    );
    this.profileUpdateListener = this.pubSubService.subscribe<{ profile }>(
      'PROFILE_UPDATED',
      ({ profile }) => {
        this.profile = profile;
      }
    );
    this.organisationSwitchListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.ORGANISATION_SWITCH,
      (data: { profile }) => {
        this.profile = data.profile;

        return this.organisationsService
          .getProfileOrganisation(data.profile)
          .then((organisation) => {
            this.organisation = organisation;

            this.registerFlags();
            this.unregisterListeners();
            this.registerListeners();
            this.refreshMenu();
          });
      }
    );

    if (this.hasMerchandising) {
      this.merchandisingPhotosCountUpdateListener =
        this.pubSubService.subscribe<{ count: number }>(
          'MERCH_TO_REVIEW_COUNT_UPDATED',
          ({ count }) => {
            this.merchandisingBadge = count;
          }
        );
    }

    if (this.hasChangeRequests) {
      this.changeRequestsCountUpdateListener = this.pubSubService.subscribe(
        'CHANGE_REQUESTS_COUNT_UPDATED',
        (counts) => {
          this.changeRequestsCount =
            this.eventChangeRequestsService.getCountByState({
              state: this.CHANGE_REQUESTS_STATES.PENDING,
              counts,
            });
        }
      );
    }

    if (this.hasNewsfeed) {
      this.lastSeenPostInfoUpdateListener = this.pubSubService.subscribe<{
        postsCount: number;
      }>('LAST_SEEN_POST_INFO_UPDATED', ({ postsCount }) => {
        this.hasUnseenPosts = postsCount > 0;
        this.unseenPostsCount = postsCount > 100 ? '99+' : postsCount;
      });
    }

    if (this.hasReportValidation) {
      this.reportToReviseListener = this.pubSubService.subscribe<{
        count: number;
      }>('REPORT_TO_REVISE_COUNT_UPDATED', ({ count }) => {
        this.toReviseCount = count;
      });
    }
  }

  unregisterListeners(): void {
    if (this.changeRequestsCountUpdateListener) {
      this.changeRequestsCountUpdateListener();
    }
    if (this.lastSeenPostInfoUpdateListener) {
      this.lastSeenPostInfoUpdateListener();
    }
    if (this.merchandisingPhotosCountUpdateListener) {
      this.merchandisingPhotosCountUpdateListener();
    }
    if (this.profileUpdateListener) {
      this.profileUpdateListener();
    }
    if (this.reportToReviseListener) {
      this.reportToReviseListener();
    }
  }

  refreshMenu(): void {
    this.menuMore = {
      ...this.userExperienceService
        .getMenuMore(this.organisation, this.useChinaConfiguration)
        .reduce(
          (menu, { key }) => ({
            ...menu,
            [key]: true,
          }),
          {}
        ),
    };
  }

  getChangeRequestCount(): ng.IPromise<void> {
    return this.eventChangeRequestsService
      .getStatesCounts()
      .then((counts) => {
        this.changeRequestsCount =
          this.eventChangeRequestsService.getCountByState({
            state: this.CHANGE_REQUESTS_STATES.PENDING,
            counts,
          });
      })
      .catch(() => {
        this.changeRequestsCount = 0;
      });
  }

  setMenuItemsUpdatesCount(): ng.IPromise<void> {
    if (this.hasNewsfeed) {
      this.newsfeedService.pullLastSeenPostInfo();
    }

    return this.merchandisingApiService
      .getValidationsNeedReactionCount()
      .then((count) => {
        this.merchandisingBadge = count;
      });
  }

  disconnect(): IActionSheet {
    const actionSheetConfig = {
      cancelText: this.$translate.instant('PROFILE_ACTION_CANCEL'),
      destructiveText: this.$translate.instant('PROFILE_ACTION_DISCONNECT'),
    };
    const onDestructiveClick = () => this.authService.logout();

    this.segmentService.track('PROFILE', { action: 'disconnect' });

    return this.actionSheetService.open(
      null,
      actionSheetConfig,
      null,
      onDestructiveClick
    );
  }

  askToDelete(): ng.IPromise<void> {
    return this.popupService.showInfo({
      title: this.$translate.instant('PROFILE_CHINA_DELETE_TITLE'),
      desc: this.$translate.instant('PROFILE_CHINA_DELETE_DESC'),
      iconName: 'user-remove',
    });
  }

  askForHelp(): ng.IPromise<void> | undefined {
    if (this.useChinaConfiguration) {
      return this.popupService.showInfo({
        title: this.$translate.instant('PROFILE_SUPPORT'),
        desc: this.$translate.instant('PROFILE_CHINA_SUPPORT'),
      });
    }
    return this.intercomService.displayConversations();
  }

  openUserAgreement(): Promise<void> {
    return this.inAppBrowserService.open(
      'https://storage.googleapis.com/sf-tos-staging/simplifield_user_agreement_ZH.html'
    );
  }

  openPrivacyPolicy(): Promise<void> {
    return this.inAppBrowserService.open(
      'https://storage.googleapis.com/sf-tos-staging/simplifield_privacy_policy_ZH.html'
    );
  }

  openPrivacyCenter(): Promise<void> {
    return this.inAppBrowserService.open('https://privacy.simplifield.com/');
  }
}
