import { pick } from 'ramda';
import { PICTURE_SYSTEM_IDENTIFIERS } from '../../../constants/picture-system.constants';
import type {
  ActionSheetAction,
  ActionSheetConfig,
  ActionSheetService,
} from '../ActionSheet/action-sheet.service';
import type { FileChooserService } from '../FileChooser/file-chooser.service';
import type { CameraService } from '../Camera/camera.service';
import { FileSystemService } from '../FileSystem/file-system.service';
import { ImagePickerService } from '../ImagePicker/image-picker.service';

export type ImgSourceIdentifier =
  typeof PICTURE_SYSTEM_IDENTIFIERS[keyof typeof PICTURE_SYSTEM_IDENTIFIERS];
type PictureSources = Record<
  ImgSourceIdentifier,
  {
    translationKey: string;
    identifier: ImgSourceIdentifier;
    getter?: (arg?) => ng.IPromise<string[] | File[]>;
  }
>;

const DEFAULT_MAX_PICTURE = 1;
const DEFAULT_SOURCE = PICTURE_SYSTEM_IDENTIFIERS.LIBRARY;

export class PictureSystemService {
  private PICTURES_SOURCES: PictureSources;

  /* @ngInject */
  // eslint-disable-next-line max-params
  constructor(
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private actionSheetService: ActionSheetService,
    private imagePickerService: ImagePickerService,
    private cameraService: CameraService,
    private fileChooserService: FileChooserService,
    private filesSystemService: FileSystemService
  ) {
    this.PICTURES_SOURCES = {
      image: {
        translationKey: 'PICTURE_SYSTEM_SOURCE_PHOTO',
        identifier: PICTURE_SYSTEM_IDENTIFIERS.IMAGE,
        getter: () => cameraService.getPhotoAsFile().then((f) => [f]),
      },
      library: {
        translationKey: 'PICTURE_SYSTEM_SOURCE_LIBRARY',
        identifier: PICTURE_SYSTEM_IDENTIFIERS.LIBRARY,
        getter: (maxPicture) => imagePickerService.getPictures(maxPicture),
      },
      file: {
        translationKey: 'PICTURE_SYSTEM_SOURCE_FILE',
        identifier: PICTURE_SYSTEM_IDENTIFIERS.FILE,
      },
    };
  }

  selectSourceType(
    sources: ImgSourceIdentifier[],
    options: { hasCancel?: boolean; hideTitle?: boolean } = {}
  ): ng.IPromise<ImgSourceIdentifier> {
    const defer = this.$q.defer<ImgSourceIdentifier>();
    const onSourceSelected = (source: ImgSourceIdentifier) =>
      defer.resolve(source);
    const actionSheetConfig: ActionSheetConfig = {};

    if (!options?.hideTitle) {
      actionSheetConfig.title = this.$translate.instant(
        'PICTURE_SYSTEM_BUTTON'
      );
    }
    if (options?.hasCancel) {
      actionSheetConfig.cancelText = this.$translate.instant('POPUP_CANCEL');
    }
    const actionSheetActions = Object.values(
      pick<PictureSources, ImgSourceIdentifier>(sources, this.PICTURES_SOURCES)
    ).map<ActionSheetAction>(({ translationKey, identifier }) => ({
      text: this.$translate.instant(translationKey),
      onClick: () => onSourceSelected(identifier),
    }));

    const onCancel = () => defer.reject();

    this.actionSheetService.open(
      actionSheetActions,
      actionSheetConfig,
      onCancel
    );

    return defer.promise;
  }
  getPictureFromSource(
    sourceIdentifier: ImgSourceIdentifier = DEFAULT_SOURCE,
    maxPicture: number = DEFAULT_MAX_PICTURE
  ): ng.IPromise<string[] | File[]> {
    const source = this.PICTURES_SOURCES[sourceIdentifier];

    if (!source?.getter) {
      return this.$q.reject();
    }
    return source.getter(maxPicture);
  }

  getBlobFromSource(source: string): ng.IPromise<Blob> {
    switch (source) {
      case PICTURE_SYSTEM_IDENTIFIERS.LIBRARY:
        return this.imagePickerService
          .getPictures(1)
          .then(([path]) => this.filesSystemService.getBlobFromPath(path));

      case PICTURE_SYSTEM_IDENTIFIERS.IMAGE:
        return this.cameraService.getPhotoAsFile();

      case PICTURE_SYSTEM_IDENTIFIERS.FILE:
        return this.fileChooserService
          .getDocument()
          .then((file) =>
            this.filesSystemService.getBlobImageFromBrowser(file)
          );
      default:
        throw Error('Wrong identifier');
    }
  }

  movePicture(
    initialPath: string,
    destinationFolderName: string,
    destinationPictureName: string
  ): ng.IPromise<{ path: string; name: string }> {
    const lastSlash = initialPath.lastIndexOf('/') + 1;
    const fileName = initialPath.slice(lastSlash);
    const newFileName = `${destinationPictureName}.jpg`;
    const dir = this.filesSystemService.fixPath(
      initialPath.slice(0, lastSlash)
    );

    return this.filesSystemService.createDir(destinationFolderName).then(() =>
      this.filesSystemService
        .moveFile(dir, fileName, destinationFolderName, newFileName)
        .then(() => ({
          path: `${destinationFolderName}/${newFileName}`,
          name: newFileName,
        }))
    );
  }
}
