import { GroupEntityListModalModule } from './components/group-entity-list-modal/group-entity-list-modal.module';
import { GroupTaskDetailStoreItemModule } from './components/group-task-detail-store-item/group-task-detail-store-item.module';
import { GroupTaskDetailComponent } from './group-task-detail.component';

export const GroupTaskDetailModule = angular
  .module('sfMobile.components.groupTaskDetail', [
    GroupTaskDetailStoreItemModule,
    GroupEntityListModalModule,
  ])
  .component('sfGroupTaskDetail', GroupTaskDetailComponent).name;
