import Chart from 'chart.js';

const RADAR_CHART_TYPE = 'radar';
const RADIAL_GRADIENT_OPTIONS = {
  X: 165,
  Y: 90,
  RADIUS: 60,
};
const RADIAL_CHART_STEP_NUMBER = 3;
let RADAR_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  elements: {
    point: {
      radius: 2,
      borderWidth: 2,
    },
    line: {
      borderWidth: 3,
      borderCapStyle: 'bevel',
    },
  },
  scale: {
    pointLabels: {
      fontStyle: 'bold',
    },
    ticks: {
      beginAtZero: true,
      min: 0,
      max: 100,
      stepSize: 100 / RADIAL_CHART_STEP_NUMBER,
      display: false,
    },
  },
};

export const RadarGraphComponent = {
  bindings: {
    data: '<',
  },
  templateUrl: 'components/Graphs/radar-graph/radar-graph.html',
  controller: function RadarGraphController(
    $element,
    $window,
    sfChartService,
    SF_SCALE_COLORS
  ) {
    'ngInject';

    // Init colors panel
    const DEFAULT_COLORS = [
      SF_SCALE_COLORS.BAD,
      SF_SCALE_COLORS.MEDIUM,
      SF_SCALE_COLORS.GOOD,
    ];
    const RGBA_DEFAULT_COLORS = sfChartService.getDefaultColor();
    const GRID_COLOR = sfChartService.getGridColor();

    const getPointsColor = (points) =>
      points.map((point) => sfChartService.getValueColor(point));

    const buildGradientFromColors = (ctx, colors) => {
      let radialGradient = ctx.createRadialGradient(
        RADIAL_GRADIENT_OPTIONS.X,
        RADIAL_GRADIENT_OPTIONS.Y,
        0,
        RADIAL_GRADIENT_OPTIONS.X,
        RADIAL_GRADIENT_OPTIONS.Y,
        RADIAL_GRADIENT_OPTIONS.RADIUS
      );
      let step = 1 / (colors.length - 1);

      colors.forEach((color, index) => {
        radialGradient.addColorStop(index * step, color);
      });

      return radialGradient;
    };

    this.$onInit = () => {
      // Get formatted data
      const radarData = sfChartService.dataToRadar(this.data);
      // Init Radar config
      const data = {
        labels: radarData.labels,
        datasets: [
          {
            data: radarData.points,
            pointBackgroundColor: 'white',
            pointBorderColor: getPointsColor(radarData.points),
          },
        ],
      };
      const canvasElement = $element[0].querySelector('#sf_radar_graph');
      let ctx = canvasElement.getContext('2d');

      data.datasets[0].borderColor = buildGradientFromColors(
        ctx,
        DEFAULT_COLORS
      );
      data.datasets[0].backgroundColor = buildGradientFromColors(
        ctx,
        RGBA_DEFAULT_COLORS
      );
      RADAR_CHART_OPTIONS.scale.gridLines = {
        circular: true,
        display: true,
        color: GRID_COLOR,
        zeroLineColor: GRID_COLOR,
      };
      this.chartOptions = {
        type: RADAR_CHART_TYPE,
        ...{ data },
        options: RADAR_CHART_OPTIONS,
      };

      this.chart = new Chart(canvasElement, this.chartOptions);
    };

    this.$onDestroy = () => {
      this.chart.destroy();
    };
  },
};
