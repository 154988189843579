"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('param1'),
    name: 'param1',
    description: 'Sample param 1.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'paramkey',
      value: 'paramvalue'
    }
  }, {
    _id: createObjectId(),
    name: 'badparam',
    description: 'Bad param.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.name']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      value: 'paramvalue'
    }
  }];
};