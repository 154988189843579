'use strict';

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Webhook',
  type: 'object',
  additionalProperties: false,
  required: ['label', 'method', 'url', 'event', 'headers', 'successStatus', 'organisation_id'],
  properties: {
    label: {
      type: 'string'
    },
    method: {
      type: 'string',
      "enum": ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']
    },
    url: {
      type: 'string',
      format: 'uri'
    },
    event: {
      type: 'string',
      "enum": ['A_COMMENT_CREATED', 'A_COMMENT_DELETED', 'A_COMMENT_UPDATED', 'A_FORM_CREATED', 'A_FORM_DELETED', 'A_FORM_ENDED', 'A_FORM_UPDATED', 'A_INTEGRATION_SALESFORCE_ACCOUNTUSER_CHANGED', 'A_INTEGRATION_SALESFORCE_ACCOUNT_CHANGED', 'A_INTEGRATION_SALESFORCE_SURVEY_CHANGED', 'A_INTEGRATION_SALESFORCE_USER_CHANGED', 'A_INTEGRATION_SALESFORCE_PUSH_REFRESH_TOKEN', 'A_MISSIONSGROUP_CREATED', 'A_MISSIONSGROUP_UPDATED', 'A_MISSIONSGROUP_DELETED', 'A_ORGANISATION_CREATED', 'A_ORGANISATION_DELETED', 'A_ORGANISATION_UPDATED', 'A_PLACES_LIST_UPDATED', 'A_PLACE_CREATED', 'A_PLACE_DELETED', 'A_PLACE_UPDATED', 'A_REPORT_DAILY_RECEIVED', 'A_REPORT_DAILY_SENT', 'A_REPORT_CREATED', 'A_REPORT_UPDATED', 'A_REPORT_DELETED', 'A_REPORT_MANAGER_DAILY_RECEIVED', 'A_REPORT_WEEKLY_RECEIVED', 'A_REPORT_WEEKLY_SENT', 'A_USERS_GROUP_UPDATED', 'A_USER_CONFIRMED', 'A_USER_CREATED', 'A_USER_DELETED', 'A_USER_EMAIL_BOUNCED', 'A_USER_LOGGED_IN', 'A_USER_LOGGED_IN_BUT_NOT_CONFIRMED', 'A_USER_PASSWORD_CHANGED', 'A_USER_PASSWORD_RESET', 'A_USER_REINVITED', 'A_USER_RESET_PASSWORD', 'A_USER_SIGNED_UP', 'A_USER_UPDATED']
    },
    successStatus: {
      type: 'number'
    },
    headers: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'object',
        additionalProperties: false,
        required: ['name', 'value'],
        properties: {
          name: {
            type: 'string'
          },
          value: {
            type: 'string'
          }
        }
      }
    },
    organisation_id: schemaUtils.oneToOne()
  }
};