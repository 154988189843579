import { ExternalInjectionService } from '../ExternalInjection/external-injection.service';
import { LocalizationService } from '../Localization/localization.service';
import { boxPreviewPatchCordova } from './box-preview.patch';
import { PlatformService } from '../Platform';
import { Box } from './typings/box';
import {
  addBoxDownloadCallback,
  BoxDownloadCallback,
} from './box-download-callback';
import { useMobilePrint } from './box-print-mobile';
import { PrintService } from '../Print/print.service';

export type BoxImport =
  | 'explorer'
  | 'openwith'
  | 'picker'
  | 'sidebar'
  | 'uploader'
  | 'preview';

export type BoxLocale =
  | 'en-AU'
  | 'en-CA'
  | 'en-GB'
  | 'en-US'
  | 'da-DK'
  | 'de-DE'
  | 'es-ES'
  | 'fi-FI'
  | 'fr-CA'
  | 'fr-FR'
  | 'it-IT'
  | 'ja-JP'
  | 'ko-KR'
  | 'nb-NO'
  | 'nl-NL'
  | 'pl-PL'
  | 'pt-BR'
  | 'ru-RU'
  | 'sv-SE'
  | 'tr-TR'
  | 'zh-CN'
  | 'zh-TW';

const BoxBaseUrl = 'https://cdn01.boxcdn.net/platform/';

const BoxVersion = '14.0.0';
const BoxVersionPreview = '2.80.0';

export class BoxInjectionService {
  private boxElementsLoaders = new Map<string, ng.IPromise<Box>>();
  private downloadCallback?: BoxDownloadCallback;
  constructor(
    private externalInjectionService: ExternalInjectionService<Box>,
    private $document: ng.IDocumentService,
    private $q: ng.IQService,
    private localizationService: LocalizationService,
    private platformService: PlatformService,
    private printService: PrintService
  ) {
    'ngInject';
  }

  import(boxImport: BoxImport): ng.IPromise<Box> {
    const boxLocale = this.getBoxLocale();

    const importUrl = this.buildUrl(
      BoxBaseUrl,
      this.getBoxVersion(boxImport),
      boxLocale,
      boxImport
    );

    if (this.boxElementsLoaders.has(importUrl)) {
      return this.boxElementsLoaders.get(importUrl)!;
    }
    const defer = this.$q.defer<Box>();
    this.runBeforeImport(boxImport);

    this.boxElementsLoaders.set(importUrl, defer.promise);

    this.importJsAndCss(importUrl, boxImport).then((box) => {
      this.patchAfterImport(boxImport, box);
      defer.resolve(box);
    });

    return defer.promise;
  }

  private importJsAndCss(importUrl: string, importId: BoxImport) {
    const defer = this.$q.defer<Box>();
    const scriptUrl = `${importUrl}.js`;
    const cssUrl = `${importUrl}.css`;
    const promiseScriptInjection = this.externalInjectionService.init(
      scriptUrl,
      'Box'
    );

    this.importCss(cssUrl, importId).then(() => {
      promiseScriptInjection.then((boxElement) => {
        boxElement
          ? defer.resolve(boxElement)
          : defer.reject('failed to donload box.com');
      });
    });

    return defer.promise;
  }

  private importCss(cssURL: string, importId: BoxImport) {
    const document = this.$document[0] as HTMLDocument;
    const style = document.createElement('link');
    const defer = this.$q.defer();

    style.id = `box-style-${importId}`;
    style.rel = 'stylesheet';
    style.href = `${cssURL}`;
    style.onload = () => {
      defer.resolve();
    };

    document.head.appendChild(style);
    return defer.promise;
  }

  private getBoxVersion(boxImport: BoxImport) {
    if (boxImport === 'preview') {
      return BoxVersionPreview;
    }
    return BoxVersion;
  }

  private buildUrl(
    boxBaseUrl: string,
    boxVersion: string,
    boxLocale: BoxLocale,
    boxImport: BoxImport
  ): string {
    const module = boxImport === 'preview' ? 'preview' : 'elements';
    return `${boxBaseUrl}${module}/${boxVersion}/${boxLocale}/${boxImport}`;
  }

  private getBoxLocale(): BoxLocale {
    const deviceLang = this.localizationService.getDeviceLang();
    const appLanguage =
      this.localizationService.getPreferredLanguage(deviceLang);

    if (
      appLanguage === 'zh' &&
      this.localizationService.getFullDeviceLang() === 'zh-TW'
      // Chinese lang diff https://www.programmersought.com/article/88442578855/:w
    ) {
      return 'zh-TW';
    }

    const localeMap: { [key: string]: BoxLocale } = {
      da: 'da-DK',
      de: 'de-DE',
      en: 'en-US',
      es: 'es-ES',
      fi: 'fi-FI',
      fr: 'fr-FR',
      it: 'it-IT',
      ja: 'ja-JP',
      nl: 'nl-NL',
      pl: 'pl-PL',
      pt: 'pt-BR',
      ru: 'ru-RU',
      sv: 'sv-SE',
      tr: 'tr-TR',
      zh: 'zh-CN',
    };

    return localeMap[appLanguage] || 'en-US';
  }

  private runBeforeImport(boxImport: BoxImport) {
    const needCordovaPatch: BoxImport[] = ['explorer', 'preview'];

    if (needCordovaPatch.includes(boxImport)) {
      boxPreviewPatchCordova();
    }
  }

  removeBox(importedStyle: BoxImport[]) {
    this.boxElementsLoaders.clear();
    importedStyle.map((importId) => {
      const document = this.$document[0] as HTMLDocument;
      const cssEl = document.getElementById(`box-style-${importId}`);
      cssEl?.remove();
    });
  }

  private patchAfterImport(boxImport: BoxImport, box: Box) {
    const isBrowser = this.platformService.isBrowser();
    if (!isBrowser && boxImport === 'preview') {
      useMobilePrint(this.printService, box);
    }
    if (boxImport === 'preview') {
      addBoxDownloadCallback(box, this.getDownloadCallback);
    }
  }

  private getDownloadCallback = () => this.downloadCallback;

  setDownloadCallback(downloadCallBack: BoxDownloadCallback) {
    this.downloadCallback = downloadCallBack;
  }

  clearDownloadCallback() {
    this.downloadCallback = undefined;
  }
}
