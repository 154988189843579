import { MissionsDraftComponent } from './components/missions-draft/missions-draft.component';
import { MissionsListComponent } from './components/missions-list/missions-list.component';
import { MissionsReadyComponent } from './components/missions-ready/missions-ready.component';
import { MissionsSentComponent } from './components/missions-sent/missions-sent.component';
import { MissionsComponent } from './missions.component';

export default angular
  .module('sfMobile.components.missions', [
    'sfMobile.services.localization',
    'pascalprecht.translate',
    'sfMobile.components.reports-list',
    'sfMobile.components.content',
    'sfMobile.components.view-states',
    'sfMobile.components.view-states-search',
    'sfMobile.components.reports-pending-selection',
    'sfMobile.services.user-experience',
    'sfMobile.services.places',
    'sfMobile.services.forms',
    'sfMobile.services.reports',
    'sfMobile.services.ongoingSheet',
    'sfMobile.services.missions',
    'sfMobile.services.tasks',
    'sfMobile.services.dates',
    'sfMobile.services.file-system',
    'sfMobile.services.helpers',
    'sfMobile.services.segment',
    'sfMobile.services.report-sending',
    'sfMobile.services.popup-request',
    'sfMobile.services.places-source-adapter',
    'sfMobile.services.pubsub',
    'sfMobile.services.searchUtils',
  ])
  .component('sfMissionsDraft', MissionsDraftComponent)
  .component('sfMissionsList', MissionsListComponent)
  .component('sfMissionsReady', MissionsReadyComponent)
  .component('sfMissionsSent', MissionsSentComponent)
  .component('sfMissions', MissionsComponent).name;
