import { SfFeatureFlags } from '@simplifield/feature-flags';
import moment from 'moment';
import { pick } from 'ramda';
import { GetTasksResponse, ObjectId, User } from '../../..';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import { DATABASE_SCHEMA } from '../../../core/database.config';
import { APIStore } from '../../../places';
import { APITaskContents, Subtask, Task, TaskStatus } from '../../../tasks';
import { SF_TASK_STATUSES } from '../../../tasks/constants/task-statuses.constant';
import { CrudFactory } from '../../Utils/CRUD/crud-service.factory';
import { DateFormatService } from '../../Utils/Dates/date-format.service';
import { TranslateNumbersService } from '../../Utils/TranslateNumbers/translateNumbers.service';
import { CampaignsService } from '../campaigns/campaigns.service';
import { Form } from '../forms/forms';
import { PovService } from '../POV/pov.service';
import { UsersService } from '../users/users.service';

type GetTasksOptions = {
  status?: TaskStatus;
  requestFilters: Record<string, unknown>[];
  search?: string;
  limit?: number;
  skip?: number;
  add_comments_count?: number;
  tab?: string;
};
type TaskCount = { status: TaskStatus; total: number };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function TasksService(
  translateNumbersService: TranslateNumbersService,
  $http: ng.IHttpService,
  placesService: { get: (id: ObjectId) => ng.IPromise<APIStore> },
  formsService: { get: (id: ObjectId) => ng.IPromise<Form> },
  usersService: UsersService,
  profileService,
  sfPOVService: PovService,
  crudFactory: CrudFactory<Task>,
  databaseSchema: typeof DATABASE_SCHEMA,
  sfFeatureFlagsService: SfFeatureFlags,
  helpersService: { sortNatural(a: string, b: string): number },
  dateFormatService: DateFormatService,
  apiUtilsService: {
    buildFilterParams(filters: Record<string, unknown>[]): {
      filters: { $and: Record<string, unknown>[] };
    };
  },
  SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
  TASK_STATUSES: typeof SF_TASK_STATUSES,
  campaignsService: CampaignsService,
  dateService
) {
  'ngInject';

  const { buildFilterParams } = apiUtilsService;
  const tableConfig = databaseSchema.tables.tasks;
  const methods = Object.assign(
    crudFactory('tasks', {
      default_params: { mode: 'compact' },
      take_care_of_user_profile: true,
      backup: {
        indexed_fields: tableConfig.indexed_fields.map((field) => field.name),
      },
    }),
    {
      moment,
      getTasks,
      getReportTasks,
      saveTask,
      getTask,
      changeStatus,
      changeSubtaskStatus,
      getTasksCount,
      getOfflineTasks,
      getOfflineTask,
      saveReportTasks,
      deleteReportTasks,
      remove,
      updateRemoteTask,
      saveRemoteTask,
      hasFeatureFlag,
      getOfflineGroupedReportTasks,
      sortTasks,
      getDueDateTimezonedHtml5,
      taskSelfAssign,
      taskAssign,
    }
  );
  const isToDo = (status) => status === TASK_STATUSES.TODO.keyword;

  /**
   * Tasks list with relative entities
   * @param {Object} options - expected properties searchText, view, status, limit, skip
   * @returns {Promise<task>} Expects to return a task.
   */
  function getTasks(
    options: GetTasksOptions = {} as GetTasksOptions
  ): ng.IPromise<GetTasksResponse> {
    const params = buildRequestParams(options);

    return sfPOVService.pBuildURL('/tasks').then((url) =>
      $http
        .get<ng.IPromise<GetTasksResponse>>(url, {
          params,
        })
        .then((result) => result.data)
    );
  }

  function buildRequestParams({
    status,
    requestFilters,
    search = '',
    limit,
    skip = 0,
    add_comments_count = 0,
    tab,
  }: GetTasksOptions) {
    const sortParams = buildSortParams(status);
    const tasksFilters = buildTasksFilters(requestFilters);
    const filters = [
      ...(tasksFilters || []),
      ...(status ? [{ name: 'status', value: status }] : []),
      ...(search ? [{ name: 'name', value: search, operator: '$regex' }] : []),
    ];

    return {
      limit: limit,
      skip: skip,
      sorts: sortParams,
      ...buildFilterParams(filters),
      add_comments_count,
      ...(tab ? { tab } : {}),
    };
  }

  function buildTasksFilters(filters: Record<string, unknown>[]): any {
    const getFilterValue = (filter: any) => {
      if (filter.value === 'done') {
        return {
          name: 'status',
          operator: '$or',
          value: [
            TASK_STATUSES.LATE_COMPLETION.keyword,
            TASK_STATUSES.DONE_ON_TIME.keyword,
          ],
        };
      }

      if (filter.name !== 'due_date') {
        return filter;
      }
      const date = new Date(filter.value);
      const startOfTheDay = dateService.getUtcStartOfDate(date);
      const endOfTheDay = dateService.getUtcEndOfDate(date);

      return {
        name: filter.name,
        operator: '$between',
        value: [startOfTheDay, endOfTheDay],
      };
    };

    return filters && filters.length ? filters.map(getFilterValue) : [];
  }

  function getTask(taskId, params = {}, offlineMode) {
    if (!offlineMode) {
      return getRemoteTask(taskId, params);
    }
    return getOfflineTask(taskId);
  }

  function getRemoteTask(taskId, params) {
    return sfPOVService
      .pBuildURL(`/tasks/${taskId}`)
      .then((url) => $http.get(url, { params: params }))
      .then((result) => result.data);
  }

  function getOfflineTask(taskId: ObjectId) {
    return methods.dataStore
      .getLocal(taskId)
      .then(_resolveTaskForm)
      .then(_resolveTaskPlace)
      .then(_resolveTaskUsers);
  }

  function _resolveTaskPlace(task) {
    if (task.contents.place_id) {
      return placesService.get(task.contents.place_id).then((place) => {
        task.places = {
          [task.contents.place_id]: place.contents,
        };
        return task;
      });
    }
    return task;
  }

  function _resolveTaskForm(task) {
    if (task.contents.form_id) {
      return formsService.get(task.contents.form_id).then((form) => ({
        ...task,
        forms: {
          ...(task.forms || {}),
          [task.contents.form_id]: form.contents,
        },
      }));
    }

    return campaignsService
      .getOne(task.contents.campaign_id)
      .then((campaign) => ({
        ...task,
        campaigns: {
          ...(task.campaigns || {}),
          [task.contents.campaign_id]: campaign.contents,
        },
      }));
  }

  function _resolveTaskUsers(task) {
    return usersService.crud
      .queryLocal({ id: [task.contents.owner_id, task.contents.assignee_id] })
      .then((users) => {
        task.users = users.reduce((usersHash, user) => {
          usersHash[user._id] = user.contents;
          return usersHash;
        }, {});
        return task;
      });
  }

  function _resolveTasksUsers(tasks) {
    const usersIds = tasks.reduce(
      (acc, task) => [
        ...acc,
        task.contents.owner_id,
        task.contents.assignee_id,
      ],
      []
    );

    return usersService.crud.queryLocal({ id: usersIds }).then((users) => {
      const usersHash = users.reduce((acc, user) => {
        acc[user._id] = user.contents;
        return acc;
      }, {});
      return { tasks, users: usersHash } as { tasks: Task[]; users: User[] };
    });
  }

  /**
   * Will update or create a task based on id property.
   * @param  {string} task Expects task that which status will be modified
   * @param  {string} status Expects task status as string
   * @param  {string} offlineMode Expects task status as string
   * @returns {Promise<task>} Should return a Promise after calling updateTask.
   */
  function changeStatus(task: Task, status: TaskStatus, offlineMode: boolean) {
    const obj = {
      ...task,
      contents: {
        ...task.contents,
        status,
      },
    };

    return saveTask(obj, offlineMode);
  }

  function changeSubtaskStatus(
    task: Task,
    subtask: Subtask,
    offlineMode: boolean
  ) {
    if (!offlineMode) {
      return updateRemoteSubtask(task._id, subtask);
    }

    return updateOfflineSubtask(task._id, subtask);
  }

  function updateRemoteSubtask(taskId: ObjectId, subtask: Subtask) {
    return sfPOVService
      .pBuildURL(`/tasks/${taskId}/subtasks/${subtask._id}`)
      .then((url) =>
        $http.patch<Task>(url, subtask).then((result) => result.data)
      );
  }

  function updateOfflineSubtask(taskId: ObjectId, subtask: Subtask) {
    return getOfflineTask(taskId).then((task) => {
      const updatedTask = {
        ...task,
        contents: {
          ...task.contents,
          subtasks: task.contents.subtasks?.map((sub) => {
            if (sub._id === subtask._id) {
              return subtask;
            }
            return sub;
          }),
        },
      };

      return updateOfflineTask(updatedTask);
    });
  }

  function remove(taskId: ObjectId, offlineMode: boolean) {
    if (offlineMode) {
      return methods.dataStore.deleteLocal(taskId);
    }
    return methods.deleteRemote(taskId, {});
  }

  function buildSortParams(status?: TaskStatus): string[] {
    const sort = !isToDo(status) ? '-' : '';
    const dueDateSort = sort + 'contents.dateOnly';

    return [dueDateSort, '-contents.priority', 'contents.lowerName'];
  }
  /**
   * Will update or create a task based on id property.
   * @param  {Object} task Expects task to be sent to server
   * @param  {boolean} offlineMode Expects true in case of saving offline
   * @returns {Promise<task>} Should return a Promise after calling taskChanged(task).
   */
  function saveTask(task: Task, offlineMode: boolean) {
    const adaptContents = (contents: APITaskContents): APITaskContents => {
      if (contents.subtasks && typeof contents.subtasks === 'object') {
        contents.subtasks = contents.subtasks
          .filter(({ name }) => name !== '')
          .map((subtask) =>
            pick(['_id', 'name', 'status', 'assignee_id'], subtask)
          );
      }
      return contents;
    };
    const taskToSave = {
      _id: task._id,
      isAutotask: task.isAutotask,
      isMandatory: task.isMandatory,
      users: task.users,
      contents: {
        ...adaptContents(task.contents),
      },
    } as unknown as Task;

    taskToSave.contents.due_date = translateNumbersService.normalizeDateTimeUTC(
      methods.moment.utc(task.contents.due_date)
    );

    if (!offlineMode) {
      if (!taskToSave._id) {
        return saveRemoteTask(taskToSave);
      }
      return updateRemoteTask(taskToSave);
    }

    return updateOfflineTask(taskToSave);
  }

  function getReportTasks(
    requestFilters: Record<string, unknown>[],
    offlineMode: boolean,
    report_id: ObjectId
  ): ng.IPromise<{ tasks: Task[]; users: User[] }> {
    if (offlineMode) {
      return getOfflineTasks({ report_id }).then((tasks) => {
        return _resolveTasksUsers(tasks);
      });
    }

    return getTasks({
      requestFilters,
    }).then((result) => ({
      tasks: result.entries as unknown as Task[],
      users: result.users as unknown as User[],
    }));
  }

  function getOfflineTasks(params: {
    report_id: ObjectId;
  }): ng.IPromise<Task[]> {
    return methods.dataStore.queryLocal(params);
  }

  /**
   *  This should not be added into methods, but used as a private method. Use saveTask instead.
   * @param  {Object} task Expects to be an object to send to the api
   * @returns {Promise<task>} Expects to return a task.
   */
  function saveRemoteTask(task: Task): ng.IPromise<Task> {
    return sfPOVService
      .pBuildURL(`/tasks`)
      .then((url) => $http.post<Task>(url, task).then((result) => result.data));
  }

  /**
   *  This should not be added into methods, but used as a private method. Use saveTask instead.
   * @param  {Object} task Expects to be an object to send to the api
   * @returns {Promise<task>} Expects to return a task.
   */
  function updateRemoteTask(task: Task): ng.IPromise<Task> {
    return sfPOVService
      .pBuildURL(`/tasks/${task._id}`)
      .then((url) =>
        $http.patch<Task>(url, task).then((result) => result.data)
      );
  }

  /**
   * This should not be added into methods, but used as a private method. Use saveTask with offlineMode instead.
   * @param  {Object} task Expects to be an object to send to the api
   * @returns {Promise<task>} Expects to return the saved task populated with place and form.
   */
  function updateOfflineTask(task: Task): ng.IPromise<Task> {
    task.id = task._id;
    task.report_id = task.contents.report_id;
    return methods.dataStore
      .saveLocal(task._id, task)
      .then(_resolveTaskForm)
      .then(_resolveTaskPlace);
  }

  /**
   * @param {String} filters - Request filters
   * @returns {Object} The response should return an array of objects of type: {status:'todo', total:10 }
   */
  function getTasksCount(filters, myTasks, othersTasks) {
    const requestParams = {
      myTasks: buildFilterParams(myTasks),
      othersTasks: buildFilterParams(othersTasks),
      ...buildFilterParams(filters),
    };

    return sfPOVService
      .pBuildURL('/taskCounts')
      .then((url) =>
        $http
          .get<TaskCount[]>(url, { params: requestParams })
          .then((result) => result.data)
      );
  }

  function saveReportTasks(report_id: ObjectId, params) {
    const timeoutDefault = 2000;
    const requestConfig = {
      timeout: params.canceler ? params.canceler.promise : timeoutDefault,
    };

    return getOfflineTasks({ report_id }).then((tasks) =>
      sfPOVService
        .pBuildURL('/tasks')
        .then((url) =>
          $http
            .patch<Task[]>(url, tasks, requestConfig)
            .then((result) => result.data)
        )
    );
  }

  function getOfflineGroupedReportTasks(
    params: { report_id: ObjectId },
    groupBy: 'question_id'
  ) {
    return getOfflineTasks(params).then((tasks) =>
      tasks.reduce((map, task) => {
        if (task.contents && task.contents[groupBy]) {
          const propName = task.contents[groupBy];

          map[propName] = map[propName] || [];
          map[propName].push(task);
        }
        return map;
      }, {} as Record<ObjectId, Task[]>)
    );
  }

  function deleteReportTasks(report_id: ObjectId) {
    return methods.deleteByExternalKey('report_id', report_id);
  }

  // ------------------
  //
  //  HELPERS Methods
  //
  // ------------------

  function hasFeatureFlag() {
    return sfFeatureFlagsService.hasFeature(SF_FEATURE_FLAGS.TASKS);
  }

  function sortTasks(tasks: Task[], profile: User) {
    return tasks.sort((a, b) => {
      const a_due_date = getDueDateTimezonedHtml5(
        profile,
        a.contents.due_date as string
      );
      const b_due_date = getDueDateTimezonedHtml5(
        profile,
        b.contents.due_date as string
      );

      if (a_due_date !== b_due_date) {
        return new Date(a_due_date).getDate() - new Date(b_due_date).getDate();
      }
      const numbersAreDifferent = b.contents.priority - a.contents.priority;

      if (numbersAreDifferent) {
        return numbersAreDifferent;
      }

      return helpersService.sortNatural(a.contents.name, b.contents.name);
    });
  }

  function getDueDateTimezonedHtml5(profile: User, due_date: string) {
    const timezoned_date = profileService.transformUtcToUserTimezone(
      profile,
      due_date
    );

    return dateFormatService.getHtml5DateFormatted(timezoned_date);
  }

  function taskSelfAssign(task: Task, offlineMode: boolean): ng.IPromise<Task> {
    return profileService.getProfile().then((profile) => {
      return taskAssign(task, profile._id, offlineMode);
    });
  }

  function taskAssign(
    task: Task,
    userId: string,
    offlineMode: boolean
  ): ng.IPromise<Task> {
    const obj = {
      ...task,
      contents: {
        ...task.contents,
        assignee_id: userId,
      },
    };
    return saveTask(obj, offlineMode);
  }

  return methods;
}
