export const TRANSLATIONS = {
    initializing: { en: 'Initializing...', nl: 'Laden...', fr: 'Chargement...' },
    errorDashboard: {
        en: 'Oops, we could not load this dashboard!',
        nl: 'Oeps, we konden het dashboard niet laden.',
        fr: 'Le chargement a echoué.'
    },
    errorItem: {
        en: 'Oops, we could not load this item!',
        nl: 'Oeps, we konden de grafiek niet laden.',
        fr: 'Le chargement a echoué.'
    }
};
export default TRANSLATIONS;
