import { DateService } from '@services/Utils/Dates/date.service';
import { LocalizationService } from '@services/Utils/Localization/localization.service';
import { TranslateNumbersService } from '@services/Utils/TranslateNumbers/translateNumbers.service';
import moment from 'moment';
import { User } from '../../index';

export const DueDateTagComponent = {
  bindings: {
    date: '<',
    dueTime: '<',
    status: '<',
  },
  templateUrl: 'components/due-date-tag/due-date-tag.html',
  controller: class DueDateTagController {
    date: string;
    dueTime: number;
    status: string;

    isRTLNeeded = false;
    dueDateFormatted: string;
    isOverdue: boolean;
    isToday: boolean;
    isOnTime: boolean;
    profile: User;

    constructor(
      private translateNumbersService: TranslateNumbersService,
      private localizationService: LocalizationService,
      private dateService: DateService,
      private profileService,
      private helpersService,
      private $translate: ng.translate.ITranslateService
    ) {
      'ngInject';
    }

    $onInit = (): void => {
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
      this.profileService.getProfile().then((profile) => {
        this.profile = profile;
        this.setDateRelatedParams();
      });
    };

    $onChanges() {
      if (this.profile) {
        this.setDateRelatedParams();
      }
    }

    setDateRelatedParams = (): void => {
      const dateHtml5Formatted = this.isRTLNeeded
        ? this.translateNumbersService.changeNumberArabicToEnglish(
            this.helpersService.getDueDateTimezonedHtml5(
              this.profile,
              this.date
            )
          )
        : this.helpersService.getDueDateTimezonedHtml5(this.profile, this.date);

      this.dueDateFormatted = this.helpersService.getDueDateDisplayValue(
        this.profile,
        this.date,
        true
      );

      if (this.status) {
        if (this.status === 'done_on_time') {
          this.isOnTime = true;
          this.dueDateFormatted = this.$translate.instant(
            'DATE_FORMAT_DONE_ON_TIME'
          );
        } else {
          this.isOverdue = true;
          this.dueDateFormatted = this.$translate.instant(
            'DATE_FORMAT_LATE_COMPLETION'
          );
        }
        return;
      }

      if (this.dueTime) {
        const dueDate = moment(this.date)
          .startOf('day')
          .add(this.dueTime)
          .toDate();
        const now = new Date();

        this.isOverdue = this.dateService.isGreaterThan(now, dueDate);
        this.isToday =
          this.dateService.isToday(dateHtml5Formatted) &&
          this.dateService.isGreaterThan(dueDate, now);

        if (this.isOverdue) {
          this.dueDateFormatted = this.$translate.instant(
            'DATE_FORMAT_OVERDUE'
          );
        } else if (this.isToday) {
          this.dueDateFormatted = this.$translate.instant('DATE_FORMAT_TODAY');
        }
      } else {
        this.isOverdue = this.dateService.isOverdue(dateHtml5Formatted);
        this.isToday = this.dateService.isToday(dateHtml5Formatted);
      }
    };
  },
};
