import { ChatService } from './chat.service';

export default angular
  .module('sfMobile.services.chat', [
    'simplifield.feature-flags',
    'sfMobile.constants.feature-flags',
    'sfMobile.services.dates',
    'sfMobile.services.users',
    'sfMobile.services.organisations',
    'sfMobile.services.sentry',
    'sfMobile.services.file-system',
    'sfMobile.services.chatApi',
  ])
  .factory('chatService', ChatService).name;
