"use strict";

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Validation',
  type: 'object',
  additionalProperties: false,
  required: ['state', 'organisation_id', 'report_id', 'question_id'],
  properties: {
    state: {
      type: 'string',
      "enum": ['pending', 'rejected', 'validated'],
      "default": 'pending'
    },
    organisation_id: schemaUtils.oneToOne(),
    report_id: schemaUtils.oneToOne(),
    question_id: schemaUtils.oneToOne()
  }
};