import EntitySelectorModalModule from '../entity-selector/components/entity-selector-modal/entity-selector-modal.module';
import { EntitySelectorService } from '../entity-selector/services/entity-selector.service';
import { CorporateEventsSelectorComponent } from './corporate-events-selector.component';

export const CorporateEventsSelectorModule = angular
  .module('sfMobile.components.event-corporate-events-selector', [
    'sfMobile.services.localization',
    'sfMobile.services.api-utils',
    'sfMobile.services.static-requests-paginate',
    'sfMobile.components.entity-selector-modal',
    EntitySelectorModalModule,
  ])
  .service('entitySelectorService', EntitySelectorService)
  .component(
    'sfCorporateEventsSelector',
    CorporateEventsSelectorComponent
  ).name;
