import { ObjectId } from '../../..';
import { Category } from '../..';
import {
  CategoriesApiFactory,
  CategoriesAPI,
  CategorizableResourceType,
} from '../categories-api/categories.api.factory';

export type CategoriesFactory = (
  resourceType: CategorizableResourceType
) => CategoriesService;

export class CategoriesService {
  private categoriesApiService: CategoriesAPI;
  private categories: Category[] | null;

  constructor(
    resourceType: CategorizableResourceType,
    private $q: ng.IQService,
    private categoriesApiFactory: CategoriesApiFactory
  ) {
    'ngInject';
    this.categoriesApiService = this.categoriesApiFactory.buildService({
      type: resourceType,
    });
  }

  getCategories(): ng.IPromise<Category[]> {
    if (this.categories) {
      return this.$q.resolve(this.categories);
    }
    return this.listCategories();
  }

  getCategoryById(category_id: ObjectId): Category | null | undefined {
    if (!category_id || !this.categories || !this.categories.length) {
      return null;
    }
    return this.categories.find(({ _id }) => _id === category_id);
  }

  listCategories(): ng.IPromise<Category[]> {
    return this.categoriesApiService.list().then((data) => {
      this.categories = data.entries;
      return this.categories;
    });
  }

  saveCategories(categories: Category[]): ng.IPromise<Category[]> {
    return this.categoriesApiService.partialUpdate(categories).then((data) => {
      this.categories = data.entries;
      return this.categories;
    });
  }

  reset(): void {
    this.categories = null;
  }
}

// @ngInject
export const CategoriesFactory =
  ($q: ng.IQService, categoriesApiFactory: CategoriesApiFactory) =>
  (resourceType: CategorizableResourceType): CategoriesService =>
    new CategoriesService(resourceType, $q, categoriesApiFactory);
