import { VERSION, sanitizeUrl } from './utils';
export const createIframeUrl = (dashboardContent) => {
    const searchString = [];
    const { appServer, language, qeVersion, loaderBackground, loaderSpinnerColor, loaderSpinnerBackground, loaderFontColor, screenMode, key, token, timezoneId, itemEmbedding, itemDimensions, dashboardId, dashboardSlug, itemId, mainColor, accentColor, editMode } = dashboardContent.params;
    // language
    if (language && language !== 'auto') {
        searchString.push('language=' + language);
    }
    // QE version
    if (qeVersion) {
        searchString.push(`qe_version=${qeVersion}`);
    }
    // loader
    if (loaderBackground) {
        searchString.push(`ldrbg=${loaderBackground}`);
    }
    if (loaderFontColor) {
        searchString.push(`ldrftclr=${loaderFontColor}`);
    }
    if (loaderSpinnerColor) {
        searchString.push(`ldrspclr=${loaderSpinnerColor}`);
    }
    if (loaderSpinnerBackground) {
        searchString.push(`ldrspbg=${loaderSpinnerBackground}`);
    }
    // main and accent color
    if (mainColor) {
        searchString.push(`mainColor=${mainColor}`);
    }
    if (accentColor) {
        searchString.push(`accentColor=${accentColor}`);
    }
    // screenMode
    if (screenMode && screenMode !== 'auto') {
        searchString.push('screenMode=' + screenMode);
    }
    // timezoneId
    if (timezoneId) {
        searchString.push('timezoneId=' + timezoneId);
    }
    // itemDimensions
    if (itemEmbedding && itemDimensions && itemDimensions.width) {
        searchString.push('width=' + itemDimensions.width);
    }
    if (itemEmbedding && itemDimensions && itemDimensions.height) {
        searchString.push('height=' + itemDimensions.height);
    }
    // editMode
    if (editMode) {
        searchString.push('editMode=' + editMode);
    }
    // Add key and token as URL anchor to end of URL.
    const keyTokenUrlAnchor = key && token ? '#' + key + ':' + token : '';
    // version
    searchString.push('version=' + (dashboardContent.VERSION || VERSION));
    const iframeUrl = itemEmbedding
        ? `${appServer}i/${dashboardId || dashboardSlug}/${itemId}?${encodeURIComponent(searchString.join('&'))}${keyTokenUrlAnchor}`
        : `${appServer}i/${dashboardId || dashboardSlug}?${encodeURIComponent(searchString.join('&'))}${keyTokenUrlAnchor}`;
    return sanitizeUrl(iframeUrl);
};
