import { QuestionProductComponent } from './question-product.component';
import ProductPickerModule from './components/product-picker/product-picker.module';

export default angular
  .module('sfMobile.components.question-product', [
    'sfMobile.constants.question-product',
    'sfMobile.constants.form-question-actions',
    'sfMobile.components.receipt',
    ProductPickerModule,
  ])
  .component('questionProduct', QuestionProductComponent).name;
