import { SkeletonDisplayComponent } from './skeleton-display.component';

export default angular
  .module('sfMobile.components.skeleton-display', [
    'sfMobile.components.title-skeleton',
    'sfMobile.components.text-skeleton',
    'sfMobile.components.search-skeleton',
    'sfMobile.components.list-items-skeleton',
    'sfMobile.components.list-images-skeleton',
    'sfMobile.components.task-skeleton',
  ])
  .component('sfSkeletonDisplay', SkeletonDisplayComponent).name;
