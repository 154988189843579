import { FormSectionComponent } from './form-section.component';

export default angular
  .module('sfMobile.component.formsection', [
    'sfMobile.component.question',
    'sfMobile.components.formNode',
    'sfMobile.services.forms',
    'sfMobile.services.reports',
    'sfMobile.components.inappbrowseropener',
    'sfMobile.services.helpers',
    'sfMobile.services.pubsub',
  ])
  .component('sfFormSection', FormSectionComponent).name;
