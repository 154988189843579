import { Browser } from '@capacitor/browser';
import { InAppBrowserService } from '../InAppBrowser/in-app-browser.service';

const getParamValue = (url: string, paramName: string): string | null =>
  new URL(url.replace(/#/g, '?')).searchParams.get(paramName);

export class OauthBrowserService {
  constructor(
    private $q: ng.IQService,
    private inAppBrowserService: InAppBrowserService
  ) {
    'ngInject';
  }

  openConnect(url: string, paramName: string): ng.IPromise<string | null> {
    return this.$q((resolve, reject) => {
      const onError = () => {
        reject('The sign in flow was canceled');
      };
      const onLoadedStart = (url) => {
        if (url.indexOf(paramName) === -1) {
          return;
        }

        resolve(getParamValue(url, paramName));
      };

      // Listen events
      Browser.addListener('browserPageLoaded', () => {
        onLoadedStart(url);
      }).then((handler) => {
        handler.remove();
        Browser.close();
      });

      Browser.addListener('browserFinished', () => {
        onError();
      }).then((handler) => {
        handler.remove();
      });

      this.inAppBrowserService.open(url, {
        height: 800,
        width: 450,
      });
    });
  }
}
