export const InputRadioFreeComponent = {
  bindings: {
    label: '@',
    inputName: '@',
    value: '=',
    hasError: '<',
    onValueChange: '&',
    disabled: '<',
    isRequired: '<',
    isPopupBehavior: '<',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  transclude: true,
  templateUrl: 'components/Inputs/input-radio-free/input-radio-free.html',
  controller: function InputRadioFreeController($element, $q, popupService) {
    'ngInject';

    this.$onInit = () => {
      this.modelCtrl.$render = () => {
        this.tempValue = this.modelCtrl.$viewValue;
      };
    };

    this.onSelect = () => {
      if (this.tempValue === this.value) {
        focusTextField();
      }
      this.onChange();
    };
    this.onTextChange = () => this.onValueChange({ value: this.value || '' });
    this.onChange = () => this.modelCtrl.$setViewValue(this.value);

    this.openPopupField = (event) => {
      event.stopPropagation();

      if (this.disabled || !this.isPopupBehavior) {
        return $q.reject();
      }

      return popupService
        .prompt({ inputValue: this.value })
        .promptPromise.then((value) => {
          this.value = value;
          this.onTextChange();
          return value;
        });
    };

    function focusTextField() {
      const textField = $element[0].querySelector('.sf_input_check_free__text');

      if (textField) {
        textField.focus();
      }
    }

    this.hasTextError = () =>
      this.modelCtrl.$viewValue === '' && this.value === '';
  },
};
