import moment, { MomentInput } from 'moment';
import momentTZ, {
  MomentInput as MomentTZInput,
  MomentZone,
} from 'moment-timezone';
import { startsWith } from 'ramda';
import { DateService } from './date.service';

const NB_MINUTES_IN_ONE_HOUR = 60;

export class DateFormatService {
  momentTZ = momentTZ;
  moment = moment;

  deviceLanguage: string;

  constructor(
    private $translate: ng.translate.ITranslateService,
    private DATE_FORMATTING_STRINGS,
    private INTL_DATE_FORMATTING_OPTS,
    private dateService: DateService
  ) {
    'ngInject';
  }

  setLocale = (lang: string): string => {
    this.deviceLanguage = lang;

    return this.moment.locale(this.getMomentLocale(lang));
  };

  getMomentLocale = (lang: string): string =>
    startsWith('zh', lang) ? 'zh-cn' : lang;

  momentFormatter =
    (formatString: string) =>
    (date: MomentInput = new Date()): string => {
      const isMoment = moment.isMoment(date);

      return this.moment(isMoment ? date.toDate() : date).format(formatString);
    };

  intlFormatter =
    (formatOptions) =>
    (date: Date = new Date()): string => {
      const locales = [this.deviceLanguage, 'en', 'fr'];
      return new Intl.DateTimeFormat(locales, formatOptions).format(date);
    };

  getDateAndTimeFormatted = this.momentFormatter(
    this.DATE_FORMATTING_STRINGS.date_and_time
  );

  getTimeFormatted = this.momentFormatter(this.DATE_FORMATTING_STRINGS.time);

  getDateFormatted = this.momentFormatter(this.DATE_FORMATTING_STRINGS.date);

  getNewsfeedDateFormatted = this.momentFormatter(
    this.DATE_FORMATTING_STRINGS.newsfeed_time
  );

  getNewsfeedDraftDateFormatted = this.momentFormatter(
    this.DATE_FORMATTING_STRINGS.newsfeed_draft_time
  );

  getIntlDateFormatted = this.intlFormatter(
    this.INTL_DATE_FORMATTING_OPTS.dateTz
  );

  getCompleteDateFormatted = this.momentFormatter(
    this.DATE_FORMATTING_STRINGS.complete_date
  );

  getEventParamDateFormatted = this.momentFormatter(
    this.DATE_FORMATTING_STRINGS.event_params_date
  );

  getCompletionDateFormatted = (timestamp: string): string => {
    const date = new Date(timestamp);

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${month}. ${day}, ${year} at ${hours}:${minutes
      .toString()
      .padStart(2, '0')}`;
  };

  getDayOfMonthFormatted = this.momentFormatter(
    this.DATE_FORMATTING_STRINGS.day_of_month
  );

  getDayNumberFormatted = this.momentFormatter(
    this.DATE_FORMATTING_STRINGS.day_number
  );

  getMonthAndYearFormatted = (date: MomentInput): string => {
    // https://en.wikipedia.org/wiki/Date_format_by_country
    let localeSpecificFormat = this.DATE_FORMATTING_STRINGS.month_and_year;

    if (['ja', 'zh', 'zh-Hanz'].includes(this.deviceLanguage)) {
      localeSpecificFormat = this.DATE_FORMATTING_STRINGS.year_and_month;
    }

    return this.momentFormatter(localeSpecificFormat)(date);
  };

  getHtml5DateFormatted = this.momentFormatter(moment.HTML5_FMT.DATE);
  getRelativeDateFormatted = (date: MomentInput): string =>
    this.moment(date).calendar();

  getFromNow = (date: MomentInput): string => this.moment(date).fromNow(true);

  getVerboseDateAndTimeFormatted = (date: MomentInput): string => {
    if (this.dateService.isToday(date as MomentTZInput)) {
      return (
        this.$translate.instant('DATE_FORMAT_TODAY') +
        ' ' +
        this.atTimeFormatter(date)
      );
    }
    if (this.dateService.isYesterday(date as MomentTZInput)) {
      return (
        this.$translate.instant('DATE_FORMAT_YESTERDAY') +
        ' ' +
        this.atTimeFormatter(date)
      );
    }
    return this.getDateFormatted(date) + ' ' + this.atTimeFormatter(date);
  };

  atTimeFormatter = (date: MomentInput): string => {
    return this.$translate.instant('DATE_FORMAT_AT', {
      time: this.getTimeFormatted(date),
    });
  };

  getTimezoneFormatted = (timezone: string, date: MomentInput): string => {
    date = this.moment(date);
    const zone = this.momentTZ.tz.zone(timezone) as MomentZone;
    const offsetInMinutes = zone.utcOffset(date as unknown as number) * -1;
    const hours = (offsetInMinutes / NB_MINUTES_IN_ONE_HOUR) | 0;
    const minutes = offsetInMinutes % NB_MINUTES_IN_ONE_HOUR | 0;
    const offsetFormatted = hours + (minutes ? `:${minutes}` : '');
    const timezoneRegionName = (zone.name.split('/').pop() as string).replace(
      '_',
      ' '
    );
    const plusSign = offsetInMinutes >= 0 ? '+' : '';

    return `${timezoneRegionName} (GMT${plusSign}${offsetFormatted})`;
  };

  getDateFrameFormatted = (
    start: MomentInput,
    end: MomentInput,
    timezone: string
  ): string => {
    const startDate = this.momentTZ.tz(start, timezone);
    const endDate = this.momentTZ.tz(end, timezone);
    const isSameDay = startDate.isSame(endDate, 'day');
    const getOneDayFormatted = () =>
      startDate.format(
        `${this.DATE_FORMATTING_STRINGS.date} | ${this.DATE_FORMATTING_STRINGS.time}`
      ) +
      ' - ' +
      endDate.format(this.DATE_FORMATTING_STRINGS.time);
    const getMultiDaysFormatted = () =>
      startDate.format(
        `${this.DATE_FORMATTING_STRINGS.date} | ${this.DATE_FORMATTING_STRINGS.time}`
      ) +
      ' - ' +
      endDate.format(
        `${this.DATE_FORMATTING_STRINGS.date} | ${this.DATE_FORMATTING_STRINGS.time}`
      );

    return isSameDay ? getOneDayFormatted() : getMultiDaysFormatted();
  };

  getToNowDateFormatted = (date: MomentInput): string => {
    if (this.dateService.isOverdue(date as MomentTZInput)) {
      return this.$translate.instant('DATE_FORMAT_OVERDUE');
    }
    if (this.dateService.isToday(date as MomentTZInput)) {
      return this.$translate.instant('DATE_FORMAT_TODAY');
    }

    if (this.dateService.isTomorrow(date as MomentTZInput)) {
      return this.$translate.instant('DATE_FORMAT_TOMORROW');
    }

    if (this.dateService.isThisWeek(date as MomentTZInput)) {
      return this.$translate.instant('DATE_FORMAT_THIS_WEEK');
    }

    if (this.dateService.isThisMonth(date as MomentTZInput)) {
      return this.$translate.instant('DATE_FORMAT_THIS_MONTH');
    }

    return this.getDateFormatted(date);
  };

  getWeekdaysFormatted = (): string[] => this.moment.weekdays();

  getDeviceTz = (): string => this.momentTZ.tz.guess();
}
