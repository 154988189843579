import { TasksListComponent } from './tasks-list.component';

export default angular
  .module('sfMobile.components.tasks-list', [
    'sfMobile.services.tasks',
    'sfMobile.services.task',
    'sfMobile.services.helpers',
    'sfMobile.services.dates',
    'sfMobile.services.requests-paginate',
    'sfMobile.components.taskItem',
    'sfMobile.components.content',
    'sfMobile.components.divider-date-overdue',
    'sfMobile.services.pubsub',
  ])
  .component('sfTasksList', TasksListComponent).name;
