export function StepCompilerDirective($compile: ng.ICompileService) {
  'ngInject';
  return (scope: ng.IScope, element, attrs: ng.IAttributes) => {
    scope.$watch(
      (scopeObj) => scopeObj.$eval(attrs.stepCompiler),
      (value) => {
        element.html(value);
        $compile(element.contents())(scope);
      }
    );
  };
}
