export const ScoringSectionsComponent = {
  bindings: {
    sections: '<',
    scoreSections: '<',
  },
  templateUrl:
    'missions/components/Scoring/scoring-sections/scoring-sections.html',
  controller: class ScoringSectionsController {
    $onInit() {
      this.sectionsScoreHash = {};

      this.initSectionStats();
    }

    initSectionStats() {
      this.sectionsScoreHash = (this.scoreSections || []).reduce(
        (hash, data) => this.getHash(hash, data),
        {}
      );
    }

    getHash(hash, data) {
      if (data) {
        hash[data.for_id] = data;
      }
      return hash;
    }

    getPercent(section) {
      const score = this.getSectionScore(section._id);

      return Math.round(score.percent * 100);
    }

    getSectionScore(sectionId) {
      return this.sectionsScoreHash[sectionId];
    }
  },
};
