import moment from 'moment';

export function WeekNavigationService(dateService, dateFormatService) {
  'ngInject';
  this.moment = moment;

  this.getWeek = (dateFrom) => {
    const monday = this.moment(dateFrom).startOf('isoWeek');

    return dateService.generateWeekDaysArray(monday).map((date) => ({
      date,
      dayStr: dateFormatService.getHtml5DateFormatted(date),
    }));
  };
}
