import { CorporateEvent } from '../../../../types';
import { Modal, ModalService } from '../../../../../services/Utils/Modal';

export class CorporateEventsListController implements ng.IComponentController {
  /** Bindings */
  events: CorporateEvent[];
  onItemClick: () => void;

  /** Fields */
  corporateEvents: CorporateEvent[] = [];

  constructor(private readonly modalService: ModalService) {
    'ngInject';
  }

  $onInit(): void {
    this.setCorporateEvents(this.events);
  }

  $onChanges(changes: ng.IOnChangesObject): void {
    if (changes?.events?.currentValue) {
      this.setCorporateEvents(changes.events.currentValue);
    }
  }

  setCorporateEvents(events: CorporateEvent[]): void {
    // sort alphabetically by corporate event name
    this.corporateEvents = events.sort((a, b) =>
      a.contents.name.localeCompare(b.contents.name)
    );
  }

  displayCorporateEventDetails(event: CorporateEvent): Modal {
    this.onItemClick && this.onItemClick();

    const eventDetailsTemplate = `
      <sf-corporate-event-details-modal
        event="$ctrl.event"
        on-close="$ctrl.onClose()"
      </sf-corporate-event-details-modal>
    `;

    const detailsModal = this.modalService.open(eventDetailsTemplate, {
      event,
    });
    return detailsModal;
  }
}
