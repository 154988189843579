import { CampaignSelectorService } from './campaign-selector.service';

export default angular
  .module('sfMobile.services.campaignsSelector', [
    'sfMobile.services.places',
    'sfMobile.services.forms',
    'sfMobile.services.reports',
    'sfMobile.services.geolocation',
    'sfMobile.services.missions',
    'sfMobile.services.objectId',
    'sfMobile.services.modal',
    'sfMobile.services.helpers',
    'sfMobile.components.campaignSelector',
  ])
  .factory('campaignsSelector', CampaignSelectorService).name;
