class ChatMessageActionsService {
  // eslint-disable-next-line max-params
  constructor(
    $q,
    $translate,
    chatPopupService,
    chatApiService,
    clipboardService
  ) {
    'ngInject';
    this.$q = $q;
    this.$translate = $translate;
    this.chatPopupService = chatPopupService;
    this.chatApiService = chatApiService;
    this.clipboardService = clipboardService;
  }

  getActions({ message, isAllowedToDelete, isAllowedToCopy, onCopyCb }) {
    const actions = [];

    if (isAllowedToCopy) {
      actions.push({
        text: this.$translate.instant('CHAT_ROOM_MESSAGE_ACTIONS_COPY'),
        onClick: () =>
          this.clipboardService
            .copyText(message.message)
            .then(() => onCopyCb && onCopyCb()),
      });
    }
    if (isAllowedToDelete) {
      actions.push({
        text: this.$translate.instant('CHAT_ROOM_MESSAGE_ACTIONS_DELETE'),
        onClick: () =>
          this.chatPopupService
            .showDeleteMessageConfirm()
            .then(() => this.chatApiService.deleteMessage(message)),
      });
    }

    return actions;
  }
}

export { ChatMessageActionsService };
