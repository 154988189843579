import { Campaign } from '@services/API/campaigns/campaigns';
import { ReportsService } from '@services/API/reports/reports.service';
import { ContentService } from 'app/components/Layout/content/content.service';
import { CampaignsUtilsService } from 'app/reactive-campaigns/services/campaigns-utils.service';
import { ObjectId } from '../../..';

type Document = {
  _id: ObjectId;
  name: string;
  size: number;
  mime_type: 'string';
};

const NB_DOCUMENTS_LIMIT = 20;
const DOCUMENTS_LIST_SEARCH_CRITERIAS = ['document_title'];

export const DocumentListComponent: ng.IComponentOptions = {
  bindings: {
    campaign: '<',
    contentKey: '@',
    key: '@',
  },
  templateUrl: 'digital-assets/components/document-list/document-list.html',
  controller: class DocumentListCController implements ng.IComponentController {
    campaign: Campaign;
    contentKey: string;

    documents: Document[];
    isLoading = false;
    isEmpty = false;
    searchField = '';

    isSearchLoading = false;
    searchError = '';
    infiniteLoadError = false;
    networkError = false;
    formFilesPath = '';

    requestPaginate;
    documentsFiltered = [];

    private shapeDocumentToFile(file) {
      return {
        _id: file._id,
        name: file.contents.fileName,
        mime_type: file.contents.fileType,
        size: file.contents.fileLength,
      };
    }

    constructor(
      private reportsService: ReportsService,
      private RequestsPaginate,
      private contentService: ContentService,
      private apiUtilsService,
      private campaignsUtilsService: CampaignsUtilsService,
      private formsService
    ) {
      'ngInject';
    }

    $onInit(): void {
      const limit = NB_DOCUMENTS_LIMIT;
      const firstFetchLimit = NB_DOCUMENTS_LIMIT;

      this.requestPaginate = new this.RequestsPaginate(
        this.reportsService.getDocumentsMedias.bind(this.reportsService),
        {
          limit,
          firstFetchLimit,
        }
      );

      const form = this.campaignsUtilsService.getFormFromCampaign(
        this.campaign
      );
      this.formFilesPath = this.formsService.getFilesPath(form._id);

      this.documentsFiltered = [];
      this.isSearchLoading = false;

      this.initView();

      this.callDocuments();
    }

    initView = () => {
      this.documentsFiltered.length = 0;
      this.infiniteLoadError = false;

      this.requestPaginate.reset();

      this.contentService.scrollTopById(this.contentKey);
    };

    callDocuments() {
      this.isLoading = true;

      return this.getDocuments()
        .then((documents: Document[] | void) => {
          this.networkError = false;

          if (!this.searchField) {
            this.isEmpty = !documents?.length;
          }

          return documents;
        })
        .catch((err) => this.requestError(err))
        .finally(() => {
          this.isLoading = false;
        });
    }

    resetInfiniteLoadErrorState() {
      this.infiniteLoadError = false;
    }

    getDocuments(params = {}) {
      const filterParams = this.composeFilterParamsReq(params);

      this.infiniteLoadError = false;

      return this.requestPaginate
        .call(filterParams)
        .then(({ entities }) => {
          const documents = entities.map(this.shapeDocumentToFile);

          this.documentsFiltered = entities.map(this.shapeDocumentToFile);

          return documents;
        })
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        });
    }

    reload() {
      return this.callDocuments();
    }

    onSearchChange(search: string) {
      this.isSearchLoading = true;

      this.initView();

      return this.getDocuments({ search })
        .catch((err) => this.requestError(err))
        .finally(() => {
          this.isSearchLoading = false;
        });
    }

    requestError(err) {
      this.networkError = true;

      throw err;
    }

    composeFilterParamsReq(params) {
      return this.apiUtilsService.buildFilterParams(
        [{ name: 'campaign_id', value: this.campaign._id, operator: '$eq' }],
        { search: params.search, criterias: DOCUMENTS_LIST_SEARCH_CRITERIAS }
      );
    }
  },
};
