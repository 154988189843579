import { IAPIResource } from '../../..';
import { DashboardFilter } from '../../../services/API/external-dashboard/external-dashboard-api.service';
import { PlacesList } from '../../../services/API/placesLists/placesLists';
import { UsersService } from '../../../services/API/users/users.service';

export type Filter = {
  id: string;
  label: string;
  type?: 'picker' | 'select';
  values?: FilterValue[];
  selectedValues?: string[];
};

export type FilterValue = {
  _id: string;
  value: string;
  label: string;
};

export class SalesDashboardFiltersService {
  // eslint-disable-next-line max-params
  constructor(
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private usersService: UsersService,
    private placesListsService,
    private placesService
  ) {
    'ngInject';
  }

  generateFilters(dashboardFilters: DashboardFilter[]): ng.IPromise<Filter[]> {
    const filterPromises: ng.IPromise<Filter>[] = dashboardFilters.map(
      (filter) => {
        switch (filter.name) {
          case 'users':
            return this.generateUserFilter();
          case 'places':
            return this.generatePlaceFilter();
          case 'storeGroups':
            return this.generateStoreGroupFilter();
          case 'teams':
            return this.generateTeamFilter();
          default:
            return this.$q.resolve({
              id: '',
              value: '',
              label: '',
            });
        }
      }
    );

    return this.$q
      .all(filterPromises)
      .then((filters) => filters.filter((filter) => filter.id !== ''));
  }

  generateUserFilter(): ng.IPromise<Filter> {
    return this.usersService
      .getAllUsers()
      .then((res) => {
        return res.entries.map((user) => {
          return {
            _id: user._id,
            value: user._id,
            label: `${user.contents.firstName} ${user.contents.lastName}`,
          };
        });
      })
      .then((userFilters) => {
        return {
          id: 'users',
          label: this.$translate.instant('SALES_DASHBOARD_USER_FILTER'),
          values: userFilters,
        };
      });
  }

  generatePlaceFilter(): ng.IPromise<Filter> {
    return this.placesService
      .apiList({})
      .then((res) => {
        return res.map((place) => {
          return {
            _id: place._id,
            value: place._id,
            label: place.contents.name,
          };
        });
      })
      .then((placeFilters) => {
        return {
          id: 'places',
          label: this.$translate.instant('SALES_DASHBOARD_PLACE_FILTER'),
          values: placeFilters,
        };
      });
  }

  generateTeamFilter(): ng.IPromise<Filter> {
    return this.usersService
      .getAllUsersGroups()
      .then((res) => {
        return res.entries.map((team) => {
          return {
            _id: team._id,
            value: team._id,
            label: team.contents.name,
          };
        });
      })
      .then((teamsFilters) => {
        return {
          id: 'teams',
          label: this.$translate.instant('SALES_DASHBOARD_TEAM_FILTER'),
          values: teamsFilters,
        };
      });
  }

  generateStoreGroupFilter(): ng.IPromise<Filter> {
    return this.placesListsService
      .apiList()
      .then((res: IAPIResource<PlacesList>[]) => {
        return res.map((placeGroup) => {
          return {
            _id: placeGroup._id,
            value: placeGroup._id,
            label: placeGroup.contents.name,
          };
        });
      })
      .then((storeGroupFilters) => {
        return {
          id: 'storeGroups',
          label: this.$translate.instant('SALES_DASHBOARD_STORE_GROUP_FILTER'),
          values: storeGroupFilters,
        };
      });
  }
}
