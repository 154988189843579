const SEARCH_FIELD_ID_PREFIX = 'sfSearchField';

export const SearchFieldComponent = {
  require: {
    modelCtrl: 'ngModel',
  },
  bindings: {
    placeholder: '@',
    onFocus: '&',
  },
  templateUrl: 'components/Search/search-field/search-field.html',
  controller: class SearchFieldController {
    constructor($element) {
      'ngInject';
      Object.assign(this, {
        $element,
      });
    }

    $onInit() {
      const timestamp = new Date().getTime();

      this.modelCtrl.$render = () => {
        this.search = this.modelCtrl.$viewValue || '';
      };
      this.SEARCH_FIELD_ID = `${SEARCH_FIELD_ID_PREFIX}_${timestamp}`;
    }

    onValueChange() {
      this.modelCtrl.$setViewValue(this.search);
    }

    onFieldSubmit() {
      const field = this.$element[0].querySelector(`#${this.SEARCH_FIELD_ID}`);

      return field ? field.blur() : null;
    }
  },
};
