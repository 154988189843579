import TaskDetailsActionsModule from './components/task-details-actions/task-details-actions.module';
import { TaskDetailsComponent } from './task-details.component';

export default angular
  .module('sfMobile.components.task-details', [
    'sfMobile.services.localization',
    'sfMobile.services.tasks',
    'sfMobile.services.dates',
    'sfMobile.services.router',
    'sfMobile.services.task',
    'sfMobile.components.task-priority-label',
    TaskDetailsActionsModule,
  ])
  .component('sfTaskDetails', TaskDetailsComponent).name;
