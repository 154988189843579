/**
 * @typedef Query
 * @property {String} name - Filter name ex: pos_name
 * @property {String} value -  Filter value ex: Paris
 * @property {String} operator - Filter operator ex: $eq
 *
 * @typedef SearchDefinition
 * @property {String} search - Searching value
 * @property {String} criterias - Properties to filter on
 */
export function ApiUtilsService() {
  'ngInject';
  const getSearchQuery = (search, operator = '$eq') => ({ [operator]: search });

  /**
   * Get a list of query filters by criterias
   * @param {Object} searchQuery - Search query. ex: { $eq 'Paris' }
   * @param {Array} criterias - Criterias to search on. ex: ['pos_name', 'pos_city']
   * @return {Array} Query search
   */
  const getFiltersByCriterias = (searchQuery, criterias = []) =>
    criterias.map((criteria) => ({ [criteria]: searchQuery }));
  /**
   * Get a list of filters with a set of definitions of these query filters
   * @param {Query[]} [filters=[]] - List of filters to apply
   * @return {Array} Query search
   */
  const getFiltersByDefinitions = (filters = []) =>
    filters.map((filter) =>
      filter.sfqlOperator
        ? {
            [filter.sfqlOperator]: filter.value,
          }
        : {
            [filter.name]: getSearchQuery(filter.value, filter.operator),
          }
    );

  /**
   * Get a the filters params format for the request
   * @param {Array} queryFilters - List of query filters
   * @param {Array} [searchQueryFilters=[]] - List of search query filters
   * @return {Array} Query search
   */
  const buildFilters = (queryFilters, searchQueryFilters = []) => ({
    filters: {
      $and: [
        ...queryFilters,
        ...(searchQueryFilters.length ? [{ $or: searchQueryFilters }] : []),
      ],
    },
  });
  /**
   * Build a filter params object to request a set of datas
   * @param {Query[]} filters - List of filters to apply
   * @param {SearchDefinition[]} [searchOptions={}] - Searching options
   * @returns {Object} Filter params
   */
  const buildFilterParams = (filters, searchOptions = {}) => {
    const { search, criterias } = searchOptions;

    if (!filters && !search) {
      return null;
    }

    const queryFilters = getFiltersByDefinitions(filters || []);
    const searchQueryFilters = search
      ? getFiltersByCriterias(getSearchQuery(search, '$regex'), criterias)
      : [];

    return buildFilters(queryFilters, searchQueryFilters);
  };

  return {
    getFiltersByCriterias,
    getFiltersByDefinitions,
    buildFilterParams,
  };
}
