import { ButtonDocumentComponent } from './button-document.component';

export default angular
  .module('sfMobile.components.button-document', [
    'ngMessages',
    'sfMobile.services.files',
    'sfMobile.services.document-viewer',
    'sfMobile.constants.error-codes',
    'sfMobile.services.file-system',
    'sfMobile.components.button-upload',
    'sfMobile.services.image-source',
  ])
  .component('sfButtonDocument', ButtonDocumentComponent).name;
