import { TSFixMe } from '../../../..';
import { ContentService } from '../../../Layout/content/content.service';

export class EntitySelectorModalController implements ng.IComponentController {
  //bindings
  emptyIcon: string;
  searchPlaceholder: string;
  title: string;
  multiple: boolean;
  selected: TSFixMe;
  filtersAvailable: boolean;
  maxSelectedEntities: number;
  entityTitleGet: () => string;
  entityDescriptionGet: () => string;
  entityGetNumberOfEntities?: () => number;
  entityImageGet: () => TSFixMe;
  entityIconClassGet: () => string;
  onImageClick: () => void;
  onClose: () => void;
  onSave: () => void;
  onReload: () => void;
  onQueryChange: (query: TSFixMe) => void;
  onSelectionChange: (query: TSFixMe) => void;
  iconClass: string;
  minSearchLength = 0;
  checkOfflineFn: () => ng.IPromise<boolean>;

  search: string;
  filters: TSFixMe[];
  state: { isLoading: boolean; isSearchLoading: boolean };
  selectedEntities: TSFixMe;
  selectedEntity: TSFixMe;
  dataProvider: TSFixMe;
  hasLoadingError: boolean;
  entities: TSFixMe;
  infiniteLoadError: boolean;
  onScrollComplete: () => void;

  isOffline = false;

  /* @ngInject */
  constructor(
    private contentService: ContentService,
    private $q: ng.IQService
  ) {}

  $onInit(): ng.IPromise<void> {
    this.search = '';
    this.filters = [];
    this.initializeSelected();
    this.updateDataProvider();
    this.state = {
      isLoading: false,
      isSearchLoading: false,
    };

    return this.load(true);
  }

  change(entity): void {
    return this.onSelectionChange({
      selection: {
        entity,
        selected: this.multiple ? this.selectedEntities[entity._id] : true,
      },
    });
  }

  initializeSelected(): void {
    if (this.multiple) {
      this.selectedEntities = (this.selected || []).reduce((acc, el) => {
        acc[el._id] = true;

        return acc;
      }, {});
    } else {
      this.selectedEntity = this.selected ? this.selected._id : {};
    }
  }

  isValid(): boolean {
    return !this.isMaxSelected() && this.multiple
      ? Object.values(this.selectedEntities).filter(Boolean).length > 0
      : !!this.selectedEntity;
  }

  updateDataProvider(): void {
    this.dataProvider = this.onQueryChange({
      query: {
        search: this.search,
        filters: this.filters,
      },
    });
  }

  reload(): ng.IPromise<void> {
    this.onReload();
    return this.load();
  }

  pingOffline(): ng.IPromise<boolean> {
    if (this.checkOfflineFn) {
      return this.checkOfflineFn().then((isOffline) => {
        this.isOffline = isOffline;

        return this.$q.resolve(isOffline);
      });
    }

    return this.$q.resolve(false);
  }

  load(isLoading = false, isSearchLoading = true): ng.IPromise<void> {
    this.state = {
      isLoading, // dirty fix to prevent place-selector full page reload in the browser
      isSearchLoading:
        isSearchLoading && this.search.length > this.minSearchLength, // search data loading
    };
    this.hasLoadingError = false;

    return this.pingOffline()
      .then((isOffline) => {
        return !isOffline
          ? this.dataProvider.load().then(({ entities }) => {
              this.entities = entities;
            })
          : this.$q.resolve();
      })
      .catch((e) => {
        this.hasLoadingError = true;
        throw e;
      })
      .finally(() => {
        this.state = {
          isLoading: false,
          isSearchLoading: false,
        };
      });
  }

  loadMore(): ng.IPromise<void> {
    this.infiniteLoadError = false;

    return this.load(false, false)
      .catch((err) => {
        this.infiniteLoadError = true;
        throw err;
      })
      .finally(() => {
        if (typeof this.onScrollComplete === 'function') {
          this.onScrollComplete();
        }
      });
  }

  onSearchChange(search: string): ng.IPromise<void> {
    this.search = search;
    this.updateDataProvider();

    return this.load().then(() => {
      this.contentService.scrollTopById('entitySelectorContent');
    });
  }

  onFiltersChange(filters): ng.IPromise<void> {
    this.filters = filters;
    this.updateDataProvider();

    return this.load();
  }

  onSaveButtonClick(): void {
    this.onClose();

    return this.onSave();
  }

  getSelectedCount(): number | TSFixMe {
    let selectedEntitiesNumber = 0;
    if (this.multiple && this.selectedEntities) {
      selectedEntitiesNumber = Object.values(this.selectedEntities).filter(
        (value) => !!value
      ).length;
    }
    return selectedEntitiesNumber;
  }

  isMaxSelected(): boolean {
    if (!this.maxSelectedEntities) {
      return false;
    }

    return this.getSelectedCount() > this.maxSelectedEntities;
  }

  hideError(): void {
    this.hasLoadingError = false;
  }
}
