export const InputCheckboxItemFreeComponent = {
  bindings: {
    label: '@',
    inputName: '@',
    value: '=',
    hasError: '<',
    onValueChange: '&',
    disabled: '<',
    isRequired: '<',
    isPopupBehavior: '<',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  transclude: true,
  templateUrl:
    'components/Inputs/input-checkbox-item-free/input-checkbox-item-free.html',
  controller: function InputCheckboxItemFreeController(
    $element,
    $q,
    popupService
  ) {
    'ngInject';

    const focusTextField = () => {
      const textField = $element[0].querySelector('.sf_input_check_free__text');

      if (textField) {
        textField.focus();
      }
    };

    this.$onInit = () => {
      this.modelCtrl.$render = () => {
        this.isSelected = !!this.modelCtrl.$viewValue;
      };
    };

    this.onSelect = (isSelected) => {
      if (isSelected) {
        focusTextField();
      }
      this.onChange();
    };
    this.onTextChange = () => this.onValueChange({ value: this.value });
    this.onChange = () => this.modelCtrl.$setViewValue(this.isSelected);

    this.hasTextError = () =>
      this.isSelected &&
      (this.value === '' || this.value === null || {}.undef === this.value);

    this.openPopupField = (event) => {
      event.stopPropagation();

      if (this.disabled || !this.isPopupBehavior) {
        return $q.reject();
      }

      return popupService
        .prompt({ inputValue: this.value })
        .promptPromise.then((value) => {
          this.value = value;
          this.onValueChange({ value });
          return value;
        });
    };
  },
};
