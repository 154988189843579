import {
  isAuthUrl,
  isAnExpiredTokenCode,
  isMalformedToken,
} from './auth-helpers';

// eslint-disable-next-line max-params
export default function unauthorizedInterceptor(
  $injector,
  $q,
  $log,
  $translate,
  sentryService
) {
  'ngInject';
  return {
    responseError: unauthorizeError,
    request: addPromiseOnRequests,
  };

  function addPromiseOnRequests(config) {
    if (!config.timeout) {
      config.cancel = $q.defer();
      config.timeout = config.cancel.promise;
    }
    return config;
  }

  function showErrorAndLogout(authService, error, title, desc, args = {}) {
    const popupService = $injector.get('popupService');

    return popupService.showError(
      {
        title: $translate.instant(title),
        desc: $translate.instant(desc, args),
        btnText: $translate.instant('POPUP_OK'),
      },
      () => {
        // Removing the device imply that we can do API call (update on user), but we can't.
        return authService
          .logout({ removeDevice: false })
          .finally(() => $q.reject(error));
      }
    );
  }

  function sendToSentry(error) {
    try {
      sentryService.captureMessage('UNAUTHORIZED_ERROR', {
        level: 'error',
        extra: {
          data: error.response
            ? JSON.stringify(error.response.data)
            : 'no data',
          error,
        },
        tags: {
          status: error.response ? error.response.status : 'no status',
          code:
            error.response && error.response.data
              ? error.response.data.code
              : 'no code',
        },
      });
    } catch (e) {
      $log.error('Error while sending to Sentry', e);
    }
  }

  function unauthorizeError(error) {
    sendToSentry(error);

    if (!error || !error.response) {
      return $q.reject(error);
    }
    const authService = $injector.get('authService');
    const resUrl = error.response.config.url;
    const isUnauthorized =
      !isAuthUrl(resUrl) &&
      error.response.status === 401 &&
      !isAnExpiredTokenCode(error.response);
    const isJwtMalformed =
      !isAuthUrl(resUrl) && isMalformedToken(error.response);
    const noUserInSFDB =
      (error.response.status === 403 || error.response.status === 401) &&
      error.response.data &&
      error.response.data.code === 'E_AUTH0_USER';

    const ipNotAllowed =
      (error.response.status === 403 || error.response.status === 401) &&
      error.response.data &&
      error.response.data.code === 'E_IP_NOT_ALLOWED';

    if (noUserInSFDB && !authService.logout.pending) {
      $log.error(
        '[Auth0] User not found in SimpliField DB but is OK on Auth0 side'
      );

      return showErrorAndLogout(
        authService,
        error,
        'POPUP_ACCOUNT_NOT_FOUND_TITLE',
        'POPUP_ACCOUNT_NOT_FOUND_DESC'
      );
    }

    if (ipNotAllowed && !authService.logout.pending) {
      $log.error('IP address Not allowed', JSON.stringify(error.response));
      return showErrorAndLogout(
        authService,
        error,
        'POPUP_IP_NOT_ALLOWED_TITLE',
        'POPUP_IP_NOT_ALLOWED_DESC',
        { ip: error.response.data.params[0] }
      );
    }

    if (isJwtMalformed && !authService.logout.pending) {
      $log.error('[Auth] Unauthorized : ', error.errorCode, error.message);

      return showErrorAndLogout(
        authService,
        error,
        'POPUP_UNAUTHORIZED_TITLE',
        'POPUP_UNAUTHORIZED_DESC'
      );
    }

    if (isUnauthorized && !authService.logout.pending) {
      // should retry
      $log.error('[Auth] Unauthorized : ', error.errorCode, error.message);

      return showErrorAndLogout(
        authService,
        error,
        'POPUP_UNAUTHORIZED_TITLE',
        'POPUP_UNAUTHORIZED_DESC'
      );
    }
    // give up
    return $q.reject(error);
  }
}
