import {
  BoxAccess,
  DocumentLibraryService,
} from '../../../services/Utils/DocumentLibrary/document-library.service';
import {
  BoxImport,
  BoxInjectionService,
} from '../../../services/Utils/BoxInjection/box-injection.service';
import {
  Box,
  ContentExplorer,
} from '../../../services/Utils/BoxInjection/typings/box';
import { PlatformService } from '../../../services/Utils/Platform';
import { FileSystemService } from '../../../services/Utils/FileSystem/file-system.service';
import { ImageSourceService } from '../../../services/Utils/ImageSource/image-source.service';
import { Organisation } from '../../..';
import { ImageService } from '../../../services/Utils/Image/image.service';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { DocumentsDownloadService } from '../../../services/Utils/DocumentsDownload/documents-download.service';
import {
  PubSubService,
  UnregisterFn,
} from '../../../services/Utils/PubSub/pubsub.service';

export const BoxContentExplorerComponent = {
  templateUrl:
    'document-library/components/box-content-explorer/box-content-explorer.html',
  bindings: {
    organisation: '<',
  },
  controller: class BoxContentExplorerController implements ng.IController {
    //bindings
    organisation: Organisation;

    // class members
    containerId = 'box-content-explorer';
    fallbackLogoUrl = 'img/logo-black.png';
    FOLDER_PATH = 'document-library/files';
    logoUrl;
    contentExplorer: ContentExplorer;
    importedStyle: BoxImport[] = ['preview', 'explorer'];
    deferredBoxLoad: ng.IDeferred<[BoxAccess, Box]> = this.$q.defer();
    organisationSwitchListener: UnregisterFn;

    /* @ngInject */
    constructor(
      private $log: ng.ILogService,
      private $translate: ng.translate.ITranslateService,
      private boxInjectionService: BoxInjectionService,
      private documentDownloadService: DocumentsDownloadService,
      private documentLibraryService: DocumentLibraryService,
      private documentViewerService,
      private filesSystemService: FileSystemService,
      private imageSourceService: ImageSourceService,
      private platformService: PlatformService,
      private imageService: ImageService,
      private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
      private $q: ng.IQService,
      private $window: ng.IWindowService,
      private pubSubService: PubSubService,
      private organisationsService
    ) {}

    $onInit(): ng.IPromise<void> {
      this.organisationSwitchListener = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.ORGANISATION_SWITCH,
        (data: { profile }) => {
          return this.organisationsService
            .getProfileOrganisation(data.profile)
            .then((organisation) => {
              this.organisation = organisation;
              this.getLogoUrl();
            });
        }
      );

      return this.$q
        .all([
          this.documentLibraryService.getBoxToken(),
          this.boxInjectionService.import('preview'),
          this.boxInjectionService.import('explorer'),
          this.getLogoUrl(),
        ])
        .then(([boxAccess, box]) =>
          this.deferredBoxLoad.resolve([boxAccess, box])
        )
        .catch((error) => {
          this.$log.error('Box ContentExplorer download error: ', error);
        });
    }

    $postLink(): ng.IPromise<void> {
      const boxId = `#${this.containerId}`;
      return this.deferredBoxLoad.promise
        .then(([{ accessToken, resourceId }, box]) => {
          // after tap on back btn and route transition  there is no sence to start box
          const boxdiv = this.$window.document.querySelector(boxId);
          if (!boxdiv) {
            this.$log.warn('No div for Box ContentExplorer');
            return;
          }
          if (!this.platformService.isBrowser()) {
            //Use download manager on mobile
            this.boxInjectionService.setDownloadCallback(this.downloadOnMobile);
          }

          this.contentExplorer = new box.ContentExplorer();

          return this.showContentExplorer(boxId, accessToken, resourceId);
        })
        .catch((error) => {
          this.$log.error('Box ContentExplorer init error: ', error);
        });
    }

    $onDestroy(): void {
      if (!this.platformService.isBrowser()) {
        this.boxInjectionService.clearDownloadCallback();
      }
      this.contentExplorer?.hide();
      this.boxInjectionService.removeBox(this.importedStyle);
      if (this.organisationSwitchListener) {
        this.organisationSwitchListener();
      }
    }

    showContentExplorer(
      container: string,
      token: string,
      folder: string
    ): void {
      this.contentExplorer.show(folder, token, {
        container: container,
        isTouch: true,
        logoUrl: this.logoUrl ?? this.fallbackLogoUrl,
      });

      const safeAreaTopPx = this.platformService.getSafeAreaValue('top');
      this.contentExplorer.addListener('preview', () => {
        window.document.documentElement.style.setProperty(
          '--sat',
          `${safeAreaTopPx}px`
        );
      });
    }

    private downloadOnMobile = (
      url: string,
      filename: string,
      extension: string
    ) => {
      const fileOptions = {
        directory: this.filesSystemService.getDeviceFilePath(),
        path: this.FOLDER_PATH,
        name: filename,
      };
      const document = this.imageSourceService.create({
        id: filename,
        url,
      });
      if (this.platformService.isAndroid()) {
        return this.documentDownloadService.downloadOnAndroid(url, filename);
      }
      return this.documentDownloadService
        .download(fileOptions, document, {
          isPopupsShow: true,
          onSuccessMessage: this.$translate.instant(
            'BOX_PREVIEW_DOCUMENT_DOWNLOAD_SUCCESS'
          ),
        })
        .then((path: string) => {
          return this.documentViewerService.openDocument(
            path,
            this.filesSystemService.getMimeTypeByExt(extension)
          );
        })
        .catch((error) => {
          this.$log.error('Box download error: ', error);
        });
    };

    getLogoUrl(): ng.IPromise<string> {
      if (!this.organisation?.contents?.logo_id) {
        this.logoUrl = this.fallbackLogoUrl;
        return this.$q.when('');
      }

      return this.imageService
        .getSizedUrlFromId(
          this.organisation.contents.logo_id,
          this.SF_IMAGE_SIZES.SQUARE_SMALL
        )
        .then((url) => {
          this.logoUrl = url;
          return url;
        })
        .catch((error) => {
          this.$log.error(
            'An error occured when retrieving organisation logo',
            error
          );
          return this.fallbackLogoUrl;
        });
    }
  },
};
