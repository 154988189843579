const OPERATION_TRANSFORM = {
  regex: {
    remote: (value) => ({ $regex: value }),
    local: (value) => new RegExp(value),
  },
  equality: {
    remote: (value) => (Array.isArray(value) ? { $in: value } : { $eq: value }),
    local: (value) => value,
  },
  inequality: {
    remote: (value) =>
      Array.isArray(value) ? { $nin: value } : { $ne: value },
    local: (value) => value,
  },
};
const getFilter = ({ operation, value }, mode) => {
  if (
    !operation ||
    !mode ||
    !OPERATION_TRANSFORM[operation] ||
    !OPERATION_TRANSFORM[operation][mode]
  ) {
    return value;
  }

  return OPERATION_TRANSFORM[operation][mode](value);
};

// eslint-disable-next-line max-params
export function DataSourceAdapterService(
  crudFactory,
  localStorageService,
  $window,
  pubSubService
) {
  'ngInject';

  let methods = {
    query,
  };

  function DataSourceAdapter(name, options) {
    methods = Object.assign(crudFactory(name, options), methods, {
      isRemoteSource:
        localStorageService.get(options.incomplete_sync_key) &&
        $window.navigator.onLine,
    });

    methods.synchronizeListener = pubSubService.subscribe(
      pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
      () => {
        methods.isRemoteSource =
          localStorageService.get(options.incomplete_sync_key) &&
          $window.navigator.onLine;
      }
    );

    return methods;
  }

  /**
   * List datas using remote or local database
   * @param {Boolean} onlineAllowed  - Are request on remote database allowed ?
   * @param {Object} params          - Object containing request params
   * @return {Promise}               - Promise of fetched data
   * @this DataSourceAdapterService
   */
  function query(onlineAllowed, params = {}, forceUrl, config, listComparator) {
    if (onlineAllowed && this.isRemoteSource) {
      const formattedParams = formatParamsForRemote(
        params,
        this.options.available_search_filter,
        this.options.customParamPrefix,
        this.options.available_sorting
      );

      const useUserProfile = !(config && config.pov);

      return this.simpleApiList(
        forceUrl,
        formattedParams,
        useUserProfile,
        config
      );
    }

    const formattedParams = formatParamsForLocal(
      params,
      this.options.available_search_filter,
      this.options.available_sorting
    );

    return this.queryLocal(
      formattedParams.listParams,
      formattedParams.limitParams,
      formattedParams.sortParams,
      listComparator
    ).then((data) => ({ entries: data }));
  }

  // Remote request building

  function formatParamsForRemote(
    params,
    availableFilters = {},
    customParamPrefix,
    availableSort
  ) {
    const unformattedParams = Object.keys(params)
      .filter(
        (key) =>
          !['filters', 'customParamsFilters', 'sort', 'limit', 'skip'].includes(
            key
          )
      )
      .reduce((acc, key) => {
        acc[key] = params[key];
        return acc;
      }, {});

    return {
      ...unformattedParams,
      filters: {
        $and: [].concat(
          formatStandardFilters(params.filters, availableFilters),
          formatCustomParamFilters(
            params.customParamsFilters,
            customParamPrefix
          )
        ),
      },
      limit: params.limit,
      skip: params.skip,
      sorts: formatSorting(params.sort, availableSort),
    };

    function formatStandardFilters(filters = {}, availableFilters) {
      const formattedFilters = Object.keys(filters)
        .filter((key) => availableFilters[key])
        .map((key) => ({
          [availableFilters[key].remote]: getFilter(filters[key], 'remote'),
        }));

      return formattedFilters;
    }

    function formatCustomParamFilters(filters = {}, customParamPrefix = '') {
      return Object.keys(filters).map((key) => {
        if (['date', 'number'].includes(filters[key].type)) {
          return {
            [`${customParamPrefix}${key}`]: {
              ['$eq']: [key, filters[key].value],
              forceCast: filters[key].type,
            },
          };
        }

        const sfqlOperator = Array.isArray(filters[key]) ? '$in' : '$regex';
        return {
          [`${customParamPrefix}${key}`]: {
            [sfqlOperator]: [key, filters[key]],
          },
        };
      });
    }

    function formatSorting(sort, availableSort) {
      return availableSort[sort] ? availableSort[sort].remote : null;
    }
  }

  // Local request building

  function formatParamsForLocal(
    params = {},
    availableFilters = {},
    availableSort
  ) {
    return {
      listParams: {
        ...formatStandardFilters(params.filters, availableFilters),
        ...formatCustomParamFilters(params.customParamsFilters),
      },
      limitParams: formatLimitParams(params.skip, params.limit),
      sortParams: formatSortParams(params.sort, availableSort),
    };

    function formatStandardFilters(filters = {}, availableFilters) {
      return Object.keys(filters).reduce((formatted, key) => {
        const availableKey = availableFilters[key];

        if (availableKey) {
          formatted[availableKey.local] = getFilter(filters[key], 'local');
        }
        return formatted;
      }, {});
    }

    function formatCustomParamFilters(
      filters = {},
      customParamPrefix = 'contents.params'
    ) {
      if (Object.keys(filters).length === 0) {
        return filters;
      }

      return Object.keys(filters).reduce((acc, key) => {
        acc[customParamPrefix] = [
          ...(acc[customParamPrefix] || []),
          {
            param_id: key,
            value: filters[key],
          },
        ];
        return acc;
      }, {});
    }

    function formatLimitParams(skip, limit) {
      return {
        limit,
        offset: skip,
      };
    }

    function formatSortParams(sort, availableSort) {
      return availableSort[sort] ? [{ key: availableSort[sort].local }] : [];
    }
  }

  return DataSourceAdapter;
}
