import { NewsfeedPostFormOptionsController } from './newsfeed-post-form-options.controller';

export const NewsfeedPostFormOptionsComponent = {
  bindings: {
    onSelectCategory: '&',
    onTargetingUpdated: '&',
    onPublishDateUpdated: '&',
    newsfeedPost: '<',
    postId: '<?',
    isPublished: '<',
    onNotifySet: '&',
    onSavePost: '&',
  },
  templateUrl:
    'newsfeed/components/newsfeed-post-form-options/newsfeed-post-form-options.html',
  controller: NewsfeedPostFormOptionsController,
};
