import { MenuMoreController } from './menu-more.controller';

export const MenuMoreComponent = {
  bindings: {
    organisation: '<',
    profile: '<',
  },
  templateUrl: 'menu-more/menu-more.html',
  controller: MenuMoreController,
};
