import { LocalizationService } from '@services/Utils/Localization/localization.service';

export const AlertBannerComponent: ng.IComponentOptions = {
  bindings: {
    text: '<',
  },
  templateUrl: 'components/Banners/alert-banner/alert-banner.html',
  controller: class AlertBannerController {
    isRTLNeeded: boolean;

    constructor(private localizationService: LocalizationService) {
      'ngInject';
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    }
  },
};
