import ReceiptLineCountModule from './receipt-line-count/receipt-line-count.module';
import ReceiptLineProductModule from './receipt-line-product/receipt-line-product.module';
import ReceiptLineSalesModule from './receipt-line-sales/receipt-line-sales.module';
import ReceiptTotalModule from './receipt-total/receipt-total.module';
import { ReceiptComponent } from './receipt.component';

export default angular
  .module('sfMobile.components.receipt', [
    ReceiptLineCountModule,
    ReceiptLineProductModule,
    ReceiptLineSalesModule,
    ReceiptTotalModule,
  ])
  .component('sfReceipt', ReceiptComponent).name;
