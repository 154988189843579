import { MonthYearSelectorController } from './month-year-selector.controller';

export const MonthYearSelectorComponent = {
  bindings: {
    date: '<',
    onSelect: '&',
    labelClass: '@?',
  },
  templateUrl:
    'calendar-events/components/calendar/month-year-selector/month-year-selector.html',
  controller: MonthYearSelectorController,
};
