import { TSFixMe } from 'app';
import { isEmpty } from 'ramda';
import { NotificationReminderPeriod } from '../../services/Utils/Notification/notifications-utils.service';

export const ReminderSelectorComponent = {
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    ngModel: '=',
    onChange: '&',
    onReset: '&',
    isDisabled: '<',
  },
  templateUrl: 'components/reminder-selector/reminder-selector.html',
  controller: class ReminderSelectorComponentController
    implements ng.IComponentController
  {
    ngModel: TSFixMe;
    ngModelCtrl: ng.INgModelController;
    isDisabled: boolean;

    get hasReminder(): boolean {
      return this.ngModel && !isEmpty(this.ngModel);
    }

    periodOptions = [
      {
        value: NotificationReminderPeriod.MINUTES,
        label: this.$translate.instant('RECURRENCE.MINUTES').toLowerCase(),
      },
      {
        value: NotificationReminderPeriod.HOURS,
        label: this.$translate.instant('RECURRENCE.HOURS').toLowerCase(),
      },
      {
        value: NotificationReminderPeriod.DAYS,
        label: this.$translate.instant('RECURRENCE.DAYS'),
      },
      {
        value: NotificationReminderPeriod.WEEKS,
        label: this.$translate.instant('RECURRENCE.WEEKS'),
      },
    ];

    constructor(private $translate: ng.translate.ITranslateService) {
      'ngInject';
    }

    onReminder() {
      this.ngModel = {
        value: 15,
        period: this.periodOptions[0].value,
      };
      this.ngModelCtrl.$setViewValue(this.ngModel);
    }

    offReminder() {
      this.ngModel = null;
      this.ngModelCtrl.$setViewValue(this.ngModel);
    }
  },
};
