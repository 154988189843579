"use strict";

var utilsLibrary = {
  idsAreUnique: utilsLibraryIdsAreUnique
};
module.exports = utilsLibrary;

function utilsLibraryIdsAreUnique(items) {
  var idsHash = {};
  return items.every(function (item) {
    if (idsHash[item._id.toString()]) {
      return false;
    }

    idsHash[item._id.toString()] = true;
    return true;
  });
}