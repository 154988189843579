import { SamlService } from './saml.service';

declare let angular: ng.IAngularStatic;

export default angular
  .module('sfMobile.services.saml', [
    'sfMobile.services.oAuth-browser',
    'sfMobile.services.platform',
  ])
  .service('samlService', SamlService).name;
