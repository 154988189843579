import { pathOr, prop, path } from 'ramda';
import { TSFixMe } from '../../../index';
import type { ModalService } from '../../../services/Utils/Modal';
import { ChangeRequestActions } from '../../change-requests-states.constant';

type MessageInfo = {
  text: string;
  crossed: boolean;
};
export const ChangeRequestItemComponent: ng.IComponentOptions = {
  bindings: {
    changeRequest: '<',
    profile: '<',
    onCancel: '&',
    onApprove: '&',
    onReject: '&',
    onCommentsCountUpdate: '<',
  },
  templateUrl:
    'change-requests/components/change-request-item/change-request-item.html',
  controller: class ChangeRequestItemController
    implements ng.IComponentController
  {
    changeRequest: TSFixMe;
    private originalEvent: TSFixMe;
    private requestedEvent: TSFixMe;
    private originalEventType: TSFixMe;
    private originalEventPlace: TSFixMe;
    private requestedEventType: TSFixMe;
    private requestedEventPlace: TSFixMe;
    private isOwnChangeRequest: boolean;
    private isPending: boolean;
    private eventTypeTitle: string;
    private profile: TSFixMe;
    private dateFrameFormatted: TSFixMe;
    private timezoneFormatted: string;
    private creatorName: string;
    private onCancel: (arg: { $event }) => void;
    private onApprove: (arg: { $event }) => void;
    private onReject: (arg: { $event }) => void;
    private messagesInfo: MessageInfo[] = [];

    // eslint-disable-next-line max-params
    constructor(
      private dateFormatService,
      private CHANGE_REQUESTS_STATES,
      private CHANGE_REQUESTS_ACTIONS: ChangeRequestActions,
      private $translate: ng.translate.ITranslateService,
      private modalService: ModalService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.originalEvent = this.changeRequest.contents.originalEvent.contents;
      this.requestedEvent = this.changeRequest.contents.requestedEvent;
      this.originalEventType = this.changeRequest.originalEventType;
      this.originalEventPlace = this.changeRequest.originalEventPlace;
      this.requestedEventType = this.changeRequest.requestedEventType;
      this.requestedEventPlace = this.changeRequest.requestedEventPlace;
      this.isOwnChangeRequest =
        this.changeRequest.creator._id === this.profile._id;
      this.isPending =
        this.changeRequest.contents.state ===
        this.CHANGE_REQUESTS_STATES.PENDING;
      this.eventTypeTitle = this.isCreation()
        ? this.requestedEventType.contents.title
        : this.originalEventType.contents.title;

      const event = this.isCreation()
        ? this.requestedEvent
        : this.originalEvent;

      if (event && event.timezone) {
        this.dateFrameFormatted = this.dateFormatService.getDateFrameFormatted(
          event.start_dateTime,
          event.end_dateTime,
          event.timezone.name
        );
        this.timezoneFormatted = this.dateFormatService.getTimezoneFormatted(
          event.timezone.name,
          event.start_dateTime
        );
      }
      this.creatorName = `${pathOr(
        '',
        ['creator', 'contents', 'firstName'],
        this.changeRequest
      ).charAt(0)}.${pathOr(
        '',
        ['creator', 'contents', 'lastName'],
        this.changeRequest
      )}`;
      this.messagesInfo = this.getMessagesInfo();
    }

    // eslint-disable-next-line complexity
    getMessagesInfo(): MessageInfo[] {
      const messagesInfo: MessageInfo[] = [];

      messagesInfo.push({
        text: this.dateFrameFormatted,
        crossed:
          ((this.requestedEvent &&
            this.originalEvent.start_dateTime !==
              this.requestedEvent.start_dateTime) ||
            (this.requestedEvent &&
              this.originalEvent.end_dateTime !==
                this.requestedEvent.end_dateTime)) &&
          !this.isCreation(),
      });
      if (this.originalEventPlace || this.requestedEventPlace) {
        messagesInfo.push({
          text: (this.originalEventPlace
            ? path(['contents', 'name'], this.originalEventPlace)
            : path(['contents', 'name'], this.requestedEventPlace)) as string,
          crossed:
            prop('_id')(this.originalEventPlace) !==
              prop('_id')(this.requestedEventPlace) && !this.isCreation(),
        });
      }
      messagesInfo.push({
        text: this.eventTypeTitle,
        crossed:
          prop('_id')(this.originalEventType) !==
            prop('_id')(this.requestedEventType) && !this.isCreation(),
      });
      return messagesInfo;
    }

    getEventAction(): string {
      const action = Object.values(this.CHANGE_REQUESTS_ACTIONS).filter(
        (s) => s.keyword === this.changeRequest.contents.action
      )[0];

      if (!action) {
        return '';
      }
      return this.$translate.instant(action.i18nKey || '');
    }

    openDetailsModal(): void {
      const template = `
        <sf-change-request-item-modal
          change-request="$ctrl.changeRequest"
          profile="$ctrl.profile"
          on-close="$ctrl.onClose()"
          on-cancel="$ctrl.onCancel($event)"
          on-approve="$ctrl.onApprove($event)"
          on-reject="$ctrl.onReject($event)"
          on-comments-count-update="$ctrl.onCommentsCountUpdate"
          is-edit="$ctrl.isEdit"
          is-creation="$ctrl.isCreation"
          is-deletion="$ctrl.isDeletion"
          is-own-change-request="$ctrl.isOwnChangeRequest"
          is-pending="$ctrl.isPending"
          >
        </sf-change-request-item-modal>
      `;

      this.modalService.open(template, {
        changeRequest: this.changeRequest,
        profile: this.profile,
        onCancel: (ev) =>
          this.onCancel({ $event: { changeRequestId: ev.changeRequestId } }),
        onApprove: (ev) =>
          this.onApprove({ $event: { changeRequestId: ev.changeRequestId } }),
        onReject: (ev) =>
          this.onReject({ $event: { changeRequestId: ev.changeRequestId } }),
        isEdit: () => this.isEdit(),
        isCreation: () => this.isCreation(),
        isDeletion: () => this.isDeletion(),
        isOwnChangeRequest: this.isOwnChangeRequest,
        isPending: this.isPending,
        onCommentsCountUpdate: (count) => {
          this.changeRequest.commentsCount = count;
        },
      });
    }

    isEdit(): boolean {
      return (
        this.changeRequest.contents.action ===
        this.CHANGE_REQUESTS_ACTIONS.EDIT.keyword
      );
    }

    isCreation(): boolean {
      return (
        this.changeRequest.contents.action ===
        this.CHANGE_REQUESTS_ACTIONS.CREATE.keyword
      );
    }

    isDeletion(): boolean {
      return (
        this.changeRequest.contents.action ===
        this.CHANGE_REQUESTS_ACTIONS.REMOVE.keyword
      );
    }
  },
};
