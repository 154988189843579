import moment from 'moment';
import { NOTIFICATION_METAEVENTS } from '../../../notifications/services/notifications/notifications.constant';

const REPORTS_REQ_PARAMS = {
  localStatus: ['draft', 'ready'],
};
const FORMS_REQ_PARAMS = {
  deleted: false,
};
const NB_HORIZONTAL_DATA = 6;
const CAMPAIGNS_LIMIT = 5;
const NB_VERTICAL_DATA = 3;
const TASK_LIMIT = 3;
const CAMPAIGNS_LIMIT_PARAMS = {
  limit: CAMPAIGNS_LIMIT,
};
const PLACES_REQ_PARAMS = {
  limit: NB_VERTICAL_DATA,
  sort: 'place_distance',
};

export const ActivityComponent = {
  bindings: {
    organisation: '<',
    profile: '<',
  },
  templateUrl: 'activity/views/activity/activity.html',
  // eslint-disable-next-line max-params
  controller: function ActivityController(
    localizationService,
    $q,
    pubSubService,
    $state,
    $timeout,
    reportsService,
    missionsService,
    formsService,
    placesSourceAdapterService,
    tasksService,
    preferencesService,
    taskService,
    synchronizeService,
    helpersService,
    dateService,
    dateFormatService,
    segmentService,
    userExperienceService,
    TASK_STATUSES,
    newsfeedService,
    geolocationService,
    modalService,
    notificationsService,
    platformService,
    campaignsService,
    profileService,
    organisationsService,
    debugService,
    multiOrganisationService,
    campaignsUtilsService
  ) {
    'ngInject';

    const organisationSwitchListener = pubSubService.subscribe(
      pubSubService.GLOBAL_EVENTS.ORGANISATION_SWITCH,
      ({ profile }) => {
        this.profile = profile;
        return organisationsService
          .getProfileOrganisation(profile)
          .then((organisation) => {
            this.organisation = organisation;
          });
      }
    );

    const dataListener = pubSubService.subscribe(
      pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
      () => {
        this.isSyncFailed = false;
        this.lastUpdateDate = synchronizeService.getLastUpdateDate();
        return this.refreshOrgAndProfile().then(() => this.callDatas());
      }
    );

    const formUpdateListener = pubSubService.subscribe(
      'FORM_REPORT_UPDATED',
      () => this.callDatas()
    );
    const dataFailedListener = pubSubService.subscribe(
      pubSubService.GLOBAL_EVENTS.DATA_SYNCED_FAILED,
      () => this.callDatas()
    );
    const placesSynchronizationListener = pubSubService.subscribe(
      'PLACES_SYNCHRONIZATION_UPDATED',
      () => this.callDatas()
    );
    const onlineStoresPrefChangeListener = pubSubService.subscribe(
      'ONLINE_STORES_PREFERENCE_CHANGED',
      () => this.callDatas()
    );
    const stateListener = pubSubService.subscribe(
      pubSubService.GLOBAL_EVENTS.STATE_CHANGE_START,
      (params) => {
        if (params.name === 'index.activity.details') {
          this.onViewDisplayed();
        }
      }
    );
    const removeSyncCampaignsListener = pubSubService.subscribe(
      'CAMPAIGNS_SYNC_SUCCESS',
      () => this.getCampaignsData()
    );

    this.moment = moment;

    const orderByDate = (entityA, entityB) =>
      dateService.compareDates(entityA.created_date, entityB.created_date);

    this.isEmpty = true;

    this.hasSliderWidgetFeatureFlag =
      newsfeedService.hasSliderWidgetFeatureFlag();
    this.hasTasksFeatureFlag = () => tasksService.hasFeatureFlag();
    this.hasNewsfeedFeatureFlag = () => newsfeedService.hasFeatureFlag();
    this.hasNotificationsViewFeatureFlag = () =>
      notificationsService.hasNotificationsViewFeatureFlag();

    this.isBrowser = platformService.isBrowser();

    this.$onInit = () => {
      this.isRTLNeeded = localizationService.shouldActivateRTL();

      this.synchronizeService = synchronizeService;
      this.lastUpdateDate = synchronizeService.getLastUpdateDate();
      this.isSyncFailed = false;
      this.activeEventId = null;
      this.isFieldExperience = userExperienceService.isField();
      this.isStoreExperience = userExperienceService.isStore();

      synchronizeService.synchronizeIfNecessary().catch(() => {
        this.isSyncFailed = true;
      });
      preferencesService.getTimezone().then((timezone) => {
        this.timezone = timezone;
      });

      if (newsfeedService.hasFeatureFlag()) {
        this.lastSeenPostInfoUpdateListener = pubSubService.subscribe(
          'LAST_SEEN_POST_INFO_UPDATED',
          ({ postsCount }) => {
            this.hasUnseenPosts = postsCount > 0;
            this.unseenPostsCount =
              postsCount > 100 ? '99+' : postsCount.toString();
          }
        );
      }

      this.lastSeenNotificationsListener = pubSubService.subscribe(
        NOTIFICATION_METAEVENTS.LAST_SEEN_NOTIFICATIONS_UPDATED,
        (notificationsCount) => {
          this.hasUnseenNotifications = notificationsCount > 0;
        }
      );

      const loading_start = performance.now();
      const start = Date.now();
      debugService.log(`Beginning of activity component loading: ${start}`, {
        start,
      });
      return this.callDatas()
        .then(() => {
          const loading_ended = performance.now();
          debugService.log(
            `Activity component loading successfully ended in ${
              (loading_start - loading_ended) / 1000
            }s`,
            {
              start,
              end: Date.now(),
            }
          );
        })
        .catch((error) => {
          const loading_ended = performance.now();
          debugService.log(
            `Activity component loading errored in ${
              (loading_start - loading_ended) / 1000
            }s`,
            {
              start,
              end: Date.now(),
              error,
            }
          );
        });
    };

    this.$onDestroy = () => {
      organisationSwitchListener();
      dataListener();
      dataFailedListener();
      placesSynchronizationListener();
      formUpdateListener();
      stateListener();
      if (this.lastSeenPostInfoUpdateListener) {
        this.lastSeenPostInfoUpdateListener();
      }
      if (this.lastSeenNotificationsListener) {
        this.lastSeenNotificationsListener();
      }
      onlineStoresPrefChangeListener();
      removeSyncCampaignsListener();
    };

    this.callDatas = () => {
      return this.getCampaignsData().then(() => this.getGeneralData());
    };

    this.getCampaignsCommon = (profile, withObjective, sorts) => {
      const params = {
        'resolved.collector_ids': [profile._id],
        contents: (val) =>
          withObjective ? 'objective' in val : !('objective' in val),
      };

      return campaignsService.crud.queryLocal(params, sorts);
    };

    this.getCampaignsData = () => {
      if (this.organisation.useNewCampaigns) {
        return profileService.getLocalProfile().then((profile) => {
          return $q
            .all([
              this.getCampaignsCommon(profile, true, [
                // {
                //   key: 'deactivation_date',
                //   desc: false,
                //   nullsLast: true,
                // },
                {
                  key: 'name',
                  desc: false,
                },
              ]),
              this.getCampaignsCommon(profile, false, [
                {
                  key: 'name',
                  desc: false,
                },
              ]),
            ])
            .then(([campaignWithObjective, campaignWithout]) => {
              const campaigns = [
                ...campaignWithObjective
                  .filter((campaign) =>
                    campaign.statistics[0]
                      ? campaign.statistics[0].objectivesCompletionStatus ===
                        'TODO'
                      : null
                  )
                  .filter((campaign) =>
                    campaignsUtilsService.isFrequencyActivePeriod(campaign)
                  )
                  .filter(
                    (campaign) => campaign.contents.state !== 'deactivated'
                  )
                  .sort((a, b) =>
                    helpersService.sortNatural(a.contents.name, b.contents.name)
                  ),
                ...campaignWithout
                  .filter((campaign) =>
                    campaign.statistics[0]
                      ? campaign.statistics[0].objectivesCompletionStatus ===
                        'TODO'
                      : null
                  )
                  .filter((campaign) =>
                    campaignsUtilsService.isFrequencyActivePeriod(campaign)
                  )
                  .filter(
                    (campaign) => campaign.contents.state !== 'deactivated'
                  )
                  .sort((a, b) =>
                    helpersService.sortNatural(a.contents.name, b.contents.name)
                  ),
              ];

              this.isEmpty = this.empty && this.isSectionEmpty(campaigns);
              this.campaigns = campaigns.slice(0, CAMPAIGNS_LIMIT_PARAMS.limit);
              return this.campaigns;
            });
        });
      }

      return $q
        .all([
          reportsService.crud.queryLocal(REPORTS_REQ_PARAMS),
          missionsService.listLocal(),
          formsService.queryLocal(FORMS_REQ_PARAMS),
        ])
        .then(([reports, missions, forms]) => {
          this.isEmpty =
            this.isEmpty &&
            reports.length === 0 &&
            missions.length === 0 &&
            forms.length === 0;

          const reportsReady = reports.filter(
            (report) => report.localStatus === 'ready'
          );
          const reportsDraft = reports.filter(
            (report) => report.localStatus === 'draft'
          );
          const formsSorted = forms.sort(orderByDate);

          const models = helpersService.getFormsAvailable(
            formsSorted,
            this.profile._id
          );
          const missionsSorted = helpersService
            .getMissionsNotStarted(missions, reportsReady)
            .sort(orderByDate);
          const missionsForms = helpersService.getAvailableFormsOfMissions(
            formsSorted,
            missionsSorted
          );

          this.hasDrafts = !!reportsDraft.length;
          this.missionsHash =
            helpersService.getMissionsHashByFormId(missionsSorted);
          this.missionsForms = missionsForms.slice(0, NB_VERTICAL_DATA);
          this.models = models.slice(0, NB_HORIZONTAL_DATA);

          return this.models;
        });
    };

    this.getGeneralData = () => {
      if (newsfeedService.hasFeatureFlag()) {
        newsfeedService.getLastSeenPostInfo().catch(() => null);
      }
      notificationsService.getLastSeenNotificationInfo();

      this.getTasks();

      return geolocationService.getCurrentPosition().then((position) =>
        placesSourceAdapterService
          .queryFromDataSource({
            ...PLACES_REQ_PARAMS,
            ...(position ? { near: `${position.lng},${position.lat}` } : {}),
          })

          .then((places) => {
            this.isEmpty = this.isEmpty && places.entries.length === 0;
            this.places = places.entries;

            return multiOrganisationService
              .getOrganisations()
              .then((multiOrganisations) => {
                const organisations = multiOrganisations.entries.filter(
                  (org) => {
                    return ['active', 'demo', 'in_trial'].includes(
                      org.subscription.status
                    );
                  }
                );
                this.hasMultipleOrganisations = organisations.length > 1;
              })
              .catch(() => {});
          })
      );
    };

    this.getTasks = () => {
      if (!this.hasTasksFeatureFlag) {
        return false;
      }
      this.networkError = false;
      this.tasksLoading = true;

      const taskAssigneeFilters = [
        {
          name: 'assignee_id',
          value: this.profile.id,
        },
      ];

      const requestParams = {
        requestFilters: taskAssigneeFilters,
        status: TASK_STATUSES.TODO.keyword,
        limit: TASK_LIMIT,
      };

      return tasksService
        .getTasks(requestParams)
        .then((data) => {
          this.tasksDatas = data;
          this.tasks = data.entries;
          return this.tasks;
        })
        .catch((err) => {
          this.networkError = true;
          this.tasks = [];
          throw err;
        })
        .finally(() => {
          this.tasksLoading = false;
        });
    };

    this.onTasksReload = () => this.getTasks();

    this.onViewDisplayed = () => {
      this.getTasks();
    };

    this.updateTask = (changedTask) => {
      this.tasks = this.tasks.map((task) =>
        task._id === changedTask._id ? changedTask : task
      );

      return this.tasks;
    };

    this.onTaskContentClick = (event, taskId) => {
      // the list is composed from item checkboxes
      // we need to prevent default behaviour in order not to check/uncheck the checkbox
      event.preventDefault();

      return taskService
        .openTaskDetailsModal(taskId, this.profile, false)
        .then((result) => {
          if (
            taskService.isDeleted(result.status) ||
            !taskService.isToDo(result.task.contents.status)
          ) {
            this.tasks = this.tasks.filter((task) => task._id !== taskId);
            this.getTasks();
          } else {
            this.updateTask(result.task);
          }

          return status;
        });
    };

    this.openActivityFeed = () => {
      this.hasUnseenNotifications = false;
      const template = `
        <ion-modal-view>
          <sf-activity-feed
            on-close="$ctrl.onClose()">
          </sf-activity-feed>
        </ion-modal-view>
      `;

      return modalService.open(template);
    };
    // Items click methods
    this.onPlaceItemClick = (place) => {
      $state.go('index.places.details.main', { placeId: place._id });
      segmentService.track('ACTIVITY', {
        action: 'tap',
        label: 'Place item',
      });
    };
    this.onMissionItemClick = (form, missionType) => {
      $state.go('index.menu-more.missions.campaign.details', {
        campaignId: form._id,
        missionType,
      });
      segmentService.track('ACTIVITY', {
        action: 'tap',
        label: `Mission item ${missionType}`,
      });
    };
    this.onDraftItemClick = () => {
      $state.go('index.menu-more.missions.lists.drafts');
      segmentService.track('ACTIVITY', {
        action: 'tap',
        label: 'Draft item',
      });
    };

    this.goToView = (stateLink, viewType) => {
      $state.go(stateLink, { shouldReset: true });
      segmentService.track('ACTIVITY', {
        action: 'tap',
        label: `More link ${viewType}`,
      });
    };

    this.onTaskStatusChanged = (task) => {
      this.tasks = this.tasks.filter((fl) => fl._id !== task._id);
      $timeout(() => {
        this.getTasks();
      }, 300);
    };
    this.onTaskStatusClick = (taskStatus) => {
      this.toasterMessageKey = taskService.isToDo(taskStatus)
        ? 'TASKS_STATUS_CHANGED_TOASTER_TO_DO'
        : 'TASKS_STATUS_CHANGED_TOASTER_DONE';
      this.showToaster();
    };

    this.onReload = () => {
      this.isSyncFailed = false;
      return synchronizeService.synchronize().catch(() => {
        this.isSyncFailed = true;
      });
    };

    this.goToMultiOrganisation = () => {
      $state.go('index.menu-more.multi-organisation.list');
    };

    // Helpers
    this.getFormMissions = (formId) => this.missionsHash[formId] || [];
    this.getTaskUser = (task) =>
      this.tasksDatas.users[task.contents.assignee_id];
    this.getTaskPlace = (task) =>
      this.tasksDatas.places[task.contents.place_id];

    this.getRelativeDateFormatted = dateFormatService.getRelativeDateFormatted;

    this.isNeverSynced = () => !this.lastUpdateDate;
    this.isSectionLoading = (datas) => typeof datas === 'undefined';
    this.isSectionEmpty = (datas) =>
      typeof datas !== 'undefined' && datas.length === 0;
    this.isSyncing = () => synchronizeService.isSynchronizing;
    this.hasToDisplaySyncError = () =>
      this.isSyncFailed && this.isNeverSynced();

    this.refreshOrgAndProfile = () => {
      if (!this.profile) {
        return $q.resolve();
      }

      return profileService.getLocalProfile().then((p) =>
        profileService
          .syncProfile(p._id)
          .then((profile) => {
            this.profile = profile;
            return organisationsService.getProfileOrganisation(profile);
          })
          .then((org) => {
            this.organisation = org;
          })
      );
    };
  },
};
