export const ListTasksSkeletonComponent = {
  bindings: {
    nbItems: '<?',
  },
  transclude: true,
  templateUrl:
    'components/Skeletons/list-tasks-skeleton/list-tasks-skeleton.html',
  controller: class ListTasksSkeletonController {
    $onInit() {
      this.fakeCards = Array.apply(null, Array(this.nbItems || 1)).map(
        () => 'skeletoncard'
      );
    }
  },
};
