import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';

export const SettingsAlertComponent = {
  bindings: {
    onClose: '&',
  },
  templateUrl: 'components/Notifications/settings-alert/settings-alert.html',
  controller: class SettingsAlertController {
    NativeSettings = NativeSettings;
    constructor() {}

    open(): void {
      this.NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
      });
    }
  },
};
