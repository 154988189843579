import { allSettled } from '../esNext';

const MISSIONS_REQ_PARAMS = {
  type: 'mission',
  states: 'empty',
};
const FORMS_REQ_PARAMS = {
  state: 'current',
  deleted: false,
};
const LOCAL_SYNC_DATE_KEY = 'synchronise_date';
const LOCAL_STORE_INCOMPLETE_SYNC_KEY = 'is_places_sync_incomplete';
const SYNC_NB_HOURS_PERDIODIC = 24; // Hours

// eslint-disable-next-line max-params
export function SynchronizeService(
  $q,
  pubSubService,
  $translate,
  awakeFactory,
  appMessageService,
  campaignsService,
  errorMessagesService,
  localStorageService,
  dateService,
  profileService,
  organisationsService,
  formsService,
  calendarEventsService,
  missionsService,
  placesService,
  productsService,
  apiUtilsService,
  segmentService,
  usersService,
  eventTypesService,
  merchandisingApiService,
  eventChangeRequestsService,
  newsfeedService,
  placesParamsService,
  calendarEventsParamsService,
  geolocationService,
  placesListsService,
  sfFeatureFlagsService,
  reportsValidationAPIService,
  SF_FEATURE_FLAGS,
  notificationsService,
  upgradeService,
  sentryService,
  preferencesService,
  logService
) {
  'ngInject';
  const { buildFilterParams } = apiUtilsService;
  const methods = {
    _userId: null,
    _orgId: null,
    synchronizePromise: null,
    synchronizeSettingsPromise: null,
    isSynchronizing: false,
    init,
    reset,
    synchronize,
    synchronizeSettings,
    synchronizeIfNecessary,
    getLastUpdateDate,
    _setLastUpdateDate: setLastUpdateDate,
    synchronizeChecklists,
    synchronizePlaces,
  };

  function init(userId, orgId) {
    methods._userId = userId;
    methods._orgId = orgId;
    MISSIONS_REQ_PARAMS.user_id = methods._userId;
    this.synchronizeListener = pubSubService.subscribe(
      pubSubService.GLOBAL_EVENTS.SYNCHRONIZE_APP,
      () => methods.synchronize()
    );
  }
  function reset() {
    methods._userId = null;
    methods._orgId = null;
    methods.synchronizePromise = null;
    methods.synchronizeSettingsPromise = null;
    methods.isSynchronizing = false;
    localStorageService.remove(LOCAL_SYNC_DATE_KEY);
    localStorageService.remove(LOCAL_STORE_INCOMPLETE_SYNC_KEY);
    if (this.synchronizeListener) {
      this.synchronizeListener();
    }
  }
  function getLastUpdateDate() {
    const date = localStorageService.get(LOCAL_SYNC_DATE_KEY);

    return date ? date : null;
  }
  function setLastUpdateDate() {
    const date = new Date();

    localStorageService.set(LOCAL_SYNC_DATE_KEY, date.getTime());

    return date;
  }

  function synchronizeIfNecessary() {
    const lastSyncDate = methods.getLastUpdateDate();
    const hasToSync =
      !lastSyncDate ||
      dateService.isDelayExceeded(SYNC_NB_HOURS_PERDIODIC, lastSyncDate);

    if (hasToSync) {
      segmentService.track('SYNCHRONIZE', {
        action: 'refresh',
        label: 'Automatic sync',
      });
    }

    return hasToSync
      ? methods.synchronizePromise || methods.synchronize()
      : $q.resolve();
  }

  function synchronize() {
    methods.isSynchronizing = true;
    awakeFactory.keepAwake();

    methods.synchronizePromise =
      methods.synchronizePromise ||
      getDatas(methods._userId).finally(() => {
        methods.synchronizePromise = null;
        methods.isSynchronizing = false;
        awakeFactory.allowSleepAgain();
        upgradeService.checkForUpgrade();
      });

    return methods.synchronizePromise;
  }

  async function synchronizeChecklists() {
    const syncChecklists = await campaignsService.crud.synchronizeList();
    logService.info(
      `[BUGS-2404] synchronize.service.js | synchronizeChecklists`,
      {
        checklistsCount: syncChecklists ? syncChecklists.length : 0,
        checklistIds: syncChecklists
          ? syncChecklists.map((checklist) => checklist._id)
          : [],
      },
      true
    );
    return syncChecklists;

    // BUGS-2404: above code needs to be replaced by the original one which is commented below
    // return campaignsService.crud.synchronizeList();
  }

  function synchronizePlaces() {
    logService.info(
      `[BUGS-2566] synchronize.service.js | synchronizePlaces`,
      {},
      true
    );
    let geoCoords = null;

    const applyCoords = () =>
      geoCoords && placesService.setPlacesDistanceFrom(geoCoords);

    return geolocationService
      .getCurrentPosition()
      .then((latLng) => {
        geoCoords = latLng;
      })
      .then(() => placesService.listLocal())
      .then((localPlaces) => {
        logService.info(
          `[BUGS-2566] synchronize.service.js | after placesService.listLocal`,
          {
            placesCount: localPlaces ? localPlaces.length : 0,
            placesIds: localPlaces ? localPlaces.map((place) => place._id) : [],
          },
          true
        );
        const places_ids = localPlaces && localPlaces.map((place) => place._id);

        return placesService
          .synchronizeList({
            ...(geoCoords ? { near: `${geoCoords.lng},${geoCoords.lat}` } : {}),
            ...(places_ids && places_ids.length ? { places_ids } : {}),
          })
          .then(applyCoords)
          .catch(applyCoords);
      });
  }

  function synchronizeSettings() {
    methods.isSynchronizing = true;
    awakeFactory.keepAwake();

    methods.synchronizeSettingsPromise =
      methods.synchronizeSettingsPromise ||
      profileService
        .syncProfile(methods._userId)
        .then(() => organisationsService.getRemote(methods._orgId))
        .then((organisation) =>
          organisationsService.setFeatureFlags(organisation)
        )
        .finally(() => {
          methods.synchronizeSettingsPromise = null;
          methods.isSynchronizing = false;
          awakeFactory.allowSleepAgain();
        });

    return methods.synchronizeSettingsPromise;
  }

  function getDatas(profileId) {
    const startTime = new Date().getTime();

    return profileService
      .syncProfile(profileId)
      .then((profile) => {
        return organisationsService
          .getRemote(profile.contents.organisation_id)
          .then((organisation) => fetchResourcesData(organisation, profile));
      })
      .then((data) => {
        segmentService.track('SYNCHRONIZATION_DONE', {
          elapsedTimeInMs: new Date().getTime() - startTime,
        });

        const message = $translate.instant(
          data.hasFullySucceed
            ? 'DATA_SYNC_SUCCESS'
            : 'DATA_SYNC_PARTIAL_SUCCESS'
        );

        organisationsService.setFeatureFlags(data.organisation);
        appMessageService.display(
          message,
          data.hasFullySucceed ? 'success' : 'pending'
        );
        setLastUpdateDate();
        pubSubService.publish(pubSubService.GLOBAL_EVENTS.DATA_SYNCED, data);
        return data;
      })
      .catch((err) => {
        pubSubService.publish(pubSubService.GLOBAL_EVENTS.DATA_SYNCED_FAILED);
        appMessageService.display(errorMessagesService.getMessage(err), 'fail');
        throw err;
      });
  }

  function fetchResourcesData(organisation, profile) {
    const eventsFilters = buildFilterParams([
      { name: 'event_assignee_id', value: profile._id },
      { name: 'event_end_date_time', value: new Date(), operator: '$gte' },
    ]);

    logService.info(
      `[BUGS-2404] synchronize.service.js | fetchResourcesData`,
      {},
      true
    );

    const promises = [
      synchronizeChecklists(),
      formsService.apiList(FORMS_REQ_PARAMS, '/sync/forms'),
      missionsService.apiList(MISSIONS_REQ_PARAMS),
      synchronizePlaces(),
      productsService.synchronizeProducts(), // Be sure to not fail if products not exists
      calendarEventsService.crud
        .apiList(eventsFilters, undefined, { pov: 'organisation' })
        .catch(() => []),
      organisation,
      usersService.crud.apiList({}, undefined, { pov: 'organisation' }),
      eventTypesService.crud.apiList({}, undefined, { pov: 'organisation' }),
      preferencesService.getTimezone(),
      merchandisingApiService.getValidationsNeedReactionCountRemote(),
      placesParamsService.apiListAsOrg().catch(() => []),
      calendarEventsParamsService.apiListAsOrg().catch(() => []),
      placesListsService.apiList(),
      ...(sfFeatureFlagsService.hasFeature(SF_FEATURE_FLAGS.REPORTS_VALIDATION)
        ? [reportsValidationAPIService.updateToReviseCounts().catch(() => null)]
        : []),
    ];

    if (newsfeedService.hasFeatureFlag()) {
      newsfeedService.getLastSeenPostInfo().catch(() => null);
    }

    eventChangeRequestsService.getStatesCounts();

    notificationsService.getLastSeenNotificationInfo();

    const settle = Promise.allSettled
      ? Promise.allSettled.bind(Promise)
      : allSettled;

    return $q.resolve(
      settle(promises).then((results) => {
        let failingIndex = [];
        const data = results.map((result, index) => {
          if (result.status === 'fulfilled') {
            return result.value;
          } else {
            failingIndex.push(index);
            return null;
          }
        });

        if (failingIndex.length === results.length) {
          sentryService.captureMessage('SYNCHRONIZATION_FULLY_FAILED', {
            level: 'error',
            extra: {
              userId: profile._id,
            },
          });
          throw new Error('Synchronization failed');
        }

        const synchronizedData = convertDataToObject(data);

        if (failingIndex.length > 0) {
          sentryService.captureMessage('SYNCHRONIZATION_PARTIALLY_FAILED', {
            level: 'error',
            extra: {
              userId: profile._id,
              failingResources: failingIndex.map(
                (index) => Object.keys(synchronizedData)[index]
              ),
            },
          });
        }

        if (!synchronizedData.timezone) {
          preferencesService.save(
            'settings.timeZone',
            Intl.DateTimeFormat().resolvedOptions().timeZone
          );
        }

        return {
          ...synchronizedData,
          hasFullySucceed: failingIndex.length === 0,
        };
      })
    );
  }

  function convertDataToObject([
    campaigns,
    forms,
    missions,
    places,
    products,
    events,
    organisation,
    users,
    eventTypes,
    timezone,
  ]) {
    return {
      campaigns,
      forms,
      missions,
      places,
      products,
      events,
      organisation,
      users,
      eventTypes,
      timezone,
    };
  }

  return methods;
}
