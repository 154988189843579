import { NamedResourceChipController } from './named-resource-chip.controller';

export const NamedResourceChipComponent = {
  bindings: {
    document: '<',
    onNameClick: '&?',
    onDelete: '&?',
  },
  templateUrl: 'components/named-resource-chip/named-resource-chip.html',
  controller: NamedResourceChipController,
};
