import * as blobUtil from 'blob-util';

const PHOTO_TYPE = 'image/jpeg';

export const QuestionSignatureComponent = {
  bindings: {
    question: '<',
    questionOptions: '<',
    questionFolderName: '<',
    hasError: '<',
    isEditable: '<',
    report: '<',
    answers: '=',
    isDisabled: '<?',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-signature/question-signature.html',
  // eslint-disable-next-line max-params
  controller: function QuestionSignatureController(
    $q,
    $window,
    $translate,
    actionSheetService,
    modalService,
    filesSystemService,
    imageService,
    objectIdService,
    platformService,
    SF_IMAGE_SIZES,
    imageSourceService
  ) {
    'ngInject';

    const getPictureSizes = (path, bigPath) => ({
      small: path,
      big: path || bigPath,
    });

    this.blobUtil = blobUtil;

    this.$onInit = () => {
      this.picturesHash = {};
      this.folderName = this.questionFolderName.toString();
      this.processing = false;
      this.reportIsSend = !this.report.localStatus;
      this.isBrowser = platformService.isBrowser();

      if (this.reportIsSend) {
        return this.getRemotePicturesHash().then((picturesHash) => {
          this.picturesHash = picturesHash;
        });
      }
      return $q.resolve(this.getLocalPicturesHash()).then((hash) => {
        this.picturesHash = hash;
      });
    };

    this.canTakeNewSignature = () => {
      const isNotMax =
        this.questionOptions.max === 'Infinity'
          ? true
          : this.questionOptions.max !== this.answers.length;

      return this.isEditable && isNotMax;
    };
    this.viewSignature = (answer) => {
      const modalTemplate = `
      <sf-images-viewer
        images="[$ctrl.image]"
        lock-zooming="true"
        on-close="$ctrl.onClose()">
      </sf-images-viewer>
    `;
      const modalBindings = {
        image: imageSourceService.create(
          {
            url: this.getPictureUrl(answer, 'big'),
          },
          false
        ),
      };

      return modalService.open(modalTemplate, modalBindings);
    };
    this.takeSignature = () => {
      let modal = null;
      const modalTemplate = `
      <sf-signature-pad
        on-validate="$ctrl.validate(signature)"
        on-close="$ctrl.onClosePad()">
      </sf-signature-pad>
    `;
      const modalBindings = {
        validate: (signature) =>
          this.validateSignature(signature).finally(() => modal.remove()),
        onClosePad: () => modal.remove(),
      };

      modal = modalService.open(modalTemplate, modalBindings);
      return modal;
    };

    // Take signature
    // ---------------
    this.validateSignature = (signature) => {
      const fileId = objectIdService.create();
      const fileInfo = {
        fileId,
        name: `${fileId}.jpg`,
      };

      this.processing = true;

      return (
        this.isBrowser
          ? $q.resolve(this.getBlobInfo(signature, fileInfo))
          : this.createFile(signature, fileInfo)
      )
        .then((res) => this.addFileToAnswers(res))
        .then((answer) => $q.all([answer._id, this.getPicturePath(answer)]))
        .then(([answerId, picPath]) => {
          this.picturesHash[answerId] = getPictureSizes(picPath);
        })
        .then(() => this.setFormDirty())
        .finally(() => {
          this.processing = false;
        });
    };

    this.createFile = (fileData, fileInfo) =>
      filesSystemService
        .createFile(this.folderName, fileInfo.name, fileData)
        .then(({ uri }) => ({ ...fileInfo, path: uri }));

    this.getBlobInfo = (fileData, fileInfo) => {
      const fileBlob = this.blobUtil.dataURLToBlob(fileData);

      return {
        ...fileInfo,
        blob: fileBlob,
        path: $window.URL.createObjectURL(fileBlob),
      };
    };

    this.addFileToAnswers = ({ fileId, path, name, blob }) => {
      var fields = this.question.fields;
      var values = [
        { value: fileId, field_id: fields[0]._id },
        { value: name, field_id: fields[1]._id },
        { value: PHOTO_TYPE, field_id: fields[2]._id },
      ];

      this.answers = this.sfQuestionForm.addAnswer(values, this.answers, {
        type: 'signature',
        temp: {
          needSync: true,
          filePath: blob ? path : null,
          fileBlob: blob,
          fileType: blob ? 'blob' : 'file',
        },
      });

      const newAnswer = this.answers.filter(
        (answer) => answer.values[0].value === fileId
      )[0];

      return newAnswer;
    };

    // Delete
    // ---------------
    this.deleteSignature = (answer) => {
      var defer = $q.defer();
      const onCancelClick = () => defer.reject();
      const onDestructiveClick = () => {
        this.removeAnswer(answer);
        defer.resolve();
      };
      const actionSheetConfig = {
        title: $translate.instant('TASK_SIGNATURE_DELETE_TITLE'),
        cancelText: $translate.instant('TASK_SIGNATURE_DELETE_CANCEL'),
        destructiveText: $translate.instant('TASK_SIGNATURE_DELETE_CONFIRM'),
        isValidation: true,
      };

      actionSheetService.open(
        null,
        actionSheetConfig,
        onCancelClick,
        onDestructiveClick
      );

      return defer.promise;
    };

    this.removeAnswer = (answer) => {
      this.setFormDirty();
      this.answers = this.sfQuestionForm.removeAnswer(answer._id, this.answers);
      this.picturesHash[answer._id] = null;
    };

    this.getRemotePicturesHash = () =>
      $q.all(this.answers.map(this.getRemotePicture)).then((pictures) =>
        pictures.reduce((hash, picture) => {
          hash[picture._id] = picture;
          return hash;
        }, {})
      );

    this.getRemotePicture = (answer) =>
      imageService
        .getSizesHashFromId(answer.values[0].value, [
          SF_IMAGE_SIZES.SQUARE_SMALL,
          SF_IMAGE_SIZES.RECTANGLE_MEDIUM,
        ])
        .then((urls) => ({
          _id: answer._id,
          small: urls[SF_IMAGE_SIZES.SQUARE_SMALL],
          big: urls[SF_IMAGE_SIZES.RECTANGLE_MEDIUM],
        }));

    this.getLocalPicturesHash = () =>
      this.answers.reduce(
        (hashPromise, answer) =>
          hashPromise.then((hash) =>
            this.getPicturePath(answer).then((picPath) => {
              hash[answer._id] = getPictureSizes(picPath);

              return hash;
            })
          ),
        $q.resolve({})
      );

    this.getLocalPath = (answer) =>
      filesSystemService.computeFileDataPath(
        this.questionFolderName,
        answer.values[0].value
      );

    this.getPicturePath = (answer) => {
      const { filePath } = answer.temp;

      return filePath ? $q.resolve(filePath) : this.getLocalPath(answer);
    };

    this.getPictureUrl = (answer, size = 'small') => {
      const answerPictures = this.picturesHash[answer._id] || {};
      const path = answerPictures[size];
      const { type } = answer.temp || {};

      return type === 'blob' ? path : platformService.convertFileSrc(path);
    };

    this.setFormDirty = () => {
      const signatureForm = this[`signature_${this.question._id}`];

      return signatureForm ? signatureForm.$setDirty(true) : false;
    };
  },
};
