import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { rrulestr } from 'rrule';
import { uniqBy } from 'ramda';
import { ObjectIdService } from '../../Utils/Objectid/objectId.service';
import { CrudFactory } from '../../Utils/CRUD/crud-service.factory';
import {
  DataAccessLayerService,
  QueryParams,
} from '../../Utils/CRUD/crud-service';
import {
  CalendarEvent,
  DateEvents,
  CorporateEvent,
  CalendarEventsByDay,
} from '../../../calendar-events/types';
import type { SfFeatureFlags } from '@simplifield/feature-flags';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import { APIStore } from '../../../places/index';
import { DateService } from '../../Utils/Dates/date.service';
import { SfEventsTypes } from '../../../shared/enums/events-types.enum';

const HALF_MINUTE_IN_SECONDS = 30;
const TRANSITION_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export class CalendarEventsService {
  moment = moment;
  crud: DataAccessLayerService<CalendarEvent>;

  /* eslint-disable max-params */
  constructor(
    private $filter: ng.IFilterService,
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private crudFactory: CrudFactory<CalendarEvent>,
    private dateService: DateService,
    private objectIdService: ObjectIdService,
    private preferencesService,
    private profileService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private EDIT_RECURRENT_EVENT_CHOICES: Record<string, string>
  ) {
    'ngInject';
    this.crud = this.crudFactory('calendar_events', {
      path_name: 'calendar-events',
      default_params: { mode: 'compact' },
      exclude_offline_params: ['filters'],
    });
  }

  hasFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.CALENDAR_EVENTS
    );
  }

  hasManagerCalendarFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.MANAGER_CALENDAR
    );
  }

  list(
    params
  ): Record<'online' | 'offline', () => ng.IPromise<CalendarEvent[]>> {
    return {
      online: () => {
        return this.profileService.getProfile().then(({ _id: profileId }) => {
          return this.crud.apiList({ ...params }, undefined, {
            pov: 'organisation',
          });
        });
      },
      offline: () => {
        return this.crud.listLocal();
      },
    };
  }

  getPeriodCalendarEventsList(
    date: moment.MomentInput,
    period: moment.unitOfTime.StartOf = 'isoWeek',
    place?: APIStore
  ): Record<'online' | 'offline', () => ng.IPromise<CalendarEvent[]>> {
    return {
      online: () => {
        return this.profileService.getProfile().then(({ _id: profileId }) => {
          const utcDate = this.moment.utc(this.moment(date).toArray());
          const startDate = utcDate
            .clone()
            .startOf(period)
            .subtract(1, 'day')
            .toISOString();
          const endOfDate = utcDate
            .clone()
            .endOf(period)
            .add(1, 'day')
            .toISOString();
          const filters = {
            $and: [
              {
                $or: [
                  {
                    $and: [
                      { event_start_date_time: { $lte: startDate } },
                      { event_end_date_time: { $gte: endOfDate } },
                    ],
                  },
                  {
                    event_start_date_time: { $between: [startDate, endOfDate] },
                  },
                  { event_end_date_time: { $between: [startDate, endOfDate] } },
                ],
              },
              ...(place
                ? [
                    {
                      $and: [
                        { event_place: { $eq: place._id } },
                        {
                          $or: [
                            { event_assignee_id: { $size: 0 } },
                            { event_assignee_id: { $eq: profileId } },
                          ],
                        },
                      ],
                    },
                  ]
                : [{ event_assignee_id: { $eq: profileId } }]),
            ],
          };

          return this.crud.apiList({ filters }, undefined, {
            pov: 'organisation',
          });
        });
      },
      offline: () => {
        return this.crud
          .listLocal()
          .then((events) => events.filter(filterEventsByPlace(place)));
      },
    };
  }

  saveCalendarEvent(
    event: CalendarEvent,
    editMode: string = this.EDIT_RECURRENT_EVENT_CHOICES.ONE
  ): string {
    const getPath = (basePath, id) => {
      const defaultPath = `${basePath}/${id}`;
      const pathsMap = {
        [this.EDIT_RECURRENT_EVENT_CHOICES.ONE]: defaultPath,
        [this.EDIT_RECURRENT_EVENT_CHOICES
          .ONE_AND_NEXT]: `${defaultPath}/oneAndNext`,
        [this.EDIT_RECURRENT_EVENT_CHOICES.ALL]: `${defaultPath}/all`,
      };

      return pathsMap[editMode];
    };

    return this.profileService
      .getProfile()
      .then(
        ({ _id: profileId }) =>
          event.contents.assignees_ids &&
          event.contents.assignees_ids.includes(profileId)
      )
      .then((saveLocally) => {
        const methodName = saveLocally ? 'save' : 'saveRemote';

        return this.crud[methodName](event._id, event, {
          pov: 'organisation',
          getPath,
        });
      });
  }

  createDefaultCalendarEvent(
    eventDate: moment.MomentInput,
    place?: APIStore
  ): ng.IPromise<unknown> {
    const date = this.createCalendarEventDefaultStartTime(eventDate);

    return this.$q
      .all([
        this.profileService.getProfile(),
        this.preferencesService.getTimezone(),
      ])
      .then(([profile, timezone]) => ({
        _id: this.objectIdService.create(),
        contents: {
          allDayLong: false,
          timezoneSource: 'manually_set',
          timezone: { name: timezone },
          start_dateTime: new Date(
            ...(date.toArray() as [number, number, number])
          ),
          end_dateTime: new Date(
            ...(date.add(1, 'h').toArray() as [number, number, number])
          ),
          description: '',
          assignees_ids: [profile._id],
          organisation_id: profile.contents.organisation_id,
          ...(place ? { place_id: place._id } : {}),
        },
        ...(place ? { place } : {}),
        assignees: [profile],
        type: {},
      }));
  }

  createCalendarEventDefaultStartTime(date: moment.MomentInput): moment.Moment {
    const now = this.moment();
    const eventDate = this.moment(date)
      .hours(now.hours())
      .minutes(now.minutes());
    const minutes = +eventDate.format('m');

    return minutes <= HALF_MINUTE_IN_SECONDS
      ? eventDate.startOf('minutes').set('minute', HALF_MINUTE_IN_SECONDS)
      : eventDate.startOf('hour').add(1, 'h');
  }

  sortCalendarEvents(events: CalendarEvent[]): CalendarEvent[] {
    return this.$filter('orderBy')(events, [
      ({ contents: { start_dateTime } }) => this.moment(start_dateTime).unix(),
      '-created',
    ]);
  }

  canEditOrDeleteEvent(event: CalendarEvent): boolean {
    return this.profileService.getProfile().then(({ _id: profileId }) => {
      return (
        event.creator._id === profileId ||
        (event.contents.assignees_ids &&
          event.contents.assignees_ids.length === 1 &&
          event.contents.assignees_ids[0] === profileId)
      );
    });
  }

  delete(...arg: [string, QueryParams, { pov: string }]) {
    // eslint-disable-next-line prefer-object-spread
    return this.crud.delete(...arg);
  }

  getRecurrenceText(rule: string): string {
    const t = (word) => this.$translate.instant(word);
    const R_RULE_WORDS = {
      every: t('RECURRENCE.EVERY'),
      day: t('RECURRENCE.DAY'),
      days: t('RECURRENCE.DAYS'),
      weekday: t('RECURRENCE.WEEKDAY'),
      weekdays: t('RECURRENCE.WEEKDAYS'),
      week: t('RECURRENCE.WEEK'),
      weeks: t('RECURRENCE.WEEKS'),
      month: t('RECURRENCE.MONTH'),
      months: t('RECURRENCE.MONTHS'),
      year: t('RECURRENCE.YEAR'),
      years: t('RECURRENCE.YEARS'),
      on: t('RECURRENCE.ON'),
      at: t('RECURRENCE.AT'),
      the: t('RECURRENCE.THE'),
      until: t('RECURRENCE.UNTIL'),
    };
    const DAY_NAMES = [
      t('RECURRENCE.SUNDAY'),
      t('RECURRENCE.MONDAY'),
      t('RECURRENCE.TUESDAY'),
      t('RECURRENCE.WEDNESDAY'),
      t('RECURRENCE.THURSDAY'),
      t('RECURRENCE.FRIDAY'),
      t('RECURRENCE.SATURDAY'),
    ];
    const MONTH_NAMES = [
      t('RECURRENCE.JANUARY'),
      t('RECURRENCE.FEBRUARY'),
      t('RECURRENCE.MARCH'),
      t('RECURRENCE.APRIL'),
      t('RECURRENCE.MAY'),
      t('RECURRENCE.JUNE'),
      t('RECURRENCE.JULY'),
      t('RECURRENCE.AUGUST'),
      t('RECURRENCE.SEPTEMBER'),
      t('RECURRENCE.OCTOBER'),
      t('RECURRENCE.NOVEMBER'),
      t('RECURRENCE.DECEMBER'),
    ];
    const getText = (word) => {
      return R_RULE_WORDS[word] || word;
    };

    return rrulestr(rule).toText(getText, {
      dayNames: DAY_NAMES,
      monthNames: MONTH_NAMES,
      tokens: {},
    });
  }

  closestToToday(events: CalendarEventsByDay[] | DateEvents[]): number {
    let closestIndex = 0;

    events.forEach((ev, index) => {
      if (this.dateService.isPast(ev.date)) {
        closestIndex = index;
      }
    });

    return closestIndex;
  }

  groupEventsByDay(events: CalendarEvent[], dateArray: Date[]): DateEvents[] {
    return dateArray.reduce((acc, date) => {
      const dateEvents = events.filter((event) =>
        this.dateService.isInRange(
          date,
          [event.contents.start_dateTime, event.contents.end_dateTime],
          'day'
        )
      );

      if (dateEvents.length !== 0) {
        acc.push({
          date,
          events: uniqBy((event) => event._id, dateEvents),
        });
      }

      return acc;
    }, [] as DateEvents[]);
  }

  groupAllEventsByDay(
    individualEvents: CalendarEvent[],
    corporateEvents: CorporateEvent[],
    dateArray: Date[]
  ): CalendarEventsByDay[] {
    return dateArray.reduce((acc, date) => {
      const individualDateEvents = individualEvents.filter((event) => {
        return this.dateService.isInRange(
          date,
          [
            momentTimezone(
              momentTimezone
                .tz(event.contents.start_dateTime, event.contents.timezone.name)
                .format(TRANSITION_DATE_FORMAT)
            ),
            momentTimezone(
              momentTimezone
                .tz(event.contents.end_dateTime, event.contents.timezone.name)
                .format(TRANSITION_DATE_FORMAT)
            ),
          ],
          'day'
        );
      });
      const corporateDateEvents = corporateEvents.filter((event) =>
        this.dateService.isInRange(
          date,
          [event.contents.start_date, event.contents.end_date],
          'day'
        )
      );

      if (
        individualDateEvents.length !== 0 ||
        corporateDateEvents.length !== 0
      ) {
        acc.push({
          date,
          individual: uniqBy((event) => event._id, individualDateEvents),
          corporate: uniqBy((event) => event._id, corporateDateEvents),
        });
      }

      return acc;
    }, [] as CalendarEventsByDay[]);
  }
}

// Helpers
function filterEventsByPlace(place) {
  if (!place) {
    return () => true;
  }
  return (event) => {
    return event.place && event.place._id === place._id;
  };
}
