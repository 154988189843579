export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 4.2,
    method: updateMethod,
  });
}

export function updateMethod($q, $log, sqlStorageService, localStorageService) {
  'ngInject';
  const LOCAL_TOKEN_KEY = 'userToken';
  const USER_ID_KEY = 'userID';

  var token = localStorageService.get(LOCAL_TOKEN_KEY);
  var id = localStorageService.get(USER_ID_KEY);

  return (
    sqlStorageService
      .execute('SELECT * FROM profile WHERE id=?', [id])
      // Stop request if reports table contains datas table exist
      .then((data) =>
        data.rows.length
          ? angular.fromJson(data.rows.item(0).payload)
          : $q.reject()
      )
      .then(updateToken)
      .then(() => $log.debug('[MIGRATION] Success: 4.2'))
      .catch(() => null)
  );

  function updateToken(payload) {
    const updateRequest = 'UPDATE profile SET payload=? WHERE id=?';

    payload.contents.token = token;

    return sqlStorageService.execute(updateRequest, [
      JSON.stringify(payload),
      id,
    ]);
  }
}
