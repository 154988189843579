export const SF_TASK_DETAILS_TABS = {
  OVERVIEW: {
    keyword: 'overview',
    i18nKey: 'TASK_DETAILS_OVERVIEW_TAB',
  },
  COMMENTS: {
    keyword: 'comments',
    i18nKey: 'TASK_DETAILS_COMMENTS_TAB',
  },
} as const;

export default angular
  .module('sfMobile.constants.task-details-tabs', [])
  .constant('TASK_DETAILS_TABS', SF_TASK_DETAILS_TABS).name;
