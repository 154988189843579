import { equals } from 'ramda';

export const PlaceItemComponent = {
  bindings: {
    place: '<',
    tagInfo: '<',
    hidePicture: '<',
  },
  transclude: {
    objectiveCompletion: '?objectiveCompletion',
  },
  templateUrl: 'places/components/place-item/place-item.html',
  controller: function PlaceItemController(
    pubSubService,
    placesService,
    addressFormat,
    SF_IMAGE_SIZES
  ) {
    'ngInject';

    this.$onInit = () => {
      if (!this.place) {
        return false;
      }
      this.distance = 0;
      this.isLocallySynchronized = false;
      this.initPlaceInfos();
      this.syncChangedListener = pubSubService.subscribe(
        'PLACE_SYNC_UPDATED',
        (placeId) => (placeId === this.place._id ? this.initPlaceInfos() : null)
      );

      return true;
    };
    this.$onChanges = (changes) => {
      if (!changes.place || !this.place) {
        return false;
      }

      this.initPlaceInfos();
      return true;
    };
    this.$onDestroy = () => {
      if (this.syncChangedListener) {
        this.syncChangedListener();
      }
    };
    this.initPlaceInfos = () => {
      this.placeAddress = addressFormat(this.place.contents);

      if (!this.hidePicture) {
        placesService
          .getPlacePicture(this.place, SF_IMAGE_SIZES.SQUARE_SMALL)
          .then((url) => {
            this.placePicture = url;
          });
      }

      placesService
        .getLocal(this.place._id)
        .then((place) => {
          this.isLocallySynchronized = !!place;
        })
        .catch(() => {
          this.isLocallySynchronized = false;
        });

      if (this.place.distance !== {}.undef && this.place.distance !== null) {
        return placesService
          .formatDistance(this.place.distance)
          .then((formatedDistance) => {
            this.distance = formatedDistance;
          });
      }
      return true;
    };

    this.hasInfoTag = () => this.tagInfo !== {}.undef;
    this.hasDistanceTag = (place = {}) =>
      !this.hasInfoTag() &&
      place.distance !== {}.undef &&
      place.distance !== null &&
      place.distance !== 0 &&
      !equals(place.contents.latLng, [0, 0]);
  },
};
