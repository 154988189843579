import { ObjectId, User } from '../../../..';
import {
  Answer,
  PreviousAnswers,
} from '../../../../services/API/campaigns/campaigns';
import { Form, FormQuestion } from '../../../../services/API/forms/forms';
import { Report } from '../../../../services/API/reports/reports';
import clone from '../../../../services/Utils/clone';
import { ReportQuestionsService } from '../../../../services/Utils/ReportQuestions/report-questions.service';
import { FORM_QUESTION_ACTIONS_TYPE } from '../form-question-actions/form-question-actions.constant';
import { APIStore } from '../../../../places';
import { CalendarEvent } from 'app/calendar-events/types';

export const FormQuestionComponent: ng.IComponentOptions = {
  bindings: {
    question: '=',
    report: '=',
    form: '<',
    isDone: '<',
    nodeId: '@',
    place: '<',
    formErrors: '=',
    profile: '<',
    unconfirmedPrefilledQuestions: '=',
    availablePreviousAnswers: '<',
    useNewCampaigns: '<',
    isPreview: '<?',
    isDisplayed: '<?',
    isDebug: '<?',
    hasNewBadge: '<?',
    events: '<?',
    onCalendarEventCreated: '&?',
    onCalendarEventDeleted: '&?',
  },
  templateUrl: 'missions/form/components/form-question/form-question.html',
  controller: class FormQuestionController implements ng.IController {
    // bindings
    question: FormQuestion;
    report: Report;
    form: Form;
    place: APIStore;
    profile: User;
    unconfirmedPrefilledQuestions: { ids: ObjectId[] };
    hasNewBadge = false;
    events: { questionId: string; event: CalendarEvent }[] = [];

    // members
    answers: Answer[];
    previousAnswers: PreviousAnswers[];
    availablePreviousAnswers: PreviousAnswers[];
    formErrors: { forceErrorsDisplay: boolean };
    actionClicked: (actions: FORM_QUESTION_ACTIONS_TYPE) => void;
    onCalendarEventCreated: (arg: {
      questionId: string;
      event: CalendarEvent;
    }) => void;
    onCalendarEventDeleted: (arg: { eventId: string }) => void;

    timezone: any;
    eventsByQuestion: CalendarEvent[] = [];

    constructor(
      private reportQuestionsService: ReturnType<typeof ReportQuestionsService>,
      private formService,
      private preferencesService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.answers = this.report.contents.answers.filter(
        (answer) => answer.question_id === this.question._id
      );
      this.previousAnswers = clone(
        this.availablePreviousAnswers.filter(
          (answer) => answer.question_id === this.question._id
        )
      );

      this.preferencesService.getTimezone().then((timezone) => {
        this.timezone = timezone;
      });

      this.filterEvents();
    }

    $onChanges(changes): void {
      if (changes.events) {
        this.filterEvents();
      }
    }

    filterEvents(): void {
      this.eventsByQuestion = this.events
        .filter(({ questionId }) => questionId === this.question._id)
        .map(({ event }) => event);
    }

    hasError(): boolean {
      const field = this.getField();
      const fieldIsDirty = this.formErrors.forceErrorsDisplay || field.$dirty;
      const fieldIsInvalid = fieldIsDirty && field.$invalid;

      return fieldIsInvalid;
    }

    getFieldError(): Record<string, unknown> {
      return this.getField().$error;
    }

    getField(): ng.IFormController {
      return this[`question_${this.question._id}`];
    }

    isProductScanQuestion(): boolean {
      return this.reportQuestionsService.isProductScan(this.question);
    }

    isProductQuestion(): boolean {
      return (
        this.reportQuestionsService.isProductCount(this.question) ||
        this.reportQuestionsService.isProductSales(this.question)
      );
    }

    hasUnconfirmedPreviousAnswers(): boolean {
      return this.unconfirmedPrefilledQuestions.ids.some(
        (id) => id === this.question._id
      );
    }

    canAnswerBeResetToPrevious(): boolean {
      return Boolean(
        !this.hasUnconfirmedPreviousAnswers() && this.previousAnswers.length
      );
    }

    getQuestionContional(): string | null {
      const condition = this.formService.getQuestionCondition(this.question);

      return condition ? condition.name : null;
    }

    onActionClicked(action: FORM_QUESTION_ACTIONS_TYPE): void {
      this.actionClicked(action);
    }

    calendarEventCreated(event: CalendarEvent): void {
      this.onCalendarEventCreated({ questionId: this.question._id, event });
    }

    onCalendarEventDelete(eventId: string): void {
      this.onCalendarEventDeleted({ eventId });
    }
  },
};
