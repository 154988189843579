import { Task, TaskStatus } from '..';
import { ObjectId, User } from '../..';
import {
  PubSubService,
  UnregisterFn,
} from '../../services/Utils/PubSub/pubsub.service';
import { TaskService } from '../services/task.service';
import { SF_TASK_STATUSES } from '../constants/task-statuses.constant';
import { GroupTasksService } from '../../group-tasks/services/group-tasks.service';

type TaskFilterTemplate = {
  title: string;
  requestFilters: RequestFilter[];
};

type RequestFilter = {
  name: string;
  value: ObjectId | string[];
  operator?: string;
};

type ButtonSelectorOption = {
  id: number;
  label: string;
};

export class TasksController implements ng.IComponentController {
  // bindings
  profile: User;
  reloadTasksTab: () => void;
  showToaster: () => void;

  // constants
  ALL_TASKS_FILTER_INDEX = 0;

  // attributes
  isInNavBar: boolean;
  unsubscribeEvent: UnregisterFn;
  taskStatusTypes: TaskFilterTemplate[];
  buttonSelectorOptions: ButtonSelectorOption[];
  activeFilterTitle: string;
  taskStatus: TaskStatus;
  taskStatusIndex: number;
  taskStatusFilters: RequestFilter[] | null;
  toasterMessageKey: string;

  constructor(
    private $translate: ng.translate.ITranslateService,
    private $timeout: ng.ITimeoutService,
    private pubSubService: PubSubService,
    private taskService: TaskService,
    private groupTasksService: GroupTasksService,
    private TASK_STATUSES: typeof SF_TASK_STATUSES
  ) {
    'ngInject';

    this.getStatusTypeByIndex = this.getStatusTypeByIndex.bind(this);
    this.setActiveFilter = this.setActiveFilter.bind(this);
    this.goToAllTasksFilter = this.goToAllTasksFilter.bind(this);
    this.onTypeSelect = this.onTypeSelect.bind(this);
  }

  $onInit(): void {
    this.setButtonSelectorOptions();
    this.setActiveFilter(this.ALL_TASKS_FILTER_INDEX);

    this.unsubscribeEvent = this.pubSubService.subscribe(
      'TASK_STATUS_CHANGED',
      ({ newStatus }: { newStatus: TaskStatus }) =>
        this.showToasterOnStatusChange(newStatus)
    );
  }

  $onDestroy(): void {
    this.unsubscribeEvent();
  }

  setButtonSelectorOptions(): void {
    const statuses = [
      this.TASK_STATUSES.TODO,
      this.TASK_STATUSES.DONE,
      this.TASK_STATUSES.DONE_ON_TIME,
      this.TASK_STATUSES.LATE_COMPLETION,
    ];

    this.taskStatusTypes = statuses.map((status) => {
      if (status.keyword === this.TASK_STATUSES.DONE.keyword) {
        return {
          title: this.$translate.instant(status.i18nKey),
          requestFilters: [
            {
              name: 'status',
              operator: '$in',
              value: [
                this.TASK_STATUSES.DONE_ON_TIME.keyword,
                this.TASK_STATUSES.LATE_COMPLETION.keyword,
              ],
            },
          ],
        };
      }

      return {
        title: this.$translate.instant(status.i18nKey),
        requestFilters: [{ name: 'status', value: status.keyword }],
      };
    });

    this.buttonSelectorOptions = this.taskStatusTypes.map(
      (statusType, index) => ({
        id: index + 1,
        label: statusType.title,
      })
    );
  }

  getStatusTypeByIndex(statusTypeIndex: number): TaskFilterTemplate {
    return this.taskStatusTypes[statusTypeIndex];
  }

  setActiveFilter(selectedViewIndex: number): void {
    const statusType = this.getStatusTypeByIndex(selectedViewIndex);

    this.activeFilterTitle = statusType.title;
    this.taskStatus = statusType.requestFilters[0].value as TaskStatus;
    this.taskStatusIndex = selectedViewIndex;
    this.taskStatusFilters = statusType.requestFilters;
  }

  goToAllTasksFilter(): ng.IPromise<void> {
    this.setActiveFilter(this.ALL_TASKS_FILTER_INDEX);
    return this.$timeout(() => this.reloadTasksTab());
  }

  onTypeSelect(option: ButtonSelectorOption): boolean {
    const { taskStatusIndex } = this;
    const selectedViewIndex = this.buttonSelectorOptions
      .map((buttonSelectorOption) => buttonSelectorOption.id)
      .indexOf(option.id);

    if (taskStatusIndex === selectedViewIndex) {
      return false;
    }

    this.setActiveFilter(selectedViewIndex);
    this.$timeout(() => {
      this.reloadTasksTab();
    });
    return true;
  }

  openTaskManageModal(task: Task): void {
    this.taskService.openTaskManageModal(task, this.profile).then(() => {
      this.goToAllTasksFilter();
    });
  }

  openTaskGroupTasksChoiceModal(): void {
    this.taskService.openTaskGroupTasksChoiceModal(this.profile).then(() => {
      this.reloadTasksTab();
    });
  }

  openCreateTasksModal(): void {
    this.groupTasksService.canUseGroupTasks()
      ? this.openTaskGroupTasksChoiceModal()
      : this.openTaskManageModal({} as Task);
  }

  showToasterOnStatusChange(newStatus: TaskStatus): void {
    this.toasterMessageKey = this.taskService.isToDo(newStatus)
      ? 'TASKS_STATUS_CHANGED_TOASTER_TO_DO'
      : 'TASKS_STATUS_CHANGED_TOASTER_DONE';
    this.showToaster();
  }
}
