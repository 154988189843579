import moment from 'moment-timezone';

const CHECKLIST_LOCALE_KEY = 'settings.checklists_locale';

export const ProfileComponent = {
  bindings: {
    profile: '<',
    organisation: '<',
  },
  templateUrl: 'profile/profile.html',
  // eslint-disable-next-line max-params
  controller: function ProfileController(
    $q,
    $translate,
    notificationsService,
    preferencesService,
    departmentService,
    appMessageService,
    errorMessagesService,
    remoteStoresService,
    profileService,
    organisationsService,
    platformService,
    AVAILABLE_TRANSLATION_LANGUAGES
  ) {
    'ngInject';

    this.moment = moment;

    this.$onInit = () => {
      this.hasFeatureFlagUserDepartment = () =>
        departmentService.hasFeatureFlag();
      this.notificationSaving = false;
      this.timezoneSaving = false;
      this.onlineStoreSaving = false;
      this.departmentList = departmentService.getDepartmentList();
      this.multilingualList = AVAILABLE_TRANSLATION_LANGUAGES.map(
        ({ label, code }) => ({ label, value: code })
      );
      this.initValues = {
        department: this.profile.contents.department,
      };

      const multilingualPref = this.profile.preferences.filter(
        (preference) => preference.name === CHECKLIST_LOCALE_KEY
      )[0];

      if (multilingualPref) {
        this.multilingualPreference = multilingualPref.value;
      }

      const userTimezonePreference = this.profile.preferences.filter(
        (preference) => preference.name === 'settings.timeZone'
      )[0];

      this.timezone = userTimezonePreference
        ? userTimezonePreference.value
        : this.moment.tz.guess();

      organisationsService
        .getProfileOrganisation(this.profile)
        .then((organisation) => {
          this.useNewCampaigns = organisation.useNewCampaigns;
        });

      this.isBrowser = platformService.isBrowser();

      return $q
        .all([
          notificationsService
            .isRegistered()
            .then((isNotificationActivated) => {
              this.isNotificationActivated = isNotificationActivated;
            }),
          remoteStoresService
            .isPreferenceActivated()
            .then((isOnlineStoreActivated) => {
              this.isOnlineStoreActivated = isOnlineStoreActivated;
            }),
        ])
        .then(() =>
          $q.all([
            preferencesService.getValue(
              'notifications.email.campaign_report_created'
            ),
            preferencesService.getValue(
              'notifications.email.campaign_report_updated'
            ),
          ])
        )
        .then((prefs) => {
          if (prefs.every((pref) => pref === undefined)) {
            this.isReportNotificationActivated = true;
          } else {
            this.isReportNotificationActivated = prefs.some((pref) => pref);
          }
        });
    };

    this.onMultilingualChange = (lang) => {
      this.multilingualSaving = true;

      return preferencesService
        .getValue(CHECKLIST_LOCALE_KEY)
        .then((multilingualPref) => {
          if (!multilingualPref) {
            this.profile.preferences = this.profile.preferences.concat([
              {
                name: CHECKLIST_LOCALE_KEY,
              },
            ]);
          }

          this.profile.preferences = this.profile.preferences.map((pref) => {
            if (pref.name === CHECKLIST_LOCALE_KEY) {
              pref.value = lang;
            }
            return pref;
          });

          return preferencesService
            .save(CHECKLIST_LOCALE_KEY, lang)
            .then(() => profileService.updateProfile(this.profile))
            .then(() => {
              appMessageService.display(
                $translate.instant('PROFILE_PREFERENCES_MULTILINGUAL_SUCCESS'),
                'success'
              );
            })
            .catch(() => {
              appMessageService.display(
                $translate.instant('PROFILE_PREFERENCES_MULTILINGUAL_ERROR'),
                'error'
              );
            })
            .finally(() => {
              this.multilingualSaving = false;
            });
        });
    };

    this.onNotificationPreferenceChange = (isActivated) => {
      const promise = isActivated
        ? notificationsService.activate()
        : notificationsService.deactivate();

      this.notificationSaving = true;

      return promise
        .catch(() => {
          this.isNotificationActivated = !this.isNotificationActivated;
        })
        .finally(() => {
          this.notificationSaving = false;
        });
    };

    this.onReportNotificationPreferenceChange = (isActivated) => {
      this.notificationSaving = true;
      const reportCreatedPref = preferencesService.getValue(
        'notifications.email.campaign_report_created'
      );
      const reportUpdatedPref = preferencesService.getValue(
        'notifications.email.campaign_report_updated'
      );

      if (reportCreatedPref === undefined) {
        this.profile.preferences = this.profile.preferences.concat([
          {
            name: 'notifications.email.campaign_report_created',
          },
        ]);
      }
      if (reportUpdatedPref === undefined) {
        this.profile.preferences = this.profile.preferences.concat([
          {
            name: 'notifications.email.campaign_report_updated',
          },
        ]);
      }

      this.profile.preferences = this.profile.preferences.map((pref) => {
        if (
          [
            'notifications.email.campaign_report_created',
            'notifications.email.campaign_report_updated',
          ].includes(pref.name)
        ) {
          pref.value = isActivated;
        }
        return pref;
      });

      return $q
        .all([
          preferencesService.save(
            'notifications.email.campaign_report_created',
            isActivated
          ),
          preferencesService.save(
            'notifications.email.campaign_report_updated',
            isActivated
          ),
        ])
        .then(() => profileService.updateProfile(this.profile))
        .catch(() => {
          this.isReportNotificationActivated =
            !this.isReportNotificationActivated;
        })
        .finally(() => {
          this.notificationSaving = false;
        });
    };

    this.onOnlineStorePreferenceChange = (isActivated) => {
      const message = $translate.instant('PROFILE_SAVED_SUCCESS');

      this.onlineStoreSaving = true;
      return remoteStoresService
        .savePreference(isActivated)
        .then(() => appMessageService.display(message, 'success'))
        .catch((err) => {
          this.isOnlineStoreActivated = !this.isOnlineStoreActivated;
          return appMessageService.display(
            errorMessagesService.getMessage(err, {
              customUnknownErrorMessage: 'ERROR_MESSAGE_NETWORK_DESC',
            }),
            'fail'
          );
        })
        .finally(() => {
          this.onlineStoreSaving = false;
        });
    };

    this.onTimezoneChange = (selectedTimezone) => {
      const message = $translate.instant('PROFILE_SAVED_SUCCESS');

      this.timezoneSaving = true;
      preferencesService
        .save('settings.timeZone', selectedTimezone)
        .then(() => {
          appMessageService.display(message, 'success');
        })
        .catch((err) => {
          appMessageService.display(
            errorMessagesService.getMessage(err, {
              customUnknownErrorMessage: 'ERROR_MESSAGE_NETWORK_DESC',
            }),
            'fail'
          );
        })
        .finally(() => {
          this.timezoneSaving = false;
        });
    };

    this.onDepartmentChange = () =>
      departmentService.saveDepartment(this.profile, this.initValues);
  },
};
