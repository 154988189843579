export const FEATURE_FLAGS = {
  ANALYTICS: 'feature_flag_analytics',
  ANALYTICS_ALPHA: 'feature_flag_analytics_alpha',
  CAMPAIGNS: 'feature_flag_reactive_campaigns',
  CHAT: 'feature_flag_mobile_chat',
  DEBUG: 'feature_flag_debug',
  PLACE_DOCS: 'feature_flag_place_docs',
  ADD_PLACE: 'feature_flag_add_store_mobile',
  TASKS: 'feature_flag_mobile_task_mgmt',
  NEXT_EVENTS: 'feature_flag_mobile_next_events',
  GROUP_TASKS: 'feature_flag_grouped_tasks',
  CALENDAR_EVENTS: 'feature_flag_visits',
  MERCHANDISING: 'feature_flag_merchandising',
  MERCHANDISING_DRAW: 'feature_flag_merchandising_draw',
  EVENTS_VALIDATION: 'feature_flag_events_validation',
  USER_DEPARTMENT: 'feature_flag_user_department',
  REPORTS_VALIDATION: 'feature_flag_reports_validation',
  FIELD_METRICS: 'feature_flag_field_metrics',
  NEWSFEED: 'feature_flag_newsfeed',
  NEWSFEED_ALPHA: 'feature_flag_newsfeed_alpha',
  NOTIFICATIONS_VIEW: 'feature_flag_notifications_view',
  CAMPAIGN_COMPLETION: 'feature_flag_campaign_completion_mobile',
  WHATS_NEW: 'feature_flag_whats_new',
  SALES_DASHBOARD_MOBILE: 'sales_dashboard_mobile',
  MANAGER_CALENDAR: 'feature_flag_manager_calendar',
  MANAGER_CALENDAR_ALPHA: 'alpha_feature_flag_manager_calendar',
  PLACES_ANALYTICS: 'feature_flag_place_analytics_mobile',
  DOCUMENT_LIBRARY_ALPHA: 'alpha_feature_flag_document_library',
  DOCUMENT_LIBRARY: 'feature_flag_document_library',
  NEW_PARAMS_TYPES: 'feature_flag_new_params_types',
  NEW_PARAMS_TYPES_ALPHA: 'alpha_feature_flag_new_params_types',
  INTERCOM_SUPPORT: 'feature_flag_intercom_support',
  CORPORATE_EVENTS: 'feature_flag_corporate_events',
  SYNCHRONIZE_REPORTS: 'feature_flag_synchronize_reports',
  NEW_APP_REDIRECTION: 'new_app_redirection',
  FULL_CALENDAR: 'feature_flag_full_calendar',
  NEWSFEED_WIDGET: 'newsfeed_widget',
  PRODUCT_CATALOG: 'feature_flag_product_catalogs',
  SCAN_PRODUCT: 'feature_flag_scan_product',
  DRAFT_REPORTS: 'feature_flag_drafts_reports',
  CORDOVA_CAMERA: 'feature_flag_cordova_camera',
} as const;

export const BUSINESS_FLAGS = {
  feature_flag_auto_scheduling: 'Module autoscheduling',
  feature_flag_newsfeed: 'Module newsfeed',
  feature_flag_mobile_chat: 'Module chat',
  feature_flag_product_catalogs: 'Module product_catalogs',
  feature_flag_merchandising: 'Module merchandising',
  feature_flag_events_validation: 'Module events_validation',
  feature_flag_mobile_task_mgmt: 'Module Task',
  feature_flag_visits: 'Module schedule',
  places_analytics_mobile: 'Modules place analytics',
  feature_flag_document_library: 'Module document library',
} as const;

export default angular
  .module('sfMobile.constants.feature-flags', [])
  .constant('SF_FEATURE_FLAGS', FEATURE_FLAGS)
  .constant('SF_BUSINESS_FLAGS', BUSINESS_FLAGS).name;
