import { FEATURE_FLAGS } from '@constants/feature-flags.constant';
import { SfFeatureFlags } from '@simplifield/feature-flags';
import { CalendarEventsModalService } from 'app/calendar-events/services/calendar-events-modal/calendar-events-modal.service';
import { DateFormatService } from '../../../../services/Utils/Dates/date-format.service';
import { LocalizationService } from '../../../../services/Utils/Localization/localization.service';
import { EVENT_ITEM_THEMES } from '../../../constants/event-item-themes.constant';
import { CalendarEvent } from '../../../types';

export class CalendarEventListItemController
  implements ng.IComponentController
{
  // bindings
  event: CalendarEvent;
  displayAsCards = false;
  onDelete: (event: CalendarEvent) => void;

  // properties
  isRTLNeeded: boolean;
  isFullCalendar: boolean;
  theme: typeof EVENT_ITEM_THEMES[keyof typeof EVENT_ITEM_THEMES];
  displayTimezone: string;

  eventCardActions: { text: string; onClick: () => void }[] = [
    {
      text: this.$translate.instant('EDIT_EVENT'),
      onClick: () => {
        this.calendarEventsModalService.editCalendarEvent(
          this.event,
          ({ event }) => {
            this.event = event;
          }
        );
      },
    },
    {
      text: this.$translate.instant('DELETE_EVENT'),
      onClick: () => {
        this.onDelete(this.event);
      },
    },
  ];

  /* @ngInject */
  constructor(
    private localizationService: LocalizationService,
    private dateFormatService: DateFormatService,
    private SF_EVENT_ITEM_THEMES: typeof EVENT_ITEM_THEMES,
    private calendarEventsModalService: CalendarEventsModalService,
    private $translate: ng.translate.ITranslateService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {}

  $onInit(): void {
    this.isFullCalendar = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.FULL_CALENDAR
    );

    this.isRTLNeeded = this.localizationService.shouldActivateRTL();

    if (!this.theme) {
      this.theme = this.SF_EVENT_ITEM_THEMES.CALENDAR;
    }
  }
  shouldDisplayTz(): boolean {
    return this.displayTimezone !== this.event.contents.timezone.name;
  }

  formatTimezone(): string {
    return this.dateFormatService.getTimezoneFormatted(
      this.event.contents.timezone.name,
      this.event.contents.start_dateTime
    );
  }
  eventDateRangeMessage(): string {
    return this.dateFormatService.getDateFrameFormatted(
      this.event.contents.start_dateTime,
      this.event.contents.end_dateTime,
      this.event.contents.timezone.name
    );
  }
}
