import {
  AngularSqlFactory,
  FilterParams,
  LocalDatabaseInterface,
} from '../../Libs/SfAngularSQLQuery/angular-sql-query.factory';
import {
  Identified,
  LimitParams,
  LocalDataAccesService,
  SortParams,
} from '../CRUD/crud-service';

export type AngularSQLServiceQuery = {
  indexed_fields: string[];
};

class AngularSqlQuery<T> implements LocalDataAccesService<T> {
  private SQLService: LocalDatabaseInterface<T>; // Should be defined in the service

  constructor(
    private angularSQLFactory,
    storeName: string,
    database: unknown,
    options
  ) {
    this.SQLService = new this.angularSQLFactory(storeName, database, options);
  }
  getLocal(id: string): ng.IPromise<Identified<T>> {
    return this.SQLService.getBackUp(id);
  }
  listLocal(): ng.IPromise<Identified<T>[]> {
    return this.SQLService.listBackUp();
  }
  countLocal(): ng.IPromise<number> {
    return this.SQLService.countBackUp();
  }
  saveLocal(id: string, entity: T): ng.IPromise<Identified<T>> {
    return this.SQLService.saveBackUp(id, entity);
  }
  queryLocal(
    params: Record<string, unknown>,
    limitParams: LimitParams,
    sortParams: SortParams,
    listComparator?: (...arg: any) => boolean
  ): ng.IPromise<Identified<T>[]> {
    return this.SQLService.queryBackUp(
      params as FilterParams,
      limitParams,
      sortParams,
      listComparator
    );
  }
  updateLocal(entity: T): ng.IPromise<Identified<T>> {
    return this.SQLService.updateBackUp(entity);
  }
  deleteLocal(id: string): ng.IPromise<any> {
    return this.SQLService.removeBackUp(id);
  }
  bulkDocsLocal(entities: T[]): ng.IPromise<any> {
    return this.SQLService.bulkDocsBackUp(entities);
  }
  execute(query: string): ng.IPromise<SqlResultSet> {
    return this.SQLService.execute(query);
  }
}

export function AngularSqlQueryFactory<T>(
  angularSQLFactory: AngularSqlFactory<T>
): (storeName: string, database: unknown, options) => AngularSqlQuery<T> {
  'ngInject';

  return (storeName: string, database, options) =>
    new AngularSqlQuery<T>(angularSQLFactory, storeName, database, options);
}
