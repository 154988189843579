import { PubSubService } from '@services/Utils/PubSub/pubsub.service';
import { ObjectId } from '../../..';
import { DigitalAssetsService } from '../../../services/Utils/DigitalAssets/digital-assets.service';

export class VideoPlayerController implements ng.IComponentController {
  /** Bindings */
  videoId: ObjectId;
  isWidget: boolean;

  /** */
  videoUrl: string;
  posterUrl: string;
  pending = false;

  private video: { transformed: string; base: string };
  private poster: { transformed: string; base: string };

  /* @ngInject */
  constructor(
    private digitalAssetsService: DigitalAssetsService,
    private pubSubService: PubSubService
  ) {}

  $onInit(): ng.IPromise<void> {
    this.pending = true;
    return this.digitalAssetsService
      .getVideoAndPosterUrl(this.videoId)
      .then(({ video, poster }) => {
        this.video = video;
        this.poster = poster;

        this.videoUrl = this.video.base;
        this.posterUrl = this.poster.base;

        if (this.isWidget) {
          this.pubSubService.publish('SLIDER_MEDIA_LOADED');
        }
      })
      .catch((e) => e)
      .finally(() => {
        this.pending = false;
      });
  }
}
