import { TaskDetailsActionsComponent } from './task-details-actions.component';

export default angular
  .module('sfMobile.components.task-details-actions', [
    'sfMobile.constants.task-statuses',
    'sfMobile.services.app-message',
    'sfMobile.services.task',
    'sfMobile.services.segment',
    'sfMobile.services.profile',
    'sfMobile.services.users',
  ])
  .component('sfTaskDetailsActions', TaskDetailsActionsComponent).name;
