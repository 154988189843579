export const ToggleDisplayComponent = {
  bindings: {
    firstLabel: '@',
    secondLabel: '@',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  templateUrl: 'components/Inputs/toggle-display/toggle-display.html',
  controller: class ToggleDisplayController {
    $onInit() {
      this.modelCtrl.$render = () => {
        this.toggleValue = this.modelCtrl.$viewValue;
      };
    }
    changeValue(value) {
      this.modelCtrl.$setViewValue(value);
    }
    onLabelClick(value) {
      this.toggleValue = value;
      this.changeValue(value);
    }
  },
};
