import ObjectID from 'bson-objectid';
import { ObjectId } from '../../..';
import { v4 as uuidv4 } from 'uuid';

export class ObjectIdService {
  create(): ObjectId {
    return new ObjectID().toHexString();
  }

  getUniqId(): string {
    return uuidv4();
  }
}
