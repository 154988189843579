import { PROFILE_PIC_OPTIONS } from '../../../constants/files-upload-options.constant';
import { ImageCropperService } from '../../../services/Utils/ImageCropper/image-cropper.service';

export const ImageCropperComponent: ng.IComponentOptions = {
  bindings: {
    image: '<',
    croppedImgOptions: '<',
    onClose: '&',
    onSave: '<',
  },
  templateUrl: 'components/Images/image-cropper/image-cropper.html',
  controller: class ImageCropperComponent implements ng.IController {
    //Bindings
    image: string;
    croppedImgOptions: typeof PROFILE_PIC_OPTIONS;
    onClose: () => void;
    onSave: (blob: Blob) => void;

    constructor(
      private $element: ng.IRootElementService,
      private $timeout: ng.ITimeoutService,
      private imageCropperService: ImageCropperService
    ) {
      'ngInject';
    }

    $postLink(): void {
      this.$timeout(() => {
        const image = this.$element[0].querySelector('#original_img');
        const { cropSize, cropArea } = this.croppedImgOptions;

        if (!image) {
          return;
        }

        this.imageCropperService.initCropper(image, {
          width: cropSize,
          height: cropSize,
          type: cropArea,
        });
      }, 0);
    }

    onSaveCropped(): ng.IPromise<void> {
      const { cropSize } = this.croppedImgOptions;

      return this.imageCropperService
        .getCroppedImage<Blob>({
          type: 'blob',
          width: cropSize,
          height: cropSize,
        })
        .then((croppedImage) => this.onSave(croppedImage))
        .finally(() => this.onClose());
    }
  },
};
