export function HtmlService() {
  'ngInject';
  const methods = {
    wrapper,
  };

  function wrapper(tag) {
    return (string) => `<${tag}>${string}</${tag}>`;
  }

  return methods;
}
