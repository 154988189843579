import { PopupSynchronizeController } from '../popup-synchronize/popup-synchronize.controller';

const DRAFT_MESSAGE_MAP = {
  E_CAMPAIGN_IS_DRAFT: 'FORM_POPUP_CAMPAIGN_IS_DRAFT',
  E_CAMPAIGN_VERSION_MISMATCH: 'FORM_POPUP_CAMPAIGN_VERSION_MISMATCH',
};

// sf-popup-moved-to-draft
export class PopupMovedToDraftController
  extends PopupSynchronizeController
  implements ng.IComponentController
{
  // bindings
  errorMessage: string;

  error: string;

  $onInit(): void {
    this.error =
      DRAFT_MESSAGE_MAP[this.errorMessage] ||
      DRAFT_MESSAGE_MAP.E_CAMPAIGN_IS_DRAFT;
  }
}
