export const ProductPickerComponent = {
  bindings: {
    question: '<',
    hasError: '<',
    onProductSelected: '&',
    isDisabled: '<?',
  },
  templateUrl:
    'missions/components/Form-questions/question-product/components/product-picker/product-picker.html',
  // eslint-disable-next-line max-params
  controller: function ProductPickerController(
    $filter,
    $translate,
    StaticRequestsPaginate,
    modalService,
    productsService,
    popupService,
    SF_CURRENCIES,
    imageSourceService,
    platformService,
    barcodeService,
    $q,
    RequestsPaginate,
    objectIdService,
    pubSubService
  ) {
    'ngInject';
    const PRODUCTS_LIMIT = 20;
    const PRODUCT_DEFAULT_PRICE = 0;

    this.products = [];
    this.isOffline = false;
    this.dataProvider = {};

    this.$onInit = () => {
      this.productsProvider = this.createStaticProductsProvider();

      this.requestsPaginate = new RequestsPaginate(
        (params) => this.fetchProducts(params),
        {
          limit: PRODUCTS_LIMIT,
        }
      );

      this.unsubscriber = pubSubService.subscribe(
        'IS_HUGE_AND_OFFLINE',
        ({ isHugeCounts, isOffline }) => {
          this.isHugeAndOffline = isHugeCounts && isOffline;
          this.isHugeProductsCount = isHugeCounts;
          this.isOffline = isOffline;
        }
      );

      return productsService.pingHugeAndOffline();
    };

    this.$onDestroy = () => {
      productsService.killPingInterval();
      this.unsubscriber();
    };

    this.resetProducts = () => {
      this.products = [];
      this.requestsPaginate.reset();
    };

    this.fetchProducts = (params) => {
      const EMPTY_ANSWER = {
        entries: [],
        count: 1,
      };

      if (this.isHugeProductsCount && params.search.length < 3) {
        return $q.resolve(EMPTY_ANSWER);
      }

      const lastCalledDataProviderId = this.dataProvider.id;

      const productsPrommise = () => {
        return productsService
          .getByCatalogs(this.question.catalogs_ids, params)
          .then((products) => {
            return $q.all(
              products.map((product) => {
                return productsService
                  .getProductImageUrls(product)
                  .then((imageUrls) => ({ ...product, imageUrls }));
              })
            );
          })
          .then((entries) => {
            if (this.dataProvider.id !== lastCalledDataProviderId) {
              this.products = [];

              return $q.resolve(EMPTY_ANSWER);
            }

            this.products.push(...entries);

            const count =
              entries.length < PRODUCTS_LIMIT
                ? entries.length
                : this.products.length + 1;

            return { entries, count };
          });
      };

      return productsService
        .pingHugeAndOffline()
        .then(({ isHugeCounts, isOffline }) =>
          !(isHugeCounts && isOffline) ? productsPrommise() : $q.resolve()
        )
        .catch(() => $q.resolve());
    };

    this.addProduct = (ean) => (ean ? this.selectValidProduct(ean) : false);

    this.scanProduct = () => {
      this.hasScanError = false;

      const scanPrommise = () => {
        return (
          platformService.isBrowser()
            ? barcodeService.promptBarCode()
            : barcodeService.scan()
        ).then(
          (ean) => {
            return this.addProduct(ean);
          },
          () => {
            this.hasScanError = true;
          }
        );
      };

      const issuePrommise = () => {
        this.hasScanError = true;

        return $q.resolve();
      };

      return productsService
        .pingHugeAndOffline()
        .then(({ isHugeCounts, isOffline }) =>
          !(isHugeCounts && isOffline) ? scanPrommise() : issuePrommise()
        );
    };

    this.selectValidProduct = (ean) =>
      productsService
        .getByEans([ean])
        .then((products) => {
          const catalogProducts = products.filter((p) =>
            this.question.catalogs_ids.includes(p.contents.catalog_id)
          );

          if (catalogProducts.length > 1) {
            popupService
              .showConfirm({
                title: $translate.instant('PRODUCT_PICKER_SAME_EAN'),
                buttonText: $translate.instant('LIST_OPTION_SELECT_BUTTON'),
                hasCheckbox: false,
              })
              .then(() =>
                this.openProductsWithSameEan(catalogProducts, ean).then(
                  (product) =>
                    product ? this.onProductSelected({ product }) : false
                )
              );
          } else {
            this.selectProductWithEan(catalogProducts[0], ean);
          }
        })
        .catch(() =>
          popupService.showError({
            title: $translate.instant('PRODUCT_PICKER_SCAN_FAIL'),
          })
        );

    this.selectProductWithEan = (product, ean) => {
      const DEFAULT_PRODUCT = {
        contents: {
          ean,
          price: {
            value: PRODUCT_DEFAULT_PRICE,
            currency: SF_CURRENCIES.EURO.code,
          },
        },
      };

      if (
        product &&
        this.question.catalogs_ids.includes(product.contents.catalog_id)
      ) {
        this.onProductSelected({ product });
        return;
      }

      this.onProductSelected({ product: DEFAULT_PRODUCT });
    };

    this.openProductsWithSameEan = (products, ean) => {
      const componentTemplate = `
      <sf-ean-product-picker
        products="$ctrl.products"
        ean="{{ ::$ctrl.ean }}"
        on-product-click="$ctrl.onProductClick(product)"
        on-close="$ctrl.onClose()"
        on-save="$ctrl.onSave()"
        >
      </sf-ean-product-picker>
      `;
      const componentBindings = {
        products,
        ean,
      };
      return modalService
        .openAsPromise(componentTemplate, componentBindings)
        .then((product) => product);
    };

    this.customDataProvider = ({ search }) => {
      const checkSearch = this.isHugeProductsCount ? search.length >= 3 : true;

      this.resetProducts();

      this.dataProvider = {
        id: objectIdService.getUniqId(),
        load: () => this.requestsPaginate.call({ search }),
        canCallMore: () => this.requestsPaginate.canCallMore() && checkSearch,
      };

      return this.dataProvider;
    };

    this.openProductsList = () => {
      this.hasScanError = false;
      let selectionResult = [];

      const onSelectionChange = ({ entity, selected }) => {
        selectionResult = selected
          ? selectionResult.concat(entity)
          : selectionResult.filter((el) => el._id !== entity._id);
      };

      const componentTemplate = `
        <sf-entity-selector-modal
          title="{{ ::$ctrl.title }}"
          multiple="true"
          search-placeholder="{{ ::$ctrl.searchPlaceholder }}"
          on-query-change="$ctrl.onQueryChange(query)"
          entity-title-get="$ctrl.entityTitleGet"
          entity-description-get="$ctrl.entityDescriptionGet"
          entity-image-get="$ctrl.entityImageGet"
          on-image-click="$ctrl.onImageClick"
          on-selection-change="$ctrl.onSelectionChange(selection)"
          on-save="$ctrl.onSave()"
          on-close="$ctrl.onClose()"
          check-offline-fn="$ctrl.checkOfflline()"
          offline-i18n-message="$ctrl.offlineI18nMessage"
          min-search-length="$ctrl.minSearchLength"
          empty-icon="no_catalog">
        </sf-entity-selector-modal>
      `;
      const componentBindings = {
        title: $translate.instant('PRODUCT_PICKER_LIST_TITLE'),
        searchPlaceholder: $translate.instant(
          'PRODUCT_PICKER_LIST_PLACEHOLDER'
        ),
        onQueryChange: (query) => this.customDataProvider(query),
        entityTitleGet: this.getProductTitle,
        entityDescriptionGet: this.getProductDescription,
        entityImageGet: this.getProductThumbImage,
        onImageClick: this.viewPicture,
        onSelectionChange: onSelectionChange,
        checkOfflline: () =>
          productsService
            .pingHugeAndOffline()
            .then(({ isOffline, isHugeCounts }) => isOffline && isHugeCounts),
        offlineI18nMessage: 'PRODUCTS_LIST_OFFLINE_ALERT',
        minSearchLength: this.isHugeProductsCount ? 3 : 0,
        onSave: () =>
          selectionResult.forEach((product) =>
            this.onProductSelected({ product })
          ),
      };

      this.productSelectionModal = modalService.open(
        componentTemplate,
        componentBindings
      );
      return this.productSelectionModal;
    };

    this.createStaticProductsProvider =
      () =>
      ({ search }) =>
        new StaticRequestsPaginate(
          $filter('filter')(this.products, search),
          PRODUCTS_LIMIT
        );

    this.viewPicture = (ev, product) => {
      ev.stopPropagation();

      const template = `
        <sf-images-viewer
          images="[$ctrl.image]"
          on-close="$ctrl.onClose()">
          <div class="padding">
            <div class="sf_image_viewer__details">
              <p class="stable">{{ $ctrl.product.contents.name }}</p>
              <p>{{ $ctrl.product.contents.brand }}</p>
            </div>
          </div>
        </sf-images-viewer>
      `;
      const url = product && product.imageUrls && product.imageUrls.full;
      const pictureViewerbindings = {
        image: imageSourceService.create({ url }, false),
        product,
      };

      return url && modalService.open(template, pictureViewerbindings);
    };

    this.getProductDescription = (product) =>
      product.contents.catalog_name + ` (EAN: ${product.contents.ean})`;
    this.getProductTitle = (product) => product.contents.name;
    this.getProductThumbImage = (product) =>
      product && product.imageUrls && product.imageUrls.thumb;
  },
};
