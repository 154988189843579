"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('document1'),
    name: 'document1',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      file_id: createObjectId('file'),
      places_ids: [createObjectId('place1'), createObjectId('place2')]
    }
  }, {
    _id: createObjectId('document2'),
    name: 'document2',
    description: 'Missing organisation',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.organisation_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      owner_id: createObjectId('owner'),
      file_id: createObjectId('file'),
      places_ids: [createObjectId('place1'), createObjectId('place2')]
    }
  }, {
    _id: createObjectId('document3'),
    name: 'document2',
    description: 'Missing owner',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.owner_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      file_id: createObjectId('file'),
      places_ids: [createObjectId('place1'), createObjectId('place2')]
    }
  }, {
    _id: createObjectId('document4'),
    name: 'document4',
    description: 'Missing file',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.file_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      places_ids: [createObjectId('place1'), createObjectId('place2')]
    }
  }, {
    _id: createObjectId('document5'),
    name: 'document5',
    description: 'Missing places',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.places_ids']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      file_id: createObjectId('file')
    }
  }, {
    _id: createObjectId('document6'),
    name: 'document6',
    description: 'Additional properties',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_UNEXPECTED_CONTENT',
        params: ['.k']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      file_id: createObjectId('file'),
      places_ids: [createObjectId('place1'), createObjectId('place2')],
      k: 'v'
    }
  }, {
    _id: createObjectId('document7'),
    name: 'document7',
    description: 'Empty places ids',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['should NOT have less than 1 items']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      file_id: createObjectId('file'),
      places_ids: []
    }
  }];
};