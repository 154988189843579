export function PopupRequestService($q, $timeout, popupService, popupConstant) {
  'ngInject';
  const { POPUP_ANIMATION_END_DURATION, POPUP_SUCCESS_DISPLAY_DURATION } =
    popupConstant;

  return {
    show,
  };

  /**
   * Start the flow of a save request
   * @param {Object} popupOptions Popup configuration
   * @param {string} popupOptions.progress Progress popup config
   * @param {string} popupOptions.error Error popup config
   * @param {string} [popupOptions.success] Success popup config
   * @param {Function} [onCancel] Call on the progress cancel popup tap
   * @param {Number} [nbSteps] Number of steps to display in the progress
   * @returns {Object} Popup methods
   */
  function show(popupOptions, onCancel, nbSteps) {
    let popupSuccess = null;
    let popupError = null;
    let hasBeenCanceled = false;
    const progressionState = {
      nb: nbSteps || 1,
      current: 0,
    };
    const cancelHandler = onCancel
      ? () => {
          hasBeenCanceled = true;
          return onCancel();
        }
      : null;
    const progressPopup = showProgress(
      progressionState,
      popupOptions.progress,
      cancelHandler
    );
    /**
     * Tick one step of the progression
     * @returns {Boolean} true
     */
    const onTick = () => {
      progressionState.current++;
      return true;
    };
    /**
     * Hide the progress popup
     * And display the success popup if the config exist
     * @param {*} [datas] Request datas
     * @returns {Promise} Pass the data back
     */
    const onSuccess = (datas) => {
      onTick();
      return $timeout(() => progressPopup.close(), POPUP_ANIMATION_END_DURATION)
        .then(() => {
          if (!popupOptions.success) {
            return $q.resolve();
          }
          popupSuccess = showSendSuccess(popupOptions.success);

          if (
            !popupOptions.success.actions ||
            !popupOptions.success.actions.length
          ) {
            return $timeout(popupSuccess.close, POPUP_SUCCESS_DISPLAY_DURATION);
          }

          // As the documentation of $ionicPopup say,
          // the promise popupSuccess will be resolved by onTap event of buttons
          return popupSuccess;
        })
        .then(() => datas);
    };
    /**
     * Hide the progress popup
     * And display the error popup if the request is not canceled
     * @param {Function} [onErrorRetry] Callback on retry tap
     * @param {Function} [onErrorCancel] Callback on cancel tap
     * @param {String} [message] Error message to display
     * @returns {Boolean} true
     */
    const onError = (
      onErrorRetry,
      onErrorCancel,
      message,
      hasInternet = true
    ) => {
      progressPopup.close();

      const errorOptions =
        !hasInternet && Boolean(popupOptions.internetConnectionIssue)
          ? popupOptions.internetConnectionIssue
          : popupOptions.error;

      if (!hasBeenCanceled) {
        popupError = showErrorPopup(
          errorOptions,
          onErrorRetry,
          onErrorCancel,
          message
        );
      }
      return true;
    };

    /**
     * Close the popups
     * @returns {Boolean} true
     */
    const clear = () => {
      const popups = [progressPopup, popupSuccess, popupError];

      popups.forEach((popup) => (popup ? popup.close() : false));
      return true;
    };

    const onReportValidationFails = (primaryAction, cancelAction, message) => {
      progressPopup.close();
      if (hasBeenCanceled) {
        return true;
      }

      return popupService.showOptions(
        {
          title: message,
          iconName: 'error',
          btnFirstOption: cancelAction.label,
          btnSecondOption: primaryAction.label,
        },
        cancelAction.action,
        primaryAction.action
      );
    };

    return {
      onTick,
      onSuccess,
      onError,
      onReportValidationFails,
      clear,
    };
  }

  function showProgress(sendState, options, onCancel) {
    return popupService.showProgress(sendState, options, onCancel);
  }
  function showSendSuccess(options) {
    return popupService.show(options);
  }
  function showErrorPopup(options, onRetry, onCancel, message) {
    const errorOptions = Object.assign(options, {
      desc: message || options.desc,
    });

    return popupService.showSaveError(errorOptions, onRetry, onCancel);
  }
}
