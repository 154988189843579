import { KeepAwake } from '@capacitor-community/keep-awake';
import { PlatformService } from '../Platform';

export class AwakeService {
  KeepAwake = KeepAwake;
  private isKeepAwakeAvailable: boolean;

  constructor(private platformService: PlatformService) {
    'ngInject';
    this.isKeepAwakeAvailable =
      this.platformService.isPluginAvailable('KeepAwake');
  }
  keepAwake(): void {
    this.isKeepAwakeAvailable && this.KeepAwake.keepAwake().catch(() => null); // don't throw in MOTW
  }

  allowSleepAgain(): void {
    this.isKeepAwakeAvailable && this.KeepAwake.allowSleep().catch(() => null);
  }
}
