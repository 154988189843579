/*
This is a **real** factory that instantiates a service for a given metric
usage example:
```js
const testMetricService = metricsServiceFactory({ metricId: 'testId' });
return testMetricService
  .getMetadata({ params: qparams })
  .then(...)
```
*/
export function MetricsService($http, sfPOVService) {
  'ngInject';

  return ({ metricId }) => ({
    getMetadata: getMetadata({ metricId }),
    queryValues: queryValues({ metricId }),
  });

  function getMetadata({ metricId }) {
    return (params = {}) =>
      sfPOVService
        .pBuildURL(`/metrics/${metricId}`, { pov: 'organisation' })
        .then((url) => $http.get(url, { params }))
        .then((response) => response.data);
  }
  function queryValues({ metricId }) {
    return (body = {}, params = {}) =>
      sfPOVService
        .pBuildURL(`/metrics/${metricId}/values`, { pov: 'organisation' })
        .then((url) =>
          $http.post(
            url,
            {
              ...body,
              withGoals: false,
              withLast: false,
            },
            { params }
          )
        )
        .then((response) => response.data);
  }
}
