import { ReportsService } from './reports.service';

export default angular
  .module('sfMobile.services.reports', [
    'sfMobile.comments',
    'sfMobile.services.reports-validation',
    'sfMobile.services.crud',
    'sfMobile.services.objectId',
    'sfMobile.services.missions',
    'sfMobile.services.file-system',
    'sfMobile.config.database',
    'sfMobile.services.dates',
    'sfMobile.services.pubsub',
    'sfMobile.services.debug',
    'sfMobile.services.log',
  ])
  .service('reportsService', ReportsService).name;
