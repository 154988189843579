export const SignaturePadComponent = {
  bindings: {
    onValidate: '&',
    onClose: '&',
  },
  templateUrl: 'components/Inputs/signature-pad/signature-pad.html',
  // eslint-disable-next-line max-params
  controller: class SignaturePadController {
    constructor($window, $element) {
      'ngInject';

      Object.assign(this, {
        $window,
        $element,
      });
    }

    $onInit() {
      const signaturePadClass = '.sf_signaturePad_container';
      const signatureContainerClass = '.sf_signaturePad_signature_container';

      this.signaturePadContainer =
        this.$element[0].querySelector(signaturePadClass);
      this.signatureContainer = this.$element[0].querySelector(
        signatureContainerClass
      );

      angular.element(this.$window).bind('resize', () => this.setDimensions());
    }

    $onDestroy() {
      angular
        .element(this.$window)
        .unbind('resize', () => this.setDimensions());
    }

    setDimensions() {
      const padHeight = this.signaturePadContainer.clientHeight;
      const padWidth = this.signaturePadContainer.clientWidth;
      const isLandscape = padHeight < padWidth;
      const newHeight = isLandscape ? `${padHeight}px` : null;

      this.signatureContainer.style.height = newHeight;

      if (this.resizeSignature) {
        this.resizeSignature();
      }
    }

    signatureIsEmpty() {
      return (
        this.signaturePadForm['$ctrl.signature'] &&
        this.signaturePadForm['$ctrl.signature'].$isEmpty()
      );
    }
  },
};
