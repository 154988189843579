import { ReportNodeComponent } from './components/report-node/report-node.component';
import { ReportQuestionComponent } from './components/report-question/report-question.component';
import { ReportAnswersComponent } from './components/report-answers/report-answers.component';
import { ReportSectionComponent } from './components/report-section/report-section.component';
import { ReportContentComponent } from './report-content.component';

export default angular
  .module('sfMobile.components.report-content', [
    'sfMobile.constants.image-sizes',
    'sfMobile.constants.question-product',
    'sfMobile.services.forms',
    'sfMobile.services.reports',
    'sfMobile.services.modal',
    'sfMobile.services.dates',
    'sfMobile.services.files',
    'sfMobile.services.file-system',
    'sfMobile.components.content',
    'sfMobile.components.report-navigation-header',
    'sfMobile.components.report-navigation-modal',
  ])
  .component('reportNode', ReportNodeComponent)
  .component('reportQuestion', ReportQuestionComponent)
  .component('reportSection', ReportSectionComponent)
  .component('sfReportAnswers', ReportAnswersComponent)
  .component('sfReportContent', ReportContentComponent).name;
