import { ModalService, Modal, ModalBindings, ModalOptions } from '.';

export class IonicModalService implements ModalService {
  onCloseMsg = 'modal onClose exit';
  constructor(
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService,
    private $ionicModal: IonicV1.IonicModalService,
    private $q: ng.IQService
  ) {
    'ngInject';
  }

  open(
    template: string,
    bindings: ModalBindings = {},
    options: ModalOptions = {}
  ): Modal {
    const scope: ng.IScope & { $ctrl?: ModalBindings } = this.$rootScope.$new();

    scope.$ctrl = bindings;

    options.animation = options.animation || 'fade-in-if';

    const modal = this.$ionicModal.fromTemplate(template, {
      scope,
      ...options,
    });

    scope.$ctrl.onClose =
      bindings.onClose || this.removeModal.bind(this, modal);

    modal.show();

    // when click on a backdrop (if modal is not in a fullscreen) - a modal becomes hidden but still exists in the dom
    // if we call open function one more time - the new modal appears but previous remains in the dom
    scope.$on('modal.hidden', () => this.removeModal(modal));

    return modal;
  }

  openAsPromise<T>(
    template: string,
    bindings: ModalBindings = {},
    options: ModalOptions = {}
  ): ng.IPromise<T> {
    const defer = this.$q.defer<T>();
    const modal = this.open(template, bindings, options);

    bindings.onClose = (value) => {
      defer.reject({ value, message: this.onCloseMsg });

      if (!modal) {
        return;
      }

      this.removeModal(modal);
    };
    bindings.onSave = () => (res: T) => {
      defer.resolve(res);
      if (!modal) {
        return;
      }

      this.removeModal(modal);
    };

    return defer.promise;
  }

  removeModal(modal: IonicV1.IonicModal): ng.IPromise<void> {
    this.$rootScope.$broadcast('ON_MODAL_CLOSE');

    return this.$timeout(() => modal.remove(), 100);
  }
}
