import ReportModule from './report/report.module';
import ReportsValidationModule from './reports-validation/reports-validation.module.js';
import ReportItemModule from './components/report-item/report-item.module';
import ReportNavigationHeaderModule from './components/report-navigation/report-navigation-header/report-navigation-header.module';
import ReportNavigationModalModule from './components/report-navigation/report-navigation-modal/report-navigation-modal.module';
import ReportStatusModule from './components/report-status/report-status.module';
import ReportValidationStatusModule from './components/report-validation-status/report-validation-status.module';
import ReportsListModule from './components/reports-list/reports-list.module';
import ReportsPendingSelectionModule from './components/reports-pending-selection/reports-pending-selection.module';

export default angular.module('sfMobile.reports', [
  ReportModule,
  ReportsValidationModule,
  ReportItemModule,
  ReportNavigationHeaderModule,
  ReportNavigationModalModule,
  ReportStatusModule,
  ReportValidationStatusModule,
  ReportsListModule,
  ReportsPendingSelectionModule,
]).name;
