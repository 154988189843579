"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId(),
    name: 'nfroidure',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['root'],
      tags: [],
      params: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: []
    }
  }, {
    _id: createObjectId('owner'),
    name: 'mpolnaref',
    description: 'Every data that can be set.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Michel',
      lastName: 'Polnaref',
      email: 'michel.polnaref@chateaudelase.fr',
      phone: '+33012345678',
      department: 'marketing',
      role: 'president',
      birth_date: new Date('1944-07-03').toISOString(),
      country: 'FR',
      experience: 'field',
      profiles: ['mobile', 'web'],
      tags: ['Hype', 'Dev'],
      params: [{
        name: 'param',
        value: 'value'
      }, {
        name: 'param2',
        value: 'value2'
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [createObjectId('usergroup1')],
      places_ids: [createObjectId()],
      subscribers_ids: [createObjectId()],
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'nfroidure',
    description: 'User with gallery profile',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['gallery'],
      tags: [],
      params: [],
      mobileDevices: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'user-store-scope-1',
    description: 'User with store scope(places_ids)',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['gallery'],
      tags: [],
      params: [],
      mobileDevices: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: [],
      storeScope: {
        places_ids: [createObjectId('place-1'), createObjectId('place-2')],
        placesLists_ids: []
      }
    }
  }, {
    _id: createObjectId(),
    name: 'user-store-scope-2',
    description: 'User with store scope(placesLists_ids)',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['gallery'],
      tags: [],
      params: [],
      mobileDevices: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: [],
      storeScope: {
        places_ids: [],
        placesLists_ids: [createObjectId('places-lists-1'), createObjectId('places-list-2')]
      }
    }
  }, {
    _id: createObjectId(),
    name: 'unknow',
    description: 'Unknown contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_UNEXPECTED_CONTENT',
        params: ['.wadup']
      }]
    },
    partial: {
      valid: false
    },
    contents: {
      firstName: 'Michel',
      lastName: 'Polnaref',
      email: 'michel.polnaref@chateaudelase.fr',
      experience: 'field',
      profiles: ['mobile', 'web'],
      tags: [],
      params: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      wadup: {
        Kikoolol: true
      },
      trololol: 'facepalm',
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'empty',
    description: 'Empty contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.firstName']
      }]
    },
    partial: {
      valid: true
    },
    contents: {}
  }, {
    _id: createObjectId(),
    name: 'noemail',
    description: 'No email',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.email']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Popol',
      lastName: 'Lours'
    }
  }, {
    _id: createObjectId(),
    name: 'bademail',
    description: 'Bad email',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.email', 'should match format email']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Popol',
      lastName: 'Lours',
      email: 'popol.lours@'
    }
  }, {
    _id: createObjectId(),
    name: 'noorg',
    description: 'No organisation',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.organisation_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Maturin',
      lastName: 'Lours',
      email: 'michel.polnaref@chateaudelase.fr',
      experience: 'field',
      profiles: ['mobile', 'web'],
      tags: [],
      params: [],
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'nfroidure',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.profiles[0]', 'should be equal to one of values']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['Web'],
      tags: [],
      params: [],
      organisation_id: 1234,
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'nfroidure',
    description: 'No organisation_id.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.organisation_id', 'should be string']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['web'],
      tags: [],
      params: [],
      organisation_id: 1234,
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'nfroidure2',
    description: 'A bad organisation id.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.organisation_id', 'should NOT be shorter than 24 characters']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['web'],
      tags: [],
      params: [],
      organisation_id: '',
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'nfroidure3',
    description: 'A bad organisation id.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.organisation_id', 'should match pattern "^([0-9a-f]{24})$"']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['web'],
      tags: [],
      params: [],
      organisation_id: 'zzzzzzzzzzzzzzzzzzzzzzzz',
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'nfroidure2',
    description: 'A partial organisation id.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.organisation_id', 'should NOT be shorter than 24 characters']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['web'],
      tags: [],
      params: [],
      organisation_id: 'abbacaca',
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'nfroidure2',
    description: 'A too large organisation id.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.organisation_id', 'should NOT be longer than 24 characters']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['web'],
      tags: [],
      params: [],
      organisation_id: 'abbacacaabbacacaabbacacaabbacacaabbacaca',
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'missing-managed-places-lists-ids',
    description: 'Missing managed places lists ids.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.managedPlacesLists_ids']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['root'],
      tags: [],
      params: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedUsersGroups_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'missing-managed-users-groups-ids',
    description: 'Missing managed users groups ids.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.managedUsersGroups_ids']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['root'],
      tags: [],
      params: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedPlacesLists_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'missing-experience',
    description: 'Missing experience.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.experience']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      profiles: ['root'],
      tags: [],
      params: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'empty-profile',
    description: 'Empty profile.',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['should NOT have less than 1 items']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Maduro',
      email: 'nicolas.maduro@promo.com',
      profiles: [],
      tags: [],
      params: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'missing-places-in-scope',
    description: 'Missing places_ids in store scope',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.places_ids']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['gallery'],
      tags: [],
      params: [],
      mobileDevices: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: [],
      storeScope: {
        placesLists_ids: []
      }
    }
  }, {
    _id: createObjectId(),
    name: 'missing-places-lists-in-scope',
    description: 'Missing placesLists_ids in store scope',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.placesLists_ids']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      firstName: 'Nicolas',
      lastName: 'Froidure',
      email: 'nicolas.froidure@simplifield.com',
      experience: 'field',
      profiles: ['gallery'],
      tags: [],
      params: [],
      mobileDevices: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      usersGroups_ids: [],
      places_ids: [],
      subscribers_ids: [],
      managedPlacesLists_ids: [],
      managedUsersGroups_ids: [],
      storeScope: {
        places_ids: []
      }
    }
  }];
};