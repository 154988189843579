import { FEATURE_FLAGS } from '@constants/feature-flags.constant';
import { CalendarEventsParamsService } from '@services/API/calendar-events-params/calendar-events-params.service';
import { CalendarEventsService } from '@services/API/calendar-events/calendar-events.service';
import { CampaignsService } from '@services/API/campaigns/campaigns.service';
import { AppMessageService } from '@services/Utils/AppMessage/app-message.service';
import { LocalizationService } from '@services/Utils/Localization/localization.service';
import { ModalService } from '@services/Utils/Modal';
import { SfFeatureFlags } from '@simplifield/feature-flags';
import { StateService } from '@uirouter/angularjs';
import { EVENT_ITEM_THEMES } from 'app/calendar-events/constants/event-item-themes.constant';
import { CampaignsUtilsService } from 'app/reactive-campaigns/services/campaigns-utils.service';
import moment from 'moment-timezone';

export const DetailsEventModalComponent = {
  bindings: {
    event: '<',
    onClose: '&',
    onDelete: '&',
    onEdit: '&',
    onRequestChanges: '&',
    onRequestDeletion: '&',
    readonly: '<',
  },
  templateUrl:
    'calendar-events/components/details-calendar-event-modal/details-event-modal.html',
  controller: class DetailsEventModalController {
    event;
    onClose;
    onDelete;
    onEdit;
    onRequestChanges;
    onRequestDeletion;
    readonly;

    isFullCalendar: boolean;
    corporateEventsTheme: string;
    isRTLNeeded: boolean;
    experience: {
      isField: boolean;
      isStore: boolean;
      isAnimator: boolean;
    };
    startDate;
    endDate;
    dateI18nKey: string;
    dayOff: boolean;
    label: string;
    eventAssignees: string | string[];
    checklists: any[];
    corporateEvents: any[];
    showCorporateEvents: number | boolean;
    canEditEvent: boolean;
    canDeleteEvent: boolean;
    canRequestDelete: boolean;
    canRequestChange: boolean;
    changeRequest: any;
    campaigns: any[];
    categorizedParams: any[];
    paramsDisplayed: any[];
    moment = moment;

    constructor(
      private dateFormatService,
      private localizationService: LocalizationService,
      private $translate: ng.translate.ITranslateService,
      private $q: ng.IQService,
      private $state: StateService,
      private userExperienceService,
      private profileService,
      private eventChangeRequestsService,
      private appMessageService: AppMessageService,
      private apiUtilsService,
      private modalService: ModalService,
      private calendarEventsService: CalendarEventsService,
      private campaignsService: CampaignsService,
      private sfFeatureFlagsService: SfFeatureFlags,
      private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
      private campaignsUtilsService: CampaignsUtilsService,
      private SF_EVENT_ITEM_THEMES: typeof EVENT_ITEM_THEMES,
      private calendarEventsParamsService: CalendarEventsParamsService
    ) {
      'ngInject';
    }

    $onInit() {
      this.isFullCalendar = this.sfFeatureFlagsService.hasFeature(
        this.SF_FEATURE_FLAGS.FULL_CALENDAR
      );
      this.corporateEventsTheme = this.SF_EVENT_ITEM_THEMES.LINKED_OPERATIONS;

      this.isRTLNeeded = this.localizationService.shouldActivateRTL();

      this.experience = {
        isField: this.userExperienceService.isField(),
        isStore: this.userExperienceService.isStore(),
        isAnimator: this.userExperienceService.isAnimator(),
      };
      this.startDate = this.moment.tz(
        this.event.contents.start_dateTime,
        this.event.contents.timezone.name
      );
      this.endDate = this.moment.tz(
        this.event.contents.end_dateTime,
        this.event.contents.timezone.name
      );
      this.dateI18nKey = this.startDate.isSame(this.endDate, 'day')
        ? 'EVENT_DETAILS_ONE_DAY_DATE_VALUE'
        : 'EVENT_DETAILS_DATE_VALUE';

      this.dayOff = this.event.type ? this.event.type.contents.dayOff : false;
      const translateKey = this.dayOff
        ? 'EVENT_DAY_OFF_LABEL'
        : 'EVENT_DAY_ON_LABEL';

      this.label = this.$translate.instant(translateKey);

      if (this.event.assignees && this.event.assignees.length) {
        this.eventAssignees = this.getEventAssignees();
      }

      this.checklists = this.event.contents.checklists || [];
      this.campaigns = this.checklists.map(
        ({ _id, archived, ...contents }) => ({
          _id,
          contents,
          archived,
        })
      );

      this.corporateEvents = this.event.contents.corporateEvents.map(
        ({ _id, ...contents }) => ({ _id, contents })
      );
      this.showCorporateEvents =
        this.corporateEvents &&
        this.corporateEvents.length &&
        this.isFullCalendar;

      return this.$q
        .all([
          this.calendarEventsService.canEditOrDeleteEvent(this.event),
          this.eventChangeRequestsService.canRequestChange(),
          this.eventChangeRequestsService.canDelete(),
          this.event.contents.params ? this.buildParams() : null,
        ])
        .then(([canEditOrDelete, canRequestChange, canDelete]) => {
          this.canEditEvent = canEditOrDelete;
          this.canDeleteEvent =
            (canDelete || canEditOrDelete) && !canRequestChange;
          this.canRequestDelete = canEditOrDelete;
          this.canRequestChange = canRequestChange;
          if (canRequestChange) {
            this.getUserChangeRequest().then((changeRequest) => {
              this.changeRequest = changeRequest;
            });
          }
        });
    }

    buildParams(): ng.IPromise<void> {
      return this.$q
        .all([
          this.calendarEventsParamsService.listCalendarEventsParamsKeys(),
          this.calendarEventsParamsService.loadCategories(),
        ])
        .then(([params, categories]) => {
          this.categorizedParams =
            this.calendarEventsParamsService.getCategorizedParams(
              params,
              categories,
              this.event.contents.params
            );

          this.showNonEmptyParams();
        });
    }

    showNonEmptyParams(): void {
      this.paramsDisplayed = this.categorizedParams
        .map((catParams) => {
          const nonEmptyParams = catParams.params
            .filter((p) => p.value !== '')
            .map((p) => ({
              ...p,
              value: p.value.toString(),
            }));

          return {
            active: true,
            label: catParams.label,
            params: nonEmptyParams,
          };
        })
        .filter((catParams) => catParams.params.length);
    }

    getUserChangeRequest() {
      const { buildFilterParams } = this.apiUtilsService;

      return this.profileService
        .getProfile()
        .then(({ _id }) =>
          buildFilterParams([
            { name: 'event_id', value: this.event._id },
            { name: 'creator_id', value: _id },
            { name: 'state', value: 'pending' },
          ])
        )
        .then((filters) =>
          this.eventChangeRequestsService.getChangeRequests(filters)
        )
        .then(({ entries: [changeRequest] }) => changeRequest);
    }

    getEventAssignees() {
      const assignees = this.event.assignees;

      if (!assignees) {
        return [];
      }
      if (assignees.length === 1) {
        return assignees[0].contents.fullName;
      }
      return this.$translate.instant('EVENT_DETAILS_ASSIGNEES_VALUE', {
        name: assignees[0].contents.fullName,
        count: assignees.length - 1,
      });
    }

    openAssigneesModal() {
      const template = `
          <sf-events-assignees-modal
            assignees="$ctrl.assignees"
            on-close="$ctrl.onClose()">
          </sf-events-assignees-modal>
        `;

      return this.modalService.open(
        template,
        {
          assignees: this.event.assignees,
        },
        { hardwareBackButtonClose: false }
      );
    }

    openChecklistDetails(campaign) {
      const activationTime =
        this.campaignsUtilsService.getActivationTime(campaign);

      const archived = campaign.contents.state === 'deactivated';
      const outOfScope = campaign.contents.outOfScope;

      if (activationTime || archived || outOfScope) {
        return;
      }

      this.$state.go('index.menu-more.reactive-campaigns.details', {
        campaignId: campaign._id,
        referer: 'index.activity.details',
      });
      this.onClose();
    }

    cancelChangeRequest() {
      return this.eventChangeRequestsService.showDeleteAgreeModal().then(() =>
        this.eventChangeRequestsService
          .delete(this.changeRequest._id)
          .then(() =>
            this.appMessageService.display(
              this.$translate.instant('CHANGE_REQUESTS_DELETE_SUCCESS'),
              'success'
            )
          )
          .then(() => {
            delete this.changeRequest;
          })
          .catch(() =>
            this.appMessageService.display(
              this.$translate.instant('CHANGE_REQUESTS_DELETE_ERROR'),
              'fail'
            )
          )
      );
    }

    requestDeletion() {
      return this.eventChangeRequestsService
        .showCreateDeletionChangeRequestConfirmModal()
        .then(() => this.onClose())
        .then(() => this.onRequestDeletion());
    }

    goToPlace() {
      return this.onClose().then(() =>
        this.$state.go('index.places.details.main', {
          placeId: this.event.place._id,
        })
      );
    }
  },
};
