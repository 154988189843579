/*
 * Please note these languages are only available for form/checklist
 * translation. It has nothing to do with app/device languages.
 */

export const AVAILABLE_TRANSLATION_LANGUAGES = [
  { code: 'ar', label: 'Arabic - اَلْعَرَبِيَّةُ' },
  { code: 'bg', label: 'Bulgarian - български' },
  { code: 'zh-TW', label: 'Chinese traditional - 漢語' },
  { code: 'zh-CN', label: 'Chinese simplified - 汉语' },
  { code: 'hr', label: 'Croatian - Hrvatski' },
  { code: 'cs', label: 'Czech - Čeština' },
  { code: 'nl', label: 'Dutch - Nederlands' },
  { code: 'en', label: 'English - English' },
  { code: 'fr', label: 'French - Français' },
  { code: 'de', label: 'German - Deutsch' },
  { code: 'hu', label: 'Hungarian - Magyar' },
  { code: 'it', label: 'Italian - Italiano' },
  { code: 'ja', label: 'Japanese - 日本語' },
  { code: 'ko', label: 'Korean - 한국어' },
  { code: 'pl', label: 'Polish - Polski' },
  { code: 'pt', label: 'Portuguese - Português' },
  { code: 'ro', label: 'Romanian - Română' },
  { code: 'ru', label: 'Russian - Русский' },
  { code: 'sr', label: 'Serbian - Српски' },
  { code: 'sk', label: 'Slovak - Slovenský' },
  { code: 'sl', label: 'Slovenian - Slovenščina' },
  { code: 'es', label: 'Spanish - Español' },
  { code: 'th', label: 'Thai - ไทย' },
  { code: 'tr', label: 'Turkish - Türkçe' },
] as const;
