import { ManagerCalendarEventsListController } from './manager-calendar-events-list.controller';

export const ManagerCalendarEventsListComponent = {
  bindings: {
    events: '<',
    userTimezone: '<',
  },
  templateUrl:
    'calendar-events/components/manager-calendar/manager-calendar-events-list/manager-calendar-events-list.html',
  controller: ManagerCalendarEventsListController,
};
