import { APIStore } from '../../..';
import { FilterValue } from '../../../../analytics/services/sales-dashboard-filters-service/sales-dashboard-filters.service';

export const PlaceAnalyticsComponent: ng.IComponentOptions = {
  bindings: {
    place: '<',
  },
  templateUrl: 'places/place/components/place-analytics/place-analytics.html',
  controller: class PlaceAnalyticsController implements ng.IComponentController {
    /** Props */
    place: APIStore;

    /** Attributes */
    filters: Record<string, FilterValue[]> = {};

    $onInit(): void {
      this.filters = {
        places: [
          {
            _id: this.place._id,
            value: this.place._id,
            label: this.place.contents.name,
          },
        ],
      };
    }
  },
};
