import { forms as formsSchema } from '@simplifield/schema';
import { ReportsService } from '../../services/API/reports/reports.service';
import {
  PubSubService,
  UnregisterFn,
} from '../../services/Utils/PubSub/pubsub.service';
import { TabService } from '../../services/Utils/Tabs';
import { CampaignService } from './services/campaign.service';

const CAMPAIGN_VIEW_ANALYTICS = {
  campaignStats: 'Campaign Stats',
  campaignReports: 'Campaign Reports',
  campaignCompletion: 'Campaign Completion',
  campaignGallery: 'Campaign Gallery',
};

export const CampaignComponent = {
  bindings: {
    campaign: '<',
  },
  templateUrl: 'legacy-campaigns/campaign/campaign.html',
  // eslint-disable-next-line max-params
  controller: class CampaignController {
    formsSchema: any;
    galleryListFilter: { name: string; value: any }[];
    campaign: any;
    reportsListUrl: string;
    hideStatTab = true;
    hideCompletionTab: boolean;
    currentStickyHandle: any;
    stateChangeListener: UnregisterFn;

    /* @ngInject */
    // eslint-disable-next-line max-params
    constructor(
      private tabsService: TabService,
      private $timeout,
      private reportsService: ReportsService,
      private segmentService,
      private campaignService: CampaignService,
      private pubSubService: PubSubService
    ) {
      this.formsSchema = formsSchema;
    }

    $onInit() {
      this.galleryListFilter = [
        {
          name: 'campaign_title',
          value: this.campaign.contents.title,
        },
      ];
      this.reportsListUrl = `/forms/${this.campaign._id}${this.reportsService.basePath}`;
      this.hideStatTab = !this.formsSchema.hasScore(this.campaign.contents);
      this.hideCompletionTab = !this.campaignService.hasCompletionFeatureFlag();

      this.stateChangeListener = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
        (params: { name: string }) => {
          if (
            params.name === 'index.menu-more.campaigns.details' &&
            this.hideStatTab
          ) {
            this.$timeout(
              () => this.tabsService.select('campaignTabs', 1),
              300
            );
          }
        }
      );
    }

    $postLink() {
      if (this.hideStatTab) {
        this.$timeout(() => this.tabsService.select('campaignTabs', 1), 0);
      }
    }

    $onDestroy() {
      if (this.stateChangeListener) {
        this.stateChangeListener();
      }
    }

    onTabClick(tabName) {
      this.currentStickyHandle = tabName;

      if (CAMPAIGN_VIEW_ANALYTICS[tabName]) {
        this.segmentService.page(CAMPAIGN_VIEW_ANALYTICS[tabName]);
      }
    }
  },
};
