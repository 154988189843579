import { StateService } from '@uirouter/angularjs';
import { isNil, partition } from 'ramda';
import { APITask, Task, TaskStatus } from '../..';
import { ObjectId, User } from '../../..';
import { ContentService } from '../../../components/Layout/content/content.service';
import {
  FilterCustom,
  FilterSelected,
} from '../../../components/Search/search';
import { GroupTasksService } from '../../../group-tasks/services/group-tasks.service';
import { APIGroupTask, APIListGroupTasks } from '../../../group-tasks/types';
import { APIStore } from '../../../places';
import { TasksService } from '../../../services/API/tasks/tasks.service';
import { DateService } from '../../../services/Utils/Dates/date.service';
import { Modal, ModalService } from '../../../services/Utils/Modal';
import { TaskService } from '../../services/task.service';

const TASKS_FILTERS_KEY = 'tasksFilters';
const TASKS_PLACE_FILTER_KEY = 'store';
const TASKS_DATE_FILTER_KEY = 'due_date';
const TASKS_TIME_FILTER_KEY = 'due_time';
const TASKS_ASSIGNEE_FILTER_KEY = 'assignee';
const TASKS_PLACES_LIST_KEY = 'placesList';
const TASKS_USERS_GROUP_KEY = 'usersGroup';

const FILTERS_CONVERTION_MAP = {
  [TASKS_PLACE_FILTER_KEY]: 'place_id',
  [TASKS_DATE_FILTER_KEY]: 'due_date',
  [TASKS_TIME_FILTER_KEY]: 'due_time',
  [TASKS_ASSIGNEE_FILTER_KEY]: 'assignee_id',
  [TASKS_PLACES_LIST_KEY]: 'placeGroupId',
  [TASKS_USERS_GROUP_KEY]: 'teamId',
};

const HAS_NEW_TASK_PARAM_FILTERS = 'newTasksParamsFilters';
const RESULTS_LIMIT = 20;

type TasksRelatedEntities = {
  users: Record<ObjectId, User>;
  places: Record<ObjectId, APIStore>;
};

type TaskPagedData = {
  entities: APITask[];
  newEntities: APITask[];
  relatedEntities: TasksRelatedEntities;
};

type RequestFilter = {
  name: string;
  value: string;
};

export class TasksListController implements ng.IComponentController {
  // bindings
  profile: User;
  isPlace?: boolean;
  useGroupTasks = false;
  taskStatus: TaskStatus;
  reloadFn: () => void;
  onReload?: () => void;
  onScrollComplete?: () => void;
  contentKey: string;
  requestFilters: RequestFilter[] | null;

  //attributes
  requestPaginate: {
    call: (
      opts: Record<string, unknown>,
      flag: boolean
    ) => ng.IPromise<TaskPagedData>;
    reset: () => void;
    removeItem: (item: Task) => void;
    canCallMore: () => boolean;
  };
  datas: TasksRelatedEntities = {
    users: {},
    places: {},
  };
  tasks: APITask[] = [];
  taskSearch = '';
  networkError: boolean;
  infiniteLoadError: boolean;
  isLoading = true;
  isSearchLoading = false;
  searchError = false;
  nextTasks: APITask[];
  assignedTasks: APITask[] = [];
  unassignedTasks: APITask[] = [];
  // For values with ids we have to keep (user, store) both their label and id
  tasksFilters: (FilterSelected & { value_id?: ObjectId })[];
  filtersAvailable: FilterCustom[] = [];
  groupTasks: APIGroupTask[] | undefined;
  tabName: 'otherstasks' | 'mytasks';
  shouldSplitAssignedTasks = false;

  constructor(
    private $translate: ng.translate.ITranslateService,
    private contentService: ContentService,
    private localStorageService: ng.local.storage.ILocalStorageService,
    private modalService: ModalService,
    private tasksService: ReturnType<typeof TasksService>,
    private taskService: TaskService,
    private helpersService,
    private dateService: DateService,
    private RequestsPaginate,
    private groupTasksService: GroupTasksService,
    private $q: ng.IQService,
    private $state: StateService
  ) {
    'ngInject';

    this.updateDatasOnScroll = this.updateDatasOnScroll.bind(this);
    this.onTasksSearchChange = this.onTasksSearchChange.bind(this);
    this.getTasksOnScroll = this.getTasksOnScroll.bind(this);
    this.taskItemContentClick = this.taskItemContentClick.bind(this);
    this.openTaskManageModal = this.openTaskManageModal.bind(this);

    this.requestPaginate = new this.RequestsPaginate(
      this.tasksService.getTasks.bind(this.tasksService),
      {
        limit: RESULTS_LIMIT,
        relatedEntitiesKeys: ['organisations', 'users', 'places'],
      }
    );
  }

  $onInit(): ng.IPromise<void> {
    this.reloadFn = this.reload;
    this.setTasksFilters();
    this.shouldSplitAssignedTasks = this.tabName === 'mytasks';

    return this.callData().then(() => this.resetFlags());
  }

  setTasksFilters(): void {
    let savedFilters = [];

    // we need to delete old params only once after upgrade of the app with new params
    const hasNewFilters = this.localStorageService.get(
      HAS_NEW_TASK_PARAM_FILTERS
    );
    if (hasNewFilters) {
      savedFilters = this.localStorageService.get(TASKS_FILTERS_KEY) || [];
    } else {
      this.localStorageService.set(HAS_NEW_TASK_PARAM_FILTERS, true);
    }

    this.tasksFilters = savedFilters;
    this.filtersAvailable = [
      ...(!this.isPlace
        ? [
            {
              id: TASKS_PLACE_FILTER_KEY,
              type: 'place',
              label: this.$translate.instant('TASKS_PLACE_FILTER_LABEL'),
            },
          ]
        : []),
      {
        id: TASKS_PLACES_LIST_KEY,
        type: 'placesList',
        label: 'Store groups',
      },
      {
        id: TASKS_USERS_GROUP_KEY,
        type: 'usersGroup',
        label: 'Teams',
      },
      {
        id: TASKS_DATE_FILTER_KEY,
        type: 'date',
        label: this.$translate.instant('TASK_DETAILS_DUE_DATE_LABEL'),
      },
      {
        id: TASKS_TIME_FILTER_KEY,
        type: 'time',
        label: this.$translate.instant('TASKS_DUE_HOUR_FILTER_LABEL'),
      },
      ...(this.tabName === 'mytasks'
        ? []
        : [
            {
              id: TASKS_ASSIGNEE_FILTER_KEY,
              type: 'user',
              label: this.$translate.instant('TASKS_ASSIGNEE_FILTER_LABEL'),
            },
          ]),
    ];
  }

  isToDo = (): boolean => this.taskService.isToDo(this.taskStatus);
  isDone = (): boolean => this.taskService.isDone(this.taskStatus);

  reload = (): ng.IPromise<void> => {
    this.isLoading = true;
    this.tasks = [];
    this.splitAssignedTasks();
    this.requestPaginate.reset();

    return this.callData().then(() => this.resetFlags());
  };

  resetFlags = (): void => {
    this.isLoading = false;
    this.networkError = false;
  };

  onReloadButtonClicked = (): void => {
    this.reload();
    if (this.onReload) {
      this.onReload();
    }
  };

  callData = (): ng.IPromise<TaskPagedData> => {
    return this.$q
      .all([this.getTasks(), this.getGroupTasks()])
      .then(([task, groupedTasks]) => this.updateData(task, groupedTasks))
      .catch((err) => {
        this.networkError = true;
        this.isLoading = false;
        throw err;
      });
  };

  updateData(
    datas: TaskPagedData,
    groupTasks?: APIListGroupTasks
  ): TaskPagedData {
    this.datas = datas.relatedEntities;
    this.updateTasks(datas.entities, groupTasks?.entries);

    return datas;
  }

  updateTasks = (tasks: APITask[], groupTasks?: APIGroupTask[]): APITask[] => {
    this.tasks = tasks;
    this.splitAssignedTasks();
    this.groupTasks = groupTasks;

    const tasksTransformed = this.tasks.map((task) => ({
      ...task,
      due_date: this.helpersService.getDueDateTimezonedHtml5(
        this.profile,
        task.contents.due_date
      ),
    }));

    this.nextTasks = this.sortInsideTimeChunks(tasksTransformed);
    return tasks;
  };

  updateDatasOnScroll(
    datas: TaskPagedData,
    groupTasks?: APIListGroupTasks
  ): TaskPagedData {
    this.datas = datas.relatedEntities;
    this.tasks = this.tasks.concat(datas.newEntities);
    this.groupTasks = groupTasks?.length
      ? this.groupTasks?.concat(groupTasks?.entries)
      : this.groupTasks;
    this.updateTasks(this.tasks, this.groupTasks);
    return datas;
  }

  onTasksSearchChange(searchText: string): ng.IPromise<TaskPagedData> {
    const scrollTopOffset = 3;

    this.contentService.scrollTopById(this.contentKey, scrollTopOffset);
    this.isSearchLoading = true;
    this.taskSearch = searchText;
    this.requestPaginate.reset();
    this.tasks = [];
    this.splitAssignedTasks();

    return this.callData()
      .catch((err) => {
        this.networkError = true;
        throw err;
      })
      .finally(() => {
        this.isSearchLoading = false;
        this.isLoading = false;
      });
  }

  getTasksOnScroll(): ng.IPromise<TaskPagedData> {
    this.infiniteLoadError = false;

    return this.$q
      .all([this.getTasks(), this.getGroupTasks()])
      .then(([tasks, groupTasks]) =>
        this.updateDatasOnScroll(tasks, groupTasks)
      )
      .catch((err) => {
        this.infiniteLoadError = true;
        throw err;
      })
      .finally(() => {
        if (this.onScrollComplete) {
          this.onScrollComplete();
        }
      });
  }

  getTasks = (): ng.IPromise<TaskPagedData> => {
    const params = {
      search: this.taskSearch,
      requestFilters: [
        ...(this.requestFilters || []),
        ...(this.prepareFiltersForRequest(this.tasksFilters) || []),
      ],
      tab: this.tabName,
      limit: RESULTS_LIMIT,
      add_comments_count: 1,
    };

    return this.requestPaginate.call(params, true);
  };

  getGroupTasks(): ng.IPromise<APIListGroupTasks> {
    const params = {
      search: this.taskSearch,
      requestFilters: [
        ...(this.requestFilters || []),
        ...(this.prepareFiltersForRequest(this.tasksFilters) || []),
      ],
    };

    if (!this.useGroupTasks) {
      return this.$q.resolve({ entries: [], count: 0 });
    }
    return this.groupTasksService.get(params);
  }

  prepareFiltersForRequest(
    filters: (FilterSelected & { value_id?: ObjectId })[]
  ): RequestFilter[] {
    return filters
      .map((filter) => ({
        name: FILTERS_CONVERTION_MAP[filter.id],
        value: filter.value_id ?? filter.value,
      }))
      .filter(
        (requestFilter) => !isNil(requestFilter.value)
      ) as RequestFilter[];
  }

  onGroupTaskClick(event: Event, groupTask: APIGroupTask): void {
    event.preventDefault();

    if (this.groupTasks) {
      this.$state.go('index.menu-more.tasks.group', {
        taskId: groupTask._id,
        groupTask,
      });
    }
  }

  taskItemContentClick(
    event: Event,
    taskId: ObjectId
  ): ng.IPromise<{
    status?: TaskStatus;
    task: Task;
  }> {
    // the list is composed from item checkboxes
    // we need to prevent default behavior in order not to check/uncheck the checkbox
    event.preventDefault();

    return this.taskService
      .openTaskDetailsModal(taskId, this.profile, false)
      .then((status) => {
        this.reload();

        if (this.onReload) {
          this.onReload();
        }

        return status;
      });
  }

  sortInsideTimeChunks = (entities: APITask[]): APITask[] =>
    entities.sort((a, b) => {
      if (a.contents.due_date !== b.contents.due_date) {
        return this.dateService.compareDates(
          b.contents.due_date,
          a.contents.due_date
        );
      }

      const numbersAreDifferent = b.contents.priority - a.contents.priority;

      if (numbersAreDifferent) {
        return numbersAreDifferent;
      }

      return this.helpersService.sortNatural(a.contents.name, b.contents.name);
    });

  getTaskUser = (task: Task): User =>
    this.datas.users[task.contents.assignee_id as ObjectId];

  getTaskPlace = (task: Task): APIStore =>
    this.datas.places[task.contents.place_id as ObjectId];

  isAssignee = (assignee_id: ObjectId): boolean =>
    assignee_id === this.profile.id;

  openTaskManageModal(task: Task): void {
    this.taskService.openTaskManageModal(task, this.profile).then(() => {
      this.reload();
      if (this.onReload) {
        this.onReload();
      }
    });
  }

  openFiltersModal(): Modal {
    const template = `
      <sf-tasks-filters-modal
        profile="$ctrl.profile"
        values="$ctrl.values"
        filters-available="$ctrl.filtersAvailable"
        on-change="$ctrl.onChange(values)"
        on-close="$ctrl.onClose()">
      </sf-tasks-filters-modal>
    `;

    const bindings = {
      profile: this.profile,
      values: this.tasksFilters,
      filtersAvailable: this.filtersAvailable,
      onChange: (values: FilterSelected[]) => {
        this.onFilterChange(values);
      },
    };
    const options = {
      animation: 'slide-in-top',
    };

    return this.modalService.open(template, bindings, options);
  }

  onFilterChange(filters: FilterSelected[]): ng.IPromise<void> {
    this.tasksFilters = filters;
    this.isSearchLoading = true;
    this.searchError = false;

    this.localStorageService.set(TASKS_FILTERS_KEY, filters);
    return this.reload()
      .catch(() => {
        this.searchError = true;
      })
      .finally(() => {
        this.isSearchLoading = false;
      });
  }

  hasResult(): boolean {
    return !!(this.tasks.length || this.groupTasks?.length);
  }

  onGroupTaskDeleted(): void {
    this.reload();

    if (this.onReload) {
      this.onReload();
    }
  }
  splitAssignedTasks(): void {
    const [assignedTasks, unassignedTasks] = partition(
      (task: APITask): boolean => {
        return !!task.contents.assignee_id;
      },
      this.tasks
    );

    this.assignedTasks = assignedTasks;
    this.unassignedTasks = unassignedTasks;
  }
}
