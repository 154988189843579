const ERRORS = {
  OUT_OF_SPACE: 'OUT_OF_SPACE',
  UNSUPPORTED_FILE_TYPE: 'UNSUPPORTED_FILE_TYPE',
  LOCAL_FILE_NOT_FOUND: 'NOT_FOUND_ERR',
  FILE_NOT_FOUND: 2,
  NO_CONNECTION: 3,
  ABORT_DOWNLOAD: 4,
  CODES_SERVER_NO_NETWORK: [-1, 0],
  CODE_SERVER_BAD_REQUEST: 400,
  CODE_SERVER_UNAUTHORIZED: 401,
  CODE_SERVER_CONFLICT_REQUEST: 409,
  CODE_SERVER_NOT_FOUND: 404,
  CODE_SERVER_DEPRECATED: 417,
  CODE_SERVER_ERROR: 500,
};

export default angular
  .module('sfMobile.constants.error-codes', [])
  .constant('SF_ERROR_CODES', ERRORS).name;
