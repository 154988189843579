export type GestureTypes = 'hold' | 'tap' | 'release' | 'touch';
export type GestureOptions = Record<string, unknown>;

export type CreateGestureParams = {
  el: HTMLElement;
  events: {
    name: GestureTypes;
    options: GestureOptions;
    cb: (...args: any) => any;
  }[];
};

// I've needed a wrapper for ioniv v1 gestures https://ionicframework.com/docs/v1/api/directive/onHold/
// But wrote this service with ionic v5 in mind https://ionicframework.com/docs/utilities/gestures & https://angular.io/api/platform-browser/HammerGestureConfig
export class GesturesService {
  constructor(private $window: ng.IWindowService) {
    'ngInject';
  }

  createGesture(params: CreateGestureParams): void {
    const { el, events } = params;

    events.forEach(({ name, options, cb }) => {
      const gesture = new this.$window.ionic.Gesture(el, options);

      gesture.on(name, cb);
    });
  }
}
