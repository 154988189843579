import { IPromise } from 'q';
import { Task } from '..';
import { User } from '../..';
import { GroupTask } from '../../group-tasks/types';
import { LocalizationService } from '../../services/Utils/Localization/localization.service';
import { TaskService } from '../services/task.service';

export class TaskGroupTasksChoiceController implements ng.IComponentController {
  //bindings
  profile: User;
  onClose: () => void;
  onSave: () => ({ entry }) => IPromise<Task | void>;

  isRTLNeeded: boolean;
  simpleTaskButtonTitle: string;
  multipleTasksButtonTitle: string;

  HEADER_TITLE = 'TASK_GROUP_TASKS_CHOICE_HEADER_TITLE';

  /* @ngInject */
  constructor(
    private taskService: TaskService,
    private localizationService: LocalizationService,
    private $translate: ng.translate.ITranslateService
  ) {}

  $onInit(): void {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();

    this.simpleTaskButtonTitle = this.$translate.instant(
      'TASK_GROUP_TASKS_CHOICE_SIMPLE_TASK_TITLE'
    );
    this.multipleTasksButtonTitle = this.$translate.instant(
      'TASK_GROUP_TASKS_CHOICE_MULTIPLE_TASKS_TITLE'
    );
  }

  onSimpleTaskClick(): IPromise<void | { entry: Task }> {
    return this.taskService
      .openTaskManageModal({} as Task, this.profile)
      .then(({ managedTask }) => {
        return this.onSave()({ entry: managedTask }) as unknown as IPromise<{
          entry: Task;
        }>;
      });
  }

  onMultipleTasksClick(): IPromise<void | { entry: GroupTask }> {
    return this.taskService
      .openGroupTaskManageModal(this.profile)
      .then((res) => {
        return this.onSave()({ entry: res }) as unknown as IPromise<{
          entry: GroupTask;
        }>;
      });
  }
}
