import { FormsService } from './forms.service';

export default angular
  .module('sfMobile.services.forms', [
    'sfMobile.constants.image-sizes',
    'sfMobile.services.crud',
    'sfMobile.services.missions',
    'sfMobile.services.reports',
    'sfMobile.services.files',
    'sfMobile.services.file-system',
    'sfMobile.services.image-source',
    'sfMobile.services.image',
    'sfMobile.services.reportScoring',
    'sfMobile.config.database',
    'sfMobile.constants.error-codes',
  ])
  .factory('formsService', FormsService).name;
