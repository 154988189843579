const REPORTS_REQUEST_PARAMS = {
  localStatus: ['ready'],
};
const FORMS_REQ_PARAMS = {
  deleted: false,
};

export const MissionsListComponent = {
  bindings: {
    profile: '<',
  },
  templateUrl: 'missions/missions/components/missions-list/missions-list.html',
  controller: class MissionsListController {
    // eslint-disable-next-line max-params
    constructor(
      $q,
      pubSubService,
      missionsService,
      reportsService,
      formsService,
      helpersService,
      searchUtilsService
    ) {
      'ngInject';
      Object.assign(this, {
        $q,
        pubSubService,
        missionsService,
        reportsService,
        formsService,
        helpersService,
        searchUtilsService,
      });

      this.formListener = this.pubSubService.subscribe(
        'FORM_REPORT_UPDATED',
        () => this.callData()
      );
      this.dataListener = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
        () => this.callData()
      );
    }

    $onInit() {
      this.isLoading = true;
      this.forms = [];
      this.missionsForms = [];
      this.missionsSearch = '';

      return this.callData().finally(() => {
        this.isLoading = false;
      });
    }

    $onDestroy() {
      this.formListener();
      this.dataListener();
    }

    callData() {
      const promises = [
        this.reportsService.crud.queryLocal(REPORTS_REQUEST_PARAMS),
        this.missionsService.listLocal(),
        this.formsService.queryLocal(FORMS_REQ_PARAMS),
      ];

      return this.$q.all(promises).then((data) => this.updateDatas(data));
    }

    updateDatas([reports, missions, forms]) {
      // Get available forms
      const models = this.helpersService
        .getFormsAvailable(forms, this.profile._id)
        .sort((a, b) => this.alphabeticalOrder(a, b));
      const missionsSorted = this.helpersService.getMissionsNotStarted(
        missions,
        reports
      );
      const missionsForms = this.helpersService
        .getAvailableFormsOfMissions(forms, missionsSorted, reports)
        .sort((a, b) => this.alphabeticalOrder(a, b));

      this.forms = models;
      this.missionsForms = missionsForms;
      this.formsFiltered = models;
      this.missionsFormsFiltered = missionsForms;
      this.missionsHash =
        this.helpersService.getMissionsHashByFormId(missionsSorted);
    }

    onSearchChange() {
      const filterFn = this.getFilterFn(this.missionsSearch);

      this.missionsFormsFiltered = this.missionsSearch
        ? this.missionsForms.filter(filterFn)
        : [].concat(this.missionsForms);
      this.formsFiltered = this.missionsSearch
        ? this.forms.filter(filterFn)
        : [].concat(this.forms);
    }

    getFilterFn(search) {
      if (!search) {
        return () => true;
      }
      const regStr =
        this.searchUtilsService.getRegexStringWithDiacritics(search);
      const reg = this.searchUtilsService.getSearchRegex(regStr);

      return (form) => reg.test(form.contents.title);
    }

    hasMissions() {
      return [this.forms, this.missionsForms].some((l) =>
        this.arrIsNotEmpty(l)
      );
    }

    hasMissionsFiltered() {
      return [this.missionsFormsFiltered, this.formsFiltered].some((l) =>
        this.arrIsNotEmpty(l)
      );
    }

    arrIsNotEmpty(list) {
      return list && list.length;
    }

    getFormMissions(formId) {
      return this.missionsHash[formId] || [];
    }

    alphabeticalOrder(a, b) {
      return this.helpersService.sortNatural(
        a.contents.title,
        b.contents.title
      );
    }
  },
};
