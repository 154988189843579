import { PopupService } from './popup.service';
import { POPUP_CONSTANT } from './popup.constant';

export default angular
  .module('sfMobile.services.popup', [
    'ionic',
    'pascalprecht.translate',
    'sfMobile.services.popup',
    'sfMobile.components.popup-icon',
    'sfMobile.components.popup-progress',
    'sfMobile.components.popup-download',
    'sfMobile.components.popup-confirm',
    'sfMobile.components.popup-prompt',
    'sfMobile.components.popup-select',
  ])
  .service('popupService', PopupService)
  .constant('popupConstant', POPUP_CONSTANT).name;
