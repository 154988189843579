import { Keyboard } from '@capacitor/keyboard';

export const KEYBOARD_MODES = {
  IDLE: '',
  NATIVE: 'native',
  BODY: 'body',
};

export function KeyboardService(
  $window,
  $document,
  pubSubService,
  platformService
) {
  'ngInject';
  const hasKeyboard = platformService.isPluginAvailable('Keyboard');
  const methods = {
    _modeStates: [],
    _currentMode: [],

    init,
    hide: () => hasKeyboard && Keyboard.hide(),
    onKeyboardShowListener,
    onKeyboardHideListener,
    registerStateMode,
    deRegisterStateMode,
    changeKeyboardResizeMode,
    showAccessoryBar: (value) =>
      hasKeyboard &&
      Keyboard.setAccessoryBarVisible({
        isVisible: value,
      }).catch(() => null), // not implemented for Android,
    setResizeMode,
  };

  function init() {
    // Always close the keyboard on view mode change.
    pubSubService.subscribe(
      pubSubService.GLOBAL_EVENTS.STATE_CHANGE_START,
      () => {
        methods.hide();
      }
    );
    pubSubService.subscribe('ON_MODAL_CLOSE', () => {
      methods.hide();
    });
    $window.addEventListener('keyboardWillShow', () => {
      $document[0].body.classList.add('keyboard-open');
    });
    $window.addEventListener('keyboardWillHide', () => {
      $document[0].body.classList.remove('keyboard-open');
    });
  }

  function changeKeyboardResizeMode(mode) {
    if (mode === methods._currentMode) {
      return false;
    }

    methods.setResizeMode(mode);
    methods._currentMode = mode;
    return true;
  }

  function setResizeMode(mode) {
    hasKeyboard && Keyboard.setResizeMode({ mode }).catch(() => null); // not implemented for Android
  }

  function registerStateMode(keyboardMode = KEYBOARD_MODES.NATIVE) {
    methods.changeKeyboardResizeMode(keyboardMode);
    return true;
  }

  function deRegisterStateMode() {
    methods.changeKeyboardResizeMode(KEYBOARD_MODES.IDLE);
  }

  function onKeyboardShowListener(callback) {
    $window.addEventListener('keyboardDidShow', () => callback());
    return () => $window.removeEventListener('keyboardDidShow', callback);
  }

  function onKeyboardHideListener(callback) {
    $window.addEventListener('keyboardWillHide', () => callback());
    return () => $window.removeEventListener('keyboardWillHide', callback);
  }

  return methods;
}
