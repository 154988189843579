const STATE_NAME = 'index.reports.validation';
const STATE_TABS = ['torevise', 'pending', 'validated'];

const reportsValidationTabsComponent = {
  bindings: {
    profile: '<',
    organisation: '<',
  },
  controller: ReportsValidationController,
  templateUrl:
    'reports/' +
    'reports-validation/' +
    'components/' +
    'reports-validation-tabs/' +
    'reports-validation-tabs.html',
};

// eslint-disable-next-line max-params
function ReportsValidationController(
  $q,
  contentService,
  pubSubService,
  tabsService,
  $timeout,
  reportsValidationAPIService
) {
  'ngInject';
  const stateChangeListener = pubSubService.subscribe(
    pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
    (params) => {
      if (STATE_NAME === params.name) {
        $timeout(() => {
          const index = tabsService.getSelectedIndex('reportsValidationTabs');

          this.reload(STATE_TABS[index]);
        }, 0);
      }
    }
  );

  this.$onInit = () => {
    this.validatedCount = 0;
    this.toreviseCount = 0;
    this.pendingCount = 0;

    reportsValidationAPIService.updateToReviseCounts().catch(() => null);

    return this.reload('torevise');
  };

  this.$onDestroy = () => {
    stateChangeListener();
  };

  this.reload = (state) =>
    this.reloadSelectedTab(state).then(() => {
      contentService.scrollTopById('reportsValidationTabsScroll');
    });

  this.reloadSelectedTab = (state) => {
    const funcReferences = {
      torevise: this.reloadToReviseList || $q.reject,
      pending: this.reloadPendingList || $q.reject,
      validated: this.reloadValidatedList || $q.reject,
    };

    return funcReferences[state] ? funcReferences[state]() : $q.reject();
  };
}

export default reportsValidationTabsComponent;
export { ReportsValidationController };
