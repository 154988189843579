import { FilesService } from './files.service';

export default angular
  .module('sfMobile.services.files', [
    'sfMobile.services.profile',
    'sfMobile.services.file-system',
    'sfMobile.services.objectId',
    'sfMobile.services.pov',
  ])
  .service('filesService', FilesService).name;
