'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var findByName = function findByName(flags, name) {
  return flags.filter(function (f) {
    return f.name === name;
  })[0];
};
var hasFlag = function hasFlag(flags, name) {
  return {}.undef !== findByName(flags, name);
};

var SfFeatureFlags = function () {
  function SfFeatureFlags() {
    _classCallCheck(this, SfFeatureFlags);

    this._flags = [];
  }

  _createClass(SfFeatureFlags, [{
    key: 'setFlags',
    value: function setFlags(flags) {
      this._flags = flags;
      return this._flags;
    }
  }, {
    key: 'hasFeature',
    value: function hasFeature(flagName) {
      var reverse = /^!(.*)/.test(flagName);

      flagName = reverse ? flagName.substring(1) : flagName;
      var res = hasFlag(this._flags, flagName) && 'on' === findByName(this._flags, flagName).value;

      return reverse ? !res : res;
    }
  }]);

  return SfFeatureFlags;
}();

exports.default = SfFeatureFlags;