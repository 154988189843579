import { DownloadFileOpts, FilesService } from '../../API/files/files.service';
import { AwakeService } from '../Awake/awake.service';
import { ImageSource } from '../ImageSource/image-source.service';
import { DownloadPopup, PopupService } from '../Popup/popup.service';
import { FileSaver } from '@simplifield/capacitor-filesaver';
import { ErrorMessagesService } from '../ErrorMessages/error-messages.service';

const POPUP_SUCCESS_TIMEOUT_MS = 600;

export class DocumentsDownloadService {
  FileSaver = FileSaver;

  constructor(
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private filesService: FilesService,
    private popupService: PopupService,
    private awakeFactory: AwakeService,
    private errorMessagesService: ErrorMessagesService,
    private $timeout: ng.ITimeoutService
  ) {
    'ngInject';
  }

  download(
    filePath: string | DownloadFileOpts,
    file: ImageSource,
    config: { isPopupsShow?: boolean; onSuccessMessage?: string } = {}
  ): ng.IPromise<string> {
    const cancelPromise = this.$q.defer<void>();
    const downloadPopup = config.isPopupsShow
      ? this.showDownloadingPopup(() => cancelPromise.resolve())
      : null;
    const onProgress = (progress) => {
      if (!downloadPopup) {
        return;
      }

      const ratio = 100 / progress.total;

      downloadPopup.onProgress(progress.loaded * ratio);
    };
    const onSuccess = () => {
      if (!downloadPopup) {
        return this.$q.when();
      }

      return downloadPopup.onSuccess(
        config.onSuccessMessage
          ? config.onSuccessMessage
          : this.$translate.instant('DOCUMENTS_DOWNLOAD_SUCCESS')
      );
    };
    const onError = (err) => {
      if (!downloadPopup) {
        return;
      }

      const errorCode = err ? err.code : null;
      const errorOptions =
        this.errorMessagesService.getFileErrorMessageConfig(errorCode);

      downloadPopup.onError(errorOptions, () => {});
    };

    this.awakeFactory.keepAwake();
    return this.filesService
      .download(filePath, file, onProgress, cancelPromise.promise)
      .then((path) => onSuccess().then(() => path))
      .catch((err) => {
        onError(err);
        throw err;
      })
      .finally(() => {
        this.awakeFactory.allowSleepAgain();
      });
  }

  private showDownloadingPopup(onCancel: () => void): DownloadPopup {
    const downloadPopupOptions = {
      title: this.$translate.instant('DOCUMENTS_DOWNLOAD_DOWNLOADING_TITLE'),
      desc: this.$translate.instant('DOCUMENTS_DOWNLOAD_DOWNLOADING_DESC'),
    };

    return this.popupService.showDownloading(downloadPopupOptions, onCancel);
  }

  downloadOnAndroid(url: string, fileName: string): ng.IPromise<void> {
    return this.FileSaver.saveToDownloads({ url, fileName })
      .then(() => {
        const popup = this.popupService.showSuccess(
          this.$translate.instant('POPUP_DOWNLOAD_STARTED')
        );
        this.$timeout(popup.close, POPUP_SUCCESS_TIMEOUT_MS);
      })
      .catch(() =>
        this.popupService.showError({
          title: this.$translate.instant(
            'POPUP_DOWNLOAD_PERMISSION_ERROR_TITLE'
          ),
        })
      );
  }
}
