"use strict";

var errorsLibrary = require('../utils/errors');

var visitsLibrary = {
  validate: visitsLibraryValidate
}; // One liner compilation to avoid lib functions scope pollution

visitsLibrary.validateSchema = new (require('ajv'))().compile(require('./visit.schema')); // eslint-disable-line

module.exports = visitsLibrary;

function visitsLibraryValidate(visit) {
  var errors = errorsLibrary.mapAjvErrors(visitsLibrary.validateSchema(visit) ? [] : visitsLibrary.validateSchema.errors);
  return errors.length ? errors : validateDuration(visit);
}

function validateDuration(visit) {
  if (!visit.allDayLong && !visit.duration) {
    return [{
      code: 'E_REQUIRED',
      params: ['.duration']
    }];
  }

  if (visit.allDayLong && visit.duration) {
    return [{
      code: 'E_UNEXPECTED_CONTENT',
      params: ['.duration']
    }];
  }

  return [];
}