import { CommentsFileService } from 'app/comments/services/comments-file/comments-file.service';
import { isNil, lensPath, pathOr, set } from 'ramda';
import { ObjectId, User, UserHash } from '../../..';
import { Comment } from '../../../comments';
import type { ContentService } from '../../../components/Layout/content/content.service';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import type { FilesService } from '../../../services/API/files/files.service';
import { LinkPreviewService } from '../../../services/API/link-preview/link-preview.service';
import { ErrorMessagesService } from '../../../services/Utils/ErrorMessages/error-messages.service';
import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';
import type { RouterService } from '../../../services/Utils/Router/router.service';
import { NewsfeedApiService } from '../../services/newsfeed-api/newsfeed-api.service';
import { NewsfeedPopupService } from '../../services/newsfeed-popup/newsfeed-popup.service';
import { NewsfeedService } from '../../services/newsfeed-service/newsfeed.service';
import { NewsfeedPost, ResourcePreview } from '../../types';

export const NewsfeedPostDetailsComponent = {
  bindings: {
    profile: '<',
  },
  templateUrl:
    'newsfeed/views/newsfeed-post-details/newsfeed-post-details.html',
  controller: class NewsfeedPostDetailsComponent
    implements ng.IComponentController
  {
    profile: User;
    COMMENT_MAX_SIZE = 1000;
    contentKey = 'postCommentsScroll';
    commentsCount = 0;
    commentField = '';
    fallbackLink = 'index.newsfeed.list';
    pictureUrls: ResourcePreview[] = [];
    documents: ResourcePreview[] = [];
    videos: ResourcePreview[] = [];
    onPostUpdateListener: () => void;
    newsfeedPost: NewsfeedPost;
    comments: Comment[];
    commentsUser: UserHash;
    editableComment?: Comment;
    editCommentContent: string;
    isEditCommentFieldDisplayed: boolean;
    isLoading: boolean;
    isCommentsLoading: boolean;
    hasLoadingError: boolean;
    hasCommentsError: boolean;
    isSending: boolean;
    hasAlphaFlag: boolean;
    onLinkPreviewClick: (event: Event) => void;
    areCommentsDisabled = true;

    // eslint-disable-next-line max-params
    constructor(
      private $q: ng.IQService,
      private pubSubService: PubSubService,
      private $element: ng.IRootElementService,
      private $timeout: ng.ITimeoutService,
      private $stateParams: {
        postId: string;
        isScrollToComments: string;
        isInputFocused: string;
      },
      private newsfeedService: NewsfeedService,
      private newsfeedApiService: NewsfeedApiService,
      private contentService: ContentService,
      private objectIdService: ObjectIdService,
      private newsfeedPopupService: NewsfeedPopupService,
      private routerService: RouterService,
      private errorMessagesService: ErrorMessagesService,
      private filesService: FilesService,
      private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
      private linkPreviewService: LinkPreviewService,
      private commentsFileService: CommentsFileService,
      private keyboardService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<void> {
      this.hasAlphaFlag = this.newsfeedService.hasAlphaFeatureFlag();

      this.onPostUpdateListener = this.pubSubService.subscribe<{
        post: NewsfeedPost;
      }>('NEWSFEED_POST_UPDATED', ({ post }) => this.onUpdatePost(post));

      return this.loadDataView().then(() => {
        if (!this.newsfeedPost.linkPreview?.origin) {
          return;
        }
        this.onLinkPreviewClick = this.linkPreviewService
          .onLinkPreviewClick(this.newsfeedPost.linkPreview.origin)
          .bind(this);
      });
    }

    $onDestroy(): void {
      this.onPostUpdateListener();
    }

    closeInputKeyboard() {
      this.keyboardService.hide();
    }

    loadDataView(): ng.IPromise<any> {
      this.isLoading = true;
      this.hasLoadingError = false;

      return this.newsfeedApiService
        .get(this.$stateParams.postId)
        .then((newsfeedPost) => {
          this.newsfeedPost = newsfeedPost;
          return newsfeedPost;
        })
        .catch((err) => {
          this.hasLoadingError = true;

          const status = pathOr(undefined, ['status'], err);

          if (this.errorMessagesService.isNotFound(status)) {
            this.newsfeedPopupService.showPostNotExistsError(() => {
              this.routerService.goBack(this.fallbackLink);
              this.pubSubService.publish('RELOAD_NEWSFEED_POSTS_VIEW');
            });
          }
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
        })
        .then(() =>
          this.$q.all([this.getComments(), this.getAttachedResources()])
        );
    }

    onCommentsLoaded(): void {
      this.$stateParams.isScrollToComments === 'true' &&
        this.scrollToFirstComment();
      this.$stateParams.isInputFocused === 'true' && this.focusOnCommentInput();
    }

    onAddComment(): void {
      this.editableComment = undefined;
      this.isEditCommentFieldDisplayed = false;

      this.focusOnCommentInput();
    }

    cancelEditComment() {
      this.editableComment = undefined;
      this.isEditCommentFieldDisplayed = false;

      this.closeInputKeyboard();
    }

    focusOnCommentInput(): void {
      const selectors = this.isEditCommentFieldDisplayed
        ? '.sf_newsfeed__post__feedback__comments__footer__edit #sf_footer_text_input__input_id'
        : '.sf_newsfeed__post__feedback__comments__footer__add #sf_footer_text_input__input_id';

      const input = this.$element[0].querySelector(selectors) as HTMLElement;

      if (input) {
        this.$timeout(() => input.focus(), 0);
      }
    }

    onUpdatePost(post: NewsfeedPost): boolean {
      if (this.newsfeedPost._id !== post._id) {
        return false;
      }
      this.newsfeedPost = post;
      return true;
    }

    onCommentsCountChanged(): void {
      if (isNil(this.commentsCount)) {
        return;
      }
      const path = ['contents', 'commentsCount'];
      const commentsCountLens = lensPath(path);
      const post = set(
        commentsCountLens,
        this.commentsCount,
        this.newsfeedPost
      );

      this.pubSubService.publish('NEWSFEED_POST_UPDATED', { post });
    }

    getComments(): ng.IPromise<Comment[]> {
      this.isCommentsLoading = true;
      this.hasCommentsError = false;
      this.comments = [];
      this.commentsUser = {};

      return this.newsfeedService.areCommentsDisabled().then((disabled) => {
        this.areCommentsDisabled = disabled;

        return !disabled
          ? this.newsfeedService.commentsService
              .list(this.newsfeedPost._id)
              .then(({ entries, users, count = 0 }) => {
                return this.updateComments(entries, users, count);
              })
              .catch((err) => {
                this.hasCommentsError = true;

                throw err;
              })
              .finally(() => {
                this.isCommentsLoading = false;
                this.$timeout(() => this.onCommentsLoaded(), 0);
              })
          : this.$q.resolve([]);
      });
    }

    getAttachedResources(): ng.IPromise<any> {
      if (
        !(this.newsfeedPost && this.newsfeedPost.contents.attachedResources)
      ) {
        return this.$q.resolve([[]]);
      }

      return this.newsfeedService
        .buildAttachedResourcesObjects(
          this.newsfeedPost.contents.attachedResources,
          this.SF_IMAGE_SIZES.SQUARE_BIG
        )
        .then(({ images, documents, videos }) => {
          this.pictureUrls = images;
          this.documents = documents;
          this.videos = videos;
        });
    }

    // ------------------
    //
    //   UPDATE METHODS
    //
    // ------------------
    updateComments(
      comments: Comment[],
      users: UserHash,
      count: number
    ): Comment[] {
      this.comments = comments.concat(this.comments);
      this.commentsCount = this.commentsCount + count;
      const newCommentUsers = Object.keys(users).reduce((obj, userId) => {
        obj[userId] = {
          _id: userId,
          contents: users[userId],
        };
        return obj;
      }, {});

      this.commentsUser = {
        ...this.commentsUser,
        ...newCommentUsers,
      };
      return comments;
    }

    /**
     * Add a comment
     * @return {Promise} - Comment
     * @this NewsfeedPostCommentsConstructor
     */
    postComment(mediaFileId: ObjectId | null = null, type): ng.IPromise<void> {
      const errorTitle = 'NEWSFEED_CREATE_ERROR_TITLE';
      let newComment;
      if (mediaFileId && type) {
        newComment = this.getNewComment(mediaFileId, type);
      } else {
        newComment = this.getNewComment();
      }

      this.isSending = true;

      return this.newsfeedService.commentsService
        .create(this.newsfeedPost._id, newComment)
        .then((comment) => {
          if (!mediaFileId) {
            this.commentField = '';
          }

          this.updateComments([comment], {}, 1);
          this.onCommentsCountChanged();
          this.scrollToFirstComment();
        })
        .catch(() => {
          this.newsfeedPopupService.showError(errorTitle);
        })
        .finally(() => {
          this.isSending = false;
        });
    }

    getNewComment(mediaFileId?: ObjectId, type?: string) {
      if (mediaFileId && type) {
        return this.newsfeedService.commentsService.buildComment(
          this.profile,
          '',
          { post_id: this.newsfeedPost._id },
          mediaFileId,
          type
        );
      }
      return this.newsfeedService.commentsService.buildComment(
        this.profile,
        this.commentField,
        { post_id: this.newsfeedPost._id }
      );
    }

    scrollToFirstComment(): void {
      const content = this.$element[0].querySelector('#sf_newsfeed__post-area');
      const pageHeader = this.$element[0].querySelector('.sf_header_title');
      const contentHeight = content?.clientHeight ?? 0;
      const pageHeaderHeight = pageHeader?.clientHeight ?? 0;
      const y = contentHeight - pageHeaderHeight;

      this.contentService.scrollTopById(this.contentKey, y);
    }

    onDeleteComment(commentId: string): ng.IPromise<void> {
      const title = 'NEWSFEED_DELETE_COMMENT_CONFIRM_TITLE';
      const text = 'NEWSFEED_DELETE_COMMENT_CONFIRM_BUTTON';
      const errorTitle = 'NEWSFEED_DELETE_ERROR_TITLE';

      if (!this.comments.find((comment) => comment._id === commentId)) {
        return this.newsfeedPopupService.showError(errorTitle);
      }

      const oldComments = [...this.comments];
      const oldCommentsCount = this.commentsCount;

      return this.newsfeedPopupService
        .showDeleteConfirm(title, text)
        .then(
          () => {
            this.comments = this.comments.filter(
              (comment) => comment._id !== commentId
            );
            this.commentsCount = this.commentsCount - 1;

            return this.newsfeedService.commentsService
              .delete(this.newsfeedPost._id, commentId)
              .then(() => this.onCommentsCountChanged());
          },
          () => {} // catch Cancel event
        )
        .catch(() => {
          this.newsfeedPopupService.showError(errorTitle);
          this.comments = oldComments;
          this.commentsCount = oldCommentsCount;
        });
    }

    onEditCommentClick(commentId: string): void {
      const errorTitle = 'NEWSFEED_EDIT_ERROR_TITLE';

      this.editableComment = this.comments.find(
        (comment) => comment._id === commentId
      );
      if (!this.editableComment) {
        this.newsfeedPopupService.showError(errorTitle);
        return;
      }

      this.editCommentContent = this.editableComment.contents.content;
      this.isEditCommentFieldDisplayed = true;
      this.commentField = '';

      this.focusOnCommentInput();
    }

    updateComment(): ng.IPromise<void> {
      const errorTitle = 'NEWSFEED_EDIT_ERROR_TITLE';

      if (!this.editableComment) {
        return this.newsfeedPopupService.showError(errorTitle);
      }
      const index = this.comments.findIndex(
        (el) => el._id === this.editableComment?._id
      );

      if (index === -1) {
        return this.newsfeedPopupService.showError(errorTitle);
      }

      const updatedComment = {
        ...this.editableComment,
        contents: {
          ...this.editableComment.contents,
          content: this.editCommentContent,
        },
      };

      return this.newsfeedService.commentsService
        .edit(this.newsfeedPost._id, updatedComment)
        .then(() => {
          this.comments.splice(index, 1, updatedComment);
        })
        .catch((error) => {
          this.newsfeedPopupService.showError(errorTitle);
        })
        .finally(() => {
          this.editCommentContent = '';
          this.isEditCommentFieldDisplayed = false;
          this.editableComment = undefined;
        });
    }

    postMediaComment(fileBlob): ng.IPromise<void> {
      const type = this.newsfeedService.getResourceTypeFromMimeType(
        fileBlob.type
      );

      const validFiles = this.commentsFileService.getValidFromSelectedFiles(
        [fileBlob],
        type
      );

      if (!validFiles.length) {
        return this.$q.resolve();
      }

      const mediaFileId = this.objectIdService.create();
      const cancelPromise = this.$q.defer<void>();
      const onSendCancel = () => {
        cancelPromise.resolve();
        popupSending.clear();
      };

      const popupSending =
        this.newsfeedPopupService.showPictureCommentSendingProgress(
          onSendCancel
        );

      return this.filesService
        .upload(fileBlob, mediaFileId, {
          canceler: cancelPromise,
          fileNameOverride: type === 'document' ? fileBlob.name : undefined,
        })
        .then(() => this.postComment(mediaFileId, type))
        .then(() => popupSending.onSuccess())
        .catch(() => popupSending.onError());
    }
  },
};
