import { TSFixMe } from 'app';
import { fabric } from 'fabric';

export class CanvasEditionService {
  canvas: fabric.Canvas;

  constructor(private $q: ng.IQService) {
    'ngInject';
  }

  init(elementIdentifier: string): CanvasEditionService {
    this.canvas = new fabric.Canvas(elementIdentifier);
    return this;
  }

  dispose(): void {
    this.canvas && this.canvas.dispose();
  }

  /** Canvas **/

  /**
   * Register a callback on event
   * @param  {String} eventKey   - Event key name
   * @param  {Function} callback - Callback to call on event triggered
   * @return {Self}              - Event listener
   * @this CanvasEditionInstance
   */
  addEventListener(
    eventKey: string,
    callback: () => void
  ): fabric.StaticCanvas {
    return this.canvas.on(eventKey, callback);
  }

  /**
   * Unregister listener on event
   * @param  {String} eventKey - Event key name
   * @return {Self}            - Event Listener
   * @this CanvasEditionInstance
   */
  removeEventListener(eventKey: string): fabric.StaticCanvas {
    return this.canvas.off(eventKey);
  }

  /**
   * Enable or disable selection of multiple element at the same time
   * @param  {Boolean} selectable  - Selection config value
   * @return {Canvas}              - Canvas instance
   * @this CanvasEditionInstance
   */
  enableCanvasMultipleSelection(selectable: boolean): fabric.Canvas {
    fabric.Canvas.prototype.selection = selectable;
    return this.canvas;
  }

  /**
   * Enable or disable free drawing on canvas (Pen tool)
   * @param  {Boolean} enabled  - Config value
   * @return {Canvas}           - Canvas instance
   * @this CanvasEditionInstance
   */
  enableCanvasDrawingMode(enabled: boolean): fabric.Canvas {
    this.canvas.isDrawingMode = enabled;
    return this.canvas;
  }

  /**
   * Render canvas elements
   * @return {Canvas}           - Canvas instance
   * @this CanvasEditionInstance
   */
  renderCanvas(): fabric.Canvas {
    return this.canvas.renderAll();
  }

  /**
   * Check if objects have been added to the canvas
   * @return {Boolean}          - Is true if object have been added
   * @this CanvasEditionInstance
   */
  hasCanvasObject(): boolean {
    return Boolean(this.canvas && this.canvas._objects.length);
  }

  /**
   * Delete the last object that have been added to canvas (FILO)
   * @return {Object}          - Deleted FabricJS object
   * @this CanvasEditionInstance
   */
  removeLastAddedObject(): fabric.Object | undefined {
    return this.canvas._objects.pop();
  }

  /**
   * Set new dimensions to the canvas
   * @param  {Number} width  - New canvas width
   * @param  {Number} height - New canvas height
   * @return {Canvas}        - Canvas instance
   * @this CanvasEditionInstance
   */
  setCanvasDimensions(
    width: string | number,
    height: string | number
  ): fabric.Canvas {
    this.canvas.setWidth(width);
    this.canvas.setHeight(height);
    return this.canvas;
  }

  /**
   * Set the background of the canvas with the specified image
   * @param  {String} imgUrl     - Url of image to set
   * @param  {Function} callback - to call on set success
   * @return {Canvas}            - Canvas instance
   * @this CanvasEditionInstance
   */
  setBackgroundImage(
    imgUrl: string,
    callback: (img: { width: number; height: number }) => void
  ): fabric.Canvas {
    return this.canvas.setBackgroundImage(imgUrl, callback, {
      crossOrigin: 'anonymous',
    });
  }

  /**
   * Scale the image without deforming by it width
   * @param  {Number} width - Width to scale to
   * @return {Canvas}       - Canvas instance
   * @this CanvasEditionInstance
   */
  setBackgroundImageWidth(width: number): fabric.Canvas {
    (this.canvas?.backgroundImage as fabric.Image)?.scaleToWidth(width);
    return this.canvas;
  }

  /**
   * Scale the image without deforming by it height
   * @param  {Number} height - Height to scale to
   * @return {Canvas}        - Canvas instance
   * @this CanvasEditionInstance
   */
  setBackgroundImageHeight(height: number): fabric.Canvas {
    (this.canvas?.backgroundImage as fabric.Image)?.scaleToHeight(height);
    return this.canvas;
  }

  /**
   * Get the currently selected object of canvas
   * @return {Object}        - FabricJS object instance
   * @this CanvasEditionInstance
   */
  getCanvasSelectedObject(): TSFixMe {
    return this.canvas.getActiveObject();
  }

  /**
   * Unselect the currently selected object of canvas
   * @return {Canvas}        - Canvas instance
   * @this CanvasEditionInstance
   */
  unselectCanvasObject(): fabric.Canvas {
    return this.canvas.discardActiveObject();
  }

  getCanvasAsDataUrl(ratio: number): string {
    const dataURL = this.canvas.toDataURL({
      format: 'jpeg',
      quality: 1,
      multiplier: 1 / ratio,
    });

    return dataURL;
  }

  /** FabricJS Object functions **/

  /**
   * Return the currently selected object type
   * @return {String}        - Object FabricJS type
   * @this CanvasEditionInstance
   */
  getSelectedObjectType(): string | undefined {
    const selectedObject = this.canvas && this.getCanvasSelectedObject();

    return selectedObject && selectedObject.get('type');
  }

  /**
   * Return the currently selected object color
   * @return {String}        - Object color code
   * @this CanvasEditionInstance
   */
  getSelectedObjectColor():
    | string
    | fabric.Gradient
    | fabric.Pattern
    | undefined {
    const selectedObject = this.canvas && this.getCanvasSelectedObject();

    return selectedObject && selectedObject.get('fill');
  }

  /**
   * Set the currently selected object color
   * @param {String} color   - Color code
   * @return {Object}        - Selected object
   * @this CanvasEditionInstance
   */
  setSelectedObjectColor(color: string): fabric.Object {
    const selectedObject = this.canvas && this.getCanvasSelectedObject();

    return selectedObject && selectedObject.set('fill', color);
  }

  /** Pen functions **/

  /**
   * Enable or disable the selection of path (Pen tool)
   * @param {Boolean} selectable   - Config value
   * @return {Canvas}              - Canvas instance
   * @this CanvasEditionInstance
   */
  enablePathSelection(selectable: boolean): fabric.Canvas {
    fabric.Path.prototype.selectable = selectable;
    return this.canvas;
  }

  /**
   * Set the pen tool color
   * @param {String} color   - Color code to set
   * @return {Canvas}        - Canvas instance
   * @this CanvasEditionInstance
   */
  setPenColor(color: string): fabric.Canvas {
    this.canvas.freeDrawingBrush.color = color;
    return this.canvas;
  }

  /**
   * Set the pen tool size
   * @param {Number} size    - Size in pixel to set
   * @return {Canvas}        - Canvas instance
   * @this CanvasEditionInstance
   */
  setPenSize(size: number): fabric.Canvas {
    this.canvas.freeDrawingBrush.width = size;
    return this.canvas;
  }

  /** Text functions **/

  /**
   * Add a text to canvas and center it
   * @param {String} text    - Text to add
   * @param {Object} config  - FabricJS text config
   * @return {Object}        - FabricJS text object
   * @this CanvasEditionInstance
   */
  addText(text: string, config: fabric.ITextOptions): fabric.Object {
    const textObject = new fabric.IText(text, config);

    this.canvas.add(textObject);
    return textObject.center();
  }

  /**
   * Set text tool color
   * @param {String} color   - Color code to set
   * @return {Canvas}        - Canvas instance
   * @this CanvasEditionInstance
   */
  setTextColor(color: string): fabric.Canvas {
    // eslint-disable-next-line no-restricted-properties
    fabric.IText.prototype.fill = color;
    return this.canvas;
  }

  /**
   * Set text tool control corners visibility
   * @param {Object} visibilityConfig  - Config object
   * @return {Canvas}                  - Canvas instance
   * @this CanvasEditionInstance
   */
  setTextControlsVisibility(visibilityConfig: {
    bl?: boolean | undefined;
    br?: boolean | undefined;
    mb?: boolean | undefined;
    ml?: boolean | undefined;
    mr?: boolean | undefined;
    mt?: boolean | undefined;
    tl?: boolean | undefined;
    tr?: boolean | undefined;
    mtr?: boolean | undefined;
  }): fabric.Canvas {
    fabric.IText.prototype.setControlsVisibility(visibilityConfig);
    return this.canvas;
  }

  /**
   * Enable or disable cursor in text elements
   * @param {Boolean} editable  - Config value
   * @return {Canvas}           - Canvas instance
   * @this CanvasEditionInstance
   */
  enableTextEdition(editable: boolean): fabric.Canvas {
    fabric.IText.prototype.editable = editable;
    return this.canvas;
  }
}
