import { Task } from '../..';
import { ObjectId, User } from '../../..';
import { FormTasksService } from '../../../missions/form/services/form-tasks.service';
import { APIStore, APIStoreContents } from '../../../places';
import { Form } from '../../../services/API/forms/forms';
import { Report } from '../../../services/API/reports/reports';
import { TaskService } from '../../services/task.service';

export const ReportTasksComponent = {
  bindings: {
    form: '<',
    report: '<',
    place: '<',
    profile: '<',
    offlineMode: '<',
    isTitleDisplayed: '<',
    hasPendingTasks: '=',
  },
  templateUrl: 'tasks/components/report-tasks/report-tasks.html',
  controller: class MissionTasksController {
    // bindings
    form: Form;
    report: Report;
    place: APIStore;
    profile: User;
    hasPendingTasks: boolean;
    offlineMode: boolean;

    // members
    tasks: Task[];
    pendingTasks: Task[] = [];

    constructor(
      private taskService: TaskService,
      private $scope: ng.IScope,
      private formTasksService: FormTasksService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.getTasks();
    }

    getTasks(): void {
      const { tasks, pendingTasks } = this.formTasksService.getReportTasks();
      this.tasks = tasks;
      this.pendingTasks = pendingTasks;
      this.hasPendingTasks = !!this.pendingTasks.length;
    }

    taskItemContentClick(event: Event, taskId: ObjectId): void {
      // the list is composed from item checkboxes
      // we need to prevent default behavior in order not to check/uncheck the checkbox

      event.preventDefault();
      this.taskService
        .openTaskDetailsModal(taskId, this.profile, this.offlineMode)
        .then(({ task, status }) => {
          if (this.taskService.isDeleted(status)) {
            this.formTasksService.deleteTask(taskId);
            this.getTasks();
          } else {
            this.updateTasks(task);
          }
        });
    }

    updateTasks(changedTask: Task): void {
      this.formTasksService.updateTasks(changedTask);
      this.getTasks();
    }

    getPlace(): APIStoreContents {
      return this.place && this.place.contents;
    }

    getTitleTranslationKey(): string {
      return this.offlineMode
        ? 'MISSION_TASKS_SECTION_TITLE'
        : 'MISSION_TASKS_REPORT_SECTION_TITLE';
    }
    isAssignee(assignee_id: ObjectId): boolean {
      return assignee_id === this.profile.id;
    }

    onSkipAutotask(autotask: Task): void {
      this.formTasksService.skipAutotask(autotask._id);
      this.getTasks();
    }

    onAutotaskConfirm(task: Task): void {
      this.formTasksService.addAutoTask(task);
      this.getTasks();
      this.$scope.$apply();
    }

    onDateChanged(date: Date, task: Task): void {
      task.contents.due_date = this.taskService.prepareTaskDate(date);
      this.updateTasks(task);
    }

    onTaskAction(action: string, task: Task): void {
      switch (action) {
        case 'delete':
          this.onTaskDeleteAction(task._id);
          break;
        case 'edit':
          this.onTaskEditAction(task);
          break;

        default:
          break;
      }
    }

    onTaskDeleteAction(taskId: ObjectId): void {
      this.formTasksService.deleteTask(taskId);
      this.getTasks();
    }

    onTaskEditAction(task: Task): void {
      const offlineMode = true;
      const context = {};

      this.taskService
        .openTaskManageModal(task, this.profile, context, offlineMode)
        .then(
          (task) => this.updateTasks(task.managedTask),
          () => {} // close without creation reject
        );
    }
  },
};
