import { QuestionFormulaController } from './question-formula.controller';

export const QuestionFormulaComponent: ng.IComponentOptions = {
  bindings: {
    question: '<',
    hasError: '<',
    report: '<',
    form: '<',
    answers: '=',
    actionClickedFn: '=?',
    previousAnswers: '<',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-formula/question-formula.html',
  controller: QuestionFormulaController,
};
