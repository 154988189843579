const SKELETON_DEFAULT_ITEMS_NB = 2;
const SKELETON_DEFAULT_PICTURES_NB = 6;

export const SkeletonDisplayComponent = {
  bindings: {
    config: '<',
  },
  templateUrl: 'components/Skeletons/skeleton-display/skeleton-display.html',
  controller: function SkeletonDisplayController() {
    'ngInject';

    this.$onInit = () => {
      this.SKELETON_DEFAULT_ITEMS_NB = SKELETON_DEFAULT_ITEMS_NB;
      this.SKELETON_DEFAULT_PICTURES_NB = SKELETON_DEFAULT_PICTURES_NB;
      this.viewConfig = this.config || [];
    };
  },
};
