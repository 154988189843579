import { TasksListController } from './tasks-list.controller';

export const TasksListComponent = {
  bindings: {
    profile: '<',
    isPlace: '<?',
    useGroupTasks: '<?',
    taskStatus: '<',
    tabName: '@',
    requestFilters: '<?',
    contentKey: '@',
    reloadFn: '=?',
    onReload: '&?',
    isAddBtnDisplayed: '<?',
  },
  templateUrl: 'tasks/components/tasks-list/tasks-list.html',
  controller: TasksListController,
};
