export const MerchandisingReplacerSelectorComponent = {
  bindings: {
    validation: '<',
    profile: '<',
    onRetakePhotoSuccess: '&',
    onClose: '&',
  },
  templateUrl:
    'merchandising/components/merchandising-replacer-selector/merchandising-replacer-selector.html',
  // eslint-disable-next-line max-params
  controller: function MerchandisingReplacerSelectorController(
    merchandisingPopupService,
    merchandisingModalService,
    merchandisingService
  ) {
    'ngInject';
    this.$onInit = () => {
      this.answers = merchandisingService.getValidations(this.validation);
    };

    this.close = () =>
      merchandisingPopupService
        .showRetakeLeaveConfirm()
        .then(() => this.onClose())
        .catch(() => null);

    this.openPictureReplacementModal = () => {
      this.onClose();
      const replaceModal =
        merchandisingModalService.openPictureReplacementModal(
          this.validation,
          this.profile,
          this.selectedAnswers,
          this.onRetakePhotoSuccess,
          () => {
            replaceModal.remove();
          }
        );
    };
  },
};
