import { NewsfeedPostFormComponentController } from './newsfeed-post-form.controller';

export const NewsfeedPostFormComponent = {
  bindings: {
    profile: '<',
    newsfeedPost: '<',
    postId: '<?',
    displayedResources: '<',
    onNextButtonClick: '&',
    onResourceDelete: '&',
    onResourcesAdd: '&',
    onRemoveAddedResource: '&',
    onLinkPreviewUpdated: '&',
  },
  templateUrl: 'newsfeed/components/newsfeed-post-form/newsfeed-post-form.html',
  controller: NewsfeedPostFormComponentController,
};
