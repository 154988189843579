const VELOCITY = 0.83;
const VELOCITY_WITH_PARALLAX_HEIGHT = 1;
const SCALE_DIVIDER = 110;
const SCALE_DIVIDER_WITH_PARALLAX_HEIGHT = 105;
const NEGATIVE_TRANSLATION_VELOCITY = 0.6;

export const ParallaxComponent = {
  bindings: {
    backgroundImage: '@',
    onScrollTopFn: '=',
    height: '<',
    hasParallaxHeight: '<?',
  },
  transclude: true,
  templateUrl: 'components/Scroll/parallax/parallax.html',
  controller: function ParallaxController($element, $window) {
    'ngInject';
    const around = (value) => Math.round(value * 100) / 100;

    this.$onInit = () => {
      this.elem = $element[0].querySelector('.sf_parallax');
      this.velocity = this.hasParallaxHeight
        ? VELOCITY_WITH_PARALLAX_HEIGHT
        : VELOCITY;
      this.onScrollTopFn = this.onScrollTop;
    };
    this.getStyle = () => {
      const style = {};

      if (this.backgroundImage) {
        style['background-image'] = `url(${this.backgroundImage})`;
      }
      style.height = this.height ? `${this.height}px` : null;

      return style;
    };
    this.onScrollTop = (value = 0) => {
      const transformProperty = $window.ionic.CSS.TRANSFORM;

      if (value <= 0) {
        const scrollTop = this.hasParallaxHeight
          ? 1
          : value * NEGATIVE_TRANSLATION_VELOCITY;

        this.elem.style[transformProperty] = [
          this.getParallaxTranslation(scrollTop),
          this.getParallaxScale(value),
        ].join(' ');
      } else {
        this.elem.style[transformProperty] = [
          this.getParallaxTranslation(value),
          this.getParallaxScale(0),
        ].join(' ');
      }
    };

    this.getParallaxTranslation = (scrollTop) => {
      const parallaxScrollTop = around((scrollTop || 0) * this.velocity * -1);

      return `translateY(${parallaxScrollTop}px)`;
    };

    this.getParallaxScale = (scrollTop) => {
      const scaleDivider = this.hasParallaxHeight
        ? SCALE_DIVIDER_WITH_PARALLAX_HEIGHT
        : SCALE_DIVIDER;
      const parallaxScale = around(1 + (scrollTop * -1) / scaleDivider);

      return `scale(${parallaxScale})`;
    };
  },
};
