export const setAuthorization = (dashboardComponent, iframe, key, token) => {
    if (iframe) {
        iframe.contentWindow.postMessage({
            action: 'setAuthorization',
            key,
            token,
            _version: dashboardComponent.VERSION
        }, '*');
        return Promise.resolve();
    }
    return Promise.reject(new Error('Cannot retrieve contentWindow.'));
};
