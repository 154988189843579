import { Camera, GalleryImageOptions } from '@capacitor/camera';
import { Dialog } from '@capacitor/dialog';
import { ForegroundNotificationService } from '../ForegroundNotification/foregroundNotification.service';

const PHOTO_LIMIT_SIZE = 3000;
const PHOTO_LIMIT_QUALITY = 90;
const LIBRARY_CONFIG: GalleryImageOptions = {
  width: PHOTO_LIMIT_SIZE,
  height: PHOTO_LIMIT_SIZE,
  quality: PHOTO_LIMIT_QUALITY,
  presentationStyle: 'fullscreen',
};

export class ImagePickerService {
  Dialogs = Dialog;
  Camera = Camera;

  constructor(
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private foregroundNotificationService: ForegroundNotificationService
  ) {
    'ngInject';
  }

  getPictures(maxPictures: number): ng.IPromise<string[]> {
    const config: GalleryImageOptions = {
      ...LIBRARY_CONFIG,
      limit: maxPictures,
    };

    return this.getPermission().then(() => {
      this.foregroundNotificationService.startForegroundService();
      return this.$q
        .resolve(this.Camera.pickImages(config))
        .then((galleryPhotos) =>
          galleryPhotos.photos.length
            ? galleryPhotos.photos.map((photo) => photo.path as string)
            : this.$q.reject()
        )
        .finally(() => {
          this.foregroundNotificationService.stopForegroundService();
        });
    });
  }

  getPermission(): ng.IPromise<void> {
    return this.$q((resolve, reject) => {
      this.Camera.checkPermissions().then((permissionStatus) =>
        permissionStatus.photos === 'granted'
          ? resolve()
          : this.askAuthorization().then(resolve).catch(reject)
      );
    });
  }

  askAuthorization(): ng.IPromise<any> {
    return this.$q((resolve, reject) => {
      this.Camera.requestPermissions()
        .then(resolve)
        .catch(() => {
          reject();
          this.Dialogs.alert({
            title: this.$translate.instant('IMAGE_PICKER_NO_AUTH_TITLE'),
            message: this.$translate.instant('IMAGE_PICKER_NO_AUTH_MESSAGE'),
          });
        });
    });
  }
}
