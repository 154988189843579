export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 20,
    method: (sqlStorageService, localStorageService, sentryService) => {
      'ngInject';
      /* Report table migration */

      const fields = [
        'id NVARCHAR(32) PRIMARY KEY UNIQUE',
        'localStatus TEXT',
        'form_id NVARCHAR(32)',
        'place_id NVARCHAR(32)',
        'payload TEXT',
        'campaign_id NVARCHAR(32)',
      ].join(',');

      const createDraftReportsTableQuery = `CREATE TABLE draft_reports(${fields})`;

      return (
        /* Reports migration execution */
        sqlStorageService
          .execute(createDraftReportsTableQuery)
          /* Force update */
          .then(() => localStorageService.remove('synchronise_date'))
          .catch((error) =>
            sentryService.captureMessage('MIGRATION_SCRIPT_FAIL_19', {
              level: 'error',
              extra: {
                error,
              },
              tags: {
                sendNotification: 'mail',
              },
            })
          )
      );
    },
  });
}
