const TEXT_INPUT_LIMIT = 30;

export const SelectOrTextComponent = {
  bindings: {
    options: '<',
    limit: '<',
    inputName: '@',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  templateUrl: 'components/Inputs/select-or-text/select-or-text.html',
  controller: class SelectOrTextController {
    $onInit() {
      this.textLimit = this.limit || TEXT_INPUT_LIMIT;
      this.modelCtrl.$render = () => {
        this.value = this.modelCtrl.$viewValue;
      };
    }
    changeValue() {
      return this.modelCtrl.$setViewValue(this.value);
    }
  },
};
