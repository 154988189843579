import { CommentController } from './comment.controller';

export const CommentComponent: ng.IComponentOptions = {
  bindings: {
    comment: '<',
    user: '<',
    content: '<?',
    actions: '<?',
    isFocused: '<',
    validationState: '<?',
    validationStatus: '<?',
  },
  transclude: true,
  templateUrl: 'comments/components/comment/comment.html',
  controller: CommentController,
};
