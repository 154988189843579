import moment from 'moment-timezone';

export const SECONDS_IN_DAY = 24 * 60 * 60;
export const SECONDS_IN_HOUR = 60 * 60;
const TRANSITION_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DISPLAY_DATE_DEBOUNCE = 1000; // ms

export const DateTimeRangeTimezoneComponent = {
  bindings: {
    startDate: '<',
    endDate: '<',
    step: '<?',
    timezone: '@',
    editTimezoneKey: '@',
    startDateLabel: '@',
    endDateLabel: '@',
    onChange: '&',
    inputName: '@',
    hasError: '<',
  },
  templateUrl:
    'components/Inputs/date-time-range-timezone/date-time-range-timezone.html',
  controller: class DateTimeRangeTimezoneController {
    constructor(dateFormatService, platformService) {
      'ngInject';
      this.moment = moment;
      this.dateFormatService = dateFormatService;
      this.platformService = platformService;
      this.focusedElement = null;
    }

    $onInit() {
      this.isBrowser = this.platformService.isBrowser();
      this.isTimezoneSelectorEnabled = !this.editTimezoneKey;
      this.calculateDisplayDate();
      this.computeTimePartOfDate();
      this.displayDateDebounce = DISPLAY_DATE_DEBOUNCE;
    }

    $onChanges(changes) {
      if (changes.startDate || changes.endDate) {
        this.calculateDisplayDate();
      } else if (changes.timezone) {
        this.onDisplayDateChange();
      }

      if (changes.step) {
        this.computeTimePartOfDate();
      }
    }

    onDisplayDateChange(checkEndDate) {
      if (checkEndDate) {
        this.calculateEndDate();
      }
      this.calculateForeignDate();
      this.disableTimezoneSelector();
      this.onChange({
        $event: {
          startDate: this.startDate,
          endDate: this.endDate,
          timezone: this.timezone,
        },
      });
    }

    onTimezoneChange() {
      this.calculateForeignDate();
      this.disableTimezoneSelector();
      this.onChange({
        $event: {
          startDate: this.startDate,
          endDate: this.endDate,
          timezone: this.timezone,
        },
      });
    }

    enableTimezoneSelector() {
      this.isTimezoneSelectorEnabled = true;
    }

    disableTimezoneSelector() {
      this.isTimezoneSelectorEnabled = false;
    }

    setFocus(elm) {
      this.focusedElement = elm;
    }

    resetFocus() {
      this.focusedElement = null;
    }

    isDisabled(elm) {
      return this.focusedElement && this.focusedElement !== elm;
    }

    calculateDisplayDate() {
      const calculateDate = (date) => {
        // create date string we have to show to user
        const foreignDateString = this.moment(date)
          .tz(this.timezone)
          .format(TRANSITION_DATE_FORMAT);

        // create Date object against user local timezone
        return this.moment(foreignDateString, TRANSITION_DATE_FORMAT).toDate();
      };

      this.displayDate = {
        startDate: calculateDate(this.startDate),
        endDate: calculateDate(this.endDate),
      };
    }

    calculateForeignDate() {
      const calculateDate = (date) => {
        // create date string user entered in the input
        const localDateString = this.moment(date).format(
          TRANSITION_DATE_FORMAT
        );

        // create Date object against foreign timezone (with timezone passed as binding)
        return this.moment.tz(
          localDateString,
          TRANSITION_DATE_FORMAT,
          this.timezone
        );
      };

      this.startDate = calculateDate(this.displayDate.startDate);
      this.endDate = calculateDate(this.displayDate.endDate);
    }

    computeTimePartOfDate() {
      if (this.step >= SECONDS_IN_DAY) {
        this.isTimeEnabled = false;
        this.displayDate = {
          startDate: this.moment(this.displayDate.startDate)
            .startOf('day')
            .toDate(),
          endDate: this.moment(this.displayDate.endDate).endOf('day').toDate(),
        };
        this.onDisplayDateChange();
      } else {
        this.isTimeEnabled = true;
      }
    }

    formatTimezone() {
      return this.dateFormatService.getTimezoneFormatted(
        this.timezone,
        this.startDate
      );
    }

    calculateEndDate() {
      const endDate = this.moment(this.displayDate.endDate);
      const startDate = this.moment(this.displayDate.startDate);

      if (!endDate.isBefore(startDate)) {
        return false;
      }

      this.displayDate.endDate = this.getNewEndDate(startDate);

      return true;
    }

    getNewEndDate(date) {
      const { step } = this;

      if (step >= SECONDS_IN_DAY) {
        return date.endOf('day').toDate();
      }

      const nbSeconds = step && step > SECONDS_IN_HOUR ? step : SECONDS_IN_HOUR;

      return date.add(nbSeconds, 'seconds').toDate();
    }
  },
};
