import { QuestionRadioComponent } from './question-radio.component';
import RadioChoicesModalModule from './components/radio-choices-modal/radio-choices-modal.module';

export default angular
  .module('sfMobile.components.question-radio', [
    'sfMobile.constants.form-question-actions',
    'sfMobile.services.keyboard',
    'sfMobile.services.modal',
    RadioChoicesModalModule,
    'sfMobile.components.input-radio',
    'sfMobile.components.input-radio-free',
  ])
  .component('questionRadio', QuestionRadioComponent).name;
