import { UserRef } from '../../..';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { UsersService } from '../../../services/API/users/users.service';
import { ImageService } from '../../../services/Utils/Image/image.service';

export const AvatarUserComponent: ng.IComponentOptions = {
  bindings: {
    user: '<',
    size: '@',
    forceUptodateAvatar: '<?',
  },
  templateUrl: 'components/Avatar/avatar-user/avatar-user.html',
  controller: class AvatarUserComponentController implements ng.IController {
    // Bindings
    user: { contents: UserRef; _id: string };
    forceUptodateAvatar: boolean;

    // Class Fields
    userInitials: string;
    imageSrc: string | null;

    constructor(
      private $q: ng.IQService,
      private imageService: ImageService,
      private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
      private usersService: UsersService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.setUserInitials();
      this.setAvatarPictureSrc();
    }

    $onChanges(changes): void {
      if (changes.user.currentValue) {
        this.setAvatarPictureSrc();
      }
    }

    setAvatarPictureSrc(): ng.IPromise<string | void> {
      if (
        this.user?._id &&
        (!this.user?.contents?.avatar_id || this.forceUptodateAvatar)
      ) {
        return this.usersService.crud
          .get(this.user._id, {})
          .then(({ contents }) => {
            const avatarId = contents?.avatar_id;

            return this.getSizedAvatar(avatarId);
          });
      }
      if (this.user?.contents?.avatar_id) {
        return this.getSizedAvatar(this.user.contents.avatar_id);
      }
      this.imageSrc = null;
      return this.$q.when();
    }

    setUserInitials(): void {
      if (this.user?.contents) {
        this.userInitials = ['firstName', 'lastName']
          .map((field) =>
            this.user.contents[field]
              ? this.user.contents[field].trim()[0] || ''
              : ''
          )
          .join('')
          .toUpperCase();
      }
    }

    getSizedAvatar(imgId?: string | null): ng.IPromise<void> {
      if (!imgId) {
        this.imageSrc = null;
        return this.$q.resolve();
      }
      return this.imageService
        .getSizedUrlFromId(imgId, this.SF_IMAGE_SIZES.SQUARE_SMALL)
        .then((url) => {
          this.imageSrc = url;
        });
    }
  },
};
