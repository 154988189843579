// eslint-disable-next-line max-params
export function ReportsValidationService(
  $http,
  pubSubService,
  $q,
  sfPOVService,
  profileService
) {
  'ngInject';
  const basePath = '/reports';

  return {
    getReportsToRevise,
    getPendingValidationReportsScope,
    getPendingValidationReports,
    getValidatedReports,
    getValidatedReportsScope,
    transformAPIData,
    getReportsNumberToRevise,
    updateToReviseCounts,
  };

  function transformAPIData(APIData, useCampaign) {
    const getForm = (report, forms) => ({
      contents: forms && forms[report.contents.form_id],
    });
    const getCampaign = (report, campaigns) => ({
      contents: campaigns && campaigns[report.contents.campaign_id],
    });

    return APIData.entries.map((report) => ({
      ...report,
      contents: {
        ...report.contents,
        ...(useCampaign
          ? { campaign: getCampaign(report, APIData.campaigns) }
          : { form: getForm(report, APIData.forms) }),
      },
    }));
  }

  /**
   * Get the reports in validationState 'TOREVISE'
   * Only online feature
   * @param {Object} params
   * @returns {Promise}
   */
  function getReportsToRevise(params = {}) {
    const reportsUrl = `${basePath}/torevise`;

    return sfPOVService
      .pBuildURL(reportsUrl, { pov: 'user' })
      .then((povUrl) => $http.get(povUrl, { params }))
      .then((res) => res.data);
  }

  /**
   * Get the reports in validationState 'TOREVIEW' owned by the user
   * Only online feature
   * @param {Object} params
   * @returns {Promise}
   */
  function getPendingValidationReports(params = {}) {
    const reportsUrl = `${basePath}/pendingValidation`;

    return sfPOVService
      .pBuildURL(reportsUrl, { pov: 'user' })
      .then((povUrl) => $http.get(povUrl, { params }))
      .then((res) => res.data);
  }

  /**
   * Get the reports in validationState 'TOREVIEW' in the user's scope
   * Only online feature
   * @param {Object} params
   * @returns {Promise}
   */
  function getPendingValidationReportsScope(params = {}) {
    const reportsUrl = `${basePath}/pendingValidation`;

    return sfPOVService
      .pBuildURL(reportsUrl, { pov: 'organisation' })
      .then((povUrl) => $http.get(povUrl, { params }))
      .then((res) => res.data);
  }

  /**
   * Get the reports in validationState 'DONE' owned by the user
   * Only online feature
   * @param {Object} params
   * @returns {Promise}
   */
  function getValidatedReports(params = {}) {
    return sfPOVService
      .pBuildURL(basePath, { pov: 'user' })
      .then((povUrl) =>
        $http.get(povUrl, {
          params: {
            ...params,
            validity: true,
            validationState: 'DONE',
          },
        })
      )
      .then((res) => res.data);
  }

  /**
   * Get the reports in validationState 'DONE' in the users scope
   * Only online feature
   * @param {Object} params
   * @returns {Promise}
   */
  function getValidatedReportsScope(params = {}) {
    return sfPOVService
      .pBuildURL(basePath, { pov: 'organisation' })
      .then((povUrl) =>
        $http.get(povUrl, {
          params: {
            ...params,
            validity: true,
            validationState: 'DONE',
          },
        })
      )
      .then((res) => res.data);
  }

  function getReportsNumberToRevise() {
    const reportsUrl = `${basePath}/count`;

    return $q
      .all([
        profileService.getProfile(),
        sfPOVService.pBuildURL(reportsUrl, { pov: 'organisation' }),
      ])
      .then(([profile, povUrl]) =>
        $http.get(povUrl, {
          params: {
            validationState: 'TOREVISE',
            savedUserId: profile._id,
          },
        })
      )
      .then((res) => res.data);
  }

  function updateToReviseCounts() {
    return getReportsNumberToRevise().then(({ count }) => {
      return pubSubService.publish('REPORT_TO_REVISE_COUNT_UPDATED', {
        count: count,
      });
    });
  }
}
