import { Preferences } from '@capacitor/preferences';

export class SimpleStorageService {
  /* @ngInject */
  constructor() {}

  save(key: string, value: string): ng.IPromise<void> {
    return Preferences.set({
      key,
      value,
    });
  }

  saveDate(key: string, date: Date): ng.IPromise<void> {
    const dateString = date.toISOString();
    return Preferences.set({
      key,
      value: dateString,
    });
  }

  get(key: string): ng.IPromise<string | null> {
    return Preferences.get({ key }).then((result) => result.value);
  }

  getDate(key: string): ng.IPromise<Date | null> {
    return Preferences.get({ key }).then((result) => {
      const dateString = result?.value;
      if (dateString) {
        return new Date(dateString);
      } else {
        return null;
      }
    });
  }

  remove(key: string): ng.IPromise<void> {
    return Preferences.remove({ key });
  }
}
