export const ReportContentComponent = {
  bindings: {
    form: '<',
    report: '<',
    profile: '<',
    place: '<',
  },
  templateUrl: 'reports/report/components/report-content/report-content.html',
  controller: class ReportContentController {
    constructor(contentService, formsService, modalService) {
      'ngInject';
      Object.assign(this, {
        contentService,
        formsService,
        modalService,
      });
    }

    $onInit() {
      this.currentIndex = 0;
      this.currentSection = null;
      this.comments = null;
      this.nbComments = null;

      this.cleanForm = this.formsService.removeLegacy(this.form);
      this.formSections = this.form.contents.sections.filter(
        (section) => section.level === 0
      );
      this.formTree = this.formsService.getFormTree(this.cleanForm);
      this.isSend = this.report.localStatus !== 'ready';

      this.setStep();
    }

    openNavigation() {
      const template = `
      <sf-report-navigation-modal
        title="{{ $ctrl.title }}"
        place="$ctrl.place"
        form="$ctrl.form"
        sections="$ctrl.sections"
        on-item-click="$ctrl.onItemClick(index)"
        on-close="$ctrl.onClose()">
      </sf-report-navigation-modal>
    `;
      const bindings = {
        title: this.form.contents.title,
        place: this.place,
        form: this.form,
        sections: this.formSections,
        onItemClick: (index) => {
          this.setStep(index);
          return true;
        },
      };

      return this.modalService.open(template, bindings);
    }

    nextStep() {
      this.changeStep('next');
    }

    previousStep() {
      this.changeStep('previous');
    }

    changeStep(direction) {
      const numeratorIndex = direction === 'next' ? 1 : -1;
      const newStep = this.currentIndex + numeratorIndex;

      if (!this.isValidStep(newStep)) {
        return;
      }
      this.setStep(newStep);
    }

    isValidStep(index) {
      return index >= 0 && index < this.formSections.length;
    }

    setStep(index) {
      this.currentIndex =
        typeof index != 'undefined' ? index : this.currentIndex;
      this.currentSection = this.formTree[this.currentIndex];

      this.contentService.scrollTopById('reportContentScroll');
    }
  },
};
