import { PlaceAddress } from '@places/index';
import { Task } from '../..';
import { ObjectId, User, UserContents } from '../../../index';
import {
  ActionSheetAction,
  ActionSheetConfig,
  ActionSheetService,
} from '../../../services/Utils/ActionSheet/action-sheet.service';
import { SegmentService } from '../../../services/Utils/Segment/segment.service';
import { TaskService } from '../../services/task.service';

type UserAvatarData = Pick<UserContents, 'firstName' | 'lastName' | 'email'>;

export class TaskItemController implements ng.IComponentController {
  // bindings
  user: UserAvatarData;
  task: Task;
  profile: User;
  checklistMode: boolean;
  place: PlaceAddress;
  onTaskAction: (arg: { action: string; task?: Task }) => void;
  onContentClick: (data: { event: Event; taskId: ObjectId }) => void;
  onDateSelect: (arg: { date: Date; task: Task }) => void;

  // attributes
  userContents: { contents: UserAvatarData };
  selected = false;
  offlineMode: boolean;
  subtasksCount = 0;
  areSubtasksDone = true;
  doneSubtasksCount = 0;

  constructor(
    private taskService: TaskService,
    private segmentService: SegmentService,
    private keyboardService,
    private actionSheetService: ActionSheetService,
    private $translate: ng.translate.ITranslateService
  ) {
    'ngInject';
  }

  $onInit = (): void => {
    this.selected =
      !this.checklistMode &&
      !this.taskService.isToDo(this.task.contents.status);

    this.userContents = { contents: this.user };
    this.subtasksCount = this.task.contents.subtasks?.length ?? 0;
    this.task.contents.subtasks?.forEach(({ status }) => {
      if (status === 'done') {
        this.doneSubtasksCount++;
      } else {
        this.areSubtasksDone = false;
      }
    });
  };

  $onChanges = (change): void => {
    if (change.task) {
      this.selected =
        !this.checklistMode &&
        !this.taskService.isToDo(this.task.contents.status);
    }
  };

  trackChangeSuccess = (): void => {
    if (!this.offlineMode) {
      this.segmentService.track('TASK_ITEM', {
        action: 'Change status',
        label: 'succeed',
        task_id: this.task._id,
        value: this.task.contents.status,
      });
    }
  };

  trackChangeFail = (error): void => {
    if (!this.offlineMode) {
      this.segmentService.track('TASK_ITEM', {
        action: 'change status',
        label: 'failed',
        task_id: this.task._id,
        value: error.status,
      });
    }
  };

  onItemClick = (event: Event): boolean => {
    event.stopPropagation();

    this.onContentClick({ event, taskId: this.task._id });
    return true;
  };

  openActionsSheet(event: Event) {
    event.stopPropagation();
    this.keyboardService.hide();

    const actionSheetActions = this.getActions();
    const actionSheetConfig: ActionSheetConfig = {
      cancelText: this.$translate.instant('PROFILE_ACTION_CANCEL'),
      destructiveText: this.task.isMandatory
        ? ''
        : this.$translate.instant('TASK_ACTIONS_DELETE'),
    };
    const onDestructiveClick = this.task.isMandatory
      ? null
      : () => this.onTaskAction({ action: 'delete', task: this.task });

    return this.actionSheetService.open(
      actionSheetActions,
      actionSheetConfig,
      null,
      onDestructiveClick
    );
  }

  getActions(): ActionSheetAction[] {
    return [
      {
        text: this.$translate.instant('TASK_ACTIONS_EDIT'),
        onClick: () => this.onTaskAction({ action: 'edit', task: this.task }),
      },
    ];
  }

  onDateChange(date: Date): void {
    this.onDateSelect({ date, task: this.task });
  }
}
