import { SfFeatureFlags } from '@simplifield/feature-flags';
import {
  CustomParamAPI,
  IAPIList,
  IAPIResource,
  MobileDevices,
  ObjectId,
  User,
  UserContents,
  UsersEntity,
} from '../../..';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import { DB_SCHEMA } from '../../../core/database.config';
import { AccessRightsService } from '../../Utils/AccessRights/access-rights.service';
import { DataAccessLayerService } from '../../Utils/CRUD/crud-service';
import { CrudFactory } from '../../Utils/CRUD/crud-service.factory';
import { PovService } from '../POV/pov.service';
import { UsersCustomParam, UsersGroup } from './users';

const USEFUL_FIELDS = [
  'contents.avatar_id',
  'contents.department',
  'contents.email',
  'contents.experience',
  'contents.firstName',
  'contents.lastName',
  'contents.mobileDevices',
  'contents.phone',
  'contents.profiles',
  'contents.managedUsersGroups_ids',
  'contents.usersGroups_ids',
];

export class UsersService {
  paramsPathName = '/customParams/users';

  crud: DataAccessLayerService<User>;
  // eslint-disable-next-line max-params
  constructor(
    private $http: ng.IHttpService,
    private crudFactory: CrudFactory<User>,
    private sfPOVService: PovService,
    private databaseSchema: DB_SCHEMA,
    private accessRightsService: AccessRightsService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private userExperienceService,
    private profileService,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {
    'ngInject';
    const tableConfig = this.databaseSchema.tables.users;

    this.crud = this.crudFactory('users', {
      default_params: { mode: 'compact', fields: USEFUL_FIELDS },
      backup: {
        indexed_fields: tableConfig.indexed_fields.map((field) => field.name),
      },
    });
  }

  callOrgEntities<T>(baseUrl: string, params = {}): ng.IPromise<T> {
    return this.sfPOVService
      .pBuildURL(baseUrl, { pov: 'organisation' })
      .then((url) => this.$http.get<T>(url, { params }))
      .then((response) => response.data);
  }

  update(user: {
    _id: ObjectId;
    contents: Partial<UserContents>;
  }): ng.IPromise<User> {
    return this.sfPOVService
      .pBuildURLByProfile(`/users/${user._id}`)
      .then((url) => this.$http.patch<User>(url, user))
      .then((response) => response.data);
  }

  getAllUsers(
    params: Record<string, string> = {}
  ): ng.IPromise<IAPIList<IAPIResource<User>>> {
    params.mode = 'compact';
    return this.callOrgEntities<IAPIList<IAPIResource<User>>>('/users', params);
  }

  getScopedUsers(
    params: Record<string, string> = {},
    placeId: ObjectId
  ): ng.IPromise<IAPIList<IAPIResource<User>>> {
    params.scopePlaceId = placeId;
    params.mode = 'compact';
    params.fields = 'contents';

    return this.callOrgEntities<IAPIList<IAPIResource<User>>>('/users', params);
  }

  getUser(userId: ObjectId): ng.IPromise<User> {
    return this.callOrgEntities(`/users/${userId}`);
  }

  getAllUsersGroups(
    params: Record<string, unknown> = {}
  ): ng.IPromise<IAPIList<IAPIResource<UsersGroup>>> {
    params.mode = 'compact';
    return this.callOrgEntities('/usersGroups', params);
  }

  getScopedUsersGroups(): ng.IPromise<IAPIList<IAPIResource<UsersGroup>>> {
    const params: Record<string, unknown> = { mode: 'compact' };
    return this.profileService.getProfile().then((p) => {
      params.filters = {
        $and: [
          {
            $or: [{ user_group_manager_id: { $eq: p._id } }],
          },
        ],
      };
      return this.callOrgEntities('/usersGroups', params);
    });
  }

  getUsersGroup(
    userGroupId: string
  ): ng.IPromise<IAPIList<IAPIResource<UsersGroup>>> {
    return this.callOrgEntities(`/usersGroups/${userGroupId}`);
  }

  listUsersParamsKeys(): ng.IPromise<IAPIList<CustomParamAPI<UsersEntity>>> {
    const url = this.paramsPathName;
    const useUserProfile = false;
    const queryParams: Record<string, unknown> = {};
    queryParams['withNewTypes'] = true;

    // when url is passed crud has different type
    return (
      this.crud as unknown as DataAccessLayerService<CustomParamAPI<'users'>>
    ).simpleApiList(url, queryParams, useUserProfile, {
      pov: 'organisation',
    });
  }

  hasParamsFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEW_PARAMS_TYPES
    );
  }

  hasAlphaParamsFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEW_PARAMS_TYPES_ALPHA
    );
  }

  areNewParamsAllowed(): boolean {
    return this.hasAlphaParamsFeatureFlag() || this.hasParamsFeatureFlag();
  }

  getCustomParamsValues(
    params: Record<string, string> = {}
  ): ng.IPromise<IAPIList<IAPIResource<UsersCustomParam>>> {
    params.mode = 'compact';
    return this.callOrgEntities('/usersParamsValues', params);
  }

  addUserMobileDevices(
    user: User,
    newMobileDevice: MobileDevices
  ): ng.IPromise<User> {
    return this.sfPOVService
      .pBuildURLByProfile(`/users/${user._id}/mobileDevices`)
      .then((url) => this.$http.post<User>(url, newMobileDevice))
      .then((response) => {
        user.contents.mobileDevices = response.data.contents.mobileDevices;
        return response.data;
      });
  }

  updateUserMobileDevices(
    user: User,
    newMobileDevices: MobileDevices[]
  ): ng.IPromise<User> {
    return this.update({
      _id: user._id,
      contents: { mobileDevices: newMobileDevices },
    }).then(() => {
      user.contents.mobileDevices = newMobileDevices;
      return user;
    });
  }

  getSendbirdToken(): ng.IPromise<string> {
    return this.sfPOVService
      .pBuildURL('/sendbirdToken')
      .then((url) => this.$http.get<string>(url))
      .then((response) => response.data);
  }

  isManagedUser(user: User, profile: User): boolean {
    if (this.accessRightsService.isAdmin() || user._id === profile._id) {
      return true;
    }
    if (this.accessRightsService.isManager()) {
      const { usersGroups_ids } = user.contents;
      const { managedUsersGroups_ids } = profile.contents;

      if (!usersGroups_ids || !managedUsersGroups_ids) {
        return false;
      }

      return usersGroups_ids.some((id) => managedUsersGroups_ids.includes(id));
    }
    return false;
  }

  isStoreManager(user: User): boolean {
    return (
      user.contents.profiles.includes('manager') &&
      this.userExperienceService.isStore()
    );
  }

  updateAvatarId(user: User, avatar_id?: ObjectId): ng.IPromise<User> {
    return this.update({
      _id: user._id,
      contents: { avatar_id: avatar_id ?? null },
    });
  }

  isCurrentUser(user: User): boolean {
    return this.accessRightsService.profile?._id === user._id;
  }
}
