import {
  CustomParamAPI,
  ObjectId,
  User,
  UsersEntity,
  IAPIResource,
  Paginated,
} from '../../..';
import { UsersService } from '../../../services/API/users/users.service';
import type {
  FilterCustomParam,
  FilterSelected,
  FilterStandard,
} from '../../Search/search';

const FILTERS_CONFIG = {
  team: {
    id: 'team',
    paramKey: 'user_teams',
    translationKey: 'CHAT_FILTER_TEAM',
  },
  placesList: {
    id: 'placesList',
    paramKey: 'pos_areas',
    translationKey: 'CHAT_FILTER_PLACESLIST',
  },
};
const CUSTOM_FILTERS_KEY = 'user_user_customparam_';
const SELECT_USERS_SEARCH_CRITERIA = [
  'user_firstName',
  'user_lastName',
  'user_fullName',
];

type FilterConfig = typeof FILTERS_CONFIG[keyof typeof FILTERS_CONFIG];

export const SelectUsersComponent = {
  bindings: {
    requestFilters: '<?',
    onUserSelect: '&',
    onUserUnselect: '&',
    onLoadingSuccess: '&',
    onLoadingError: '&',
    contentKey: '@',
  },
  templateUrl: 'components/Select/select-users/select-users.html',
  controller: class SelectUsersController {
    // bindings
    requestFilters: FilterStandard[];
    onUserSelect: (arg: { userId: ObjectId }) => void;
    onUserUnselect: (arg: { userId: ObjectId }) => void;
    onLoadingSuccess: () => void;
    onLoadingError: () => void;

    // fields
    users: User[];
    selectedUsers: Record<string, unknown>;
    filters: (FilterStandard | FilterCustomParam)[];
    userFiltersSelected: FilterSelected[];
    infiniteLoadError: boolean;
    isSearchLoading: boolean;
    userSearch: string;
    NB_USERS_TO_GET: number;
    requestPaginate: {
      call: (opts: Record<string, unknown>) => ng.IPromise<Paginated<User>>;
      reset: () => void;
    };
    hasUsers: boolean;
    isLoading: boolean;
    hasLoadingError: boolean;
    hasUsersFound: boolean;
    onScrollComplete: () => void;
    searchTimestamp: number;
    hasSearchLoadingError: boolean;

    constructor(
      private $q: ng.IQService,
      private $translate: ng.translate.ITranslateService,
      private usersService: UsersService,
      private placesService,
      private apiUtilsService,
      private RequestsPaginate
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.users = [];
      this.selectedUsers = {};
      this.filters = [];
      this.userFiltersSelected = [];
      this.infiniteLoadError = false;
      this.isSearchLoading = false;
      this.userSearch = '';

      this.NB_USERS_TO_GET = 20;

      this.requestPaginate = new this.RequestsPaginate(
        this.usersService.getAllUsers.bind(this.usersService),
        {
          limit: this.NB_USERS_TO_GET,
        }
      );

      this.callUsers();
    }

    reload(): ng.IPromise<Paginated<User>> {
      return this.callUsers();
    }

    callUsers(): ng.IPromise<Paginated<User>> {
      this.hasUsers = false;
      this.isLoading = true;
      this.hasLoadingError = false;
      this.requestPaginate.reset();

      return this.$q
        .all([
          this.getUsers(this.userSearch, this.userFiltersSelected),
          this.getFilters(),
        ])
        .then(([users]) => {
          this.hasUsers = this.hasUsersFound = Boolean(
            users.entities && users.entities.length
          );
          return users;
        })
        .catch((err) => {
          this.hasLoadingError = true;
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    getUsers(
      search: string,
      filters: FilterSelected[],
      $timestamp?: number
    ): ng.IPromise<Paginated<User>> {
      const queryFilters = [
        ...(this.requestFilters || []),
        ...this.buildFilterQuery(filters, FILTERS_CONFIG.team),
        ...this.buildFilterQuery(filters, FILTERS_CONFIG.placesList),
        ...this.buildCustomParamsFilterQuery(filters),
      ];
      const requestOption = {
        fields: [
          'contents.firstName',
          'contents.lastName',
          'virtualFields.fullName',
        ],
        sorts: ['contents.firstName', 'contents.lastName'],
        ...this.apiUtilsService.buildFilterParams(queryFilters, {
          search,
          criterias: SELECT_USERS_SEARCH_CRITERIA,
        }),
        sfqlOptions: { isRegexUnescaped: true, isDiacriticsInsensitive: true },
      };

      return this.requestPaginate
        .call(requestOption)
        .then((res) => {
          this.users = res.entities;
          this.onLoadingSuccess();
          return $timestamp ? { ...res, $timestamp } : res;
        })
        .catch((err) => {
          this.onLoadingError();
          throw err;
        });
    }
    /**
     * Load more data thanks to the infinite scroll
     * and manage the infinite loading state
     *
     * @return {Promise} Result of the promise
     */
    getNextUsers(): ng.IPromise<Paginated<User>> {
      this.infiniteLoadError = false;

      return this.getUsers(this.userSearch, this.userFiltersSelected)
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        })
        .finally(() => {
          if (this.onScrollComplete) {
            this.onScrollComplete();
          }
        });
    }
    /**
     * Get and set the user filters
     *
     * @return {Promise} The result of the request
     */
    getFilters(): ng.IPromise<void> {
      const filtersDataPromises = [
        this.usersService.getAllUsersGroups(),
        this.placesService.getAllPlacesLists(),
        this.usersService.listUsersParamsKeys(),
      ];

      return this.$q
        .all(filtersDataPromises)
        .then(([usersGroups, placesLists, paramsValues]) => {
          this.filters = [
            this.initFilter(FILTERS_CONFIG.team, usersGroups.entries),
            this.initFilter(FILTERS_CONFIG.placesList, placesLists.entries),
            ...this.initCustomParamsFilter(paramsValues.entries),
          ];
        });
    }

    /**
     * Triggered when the user change a filter or do a new search
     * and manage the search view states
     *
     * @param {String} search - Field search value
     * @param {Array} filters - Filters selected list
     * @return {Promise} Result of the request
     */
    onSearchChange(
      search: string,
      filters: FilterSelected[]
    ): ng.IPromise<Paginated<User> | void> {
      this.userSearch = search;
      this.userFiltersSelected = filters;
      this.searchTimestamp = new Date().getTime();

      this.hasSearchLoadingError = false;
      this.isSearchLoading = true;

      this.requestPaginate.reset();

      return this.getUsers(search, filters, this.searchTimestamp)
        .then((res) => {
          if (res.$timestamp === this.searchTimestamp) {
            this.hasUsersFound = Boolean(res.entities && res.entities.length);
            this.isSearchLoading = false;
          }
          return res;
        })
        .catch(() => {
          this.hasSearchLoadingError = true;
          this.isSearchLoading = false;
        });
    }

    onSelectChange(userId: ObjectId): void {
      if (this.selectedUsers[userId]) {
        this.onUserSelect({ userId });
      } else {
        this.onUserUnselect({ userId });
      }
    }

    buildUserName(user: User): string {
      return `${user.contents.firstName} ${user.contents.lastName}`;
    }

    resetInfiniteLoadErrorState(): void {
      this.infiniteLoadError = false;
    }

    /**
     * Format the custom params list for the component
     *
     * @param {Array} customParams - List of custom params available
     * @return {Array} Params filtered formated
     */
    initCustomParamsFilter(
      customParams: CustomParamAPI<UsersEntity>[]
    ): FilterCustomParam[] {
      return (customParams || []).map((param) => {
        return {
          id: param._id,
          label: this.$translate.instant('CHAT_FILTER_CUSTOM_PARAM', {
            customParam: param.contents.label.en,
          }),
          type: param.contents.type,
          values: param.contents.predefinedValues,
        };
      });
    }

    initFilter(
      config: FilterConfig,
      resources: IAPIResource<{ name: string }>[]
    ): FilterStandard {
      return {
        id: config.id,
        label: this.$translate.instant(config.translationKey),
        values: (resources || []).map((resource) => resource.contents.name),
      };
    }

    /**
     * Build the query for user search
     *
     * @param {String} searchString - Search value from the field
     * @return {Array} Query filter with params
     */
    buildSearchQuery(searchString: string): Record<string, unknown>[] {
      return searchString
        ? [
            {
              $or: [
                { user_firstName: { $regex: searchString } },
                { user_lastName: { $regex: searchString } },
              ],
            },
          ]
        : [];
    }
    /**
     * Build the query for groups and lists
     *
     * @param {Array} filters - selected filters
     * @param {Object} configFilter - filter configuration
     * @return {Array|Object} Query filter of list
     */
    buildFilterQuery(
      filters: FilterSelected[],
      configFilter: FilterConfig
    ): Record<string, unknown>[] {
      const filterConfig = this.getFilterConfig(configFilter, filters);

      return filterConfig
        ? [{ name: configFilter.paramKey, value: filterConfig.value }]
        : [];
    }
    getFilterConfig(
      configFilter: FilterConfig,
      filters: FilterSelected[]
    ): FilterSelected {
      return (filters || []).filter(
        (filter) => filter.id === configFilter.id
      )[0];
    }
    /**
     * Build the query for custom params values selected
     *
     * @param {Array} filters - selected filters
     * @return {Array} Query filters with custom params
     */
    buildCustomParamsFilterQuery(
      filters: FilterSelected[]
    ): { name: string; value: (string | FilterSelected['value'])[] }[] {
      const customParamsFilters = (filters || []).filter(
        (filter) =>
          ![FILTERS_CONFIG.team.id, FILTERS_CONFIG.placesList.id].includes(
            filter.id
          )
      );

      return customParamsFilters.map((filter) => ({
        name: `${CUSTOM_FILTERS_KEY}${filter.id}`,
        value: [filter.id, filter.value],
        operator: '$regex',
      }));
    }
  },
};
