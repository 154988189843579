"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('usergroup1'),
    name: 'usergroup1',
    description: 'Sample group 1',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Sample group 1',
      users_ids: [createObjectId('user2')],
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee'),
      managers_ids: []
    }
  }, {
    _id: createObjectId('usergroup2'),
    name: 'usergroup2',
    description: 'Sample group 2',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Sample group 2',
      description: 'A group of users (1 & 2)',
      users_ids: [createObjectId('user1'), createObjectId('user2')],
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee'),
      managers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'badusergroup',
    description: 'Bad group',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.name']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      description: 'A group of users (1 & 2)',
      users_ids: [createObjectId('user1'), createObjectId('user2')],
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee'),
      managers_ids: []
    }
  }];
};