const SIZE_CLASSES = {
  small: 'sf_user__name_small',
  medium: 'sf_user__name_medium',
  big: 'sf_user__name_big',
};

export const UserComponent = {
  bindings: {
    user: '<',
    avatarSize: '@?',
    shortName: '<?',
  },
  templateUrl: 'components/user/user.html',
  controller: class UserComponent {
    $onInit() {
      this.sizeClass = SIZE_CLASSES[this.avatarSize] || SIZE_CLASSES.small;
    }
  },
};
