import { NextEventsListController } from './next-events-list.controller';

export const NextEventsListComponent = {
  bindings: {
    sectionTitle: '@',
    type: '@',
    placeId: '<',
  },
  templateUrl: 'components/next-events/next-events-list/next-events-list.html',
  controller: NextEventsListController,
};
