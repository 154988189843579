import { NewsfeedService } from '../../services/newsfeed-service/newsfeed.service';
import { ObjectId } from '../../..';
import type {
  PostContents,
  NewsfeedPostResourceType,
  ResourcesSelectedCb,
  EditedResourcePreview,
} from '../../types';
import { ActionSheetService } from '../../../services/Utils/ActionSheet/action-sheet.service';
import { NewsfeedResourcesSelectorService } from '../../services/newsfeed-resources-selector/newsfeed-resources-selector.service';
import { LinkPreviewService } from '../../../services/API/link-preview/link-preview.service';
import { LinkPreview } from '../../../services/API/link-preview/types';
import { Targeting } from '../../../targeting/types';

// Here we operate view
export class NewsfeedPostFormComponentController {
  // bindings
  onResourcesAdd: (options) => void;
  onResourceDelete: (options: { resourceId: ObjectId }) => void;
  onRemoveAddedResource: (options: {
    resourceId: ObjectId;
    resourceType: NewsfeedPostResourceType;
  }) => void;
  onLinkPreviewUpdated: (linkPreview) => void;
  onLinkPreviewClick: (event: Event) => void;
  displayedResources: {
    images: EditedResourcePreview[];
    documents: EditedResourcePreview[];
    videos: EditedResourcePreview[];
  };

  newsfeedPost: Partial<PostContents> & {
    targeting?: Targeting;
    linkPreview?: LinkPreview;
  };
  hasAlphaFlag: boolean;
  postId?: ObjectId;
  onPostSave: () => void;
  POST_MAX_SIZE = 4400;
  pending = false;
  newsfeedPostForm: ng.IFormController;

  // eslint-disable-next-line max-params
  constructor(
    private $translate: ng.translate.ITranslateService,
    private newsfeedService: NewsfeedService,
    private actionSheetService: ActionSheetService,
    private newsfeedResourcesSelectorService: NewsfeedResourcesSelectorService,
    private linkPreviewService: LinkPreviewService,
    private $scope: ng.IScope
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.hasAlphaFlag = this.newsfeedService.hasAlphaFeatureFlag();
    const onFilesSelected: ResourcesSelectedCb = ({
      $files,
      $type,
    }: {
      $files: Blob[];
      $type: NewsfeedPostResourceType;
    }) => this.onFilesSelected($files, $type);

    this.newsfeedResourcesSelectorService.setResourceSelectCallback(
      onFilesSelected
    );
    this.initWatchPristineState();
  }

  deleteImage(imageFile: EditedResourcePreview): void {
    this.deleteResourceConfirm(imageFile._id, 'image');
  }

  deleteDocument(documentFile: EditedResourcePreview): void {
    this.deleteResourceConfirm(documentFile._id, 'document');
  }

  deleteVideo(videoFile: EditedResourcePreview): void {
    this.deleteResourceConfirm(videoFile._id, 'video');
  }

  private deleteResourceConfirm(
    resourceId: ObjectId,
    resourceType: NewsfeedPostResourceType
  ) {
    this.unsetFormPristine();
    const onDestructiveClick = () =>
      this.onConfirmDeleteResource(resourceId, resourceType);

    const title = this.newsfeedService.isImageResource(resourceType)
      ? this.$translate.instant('NEWSFEED_POST_CONFIRM_DELETE_PHOTO_TITLE')
      : this.$translate.instant('NEWSFEED_POST_CONFIRM_DELETE_DOCUMENT_TITLE');
    const actionSheetConfig = {
      title,
      cancelText: this.$translate.instant(
        'NEWSFEED_POST_CONFIRM_DELETE_RESOURCE_CANCEL'
      ),
      destructiveText: this.$translate.instant(
        'NEWSFEED_POST_CONFIRM_DELETE_RESOURCE_CONFIRM'
      ),
      isValidation: true,
    };

    this.actionSheetService.open(
      null,
      actionSheetConfig,
      null,
      onDestructiveClick
    );
  }

  private onConfirmDeleteResource(
    resourceId: ObjectId,
    resourceType: NewsfeedPostResourceType
  ) {
    // this all is related to view
    this.onRemoveAddedResource({ resourceId, resourceType });
  }

  onResourceSelectEvent(isPending: boolean): void {
    this.pending = isPending;
  }

  onFilesSelected(files: Blob[], type: NewsfeedPostResourceType): void {
    this.unsetFormPristine();
    this.onResourcesAdd({ resources: files, type });
  }

  onDocumentNameClick(): ng.IPromise<void> {
    const currentDocumentId = this.displayedResources.documents[0]
      ._id as ObjectId;

    return this.newsfeedResourcesSelectorService
      .selectDocumentsOnMobile()
      .then(() => this.onResourceDelete({ resourceId: currentDocumentId }));
  }

  onTextPasted(
    event: ng.IAngularEvent & { clipboardData: any }
  ): ng.IPromise<void> | null {
    const pastedText = (event?.clipboardData).getData('text');

    if (!pastedText || this.newsfeedPost?.linkPreview) {
      return null;
    }
    const [urlFromText] =
      this.linkPreviewService.extractUrlsFromText(pastedText);

    if (!urlFromText) {
      return null;
    }
    return this.linkPreviewService
      .getLinkPreview(urlFromText)
      .then((linkPreview) => {
        this.onLinkPreviewUpdated({ linkPreview });
        this.onLinkPreviewClick = this.linkPreviewService
          .onLinkPreviewClick(linkPreview.origin)
          .bind(this);
      });
  }

  onTextChanged(event: ng.IAngularEvent & { inputType: string }): void {
    if (
      !this.newsfeedPost.linkPreview ||
      !event?.inputType.startsWith('deleteContent')
    ) {
      return;
    }
    // all newsfeed text was deleted
    if (!this.newsfeedPost.text) {
      this.newsfeedPost.linkPreview = undefined;
      return;
    }
    // link that was previewed was deleted
    const urlsFromText = this.linkPreviewService.extractUrlsFromText(
      this.newsfeedPost.text
    );

    if (
      !urlsFromText.some((url) => url === this.newsfeedPost.linkPreview?.origin)
    ) {
      this.newsfeedPost.linkPreview = undefined;
    }
  }

  onVideoNameClick(): null {
    return null;
  }

  private initWatchPristineState() {
    this.$scope.$watch(
      () => (this.newsfeedPostForm ? this.newsfeedPostForm.$pristine : false),
      () => {
        if (this.newsfeedPostForm && !this.newsfeedPostForm.$pristine) {
          this.$scope.$emit('newsfeedPostFormTouched');
        }
      }
    );
  }

  private unsetFormPristine(): void {
    if (this.newsfeedPostForm) {
      this.newsfeedPostForm.$pristine = false;
    }
  }
}
