const PDF_FILE_TYPE = 'application/pdf';
const JPG_FILE_TYPE = 'image/jpeg';
const PNG_FILE_TYPE = 'image/png';
const SF_DOCUMENT_ITEM_CONSTANTS = {
  SVG_BASE_PATH: 'img/icons/',
  DOCUMENTS_FOLDER_PATH: 'downloaded',
  PDF_FILE_TYPE,
  JPG_FILE_TYPE,
  PNG_FILE_TYPE,
  TYPE_PROPERTIES: {
    [PDF_FILE_TYPE]: { svgName: 'document_pdf.svg', extension: 'pdf' },
    [JPG_FILE_TYPE]: { svgName: 'document_img.svg', extension: 'jpg' },
    [PNG_FILE_TYPE]: { svgName: 'document_img.svg', extension: 'png' },
    default: { svgName: '', extension: '' },
  },
};

export const DocumentItemComponent = {
  bindings: {
    document: '<',
  },
  templateUrl: 'digital-assets/components/document-item/document-item.html',
  controller: class DocumentItemController {
    // eslint-disable-next-line max-params
    constructor(
      imageService,
      filesSystemService,
      modalService,
      popupService,
      documentViewerService,
      documentDownloadService,
      errorMessagesService,
      SF_ERROR_CODES,
      SF_IMAGE_SIZES,
      imageSourceService,
      platformService
    ) {
      'ngInject';
      Object.assign(this, {
        imageService,
        filesSystemService,
        modalService,
        popupService,
        documentViewerService,
        documentDownloadService,
        errorMessagesService,
        SF_ERROR_CODES,
        SF_IMAGE_SIZES,
        imageSourceService,
        platformService,
      });
    }

    $onInit() {
      const properties =
        SF_DOCUMENT_ITEM_CONSTANTS.TYPE_PROPERTIES[this.document.type] ||
        SF_DOCUMENT_ITEM_CONSTANTS.TYPE_PROPERTIES.default;
      const extension = properties.extension;
      const svgName = properties.svgName;

      this.isBrowser = this.platformService.isBrowser();
      this.isDownloading = false;
      this.isDownloaded = false;
      this.extension = extension;
      this.svgPath = svgName
        ? SF_DOCUMENT_ITEM_CONSTANTS.SVG_BASE_PATH + svgName
        : '';
      this.fileName = this.removeFileNameExtension(
        this.document.name,
        this.extension
      );

      if (this.isBrowser) {
        return null;
      }

      return this.getFilePath(this.document._id, extension).then((filePath) => {
        this.filePath = filePath;

        this.filesSystemService
          .checkFile(filePath)
          .then((exist) => {
            this.isDownloaded = Boolean(exist);
          })
          .catch(() => {
            this.isDownloaded = false;
          });
      });
    }

    removeFileNameExtension(fileName, extension) {
      return fileName
        ? extension
          ? fileName.replace(new RegExp(`(.${extension})$`), '')
          : fileName
        : '';
    }

    onClick() {
      const imagesTypes = [PNG_FILE_TYPE, JPG_FILE_TYPE];
      const supportedTypes = [].concat(
        imagesTypes,
        SF_DOCUMENT_ITEM_CONSTANTS.PDF_FILE_TYPE
      );
      const isSupported = supportedTypes.includes(this.document.type);
      const isImage = imagesTypes.includes(this.document.type);

      if (!isSupported) {
        const errorCode = this.SF_ERROR_CODES.UNSUPPORTED_FILE_TYPE;
        const errorContent =
          this.errorMessagesService.getFileErrorMessageConfig(errorCode);

        return this.popupService.showError({
          ...errorContent,
          iconName: 'document_error',
        });
      }
      if (this.isBrowser) {
        const { _id, type } = this.document;

        return this.documentViewerService
          .openDocumentInBrowser({
            _id,
            mime_type: type,
          })
          .catch(() => undefined); // prevent sentry polluting from filesService.getFileBlob errors
      }
      if (isImage) {
        return this.openImage();
      }

      if (this.isDownloaded) {
        return this.openPdf();
      }

      return this.downloadDocument();
    }

    openPdf(path) {
      const filePath = path || this.filePath;

      return this.documentViewerService.openDocument(filePath);
    }

    openImage() {
      return this.imageService
        .getSizedUrlFromId(
          this.document._id,
          this.SF_IMAGE_SIZES.RECTANGLE_MEDIUM
        )
        .then((url) => {
          const template = `
            <sf-images-viewer
              images="[$ctrl.image]"
              on-close="$ctrl.onClose()">
            </sf-images-viewer>
          `;
          const bindings = {
            image: this.imageSourceService.create({ url }, false),
          };

          return this.modalService.open(template, bindings);
        });
    }

    downloadDocument() {
      this.isDownloading = true;
      const fileOptions = {
        directory: this.filesSystemService.getDeviceCachedPath(),
        path: SF_DOCUMENT_ITEM_CONSTANTS.DOCUMENTS_FOLDER_PATH,
        name: `${this.document._id}.${this.extension}`,
      };

      return this.documentDownloadService
        .download(
          fileOptions,
          this.imageSourceService.create({ id: this.document._id }, true)
        )
        .then((res) => {
          this.isDownloaded = true;
          return this.openPdf(res);
        })
        .finally(() => {
          this.isDownloading = false;
        });
    }

    getFilePath(docId, ext) {
      const path = SF_DOCUMENT_ITEM_CONSTANTS.DOCUMENTS_FOLDER_PATH;

      return this.filesSystemService.computeFileCachedPath(path, docId, ext);
    }
  },
};
