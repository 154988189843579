import { StateService } from '@uirouter/angularjs';

// sf-popup-synchronize
export class PopupSynchronizeController implements ng.IComponentController {
  // bindings
  onClose: () => void;
  onSuccess: () => void;

  isSynchronizing = false;

  constructor(private $state: StateService, private synchronizeService) {
    'ngInject';
  }

  onSubmit() {
    this.isSynchronizing = true;

    this.synchronizeService.synchronize().then(() => {
      this.$state.go('index.activity.details');
      this.onSuccess();

      this.isSynchronizing = false;
      this.onClose();
    });
  }
}
