import { PlacesList } from '@services/API/placesLists/placesLists';
import { UsersGroup } from '@services/API/users/users';
import { UsersService } from '@services/API/users/users.service';
import { DateFormatService } from '@services/Utils/Dates/date-format.service';
import { StateService } from '@uirouter/core';
import { ObjectId, TSFixMe, UserRef } from '../../..';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { FilesService } from '../../../services/API/files/files.service';
import { ActionSheetService } from '../../../services/Utils/ActionSheet/action-sheet.service';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';
import { NewsfeedApiService } from '../../services/newsfeed-api/newsfeed-api.service';
import { NewsfeedPopupService } from '../../services/newsfeed-popup/newsfeed-popup.service';
import { NewsfeedService } from '../../services/newsfeed-service/newsfeed.service';
import {
  NewsfeedPost,
  NewsfeedPostResource,
  ResourcePreview,
} from '../../types';

export class NewsfeedPostSimpleItemController
  implements ng.IComponentController
{
  //bindings
  post: NewsfeedPost;
  profile: TSFixMe;
  authorType: string;

  onEditPost: () => void;
  onReload: () => void;
  isChatLoading: boolean;

  pictureUrls: ResourcePreview[] = [];
  documents: ResourcePreview[] = [];
  videos: ResourcePreview[] = [];
  onPostUpdateListener: () => void;
  hasChatFeatureFlag: boolean;
  hasAlphaFlag: boolean;
  audience: string;
  dateFormatted: string;
  isDraft: boolean;

  // eslint-disable-next-line max-params
  constructor(
    private $state: StateService,
    private $q: ng.IQService,
    private filesService: FilesService,
    private pubSubService: PubSubService,
    private newsfeedApiService: NewsfeedApiService,
    private newsfeedPopupService: NewsfeedPopupService,
    private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
    private newsfeedService: NewsfeedService,
    private dateFormatService: DateFormatService,
    private actionSheetService: ActionSheetService,
    private $translate: ng.translate.ITranslateService,
    private chatService,
    private chatPopupService,
    private usersService: UsersService,
    private placesService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.getAudience().then((audience) => {
      this.audience = audience;
    });

    this.isDraft = !!this.post.contents.isDraft;

    this.dateFormatted = this.isDraft
      ? this.$translate.instant('NEWSFEED_MY_DRAFTS_DESC_DATE', {
          date: this.dateFormatService.getNewsfeedDraftDateFormatted(
            this.post.created_date
          ),
        })
      : this.$translate.instant('NEWSFEED_SCHEDULED_DESC_DATE', {
          date: this.dateFormatService.getNewsfeedDraftDateFormatted(
            this.post.contents.publication_date
          ),
        });

    this.hasChatFeatureFlag = this.chatService.hasFeatureFlag();
    this.hasAlphaFlag = this.newsfeedService.hasAlphaFeatureFlag();

    this.onPostUpdateListener = this.pubSubService.subscribe<{
      post: NewsfeedPost;
    }>('NEWSFEED_POST_UPDATED', ({ post }) => this.onUpdatePost(post));
  }

  $onDestroy(): void {
    this.onPostUpdateListener();
  }

  getAudience(): ng.IPromise<string> {
    const { targeting } = this.post;

    if (!targeting) {
      return Promise.resolve('All');
    }

    const count = targeting.links.length;
    const { type, _id } = targeting.links[0];

    switch (type) {
      case 'usersGroup':
        return this.usersService.getUsersGroup(_id).then(({ contents }) => {
          const { name } = contents as UsersGroup;
          return `${name} ${count >= 2 ? `(+${count - 1})` : ''}`;
        });

      default:
        return this.placesService
          .getStoreGroupById(_id)
          .then(({ contents }) => {
            const { name } = contents as PlacesList;
            return `${name} ${count >= 2 ? `(+${count - 1})` : ''}`;
          });
    }
  }

  onDeletePost(): ng.IPromise<void | void[]> {
    const title = this.isDraft
      ? 'NEWSFEED.MODAL.DRAFT.DELETE_TITLE'
      : 'NEWSFEED.MODAL.SCHEDULED.DELETE_TITLE';
    const text = 'BUTTON_DELETE';

    return this.newsfeedPopupService.showDeleteConfirm(title, text).then(() =>
      this.newsfeedApiService
        .deletePost(this.post._id)
        .then(() => this.deletePostFiles(this.post.contents.attachedResources))
        .catch(() => this.newsfeedPopupService.showPostNotExistsError())
        .finally(() => this.onReload())
    );
  }

  deletePostFiles(files: NewsfeedPostResource[] = []): ng.IPromise<void[]> {
    const filesIds = files
      .filter((file) => file.type !== 'video')
      .map((file) => file.file_id);
    const videoFilesIds = files
      .filter((file) => file.type == 'video')
      .map((file) => file.file_id);

    return this.$q.all([
      ...filesIds.map((fileId) => this.filesService.deleteFile(fileId, false)),
      ...videoFilesIds.map((videosIds) =>
        this.filesService.deleteFile(videosIds, true)
      ),
    ]);
  }

  onUpdatePost(post: NewsfeedPost): boolean {
    if (this.post._id !== post._id) {
      return false;
    }
    this.post = post;
    this.getAttachedResources();
    this.onReload();
    return true;
  }

  getAttachedResources(): ng.IPromise<void> {
    if (!(this.post && this.post.contents.attachedResources)) {
      return this.$q.resolve();
    }

    return this.newsfeedService
      .buildAttachedResourcesObjects(
        this.post.contents.attachedResources,
        this.SF_IMAGE_SIZES.SQUARE_LARGE
      )
      .then(({ images, documents, videos }) => {
        this.pictureUrls = images;
        this.documents = documents;
        this.videos = videos;
      });
  }

  startChat(author: UserRef): void {
    const postAuthorName = this.newsfeedService.getPostAuthorName(author);
    const startChatText =
      this.$translate.instant('NEWSFEED_SEND_CHAT_MESSAGE') +
      ` ${postAuthorName}`;

    const actions = [
      { text: startChatText, onClick: () => this.openChat(author._id) },
    ];
    const actionSheetConfig = {
      cancelText: this.$translate.instant('NEWSFEED_SEND_CHAT_CANCEL_BUTTON'),
      customClass: 'ellipsis-wrapped-actions',
    };

    this.actionSheetService.open(actions, actionSheetConfig);
  }

  openChat(userId: ObjectId): ng.IPromise<void> {
    this.isChatLoading = true;

    return this.chatService
      .getUserChannel(userId)
      .then(
        (channel?: { url: string }) => {
          if (!channel) {
            return this.createChat(userId);
          }
          return this.openChatRoom(channel.url);
        },
        () => this.createChat(userId)
      )
      .finally(() => {
        this.isChatLoading = false;
      });
  }

  createChat(userId: ObjectId): ng.IPromise<void> {
    const usersIds = [userId, this.profile._id];
    const progressPopup = this.chatPopupService.showChannelCreatingProgress();

    // sendbird handles itself the opening of already existed room or creating new one
    return this.chatService
      .createChannel(usersIds)
      .then((channel: { url: string }) =>
        progressPopup.onSuccess().then(() => {
          this.openChatRoom(channel.url);
        })
      )
      .catch((err: unknown) => {
        progressPopup.onError(() => this.openChat(userId));
        throw err;
      });
  }

  openChatRoom(channelUrl: string): void {
    this.$state.go('index.chat.channel', { channelUrl });
  }
}
