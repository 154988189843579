import { IAPIList, ObjectId } from '../../..';
import { PovService } from '../../../services/API/POV/pov.service';
import { NewsfeedPost } from '../../types';
import { LastSeenPostInfo } from '../newsfeed-service/newsfeed.service';

export class NewsfeedApiService {
  private baseUrl = '/newsfeed/posts';

  constructor(
    private $http: ng.IHttpService,
    private sfPOVService: PovService
  ) {
    'ngInject';
  }

  createPost(newsfeedPost, params = {}): ng.IPromise<NewsfeedPost> {
    return this.sfPOVService
      .pBuildURL(this.baseUrl, { pov: 'organisation' })
      .then((uri) =>
        this.$http.post<NewsfeedPost>(uri, newsfeedPost, { params })
      )
      .then((res) => res.data);
  }

  updatePost(newsfeedPost, postId: ObjectId): ng.IPromise<NewsfeedPost> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/${postId}`, { pov: 'organisation' })
      .then((uri) => this.$http.patch<NewsfeedPost>(uri, newsfeedPost))
      .then((res) => res.data);
  }

  deletePost(postId: ObjectId): ng.IPromise<NewsfeedPost> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/${postId}`, { pov: 'organisation' })
      .then((uri) => this.$http.delete<NewsfeedPost>(uri))
      .then((res) => res.data);
  }

  getPosts(params = {}): ng.IPromise<IAPIList<NewsfeedPost>> {
    return this.sfPOVService
      .pBuildURL(this.baseUrl, { pov: 'organisation' })
      .then((uri) => this.$http.get<IAPIList<NewsfeedPost>>(uri, { params }))
      .then((res) => res.data);
  }

  get(postId: ObjectId): ng.IPromise<NewsfeedPost> {
    return this.sfPOVService
      .pBuildURL(this.baseUrl, { pov: 'organisation' })
      .then((uri) => this.$http.get<NewsfeedPost>(`${uri}/${postId}`))
      .then((res) => res.data);
  }

  likePost(postId) {
    return this.sfPOVService
      .pBuildURL(this.baseUrl, { pov: 'organisation' })
      .then((uri) => this.$http.post(`${uri}/${postId}/like`, {}));
  }

  unlikePost(postId) {
    return this.sfPOVService
      .pBuildURL(this.baseUrl, { pov: 'organisation' })
      .then((uri) => this.$http.post(`${uri}/${postId}/unlike`, {}));
  }

  getLastSeenPostInfo(params): ng.IPromise<LastSeenPostInfo> {
    return this.sfPOVService
      .pBuildURL(this.baseUrl, { pov: 'organisation' })
      .then((uri) =>
        this.$http.get<LastSeenPostInfo>(`${uri}/counts`, { params })
      )
      .then((res) => res.data);
  }
}
