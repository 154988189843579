const NB_PROGRESS_STEP = 5;
const INTERVAL_UPDATE = 500;

export const ProgressBarAutoComponent = {
  bindings: {
    nb: '<',
    current: '<',
  },
  templateUrl: 'components/Progress/progress-bar-auto/progress-bar-auto.html',
  controller: class ProgressBarAutoController {
    constructor($timeout) {
      'ngInject';
      Object.assign(this, {
        $timeout,
      });
    }

    $onInit() {
      this.max = 100; // In percent
      this.step = this.max / this.nb;
      this.stepAuto = this.step / NB_PROGRESS_STEP;
      this.percent = 0;

      this.setValue(this.current);
    }
    $onDestroy() {
      this.cancelTimeout();
    }
    $onChanges(changes) {
      if (changes.current) {
        this.setValue(changes.current.currentValue);
      }
    }

    setValue(value) {
      let progressValue = value * this.step;

      this.$timeout.cancel(this.timeout);
      this.progress(progressValue);
      if (this.max >= progressValue) {
        this.timeout = this.$timeout(() => this.tryProgress(), INTERVAL_UPDATE);
      }
    }

    /**
     * Try to simulate a progression.
     */
    tryProgress() {
      const limit = this.step * (this.current + 1);

      if (this.percent + this.stepAuto < limit) {
        this.progress();
        this.timeout = this.$timeout(() => this.tryProgress(), INTERVAL_UPDATE);
        return;
      }

      this.cancelTimeout();
    }

    cancelTimeout() {
      if (this.timeout) {
        this.$timeout.cancel(this.timeout);
      }
    }

    /**
     * Set the percent value to the bar.
     *
     * @param  {[Number]} value - Value of percent to set.
     * @return {Number}         - New percent value set.
     */
    progress(value) {
      this.percent = value ? value : this.percent + this.stepAuto;
      this.percent = this.percent > 100 ? 100 : this.percent;

      return this.percent;
    }
  },
};
