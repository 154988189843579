import { pathOr } from 'ramda';
import { User, UserProfileType } from '../../../index';

export const ADMIN_AT_LEAST: UserProfileType[] = ['admin', 'root'];
export const CONTENT_MANAGER_AT_LEAST: UserProfileType[] = [
  ...ADMIN_AT_LEAST,
  'contentManager',
];
export const MANAGER_AT_LEAST: UserProfileType[] = [
  ...CONTENT_MANAGER_AT_LEAST,
  'manager',
];

export class AccessRightsService {
  private _profile: User | null;

  init(profile: User) {
    this._profile = profile;
  }

  reset() {
    this._profile = null;
  }

  // Check each rights
  isRoot(): boolean {
    return this.hasProfile('root');
  }

  isAdmin(): boolean {
    return this.hasProfile('admin');
  }

  isContentManager(): boolean {
    return this.hasProfile('contentManager');
  }

  isManager(): boolean {
    return this.hasProfile('manager');
  }

  isContributor(): boolean {
    return !(
      this.isRoot() ||
      this.isAdmin() ||
      this.isContentManager() ||
      this.isManager()
    );
  }

  // Check minimum rights
  isAtLeastAdmin(): boolean {
    return this.hasSomeProfiles(ADMIN_AT_LEAST);
  }
  isAtLeastManager(): boolean {
    return this.hasSomeProfiles(MANAGER_AT_LEAST);
  }
  isAtLeastContentManager(): boolean {
    return this.hasSomeProfiles(CONTENT_MANAGER_AT_LEAST);
  }

  hasProfile(profile: UserProfileType): boolean {
    const userProfiles = this.getProfileProfiles();

    return userProfiles.includes(profile);
  }
  hasSomeProfiles(profiles: UserProfileType[]): boolean {
    const userProfiles: UserProfileType[] = this.getProfileProfiles();

    return profiles.some((p) => userProfiles.includes(p));
  }

  // Helpers
  getProfileProfiles(): UserProfileType[] {
    return pathOr([], ['contents', 'profiles'], this._profile);
  }

  get profile(): User | null {
    return this._profile;
  }
}
