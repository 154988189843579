import { MissionComponent } from './mission.component';

export default angular
  .module('sfMobile.components.mission', [
    'sfMobile.services.missions',
    'sfMobile.services.reports',
    'sfMobile.services.forms',
    'sfMobile.services.campaignsSelector',
    'sfMobile.services.helpers',
    'sfMobile.services.dates',
    'sfMobile.components.imageFallback',
    'sfMobile.components.button-document',
    'sfMobile.components.button-loader',
  ])
  .component('sfMission', MissionComponent).name;
