export const ChatRoomInfoUserComponent = {
  bindings: {
    userName: '@',
    onRemoveUser: '&?',
  },
  template: `
  <div class="item item-action item-icon-right sf_chat_room_info_user">
    {{ $ctrl.userName }}
    <i class="icon icon-big fa-light fa-xmark"
      ng-if="$ctrl.onRemoveUser"
      ng-click="$ctrl.onRemoveUser()">
    </i>
  </div>
  `,
};
