const MERCH_ITEM_LIMIT = 20;

export const MerchandisingListComponent = {
  bindings: {
    profile: '<',
    state: '@',
    reloadFn: '=',
    onSaveSuccess: '&',
    onSaveFail: '&',
    contentKey: '@',
  },
  templateUrl: 'merchandising/views/merchandising-list/merchandising-list.html',
  controller: function MerchandisingListController(
    merchandisingApiService,
    RequestsPaginate
  ) {
    'ngInject';
    this.$onInit = () => {
      this.reloadFn = this.reload;
      this.requestOption = merchandisingApiService.getValidationsRequestOptions(
        this.state
      );

      this.requestPaginate = new RequestsPaginate(
        merchandisingApiService.getValidations,
        {
          limit: MERCH_ITEM_LIMIT,
        }
      );

      return this.loadValidation();
    };

    this.reload = () => {
      this.requestPaginate.reset();

      return this.loadValidation();
    };

    this.loadValidation = () => {
      this.isLoading = true;
      this.networkError = false;
      this.infiniteLoadError = false;

      return this.getValidations()
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.getValidations = () =>
      this.requestPaginate.call(this.requestOption).then((validations) => {
        this.validations = validations.entities;
      });

    this.getNextValidations = () => {
      this.infiniteLoadError = false;

      return this.getValidations().catch(() => {
        this.infiniteLoadError = true;
      });
    };

    this.resetInfiniteLoadErrorState = () => {
      this.infiniteLoadError = false;
    };
  },
};
