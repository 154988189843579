import { AssigneesModalComponent } from './calendar/assignees-modal/assignees-modal.component';
import { CalendarEventFormComponent } from './calendar/calendar-event-form/calendar-event-form.component';
import { CalendarEventListItemComponent } from './calendar/calendar-event-list-item/calendar-event-list-item.component';
import { CalendarEventsListComponent } from './calendar/calendar-events-list/calendar-events-list.component';
import { CreateCalendarEventModalComponent } from './calendar/create-calendar-event-modal/create-calendar-event-modal.component';
import { EditCalendarEventModalComponent } from './calendar/edit-calendar-event-modal/edit-calendar-event-modal.component';
import { EventAssigneesComponent } from './calendar/event-assignees/event-assignees.component';
import { MonthYearSelectorComponent } from './calendar/month-year-selector/month-year-selector.component';
import { RequestEventChangesModalComponent } from './calendar/request-event-changes-modal/request-event-changes-modal.component';
import { RequestEventCreationModalComponent } from './calendar/request-event-creation-modal/request-event-creation-modal.component';
import { DetailsEventModalComponent } from './details-calendar-event-modal/details-event-modal.component';

import { ManagerCalendarEventListItemComponent } from './manager-calendar/manager-calendar-event-list-item/manager-calendar-event-list-item.component';
import { ManagerCalendarEventsListComponent } from './manager-calendar/manager-calendar-events-list/manager-calendar-events-list.component';

import { CorporateEventDetailsModalComponent } from './calendar/corporate-events/corporate-event-details-modal/corporate-event-details-modal.component';
import { CorporateEventItemComponent } from './calendar/corporate-events/corporate-event-item/corporate-event-item.component';
import { CorporateEventsListComponent } from './calendar/corporate-events/corporate-events-list/corporate-events-list.component';
export default angular
  .module('sfMobile.calendar-events.components', [
    'pascalprecht.translate',
    'sfMobile.services.popup-request',
    'sfMobile.services.preferences',
    'sfMobile.services.files',
    'sfMobile.components.input-radio-buttons-bar',
    'sfMobile.components.entity-selector',
    'sfMobile.components.textarea',
    'sfMobile.components.view-content-states',
    'sfMobile.components.spinner-floating',
    'sfMobile.components.button-header-close',
    'sfMobile.components.date-time-range-timezone',
    'sfMobile.services.preferences',
    'sfMobile.components.status-badge',
    'sfMobile.constants.calendar-events',
    'sfMobile.components.custom-params-selector',
  ])
  .component('sfEventsAssigneesModal', AssigneesModalComponent)
  .component('sfCalendarEventForm', CalendarEventFormComponent)
  .component('sfCalendarEventListItem', CalendarEventListItemComponent)
  .component('sfCalendarEventsList', CalendarEventsListComponent)
  .component(
    'sfManagerCalendarEventListItem',
    ManagerCalendarEventListItemComponent
  )
  .component('sfManagerCalendarEventsList', ManagerCalendarEventsListComponent)
  .component('sfCreateCalendarEventModal', CreateCalendarEventModalComponent)
  .component('sfDetailsEventModal', DetailsEventModalComponent)
  .component('sfEditCalendarEventModal', EditCalendarEventModalComponent)
  .component('sfEventAssignees', EventAssigneesComponent)
  .component('sfMonthYearSelector', MonthYearSelectorComponent)
  .component('sfRequestEventChangesModal', RequestEventChangesModalComponent)
  .component('sfRequestEventCreationModal', RequestEventCreationModalComponent)
  .component('sfCorporateEventItem', CorporateEventItemComponent)
  .component(
    'sfCorporateEventDetailsModal',
    CorporateEventDetailsModalComponent
  )
  .component('sfCorporateEventsList', CorporateEventsListComponent).name;
