import { Option } from '@simplifield/webcomponents/dist/types/components/multi-select/multi-select';

import { DateService } from '@services/Utils/Dates/date.service';
import { APIStore } from '../../..';
import {
  CustomParamAPI,
  CustomParameter,
  IAPIList,
  PlacesEntity,
} from '../../../..';
import { ButtonSelectorOption } from '../../../../components/Buttons/button-selector/button-selector.controller';
import {
  CategorizedParam,
  PlacesParamsService,
} from '../../../../services/API/places-params/places-params.service';
import { LocalizationService } from '../../../../services/Utils/Localization/localization.service';
import { PubSubService } from '../../../../services/Utils/PubSub/pubsub.service';
import { SegmentService } from '../../../../services/Utils/Segment/segment.service';
import { PlacePopupService } from '../../services/place-popup.service';

type EditedPlace = APIStore;
type DisplayedParam = CategorizedParam & { active: boolean };

export const PlacesInfosEditComponent = {
  bindings: {
    place: '<',
    onClose: '&',
  },
  templateUrl: 'places/place/components/place-infos-edit/place-infos-edit.html',
  controller: class PlaceInfosEditController {
    // bindings
    place: EditedPlace;
    onClose: (arg?: { place: EditedPlace }) => void;

    // members
    hasError = false;
    pendingRecording: boolean;
    PLACE_INFOS_EDIT_POPUP_SAVE_CONFIG: {
      progress: { title: string; desc: string };
      error: { title: string; desc: string };
    };

    isDataLoaded = false;
    isLoading: boolean;
    paramsDisplayed: DisplayedParam[];
    categorizedParams: CategorizedParam[];
    canEditPlaces: boolean;
    paramsCategories: ButtonSelectorOption[] = [];
    possibleMultiChoiceValuesMap: Record<string, Option[] | undefined>;
    valuesMap: Record<string, CustomParameter['value']> = {};
    isRTLNeeded: boolean;

    constructor(
      private localizationService: LocalizationService,
      private $translate: ng.translate.ITranslateService,
      private placePopupService: PlacePopupService,
      private $q: ng.IQService,
      private placesService,
      private placesParamsService: PlacesParamsService,
      private segmentService: SegmentService,
      private popupRequestService,
      private pubSubService: PubSubService,
      private dateService: DateService
    ) {
      'ngInject';
    }

    $onInit() {
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
      this.pendingRecording = false;
      this.PLACE_INFOS_EDIT_POPUP_SAVE_CONFIG = {
        progress: {
          title: this.$translate.instant('PLACE_INFOS_EDIT_SENDING_TITLE'),
          desc: this.$translate.instant('PLACE_INFOS_EDIT_SENDING_DESC'),
        },
        error: {
          title: this.$translate.instant('PLACE_INFOS_EDIT_SEND_ERROR_TITLE'),
          desc: this.$translate.instant('PLACE_INFOS_EDIT_SEND_ERROR_DESC'),
        },
      };

      return this.setCanEditPlaces().then(() => this.callData());
    }

    callData(): ng.IPromise<void> {
      this.hasError = false;
      this.isLoading = true;
      this.isDataLoaded = false;

      return this.$q
        .all([
          this.placesParamsService.listPlaceParamsKeys(),
          this.placesService.getRemote(this.place._id),
          this.placesParamsService.loadCategories(),
        ])
        .then((data) => this.updateData(data))
        .catch((err) => {
          this.hasError = true;
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
          this.isDataLoaded = true;
        });
    }

    updateData(
      datas: [
        IAPIList<CustomParamAPI<PlacesEntity>>,
        APIStore,
        ButtonSelectorOption[]
      ]
    ): void {
      const [params, place, categories] = datas;
      this.initValueMap(params.entries, place.contents.params);
      this.preparePossibleValuesMap(params.entries);
      this.categorizedParams = this.placesParamsService.getCategorizedParams(
        params,
        categories,
        this.place.contents.params
      );
      this.paramsDisplayed = this.categorizedParams.map<DisplayedParam>(
        (catParams) => ({
          active: true,
          ...catParams,
        })
      );
      this.place = place;
      this.valuesMap = this.place.contents.params.reduce((output, param) => {
        output[param.param_id] = param.value;
        return output;
      }, {});
    }

    initValueMap(
      customParams: CustomParamAPI<PlacesEntity>[],
      placeParams: CustomParameter[]
    ): void {
      this.valuesMap = customParams.reduce((acc, param) => {
        const value = placeParams.find(
          (paramValue) => paramValue.param_id === param._id
        )?.value;

        acc[param._id] = value;
        return acc;
      }, {});
    }

    preparePossibleValuesMap(params: CustomParamAPI<PlacesEntity>[]): void {
      this.possibleMultiChoiceValuesMap = params.reduce((acc, param) => {
        if (param.contents.type === 'MultipleChoice' && param._id) {
          const selectedValues = this.valuesMap[param._id] as string[];
          const options =
            param.contents.predefinedValues?.map((option) => ({
              label: option.value.toString(),
              value: option.id,
              selected: selectedValues?.includes(option.id) || false,
            })) || [];
          acc[param._id] = options;
        }
        return acc;
      }, {});
    }

    updateOptions(event: CustomEvent<Option[]>, id: string): void {
      this.valuesMap[id] = event.detail.map((option) => option.value);
    }

    save(): ng.IPromise<void> {
      const paramsEdit = this.valuesMap;
      const newParams = Object.keys(paramsEdit)
        .reduce(
          (output, paramKey) =>
            output.concat({
              value: paramsEdit[paramKey],
              param_id: paramKey,
            }),
          [] as CustomParameter[]
        )
        .filter((param) => !!param.value);

      const dataToSave = {
        _id: this.place._id,
        id: this.place._id,
        contents: this.place.contents,
      };
      const savePromise = this.$q.defer();
      const popupRequest = this.popupRequestService.show(
        this.PLACE_INFOS_EDIT_POPUP_SAVE_CONFIG,
        () => savePromise.resolve()
      );

      dataToSave.contents.params = newParams;
      this.place.contents.params = newParams;

      this.pendingRecording = true;

      this.segmentService.track('PLACE_EDITION', {
        action: 'save',
        label: 'Edition saving',
      });

      return this.placesService
        .save(dataToSave, {
          canceler: savePromise,
        })
        .then((data) =>
          popupRequest.onSuccess().then(() => {
            this.pubSubService.publish('PLACE_PARAMS_UPDATED', data);
            return data;
          })
        )
        .catch((err) => {
          popupRequest.onError(() => this.save());
          throw err;
        })
        .finally(() => {
          this.pendingRecording = false;
          this.onClose({ place: this.place });
        });
    }

    close(): void | ng.IPromise<void | null> {
      // Close directly if there is no change
      if (!this.hasPlaceInfosChanged()) {
        return this.onClose();
      }

      return this.placePopupService
        .showCloseEditConfirm()
        .then(() => this.onClose({ place: this.place }))
        .catch(() => null); // cancelled confirm popup rejects and produces console error
    }

    toggleCollapse(index: number): void {
      this.paramsDisplayed[index].active = !this.paramsDisplayed[index].active;
    }

    hasPlaceInfosChanged(): boolean {
      const paramsValuesHash = this.valuesMap;
      const findParam = (id) =>
        this.place.contents.params.filter(
          (param) => param.param_id === id
        )[0] || {};

      return Object.keys(paramsValuesHash).some((paramId) => {
        const paramEdit = paramsValuesHash[paramId];

        return (findParam(paramId).value || null) !== (paramEdit || null);
      });
    }

    onFormSubmit(): void {
      const selectedInput = document.activeElement as HTMLInputElement;

      return selectedInput?.blur();
    }

    setCanEditPlaces(): ng.IPromise<void> {
      return this.placesService.isAllowedToSave().then((isAllowed) => {
        this.canEditPlaces = isAllowed;
      });
    }
  },
};
