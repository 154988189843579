import merchandisingRouteConfig from './merchandising.routes';
import MerchandisingConstantModule from './merchandising.constant';

import MerchandisingCommentModule from './services/merchandising-comment.module';
import MerchandisingModalModule from './services/merchandising-modal.module';
import MerchandisingPopupModule from './services/merchandising-popup.module';
import MerchandisingModule from './services/merchandising.module';

import { MerchandisingCommentComponent } from './components/merchandising-comment/merchandising-comment.component';
import { MerchandisingCommentsComponent } from './components/merchandising-comments/merchandising-comments.component';
import { MerchandisingDrawSelectorComponent } from './components/merchandising-draw-selector/merchandising-draw-selector.component';
import { MerchandisingInfosComponent } from './components/merchandising-infos/merchandising-infos.component';
import { MerchandisingItemComponent } from './components/merchandising-item/merchandising-item.component';
import { MerchandisingPhotoDrawingComponent } from './components/merchandising-photo-drawing/merchandising-photo-drawing.component';
import { MerchandisingPhotoReplacerComponent } from './components/merchandising-photo-replacer/merchandising-photo-replacer.component';
import { MerchandisingPhotoSelectorComponent } from './components/merchandising-photo-selector/merchandising-photo-selector.component';
import { MerchandisingPhotoSliderComponent } from './components/merchandising-photo-slider/merchandising-photo-slider.component';
import { MerchandisingReplacerSelectorComponent } from './components/merchandising-replacer-selector/merchandising-replacer-selector.component';
import { MerchandisingStateTagComponent } from './components/merchandising-state-tag/merchandising-state-tag.component';

import { MerchandisingDetailsComponent } from './views/merchandising-details/merchandising-details.component';
import { MerchandisingListComponent } from './views/merchandising-list/merchandising-list.component';
import { MerchandisingTabsComponent } from './views/merchandising-tabs/merchandising-tabs.component';

export default angular
  .module('sfMobile.merchandising', [
    'sfMobile.comments',
    'sfMobile.constants.image-sizes',
    'sfMobile.services.image-source',
    'sfMobile.services.requests-paginate',
    'sfMobile.services.picture-system',
    'sfMobile.services.files',
    'sfMobile.services.tabs',
    'sfMobile.services.pubsub',
    'sfMobile.services.objectId',
    MerchandisingConstantModule,
    MerchandisingCommentModule,
    MerchandisingModalModule,
    MerchandisingPopupModule,
    MerchandisingModule,
  ])
  .component('sfMerchandisingComment', MerchandisingCommentComponent)
  .component('sfMerchandisingComments', MerchandisingCommentsComponent)
  .component('sfMerchandisingDetails', MerchandisingDetailsComponent)
  .component('sfMerchandisingDrawSelector', MerchandisingDrawSelectorComponent)
  .component('sfMerchandisingInfos', MerchandisingInfosComponent)
  .component('sfMerchandisingItem', MerchandisingItemComponent)
  .component('sfMerchandisingList', MerchandisingListComponent)
  .component('sfMerchandisingPhotoDrawing', MerchandisingPhotoDrawingComponent)
  .component(
    'sfMerchandisingPhotoReplacer',
    MerchandisingPhotoReplacerComponent
  )
  .component(
    'sfMerchandisingPhotoSelector',
    MerchandisingPhotoSelectorComponent
  )
  .component('sfMerchandisingPhotoSlider', MerchandisingPhotoSliderComponent)
  .component(
    'sfMerchandisingReplacerSelector',
    MerchandisingReplacerSelectorComponent
  )
  .component('sfMerchandisingStateTag', MerchandisingStateTagComponent)
  .component('sfMerchandisingTabs', MerchandisingTabsComponent)
  .config(merchandisingRouteConfig).name;
