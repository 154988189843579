const NB_PICTURES_BY_ROW = 3;
const NB_PICTURES_FIRST_ROW = 6;
const NB_PICTURES_REQ_ROW = 4;
const NB_PICTURES_FIRST_LIMIT = NB_PICTURES_BY_ROW * NB_PICTURES_FIRST_ROW;
const NB_PICTURES_REQ_LIMIT = NB_PICTURES_BY_ROW * NB_PICTURES_REQ_ROW;
const GALLERY_LIST_SEARCH_CRITERIAS = [
  'campaign_title',
  'user_firstName',
  'user_lastName',
  'question_title',
  'pos_name',
  'tags',
];

const getPictureId = (picture) => picture._id;
const getPictureName = (picture) => picture.contents.fileName;
const getPictureMime = (picture) => picture.contents.fileType;

export const GalleryListComponent = {
  bindings: {
    key: '@',
    contentKey: '@',
    contentName: '<',
    requestFilters: '<?',
    maxPictures: '<?',
    isSearchHidden: '<?',
  },
  templateUrl: 'digital-assets/components/gallery-list/gallery-list.html',
  // eslint-disable-next-line max-params
  controller: function GalleryListController(
    $q,
    contentService,
    imageService,
    reportsService,
    apiUtilsService,
    RequestsPaginate,
    dateFormatService,
    modalService,
    SF_IMAGE_SIZES,
    imageSourceService,
    $stateParams,
    tagsSelectorService,
    profileService,
    TAG_LABEL_LENGTH,
    platformService,
    campaignsUtilsService,
    campaignsService
  ) {
    'ngInject';
    const { buildFilterParams } = apiUtilsService;
    profileService.getProfile().then((profile) => {
      this.profile = profile;
    });
    if ($stateParams.campaignId) {
      campaignsService.getOne($stateParams.campaignId).then((campaign) => {
        this.campaign = campaign;
      });
    }

    this.getPictureId = getPictureId;

    this.$onInit = () => {
      this.isBrowser = platformService.isBrowser();
      this.tags = [];
      tagsSelectorService.getTags().then((tags) => {
        this.tags = tags;
      });
      const limit = this.maxPictures ? this.maxPictures : NB_PICTURES_REQ_LIMIT;
      const firstFetchLimit = this.maxPictures
        ? this.maxPictures
        : NB_PICTURES_FIRST_LIMIT;

      this.requestPaginate = new RequestsPaginate(
        reportsService.getImagesMedias.bind(reportsService),
        {
          limit,
          firstFetchLimit,
          relatedEntitiesKeys: ['forms', 'places', 'users', 'campaigns'],
        }
      );
      this.picturesFiltered = [];
      this.reportsList = new Map();
      this.picturesPath = {};
      this.picturesTallPath = {};
      this.isSearchLoading = false;

      this.initGalleryView();

      return this.callPictures();
    };

    this.callPictures = () => {
      this.isLoading = true;

      return this.getPictures()
        .then((pictures) => {
          this.networkError = false;

          if (!this.searchField) {
            this.hasPictures = !!pictures.length;
          }

          if ($stateParams.openPreviewWithAnswerId) {
            const index = pictures.findIndex(
              (picture) =>
                picture.contents.answer_id ===
                $stateParams.openPreviewWithAnswerId
            );

            this.viewPicture(index);
          }

          return pictures;
        })
        .catch(this.requestError)
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.initGalleryView = () => {
      this.picturesFiltered.length = 0;
      this.infiniteLoadError = false;

      this.requestPaginate.reset();

      contentService.scrollTopById(this.contentkey);
    };

    this.resetInfiniteLoadErrorState = () => {
      this.infiniteLoadError = false;
    };

    this.getPictures = () => {
      const filterParams = this.composeFilterParamsReq();

      this.infiniteLoadError = false;

      return this.requestPaginate
        .call(filterParams)
        .then((datas) => {
          const answers = datas.entities;

          const picturesUrlPromises = answers.map((picture) => {
            if (!this.reportsList.has(picture.contents.report_id)) {
              this.reportsList.set(picture.contents.report_id, {});
              reportsService.crud
                .getRemote(picture.contents.report_id)
                .then((report) => {
                  this.reportsList.set(picture.contents.report_id, report);
                });
            }
            const imageId = getPictureId(picture);

            return imageService
              .getSizesHashFromId(imageId, [
                SF_IMAGE_SIZES.SQUARE_MEDIUM,
                SF_IMAGE_SIZES.SQUARE_BIG,
              ])
              .then((urls) => {
                this.picturesPath[imageId] = urls[SF_IMAGE_SIZES.SQUARE_MEDIUM];
                this.picturesTallPath[imageId] =
                  urls[SF_IMAGE_SIZES.SQUARE_BIG];
              })
              .catch(() => null);
          });

          this.picturesFiltered = answers;
          this.relatedEntities = datas.relatedEntities;

          return $q.all(picturesUrlPromises).then(() => answers);
        })
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        })
        .finally(() => {
          if (this.onScrollComplete && !this.isBrowser) {
            this.onScrollComplete();
          }
        });
    };

    this.getTaglabel = (image) => {
      const tagsIds = image.contents.tags_ids;
      if (!tagsIds) {
        return;
      }

      let maxLength = TAG_LABEL_LENGTH.ONE_TAG;

      const trimAndAddEllipsis = (inputString) => {
        if (inputString.length >= maxLength) {
          return inputString.slice(0, maxLength - 3) + '...';
        } else {
          return inputString;
        }
      };

      const tagsFiltered = this.tags.filter(({ _id }) => tagsIds.includes(_id));
      if (tagsFiltered.length >= 2) {
        maxLength = TAG_LABEL_LENGTH.TWO_OR_MORE;
      }

      if (tagsFiltered.length) {
        return `${trimAndAddEllipsis(tagsFiltered[0].contents.name)} ${
          tagsFiltered.length >= 2 ? `(+${tagsFiltered.length - 1})` : ''
        }`;
      }
    };

    this.searchPictures = () => {
      this.isSearchLoading = true;

      this.initGalleryView();

      return this.getPictures()
        .catch(this.requestError)
        .finally(() => {
          this.isSearchLoading = false;
        });
    };

    this.composeFilterParamsReq = () =>
      buildFilterParams(this.requestFilters, {
        search: this.searchField,
        criterias: GALLERY_LIST_SEARCH_CRITERIAS,
      });

    this.requestError = (err) => {
      this.networkError = true;
      throw err;
    };

    this.reload = () => this.callPictures();

    this.viewPicture = async (activeSlideId) => {
      const template = `
        <sf-preview-modal
          on-close="$ctrl.onPreviewClose()"
          active-slide-id="$ctrl.activeSlideId"
          content-name="$ctrl.contentName"
          images="$ctrl.images"
          pictures-filtered="$ctrl.picturesFiltered"
          params="$ctrl.params"
        ></sf-preview-modal>
      `;

      const isTagsRequired = (campaign, questionId) => {
        const question = campaign.form.questions.find(
          ({ _id }) => _id === questionId
        );

        return question ? question.tagsRequired : null;
      };

      const params = this.picturesFiltered
        ? this.picturesFiltered.map((picture) => {
            const form = this.relatedEntities.forms[picture.contents.form_id];
            const campaign =
              this.relatedEntities.campaigns[picture.contents.campaign_id];
            const place =
              this.relatedEntities.places[picture.contents.place_id];
            const user = this.relatedEntities.users[picture.contents.user_id];
            const pictureQuestionTitle = picture.contents.question_title;
            let translations = {};

            if (form && form.i18n) {
              translations = form.i18n;
            } else if (campaign && campaign.i18n) {
              translations = campaign.i18n;
            }

            return {
              address: place ? place.formatted_address : null,
              report_date: dateFormatService.getDateAndTimeFormatted(
                picture.contents.reportSaved_date
              ),
              campaign_title: campaign ? campaign.name : null,
              question_title: pictureQuestionTitle,
              user_name: user ? `${user.firstName} ${user.lastName}` : null,
              place_name: place ? place.name : null,
              translations,
              report_id: picture.contents.report_id,
              campaign_id: picture.contents.campaign_id,
            };
          })
        : [];

      const imagesPromises = this.picturesFiltered.map(async (p) => {
        const campaign = this.relatedEntities.campaigns[p.contents.campaign_id];

        const id = getPictureId(p);
        const name = getPictureName(p);
        const type = getPictureMime(p);
        const url = this.picturesTallPath[id];
        const answerId = p.contents.answer_id;
        const tagsIds = p.contents.tags_ids;
        const tagsRequired = campaign
          ? isTagsRequired(campaign, p.contents.question_id)
          : null;
        let canEditTags = false;
        if (p.contents.report_id) {
          const photoCampaign = this.campaign
            ? this.campaign
            : await campaignsService.getOne(p.contents.campaign_id);
          const report = this.reportsList.get(p.contents.report_id);
          canEditTags = campaignsUtilsService.canEditReport(
            report,
            photoCampaign,
            this.profile
          );
        }
        return imageSourceService.create(
          { id, url, name, type, answerId, tagsIds, canEditTags, tagsRequired },
          false
        );
      });

      const images = await Promise.all(imagesPromises);

      const bindings = {
        activeSlideId,
        contentName: this.contentName,
        images,
        params,
        onPreviewClose: () => {
          this.searchPictures();

          bindings.onClose();
        },
      };

      return modalService.open(template, bindings);
    };
  },
};
