import { ForegroundNotificationService } from '../../../services/Utils/ForegroundNotification/foregroundNotification.service';

export const DocumentLibraryComponent: ng.IComponentOptions = {
  templateUrl: 'document-library/views/document-library/document-library.html',
  bindings: {
    organisation: '<',
  },
  controller: class DocumentLibraryController implements ng.IController {
    organisation;
    isInNavBar = false;

    constructor(
      private keyboardService,
      private KEYBOARD_MODES,
      private foregroundNotificationService: ForegroundNotificationService,
      private userExperienceService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.keyboardService.registerStateMode(this.KEYBOARD_MODES.NATIVE);
      this.foregroundNotificationService.startForegroundService();
      this.isInNavBar = this.userExperienceService
        .getNav(this.organisation)
        .find((nav) => nav.key === 'documentLibrary');
    }

    $onDestroy(): void {
      this.keyboardService.deRegisterStateMode();
      this.foregroundNotificationService.stopForegroundService();
    }
  },
};
