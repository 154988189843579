export const SF_PLACE_SORTERS = [
  {
    key: 'place_name',
    sortBy: 'contents.name',
    label: 'PLACES_SORT_BY_NAME',
    desc: 'PLACES_SORT_BY_NAME_DESC',
  },
  {
    key: 'place_distance',
    sortBy: 'universalDistance',
    label: 'PLACES_SORT_BY_DISTANCE',
    desc: 'PLACES_SORT_BY_DISTANCE_DESC',
  },
] as const;
