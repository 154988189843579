import {
  ActionSheetService,
  ActionSheetConfig,
  ActionSheetAction,
} from '../../../services/Utils/ActionSheet/action-sheet.service';
import { PICTURE_SYSTEM_IDENTIFIERS } from '../../../constants/picture-system.constants';
import { FileChooserService } from '../../../services/Utils/FileChooser/file-chooser.service';
import { ObjectId, User } from '../../..';
import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import { PictureSystemService } from '../../../services/Utils/PictureSystem/picture-system.service';
import { CameraService } from '../../../services/Utils/Camera/camera.service';
import { FILES_UPLOAD_OPTIONS } from '../../../constants/files-upload-options.constant';
import type { ModalService } from '../../../services/Utils/Modal';
import type { PopupService } from '../../../services/Utils/Popup/popup.service';
import type { PlatformService } from '../../../services/Utils/Platform';
import type { FilesService } from '../../../services/API/files/files.service';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';
import { UsersService } from '../../../services/API/users/users.service';
import { FileSystemService } from '../../../services/Utils/FileSystem/file-system.service';
import { ImageService } from '../../../services/Utils/Image/image.service';

export class ProfilePictureComponentController {
  profile: User;

  currentAvatarId: ObjectId | null | undefined;
  isBrowser = false;
  isPendingUpload = false;
  pictureEditActions: {
    actions: ActionSheetAction[];
    actionSheetConfig: ActionSheetConfig;
    onDestructiveClick: () => void;
  };

  // eslint-disable-next-line max-params
  constructor(
    private $q: ng.IQService,
    private pubSubService: PubSubService,
    private $window: ng.IWindowService,
    private $translate: ng.translate.ITranslateService,
    private actionSheetService: ActionSheetService,
    private platformService: PlatformService,
    private cameraService: CameraService,
    private filesSystemService: FileSystemService,
    private pictureSystemService: PictureSystemService,
    private modalService: ModalService,
    private objectIdService: ObjectIdService,
    private filesService: FilesService,
    private profileService,
    private usersService: UsersService,
    private popupService: PopupService,
    private popupRequestService,
    private fileChooserService: FileChooserService,
    private imageService: ImageService,
    private SF_FILES_UPLOAD_OPTIONS: typeof FILES_UPLOAD_OPTIONS
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.isBrowser = this.platformService.isBrowser();
    this.currentAvatarId = this.profile.contents.avatar_id;
    this.setPictureEditActions();
  }

  setPictureEditActions(): void {
    let actions = [
      {
        text: this.$translate.instant('PROFILE_ADD_PICTURE_TAKE_PICTURE'),
        onClick: () => this.takePicture(),
      },
      {
        text: this.$translate.instant('PROFILE_ADD_PICTURE_UPLOAD_PICTURE'),
        onClick: this.isBrowser
          ? () => this.selectFromBrowser()
          : () => this.selectFromGallery(),
        isForBrowserOnly: true,
      },
    ];
    const actionSheetConfig: ActionSheetConfig = {
      cancelText: this.$translate.instant('PROFILE_ADD_PICTURE_CANCEL_BUTTON'),
    };
    const onDestructiveClick = () => this.deleteProfilePicture();

    if (this.isBrowser) {
      actions = actions.filter((action) => action.isForBrowserOnly);
    }

    this.pictureEditActions = {
      actions,
      actionSheetConfig,
      onDestructiveClick,
    };
  }

  addPicture(): ng.IPromise<void> | undefined {
    if (this.isPendingUpload) {
      return;
    }
    const { actions, actionSheetConfig, onDestructiveClick } =
      this.pictureEditActions;
    const sheetConfig = { ...actionSheetConfig };

    if (this.profile.contents.avatar_id) {
      sheetConfig.destructiveText = this.$translate.instant(
        'PROFILE_EDIT_PICTURE_DELETE_BUTTON'
      );
    }

    this.actionSheetService.open(
      actions,
      sheetConfig,
      null,
      onDestructiveClick
    );
  }

  takePicture(): ng.IPromise<void> {
    return this.cameraService
      .getPhotoAsFile()
      .then((imageBlob: Blob) => this.imageService.resizeBlobImage(imageBlob))
      .then((blob: Blob) => this.onFileSelect(blob));
  }

  selectFromGallery(): ng.IPromise<void> {
    return this.pictureSystemService
      .getPictureFromSource(PICTURE_SYSTEM_IDENTIFIERS.LIBRARY)
      .then((paths) =>
        this.$q.all(
          paths.map((path) => this.filesSystemService.getBlobFromPath(path))
        )
      )
      .then((blobs: Blob[]) => this.onFileSelect(blobs[0]));
  }

  selectFromBrowser(): ng.IPromise<void> {
    return this.fileChooserService
      .getImage()
      .then((blob: Blob) => this.onFileSelect(blob));
  }

  onFileSelect(blob: Blob): void {
    const url = this.$window.URL.createObjectURL(blob);

    this.openImageCropperModal(url);
  }

  openImageCropperModal(image: Blob): void {
    const template = `
      <sf-image-cropper
        image="$ctrl.image"
        cropped-img-options="$ctrl.croppedImgOptions"
        on-close="$ctrl.onClose()"
        on-save="$ctrl.onSave"
      >
      </sf-image-cropper>
    `;

    this.modalService.open(
      template,
      {
        image,
        croppedImgOptions: this.SF_FILES_UPLOAD_OPTIONS.PROFILE_PIC,
        onSave: (croppedImageBlob: Blob): void => {
          this.saveCroppedImageFile(croppedImageBlob);
        },
      },
      { hardwareBackButtonClose: false }
    );
  }

  saveCroppedImageFile(croppedImageBlob: Blob): ng.IPromise<void> {
    const name = `profile_pic_${new Date().getTime()}.png`;
    const profileImageFile = this.filesSystemService.createFileFromBlob(
      croppedImageBlob,
      name
    );

    return this.uploadProfilePicture(profileImageFile);
  }

  uploadProfilePicture(profileImageFile: File): ng.IPromise<void> {
    const profilePictureId = this.objectIdService.create();

    this.isPendingUpload = true;
    return this.filesService
      .upload(profileImageFile, profilePictureId)
      .catch((err) => {
        this.pictureSaveError();
        throw err;
      })
      .then(() => this.updateProfilePicture(profilePictureId.toString()))
      .finally(() => {
        this.isPendingUpload = false;
      });
  }

  updateProfilePicture(avatar_id?: string): ng.IPromise<void> {
    const updatePicPopup = this.showPictureUploadingProgress();
    const onRetry = () => this.updateProfilePicture(avatar_id);

    return this.usersService
      .updateAvatarId(this.profile, avatar_id)
      .then(() =>
        this.profileService.updateProfilePicture(this.profile, avatar_id)
      )
      .then((profile: User) => {
        this.setComponentProfile(profile);
        if (this.currentAvatarId) {
          this.filesService.deleteFile(this.currentAvatarId); // delete previous profile pic
        }
        this.currentAvatarId = avatar_id;
      })
      .then(() => updatePicPopup.onSuccess())
      .catch((err) => {
        updatePicPopup.onError(onRetry);
        throw err;
      });
  }

  pictureSaveError(): ng.IPromise<void> {
    return this.popupService.showError({
      title: this.$translate.instant('PROFILE_ADD_PICTURE_SAVE_ERROR'),
    });
  }

  setComponentProfile(profile: User): void {
    this.profile = profile;
    this.pubSubService.publish('PROFILE_UPDATED', { profile: this.profile });
  }

  deleteProfilePicture(): ng.IPromise<void> | null {
    if (!this.currentAvatarId) {
      return null;
    }
    this.isPendingUpload = true;

    return this.filesService
      .deleteFile(this.currentAvatarId)
      .catch((err) => {
        this.pictureSaveError();
        throw err;
      })
      .then(() => {
        this.currentAvatarId = null;
        return this.updateProfilePicture(undefined);
      })
      .finally(() => {
        this.isPendingUpload = false;
      });
  }

  showPictureUploadingProgress(): {
    onSuccess: () => void;
    onError: (cb) => void;
  } {
    return this.popupRequestService.show({
      progress: {
        title: this.$translate.instant('PROFILE_ADD_PICTURE_SAVE_PENDING'),
      },
      success: {
        title: this.$translate.instant('PROFILE_ADD_PICTURE_SAVE_SUCCESS'),
        iconName: 'thumbsup',
        actions: [{ text: 'OK', type: 'button-dark' }],
      },
      error: {
        title: this.$translate.instant('PROFILE_ADD_PICTURE_SAVE_ERROR'),
      },
    });
  }
}
