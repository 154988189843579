import { NextEventsListComponent } from './next-events-list/next-events-list.component';
import { NextEventsService } from './services/next-events.service';

export const NextEventsModule = angular
  .module('sfMobile.components.next-events', [
    'sfMobile.constants.calendar-events',
    'sfMobile.services.events',
  ])
  .component('sfNextEventsList', NextEventsListComponent)
  .service('nextEventsService', NextEventsService).name;
