type DropdownOption = { label: string; value: string; iconClass?: string };

export const DropdownComponent = {
  bindings: {
    selectedValue: '=',
    options: '<',
    onValueChange: '&',
  },
  templateUrl: 'components/dropdown/dropdown.html',
  controller: class DropdownController {
    label: string;
    iconClass: string;
    selectedValue: string;
    onValueChange: (option: { value: string }) => void;
    options: DropdownOption[];
    showOptions: boolean;

    constructor() {
      'ngInject';
    }

    $onChanges(changes: {
      selectedValue?: string;
      options?: DropdownOption[];
    }): void {
      if (changes.selectedValue || changes.options) {
        this.setLabelAndIcon(this.selectedValue);
      }
    }

    toggleOptions(): void {
      this.showOptions = !this.showOptions;
    }

    setLabelAndIcon(optionValue: string): void {
      const selectedOption = this.options.find(
        (option) => option.value === optionValue
      );

      if (selectedOption) {
        this.label = selectedOption.label;
        this.iconClass = selectedOption.iconClass || '';
      } else {
        this.label = '';
        this.iconClass = '';
      }
    }

    changeValue(optionValue: string): void {
      this.setLabelAndIcon(optionValue);

      this.showOptions = false;
      this.selectedValue = optionValue;
      this.onValueChange({ value: optionValue });
    }
  },
};
