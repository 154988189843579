import { CalendarEventsComponentController } from './calendar-events.controller';

export const CalendarEventsComponent: ng.IComponentOptions = {
  bindings: {
    organisation: '<',
    place: '<',
  },
  templateUrl: 'calendar-events/views/calendar-events/calendar-events.html',
  controller: CalendarEventsComponentController,
};
