import { NewsfeedPopupService } from '../../services/newsfeed-popup/newsfeed-popup.service';
import { NewsfeedPostResourceType, ResourcesSelectedCb } from '../../types';
import { NewsfeedResourcesSelectorService } from '../../services/newsfeed-resources-selector/newsfeed-resources-selector.service';
import type { NgfFile } from '../../../services/Utils/FileValidator/file-validator.service';
import { ActionSheetService } from '../../../services/Utils/ActionSheet/action-sheet.service';
import {
  DOCUMENT_UPLOAD_OPTIONS,
  FILES_UPLOAD_OPTIONS,
  IMAGE_UPLOAD_OPTIONS,
  VIDEO_UPLOAD_OPTIONS,
} from '../../../constants/files-upload-options.constant';
import type { PlatformService } from '../../../services/Utils/Platform';

export class NewsfeedResourcesSelectorController
  implements ng.IComponentController
{
  resourceSelectEvent: (evt: { isPending: boolean }) => void;
  onResourcesChanged: ResourcesSelectedCb;
  isDisabled: boolean;
  readyImagesCount = 0;
  readyVideosCount = 0;
  documentOptions: typeof DOCUMENT_UPLOAD_OPTIONS;
  imageOptions: typeof IMAGE_UPLOAD_OPTIONS;
  videoOptions: typeof VIDEO_UPLOAD_OPTIONS;
  resizeOptions: Record<'width' | 'height' | 'quality', number>;
  isBrowser = false;

  // eslint-disable-next-line max-params
  constructor(
    private platformService: PlatformService,
    private newsfeedResourcesSelectorService: NewsfeedResourcesSelectorService,
    private keyboardService,
    private newsfeedPopupService: NewsfeedPopupService,
    private SF_FILES_UPLOAD_OPTIONS: typeof FILES_UPLOAD_OPTIONS,
    private actionSheetService: ActionSheetService,
    private $translate: ng.translate.ITranslateService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.newsfeedResourcesSelectorService.setResourceSelectCallback(
      this.onResourcesChanged
    );
    this.isBrowser = this.platformService.isBrowser();
    this.documentOptions = this.SF_FILES_UPLOAD_OPTIONS.DOCUMENT;
    this.imageOptions = this.SF_FILES_UPLOAD_OPTIONS.IMAGE;
    this.videoOptions = this.SF_FILES_UPLOAD_OPTIONS.VIDEO;
    this.resizeOptions = {
      width: this.SF_FILES_UPLOAD_OPTIONS.IMAGE.maxWidth,
      height: this.SF_FILES_UPLOAD_OPTIONS.IMAGE.maxHeight,
      quality: this.SF_FILES_UPLOAD_OPTIONS.IMAGE.quality,
    };
  }

  hasToResize(width: number, height: number): boolean {
    return this.newsfeedResourcesSelectorService.hasToResize(width, height);
  }

  isMobileMediaImportDisabled(): boolean {
    return (
      this.isDisabled ||
      (this.readyVideosCount >= this.videoOptions.maxFiles &&
        this.readyImagesCount >= this.imageOptions.maxFiles)
    );
  }

  isBrowserImageImportDisabled(): boolean {
    return (
      this.isDisabled || this.readyImagesCount >= this.imageOptions.maxFiles
    );
  }

  isBrowserVideoImportDisabled(): boolean {
    return (
      this.isDisabled || this.readyVideosCount >= this.videoOptions.maxFiles
    );
  }
  selectDocumentsOnMobile(): ng.IPromise<void> {
    return this.newsfeedResourcesSelectorService.selectDocumentsOnMobile();
  }

  selectDocumentsOnBrowser(files: File[], invalidFiles: NgfFile[]): void {
    this.selectFiles(files, invalidFiles, 'document');
  }

  selectImages(files: File[], invalidFiles: NgfFile[]): void {
    this.selectFiles(files, invalidFiles, 'image');
  }

  selectVideo(files: File[], invalidFiles: NgfFile[]): void {
    this.selectFiles(files, invalidFiles, 'video');
  }

  selectFiles(
    files: File[],
    invalidFiles: NgfFile[],
    type: NewsfeedPostResourceType
  ): void {
    this.newsfeedResourcesSelectorService.selectFilesOnBrowser(
      files,
      invalidFiles,
      type
    );
  }

  selectFromGallery(): void {
    this.keyboardService.hide();

    const videoActions =
      this.readyVideosCount >= this.videoOptions.maxFiles
        ? []
        : [
            {
              text: this.$translate.instant('NEWSFEED_EDIT_POST_GALLERY_VIDEO'),
              onClick: () => this.selectFilesFromGallery('video'),
            },
          ];
    const imageActions =
      this.readyImagesCount >= this.imageOptions.maxFiles
        ? []
        : [
            {
              text: this.$translate.instant(
                'NEWSFEED_EDIT_POST_GALLERY_IMAGES'
              ),
              onClick: () => this.selectFilesFromGallery('image'),
            },
          ];
    const actions = [...imageActions, ...videoActions];

    this.actionSheetService.open(actions);
  }

  selectFilesFromGallery(type: NewsfeedPostResourceType): ng.IPromise<any> {
    const readyFilesCount =
      type === 'image' ? this.readyImagesCount : this.readyVideosCount;
    const maxFiles =
      type === 'image'
        ? +this.imageOptions.maxFiles
        : +this.videoOptions.maxFiles;

    if (readyFilesCount >= maxFiles) {
      return this.newsfeedPopupService.showError(
        'NEWSFEED_EDIT_POST_RESOURCE_MAX_FILES'
      );
    }
    if (type === 'image') {
      // Image is promise friendly with cancel event.
      // Video is just an <input> without cancel event, so isPending never cancelled
      this.resourceSelectEvent({ isPending: true });
    }
    const filesCount = maxFiles - readyFilesCount;

    return this.newsfeedResourcesSelectorService
      .selectFromGallery(filesCount, type)
      .finally(() => this.resourceSelectEvent({ isPending: false }));
  }

  takeFromCamera(): void {
    this.keyboardService.hide();

    const videoActions =
      this.readyVideosCount >= this.videoOptions.maxFiles
        ? []
        : [
            {
              text: this.$translate.instant('NEWSFEED_EDIT_POST_CAMERA_VIDEO'),
              onClick: () => this.takeVideo(),
            },
          ];
    const imageActions =
      this.readyImagesCount >= this.imageOptions.maxFiles
        ? []
        : [
            {
              text: this.$translate.instant('NEWSFEED_EDIT_POST_CAMERA_IMAGE'),
              onClick: () => this.takePicture(),
            },
          ];
    const actions = [...imageActions, ...videoActions];

    this.actionSheetService.open(actions);
  }

  takePicture(): ng.IPromise<any> {
    if (this.readyImagesCount >= this.SF_FILES_UPLOAD_OPTIONS.IMAGE.maxFiles) {
      return this.newsfeedPopupService.showError(
        'NEWSFEED_EDIT_POST_RESOURCE_MAX_FILES'
      );
    }

    return this.newsfeedResourcesSelectorService.takePicture();
  }

  takeVideo(): ng.IPromise<any> {
    if (this.readyVideosCount >= this.SF_FILES_UPLOAD_OPTIONS.VIDEO.maxFiles) {
      return this.newsfeedPopupService.showError(
        'NEWSFEED_EDIT_POST_RESOURCE_MAX_FILES'
      );
    }
    this.resourceSelectEvent({ isPending: true });

    return this.newsfeedResourcesSelectorService
      .takeVideo()
      .finally(() => this.resourceSelectEvent({ isPending: false }));
  }
}
