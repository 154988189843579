import { DateFormatService } from '@services/Utils/Dates/date-format.service';
import moment, { Moment } from 'moment';
import { path } from 'ramda';
import sfPreferences from 'sf-preferences';
import { ObjectId, User } from '../..';
import { FormContents } from '../../missions/form/types';
import { APIStore } from '../../places';
import {
  Campaign,
  CampaignObjective,
  CampaignObjectiveCompletion,
  PerStoreObjectiveCompletion,
  PerStoreObjectiveCompletionExpected,
  PerUserObjectiveCompletion,
  PerUserObjectiveCompletionExpected,
  PreviousAnswers,
  PreviousAnswersPayload,
  RoutinePeriod,
  SimplifiedCampaignObjectiveCompletion,
} from '../../services/API/campaigns/campaigns';
import { CampaignPreferencesKeys } from '../../services/API/campaigns/campaigns.service';
import {
  Report,
  ReportContents,
  ReportPosAudit,
} from '../../services/API/reports/reports';
import { DateService } from '../../services/Utils/Dates/date.service';
import { ObjectIdService } from '../../services/Utils/Objectid/objectId.service';
import { TranslationDictionnary } from '../../services/Utils/sfTranslation/sf-translation.filters';

export class CampaignsUtilsService {
  constructor(
    private $translate: ng.translate.ITranslateService,
    private dateService: DateService,
    private dateFormatService: DateFormatService,
    private modalService,
    private objectIdService: ObjectIdService
  ) {
    'ngInject';
  }

  getRemainingTime(campaign: Campaign): string {
    const today = new Date();
    const deactivationDate = campaign.contents.deactivation_date;

    if (
      deactivationDate &&
      this.dateService.isGreaterThan(deactivationDate, today)
    ) {
      return this.dateService.formatDifference(today, deactivationDate);
    }

    return '';
  }

  getActivationTime(campaign: Campaign): string {
    const today = new Date();
    const activationDate = campaign.contents.activation_date;

    if (
      activationDate &&
      this.dateService.isGreaterThan(activationDate, today)
    ) {
      return this.dateService.formatDifference(today, activationDate);
    }

    return '';
  }

  getActivationPeriod(campaign: Campaign): string {
    const formattedActivationDate = this.dateFormatService.getDateFormatted(
      campaign.contents.activation_date
    );
    const formattedDeactivationDate = this.dateFormatService.getDateFormatted(
      campaign.contents.deactivation_date
    );

    if (
      campaign.contents.activation_date &&
      campaign.contents.deactivation_date
    ) {
      return this.$translate.instant('REACTIVE_CAMPAIGNS_PERIOD_FROM_TO', {
        from: formattedActivationDate,
        to: formattedDeactivationDate,
      });
    }
    if (campaign.contents.activation_date) {
      return this.$translate.instant('REACTIVE_CAMPAIGNS_PERIOD_FROM', {
        from: formattedActivationDate,
      });
    }
    if (campaign.contents.deactivation_date) {
      return this.$translate.instant('REACTIVE_CAMPAIGNS_PERIOD_TO', {
        to: formattedDeactivationDate,
      });
    }

    return '';
  }

  getFrequencyDate(date: Moment | Date | number, isMonthly: boolean) {
    const formattedDate = this.dateFormatService.getDateFormatted(date);

    if (isMonthly) {
      return formattedDate;
    }

    const formattedTime = this.dateFormatService.getTimeFormatted(date);

    const at = this.$translate.instant('RECURRENCE.AT');

    return `${formattedDate} ${at} ${formattedTime}`;
  }

  getFrequencyNextActivationTime(campaign) {
    const { activationPeriod, perMonth } = campaign.contents.objective;

    if (!activationPeriod) return '';

    const isMonthly = Boolean(perMonth?.length);
    const start = this.initFrequencyActivePeriodDates(
      campaign,
      isMonthly,
      true
    ).start.toDate();

    if (this.dateService.isAfter(start, new Date())) {
      return this.dateService.formatDifference(new Date(), start);
    }

    return '';
  }

  initFrequencyActivePeriodDates(
    campaign,
    isMonthly,
    shouldInitNextPeriod = false
  ) {
    const objective = campaign.contents.objective;
    const perRange = isMonthly ? objective.perMonth : objective.perDay;
    const period = isMonthly ? 'month' : 'day';

    const initDate = (date) => {
      const initFn = shouldInitNextPeriod
        ? this.getNextActivePeriod
        : this.getCurentActivePeriod;
      const activePeriod = initFn(campaign, perRange, period, isMonthly);
      const currentPeriod = moment().set(period, activePeriod);

      const daysInMonth = currentPeriod.daysInMonth();

      if (isMonthly && date > daysInMonth) {
        date = daysInMonth;
      }

      return currentPeriod
        .startOf(period)
        .set(isMonthly ? 'date' : 'milliseconds', date);
    };

    const { start, end, due } = objective.activationPeriod!;

    return {
      start: initDate(start),
      end: initDate(end),
      due: initDate(due),
    };
  }

  getCurentActivePeriod = (campaign, perRange, period, isMonthly) => {
    const date = moment().get(period);

    if (
      this.isFrequencyActivePeriod(campaign) ||
      this.isAfterActivePeriod(campaign.contents.objective)
    ) {
      return date;
    }

    const rangeBefore = perRange.filter((val) => val < date);

    if (rangeBefore.length) {
      return Math.max(...rangeBefore);
    }

    const rangeAfter = perRange.filter((val) => val >= date);
    const rangeDuration = isMonthly ? 12 : 7;

    return Math.max(...rangeAfter) - rangeDuration; // set month from past year
  };

  getNextActivePeriod = (campaign, perRange, period, isMonthly) => {
    const objective = campaign.contents.objective;
    const date = moment().get(period);

    if (
      !this.isFrequencyActivePeriod(campaign) &&
      this.isBeforeActivePeriod(objective)
    ) {
      return date;
    }

    const rangeAfter = perRange.filter((val) => val > date);

    if (rangeAfter.length) {
      return Math.min(...rangeAfter);
    }

    const rangeBefore = perRange.filter((val) => val <= date);
    const rangeDuration = isMonthly ? 12 : 7;

    return Math.max(...rangeBefore) + rangeDuration; // set month from next year
  };

  isBeforeActivePeriod(objective): boolean {
    const { perMonth, perDay, activationPeriod } = objective!;
    const isBefore = (val) => {
      return val < activationPeriod.start;
    };

    const isBeforeDaylyPeriod =
      perDay?.includes(new Date().getDay()) &&
      isBefore(this.dateService.getMilisecondsFromMidnight(new Date()));

    const isBeforeMonthPeriod =
      perMonth?.includes(new Date().getMonth()) &&
      isBefore(new Date().getDate());

    return isBeforeDaylyPeriod || isBeforeMonthPeriod;
  }

  isAfterActivePeriod(objective): boolean {
    const { perMonth, perDay, activationPeriod } = objective!;
    const isAfter = (val) => {
      return val > activationPeriod.start;
    };

    const isAfterDaylyPeriod =
      perDay?.includes(new Date().getDay()) &&
      isAfter(this.dateService.getMilisecondsFromMidnight(new Date()));

    const isAfterMonthPeriod =
      perMonth?.includes(new Date().getMonth()) &&
      isAfter(new Date().getDate());

    return isAfterDaylyPeriod || isAfterMonthPeriod;
  }

  getFrequencyPeriods(campaign: Campaign): string[] {
    const result: string[] = [];
    const objective = campaign.contents.objective;
    const { activationPeriod, perMonth, perDay } = objective!;

    if (activationPeriod && (perDay || perMonth)) {
      const isMonthly = Boolean(perMonth?.length);
      const { start, end, due } = this.initFrequencyActivePeriodDates(
        campaign,
        isMonthly
      );

      const isCurrentMonthPeriod = perMonth?.includes(moment().get('month'));
      const isCurrentDaylyPeriod = perDay?.includes(moment().get('day'));

      if (isCurrentDaylyPeriod || isCurrentMonthPeriod) {
        result.push(
          this.$translate.instant('REACTIVE_CAMPAIGNS_PERIOD_FROM_TO', {
            from: this.getFrequencyDate(start, isMonthly),
            to: this.getFrequencyDate(end, isMonthly),
          }),
          this.getFrequencyDate(due, isMonthly)
        );
      }
    }

    return result;
  }

  isRoutineChecklist(campaign: Campaign): boolean {
    if (!campaign.contents.objective) {
      return false;
    }
    return Boolean(
      campaign.contents.objective.perDay || campaign.contents.objective.perMonth
    );
  }

  openStoreSelectorModal(
    campaign: Campaign,
    referer = null,
    onClose?: () => unknown
  ): ng.IPromise<void> {
    const template = `
      <sf-reactive-campaign-store-selector
        referer="$ctrl.referer"
        campaign="$ctrl.campaign"
        on-close="$ctrl.onClose()">
      </sf-reactive-campaign-store-selector>
    `;
    const bindings = {
      campaign,
      referer,
      onClose,
    };

    return this.modalService.open(template, bindings);
  }

  openParamSelectorModal(
    campaign: Campaign,
    placeId: ObjectId,
    referer?: string
  ): ng.IPromise<void> {
    const template = `
      <sf-reactive-campaign-param-selector
        referer="$ctrl.referer"
        campaign="$ctrl.campaign"
        place-id="$ctrl.placeId"
        on-close="$ctrl.onClose()"
        on-save="$ctrl.onSave()">
      </sf-reactive-campaign-param-selector>
    `;
    const bindings = {
      campaign,
      placeId,
      referer,
    };

    return this.modalService.openAsPromise(template, bindings);
  }

  initReport(
    reportId: ObjectId,
    campaign: Campaign,
    profile: User,
    placeId?: ObjectId,
    posAudit?: ReportPosAudit,
    answers?: string
  ): Report {
    const report = this.getNewReportForCampaign(reportId, campaign, profile);

    if (placeId) {
      report.contents.place_id = placeId;
    }
    if (posAudit) {
      report.contents.posAudit = posAudit;
    }
    if (answers) {
      report.contents.answers = JSON.parse(answers);
      report.editDraft = true;
    }

    return report;
  }

  getNewReportForCampaign(
    id: ObjectId,
    campaign: Campaign,
    profile: User
  ): Report {
    return {
      _id: id,
      id,
      localStatus: 'draft',
      contents: {
        organisation_id: campaign.contents.organisation_id,
        owner_id: campaign.creator._id,
        campaign_id: campaign._id,
        user_id: profile._id,
        type: 'share',
        nodes: [],
        answers: [],
        users_ids: [],
      },
    };
  }

  isPerStoreObjectiveSimplifiedCompletion(
    completion: SimplifiedCampaignObjectiveCompletion
  ): completion is { perStore: PerStoreObjectiveCompletionExpected } {
    const storeCompletion = completion as {
      perStore: PerStoreObjectiveCompletionExpected;
    };

    return Boolean(storeCompletion.perStore);
  }

  isPerUserObjectiveSimplifiedCompletion(
    completion: SimplifiedCampaignObjectiveCompletion
  ): completion is { perUser: PerUserObjectiveCompletionExpected } {
    const userCompletion = completion as {
      perUser: PerUserObjectiveCompletionExpected;
    };

    return Boolean(userCompletion.perUser);
  }

  isPerStoreObjectiveCompletion(
    completion: CampaignObjectiveCompletion
  ): completion is PerStoreObjectiveCompletion {
    const storeCompletion = completion as PerStoreObjectiveCompletion;

    return Boolean(storeCompletion.perStore);
  }

  isPerUserObjectiveCompletion(
    completion: CampaignObjectiveCompletion
  ): completion is PerUserObjectiveCompletion {
    const userCompletion = completion as PerUserObjectiveCompletion;

    return Boolean(userCompletion.perUser);
  }

  getObjectiveCompletionPercent(
    completion: SimplifiedCampaignObjectiveCompletion
  ): number {
    if (this.isPerUserObjectiveSimplifiedCompletion(completion)) {
      const { reportsReceived, reportsExpected } = completion.perUser;

      return reportsExpected
        ? Math.floor(
            (100 * Math.min(reportsReceived, reportsExpected)) / reportsExpected
          )
        : 0;
    }

    if (this.isPerStoreObjectiveSimplifiedCompletion(completion)) {
      const { storesCovered, storesIncluded } = completion.perStore;

      return storesIncluded
        ? Math.floor((100 * storesCovered) / storesIncluded)
        : 0;
    }

    return 0;
  }

  getObjectiveCompletionPercentByStore(
    goal: number,
    places: APIStore[],
    reportsByStore: Record<string, number>
  ): Record<
    ObjectId,
    {
      percent: number;
      label: {
        title: string;
      };
    }
  > {
    return places.reduce((acc, place) => {
      const reportsReceivedNumber = reportsByStore[place._id] ?? 0;
      const percent = Math.floor(
        (100 * Math.min(reportsReceivedNumber, goal)) / goal
      );

      acc[place._id] = {
        percent,
        label: {
          title: `${percent} %`,
        },
      };

      return acc;
    }, {});
  }

  // TODO: At term, this should be removed once form does not exists anymore
  getFormFromCampaign(campaign: Campaign): {
    _id: ObjectId;
    contents: FormContents;
    i18n: TranslationDictionnary;
  } {
    const formContents: FormContents = {
      ...(campaign.contents.form as unknown as FormContents),
      statusMap: [],
      title: campaign.contents.name,
    };

    if (campaign.contents.tagsMap) {
      formContents.statusMap = campaign.contents.tagsMap;
    }

    return {
      _id: campaign._id,
      contents: formContents,
      i18n: campaign.i18n || {},
    };
  }

  buildNodesForPreviousAnswers(
    campaign: Campaign,
    report: Report,
    previousAnswers?: PreviousAnswersPayload
  ): Pick<ReportContents, 'nodes'> & {
    previousAnswers: PreviousAnswers[];
  } {
    if (
      !previousAnswers ||
      !previousAnswers.answers ||
      !previousAnswers.answers.length
    ) {
      return {
        nodes: report.contents.nodes,
        previousAnswers: [],
      };
    }

    let nodes: {
      _id: string;
      sections_ids?: string[];
      parents_ids?: string[];
    }[] = [];
    let parentNode: {
      _id?: string;
      sections_ids?: string[];
    } = {};

    campaign.contents.form.sections.forEach((section) => {
      let node = report.contents.nodes.find(
        (n) => n.sections_ids[0] === section._id
      );

      if (section.level === 0) {
        parentNode = {};
      }

      node = {
        _id: this.objectIdService.create(),
        sections_ids: [section._id].concat(parentNode.sections_ids || []),
        parents_ids: parentNode._id ? [parentNode._id] : [],
        ...node,
      };

      previousAnswers.answers = previousAnswers.answers.map((answer) =>
        answer.sections_ids[0] === section._id
          ? {
              _id: this.objectIdService.create(),
              nodes_ids: [node._id],
              ...answer,
            }
          : answer
      );

      if (section.level === 0) {
        parentNode = node;
      }

      nodes = nodes.concat(node);
    });

    return {
      nodes,
      previousAnswers: previousAnswers.answers,
    };
  }

  canEditReport(report: Report, campaign: Campaign, user: User): boolean {
    const editReport: boolean[] = [];
    if (this.isCampaignAnalyst(campaign, user)) {
      editReport.push(
        sfPreferences.query(
          CampaignPreferencesKeys.allowEditByAnalyst,
          false,
          path(['contents', 'preferences'], campaign)
        )
      );
    }

    if (this.isCampaignOwner(campaign, user)) {
      editReport.push(
        sfPreferences.query(
          CampaignPreferencesKeys.allowEditByOwner,
          false,
          path(['contents', 'preferences'], campaign)
        )
      );
    }

    if (report.contents.user_id === user._id) {
      editReport.push(
        sfPreferences.query(
          CampaignPreferencesKeys.allowEditByRespondent,
          false,
          path(['contents', 'preferences'], campaign)
        )
      );
    }

    return editReport.some((e: boolean) => e === true);
  }

  isFrequencyActivePeriod(campaign: Campaign): boolean {
    return this.isDateActivePeriod(moment(), campaign.contents.objective);
  }

  isPeriodCurrentlyActive(period: RoutinePeriod, campaign: Campaign): boolean {
    const now = moment();

    return this.isDateActivePeriod(
      moment(period.date)
        .set('hour', now.hour())
        .set('minute', now.minute())
        .set('second', now.second()),
      campaign.contents.objective
    );
  }

  isDateActivePeriod(date: Moment, objective?: CampaignObjective): boolean {
    if (!objective) {
      return true;
    }

    const { perMonth, perDay } = objective;

    if (perMonth?.length) {
      return this.isFrequencyActiveMonthlyPeriod(date.month(), objective);
    }

    if (perDay?.length) {
      return this.isFrequencyActiveDailyPeriod(date, objective);
    }

    return true;
  }

  isFrequencyActiveDailyPeriod(
    date: Moment,
    objective: CampaignObjective
  ): boolean {
    if (!objective?.perDay) {
      return false;
    }

    if (!objective?.perDay.includes(date.day())) {
      return false;
    }

    const startOfDay = date.clone().startOf('day');

    const { end, start } = objective.activationPeriod;

    const startTime = startOfDay.clone().add(start, 'milliseconds');
    const endTime = startOfDay.clone().add(end, 'milliseconds');

    return date.isBetween(startTime, endTime);
  }

  isFrequencyActiveMonthlyPeriod(
    monthNumber: number,
    objective: CampaignObjective
  ): boolean {
    if (!objective?.perMonth) {
      return false;
    }

    const { perMonth, activationPeriod } = objective;

    if (!perMonth.includes(monthNumber)) {
      return false;
    }

    const currentDay = moment().date();
    const { end, start } = activationPeriod;

    return currentDay >= start && currentDay <= end;
  }

  private isCampaignAnalyst(campaign: Campaign, user: User): boolean {
    return campaign.resolved.analysts_ids.includes(user._id);
  }

  private isCampaignOwner(campaign: Campaign, user: User): boolean {
    return (
      campaign.resolved.owner_ids.includes(user._id) ||
      user.contents.profiles.includes('admin') ||
      campaign.creator._id === user._id
    );
  }
}
