import sfPreferences from 'sf-preferences';
import moment from 'moment-timezone';

// eslint-disable-next-line max-params
export function PreferencesService($http, sfPOVService, profileService) {
  'ngInject';

  this.moment = moment;

  /**
   * List the profile preferences
   *
   * @returns {Promise} Profile preferences
   */
  this.list = () => {
    return profileService
      .getProfile()
      .then((profile) => profile.preferences || []);
  };

  /**
   * Get the preference value
   *
   * @param {String} key Preference key
   * @returns {Promise} Preference value
   */
  this.getValue = (key) => {
    return this.list().then((preferences) =>
      sfPreferences.get(preferences, key)
    );
  };

  /**
   * Return the value of a targeted preference by name respecting inheritance
   * providedDefaultValue -> orgPref -> userPref
   *
   * @param {String} key The preference name to query for
   * @param {String | Boolean | Number} fallbackValue The default value to fallback to.
   * @returns {Promise} Preference value
   */
  this.query = (key, fallbackValue) =>
    this.list().then((preferences) =>
      sfPreferences.query(key, fallbackValue, preferences)
    );

  /**
   * Returns timezone preference
   *
   * @returns {Promise} current user timezone
   */
  this.getTimezone = () => {
    return this.getValue('settings.timeZone')
      .then((timezone) => timezone || moment.tz.guess())
      .catch(() => this.moment.tz.guess());
  };

  /**
   * Save the preference to the API and update the profile locally
   *
   * @param {String} key Parameter key
   * @param {*} value Paramater value
   * @returns {Promise} New preferences
   */
  this.save = (key, value) => {
    const urlKey = this.formatKeyForUrl(key);
    const data = { value };

    return profileService.getProfile().then((profile) => {
      const apiUrl = `/users/${profile._id}/preferences/${urlKey}`;

      return sfPOVService
        .pBuildURL(apiUrl)
        .then((url) => $http.put(url, data))
        .then(() => {
          const preferences = profile.preferences || [];

          return sfPreferences.set(preferences, key, value);
        })
        .then((newPreferences) => {
          profile.preferences = newPreferences;

          return profileService
            .saveLocal(profile._id, profile)
            .then(() => newPreferences);
        });
    });
  };

  this.formatKeyForUrl = (name) => {
    return name.replace(/\./g, '-');
  };
}
