import DocumentItemModule from './components/document-item/document-item.module';
import DocumentListModule from './components/document-list/document-list.module';
import GalleryListModule from './components/gallery-list/gallery-list.module';
import PreviewModalModule from './components/preview-modal/preview-modal.module';

export const DigitaAssetsModule = angular.module('sfMobile.digitalAssets', [
  DocumentItemModule,
  GalleryListModule,
  DocumentListModule,
  PreviewModalModule,
]).name;
