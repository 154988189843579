import { CalendarEventsListController } from './calendar-events-list.controller';

export const CalendarEventsListComponent = {
  bindings: {
    events: '<',
    userTimezone: '<',
    displayActions: '<?',
    disableStoreLink: '<?',
    displayAsCards: '<?',
    openDetailsByClick: '<?',
    onDelete: '&',
    onUpdate: '&',
    onItemClick: '&',
  },
  templateUrl:
    'calendar-events/components/calendar/calendar-events-list/calendar-events-list.html',
  controller: CalendarEventsListController,
};
