import { SfFeatureFlags } from '@simplifield/feature-flags';
import { FEATURE_FLAGS } from '../../constants/feature-flags.constant';
import { LocalizationService } from '../../services/Utils/Localization/localization.service';
import { Modal, ModalService } from '../../services/Utils/Modal';
import { PlatformService } from '../../services/Utils/Platform';
import {
  PubSubService,
  UnregisterFn,
} from '../../services/Utils/PubSub/pubsub.service';
import { SegmentService } from '../../services/Utils/Segment/segment.service';

const DEFAULT_PLACE_PICTURE_URL = 'img/background_medium.png';
const PLACE_VIEW_ANALYTICS = {
  placeActivity: 'Place Activity',
  placeAssets: 'Place Assets',
  placeInfos: 'Place Infos',
};

const PARALLAX_HEIGHT = 210;

export class PlaceController implements ng.IComponentController {
  // Bindings
  place: any;
  profile: any;
  targetTab: any;

  // Props
  canSyncMore: boolean;
  placePicture: string;
  unsubscribeEvent: UnregisterFn;
  parallaxHeight: number;
  currentStickyHandle: string;
  toasterMessageKey: string;
  placeInfoModal: Modal;
  isRTLNeeded: boolean;
  // eslint-disable-next-line max-params
  constructor(
    private localizationService: LocalizationService,
    private pubSubService: PubSubService,
    private placesService,
    private taskService,
    private segmentService: SegmentService,
    private modalService: ModalService,
    private localStorageService: ng.local.storage.ILocalStorageService,
    private platformService: PlatformService,
    private $stateParams: Record<string, unknown>,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<string> {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();

    this.canSyncMore = this.localStorageService.get(
      'is_places_sync_incomplete'
    );

    this.placePicture = DEFAULT_PLACE_PICTURE_URL;

    this.unsubscribeEvent = this.pubSubService.subscribe(
      'TASK_STATUS_CHANGED',
      (({ newStatus }) => this.showToasterOnStatusChange(newStatus)) as any
    );
    this.parallaxHeight =
      this.platformService.getSafeAreaValue('top') + PARALLAX_HEIGHT;

    return this.placesService
      .getPlacePicture(this.place)
      .then((url) => {
        this.placePicture = url;
      })
      .finally(() => {
        if (this.$stateParams.shouldEditParams) {
          this.editPlaceInfo();
        }
      });
  }
  $onDestroy(): void {
    this.unsubscribeEvent();
    this.placeInfoModal?.remove();
  }

  hasPlaceAnalytics(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.PLACES_ANALYTICS
    );
  }

  onTabClick(tabName: string): void {
    this.currentStickyHandle = tabName;

    if (PLACE_VIEW_ANALYTICS[tabName]) {
      this.segmentService.page(PLACE_VIEW_ANALYTICS[tabName]);
    }
  }

  showToasterOnStatusChange(newStatus: string): void {
    this.toasterMessageKey = this.taskService.isToDo(newStatus)
      ? 'TASKS_STATUS_CHANGED_TOASTER_TO_DO'
      : 'TASKS_STATUS_CHANGED_TOASTER_DONE';
  }

  openPlaceSettings(): void {
    const template = `
        <sf-place-settings-modal
          place="$ctrl.place"
          on-close="$ctrl.onClose()">
        </sf-place-settings-modal>
      `;

    const bindings = {
      place: this.place,
    };

    this.modalService.open(template, bindings);
  }

  editPlaceInfo(): void {
    let modal = { remove: () => {} };
    const template = `
        <sf-place-infos-edit
          place="$ctrl.place"
          on-close="$ctrl.modalClose(place)">
        </sf-place-infos-edit>`;
    const bindings = {
      place: this.place,
      modalClose: (place) => {
        if (place) {
          this.place = place;
        }
        modal.remove();
      },
    };

    modal = this.modalService.open(template, bindings, {
      hardwareBackButtonClose: false,
    });

    this.segmentService.track('PLACE_INFO', {
      action: 'open',
      label: 'Edition',
    });
  }

  openPlaceInfo(): void {
    let modal = { remove: () => {} };
    const template = `
        <sf-place-infos
          place="$ctrl.place"
          on-close="$ctrl.modalClose(place)">
        </sf-place-infos>`;
    const bindings = {
      place: this.place,
      modalClose: (place) => {
        if (place) {
          this.place = place;
        }
        modal.remove();
      },
    };

    modal = this.modalService.open(template, bindings, {
      hardwareBackButtonClose: false,
    });

    this.segmentService.page(PLACE_VIEW_ANALYTICS.placeInfos);
  }
}
