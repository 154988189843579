const NB_REPORT_TO_GET = 20;
const REPORTS_LIST_SEARCH_CRITERIAS = [
  'campaign_title',
  'user_firstName',
  'user_lastName',
];

export const ReportsListComponent = {
  bindings: {
    key: '@',
    contentKey: '@',
    form: '<',
    isPlaceDisplay: '<',
    requestUrl: '<',
    requestParams: '<',
    reloadFn: '=?',
    hasHeaderOnTop: '<?',
  },
  templateUrl: 'reports/components/reports-list/reports-list.html',
  // eslint-disable-next-line max-params
  controller: function ReportsListController(
    $state,
    contentService,
    $translate,
    pubSubService,
    reportsService,
    apiUtilsService,
    helpersService,
    RequestsPaginate,
    profileService,
    organisationsService
  ) {
    'ngInject';
    const { buildFilterParams } = apiUtilsService;

    this.$onInit = () => {
      this.requestPaginate = new RequestsPaginate(
        reportsService.crud.simpleApiList.bind(
          reportsService.crud,
          this.requestUrl || undefined
        ),
        {
          limit: NB_REPORT_TO_GET,
          relatedEntitiesKeys: ['forms', 'users', 'places'],
        }
      );

      this.reloadFn = this.reload;
      this.reports = [];
      this.searchField = '';
      this.filtersField = [];
      this.isSearchLoading = false;
      this.reportsParams = {
        ...this.requestParams,
        'sorts[]': '-saved.seal_date',
      };

      this.updateEvent = pubSubService.subscribe('FORM_REPORT_UPDATED', () =>
        this.reload()
      );

      this.filters =
        this.form && this.form.contents.statusMap
          ? [
              {
                id: 'report_state',
                label: 'REPORTS_LIST_FILTER_STATE',
                values: this.form.contents.statusMap
                  .filter((filter) => filter.key !== 'empty')
                  .map((filter) => ({
                    id: filter.label,
                    label: $translate.instant(filter.label),
                  })),
              },
            ]
          : null;

      this.initReports();

      profileService
        .getProfile()
        .then((profile) => organisationsService.getProfileOrganisation(profile))
        .then((organisation) => {
          this.useNewCampaigns = organisation.useNewCampaigns;
        });

      return this.callData();
    };

    this.$onDestroy = () => {
      if (this.updateEvent) {
        this.updateEvent();
      }
    };

    this.callData = () => {
      this.isLoading = true;

      return this.getReports()
        .then((reports) => {
          const hasSearchOrFilter =
            this.searchField || (this.filtersField && this.filtersField.length);

          this.networkError = false;
          if (!hasSearchOrFilter) {
            this.hasReports = !!reports.length;
          }
        })
        .catch((error) => this.requestError(error))
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.initReports = () => {
      this.reports.length = 0;
      this.infiniteLoadError = false;

      this.requestPaginate.reset();

      contentService.scrollTopById(this.contentKey);
    };
    this.resetInfiniteLoadErrorState = () => {
      this.infiniteLoadError = false;
    };

    this.getReports = () => {
      const filterParams = this.composeFilterParamsReq();
      const reqParams = {
        ...this.reportsParams,
        ...filterParams,
      };

      this.infiniteLoadError = false;

      return this.requestPaginate
        .call(reqParams, true)
        .then(({ entities }) => {
          this.reports = helpersService.addDividerDays(
            entities,
            'saved_date',
            true
          );
          this.forms = this.requestPaginate.getRelatedEntities('forms');
          this.places = this.requestPaginate.getRelatedEntities('places');
          this.users = this.requestPaginate.getRelatedEntities('users');
          return entities;
        })
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        })
        .finally(() => {
          if (this.onScrollComplete) {
            this.onScrollComplete();
          }
        });
    };
    this.buildFilters = (filters = []) => {
      const hasStateFilter = filters.filter(
        (filter) => filter.id === 'report_state'
      )[0];

      let filtersReq = filters.map((filter) => ({
        name: filter.id,
        value: this.getStatusKey(filter.value.id),
      }));
      const emptyStateFilter = {
        name: 'report_state',
        value: 'empty',
        operator: '$ne',
      };

      return [
        ...filtersReq,
        ...(!hasStateFilter ? [emptyStateFilter] : []), // Don't display the to do reports
      ];
    };
    this.composeFilterParamsReq = () => {
      const filters = this.buildFilters(this.filtersField);

      return buildFilterParams(filters, {
        search: this.searchField,
        criterias: REPORTS_LIST_SEARCH_CRITERIAS,
      });
    };
    this.searchReports = (search) => {
      this.isSearchLoading = true;
      this.searchField = search;

      this.initReports();

      return this.getReports()
        .catch((error) => this.requestError(error))
        .finally(() => {
          this.isSearchLoading = false;
        });
    };
    this.getStatusKey = (label) => {
      const curStatus = this.form.contents.statusMap.filter(
        (status) => label === status.label
      )[0];

      return (curStatus || {}).key;
    };
    this.onFilterChange = (filters) => {
      this.isSearchLoading = true;
      this.filtersField = filters;

      this.initReports();

      return this.getReports()
        .catch((error) => this.requestError(error))
        .finally(() => {
          this.isSearchLoading = false;
        });
    };
    this.requestError = (err) => {
      this.networkError = true;
      throw err;
    };

    this.reload = () => {
      this.initReports();
      return this.callData();
    };

    this.goToReport = (report, targetTab = null) => {
      if (this.reportStateIsEmpty(report)) {
        return false;
      }

      return $state.go('index.menu-more.missions.report', {
        campaignId: report.contents.form_id,
        reportId: report._id,
        online: true,
        targetTab,
      });
    };
    this.getCommentsNumber = (report) =>
      (report.statisticsDigest && report.statisticsDigest.commentsCount) || 0;
    this.getForm = (report) => {
      return this.forms[report.contents.form_id];
    };
    this.getPlace = (report) => {
      return this.places[report.contents.place_id];
    };
    this.getUser = (report) => {
      return this.users[report.contents.user_id];
    };
    this.reportStateIsEmpty = (report) => report.contents.state === 'empty';
  },
};
