import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import { PlatformService } from '../Platform';
import { PopupService } from '../../Utils/Popup/popup.service';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';

export class TrackingTransparencyService {
  AppTrackingTransparency = AppTrackingTransparency;
  NativeSettings = NativeSettings;

  constructor(
    private $q: ng.IQService,
    private popupService: PopupService,
    private platformService: PlatformService,
    private $translate: ng.translate.ITranslateService
  ) {
    'ngInject';
  }
  getAuthorizationPromise(
    showPopUp = false
  ): ng.IPromise<AppTrackingStatusResponse> {
    if (this.platformService.isiOS() && this.AppTrackingTransparency) {
      return this.$q.resolve(
        this.AppTrackingTransparency.getStatus().then((response) => {
          if (response.status === 'notDetermined') {
            return this.AppTrackingTransparency.requestPermission().then(
              (permission) => {
                if (permission.status === 'denied') {
                  if (showPopUp) {
                    this.openAuthorizeModal();
                  }
                  return this.AppTrackingTransparency.requestPermission();
                }
                return permission;
              }
            );
          }
          if (response.status === 'denied') {
            if (showPopUp) {
              this.openAuthorizeModal();
            }
            return this.AppTrackingTransparency.requestPermission();
          }
          return response;
        })
      );
    }

    return this.$q.resolve<AppTrackingStatusResponse>({
      status: 'authorized',
    });
  }
  openAuthorizeModal(): void {
    const options = {
      title: this.$translate.instant('MODAL_NOTIFICATION_TRACKING_SIMPLIFIELD'),
      desc: this.$translate.instant('MODAL_NOTIFICATION_TRACKING_DESC'),
      iconName: 'error',
      btnFirstOption: this.$translate.instant('POPUP_OK'),
      btnSecondOption: this.$translate.instant(
        'MODAL_NOTIFICATION_TRACKING_BUTTON'
      ),
    };

    this.popupService.showOptions(
      options,
      () => {
        return;
      },
      () =>
        this.$q.resolve(
          this.NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
          })
        )
    );
  }
}
