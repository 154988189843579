import type { User } from '../../..';
import { PlatformService } from '../Platform';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';

import { ConfigService } from '../Config/config.service';

export class FirebaseService {
  private isCrashlyticsAvailable: boolean;
  FirebaseCrashlytics = FirebaseCrashlytics;

  constructor(
    private platformService: PlatformService,
    private configService: ConfigService
  ) {
    'ngInject';

    if (this.configService.isChinaBuild()) {
      this.isCrashlyticsAvailable = false;
    } else {
      this.isCrashlyticsAvailable =
        !this.platformService.isBrowser() &&
        this.platformService.isPluginAvailable('FirebaseCrashlytics');
    }
  }

  reset(): void {
    if (!this.isCrashlyticsAvailable) {
      return;
    }
    this.FirebaseCrashlytics.deleteUnsentReports();
  }

  registerUser(user: User): void {
    if (!this.isCrashlyticsAvailable) {
      return;
    }
    const { _id, contents } = user;

    this.FirebaseCrashlytics.setUserId({ userId: _id });
    this.FirebaseCrashlytics.setEnabled({
      enabled: true,
    });
    this.FirebaseCrashlytics.setCustomKey({
      key: 'organisation_id',
      value: contents.organisation_id,
      type: 'string',
    });
  }
}
