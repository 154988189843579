export class ExternalInjectionService<T = unknown> {
  private _promises = new Map<string, ng.IPromise<T>>();
  private service: T;
  constructor(
    private $window: ng.IWindowService,
    private $document: ng.IDocumentService,
    private $q: ng.IQService
  ) {
    'ngInject';
  }

  init(scriptSrc: string, windowObject: string): ng.IPromise<T | null> {
    if (this._promises.has(scriptSrc)) {
      const scriptPromise = this._promises.get(scriptSrc);
      return scriptPromise || this.$q.resolve(null);
    }
    const defer = this.$q.defer<T>();

    this._promises.set(scriptSrc, defer.promise);

    const onScriptLoad = () => {
      this.service = this.initService(windowObject);
      defer.resolve(this.service);
    };
    const document = this.$document[0] as HTMLDocument;
    const script = document.createElement('script');

    script.onload = onScriptLoad;
    script.src = scriptSrc;
    document.body.appendChild(script);

    const scriptPromise = this._promises.get(scriptSrc);
    return scriptPromise || this.$q.resolve(null);
  }

  initService(windowObject: string): T {
    return (
      this.$window[windowObject] || {
        queue: [],
        on: (n, x) => {
          this.$window[windowObject].queue.push([n, x]);
        },
        push: (x) => {
          this.$window[windowObject].queue.push(x);
        },
      }
    );
  }
}
