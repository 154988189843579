export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 21,
    method: (sqlStorageService, localStorageService, sentryService) => {
      'ngInject';
      /* Report table migration */

      const fields = [
        'id NVARCHAR(32) PRIMARY KEY UNIQUE',
        'report_id NVARCHAR(32)',
        'payload TEXT',
      ].join(',');

      const createReportVersionsTableQuery = `CREATE TABLE report_versions(${fields})`;

      return (
        /* Reports migration execution */
        sqlStorageService
          .execute(createReportVersionsTableQuery)
          /* Force update */
          .then(() => localStorageService.remove('synchronise_date'))
          .catch((error) =>
            sentryService.captureMessage('MIGRATION_SCRIPT_FAIL_21', {
              level: 'error',
              extra: {
                error,
              },
              tags: {
                sendNotification: 'mail',
              },
            })
          )
      );
    },
  });
}
