export const ReportSectionComponent = {
  bindings: {
    item: '<',
    node: '<',
    form: '<',
    context: '<',
  },
  templateUrl:
    'reports/report/components/report-content/components/report-section/report-section.html',
  controller: function ReportSectionController(formsService) {
    'ngInject';

    this.displayQuestion = (question) => {
      const answers = this.context.report.contents.answers;

      return formsService.displayQuestion(
        question,
        this.form,
        answers,
        this.context
      );
    };

    this.displayItem = (item) =>
      item.description || (item.files && item.files.length);
  },
};
