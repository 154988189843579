export const ReceiptTotalComponent = {
  bindings: {
    answers: '<',
    mode: '@',
  },
  templateUrl: 'missions/components/Receipt/receipt-total/receipt-total.html',
  controller: function ReceiptTotalController(SF_QUESTION_PRODUCT_TYPES) {
    'ngInject';
    const QUANTITY_VALUES_INDEX = 1;

    this.$onInit = () => {
      this.isSalesMode = this.mode === SF_QUESTION_PRODUCT_TYPES.SALES;
      this.productsCount = this.computeProductsCount();

      if (this.isSalesMode) {
        this.totalPrice = this.computeProductsPrice();
      }
    };

    this.$onChanges = () => {
      this.productsCount = this.computeProductsCount();
      if (this.isSalesMode) {
        this.totalPrice = this.computeProductsPrice();
      }
    };

    this.computeProductsCount = () =>
      this.answers.reduce(
        (sum, answer) => sum + answer.values[QUANTITY_VALUES_INDEX].value,
        0
      );

    this.computeProductsPrice = () =>
      this.answers.reduce(
        (sum, answer) =>
          this.roundTwoDecimalsNumber(sum + answer.metadata.sellTotalPrice),
        0
      );

    this.roundTwoDecimalsNumber = (value) => Math.round(value * 100) / 100;
  },
};
