"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _ = require("./");

var _default = {
  type: 'array',
  uniqueItems: true,
  items: _.objectId
};
exports["default"] = _default;