import { PlaceMissionsController } from './place-missions.controller';

export const PlaceMissionsComponent = {
  bindings: {
    profile: '<',
    place: '<',
  },
  templateUrl: 'places/place/components/place-missions/place-missions.html',
  controller: PlaceMissionsController,
};
