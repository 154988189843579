import { NewsfeedPostFeedbackController } from './newsfeed-post-feedback.controller';

export const NewsfeedPostFeedbackComponent: ng.IComponentOptions = {
  bindings: {
    post: '<',
    profile: '<',
    onAddComment: '&',
    isCommentsCountDisplayed: '<?',
    disableComments: '<?',
    isWidget: '&',
  },
  templateUrl:
    'newsfeed/components/newsfeed-post-feedback-item/newsfeed-post-feedback.html',
  controller: NewsfeedPostFeedbackController,
};
