import { DIACRITICS_MAP } from './regexDiacritics.const';

export class SearchUtilsService {
  readonly DIACRITICS_MAP = DIACRITICS_MAP;

  getRegexStringWithDiacritics(search: string): string {
    const searchArray = [...search.toLocaleLowerCase()];
    const result = searchArray
      .map((char) => DIACRITICS_MAP.get(char) ?? char)
      .join('');

    return result;
  }

  getSearchRegex(regStr: string, options = 'i'): RegExp {
    return new RegExp(`.*${regStr}.*`, options);
  }
}
