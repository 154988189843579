import {
  CumulDashboardParams,
  DashboardLink,
  ExternalDashboardService,
} from '@services/Utils/ExternalDashboard/external-dashboard.service';
import { LocalizationService } from '@services/Utils/Localization/localization.service';
import {
  Filter,
  FilterValue,
  SalesDashboardFiltersService,
} from '../../services/sales-dashboard-filters-service/sales-dashboard-filters.service';
import '@luzmo/embed';

type DashboardFilter = Record<string, FilterValue[]>;

export class DashboardDetailsModalController {
  dashboard: DashboardLink;
  filterable = false;
  defaultFilters: DashboardFilter;
  externalDashboard;

  /** Attributes */
  private CONTAINER_ID = 'sales-dashboard';

  availableFilters: Filter[];
  dashboardTitle?: string;
  filters: DashboardFilter = {};
  isLoading = true;
  isNetworkError = false;
  isRTLNeeded: boolean;
  locale: CumulDashboardParams['language'] = 'en';
  selectLimit = Number.POSITIVE_INFINITY;

  constructor(
    private externalDashboardService: ExternalDashboardService,
    private localizationService: LocalizationService,
    private salesDashboardFiltersService: SalesDashboardFiltersService,
    private $q: ng.IQService
  ) {
    'ngInject';

    this.onFiltersChange = this.onFiltersChange.bind(this);
  }

  $onInit(): ng.IPromise<void> {
    this.filters = this.defaultFilters ?? {};
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();

    this.locale = this.externalDashboardService.getSupportedLang();
    this.dashboardTitle = this.getDashboardLabel(this.dashboard.name);

    return this.loadData()
      .catch(() => {
        this.isNetworkError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getDashboardLabel(dashboardName: Record<string, string>): string {
    return dashboardName[this.locale] || dashboardName.en || dashboardName.fr;
  }

  onFiltersChange(filters: DashboardFilter): ng.IPromise<unknown> {
    this.filters = { ...this.defaultFilters, ...filters };

    return this.loadData();
  }

  prepareFilters(filters: DashboardFilter): DashboardFilter {
    const preparedFilters = {};

    Object.keys(filters).forEach((key) => {
      if (!(filters[key].length === 0)) {
        preparedFilters[key] = filters[key].map((el) => el.value);
      }
    });

    return preparedFilters;
  }

  loadData(): ng.IPromise<void> {
    this.isLoading = true;
    this.isNetworkError = false;

    if (!this.dashboard) {
      return this.$q
        .resolve()
        .catch(() => {
          this.isNetworkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    return this.externalDashboardService
      .getDashboard(this.dashboard.id, {
        filters: this.prepareFilters(this.filters),
      })
      .then((dashboard) => {
        this.externalDashboard = dashboard;

        this.salesDashboardFiltersService
          .generateFilters(dashboard.availableFilters || [])
          .then((filters) => {
            this.availableFilters = filters;
          });
      })
      .catch((e) => {
        console.error(e);
        this.isNetworkError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
