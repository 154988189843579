// Synchronize events seems  global enough to be declared here.
import { GLOBAL_EVENT, GLOBAL_EVENTS } from './pubsub.events';

export type UnregisterFn = () => void;

/**
 * Centralize and enforce ONE way of applying the pubsub pattern in the codebase.
 * It also provide an abstraction around angular $rootScope service.
 */
export class PubSubService<LOCAL_EVENT extends string = string> {
  readonly GLOBAL_EVENTS = GLOBAL_EVENTS;
  /* @ngInject */
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $window: ng.IWindowService
  ) {}

  init(): void {
    this.$window.addEventListener(
      'click',
      (event) => this.publish(this.GLOBAL_EVENTS.CLICK, event),
      true
    );
  }

  /**
   * Send an event system wide that can be subscribed to using the sub method.
   * You can pass any kind of payload, but please give it a type that you will
   * reuse on the subscribe side.
   */
  publish<U>(event: GLOBAL_EVENT | LOCAL_EVENT, data?: U): void {
    if (data) {
      this.$rootScope.$broadcast(event, data);
      return;
    }
    this.$rootScope.$broadcast(event);
  }

  /**
   * Subscribe to the given event, it will execute passed callback with the the
   * event payload as data. Second callback arg is angularjs event,
   * used in a few components and might be removed after moving from angularjs
   */
  subscribe<U>(
    event: GLOBAL_EVENT | LOCAL_EVENT,
    callback: (data: U, ev?: ng.IAngularEvent) => void
  ): UnregisterFn {
    return this.$rootScope.$on(event, (ev, data: U) => callback(data, ev));
  }
}
