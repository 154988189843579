import { PhotoResponse } from '@capacitor-community/media';
import { StatusBar } from '@capacitor/status-bar';
import type { FileSystemService } from '../../../services/Utils/FileSystem/file-system.service';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import type { PlatformService } from '../../../services/Utils/Platform';
import type { ShareService } from '../../../services/Utils/Share/share.service';
import type { ContentService } from '../../Layout/content/content.service';
import { ScreenOrientationService } from '../../../services/Utils/ScreenOrientation/screen-orientation.service';
import { PluginListenerHandle } from '@capacitor/core';

const DRAG_DISTANCE_MAX = 100;
const PICTURE_DOWNLOAD_FOLDER_NAME = 'downloaded';
const SCROLL_DELEGATE_HANDLE = 'pictureViewerZoom';

export class ImagesViewerController implements ng.IComponentController {
  //Bindings
  onClose: () => void;
  setActiveSlide: (id) => void;
  activeSlide = 1;
  images: { id: string; url: string; name: string }[];
  lockZooming: boolean;
  hideShareButton: boolean;
  hideDownloadButton: boolean;

  private StatusBar = StatusBar;
  private isStatusBarAvailable: boolean;
  isMobile: boolean;
  hideElems = false;
  isSharing = false;
  pictureView: HTMLElement;
  currentImage: { id: string; url: string; name: string };
  screenOrientationChangeListenerHandle: PluginListenerHandle;
  hide: boolean;
  canZoom: boolean;
  disableScroll: boolean;
  isRTLNeeded: boolean;
  options = {};
  slider: { activeIndex: number; slideTo: (index: number) => void };

  // eslint-disable-next-line max-params
  constructor(
    private localizationService: LocalizationService,
    private $window: ng.IWindowService,
    private $element: ng.IRootElementService,
    private $q: ng.IQService,
    private $scope: ng.IScope,
    private contentService: ContentService,
    private documentDownloadService,
    private filesSystemService: FileSystemService,
    private screenOrientationService: ScreenOrientationService,
    private platformService: PlatformService,
    private shareService: ShareService,
    private $document: ng.IDocumentService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.isMobile = !this.platformService.isBrowser();
    this.isStatusBarAvailable =
      this.platformService.isPluginAvailable('StatusBar');
    this.pictureView = this.$element[0];
    this.currentImage = this.images[this.activeSlide];
    this.canZoom = !this.lockZooming;
    this.disableScroll = this.images.length === 1;
    this.options = {
      pagination: '',
      initialSlide: this.activeSlide,
    };

    this.screenOrientationService
      .screenOrientationChangeListener(() => this.onOrientationChange())
      .then((handle) => {
        this.screenOrientationChangeListenerHandle = handle;
      });

    // Workaround to make zooming and sliding work together
    (this.$document[0] as HTMLDocument).addEventListener(
      'gestureend',
      (event: any) => {
        if (event.scale < 1.0) {
          // User moved fingers closer together
          this.disableScroll = false;
        } else if (event.scale > 1.0) {
          // User moved fingers further apart
          this.disableScroll = true;
        }
        this.$scope.$apply();
      },
      false
    );

    this.$scope.$on('$ionicSlides.sliderInitialized', (_, data) => {
      this.slider = data.slider;
    });

    this.$scope.$on('$ionicSlides.slideChangeStart', (_, data) => {
      this.setActiveSlide({ activeIndex: data.slider.activeIndex });
    });

    this.$scope.$on('$ionicSlides.slideChangeEnd', (_, data) => {
      this.activeSlide = data.slider.activeIndex;
      this.setActiveSlide({ activeIndex: data.slider.activeIndex });

      this.$scope.$apply();
    });

    this.showView();
  }

  $onDestroy(): void {
    this.screenOrientationChangeListenerHandle.remove();
  }

  $onChanges(changes: ng.IOnChangesObject): void {
    if (changes.lockZooming) {
      this.canZoom = !this.lockZooming;
    }

    if (changes.activeSlide && this.slider) {
      this.slider.slideTo(changes.activeSlide.currentValue);
    }
  }

  $doCheck(): void {
    if (!this.slider) {
      return;
    }

    if (this.slider.activeIndex !== this.activeSlide) {
      this.slider.slideTo(this.activeSlide);
    }
  }

  showView(): void {
    this.pictureView.style.opacity = '1';
    this.hide = false;
    this.isStatusBarAvailable && this.StatusBar.show();
  }

  slideHasChanged(index: number): void {
    this.currentImage = this.images[index];
  }

  onOrientationChange(): void {
    // Need to scroll top before resizing so screen isn't shift for oriented content
    this.contentService.scrollTop(SCROLL_DELEGATE_HANDLE);
    this.$scope.$apply();
  }

  toggleInfos(): void {
    this.hideElems = !this.hideElems;
    if (this.hideElems) {
      this.isStatusBarAvailable && this.StatusBar.hide();
      return;
    }
    this.isStatusBarAvailable && this.StatusBar.show();
  }

  share(): ng.IPromise<void> {
    return this.downloadFromApi(false).then((filePath) =>
      this.shareService.share(filePath)
    );
  }

  download(): ng.IPromise<void | PhotoResponse | null> {
    if (this.platformService.isBrowser()) {
      this.downloadImageInBrowser();
      return this.$q.resolve();
    }

    return this.downloadFromApi().then((path) =>
      this.filesSystemService.saveInGallery(path)
    );
  }

  downloadImageInBrowser(): void {
    const { url, name = 'image' } = this.images[this.activeSlide];

    const link = Object.assign(this.$window.document.createElement('a'), {
      href: url,
      download: name,
      target: '_blank',
    });

    link.click();
  }

  downloadFromApi(isPopupsShow = true): ng.IPromise<string> {
    const fileOptions = {
      directory: this.filesSystemService.getDeviceCachedPath(),
      path: PICTURE_DOWNLOAD_FOLDER_NAME,
      name: `${this.images[this.activeSlide].id}.jpg`,
    };

    return this.documentDownloadService
      .download(fileOptions, this.images[this.activeSlide], { isPopupsShow })
      .then((path: string) => path);
  }

  onDragEnd(): void {
    if (this.hide) {
      return;
    }

    this.showView();
  }
}
