import { complement, intersection, isNil, path, pickBy } from 'ramda';
import { ObjectId, User } from '../../..';
import { ButtonSelectorOption } from '../../../components/Buttons/button-selector/button-selector.controller';
import { StepContent } from '../../../components/Modals/modal-stepper/modal-stepper.controller';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import type { FilesService } from '../../../services/API/files/files.service';
import { LinkPreview } from '../../../services/API/link-preview/types';
import { Targeting } from '../../../targeting/types';
import { NewsfeedApiService } from '../../services/newsfeed-api/newsfeed-api.service';
import { NewsfeedPopupService } from '../../services/newsfeed-popup/newsfeed-popup.service';
import { NewsfeedService } from '../../services/newsfeed-service/newsfeed.service';
import type {
  EditedResourcePreview,
  NewsfeedPost,
  NewsfeedPostResource,
  NewsfeedPostResourceType,
  PostContents,
  ResourceFile,
} from '../../types';

type PickedPostPayload = Pick<
  PostContents,
  'text' | 'category_id' | 'attachedResources' | 'isDraft'
> &
  Pick<NewsfeedPost, 'targeting' | 'linkPreview'>;

// Here we operate data model
export class NewsfeedPostCreationModalController {
  // bindings
  profile: User;
  postToEdit: NewsfeedPost;
  isPublished: boolean;
  onClose: () => void;
  onSave: () => (obj: Record<string, unknown>) => void;

  steps: StepContent[];
  postId: ObjectId | null;
  newsfeedPost: {
    text?: string;
    category_id?: ObjectId;
    attachedResources?: NewsfeedPostResource[];
    targeting?: Targeting;
    linkPreview?: LinkPreview;
    publication_date?: string;
    isDraft?: boolean;
  };

  notify = false;
  removedFileIds: ObjectId[] = [];
  newImageFiles: ResourceFile[] = [];
  newDocuments: ResourceFile[] = [];
  newVideos: ResourceFile[] = [];
  prevImageFiles: NewsfeedPostResource[] = [];
  prevDocumentFiles: NewsfeedPostResource[] = [];
  prevVideoFiles: NewsfeedPostResource[] = [];
  displayedResources: {
    images: EditedResourcePreview[];
    documents: EditedResourcePreview[];
    videos: EditedResourcePreview[];
  } = { images: [], documents: [], videos: [] };
  newsfeedPostFormTouched = false;

  // eslint-disable-next-line max-params
  constructor(
    private $q: ng.IQService,
    private filesService: FilesService,
    private newsfeedService: NewsfeedService,
    private newsfeedApiService: NewsfeedApiService,
    private newsfeedPopupService: NewsfeedPopupService,
    private $scope: ng.IScope,
    private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
    private $timeout: ng.ITimeoutService
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    this.postId = this.getPostId();
    this.$scope.$on('newsfeedPostFormTouched', () => {
      this.newsfeedPostFormTouched = true;
    });
    this.newsfeedPost = this.postToEdit
      ? {
          text: this.postToEdit.contents.text,
          category_id: this.postToEdit.contents.category_id,
          attachedResources: this.postToEdit.contents.attachedResources,
          targeting: this.postToEdit.targeting,
          isDraft: !!this.postToEdit.contents.isDraft,
        }
      : {
          attachedResources: [],
        };

    if (this.postToEdit && this.postToEdit.contents.publication_date) {
      this.newsfeedPost.publication_date =
        this.postToEdit.contents.publication_date;
    }

    return this.getAttachedResources().then(() => this.setSteps());
  }

  getAttachedResources(): ng.IPromise<void> {
    if (!(this.newsfeedPost && this.newsfeedPost.attachedResources)) {
      return this.$q.resolve();
    }

    const filterByType =
      (typeFilter) =>
      ({ type }) =>
        type === typeFilter;

    this.prevImageFiles = this.newsfeedPost.attachedResources.filter(
      filterByType('image')
    );
    this.prevDocumentFiles = this.newsfeedPost.attachedResources.filter(
      filterByType('document')
    );
    this.prevVideoFiles = this.newsfeedPost.attachedResources.filter(
      filterByType('video')
    );

    return this.newsfeedService
      .buildAttachedResourcesObjects(
        this.newsfeedPost.attachedResources,
        this.SF_IMAGE_SIZES.SQUARE_LARGE
      )
      .then(({ images, documents, videos }) => {
        this.displayedResources.images = images
          ? images.map((image) => ({ ...image, _id: image.id }))
          : [];
        this.displayedResources.documents = documents
          ? documents.map((document) => ({ ...document, _id: document.id }))
          : [];
        this.displayedResources.videos = videos
          ? videos.map((video) => ({ ...video, _id: video.id }))
          : [];
      });
  }

  setSteps(): void {
    this.steps = [
      {
        title: this.postToEdit
          ? 'NEWSFEED_EDIT_POST'
          : 'NEWSFEED_CREATE_NEW_POST',
        template: `
          <sf-newsfeed-post-form
            newsfeed-post="$ctrl.resolve.newsfeedPost"
            profile="$ctrl.resolve.profile"
            post-id="$ctrl.resolve.postId"
            displayed-resources="$ctrl.resolve.displayedResources"
            on-next-button-click="$ctrl.resolve.stepper.nextStep()"
            on-resources-add="$ctrl.resolve.onResourcesAdd(resources, type)"
            on-resource-delete="$ctrl.resolve.onResourceDelete(resourceId)"
            on-remove-added-resource="$ctrl.resolve.onRemoveAddedResource(resourceId, resourceType)"
            on-link-preview-updated="$ctrl.resolve.onLinkPreviewUpdated(linkPreview)"
          ></sf-newsfeed-post-form>`,
        resolve: {
          profile: this.profile,
          newsfeedPost: this.newsfeedPost,
          postId: this.postToEdit ? this.postToEdit._id : null,
          displayedResources: this.displayedResources,
          onSavePost: () => this.onSavePost(),
          onResourcesAdd: (resources, type) =>
            this.onResourcesAdd(resources, type),
          onResourceDelete: (resourceId) => this.onResourceDelete(resourceId),
          onRemoveAddedResource: (resourceId, resourceType) =>
            this.onRemoveAddedResource(resourceId, resourceType),
          onLinkPreviewUpdated: (linkPreview: LinkPreview) =>
            this.onLinkPreviewUpdated(linkPreview),
        },
      },
      {
        title: 'NEWSFEED_POST_STEP_TWO_TITLE',
        template: `
          <sf-newsfeed-post-form-options
            post-id="$ctrl.resolve.postId"
            is-published="$ctrl.resolve.isPublished"
            newsfeed-post="$ctrl.resolve.newsfeedPost"
            on-select-category="$ctrl.resolve.onSelectCategory(option)"
            on-targeting-updated="$ctrl.resolve.onTargetingUpdated(targeting)"
            on-publish-date-updated="$ctrl.resolve.onPublishDateUpdated(date)"
            on-notify-set="$ctrl.resolve.onNotifySet(isSet)"
            on-save-post="$ctrl.resolve.onSavePost(isDraft)"
          >
          </sf-newsfeed-post-form-options>
        `,
        resolve: {
          postId: this.postToEdit ? this.postToEdit._id : null,
          newsfeedPost: this.newsfeedPost,
          isPublished: this.isPublished,
          onSelectCategory: (option) => this.onSelectCategory(option),
          onTargetingUpdated: (targeting) => this.onTargetingUpdated(targeting),
          onPublishDateUpdated: (date) => this.onPublishDateUpdated(date),
          onNotifySet: (isSet: boolean) => {
            this.notify = isSet;
          },
          onSavePost: (isDraft: boolean) => this.onSavePost(isDraft),
        },
      },
    ];
  }

  // API methods
  onPostCreate(post: PickedPostPayload): ng.IPromise<NewsfeedPost> {
    return this.newsfeedApiService.createPost(
      { contents: post },
      { notify: this.notify }
    );
  }

  onPostUpdate(
    post: PickedPostPayload,
    postId: ObjectId
  ): ng.IPromise<NewsfeedPost> {
    return this.newsfeedApiService.updatePost({ contents: post }, postId);
  }

  // Popup methods
  showPostNotExistsError(): ng.IPromise<void> {
    return this.newsfeedPopupService.showPostNotExistsError();
  }

  showCloseConfirmModal(): ng.IPromise<void> | void {
    if (this.newsfeedPostFormTouched) {
      return this.newsfeedPopupService
        .showCloseCreateEditConfirm()
        .then(() => this.onClose());
    }
    return this.onClose();
  }

  getPostId(): ObjectId | null {
    return this.postToEdit?._id;
  }

  onSavePost(isDraft = false): ng.IPromise<void> {
    const popupRequest = this.newsfeedPopupService.showPostSendingProgress();

    return this.uploadFiles()
      .then((loadedFiles) => this.buildPost(loadedFiles))
      .then((post) => {
        if (post.isDraft || isDraft) {
          post.isDraft = isDraft;
        }

        return this.postId
          ? this.onPostUpdate(post, this.postId)
          : this.onPostCreate(post);
      })
      .then(({ contents }: NewsfeedPost) => {
        const fileIds = intersection(
          [...this.prevImageFiles, ...this.prevDocumentFiles].map<ObjectId>(
            ({ file_id }) => file_id
          ),
          this.removedFileIds
        );
        const videoFileIds = intersection(
          this.prevVideoFiles.map(({ file_id }) => file_id),
          this.removedFileIds
        );

        this.deleteFiles(fileIds, videoFileIds);

        if (contents.isDraft) {
          popupRequest.clear();

          return this.onSave()({
            postId: this.postId,
            updatedPostContents: contents,
            tab: 'draft',
          });
        }

        if (contents.publication_date) {
          popupRequest.clear();

          return this.onSave()({
            postId: this.postId,
            updatedPostContents: contents,
            tab: 'schedule',
          });
        }

        return popupRequest.onSuccess().then(() => {
          this.onSave()({
            postId: this.postId,
            updatedPostContents: contents,
            tab: 'overview',
          });
        });
      })
      .catch((error) => {
        // for deleted posts we have specific response
        // and no retry
        const code = path(['data', 'code'])(error);

        if (code === 'E_POST_NOT_EXISTS') {
          popupRequest.clear();
          this.showPostNotExistsError();
          return;
        }
        popupRequest.onError(() => this.onSavePost());
      });
  }

  uploadFiles(): ng.IPromise<ResourceFile[]> {
    if (
      this.newImageFiles.length === 0 &&
      this.newDocuments.length === 0 &&
      this.newVideos.length === 0
    ) {
      return this.$q.resolve([]);
    }
    return this.$q
      .all([
        this.uploadResourceFiles('image', this.newImageFiles),
        this.uploadResourceFiles('document', this.newDocuments),
        this.uploadResourceFiles('video', this.newVideos),
      ])
      .then(([images, documents, videos]) => [
        ...images,
        ...documents,
        ...videos,
      ])
      .catch((fileError) => {
        const fileIds = [...this.newImageFiles, ...this.newDocuments].map(
          ({ _id }) => _id
        );

        this.deleteFiles(
          fileIds,
          this.newVideos.map(({ _id }) => _id)
        );
        throw fileError;
      });
  }

  uploadResourceFiles(
    resourceType: NewsfeedPostResourceType,
    resourceFiles: ResourceFile[]
  ): ng.IPromise<ResourceFile[]> {
    const files: ResourceFile[] = [];

    if (!resourceFiles.length || !resourceFiles[0]) {
      return this.$q.resolve(files);
    }
    return this.$q
      .all(
        resourceFiles.map((file) => {
          files.push(file);
          const isAsset = this.newsfeedService.isVideoResource(resourceType);

          return this.filesService.upload(
            file,
            file._id,
            {
              config: { pov: 'organisation' },
            },
            isAsset
          );
        })
      )
      .then(() => files);
  }

  deleteFiles(fileIds: ObjectId[], videoFileIds: ObjectId[] = []): void {
    fileIds.forEach((fileId) => this.filesService.deleteFile(fileId, false));
    videoFileIds.forEach(
      (videoFileId) => this.filesService.deleteFile(videoFileId, true) // TODO: use digitalAssets service instead of the filesService
    );
  }

  // update functions
  onSelectCategory(category: ButtonSelectorOption): void {
    this.newsfeedPost.category_id = category.id;
  }

  onTargetingUpdated(targeting: Targeting): void {
    this.newsfeedPost.targeting = targeting;
  }

  onLinkPreviewUpdated(linkPreview: LinkPreview): void {
    this.newsfeedPost.linkPreview = linkPreview;
  }

  onPublishDateUpdated(date: string): void {
    this.newsfeedPost.publication_date = date;
  }

  onResourcesAdd(files: ResourceFile[], type: NewsfeedPostResourceType): void {
    if (this.newsfeedService.isImageResource(type)) {
      this.newImageFiles = [...this.newImageFiles, ...files];
      this.displayedResources.images = [
        ...this.displayedResources.images,
        ...files,
      ];
    }
    if (this.newsfeedService.isDocumentResource(type)) {
      this.newDocuments = [files[0]];
      this.displayedResources.documents = [files[0]];
    }

    if (this.newsfeedService.isVideoResource(type)) {
      this.newVideos = [files[0]];
      this.displayedResources.videos = [files[0]];
    }

    this.$timeout(() => {
      this.$scope.$apply();
    });
  }

  getResourceVariableNames(resourceType: NewsfeedPostResourceType): {
    displayedResourcesName: string;
    newResources: string;
  } {
    switch (resourceType) {
      case 'video':
        return {
          displayedResourcesName: 'videos',
          newResources: 'newVideos',
        };
      case 'document':
        return {
          displayedResourcesName: 'documents',
          newResources: 'newDocuments',
        };
      case 'image':
      default:
        return {
          displayedResourcesName: 'images',
          newResources: 'newImageFiles',
        };
    }
  }

  onRemoveAddedResource(
    resourceId: ObjectId,
    resourceType: NewsfeedPostResourceType
  ): void {
    const checkNonSelected = (resource: ResourceFile): boolean =>
      resource._id !== resourceId;
    const { displayedResourcesName, newResources } =
      this.getResourceVariableNames(resourceType);

    this.displayedResources[displayedResourcesName] =
      this.displayedResources[displayedResourcesName].filter(checkNonSelected);
    this[newResources] = this[newResources].filter(checkNonSelected);
    this.onResourceDelete(resourceId);
  }

  onResourceDelete(resourceId: ObjectId): void {
    this.removedFileIds.push(resourceId);
  }

  // build functions
  buildPost(loadedFiles: ResourceFile[]): PickedPostPayload {
    const { text, category_id, linkPreview, publication_date, isDraft } =
      this.newsfeedPost;
    let { targeting } = this.newsfeedPost;
    if (!targeting?.links.length) {
      targeting = undefined;
    }
    const attachedResources = this.buildPostResources(loadedFiles);
    const postContentsPatched = pickBy(complement(isNil), {
      text,
      category_id,
      attachedResources,
      targeting,
      linkPreview,
      publication_date,
      isDraft,
    });

    return postContentsPatched as PickedPostPayload;
  }

  buildPostResources(loadedFiles: ResourceFile[]): NewsfeedPostResource[] {
    const prevResourceFiles = [
      ...this.prevImageFiles,
      ...this.prevDocumentFiles,
      ...this.prevVideoFiles,
    ];
    const prevFiles =
      this.removedFileIds.length > 0
        ? prevResourceFiles.filter(
            ({ file_id }) => !this.removedFileIds.find((id) => id === file_id)
          )
        : prevResourceFiles;

    const newFiles = loadedFiles.map((file) => this.buildResource(file));

    return [...prevFiles, ...newFiles];
  }

  buildResource(file: ResourceFile): NewsfeedPostResource {
    const { _id, name, type, size } = file;
    const resourceType = this.newsfeedService.getResourceTypeFromMimeType(type);

    return {
      file_id: _id,
      name,
      type: resourceType,
      mimeType: type, // type and size File's properties
      size,
    };
  }
}
