import { path } from 'ramda';

export const NewsfeedPostCommentsComponent = {
  bindings: {
    profile: '<',
    comments: '<',
    commentsUser: '<',
    isCommentsLoading: '<',
    hasCommentsErrors: '<',
    editedCommentId: '<',
    onDeleteComment: '&',
    onEditComment: '&',
    onReload: '&',
  },
  templateUrl:
    'newsfeed/components/newsfeed-post-comments/newsfeed-post-comments.html',
  controller: class NewsfeedPostCommentsController {
    // eslint-disable-next-line max-params
    constructor(newsfeedActionsService) {
      'ngInject';
      Object.assign(this, { newsfeedActionsService });
    }

    $onInit() {
      this.commentsActions = this.getCommentsActions();
    }
    $onChanges({ comments }) {
      if (comments) {
        this.commentsActions = this.getCommentsActions();
      }
    }

    getCommentsActions() {
      return this.comments.reduce((acc, comment) => {
        const { _id } = comment;

        acc[_id] = this.newsfeedActionsService.getAvailableActions({
          author_id: path(['contents', 'author_id'])(comment),
          profile: this.profile,
          callbacks: {
            onDelete: () => this.onDeleteComment({ commentId: _id }),
            onEdit: () => this.onEditComment({ commentId: _id }),
          },
        });

        return acc;
      }, {});
    }

    getUserFromComment(userId) {
      const commentsUserWithProfile = {
        ...this.commentsUser,
        [this.profile._id]: this.profile,
      };

      return commentsUserWithProfile[userId];
    }
  },
};
