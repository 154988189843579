"use strict";

/* eslint global-require:[0] */
module.exports = {
  comments: require('./comments/comment.lib.js'),
  documents: require('./documents/document.lib.js'),
  events: require('./events/event.lib.js'),
  forms: require('./forms/form.lib.js'),
  hooks: require('./hooks/hook.lib.js'),
  missionsGroups: require('./missionsGroups/missionsGroup.lib.js'),
  organisations: require('./organisations/organisation.lib.js'),
  params: require('./params/param.lib.js'),
  places: require('./places/place.lib.js'),
  placesLists: require('./placesLists/placesList.lib.js'),
  products: require('./products/product.lib.js'),
  reports: require('./reports/report.lib.js'),
  tokens: require('./tokens/token.lib.js'),
  users: require('./users/user.lib.js'),
  usersGroups: require('./usersGroups/usersGroup.lib.js'),
  views: require('./views/view.lib.js'),
  webhooks: require('./webhooks/webhook.lib'),
  visits: require('./visits/visit.lib'),
  tasks: require('./tasks/task.lib.js'),
  validations: require('./validations/validation.lib'),
  calendarEventTypes: require('./calendarEventTypes/calendarEventType.lib'),
  calendarEvents: require('./calendarEvents/calendarEvent.lib'),
  calendarEventChangeRequests: require('./calendarEventChangeRequests/calendarEventChangeRequest.lib'),
  commentSchema: require('./comments/comment.schema.js'),
  documentSchema: require('./documents/document.schema.js'),
  eventSchema: require('./events/event.schema.js'),
  formSchema: require('./forms/form.schema.js'),
  hookSchema: require('./hooks/hook.schema.js'),
  missionsGroupSchema: require('./missionsGroups/missionsGroup.schema.js'),
  organisationSchema: require('./organisations/organisation.schema.js'),
  paramSchema: require('./params/param.schema.js'),
  placeSchema: require('./places/place.schema.js'),
  placesListSchema: require('./placesLists/placesList.schema.js'),
  productSchema: require('./products/product.schema.js'),
  reportSchema: require('./reports/report.schema.js'),
  tokenSchema: require('./tokens/token.schema.js'),
  userSchema: require('./users/user.schema.js'),
  usersGroupSchema: require('./usersGroups/usersGroup.schema.js'),
  viewsSchema: require('./views/view.schema.js'),
  webhookSchema: require('./webhooks/webhook.schema.js'),
  visitSchema: require('./visits/visit.schema.js'),
  taskSchema: require('./tasks/task.schema.js'),
  validationSchema: require('./validations/validation.schema.js'),
  calendarEventTypeSchema: require('./calendarEventTypes/calendarEventType.schema.js'),
  calendarEventSchema: require('./calendarEvents/calendarEvent.schema.js'),
  calendarEventChangeRequestSchema: require('./calendarEventChangeRequests/calendarEventChangeRequest.schema.js'),
  commentExamples: require('./comments/comment.examples.js'),
  documentExamples: require('./documents/document.examples.js'),
  eventExamples: require('./events/event.examples.js'),
  formExamples: require('./forms/form.examples.js'),
  hookExamples: require('./hooks/hook.examples.js'),
  missionsGroupExamples: require('./missionsGroups/missionsGroup.examples.js'),
  organisationExamples: require('./organisations/organisation.examples.js'),
  paramExamples: require('./params/param.examples.js'),
  placeExamples: require('./places/place.examples.js'),
  placesListExamples: require('./placesLists/placesList.examples.js'),
  productExamples: require('./products/product.examples.js'),
  reportExamples: require('./reports/report.examples.js'),
  tokenExamples: require('./tokens/token.examples.js'),
  userExamples: require('./users/user.examples.js'),
  userGroupExamples: require('./usersGroups/usersGroup.examples.js'),
  viewExamples: require('./views/view.examples.js'),
  webhookExamples: require('./webhooks/webhook.examples.js'),
  visitExamples: require('./visits/visit.examples.js'),
  taskExamples: require('./tasks/task.examples.js'),
  validationExamples: require('./validations/validation.examples.js'),
  calendarEventTypeExamples: require('./calendarEventTypes/calendarEventType.examples.js'),
  calendarEventExamples: require('./calendarEvents/calendarEvent.examples.js'),
  calendarEventChangeRequestExamples: require('./calendarEventChangeRequests/calendarEventChangeRequest.examples.js'),
  utils: {
    formulas: require('./utils/formulas.js'),
    syntax: require('./utils/syntax.js'),
    createObjectId: require('./utils/createObjectId.js')
  }
};