import { IntercomService } from './intercom.service';
import { WebIntercomBridge } from './web-intercom-bridge';

export default angular
  .module('sfMobile.services.intercom', [
    'sfMobile.services.platform',
    'sfMobile.config.server',
    'sfMobile.services.trackingTransparency',
  ])
  .service('intercomService', IntercomService)
  .service('webIntercomBridge', WebIntercomBridge).name;
