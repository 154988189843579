export const SelectComponent = {
  bindings: {
    options: '<', // can be an array ['value'...] or collection [{value: 'value', label: 'label'}...]
    inputName: '@',
    placeholder: '@',
    isRequired: '<',
    isDisabled: '<',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  templateUrl: 'components/Inputs/select/select.html',
  controller: function SelectController() {
    'ngInject';
    this.$onInit = () => {
      this.isObjType = !!(
        this.options.length &&
        this.options[0] &&
        typeof this.options[0] === 'object'
      );
      this.modelCtrl.$render = () => {
        this.value = this.isObjType
          ? this.options.filter(
              (item) => item.value === this.modelCtrl.$viewValue
            )[0]
          : this.modelCtrl.$viewValue;
      };
    };

    this.changeValue = () =>
      this.modelCtrl.$setViewValue(
        this.isObjType ? this.value.value : this.value
      );

    this.getOptionLabel = (option) => option.label || option;
  },
};
