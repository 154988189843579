import { LoginComponent } from './login.component';

export default angular
  .module('sfMobile.components.login', [
    'pascalprecht.translate',
    'sfMobile.services.auth',
    'sfMobile.services.segment',
    'sfMobile.services.modal',
    'sfMobile.services.error-messages',
    'sfMobile.services.keyboard',
    'sfMobile.components.divider',
    'sfMobile.components.button-loader-delay',
  ])
  .component('sfLogin', LoginComponent).name;
