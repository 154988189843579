import { QuestionProductScanComponent } from './question-product-scan.component';
import QuestionProductScanItemModule from './components/question-product-scan-item.module';

export default angular
  .module('sfMobile.components.question-product-scan', [
    'pascalprecht.translate',
    'sfMobile.services.products',
    'sfMobile.services.popup',
    QuestionProductScanItemModule,
    'sfMobile.components.barcode-button',
  ])
  .component('questionProductScan', QuestionProductScanComponent).name;
