import { DateService } from '@services/Utils/Dates/date.service';
import type {
  Campaign,
  SimplifiedCampaignObjectiveCompletion,
} from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';
import { CampaignsUtilsService } from '../../services/campaigns-utils.service';

export const ReactiveCampaignItemComponent: ng.IComponentOptions = {
  bindings: {
    campaign: '<',
    clearable: '<?',
    isLinked: '<?',
    onDelete: '&',
  },
  templateUrl:
    'reactive-campaigns/components/reactive-campaign-item/reactive-campaign-item.html',
  controller: class ReactiveCampaignItemController implements ng.IController {
    /** Bindings */
    campaign: Campaign;
    isLinked: boolean;

    /** Fields */
    completion?: SimplifiedCampaignObjectiveCompletion;
    objectiveLabel: {
      title?: string;
    };
    objectivePercent: number;
    objectiveColorClass: string;
    objectiveLabelColorClass?: string;

    outOfScope = false;
    deactivated = false;
    archived = false;

    thumbImageUrl = '';
    disableChecklist = false;
    frequencyDue;
    displayDueTag = false;

    constructor(
      private campaignsService: CampaignsService,
      private campaignsUtilsService: CampaignsUtilsService,
      private $q: ng.IQService,
      private $translate: ng.translate.ITranslateService,
      private dateService: DateService
    ) {
      'ngInject';
    }
    $onInit(): void {
      this.outOfScope = !!this.campaign?.contents?.outOfScope;
      this.deactivated = this.campaign?.contents?.state === 'deactivated';
      this.archived = !!this.campaign?.archived;

      this.disableChecklist =
        this.displayActivationTime() || this.deactivated || this.outOfScope;

      this.initDueDate();
    }

    $onChanges(): ng.IPromise<[void, void]> {
      this.initDueDate();

      return this.$q.all([this.getCampaignCover(), this.getObjectiveData()]);
    }

    initDueDate() {
      if (this.isFrequency()) {
        const { perMonth } = this.campaign.contents.objective!;

        this.frequencyDue =
          this.campaignsUtilsService.initFrequencyActivePeriodDates(
            this.campaign,
            !!perMonth?.length
          ).due;

        this.displayDueTag =
          this.frequencyDue.diff(new Date(), 'hours') < 24 &&
          this.campaignsUtilsService.isFrequencyActivePeriod(this.campaign);
      }
    }

    getObjectiveData(): ng.IPromise<void> {
      return this.campaignsService
        .getCampaignObjectiveCompletion(this.campaign._id)
        .then((completion?: SimplifiedCampaignObjectiveCompletion) => {
          this.completion = completion;

          if (completion) {
            this.objectivePercent =
              this.campaignsUtilsService.getObjectiveCompletionPercent(
                completion
              );
            this.objectiveLabel = {
              title:
                this.objectivePercent > 0 && this.objectivePercent < 100
                  ? `${this.objectivePercent} %`
                  : '<i class="fa-light fa-check"></i>',
            };

            if (this.objectivePercent === 0) {
              this.setEmptyObjectiveDesign();
            } else if (this.objectivePercent === 100) {
              this.setFullObjectiveDesign();
            } else {
              this.setPartialObjectiveDesign();
            }
          }
        });
    }

    isArchived(): boolean {
      return !!this.campaign?.archived;
    }

    isDeactivated(): boolean {
      return this.campaign?.contents?.state === 'deactivated';
    }

    isOutOfScope(): boolean {
      return !!this.campaign?.contents?.outOfScope;
    }

    getFrequencyDueTime() {
      const { perDay, activationPeriod } = this.campaign.contents.objective!;

      return perDay ? activationPeriod.due : null;
    }

    isFrequency(): boolean {
      return (
        Boolean(this.campaign.contents.objective?.perDay) ||
        Boolean(this.campaign.contents.objective?.perMonth)
      );
    }

    getCampaignCover(): ng.IPromise<void> {
      if (this.campaign.contents.picture) {
        return this.campaignsService
          .getCampaignCoverUrl(this.campaign, 'medium')
          .then((url) => {
            this.thumbImageUrl = url;
          });
      }

      return this.$q.resolve();
    }

    setEmptyObjectiveDesign(): void {
      this.objectiveColorClass = 'sf_progressCircle__percentCircle--sfGrey';
      this.objectiveLabelColorClass = 'sf_progressCircle__label--sfGrey';
      // To set the full circle in the same color
      this.objectivePercent = 100;
    }
    setPartialObjectiveDesign(): void {
      this.objectiveColorClass = 'sf_progressCircle__percentCircle--sfRed';
      this.objectiveLabelColorClass = undefined;
    }
    setFullObjectiveDesign(): void {
      this.objectiveColorClass = 'sf_progressCircle__percentCircle--sfGreen';
      this.objectiveLabelColorClass = 'sf_progressCircle__label--sfGreen';
    }

    getSubInfo() {
      if (this.outOfScope) {
        return this.$translate.instant('EVENT_CHECKLIST_NOT_ALLOWED');
      }

      if (this.deactivated) {
        return this.$translate.instant(
          this.archived
            ? 'EVENT_ATTACH_CHECKLIST_ARCHIVED'
            : 'EVENT_ATTACH_CHECKLIST_DEACTIVATED'
        );
      }

      if (this.displayActivationTime()) {
        const activationDate = this.campaign.contents.activation_date;
        const isActivationDateIsPast =
          !activationDate || this.dateService.isPast(activationDate);

        return this.isFrequency() && isActivationDateIsPast
          ? this.campaignsUtilsService.getFrequencyNextActivationTime(
              this.campaign
            )
          : this.campaignsUtilsService.getActivationTime(this.campaign);
      }

      if (this.isFrequency()) {
        return this.campaignsUtilsService
          .getFrequencyPeriods(this.campaign)
          .at(1)!;
      } else {
        return this.campaignsUtilsService.getActivationPeriod(this.campaign);
      }
    }

    displayActivationTime(): boolean {
      if (!this.isLinked) {
        return false;
      }

      const activationDate = this.campaign.contents.activation_date;
      const isNotStartedYet =
        !!activationDate &&
        this.dateService.isAfter(activationDate, new Date());

      return (
        isNotStartedYet ||
        (this.isFrequency() &&
          !this.campaignsUtilsService.isFrequencyActivePeriod(this.campaign))
      );
    }
  },
};
