const FIRST_YEAR = 1970;

export const InputTimeComponent = {
  bindings: {
    inputName: '@',
    hasError: '<',
    isRequired: '<',
    isDisabled: '<',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  templateUrl: 'components/Inputs/input-time/input-time.html',
  controller: function InputTimeController(platformService) {
    'ngInject';
    const isDefined = (value) => typeof value !== 'undefined' && value !== null;
    const convertValue = (value) => {
      const hourInMs = 3600000;
      const minuteInMs = 60000;
      const secondInMs = 1000;

      const nbHours = Math.floor(value / hourInMs);
      const nbMinutes = Math.floor((value - nbHours * hourInMs) / minuteInMs);
      const nbSeconds = Math.floor(
        (value - nbHours * hourInMs - nbMinutes * minuteInMs) / secondInMs
      );

      return new Date(FIRST_YEAR, 0, 1, nbHours, nbMinutes, nbSeconds, 0);
    };

    const transformValue = (value) => {
      const hourInMs = 3600000;
      const minuteInMs = 60000;
      const secondInMs = 1000;
      const date = new Date(value);
      const ms =
        date.getHours() * hourInMs +
        date.getMinutes() * minuteInMs +
        date.getSeconds() * secondInMs;

      return ms;
    };

    this.$onInit = () => {
      this.isIOS = platformService.isiOS();
      this.modelCtrl.$render = () => {
        const modelValue = this.modelCtrl.$viewValue;

        this.value = isDefined(modelValue)
          ? convertValue(modelValue)
          : modelValue;
      };
    };

    this.onValueChange = (value) => {
      this.modelCtrl.$setViewValue(value ? transformValue(value) : value);
    };

    this.reset = () => {
      this.modelCtrl.$setViewValue(null);
      this.value = null;
    };
  },
};
