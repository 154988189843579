export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 13,
    method: (sqlStorageService, localStorageService, sentryService) => {
      'ngInject';
      /* Report table migration */

      const newReportsFields = [
        'id NVARCHAR(32) PRIMARY KEY UNIQUE',
        'localStatus TEXT',
        'form_id NVARCHAR(32)',
        'place_id NVARCHAR(32)',
        'payload TEXT',
        'campaign_id NVARCHAR(32)',
      ].join(',');
      const oldReportsFields = [
        'id',
        'localStatus',
        'form_id',
        'place_id',
        'payload',
      ].join(',');

      const createReportsTempTableQuery = `CREATE TEMPORARY TABLE reports_backup(${oldReportsFields})`;
      const insertInReportsTempTableQuery = `INSERT INTO reports_backup SELECT ${oldReportsFields} FROM reports`;
      const dropReportsTableQuery = 'DROP TABLE reports';
      const createReportsTableQuery = `CREATE TABLE reports(${newReportsFields})`;
      const insertInReportsTableQuery = `INSERT INTO reports SELECT ${oldReportsFields},NULL FROM reports_backup`;
      const dropReportsTempTableQuery = 'DROP TABLE reports_backup';

      return (
        /* Reports migration execution */
        sqlStorageService
          .execute(createReportsTempTableQuery)
          .then(() => sqlStorageService.execute(insertInReportsTempTableQuery))
          .then(() => sqlStorageService.execute(dropReportsTableQuery))
          .then(() => sqlStorageService.execute(createReportsTableQuery))
          .then(() => sqlStorageService.execute(insertInReportsTableQuery))
          .then(() => sqlStorageService.execute(dropReportsTempTableQuery))
          /* Force update */
          .then(() => localStorageService.remove('synchronise_date'))
          .catch((error) =>
            sentryService.captureMessage('MIGRATION_SCRIPT_FAIL_13', {
              level: 'error',
              extra: {
                error,
              },
              tags: {
                sendNotification: 'mail',
              },
            })
          )
      );
    },
  });
}
