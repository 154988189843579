export const IMAGE_SIZES = {
  SQUARE_SMALL: '128x128',
  SQUARE_MEDIUM: '256x256',
  SQUARE_LARGE: '512x512',
  SQUARE_BIG: '2000x2000',
  RECTANGLE_MEDIUM: '640x480',
} as const;

export default angular
  .module('sfMobile.constants.image-sizes', [])
  .constant('SF_IMAGE_SIZES', IMAGE_SIZES).name;
