import { EntityType, ObjectId, TSFixMe } from '../../..';
import { UsersService } from '../../../services/API/users/users.service';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import type { ModalService } from '../../../services/Utils/Modal';
import { TARGETING_TYPES as SF_TARGETING_TYPES } from '../../constants/targeting-types.constants';
import { Targeting, TargetingTypeObject, TargetLinkType } from '../../types';

export class ResourceTargetSelectorController {
  // bindings
  prevTargeting: Targeting;
  hiddenTargeting: string[] = [];
  onUpdated: (options: {
    targeting: {
      type: TargetLinkType;
      targeting_ids: ObjectId[];
    } | null;
  }) => void;
  isScoped: boolean; // Retrieve entites from my scope
  reverseInputDisplay: boolean;

  // local properties
  targetingTypes: TargetingTypeObject[];
  selectedTargetingType: TargetLinkType;
  selectedTargetingIcon?: string;
  targeting_ids: ObjectId[] = [];
  cachedState: Record<TargetLinkType, any> = {
    usersGroup: null,
    storesGroup: null,
    placesList: null,
    adminOnly: null,
    all: null,
  };
  entityTitleGet: (entity: TSFixMe) => string;
  entityDescriptionGet: (entity: TSFixMe) => string;
  entityGetNumberOfEntities: (entity: TSFixMe) => number;
  title: string;
  selected: TSFixMe[];
  entities: TSFixMe[];
  GET_LIMIT = 20;
  isRTLNeeded: boolean;
  postId: ObjectId | null;

  // eslint-disable-next-line max-params
  constructor(
    private localizationService: LocalizationService,
    private $filter: ng.IFilterService,
    private $translate: ng.translate.ITranslateService,
    private $q: ng.IQService,
    private modalService: ModalService,
    private placesService,
    private StaticRequestsPaginate,
    private TARGETING_TYPES: typeof SF_TARGETING_TYPES,
    private usersService: UsersService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.targetingTypes = this.TARGETING_TYPES.filter(
      ({ hidden }) => !hidden
    ).filter(({ key }) => !this.hiddenTargeting.includes(key));

    this.selectedTargetingType = this.prevTargeting?.links[0]?.type || null;

    if (!this.selectedTargetingType && this.postId) {
      this.selectedTargetingType = 'all';
      this.onSelectValue(this.selectedTargetingType);
    }

    this.targeting_ids = this.selectedTargetingType
      ? this.prevTargeting?.links.map(({ _id }) => _id)
      : [];

    this.targetingTypes = this.targetingTypes.map((t) => ({
      ...t,
      label: this.$translate.instant(t.translationKey!),
    }));
  }

  dataProvider({ search }) {
    return new this.StaticRequestsPaginate(
      this.$filter('filter')(this.entities, search),
      this.GET_LIMIT
    );
  }

  onSelectValue(value: string): ng.IPromise<void> {
    this.entities = [];
    this.selected = this.cachedState[value];

    if (value === 'all') {
      this.onUpdated({
        targeting: {
          type: 'all',
          targeting_ids: [],
        },
      });
    }

    if (!value || value === 'all') {
      return this.$q.resolve();
    }
    this.onUpdated({ targeting: null });

    const getEntities = this.getEntitiesProvider(
      value === 'usersGroup' ? 'users' : 'places',
      this.isScoped
    );

    this.title =
      value === 'usersGroup'
        ? this.$translate.instant('TARGETING_MODAL_TITLE_USERS_GROUPS')
        : this.$translate.instant('TARGETING_MODAL_TITLE_PLACES_LISTS');

    this.entityGetNumberOfEntities = (entity) =>
      entity.contents.places_ids
        ? entity.contents.places_ids.length
        : entity.contents.users_ids.length;

    this.selectedTargetingIcon = this.targetingTypes.find(
      (t) => t.value === value
    )?.iconClass;

    return getEntities().then((data) => {
      this.entities = data.entries || [];
      this.selected = this.entities.filter(({ _id }) =>
        (this.cachedState[value]?.map((v) => v._id) || []).includes(_id)
      );
      this.entityTitleGet = (entry) => entry.contents.name;
      this.openSelectEntityModal();
    });
  }

  getEntitiesProvider(entityType: EntityType, scoped?: boolean) {
    if (entityType === 'users') {
      return scoped
        ? this.usersService.getScopedUsersGroups.bind(this.usersService)
        : this.usersService.getAllUsersGroups.bind(this.usersService);
    }

    return this.placesService.getAllPlacesLists;
  }

  getPlaceholder(targeting: TargetingTypeObject) {
    const typeKeyPart = targeting.key.toUpperCase().replace('-', '_');

    if (!this.selected?.length) {
      return this.$translate.instant(`SELECT_TARGETING_OPTIONS_${typeKeyPart}`);
    } else if (this.selected.length === 1) {
      return this.selected[0].contents.name;
    } else {
      return this.$translate.instant(`TARGETING_OPTIONS_${typeKeyPart}_COUNT`, {
        count: this.targeting_ids.length,
      });
    }
  }

  openSelectEntityModal(): void {
    const template = `
      <sf-entity-selector-modal
        empty-icon="no_users"
        search-placeholder="{{ 'TARGETING_MODAL_SEARCH_PLACEHOLDER' | translate }}"
        title="{{ $ctrl.title }}"
        multiple="true"
        selected="$ctrl.selected"
        entity-title-get="$ctrl.entityTitleGet"
        entity-description-get="$ctrl.entityDescriptionGet"
        entity-get-number-of-entities="$ctrl.entityGetNumberOfEntities"
        filters-available="$ctrl.filtersAvailable"
        on-selection-change="$ctrl.onSelectionChange(selection)"
        on-save="$ctrl.onSave()"
        on-query-change="$ctrl.onQueryChange(query)"
        on-close="$ctrl.onClose()"
        on-reload="$ctrl.onReload()"
        icon-class="{{ $ctrl.iconClass }}"
      ></sf-entity-selector-modal>
    `;

    let selectionResult = [...(this.selected || [])];

    const onMultipleSelectionChange = ({ entity, selected }) => {
      selectionResult = selected
        ? selectionResult.concat(entity)
        : selectionResult.filter((el) => el._id !== entity._id);
    };

    const modal = this.modalService.open(template, {
      entityTitleGet: this.entityTitleGet,
      entityDescriptionGet: this.entityDescriptionGet,
      entityGetNumberOfEntities: this.entityGetNumberOfEntities,
      title: this.title,
      selected: this.selected,
      iconClass: this.selectedTargetingIcon,
      filtersAvailable: {},
      onSelectionChange: onMultipleSelectionChange,
      onClose: () => onClose(),
      onSave: () => this.onChange({ result: selectionResult }),
      onQueryChange: (query) => this.dataProvider(query),
      onReload: () => null, // TODO: change to an actual function
    });

    // When modal is submitted it will emit value once here and once in onChange.
    // Requires modal form refactor - add onRemove observable.
    const onClose = () => {
      this.selected = [];
      this.modalService.removeModal(modal);
      this.onChange({ result: this.cachedState[this.selectedTargetingType] });
    };
  }

  onChange({ result }): void {
    this.selected = result;
    this.targeting_ids = result?.map(({ _id }) => _id);
    this.cachedState[this.selectedTargetingType] = result;
    this.targetingTypes = this.targetingTypes.map((t) => {
      if (
        this.targeting_ids?.length > 0 &&
        t.value === 'placesList' &&
        this.selectedTargetingType === 'placesList'
      ) {
        return {
          ...t,
          label: this.$translate.instant(
            'TARGETING_OPTIONS_PLACES_LISTS_COUNT',
            {
              count: this.targeting_ids.length,
            }
          ),
        };
      }

      if (
        this.targeting_ids?.length > 0 &&
        t.value === 'usersGroup' &&
        this.selectedTargetingType === 'usersGroup'
      ) {
        return {
          ...t,
          label: this.$translate.instant('TARGETING_OPTIONS_TEAMS_COUNT', {
            count: this.targeting_ids.length,
          }),
        };
      }

      return {
        ...t,
        label: this.$translate.instant(t.translationKey!),
      };
    });

    this.onUpdated({
      targeting: result
        ? {
            type: this.selectedTargetingType,
            targeting_ids: this.targeting_ids,
          }
        : null,
    });
  }
}
