import { Answer } from '@services/API/reports/reports';
import { PubSubService } from '@services/Utils/PubSub/pubsub.service';
import { RPNService } from '@services/Utils/Rpn/rpn.service';
import { isEmpty } from 'ramda';

export class QuestionFormulaController implements ng.IController {
  answers: Answer[];
  report;

  answersList;
  value;
  copyData = null;
  form;
  question;
  formula: string;
  firstField;
  hasUnit = false;

  sfQuestionForm;

  constructor(
    private pubSubService: PubSubService,
    private rpnService: RPNService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.firstField = this.question.fields[0];
    this.hasUnit = this.getFieldUnit();
    this.value = this.getAnswerValue();

    const _formula = this.normalizeFormula(this.question.metadata.formula);
    this.formula = this.rpnService.convertToRPN(_formula);

    this.pubSubService.subscribe('FORM_VALUE_UPDATE', () => {
      this.normalizeAnswers(this.report.contents.answers);

      const nFormula = this.replaceLettersWithValues(this.formula);
      const answer = this.rpnService.evaluateRPN(nFormula);

      this.value = answer.errorMessage || answer.value.toString();

      this.onUpdate(this.value);
    });
  }

  private replaceLettersWithValues(formula) {
    const _formula = formula.replace(/[A-Z]+/g, (letter) => {
      const questionId = this.question.metadata.linkedItems.find(
        (item) => item.identifier === letter
      ).question_id;

      const answer = this.answersList.find(
        (answer) => answer.question_id === questionId
      );
      return answer ? answer.value : letter;
    });

    return _formula;
  }

  private getFieldUnit() {
    return this.question.fields.filter(
      ({ type, set }) => type === 'string' && set
    )[0];
  }

  private normalizeAnswers(answersList) {
    this.answersList = answersList.map((item) => ({
      question_id: item.question_id,
      value: item.values[0].value,
    }));
  }

  private normalizeFormula(formula: string): string {
    const pattern = /(\d*\.\d+|[-+]?\d+|\(|\)|\+|-|\*|\/|\w+)/g;
    const tokens = formula.match(pattern);
    return tokens?.join(' ') || '';
  }

  private getAnswerValue() {
    return !isEmpty(this.answers) ? this.answers[0].values[0].value : null;
  }

  private onUpdate(value): void {
    if (this.copyData !== value) {
      this.answers = this.updateAnswers(value);
      this.copyData = value;
    }
  }

  private getValues(value) {
    const values = [{ field_id: this.firstField._id, value }];
    const fieldUnit = this.getFieldUnit();

    if (fieldUnit) {
      return values.concat({
        field_id: fieldUnit._id,
        value: fieldUnit.set[0],
      });
    }

    return values;
  }

  private updateAnswers(value) {
    const values = !isEmpty(value) ? this.getValues(value) : null;
    const answer = this.answers[0];

    if (values) {
      return this.sfQuestionForm.saveAnswer(values, this.answers);
    }

    return answer
      ? this.sfQuestionForm.removeAnswer(answer._id, this.answers)
      : this.answers;
  }
}
