export const DOCUMENT_UPLOAD_OPTIONS = {
  accept:
    'application/pdf,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation',
  types: '.pdf,.odt,.ods,.odp,.docx,.xlsx,.pptx', // doesn't work on mobile as accept attribute of input tag
  maxSize: '10MB',
  maxSizeInBytes: 10485760,
  maxFiles: 10,
} as const;

export const IMAGE_UPLOAD_OPTIONS = {
  accept: 'image/jpeg,image/png', // could be used in html5 input accept attribute and ngf-accept
  pattern: 'image/jpeg,image/png', // ngf-pattern specific to ng-file-upload-library
  multiple: true,
  maxSize: '8MB',
  maxSizeInBytes: 8388608,
  maxFiles: 10,
  maxWidth: 3000,
  maxHeight: 3000,
  minWidth: 100,
  minHeight: 100,
  quality: 0.9,
} as const;

export const PROFILE_PIC_OPTIONS = {
  ratio: 1,
  cropArea: 'circle',
  cropSize: 200,
} as const;

export const VIDEO_UPLOAD_OPTIONS = {
  duration: 300, //seconds
  // video/* could cause wrong selection in browser
  accept:
    'video/3gpp2,video/3gpp,video/x-flv,video/quicktime,video/x-msvideo,video/mp4,video/mpeg,video/ogg,video/H264,video/H265,video/webm',
  pattern: '.3g2,.3gp,.avi,.flv,.mov,.mkv,.mp4,.mpeg,.ogv,.webm,.wmv',
  maxSize: '40MB',
  maxFiles: 1,
  maxSizeInBytes: 41943040,
} as const;

export const FILES_UPLOAD_OPTIONS = {
  DOCUMENT: DOCUMENT_UPLOAD_OPTIONS,
  IMAGE: IMAGE_UPLOAD_OPTIONS,
  VIDEO: VIDEO_UPLOAD_OPTIONS,
  PROFILE_PIC: PROFILE_PIC_OPTIONS,
} as const;

export const FilesUploadOptions = angular
  .module('sfMobile.constants.files-upload-options', [])
  .constant('SF_FILES_UPLOAD_OPTIONS', FILES_UPLOAD_OPTIONS).name;
