import * as angular from 'angular';
import analyticsRouteConfig from './analytics.routes';
import { DashboardDetailsModalComponent } from './components/dashboard-details-modal/dashboard-details-modal.component';
import { ExternalDashboardComponent } from './components/external-dashboard/external-dashboard.component';
import { SalesDashboardFilterModalComponent } from './components/sales-dashboard-filter-modal/sales-dashboard-filter-modal.component';
import { SalesDashboardFilterComponent } from './components/sales-dashboard-filter/sales-dashboard-filter.component';
import { SalesDashboardComponent } from './components/sales-dashboard/sales-dashboard.component';
import { AnalyticsApiService } from './services/analytics-api/analytics-api.service';
import { AnalyticsService } from './services/analytics-service/analytics.service';
import { SalesDashboardFiltersService } from './services/sales-dashboard-filters-service/sales-dashboard-filters.service';

export default angular
  .module('sfMobile.analytics', ['pascalprecht.translate'])
  .component('sfDashboardDetailsModal', DashboardDetailsModalComponent)
  .component('sfExternalDashboard', ExternalDashboardComponent)
  .component('sfSalesDashboard', SalesDashboardComponent)
  .component('sfSalesDashboardFilter', SalesDashboardFilterComponent)
  .component('sfSalesDashboardFilterModal', SalesDashboardFilterModalComponent)
  .service('analyticsApiService', AnalyticsApiService)
  .service('analyticsService', AnalyticsService)
  .service('salesDashboardFiltersService', SalesDashboardFiltersService)
  .config(analyticsRouteConfig).name;
