export const ListImagesSkeletonComponent = {
  bindings: {
    nbImages: '<?',
  },
  templateUrl:
    'components/Skeletons/list-images-skeleton/list-images-skeleton.html',
  controller: class ListImagesSkeletonController {
    $onInit() {
      this.fakeImages = Array.apply(null, Array(this.nbImages || 1)).map(
        () => 'skeletoncard'
      );
    }
  },
};
