export const EmptyComponent = {
  bindings: {
    title: '<',
    desc: '<',
    buttonText: '<',
    onButtonClick: '&?',
    svgName: '@',
    iconSize: '@?',
  },
  templateUrl: 'components/Scenes/empty/empty.html',
  controller: class EmptyController {
    $onInit() {
      this.svgPath = `img/icons/empty/${this.svgName}.svg`;
    }
  },
};
