import { ResourceTargetSelectorController } from './resource-target-selector.controller';

export const ResourceTargetSelectorComponent = {
  bindings: {
    prevTargeting: '<',
    hiddenTargeting: '<?',
    onUpdated: '&',
    isScoped: '<',
    postId: '<?',
    reverseInputDisplay: '<',
  },
  templateUrl:
    'targeting/components/resource-target-selector/resource-target-selector.html',
  controller: ResourceTargetSelectorController,
};
