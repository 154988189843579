"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var schemaUtils = require('../utils/schema'); // In theory, we can add document to any resource in system (users, organisations, places)
// So far only relation to places added and supported


var baseDocumentSchema = {
  required: ['organisation_id', 'owner_id', 'file_id'],
  properties: {
    organisation_id: schemaUtils.oneToOne(),
    owner_id: schemaUtils.oneToOne(),
    file_id: schemaUtils.oneToOne()
  }
};
module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Document',
  type: 'object',
  anyOf: [{
    required: ['places_ids'],
    properties: {
      places_ids: schemaUtils.oneToSeveral({
        minItems: 1
      })
    }
  }].map(function (any) {
    return {
      additionalProperties: false,
      required: [].concat(_toConsumableArray(baseDocumentSchema.required), _toConsumableArray(any.required)),
      properties: _objectSpread({}, baseDocumentSchema.properties, {}, any.properties)
    };
  })
};