import { FilesService } from '@services/API/files/files.service';
import { NewsfeedService } from 'app/newsfeed/services/newsfeed-service/newsfeed.service';
import { Comment } from '../..';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { ObjectId, TSFixMe, User } from '../../../index';
import { ImageService } from '../../../services/Utils/Image/image.service';
import type { ImageSourceService } from '../../../services/Utils/ImageSource/image-source.service';
import type { ModalService } from '../../../services/Utils/Modal';

export type CommentImageResource = {
  id: string;
  url: string;
  bigUrl?: string;
};

export type CommentFileResource = {
  _id: ObjectId;
  name: string;
  type: string;
  size: number;
};

export type Action = {
  text: string;
  onClick: () => void;
};

export class CommentController implements ng.IComponentController {
  comment: Comment;
  user: User;
  content?: string;
  actions: Action[];
  image?: CommentImageResource | null;
  files;
  commentDate;
  validationState?: any;
  validationStatus?: any;

  // eslint-disable-next-line max-params
  constructor(
    private $q: ng.IQService,
    private dateFormatService,
    private imageService: ImageService,
    private imageSourceService: ImageSourceService,
    private modalService: ModalService,
    private filesService: FilesService,
    private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
    private newsfeedService: NewsfeedService
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    this.files = { documents: [], images: [] };
    this.commentDate = this.dateFormatService.getVerboseDateAndTimeFormatted(
      this.comment.created_date
    );
    this.content = this.content || this.comment.contents.content;

    this.getFiles().then((files) => this.sortFiles(files));

    return this.getPicture().then((image) => {
      this.image = image;
    });
  }

  sortFiles(files) {
    files?.map((file) => {
      const fileType = this.newsfeedService.getResourceTypeFromMimeType(
        file.type
      );
      if (fileType === 'image') {
        this.files.images.push(file);
      } else {
        this.files.documents.push(file);
      }
    });
  }

  $onChanges(changes: ng.IOnChangesObject): void {
    if (changes.comment) {
      this.content = this.comment.contents.content;
    }
  }

  getFullName() {
    const user = this.user.contents || this.user;

    return `${user.firstName} ${user.lastName}`;
  }

  getFiles(): ng.IPromise<TSFixMe> {
    const { files } = this.comment.contents;

    if (!files || !files.length) {
      return this.$q.resolve([]);
    }

    return this.$q.all(
      files?.map((file) => {
        const { _id, name, type, size } = file;
        const fileType = this.newsfeedService.getResourceTypeFromMimeType(type);

        if (fileType === 'image') {
          return this.getPicture(_id);
        }

        return this.filesService.getUrl(file._id).then((url) => ({
          id: _id,
          name,
          type,
          size,
          url,
        }));
      })
    );
  }

  getPicture(picId?: string): ng.IPromise<CommentImageResource | null> {
    let { picture_id } = this.comment.contents;
    const { SQUARE_LARGE, SQUARE_BIG } = this.SF_IMAGE_SIZES;

    if (picId) {
      picture_id = picId;
    }
    if (!picture_id) {
      return this.$q.resolve(null);
    }
    return this.imageService
      .getUrl(picture_id)
      .then((url) =>
        this.$q.all([
          this.imageService.getSizedUrl(url, SQUARE_LARGE),
          this.imageService.getSizedUrl(url, SQUARE_BIG),
        ])
      )
      .then(([url, bigUrl]) => ({
        id: picture_id || '',
        url,
        type: 'image',
        bigUrl,
      }));
  }

  viewCommentPicture(): void {
    if (!this.image || !this.image.bigUrl) {
      return;
    }

    const template = `
      <sf-images-viewer
        images="[$ctrl.image]"
        on-close="$ctrl.onClose()">
      </sf-images-viewer>
    `;
    const bindings = {
      image: this.imageSourceService.create({
        id: this.image.id,
        url: this.image.bigUrl,
      }),
    };

    this.modalService.open(template, bindings);
  }

  displayValidationState(): boolean {
    return this.validationState && this.validationStatus;
  }
}
