const BUTTON_STATE_CHANGE_DELAY = 2000;

export const ButtonLoaderDelayComponent = {
  bindings: {
    onClick: '&',
    onSuccess: '&',
    onError: '&',
    isDisabled: '<?',
    btnClass: '@?',
    btnType: '@?',
  },
  transclude: true,
  templateUrl:
    'components/Buttons/button-loader-delay/button-loader-delay.html',
  controller: function ButtonLoaderDelayController($timeout) {
    'ngInject';
    this.$onInit = () => {
      this.state = 'idle';
    };

    this.$onDestroy = () => {
      if (this.delayPromise) {
        $timeout.cancel(this.delayPromise);
      }
    };

    this.doAction = () => {
      this.state = 'loading';
      return this.onClick().then(
        (successData) => {
          this.state = 'success';
          this.delay(() => {
            this.onSuccess(successData);
            this.state = 'idle';
          });
        },
        (err) => {
          this.state = 'error';
          this.onError({ err: err });
          this.delay(() => {
            this.state = 'idle';
          });
        }
      );
    };

    this.delay = (fn) => {
      this.delayPromise = $timeout(fn, BUTTON_STATE_CHANGE_DELAY);
    };
  },
};
