import { FEATURE_FLAGS } from '@constants/feature-flags.constant';
import { Modal } from '@services/Utils/Modal';
import { SfFeatureFlags } from '@simplifield/feature-flags';
import { StateService } from '@uirouter/angularjs';
import { ObjectId } from '../../..';
import {
  EVENT_ITEM_THEMES,
  EventItemThemeEnum,
} from '../../../calendar-events/constants/event-item-themes.constant';
import {
  CalendarEvent,
  CorporateEvent,
  GenericEventModel,
  Timezone,
} from '../../../calendar-events/types';
import type { IAPIEventsList } from '../../../services/API/events/events';
import {
  PubSubService,
  UnregisterFn,
} from '../../../services/Utils/PubSub/pubsub.service';
import { SegmentService } from '../../../services/Utils/Segment/segment.service';
import { NextEventsService } from '../services/next-events.service';

export class NextEventsListController {
  type: 'homepage' | 'storepage';
  placeId?: ObjectId;
  timezone: Timezone;
  networkErrors: boolean;
  isLoading: boolean;
  isFullCalendar: boolean;
  nextEvents: GenericEventModel[] = [];
  nextEventsTheme: EventItemThemeEnum;
  dataListener: UnregisterFn;
  dataFailedListener: UnregisterFn;
  stateListner: UnregisterFn;
  /* @ngInject */
  constructor(
    private readonly nextEventsService: NextEventsService,
    private readonly preferencesService,
    private readonly $state: StateService,
    private readonly segmentService: SegmentService,
    private readonly pubSubService: PubSubService,
    private readonly SF_EVENT_ITEM_THEMES: typeof EVENT_ITEM_THEMES,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {}

  $onInit(): ng.IPromise<void> {
    this.isFullCalendar = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.FULL_CALENDAR
    );

    this.nextEventsTheme = this.SF_EVENT_ITEM_THEMES.NEXT_EVENT;
    this.dataListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
      () => this.getNextEvents()
    );
    this.dataFailedListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED_FAILED,
      () => this.getNextEvents()
    );
    this.stateListner = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.STATE_CHANGE_START,
      (params: { name: string }) => {
        if (params.name === 'index.activity.details') {
          this.getNextEvents();
        }
      }
    );
    return this.preferencesService.getTimezone().then((timezone) => {
      this.timezone = timezone;
      return this.getNextEvents();
    });
  }
  getNextEvents = (): ng.IPromise<void> => {
    this.networkErrors = false;
    this.isLoading = true;

    return this.nextEventsService
      .getNextEvents(this.type, this.placeId)
      .then((data: IAPIEventsList) => {
        this.nextEvents = data.entries;
      })
      .catch((err) => {
        this.networkErrors = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  isSectionEmpty(datas): boolean {
    return typeof datas !== 'undefined' && datas.length === 0;
  }
  getDefaultDateToDisplayInEventsCalendar(): Date {
    let defaultDateToDisplay = new Date();
    if (this.nextEvents.length > 0) {
      const firstNextEvent = this.nextEvents[0] as any;
      defaultDateToDisplay = new Date(firstNextEvent.contents.start_date);
    }
    return defaultDateToDisplay;
  }

  goToView(stateLink: string, viewType: string): void {
    this.$state.go(stateLink, {
      defaultDateToDisplay: this.getDefaultDateToDisplayInEventsCalendar(),
      placeId: this.placeId,
      nextEvents: this.nextEvents,
    });

    this.segmentService.track('ACTIVITY', {
      action: 'tap',
      label: `More link ${viewType}`,
    });
  }

  onEventClick(event: GenericEventModel): Modal {
    return event._type === 'corporate'
      ? this.nextEventsService.openCorporateEventDetails(
          event as CorporateEvent
        )
      : this.nextEventsService.openIndividualEventDetails(
          event as unknown as CalendarEvent,
          {
            onDelete: () => this.getNextEvents(),
            onEdit: () => this.getNextEvents(),
          }
        );
  }
}
