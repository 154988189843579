import { StateService, TransitionPromise } from '@uirouter/core';
import { User } from '../../../..';
import {
  Report,
  ReportCampaignContents,
} from '../../../../services/API/reports/reports';
import { Identified } from '../../../../services/Utils/CRUD/crud-service';
import { Campaign, Tag } from '../../../../services/API/campaigns/campaigns';

const NB_REPORT_TO_GET = 20;

class CampaignReportsValidationListController {
  /* props */
  profile: User;
  reloadFn: () => ng.IPromise<unknown>;
  state: string;
  contentKey: string;

  requestPaginateHash = {};
  requestsPaginate;
  reportsListElements;
  infiniteLoadError: boolean;
  loading: boolean;
  networkError: boolean;
  onScrollComplete?: () => void;
  campaigns: Record<string, Identified<Campaign>> = {};

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $q: ng.IQService,
    private $state: StateService,
    private reportsValidationAPIService,
    private helpersService,
    private RequestsPaginate
  ) {
    'ngInject';

    this.requestPaginateHash = {
      torevise: reportsValidationAPIService.getReportsToRevise,
      pending: reportsValidationAPIService.getPendingValidationReportsScope,
      validated: reportsValidationAPIService.getValidatedReportsScope,
    };
  }

  $onInit(): void {
    // Handle when the user edit a report to refresh the list
    this.$rootScope.$on('REPORT_FILLED_REDIRECT_BACK', () => {
      this.reload();
    });

    this.requestsPaginate = new this.RequestsPaginate(
      this.requestPaginateHash[this.state],
      {
        limit: NB_REPORT_TO_GET,
        relatedEntitiesKeys: ['campaigns', 'users', 'places'],
      }
    );
    this.reloadFn = this.reload.bind(this);
    this.reload();
    this.contentKey = `${this.state}Content`;
  }

  resetRequests(): void {
    this.infiniteLoadError = false;

    this.requestsPaginate.reset();
  }

  reload(): ng.IPromise<unknown> {
    this.loading = true;
    this.networkError = false;

    this.resetRequests();

    return this.getReports()
      .catch(() => {
        this.networkError = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  resetInfiniteLoadErrorState(): void {
    this.infiniteLoadError = false;
  }

  getReports(): ng.IPromise<void> {
    return (
      this.requestsPaginate ? this.requestsPaginate.call() : this.$q.reject()
    )
      .then((data) => {
        this.campaigns = {
          ...this.requestsPaginate.getRelatedEntities('campaigns'),
          ...this.campaigns,
        };
        return {
          entries: data.entities,
          ...data.relatedEntities,
        };
      })
      .then((apiData) =>
        this.reportsValidationAPIService.transformAPIData(apiData, true)
      )
      .then((reports) => {
        this.reportsListElements = this.helpersService.addDividerDays(
          reports,
          'saved_date',
          true
        );
      })
      .finally(() => {
        if (this.onScrollComplete) {
          this.onScrollComplete();
        }
      });
  }

  edit(report: { _id: string; contents: { campaign_id: string } }): void {
    if (this.loading) {
      return;
    }

    this.loading = true;

    this.$state
      .go('index.menu-more.reactive-campaigns.report', {
        campaignId: report.contents.campaign_id,
        reportId: report._id,
        referer: this.$state.current.name,
        online: true,
      })
      .then(() => (this.loading = false));
  }

  getPlace(report: Report) {
    if (!report.contents || !report.contents.place_id) {
      return null;
    }
    const places = this.requestsPaginate.getRelatedEntities('places');

    return places[report.contents.place_id];
  }

  getUser(report: Report, type: string): User | null {
    if (!report.contents || !report.contents.user_id) {
      return null;
    }
    const users = this.requestsPaginate.getRelatedEntities('users');

    return users[report.contents[`${type}_id`]];
  }

  getTagsMap(report: Report): Tag[] {
    const campaign =
      this.campaigns[(report.contents as ReportCampaignContents).campaign_id];

    return campaign?.contents?.tagsMap ?? [];
  }
}

const sfCampaignReportsValidationList = {
  bindings: {
    profile: '<',
    reloadFn: '=',
    state: '@',
    contentKey: '@',
  },
  controller: CampaignReportsValidationListController,
  templateUrl:
    'reports/' +
    'reports-validation/' +
    'components/' +
    'campaign-reports-validation-list/' +
    'campaign-reports-validation-list.html',
};

export {
  sfCampaignReportsValidationList,
  CampaignReportsValidationListController,
};
