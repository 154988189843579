import { TSFixMe } from '../..';
import type {
  Popup,
  PopupService,
} from '../../services/Utils/Popup/popup.service';

export class ChangeRequestPopupService {
  constructor(
    private $translate: ng.translate.ITranslateService,
    private popupService: PopupService,
    private popupRequestService: TSFixMe
  ) {
    'ngInject';
  }

  showPictureCommentSendingProgress(onCancel): TSFixMe {
    const popupConfig = {
      progress: {
        title: this.$translate.instant(
          'CHANGE_REQUEST_PICTURE_COMMENT_SENDING_TITLE'
        ),
        desc: this.$translate.instant(
          'CHANGE_REQUEST_PICTURE_COMMENT_SENDING_DESC'
        ),
      },
      success: {
        title: this.$translate.instant(
          'CHANGE_REQUEST_PICTURE_COMMENT_SUCCESS_TITLE'
        ),
        desc: this.$translate.instant(
          'CHANGE_REQUEST_PICTURE_COMMENT_SUCCESS_DESC'
        ),
        iconName: 'thumbsup',
      },
      error: {
        title: this.$translate.instant(
          'CHANGE_REQUEST_PICTURE_COMMENT_ERROR_TITLE'
        ),
        desc: this.$translate.instant(
          'CHANGE_REQUEST_PICTURE_COMMENT_ERROR_DESC'
        ),
      },
    };

    return this.popupRequestService.show(popupConfig, onCancel);
  }

  showDeleteConfirm(title: string, text: string): ng.IPromise<void> {
    return this.popupService.showConfirm({
      title: this.$translate.instant(title),
      iconName: 'item-danger',
      buttonText: this.$translate.instant(text),
    });
  }

  showError(title: string): Popup {
    return this.popupService.showError({
      title: this.$translate.instant(title),
    });
  }
}
