export class CalendarEventsRecurrenceService {
  /* eslint-disable max-params */
  constructor(crudFactory) {
    'ngInject';
    this.crud = crudFactory('calendar_events_recurrence', {
      path_name: 'calendar-events-recurrence',
    });
  }

  delete(...arg) {
    return this.crud.deleteRemote(...arg);
  }

  patch(...arg) {
    return this.crud.patchRemote(...arg);
  }
}
