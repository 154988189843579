"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _ = require("./");

var _default = {
  type: 'object',
  required: ['_id', 'label', 'reviewers_ids'],
  properties: {
    _id: _.objectId,
    label: {
      type: 'string'
    },
    reviewers_ids: _.objectIds
  }
};
exports["default"] = _default;