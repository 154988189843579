import CustomParamsSelectorModalModule from './components/custom-params-selector-modal/custom-params-selector-modal.module';
import { CustomParamsSelectorComponent } from './custom-params-selector.component';

export default angular
  .module('sfMobile.components.custom-params-selector', [
    'sfMobile.services.requests-paginate',
    'sfMobile.services.static-requests-paginate',
    'sfMobile.components.button-header-close',
    'sfMobile.services.searchUtils',
    'sfMobile.components.custom-params-selector-modal',
    CustomParamsSelectorModalModule,
  ])
  .component('sfCustomParamsSelector', CustomParamsSelectorComponent).name;
