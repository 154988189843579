export const ModalSlideUpComponent = {
  bindings: {},
  transclude: true,
  templateUrl: 'components/Modals/modal-slide-up/modal-slide-up.html',
  controller: class ModalSlideUpController {
    constructor($element, $document, $timeout) {
      'ngInject';
      Object.assign(this, {
        $element,
        $document,
        $timeout,
      });
      this.MODAL_BOTTOM_MAX_HEIGHT_PERCENT = 90;
    }
    $onInit() {
      this.modalStyle = {};

      this.$timeout(() => {
        this.modalStyle = this.getModalStyle();
      }, 0);
    }

    getModalStyle() {
      const { document, content } = this.getDomElements();
      const heightValue = this.getHeightValue(document, content);

      return {
        height: `${heightValue}%`,
      };
    }

    getDomElements() {
      const document = this.$document[0].documentElement;
      const content = this.$element[0].querySelector(
        '.sf_modal_slide_up__container'
      );

      return { document, content };
    }

    getHeightValue(document, content) {
      const { clientHeight: documentHeight } = document;
      const { clientHeight: contentHeight } = content;
      const heightValue = (contentHeight * 100) / documentHeight;

      return this.MODAL_BOTTOM_MAX_HEIGHT_PERCENT < heightValue
        ? this.MODAL_BOTTOM_MAX_HEIGHT_PERCENT
        : heightValue;
    }
  },
};
