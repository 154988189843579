"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint max-nested-callbacks:[1], complexity:[0], max-nested-callbacks:[0], max-lines:[0] */
var YError = require('yerror');

var reportsLibrary = require('../reports/report.lib'); // var isEmail = require('isemail');


var isURI = require('isuri'); // var phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();


var clone = require('clone-deep');

var errorsLibrary = require('../utils/errors');

var syntaxUtils = require('../utils/syntax');

var formulasUtils = require('../utils/formulas');

var utilsLibrary = require('../utils/utils');

var createObjectId = require('../utils/createObjectId');

var FORMULA_FIELD_TYPES = ['number', 'string', 'date', 'boolean'];

var hasQuestionType = function hasQuestionType(type) {
  return function (form) {
    return form.questions.some(function (q) {
      return (q.fields || []).some(function (f) {
        return f.type === type;
      });
    });
  };
};

var hasQuestionEan = hasQuestionType('ean');

var getMediaQuestions = function getMediaQuestions(form) {
  return form.questions.filter(function (question) {
    return ['image', 'video', 'audio', 'signature'].includes(question.type);
  });
};

var formsLibrary = {
  defaults: formsLibraryDefaults,
  validate: formsLibraryValidate,
  validateForm: formsLibraryValidateForm,
  validateTree: formsLibraryValidateTree,
  validateTreeSection: formsLibraryValidateTreeSection,
  validateCustomConditions: formsLibraryValidateCustomConditions,
  reorderReportSectionsIds: formsLibraryReorderReportSectionsIds,
  reorderReportNodesIds: formsLibraryReorderReportNodesIds,
  guessReportSectionsIds: formsLibraryGuessReportSectionsIds,
  guessReportNodesIds: formsLibraryGuessReportNodesIds,
  repairReportAnswers: formsLibraryRepairReportAnswers,
  createMissingNodes: formsLibraryCreateMissingNodes,
  validateReport: formsLibraryValidateReport,
  getSectionQuestions: formsLibraryGetSectionQuestions,
  getMediaQuestions: getMediaQuestions,
  computeFormula: formulasUtils.computeFormula,
  checkValueAgainstField: formsLibraryCheckValueAgainstField,
  checkDateAgainstField: formsLibraryCheckDateAgainstField,
  checkBooleanAgainstField: formsLibraryCheckBooleanAgainstField,
  checkUUIDAgainstField: formsLibraryCheckUUIDAgainstField,
  checkNumberAgainstField: formsLibraryCheckNumberAgainstField,
  checkStringAgainstField: formsLibraryCheckStringAgainstField,
  hasQuestionEan: hasQuestionEan,
  hasScore: formsLibraryHasScore,
  formToTree: formsLibraryFormToTree,
  treeToForm: formsLibraryTreeToForm,
  // formToTree is used in many places and returns sections tree without questions.
  // changes may break validation logic. Also some pending PRs use this method. It will be hard to resolve all conflicts
  // currently formToOrderedTree returns ordered form tree with full structure (sections & questions)
  // In the future we had better to remove old method and use a new one
  formToOrderedTree: formsLibraryFormToOrderedTree
}; // One liner compilation to avoid lib functions scope pollution

/* eslint-disable global-require */

formsLibrary.validateSchema = new (require('ajv'))().compile(require('./form.schema'));
/* eslint-enable */

module.exports = formsLibrary;

function formsLibraryDefaults() {
  var form = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultForm = {
    description: '',
    sections: [],
    questions: [],
    statusMap: [{
      key: 'empty',
      label: 'STATUS_REPORT_EMPTY'
    }, {
      key: 'done',
      label: 'STATUS_REPORT_DONE'
    }],
    tags: [],
    users_ids: [],
    places_ids: [],
    subscribers_ids: [],
    analysts_ids: []
  };
  return _objectSpread({}, defaultForm, {}, form);
}

function formsLibraryValidate(form) {
  return errorsLibrary.mapAjvErrors(formsLibrary.validateSchema(form) ? [] : formsLibrary.validateSchema.errors);
}

function formsLibraryValidateForm(form) {
  var errors = formsLibrary.validateTree(formsLibrary.formToTree(form));

  if (!utilsLibrary.idsAreUnique(form.sections)) {
    errors.push(new YError('E_SECTION_ID_NOT_UNIQUE'));
  }

  if (!utilsLibrary.idsAreUnique(form.questions)) {
    errors.push(new YError('E_QUESTION_ID_NOT_UNIQUE'));
  } // eslint-disable-next-line max-len


  if (!utilsLibrary.idsAreUnique(form.questions.reduce(function (fields, question) {
    return fields.concat(question.fields);
  }, []))) {
    errors.push(new YError('E_FIELD_ID_NOT_UNIQUE'));
  }

  form.questions.forEach(function (question) {
    question.fields.forEach(function (field) {
      if (field["default"] && field.exclusiveSet && field.set && -1 === field.set.indexOf(field["default"])) {
        errors.push(new YError('E_FIELD_DEFAULT_NOT_IN_SET', field._id));
      }

      if (field.set && field.set.some(function (value) {
        return _typeof(field.set[0]) !== _typeof(value);
      })) {
        errors.push(new YError('E_FIELD_MIXED_SET_TYPES', field._id));
      }
    });
  });
  return errors;
}

function formsLibraryValidateTree(tree) {
  return tree.sections.reduce(function (theErrors, section) {
    return theErrors.concat(formsLibrary.validateTreeSection(tree, section, []));
  }, []);
}

function formsLibraryValidateTreeSection(tree, section, questionsStack) {
  var errors = [];
  var sectionQuestions = formsLibrary.getSectionQuestions(tree, section._id); // Checking minimum/maximum formulas

  if ('string' === typeof section.minimum) {
    errors = errors.concat(formsLibraryCheckFormula('number', questionsStack, section.minimum));
  }

  if ('string' === typeof section.maximum) {
    errors = errors.concat(formsLibraryCheckFormula('number', questionsStack, section.maximum));
  }

  errors = sectionQuestions // eslint-disable-next-line max-len
  .reduce(function (errorsAccumulator, question, questionIndex) {
    return errorsAccumulator.concat(formsLibraryValidateQuestion(questionsStack.concat(sectionQuestions.slice(0, questionIndex)), question));
  }, errors);

  if (section.sections && section.sections.length) {
    // eslint-disable-next-line max-len
    errors = errors.concat(section.sections.reduce(function (theErrors, theSection) {
      return theErrors.concat(formsLibrary.validateTreeSection(tree, theSection, questionsStack.concat(sectionQuestions)));
    }, []));
  } else if (!sectionQuestions.length) {
    errors.push(new YError('E_EMPTY_SECTION', section._id));
  }

  return errors;
}

function formsLibraryValidateQuestion(questionsStack, question) {
  var errors = []; // Checking minimum/maximum formulas

  if ('string' === typeof question.minimum) {
    errors = errors.concat(formsLibraryCheckFormula('number', questionsStack, question.minimum));
  }

  if ('string' === typeof question.maximum) {
    errors = errors.concat(formsLibraryCheckFormula('number', questionsStack, question.maximum));
  } // Checking fields


  errors = question.fields.reduce(function (errorsAccumulator, field) {
    // A field with no formula nor default value must be editable
    if (!(field["default"] || field.formula)) {
      if ('undefined' !== typeof field.editable && !field.editable) {
        errorsAccumulator = errorsAccumulator.concat(new YError('E_UNFILLABLE_FIELD', field._id, field["default"], field.formula, field.editable));
      }
    } // A field that is not displayable is not editable


    if (false === field.displayable && ('undefined' !== typeof field.editable || !field.editable)) {
      errorsAccumulator = errorsAccumulator.concat(new YError('E_BAD_FIELD_PROPERTIES', field._id, field.editable, field.displayable));
    } // Formulas


    if (!field.formula) {
      return errorsAccumulator;
    }

    if (-1 === FORMULA_FIELD_TYPES.indexOf(field.type)) {
      // eslint-disable-next-line max-len
      return errorsAccumulator.concat(new YError('E_BAD_FORMULA_TYPE', field._id, field.type, field.formula));
    }

    errorsAccumulator = errorsAccumulator.concat(formsLibraryCheckFormula(field.type, questionsStack, field.formula));
    return errorsAccumulator;
  }, errors);
  return errors;
}

function formsLibraryCheckFormula(type, questionsStack, formula) {
  return syntaxUtils["".concat(type, "FormulaCheck")](formula, questionsStack);
} // Currently, the mobile app can send unordored sections ids so we reorder it
// This function should be reused to check the sections ids order once the
// mobile will be fixed


function formsLibraryReorderReportSectionsIds(form, report) {
  var descendingSectionsIds = form.sections.reduce(function (sectionsIds, section) {
    sectionsIds.unshift(section._id);
    return sectionsIds;
  }, []);
  report.nodes = report.nodes.map(function (node) {
    if (1 < node.sections_ids.length) {
      // eslint-disable-next-line max-len
      node.sections_ids = descendingSectionsIds.filter(function (sectionId) {
        return node.sections_ids.some(function (aSectionId) {
          return sectionId.toString() === aSectionId.toString();
        });
      });
    }

    return node;
  });
  return report;
} // Currently, the mobile app send partial sections ids so we need to guess it


function formsLibraryGuessReportSectionsIds(form, report) {
  report.nodes = report.nodes.map(function (node) {
    // If we have more than 1 section id, it's probably a well formed node
    if (1 !== node.sections_ids.length) {
      return node;
    } // eslint-disable-next-line max-len


    var section = form.sections.filter(function (aSection) {
      return aSection._id.toString() === node.sections_ids[0];
    })[0];
    node.sections_ids = node.sections_ids.concat(_getParentSectionsId(form, section._id));
    return node; // eslint-disable-next-line no-shadow

    function _getParentSectionsId(form, sectionId) {
      var descendingSections = form.sections.reduce( // eslint-disable-next-line no-shadow
      function (descendingSections, section) {
        descendingSections.unshift(section);
        return descendingSections;
      }, []);
      var parentsSectionsIds = [];
      var level = -1;
      descendingSections.forEach(function (aSection) {
        if (-1 === level) {
          if (aSection._id.toString() === sectionId.toString()) {
            level = aSection.level;
          }
        } else if (aSection.level < level) {
          parentsSectionsIds.push(aSection._id);
          level = aSection.level;
        }
      });
      return parentsSectionsIds;
    }
  });
  return report;
} // Currently, the mobile app send partial sections ids so we need to guess it


function formsLibraryGuessReportNodesIds(form, report) {
  report.nodes = report.nodes.map(function (node) {
    // Remove the first section id since it is the current node one and we won't
    // need it to reorder parent nodes ids
    var descendingSectionsIds = node.sections_ids.slice(1); // If we have more than 1 node id, it's probably a well formed node

    if (1 !== node.parents_ids.length) {
      return node;
    }

    node.parents_ids = descendingSectionsIds.map(function (sectionId) {
      return report.nodes.filter(function (item) {
        return item.sections_ids[0].toString() === sectionId.toString();
      })[0]._id;
    });
    return node;
  });
  return report;
} // Currently, the mobile app send partial bad answer so we need to repair it


function formsLibraryRepairReportAnswers(form, report) {
  report.answers = report.answers.map(function (answer) {
    var node = report.nodes.filter(function (item) {
      return item._id.toString() === answer.nodes_ids[0].toString();
    })[0];
    answer.sections_ids = node.sections_ids;
    answer.nodes_ids = [node._id].concat(node.parents_ids);
    return answer;
  });
  return report;
} // Currently, the mobile app can send unordored nodes ids so we reorder it
// This function should be reused to check the nodes ids order once the
// mobile will be fixed


function formsLibraryReorderReportNodesIds(form, report) {
  report.nodes = report.nodes.map(function (node) {
    // Remove the first section id since it is the current node one and we won't
    // need it to reorder parent nodes ids
    var descendingSectionsIds = node.sections_ids.slice(1);

    if (1 < node.parents_ids.length) {
      // eslint-disable-next-line max-len
      node.parents_ids = descendingSectionsIds.map(function (sectionId) {
        return node.parents_ids.filter(function (nodeId) {
          // eslint-disable-next-line no-shadow
          var node = report.nodes.filter(function (item) {
            return item._id.toString() === nodeId.toString();
          })[0];
          return node.sections_ids[0].toString() === sectionId.toString();
        })[0];
      });
    }

    return node;
  });
  return report;
} // Currently in some weird cases, the mobile do create some nodes
// This function fixes that by reorganizing nodes and creating missing ones


function formsLibraryCreateMissingNodes(form, report) {
  var sectionsWithoutNodes = form.sections.filter(function (section) {
    return !report.nodes.some(function (node) {
      return node.sections_ids[0].toString() === section._id.toString();
    });
  });
  var newNodes = sectionsWithoutNodes.map(function (section) {
    return {
      _id: "".concat(createObjectId()),
      sections_ids: [section._id.toString()],
      parents_ids: []
    };
  });
  report.nodes = newNodes.concat(report.nodes);
  return report;
}

function formsLibraryValidateReport(form, context) {
  var errors = [];
  var formTree = formsLibrary.formToTree(form);

  if (!utilsLibrary.idsAreUnique(context.report.nodes)) {
    errors.push(new YError('E_NODE_ID_NOT_UNIQUE'));
  }

  if (!utilsLibrary.idsAreUnique(context.report.answers)) {
    errors.push(new YError('E_ANSWER_ID_NOT_UNIQUE'));
  }

  return formsLibraryValidateReportSections(form, context, formTree.sections, []);
}

function formsLibraryValidateReportSections(form, context, sections, answersStack) {
  var errors = [];
  sections.forEach(function (section) {
    var questions = formsLibrary.getSectionQuestions(form, section._id);
    var nodes = reportsLibrary.getSectionNodes(context.report, section._id);
    var sectionMinimum;
    var sectionMaximum;

    if ('string' === typeof section.minimum) {
      sectionMinimum = formulasUtils.computeFormula('number', section.minimum, {
        form: form,
        time: Date.now.bind(Date)
      }, _objectSpread({
        answers: answersStack
      }, context));
    } else {
      sectionMinimum = section.minimum;
    }

    if ('string' === typeof section.maximum) {
      sectionMaximum = formulasUtils.computeFormula('number', section.maximum, {
        form: form,
        time: Date.now.bind(Date)
      }, _objectSpread({
        answers: answersStack
      }, context));
    } else {
      sectionMaximum = section.maximum;
    }

    sectionMinimum = sectionMinimum > sectionMaximum ? sectionMaximum : sectionMinimum; // Check section multiplicity

    if (nodes.length < sectionMinimum) {
      errors.push(new YError('E_NODE_MINIMUM', section._id, nodes.length, sectionMinimum));
    }

    if (nodes.length > sectionMaximum) {
      errors.push(new YError('E_NODE_MAXIMUM', section._id, nodes.length, sectionMaximum));
    } // Check nodes


    nodes.forEach(function (node) {
      var nodeAnswers = reportsLibrary.getNodeAnswers(context.report, node._id);
      var nodeAnswersStack = answersStack.slice(0);

      if (section.sections) {
        formsLibraryValidateReportSections(form, context, section.sections, nodeAnswersStack.concat(nodeAnswers));
      }

      questions.forEach(function (question) {
        var questionAnswers = nodeAnswers.filter(function (answer) {
          return answer.question_id === question._id;
        });
        var questionMinimum;
        var questionMaximum;

        if ('string' === typeof question.minimum) {
          questionMinimum = formulasUtils.computeFormula('number', question.minimum, {
            form: form,
            time: Date.now.bind(Date)
          }, _objectSpread({
            answers: nodeAnswersStack
          }, context));
        } else {
          questionMinimum = question.minimum;
        }

        if ('string' === typeof question.maximum) {
          questionMaximum = formulasUtils.computeFormula('number', question.maximum, {
            form: form,
            time: Date.now.bind(Date)
          }, _objectSpread({
            answers: nodeAnswersStack
          }, context));
        } else {
          questionMaximum = question.maximum;
        }

        questionMinimum = questionMinimum > questionMaximum ? questionMaximum : questionMinimum;

        if (questionAnswers.length < questionMinimum) {
          errors.push(new YError('E_QUESTION_MINIMUM', question._id, questionAnswers.length, question.minimum, questionMinimum));
        }

        if (questionAnswers.length > questionMaximum) {
          errors.push(new YError('E_QUESTION_MAXIMUM', question._id, questionAnswers.length, question.maximum, questionMaximum));
        } // Check questions


        questionAnswers.reduce(function (hashMap, answer) {
          var hash;
          question.fields.forEach(function (field, fieldIndex) {
            var value;

            if (field.formula && (!question.editable || !answer.value)) {
              value = formulasUtils.computeFormula('number', field.formula, {
                form: form,
                time: Date.now.bind(Date)
              }, _objectSpread({
                answers: nodeAnswersStack
              }, context));
              answer.values[fieldIndex].value = value;
            }
          });

          if (question.unique) {
            hash = reportsLibrary.getAnswerHash(answer);

            if (hashMap[hash]) {
              errors.push(new YError('E_NOT_UNIQUE', answer._id, hashMap[hash]));
            }

            hashMap[hash] = answer._id;
          }

          if (question.fields.length !== answer.values.length) {
            errors.push(new YError('E_BAD_VALUE_COUNT', answer._id, question.fields.length, answer.values.length));
            return hashMap;
          }

          question.fields.forEach(function (field, i) {
            if (field._id !== answer.values[i].field_id) {
              errors = errors.concat(new YError('E_BAD_FIELD_ID', answer._id, i, field._id, answer.values[i].field_id));
              return;
            }

            errors = errors.concat(formsLibrary.checkValueAgainstField(field, answer.values[i].value));
          });
          return hashMap;
        }, {});
        nodeAnswersStack = nodeAnswersStack.concat(questionAnswers);
      });
    });
  });
  return errors;
}

function formsLibraryGetSectionQuestions(form, sectionId) {
  return form.questions.filter(function (question) {
    return question.section_id === sectionId;
  });
} // TODO: SSOT issue with form schema


function formsLibraryCheckValueAgainstField(field, value) {
  if (-1 !== ['duration', 'time', 'number', 'score'].indexOf(field.type)) {
    return formsLibrary.checkNumberAgainstField(field, value);
  } else if (-1 !== ['string', 'ean'].indexOf(field.type)) {
    return formsLibrary.checkStringAgainstField(field, value);
  } else if ('date' === field.type) {
    return formsLibrary.checkDateAgainstField(field, value);
  } else if ('boolean' === field.type) {
    return formsLibrary.checkBooleanAgainstField(field, value);
  } else if ('uuid' === field.type) {
    return formsLibrary.checkUUIDAgainstField(field, value);
  }

  return [new YError('E_UNKOWN_FIELD_TYPE', field.type)];
}

function formsLibraryCheckDateAgainstField(field, value) {
  var errors = [];

  try {
    if ('string' !== typeof value || new Date(value).toISOString() !== value) {
      errors.push(new YError('E_NOT_AN_ISO_DATE', _typeof(value), value));
    }
  } catch (err) {
    errors.push(YError.wrap(err, 'E_NOT_AN_ISO_DATE', _typeof(value), value));
  }

  if (field["enum"] && field["enum"].length && -1 === field["enum"].indexOf(value)) {
    errors.push(new YError('E_NOT_IN_ENUM', value, field["enum"]));
  }

  if ('string' === typeof field.minimum && new Date(value).getTime() < new Date(field.minimum).getTime() + (field.exclusiveMinimum ? 1 : 0)) {
    errors.push(new YError('E_FIELD_MINIMUM', value, field.minimum, field.exclusiveMinimum ? 'exclusive' : 'inclusive'));
  }

  if ('string' === typeof field.maximum && new Date(value).getTime() > new Date(field.maximum).getTime() - (field.exclusiveMaximum ? 1 : 0)) {
    errors.push(new YError('E_FIELD_MAXIMUM', value, field.maximum, field.exclusiveMaximum ? 'exclusive' : 'inclusive'));
  }

  return errors;
}

function formsLibraryCheckBooleanAgainstField(field, value) {
  var errors = [];

  if ('number' !== typeof value || -1 === [0, 1].indexOf(value)) {
    errors.push(new YError('E_NOT_A_BOOLEAN', _typeof(value), value));
  }

  return errors;
}

function formsLibraryCheckUUIDAgainstField(field, value) {
  var errors = [];

  if ('string' !== typeof value || !/^([a-f0-9]{24})$/.test(value)) {
    errors.push(new YError('E_NOT_A_UUID', _typeof(value), value));
  }

  return errors;
}

function formsLibraryCheckNumberAgainstField(field, value) {
  var errors = [];
  var isScoreField = 'score' === field.type;

  if (!isScoreField && 'number' !== typeof value) {
    errors.push(new YError('E_NOT_A_NUMBER', _typeof(value), value));
  }

  if (isScoreField && 'number' !== typeof value && null !== value && 'undefined' !== typeof value) {
    errors.push(new YError('E_NOT_A_SCORE', _typeof(value), value));
  }

  if (field["enum"] && field["enum"].length && -1 === field["enum"].indexOf(value)) {
    errors.push(new YError('E_NOT_IN_ENUM', value, field["enum"]));
  }

  if (field.multipleOf && 0 !== value % field.multipleOf) {
    errors.push(new YError('E_NOT_MULTIPLE_OF', value, field.multipleOf));
  }

  errors = errors.concat(_checkFieldBounds(field, value));
  return errors;
}

function formsLibraryCheckStringAgainstField(field, value) {
  var errors = [];

  if ('string' !== typeof value) {
    errors.push(new YError('E_NOT_A_STRING', _typeof(value), value));
  }

  if (field["enum"] && field["enum"].length && -1 === field["enum"].indexOf(value)) {
    errors.push(new YError('E_NOT_IN_ENUM', value, field["enum"]));
  }

  errors = errors.concat(_checkFieldBounds(field, value.length));

  if ('string' === typeof field.format) {
    if ('inline' === field.format && (-1 !== value.indexOf('\n') || -1 !== value.indexOf('\r'))) {
      errors.push(new YError('E_NOT_INLINE', value, value.indexOf('\n'), value.indexOf('\r')));
    } else if ('phone' === field.format) {
      /* LEGACY mobile
      Temporarily disabled awaiting mobile app to handle this
      try {
        if(!phoneUtil.parse(value)) {
          errors.push(new YError(
            'E_NOT_A_PHONE_NUMBER',
            value
          ));
        }
      } catch(err) {
        errors.push(new YError(
          'E_NOT_A_PHONE_NUMBER',
          value, err.message
        ));
      }*/

      /*
      } else if('email' === field.format && !isEmail.validate(value)) {
      errors.push(new YError(
        'E_NOT_AN_EMAIL',
        value
      ));
      */
    } else if ('uri' === field.format && !isURI.test(value)) {
      errors.push(new YError('E_NOT_AN_URI', value));
    } else if ('regexp' === field.format && // eslint-disable-next-line security/detect-non-literal-regexp
    !new RegExp(field.pattern).test(value)) {
      errors.push(new YError('E_NO_PATTERN_MATCH', value, field.pattern));
    }
  }

  return errors;
}

function formsLibraryHasScore(form) {
  var questions = form.questions;
  var hasScore = questions.some(hasScoringField);
  return hasScore;
}

function hasScoringField(question) {
  var fields = question.fields || [];
  return fields.some(function (field) {
    return 'score' === field.type && field.scoreMap;
  });
}

function formsLibraryFormToTree(form) {
  var tree = clone(form);
  var stack = [tree];
  var sections = tree.sections;
  tree.sections = [];
  sections.forEach(function (section, i) {
    // Upper level
    if (stack.length < section.level) {
      if (1 < section.level - stack.length) {
        throw new YError('E_BAD_LEVEL', i, section.level, stack.length);
      }

      stack[stack.length - 1].sections = (stack[stack.length - 1].sections || []).concat(section);
      stack.push(section); // Same or lower level
    } else {
      // Remove sections from stack if necessary
      if (stack.length > section.level + 1) {
        stack.splice(section.level + 1);
      } // Add a sections property to the current level


      if (!stack[section.level].sections) {
        stack[section.level].sections = [];
      }

      stack[section.level].sections.push(section);
      stack[section.level + 1] = section;
    }
  });
  return tree;
}

function formsLibraryTreeToForm(tree) {
  var form = clone(tree);
  form.sections = _linearizeSections(form.sections, 0);
  return form;
}

function formsLibraryValidateCustomConditions(form) {
  var formTree = formsLibrary.formToTree(form);

  if (0 === formTree.sections.length) {
    return [];
  }

  var validSection = formTree.sections.find(function (section) {
    return _hasSectionQuestionWithoutCustomCondition(formTree, section);
  });
  return validSection ? [] : [new YError('E_CONFUSING_BRANCHING')];
}

function _hasSectionQuestionWithoutCustomCondition(form, section) {
  var isGpsQuestion = function isGpsQuestion(item) {
    return 'gps' === item.type;
  };

  var hasItemCustomCondition = function hasItemCustomCondition(item) {
    return item.metadata && item.metadata.conditionMode && 'regular' !== item.metadata.conditionMode;
  };

  if (hasItemCustomCondition(section)) {
    return false;
  }

  var questions = formsLibrary.getSectionQuestions(form, section._id);

  if (Array.isArray(questions) && 0 < questions.length) {
    var validQuestion = questions.find(function (question) {
      return !isGpsQuestion(question) && !hasItemCustomCondition(question);
    });

    if (validQuestion) {
      return true;
    }
  }

  if (Array.isArray(section.sections) && 0 < section.sections.length) {
    var validSubsection = section.sections.find(function (subsection) {
      return _hasSectionQuestionWithoutCustomCondition(form, subsection);
    });

    if (validSubsection) {
      return true;
    }
  }

  return false;
}

function buildOrderedTree(form, section) {
  var items = (section.itemsOrder || []).map(function (item) {
    if (item.type === 'section') {
      var subSection = form.sections.find(function (s) {
        return s._id.toString() === item._id.toString();
      });
      return subSection ? buildOrderedTree(form, subSection) : subSection;
    }

    return form.questions.find(function (q) {
      return q._id === item._id;
    });
  }).filter(Boolean);
  return _objectSpread({}, section, {
    items: items
  });
} // it is used for legacy forms without itesmOrder property


function buildNotOrderedTree(form) {
  return form.sections.reduce(function (output, section) {
    var items = form.questions.filter(function (question) {
      return question.section_id === section._id;
    });

    var newSection = _objectSpread({}, section, {
      items: items
    });

    var pushTo = output;

    for (var i = 0; i < section.level; i++) {
      pushTo = pushTo[pushTo.length - 1].items;
    }

    pushTo.push(newSection);
    return output;
  }, []);
}

function formsLibraryFormToOrderedTree(form) {
  var tree = clone(form);
  var treeSections = tree.sections;
  var currTopLevelSection; // build sections tree in order to keep backward compatibility

  tree.sections = treeSections.reduce(function (acc, formSection) {
    if (0 === formSection.level) {
      currTopLevelSection = formSection;
    } else {
      currTopLevelSection.sections = currTopLevelSection.sections || [];
      currTopLevelSection.sections.push(formSection);
    }

    acc.push(formSection);
    return acc;
  }, []);

  if (tree.sections.length && tree.sections[0].itemsOrder) {
    tree.sections = tree.sections.filter(function (s) {
      return 0 === s.level;
    }).map(buildOrderedTree.bind(null, tree));
  } else {
    tree.sections = buildNotOrderedTree(tree);
  }

  return tree;
}

function _linearizeSections(sections, level) {
  return sections.reduce(function (finalSections, curSection) {
    curSection.level = level;

    if ('items' in curSection) {
      delete curSection.items;
    }

    finalSections.push(curSection);

    if (curSection.sections) {
      finalSections = finalSections.concat(_linearizeSections(curSection.sections, level + 1));
      delete curSection.sections;
    }

    return finalSections;
  }, []);
}

function _checkFieldBounds(field, value) {
  var errors = [];

  if ('number' === typeof field.minimum && value < field.minimum + (field.exclusiveMinimum ? 1 : 0)) {
    errors.push(new YError('E_FIELD_MINIMUM', value, field.minimum, field.exclusiveMinimum ? 'exclusive' : 'inclusive'));
  }

  if ('number' === typeof field.maximum && value > field.maximum - (field.exclusiveMaximum ? 1 : 0)) {
    errors.push(new YError('E_FIELD_MAXIMUM', value, field.maximum, field.exclusiveMaximum ? 'exclusive' : 'inclusive'));
  }

  return errors;
}