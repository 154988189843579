import { formatDashboardList, uuidv4 } from '../helpers/utils';
export const getAccessibleDashboards = (dashboardComponent, iframe) => {
    if ((dashboardComponent.dashboardId || dashboardComponent.dashboardSlug)
        && iframe?.contentWindow) {
        const requestId = uuidv4();
        iframe.contentWindow.postMessage({
            action: 'getAccessibleDashboards',
            _version: dashboardComponent.VERSION,
            requestId
        }, '*');
        return new Promise((resolve, reject) => {
            dashboardComponent.promises[requestId] = { resolve, reject };
            // Check if promise has been resolved after 2 seconds
            setTimeout(() => {
                if (dashboardComponent.promises[requestId]) {
                    const errorMessage = 'getAccessibleDashboards request timed out.';
                    dashboardComponent.promises[requestId].reject(new Error(errorMessage));
                    delete dashboardComponent.promises[requestId];
                }
            }, 2000);
        });
    }
    const fetchUser = new Promise((resolve, reject) => {
        void (() => {
            try {
                const responsePromise = window.fetch(`${String(dashboardComponent.params.apiHost)}0.1.0/authorization`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: { 'Content-Type': 'application/json' },
                    redirect: 'follow',
                    body: JSON.stringify({
                        action: 'get',
                        version: '0.1.0',
                        key: dashboardComponent.authKey,
                        token: dashboardComponent.authToken,
                        find: {
                            where: { id: dashboardComponent.authKey },
                            attributes: ['id', 'integration_id'],
                            include: [
                                {
                                    model: 'User',
                                    attributes: ['id', 'name', 'locale_id'],
                                    include: [{ model: 'Group' }]
                                }
                            ]
                        }
                    })
                });
                responsePromise
                    .then((response) => response.json())
                    .then((data) => {
                    if (!data
                        || !data.rows
                        || data.rows.length === 0
                        || !data.rows[0].user
                        || !data.rows[0].integration_id) {
                        reject();
                    }
                    else {
                        data.rows[0].user.userGroups = data.rows[0].user.groups.map((group) => group.id);
                        delete data.rows[0].user.groups;
                        resolve({
                            integrationId: data.rows[0].integration_id,
                            user: data.rows[0].user
                        });
                    }
                });
            }
            catch (error) {
                reject(error);
            }
        })();
    });
    return new Promise((resolve, reject) => void (() => {
        try {
            // First, use the authKey & authToken to fetch the authorization, integration_id and the User attached to it. Needed to further process dashboard rights.
            fetchUser
                .then((authorizationData) => {
                // Then fetch all dashboards the SSO user has access to with this key and token.
                const responsePromise = window.fetch(`${String(dashboardComponent.params.apiHost)}0.1.0/securable`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: { 'Content-Type': 'application/json' },
                    redirect: 'follow',
                    body: JSON.stringify({
                        action: 'get',
                        version: '0.1.0',
                        key: dashboardComponent.authKey,
                        token: dashboardComponent.authToken,
                        find: {
                            where: { type: 'dashboard', is_variant: false },
                            attributes: ['id', 'name', 'modified_at'],
                            order: [['modified_at', 'desc']],
                            include: [
                                { model: 'Tag', attributes: ['tag'] },
                                { model: 'User', attributes: ['id', 'name'] },
                                {
                                    model: 'Group',
                                    attributes: ['id', 'name', 'public']
                                },
                                { model: 'Integration', attributes: ['id', 'name'] }
                            ]
                        }
                    })
                });
                responsePromise
                    .then((response) => response.json())
                    .then((data) => {
                    const dashboardList = data.rows.length > 0
                        ? formatDashboardList(data.rows, authorizationData.integrationId, authorizationData.user, dashboardComponent)
                        : [];
                    resolve(dashboardList);
                });
            })
                .catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    })());
};
