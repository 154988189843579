import { User } from '../..';
import { AccessRightsService } from '../../services/Utils/AccessRights/access-rights.service';

export type ActionOptions = {
  text: string;
  onClick: () => void;
};

export class GroupTaskActionsService {
  constructor(
    private $translate: ng.translate.ITranslateService,
    private accessRightsService: AccessRightsService
  ) {
    'ngInject';
  }

  getGrouptaskActions({
    owner_id,
    profile,
    callbacks,
  }: {
    owner_id?: string;
    profile: User;
    callbacks: { onDelete?: () => void };
  }): ActionOptions[] {
    const { onDelete } = callbacks;
    const isAtLeastContentManager =
      this.accessRightsService.isAtLeastContentManager();
    const isOwnerOrAdmin = this.isOwnerOrAdmin(owner_id, profile);

    const deleteAction = onDelete
      ? [
          {
            text: this.$translate.instant('GROUP_TASK_ACTION_DELETE'),
            onClick: () => onDelete(),
            customClass:
              isAtLeastContentManager || isOwnerOrAdmin ? '' : 'disabled',
          },
        ]
      : [];
    return deleteAction;
  }

  isOwnerOrAdmin(owner_id: string | undefined, profile: User): boolean {
    return (
      owner_id === profile._id ||
      (profile.contents.role?.includes('admin') ?? false)
    );
  }
}
