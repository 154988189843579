export const InputCheckboxItemComponent = {
  bindings: {
    isRequired: '<',
    disabled: '<',
    hasError: '<',
    label: '@',
    inputName: '@',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  transclude: true,
  templateUrl: 'components/Inputs/input-checkbox-item/input-checkbox-item.html',
  controller: class InputCheckboxItemController {
    $onInit() {
      this.modelCtrl.$render = () => {
        this.isSelected = !!this.modelCtrl.$viewValue;
        this.isSelectedInput = this.isSelected;
      };
    }
    select() {
      this.onChange();
    }
    onChange() {
      if (this.disabled) {
        return false;
      }
      this.isSelected = !this.isSelected;
      this.isSelectedInput = this.isSelected;
      return this.modelCtrl.$setViewValue(this.isSelected);
    }
  },
};
