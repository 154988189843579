import { DEFAULT_PARAMETERS } from './default-parameters';
export function containerStyles(dashboardComponent) {
    const styles = {
        width: '100%',
        height: '100%'
    };
    if (dashboardComponent.params.loaderBackground
        && !dashboardComponent.iframeLoaded) {
        styles.background = dashboardComponent.params.loaderBackground;
    }
    return styles;
}
// luzmo-container-loader
export function containerLoaderStyles(dashboardComponent) {
    const height = dashboardComponent.params.itemDimensions.height
        || DEFAULT_PARAMETERS.itemDimensions.height;
    const chartHeightDefined = dashboardComponent.params.itemEmbedding && height !== 'auto';
    const display = dashboardComponent.iframeLoaded ? 'none' : '';
    return {
        display,
        height: `${chartHeightDefined ? height : dashboardComponent.minHeight}px`
    };
}
// luzmo-loader
export function loaderStyles(dashboardComponent) {
    const background = dashboardComponent.params.loaderBackground
        || DEFAULT_PARAMETERS.loaderBackground;
    if (dashboardComponent.params.itemEmbedding) {
        dashboardComponent.loaderSize = 24;
        dashboardComponent.borderWidth = 4;
        dashboardComponent.minHeight = 200;
        const width = dashboardComponent.params.itemDimensions.width
            || DEFAULT_PARAMETERS.itemDimensions.width;
        const height = dashboardComponent.params.itemDimensions.height
            || DEFAULT_PARAMETERS.itemDimensions.height;
        const chartWidthDefined = width !== 'auto';
        const chartHeightDefined = height !== 'auto';
        return {
            background,
            width: chartWidthDefined ? `${width}px` : '100%',
            height: `${chartHeightDefined ? height : '200'}px`,
            'min-height': chartHeightDefined ? '' : '200px'
        };
    }
    return {
        background
    };
}
// luzmo-pl
export function plStyles(dashboardComponent) {
    const { loaderSpinnerColor: defaultSpinnerColor, loaderSpinnerBackground: defaultSpinnerBackground } = DEFAULT_PARAMETERS;
    const spinnerColorBorder = generateBorderStyle(dashboardComponent.borderWidth, dashboardComponent.params.loaderSpinnerColor || defaultSpinnerColor);
    const spinnerBackgroundBorder = generateBorderStyle(dashboardComponent.borderWidth, dashboardComponent.params.loaderSpinnerBackground
        || defaultSpinnerBackground);
    const size = `${dashboardComponent.loaderSize}px`;
    return {
        'border-top': spinnerColorBorder,
        'border-right': spinnerColorBorder,
        'border-bottom': spinnerBackgroundBorder,
        'border-left': spinnerBackgroundBorder,
        width: size,
        height: size
    };
}
// luzmo-lc
export function lcStyles(dashboardComponent) {
    const height = dashboardComponent.params.itemDimensions.height
        || DEFAULT_PARAMETERS.itemDimensions.height;
    const chartHeightDefined = dashboardComponent.params.itemEmbedding && height !== 'auto';
    const getPaddingStr = (value) => `${(value - dashboardComponent.loaderSize - 32) / 2}px`;
    const verticalPadding = dashboardComponent.params.itemId
        ? (chartHeightDefined
            ? getPaddingStr(Number.parseInt(`${height}`, 10))
            : getPaddingStr(dashboardComponent.minHeight))
        : '128px';
    return {
        padding: `${verticalPadding} 16px`
    };
}
export function calculateIframeStyles(dashboardComponent) {
    const height = dashboardComponent.params.itemDimensions.height
        || DEFAULT_PARAMETERS.itemDimensions.height;
    const width = dashboardComponent.params.itemDimensions.width
        || DEFAULT_PARAMETERS.itemDimensions.width;
    if (dashboardComponent.params.itemEmbedding && height !== 'auto') {
        dashboardComponent.iframeHeight = `${height}px`;
    }
    else if (dashboardComponent.params.itemEmbedding) {
        dashboardComponent.iframeHeight = '200px';
    }
    // TODO: olegpetriienko | 09/15/2022 | We have the same fragment of logic in '_setCalculatedDimensions' method. Could be refactored
    else if (['editFull', 'editLimited'].includes(dashboardComponent.params.editMode)) {
        dashboardComponent.iframeHeight = '100%';
    }
    else {
        dashboardComponent.iframeHeight = '400px';
    }
    // Standard Width
    dashboardComponent.iframeWidth
        = dashboardComponent.params.itemEmbedding && width !== 'auto'
            ? `${width}px`
            : '100%';
    const styles = {
        height: dashboardComponent.iframeHeight,
        width: dashboardComponent.iframeWidth
    };
    // If status loaded, show iframe
    if (dashboardComponent.iframeLoaded) {
        styles.opacity = 1;
    }
    else if (dashboardComponent.error) {
        styles.opacity = 1;
        styles.width = '100%';
        styles.height = '400px';
    }
    else {
        styles.opacity = 0;
    }
    return styles;
}
function generateBorderStyle(borderWidth, color) {
    return `${borderWidth}px solid ${color}`;
}
