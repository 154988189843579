export const MerchandisingDrawSelectorComponent = {
  bindings: {
    validation: '<',
    onClose: '&',
    onSave: '&',
  },
  templateUrl:
    'merchandising/components/merchandising-draw-selector/merchandising-draw-selector.html',
  controller: function MerchandisingDrawSelectorController(
    merchandisingService
  ) {
    'ngInject';
    this.$onInit = () => {
      this.answers = merchandisingService.getValidations(this.validation);
    };

    this.validateSelectedAnswer = () =>
      this.onSave()(this.selectedAnswers[0].answer);
  },
};
