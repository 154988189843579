export const ScanSummaryComponent = {
  bindings: {
    answers: '<',
  },
  templateUrl: 'missions/components/Scans/scan-summary/scan-summary.html',
  controller: function ScanSummaryController(
    $translate,
    SF_PRODUCT_ANSWER_TYPES
  ) {
    'ngInject';
    const UNRECOGNIZED_CATALOG_KEY = 'unrecognized_catalog';

    this.$onInit = () => {
      const catalogsSummary = this.answers
        .filter((a) =>
          [
            SF_PRODUCT_ANSWER_TYPES.SALES,
            SF_PRODUCT_ANSWER_TYPES.COUNT,
          ].includes(a.type)
        )
        .reduce((summary, answer) => {
          const catalogKey =
            answer.metadata.catalog_id || UNRECOGNIZED_CATALOG_KEY;

          if (!summary[catalogKey]) {
            summary[catalogKey] = {
              name:
                answer.metadata.catalog_name ||
                $translate.instant('SCAN_SUMMARY_UNRECOGNIZED_CATALOG'),
            };
          }

          if (answer.type === SF_PRODUCT_ANSWER_TYPES.SALES) {
            return this.computeSalesSummary(
              summary,
              catalogKey,
              answer.metadata.sellTotalPrice
            );
          }

          if (answer.type === SF_PRODUCT_ANSWER_TYPES.COUNT) {
            return this.computeCountSummary(
              summary,
              catalogKey,
              answer.values[1].value
            );
          }

          return summary;
        }, {});

      this.catalogsSummary = Object.values(catalogsSummary);
    };

    this.computeSalesSummary = (summary, catalogKey, value) => {
      if (!summary[catalogKey].sales) {
        summary[catalogKey].sales = value;
        return summary;
      }
      summary[catalogKey].sales += value;
      return summary;
    };

    this.computeCountSummary = (summary, catalogKey, value) => {
      if (!summary[catalogKey].count) {
        summary[catalogKey].count = value;
        return summary;
      }
      summary[catalogKey].count += value;
      return summary;
    };

    this.isNumber = (value) => typeof value === 'number';
  },
};
