export const PopupIconComponent = {
  bindings: {
    title: '@',
    desc: '@',
    illustrationName: '@',
  },
  templateUrl: 'components/Popup/popup-icon/popup-icon.html',
  transclude: true,
  controller: class PopupIconController {
    $onInit() {
      this.src = `img/icons/${this.illustrationName}.svg`;
    }
  },
};
