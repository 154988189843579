export const InputTextComponent = {
  bindings: {
    model: '=',
    inputName: '@',
    maxChar: '<?',
    placeholder: '@?',
    isRequired: '<?',
    isDisabled: '<?',
    hasError: '<',
    pattern: '<?',
  },
  templateUrl: 'components/Inputs/input-text/input-text.html',
};
