import { ReportItemComponent } from './report-item.component';

export default angular
  .module('sfMobile.components.report-item', [
    'sfMobile.components.item-header',
    'sfMobile.services.dates',
    'sfMobile.services.pubsub',
    'sfMobile.constants.feature-flags',
    'simplifield.feature-flags',
  ])
  .component('sfReportItem', ReportItemComponent).name;
