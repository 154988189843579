import { TSFixMe } from 'app';
import {
  buildPlacesParams,
  initPlacesFilters,
} from '../../places/places/helpers';
import { PlacesParamsService } from '../../services/API/places-params/places-params.service';
import { LogService } from '../../services/Utils/log/log.service';
import { FilterCustomParam, FilterStandard } from '../Search/search';

const GET_LIMIT = 20;

type Filter = FilterStandard | FilterCustomParam;

export const PlaceSelectorComponent = {
  bindings: {
    placeholder: '@',
    searchPlaceholder: '@',
    title: '@',
    value: '<',
    multiple: '<',
    disabled: '<',
    selected: '<?',
    onChange: '&',
    onReset: '&',
    entityDescriptionGet: '<?',
  },
  templateUrl: 'components/PlaceSelector/place-selector.html',
  controller: class PlaceSelectorComponentController {
    dataProvider: TSFixMe;
    filters: Filter[];
    placesListsNameHash: Record<string, { contents: { places_ids: string[] } }>;
    placesParamsHash: Record<string, FilterCustomParam> = {};
    requestPaginate: TSFixMe;

    constructor(
      private $q: ng.IQService,
      private $translate: ng.translate.ITranslateService,
      private placesSourceAdapterService,
      private RequestsPaginate,
      private placesParamsService: PlacesParamsService,
      private placesListsService,
      private logService: LogService
    ) {
      'ngInject';
    }

    $onInit() {
      this.logService.info(
        `[BUGS-2566] place-selector.component.ts | onInit`,
        {},
        true
      );

      this.requestPaginate = new this.RequestsPaginate(
        this.placesSourceAdapterService.queryFromDataSource.bind(
          this.placesSourceAdapterService
        ),
        {
          limit: GET_LIMIT,
        }
      );

      this.dataProvider = this.customDataProvider.bind(this);
      this.initializePlaces();
    }

    initializePlaces() {
      this.logService.info(
        `[BUGS-2566] place-selector.component.ts | initializePlaces`,
        {},
        true
      );

      return this.$q
        .all([
          this.placesParamsService.listFiltersParams(),
          this.placesListsService.listLocal(),
        ])
        .then(([placesParams, placesLists]) => {
          this.logService.info(
            `[BUGS-2566] place-selector.component.ts | after placesListsService.listLocal`,
            {
              placesCount: placesLists ? placesLists.length : 0,
              placesIds: placesLists
                ? placesLists.map((place) => place._id)
                : [],
            },
            true
          );
          const { filters, placesListsNameHash, placesParamsHash } =
            initPlacesFilters(placesParams, placesLists, this.$translate);

          this.filters = filters;
          this.placesListsNameHash = placesListsNameHash;
          this.placesParamsHash = placesParamsHash;
        });
    }

    onReload() {
      return this.placesSourceAdapterService.setRemoteSource();
    }

    customDataProvider() {
      return ({ search, filters }) => {
        this.requestPaginate.reset();

        return {
          load: () =>
            this.requestPaginate.call(
              buildPlacesParams(
                search,
                filters,
                this.placesParamsHash,
                this.placesListsNameHash
              )
            ),
          canCallMore: () => this.requestPaginate.canCallMore(),
        };
      };
    }

    getPlaceTitle(place) {
      return place.contents.name;
    }
  },
};
