export function clearDataStores(database, $q, excludedStoreNames = []) {
  if (!database) {
    return $q.reject();
  }

  return database.then((db) => {
    return Array.from(db.objectStoreNames)
      .filter((name) => !excludedStoreNames.includes(name))
      .forEach((storeName) => db.clear(storeName));
  });
}
