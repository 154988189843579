import { ChangeRequestsTabsComponent } from './components/change-requests-tabs/change-requests-tabs.component';
import { ChangeRequestsListComponent } from './components/change-requests-list/change-requests-list.component';
import { ChangeRequestItemComponent } from './components/change-request-item/change-request-item.component';
import { ChangeRequestItemModalComponent } from './components/change-request-item-modal/change-request-item-modal.component';
import {
  CHANGE_REQUESTS_STATES,
  CHANGE_REQUESTS_ACTIONS,
} from './change-requests-states.constant';
import { ChangeRequestCommentsComponent } from './components/change-request-comments/change-request-comments.component';
import changeRequestsRoutes from './change-requests.routes';
import { ChangeRequestPopupService } from './services/change-request-popup.service';
import { ChangeRequestActionsService } from './services/change-request-actions.service';

export default angular
  .module('sfMobile.change-requests', ['sfMobile.services.pubsub'])
  .component('sfChangeRequestsTabs', ChangeRequestsTabsComponent)
  .component('sfChangeRequestsList', ChangeRequestsListComponent)
  .component('sfChangeRequestItem', ChangeRequestItemComponent)
  .component('sfChangeRequestItemModal', ChangeRequestItemModalComponent)
  .component('sfChangeRequestComments', ChangeRequestCommentsComponent)
  .constant('CHANGE_REQUESTS_STATES', CHANGE_REQUESTS_STATES)
  .constant('CHANGE_REQUESTS_ACTIONS', CHANGE_REQUESTS_ACTIONS)
  .service('changeRequestPopupService', ChangeRequestPopupService)
  .service('changeRequestActionsService', ChangeRequestActionsService)
  .config(changeRequestsRoutes).name;
