import { Clipboard } from '@capacitor/clipboard';

export class ClipboardService {
  Clipboard = Clipboard;

  constructor(private $q: ng.IQService) {
    'ngInject';
  }

  copyText(text: string): ng.IPromise<void> {
    return this.$q.resolve(this.Clipboard.write({ string: text }));
  }

  pasteText(): ng.IPromise<string> {
    return this.$q.resolve(
      this.Clipboard.read().then((result) => result.value)
    );
  }
}
