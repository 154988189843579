import { FILES_UPLOAD_OPTIONS } from '../../../constants/files-upload-options.constant';
import { NgfFile } from '../../../services/Utils/FileValidator/file-validator.service';

export const InputFileUploadComponent = {
  bindings: {
    hasError: '<',
    onSelect: '&',
    onError: '&',
    isDisabled: '<?',
    buttonText: '@',
    iconName: '@',
  },
  templateUrl: 'components/Inputs/input-file-upload/input-file-upload.html',
  controller: class InputFileUploadController {
    hasError: boolean;
    onSelect: (arg: { files }) => void;
    onError: () => void;
    isDisabled: boolean;

    pattern: string;
    maxSize: string;
    minHeight: number;
    resizeOption: { quality: number };

    constructor(private SF_FILES_UPLOAD_OPTIONS: typeof FILES_UPLOAD_OPTIONS) {
      'ngInject';
    }

    $onInit() {
      this.pattern = this.SF_FILES_UPLOAD_OPTIONS.IMAGE.pattern;
      this.maxSize = this.SF_FILES_UPLOAD_OPTIONS.IMAGE.maxSize;
      this.minHeight = this.SF_FILES_UPLOAD_OPTIONS.IMAGE.minHeight;
      this.resizeOption = {
        quality: this.SF_FILES_UPLOAD_OPTIONS.IMAGE.quality,
      };
    }

    onPictureSelected(files: File[], invalidFiles: NgfFile[]): void {
      this.hasError = false;

      if (invalidFiles && invalidFiles.length) {
        this.hasError = true;
        return;
      }
      if (files && !files.length) {
        return;
      }
      this.onSelect({ files });
    }

    hasToResize(width: number, height: number): boolean {
      return (
        width > this.SF_FILES_UPLOAD_OPTIONS.IMAGE.maxWidth ||
        height > this.SF_FILES_UPLOAD_OPTIONS.IMAGE.maxHeight
      );
    }
  },
};
