export class WebIntercomBridge {
  private defer: ng.IDeferred<any>;

  // eslint-disable-next-line max-params
  constructor(
    private $window: ng.IWindowService,
    private $log: ng.ILogService,
    private $document: ng.IDocumentService,
    private $q: ng.IQService,
    private ConfigServer
  ) {
    'ngInject';
  }

  private isAvailable() {
    const ic = this.getIntercom();

    if (!ic) {
      this.$log.error('Intercom is not implemented');
    }
    return Boolean(ic);
  }

  private getIntercom() {
    return this.$window.Intercom;
  }

  boot(settings: Intercom_.IntercomSettings): void {
    const ic = this.getIntercom();

    if (!ic) {
      return;
    }
    ic('reattach_activator');
    ic('update', settings);
    ic('boot', settings);
  }

  logEvent(eventName, metadata) {
    return (
      this.isAvailable() &&
      this.$window.Intercom('trackEvent', eventName, metadata)
    );
  }

  showMessages() {
    return this.isAvailable() && this.$window.Intercom('showMessages');
  }

  shutdown() {
    return this.isAvailable() && this.$window.Intercom('shutdown');
  }

  loadScript() {
    const doc = this.$document[0] as HTMLDocument;

    this.defer = this.$q.defer();

    const load = () => {
      const script = doc.createElement('script');

      script.onload = () => {
        this.defer.resolve();
      };
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${this.ConfigServer.INTERCOM_APP_ID}`;
      const x = doc.getElementsByTagName('script')[0];

      x.parentNode?.insertBefore(script, x);
    };

    if (doc.readyState === 'complete') {
      load();
    } else {
      this.$window.addEventListener('load', load, false);
    }
    return this.defer.promise;
  }
}
