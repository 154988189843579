export const TextareaComponent = {
  bindings: {
    model: '=',
    inputName: '@',
    maxChar: '<?',
    placeholder: '@?',
    isRequired: '<?',
    isDisabled: '<?',
    hasError: '<',
    onPaste: '&?',
    onInput: '&?',
  },
  templateUrl: 'components/Inputs/textarea/textarea.html',
};
