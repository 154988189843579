export const FormNodeComponent = {
  bindings: {
    item: '<',
    form: '<',
    nodeParent: '<',
    isDone: '<',
    context: '<', // { report, place, user}
    sectionNumber: '<',
    subSectionNumber: '<',
    formErrors: '=',
    profile: '<',
    place: '<',
    unconfirmedPrefilledQuestions: '=',
    availablePreviousAnswers: '<',
    useNewCampaigns: '<',
    isPreview: '<',
    isDebug: '<?',
    events: '<?',
    onCalendarEventCreated: '&?',
    onCalendarEventDeleted: '&?',
  },
  templateUrl: 'missions/form/components/form-node/form-node.html',
  controller: class FormNodeController {
    constructor(pubSubService, formsService, reportsService) {
      'ngInject';
      Object.assign(this, {
        pubSubService,
        formsService,
        reportsService,
      });
    }

    $onInit() {
      this.isItemDisplayed = this.displayItem(this.item);

      this.answerChangedListener = this.pubSubService.subscribe(
        'QUESTION_ANSWER_CHANGED',
        (data) => this.onAnswerChanged(data)
      );

      this.nodes = this.getNodes(this.item);
    }

    $onChanges(changes) {
      if (changes.item) {
        this.nodes = this.getNodes(this.item);
      }
    }

    $onDestroy() {
      if (this.answerChangedListener) {
        this.answerChangedListener();
      }
    }

    onAnswerChanged(data) {
      if (
        this.item.metadata &&
        this.item.metadata.conditions &&
        this.item.metadata.conditions.some(
          (condition) => condition.item === data._id
        )
      ) {
        this.isItemDisplayed = this.displayItem(this.item);
      }
    }

    displayItem(item) {
      var answers = this.context.report.contents.answers;

      return this.formsService.displayQuestion(
        item,
        this.form,
        answers,
        this.context
      );
    }

    getNodes(item) {
      var itemNode = this.context.report.contents.nodes.filter(getSectionNode);

      return !itemNode.length ? this.addNode(itemNode) : itemNode;

      function getSectionNode(node) {
        return node.sections_ids[0] === item._id;
      }
    }

    addNode(nodes) {
      const parentSectionsIds = this.nodeParent
        ? this.nodeParent.sections_ids
        : [];
      const sectionIds = [].concat(this.item._id, parentSectionsIds);
      const parentsIds = this.nodeParent
        ? [].concat(this.nodeParent._id, this.nodeParent.parents_ids)
        : [];
      const newNode = this.reportsService.getDefaultNode(
        sectionIds,
        parentsIds
      );

      this.context.report.contents.nodes.push(newNode);
      return nodes.concat(newNode);
    }

    canDisplaySection() {
      if (this.isDebug) {
        return true;
      }
      return this.isItemDisplayed;
    }

    calendarEventCreated(questionId, event) {
      this.onCalendarEventCreated({ questionId, event });
    }

    calendarEventDeleted(eventId) {
      this.onCalendarEventDeleted({ eventId });
    }
  },
};
