export const ReceiptComponent = {
  bindings: {
    answers: '<',
    mode: '@',
    onUpdateAnswers: '&',
    onRemoveProduct: '&',
    isReadOnly: '<',
  },
  templateUrl: 'missions/components/Receipt/receipt.html',
  controller: function ReceiptController($log, SF_QUESTION_PRODUCT_TYPES) {
    'ngInject';
    const MODES = [
      SF_QUESTION_PRODUCT_TYPES.SALES,
      SF_QUESTION_PRODUCT_TYPES.COUNT,
    ];

    this.$onInit = () => {
      if (!MODES.includes(this.mode)) {
        $log.error(
          'mode is mandatory for sfReceipt and shoud be one of',
          MODES
        );
      }
      this.isSalesMode = this.mode === SF_QUESTION_PRODUCT_TYPES.SALES;
      this.isCountMode = this.mode === SF_QUESTION_PRODUCT_TYPES.COUNT;
    };

    this.updateAnswersArray = () => {
      // Update answers reference to trigger receipt-total changes
      this.answers = [...this.answers];
      this.onUpdateAnswers({ answers: this.answers });
    };

    this.removeProduct = (answer) => this.onRemoveProduct({ answer });
  },
};
