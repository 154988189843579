import { ChatRoomInfoComponent } from './chat-room-info.component';
import ChatRoomAddParticipantsModule from './components/chat-room-add-participants/chat-room-add-participants.module';
import ChatRoomInfoUserModule from './components/chat-room-info-user/chat-room-info-user.module';

export default angular
  .module('sfMobile.components.chat-room-info', [
    'sfMobile.services.localization',
    'pascalprecht.translate',
    'sfMobile.services.chat',
    'sfMobile.services.dates',
    'sfMobile.services.modal',
    'sfMobile.services.chatApi',
    'sfMobile.services.access-rights',
    'sfMobile.services.chat',
    'sfMobile.services.dates',
    'sfMobile.components.button-header-close',
    ChatRoomAddParticipantsModule,
    ChatRoomInfoUserModule,
  ])
  .component('sfChatRoomInfo', ChatRoomInfoComponent).name;
