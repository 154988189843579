const add = (target, key, value) => {
  if (target instanceof Array) {
    target.push(value);

    return target[target.length - 1];
  }

  target[key] = value;

  return target[key];
};

// eslint-disable-next-line complexity
const doClone = (source, target) => {
  for (let key in source) {
    if (!{}.hasOwnProperty.call(source, key)) {
      continue;
    }
    const obj = source[key];

    switch (true) {
      case obj instanceof Date:
        add(target, key, new Date(obj.getTime()));
        break;

      case obj instanceof Function:
        add(target, key, obj);
        break;

      case obj instanceof Array:
        doClone(obj, add(target, key, []));
        break;

      case obj instanceof Object:
        doClone(obj, add(target, key, {}));
        break;

      default:
        add(target, key, obj);
        break;
    }
  }
};

/**
 * Creates a deep copy of `source`
 * Inspired by `angular.copy`
 * @param {*} source The source
 * @returns {*} The copy or source
 */
export default (source) => {
  if (['boolean', 'number', 'string'].includes(typeof source)) {
    return source;
  }

  if (source instanceof Date) {
    return new Date(source.getTime());
  }

  const target = source instanceof Array ? [] : {};

  doClone(source, target);

  return target;
};
