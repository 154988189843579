import { PopupSynchronizeController } from './popup-synchronize.controller';

export const PopupSynchronizeComponent: ng.IComponentOptions = {
  bindings: {
    onClose: '&',
    onSuccess: '&',
    hideAlertMessage: '<?',
  },
  transclude: true,
  templateUrl: 'components/Popup/popup-synchronize/popup-synchronize.html',
  controller: PopupSynchronizeController,
};
