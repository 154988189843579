import sfPreferences from 'sf-preferences';

const CSS_THEME_MAIN_COLOR = '--theme-main-color';
const CSS_THEME_PRIMARY_COLOR = '--theme-primary-color';

export function ThemeService($document, SF_ORGANISATION_PREFERENCES) {
  'ngInject';

  this.sfPreferences = sfPreferences;

  this.bodyStyleProperty = (body) => (key, style) => {
    if (!style) {
      return false;
    }
    body.style.setProperty(key, style);
    return true;
  };
  this.init = (organisation) => {
    const { THEME_MAIN_COLOR, THEME_PRIMARY_COLOR } =
      SF_ORGANISATION_PREFERENCES;
    const { body } = $document[0];
    const { preferences } = organisation;
    const setBodyProperty = this.bodyStyleProperty(body);

    const mainColor = this.sfPreferences.get(preferences, THEME_MAIN_COLOR);
    const primaryColor = this.sfPreferences.get(
      preferences,
      THEME_PRIMARY_COLOR
    );

    setBodyProperty(CSS_THEME_MAIN_COLOR, mainColor);
    if (isContrastEnough(primaryColor)) {
      setBodyProperty(CSS_THEME_PRIMARY_COLOR, primaryColor);
    }
  };
}

export function isContrastEnough(color) {
  const hexColorRegex = RegExp('#[0-9A-F]{6}', 'i');
  if (!hexColorRegex.test(color)) {
    return false;
  }

  // https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
  const rgb = getRgb(color);
  const luminance = getLuminance(rgb);
  const whiteLuminance = getLuminance({ r: '255', g: '255', b: '255' });
  const ratio =
    luminance > whiteLuminance
      ? (whiteLuminance + 0.05) / (luminance + 0.05)
      : (luminance + 0.05) / (whiteLuminance + 0.05);

  return ratio < 1 / 4.5 ? true : false;
}

function getRgb(color) {
  // Convert hex to RGB: http://gist.github.com/983661
  color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

  const r = color >> 16;
  const g = (color >> 8) & 255;
  const b = color & 255;

  return { r, g, b };
}

function getLuminance({ r, g, b }) {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
