export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 19,
    method: updateMethod,
  });
  // this script just recreates calendarEventsParams table after new custom params arrived

  // @ngInject
  function updateMethod(sqlStorageService, localStorageService, sentryService) {
    const calendarEventsParams = [
      'id NVARCHAR(32) PRIMARY KEY UNIQUE',
      'payload TEXT',
    ].join(',');

    const dropCalendarEventsParams =
      'DROP TABLE IF EXISTS calendar_events_params';
    const createTableCalendarEventsParams = `CREATE TABLE calendar_events_params(${calendarEventsParams})`;

    const update_date_keys = localStorageService
      .keys()
      .filter((key) => key.indexOf('_update_date') !== -1);

    return sqlStorageService
      .execute(dropCalendarEventsParams)
      .then(() => sqlStorageService.execute(createTableCalendarEventsParams))
      .then(() =>
        localStorageService.remove.apply(localStorageService, update_date_keys)
      )
      .then(() => localStorageService.remove('synchronise_date'))
      .catch((error) =>
        sentryService.captureMessage('MIGRATION_SCRIPT_FAIL_19', {
          level: 'error',
          extra: {
            error,
          },
          tags: {
            sendNotification: 'mail',
          },
        })
      );
  }
}
