import { ImagesViewerController } from './images-viewer.controller';

export const ImagesViewerComponent = {
  bindings: {
    activeSlide: '<',
    header: '<',
    images: '<',
    lockZooming: '<',
    onClose: '&',
    setActiveSlide: '&',
    hideShareButton: '<?',
    hideDownloadButton: '<?',
  },
  templateUrl: 'components/Images/images-viewer/images-viewer.html',
  transclude: true,
  controller: ImagesViewerController,
};
