import { NotificationItemController } from './notification-item.controller';

export const NotificationItemComponent: ng.IComponentOptions = {
  bindings: {
    notification: '<',
    onRedirect: '&',
  },
  templateUrl:
    'notifications/components/notification-item/notification-item.html',
  controller: NotificationItemController,
};
