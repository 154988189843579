const VIEW_NAV_BAR_DISPLAY_PERCENT = 85;

export type NavBarElement = {
  title: HTMLElement | null;
  bar: HTMLElement;
};

export class ViewController {
  navBar: NavBarElement | null = null;
  hasHeader = false;
  hasFixedParralax: boolean;

  initHeader(): void {
    this.setTitleVisibility(this.navBar, 0);
    this.hasHeader = true;
  }

  registerNavBar(bar: HTMLElement): void {
    const title = bar.querySelector<HTMLElement>('.sf_header_title');

    this.navBar = { bar, title };
  }
  onContentScroll(percentScrolled: number): void {
    const { hasHeader, navBar } = this;

    if (!hasHeader) {
      return;
    }
    this.setTitleVisibility(navBar, percentScrolled);
  }
  setTitleVisibility(
    navBar: NavBarElement | null,
    percentScrolled: number
  ): void {
    if (!navBar || !navBar.title) {
      return;
    }
    const visible =
      percentScrolled >=
      (this.hasFixedParralax ? 100 : VIEW_NAV_BAR_DISPLAY_PERCENT);

    navBar.title.classList.toggle('invisible', !visible);
    navBar.title.classList.toggle('visible', visible);
  }
}
