"use strict";

var _definitions = require("../definitions");

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Form',
  description: 'A structured schema to create new reports.',
  type: 'object',
  additionalProperties: false,
  required: ['title', 'needPlace', 'state', 'sections', 'questions', 'statusMap', 'organisation_id', 'users_ids', 'places_ids', 'subscribers_ids', 'owner_id'],
  properties: {
    title: {
      type: 'string'
    },
    description: {
      type: 'string'
    },
    needPlace: {
      type: 'boolean'
    },
    tags: {
      type: 'array',
      uniqueItems: true,
      items: [{
        type: 'string'
      }]
    },
    state: schemaUtils.formState(),
    disabled: {
      type: 'boolean',
      "default": false
    },
    reminder: {
      type: 'boolean',
      "default": false
    },
    reminderContents: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'number',
        minimum: 0,
        maximum: 100
      }
    },
    start_date: {
      type: 'string',
      format: 'date-time'
    },
    end_date: {
      type: 'string',
      format: 'date-time'
    },
    choice: {
      type: 'string',
      "enum": ['share', 'assign', 'none'],
      "default": 'none'
    },
    files: schemaUtils.files(),
    sections: {
      type: 'array',
      items: {
        title: 'FormSection',
        type: 'object',
        additionalProperties: false,
        required: ['_id', 'title', 'level', 'minimum', 'maximum'],
        properties: {
          _id: {
            type: 'string',
            format: 'uuid'
          },
          name: {
            description: 'Name to give for referring in computable expressions.',
            type: 'string',
            minlength: 1,
            pattern: '^[a-z][a-z0-9\\-_]+$'
          },
          title: {
            description: 'Section title',
            type: 'string'
          },
          description: {
            description: 'Section description',
            type: 'string'
          },
          type: schemaUtils.sectionType(),
          metadata: {
            description: 'Do wathever you want here',
            type: 'object',
            additionalProperties: true
          },
          level: {
            description: 'Level in the FormSections tree',
            type: 'number',
            minimum: 0,
            "default": 0
          },
          minimum: {
            description: 'Minimum amount of AnswerSections' + ' (can be a number or an expression to compute)',
            type: ['number', 'string'],
            "default": 0
          },
          maximum: {
            description: 'Maximum amount of AnswerSections' + ' (can be a number or an expression to compute)',
            type: ['number', 'string'],
            minimum: 1,
            "default": 1
          },
          files: schemaUtils.files(),
          itemsOrder: {
            type: 'array',
            items: {
              type: 'object',
              additionalProperties: false,
              required: ['_id', 'type'],
              properties: {
                _id: {
                  type: 'string',
                  format: 'uuid'
                },
                type: {
                  description: 'Type of item',
                  type: 'string',
                  "enum": ['question', 'section']
                }
              }
            }
          }
        }
      }
    },
    questions: {
      type: 'array',
      items: [{
        name: 'FormQuestion',
        type: 'object',
        required: ['_id', 'section_id', 'type', 'title', 'minimum', 'maximum', 'unique', 'fields'],
        additionalProperties: false,
        properties: {
          _id: {
            type: 'string',
            format: 'uuid'
          },
          section_id: {
            $ref: '#/definitions/objectId'
          },
          name: {
            description: 'Name to give for referring in computable expressions.',
            type: 'string',
            minlength: 1,
            pattern: '^[a-z][a-z0-9\\-_]+$'
          },
          type: schemaUtils.questionType(),
          validation: {
            description: 'Validation needed (only for images)',
            type: 'boolean'
          },
          title: {
            description: 'Label of the question, to be displayed in the form',
            type: 'string',
            "default": 'default'
          },
          description: {
            description: 'Question description',
            type: 'string'
          },
          metadata: {
            description: 'Do wathever you want here',
            type: 'object',
            additionalProperties: true
          },
          minimum: {
            description: 'Minimum amount of Answers (can be a number or an expression to compute).',
            type: ['number', 'string'],
            "default": 0
          },
          maximum: {
            description: 'Maximum amount of Answers (can be a number or an expression to compute)',
            type: ['number', 'string'],
            "default": 1
          },
          unique: {
            description: 'Check that the values sequence is unique',
            type: 'boolean',
            "default": true
          },
          fields: {
            description: 'List of fields the question exposes.',
            type: 'array',
            items: [{
              type: 'object',
              additionalProperties: false,
              required: ['_id', 'type'],
              properties: {
                _id: {
                  type: 'string',
                  format: 'uuid'
                },
                type: {
                  description: 'Type of value to fill in.',
                  type: 'string',
                  "enum": ['boolean', 'number', 'string', 'date', 'time', 'uuid', 'score', 'ean']
                },
                formula: {
                  description: 'The formula to compute the field value.',
                  type: 'string'
                },
                editable: {
                  description: 'Whether the value can be edited per the user or not.',
                  type: 'boolean',
                  "default": true
                },
                displayable: {
                  description: 'Whether the value must be displayed to the user or not.',
                  type: 'boolean',
                  "default": true
                },
                sources: {
                  description: 'Privilegied sources',
                  type: 'array',
                  uniqueItems: true,
                  items: [{
                    type: 'string',
                    "enum": ['audio', 'barcode', 'drawing', 'image', 'keyboard', 'library', 'video']
                  }]
                },
                multipleOf: {
                  description: 'Value a number field must be multiple of' + ' (can be a number or an expression to compute)',
                  type: ['number', 'string']
                },
                minimum: {
                  description: 'Minimum value for date/time/day/duration/number' + ' fields or length for string field' + ' (can be a number or an expression to compute).',
                  type: ['number', 'string']
                },
                exclusiveMinimum: {
                  description: 'Whether the minimum value is exclusive or not.',
                  type: 'boolean',
                  "default": false
                },
                maximum: {
                  description: 'Maximum value for date/time/day/duration/number' + ' fields or length for string field' + ' (can be a number or an expression to compute).',
                  type: ['number', 'string']
                },
                exclusiveMaximum: {
                  description: 'Whether the maximum value is exclusive or not.',
                  type: 'boolean',
                  "default": false
                },
                format: {
                  description: 'Format a string field has to obey to.',
                  type: 'string',
                  "enum": ['text', 'inline', 'email', 'phone', 'uri', 'regexp']
                },
                pattern: {
                  description: 'Pattern for the RegExp form.',
                  type: 'string'
                },
                set: {
                  description: 'Values the field can contain.',
                  type: 'array',
                  uniqueItems: true,
                  items: [{
                    type: ['string', 'number']
                  }]
                },
                exclusiveSet: {
                  description: 'Whether the set values are exclusive or not.',
                  type: 'boolean',
                  "default": false
                },
                scoreMap: {
                  description: 'A hash containing score values for each possible answers',
                  type: 'array',
                  uniqueItems: true,
                  items: [{
                    type: 'object',
                    additionalProperties: false,
                    required: ['condition', 'value'],
                    properties: {
                      condition: {
                        type: ['string', 'number']
                      },
                      value: {
                        type: ['number', 'object', 'null']
                      } // 'object' is for null

                    }
                  }]
                },
                anterior: {
                  description: 'Whether anterior date is allowed or not',
                  type: 'boolean',
                  "default": true
                },
                "default": {
                  description: 'Default value of the field.',
                  type: ['string', 'number']
                }
              }
            }]
          },
          catalogs_ids: {
            description: 'Ids of catalogs associated to a product question',
            type: 'array',
            items: {
              type: 'string'
            }
          },
          prefillWithLastAnswer: {
            description: 'Last answers should be prefill in the report',
            type: 'boolean',
            "default": false
          },
          files: schemaUtils.files()
        }
      }]
    },
    statusMap: {
      type: 'array',
      description: 'A list of all possible report statuses for this form',
      uniqueItems: true,
      minItems: 2,
      items: [{
        name: 'PossibleStatus',
        type: 'object',
        additionalProperties: false,
        required: ['key', 'label'],
        properties: {
          key: {
            type: 'string'
          },
          label: {
            type: 'string'
          }
        }
      }]
    },
    organisation_id: {
      $ref: '#/definitions/objectId'
    },
    owner_id: {
      $ref: '#/definitions/objectId'
    },
    subscribers_ids: {
      $ref: '#/definitions/objectIds'
    },
    analysts_ids: {
      $ref: '#/definitions/objectIds'
    },
    users_ids: {
      $ref: '#/definitions/objectIds'
    },
    places_ids: {
      $ref: '#/definitions/objectIds'
    },
    template_id: {
      $ref: '#/definitions/objectId'
    },
    forks_ids: {
      $ref: '#/definitions/objectIds'
    },
    reportTemplate_id: {
      $ref: '#/definitions/objectId'
    },
    preferences: schemaUtils.preferences(),
    reportsValidation: {
      type: 'array',
      minItems: 1,
      items: {
        $ref: '#/definitions/checkDefinition'
      }
    }
  },
  definitions: {
    checkDefinition: _definitions.checkDefinition,
    objectId: _definitions.objectId,
    objectIds: _definitions.objectIds
  }
};