import { pathOr } from 'ramda';

const GET_LIMIT = 20;

export const EntitySelectorComponent = {
  bindings: {
    emptyIcon: '@',
    placeholder: '@',
    searchPlaceholder: '@',
    title: '@',
    multiple: '<',
    value: '<',
    disabled: '<',
    selected: '<?',
    entities: '<?',
    searchFields: '<?',
    staticSearchFields: '<?',
    entityProvider: '<?',
    entityTitleGet: '<',
    entityDescriptionGet: '<?',
    customDataProvider: '<?',
    onChange: '&',
    onReset: '&',
    onReload: '&',
    filtersAvailable: '<',
  },
  templateUrl: 'components/entity-selector/entity-selector.html',
  controller: class EntitySelectorComponentController {
    // eslint-disable-next-line max-params
    constructor(
      localizationService,
      modalService,
      RequestsPaginate,
      StaticRequestsPaginate,
      apiUtilsService,
      $filter,
      sfFeatureFlagsService,
      SF_FEATURE_FLAGS,
      searchUtilsService
    ) {
      'ngInject';
      this.localizationService = localizationService;
      this.modalService = modalService;
      this.RequestsPaginate = RequestsPaginate;
      this.StaticRequestsPaginate = StaticRequestsPaginate;
      this.apiUtilsService = apiUtilsService;
      this.$filter = $filter;
      this.searchUtilsService = searchUtilsService;
      this.sfFeatureFlagsService = sfFeatureFlagsService;
      this.SF_FEATURE_FLAGS = SF_FEATURE_FLAGS;
    }

    $onInit() {
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
      this.isFullCalendar = this.sfFeatureFlagsService.hasFeature(
        this.SF_FEATURE_FLAGS.FULL_CALENDAR
      );

      this.dataProvider = this.customDataProvider
        ? this.customDataProvider()
        : this.entities
        ? this.createStaticDataProvider()
        : this.createHttpDataProvider();
    }

    openSelectEntityModal() {
      const template = `
        <sf-entity-selector-modal
          empty-icon="{{ $ctrl.emptyIcon }}"
          search-placeholder="{{ $ctrl.searchPlaceholder }}"
          title="{{ $ctrl.title }}"
          multiple="$ctrl.multiple"
          selected="$ctrl.selected"
          entity-title-get="$ctrl.entityTitleGet"
          entity-description-get="$ctrl.entityDescriptionGet"
          filters-available="$ctrl.filtersAvailable"
          on-selection-change="$ctrl.onSelectionChange(selection)"
          on-save="$ctrl.onSave()"
          on-query-change="$ctrl.onQueryChange(query)"
          on-close="$ctrl.onClose()"
          on-reload="$ctrl.onReload()"
        ></sf-entity-selector-modal>
      `;

      let selectionResult = this.multiple
        ? [...(this.selected || [])]
        : { ...this.selected };
      const onSingleSelectChange = ({ entity }) => {
        selectionResult = entity;
      };
      const onMultipleSelectionChange = ({ entity, selected }) => {
        selectionResult = selected
          ? selectionResult.concat(entity)
          : selectionResult.filter((el) => el._id !== entity._id);
      };

      return this.modalService.open(template, {
        entityTitleGet: this.entityTitleGet,
        entityDescriptionGet: this.entityDescriptionGet,
        emptyIcon: this.emptyIcon,
        title: this.title,
        searchPlaceholder: this.searchPlaceholder,
        multiple: this.multiple,
        selected: this.selected,
        filtersAvailable: this.filtersAvailable || [],
        onSelectionChange: this.multiple
          ? onMultipleSelectionChange
          : onSingleSelectChange,
        onSave: () => this.onChange({ result: selectionResult }),
        onQueryChange: (query) => this.dataProvider(query),
        onReload: () => this.onReload(),
      });
    }

    createStaticDataProvider() {
      return ({ search }) => {
        const filteredEntities = this.staticSearchFields
          ? this.getStaticFilteredEntities(search)
          : this.$filter('filter')(this.entities, search);

        return new this.StaticRequestsPaginate(filteredEntities, GET_LIMIT);
      };
    }

    createHttpDataProvider() {
      const { buildFilterParams } = this.apiUtilsService;
      const { RequestsPaginate } = this;

      const requestPaginate = new RequestsPaginate(this.entityProvider, {
        limit: GET_LIMIT,
      });

      return ({ search = '', filters = [] }) => {
        const requestFilters = buildFilterParams(filters, {
          search,
          criterias: this.searchFields,
        });

        requestPaginate.reset();

        return {
          load: () => requestPaginate.call(requestFilters),
          canCallMore: () => requestPaginate.canCallMore(),
        };
      };
    }

    getStaticFilteredEntities(search) {
      const filterFn = this.getFilterFn(search);
      const filteredEntities = this.entities
        .map((entity) => {
          const searchField = this.staticSearchFields
            .map((field) => pathOr('', field.split('.'), entity))
            .join(' ');

          return { ...entity, searchField };
        })
        .filter(({ searchField }) => filterFn(searchField));

      return filteredEntities;
    }

    getFilterFn(search) {
      if (!search) {
        return () => true;
      }

      const regStr =
        this.searchUtilsService.getRegexStringWithDiacritics(search);
      const reg = this.searchUtilsService.getSearchRegex(regStr);

      return (entity) => reg.test(entity);
    }
  },
};
