export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 5.0,
    method: (sqlStorageService, localStorageService) => {
      'ngInject';
      const commonFields = [
        'id NVARCHAR(32) PRIMARY KEY UNIQUE',
        'payload TEXT',
      ];
      const placesFields = [].concat(commonFields).join(',');
      const usersFields = []
        .concat(commonFields, [
          'organisation_id NVARCHAR(32)',
          'owner_id NVARCHAR(32)',
        ])
        .join(',');

      const dropTablePlaces = 'DROP TABLE IF EXISTS places';
      const dropTableUsers = 'DROP TABLE IF EXISTS users';
      const createTablePlaces = `CREATE TABLE places(${placesFields})`;
      const createTableUsers = `CREATE TABLE users(${usersFields})`;

      return sqlStorageService
        .execute(dropTablePlaces)
        .then(() => sqlStorageService.execute(dropTableUsers))
        .then(() => sqlStorageService.execute(createTablePlaces))
        .then(() => sqlStorageService.execute(createTableUsers))
        .then(() => localStorageService.remove('synchronise_date')) // Force a sync of data
        .catch(() => null);
    },
  });
}
