import { PlaceAddController } from './place-add.controller';

export const PlaceAddComponent = {
  bindings: {
    profile: '<',
    onClose: '&',
    onSave: '&',
  },
  templateUrl: 'places/place/components/place-add/place-add.html',
  controller: PlaceAddController,
};
