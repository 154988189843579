"use strict";

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Views',
  type: 'object',
  additionalProperties: false,
  required: ['label', 'definition', 'organisation_id', 'owner_id', 'ressourceType'],
  properties: {
    label: {
      type: 'string'
    },
    definition: {
      type: 'string'
    },
    ressourceType: {
      type: 'string'
    },
    parentRessource: {
      type: 'object',
      additionalProperties: false,
      required: ['ressourceType', '_id'],
      properties: {
        ressourceType: {
          type: 'string'
        },
        _id: schemaUtils.oneToOne()
      }
    },
    columns: {
      type: 'array',
      items: {
        type: 'object'
      }
    },
    organisation_id: schemaUtils.oneToOne(),
    owner_id: schemaUtils.oneToOne()
  }
};