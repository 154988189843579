import type { User, ObjectId } from '../../../../..';
import type { Task, TaskStatus } from '../../../../../tasks';
import type { APIStore } from '../../../../index';
import { SF_TASK_STATUSES } from '../../../../../tasks/constants/task-statuses.constant';
import {
  PubSubService,
  UnregisterFn,
} from '../../../../../services/Utils/PubSub/pubsub.service';
import { TaskService } from '../../../../../tasks/services/task.service';

type RequestFilter = {
  name: string;
  value: ObjectId | string[];
  operator?: string;
};

type TaskFilterTemplate = {
  title: string;
  requestFilters: RequestFilter[];
};

type ButtonSelectorOption = {
  id: number;
  label: string;
};

export class PlaceTasksTabsController implements ng.IComponentController {
  // bindings
  place: APIStore;
  profile: User;
  reloadTasksTab: () => void;
  showToaster: () => void;

  //constants
  ALL_TASKS_FILTER_INDEX = 0;

  // fields
  isFieldExperience;
  unsubscribeEvent: UnregisterFn;
  taskStatusTypes: TaskFilterTemplate[];
  buttonSelectorOptions: ButtonSelectorOption[];
  activeFilterTitle: string;
  taskStatus: TaskStatus;
  taskStatusIndex: number;
  taskStatusFilters: RequestFilter[] | null;
  toasterMessageKey: string;
  PLACE_FILTER: RequestFilter;

  constructor(
    private $timeout: ng.ITimeoutService,
    private $translate: ng.translate.ITranslateService,
    private pubSubService: PubSubService,
    private taskService: TaskService,
    private userExperienceService,
    private TASK_STATUSES: typeof SF_TASK_STATUSES
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.PLACE_FILTER = {
      name: 'place_id',
      value: this.place._id,
    };
    this.isFieldExperience = this.userExperienceService.isField();

    this.setButtonSelectorOptions();
    this.setActiveFilter(this.ALL_TASKS_FILTER_INDEX);

    this.unsubscribeEvent = this.pubSubService.subscribe(
      'TASK_STATUS_CHANGED',
      ({ newStatus }: { newStatus: TaskStatus }) =>
        this.showToasterOnStatusChange(newStatus)
    );
  }

  $onDestroy(): void {
    this.pubSubService.publish('TASKS_LIST_DESTROYED');
    this.unsubscribeEvent();
  }

  setButtonSelectorOptions(): void {
    const statuses = [
      this.TASK_STATUSES.TODO,
      this.TASK_STATUSES.DONE,
      this.TASK_STATUSES.DONE_ON_TIME,
      this.TASK_STATUSES.LATE_COMPLETION,
    ];

    this.taskStatusTypes = statuses.map((status) => {
      if (status.keyword === this.TASK_STATUSES.DONE.keyword) {
        return {
          title: this.$translate.instant(status.i18nKey),
          requestFilters: [
            {
              name: 'status',
              operator: '$in',
              value: [
                this.TASK_STATUSES.DONE_ON_TIME.keyword,
                this.TASK_STATUSES.LATE_COMPLETION.keyword,
              ],
            },
          ],
        };
      }
      return {
        title: this.$translate.instant(status.i18nKey),
        requestFilters: [{ name: 'status', value: status.keyword }],
      };
    });

    this.buttonSelectorOptions = this.taskStatusTypes.map(
      (statusType, index) => ({
        id: index + 1,
        label: statusType.title,
      })
    );
  }

  getStatusTypeByIndex = (statusTypeIndex: number): TaskFilterTemplate => {
    return this.taskStatusTypes[statusTypeIndex];
  };

  setActiveFilter = (selectedViewIndex: number): void => {
    const statusType = this.getStatusTypeByIndex(selectedViewIndex);

    this.activeFilterTitle = statusType.title;
    this.taskStatus = statusType.requestFilters[0].value as TaskStatus;
    this.taskStatusIndex = selectedViewIndex;
    this.taskStatusFilters = [...statusType.requestFilters, this.PLACE_FILTER];
  };

  goToAllTasksFilter = (): ng.IPromise<void> => {
    this.setActiveFilter(this.ALL_TASKS_FILTER_INDEX);
    return this.$timeout(() => this.reloadTasksTab());
  };

  onTypeSelect = (option: ButtonSelectorOption): boolean => {
    const { taskStatusIndex } = this;
    const selectedViewIndex = this.buttonSelectorOptions
      .map((buttonSelectorOption) => buttonSelectorOption.id)
      .indexOf(option.id);

    if (taskStatusIndex === selectedViewIndex) {
      return false;
    }

    this.setActiveFilter(selectedViewIndex);
    this.$timeout(() => {
      this.reloadTasksTab();
    });
    return true;
  };

  openCreateTaskModal(): ng.IPromise<void> {
    return this.taskService
      .openTaskManageModal({} as Task, this.profile, {
        place: {
          _id: this.place._id,
          name: this.place.contents.name,
        },
      })
      .then(() => {
        this.goToAllTasksFilter();
      });
  }

  showToasterOnStatusChange(newStatus: TaskStatus): void {
    this.toasterMessageKey = this.taskService.isToDo(newStatus)
      ? 'TASKS_STATUS_CHANGED_TOASTER_TO_DO'
      : 'TASKS_STATUS_CHANGED_TOASTER_DONE';
    this.showToaster();
  }
}
