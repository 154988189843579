import { TSFixMe } from 'app';
import { Category, CategoryAPIList } from '../..';
import { PovService } from '../../../services/API/POV/pov.service';

export enum CategorizableResourceType {
  NEWSFEED = 'newsfeedPost',
  PLACES_PARAMS = 'placesParams',
  USERS_PARAMS = 'usersParams',
  CALENDAR_EVENTS_PARAMS = 'calendarEventsParams',
}

export type CategoriesAPI = {
  list: (params?) => ng.IPromise<CategoryAPIList>;
  partialUpdate: (changes: Category[], params?) => ng.IPromise<CategoryAPIList>;
};

export const PATH_MAP = new Map([
  [CategorizableResourceType.NEWSFEED, '/newsfeed'],
  [CategorizableResourceType.PLACES_PARAMS, '/placesParams'],
  [CategorizableResourceType.CALENDAR_EVENTS_PARAMS, '/calendarEvents'],
]);

export class CategoriesApiFactory {
  constructor(
    private $http: ng.IHttpService,
    private sfPOVService: PovService
  ) {
    'ngInject';
  }

  buildService({ type }: { type: CategorizableResourceType }): CategoriesAPI {
    return {
      list: this.list(type, this.$http, this.sfPOVService),
      partialUpdate: this.partialUpdate(type, this.$http, this.sfPOVService),
    };
  }

  private list(
    type: CategorizableResourceType,
    $http: ng.IHttpService,
    sfPOVService
  ) {
    const params: TSFixMe = {};
    if (type === CategorizableResourceType.CALENDAR_EVENTS_PARAMS) {
      params.filters = {
        'contents.entityType': {
          $eq: 'calendarEvents',
        },
      };
    }
    return (): ng.IPromise<CategoryAPIList> =>
      sfPOVService
        .pBuildURL(PATH_MAP.get(type) + '/categories', { pov: 'organisation' })
        .then((url) => $http.get<CategoryAPIList>(url, { params }))
        .then((response) => response.data);
  }

  private partialUpdate(
    type: CategorizableResourceType,
    $http: ng.IHttpService,
    sfPOVService
  ) {
    return (changes: Category[], params = {}): ng.IPromise<CategoryAPIList> =>
      sfPOVService
        .pBuildURL(PATH_MAP.get(type) + '/categories', { pov: 'organisation' })
        .then((url) =>
          $http.patch<CategoryAPIList>(url, { contents: changes }, { params })
        )
        .then((response) => response.data);
  }
}
