import moment from 'moment-timezone';

export const SelectTimezoneComponent = {
  bindings: {
    isRequired: '<?',
    inputName: '@',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  templateUrl: 'components/Inputs/select-timezone/select-timezone.html',
  controller: function SelectTimezoneController() {
    'ngInject';

    this.moment = moment;

    this.$onInit = () => {
      this.modelCtrl.$render = () => {
        this.value = this.modelCtrl.$viewValue;
      };
      this.options = this.moment.tz.names();
    };

    this.changeValue = () => this.modelCtrl.$setViewValue(this.value);
  },
};
