export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 8,
    method: updateMethod,
  });

  // @ngInject
  function updateMethod(sqlStorageService, localStorageService) {
    const fields = [
      'id NVARCHAR(32) PRIMARY KEY UNIQUE',
      'payload TEXT',
      'name TEXT',
      'distance DOUBLE',
    ].join(',');
    const update_date_keys = localStorageService
      .keys()
      .filter((key) => key.indexOf('_update_date') !== -1);

    const resetPlacesTable = () =>
      sqlStorageService
        .execute('DROP TABLE places')
        .then(() => sqlStorageService.execute(`CREATE TABLE places(${fields})`))
        .then(() =>
          localStorageService.remove.apply(
            localStorageService,
            update_date_keys
          )
        );

    return sqlStorageService
      .execute('SELECT * FROM places WHERE payload IS NULL')
      .then((res) => {
        // If payload data are not null, data is ok, skip.
        if (!res.rows.length) {
          return true;
        }
        // If payload data are null, data is corrupted, reset the places table
        return resetPlacesTable();
      })
      .catch(() => null);
  }
}
