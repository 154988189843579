import {
  ActionSheetConfig,
  ActionSheetService,
} from '../../../services/Utils/ActionSheet/action-sheet.service';
import { ObjectId } from '../../..';

export interface ButtonSelectorOption {
  id: ObjectId;
  label: string;
}

export class ButtonSelectorController {
  options: ButtonSelectorOption[];
  defaultActiveOption?: ButtonSelectorOption;
  emptyLabel?: string;
  color: string;
  onSelect: ({ option }) => void;
  hasCancel?: boolean;
  optionActive?: ButtonSelectorOption;
  colorClass: string;
  actionSheetActions: any[];
  isScrollable: boolean;
  onSelectorOpen: () => void;
  onSelectorCancel: () => void;

  constructor(
    private $translate: ng.translate.ITranslateService,
    private actionSheetService: ActionSheetService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.optionActive = this.defaultActiveOption;
    this.colorClass = `button-${this.color || 'light'}`;
    this.actionSheetActions = this.options.map((option) => ({
      ...option,
      text: option.label,
      onClick: () => {
        this.optionActive = option;
        this.onSelect({ option });
      },
    }));
  }

  onClick(): () => void {
    this.onSelectorOpen && this.onSelectorOpen();

    const actionSheetConfig: ActionSheetConfig = {};

    if (this.hasCancel) {
      actionSheetConfig.cancelText = this.$translate.instant(
        'BUTTON_ITEM_ACTIONS_CANCEL'
      );
    }
    if (this.isScrollable) {
      actionSheetConfig.customClass = 'action-sheet-scrollable';
    }

    return this.actionSheetService.open(
      this.actionSheetActions,
      actionSheetConfig,
      () => this.onSelectorCancel && this.onSelectorCancel()
    );
  }
}
