export const NewsfeedPostCommentComponent = {
  bindings: {
    comment: '<',
    commentActions: '<',
    commentUser: '<',
    isFocused: '<',
  },
  templateUrl:
    'newsfeed/components/newsfeed-post-comment/newsfeed-post-comment.html',
};
