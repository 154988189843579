export const ButtonHeaderSynchronizeComponent = {
  templateUrl:
    'components/Buttons/button-header-synchronize/button-header-synchronize.html',
  controller: function ButtonSynchronizeController(
    synchronizeService,
    segmentService
  ) {
    'ngInject';
    this.syncData = () => {
      segmentService.track('SYNCHRONIZE', {
        action: 'tap',
        label: 'Manual sync',
      });
      return synchronizeService.synchronize();
    };
    this.isSyncing = () => synchronizeService.isSynchronizing;
  },
};
