export const ReportQuestionComponent = {
  bindings: {
    question: '<',
    nodeId: '@',
    report: '<',
    form: '<',
  },
  templateUrl:
    'reports/report/components/report-content/components/report-question/report-question.html',
  controller: class ReportQuestionController {
    $onInit() {
      this.answers = this.report.contents.answers.filter((a) =>
        this.getQuestionAnswers(a)
      );
      this.reportId = this.report._id;
    }

    getQuestionAnswers(answer) {
      return (
        answer.nodes_ids[0] === this.nodeId &&
        answer.question_id === this.question._id
      );
    }
  },
};
