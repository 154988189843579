import { NewsfeedPostItemController } from '../newsfeed-post-item.controller';

export const NewsfeedPostItemWidgetComponent = {
  bindings: {
    post: '<',
    authorType: '@',
    hideActions: '<?',
    disableClick: '<?',
    profile: '<',
    onEditPost: '&',
    onReload: '&',
    isChatLoading: '=',
    isWidget: '&',
  },
  templateUrl:
    'newsfeed/components/newsfeed-post-item/widget/newsfeed-post-item-widget.html',
  controller: NewsfeedPostItemController,
};
