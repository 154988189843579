import { SfFeatureFlags } from '@simplifield/feature-flags';
import { intersection } from 'ramda';
import type {
  CustomParam,
  CustomParamAPI,
  CustomParameter,
  CustomParamType,
  IAPIList,
  ObjectId,
  PlacesEntity,
} from '../../..';
import { CategorizableResourceType } from '../../../categories/services/categories-api/categories.api.factory';
import {
  CategoriesFactory,
  CategoriesService,
} from '../../../categories/services/categories/categories.factory';
import { ButtonSelectorOption } from '../../../components/Buttons/button-selector/button-selector.controller';
import type { FilterCustomParam } from '../../../components/Search/search';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import { DataAccessLayerService } from '../../Utils/CRUD/crud-service';
import { CrudFactory } from '../../Utils/CRUD/crud-service.factory';
import { LogService } from '../../Utils/log/log.service';
import { PovService } from '../POV/pov.service';

export type ParamWithFormattedValue = CustomParamAPI<'places'> & {
  name: string;
  value: string;
};
export type CategorizedParam = {
  label: string;
  params: ParamWithFormattedValue[];
};

export const CUSTOM_PARAM_TYPES: Record<string, CustomParamType> = {
  SINGLE: 'SingleChoice',
  MULTIPLE: 'MultipleChoice',
  DATE: 'date',
  NUMBER: 'number',
  STRING: 'string',
};

export type ForceCastParam = {
  value: string;
  forceCast: 'date' | 'number';
};

export class PlacesParamsService {
  crud: DataAccessLayerService<CustomParamAPI<PlacesEntity>>;
  pathName = '/customParams/places';
  categoriesService: CategoriesService;

  constructor(
    private $http: ng.IHttpService,
    crudFactory: CrudFactory<CustomParamAPI<PlacesEntity>>,
    private sfPOVService: PovService,
    databaseSchema,
    private categoriesFactory: CategoriesFactory,
    private $translate: ng.translate.ITranslateService,
    private dateFormatService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private logService: LogService
  ) {
    'ngInject';
    const tableConfig = databaseSchema.tables.place_params;

    this.crud = crudFactory(tableConfig.table_name, {
      path_name: this.pathName,
      take_care_of_user_profile: false,
    }) as DataAccessLayerService<CustomParamAPI<PlacesEntity>>;

    this.crud.registerHook('listApi:after', this.setNameForIds);

    this.categoriesService = this.categoriesFactory(
      CategorizableResourceType.PLACES_PARAMS
    );
  }

  loadCategories(): ng.IPromise<ButtonSelectorOption[]> {
    return this.categoriesService
      .getCategories()
      .then((categories) => {
        const paramsCategories = [
          {
            id: null as unknown as ObjectId, // this is a special case so unknown is ok
            label: this.$translate.instant('PLACE_PARAM_EMPTY_CATEGORY'),
          },
          ...categories.map(({ _id, name }) => ({
            id: _id,
            label: name,
          })),
        ];
        return paramsCategories;
      })
      .catch(() => {
        return [];
      });
  }

  getCategorizedParams(
    customParams: IAPIList<CustomParamAPI<PlacesEntity>>,
    categories: ButtonSelectorOption[],
    placeParams: CustomParameter[]
  ): CategorizedParam[] {
    const sortViewPosition = (
      paramA: CustomParamAPI<PlacesEntity>,
      paramB: CustomParamAPI<PlacesEntity>
    ) => {
      return (
        (paramA.contents.viewPosition || 0) -
        (paramB.contents.viewPosition || 0)
      );
    };
    const addParamValue = this.addParamValue(placeParams);
    const paramsWithCategories: ParamWithFormattedValue[] = [];
    const paramsWithOutCategories: ParamWithFormattedValue[] = [];
    customParams.entries.forEach((param) => {
      const hasCategory =
        param.contents.category_id &&
        categories.some(({ id }) => id === param.contents.category_id);
      const paramWithValue = addParamValue(param);
      if (hasCategory) {
        paramsWithCategories.push(paramWithValue);
        return;
      }
      paramsWithOutCategories.push(paramWithValue);
    });

    const categorizedParams: CategorizedParam[] = [];

    categories.forEach((cat, i) => {
      if (!cat.id && i === 0) {
        categorizedParams.push({
          label: cat.label,
          params: paramsWithOutCategories.sort(sortViewPosition),
        });
        return;
      }
      const params = paramsWithCategories
        .filter((param) => param.contents.category_id === cat.id)
        .sort(sortViewPosition);

      categorizedParams.push({ label: cat.label, params });
    });
    return categorizedParams.filter(
      (categorizedParams) => categorizedParams.params.length !== 0
    );
  }

  private addParamValue(placeParams: CustomParameter[]) {
    return (customParam: CustomParamAPI<PlacesEntity>) => {
      const placeParam = placeParams.find(
        (p) => p.param_id === customParam._id
      );
      const formattedValue = this.formatParamValueToString(
        customParam.contents,
        placeParam?.value
      );
      return {
        ...customParam,
        name: customParam.contents.externalKey,
        value: formattedValue,
      };
    };
  }

  formatParamValueToString(
    param: CustomParam<PlacesEntity>,
    paramValue?: CustomParameter['value']
  ): string {
    if (!paramValue || !param) return '';

    let formattedValue;
    switch (param.type) {
      case CUSTOM_PARAM_TYPES.SINGLE:
        formattedValue = param.predefinedValues?.find(
          ({ id }) => id === paramValue
        )?.value as string;
        break;
      case CUSTOM_PARAM_TYPES.MULTIPLE:
        formattedValue = param.predefinedValues
          ?.filter(({ id }) => (paramValue as string[]).includes(id))
          .map(({ value }) => value)
          .join(', ') as string;
        break;
      case CUSTOM_PARAM_TYPES.DATE:
        formattedValue = this.dateFormatService.getEventParamDateFormatted(
          new Date(paramValue as string)
        );
        break;
      case CUSTOM_PARAM_TYPES.NUMBER:
      case CUSTOM_PARAM_TYPES.STRING:
      default:
        formattedValue = paramValue;
        break;
    }

    return formattedValue;
  }

  hasFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEW_PARAMS_TYPES
    );
  }

  hasAlphaFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEW_PARAMS_TYPES_ALPHA
    );
  }

  areNewTypesAllowed(): boolean {
    return this.hasAlphaFeatureFlag() || this.hasFeatureFlag();
  }

  filterLocalParams(
    filter: {
      param_id: string;
      value?: string | { value: string; type: string };
    },
    value: { param_id: string; value: string }
  ): boolean {
    if (Array.isArray(filter.value) && Array.isArray(value.value)) {
      return (
        value.param_id === filter.param_id &&
        Boolean(intersection(filter.value, value.value).length)
      );
    }
    if (filter.value) {
      if (typeof filter.value === 'object' && filter.value.value) {
        if (filter.value.type === 'number') {
          return (
            value.param_id === filter.param_id &&
            value?.value === filter.value.value
          );
        }

        return (
          value.param_id === filter.param_id &&
          value?.value?.toLowerCase().includes(filter.value.value.toLowerCase())
        );
      }

      return (
        value.param_id === filter.param_id &&
        value?.value
          ?.toLowerCase()
          .includes((filter.value as string).toLowerCase())
      );
    }

    return value.param_id === filter.param_id;
  }

  // ---------------
  //
  //     Hooks
  //
  // ---------------
  /**
   * Add new infos on place params data.
   * @param {Array} params - Hook datas
   * @return {Array}       - Datas modified
   */
  setNameForIds(params) {
    params.entries = params.entries.map((refreshData) => {
      refreshData.id = refreshData.name;
      return refreshData;
    });
    return params;
  }

  // ------------------
  //
  //    Place Params
  //
  // ------------------
  listPlaceParamsKeys(): ng.IPromise<IAPIList<CustomParamAPI<PlacesEntity>>> {
    const url = this.pathName;
    const useUserProfile = false;
    const queryParams: Record<string, unknown> = {};
    queryParams['withNewTypes'] = true;

    return this.crud.simpleApiList(url, queryParams, useUserProfile, {
      pov: 'organisation',
    });
  }

  listFiltersParams(): ng.IPromise<FilterCustomParam[]> {
    this.logService.info(
      `[BUGS-2566] places-params.service.ts | listFiltersParams`,
      {},
      true
    );
    return this.listPlaceParamsKeys()
      .catch(() => this.crud.listLocal().then((list) => ({ entries: list })))
      .then((params) => {
        return params.entries.map((p) => {
          return {
            id: p._id || p.id,
            type: p.contents.type,
            label: p.contents.label.en,
            values: p.contents.predefinedValues,
          };
        });
      });
  }

  getParamById(idToFind: string): ng.IPromise<FilterCustomParam | undefined> {
    return this.listFiltersParams().then((params) => {
      return params.find(({ id }) => idToFind === id);
    });
  }

  apiListAsOrg() {
    const queryParams = {
      withNewTypes: true,
    };

    return this.crud
      .apiList(queryParams, this.pathName, {
        pov: 'organisation',
      })
      .then((res) => {
        return res;
      });
  }

  static buildCustomParamFilters(
    filters,
    placesParamsHash: Record<string, FilterCustomParam>
  ): Record<string, string | ForceCastParam> {
    return filters.reduce((output, filter) => {
      let value = filter.value;
      if (
        placesParamsHash[filter.id]?.values &&
        filter.paramType === 'SingleChoice'
      ) {
        const filteredPredefinedValue = placesParamsHash[
          filter.id
        ]?.values?.find(({ id }) => id === filter.value);
        value = filteredPredefinedValue?.id || '';
      }
      if (
        placesParamsHash[filter.id]?.values &&
        filter.paramType === 'MultipleChoice'
      ) {
        const filterPredefinedValues = placesParamsHash[
          filter.id
        ]?.values?.filter(
          ({ id }) =>
            id === filter.value.find((predefinedId) => predefinedId === id)
        );
        value = filterPredefinedValues?.map(({ id }) => id) || [];
      }
      if (placesParamsHash[filter.id] && filter.paramType === 'date') {
        value = {
          value: filter.value,
          type: 'date',
        };
      }
      if (placesParamsHash[filter.id] && filter.paramType === 'number') {
        value = {
          value: filter.value,
          type: 'number',
        };
      }
      output[filter.id] = value;
      return output;
    }, {});
  }

  // ------------------
  //
  //  Modifier this
  //
  // ------------------
  makeRequest(requestType, url, datas?): ng.IPromise<any> {
    return this.sfPOVService
      .pBuildURL(url)
      .then((povUrl) => this.$http[requestType](povUrl, datas))
      .then((res) => res.data);
  }
  getParamUrl(placeId: ObjectId, paramId?: ObjectId): string {
    let url = '/places/' + placeId + '/places_params/';

    if (paramId) {
      url += paramId;
    }
    return url;
  }
}
