export const PlaceAddressSuggestComponent: ng.IComponentOptions = {
  bindings: {
    suggestedAddress: '@',
    inputtedAddress: '@',
    onInputtedAction: '<',
    onSuggestedAction: '<',
    onClose: '<',
    afterClose: '<',
  },
  templateUrl:
    'places/place/components/place-address-suggest/place-address-suggest.component.html',
  controller: class PlaceAddressSuggestController {
    onInputtedAction: () => void;
    onSuggestedAction: () => void;
    onClose: () => void; // added implicitly by popup service and closes popup
    afterClose: () => void; // added explicitly in place component/service

    // every popup button click should close a popup
    selectInputted(): void {
      this.onClose();
      this.onInputtedAction();
    }
    selectSuggested(): void {
      this.onClose();
      this.onSuggestedAction();
    }
    close(): void {
      this.onClose();
      this.afterClose();
    }
  },
};
