import { RecurrenceSelectorController as controller } from './recurrence-selector.controller';

export const RecurrenceSelector: ng.IComponentOptions = {
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    ngModel: '=',
    startDate: '<',
    ngDisabled: '<',
    onChange: '&',
  },
  controller,
  templateUrl:
    'calendar-events/components/recurrence-selector/recurrence-selector.html',
};
