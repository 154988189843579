import { Notification } from '../..';
import { ContentService } from '../../../components/Layout/content/content.service';
import { DateService } from '../../../services/Utils/Dates/date.service';
import { NotificationsApiService } from '../../services/notifications-api/notifications-api.service';
import { NotificationsService } from '../../services/notifications/notifications.service';

export class NotificationsListController implements ng.IComponentController {
  onItemRedirect: () => void;
  requestPaginate: any;
  notifications: Notification[] = [];
  lastDisplayNotificationDate: Date | null;
  infiniteLoadError = false;
  isLoading = true;
  loadError = null;
  scrollEl: IonicV1.IonicContent;
  isBrowser = false;

  // eslint-disable-next-line max-params
  constructor(
    private RequestsPaginate,
    private notificationsApiService: NotificationsApiService,
    private notificationsService: NotificationsService,
    private dateService: DateService,
    private contentService: ContentService
  ) {
    'ngInject';
  }
  $onInit(): ng.IPromise<void> {
    this.scrollEl = this.contentService.getScrollsById(
      'activityFeedContent'
    )[0];
    this.requestPaginate = new this.RequestsPaginate(
      (params) =>
        this.notificationsApiService.list({
          ...params,
        }),
      { limit: 20 }
    );
    this.lastDisplayNotificationDate =
      this.notificationsService.getLastDislayNotificationDate();
    this.initNotifications();

    return this.callNotifications();
  }

  initNotifications(): void {
    this.loadError = null;
    this.notifications = [];
    this.infiniteLoadError = false;
    this.requestPaginate.reset();
  }

  callNotifications(): ng.IPromise<void> {
    const scrollTop = this.getViewScrollTop();

    this.isLoading = true;
    this.infiniteLoadError = false;

    return this.requestPaginate
      .call(undefined, true)
      .then(({ entities }: { entities: Notification[] }) => {
        this.notifications = entities;
        this.notificationsService.setLastDislayNotificationDate(
          this.notifications[0]
        );
        return this.notifications;
      })
      .catch((err) => {
        this.loadError = err;
        throw err;
      })
      .finally(() => {
        this.isLoading = false;
        // bypass ionic v1 infinite-scroll get stuck after first event on MOTW
        this.scrollToPos(scrollTop);
      });
  }

  isNewDisplayedNotification(notification: Notification): boolean {
    return (
      Boolean(this.lastDisplayNotificationDate) &&
      this.dateService.isGreaterThan(
        notification?.created_date ?? 0,
        this.lastDisplayNotificationDate || 0
      )
    );
  }

  getViewScrollTop(): number {
    return this.scrollEl?.getScrollPosition()?.top ?? 0;
  }

  scrollToPos(top: number): void {
    this.scrollEl && this.scrollEl.scrollTo(0, top, true);
  }
}
