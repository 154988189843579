import { Chip } from '@simplifield/webcomponents/dist/types/services/chips';
import {
  Filter,
  FilterValue,
} from '../../services/sales-dashboard-filters-service/sales-dashboard-filters.service';

type ExtendedChip = Chip & { id: string };

export class SalesDashboardFilterController {
  filters: Filter[];
  selectedValues: Record<string, FilterValue[]> = {};
  chips: ExtendedChip[];
  onFiltersChange: (selectedValues: Record<string, FilterValue[]>) => void;

  constructor(private modalService) {
    'ngInject';
    this.resetFilter = this.resetFilter.bind(this);
  }

  $onInit(): void {
    this.chips = this.generateChips();
  }

  generateChips(): ExtendedChip[] {
    if (this.selectedValues) {
      return Object.entries(this.selectedValues).reduce(
        (acc, [, selectedValue]) => {
          const res = selectedValue.map((el) => ({
            id: el._id,
            label: el.label,
            value: el.value,
          }));

          return acc.concat(res);
        },
        [] as ExtendedChip[]
      );
    }
    return [];
  }

  openFilterModal(): ng.IPromise<unknown> {
    const template = `
          <sf-sales-dashboard-filter-modal
            selected-values="$ctrl.selectedValues"
            filters="$ctrl.filters"
            on-change="$ctrl.onChange"
            on-close="$ctrl.onClose"
          </sf-sales-dashboard-filter-modal>
        `;
    const bindings = {
      filters: this.filters,
      selectedValues: this.selectedValues,
      onChange: (selectedValues: Record<string, FilterValue[]>) =>
        this.onChange(selectedValues),
    };
    const options = {
      animation: 'slide-in-top',
    };

    return this.modalService.open(template, bindings, options);
  }

  onChange(selectedValues: Record<string, FilterValue[]>): void {
    this.selectedValues = selectedValues;
    this.onFiltersChange(this.selectedValues);
    this.chips = this.generateChips();
  }

  resetFilter(value: unknown, index: number): void {
    const id = this.chips[index].id;
    const newSelectedValue: Record<string, FilterValue[]> = {};

    Object.keys(this.selectedValues).forEach((key) => {
      newSelectedValue[key] = this.selectedValues[key].filter(
        (value) => value._id !== id
      );
    });

    this.onChange(newSelectedValue);
  }
}
