import {
  defaultTo,
  pipe,
  props,
  join,
  trim,
  always,
  has,
  hasPath,
} from 'ramda';
import { APIStoreContents } from '../../../places';

const DEFAULT_LAT = 0;
const DEFAULT_LNG = 0;

export const CITY_PROPERTY_PRIORITY = [
  'locality',
  'postal_town',
  'sublocality',
  'sublocality_level_1',
];

type AddressFormatted = {
  address: string;
  latitude?: number;
  longitude?: number;
  types?: string[];
  postal_code?: string;
  country?: string;
  administrative_area_level_1?: string;
  locality?: string;
  postal_town?: string;
  sublocality?: string;
  sublocality_level_1?: string;
};

export class GoogleAddressService {
  setPlaceAddressProperties(
    address: AddressFormatted
  ): Partial<APIStoreContents> {
    const orEmptyString = defaultTo('');
    const humanReadable = pipe(
      props(['street_number', 'route']) as () => string[],
      join(' '),
      trim
    );

    return {
      formatted_address: orEmptyString(address.address),
      country: orEmptyString(address.country),
      city: pipe(
        always(this.getPropertyByPriority(CITY_PROPERTY_PRIORITY, address)),
        orEmptyString
      )(),
      latLng: [
        defaultTo(DEFAULT_LAT, address.latitude),
        defaultTo(DEFAULT_LNG, address.longitude),
      ],
      street: (
        pipe(humanReadable, orEmptyString) as (address: unknown) => string
      )(address),
      zipcode: orEmptyString(address.postal_code),
      administrative_area: orEmptyString(address.administrative_area_level_1),
    };
  }

  getPropertyByPriority(
    priorityKeys: string[],
    address: AddressFormatted
  ): string | undefined {
    const addressField = priorityKeys.find((prop) => has(prop, address));

    return addressField && address[addressField];
  }

  formatAddressFromGoogle(
    gmapsData: google.maps.places.PlaceResult
  ): AddressFormatted {
    const addressFormated: AddressFormatted = {
      address: gmapsData.formatted_address ?? '',
    };

    if (hasPath(['geometry', 'location'])(gmapsData)) {
      addressFormated.latitude = gmapsData.geometry?.location?.lat();
      addressFormated.longitude = gmapsData.geometry?.location?.lng();
    }
    if (hasPath(['address_components'])(gmapsData)) {
      gmapsData.address_components?.forEach((component) => {
        const type = component.types[0];

        addressFormated[type] = component.long_name;
      });
    }
    if (hasPath(['types'])(gmapsData)) {
      addressFormated.types = gmapsData.types;
    }
    return addressFormated;
  }
}
