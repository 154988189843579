import { StateService, TransitionPromise } from '@uirouter/angularjs';
import { User, IAPIResource, ObjectId } from '../../../../index';
import { APIStore } from '../../../';
import {
  Report,
  ReportCampaignContents,
  ReportFormContents,
} from '../../../../services/API/reports/reports';
import { FormContents } from '../../../../missions/form/types';
import {
  Campaign,
  CampaignContents,
  Tag,
} from '../../../../services/API/campaigns/campaigns';
import { Identified } from '../../../../services/Utils/CRUD/crud-service';
import { TranslationDictionnary } from '../../../../services/Utils/sfTranslation/sf-translation.filters';
import { ReportsService } from '../../../../services/API/reports/reports.service';
const NB_REPORT_TO_GET = 3;
const DEFAULT_STATUS_MAP: Tag[] = [
  {
    key: 'done',
    label: 'STATUS_REPORT_DONE',
  },
];

export class PlaceReportsController {
  // bindings
  place: APIStore;
  useNewCampaigns: boolean;

  // class members
  forms: Record<ObjectId, FormContents & { i18n: TranslationDictionnary }>;
  campaigns: Record<string, Identified<Campaign>>;
  users: User;
  reports: Record<string, Report>;
  isLoading: boolean;
  requestPaginate: {
    call: (...args: unknown[]) => ng.IPromise<any>;
    getRelatedEntities: (arg: string) => any;
  };
  hasError = false;

  // eslint-disable-next-line max-params
  constructor(
    private $state: StateService,
    private apiUtilsService,
    private reportsService: ReportsService,
    private RequestsPaginate
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    this.requestPaginate = new this.RequestsPaginate(
      this.reportsService.crud.simpleApiList.bind(
        this.reportsService.crud,
        undefined
      ),
      {
        limit: NB_REPORT_TO_GET,
        relatedEntitiesKeys: ['forms', 'users', 'campaigns'],
      }
    );

    return this.getReports();
  }

  getReports(): ng.IPromise<void> {
    const filterParams = this.composeFilterParamsReq();
    const reqParams = {
      ...filterParams,
      place_id: this.place._id,
      'sorts[]': '-saved.seal_date',
      limit: NB_REPORT_TO_GET,
      ...(this.useNewCampaigns
        ? { fromCampaign: Boolean(this.useNewCampaigns) }
        : {}),
    };

    this.isLoading = true;

    return this.requestPaginate
      .call(reqParams, true)
      .then(({ entities }) => {
        this.reports = entities;
        this.forms = this.requestPaginate.getRelatedEntities('forms');
        this.campaigns = this.requestPaginate.getRelatedEntities('campaigns');
        this.users = this.requestPaginate.getRelatedEntities('users');
        return entities;
      })
      .catch((error) => {
        this.hasError = true;
        throw error;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  composeFilterParamsReq(): Record<string, unknown> {
    const filters = [
      {
        name: 'report_state',
        value: 'empty',
        operator: '$ne',
      },
    ];

    return this.apiUtilsService.buildFilterParams(filters);
  }

  goToReport(
    report: IAPIResource<Report>,
    targetTab: string | null = null
  ): void | TransitionPromise {
    if (report.contents.state === 'empty') {
      return;
    }

    const routeName = this.useNewCampaigns
      ? 'index.menu-more.reactive-campaigns.report'
      : 'index.menu-more.missions.report';

    return this.$state.go(routeName, {
      campaignId: report.contents.form_id || report.contents.campaign_id,
      reportId: report._id,
      online: true,
      targetTab,
    });
  }

  goToReportsList(): TransitionPromise {
    if (this.useNewCampaigns) {
      return this.$state.go('index.menu-more.reactive-campaigns.reports', {
        placeId: this.place._id,
      });
    }
    return this.$state.go('index.places.details.reports');
  }

  getCampaign(report: Report): Identified<Campaign> {
    return this.campaigns[
      (report.contents as ReportCampaignContents).campaign_id
    ];
  }

  getCampaignName(report: Report): string {
    const campaign = this.getCampaign(report);

    return campaign?.contents?.name;
  }

  getTagsMap(report: Report): Tag[] {
    const campaign = this.getCampaign(report);

    return campaign?.contents?.tagsMap ?? DEFAULT_STATUS_MAP;
  }

  getCampaignTranslations(report: Report): TranslationDictionnary | undefined {
    const campaign = this.getCampaign(report);

    return campaign?.i18n;
  }

  getCommentsNumber(report: Report): number {
    return (
      (report.statisticsDigest && report.statisticsDigest.commentsCount) || 0
    );
  }

  getUser(report): User | undefined {
    return this.users[report.contents.user_id] as User | undefined;
  }
}
