export const MerchandisingPhotoSliderComponent = {
  bindings: {
    pictureIds: '<',
  },
  templateUrl:
    'merchandising/components/merchandising-photo-slider/merchandising-photo-slider.html',
  controller: function MerchandisingPhotoSliderController(
    imageService,
    merchandisingModalService,
    SF_IMAGE_SIZES
  ) {
    'ngInject';

    this.$onInit = () => {
      this.picturesPath = {};
      this.pictureIds.forEach((id) =>
        imageService
          .getSizesHashFromId(id, [
            SF_IMAGE_SIZES.SQUARE_LARGE,
            SF_IMAGE_SIZES.SQUARE_BIG,
          ])
          .then((urls) => {
            this.picturesPath[id] = {
              SQUARE_LARGE: urls[SF_IMAGE_SIZES.SQUARE_LARGE],
              SQUARE_BIG: urls[SF_IMAGE_SIZES.SQUARE_BIG],
            };
          })
          .catch(() => null)
      );

      return this.picturesPath;
    };

    this.viewPicture = (pictureId) =>
      merchandisingModalService.openPictureModal(
        pictureId,
        this.picturesPath[pictureId].SQUARE_BIG
      );
  },
};
