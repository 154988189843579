import type { FilesService } from '../../API/files/files.service';
import type { ImageService } from '../Image/image.service';

export type ImageSource = {
  id: string;
  type?: string;
  url?: string;
  requireAuth: boolean;
  getDownloadUrl: () => ng.IPromise<string>;
};

export class ImageSourceService {
  constructor(
    private $q: ng.IQService,
    private filesService: FilesService,
    private imageService: ImageService
  ) {
    'ngInject';
  }

  create(
    file: { id: string; _id?: string; url?: string; type?: string },
    isDocument = false,
    requireAuth?: boolean
  ): ImageSource {
    // some files like form files have ObjectId string stored in  ._id, and some have .id
    // I don't really know how many entites could have ._id since there are many untyped js files,
    // so this litle hack should help and won't break anything
    const fileId = file._id || file.id;
    const imageSource = {
      ...file,
      requireAuth:
        typeof requireAuth !== 'undefined' ? requireAuth : isDocument,
      getDownloadUrl: () => {
        if (file.url) {
          return this.$q.resolve(file.url);
        }

        return isDocument
          ? this.filesService.getUrl(fileId)
          : this.imageService.getImgAssetUrlById(fileId);
      },
    };

    imageSource.id ??= fileId; // New shiny ts operator - id will be assigned only if it's not assigned yet
    return imageSource;
  }

  createFromChatMessage(message: {
    messageId: string;
    url: string;
  }): ImageSource {
    return {
      ...message,
      id: message.messageId,
      url: message.url,
      requireAuth: false,
      getDownloadUrl: () => this.$q.resolve(message.url),
    };
  }
}
