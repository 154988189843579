'use strict';

export default function routeConfig($stateProvider) {
  'ngInject';
  // Routes
  $stateProvider
    .state('index.menu-more.reports', {
      url: '/reports',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('index.menu-more.reports.validation', {
      url: '/validation',
      template: `<ion-view>
        <sf-view sf-tab-bar-hide="true">
          <sf-nav-bar
            display-back-button="true"
            title="Reports validation">
          </sf-nav-bar>
          <sf-reports-validation-tabs
            profile="$ctrl.profile"
            organisation="$ctrl.organisation"
          ></sf-reports-validation-tabs>
        </sf-view>
      </ion-view>`,
      controller: function ($state, rsvpProfile, rsvpOrganisation) {
        'ngInject';

        this.profile = rsvpProfile;
        this.organisation = rsvpOrganisation;
      },
      controllerAs: '$ctrl',
      analyticsName: 'Reports validation',
    });
}
