const validationStatusMap = {
  DONE: {
    label: 'REPORTS_VALIDATION_TABS_VALIDATED',
    color: 'balanced',
  },
  TOREVISE: {
    label: 'REPORTS_VALIDATION_STATE_REJECTED',
    color: 'assertive',
  },
  TOREVIEW: {
    label: 'REPORTS_VALIDATION_STATE_PENDING',
    color: 'energized',
  },
};

export const ReportValidationStatusComponent = {
  bindings: {
    displayValidationSteps: '<?',
    validationStatus: '<',
    validationStep: '<',
  },
  templateUrl:
    'reports/components/report-validation-status/report-validation-status.html',
  controller: class ReportValidationStatusController {
    constructor($translate) {
      'ngInject';
      this.$translate = $translate;
    }

    $onInit() {
      if (!this.validationStatus) return;

      this.color = this.getStatusColor(this.validationStatus);
      this.statusLabel = this.getStatusLabel(this.validationStatus);

      if (this.validationStatus === 'TOREVIEW') {
        this.statusLabel = this.validationStep.label;
      }
    }

    getStatusColor(status) {
      return validationStatusMap[status] && validationStatusMap[status].color;
    }

    getStatusLabel(status) {
      return (
        validationStatusMap[status] &&
        this.$translate.instant(validationStatusMap[status].label)
      );
    }
  },
};
