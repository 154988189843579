import { PlaceController } from './place.controller';

export const PlaceComponent = {
  bindings: {
    place: '<',
    organisation: '<',
    profile: '<',
    targetTab: '<',
  },
  templateUrl: 'places/place/place.html',
  controller: PlaceController,
};
