import { WeekDaySelectorController as controller } from './weekday-selector.controller';

export const WeekDaySelector: ng.IComponentOptions = {
  require: {
    ngModelCtrl: 'ngModel',
  },
  transclude: true,
  bindings: {
    ngModel: '=',
  },
  controller,
  templateUrl:
    'calendar-events/components/recurrence-selector/internal/weekday-selector/weekday-selector.html',
};
