"use strict";

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Report',
  description: 'A form realization.',
  type: 'object',
  additionalProperties: false,
  required: ['type', 'state', 'nodes', 'answers', 'organisation_id', 'owner_id', 'form_id', 'users_ids'],
  properties: {
    type: {
      type: 'string',
      "enum": ['import', 'mission', 'share']
    },
    state: {
      type: 'string'
    },
    brief: {
      type: 'string'
    },
    latLng: schemaUtils.latLng(),
    precision: {
      type: 'string'
    },
    distance: {
      type: 'number'
    },
    nodes: {
      type: 'array',
      items: {
        title: 'ReportAnswer',
        type: 'object',
        additionalProperties: false,
        required: ['_id', 'sections_ids', 'parents_ids'],
        properties: {
          _id: schemaUtils.oneToOne(),
          sections_ids: schemaUtils.oneToSeveral(),
          parents_ids: schemaUtils.oneToSeveral()
        }
      }
    },
    answers: {
      type: 'array',
      items: {
        title: 'ReportAnswer',
        type: 'object',
        additionalProperties: false,
        required: ['_id', 'question_id', 'sections_ids', 'nodes_ids', 'type', 'values'],
        properties: {
          _id: schemaUtils.oneToOne(),
          question_id: schemaUtils.oneToOne(),
          sections_ids: schemaUtils.oneToSeveral(),
          nodes_ids: schemaUtils.oneToSeveral(),
          type: schemaUtils.questionType(),
          metadata: {
            type: 'object',
            additionalProperties: true
          },
          values: {
            type: 'array',
            items: [{
              title: 'ReportValue',
              type: 'object',
              additionalProperties: false,
              required: ['field_id', 'value'],
              properties: {
                field_id: schemaUtils.oneToOne(),
                value: schemaUtils.fieldValue()
              }
            }]
          }
        }
      }
    },
    organisation_id: schemaUtils.oneToOne(),
    owner_id: schemaUtils.oneToOne(),
    form_id: schemaUtils.oneToOne(),
    missionsGroup_id: schemaUtils.oneToOne(),
    user_id: schemaUtils.oneToOne(),
    users_ids: schemaUtils.oneToSeveral(),
    place_id: schemaUtils.oneToOne(),
    tasks_ids: schemaUtils.oneToSeveral(),
    comments_ids: schemaUtils.oneToSeveral()
  }
};