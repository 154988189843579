import { PlacesService } from './places.service';

export default angular
  .module('sfMobile.services.places', [
    'sfMobile.services.translateNumbers',
    'sfMobile.services.localization',
    'sfMobile.services.crud',
    'sfMobile.services.pov',
    'sfMobile.services.helpers',
    'sfMobile.constants.unit-system',
    'sfMobile.constants.image-sizes',
    'sfMobile.services.data-source-adapter',
    'sfMobile.services.remote-stores',
  ])
  .factory('placesService', PlacesService).name;
