import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import { GoogleMapProvider } from './google-map.service';

const GOOGLE_MAP_CLUSTER_PATH = 'img/map-cluster.png';
const LABEL_OPTIONS = {
  fontSize: '14px',
  color: 'white',
};

export class MapsMarkerClusterer {
  getClusterer(
    map: google.maps.Map,
    markers: google.maps.Marker[],
    api: GoogleMapProvider
  ): MarkerClusterer {
    const renderer = {
      render: ({ count, position }: Cluster) =>
        new api.Marker({
          label: {
            text: String(count),
            color: LABEL_OPTIONS.color,
            fontSize: LABEL_OPTIONS.fontSize,
          },
          icon: {
            url: GOOGLE_MAP_CLUSTER_PATH,
            scaledSize: new api.Size(40, 40),
          },
          position,
          // adjust zIndex to be above other markers
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        }),
    };

    return new MarkerClusterer({ map, markers, renderer });
  }
}
