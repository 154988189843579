export const PopupSelectComponent = {
  bindings: {
    onSuccess: '&',
    onCancel: '&',
    options: '<',
  },
  templateUrl: 'components/Popup/popup-select/popup-select.html',
  controller: class PopupSelectController {
    acceptSelect() {
      this.onSuccess({ $event: this.options.value });
    }
  },
};
