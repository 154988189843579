import { pathEq } from 'ramda';

import { AppMessageService } from '../AppMessage/app-message.service';

type ErrorCode = string | number | undefined;
export type SfError = {
  data?: {
    code?: string;
  };
  message?: ErrorCode;
  status?: number;
};

export class ErrorMessagesService {
  /* @ngInject */
  constructor(
    private $translate: ng.translate.ITranslateService,
    private appMessageService: AppMessageService,
    private SF_ERROR_CODES
  ) {}

  /*
   * Helpers
   */

  isReportErrorCode(errorCode: ErrorCode): boolean {
    return errorCode === this.SF_ERROR_CODES.CODE_SERVER_BAD_REQUEST;
  }
  isServerBadRequestCode(errorCode: ErrorCode): boolean {
    return errorCode === this.SF_ERROR_CODES.CODE_SERVER_BAD_REQUEST;
  }
  isConflictErrorCode(errorCode: ErrorCode): boolean {
    return errorCode === this.SF_ERROR_CODES.CODE_SERVER_CONFLICT_REQUEST;
  }
  isNoNetworkCode(errorCode: ErrorCode): boolean {
    return (
      this.SF_ERROR_CODES.CODES_SERVER_NO_NETWORK.indexOf(errorCode) !== -1
    );
  }
  isServerErrorCode(errorCode: ErrorCode): boolean {
    if (errorCode) {
      return errorCode >= this.SF_ERROR_CODES.CODE_SERVER_ERROR;
    }
    return false;
  }
  isNotFound(errorCode: ErrorCode): boolean {
    if (errorCode) {
      return errorCode >= this.SF_ERROR_CODES.CODE_SERVER_NOT_FOUND;
    }
    return false;
  }
  isUnauthorizedCode(errorCode: ErrorCode): boolean {
    return errorCode === this.SF_ERROR_CODES.CODE_SERVER_UNAUTHORIZED;
  }
  isFileNotFound(errorCode: ErrorCode): boolean {
    return errorCode === this.SF_ERROR_CODES.LOCAL_FILE_NOT_FOUND;
  }
  isOutOfSpaceError(errorCode: ErrorCode): boolean {
    return errorCode === this.SF_ERROR_CODES.OUT_OF_SPACE;
  }
  isFileNotFoundError(errorCode: ErrorCode): boolean {
    return errorCode === this.SF_ERROR_CODES.FILE_NOT_FOUND;
  }
  isNotConnectedError(errorCode: ErrorCode): boolean {
    return errorCode === this.SF_ERROR_CODES.NO_CONNECTION;
  }
  isUnsupportedFileType(errorCode: ErrorCode): boolean {
    return errorCode === this.SF_ERROR_CODES.UNSUPPORTED_FILE_TYPE;
  }

  hasHttpCode(error: SfError | Record<string, never> = {}): boolean {
    return !!(error && error.data && error.data.code);
  }
  isReportFileNotFoundError(
    error: SfError | Record<string, never> = {}
  ): boolean {
    return this.isFileNotFound(error.message);
  }
  isReportError(error: SfError | Record<string, never> = {}): boolean {
    return this.isReportErrorCode(error.status);
  }
  isNetworkError(error: SfError | Record<string, never> = {}): boolean {
    return this.isNoNetworkCode(error.status);
  }
  isServerError(error: SfError | Record<string, never> = {}): boolean {
    return this.isServerErrorCode(error.status);
  }
  isServerBadRequest(error: SfError | Record<string, never> = {}): boolean {
    return this.isServerBadRequestCode(error.status);
  }
  isUnauthorizedError(error: SfError | Record<string, never> = {}): boolean {
    return this.isUnauthorizedCode(error.status);
  }
  isConflictError(error: SfError | Record<string, never> = {}): boolean {
    return this.isConflictErrorCode(error.status);
  }
  isCriticalError(error: SfError | Record<string, never> = {}): boolean {
    return !(this.isNetworkError(error) || this.isServerError(error));
  }

  /*
   * Methods
   */

  isValidationError(error: SfError | Record<string, never> = {}): boolean {
    return pathEq('E_FORBIDDEN_STATUS_TRANSITION', ['data', 'code'])(error);
  }
  isNewCampaignValidationError(
    error: SfError | Record<string, never> = {}
  ): boolean {
    return pathEq('E_FORBIDDEN_STATUS_TRANSITION_CHECKLISTS', ['data', 'code'])(
      error
    );
  }

  getMessage(
    error: SfError,
    config: { customUnknownErrorMessage?: string } = {}
  ): string {
    const getErrorMessage = () => {
      if (this.hasHttpCode(error)) {
        return error?.data?.code;
      }
      if (this.isNetworkError(error)) {
        return 'ERROR_MESSAGE_NETWORK_DESC';
      }
      if (config.customUnknownErrorMessage) {
        return config.customUnknownErrorMessage;
      }
      return 'COMMON_ERROR';
    };

    return this.$translate.instant(getErrorMessage() as string);
  }

  getFileErrorMessageConfig(errorCode: string | number): {
    title: string;
    desc: string;
  } {
    if (this.isOutOfSpaceError(errorCode)) {
      return {
        title: this.$translate.instant('FILE_SPACE_SATURATED_TITLE'),
        desc: this.$translate.instant('FILE_SPACE_SATURATED_MESSAGE'),
      };
    }
    if (this.isFileNotFoundError(errorCode)) {
      return {
        title: this.$translate.instant('FILE_NOT_FOUND_TITLE'),
        desc: this.$translate.instant('FILE_NOT_FOUND_DESC'),
      };
    }
    if (this.isNotConnectedError(errorCode)) {
      return {
        title: this.$translate.instant('ERROR_MESSAGE_NETWORK_TITLE'),
        desc: this.$translate.instant('ERROR_MESSAGE_NETWORK_DESC'),
      };
    }
    if (this.isUnsupportedFileType(errorCode)) {
      return {
        title: this.$translate.instant('UNSUPPORTED_FILE_TYPE_TITLE'),
        desc: this.$translate.instant('UNSUPPORTED_FILE_TYPE_DESC'),
      };
    }
    return {
      title: this.$translate.instant('COMMON_ERROR'),
      desc: this.$translate.instant('FORM_UNKNOWN_ERROR'),
    };
  }

  /*
   * Depending of the error type it choose the best way to display its message
   */
  display(
    error: SfError,
    config: { inlineErrorMessageUpdater: (message: string) => void }
  ): void {
    return this.isCriticalError(error)
      ? config.inlineErrorMessageUpdater(this.getMessage(error))
      : this.appMessageService.display(this.getMessage(error), 'fail');
  }
}
