import { Form, FormQuestion } from '../../API/forms/forms';
import { Report } from '../../API/reports/reports';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ReportQuestionsService(formsService) {
  'ngInject';
  const methods = {
    isMediaType,
    isProductScan,
    isRadioType,
    isCheckboxType,
    isSignatureType,
    isBarcodeType,
    isRatingType,
    isProductQuestion,
    isProductCount,
    isProductSales,
    isDocument,
    getQuestionTypeName,
  };

  function isMediaType(question: FormQuestion): boolean {
    return ['video', 'audio', 'image'].indexOf(question.type) !== -1;
  }
  function isDocument(question: FormQuestion): boolean {
    return question.type === 'document';
  }
  function isDate(question: FormQuestion): boolean {
    const firstField = question.fields[0];

    return firstField.type === 'date';
  }
  function isTime(question: FormQuestion): boolean {
    const firstField = question.fields[0];

    return firstField.type === 'time';
  }
  function isRadioType(
    question: FormQuestion,
    form: Form,
    report: Report,
    ctx
  ): boolean {
    const firstField = question.fields[0];
    const maximum = getMaximum(question, form, report, ctx);

    return Boolean(firstField.set && maximum === 1);
  }
  function isCheckboxType(
    question: FormQuestion,
    form: Form,
    report: Report,
    ctx
  ): boolean {
    const firstField = question.fields[0];
    const maximum = getMaximum(question, form, report, ctx);

    return Boolean(firstField.set && maximum !== 1);
  }
  function isProductScan(question: FormQuestion): boolean {
    const firstField = question.fields[0];

    return Boolean(
      firstField.type === 'ean' &&
        firstField.sources &&
        firstField.sources[0] === 'barcode'
    );
  }
  function isSignatureType(question: FormQuestion): boolean {
    return question.type === 'signature';
  }
  function isBarcodeType(question: FormQuestion): boolean {
    const firstField = question.fields[0];

    return Boolean(firstField.sources && firstField.sources[0] === 'barcode');
  }
  function isRatingType(question: FormQuestion): boolean {
    const firstField = question.fields[0];

    return Boolean(firstField?.type === 'number' && firstField?.multipleOf);
  }
  function isProductQuestion(question: FormQuestion): boolean {
    return isProductCount(question) || isProductSales(question);
  }
  function isProductCount(question: FormQuestion): boolean {
    return question.type === 'product-count';
  }
  function isProductSales(question: FormQuestion): boolean {
    return question.type === 'product-sales';
  }
  function isFormula(question: FormQuestion): boolean {
    return question.type === 'formula';
  }

  function getQuestionTypeName(
    question: FormQuestion,
    form: Form,
    report: Report,
    ctx
  ): string {
    const tests = [
      { type: 'image', test: isMediaType },
      { type: 'date', test: isDate },
      { type: 'time', test: isTime },
      { type: 'productScan', test: isProductScan },
      { type: 'questionRadio', test: isRadioType },
      { type: 'questionCheckbox', test: isCheckboxType },
      { type: 'signature', test: isSignatureType },
      { type: 'barcode', test: isBarcodeType },
      { type: 'rating', test: isRatingType },
      { type: 'productCount', test: isProductCount },
      { type: 'productSales', test: isProductSales },
      { type: 'formula', test: isFormula },
      { type: 'document', test: isDocument },
    ];
    const typeFound = tests.reduce(
      (type, test) =>
        type || (test.test(question, form, report, ctx) ? test.type : null),
      null as unknown // prevent some weird ts error msg
    ) as string;

    return typeFound || 'simple';
  }
  function getMaximum(question: FormQuestion, form: Form, report: Report, ctx) {
    return formsService.computeMaximum(
      question,
      form,
      report.contents.answers,
      ctx
    );
  }

  return methods;
}
