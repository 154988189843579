export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 17,
    method: updateMethod,
  });
  // this script just recreates places/placesparams/users tables after new custom params arrived

  // @ngInject
  function updateMethod(sqlStorageService, localStorageService, sentryService) {
    const placesFields = [
      'id NVARCHAR(32) PRIMARY KEY UNIQUE',
      'payload TEXT',
      'name TEXT',
      'distance DOUBLE',
      'isAssigned INTEGER DEFAULT 0',
      'isTemporaryVisible INTEGER DEFAULT 0',
      'isTerritoryScope INTEGER DEFAULT 0',
    ].join(',');
    const usersFields = [
      'id NVARCHAR(32) PRIMARY KEY UNIQUE',
      'payload TEXT',
      'organisation_id NVARCHAR(32)',
      'owner_id NVARCHAR(32)',
    ].join(',');
    const placesParamsFields = [
      'id NVARCHAR(32) PRIMARY KEY UNIQUE',
      'payload TEXT',
    ].join(',');

    const dropTablePlacesParams = 'DROP TABLE IF EXISTS place_params';
    const dropTablePlaces = 'DROP TABLE IF EXISTS places';
    const dropTableUsers = 'DROP TABLE IF EXISTS users';
    const createTablePlacesParams = `CREATE TABLE place_params(${placesParamsFields})`;
    const createTablePlaces = `CREATE TABLE places(${placesFields})`;
    const createTableUsers = `CREATE TABLE users(${usersFields})`;

    const update_date_keys = localStorageService
      .keys()
      .filter((key) => key.indexOf('_update_date') !== -1);

    return sqlStorageService
      .execute(dropTablePlacesParams)
      .then(() => sqlStorageService.execute(dropTablePlaces))
      .then(() => sqlStorageService.execute(dropTableUsers))
      .then(() => sqlStorageService.execute(createTablePlacesParams))
      .then(() => sqlStorageService.execute(createTablePlaces))
      .then(() => sqlStorageService.execute(createTableUsers))
      .then(() =>
        localStorageService.remove.apply(localStorageService, update_date_keys)
      )
      .then(() => localStorageService.remove('synchronise_date'))
      .catch((error) =>
        sentryService.captureMessage('MIGRATION_SCRIPT_FAIL_17', {
          level: 'error',
          extra: {
            error,
          },
          tags: {
            sendNotification: 'mail',
          },
        })
      );
  }
}
