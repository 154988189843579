import { MenuMoreComponent } from './menu-more.component';
import ProfileModule from '../profile/profile.module';
import menuMoreRouteConfig from './menu-more.routes';

export default angular
  .module('sfMobile.menu-more', [
    ProfileModule,
    'sfMobile.services.localization',
    'sfMobile.config.server',
    'sfMobile.services.intercom',
    'sfMobile.services.user-experience',
    'sfMobile.components.avatar-user',
    'simplifield.feature-flags',
    'sfMobile.constants.feature-flags',
    'sfMobile.services.in-app-browser',
    'sfMobile.services.pubsub',
  ])
  .config(menuMoreRouteConfig)
  .component('sfMenuMore', MenuMoreComponent).name;
