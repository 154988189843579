export default function appConfig( // eslint-disable-line
  $httpProvider,
  $ionicConfigProvider,
  $compileProvider,
  $translateProvider,
  $qProvider,
  sqlStorageServiceProvider,
  platformServiceProvider,
  ConfigServer,
  databaseSchema,
  databaseConfig
) {
  'ngInject';
  const platformService = platformServiceProvider.$get();
  const isDevelopment = ConfigServer.ENV === 'development';

  const hrefWhiteList = /^\s*(https?|ftp|mailto|geo|maps|itms-apps|market):/;
  const imgScrWhiteList =
    /^\s*(https?|ftp|file|cdvfile|blob|ms-appdata|ionic):/;

  sqlStorageServiceProvider.setDatabaseConfig(databaseConfig);
  sqlStorageServiceProvider.setDatabaseSchema(databaseSchema);

  if (!isDevelopment || !platformService.isBrowser()) {
    sqlStorageServiceProvider.setDatabaseInstance(databaseInstance);
  }

  // Q
  $qProvider.errorOnUnhandledRejections(false);

  // Http
  $httpProvider.useApplyAsync(true);
  $httpProvider.interceptors.push('deprecationInterceptor');
  $httpProvider.interceptors.push('authInterceptor');
  $httpProvider.interceptors.push('refreshTokenInterceptor');
  $httpProvider.interceptors.push('unauthorizedInterceptor');
  $httpProvider.interceptors.push('versionInterceptor');
  // Need to be invoked before other interceptors
  $httpProvider.interceptors.push('errorsInterceptor');

  // Tabs
  $ionicConfigProvider.tabs.style('standard');
  $ionicConfigProvider.tabs.position('bottom');
  $ionicConfigProvider.navBar.alignTitle('center');

  // Scroll
  if (platformService.isBrowser()) {
    $ionicConfigProvider.scrolling.jsScrolling(false);
  }

  // Href.
  $compileProvider.aHrefSanitizationWhitelist(hrefWhiteList);
  $compileProvider.imgSrcSanitizationWhitelist(imgScrWhiteList);
  $compileProvider.debugInfoEnabled(ConfigServer.ANGULAR_DEBUG_DATA);

  // Translate.
  $translateProvider.useSanitizeValueStrategy(null);
  $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
}

function databaseInstance($window, platformService, databaseConfig) {
  'ngInject';

  const databaseName = databaseConfig.name;

  return platformService.isBrowser()
    ? $window.openDatabase(databaseName, '1.0', 'database', 200000)
    : $window.sqlitePlugin.openDatabase({
        name: databaseName,
        iosDatabaseLocation: 'default',
        bgType: 1,
        androidDatabaseProvider: 'system',
      });
}
