import moment from 'moment';
import { path } from 'ramda';

const CAMPAIGN_FIELDS_STATIC_SEARCH_FILTERS = ['contents.name'];

export const RequestEventCreationModalComponent = {
  bindings: {
    eventDate: '<',
    place: '<?',
    onClose: '&',
  },
  templateUrl:
    'calendar-events/components/calendar/request-event-creation-modal/request-event-creation-modal.html',
  controller: class RequestEventCreationModalController {
    // eslint-disable-next-line max-params
    constructor(
      $translate,
      $q,
      objectIdService,
      eventChangeRequestsService,
      eventTypesService,
      popupRequestService,
      preferencesService,
      usersService,
      calendarEventsService,
      formValidationsService,
      campaignsService,
      corporateEventsApiService,
      sfFeatureFlagsService,
      SF_FEATURE_FLAGS
    ) {
      'ngInject';
      this.$translate = $translate;
      this.$q = $q;
      this.objectIdService = objectIdService;
      this.eventChangeRequestsService = eventChangeRequestsService;
      this.eventTypesService = eventTypesService;
      this.popupRequestService = popupRequestService;
      this.preferencesService = preferencesService;
      this.usersService = usersService;
      this.campaignsService = campaignsService;
      this.calendarEventsService = calendarEventsService;
      this.formValidationsService = formValidationsService;
      this.corporateEventsApiService = corporateEventsApiService;
      this.searchStaticCampaignFields = CAMPAIGN_FIELDS_STATIC_SEARCH_FILTERS;
      this.getEventTypeTitle = (eventType) => eventType.contents.title;
      this.getUserName = (user) =>
        user.contents.fullName
          ? user.contents.fullName
          : `${user.contents.firstName} ${user.contents.lastName}`;

      this.getCampaignTitle = getCampaignTitle;
      this.eventTypes = [];
      this.users = [];

      this.eventContents = {};
      this.eventPlace = {};
      this.eventType = {};
      this.assignees = [];
      this.assignedCampaigns = [];
      this.campaigns = [];
      this.corporateEvents = [];
      this.corporateEventsSelected = [];

      this.isFullCalendar = sfFeatureFlagsService.hasFeature(
        SF_FEATURE_FLAGS.FULL_CALENDAR
      );
    }

    $onInit() {
      this.loadEventTypes();
      this.loadUsers();
      this.loadCampaigns();
      this.loadCorporateEvents();

      return this.$q
        .all([
          this.calendarEventsService.createDefaultCalendarEvent(
            this.eventDate,
            this.place
          ),
          this.preferencesService.getTimezone(),
        ])
        .then(([event, userTimezone]) => {
          this.event = event;
          this.eventContents = this.event.contents;
          this.eventPlace = { ...this.event.place };
          this.eventType = { ...this.event.type };
          this.assignees = [...this.event.assignees];

          if (this.eventContents.checklists) {
            this.assignedCampaigns = this.eventContents.checklists.map(
              ({ _id, ...contents }) => ({
                _id,
                contents,
              })
            );
          }

          if (this.eventContents.corporateEvents) {
            this.corporateEventsSelected =
              this.eventContents.corporateEvents.map(
                ({ _id, ...contents }) => ({
                  _id,
                  contents,
                })
              );
          }

          this.userTimezone = userTimezone;
        });
    }

    loadUsers() {
      return this.usersService.crud
        .listLocal({})
        .catch(() => [])
        .then((users) => {
          this.users = users;
        });
    }

    loadCampaigns() {
      return this.campaignsService.getCampaignsList().then((campaigns) => {
        this.campaigns = campaigns;
      });
    }

    loadCorporateEvents() {
      return this.corporateEventsApiService
        .getCorporateEventsList({
          filters: {
            $and: [{ corporate_event_end_date: { $gte: new Date() } }],
          },
        })
        .then((corporateEvents) => {
          this.corporateEvents = corporateEvents;
        });
    }

    loadEventTypes() {
      return this.eventTypesService
        .listLocal()
        .catch(() => [])
        .then((eventTypes) => {
          this.eventTypes = eventTypes;
        });
    }

    selectPlace(place) {
      this.eventPlace = place;
      this.eventContents.place_id = place._id;

      if (place.contents.timezone && place.contents.timezone.name) {
        this.eventContents.timezoneSource = 'inherited_from_place';
        this.eventContents.timezone.name = place.contents.timezone.name;
      } else {
        this.eventContents.timezone.name = this.userTimezone;
      }
    }

    resetPlace() {
      this.eventPlace = {};
      delete this.eventContents.place_id;
      this.resetTimezoneSource();
    }

    selectEventType(eventType) {
      this.eventType = eventType;
      this.eventContents.calendarEventType_id = eventType._id;
    }

    resetEventType() {
      this.eventType = {};
      this.eventContents.calendarEventType_id = null;
    }

    resetParams() {
      this.eventContents.params = [];
      this.event.contents.params = [];
    }

    selectAssignees(assignees) {
      this.assignees = [].concat(assignees);
      this.eventContents.assignees_ids = this.assignees.map((a) => a._id);
    }

    selectCampaigns(campaigns) {
      this.assignedCampaigns = [].concat(campaigns);
    }

    updateParams(params) {
      this.eventContents.params = params;
      this.event.contents.params = params;
    }

    deleteCampaign(campaignId) {
      this.assignedCampaigns = this.assignedCampaigns.filter(
        (c) => c._id !== campaignId
      );
    }

    mapSelectedCampaigns() {
      return this.assignedCampaigns.map((campaign) => campaign._id);
    }

    resetCampaigns() {
      this.assignedCampaigns = [];
    }

    resetAssignees() {
      this.assignees = [];
      this.eventContents.assignees_ids = [];
    }

    resetTimezoneSource() {
      this.eventContents.timezoneSource = 'manually_set';
    }

    requestEventCreation() {
      this.pending = true;

      const popupRequest = this.popupRequestService.show({
        progress: {
          title: this.$translate.instant('REQUEST_EVENT_CREATION_PENDING'),
        },
        error: {
          title: this.$translate.instant('REQUEST_EVENT_CREATION_ERROR_TITLE'),
          desc: this.$translate.instant('REQUEST_EVENT_CREATION_ERROR_DESC'),
        },
      });

      this.eventContents.checklists = this.mapSelectedCampaigns();

      const requestedEvent = {
        ...this.eventContents,
      };

      if (this.corporateEventsSelected.length) {
        requestedEvent.corporateEvents = this.corporateEventsSelected.map(
          ({ _id }) => _id
        );
      }

      if (!this.isRequired('assignees_ids')) {
        requestedEvent.assignees_ids = [];
      }

      return this.eventChangeRequestsService
        .saveNew({
          contents: {
            originalEvent: {
              _id: this.objectIdService.create(),
              contents: {},
            },
            requestedEvent,
            state: 'pending',
            action: 'create',
            organisation_id: requestedEvent.organisation_id,
            requestComment: this.requestComment || '',
          },
        })
        .then(() => popupRequest.onSuccess())
        .then(() => this.onClose())
        .catch(() => popupRequest.onError(() => this.requestEventCreation()))
        .finally(() => {
          this.pending = false;
        });
    }

    startDateTimezoneChange({ startDate, endDate, timezone }) {
      if (startDate) {
        this.eventContents.start_dateTime = startDate.toDate();
      }
      if (endDate) {
        this.eventContents.end_dateTime = endDate.toDate();
      }

      if (timezone) {
        this.eventContents.timezone.name = timezone;
        this.resetTimezoneSource();
      }

      this.hasError = !this.isValidDateRange();

      if (this.eventCreationRequestForm.sfbDateTimeRangeTimezone__startDate) {
        this.eventCreationRequestForm.sfbDateTimeRangeTimezone__startDate.$setValidity(
          'invalid_date_range',
          !this.hasError
        );
      }
    }

    getError(fieldName) {
      return this.formValidationsService.getInputError(
        this.eventCreationRequestForm,
        fieldName
      );
    }

    isRequired(fieldName) {
      return (
        this.eventType.contents &&
        this.eventType.contents.requiredFields.includes(fieldName)
      );
    }

    getAssigneesValue() {
      const length = this.assignees.length;

      if (!length) {
        return null;
      }

      if (length === 1) {
        return this.getUserName(this.assignees[0]);
      }

      return this.$translate.instant('EVENT_SELECTED_ASSIGNEES_VALUE', {
        count: length,
      });
    }

    isValidDateRange() {
      return moment(this.eventContents.start_dateTime).isSameOrBefore(
        this.eventContents.end_dateTime
      );
    }

    getCampaignsValue() {
      const length = this.campaigns.length;

      if (!length) {
        return null;
      }

      if (length === 1) {
        return getCampaignTitle(this.campaigns[0]);
      }

      return this.$translate.instant('EVENT_SELECTED_ASSIGNEES_VALUE', {
        count: length,
      });
    }

    getCorporateEventTitle(corporateEvent) {
      return [
        path(['contents', 'name'])(corporateEvent),
        path(['contents', 'eventType', 'label', 'en'])(corporateEvent),
      ]
        .filter(Boolean)
        .join(' • ');
    }

    selectCorporateEvent(corporateEvent) {
      this.corporateEventsSelected = [].concat(corporateEvent);
    }

    deleteCorporateEvent(corporateEventId) {
      this.corporateEventsSelected = this.corporateEventsSelected.filter(
        (e) => e._id !== corporateEventId
      );
    }

    resetCorporateEvent() {
      this.corporateEventsSelected = [];
    }
  },
};

function getCampaignTitle(campaign) {
  return campaign.contents.name;
}
