import {
  CompletionFilterType,
  FilterType,
} from '../../../../constants/campaign-completion.constants';
import {
  Filter,
  FilterValue,
  FilterChangePayload,
} from '../../types/campaign-completion';

export class CampaignCompletionFilterModalController {
  filtersAvailable: Filter[];
  filters: FilterValue[];
  sortBy: 'asc' | 'desc';
  sortOptions: {
    label: string;
    value: string;
  }[];
  filtersHash: Partial<Record<CompletionFilterType, any>> = {};
  onFilterChange: (arg: FilterChangePayload) => void;
  onSortByChange: (param: { sortBy: 'asc' | 'desc' }) => void;
  onClose: () => void;
  useNewCampaigns: boolean;

  /* @ngInject */
  constructor(
    private SF_FILTER_TYPE: typeof FilterType,
    private $translate: ng.translate.ITranslateService,
    private profileService,
    private organisationsService
  ) {}

  $onInit(): void {
    const generateHash = (values: FilterValue[], availableValues: Filter[]) => {
      return availableValues.reduce((hash, filter) => {
        const filterValue = values.filter((value) => value.id === filter.id)[0];

        hash[filter.id] = filterValue ? filterValue.value : '';
        return hash;
      }, {} as Partial<Record<CompletionFilterType, any>>);
    };

    this.filtersHash = generateHash(this.filters, this.filtersAvailable);
    this.sortOptions = [
      {
        label: this.$translate.instant('CAMPAIGN_COMPLETION_MODAL_ASC_LABEL'),
        value: 'asc',
      },
      {
        label: this.$translate.instant('CAMPAIGN_COMPLETION_MODAL_DESC_LABEL'),
        value: 'desc',
      },
    ];

    this.profileService
      .getProfile()
      .then((profile) =>
        this.organisationsService.getProfileOrganisation(profile)
      )
      .then((organisation) => {
        this.useNewCampaigns = organisation.useNewCampaigns;
      });
  }

  onChangeFilterValue(filterChanged: Filter): void {
    const filterValue = this.filtersHash[filterChanged.id];
    const newValue = filterChanged.values.find(
      (entry) => entry.value === filterValue
    );

    if (!newValue) {
      return;
    }

    this.filters = this.filters
      .filter((value) => value.id !== filterChanged.id)
      .concat({
        id: filterChanged.id,
        value: newValue.value,
        label: newValue.label,
      });
  }

  isDropdown(filter: Filter): boolean {
    return filter.type === this.SF_FILTER_TYPE.dropdown;
  }

  isToggle(filter: Filter): boolean {
    return filter.type === this.SF_FILTER_TYPE.toggle;
  }

  onSave(): void {
    this.onFilterChange({ values: this.filters } as FilterChangePayload);
    this.onSortByChange({ sortBy: this.sortBy });
    this.onClose();
  }
}
