import {
  FilterCustomParam,
  FilterStandard,
} from '../../../components/Search/search';
import {
  PLACES_CITY_FILTER_KEY,
  PLACES_COUNTRY_FILTER_KEY,
  PLACES_ZIPCODE_FILTER_KEY,
  PLACESLISTS_FILTER_KEY,
} from '../../../constants/place-filters.constant';

type ReturnedType = {
  filters: FilterStandard[];
  placesListsNameHash: Record<string, { contents: { places_ids: string[] } }>;
  placesParamsHash: Record<string, FilterCustomParam>;
};

export const initPlacesFilters = (
  placesParams,
  placesLists,
  $translate
): ReturnedType => {
  const standardFilters = [
    {
      id: PLACES_CITY_FILTER_KEY,
      label: $translate.instant('PLACES_CITY_FILTER_LABEL'),
    },
    {
      id: PLACES_ZIPCODE_FILTER_KEY,
      label: $translate.instant('PLACES_ZIPCODE_FILTER_LABEL'),
    },
    {
      id: PLACES_COUNTRY_FILTER_KEY,
      label: $translate.instant('PLACES_COUNTRY_FILTER_LABEL'),
    },
    {
      id: PLACESLISTS_FILTER_KEY,
      label: $translate.instant('PLACESLIST_FILTER_LABEL'),
      values: placesLists.map((list) => list.contents.name),
    },
  ];

  const placesParamsHash = placesParams.reduce((hash, placeParam) => {
    hash[placeParam.id] = placeParam;

    return hash;
  }, {});

  const placesListsNameHash = placesLists.reduce((hash, list) => {
    hash[list.contents.name] = list;

    return hash;
  }, {});

  const filters = [...standardFilters, ...placesParams];

  return {
    filters,
    placesParamsHash,
    placesListsNameHash,
  };
};
