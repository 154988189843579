import type { ObjectId } from '../../..';
import type { NotificationsService } from '../../../notifications/services/notifications/notifications.service';
import type { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import type { DeviceNotification } from '../../../services/Utils/PushNotifications/push-notifications.service';
import {
  NOTIFICATION_METAEVENTS,
  NotificationMetaEvents,
} from '../../../notifications/services/notifications/notifications.constant';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';

const TIME_NOTIF_DISPLAY = 5000; // Magic number in ms

type NotificationDisplay = {
  id: ObjectId;
  title?: string;
  message?: string;
  data: DeviceNotification;
};

export const AppNotificationComponent = {
  bindings: {},
  templateUrl:
    'components/Notifications/app-notification/app-notification.html',
  controller: class AppNotificationController
    implements ng.IComponentController
  {
    notifications: NotificationDisplay[] = [];
    #unsubscribe: () => void;
    constructor(
      private pubSubService: PubSubService<NotificationMetaEvents>,
      private $timeout: ng.ITimeoutService,
      private notificationsService: NotificationsService,
      private objectIdService: ObjectIdService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.notifications = [];

      this.#unsubscribe = this.pubSubService.subscribe(
        NOTIFICATION_METAEVENTS.NOTIFICATION_DISPLAY,
        (notif: DeviceNotification) => this.onNotificationReceived(notif)
      );
    }

    $onDestroy(): void {
      this.#unsubscribe();
    }

    onNotificationReceived(notif: DeviceNotification): void {
      const newNotif: NotificationDisplay = {
        id: this.objectIdService.create(),
        title: notif.title || notif.body,
        message: notif.title ? notif.body : undefined,
        data: notif,
      };

      this.$timeout(() => {
        this.notifications = this.notifications.concat(newNotif);
      }, 0);

      this.$timeout(
        () => this.hideNotification(newNotif.id),
        TIME_NOTIF_DISPLAY
      );
    }

    hideNotification(notifId: ObjectId): void {
      this.notifications = this.notifications.filter(
        (notif) => notif.id !== notifId
      );
    }

    onNotifClick(notif: NotificationDisplay): void {
      const notificationExist =
        notif &&
        this.notifications.some((notification) => notification.id === notif.id);

      if (!notificationExist) {
        return;
      }

      this.notificationsService.redirectNotificationOnClick(notif.data);
      this.hideNotification(notif.id);
    }

    hasDesc(notif: NotificationDisplay): boolean {
      return !!notif.message;
    }
  },
};
