const COLOR_CLASS_PREFIX = 'sf_drawing_color_picker__color';

export const DrawingColorPickerComponent = {
  bindings: {
    onColorSelect: '&', // Callback on color click
    selectedColorCode: '<', // Override default selected color
  },
  templateUrl:
    'components/Drawing/drawing-color-picker/drawing-color-picker.html',
  controller: function DrawingColorPickerController(SF_DRAWING_COLORS) {
    'ngInject';
    this.$onInit = () => {
      this.colors = Object.entries(SF_DRAWING_COLORS).map(([key, value]) => ({
        class: `${COLOR_CLASS_PREFIX}--${key.toLowerCase()}`,
        code: value,
      }));
      this.colorCode = this.selectedColorCode || SF_DRAWING_COLORS.RED;
    };

    this.$onChanges = (changes) => {
      if (changes.selectedColorCode) {
        this.colorCode = this.selectedColorCode;
      }
    };

    this.onColorChanged = (colorCode) => {
      this.colorCode = colorCode;
      return this.onColorSelect({ color: this.colorCode });
    };

    this.isSelectedColor = (colorCode) => this.colorCode === colorCode;
  },
};
