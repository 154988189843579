export const DeletedChatMessageComponent = {
  bindings: {
    message: '<',
    isSenderDisplayed: '<',
  },
  templateUrl: 'chat/components/deleted-chat-message/deleted-chat-message.html',
  // eslint-disable-next-line max-params
  controller: function DeletedChatMessageController(
    chatService,
    dateFormatService
  ) {
    'ngInject';
    this.getTimeFormatted = dateFormatService.getTimeFormatted;

    this.$onInit = () => {
      this.userInfo = this.getUserInfo();
    };

    this.getUserInfo = () => {
      const { _sender } = this.message;
      const { firstName, lastName, avatar_id } =
        chatService.getMemberMetadata(_sender);

      return {
        _id: _sender.userId,
        contents: {
          firstName,
          lastName,
          avatar_id,
        },
      };
    };
  },
};
