export const BrandGoalComponent = {
  bindings: {
    brand: '<',
    objective: '<',
    currentSale: '<',
    currency: '<',
  },
  templateUrl: 'missions/components/Scans/brand-goal/brand-goal.html',
  controller: class BrandGoalController {
    $onInit() {
      this.initPercent();
    }

    $onChanges(changes) {
      if (changes.currentSale || changes.objective) {
        this.initPercent();
      }
    }

    initPercent() {
      this.currentSale = this.currentSale || 0;

      if (this.objective) {
        const realPercent = (this.currentSale * 100) / this.objective;
        const realPercentTrunced = Math.trunc(realPercent);

        this.realPercent = realPercentTrunced;
        this.percent = realPercentTrunced > 100 ? 100 : realPercentTrunced;
      }
    }
  },
};
