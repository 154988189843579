"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId(),
    name: 'oneright',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      endOfLife: 1267833600000,
      organisation_id: createObjectId('owner'),
      user_id: createObjectId('owner'),
      rights: [{
        path: '/plop',
        methods: 127
      }]
    }
  }, {
    _id: createObjectId('owner'),
    name: 'severalrights',
    description: 'Every data that can be set.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      endOfLife: 1267833600000,
      organisation_id: createObjectId('owner'),
      user_id: createObjectId('owner'),
      rights: [{
        path: '/plop',
        methods: 127
      }, {
        path: '/wadup',
        methods: 127
      }]
    }
  }, {
    _id: createObjectId(),
    name: 'unknow',
    description: 'Unknown contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_UNEXPECTED_CONTENT',
        params: ['.wadup']
      }]
    },
    partial: {
      valid: false
    },
    contents: {
      endOfLife: 1267833600000,
      organisation_id: createObjectId('owner'),
      user_id: createObjectId('owner'),
      rights: [{
        path: '/plop',
        methods: 127
      }],
      wadup: {
        Kikoolol: true
      },
      trololol: 'facepalm'
    }
  }, {
    _id: createObjectId(),
    name: 'empty',
    description: 'Empty contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.endOfLife']
      }]
    },
    partial: {
      valid: true
    },
    contents: {}
  }, {
    _id: createObjectId(),
    name: 'nouser',
    description: 'No user',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.user_id']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      endOfLife: 1267833600000,
      rights: [{
        path: '/plop',
        methods: 127
      }],
      organisation_id: createObjectId('owner')
    }
  }];
};