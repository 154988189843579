export default function analyticsRouteConfig($stateProvider): void {
  'ngInject';

  $stateProvider
    .state('index.menu-more.analytics', {
      url: '/analytics',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('index.menu-more.analytics.list', {
      url: '',
      template: `
        <ion-view cache-view="false">
          <sf-sales-dashboard></sf-sales-dashboard>
        </ion-view>
      `,
      controller: AnalyticsViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Analytics',
      resolve: {},
    });

  function AnalyticsViewController() {
    'ngInject';
  }
}
