import { DocumentItemComponent } from './document-item.component';

export default angular
  .module('sfMobile.components.document-item', [
    'sfMobile.constants.image-sizes',
    'sfMobile.services.files',
    'sfMobile.services.file-system',
    'sfMobile.services.document-viewer',
    'sfMobile.services.modal',
    'sfMobile.constants.error-codes',
    'sfMobile.services.documents-download',
    'sfMobile.services.image-source',
  ])
  .component('sfDocumentItem', DocumentItemComponent).name;
