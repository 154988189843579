import { QuestionSignatureComponent } from './question-signature.component';

export default angular
  .module('sfMobile.components.question-signature', [
    'pascalprecht.translate',
    'sfMobile.constants.image-sizes',
    'sfMobile.services.objectId',
    'sfMobile.services.files',
    'sfMobile.services.file-system',
    'sfMobile.services.action-sheet',
    'sfMobile.services.modal',
    'sfMobile.components.signature-pad',
    'sfMobile.components.button-big',
  ])
  .component('sfQuestionSignature', QuestionSignatureComponent).name;
