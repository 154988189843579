import { LifecycleService } from './lifecycle.service';
import { LIFECYCLE_STORAGE_KEYS } from './lifecycle.constants';
import { LIFECYCLE_EVENTS } from './lifecycle.events';

export default angular
  .module('sfMobile.services.lifecycle', [
    'LocalStorageModule',
    'ui.router',
    'sfMobile.services.platform',
    'sfMobile.services.router',
    'sfMobile.services.pubsub',
    'sfMobile.services.debug',
  ])
  .constant('LIFECYCLE_STORAGE_KEYS', LIFECYCLE_STORAGE_KEYS)
  .constant('LIFECYCLE_EVENTS', LIFECYCLE_EVENTS)
  .factory('lifecycleService', LifecycleService).name;
