import { StateService } from '@uirouter/core';
import { translate } from 'angular';
import {
  Notification,
  NotificationAnswerCommentAddedMetadata,
  NotificationCampaignActivatedMetadata,
  NotificationCampaignObjectiveReminderMetadata,
  NotificationContents,
  NotificationMetadata,
  NotificationMissionsMetadata,
  NotificationNewsfeedPostMetadata,
  NotificationPlaceDocLoadedMetadata,
  NotificationRejectedPhotoMetadata,
  NotificationReportCommentAddedMetadata,
  NotificationTaskDueDateReachedMetadata,
  NotificationTaskMetadata,
  NotificationTaskReminderMetadata,
} from '../..';
import { ObjectId, UserContents } from '../../..';
import { Campaign } from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';
import { TranslationDictionnary } from '../../../services/Utils/sfTranslation/sf-translation.filters';
import type { NotificationEventsType } from '../../services/notifications/notifications.constant';
import moment from 'moment';

type I18Params<T = NotificationMetadata> = T & {
  sender_name?: string;
  $filter: ng.IFilterService;
  additionalResources: unknown[];
};
type NotificationContent = {
  metadata: {
    form_id?: ObjectId;
    campaignId?: ObjectId;
  };
};
type LoadDataArguments = {
  $q: ng.IQService;
  contents: NotificationContent;
  formsService: { getLocal: (form_id: ObjectId) => ng.IPromise<unknown[]> };
  campaignsService: CampaignsService;
};
type NotificationConfigData = {
  icon: string;
  state: string;
  i18nKey: string;
  i18nKeyGetter?: (data: NotificationMetadata) => string;
  loadData?: (args: LoadDataArguments) => ng.IPromise<unknown[]>;
  getterI18nParams: (data: I18Params) => Record<string, unknown>;
  getRouterParams?: (data: NotificationMetadata) => Record<string, unknown>;
  extraI18n?: {
    key: string;
    isParams: (params) => boolean;
  };
};
type NotificationConfig = Record<
  NotificationEventsType,
  NotificationConfigData
>;

function loadCampaign({
  $q,
  contents,
  campaignsService,
}: LoadDataArguments): ng.IPromise<Campaign[]> {
  if (!contents.metadata.campaignId) {
    return $q.when([]);
  }
  const skipLocalSave = true;

  return campaignsService
    .getOne(contents.metadata.campaignId, skipLocalSave)
    .then((campaign) => [campaign])
    .catch(() => []);
}

function getCampaignI18nParams(
  params: I18Params<NotificationMetadata>
): Record<string, unknown> {
  const {
    campaignName,
    $filter,
    additionalResources,
    sender_name,
    placeName,
    dueDate,
  } = params;

  const [campaign] = additionalResources as [Campaign];
  const translator: ng.IFilterFunction = $filter('sfTranslation');

  return {
    ...params,
    senderName: sender_name,
    campaignName: translator(
      campaign?.contents.name || campaignName,
      campaign?.i18n
    ),
    dueDate: dueDate,
    POSName: placeName,
  };
}

export const NOTIFICATIONS_DISPLAY: NotificationConfig = {
  MISSIONS_CREATED: {
    icon: 'fa-ballot-check',
    state: 'index.menu-more.missions.lists.missions',
    i18nKey: 'ACTIVITY_FEED_MISSIONS_CREATED',
    loadData: ({
      $q,
      contents,
      formsService,
    }: LoadDataArguments): ng.IPromise<unknown[]> => {
      if (!contents.metadata.form_id) {
        return $q.when([]);
      }

      return formsService
        .getLocal(contents.metadata.form_id)
        .then((form) => [form])
        .catch(() => [] as unknown[]);
    },
    getterI18nParams: ({
      form_title,
      nb_missions,
      $filter,
      additionalResources,
    }: I18Params<NotificationMissionsMetadata>): Record<string, unknown> => {
      const sfTranslation: (
        formTitle: string,
        i18n?: TranslationDictionnary
      ) => string = $filter('sfTranslation');
      const [form] = additionalResources as [{ i18n?: TranslationDictionnary }];

      return {
        formName: sfTranslation(form_title, form?.i18n),
        nbMissions: nb_missions,
      };
    },
  },
  TASK_CREATED: {
    icon: 'fa-list-check',
    state: 'index.menu-more.tasks.list',
    i18nKey: 'ACTIVITY_FEED_TASK_CREATED',
    i18nKeyGetter: ({ place_name }) =>
      place_name
        ? 'ACTIVITY_FEED_PLACE_TASK_CREATED'
        : 'ACTIVITY_FEED_TASK_CREATED',
    getterI18nParams: ({
      sender_name,
      task_name,
      place_name,
    }: I18Params<NotificationTaskMetadata>): Record<string, unknown> => {
      const i18nParams = {
        senderName: sender_name,
        taskName: task_name,
      };
      if (place_name) {
        i18nParams['placeName'] = place_name;
      }
      return i18nParams;
    },
  },
  TASKS_CREATED: {
    icon: 'fa-list-ul',
    state: 'index.menu-more.tasks.list',
    i18nKey: 'ACTIVITY_FEED_TASKS_CREATED',
    getterI18nParams: ({
      sender_name,
      task_count,
    }: I18Params<NotificationTaskMetadata>): Record<string, unknown> => ({
      senderName: sender_name,
      taskCount: task_count,
    }),
  },
  TASK_STATUS_CHANGED: {
    icon: 'fa-list-check',
    state: 'index.menu-more.tasks.list',
    i18nKey: 'ACTIVITY_FEED_TASK_STATUS_CHANGES',
    getterI18nParams: ({
      sender_name,
      task_status_old,
      task_status_new,
    }: I18Params<NotificationTaskMetadata>): Record<string, unknown> => ({
      senderName: sender_name,
      taskStatusOld: task_status_old,
      taskStatusNew: task_status_new,
    }),
  },
  TASK_ASSIGNED: {
    icon: 'fa-list-check',
    state: 'index.menu-more.tasks.list',
    i18nKey: 'ACTIVITY_FEED_TASK_ASSIGNED',
    getterI18nParams: ({
      sender_name,
      task_name,
    }: I18Params<NotificationTaskMetadata>): Record<string, unknown> => ({
      senderName: sender_name,
      taskName: task_name,
    }),
  },
  SUBTASK_ASSIGNED: {
    icon: 'fa-list-check',
    state: 'index.menu-more.tasks.list',
    i18nKey: 'ACTIVITY_FEED_SUBTASK_ASSIGNED',
    getterI18nParams: ({
      sender_name,
      subtask_name,
      task_name,
    }: I18Params<NotificationTaskMetadata>): Record<string, unknown> => ({
      senderName: sender_name,
      subtaskName: subtask_name,
      taskName: task_name,
    }),
  },
  SUBTASK_STATUS_CHANGED: {
    icon: 'fa-list-check',
    state: 'index.menu-more.tasks.list',
    i18nKey: 'ACTIVITY_FEED_SUBTASK_STATUS_CHANGED',
    getterI18nParams: ({
      sender_name,
      subtask_name,
      task_name,
      subtask_status_old,
      subtask_status_new,
    }: I18Params<NotificationTaskMetadata>): Record<string, unknown> => ({
      senderName: sender_name,
      subtaskName: subtask_name,
      taskName: task_name,
      subtaskStatusOld: subtask_status_old,
      subtaskStatusNew: subtask_status_new,
    }),
  },
  NEWSFEED_POST_CREATED: {
    icon: 'fa-newspaper',
    state: 'index.newsfeed.details',
    i18nKey: 'NEWSFEED_POST_CREATED',
    getterI18nParams: ({
      sender_name,
      categoryName,
    }: I18Params): Record<string, unknown> => ({
      senderName: sender_name,
      categoryName,
    }),
    getRouterParams: ({
      post_id,
    }: NotificationNewsfeedPostMetadata): Record<string, unknown> => ({
      postId: post_id,
    }),
  },
  REPORT_COMMENT_CREATED: {
    icon: 'fa-ballot-check',
    state: 'index.menu-more.reactive-campaigns.report',
    i18nKey: 'REPORT_COMMENT_CREATED',
    extraI18n: {
      key: 'REPORT_COMMENT_CREATED_ASSOCIATED_STORE',
      isParams: ({ POSName }) => Boolean(POSName),
    },
    getterI18nParams: getCampaignI18nParams,
    getRouterParams: ({
      campaignId,
      reportId,
    }: NotificationReportCommentAddedMetadata): Record<string, unknown> => ({
      campaignId,
      reportId,
      online: true,
      targetTab: 'comments',
    }),
    loadData: loadCampaign,
  },
  TASK_COMMENT_CREATED: {
    icon: 'fa-ballot-check',
    state: 'index.menu-more.tasks.list',
    i18nKey: 'TASK_COMMENT_CREATED',
    getterI18nParams: ({
      sender_name,
      comment,
    }: I18Params<NotificationReportCommentAddedMetadata>): Record<
      string,
      unknown
    > => ({
      senderName: sender_name,
      comment,
    }),
  },
  PHOTO_VALIDATION_REJECTED: {
    icon: 'fa-camera',
    state: 'index.menu-more.merchandising.validation-details',
    i18nKey: 'PHOTO_VALIDATION_REJECTED',
    getterI18nParams: ({
      sender_name,
    }: I18Params): Record<string, unknown> => ({
      senderName: sender_name,
    }),
    getRouterParams: ({
      validationId,
    }: NotificationRejectedPhotoMetadata): Record<string, unknown> => ({
      validationId,
    }),
  },
  REPORT_APPROVED: {
    icon: 'fa-file-check',
    state: 'index.menu-more.reactive-campaigns.report',
    i18nKey: 'REPORT_APPROVED',
    extraI18n: {
      key: 'REPORT_APPROVED_ASSOCIATED_STORE',
      isParams: ({ POSName }) => Boolean(POSName),
    },
    loadData: loadCampaign,
    getterI18nParams: getCampaignI18nParams,
    getRouterParams: ({ campaignId, report_id }) => ({
      campaignId,
      reportId: report_id,
      online: true,
    }),
  },
  REPORT_NEEDS_REVISION: {
    icon: 'fa-file-check',
    state: 'index.menu-more.reactive-campaigns.report',
    i18nKey: 'REPORT_NEEDS_REVISION',
    extraI18n: {
      key: 'REPORT_NEEDS_REVISION_ASSOCIATED_STORE',
      isParams: ({ POSName }) => Boolean(POSName),
    },
    loadData: loadCampaign,
    getterI18nParams: getCampaignI18nParams,
    getRouterParams: ({ campaignId, report_id }) => ({
      campaignId,
      reportId: report_id,
      online: true,
    }),
  },
  TASK_DUE_DATE_REACHED: {
    icon: 'fa-list-check',
    state: 'index.menu-more.tasks.details',
    i18nKey: 'TASK_DUE_DATE_REACHED',
    getterI18nParams: ({
      sender_name,
      taskName,
    }: I18Params<NotificationTaskDueDateReachedMetadata>): Record<
      string,
      unknown
    > => ({
      senderName: sender_name,
      taskName,
    }),
    getRouterParams: ({
      taskId,
    }: NotificationTaskDueDateReachedMetadata): Record<string, unknown> => ({
      taskId,
    }),
  },
  PLACE_DOCUMENT_UPLOADED: {
    icon: 'fa-file-upload',
    state: 'index.places.details.documents',
    i18nKey: 'PLACE_DOCUMENT_UPLOADED',
    getterI18nParams: ({
      placeName,
    }: I18Params<NotificationPlaceDocLoadedMetadata>): Record<
      string,
      unknown
    > => ({
      placeName,
    }),
    getRouterParams: ({
      placeId,
    }: NotificationPlaceDocLoadedMetadata): Record<string, unknown> => ({
      placeId,
    }),
  },
  A_IN_STORE_CAMPAIGN_ACTIVATED: {
    icon: 'fa-poll',
    state: 'index.menu-more.reactive-campaigns.details',
    i18nKey: 'A_IN_STORE_CAMPAIGN_ACTIVATED',
    loadData: loadCampaign,
    getterI18nParams: getCampaignI18nParams,
    getRouterParams: ({
      campaignId,
    }: NotificationCampaignActivatedMetadata): Record<string, unknown> => ({
      campaignId,
    }),
  },
  A_USER_FEEDBACK_CAMPAIGN_ACTIVATED: {
    icon: 'fa-poll',
    state: 'index.menu-more.reactive-campaigns.details',
    i18nKey: 'A_USER_FEEDBACK_CAMPAIGN_ACTIVATED',
    loadData: loadCampaign,
    getterI18nParams: getCampaignI18nParams,
    getRouterParams: ({
      campaignId,
    }: NotificationCampaignActivatedMetadata): Record<string, unknown> => ({
      campaignId,
    }),
  },
  STORE_OBJECTIVE_REMINDERS: {
    icon: 'fa-list-check',
    state: 'index.menu-more.reactive-campaigns.details',
    i18nKey: 'NOTIF_REMINDER_STORE_MESSAGE',
    loadData: loadCampaign,
    getterI18nParams: getCampaignI18nParams,
    getRouterParams: ({
      campaignId,
    }: NotificationCampaignObjectiveReminderMetadata): Record<
      string,
      unknown
    > => ({
      campaignId,
    }),
  },
  USER_OBJECTIVE_REMINDERS: {
    icon: 'fa-list-check',
    state: 'index.menu-more.reactive-campaigns.details',
    i18nKey: 'NOTIF_REMINDER_USER_MESSAGE',
    loadData: loadCampaign,
    getterI18nParams: getCampaignI18nParams,
    getRouterParams: ({
      campaignId,
    }: NotificationCampaignObjectiveReminderMetadata): Record<
      string,
      unknown
    > => ({
      campaignId,
    }),
  },
  ANSWER_COMMENT_CREATED: {
    icon: 'fa-ballot-check',
    state: 'index.menu-more.reactive-campaigns.activity-details',
    i18nKey: 'ANSWER_COMMENT_CREATED',
    getterI18nParams: getCampaignI18nParams,
    getRouterParams: ({
      answerId,
      campaignId,
    }: NotificationAnswerCommentAddedMetadata) => ({
      campaignId,
      openPreviewWithAnswerId: answerId,
      targetTab: 'gallery',
    }),
    loadData: loadCampaign,
  },
  ROUTINE_CHECKLIST_ACTIVATION: {
    icon: 'fa-ballot-check',
    state: 'index.menu-more.reactive-campaigns.details',
    i18nKey: 'ROUTINE_CHECKLIST_ACTIVATION',
    getterI18nParams: getCampaignI18nParams,
    getRouterParams: ({ campaignId }) => ({ campaignId }),
    loadData: loadCampaign,
  },
  ROUTINE_CHECKLIST_REMINDER: {
    icon: 'fa-ballot-check',
    state: 'index.menu-more.reactive-campaigns.details',
    i18nKey: 'ROUTINE_CHECKLIST_REMINDER',
    getterI18nParams: getCampaignI18nParams,
    getRouterParams: ({ campaignId }) => ({ campaignId }),
    loadData: loadCampaign,
  },
  TASK_REMINDER_NOTIFICATION: {
    icon: 'fa-list-check',
    state: 'index.menu-more.tasks.details',
    i18nKey: 'TASK_REMINDER_NOTIFICATION',
    getterI18nParams: ({
      sender_name,
      task_name,
      due_date,
      task_id,
    }: I18Params<NotificationTaskMetadata>): Record<string, unknown> => {
      const i18nParams = {
        senderName: sender_name,
        taskName: task_name,
        dueDate: due_date,
        task_id,
      };
      return i18nParams;
    },
    getRouterParams: ({ task_id }: NotificationTaskReminderMetadata) => ({
      taskId: task_id,
    }),
  },
};

type Sender = {
  _id: ObjectId;
  contents: Partial<UserContents>;
};

export class NotificationItemController implements ng.IComponentController {
  onRedirect: () => void;
  notification: Notification;
  event: NotificationConfigData;
  icon: string;
  text: string;
  createdDate: string;
  sender: Sender;

  constructor(
    private $q: ng.IQService,
    private $filter: ng.IFilterService,
    private $translate: translate.ITranslateService,
    private $state: StateService,
    private dateFormatService,
    private formsService,
    private campaignsService: CampaignsService
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    const { sender, links, contents, created_date } = this.notification;

    this.sender = {
      _id: links.sender_id,
      contents: sender,
    };

    this.event = NOTIFICATIONS_DISPLAY[contents.event];
    return this.$q
      .resolve(
        this.event.loadData
          ? this.event.loadData({
              $q: this.$q,
              contents,
              formsService: this.formsService,
              campaignsService: this.campaignsService,
            })
          : []
      )
      .then((additionalResources) => {
        this.icon = this.getNotificationIcon(this.event);
        this.text = this.getNotificationText(
          this.event,
          sender,
          contents,
          additionalResources
        );
        this.createdDate = this.dateFormatService.getFromNow(created_date);
      });
  }

  getNotificationIcon(event: NotificationConfigData): string {
    return event.icon;
  }
  getNotificationText(
    event: NotificationConfigData,
    sender: { firstName: string; lastName: string },
    { metadata }: NotificationContents,
    additionalResources: unknown[]
  ): string {
    const { i18nKey, getterI18nParams, i18nKeyGetter, extraI18n } = event;
    const i18nParams = getterI18nParams({
      sender_name: sender.firstName + ' ' + sender.lastName,
      $filter: this.$filter,
      additionalResources,
      ...metadata,
    });
    const key = i18nKeyGetter ? i18nKeyGetter(metadata) : i18nKey;
    const mainTranslation = this.$translate.instant(key, i18nParams);
    const extraTextranslation =
      extraI18n?.key && extraI18n?.isParams(i18nParams)
        ? this.$translate.instant(extraI18n.key, i18nParams)
        : '';

    return `${mainTranslation} ${extraTextranslation}`;
  }

  goToEvent(): void {
    this.onRedirect();
    let params = {};

    if (this.event.getRouterParams) {
      params = this.event.getRouterParams(this.notification.contents.metadata);
    }

    this.$state.go(this.event.state, params);
  }
}
