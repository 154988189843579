export const CampaignStatsComponent = {
  bindings: {
    campaign: '<',
  },
  templateUrl:
    'legacy-campaigns/campaign/components/campaign-stats/campaign-stats.html',
  controller: function CampaignStatsController($translate, formsService) {
    'ngInject';

    this.$onInit = () => {
      this.isLoading = true;
      this.networkError = false;
      this.formSections = this.campaign.contents.sections;

      this.loadReportsStats();
    };

    this.loadReportsStats = () =>
      formsService
        .getReportsStatistics(this.campaign._id)
        .then((stats) => {
          const statsScore = stats.score || {};

          this.formWholeScore = statsScore.whole;
          this.formSectionsScore = statsScore.sections;
          this.formWholeScorePercent = this.getPercent(statsScore.whole);
          this.formWholeScoreLabel = {
            title: $translate.instant('CAMPAIGN_STATS_AVERAGE_SCORE'),
            desc: `${this.formWholeScorePercent}%`,
          };
          return stats;
        })
        .catch((err) => {
          this.networkError = true;
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
        });

    this.reload = () => {
      this.isLoading = true;

      return this.loadReportsStats().then(() => {
        this.networkError = false;
      });
    };

    this.hasScore = () => this.formWholeScore;
    this.getPercent = (score) =>
      score ? `${Math.round(score.percent * 100)}` : '-';
  },
};
