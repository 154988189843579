import { PictureTagModel, SelectedTags } from '@services/API/tags/tags';
import { clone } from 'ramda';
import { ObjectId } from '../index';
import { EditedResourcePreview, ResourceFile } from '../newsfeed/types';
import { TagsSelectorService } from './tags-selector.service';
import { PlatformService } from '@services/Utils/Platform';

type TagImage = EditedResourcePreview & {
  imageUrl: string;
  answerId: string;
  tagsIds?: string[];

  temp: ResourceFile & {
    tags_ids: string[];
  };
};
export const tagsSelectorComponent: ng.IComponentOptions = {
  templateUrl: 'tags-selector/tags-selector.html',
  bindings: {
    resolve: '<',
    onCancel: '&',
    onSave: '&',
    isLocal: '<',
    isRequired: '<',
  },
  controller: class TagsSelectorController {
    images: TagImage[];
    tags: PictureTagModel[];
    selectedTags: SelectedTags = {};
    filteredTags: PictureTagModel[];
    isLocal: boolean;

    resolve: {
      images: TagImage[];
      selectedTags: ObjectId[];
    };
    isLoading: boolean;
    isRequired: boolean;
    currentImageIndex = 0;
    onSave;
    search = '';
    activeSlideId = 0;
    isBrowser: boolean;
    slider: { activeIndex: number; slideTo: (index: number) => void };
    options = {
      pagination: false,
    };

    constructor(
      private tagsSelectorService: TagsSelectorService,
      private $q: ng.IQService,
      private platformService: PlatformService
    ) {
      'ngInject';
    }

    $onInit() {
      this.isBrowser = this.platformService.isBrowser();
      this.isLoading = true;
      this.tagsSelectorService
        .getTags()
        .then((tags) => {
          this.tags = tags;
          this.filteredTags = this.tags;
          this.getImageData();
        })
        .finally(() => {
          this.isLoading = false;
          this.$q.resolve();
        });
    }
    private getImageData(): void {
      this.images = clone(this.resolve.images);

      this.images.map((image) => {
        const sfImageUrl = image.url;
        const isBlob = sfImageUrl?.indexOf('blob') !== -1;
        image.imageUrl = isBlob ? sfImageUrl : this.getImgUrl(sfImageUrl);

        if (!Object.keys(this.selectedTags)?.includes(image.answerId)) {
          this.selectedTags[image.answerId] = {};
        }

        image.tagsIds?.map((tagId) => {
          if (!this.tags.some(({ _id }) => _id === tagId)) {
            return;
          }
          this.selectedTags[image.answerId][tagId] = true;
        });
      });
    }
    private getImgUrl(image): string {
      const cache = new Date().getTime();
      const urlChar = image.indexOf('?') === -1 ? '?' : '&';

      return `${image}${urlChar}${cache}`;
    }

    nextSlide() {
      if (this.activeSlideId < this.images.length - 1) {
        this.setActiveSlide(this.activeSlideId + 1);
      }
    }

    prevSlide() {
      this.setActiveSlide(this.activeSlideId - 1);
    }

    setActiveSlide(activeIndex) {
      this.activeSlideId = activeIndex;
    }

    isTagsSelected(): boolean {
      const answerIds = Object.keys(this.selectedTags);
      return !answerIds.some((answerId) => {
        const tagIds = Object.keys(this.selectedTags[answerId]);

        return tagIds.length === 0;
      });
    }

    onSearchChange(search): void {
      this.search = search;
      this.filteredTags = this.tags.filter(({ contents: { name } }) =>
        name.toLowerCase().includes(this.search.toLowerCase())
      );
    }

    onSelectChange(answerId, tag): void {
      if (this.selectedTags[answerId]) {
        if (!this.selectedTags[answerId][tag]) {
          delete this.selectedTags[answerId][tag];
        }
      }
    }

    validate() {
      return this.onSave()(this.selectedTags);
    }
  },
};
