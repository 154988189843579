import { AuthService } from './auth.service';
import authInterceptor from './auth-interceptor.service';
import unauthorizedInterceptor from './unauthorized-interceptor.service';
import refreshTokenInterceptor from './refresh-token-interceptor.service';

export default angular
  .module('sfMobile.services.auth', [
    'sf.sqlStorage',
    'ui.router',
    'sfMobile.services.config',
    'sfMobile.services.ping',
    'sfMobile.services.profile',
    'sfMobile.services.api-url',
    'sfMobile.services.app-state',
    'sfMobile.services.user-devices',
    'sfMobile.services.popup',
    'sfMobile.services.in-app-browser',
    'sfMobile.services.simple-storage',
  ])
  .service('authService', AuthService)
  .factory('authInterceptor', authInterceptor)
  .factory('unauthorizedInterceptor', unauthorizedInterceptor)
  .factory('refreshTokenInterceptor', refreshTokenInterceptor).name;
