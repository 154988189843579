import type { PlatformService, SafeAreaSide } from '.';
import { Capacitor } from '@capacitor/core';

export class IonicPlatformService implements PlatformService {
  private cssRootSafeArea = {
    top: '--sat',
    right: '--sar',
    bottom: '--sab',
    left: '--sal',
  };

  Capacitor = Capacitor;

  constructor(
    private $ionicPlatform: IonicV1.IonicPlatformService,
    private $window: ng.IWindowService
  ) {
    'ngInject';
  }

  registerBackButtonAction(cb: () => void, priority: number): () => void {
    return this.$ionicPlatform.registerBackButtonAction(cb, priority);
  }
  onHardwareBackButton(cb: () => void): () => void {
    this.$ionicPlatform.onHardwareBackButton(cb);
    return () => this.$ionicPlatform.offHardwareBackButton(cb);
  }
  on(evName: string, cb: () => void): () => void {
    return this.$ionicPlatform.on(evName, cb);
  }

  isAndroid(): boolean {
    return this.Capacitor.getPlatform() === 'android';
  }
  isiOS(): boolean {
    return this.Capacitor.getPlatform() === 'ios';
  }
  isBrowser(): boolean {
    return this.Capacitor.getPlatform() === 'web';
  }
  convertFileSrc(path: string): string {
    return this.Capacitor.convertFileSrc(path);
  }

  isWebKit(): boolean {
    const UA = this.$window.navigator.userAgent;
    return /AppleWebKit\//.test(UA);
  }

  getSafeAreaValue(side: SafeAreaSide): number {
    const cssVar = this.cssRootSafeArea[side];

    if (!cssVar) {
      return 0;
    }
    const safeAreaPx = getComputedStyle(
      document.documentElement
    ).getPropertyValue(cssVar);

    return parseInt(safeAreaPx, 10);
  }

  isPluginAvailable(name: string): boolean {
    return this.Capacitor.isPluginAvailable(name);
  }
}
