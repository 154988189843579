export const DISTANCE_UNITS_PREFERENCE_NAME =
  'settings.distance_unit_system' as const;
export const DISTANCE_UNITS = [
  {
    system: 'metric',
    abbreviation: 'km',
    toKm: (n) => n,
    fromKm: (n) => n,
    i18nKey: 'DISTANCE_UNIT_SYSTEM_METRIC',
  },
  {
    system: 'metric_ar',
    abbreviation: 'كم',
    toKm: (n) => n,
    fromKm: (n) => n,
    i18nKey: 'DISTANCE_UNIT_SYSTEM_METRIC_AR',
  },
  {
    system: 'imperial',
    abbreviation: 'mi',
    toKm: (n) => n * 1.60934,
    fromKm: (n) => n * 0.621371,
    i18nKey: 'DISTANCE_UNIT_SYSTEM_IMPERIAL',
  },
] as const;

export default angular
  .module('sfMobile.constants.unit-system', [])
  .constant('SF_DISTANCE_UNITS_PREFERENCE_NAME', DISTANCE_UNITS_PREFERENCE_NAME)
  .constant('SF_DISTANCE_UNITS', DISTANCE_UNITS).name;
