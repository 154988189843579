const STORE_METRICS_DASHBOARD_ID = 'store-field-metrics';

export function FieldMetricsService(
  $q,
  dashboardsApiService,
  metricsServiceFactory
) {
  'ngInject';
  const service = {
    getDashboard,
    getForStore,
  };

  return service;

  function getDashboard() {
    return dashboardsApiService.getDashboard(STORE_METRICS_DASHBOARD_ID);
  }
  function getForStore({ store_id }) {
    return service.getDashboard().then((FMDash) => {
      return $q.all(
        FMDash.contents.metrics.map((m) =>
          metricsServiceFactory({ metricId: m.info.metricId })
            .queryValues({
              operation: 'last',
              groupBy: {
                primary: 'reportId',
                secondary: 'questionId',
              },
              filters: {
                storeId: store_id,
                time: `2000-01-01T00:00:00.000Z/${new Date().toISOString()}`,
              },
              extra: {},
            })
            .then((mValues) => {
              // since operator is 'last' metricValues will be answers
              const hasValues = mValues.entries.length > 0;

              return {
                title: m.info.title,
                metricId: m.info.metricId,
                answers: mValues.entries.map(
                  (entry) => entry.value.metadata.answer
                ),
                question: hasValues
                  ? mValues.entries[0].value.metadata.question
                  : {},
              };
            })
        )
      );
    });
  }
}
