import { ParsedOptions } from 'rrule/dist/esm/src/types';
import { RRule, rrulestr } from 'rrule';
import { RecurrenceService } from './recurrence.service';
import type { ModalService } from '../../../services/Utils/Modal';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import { DateService } from '../../../services/Utils/Dates/date.service';

export class RecurrenceSelectorController implements ng.IComponentController {
  ngModelCtrl: ng.INgModelController;
  startDate: Date;
  onChange: () => void;
  onClose: () => void;
  ngDisabled: boolean;
  recurrence: RRule;
  recurrenceLabel: string;
  isRTLNeeded: boolean;
  /* @ngInject */
  // eslint-disable-next-line max-params
  constructor(
    private localizationService: LocalizationService,
    private $translate: ng.translate.ITranslateService,
    private $timeout: ng.ITimeoutService,
    private modalService: ModalService,
    private dateService: DateService,
    private calendarEventsService,
    private recurrenceService: RecurrenceService
  ) {}

  $onInit(): void {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.recurrence = this.parseRecurrenceRule(this.ngModelCtrl.$modelValue);
    this.computeRecurrenceLabel();
  }

  computeRecurrenceLabel(): void {
    this.$timeout(() => {
      this.recurrenceLabel = this.ngModelCtrl.$modelValue
        ? this.calendarEventsService.getRecurrenceText(
            this.ngModelCtrl.$modelValue
          )
        : this.$translate.instant('RECURRENCE.ONCE');
    });
  }

  onResetClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.reset();
  }

  // eslint-disable-next-line complexity
  $onChanges(changes: ng.IOnChangesObject): void {
    if (changes.ngDisabled && this.ngDisabled === true) {
      this.reset();
    }

    // start date has been changed we have to recalculate recurrence
    if (changes.startDate && this.startDate && this.recurrence) {
      this.recurrence = this.recurrenceService.changeRecurrenceStartDate(
        this.startDate,
        this.recurrence
      );

      if (this.ngModelCtrl.$viewValue) {
        this.updateModelValue(this.recurrence.toString());
      }
    }
  }

  openModal(): ng.IPromise<void> {
    const template = `
        <sf-custom-recurrence-modal
         on-close="$ctrl.onClose()"
         on-save="$ctrl.onSave()"
         resolve="$ctrl.resolve">
        </sf-custom-recurrence-modal>
      `;

    return this.modalService
      .openAsPromise<ParsedOptions>(template, {
        resolve: { options: this.recurrence.options },
      })
      .then((options) => {
        this.recurrence = new RRule(options);
        this.updateModelValue(this.recurrence.toString());
        this.computeRecurrenceLabel();
      });
  }

  parseRecurrenceRule(rule: string): RRule {
    try {
      return rrulestr(rule);
    } catch (e) {
      return new RRule({
        interval: 1,
        freq: RRule.DAILY,
        dtstart: this.dateService.getDateFromString(this.startDate),
        until: this.dateService.getNextYear(),
        byweekday: [this.recurrenceService.getWeekDayNumber(this.startDate)],
      });
    }
  }

  reset(): void {
    this.recurrence = new RRule({
      interval: 1,
      freq: RRule.DAILY,
      dtstart: this.dateService.getDateFromString(this.startDate),
      until: this.dateService.getNextYear(),
    });
    this.updateModelValue(null);
    this.computeRecurrenceLabel();
  }

  updateModelValue(newValue): void {
    this.ngModelCtrl.$setViewValue(newValue);
    this.ngModelCtrl.$setDirty();
    this.ngModelCtrl.$setTouched();
    this.$timeout(() => this.onChange());
  }
}
