// Synchronize events seems  global enough to be declared here.
export const GLOBAL_EVENTS = {
  CLICK: 'CLICK',
  DATA_SYNCED: 'DATA_SYNCED',
  DATA_SYNCED_FAILED: 'DATA_SYNCED_FAILED',
  SYNCHRONIZE_APP: 'SYNCHRONIZE_APP',
  ORGANISATION_SWITCH: 'ORGANISATION_SWITCH',
  STATE_CHANGE_START: '$stateChangeStart',
  STATE_CHANGE_SUCCESS: '$stateChangeSuccess',
  STATE_CHANGE_ERROR: '$stateChangeError',
  DESTROY: '$destroy',
  NETWORK_CONNECTED: 'NETWORK_CONNECTED',
  NETWORK_DISCONNECTED: 'NETWORK_DISCONNECTED',
} as const;

export type GLOBAL_EVENT = typeof GLOBAL_EVENTS[keyof typeof GLOBAL_EVENTS];
