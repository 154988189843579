import { filter } from 'ramda';
import { IMAGE_SIZES } from '../../constants/image-sizes.constant';
import { PICTURE_SYSTEM_IDENTIFIERS } from '../../constants/picture-system.constants';
import type { FilesService } from '../../services/API/files/files.service';
import type { FileSystemService } from '../../services/Utils/FileSystem/file-system.service';
import type {
  IdUrlsHash,
  ImageService,
} from '../../services/Utils/Image/image.service';
import type { ObjectIdService } from '../../services/Utils/Objectid/objectId.service';
import type { PictureSystemService } from '../../services/Utils/PictureSystem/picture-system.service';
import type { PlatformService } from '../../services/Utils/Platform';

interface FileUploaderComponentBindings {
  photosIds: string[];
  onSelect: (evt: SelectEvent) => void;
}

interface ImagesToPreview {
  _id: string;
  url: string;
}

type SelectEvent = {
  $event: {
    photos_ids: string[];
  };
};

export const FileUploaderComponent: ng.IComponentOptions = {
  bindings: {
    photosIds: '<?',
    onSelect: '&',
    maxPhotos: '<',
  },
  templateUrl: 'components/file-uploader/file-uploader.html',
  controller: class FileUploaderController
    implements ng.IComponentController, FileUploaderComponentBindings
  {
    public photosIds: string[];
    public onSelect: (evt: SelectEvent) => void;
    isBrowser: boolean;
    imagesToPreview: ImagesToPreview[];

    /* @ngInject */
    // eslint-disable-next-line max-params
    constructor(
      private objectIdService: ObjectIdService,
      private filesService: FilesService,
      private imageService: ImageService,
      private filesSystemService: FileSystemService,
      private $q: ng.IQService,
      private platformService: PlatformService,
      private $window: ng.IWindowService,
      private keyboardService,
      private pictureSystemService: PictureSystemService,
      private SF_IMAGE_SIZES: typeof IMAGE_SIZES
    ) {}

    $onInit(): void {
      this.isBrowser = this.platformService.isBrowser();
      this.displayImagesToPreview();
    }

    displayImagesToPreview(): ng.IPromise<void> {
      this.imagesToPreview = [];
      this.photosIds = this.photosIds || [];
      return this.imageService
        .getSizedUrlsFromIds(this.photosIds, this.SF_IMAGE_SIZES.SQUARE_LARGE)
        .then((urls: IdUrlsHash) => {
          this.imagesToPreview = Object.entries(urls || {}).map(
            ([_id, url]) => ({
              _id,
              url,
            })
          );
        });
    }

    /**
     * Handles pictures that come from the html file input and upload them
     * On success dispatch an event with actual ids list
     * @param fileOrPaths - Html Files (paths to the files)
     */
    onPictureSelected(
      fileOrPaths: (string | File | Blob)[]
    ): ng.IPromise<void> {
      const newPhotosIds: string[] = [];
      const selectedImages: ImagesToPreview[] = [];

      const promises = fileOrPaths.reduce((acc, fileOrPath) => {
        const fileId = this.objectIdService.create();

        newPhotosIds.push(fileId);

        const promise = (
          typeof fileOrPath === 'string'
            ? this.getBlobByPath(fileOrPath)
            : this.$q.when(fileOrPath)
        ).then((file) => {
          selectedImages.push({
            _id: fileId,
            url: this.getPictureUrl(file),
          });
          return this.filesService.upload(file, fileId);
        });

        return acc.concat(promise);
      }, [] as ng.IPromise<unknown>[]);

      return this.$q.all(promises).then(() => {
        this.photosIds = [...(this.photosIds || []), ...newPhotosIds];
        this.imagesToPreview = [...this.imagesToPreview, ...selectedImages];
        this.onSelect({ $event: { photos_ids: this.photosIds } });
      });
    }

    getBlobByPath(filePath: string): ng.IPromise<Blob> {
      const path = this.filesSystemService.fixPath(filePath);
      const pathInfos = this.filesSystemService.getPathInfos(path);

      return this.filesSystemService.getUrlToBlobFile(
        pathInfos.path,
        pathInfos.name
      );
    }

    takePicture(): ng.IPromise<void> {
      this.keyboardService.hide();
      return this.pictureSystemService
        .selectSourceType([
          PICTURE_SYSTEM_IDENTIFIERS.IMAGE,
          PICTURE_SYSTEM_IDENTIFIERS.LIBRARY,
        ])
        .then((source) =>
          this.pictureSystemService.getPictureFromSource(source)
        )
        .then((fileOrPath) => this.onPictureSelected(fileOrPath));
    }

    getPictureUrl(blob: Blob): string {
      return this.$window.URL.createObjectURL(blob);
    }

    /**
     * Delete photo from a list and dispatch an event with actual ids list.
     */
    deletePhoto(photo: ImagesToPreview): void {
      this.imagesToPreview = filter(
        (p) => p._id !== photo._id,
        this.imagesToPreview
      );
      this.photosIds = filter((id) => id !== photo._id, this.photosIds);

      this.onSelect({ $event: { photos_ids: this.photosIds } });
    }
  },
};
