// Services
import { PlaceService } from './services/place.service';
import { PlacePopupService } from './services/place-popup.service';
// Components
import { AutofillButtonComponent } from './components/place-add/components/autofill-button.component';
import { PlaceAddComponent } from './components/place-add/place-add.component';
import { PlaceCalendarEventsListComponent } from './components/place-calendar-events/place-calendar-events-list/place-calendar-events-list.component';
import { PlaceDocumentsComponent } from './components/place-documents/place-documents.component';
import { PlaceInfosComponent } from './components/place-infos/place-infos.component';
import { PlacesInfosEditComponent } from './components/place-infos-edit/place-infos-edit.component';
import { PlaceMissionsComponent } from './components/place-missions/place-missions.component';
import { PlaceCampaignsComponent } from './components/place-campaigns/place-campaigns.component';
import { PlaceMissionsSectionComponent } from './components/place-missions-section/place-missions-section.component';
import { PlaceMissionsListComponent } from './components/place-missions-list/place-missions-list.component';
import { PlaceCampaignsSectionComponent } from './components/place-campaigns-section/place-campaigns-section.component';
import { PlaceCampaignsListComponent } from './components/place-campaigns-list/place-campaigns-list.component';
import { PlaceParamsComponent } from './components/place-params/place-params.component';
import { PlaceFieldMetricsComponent } from './components/place-field-metrics/place-field-metrics.component';
import { PlaceSettingsModalComponent } from './components/place-settings-modal/place-settings-modal.component';
import { PlaceActivityComponent } from './components/place-activity/place-activity.component';
import { PlaceAssetsComponent } from './components/place-assets/place-assets.component';
import { PlaceGalleryComponent } from './components/place-gallery/place-gallery.component';
import { PlaceReportsListComponent } from './components/place-reports-list/place-reports-list.component';
import { PlaceReportsComponent } from './components/place-reports/place-reports.component';
import { PlaceHeaderComponent } from './components/place-header/place-header.component';
import { PlaceTasksSectionComponent } from './components/place-tasks/place-tasks-section/place-tasks-section.component';
import { PlaceTasksTabsComponent } from './components/place-tasks/place-tasks-tabs/place-tasks-tabs.component';
import { PlaceComponent } from './place.component';
import { PlaceAnalyticsComponent } from './components/place-analytics/place-analytics.component';
import { PlaceAddressSuggestComponent } from './components/place-address-suggest/place-address-suggest.component';

export default angular
  .module('sfMobile.components.place', [
    'sfMobile.services.localization',
    'sfMobile.constants.feature-flags',
    'sfMobile.services.reports',
    'sfMobile.services.missions',
    'sfMobile.services.forms',
    'sfMobile.services.places',
    'sfMobile.services.places-params',
    'sfMobile.services.tasks',
    'sfMobile.services.task',
    'sfMobile.services.calendar-events-notifications',
    'sfMobile.services.address-format',
    'sfMobile.services.segment',
    'sfMobile.services.helpers',
    'sfMobile.services.dates',
    'sfMobile.services.calendar-events',
    'sfMobile.services.documents',
    'sfMobile.services.modal',
    'sfMobile.services.popup',
    'sfMobile.services.preferences',
    'sfMobile.services.popup-request',
    'sfMobile.services.action-sheet',
    'sfMobile.services.error-messages',
    'sfMobile.services.geolocation',
    'sfMobile.services.html',
    'sfMobile.services.field-metrics',

    'sfMobile.activity',

    'sfMobile.components.content',
    'sfMobile.components.view-states-search',
    'sfMobile.components.button-more',
    'sfMobile.components.button-header-add',
    'sfMobile.components.button-header-close',
    'sfMobile.components.document-item',
    'sfMobile.reactive-campaigns',
    'sfMobile.components.campaign-item',
    'sfMobile.components.item-inline',
    'sfMobile.components.week-navigation',
    'sfMobile.components.badge-beta',
    'sfMobile.calendar-events.components',

    'sfMobile.components.reports-list',
    'sfMobile.components.gallery-list',
    'sfMobile.services.platform',
    'sfMobile.services.pubsub',
    'sfMobile.services.searchUtils',
  ])
  .component('sfAutofillButton', AutofillButtonComponent)
  .component('sfPlaceAdd', PlaceAddComponent)
  .component('sfPlaceCalendarEventsList', PlaceCalendarEventsListComponent)
  .component('sfPlaceDocuments', PlaceDocumentsComponent)
  .component('sfPlaceFieldMetrics', PlaceFieldMetricsComponent)
  .component('sfPlaceInfos', PlaceInfosComponent)
  .component('sfPlaceInfosEdit', PlacesInfosEditComponent)
  .component('sfPlaceMissions', PlaceMissionsComponent)
  .component('sfPlaceCampaigns', PlaceCampaignsComponent)
  .component('sfPlaceMissionsSection', PlaceMissionsSectionComponent)
  .component('sfPlaceMissionsList', PlaceMissionsListComponent)
  .component('sfPlaceCampaignsSection', PlaceCampaignsSectionComponent)
  .component('sfPlaceCampaignsList', PlaceCampaignsListComponent)
  .component('sfPlaceParams', PlaceParamsComponent)
  .component('sfPlaceSettingsModal', PlaceSettingsModalComponent)
  .component('sfPlaceActivity', PlaceActivityComponent)
  .component('sfPlaceAssets', PlaceAssetsComponent)
  .component('sfPlaceGallery', PlaceGalleryComponent)
  .component('sfPlaceReportsList', PlaceReportsListComponent)
  .component('sfPlaceReports', PlaceReportsComponent)
  .component('sfPlaceHeader', PlaceHeaderComponent)
  .component('sfPlaceTasksSection', PlaceTasksSectionComponent)
  .component('sfPlaceTasksTabs', PlaceTasksTabsComponent)
  .component('sfPlace', PlaceComponent)
  .component('sfPlaceAnalytics', PlaceAnalyticsComponent)
  .component('sfPlaceAddressSuggest', PlaceAddressSuggestComponent)
  .service('placePopupService', PlacePopupService)
  .service('placeService', PlaceService).name;
