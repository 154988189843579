export function FormSheetService(
  $translate,
  actionSheetService,
  $q,
  platformService,
  sfFeatureFlagsService,
  SF_FEATURE_FLAGS
) {
  'ngInject';

  const methods = {
    exitSheet: null,
    deleteSheet: null,
    statusSheet: null,

    openExit,
    openCancel,
    openDeleteConfirm,
  };

  /**
   * Display action sheet to select the action to do on screen leaving.
   * @return {Object} - Promise of the selection
   */
  function openExit(useNewCampaigns = true) {
    const isDraftReportsFlagEnabled = sfFeatureFlagsService.hasFeature(
      SF_FEATURE_FLAGS.DRAFT_REPORTS
    );

    const exitPromise = $q.defer();
    const actionSheetConfig = {
      cancelText: $translate.instant('FORM_ACTION_CANCEL'),
      destructiveText: $translate.instant('FORM_ACTION_DELETE'),
      isValidation: true,
    };
    const actionSheetActions =
      !platformService.isBrowser() || isDraftReportsFlagEnabled
        ? [
            {
              text: $translate.instant('FORM_ACTION_SAVE'),
              onClick: () => exitPromise.resolve('save'),
            },
          ]
        : [];
    const onCancelClick = () => exitPromise.reject('cancel_exit');
    const onDestructiveClick = () =>
      methods
        .openDeleteConfirm(useNewCampaigns)
        .then((reason) => exitPromise.resolve(reason))
        .catch(() => exitPromise.reject());

    actionSheetService.open(
      actionSheetActions,
      actionSheetConfig,
      onCancelClick,
      onDestructiveClick
    );

    return exitPromise.promise;
  }
  function openDeleteConfirm(useNewCampaigns = true) {
    const confirmPromise = $q.defer();
    const actionSheetConfig = {
      title: useNewCampaigns
        ? $translate.instant('FORM_ACTION_DELETE_TITLE_CHECKLISTS')
        : $translate.instant('FORM_ACTION_DELETE_TITLE'),
      cancelText: $translate.instant('FORM_ACTION_DELETE_CANCEL'),
      destructiveText: $translate.instant('FORM_ACTION_DELETE_VALIDATE'),
      isValidation: true,
    };
    const actionSheetActions = [
      {
        text: $translate.instant('FORM_ACTION_EXIT'),
        onClick: () => confirmPromise.resolve('cancel_delete'),
      },
    ];
    const onCancelClick = () => confirmPromise.reject('cancel_delete');
    const onDestructiveClick = () => confirmPromise.resolve('delete');

    methods.deleteSheet = actionSheetService.open(
      actionSheetActions,
      actionSheetConfig,
      onCancelClick,
      onDestructiveClick
    );

    return confirmPromise.promise;
  }

  function openCancel() {
    const cancelPromise = $q.defer();
    const actionSheetConfig = {
      cancelText: $translate.instant('FORM_ACTION_CANCEL'),
      isValidation: true,
    };
    const actionSheetActions = [
      {
        text: $translate.instant('FORM_ACTION_EXIT'),
        onClick: () => cancelPromise.resolve(),
      },
    ];
    const onCancelClick = () => cancelPromise.reject();

    actionSheetService.open(
      actionSheetActions,
      actionSheetConfig,
      onCancelClick
    );

    return cancelPromise.promise;
  }

  return methods;
}
