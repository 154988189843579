import EntitySelectorModalModule from './../entity-selector/components/entity-selector-modal/entity-selector-modal.module';
import { EntitySelectorService } from './../entity-selector/services/entity-selector.service';
import { EventChecklistSelectorComponent } from './event-checklist-selector.component';

export const EventChecklistSelectorModule = angular
  .module('sfMobile.components.event-checklist-selector', [
    'sfMobile.services.localization',
    'sfMobile.services.api-utils',
    'sfMobile.services.static-requests-paginate',
    'sfMobile.components.entity-selector-modal',
    EntitySelectorModalModule,
  ])
  .service('entitySelectorService', EntitySelectorService)
  .component('sfEventChecklistSelector', EventChecklistSelectorComponent).name;
