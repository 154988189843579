export const ChatAvatarComponent = {
  bindings: {
    channel: '<',
    profile: '<',
    size: '@?',
  },
  templateUrl: 'chat/components/chat-avatar/chat-avatar.html',
  controller: function ChatAvatarController(chatService) {
    'ngInject';
    this.$onInit = () => {
      const coverUrl = this.channel.coverUrl;

      this.is1to1Channel = chatService.is1to1Channel(this.channel);
      this.channelUser = this.is1to1Channel
        ? this.getUserInfo(this.channel)
        : null;
      this.circleSize = this.size ? `circle-${this.size}` : 'circle-medium';
      this.coverUrl = !this.is1to1Channel && coverUrl ? coverUrl : null;
    };

    this.getUserInfo = (channel) => {
      const user = chatService.getOtherUsers(channel, this.profile)[0];

      if (!user) {
        return null;
      }
      const { firstName, lastName, avatar_id } =
        chatService.getMemberMetadata(user);

      return {
        _id: user.userId,
        contents: {
          firstName,
          lastName,
          avatar_id,
        },
      };
    };
  },
};
