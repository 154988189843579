export function MerchandisingService(SF_MERCHANDISING_STATES) {
  'ngInject';

  const methods = {
    canBeValidated,
    canBePutInPending,
    canBeRetook,
    isPending,
    getQuestionTitle,
    getValidations,
  };

  function canBeValidated(profile, validation) {
    return isAnalyst(profile, validation) && isPending(validation);
  }
  function canBePutInPending(profile, validation) {
    return isAnalyst(profile, validation) && !isPending(validation);
  }
  function canBeRetook(profile, validation) {
    const globalPreference = validation.form.contents.preferences.find(
      (preference) => preference.name === 'allowEdit'
    );
    const byOwnerPreference = validation.form.contents.preferences.find(
      (preference) => preference.name === 'allowEditByOwner'
    );
    const byAnalystPreference = validation.form.contents.preferences.find(
      (preference) => preference.name === 'allowEditByAnalyst'
    );
    const byRespondentPreference = validation.form.contents.preferences.find(
      (preference) => preference.name === 'allowEditByRespondent'
    );
    const isAllowedGlobally = Boolean(
      isRespondent(profile, validation) &&
        globalPreference &&
        globalPreference.value
    );
    const isAllowedByOwner = Boolean(
      isOwner(profile, validation) &&
        byOwnerPreference &&
        byOwnerPreference.value
    );
    const isAllowedByAnalyst = Boolean(
      isAnalyst(profile, validation) &&
        byAnalystPreference &&
        byAnalystPreference.value
    );
    const isAllowedByRespondent = Boolean(
      isRespondent(profile, validation) &&
        byRespondentPreference &&
        byRespondentPreference.value
    );

    return (
      isRejected(validation) &&
      (isAllowedGlobally ||
        isAllowedByOwner ||
        isAllowedByAnalyst ||
        isAllowedByRespondent)
    );
  }
  function isPending(validation) {
    return validation.contents.state === SF_MERCHANDISING_STATES.PENDING;
  }
  function getQuestionTitle(questionId, questions) {
    const question = questions.filter((q) => q._id === questionId);

    return question.length ? question[0].title : '';
  }
  function getValidations(validation) {
    return hasAnswers(validation) && hasQuestionId(validation)
      ? validation.report.contents.answers.filter(
          (answer) => answer.question_id === validation.contents.question_id
        )
      : [];

    function hasAnswers() {
      return (
        validation &&
        validation.report &&
        validation.report.contents &&
        validation.report.contents.answers
      );
    }
    function hasQuestionId() {
      return (
        validation && validation.contents && validation.contents.question_id
      );
    }
  }

  // Helpers
  function isRejected(validation) {
    return validation.contents.state === SF_MERCHANDISING_STATES.REJECTED;
  }
  function isOwner(profile, validation) {
    return (
      validation.form.contents.owner_ids &&
      validation.form.contents.owner_ids.includes(profile._id)
    );
  }
  function isAnalyst(profile, validation) {
    return (
      validation.form.contents.analysts_ids &&
      validation.form.contents.analysts_ids.includes(profile._id)
    );
  }
  function isRespondent(profile, validation) {
    return validation.report.contents.user_id === profile._id;
  }

  return methods;
}
