import { ContentController } from './content.controller';

export const ContentComponent = {
  bindings: {
    key: '@?',
    currentStickyHandle: '<',
    hasMultipleSticky: '<',
    parallaxImage: '@?',
    forceJsScroll: '<?',
    onScroll: '&',
    onRefresh: '&',
    refresher: '<?',
    parallaxHeight: '<?',
  },
  require: {
    viewController: '^sfView',
  },
  transclude: true,
  templateUrl: 'components/Layout/content/content.html',
  controller: ContentController,
};
