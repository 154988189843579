import SendBird from 'sendbird';

const CHAT_ORG_SETTING_APP_ID = 'settings.sendbirdAppId';

const CHAT_ROOM_NB_MESSAGES_TO_LOAD = 20;
const CHAT_NB_USERS_1_TO_1 = 2;
const CHAT_MAX_UNREAD_MESSAGES_NUMBER = 99;
const CHAT_ONE_TO_ONE_CUSTOM_TYPE = 'oneToOneChannel'; // Warning: same constant defined in sf-backend
const CHAT_GROUP_CUSTOM_TYPE = 'groupChannel'; // Warning: same constant defined in sf-backend
const CHAT_CHANNEL_USER_NUMBER_LIMIT = 100;
const CONVERSATION_TITLE_MAX_LENGTH = 25;

const SENDBIRD_ERRROR_USER_IS_NOT_AUTHORIZED = 400108;
const SENDBIRD_ERRROR_CHANNEL_DOES_NOT_EXIST = 400201;

const SENDBIRD_CONNECTION_CONNECTING_KEY = 'CONNECTING';

const CUSTOM_TYPES = {
  IMAGE: 'FILE_IMAGE',
  DOCUMENT: 'FILE_DOC',
  AUTO: 'SENDBIRD:AUTO_EVENT_MESSAGE',
};

// eslint-disable-next-line max-params
export function ChatService(
  $q,
  $translate,
  $log,
  dateService,
  sfFeatureFlagsService,
  organisationsService,
  usersService,
  SF_FEATURE_FLAGS
) {
  'ngInject';
  const methods = {
    _initPromise: null,
    _connectP: null,
    _user: null,
    SendBird,
    unreadMessageCount: 0,
    getSendbirdId,
    // User
    init,
    reset,
    login,
    hasFeatureFlag,
    canUseChat,
    // Channel
    getChannelList,
    getChannel,
    getUserChannel,
    createChannel,
    updateChannelName,
    updateChannel,
    refreshChannel,
    leaveChannel,
    inviteChannelMembers,
    getChannelMessages,
    sendChannelMessage,
    sendChannelFileMessage,
    // Listener
    connectionListener,
    // Events
    onChannelChanged,
    onChannelMessageReceived,
    onChannelCreated,
    onLeave,
    onUserLeft,
    onUserJoined,
    onChannelDeleted,
    onMessageDeleted,
    // Helpers
    updateUnreadMessageCount,
    getLoginPromise,
    isConnecting,
    // - member
    getMemberMetadata,
    // - unread message
    getUnreadMessageCount,
    getNbUnreadCount,
    // - channel
    is1to1Channel,
    getOtherUsers,
    getChannelName,
    // - message
    isMessageFile,
    isMessageAuto,
    isMessageImage,
    isMessageDocument,
    // Constants
    CHAT_CHANNEL_USER_NUMBER_LIMIT,
    CONVERSATION_TITLE_MAX_LENGTH,
    SENDBIRD_ERRROR_USER_IS_NOT_AUTHORIZED,
    SENDBIRD_ERRROR_CHANNEL_DOES_NOT_EXIST,
  };
  let _sb = {};

  function hasFeatureFlag() {
    return sfFeatureFlagsService.hasFeature(SF_FEATURE_FLAGS.CHAT);
  }
  function getSendbirdId() {
    const hasFeatureFlagActivated = hasFeatureFlag();

    if (!hasFeatureFlagActivated) {
      return $q.reject();
    }

    return organisationsService
      .getPreference(CHAT_ORG_SETTING_APP_ID)
      .then((pref) => (pref ? $q.when(pref) : $q.reject()));
  }

  function init() {
    if (methods._initPromise) {
      return methods._initPromise;
    }

    methods._initPromise = getSendbirdId()
      .then((sendbirdAppId) => {
        _sb = new methods.SendBird({ appId: sendbirdAppId });
        return _sb;
      })
      .catch((err) => {
        methods._initPromise = null;
        throw err;
      });

    return methods._initPromise;
  }

  function reset() {
    init().then((sb) => {
      sb.disconnect();
    });
    methods._initPromise = null;
    methods._user = null;
    methods._connectP = null;
    _sb = null;
    methods.unreadMessageCount = 0;
  }

  function login(user) {
    methods._user = user;

    methods._connectP = methods.init().then((sb) =>
      usersService.getSendbirdToken().then(({ token }) =>
        $q((resolve, reject) => {
          try {
            // on old browsers / android devices there is an unhandled error thrown from connect method
            sb.connect(user._id, token, (sbUser, cError) => {
              const { firstName, lastName, avatar_id } = user.contents;
              const name = `${firstName} ${lastName}`;

              if (cError) {
                $log.error(cError, user);
                return reject(cError);
              }

              resolve(sbUser);

              sb.updateCurrentUserInfo(name, '' /* profileUrl */);
              return sbUser.updateMetaData(
                {
                  firstName,
                  lastName,
                  avatar_id,
                },
                true, //upsert
                () => {} //commonCallback
              );
            });
          } catch (error) {
            $log.error(error, user);
            return reject(error);
          }
        })
      )
    );

    // Update the tab bar notification number
    methods._connectP.then(() => {
      methods.updateUnreadMessageCount();
      methods.onChannelChanged(methods.updateUnreadMessageCount, 'CHAT_TAB');
      methods.onLeave(user._id, methods.updateUnreadMessageCount, 'CHAT_TAB');
    });

    return methods._connectP;
  }

  function getLoginPromise() {
    return (
      methods._connectP ||
      login(methods._user).catch((err) => {
        methods._connectP = null;
        throw err;
      })
    );
  }

  function isConnecting() {
    return _sb.getConnectionState() === SENDBIRD_CONNECTION_CONNECTING_KEY;
  }

  function updateUnreadMessageCount() {
    return methods.getUnreadMessageCount().then((unreadMessageCount) => {
      methods.unreadMessageCount = unreadMessageCount;
      return unreadMessageCount;
    });
  }

  function getChannelList(filter = {}) {
    const castedFilters = castFilter(filter);

    return combineChannelListRequests(castedFilters);
  }

  function getChannel(channelUrl) {
    return getLoginPromise().then(() =>
      $q((resolve, reject) => {
        _sb.GroupChannel.getChannel(channelUrl, (channel, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(channel);
          }
        });
      })
    );
  }

  // TODO: refactor it, once we have more custom params
  function castFilter(filter) {
    let castedFilters;

    if (filter.channelNameOrUserNickname) {
      castedFilters = [
        {
          channelNameContainsFilter: filter.channelNameOrUserNickname,
          customTypeFilter: CHAT_GROUP_CUSTOM_TYPE,
        },
        {
          nicknameContainsFilter: filter.channelNameOrUserNickname,
          customTypeFilter: CHAT_ONE_TO_ONE_CUSTOM_TYPE,
        },
      ];

      return castedFilters.map((castedFilter) =>
        Object.assign(castedFilter, filter)
      );
    }

    return [filter];
  }

  function combineChannelListRequests(requestFilters) {
    return getNextChannels(
      requestFilters.map((filter) => callChannelList(filter))
    );

    function getNextChannels(matchingChannelsPromises) {
      return $q.all(matchingChannelsPromises).then((searchedChannels) => {
        const order = requestFilters[0] && requestFilters[0].order;
        const entities = mergeUniqueChannelsLists(
          searchedChannels.map((channel) => channel.entities)
        ).sort(getChannelComparator(order));

        return {
          entities,
          next: () =>
            getNextChannels(
              searchedChannels
                .filter((channel) => channel.hasNext)
                .map((channel) => channel.next())
            ),
          hasNext: searchedChannels.some((channel) => channel.hasNext),
        };
      });
    }
    function mergeUniqueChannelsLists(channelsLists) {
      return []
        .concat(...channelsLists)
        .filter(
          (channel, index, self) =>
            self.findIndex((chat) => chat.url === channel.url) === index
        );
    }
    function getChannelComparator(orderType) {
      const channelComparators = {
        latest_last_message: (a, b) =>
          dateService.compareDates(getLastDate(a), getLastDate(b)),
        default: () => 0,
      };

      if (
        !Object.prototype.hasOwnProperty.call(channelComparators, orderType)
      ) {
        return channelComparators.default;
      }

      return channelComparators[orderType];

      function getLastDate(channel) {
        const lastMessage = channel.lastMessage;

        return lastMessage ? lastMessage.createdAt : channel.createdAt;
      }
    }
  }

  function getNextChannels(channelListQuery) {
    return $q((resolve, reject) => {
      channelListQuery.next((channelList, error) => {
        if (error) {
          reject(error);
          return;
        }

        resolve({
          entities: channelList,
          next: () => getNextChannels(channelListQuery),
          hasNext: channelListQuery.hasNext,
        });
      });
    });
  }

  function callChannelList(filter) {
    return getLoginPromise().then(() => {
      let channelListQuery = _sb.GroupChannel.createMyGroupChannelListQuery();

      channelListQuery = Object.assign(channelListQuery, filter);

      return getNextChannels(channelListQuery);
    });
  }

  function getUserChannel(userId) {
    return getLoginPromise().then(() => {
      let channelListQuery = _sb.GroupChannel.createMyGroupChannelListQuery();

      // https://docs.sendbird.com/javascript/group_channel#3_filter_channels_by_user_ids
      channelListQuery.userIdsExactFilter = [userId]; // filter for 1-on-1 group channels with userId

      return getNextChannels(channelListQuery).then((nextChannels) => {
        if (nextChannels.entities && nextChannels.entities[0]) {
          return nextChannels.entities[0];
        }
        return null;
      });
    });
  }

  function createChannel(usersIds, name = '') {
    if (!_sb || !_sb.GroupChannel) {
      return $q.reject(
        'The chat feature has not been initialized, please refer to an' +
          ' administrator.'
      );
    }

    return $q((resolve, reject) => {
      const userIds = usersIds;
      const isGroupChannel = usersIds.length > CHAT_NB_USERS_1_TO_1;
      const isDistinct = !isGroupChannel;

      _sb.GroupChannel.createChannelWithUserIds(
        userIds,
        isDistinct,
        name,
        '', // coverUrlOrImageFile
        null,
        isGroupChannel ? CHAT_GROUP_CUSTOM_TYPE : CHAT_ONE_TO_ONE_CUSTOM_TYPE,
        (createdChannel, error) => {
          if (error) {
            reject(error);
            return error;
          }

          resolve(createdChannel);

          return createdChannel;
        }
      );
    });
  }

  function updateChannelName(channel, name) {
    return methods.updateChannel(channel, null, name, null, null, null);
  }

  // eslint-disable-next-line max-params
  function updateChannel(
    channel,
    isDistinct,
    name,
    coverUrlOrImage,
    data,
    customType
  ) {
    return $q((resolve, reject) =>
      channel.updateChannel(
        isDistinct,
        name,
        coverUrlOrImage,
        data,
        customType,
        (editedChannel, error) => {
          if (error) {
            reject(error);
            return error;
          }

          resolve(editedChannel);

          return editedChannel;
        }
      )
    );
  }

  function refreshChannel(channel) {
    return $q((resolve, reject) =>
      channel.refresh((refreshedChannel, error) => {
        if (error) {
          reject(error);
          return error;
        }

        resolve(refreshedChannel);

        return refreshedChannel;
      })
    );
  }

  function leaveChannel(channel) {
    return $q((resolve, reject) => {
      channel.leave((response, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(response);
        }
      });
    });
  }
  function inviteChannelMembers(channel, usersIds) {
    return $q((resolve, reject) => {
      channel.inviteWithUserIds(usersIds, (response, error) => {
        if (error) {
          reject(error);
          return error;
        }

        resolve(response);
        return response;
      });
    });
  }

  function getChannelMessages(channel) {
    const messageListQuery = channel.createPreviousMessageListQuery();

    return getNextMessages();

    function getNextMessages() {
      return $q((resolve, reject) => {
        messageListQuery.load(
          CHAT_ROOM_NB_MESSAGES_TO_LOAD,
          false,
          (messageList, error) => {
            if (error) {
              reject(error);
              return;
            }

            resolve({
              entities: messageList,
              next: () => getNextMessages(),
              hasNext: messageListQuery.hasMore,
            });
          }
        );
      });
    }
  }

  function sendChannelMessage(message, channel) {
    return $q((resolve, reject) => {
      channel.sendUserMessage(message, null, null, (newMessage, error) => {
        if (error) {
          reject(error);
          return;
        }

        resolve(newMessage);
      });
    });
  }

  function sendChannelFileMessage(file, channel, onProgress) {
    const promise = $q.defer();
    const isFileImage = (formFile) =>
      formFile.type && formFile.type.includes('image/');
    let thumbnailSizes = isFileImage(file)
      ? [
          { maxWidth: 300, maxHeight: 150 },
          { maxWidth: 1000, maxHeight: 500 },
        ]
      : [];
    const fileParams = new _sb.FileMessageParams();

    fileParams.file = file;
    fileParams.fileName = file.name;
    fileParams.fileSize = file.size;
    fileParams.mimeType = file.type;
    fileParams.customType = isFileImage(file)
      ? CUSTOM_TYPES.IMAGE
      : CUSTOM_TYPES.DOCUMENT;
    fileParams.thumbnailSizes = thumbnailSizes;

    const sentFileMessage = channel.sendFileMessage(
      fileParams,
      (event) => {
        let percent = Math.floor((event.loaded / event.total) * 100);

        return onProgress && onProgress(percent);
      },
      (fileMessage, error) => {
        if (error) {
          promise.reject(error);
          return;
        }

        promise.resolve(fileMessage);
      }
    );

    return {
      promise: promise.promise,
      abort: () => channel.cancelUploadingFileMessage(sentFileMessage.reqId),
    };
  }

  function onChannelChanged(cb, eventKey) {
    const channelKey = `ON_CHANNEL_CHANGED_${eventKey || 'DEFAULT'}`;

    return channelListener('onChannelChanged', channelKey, (channel) =>
      cb(channel)
    );
  }

  function onChannelCreated(userId, cb, eventKey) {
    const channelKey = `ON_CHANNEL_CREATED_${eventKey || 'DEFAULT'}`;

    return channelListener('onUserJoined', channelKey, (groupChannel, user) => {
      if (userId === user.userId) {
        return cb(groupChannel, user);
      }
      return null;
    });
  }

  function onLeave(userId, cb, eventKey) {
    const channelKey = `ON_LEAVE_CHANNEL_${eventKey || 'DEFAULT'}`;

    return channelListener('onUserLeft', channelKey, (groupChannel, user) => {
      if (userId === user.userId) {
        return cb(groupChannel, user);
      }
      return null;
    });
  }

  function onUserJoined(cb, eventKey) {
    const channelKey = `ON_USER_JOINED_${eventKey || 'DEFAULT'}`;

    return channelListener('onUserJoined', channelKey, cb);
  }

  function onUserLeft(cb, eventKey) {
    const channelKey = `ON_USER_LEFT_${eventKey || 'DEFAULT'}`;

    return channelListener('onUserLeft', channelKey, cb);
  }

  function onChannelDeleted(cb, eventKey) {
    const channelKey = `ON_CHANNEL_DELETED_${eventKey || 'DEFAULT'}`;

    return channelListener('onChannelDeleted', channelKey, (channel) =>
      cb(channel)
    );
  }

  function onChannelMessageReceived(channel, cb, eventKey) {
    const channelKey = `ON_MESSAGE_RECEIVED_${eventKey || 'DEFAULT'}`;

    return channelListener(
      'onMessageReceived',
      channelKey,
      (messageChannel, message) => {
        if (channel && channel.url === messageChannel.url) {
          return cb(message);
        }
        return null;
      }
    );
  }

  function onMessageDeleted(channel, cb, eventKey) {
    const channelKey = `ON_MESSAGE_DELETED_${eventKey || 'DEFAULT'}`;

    return channelListener(
      'onMessageDeleted',
      channelKey,
      (messageChannel, messageId) => {
        if (channel && channel.url === messageChannel.url) {
          return cb(messageId);
        }
        return null;
      }
    );
  }

  function channelListener(channelEventKey, listenerKey, cb) {
    const ChannelHandler = new _sb.ChannelHandler();

    ChannelHandler[channelEventKey] = cb;
    _sb.addChannelHandler(listenerKey, ChannelHandler);

    return () => {
      _sb.removeChannelHandler(listenerKey);
    };
  }

  function connectionListener(
    onReconnectStarted,
    onReconnectSucceeded,
    onReconnectFailed,
    key
  ) {
    const eventKey = `ON_CONNECTION_RECONNECT_${key || 'DEFAULT'}`;
    const ConnectionHandler = new _sb.ConnectionHandler();

    ConnectionHandler.onReconnectStarted = onReconnectStarted;
    ConnectionHandler.onReconnectSucceeded = onReconnectSucceeded;
    ConnectionHandler.onReconnectFailed = onReconnectFailed;

    _sb.addConnectionHandler(eventKey, ConnectionHandler);

    return () => {
      _sb.removeConnectionHandler(eventKey);
    };
  }

  function getMemberMetadata(member) {
    const [firstName, lastName] = member.nickname.split(' ');
    const { metaFirstName, metaLastName } = member.metaData || {};

    return {
      firstName: metaFirstName || firstName,
      lastName: metaLastName || lastName,
      nickname: member.nickname,
      avatar_id: member.avatar_id
        ? member.avatar_id
        : member.metaData.avatar_id,
    };
  }

  function getUnreadMessageCount() {
    return $q((resolve, reject) => {
      _sb.GroupChannel.getTotalUnreadMessageCount(
        (unreadMessageCount, error) => {
          if (error) {
            reject(error);
            return error;
          }
          resolve(unreadMessageCount);
          return unreadMessageCount;
        }
      );
    });
  }

  function getNbUnreadCount(unreadMessagesCount) {
    if (!unreadMessagesCount) {
      return '';
    }
    if (unreadMessagesCount > CHAT_MAX_UNREAD_MESSAGES_NUMBER) {
      return '99';
    }
    return unreadMessagesCount;
  }

  function getChannelName(channel, profile) {
    if (is1to1Channel(channel)) {
      const user = channel.members.filter(
        (member) => member.userId !== profile._id
      )[0];

      return user ? user.nickname : $translate.instant('CHAT_DELETED_USER');
    }

    return channel.name;
  }

  function getOtherUsers(channel, profile) {
    const user = channel.members.filter(
      (member) => member.userId !== profile._id
    );

    return user;
  }

  function is1to1Channel(channel) {
    return channel.customType === CHAT_ONE_TO_ONE_CUSTOM_TYPE;
  }

  function isMessageFile(message) {
    return message && message.messageType === 'file';
  }
  function isMessageAuto(message) {
    return message.customType === CUSTOM_TYPES.AUTO;
  }
  function isMessageImage(message) {
    return message.customType === CUSTOM_TYPES.IMAGE;
  }

  function isMessageDocument(message) {
    return message.customType === CUSTOM_TYPES.DOCUMENT;
  }

  function canUseChat() {
    return methods
      .getSendbirdId()
      .then(() => true)
      .catch(() => false);
  }

  return methods;
}
