export const MerchandisingPhotoSelectorComponent = {
  bindings: {
    answers: '<',
    selectedAnswers: '=',
    limitSelection: '<',
  },
  templateUrl:
    'merchandising/components/merchandising-photo-selector/merchandising-photo-selector.html',
  controller: function MerchandisingPhotoSelectorController(
    imageService,
    SF_IMAGE_SIZES
  ) {
    'ngInject';
    this.$onInit = () => {
      this.selectedAnswers = [];
      this.answersPicturePathHash = {};

      return imageService
        .getSizedUrlsFromIds(
          this.answers.map((answer) => answer.values[0].value),
          SF_IMAGE_SIZES.SQUARE_LARGE
        )
        .then((urls) => {
          this.answersPicturePathHash = urls;
        });
    };

    this.selectAnswer = (answer, index) => {
      if (this.isAnswerSelected(answer)) {
        this.selectedAnswers = this.selectedAnswers.filter(
          (selectedAnswer) => selectedAnswer.answer._id !== answer._id
        );
        return this.selectedAnswers;
      }
      if (this.limitSelection) {
        this.selectedAnswers = [{ index, answer }];
        return this.selectedAnswers;
      }
      this.selectedAnswers = this.selectedAnswers.concat({ index, answer });
      return this.selectedAnswers;
    };

    this.isAnswerSelected = (answer) =>
      this.selectedAnswers.some(
        (selectedAnswer) => selectedAnswer.answer._id === answer._id
      );
  },
};
