import moment from 'moment-timezone';

const ALL_DAY_NOTIFICATE_BEFORE_HOURS = 5;
const NON_ALL_DAY_NOTIFICATE_BEFORE_HOURS = 1;

// eslint-disable-next-line max-params
export function CalendarEventsNotificationsService(
  $translate,
  localNotificationsService,
  calendarEventsService,
  dateFormatService
) {
  'ngInject';
  const methods = {
    moment,
    schedule,
    unschedule,
  };

  calendarEventsService.crud.registerHook('refresh:after', (events) => {
    events.forEach((event) => {
      schedule(event);
    });
    return events;
  });

  function schedule(event) {
    const notificationId = makeNotificationId(event._id);

    let startDateTime = methods.moment
      .tz(event.contents.start_dateTime, event.contents.timezone.name)
      .startOf('minute');
    let endDateTime = methods.moment
      .tz(event.contents.end_dateTime, event.contents.timezone.name)
      .startOf('minute');
    let notifyAtDateTime;

    if (event.contents.allDayLong) {
      notifyAtDateTime = startDateTime
        .clone()
        .startOf('day')
        .subtract(ALL_DAY_NOTIFICATE_BEFORE_HOURS, 'hour');
    } else {
      notifyAtDateTime = startDateTime
        .clone()
        .subtract(NON_ALL_DAY_NOTIFICATE_BEFORE_HOURS, 'hour');
    }

    if (notifyAtDateTime.isAfter()) {
      localNotificationsService.schedule({
        id: notificationId,
        title: getEventNotificationTitle(event),
        body: getEventNotificationSubtitle(
          event.contents.allDayLong,
          startDateTime,
          endDateTime
        ),
        schedule: { at: notifyAtDateTime.toDate() },
      });
    }
  }

  function unschedule(event) {
    localNotificationsService.cancel([makeNotificationId(event._id)]);
  }

  function makeNotificationId(objectIdString) {
    // obtain datetime part of the object id
    return parseInt(objectIdString.substring(0, 8), 16);
  }

  function getEventNotificationTitle(event) {
    return event.place
      ? $translate.instant('EVENT_NOTIFICATION_PLANNED_AT_PLACE', {
          eventType: event.type.contents.title,
          place: event.place.contents.name,
        })
      : $translate.instant('EVENT_NOTIFICATION_PLANNED', {
          eventType: event.type.contents.title,
        });
  }

  function getEventNotificationSubtitle(allDayLong, from, to) {
    if (allDayLong) {
      return $translate.instant('EVENT_NOTIFICATION_TOMORROW');
    }
    if (from.isSame(to, 'day')) {
      return $translate.instant('EVENT_NOTIFICATION_FROM_TO', {
        from: dateFormatService.getTimeFormatted(from),
        to: dateFormatService.getTimeFormatted(to),
      });
    }
    return $translate.instant('EVENT_NOTIFICATION_FROM_TO', {
      from: dateFormatService.getDateAndTimeFormatted(from),
      to: dateFormatService.getDateAndTimeFormatted(to),
    });
  }

  return methods;
}
