export function NetworkService() {
  'ngInject';
  const methods = {
    onConnectionSuccess,
    onConnectionFail,
  };

  function onConnectionSuccess(cb) {
    return connectionListener('online', cb);
  }

  function onConnectionFail(cb) {
    return connectionListener('offline', cb);
  }

  function connectionListener(eventKey, cb) {
    document.addEventListener(eventKey, cb);
    return () => {
      document.removeEventListener(eventKey, cb);
    };
  }

  return methods;
}
