import { CalendarEventsComponent } from './views/calendar-events/calendar-events.component';
import CalendarEventsComponentsModule from './components/calendar-events-components.module';
import CalendarEventsNotificationsModule from './services/calendar-events-notifications.module';
import EventsTransformModule from './services/events-transform.module';
import calendarEventsRoutes from './calendar-events.routes';
import CalendarEventsRecurrenceServiceModule from './services/calendar-events-recurrence.module';
import RecurrenceSelectorModule from './components/recurrence-selector/recurrence-selector.module';
import { CalendarEventsMonthList } from './components/calendar/calendar-events-month-list/calendar-events-month-list.component';

import { ManagerCalendarComponent } from './views/manager-calendar/manager-calendar.component';
import { ManagerCalendarApiService } from './services/manager-calendar-api/manager-calendar-api.service';
import { ManagerCalendarService } from './services/manager-calendar/manager-calendar.service';

import { CorporateEventsService } from './services/corporate-events/corporate-events.service';
import { CalendarEventsModalService } from './services/calendar-events-modal/calendar-events-modal.service';
import { CorporateEventsApiService } from './services/corporate-events-api/corporate-events-api.service';

import EventItemThemesConstantModule from './constants/event-item-themes.constant';

export default angular
  .module('sfMobile.calendar-events', [
    'sfMobile.services.localization',
    'sfMobile.services.calendar-events',
    'sfMobile.services.preferences',
    'sfMobile.services.calendar-events-notifications',
    'sfMobile.services.events-transform',
    'sfMobile.services.weekNavigation',
    'sfMobile.components.week-navigation',
    'sfMobile.components.button-header-add',
    'sfMobile.components.badge-beta',
    'sfMobile.components.badge-circle',
    'sfMobile.components.users-list',
    'sfMobile.services.pubsub',
    RecurrenceSelectorModule,
    CalendarEventsComponentsModule,
    CalendarEventsNotificationsModule,
    EventsTransformModule,
    CalendarEventsRecurrenceServiceModule,
    EventItemThemesConstantModule,
  ])
  .component('sfCalendarEvents', CalendarEventsComponent)
  .component('sfManagerCalendar', ManagerCalendarComponent)
  .component('sfCalendarEventsMonthList', CalendarEventsMonthList)
  .service('managerCalendarApiService', ManagerCalendarApiService)
  .service('managerCalendarService', ManagerCalendarService)
  .service('corporateEventsApiService', CorporateEventsApiService)
  .service('corporateEventsService', CorporateEventsService)
  .service('calendarEventsModalService', CalendarEventsModalService)
  .config(calendarEventsRoutes).name;
