import { AnalyticsService } from '../../../../analytics/services/analytics-service/analytics.service';
import { AnalyticsFilterService } from '../../../../services/Utils/AnalyticsFilter/analytics-filter.service';
import {
  CampaignCompletionTypes,
  Filter,
  FilterValue,
} from '../../types/campaign-completion';
import { CompletionFilterType } from '../../../../constants/campaign-completion.constants';
import { CampaignCompletionFilterService } from '../../services/campaign-completion-filter/campaign-completion-filter.service';
import { Campaign } from '../../../../services/API/campaigns/campaigns';
import {
  APIStatsFilters,
  AnalyticsDataGroup,
} from '../../../../analytics/types/analytics';
import { DateService } from '../../../../services/Utils/Dates/date.service';

export class CampaignCompletionListController {
  data: AnalyticsDataGroup[];
  filters: APIStatsFilters;
  campaign?: Campaign;
  form: any;
  isLoading = false;
  hasLoadingError: boolean;
  resource: CampaignCompletionTypes;
  // Because of the transclude in the view, we should store the search filters here
  searchFilters: FilterValue[] = [];
  availableSearchFilters: Filter[] = [];
  sortBy: 'asc' | 'desc' = 'desc';

  /* @ngInject */
  // eslint-disable-next-line max-params
  constructor(
    private analyticsService: AnalyticsService,
    private dateService: DateService,
    private analyticsFilterService: AnalyticsFilterService,
    private SF_COMPLETION_FILTER_TYPE: typeof CompletionFilterType,
    private campaignCompletionFilterService: CampaignCompletionFilterService,
    private profileService,
    private organisationsService
  ) {
    this.data = [];
  }

  $onInit(): ng.IPromise<void> {
    return this.profileService
      .getProfile()
      .then((profile) =>
        this.organisationsService.getProfileOrganisation(profile)
      )
      .then((organisation) =>
        this.campaignCompletionFilterService.generateFilters(
          this.resource,
          organisation.useNewCampaigns
        )
      )
      .then((filters) => {
        this.availableSearchFilters = filters;
        // set default value
        const defaultSearchFilters = this.availableSearchFilters.map(
          (availableFilter): FilterValue => {
            const filterValueIndex = this.getFilterIndex(
              availableFilter.id,
              typeof availableFilter.initValue !== 'undefined'
                ? availableFilter.initValue
                : availableFilter.defaultValue
            );

            return {
              id: availableFilter.id,
              value: availableFilter.values[filterValueIndex].value,
              label: availableFilter.values[filterValueIndex].label,
            };
          }
        );

        this.searchFilters =
          this.campaignCompletionFilterService.getFilterValues(
            this.resource,
            defaultSearchFilters,
            this.campaign ? this.campaign._id : this.form._id
          );

        const { startDate, endDate } = this.resolveStartAndDateDates(
          this.dateService,
          this.campaign
        );

        this.filters = {
          ...(this.campaign
            ? { campaigns_ids: [this.campaign._id] }
            : { forms_ids: [this.form._id] }),
          from: startDate,
          to: endDate,
          groupBy: this.resource,
          sort: this.sortBy,
          limit: 300,
          min: 0,
        };

        this.updateQueryFilters(this.searchFilters);
        return this.onReload();
      });
  }

  onReload(): ng.IPromise<void> {
    this.isLoading = true;
    this.hasLoadingError = false;

    return this.analyticsService
      .getCount('reports', this.filters)
      .then((res) => {
        this.data = res.entry.groups ? res.entry.groups : [];
      })
      .catch((err) => {
        this.hasLoadingError = true;
        throw err;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getFilterIndex(filterId: string, searchedValue: unknown): number {
    const index = this.availableSearchFilters
      .find((availableFilter) => availableFilter.id === filterId)
      ?.values.findIndex((valueEntry) => valueEntry.value === searchedValue);

    return index !== -1 && typeof index === 'number' ? index : 0;
  }

  isUserResource(): boolean {
    return this.resource === 'users';
  }

  isStoreResource(): boolean {
    return this.resource === 'stores';
  }

  onFilterUpdate(filterValues: FilterValue[]): void {
    this.searchFilters = filterValues;
    this.searchFilters = this.getDefaultFilters();
    this.updateQueryFilters(this.searchFilters);
    this.campaignCompletionFilterService.saveFilterValues(
      this.resource,
      this.searchFilters,
      this.campaign ? this.campaign._id : this.form._id
    );
    this.onReload();
  }

  onSortUpdate(sortBy: 'asc' | 'desc'): void {
    this.sortBy = sortBy;
    this.filters.sort = this.sortBy;
    this.onReload();
  }

  getDefaultFilters(): FilterValue[] {
    const newSearchFilters = [...this.searchFilters];

    for (const type in this.SF_COMPLETION_FILTER_TYPE) {
      if (!newSearchFilters.find((filter) => filter.id === type)) {
        const filter = this.availableSearchFilters.find(
          (availableFilter) => availableFilter.id === type
        );

        if (filter) {
          const defaultOption =
            filter.values[this.getFilterIndex(filter.id, filter.defaultValue)];

          if (typeof defaultOption !== 'undefined') {
            newSearchFilters.push({
              id: type,
              value: defaultOption.value,
              label: defaultOption.label,
            });
          }
        }
      }
    }

    return newSearchFilters;
  }

  resolveStartAndDateDates(
    dateService: DateService,
    campaign?: Campaign
  ): { startDate: Date; endDate: Date } {
    if (!campaign?.contents) {
      console.error(`Campaign ${campaign?._id} has no contents`);
      return {
        startDate: new Date(),
        endDate: new Date(),
      };
    }
    let startDate;
    let endDate;
    const dateSixMonthsAgo = dateService.getDateFrom('months', 6);

    if (!campaign.contents.objective?.activationPeriod) {
      if (
        campaign.contents.activation_date &&
        !dateService.isAfter(campaign.contents.activation_date, new Date())
      ) {
        startDate = campaign.contents.activation_date;
      } else if (campaign.created_date) {
        startDate = dateService.ISODateToDate(campaign.created_date);
      }
      startDate =
        startDate && dateService.isAfter(startDate, dateSixMonthsAgo)
          ? startDate
          : dateSixMonthsAgo;
    }

    if (campaign.contents.objective?.perDay) {
      return {
        startDate: new Date(new Date().setHours(0, 0, 0, 0)),
        endDate: new Date(new Date().setHours(23, 59, 59, 999)),
      };
    }

    if (campaign.contents.objective?.perMonth) {
      startDate = new Date().setDate(
        campaign.contents.objective.activationPeriod.start
      );
      endDate = new Date().setDate(
        campaign.contents.objective.activationPeriod.end
      );

      return {
        startDate: new Date(new Date(startDate).setHours(0, 0, 0, 0)),
        endDate: new Date(new Date(endDate).setHours(23, 59, 59, 999)),
      };
    }

    return {
      startDate,
      endDate,
    };
  }

  updateQueryFilters(filters: FilterValue[]): void {
    filters.forEach((filter) => {
      this.filters = this.analyticsFilterService.updateFilter(
        this.filters,
        filter
      );
    });
  }
}
