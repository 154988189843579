export const ButtonBigComponent = {
  bindings: {
    iconName: '@',
    iconHeight: '@',
    buttonText: '@',
    hasError: '<',
    isDisabled: '<?',
    onClick: '&',
  },
  transclude: true,
  templateUrl: 'components/Buttons/button-big/button-big.html',
  controller: function ButtonBigController() {
    'ngInject';
    this.$onInit = () => {
      this.iconPath = `img/icons/${this.iconName}.svg`;
    };
  },
};
