import { FilesService } from '../../../services/API/files/files.service';
import { DocumentViewerService } from '../../../services/Utils/DocumentViewer/document-viewer.service';
import { FileSystemService } from '../../../services/Utils/FileSystem/file-system.service';
import { ImageSourceService } from '../../../services/Utils/ImageSource/image-source.service';
import { PlatformService } from '../../../services/Utils/Platform';

export const ButtonDocumentComponent: ng.IComponentOptions = {
  bindings: {
    file: '<',
    fileId: '<',
    needSync: '<',
    folderPath: '<',
    hasDeleteButton: '<?',
    deleteDocument: '&',
  },
  transclude: true,
  templateUrl: 'components/Buttons/button-document/button-document.html',
  // eslint-disable-next-line max-params
  controller: class ButtonDocumentController implements ng.IController {
    downloadStatus: string | null = null;
    isDownloading: boolean | null = null;
    hasDeleteButton = false;
    isDownloaded;
    blob;
    needSync;
    folderPath;
    file;
    fileId;
    filePath;
    downloadError;
    uploadStatus;

    isInitCompleated = false;

    deleteDocument: () => void;

    constructor(
      private $q: ng.IQService,
      private $translate: ng.translate.ITranslateService,
      private filesSystemService: FileSystemService,
      private filesService: FilesService,
      private documentViewerService: DocumentViewerService,
      private platformService: PlatformService,
      private imageSourceService: ImageSourceService,
      private SF_ERROR_CODES
    ) {
      'ngInject';
    }

    $onInit() {
      this.isDownloaded = false;

      if (this.platformService.isBrowser()) {
        this.isDownloading = false;
        this.isDownloaded = true;

        return this.$q.when();
      }

      const { ext = 'pdf' } = this.filesSystemService.getFileNameExt(
        this.file.name
      );

      return this.filesSystemService
        .computeFileDataPath(this.folderPath, this.fileId ?? this.file._id, ext)
        .then((filePath) => {
          this.filePath = filePath;

          return (
            this.filesSystemService
              .checkFile(filePath)
              .then((exist) => {
                this.isDownloaded = Boolean(exist);
                this.isDownloading = false;

                if (!this.isDownloaded && this.file.forceFetch) {
                  this.downloadFile();
                }
              })
              // to prevent rejected promise errors in console and sentry better use catch than finally
              .catch(() => {
                this.isDownloading = false;
              })
              .finally(() => {
                this.isInitCompleated = true;
              })
          );
        });
    }

    getLabel() {
      if (this.uploadStatus === 'fail') {
        return this.$translate.instant('BUTTON_DOCUMENT_DOWNLOAD_ERROR');
      }

      if (this.file?.name || this.isDownloaded) {
        return this.file.name;
      }

      return this.$translate.instant('BUTTON_DOCUMENT_EMPTY_TITLE');
    }

    onClick() {
      if (this.platformService.isBrowser()) {
        return this.openDocumentInBrowser();
      }
      if (this.isDownloaded) {
        return this.documentViewerService.openDocument(this.filePath);
      }
      return this.downloadFile();
    }

    downloadFile() {
      const checkSpacePromise = this.filesSystemService
        .checkDeviceFreeSpace(this.file.size)
        .catch((err) => {
          if (
            err &&
            err.code &&
            this.SF_ERROR_CODES.OUT_OF_SPACE === err.code
          ) {
            this.filesSystemService.displayDiskSpaceAlert();
          }
          throw err;
        });
      const file = this.imageSourceService.create({ id: this.file._id }, true);

      this.isDownloading = true;
      this.downloadStatus = null;

      const { ext = 'pdf' } = this.filesSystemService.getFileNameExt(
        this.file.name
      );
      const fileOptions = {
        directory: this.filesSystemService.getDeviceFilePath(),
        path: this.folderPath,
        name: `${this.file._id}.${ext}`,
      };

      return checkSpacePromise
        .then(() =>
          this.filesService
            .download(fileOptions, file)
            .then((path) => {
              this.filePath = path;
              this.downloadStatus = 'success';
              this.isDownloaded = true;
            })
            .catch((err) => this.onFileDownloadError(err))
        )
        .finally(() => {
          this.isDownloading = false;
        });
    }

    openDocumentInBrowser() {
      this.isDownloading = true;
      this.downloadStatus = null;

      return this.documentViewerService
        .openDocumentInBrowser(this.file)
        .catch(this.onFileDownloadError)
        .finally(() => {
          this.isDownloading = false;
        });
    }

    onFileDownloadError(err) {
      this.downloadStatus = 'fail';
      this.downloadError = {
        FILE_NOT_FOUND_ERR: this.SF_ERROR_CODES.FILE_NOT_FOUND === err.code,
        CONNECTION_ERR:
          this.SF_ERROR_CODES.NO_CONNECTION === err.code ||
          err.code !== this.SF_ERROR_CODES.FILE_NOT_FOUND,
      };
      this.isDownloaded = false;
    }
  },
};
