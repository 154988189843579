import { TSFixMe } from 'app';

export class EanProductPickerController implements ng.IComponentController {
  //bindings
  products: TSFixMe[];
  ean: string;
  onClose: () => void;
  onSave: () => TSFixMe;

  onProductSelected(product) {
    return this.onSave()(product);
  }
}
