import { NewsfeedPostSimpleItemController } from './newsfeed-post-simple-item.controller';

export const NewsfeedPostSimpleItemComponent = {
  bindings: {
    post: '<',
    profile: '<',
    onEditPost: '&',
    onReload: '&',
    isChatLoading: '=',
  },
  templateUrl:
    'newsfeed/components/newsfeed-post-simple-item/newsfeed-post-simple-item.html',
  controller: NewsfeedPostSimpleItemController,
};
