export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 4.0,
    method: updateMethod,
  });
}

// eslint-disable-next-line max-params
export function updateMethod(
  $q,
  $log,
  sqlStorageService,
  localStorageService,
  databaseSchema
) {
  'ngInject';

  var sqlQueries = [
    'profile',
    'campaigns',
    'places',
    'missions',
    'comments',
    'place_params',
  ].map(dropTable);

  var defaultFields = databaseSchema.defaultFields || [];
  var tables = databaseSchema.tables || [];
  var update_date_keys = localStorageService
    .keys()
    .filter((key) => key.indexOf('_update_date') !== -1);

  return (
    sqlStorageService
      .execute('SELECT * FROM reports')
      // Stop request if reports table contains datas table exist
      .then((data) => (data.rows.length ? $q.reject() : $q.when()))
      .then(() => $q.all(sqlQueries))
      .then(() => sqlStorageService.createTables(defaultFields, tables))
      .then(() => localStorageService.remove(...update_date_keys))
      .then(() => $log.debug('[MIGRATION] Success: 4.0'))
      .catch(() => null)
  );

  function dropTable(table) {
    return sqlStorageService.execute('DROP TABLE IF EXISTS ' + table);
  }
}
