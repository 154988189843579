export const AutofillButtonComponent = {
  bindings: {
    state: '<',
    autofillMethod: '&',
  },
  controller: () => {},
  template: `
    <span ng-switch="$ctrl.state">
      <a class="button button-clear button-stable button-small pull-right"
        ng-switch-default
        ng-click="$ctrl.autofillMethod()">
        <i class="icon fa-light fa-map-location-dot"></i> {{ ::'PLACE_ADD_AUTOFILL' | translate }}
      </a>

      <a class="button button-clear button-stable button-small pull-right"
        ng-switch-when="pending">
        <i class="icon fa-light fa-rotate"></i> {{ ::'PLACE_ADD_AUTOFILL_PENDING' | translate }}
      </a>

      <a class="button button-clear button-balanced button-small pull-right"
        ng-switch-when="success">
        <i class="icon fa-light fa-thumbs-up"></i> {{ ::'PLACE_ADD_AUTOFILL_SUCCESS' | translate }}
      </a>

      <a class="button button-clear button-assertive button-small pull-right"
        ng-switch-when="error">
        <i class="icon fa-light fa-circle-xmark"></i> {{ ::'PLACE_ADD_GEOLOC_ERROR' | translate }}
      </a>
    </span>
  `,
};
