const CHAT_LIST_QUERY_LIMIT = 20;

export const ChatComponent = {
  bindings: {
    profile: '<',
  },
  templateUrl: 'chat/chat/chat.html',
  // eslint-disable-next-line max-params
  controller: function ChatController(
    pubSubService,
    $state,
    $scope,
    chatService,
    modalService
  ) {
    'ngInject';

    let stateChangeListener = null;
    let organisationSwitchListener = null;

    this.$onInit = () => {
      const stateName = $state.current.name;

      stateChangeListener = pubSubService.subscribe(
        pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
        (params) => {
          this.isRefreshMessageDisplayed = false;
          if (stateName === params.name) {
            return chatService.getLoginPromise().then(() => {
              this.registerListeners();
              return this.loadChannelsSilently();
            });
          }
          return this.unregisterListeners();
        }
      );
      organisationSwitchListener = pubSubService.subscribe(
        pubSubService.GLOBAL_EVENTS.ORGANISATION_SWITCH,
        (data) => {
          this.profile = data.profile;
          this.unregisterListeners.bind(this);
          this.registerListeners.bind(this);
        }
      );

      this.isRefreshMessageDisplayed = false;
      this.channelFilter = {
        includeEmpty: true,
        limit: CHAT_LIST_QUERY_LIMIT,
        order: 'latest_last_message',
      };
      this.eventListeners = [];
      this.channelList = [];
      this.isLoading = true;

      return chatService
        .getLoginPromise()
        .then(() => {
          this.registerListeners();
          return this.loadChannels();
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.$onDestroy = () => {
      this.unregisterListeners();
      if (stateChangeListener) {
        stateChangeListener();
      }
      if (organisationSwitchListener) {
        organisationSwitchListener();
      }
      if (this.chatCreationModal) {
        this.chatCreationModal.remove();
      }
    };

    this.unregisterListeners = () => {
      while (this.eventListeners.length) {
        this.eventListeners.pop()();
      }
    };

    this.registerListeners = () => {
      this.eventListeners = this.eventListeners.concat([
        chatService.onChannelChanged((channel) => {
          this.onChannelChange(channel);
        }),
        chatService.onChannelCreated(this.profile._id, () => {
          this.onChannelListChange();
        }),
        chatService.onLeave(this.profile._id, () => {
          this.onChannelListChange();
        }),
        chatService.onChannelDeleted(() => {
          this.onChannelListChange();
        }),
      ]);
    };

    this.callChannels = () =>
      chatService.getChannelList(this.channelFilter).then((channelQuery) => {
        // Make a copy of objects in array to trigger $onChanges()
        this.channelList = channelQuery.entities.map((entity) => ({
          ...entity,
        }));
        this.channelQuery = channelQuery;
        this.hasChannels = Boolean(this.channelList && this.channelList.length);
        chatService.updateUnreadMessageCount();

        return channelQuery.entities;
      });

    this.loadChannels = () => {
      this.isLoading = true;
      this.hasLoadingError = false;

      return this.callChannels()
        .catch((err) => {
          this.hasLoadingError = true;
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.reloadChannels = () => this.loadChannels();

    this.onChannelListChange = () => {
      this.isRefreshMessageDisplayed = true;
      $scope.$apply();
    };

    this.loadChannelsWhenSearch = () => {
      this.isLoading = true;
      this.hasSearchError = false;

      return this.callChannels()
        .catch((err) => {
          this.hasSearchError = true;
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.loadChannelsSilently = () => {
      this.hasLoadingError = false;

      return this.callChannels().catch((err) => {
        this.hasLoadingError = true;
        throw err;
      });
    };

    this.onChannelChange = (channelChanged) => {
      this.channelList = this.channelList.map((channel) =>
        channel.url === channelChanged.url ? channelChanged : channel
      );
      $scope.$apply();
    };

    this.getNextChannels = () => {
      this.infiniteLoadError = false;

      return this.channelQuery
        .next()
        .then((channelQuery) => {
          this.channelList = this.channelList.concat(channelQuery.entities);
          this.channelQuery = channelQuery;

          return this.channelList;
        })
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        })
        .finally(() => {
          if (this.onScrollComplete) {
            this.onScrollComplete();
          }
        });
    };

    this.openCreateRoom = () => {
      const template = `
        <sf-chat-create
          profile="$ctrl.profile"
          on-close="$ctrl.onClose()"
          on-chat-room-create="$ctrl.openChatRoom(channel)">
        </sf-chat-create>
      `;
      const chatCreateBindings = {
        profile: this.profile,
        openChatRoom: this.openChatRoom,
      };

      this.chatCreationModal = modalService.open(template, chatCreateBindings);
      return this.chatCreationModal;
    };

    this.openChatRoom = (channel) => {
      $state.go('index.chat.channel', {
        channelUrl: channel.url,
      });
    };

    this.onChannelSearchChange = () => {
      this.channelFilter.channelNameOrUserNickname =
        this.channelNameSearchField;
      this.loadChannelsWhenSearch();
    };

    this.resetInfiniteLoad = () => {
      this.infiniteScrollError = false;
    };
  },
};
