import { CampaignCompletionSearchFilterController } from './campaign-completion-search-filter.controller';

export const CampaignCompletionSearchFilterComponent = {
  bindings: {
    filterValues: '<',
    sortBy: '<',
    filtersAvailable: '<',
    onFiltersChange: '&',
    onSortByChange: '&',
  },
  templateUrl:
    'legacy-campaigns/campaign/components/campaign-completion-search-filter/campaign-completion-search-filter.html',
  controller: CampaignCompletionSearchFilterController,
};
