import { toArabicNumber } from './translateNumbersToArabicFilter.filter';
import { TranslateNumbersService } from './translateNumbers.service';

export default angular
  .module('sfMobile.services.translateNumbers', [
    'sfMobile.services.localization',
  ])
  .filter(
    'toArabicNumber',
    function (localizationService, translateNumbersService) {
      'ngInject';
      return toArabicNumber(localizationService, translateNumbersService);
    }
  )
  .service('translateNumbersService', TranslateNumbersService).name;
