"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

/* eslint-disable global-require */
var errorsLibrary = require('../utils/errors');

var baseSchema = require('./calendarEvent.schema');

var Ajv = require('ajv');

var _require = require('ramda'),
    uniq = _require.uniq,
    lensPath = _require.lensPath,
    set = _require.set,
    lensProp = _require.lensProp,
    mergeLeft = _require.mergeLeft,
    path = _require.path,
    pipe = _require.pipe;

var lib = {
  validate: validate
};
module.exports = lib;

function validate(calendarEvent, eventType) {
  var schema = applyEventTypeValidationRules(baseSchema, eventType);
  var validateSchema = new Ajv().compile(schema);
  var errors = validateSchema(calendarEvent) ? [] : validateSchema.errors;
  return errorsLibrary.mapAjvErrors(errors);
}

function applyEventTypeValidationRules(schema) {
  var eventType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var requiredFields = eventType.requiredFields || [];
  var adjustRequiredFields = set(lensProp('required'), uniq([].concat(_toConsumableArray(schema.required), _toConsumableArray(requiredFields))));
  var itemsPath = ['properties', 'assignees_ids'];
  var maxAssignees = eventType.multipleAssignees ? {} : {
    maxItems: 1
  };
  var adjustAssignees = set(lensPath(itemsPath), mergeLeft(path(itemsPath, schema), maxAssignees));
  return pipe(adjustRequiredFields, adjustAssignees)(schema);
}