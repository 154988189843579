"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

/* eslint-disable global-require */
var errorsLibrary = require('../utils/errors');

var baseSchema = require('./calendarEventChangeRequest.schema');

var Ajv = require('ajv');

var _require = require('ramda'),
    uniq = _require.uniq,
    propEq = _require.propEq,
    complement = _require.complement,
    set = _require.set,
    lensProp = _require.lensProp;

var lib = {
  validate: validate
};
module.exports = lib;

function validate(changeRequest) {
  var schema = adjustSchema(baseSchema, changeRequest);
  var validateSchema = new Ajv().compile(schema);
  var errors = validateSchema(changeRequest) ? [] : validateSchema.errors;
  return errorsLibrary.mapAjvErrors(errors);
}

function adjustSchema(schema, changeRequest) {
  var isStateNotPending = complement(propEq('state', 'pending'));
  var makeStateChangedByRequired = set(lensProp('required'), uniq([].concat(_toConsumableArray(schema.required), ['stateChangedBy_id'])));

  if (isStateNotPending(changeRequest)) {
    return makeStateChangedByRequired(schema);
  }

  return schema;
}