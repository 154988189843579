const CURRENCIES = {
  EURO: { code: 'EUR', symbol: '€' },
};

export function getCurrencySymbol() {
  'ngInject';
  return (code) => {
    const currencyCode = Object.keys(CURRENCIES).find(
      (k) => CURRENCIES[k].code === code
    );
    if (!currencyCode) {
      return '';
    }
    return CURRENCIES[currencyCode].symbol;
  };
}

export default angular
  .module('sfMobile.constants.currencies', [])
  .filter('getCurrencySymbol', getCurrencySymbol)
  .constant('SF_CURRENCIES', CURRENCIES).name;
