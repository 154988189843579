import TaskManageFormModule from './components/task-manage-form/task-manage-form.module';
import TaskManageModule from './services/task-manage.module';
import { TaskManageComponent } from './task-manage.component';

export default angular
  .module('sfMobile.components.task-manage', [
    'sfMobile.services.localization',
    TaskManageFormModule,
    TaskManageModule,
  ])
  .component('sfTaskManage', TaskManageComponent).name;
