const FOLDER_NAME = 'chat';
const HOLD_TO_COPY_TIMEOUT_MS = 3000;

export const ChatMessageComponent = {
  bindings: {
    message: '<',
    profile: '<',
    isSenderDisplayed: '<',
    onMessageCopied: '<',
  },
  templateUrl: 'chat/components/chat-message/chat-message.html',
  // eslint-disable-next-line max-params
  controller: function ChatMessageController(
    modalService,
    chatService,
    dateFormatService,
    documentViewerService,
    filesSystemService,
    platformService,
    SF_ERROR_CODES,
    $window,
    imageSourceService,
    chatMessageActionsService,
    clipboardService,
    $element,
    $timeout,
    gesturesService
  ) {
    'ngInject';
    this.getTimeFormatted = dateFormatService.getTimeFormatted;

    this.$onInit = () => {
      this.initComponent();
      this.isBrowser = platformService.isBrowser();
      this.userInfo = this.getUserInfo();
      this.isCurrentUser = this.message._sender.userId === this.profile._id;
      this.hasText = Boolean(this.message.message);
      this.messageActions = chatMessageActionsService.getActions({
        message: this.message,
        isAllowedToDelete: this.isCurrentUser,
        isAllowedToCopy: this.hasText,
        onCopyCb: this.onMessageCopied,
      });
    };

    this.$postLink = () => {
      $timeout(() => {
        if (!this.hasText || this.isBrowser) {
          return;
        }
        this.onMessageHold();
      }, 0);
    };

    this.$onChanges = () => {
      this.initComponent();
    };

    this.initComponent = () => {
      this.isMessageFile = chatService.isMessageFile(this.message);
      this.isImage = chatService.isMessageImage(this.message);
      this.isDocument = chatService.isMessageDocument(this.message);
    };

    this.getImageThumb = (message, index) => {
      const thumb = message.thumbnails[index];

      return thumb ? thumb.url : message.url;
    };
    this.getUserInfo = () => {
      const { _sender } = this.message;
      const { firstName, lastName, avatar_id } =
        chatService.getMemberMetadata(_sender);

      return {
        _id: _sender.userId,
        contents: {
          firstName,
          lastName,
          avatar_id,
        },
      };
    };

    this.viewPicture = (message) => {
      const imagesViewerTemplate = `
        <sf-images-viewer
          images="[$ctrl.image]"
          on-close="$ctrl.onClose()">
        </sf-images-viewer>
      `;

      return modalService.open(imagesViewerTemplate, {
        image: imageSourceService.createFromChatMessage(message),
      });
    };

    this.openDocument = () => {
      if (this.isBrowser) {
        return $window.open(this.message.url, '_blank');
      }
      return filesSystemService
        .checkDeviceFreeSpace(this.message.size)
        .then(() => {
          return documentViewerService.openRemoteDocument({
            url: this.message.url,
            id: this.message.messageId,
            contentType: this.message.type,
            name: this.message.name,
            folder: FOLDER_NAME,
            requireAuthForDownload: false,
          });
        })
        .catch((err) => {
          if (err && err.code && SF_ERROR_CODES.OUT_OF_SPACE === err.code) {
            filesSystemService.displayDiskSpaceAlert();
          }
          return err;
        });
    };

    this.copyText = () => {
      clipboardService
        .copyText(this.message.message || '')
        .then(() => this.onMessageCopied());
    };

    this.onMessageHold = () => {
      const button = $element[0].querySelector('.sf_chat_message_item');
      const options = { hold_timeout: HOLD_TO_COPY_TIMEOUT_MS };

      gesturesService.createGesture({
        el: button,
        events: [{ name: 'hold', options, cb: () => this.copyText() }],
      });
    };
  },
};
