import { path, hasPath } from 'ramda';
import { TSFixMe, User } from '../../..';
import {
  ChangeRequestActionsService,
  ActionOptions,
} from '../../services/change-request-actions.service';

export const ChangeRequestCommentsComponent: ng.IComponentOptions = {
  bindings: {
    profile: '<',
    comments: '<',
    commentsUser: '<',
    isCommentsLoading: '<',
    hasCommentsErrors: '<',
    onDeleteComment: '&',
    onEditComment: '&',
    onReload: '&',
  },
  templateUrl:
    'change-requests/components/change-request-comments/change-request-comments.html',
  controller: class ChangeRequestCommentsController
    implements ng.IComponentController
  {
    onDeleteComment: (arg: { commentId }) => void;
    comments: TSFixMe[];
    profile: User;
    commentsActions: Record<string, ActionOptions>;
    commentsUser: Record<string, User>;
    onEditComment: (arg: { commentId }) => void;

    constructor(
      private changeRequestActionsService: ChangeRequestActionsService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.commentsActions = this.getCommentsActions();
    }

    $onChanges(changes: ng.IOnChangesObject): void {
      if (changes.comments) {
        this.commentsActions = this.getCommentsActions();
      }
    }

    getCommentsActions(): Record<string, ActionOptions> {
      return this.comments.reduce((acc, comment) => {
        const { _id } = comment;

        acc[_id] = this.changeRequestActionsService.getCommentActions({
          author_id: path(['contents', 'author_id'])(comment) as string,
          profile: this.profile,
          isPicture: hasPath(['contents', 'picture_id'])(comment),
          callbacks: {
            onDelete: () => this.onDeleteComment({ commentId: _id }),
            onEdit: () => this.onEditComment({ commentId: _id }),
          },
        });

        return acc;
      }, {});
    }

    getUserFromComment(userId): User {
      return this.commentsUser[userId];
    }
  },
};
