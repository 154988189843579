// constants
import { TARGETING_TYPES } from './constants/targeting-types.constants';
// components
import { ResourceTargetTypeComponent } from './components/resource-target-type/resource-target-type.component';
import { ResourceTargetSelectorComponent } from './components/resource-target-selector/resource-target-selector.component';

export default angular
  .module('sfMobile.targeting', [])
  .constant('TARGETING_TYPES', TARGETING_TYPES)
  .component('sfResourceTargetType', ResourceTargetTypeComponent)
  .component('sfResourceTargetSelector', ResourceTargetSelectorComponent).name;
