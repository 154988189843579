import { computeFormula } from '@simplifield/formula';
import { forms as formsSchema } from '@simplifield/schema';
import { filter, head, includes, pathOr, pipe } from 'ramda';

const USEFUL_FIELDS = [
  'contents.analysts_ids',
  'contents.description',
  'contents.files',
  'contents.needPlace',
  'contents.organisation_id',
  'contents.owner_id',
  'contents.places_ids',
  'contents.preferences',
  'contents.questions',
  'contents.sections',
  'contents.statusMap',
  'contents.title',
  'contents.users_ids',
  'contents.reportsValidation',
  'contents.start_date',
  'contents.end_date',
  'i18n',
];
const DEFAULT_FORM_IMAGE_SIZE = 'thumb';

// eslint-disable-next-line max-params
export function FormsService(
  $q,
  $http,
  $translate,
  crudFactory,
  sfPOVService,
  missionsService,
  reportsService,
  filesService,
  filesSystemService,
  databaseSchema,
  imageSourceService,
  imageService,
  SF_ERROR_CODES,
  SF_IMAGE_SIZES,
  platformService,
  reportScoringService
) {
  'ngInject';
  const tableConfig = databaseSchema.tables.forms;
  const methods = crudFactory(tableConfig.table_name, {
    default_params: {
      mode: 'compact',
      fields: USEFUL_FIELDS,
    },
    exclude_offline_params: ['state'],
    take_care_of_user_profile: true,
    backup: {
      indexed_fields: tableConfig.indexed_fields.map((field) => field.name),
    },
  });
  const FORM_IMAGE_SIZES = {
    full: SF_IMAGE_SIZES.SQUARE_BIG,
    medium: SF_IMAGE_SIZES.RECTANGLE_MEDIUM,
    thumb: SF_IMAGE_SIZES.SQUARE_SMALL,
  };

  // TODO: create a service helper for these.
  const isImage = pipe(pathOr('', ['mime_type']), includes('image/'));

  // hook
  methods.registerHook('refresh:offline-params', cleanParams);
  methods.registerHook('list:rewrite', listRewrite);
  methods.registerHook('refresh:after', afterRefreshHook);

  methods.listMissions = listMissions;
  methods.getStatistics = getStatistics;
  methods.getReportsStatistics = getReportsStatistics;
  methods.getAvailableStatus = getAvailableStatus;
  methods.getFormTree = getFormTree;
  methods.getFormImage = getFormImage;
  methods.computeMaximum = computeMaximum;
  methods.displayQuestion = displayQuestion;
  methods.getRequestedQuestions = getRequestedQuestions;
  methods.getOptionalQuestions = getOptionalQuestions;
  methods.removeLegacy = removeLegacy;
  methods.getStatusLabel = getStatusLabel;
  methods.getFilesPath = getFilesPath;
  methods.getReportScoringProgress = getReportScoringProgress;
  methods.getFormStatsLabel = getFormStatsLabel;
  methods.getLastReportAnswers = getLastReportAnswers;

  /**
   * Don't pay attention to the deleted param because the ressource need
   * to be updated
   * @param  {Object} params - Offline request params
   * @return {Object}        - Params
   */
  function cleanParams(params) {
    delete params.deleted;
    return params;
  }

  function listRewrite(forms) {
    return keepFormWithAReportAssociate(forms);
  }

  function afterRefreshHook(forms) {
    const newForms = forms.filter((form) => !form._deleted);
    const deletedForms = forms.filter((form) => form._deleted);

    if (platformService.isBrowser()) {
      return $q(forms);
    }

    return uploadFiles(newForms)
      .then(() => deleteFilesDir(deletedForms))
      .then(() => deletedReports(deletedForms))
      .then(() => forms);
  }

  function uploadFiles(newForms) {
    return newForms
      .reduce(
        (promise, form) => promise.then(() => uploadFormFiles(form)),
        $q.when()
      )
      .catch((err) => {
        if (err && err.code && SF_ERROR_CODES.OUT_OF_SPACE === err.code) {
          filesSystemService.displayDiskSpaceAlert();
        }
        return null;
      });
  }
  function uploadFormFiles(form) {
    const filesPath = getFilesPath(form._id);
    const formFiles = getFormFiles(form);

    return formFiles.length
      ? filesSystemService
          .createDir(filesPath)
          .then(() => filesService.downloadBunch(formFiles, filesPath))
          .catch(() => form)
      : $q.when(form);
  }
  function getFormFiles(form) {
    return []
      .concat(form.contents, form.contents.sections, form.contents.questions)
      .filter((node) => (node.files || []).length)
      .reduce((output, node) => output.concat(node.files), [])
      .map((file) => imageSourceService.create(file, !isImage(file), true));
  }

  function getFormImage(form, size = DEFAULT_FORM_IMAGE_SIZE) {
    const getFiles = pathOr([], ['contents', 'files']);
    const getFirstImage = pipe(filter(isImage), head);
    const getImgId = (file) => file && file._id;

    const imgId = pipe(getFiles, getFirstImage, getImgId)(form);
    const widthxheight = FORM_IMAGE_SIZES[size];

    return imgId
      ? imageService.getSizedUrlFromId(imgId, widthxheight)
      : $q.when();
  }

  function deleteFilesDir(deletedForms) {
    return deletedForms
      .reduce(
        (promise, form) =>
          promise.then(() => filesSystemService.deleteDir(`form/${form._id}`)),
        $q.when()
      )
      .catch(() => null);
  }
  function deletedReports(deletedForms) {
    return deletedForms
      .reduce(
        (promise, form) =>
          promise.then(() =>
            reportsService.crud.dataStore.deleteLocal({ form_id: form._id })
          ),
        $q.when()
      )
      .catch(() => null);
  }

  function getReportScoringProgress(form, report) {
    const reportProgress = {};

    reportProgress.wholeScore = reportScoringService.computeScore(form, report);
    reportProgress.wholeScorePercent = getPercent(reportProgress.wholeScore);

    reportProgress.wholeScoreLabel = {
      title: $translate.instant('REPORT_SCORING_TOTAL_SCORE'),
      desc: `${reportProgress.wholeScorePercent}%`,
    };
    return reportProgress;
  }
  function getFormStatsLabel(form) {
    return getReportsStatistics(form._id).then((formStats) =>
      formStats.score.whole
        ? [].concat({
            title: $translate.instant('REPORT_SCORING_FORM_PREV'),
            desc: `${getPercent(formStats.score.whole)}%`,
          })
        : []
    );
  }
  function getPercent(score) {
    return score ? `${Math.round(score.percent * 100)}` : null;
  }

  function getLastReportAnswers(form_id, params) {
    const url = `/forms/${form_id}/lastAnswers`;

    return methods.simpleApiList(url, params, false);
  }

  /**
   * Keep the forms which are still related to some local reports
   * in the local database
   * @param  {Array} forms - Forms trying to be updated locally
   * @return {Array}       - Forms transformed
   */
  function keepFormWithAReportAssociate(forms) {
    const formDeleted = forms.filter((form) => form._deleted);
    const reportsRequestParams = {
      localStatus: ['draft', 'ready'],
      form_id: formDeleted.map((form) => form._id),
    };

    // Init the deleted values by default
    forms = forms.map((form) => {
      form.deleted = false;
      return form;
    });

    // If a form is deleted, try to find a related local report
    return formDeleted.length
      ? reportsService.crud
          .queryLocal(reportsRequestParams)
          .then((reports) => reports.map((report) => report.contents.form_id))
          .then((formsIdReport) =>
            forms.map((form) => {
              const formIsDeletedAndAssociated =
                form._deleted === true &&
                formsIdReport.indexOf(form._id) !== -1;

              // If the form is associate to a report,
              // remove the flag for delete the data of the database
              // and attach the deleted info to the ressource
              if (formIsDeletedAndAssociated) {
                form._deleted = false;
                form.deleted = true;
              }
              return form;
            })
          )
      : $q.when(forms);
  }

  function listMissions(queryParam, params) {
    return missionsService.queryLocal(queryParam).catch(queryFail);

    function queryFail() {
      var listUrl = geFormReportsUri(queryParam.form_id);

      return sfPOVService
        .pBuildURL(listUrl)
        .then((url) => $http.get(url, { params: params }))
        .then((res) => res.data);
    }
  }

  function getStatistics(formId) {
    const statsUrl = `${methods.basePath}/${formId}`;
    const params = { withDigests: true };

    return sfPOVService
      .pBuildURL(statsUrl)
      .then((url) => $http.get(url, { params: params }))
      .then((res) => res.data.statisticsDigest);
  }
  function getReportsStatistics(formId, params) {
    const formReportsUri = geFormReportsUri(formId);
    const statsUrl = `${formReportsUri}/statistics`;

    return sfPOVService
      .pBuildURLByProfile(statsUrl)
      .then((url) => $http.get(url, { params: params }))
      .then((res) => res.data);
  }

  function geFormReportsUri(formId) {
    return `${methods.basePath}/${formId}/reports`;
  }

  // ------------------
  //
  //    Status
  //
  // ------------------
  function getStatusLabel(statusKey, statusMap) {
    return (
      statusMap &&
      statusMap.filter((status) => status.key === statusKey)[0].label
    );
  }

  // ------------------
  //
  //    Helpers
  //
  // ------------------
  function getAvailableStatus(form) {
    const notAvailableStatusKey = ['empty'];
    const statusMap = form.contents.statusMap || [];

    return statusMap.filter(
      (status) => notAvailableStatusKey.indexOf(status.key) === -1
    );
  }
  /**
   * Construct form tree
   * @param  {Object} form - form data
   * @return {Array}       - Sections that contain questions
   */
  function getFormTree(form) {
    const formTree = formsSchema.formToOrderedTree(form.contents);

    return formTree.sections;
  }
  function getFilesPath(formId) {
    return `form/${formId}/files`;
  }

  function isLegacyGPSQuestion(question, questionIndex) {
    // TODO: in the future (if we do legitimate 'gps' questions) this check
    // must be done with a dedicated 'legacy' property on the question object.
    return question.type === 'gps' && questionIndex === 0;
  }

  /**
   * Clean a form of eventual legacy leftovers
   * @param  {Object} form - A form object
   * @return {Object}      - A clean copy of the given form
   */
  function removeLegacy(form) {
    const formCopy = angular.copy(form);

    formCopy.contents.questions = formCopy.contents.questions.filter(
      (q, i) => !isLegacyGPSQuestion(q, i)
    );

    return formCopy;
  }
  /**
   * Get the tasks not optional
   * @param  {Object} questions - all questions
   * @return {Array}            - Requested questions
   */
  function getRequestedQuestions(questions) {
    return questions.filter(function getMandatory(data) {
      return data.minimum > 0;
    });
  }

  /**
   * Get the optional
   * @param  {Object} questions - all questions
   * @return {Array}            - Requested questions
   */
  function getOptionalQuestions(questions) {
    return questions.filter((question) => {
      return question.minimum === 0;
    });
  }
  /**
   * Return if question need to be displayed
   * @param  {Object} question - Question to check
   * @param  {Object} form     - Form concerned
   * @param  {Array}  answers  - Form answers
   * @param  {Object} ctx      - Context
   * @return {Number}          - Maximum
   */
  function displayQuestion(question, form, answers, ctx = {}) {
    form.contents.questions.map((q) => {
      if (q.metadata && q.metadata.conditions) {
        const numberTypeParams = q.metadata.conditions.filter(
          ({ paramType }) => paramType === 'number'
        );

        const regex = /param_id=([^\]]+)/; // get the param_id from the maximum string

        numberTypeParams.map(({ item, paramType }) => {
          const paramId = item.match(regex)[1];
          if (paramType === 'number') {
            const item = ctx.place.contents.params.find(
              ({ param_id }) => param_id === paramId
            );

            if (item) {
              item.value = String(item.value);
            }
          }
        });
      }
    });

    return computeMaximum(question, form, answers, ctx);
  }
  function computeMaximum(question, form, answers, ctx = {}) {
    return typeof question.maximum === 'string' &&
      question.maximum !== 'Infinity'
      ? computeFormula(
          'number',
          question.maximum,
          {
            form: form.contents || form,
            time: Date.now.bind(Date),
          },
          {
            answers,
            ...ctx,
          }
        )
      : question.maximum;
  }

  return methods;
}
