import { Task, TaskStatus } from '../../..';
import { IAPIResource, User } from '../../../..';
import { EntitySelectorService } from '../../../../components/entity-selector/services/entity-selector.service';
import { TasksService } from '../../../../services/API/tasks/tasks.service';
import { UsersService } from '../../../../services/API/users/users.service';
import { AppMessageService } from '../../../../services/Utils/AppMessage/app-message.service';
import { ErrorMessagesService } from '../../../../services/Utils/ErrorMessages/error-messages.service';
import { SegmentService } from '../../../../services/Utils/Segment/segment.service';
import { SF_TASK_STATUSES } from '../../../constants/task-statuses.constant';
import { TaskService } from '../../../services/task.service';

export const TaskDetailsActionsComponent: ng.IComponentOptions = {
  bindings: {
    currentStatus: '<',
    offlineMode: '<',
    task: '<',
    isOwner: '<',
    canChangeStatus: '<',
    onStatusChanged: '&',
    onTaskSelfAssign: '&',
    onTaskAssignToOther: '&',
  },
  templateUrl:
    'tasks/task-details/components/task-details-actions/task-details-actions.html',
  // eslint-disable-next-line max-params
  controller: class TaskDetailsActionsController
    implements ng.IComponentController
  {
    //bindings
    currentStatus: TaskStatus;
    offlineMode: boolean;
    task: Task;
    isOwner: boolean;
    canChangeStatus: boolean;
    onStatusChanged: (data: { status: string; task?: Task }) => void;
    onTaskSelfAssign: (data: { task: Task }) => void;
    onTaskAssignToOther: (data: { task: Task }) => void;
    // attributes
    isPending = false;
    assignee: User | null;

    constructor(
      private $translate: ng.translate.ITranslateService,
      private appMessageService: AppMessageService,
      private errorMessagesService: ErrorMessagesService,
      private segmentService: SegmentService,
      private taskService: TaskService,
      private readonly tasksService: ReturnType<typeof TasksService>,
      private TASK_STATUSES: typeof SF_TASK_STATUSES,
      private readonly popupRequestService,
      private entitySelectorService: EntitySelectorService,
      private profileService,
      private usersService: UsersService
    ) {
      'ngInject';
    }

    changeStatus = (status: TaskStatus): ng.IPromise<boolean | void> => {
      const trackActionName = 'change status';

      this.isPending = true;
      return this.tasksService
        .changeStatus({ ...this.task }, status, this.offlineMode)
        .then((task) => this.onStatusChanged({ status, task }))
        .then(() => this.trackSuccess(trackActionName))
        .catch((error) => {
          if (error?.response?.status) {
            const errorResponse = error.response;
            const errorStatus = errorResponse.status;
            this.trackFail(errorStatus, trackActionName);
            if (this.errorMessagesService.isConflictError(errorResponse)) {
              this.handleTaskDeleted();
              return false;
            }
            const errorMessage = this.errorMessagesService.getMessage(
              errorResponse,
              {
                customUnknownErrorMessage: 'TASK_DETAILS_CHANGE_STATUS_FAILED',
              }
            );
            this.appMessageService.display(
              this.$translate.instant(errorMessage),
              'fail'
            );
          }
          return false;
        })
        .finally(() => {
          this.isPending = false;
        });
    };

    isButtonVisible = (statusName: TaskStatus): boolean =>
      this.currentStatus !== statusName && this.canChangeStatus;

    handleTaskDeleted = (): void =>
      this.taskService.showTaskDeletedError(() => {
        this.onStatusChanged({ status: this.TASK_STATUSES.DELETED.keyword });
      });

    isToDo = (): boolean => this.taskService.isToDo(this.currentStatus);

    isDone = (): boolean => this.taskService.isDone(this.currentStatus);

    trackSuccess = (action): void => {
      if (!this.offlineMode) {
        this.segmentService.track('TASK_ACTIONS', {
          action: action,
          label: 'succeed',
          task_id: this.task._id,
          value: this.task.contents.status,
        });
      }
    };

    trackFail = (errorStatus: number, action): void => {
      if (!this.offlineMode) {
        this.segmentService.track('TASK_ACTIONS', {
          action: action,
          label: 'failed',
          task_id: this.task._id,
          value: errorStatus,
        });
      }
    };

    isTaskAssigned = (): boolean => {
      const { assignee_id } = this.task.contents;
      return !!assignee_id;
    };

    taskSelfAssign = (): ng.IPromise<void> => {
      const trackActionName = 'task self assign';
      this.isPending = true;
      const popupText = this.taskService.getTaskUpdatePopupText();
      const popupRequest = this.popupRequestService.show(popupText);
      const onRetry = () => this.taskSelfAssign();
      return this.tasksService
        .taskSelfAssign(this.task, this.offlineMode)
        .then((task: Task) => {
          return popupRequest.onSuccess().then(() => {
            this.onTaskSelfAssign({ task });
            this.trackSuccess(trackActionName);
          });
        })
        .catch((error) => {
          if (error?.response?.status) {
            const errorResponse = error.response;
            const errorStatus = errorResponse.status;
            popupRequest.onError(onRetry);
            this.trackFail(errorStatus, trackActionName);
          }
        })
        .finally(() => {
          this.isPending = false;
        });
    };

    taskAssignToOther = (): void => {
      this.entitySelectorService.openSelectEntityModal({
        emptyIcon: 'no_users',
        title: this.$translate.instant(
          'MANAGE_TASK_BIG_LIST_MODAL_TITLE_ASSIGNEE'
        ),
        searchPlaceholder: this.$translate.instant('PLACE_INFOS_PLACEHOLDER'),
        multiple: false,
        selected: this.assignee,
        onSave: ({ result }) => {
          const user = result as IAPIResource<User>;

          if (user) {
            this.tasksService
              .taskAssign(this.task, user._id, this.offlineMode)
              .then((task) => {
                this.assignee = user.contents;
                this.task = task;
                this.onTaskAssignToOther({
                  task,
                });
              });
          }
        },
        entityTitleGet: (user: IAPIResource<User>) =>
          this.profileService.getNameFromUser(user.contents),
        searchFields: ['user_firstName', 'user_lastName'],
        staticSearchFields: [],
        entityProvider: (params) => {
          if (this.task.place?._id) {
            return this.usersService.getScopedUsers(
              params,
              this.task.place._id
            );
          } else {
            return this.usersService.getAllUsers(params);
          }
        },
      });
    };
  },
};
