"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('a_user_new1'),
    name: 'a_user_new1',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_USER_CREATED',
      organisation_id: createObjectId('organisation'),
      user_id: createObjectId('owner')
    }
  }, {
    _id: createObjectId('a_user_new2'),
    name: 'a_user_new2',
    description: 'Additionnal properties',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_USER_CREATED',
      organisation_id: createObjectId('organisation'),
      user_id: createObjectId('owner'),
      metadata: {
        platform: 'mobile'
      }
    }
  }, {
    _id: createObjectId('a_user_reconfirm'),
    name: 'a_user_reconfirm',
    description: 'A reconfirm event.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_USER_RECONFIRMED',
      organisation_id: createObjectId('organisation'),
      user_id: createObjectId('owner'),
      locale: 'fr'
    }
  }, {
    _id: createObjectId('a_user_reinvite'),
    name: 'a_user_reinvite',
    description: 'A reinvite event.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_USER_REINVITED',
      organisation_id: createObjectId('organisation'),
      user_id: createObjectId('owner')
    }
  }, {
    _id: createObjectId('a_form_created'),
    name: 'a_form_created',
    description: 'A new form',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_FORM_CREATED',
      organisation_id: createObjectId('organisation'),
      form_id: createObjectId('form'),
      owner_id: createObjectId('owner')
    }
  }, {
    _id: createObjectId('a_report_week_sent'),
    name: 'a_report_week_sent',
    description: 'A report weekly sent',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_REPORT_WEEKLY_SENT',
      organisation_id: createObjectId('organisation'),
      user_id: createObjectId('owner'),
      start_date: '2015-12-01T16:19:54.545Z',
      end_date: '2015-12-01T16:19:54.545Z',
      report_date: '2015-12-01T16:19:54.545Z'
    }
  }, {
    _id: createObjectId('a_form_created'),
    name: 'a_form_created',
    description: 'A form update',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_FORM_UPDATED',
      organisation_id: createObjectId('organisation'),
      form_id: createObjectId('form'),
      owner_id: createObjectId('owner'),
      newState: 'current'
    }
  }, {
    _id: createObjectId('a_places_list_update'),
    name: 'a_places_list_update',
    description: 'A place list update',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_PLACES_LIST_UPDATED',
      organisation_id: createObjectId('organisation'),
      placesList_id: createObjectId()
    }
  }, {
    _id: createObjectId('a_users_group_update'),
    name: 'a_users_group_update',
    description: 'A user group update',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_USERS_GROUP_UPDATED',
      organisation_id: createObjectId('organisation'),
      usersGroup_id: createObjectId()
    }
  }, {
    _id: createObjectId('a_report_created'),
    name: 'a_report_created',
    description: 'A report created',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_REPORT_CREATED',
      organisation_id: createObjectId('organisation'),
      form_id: createObjectId('form'),
      report_id: createObjectId('report'),
      place_id: createObjectId('place')
    }
  }, {
    _id: createObjectId('a_report_updated'),
    name: 'a_report_updated',
    description: 'A report updated',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_REPORT_UPDATED',
      organisation_id: createObjectId('organisation'),
      form_id: createObjectId('form'),
      report_id: createObjectId('report'),
      place_id: createObjectId('place'),
      oldState: 'done',
      newState: 'done'
    }
  }, {
    _id: createObjectId('a_missions_group_updated'),
    name: 'a_missions_group_updated',
    description: 'A missionsGroup updated',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_MISSIONSGROUP_UPDATED',
      organisation_id: createObjectId('organisation'),
      missionsGroup_id: createObjectId('missionsgroup')
    }
  }, {
    _id: createObjectId('a_missions_group_deleted'),
    name: 'a_missions_group_deleted',
    description: 'A missionsGroup deleted',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_MISSIONSGROUP_DELETED',
      organisation_id: createObjectId('organisation'),
      missionsGroup_id: createObjectId('missionsgroup')
    }
  }, {
    _id: createObjectId('a_missions_group_created'),
    name: 'a_missions_group_created',
    description: 'A missionsGroup created',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_MISSIONSGROUP_CREATED',
      organisation_id: createObjectId('organisation'),
      missionsGroup_id: createObjectId('missionsgroup')
    }
  }, {
    _id: createObjectId('a_missions_group_started'),
    name: 'a_missions_group_started',
    description: 'A missionsGroup started',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_MISSIONSGROUP_STARTED',
      organisation_id: createObjectId('organisation'),
      missionsGroup_id: createObjectId('missionsgroup')
    }
  }, {
    _id: createObjectId('a_webhook_call_scheduled'),
    name: 'a_webhook_call_scheduled',
    description: 'A webhook call scheduled',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_WEBHOOK_CALL_SCHEDULED',
      organisation_id: createObjectId('organisation'),
      webhook: {
        _id: createObjectId('webhook'),
        contents: {
          label: 'Webhook 1',
          method: 'POST',
          url: 'http://example.com',
          event: 'A_REPORT_CREATED',
          headers: [],
          successStatus: 201,
          organisation_id: createObjectId('organisation')
        }
      }
    }
  }, {
    _id: createObjectId('a_sendbird_webhook'),
    name: 'a_sendbird_message_sent_webhook',
    description: 'Sendbird webhook',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_SENDBIRD_CHAT_MESSAGE_SENT',
      organisation_id: createObjectId('organisation'),
      sender_id: createObjectId('sender'),
      users_ids: [createObjectId('user')],
      channel: 'some-channel-url',
      message: 'Test message'
    }
  }, {
    _id: createObjectId('A_USERS_IMPORT_REQUESTED'),
    name: 'a_users_import_requested',
    description: 'Data import for users',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_USERS_IMPORT_REQUESTED',
      inhibWorkers: [],
      user_id: createObjectId('user'),
      organisation_id: createObjectId('organisation'),
      import_id: createObjectId('importId'),
      file_id: createObjectId('file'),
      fileType: 'CSV'
    }
  }, {
    _id: createObjectId('A_PLACES_IMPORT_REQUESTED'),
    name: 'a_places_import_requested',
    description: 'Data import for places',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_PLACES_IMPORT_REQUESTED',
      inhibWorkers: [],
      user_id: createObjectId('user'),
      organisation_id: createObjectId('organisation'),
      import_id: createObjectId('importId'),
      file_id: createObjectId('file'),
      fileType: 'XLS'
    }
  }, {
    _id: createObjectId('A_PRODUCTS_IMPORT_REQUESTED'),
    name: 'a_products_import_requested',
    description: 'Data import for products',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_PRODUCTS_IMPORT_REQUESTED',
      inhibWorkers: [],
      user_id: createObjectId('user'),
      organisation_id: createObjectId('organisation'),
      import_id: createObjectId('importId'),
      file_id: createObjectId('file'),
      fileType: 'XLSX'
    }
  }, {
    _id: createObjectId('A_VISITS_IMPORT_REQUESTED'),
    name: 'a_visits_import_requested',
    description: 'Data import for visits',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_VISITS_IMPORT_REQUESTED',
      inhibWorkers: [],
      user_id: createObjectId('user'),
      organisation_id: createObjectId('organisation'),
      import_id: createObjectId('importId'),
      file_id: createObjectId('file'),
      fileType: 'XLSX'
    }
  }, {
    _id: createObjectId('A_TASKS_IMPORT_REQUESTED'),
    name: 'a_tasks_import_requested',
    description: 'Data import for tasks',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_TASKS_IMPORT_REQUESTED',
      inhibWorkers: [],
      user_id: createObjectId('user'),
      organisation_id: createObjectId('organisation'),
      import_id: createObjectId('importId'),
      file_id: createObjectId('file'),
      fileType: 'XLSX'
    }
  }, {
    _id: createObjectId('A_CALENDAR_EVENTS_IMPORT_REQUESTED'),
    name: 'a_calendar_events_import_requested',
    description: 'Data import for calendar events',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_CALENDAR_EVENTS_IMPORT_REQUESTED',
      inhibWorkers: [],
      user_id: createObjectId('user'),
      organisation_id: createObjectId('organisation'),
      import_id: createObjectId('importId'),
      file_id: createObjectId('file'),
      fileType: 'XLSX'
    }
  }, {
    _id: createObjectId('A_DOCUMENTS_CREATED'),
    name: 'documents creation',
    description: 'one or more document was created',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_DOCUMENTS_CREATED',
      inhibWorkers: [],
      organisation_id: createObjectId('organisation'),
      documents_ids: [createObjectId('doc1'), createObjectId('doc2')]
    }
  }, {
    _id: createObjectId('A_INTEGRATION_SALESFORCE_ACCOUNT_CHANGED'),
    name: 'salesforce account changed',
    description: 'One or more account has change on salesforce',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_INTEGRATION_SALESFORCE_ACCOUNT_CHANGED',
      inhibWorkers: [],
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('A_SENDBIRD_CONFIG_UPDATED'),
    name: 'sendbird_config_updated',
    description: 'Sendbird config updated',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_SENDBIRD_CONFIG_UPDATED',
      organisation_id: createObjectId('organisation'),
      user_id: createObjectId('user'),
      payload: {
        sendbirdConfig: {
          appId: '4536D41C-1657-4F59-ASDF-6BA6ABCD9A13',
          token: 'f7abc123abc123abc123',
          apiUrl: 'https://api.sendbird.com'
        },
        resultsRecipients: 'darth@simplifield.com, yoda@simplifield.com'
      }
    }
  }, {
    _id: createObjectId('A_VALIDATION_UPDATED'),
    name: 'VALIDATION_updated',
    description: 'Validation updated',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_VALIDATION_UPDATED',
      organisation_id: createObjectId('organisation'),
      validation_id: createObjectId('validation')
    }
  }, {
    _id: createObjectId('A_CALENDAR_EVENT_RECAP_SCHEDULED'),
    name: 'A_CALENDAR_EVENT_RECAP_SCHEDULED',
    description: 'Calendar event recap',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_CALENDAR_EVENT_RECAP_SCHEDULED',
      organisation_id: createObjectId('organisation'),
      start_dateTime: '2019-12-01T18:54:00.000Z'
    }
  }, {
    _id: createObjectId('A_CALENDAR_EVENT_CHANGE_REQUEST_RECAP_SCHEDULED'),
    name: 'A_CALENDAR_EVENT_CHANGE_REQUEST_RECAP_SCHEDULED',
    description: 'Calendar event change request recap',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_CALENDAR_EVENT_CHANGE_REQUEST_RECAP_SCHEDULED',
      organisation_id: createObjectId('organisation'),
      created_dateTime: '2019-11-01T18:54:00.000Z'
    }
  }, {
    _id: createObjectId('A_CALENDAR_EVENT_CHANGE_REQUEST_STATUS_CHANGED'),
    name: 'A_CALENDAR_EVENT_CHANGE_REQUEST_STATUS_CHANGED',
    description: 'Change request accept/reject event',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_CALENDAR_EVENT_CHANGE_REQUEST_STATUS_CHANGED',
      organisation_id: createObjectId('organisation'),
      changeRequest_id: createObjectId()
    }
  }, {
    _id: createObjectId('A_CALENDAR_EVENT_CHANGED'),
    name: 'A_CALENDAR_EVENT_CHANGED',
    description: 'Changed calendarEvent',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_CALENDAR_EVENT_CHANGED',
      organisation_id: createObjectId('organisation'),
      changedBy_id: createObjectId(),
      calendarEvent_id: createObjectId(),
      originalEvent: {
        contents: {
          start_dateTime: new Date('2018-11-29T11:00:00').toISOString(),
          end_dateTime: new Date('2018-11-29T14:00:00').toISOString(),
          timezone: {
            name: 'Europe/London'
          },
          timezoneSource: 'manually_set',
          allDayLong: false,
          assignees_ids: [createObjectId('user1')],
          calendarEventType_id: createObjectId('type'),
          organisation_id: createObjectId('organisation')
        }
      },
      updatedEvent: {
        contents: {
          start_dateTime: new Date('2019-11-29T11:00:00').toISOString(),
          end_dateTime: new Date('2019-11-29T14:00:00').toISOString(),
          timezone: {
            name: 'Europe/Berlin'
          },
          timezoneSource: 'manually_set',
          allDayLong: false,
          assignees_ids: [createObjectId('user1'), createObjectId('user2')],
          calendarEventType_id: createObjectId('type'),
          organisation_id: createObjectId('organisation')
        }
      }
    }
  }, {
    _id: createObjectId('A_CALENDAR_EVENT_DELETED'),
    name: 'A_CALENDAR_EVENT_DELETED',
    description: 'Deleted calendar event',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_CALENDAR_EVENT_DELETED',
      organisation_id: createObjectId('organisation'),
      calendarEvent_id: createObjectId('calendarEvent')
    }
  }, {
    _id: createObjectId('a_reminder_email '),
    name: 'A_REMINDER_EMAIL',
    description: 'A reminder email ',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_REMINDER_EMAIL',
      reminderContent: 25,
      organisation_id: createObjectId('organisation'),
      form_id: createObjectId('form'),
      owner_id: createObjectId('owner')
    }
  }, {
    _id: createObjectId('A_CALENDAR_EVENT_CHANGE_REQUESTS_ACCEPTED_RECAP_SCHEDULED'),
    name: 'A_CALENDAR_EVENT_CHANGE_REQUESTS_ACCEPTED_RECAP_SCHEDULED',
    description: 'Accepted change requests recap',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      exchange: 'A_CALENDAR_EVENT_CHANGE_REQUESTS_ACCEPTED_RECAP_SCHEDULED',
      organisation_id: createObjectId('organisationId'),
      created_dateTime: '2019-08-19T18:00:00.000Z'
    }
  }];
};