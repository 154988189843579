export const MerchandisingItemComponent = {
  bindings: {
    profile: '<',
    validation: '<',
    onSaveSuccess: '&',
    onSaveFail: '&',
  },
  templateUrl:
    'merchandising/components/merchandising-item/merchandising-item.html',
  // eslint-disable-next-line max-params
  controller: function MerchandisingItemController(
    $state,
    merchandisingService,
    merchandisingApiService,
    merchandisingPopupService,
    merchandisingModalService
  ) {
    'ngInject';
    this.$onInit = () => {
      this.canBeValidated = merchandisingService.canBeValidated(
        this.profile,
        this.validation
      );
      this.canBePutInPending = merchandisingService.canBePutInPending(
        this.profile,
        this.validation
      );
      this.canBeRetook = merchandisingService.canBeRetook(
        this.profile,
        this.validation
      );

      this.pictureIds = merchandisingService
        .getValidations(this.validation)
        .map((answer) => answer.values[0].value);
      this.questionTitle = merchandisingService.getQuestionTitle(
        this.validation.contents.question_id,
        this.validation.form.contents.questions
      );
      this.commentsNumber = this.validation.comments.length;

      return this.validation;
    };

    this.goToDetails = () =>
      $state.go('index.menu-more.merchandising.validation-details', {
        validationId: this.validation._id,
        profile: this.profile,
      });

    this.validateAnswer = ($event) => {
      $event.stopPropagation();
      this.stateChangePending = true;

      return merchandisingApiService
        .validateAnswer(this.validation, this.profile)
        .then(() => this.onSaveSuccess())
        .catch(() => this.onSaveFail())
        .finally(() => {
          this.stateChangePending = false;
        });
    };
    this.rejectAnswer = ($event) => {
      $event.stopPropagation();
      this.stateChangePending = true;

      return merchandisingPopupService
        .showRejectLongPrompt()
        .promptPromise.then((comment) =>
          merchandisingApiService
            .rejectAnswer(this.validation, this.profile, comment)
            .then(() => this.onSaveSuccess())
            .catch(() => this.onSaveFail())
        )
        .finally(() => {
          this.stateChangePending = false;
        });
    };

    this.putAnswerInPending = ($event) => {
      $event.stopPropagation();
      this.stateChangePending = true;

      return merchandisingApiService
        .putAnswerInPending(this.validation, this.profile)
        .then(() => this.onSaveSuccess())
        .catch(() => this.onSaveFail())
        .finally(() => {
          this.stateChangePending = false;
        });
    };

    this.retakeAnswerPhoto = ($event) => {
      $event.stopPropagation();
      const retakePhotoModal =
        merchandisingModalService.openReplacerSelectorModal(
          this.validation,
          this.profile,
          () => this.onSaveSuccess(),
          () => {
            retakePhotoModal.remove();
          }
        );
    };
  },
};
