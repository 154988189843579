export default function routeConfig($stateProvider) {
  'ngInject';
  // Routes.
  $stateProvider
    .state('index.activity', {
      url: '/activity',
      abstract: true,
      views: {
        'tab-activity': {
          template: '<ion-nav-view></ion-nav-view>',
        },
      },
    })
    .state('index.activity.details', {
      url: '',
      template: `
        <ion-view class="sf_activity sf_view__has_nav_bar">
          <sf-view sf-tab-bar-hide="false">
            <sf-activity
              organisation="$ctrl.organisation"
              profile="$ctrl.profile">
            </sf-activity>
          </sf-view>
        </ion-view>
      `,
      controller: ActivityController,
      controllerAs: '$ctrl',
      analyticsName: 'Activity',
    });
}

// @ngInject
function ActivityController(rsvpProfile, rsvpOrganisation) {
  this.profile = rsvpProfile;
  this.organisation = rsvpOrganisation;
}
