import AvatarOrganisationController from './avatar-organisation.controller';

export const AvatarOrganisationComponent: ng.IComponentOptions = {
  bindings: {
    organisation: '<?',
    size: '@',
  },
  templateUrl: 'components/Avatar/avatar-organisation/avatar-organisation.html',
  controller: AvatarOrganisationController,
};
