import { Organisation } from '../../..';
import { NewsfeedPost } from '../../types';

export const NewsfeedDefaultPostComponent = {
  templateUrl:
    'newsfeed/components/newsfeed-default-post/newsfeed-default-post.html',
  controller: class NewsfeedDefaultPostController {
    defaultPost: Omit<NewsfeedPost, '_id' | 'targeting'>;

    constructor(
      private $translate: ng.translate.ITranslateService,
      private organisationsService,
      private profileService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<void> {
      return this.profileService
        .getLocalProfile()
        .then(this.organisationsService.getProfileOrganisation)
        .then((organisation: Organisation) => {
          this.defaultPost = {
            contents: {
              text: this.$translate.instant('NEWSFEED_DEFAULT_POST'),
              author: {
                name: organisation.contents.name,
                logo_id: organisation.contents.logo_id,
              },
            },
            created_date: new Date().toISOString(),
          };
        });
    }
  },
};
