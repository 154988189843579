export const ButtonUploadComponent: ng.IComponentOptions = {
  bindings: {
    label: '@',
    illustrationName: '@',
    isLoading: '<',
    uploadedStatus: '<',
    disabled: '<',
    onClick: '&',
    deleteDocument: '&',
    hasDeleteButton: '<?',
  },
  transclude: true,
  templateUrl: 'components/Buttons/button-upload/button-upload.html',
  controller: class ButtonUploadController implements ng.IController {
    illuPath;
    illustrationName;
    hasDeleteButton = false;

    deleteDocument: () => void;

    constructor() {
      'ngInject';
    }
    $onInit() {
      this.illuPath = `img/icons/${this.illustrationName}.svg`;
    }

    onDelete(event: Event) {
      event.stopPropagation();

      this.deleteDocument();
    }
  },
};
