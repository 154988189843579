export const UserListComponent = {
  bindings: {
    users: '<',
    onClose: '&',
  },
  templateUrl: 'components/users-list/users-list.html',
  controller: class UserListController {
    constructor() {
      this.search = '';
    }

    $onInit() {
      this.filteredUsers = [...this.users];
    }

    filterUsers() {
      const criteria = new RegExp(this.search, 'i');

      this.filteredUsers = this.users.filter((user) =>
        user.contents.fullName.match(criteria)
      );
    }

    onUsersSearchChange(search) {
      this.search = search;
      this.filterUsers();
    }
  },
};
