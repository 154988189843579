import { StateService } from '@uirouter/angularjs';
import { IDeferred } from 'angular';
import { CommentsFileService } from 'app/comments/services/comments-file/comments-file.service';
import { NewsfeedService } from 'app/newsfeed/services/newsfeed-service/newsfeed.service';
import {
  always,
  cond,
  find,
  hasPath,
  isNil,
  path,
  pipe,
  prop,
  propEq,
  propOr,
  T,
} from 'ramda';
import { Task } from '..';
import { ObjectId, User, UserHash } from '../../';
import { Comment } from '../../comments';
import { ContentService } from '../../components/Layout/content/content.service';
import { FilesService } from '../../services/API/files/files.service';
import { AppMessageService } from '../../services/Utils/AppMessage/app-message.service';
import { DateService } from '../../services/Utils/Dates/date.service';
import { ErrorMessagesService } from '../../services/Utils/ErrorMessages/error-messages.service';
import { LocalizationService } from '../../services/Utils/Localization/localization.service';
import { ObjectIdService } from '../../services/Utils/Objectid/objectId.service';
import { Popup } from '../../services/Utils/Popup/popup.service';
import { SF_TASK_DETAILS_TABS } from '../constants/task-details-tabs.constant';
import { SF_TASK_STATUSES } from '../constants/task-statuses.constant';
import { TaskCommentsPopupService } from '../services/task-comments.popup.service';
import { TaskService } from '../services/task.service';

const COMMENT_MAX_SIZE = 1000;
const DELETE_COMMENT_TITLE = 'TASK_DELETE_COMMENT_CONFIRM_TITLE';
const DELETE_COMMENT_BUTTON = 'TASK_DELETE_COMMENT_CONFIRM_BUTTON';
const DELETE_COMMENT_ERROR = 'TASK_DELETE_ERROR_TITLE';
const COMMENT_DELETE_CANCELED = 'canceled';

export class TaskDetailsController implements ng.IComponentController {
  // bindings
  offlineMode: boolean;
  profile: User;
  taskId: ObjectId;
  isChecklistTab: boolean;
  onSave: () => (data: unknown) => void;
  onClose: (data?: { task: Task }) => void;
  onTaskUpdate: (task: Task) => void;

  // attributes
  task: Task;
  currentStickyHandle: string;
  isOverviewActive: boolean;
  isRTLNeeded: boolean;
  campaignName?: string;
  closeInputKeyboard: () => void;
  contentKey = 'taskDetailsContent';
  isLoading = false;
  networkError = false;

  private _isOwner: boolean;
  private _isAssignee: boolean;
  isRelatedToPlace = false;

  // comments attributes
  keyboardModeType: string;
  isCommentsLoading = false;
  hasCommentsError = false;
  isCommentsSending = false;
  comments: Comment[] = [];
  commentsUsers: UserHash = {};
  commentsCount = 0;
  isAddCommentFieldDisplayed = false;
  isEditCommentFieldDisplayed = false;
  editableComment: Comment | null = null;
  editableCommentId;
  editCommentContent = '';
  commentField = '';
  commentsMaxSize = COMMENT_MAX_SIZE;

  /* @ngInject */
  constructor(
    private $element: ng.IRootElementService,
    private $q: ng.IQService,
    private $state: StateService,
    private $stateParams: Record<string, unknown>,
    private $timeout: ng.ITimeoutService,
    private $translate: ng.translate.ITranslateService,
    private appMessageService: AppMessageService,
    private contentService: ContentService,
    private dateService: DateService,
    private errorMessagesService: ErrorMessagesService,
    private filesService: FilesService,
    private formsService,
    private localizationService: LocalizationService,
    private objectIdService: ObjectIdService,
    private taskCommentsPopupService: TaskCommentsPopupService,
    private taskService: TaskService,
    private tasksService,
    private placesService,
    private KEYBOARD_MODES: Record<string, string>,
    private TASK_DETAILS_TABS: typeof SF_TASK_DETAILS_TABS,
    private TASK_STATUSES: typeof SF_TASK_STATUSES,
    private helpersService,
    private newsfeedService: NewsfeedService,
    private commentsFileService: CommentsFileService
  ) {}

  $onInit = (): ng.IPromise<void | null> => {
    if (!this.taskId && this.$state.params.taskId) {
      this.taskId = this.$state.params.taskId;
    }

    this.keyboardModeType = this.KEYBOARD_MODES.BODY;
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.onTabSelect(this.TASK_DETAILS_TABS.OVERVIEW.keyword);

    return this.callData();
  };

  callData() {
    return this.getTask()
      .then(() => {
        let relatedPromise = this.$q.resolve({});
        if (!this.isOwnerOrAssignee() && this.task.contents.place_id) {
          relatedPromise = this.placesService.checkPlaceUsersRelations(
            this.task.contents.place_id,
            [this.profile._id]
          );
        }
        return this.$q
          .all([relatedPromise, this.getComments()])
          .then(([userRelations]: [Record<string, boolean>, Comment[]]) => {
            this.isRelatedToPlace = !!userRelations[this.profile._id];
          });
      })
      .catch(() => null); // getTask processes critical errors
  }

  getTask = (): ng.IPromise<void> => {
    this.isLoading = true;
    this.networkError = false;
    return this.tasksService
      .getTask(this.getId(), {}, this.offlineMode)
      .then((task) => {
        if (this.taskService.isArchived(task)) {
          return this.handleTaskDeleted();
        }
        this.isLoading = false;
        return this.resolveTask(task);
      })
      .then(() => {
        if (this.task.form && this.task.form._id) {
          return this.aggregateFormLanguage();
        }

        return this.task;
      })
      .then(() => {
        this.campaignName = this.task.campaign?.name ?? '';
      })
      .catch(this._resolveError.bind(this));
  };

  getId = (): ObjectId => this.taskId || (this.$stateParams.taskId as ObjectId);

  aggregateFormLanguage = (): Task => {
    return this.formsService
      .getLocal((this.task.form as { _id: ObjectId; i18n: string })._id)
      .then((form) => {
        (this.task.form as { _id: ObjectId; i18n: string }).i18n = form.i18n;
        return this.task;
      })
      .catch(() => {
        // this catch doesn't work on mobile devices. Don't know why
        return this.task;
      });
  };

  resolveTask = (task): void => {
    this.task = {
      _id: task._id,
      isMandatory: !!task.isMandatory,
      isAutotask: !!task.isAutotask,
      ...(task.contents.form_id
        ? { form: prop(task.contents.form_id, task.forms || {}) }
        : {}),
      ...(task.contents.campaign_id
        ? { campaign: prop(task.contents.campaign_id, task.campaigns || {}) }
        : {}),
      ...(task.contents.place_id
        ? { place: this._getObjectFromContents(task, task.places, 'place_id') }
        : {}),
      assignee: this._getObjectFromContents(task, task.users, 'assignee_id'),
      owner: this._getObjectFromContents(task, task.users, 'owner_id'),
      users: task.users,
      question: (
        pipe(
          cond([
            [
              hasPath(['contents', 'form_id']),
              path(['forms', task.contents.form_id]),
            ],
            [
              hasPath(['contents', 'campaign_id']),
              path(['campaigns', task.contents.campaign_id, 'form']),
            ],
            [T, always({})],
          ]),
          propOr([], 'questions'),
          find(propEq(task.contents.question_id, '_id'))
        ) as (data: unknown) => unknown
      )(task) as unknown as { title: string },
      contents: {
        ...task.contents,
        ...(task.contents.subtasks
          ? {
              subtasks: task.contents.subtasks.map((subtask) => ({
                ...subtask,
                assignee: {
                  contents: task.users[subtask.assignee_id as ObjectId] || {
                    firstName: '-',
                    lastName: '-',
                  },
                  _id: subtask.assignee_id,
                },
              })),
            }
          : {}),
      },
      created_date: task.created_date,
      filesMetadata: (
        Object.entries(task.filesMetadata || {}) as unknown as [
          string,
          { fileName: string; fileLength: unknown; fileType: string }
        ][]
      ).map(([_id, { fileName, fileLength, fileType }]) => ({
        _id,
        name: fileName,
        size: fileLength,
        mime_type: fileType,
      })),
    };
  };

  cancelEditComment() {
    this.editableComment = null;
    this.editableCommentId = null;
    this.isEditCommentFieldDisplayed = false;
    this.focusOnCommentInput();
  }

  private _getObjectFromContents = (task, obj, property) => {
    obj = obj || {};
    const objId = task.contents[property];

    obj = { _id: objId, ...obj[objId] } || {};
    return obj;
  };

  private _resolveError = (error) => {
    if (this.errorMessagesService.isNetworkError(error)) {
      this.networkError = true;
      this.isLoading = false;
      return false;
    }
    this.appMessageService.display(
      this.$translate.instant('TASK_DETAILS_UNAVAILABLE_TASK'),
      'fail'
    );
    this.onCloseModal();
    throw error;
  };

  showOverdueTask = (): boolean => {
    const timezonedDate = this.helpersService.getDueDateTimezonedHtml5(
      this.profile,
      this.task.contents.due_date
    );

    return this.taskService.showOverdueTask(
      timezonedDate,
      this.task.contents.status
    );
  };

  onTabSelect(tab: string): void {
    this.currentStickyHandle = 'taskDetails' + tab;
    this.isOverviewActive = tab === this.TASK_DETAILS_TABS.OVERVIEW.keyword;
  }

  // helper methods
  public get isOwner(): boolean {
    if (isNil(this._isOwner)) {
      this._isOwner = this.taskService.isOwnerOfTask(this.task, this.profile);
    }
    return this._isOwner;
  }

  public get isAssignee(): boolean {
    if (isNil(this._isAssignee)) {
      this._isAssignee = this.taskService.isAssigneeOfTask(
        this.task,
        this.profile
      );
    }

    return this._isAssignee;
  }

  isDeleteForbidden = (): boolean => !!this.task?.contents?.taskGroup_id;

  isOwnerOrAssignee = (): boolean => this.isOwner || this.isAssignee;

  isTaskAssigned = (): boolean => {
    return !!this.task?.contents.assignee_id;
  };

  isDone = (): boolean => this.taskService.isDone(this.task.contents.status);

  getScrollingCoordinates = (): number => {
    const content = this.$element[0].querySelector(
      '#task-details-content-area'
    ) as Element;
    const pageHeader = this.$element[0].querySelector(
      '.sf_header_title'
    ) as Element;

    return content.clientHeight - pageHeader.clientHeight;
  };

  handleTaskDeleted = (): void =>
    this.taskService.showTaskDeletedError(() => {
      this.onCloseModal();
    });

  deleteTask = (): ng.IPromise<void> => {
    this.isLoading = true;
    return this.tasksService
      .remove(this.task._id, this.offlineMode)
      .then((task) =>
        this.onStatusChanged(task, this.TASK_STATUSES.DELETED.keyword)
      )
      .catch((error) => {
        const errorMessage = this.errorMessagesService.getMessage(error, {
          customUnknownErrorMessage: 'TASK_DETAILS_DELETE_FAILED',
        });

        this.appMessageService.display(
          this.$translate.instant(errorMessage),
          'fail'
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  onStatusChanged = (task: Task, status: string): void =>
    this.onSave()({ task, status });

  openEditModal = (): ng.IPromise<void> =>
    this.taskService
      .openTaskManageModal(
        this.task,
        this.profile,
        { isRelatedToPlace: this.isRelatedToPlace },
        this.offlineMode
      )
      .then((taskChanged) => {
        const { managedTask: task } = taskChanged;

        if (this.taskService.isDeleted(task.contents.status)) {
          return this.onClose({ task });
        }
        this.task = task;

        if (this.isChecklistTab) {
          this.callData();
        }
      });

  openDeleteModal = (): ng.IPromise<void> => {
    return this.taskService
      .showDeleteAgreeModal()
      .then(() => this.deleteTask());
  };

  onCloseModal = (): void => this.onClose({ task: this.task });

  // comments related metods
  getComments = (): ng.IPromise<Comment[]> => {
    this.isCommentsLoading = true;
    this.hasCommentsError = false;

    return this.taskService.commentsService
      .list(this.task._id)
      .then(({ entries, users, count }) =>
        this.updateComments(entries, users, count)
      )
      .catch((err) => {
        this.hasCommentsError = true;

        throw err;
      })
      .finally(() => {
        this.isCommentsLoading = false;
      });
  };

  updateComments = (
    comments: Comment[],
    users: UserHash,
    count: number
  ): Comment[] => {
    this.comments = comments.concat(this.comments);
    this.commentsCount = this.commentsCount + count;
    const newCommentUsers = Object.keys(users).reduce((obj, userId) => {
      obj[userId] = {
        _id: userId,
        contents: users[userId],
      };
      return obj;
    }, {});

    this.commentsUsers = {
      ...this.commentsUsers,
      ...newCommentUsers,
    };
    return comments;
  };

  postComment = (
    mediaFileId: ObjectId | null = null,
    type
  ): ng.IPromise<void> => {
    this.isAddCommentFieldDisplayed = false;
    const newComment = this.buildComment(mediaFileId, type);
    this.isCommentsSending = true;

    return this.taskService.commentsService
      .create(this.task._id, newComment)
      .then((comment) => {
        this.commentField = '';
        this.closeInputKeyboard();
        this.updateComments([comment], comment.users as UserHash, 1);
        this.scrollToFirstComment();
      })
      .catch((err) => {
        this.hasCommentsError = true;
        throw err;
      })
      .finally(() => {
        this.isCommentsSending = false;
      });
  };

  buildComment = (mediaFileId, type): Comment => {
    if (mediaFileId && type) {
      return this.taskService.commentsService.buildComment(
        this.profile,
        '',
        { task_id: this.task._id },
        mediaFileId,
        type
      );
    }

    return this.taskService.commentsService.buildComment(
      this.profile,
      this.commentField,
      { task_id: this.task._id }
    );
  };

  postMediaComment = (fileBlob): ng.IPromise<void> => {
    const type = this.newsfeedService.getResourceTypeFromMimeType(
      fileBlob.type
    );

    const validFiles = this.commentsFileService.getValidFromSelectedFiles(
      [fileBlob],
      type
    );

    if (!validFiles.length) {
      return this.$q.resolve();
    }

    this.isAddCommentFieldDisplayed = false;
    const mediaFileId = this.objectIdService.create();
    const cancelPromise: IDeferred<void> = this.$q.defer();
    const onSendCancel = () => {
      cancelPromise.resolve();
      popupSending.clear();
    };

    const popupSending =
      this.taskCommentsPopupService.showPictureCommentSendingProgress(
        onSendCancel
      );

    return this.filesService
      .upload(fileBlob, mediaFileId, {
        canceler: cancelPromise,
        fileNameOverride: type === 'document' ? fileBlob.name : undefined,
      })
      .then(() => this.postComment(mediaFileId, type))
      .then(() => popupSending.onSuccess())
      .catch(() => popupSending.onError());
  };

  updateOneComment = (): ng.IPromise<void> => {
    if (!this.editableComment) {
      return this.taskCommentsPopupService.showError(
        'TASK_COMMENT_EDIT_ERROR_TITLE'
      );
    }

    this.editableCommentId = null;

    const index = this.comments.findIndex(
      (el) => el._id === (this.editableComment as Comment)._id
    );

    if (index === -1) {
      return this.taskCommentsPopupService.showError(
        'TASK_COMMENT_EDIT_ERROR_TITLE'
      );
    }

    this.editableComment = {
      ...this.editableComment,
      contents: {
        ...this.editableComment.contents,
        content: this.editCommentContent,
      },
    };
    this.comments.splice(index, 1, this.editableComment);
    this.isCommentsSending = true;

    return this.taskService.commentsService
      .edit(this.task._id, this.editableComment)
      .then(() => this.closeInputKeyboard())
      .catch((err) => {
        this.hasCommentsError = true;

        throw err;
      })
      .finally(() => {
        this.isCommentsSending = false;
        this.editCommentContent = '';
        this.isEditCommentFieldDisplayed = false;
        this.editableComment = null;
      });
  };

  // user event handlers
  onEditCommentClick = (commentId: ObjectId): void | Popup => {
    this.isAddCommentFieldDisplayed = false;
    this.setEditableComment(commentId);

    if (!this.editableComment) {
      return this.taskCommentsPopupService.showError(
        'TASK_COMMENT_EDIT_ERROR_TITLE'
      );
    }

    this.isEditCommentFieldDisplayed = true;
    return this.focusOnCommentInput();
  };

  setEditableComment = (commentId: ObjectId): void => {
    this.editableComment =
      this.comments.find((comment) => comment._id === commentId) || null;
    this.editCommentContent = this.editableComment?.contents.content || '';
    this.editableCommentId = commentId;
  };

  onDeleteCommentClick = (commentId: ObjectId): ng.IPromise<Comment | void> => {
    if (!this.comments.find((comment) => comment._id === commentId)) {
      return this.taskCommentsPopupService.showError(DELETE_COMMENT_ERROR);
    }

    const commentsBackup = [...this.comments];
    const commentsCountBackup = this.commentsCount;

    return this.taskCommentsPopupService
      .showDeleteConfirm(DELETE_COMMENT_TITLE, DELETE_COMMENT_BUTTON)
      .catch(() => {
        throw new Error(COMMENT_DELETE_CANCELED);
      })
      .then(() => this.removeComment(commentId))
      .catch((err: Error) => {
        if (err.message === COMMENT_DELETE_CANCELED) {
          return;
        }
        this.taskCommentsPopupService.showError(DELETE_COMMENT_ERROR);
        this.restoreComments(commentsBackup, commentsCountBackup);
      });
  };

  removeComment = (commentId: ObjectId): ng.IPromise<Comment> => {
    this.comments = this.comments.filter(
      (comment) => comment._id !== commentId
    );
    this.commentsCount = this.commentsCount - 1;

    return this.taskService.commentsService.delete(this.task._id, commentId);
  };

  restoreComments = (
    commentsBackup: Comment[],
    commentsCountBackup: number
  ): void => {
    this.comments = commentsBackup;
    this.commentsCount = commentsCountBackup;
  };

  onAddCommentClick = (): void => {
    this.isEditCommentFieldDisplayed = false;
    this.isAddCommentFieldDisplayed = true;
    this.focusOnCommentInput();
  };

  // ui controllers
  hideCommentInput = (): void => {
    this.isAddCommentFieldDisplayed = false;
    this.isEditCommentFieldDisplayed = false;
  };

  focusOnCommentInput = (): void => {
    this.$timeout(() => {
      const input: HTMLTextAreaElement | null = this.$element[0].querySelector(
        '#sf_footer_text_input__input_id'
      );

      if (input) {
        input.focus();
      }
    });
  };

  scrollToFirstComment = (): void => {
    const coordinateToScroll = this.getScrollingCoordinates();
    this.contentService.scrollTopById(this.contentKey, coordinateToScroll);
  };
  onTaskSelfAssign = (newValue: Task): void => {
    const selfAssignee = newValue.assignee;
    this.task = {
      ...this.task,
      assignee: { ...selfAssignee },
      contents: {
        ...this.task.contents,
        assignee_id: selfAssignee._id,
      },
    };
  };

  onTaskAssignedToOther(newValue: Task): void {
    this.task = newValue;

    this.onTaskUpdate(newValue);
  }
}
