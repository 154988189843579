export const ReceiptLineSalesComponent = {
  bindings: {
    answer: '<',
    onChangeMethod: '&',
    onRemoveMethod: '&',
    isReadOnly: '<',
  },
  templateUrl:
    'missions/components/Receipt/receipt-line-sales/receipt-line-sales.html',
  controller: function ReceiptLineSalesController(
    productsService,
    SF_CURRENCIES,
    SF_PRODUCT_DISCOUNT_BOUNDARIES,
    SF_PRODUCT_QUANTITY_BOUNDARIES
  ) {
    'ngInject';
    const EAN_VALUES_INDEX = 0;
    const QUANTITY_VALUES_INDEX = 1;
    const DISCOUNT_VALUES_INDEX = 2;

    this.$onInit = () => {
      this.quantityField = this.answer.values[QUANTITY_VALUES_INDEX];
      this.discountField = this.answer.values[DISCOUNT_VALUES_INDEX];
      this.currencySymbol = SF_CURRENCIES.EURO.symbol;
      this.MINIMUM_QUANTITY = SF_PRODUCT_QUANTITY_BOUNDARIES.MIN;
      this.MINIMUM_DISCOUNT = SF_PRODUCT_DISCOUNT_BOUNDARIES.MIN;
      this.MAXIMUM_DISCOUNT = SF_PRODUCT_DISCOUNT_BOUNDARIES.MAX;

      return productsService
        .getByEans([this.answer.values[EAN_VALUES_INDEX].value])
        .then((product) => {
          this.fullImageUrl =
            product && product.imageUrls && product.imageUrls.full;
          this.thumbImageUrl =
            product && product.imageUrls && product.imageUrls.thumb;
        });
    };

    this.removeProduct = () => this.onRemoveMethod({ answer: this.answer });

    this.computeSellTotal = () => {
      const totalWithDiscount = this.applyDiscount(
        this.answer.metadata.sellPrice * this.quantityField.value,
        this.discountField.value
      );

      this.answer.metadata.sellTotalPrice =
        this.roundTwoDecimalsNumber(totalWithDiscount);

      return this.onChangeMethod();
    };

    this.applyDiscount = (value, discount) =>
      value * ((100 - discount) / 100) || 0;

    this.roundTwoDecimalsNumber = (value) => Math.round(value * 100) / 100;

    this.formatDiscount = (discount) => (discount > 0 ? `${discount}%` : '-');
    this.getAnswerForm = () => this[`sales_answer_${this.answer._id}`];
    this.getQuantityError = () => this.getAnswerForm().product_quantity.$error;
    this.getDiscountError = () => this.getAnswerForm().product_discount.$error;
  },
};
