export default function newsfeedRouteConfig($stateProvider) {
  'ngInject';

  // Routes
  $stateProvider
    .state('index.newsfeed', {
      url: '/newsfeed',
      abstract: true,
      views: {
        'tab-newsfeed': {
          template: '<ion-nav-view></ion-nav-view>',
        },
      },
    })
    .state('index.newsfeed.list', {
      url: '',
      cache: false,
      template: `
        <ion-view ng-class="{ 'sf_view__has_nav_bar': $ctrl.isInNavBar}">
          <sf-newsfeed
            profile="$ctrl.profile"
            organisation="$ctrl.organisation"
          ></sf-newsfeed>
        </ion-view>
      `,
      controller: NewsfeedViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Newsfeed',
      resolve: {
        localProfile: getLocalProfile,
        organisation: getOrganisation,
      },
    })
    .state('index.newsfeed.category', {
      url: '/category/:category_id',
      template: `
        <ion-view ng-class="{ 'sf_view__has_nav_bar': $ctrl.isInNavBar}">
          <sf-newsfeed
            profile="$ctrl.profile"
            organisation="$ctrl.organisation"
            category-id="$ctrl.categoryId"
          ></sf-newsfeed>
        </ion-view>
      `,
      controller: NewsfeedViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Newsfeed',
      resolve: {
        localProfile: getLocalProfile,
        organisation: getOrganisation,
      },
    })
    .state('index.newsfeed.details', {
      url: '/post/:postId?isScrollToComments&isInputFocused',
      params: {
        isInputFocused: 'false',
        isScrollToComments: 'false',
      },
      template: `
        <ion-view>
          <sf-newsfeed-post-details
            newsfeed-post="$ctrl.newsfeedPost"
            profile="$ctrl.profile"
          ></sf-newsfeed-posts-details>
        </ion-view>
      `,
      controller: NewsfeedPostViewController,
      controllerAs: '$ctrl',
      analyticsName: 'NewsfeedPostDetails',
      resolve: {
        localProfile: getLocalProfile,
      },
    });

  function NewsfeedViewController(
    $state,
    userExperienceService,
    localProfile,
    organisation
  ) {
    'ngInject';
    this.profile = localProfile;
    this.organisation = organisation;
    this.categoryId = $state.params.category_id;
    this.isInNavBar = userExperienceService
      .getNav(this.organisation)
      .find((nav) => nav.key === 'newsfeed');
  }
  function NewsfeedPostViewController(localProfile) {
    'ngInject';
    this.profile = localProfile;
  }
}

function getLocalProfile(profileService) {
  'ngInject';
  return profileService.getLocalProfile();
}
function getOrganisation(localProfile, organisationsService) {
  'ngInject';
  return organisationsService.getProfileOrganisation(localProfile);
}
