import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

const STATUS_BAR_BACKGROUND_COLOR = '#393F45';
const APP_STORE_URL_ANDROID = 'market://details?id=com.simplifield.app';
const APP_STORE_URL_IOS =
  'https://apps.apple.com/us/app/simplifield/id1010713255';

export function animateConfig($animateProvider) {
  'ngInject';
  $animateProvider.classNameFilter(/\banimated\b/);
}

// -------------------------
//
//   Ionic Initialization
//
// -------------------------
// @ngInject
// eslint-disable-next-line max-params
export function runApp(
  appStateService,
  keyboardService,
  localizationService,
  geolocationService,
  platformService,
  pubSubService
) {
  'ngInject';

  localizationService.init();
  pubSubService.init();

  if (platformService.isAndroid() || platformService.isiOS()) {
    geolocationService.displayLocationPopupIfNeeded();
  }

  if (platformService.isPluginAvailable('StatusBar')) {
    StatusBar.setStyle({
      style: Style.Dark,
    });

    // Not implemented on iOS
    if (platformService.isAndroid()) {
      StatusBar.setBackgroundColor({
        color: STATUS_BAR_BACKGROUND_COLOR,
      });
    }
  }

  keyboardService.showAccessoryBar(true);
  keyboardService.setResizeMode('none');

  appStateService.initAppServices();
}

export function logStateErrors($rootScope, $log) {
  'ngInject';
  const stateChangeListener = $rootScope.$on('$stateChangeError', logErrors);

  $rootScope.$on('$destroy', stateChangeListener);

  // eslint-disable-next-line max-params
  function logErrors(event, toState, toParams, fromState, fromParams, error) {
    error = error || {};
    $log.error(toState.name, ':', error.stack);
    throw error;
  }
}

export function removeSplashScreen($rootScope) {
  'ngInject';
  const loadedListerner = $rootScope.$on('$ionicView.loaded', () => {
    loadedListerner(); // Kill listener
    SplashScreen.hide();
  });
}

export function versionInterceptor($injector, ConfigServer) {
  'ngInject';

  var appVersion = ConfigServer.APP_VERSION.toString();

  return { request: versionAdd };

  function versionAdd(config) {
    const apiUrlService = $injector.get('apiUrlService');
    const isApiUrl = config.url.indexOf(apiUrlService.getApiUrl()) !== -1;
    const isAuthUrl = config.url.indexOf(apiUrlService.getAuthUrl()) !== -1;

    // Add token for Simplifield API and Auth url
    if (isApiUrl || isAuthUrl) {
      config.headers = config.headers || {};
      config.headers['X-SF-IONIC-VERSION'] = appVersion;
    }

    return config;
  }
}

export function deprecationInterceptor($injector, $q) {
  'ngInject';
  let errorIsTrigerred = false;

  return { responseError };

  function responseError(res) {
    const $http = $injector.get('$http');
    const $translate = $injector.get('$translate');
    const popupService = $injector.get('popupService');
    const platformService = $injector.get('platformService');
    const $window = $injector.get('$window');
    const inAppBrowserService = $injector.get('inAppBrowserService');
    const SF_ERROR_CODES = $injector.get('SF_ERROR_CODES');
    const onUpdateClick = () => {
      errorIsTrigerred = false;

      if (platformService.isiOS()) {
        inAppBrowserService.open(APP_STORE_URL_IOS);
        return;
      }

      if (platformService.isAndroid()) {
        $window.open(APP_STORE_URL_ANDROID, '_blank');
        return;
      }

      $window.location.reload();
    };
    const popupConfig = {
      title: $translate.instant('DEPRECATION_INTERCEPTOR_TITLE'),
      desc: $translate.instant('DEPRECATION_INTERCEPTOR_DESC'),
      iconName: 'rocket',
      actions: [
        {
          text: $translate.instant('DEPRECATION_INTERCEPTOR_CANCEL_BUTTON'),
          action: () => {},
        },
        {
          text: $translate.instant('DEPRECATION_INTERCEPTOR_BUTTON'),
          action: onUpdateClick,
        },
      ],
    };

    if (
      res.status === SF_ERROR_CODES.CODE_SERVER_DEPRECATED &&
      !errorIsTrigerred
    ) {
      errorIsTrigerred = true;

      $http.pendingRequests = $http.pendingRequests.map((req) => {
        if (req.cancel) {
          req.cancel.resolve();
        }
        return req;
      });

      popupService.show(popupConfig);
    }

    return $q.reject(res);
  }
}
