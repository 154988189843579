import { DashboardDetailsModalController } from './dashboard-details-modal.controller';

export const DashboardDetailsModalComponent = {
  bindings: {
    dashboard: '<',
    filterable: '<',
    onClose: '<',
    defaultFilters: '<?',
  },
  templateUrl:
    'analytics/components/dashboard-details-modal/dashboard-details-modal.html',
  controller: DashboardDetailsModalController,
};
