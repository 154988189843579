import { TaskItemController } from './task-item.controller';

export const TaskItemComponent: ng.IComponentOptions = {
  bindings: {
    task: '<',
    user: '<',
    place: '<',
    profile: '<',
    onContentClick: '&',
    onTaskAction: '&?',
    checklistMode: '<?',
    onDateSelect: '&?',
  },
  transclude: true,
  templateUrl: 'tasks/components/task-item/task-item.html',
  controller: TaskItemController,
};
