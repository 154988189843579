export const PlaceFieldMetricsComponent = {
  bindings: {
    place: '<',
  },
  templateUrl:
    'places/place/components/place-field-metrics/place-field-metrics.html',
  controller: function PlaceFieldMetricsController(
    fieldMetricsService,
    segmentService
  ) {
    'ngInject';

    this.$onInit = () => {
      segmentService.page('Place Field Metrics');
      return this.fetchFieldMetrics();
    };

    this.fetchFieldMetrics = () => {
      this.fieldMetrics = [];
      this.isLoadingFieldMetrics = true;
      this.hasError = false;

      return fieldMetricsService
        .getForStore({
          store_id: this.place._id,
        })
        .then((fieldMetrics) => {
          this.fieldMetrics = fieldMetrics;
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.isLoadingFieldMetrics = false;
        });
    };

    this.hasFieldMetrics = () =>
      !!(this.fieldMetrics && this.fieldMetrics.length);
  },
};
