export const refreshData = (dashboardComponent, iframe, itemId) => {
    if (iframe) {
        iframe.contentWindow.postMessage({
            action: 'refreshData',
            id: itemId ?? null,
            _version: dashboardComponent.VERSION
        }, '*');
        return Promise.resolve();
    }
    return Promise.reject(new Error('Cannot retrieve contentWindow.'));
};
