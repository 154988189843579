import { NetworkError } from './errors';

export function errorsInterceptor($log: ng.ILogService): ng.IHttpInterceptor {
  'ngInject';
  return {
    responseError: networkErrorHandling,
  };

  function networkErrorHandling<T>(
    rejection: ng.IHttpResponse<T>
  ): ng.IPromise<ng.IHttpResponse<T>> | ng.IHttpResponse<T> {
    if (!rejection) {
      throw new Error('Unknown error - no IHttpResponse');
    }
    const error = new NetworkError<T>(
      rejection,
      rejection.status,
      rejection.statusText,
      rejection && rejection.config
        ? rejection.config.url
        : 'config.url is undefined'
    );

    $log.error(error);
    throw error;
  }
}
