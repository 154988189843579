import FormQuestionActionsModule from './form-question-actions.constant';
import { FormQuestionActionsComponent } from './form-question-actions.component';

export default angular
  .module('sfMobile.components.question-actions', [
    FormQuestionActionsModule,
    'sfMobile.services.task',
    'sfMobile.services.reportQuestions',
    'sfMobile.services.tasks',
    'sfMobile.services.form',
    'sfMobile.services.action-sheet',
    'sfMobile.components.button-item-actions',
  ])
  .component('sfFormQuestionActions', FormQuestionActionsComponent).name;
