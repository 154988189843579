import { FormService } from './form.service';
import { FormTasksService } from './form-tasks.service';

export default angular
  .module('sfMobile.services.form', [
    'sfMobile.services.reports',
    'sfMobile.services.report-sending',
    'sfMobile.services.file-system',
    'sfMobile.services.geolocation',
    'sfMobile.services.tasks',
    'sfMobile.components.form-score-details',
    'sfMobile.services.objectId',
    'sfMobile.services.task-manage',
  ])
  .service('formTasksService', FormTasksService)
  .factory('formService', FormService).name;
