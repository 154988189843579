import { StateService } from '@uirouter/core';

export default class NewsfeedUpdatesInfoController {
  unseenPostsCount: string;

  constructor(private $state: StateService) {
    'ngInject';
  }

  goToNewsfeed(): void {
    this.$state.go('index.newsfeed.list', {});
  }
}
