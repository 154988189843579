import { PubSubService } from '../PubSub/pubsub.service';

const ONLINE_STORES_PREFERENCE_KEY = 'mobile.onlineStores.enabled';

export class RemoteStoresService {
  constructor(
    private preferencesService,
    private pubSubService: PubSubService
  ) {
    'ngInject';
  }

  isPreferenceActivated(): ng.IPromise<boolean> {
    return this.preferencesService.query(ONLINE_STORES_PREFERENCE_KEY, true);
  }

  savePreference(value: boolean): ng.IPromise<void> {
    return this.preferencesService
      .save(ONLINE_STORES_PREFERENCE_KEY, value)
      .then(() => {
        this.pubSubService.publish('ONLINE_STORES_PREFERENCE_CHANGED');
      });
  }
}
