import { FormFilesComponent } from './form-files.component';

export default angular
  .module('sfMobile.components.formFiles', [
    'sfMobile.templates',
    'sfMobile.constants.image-sizes',
    'sfMobile.services.forms',
    'sfMobile.services.files',
    'sfMobile.services.file-system',
    'sfMobile.components.imageFallback',
    'sfMobile.components.button-document',
    'sfMobile.services.image-source',
  ])
  .component('sfFormFiles', FormFilesComponent).name;
