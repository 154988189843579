const TOASTER_ANIMATION_TIME = 150;
const TOASTER_DISPLAY_TIME = 4000;

const APP_MESSAGE_COLORS = {
  fail: 'sf_message--fail',
  success: 'sf_message--success',
  pending: 'sf_message--pending',
  info: 'sf_message--info',
  _default: 'sf_message--success',
};

export const MessageToasterComponent = {
  bindings: {
    type: '@?',
    showToasterFn: '=?',
  },
  transclude: true,
  templateUrl: 'components/Notifications/toaster/message-toaster.html',
  controller: function MessageToasterController($q, $timeout) {
    'ngInject';

    this.$onInit = () => {
      this.isDisplayed = false;
      this.messageColor = this.getColorClass();
      this.showToasterFn = this.showToaster;
    };

    this.showToaster = () => {
      const clearToaster = () => {
        if (!this.isDisplayed) {
          return $q.when();
        }
        this.isDisplayed = false;
        return $timeout(this.hideToaster, TOASTER_ANIMATION_TIME);
      };

      return clearToaster().then(() => {
        this.isDisplayed = true;
        this.toasterTimeout = $timeout(this.hideToaster, TOASTER_DISPLAY_TIME);
      });
    };

    this.hideToaster = () => {
      this.clearTimeout();
      this.isDisplayed = false;
      return true;
    };
    this.clearTimeout = () => {
      if (this.toasterTimeout) {
        $timeout.cancel(this.toasterTimeout);
      }
      this.toasterTimeout = null;
    };

    this.getColorClass = () =>
      APP_MESSAGE_COLORS[this.type] || APP_MESSAGE_COLORS._default;
  },
};
