import { FormNodeComponent } from './form-node.component';

export default angular
  .module('sfMobile.components.formNode', [
    'sfMobile.component.formsection',
    'sfMobile.services.reports',
    'sfMobile.services.files',
    'sfMobile.services.pubsub',
  ])
  .component('sfFormNode', FormNodeComponent).name;
