import { ObjectIdService } from '../../services/Utils/Objectid/objectId.service';

export const AccordionComponent: ng.IComponentOptions = {
  bindings: {
    isCollapsed: '<?',
  },
  transclude: {
    accordionTitle: '?accordionTitle',
    accordionBody: '?accordionBody',
  },
  templateUrl: 'components/accordion/accordion.html',
  controller: class AccordionController implements ng.IController {
    // bindings
    isCollapsed: boolean;

    // members
    accordId: string;
    expanded = true;

    constructor(private objectIdService: ObjectIdService) {
      'ngInject';
    }

    $onInit(): void {
      this.accordId = `sf_accordion_check${this.objectIdService.getUniqId()}`;
      this.expanded = !this.isCollapsed;
    }
  },
};
