import {
  DOCUMENT_UPLOAD_OPTIONS,
  FILES_UPLOAD_OPTIONS,
  IMAGE_UPLOAD_OPTIONS,
  VIDEO_UPLOAD_OPTIONS,
} from '../../../constants/files-upload-options.constant';

export type FileOptions =
  | typeof IMAGE_UPLOAD_OPTIONS
  | typeof DOCUMENT_UPLOAD_OPTIONS
  | typeof VIDEO_UPLOAD_OPTIONS;
export type NgfFile = File & { $error: string };
export type ValidationError = { errorName: string; fileNames: string[] };
type FilesByValidity = { valid: Blob[]; invalid: NgfFile[] };

export class FileValidatorService {
  imageOptions: typeof IMAGE_UPLOAD_OPTIONS;
  documentOptions: typeof DOCUMENT_UPLOAD_OPTIONS;
  videoOptions: typeof VIDEO_UPLOAD_OPTIONS;
  /* @ngInject */
  constructor(
    private $translate: ng.translate.ITranslateService,
    private SF_FILES_UPLOAD_OPTIONS: typeof FILES_UPLOAD_OPTIONS
  ) {
    this.imageOptions = this.SF_FILES_UPLOAD_OPTIONS.IMAGE;
    this.documentOptions = this.SF_FILES_UPLOAD_OPTIONS.DOCUMENT;
    this.videoOptions = this.SF_FILES_UPLOAD_OPTIONS.VIDEO;
  }

  filterByValidity(
    blobs: Blob[],
    type: 'image' | 'document' | 'video',
    maxFilesAllowed = Infinity,
    fileOptions?: FileOptions
  ): FilesByValidity {
    const defaultOptions = {
      image: this.imageOptions,
      document: this.documentOptions,
      video: this.videoOptions,
    };
    const options = fileOptions ?? defaultOptions[type];

    const isAllowedType = (options: FileOptions) => (mimeType: string) =>
      options.accept.split(',').includes(mimeType);
    const isAllowedSize = (options: FileOptions) => (size: number) =>
      size <= options.maxSizeInBytes;
    const isValidType = isAllowedType(options);
    const isValidSize = isAllowedSize(options);

    return (blobs as (Blob & NgfFile)[]).reduce(
      (acc, blob, i) => {
        if (!isValidType(blob.type)) {
          blob.$error = 'pattern';
          acc.invalid.push(blob);
          return acc;
        }
        if (!isValidSize(blob.size)) {
          blob.$error = 'maxSize';
          acc.invalid.push(blob);
          return acc;
        }
        if (i >= maxFilesAllowed) {
          blob.$error = 'maxFiles';
          acc.invalid.push(blob);
          return acc;
        }
        acc.valid.push(blob);
        return acc;
      },
      { valid: [], invalid: [] } as FilesByValidity
    );
  }

  getFilesGroupedByErrors(
    invalidFiles: NgfFile[],
    errorMessages?: Record<'maxSize' | 'maxFiles' | 'pattern', string>
  ): { errorName: string; fileNames: string[] }[] {
    const errors = errorMessages || {
      maxSize: this.$translate.instant('VALID_FILE_RESOURCE_MAX_SIZE'),
      maxFiles: this.$translate.instant('VALID_FILE_RESOURCE_MAX_FILES'),
      pattern: this.$translate.instant('VALID_FILE_RESOURCE_PATTERN'),
    };
    const filesByErrorType = (errorKey) =>
      invalidFiles
        .filter(({ $error }) => $error === errorKey)
        .map(({ name }) => name);

    return Object.entries(errors).reduce((errors, [errorKey, errorName]) => {
      const fileNames = filesByErrorType(errorKey);

      if (fileNames.length) {
        errors.push({ errorName, fileNames });
      }
      return errors;
    }, [] as ValidationError[]);
  }
}
