import { ObjectId } from '../../../..';
import { StateService } from '@uirouter/core';
import { Campaign } from '../../../../services/API/campaigns/campaigns';

const MAX_CAMPAIGNS_IN_SECTION = 5;

export class PlaceCampaignsSectionController {
  // bindings
  campaigns: Campaign[] = [];
  campaignsDisplay: Campaign[];
  placeId: ObjectId;
  title: string;

  constructor(private $state: StateService) {
    'ngInject';
  }

  $onInit(): void {
    this.setCampaigns();
  }

  $onChanges(changesObj: {
    campaigns: {
      currentValue: Campaign[];
      previousValue: Campaign[];
    };
  }): void {
    const { currentValue, previousValue } = changesObj.campaigns;

    if (currentValue === previousValue) {
      this.setCampaigns();
    }
  }

  setCampaigns(): void {
    this.campaignsDisplay = this.campaigns.slice(0, MAX_CAMPAIGNS_IN_SECTION);
  }

  isSectionLoading(entities?: Campaign[]): boolean {
    return typeof entities === 'undefined';
  }

  goToView(stateLink: string): void {
    this.$state.go(stateLink, { placeId: this.placeId });
  }
}
