import { IonicTabService } from '@services/Utils/Tabs/tabs.service';
import { TaskStatus } from '../..';
import { IAPIResource, ObjectId, User } from '../../..';
import { GroupTasksService } from '../../../group-tasks/services/group-tasks.service';
import { UsersGroup } from '../../../services/API/users/users';
import { UsersService } from '../../../services/API/users/users.service';
import { AccessRightsService } from '../../../services/Utils/AccessRights/access-rights.service';
import { SegmentService } from '../../../services/Utils/Segment/segment.service';
import { SF_TASK_ASSIGNEES } from '../../constants/task-assignees.constant';
import { TaskService } from '../../services/task.service';

type TabFilter =
  | {
      name: string;
      value: string;
      operator?: string;
      forceCast?: string;
    }[]
  | { sfqlOperator: string; value: Record<string, unknown>[] }[];

const TASKS_TABS_VIEW_ANALYTICS = {
  mytasks: 'Tasks assigned to me',
  otherstasks: 'Tasks assigned to others',
};

enum TasksTabsOrder {
  MINE_FIRST = 'MINE_FIRST',
  OTHERS_FIRST = 'OTHERS_FIRST',
}

export class TasksTabsController implements ng.IComponentController {
  // bindings
  profile: User;
  isPlace?: boolean;
  taskStatus: TaskStatus;
  requestFilters: {
    name: string;
    value: ObjectId;
    operator?: string;
  }[];
  myTasksReload: () => void;
  otherTasksReload: () => void;

  // attributes
  activeTab: string;
  reloadFn: () => void;
  useGroupTasks = false;
  currentStickyHandle: string;
  tasksCount = {};
  hasLoadError = false;
  tasksTabsOrder: TasksTabsOrder = TasksTabsOrder.MINE_FIRST;
  areTabsInit = false;

  constructor(
    private tasksService,
    private groupTasksService: GroupTasksService,
    private taskService: TaskService,
    private segmentService: SegmentService,
    private usersService: UsersService,
    private TASK_ASSIGNEES: typeof SF_TASK_ASSIGNEES,
    private accessRightsService: AccessRightsService,
    private tabsService: IonicTabService
  ) {
    'ngInject';
    this.reload = this.reload.bind(this);
    this.onTabSelect = this.onTabSelect.bind(this);
    this.getTasksCounts = this.getTasksCounts.bind(this);
    this.callReloadTabs = this.callReloadTabs.bind(this);
    this.getCount = this.getCount.bind(this);
  }

  $onInit(): void {
    this.setTabsOrder();
    this.useGroupTasks = this.groupTasksService.canUseGroupTasks();
    this.reloadFn = this.reload;
  }

  reload(): void {
    this.getTasksCounts();
    this.callReloadTabs();
  }

  onTabSelect(tab: string): void {
    if (!this.areTabsInit) {
      this.setFirstActiveTab();
    } else {
      this.activeTab = tab;
    }

    this.currentStickyHandle = 'task' + tab;
    this.reload();
    this.segmentService.page(TASKS_TABS_VIEW_ANALYTICS[tab]);
    this.segmentService.track('TASKS_TABS', {
      action: 'tap',
      label: 'tabs',
      value: tab,
    });
  }

  getTasksCounts(): void {
    this.tasksService
      .getTasksCount(this.requestFilters || [])
      .then((taskCounts) => {
        this.hasLoadError = false;
        this.tasksCount = taskCounts.reduce((hash, taskCount) => {
          hash[taskCount.type] = taskCount.total;
          return hash;
        }, {});
      })
      .catch((err) => {
        this.hasLoadError = true;
        throw err;
      });
  }

  callReloadTabs(): unknown {
    const callReloadFn = (fn) => fn && fn();

    if (this.taskService.isMy(this.activeTab)) {
      return callReloadFn(this.myTasksReload);
    }

    return callReloadFn(this.otherTasksReload);
  }

  getCount(type: string): string {
    const taskCountString = this.tasksCount[type] || 0;

    return !this.hasLoadError ? taskCountString : '';
  }

  getMyTasksTabs(): TabFilter {
    // 1. Task assigned to the user
    // 2. Subtask assigned to the user
    // 3. Has no assignee but in related store
    return [
      {
        sfqlOperator: '$or',
        value: [
          { assignee_id: { $eq: this.profile._id } },
          { subtaskAssignee: { $eq: this.profile._id } },
          {
            $and: [
              { place_id: { $in: this.profile.contents.places_ids } },
              { assignee_id: { $eq: null, forceCast: 'nullable' } },
            ],
          },
        ],
      },
    ];
  }

  getOtherTasksTabs(usersGroups: IAPIResource<UsersGroup>[]): TabFilter {
    // 1. User is task creator
    // 2. User is team manager of task assignee
    // 3. User is store manager
    const relatedUsers = usersGroups.reduce((acc, usersGroup) => {
      return [...acc, ...usersGroup.contents.users_ids];
    }, [] as string[]);

    return [
      {
        sfqlOperator: '$and',
        value: [
          { assignee_id: { $ne: this.profile._id } },
          { assignee_id: { $ne: null, forceCast: 'nullable' } },
          {
            $or: [
              { owner_id: { $eq: this.profile._id } },
              { assignee_id: { $in: relatedUsers } },
              ...(this.usersService.isStoreManager(this.profile)
                ? [
                    {
                      place_id: { $in: this.profile.contents.places_ids },
                    },
                  ]
                : []),
            ],
          },
        ],
      },
    ];
  }

  setTabsOrder(): void {
    if (this.accessRightsService.isAtLeastManager()) {
      this.tasksTabsOrder = TasksTabsOrder.OTHERS_FIRST;
    } else {
      this.tasksTabsOrder = TasksTabsOrder.MINE_FIRST;
    }
  }

  setFirstActiveTab(): void {
    if (this.tasksTabsOrder === TasksTabsOrder.MINE_FIRST) {
      this.tabsService.select('tasksTabs', 0);
      this.activeTab = this.TASK_ASSIGNEES.MY_TASKS.keyword;
    } else {
      this.tabsService.select('tasksTabs', 1);
      this.activeTab = this.TASK_ASSIGNEES.OTHERS_TASKS.keyword;
    }

    this.areTabsInit = true;
  }
}
