import { clone, indexBy, pathOr, prop } from 'ramda';

// eslint-disable-next-line max-params
export function ReportSendingService(
  $q,
  pingService,
  reportsService,
  formsService,
  filesService,
  filesSystemService,
  errorMessagesService,
  intercomService,
  sentryService,
  segmentService,
  campaignsService,
  draftReportsService,
  sfFeatureFlagsService,
  SF_FEATURE_FLAGS,
  platformService,
  SF_ERROR_CODES,
  logService
) {
  'ngInject';

  const getErrorName = (err) => {
    if (errorMessagesService.isNetworkError(err)) {
      return 'Network error';
    }
    if (errorMessagesService.isServerError(err)) {
      return 'Server error';
    }
    if (errorMessagesService.isReportFileNotFoundError(err)) {
      return 'File missing error';
    }
    if (errorMessagesService.isReportError(err)) {
      return 'Report format error';
    }
    if (errorMessagesService.isUnauthorizedError(err)) {
      return 'Credentials error';
    }
    return 'Unknown error';
  };

  class ReportSending {
    /**
     * @param  {Object} form - Form attached to report
     * @param  {Object} ctx  - Context
     */
    constructor(form, ctx) {
      this.form = form;
      this.ctx = clone(ctx);

      this.reportCleaned = this.cleanReport();

      this.answersFiles = this.reportCleaned.contents.answers.filter(
        (answer) => getFiles(answer) && isNotSyncing(answer)
      );
    }

    /**
     * Send all datas (pictures and, after, report).
     *
     * @param  {Object} options  - Send option
     * @param  {Function} onProgress  - Function that called when a have been sent
     * @return {Promise}         - All sending report
     */
    send(options, onProgress) {
      const { ctx, answersFiles } = this;
      const startTime = new Date().getTime();
      logService.reportLog(
        '[IT-3253] report-sending.service.js | send',
        ctx.report
      );

      const sendSuccess = (report) => {
        const intercomInfos = this.getEventInfos(startTime);

        reportsService.crud.dataStore.deleteLocal(report._id);

        if (sfFeatureFlagsService.hasFeature(SF_FEATURE_FLAGS.DRAFT_REPORTS)) {
          draftReportsService.deleteOne(report._id, true);
        }

        if (onProgress) {
          onProgress();
        }
        // Give infos for the AM team
        intercomService.sendEvent('mobile-report-send-success', intercomInfos);
        const event = this.ctx.useNewCampaigns
          ? {
              checklist_id: this.form._id,
              checklist_name: this.form.contents.title,
            }
          : {
              form_id: this.form._id,
              form_name: this.form.contents.title,
            };

        return segmentService
          .identifiedTrack('REPORT_SENT', event)
          .then(() =>
            campaignsService.crud.refreshEntity(
              report.contents.campaign_id,
              { fields: ['contents', 'statistics'] },
              { pov: 'organisation' }
            )
          )
          .then(() => report);
      };
      const sendFail = (err) => {
        const errorInfos = this.getEventInfos(startTime);
        const intercomInfos = {
          ...errorInfos,
          error_name: getErrorName(err),
          error_status: err.status,
          error_message: err.statusText || err.message,
        };

        // Give infos for the AM team
        intercomService.sendEvent('mobile-report-send-fail', intercomInfos);

        if (errorMessagesService.isCriticalError(err)) {
          captureSentrySentError(err, intercomInfos);
        }
        throw err;
      };

      return pingService
        .ping()
        .then(() => _sendFiles(ctx.report, answersFiles, options, onProgress))
        .then((reportToSend) => {
          /* logService.reportLog(
            '[IT-3253] report-sending.service.js | reportsService.create',
            reportToSend
          ); */
          return reportsService.create(
            reportToSend._id,
            cleanTempAnswers(reportToSend),
            options
          );
        })
        .then(sendSuccess)
        .then((report) => reportsService.deleteLocally(report._id))
        .catch(sendFail);
    }

    /**
     * Send all datas (pictures and, after, report).
     *
     * @param  {Object} options  - Send option
     * @param  {Function} onProgress  - Function that called when a have been sent
     * @return {Promise}         - All sending report
     */
    sendDraft(options, onProgress) {
      const { ctx, answersFiles } = this;
      const startTime = new Date().getTime();
      /* logService.reportLog(
        '[IT-3253] report-sending.service.js | sendDraft',
        ctx.report
      ); */

      const sendSuccess = (report) => {
        const intercomInfos = this.getEventInfos(startTime);

        if (onProgress) {
          onProgress();
        }
        // Give infos for the AM team
        intercomService.sendEvent(
          'mobile-draft-report-send-success',
          intercomInfos
        );
        const event = {
          checklist_id: this.form._id,
          checklist_name: this.form.contents.title,
        };

        return segmentService
          .identifiedTrack('REPORT_SENT', event)
          .then(() => report);
      };
      const sendFail = (err) => {
        const errorInfos = this.getEventInfos(startTime);
        const intercomInfos = {
          ...errorInfos,
          error_name: getErrorName(err),
          error_status: err.status,
          error_message: err.statusText || err.message,
        };

        // Give infos for the AM team
        intercomService.sendEvent(
          'mobile-draft-report-send-fail',
          intercomInfos
        );

        if (errorMessagesService.isCriticalError(err)) {
          captureSentrySentError(err, intercomInfos);
        }
        throw err;
      };

      return pingService
        .ping()
        .then(() => _sendFiles(ctx.report, answersFiles, options, onProgress))
        .then(() => {
          logService.reportLog(
            '[IT-3253] report-sending.service.js | draftReportsService.createOne',
            ctx.report
          );
          return draftReportsService.createOne(cleanTempAnswers(ctx.report), {
            form: this.form,
            checklist: ctx.campaign,
            ...ctx,
          });
        })
        .then(sendSuccess)
        .catch((err) => {
          if (
            err.status === SF_ERROR_CODES.CODES_SERVER_NO_NETWORK[0] &&
            platformService.isBrowser()
          ) {
            throw err;
          }

          return draftReportsService.createOne(
            ctx.report,
            {
              form: this.form,
              checklist: ctx.campaign,
              ...ctx,
            },
            true
          );
        })
        .catch(sendFail);
    }

    /**
     * Get answers to send
     *
     * @param  {Object} form    - Form datas
     * @param  {Object} ctx     - Context datas
     * @return {Object}         - Report clean
     */
    cleanReport() {
      const { report } = this.ctx;
      /* logService.reportLog(
        '[IT-3253] report-sending.service.js | cleanReport',
        report
      ); */

      const nodesWithoutCond = this.removeConditionalNodes(
        report.contents.answers,
        report.contents.nodes
      );
      const answersWithoutCond = this.removeConditionalAnswers(
        nodesWithoutCond,
        report.contents.answers
      );
      const nodesId = getNodeIdsUsed(answersWithoutCond, nodesWithoutCond);
      const nodes = nodesWithoutCond.filter((node) =>
        nodesId.includes(node._id)
      );
      logService.reportLog(
        '[IT-3253] report-sending.service.js | cleanReport: cleaning nodes and answers',
        report,
        {
          nodesWithoutCondCount: nodesWithoutCond.length,
          nodesWithoutCondIds: nodesWithoutCond.map((node) => node._id),
          usedNodesWithoutCondCount: nodes.length,
          usedNodesWithoutCondIds: nodes.map((node) => node._id),
          answersWithoutCondCount: answersWithoutCond.length,
          answersWithoutCondIds: answersWithoutCond.map((answer) => answer._id),
        }
      );

      report.contents.answers = answersWithoutCond;
      report.contents.nodes = nodes;
      logService.reportLog(
        '[IT-3253] report-sending.service.js | cleanReport: after removeConditionalAnswers',
        report
      );

      return report;
    }

    removeConditionalNodes(answers, nodes) {
      const { form, ctx } = this;
      const sections = form.sections || form.contents.sections;
      const sectionsHash = indexBy(prop('_id'), sections);

      return nodes.filter((node) => {
        const section = sectionsHash[node.sections_ids[0]];
        const keepData =
          section && formsService.displayQuestion(section, form, answers, ctx);

        return keepData;
      });
    }

    removeConditionalAnswers(nodes, answers) {
      const { form, ctx } = this;
      const questions = form.questions || form.contents.questions;
      const questionsHash = indexBy(prop('_id'), questions);
      const nodeIds = nodes.map((data) => data._id);

      return answers.filter((answer) => {
        const question = questionsHash[answer.question_id];
        const nodeExist = nodeIds.includes(getNodeId(answer));
        const keepData =
          nodeExist &&
          question &&
          formsService.displayQuestion(question, form, answers, ctx);

        return keepData;
      });
    }

    getEventInfos(startTime) {
      const { ctx, form } = this;
      const time = new Date().getTime();

      return {
        report_id: ctx.report._id,
        form_id: ctx.report.contents.form_id,
        form_title: form.contents.title,
        place_id: pathOr('-', ['place', '_id'], ctx),
        place_name: pathOr('-', ['place', 'contents', 'name'], ctx),
        sending_time_in_seconds: (time - startTime) / NB_MILLI_IN_ONE_SECOND,
      };
    }
  }

  return ReportSending;

  /**
   * Send files recursively.
   *
   * @param  {String} report        - The report saved
   * @param  {Array} answerFiles  - All files task
   * @param  {Object} options       - Send option
   * @param  {Function} onProgress  - Function that called when a have been sent
   * @return {Promise}              - All files sending result
   */
  function _sendFiles(report, answerFiles, options, onProgress) {
    return answerFiles.reduce(
      (promise, answerFile) =>
        promise.then(() =>
          sendFile(answerFile)
            .then((answer) => saveReport(answer))
            .then((newReport) => {
              if (onProgress) {
                onProgress();
              }
              return newReport;
            })
            .catch((err) => {
              if (errorMessagesService.isCriticalError(err)) {
                logFileError(err);
              }
              throw err;
            })
        ),
      $q.when(report)
    );

    function sendFile(answerFile) {
      const fileId = answerFile.values[0].value;
      const { fileType, fileBlob, tags_ids } = answerFile.temp;

      const answerFileWithExtension = answerFile.values[1];
      const extension = filesSystemService.getExtension(
        answerFileWithExtension.value
      );
      const ext = extension ? `.${extension}` : '';

      const getBlobPicture = () => {
        return fileType && fileType === 'blob'
          ? $q.when(fileBlob)
          : filesSystemService.getBlobFile(report._id, `${fileId}${ext}`);
      };

      return getBlobPicture()
        .then((file) =>
          filesService.upload(file, fileId, {
            ...options,
            fileNameOverride: answerFileWithExtension.value,
            tags_ids,
          })
        )
        .then(() => answerFile);
    }
    function saveReport(newAnswerFile) {
      logService.reportLog(
        '[IT-3253] report-sending.service.js | saveReport: updateLocal for report',
        report
      );
      report.contents.answers = report.contents.answers.map((answer) =>
        newAnswerFile._id === answer._id ? cleanAnswer(newAnswerFile) : answer
      );

      return reportsService.crud.updateLocal(report);
    }
    function logFileError(err) {
      return sentryService.captureMessage('Send report file failed', {
        level: 'info',
        extra: {
          report_id: report._id,
          form_id: report.contents.form_id,
          error_status: err.status,
          error: err,
        },
      });
    }
  }
  /**
   * Send the sending error to Sentry
   * @param {Object} err Error response of the server
   * @param {Object} errorInfos Entities infos
   * @returns {Boolean} true
   */
  function captureSentrySentError(err, errorInfos) {
    const isNetworkError = err.config;

    if (isNetworkError) {
      const sentryInfos = {
        ...errorInfos,
        response: {
          status: err.status,
          body: err.data,
        },
        request_url: err.config.url,
        request_data: err.config.data,
      };

      sentryService.captureMessage(
        `[Critical] Send report failed:${err.status}`,
        {
          level: 'error',
          tags: {
            sendNotification: err.status === 400 ? 'mail' : null,
            errorStatus: err.status,
          },
          extra: sentryInfos,
        }
      );

      return true;
    }
    const sentryInfos = {
      ...errorInfos,
      code_error: err,
    };

    sentryService.captureMessage('[Critical] Send report failed', {
      level: 'error',
      tags: {
        sendNotification: 'mail',
        errorMessage: err.message,
      },
      extra: sentryInfos,
    });
    return true;
  }
}

const NB_MILLI_IN_ONE_SECOND = 1000;
const REPORT_SENDING_FILES_TYPES = ['image', 'signature', 'document'];

const getNodeId = (datas) => datas.nodes_ids && datas.nodes_ids[0];
const getFiles = (datas) => REPORT_SENDING_FILES_TYPES.includes(datas.type);

const getNodeIdsUsed = (answers, nodes) => {
  const nodeIdsOfAnswers = answers.map(getNodeId);

  return nodes.reduce(
    (output, node) =>
      nodeIdsOfAnswers.includes(node._id)
        ? output.concat(node._id, node.parents_ids)
        : output,
    []
  );
};
const isNotSyncing = (data) => data.temp && data.temp.needSync;
const cleanAnswer = (answer) => {
  delete answer.temp;
  return answer;
};
const cleanTempAnswers = (report) => {
  report.contents.answers = report.contents.answers.map((answer) =>
    cleanAnswer(answer)
  );
  return report;
};
