import ChatRoomInfoModule from './../chat-room-info/chat-room-info.module';
import { ChatRoomComponent } from './../chat-room/chat-room.component';
import ChatMessageActionsModule from '../../../services/chat-message-actions.module';
import ngFileUpload from 'ng-file-upload';

export default angular
  .module('sfMobile.components.chat-room', [
    'sfMobile.services.localization',
    'sfMobile.services.dates',
    'sfMobile.services.modal',
    'sfMobile.services.chat',
    'sfMobile.services.error-messages',
    'sfMobile.services.camera',
    'sfMobile.services.image-picker',
    'sfMobile.services.network',
    'sfMobile.services.keyboard',
    'sfMobile.services.pubsub',
    'sfMobile.services.file-chooser',
    ChatRoomInfoModule,
    'sfMobile.components.content',
    'sfMobile.components.divider-date',
    'sfMobile.components.footer-text-input',
    ChatMessageActionsModule,
    ngFileUpload,
  ])
  .component('sfChatRoom', ChatRoomComponent).name;
