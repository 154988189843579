export const InformationDisplayComponent = {
  bindings: {
    title: '@',
    desc: '@',
    buttonText: '@',
    onButtonClick: '&?',
  },
  transclude: true,
  templateUrl: 'components/Scenes/information-display/information-display.html',
  controller: class InformationDisplayController {},
};
