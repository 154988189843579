export function MerchandisingCommentService() {
  'ngInject';

  return {
    isValidationComment,
    isResponsibleOfValidationStateChange: checkValidationComment(
      isResponsibleOfValidationStateChange
    ),
    isWitnessOfPicturesReplacement: checkValidationComment(
      isWitnessOfPicturesReplacement
    ),
  };

  function isValidationComment(comment) {
    return Object.prototype.hasOwnProperty.call(comment.contents, 'validation');
  }

  function checkValidationComment(fn) {
    return (comment) => {
      if (!isValidationComment(comment)) {
        return false;
      }
      return fn(comment);
    };
  }

  /**
   * Tell if a comment is responsible of a validation state change
   * @param {Object} comment - A simplifield Comment object
   * @return {Boolean} - True if it contains a state change
   */
  function isResponsibleOfValidationStateChange(comment) {
    return comment.contents.validation.stateSetter;
  }

  /**
   * Tell if a comment show a picture replacement
   * @param {Object} comment - A simplifield Comment object
   * @return {Boolean} - True if it figures a picture replacement
   */
  function isWitnessOfPicturesReplacement(comment) {
    return (
      comment.contents.validation.changedFiles_ids &&
      comment.contents.validation.changedFiles_ids.length
    );
  }
}
