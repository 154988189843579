import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import { PlatformService } from '../../../services/Utils/Platform';

export const ActivityFeedComponent: ng.IComponentOptions = {
  bindings: {
    onClose: '&',
  },
  templateUrl: 'activity/components/activity-feed/activity-feed.html',
  controller: class ActivityFeedController {
    isAndroid = false;
    isRTLNeeded: boolean;
    constructor(
      private platformService: PlatformService,
      private localizationService: LocalizationService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
      // android hass issues with scroll in this component
      this.isAndroid = this.platformService.isAndroid();
    }
  },
};
