import { path } from 'ramda';
import clone from '../../../../services/Utils/clone';

const CAMPAIGN_FIELDS_STATIC_SEARCH_FILTERS = ['contents.name'];

export const RequestEventChangesModalComponent = {
  bindings: {
    event: '<',
    onClose: '&',
  },
  templateUrl:
    'calendar-events/components/calendar/request-event-changes-modal/request-event-changes-modal.html',
  controller: class RequestEventChangesModalController {
    // eslint-disable-next-line max-params
    constructor(
      localizationService,
      eventChangeRequestsService,
      popupRequestService,
      $translate,
      eventTypesService,
      formValidationsService,
      dateService,
      campaignsService,
      corporateEventsApiService,
      sfFeatureFlagsService,
      SF_FEATURE_FLAGS
    ) {
      'ngInject';
      this.isRTLNeeded = localizationService.shouldActivateRTL();
      this.eventChangeRequestsService = eventChangeRequestsService;
      this.popupRequestService = popupRequestService;
      this.$translate = $translate;
      this.eventTypesService = eventTypesService;
      this.formValidationsService = formValidationsService;
      this.searchStaticCampaignFields = CAMPAIGN_FIELDS_STATIC_SEARCH_FILTERS;
      this.campaignsService = campaignsService;
      this.corporateEventsApiService = corporateEventsApiService;
      this.dateService = dateService;

      this.getCampaignTitle = getCampaignTitle;
      this.getEventTypeTitle = ({ contents }) => contents.title;

      this.eventTypes = [];
      this.assignedCampaigns = [];
      this.campaigns = [];
      this.corporateEvents = [];
      this.corporateEventsSelected = [];

      this.isFullCalendar = sfFeatureFlagsService.hasFeature(
        SF_FEATURE_FLAGS.FULL_CALENDAR
      );
    }

    $onInit() {
      this.originalEvent = {
        _id: this.event._id,
        contents: clone(this.event.contents),
      };
      this.event = clone(this.event);
      this.eventContents = this.event.contents;

      if (this.eventContents.checklists) {
        this.assignedCampaigns = this.eventContents.checklists.map(
          ({ _id, ...contents }) => ({
            _id,
            contents,
          })
        );
      }

      if (this.eventContents.corporateEvents) {
        this.corporateEventsSelected = this.eventContents.corporateEvents.map(
          ({ _id, ...contents }) => ({
            _id,
            contents,
          })
        );
      }

      this.changeRequest = {
        state: 'pending',
        action: 'edit',
        organisation_id: this.event.contents.organisation_id,
        requestComment: '',
      };

      this.loadCampaigns();
      this.loadEventTypes();
      this.loadCorporateEvents();
    }

    loadCorporateEvents() {
      return this.corporateEventsApiService
        .getCorporateEventsList({
          filters: {
            $and: [{ corporate_event_end_date: { $gte: new Date() } }],
          },
        })
        .then((corporateEvents) => {
          this.corporateEvents = corporateEvents;
        });
    }

    loadEventTypes() {
      return this.eventTypesService
        .listLocal()
        .catch(() => [])
        .then((eventTypes) => {
          this.eventTypes = eventTypes.filter((eventType) =>
            eventType.contents.requiredFields.includes('assignees_ids')
          );
        });
    }

    loadCampaigns() {
      return this.campaignsService.getCampaignsList().then((campaigns) => {
        this.campaigns = campaigns;
      });
    }

    selectPlace(place) {
      this.event.place = place;
      this.event.contents.place_id = place._id;
    }

    selectEventType(eventType) {
      this.event.type = eventType;
      this.event.contents.calendarEventType_id = eventType._id;
    }

    selectCampaigns(campaigns) {
      this.assignedCampaigns = [].concat(campaigns);
    }

    updateParams(params) {
      this.eventContents.params = params;
      this.event.contents.params = params;
    }

    deleteCampaign(campaignId) {
      this.assignedCampaigns = this.assignedCampaigns.filter(
        (c) => c._id !== campaignId
      );
    }

    mapSelectedCampaigns() {
      return this.assignedCampaigns.map((campaign) => campaign._id);
    }

    resetCampaigns() {
      this.assignedCampaigns = [];
    }

    resetParams() {
      this.eventContents.params = [];
      this.event.contents.params = [];
    }

    resetEventType() {
      this.event.type = {};
      this.event.contents.calendarEventType_id = null;
    }

    resetPlace() {
      this.event.place = {};
      delete this.event.contents.place_id;
    }

    isRequired(fieldName) {
      return (
        this.event.type.contents &&
        this.event.type.contents.requiredFields.includes(fieldName)
      );
    }

    startDateTimezoneChange({ startDate, endDate, timezone }) {
      if (startDate) {
        this.event.contents.start_dateTime = startDate.toDate();
      }
      if (endDate) {
        this.event.contents.end_dateTime = endDate.toDate();
      }

      if (timezone) {
        this.event.contents.timezone.name = timezone;
      }

      this.hasError = !this.isValidDateRange();

      if (this.calendarEventContentsForm.sfbDateTimeRangeTimezone__startDate) {
        this.calendarEventContentsForm.sfbDateTimeRangeTimezone__startDate.$setValidity(
          'invalid_date_range',
          !this.hasError
        );
      }
    }

    getError(fieldName) {
      return this.formValidationsService.getInputError(
        this.calendarEventContentsForm,
        fieldName
      );
    }

    getAssigneesValue() {
      const length = this.event.assignees.length;

      if (length === 1) {
        const [assignee] = this.event.assignees;

        return `${assignee.contents.firstName} ${assignee.contents.lastName}`;
      }

      return this.$translate.instant('EVENT_SELECTED_ASSIGNEES_VALUE', {
        count: length,
      });
    }

    requestChanges() {
      const popupRequest = this.popupRequestService.show({
        progress: {
          title: this.$translate.instant('REQUEST_EVENT_CHANGE_PENDING'),
        },
        success: {
          title: this.$translate.instant('REQUEST_EVENT_CHANGE_SUCCESS_TITLE'),
          desc: this.$translate.instant('REQUEST_EVENT_CHANGE_SUCCESS_DESC'),
          iconName: 'thumbsup',
          actions: [{ text: 'OK', type: 'button-dark' }],
        },
        error: {
          title: this.$translate.instant('REQUEST_EVENT_CHANGE_ERROR_TITLE'),
          desc: this.$translate.instant('REQUEST_EVENT_CHANGE_ERROR_DESC'),
        },
      });

      this.event.contents.checklists = this.mapSelectedCampaigns();

      if (this.originalEvent.contents.corporateEvents) {
        this.originalEvent.contents.corporateEvents =
          this.originalEvent.contents.corporateEvents.map(({ _id }) => _id);
      }

      if (this.corporateEventsSelected.length) {
        this.event.contents.corporateEvents = this.corporateEventsSelected.map(
          ({ _id }) => _id
        );
      } else {
        delete this.event.contents.corporateEvents;
      }

      return this.eventChangeRequestsService
        .saveNew({
          contents: {
            ...this.changeRequest,
            originalEvent: this.originalEvent,
            requestedEvent: this.event.contents,
          },
        })
        .then(() => popupRequest.onSuccess())
        .then(() => this.onClose())
        .catch(() => popupRequest.onError(() => this.requestChanges()));
    }

    isValidDateRange() {
      return this.dateService.isSameOrBefore(
        this.event.contents.start_dateTime,
        this.event.contents.end_dateTime
      );
    }
    getCampaignsValue() {
      const length = this.campaigns.length;

      if (!length) {
        return null;
      }

      if (length === 1) {
        return getCampaignTitle(this.campaigns[0]);
      }

      return this.$translate.instant('EVENT_SELECTED_ASSIGNEES_VALUE', {
        count: length,
      });
    }

    getCorporateEventTitle(corporateEvent) {
      return [
        path(['contents', 'name'])(corporateEvent),
        path(['contents', 'eventType', 'label', 'en'])(corporateEvent),
      ]
        .filter(Boolean)
        .join(' • ');
    }

    selectCorporateEvent(corporateEvent) {
      this.corporateEventsSelected = [].concat(corporateEvent);
    }

    deleteCorporateEvent(corporateEventId) {
      this.corporateEventsSelected = this.corporateEventsSelected.filter(
        (e) => e._id !== corporateEventId
      );
    }

    resetCorporateEvent() {
      this.corporateEventsSelected = [];
    }
  },
};

function getCampaignTitle(campaign) {
  return campaign.contents.name;
}
