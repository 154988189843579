const NB_POST_TO_GET = 20;

const NEWSFEED_TABS = {
  overview: 0,
  schedule: 1,
  draft: 2,
};

export const NewsfeedComponent = {
  templateUrl: 'newsfeed/views/newsfeed/newsfeed.html',
  bindings: {
    profile: '<',
    organisation: '<',
    categoryId: '<?',
  },
  controller: class NewsfeedComponent {
    // eslint-disable-next-line max-params
    constructor(
      localizationService,
      $translate,
      pubSubService,
      modalService,
      newsfeedApiService,
      newsfeedService,
      RequestsPaginate,
      accessRightsService,
      synchronizeService,
      userExperienceService,
      organisationsService,
      tabsService,
      $timeout
    ) {
      'ngInject';
      this.isRTLNeeded = localizationService.shouldActivateRTL();
      Object.assign(this, {
        $translate,
        pubSubService,
        modalService,
        newsfeedApiService,
        newsfeedService,
        RequestsPaginate,
        accessRightsService,
        synchronizeService,
        userExperienceService,
        organisationsService,
        tabsService,
        $timeout,
      });
      this.isRTLNeeded = localizationService.shouldActivateRTL();
      this.requestsPaginate = new RequestsPaginate(
        (params) => newsfeedApiService.getPosts(params),
        {
          limit: NB_POST_TO_GET,
          cursor: (lastPost) => lastPost.created_date,
        }
      );
    }

    $onInit() {
      this.isLoading = true;
      this.isNetworkError = false;
      this.posts = [];
      this.categories = [];
      this.activeTab = 'overview';

      this.$timeout(
        () =>
          this.tabsService.select('newsfeed', NEWSFEED_TABS[this.activeTab]),
        0
      );

      this.isInNavBar = this.userExperienceService
        .getNav(this.organisation)
        .find((nav) => nav.key === 'newsfeed');

      // Reload view when a new post is created
      this.newNewsfeedPostListener = this.pubSubService.subscribe(
        'NOTIFICATION:TRIGGER:NEW_NEWSFEED_POST',
        () => this.reload()
      );
      this.reloadNewsfeedPostListener = this.pubSubService.subscribe(
        'RELOAD_NEWSFEED_POSTS_VIEW',
        () => this.reload()
      );
      this.organisationSwitchListener = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.ORGANISATION_SWITCH,
        (data) => {
          return this.organisationsService
            .getProfileOrganisation(data.profile)
            .then((organisation) => {
              this.profile = data.profile;
              this.organisation = organisation;
              this.isInNavBar = this.userExperienceService
                .getNav(this.organisation)
                .find((nav) => nav.key === 'newsfeed');
            });
        }
      );

      return this.synchronize();
    }

    $onDestroy() {
      this.newNewsfeedPostListener();
      this.reloadNewsfeedPostListener();
      if (this.organisationSwitchListener) {
        this.organisationSwitchListener();
      }
    }

    loadCategories() {
      return this.newsfeedService.categoriesService
        .listCategories()
        .then((categories) => this.setCategories(categories));
    }

    setCategories(categories) {
      this.categories = categories;
      if (this.categoryId) {
        this.category = this.newsfeedService.categoriesService.getCategoryById(
          this.categoryId
        );
      }
      return categories;
    }

    resetRequests() {
      this.infiniteLoadError = false;
      this.requestsPaginate.reset();
    }

    synchronize() {
      return this.synchronizeService
        .synchronizeSettings()
        .then(() => this.newsfeedService.canAddPost())
        .then((canAddPost) => {
          this.canAddPost = canAddPost;
        })
        .then(() => this.reload());
    }

    reload() {
      this.posts = [];
      this.isLoading = true;
      this.isNetworkError = false;

      this.resetRequests();

      this.loadCategories();

      return this.getPosts(this.activeTab)
        .then(() => this.updateLastSeenPostInfo())
        .catch(() => {
          this.isNetworkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    getPosts(tab) {
      const params = {
        viewType: tab,
        mode: 'expanded',
      };

      if (this.activeTab !== 'overview') {
        params.sort = '-created.seal_date';
      }

      if (this.categoryId) {
        params.category_id = this.categoryId;
      }

      return this.requestsPaginate
        .call(params)
        .then(({ entities }) => {
          this.posts = entities;
        })
        .finally(() => {
          if (this.onScrollComplete) {
            this.onScrollComplete();
          }
        });
    }

    setActiveTab(tab, forceReload = false) {
      if (this.activeTab !== tab || forceReload) {
        this.activeTab = tab;
        this.reload();
      }
    }

    openPostFormModal(post = null, isPublished = false) {
      const template = `
      <sf-newsfeed-post-creation-modal
        post-to-edit="$ctrl.postToEdit"
        profile="$ctrl.profile"
        is-published="$ctrl.isPublished"
        on-close="$ctrl.onClose()"
        on-save="$ctrl.onSave()">
      </sf-newsfeed-post-creation-modal>`;

      return this.modalService
        .openAsPromise(
          template,
          {
            postToEdit: post,
            profile: this.profile,
            isPublished,
          },
          { hardwareBackButtonClose: false }
        )
        .then((result) => {
          this.setActiveTab(result.tab, true);
          if (!post) {
            this.$timeout(() => {
              this.tabsService.select(
                'newsfeed',
                NEWSFEED_TABS[this.activeTab]
              );
            }, 0);

            return;
          }

          this.$timeout(
            () =>
              this.tabsService.select(
                'newsfeed',
                NEWSFEED_TABS[this.activeTab]
              ),
            0
          );

          return this.onPostUpdate(result);
        })
        .catch(() => null); // close event rejects
    }

    onPostUpdate({ postId, updatedPostContents }) {
      const oldPostIndex = this.posts.findIndex((post) => post._id === postId);
      const oldPost = this.posts[oldPostIndex];

      if (
        oldPost.contents.category_id &&
        oldPost.contents.category_id !== updatedPostContents.category_id
      ) {
        this.reload();
        return;
      }
      const newPost = {
        ...oldPost,
        contents: { ...oldPost.contents, ...updatedPostContents },
      };

      this.posts[oldPostIndex] = newPost;
      this.pubSubService.publish('NEWSFEED_POST_UPDATED', { post: newPost });
    }

    newPost() {
      return this.openPostFormModal();
    }

    editPost(post, isPublished = false) {
      return this.openPostFormModal(post, isPublished);
    }

    updateLastSeenPostInfo() {
      if (!this.posts || !this.posts.length || this.categoryId) {
        return null;
      }
      return this.newsfeedService.setLastSeenPostInfo(this.posts[0]);
    }
  },
};
