"use strict";

var _definitions = require("../definitions");

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'User',
  type: 'object',
  additionalProperties: false,
  required: ['firstName', 'lastName', 'email', 'profiles', 'experience', 'params', 'tags', 'organisation_id', 'owner_id', 'usersGroups_ids', 'places_ids', 'subscribers_ids', 'managedUsersGroups_ids', 'managedPlacesLists_ids'],
  properties: {
    firstName: {
      type: 'string'
    },
    lastName: {
      type: 'string'
    },
    email: {
      type: 'string',
      format: 'email'
    },
    phone: {
      type: 'string' // format: 'phone', should be added later with libphonmenumber and format option
      // https://github.com/epoberezkin/ajv#api-addformat

    },
    birth_date: {
      type: 'string',
      format: 'date-time'
    },
    country: {
      type: 'string',
      format: 'country_code'
    },
    profiles: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'string',
        "enum": ['mobile', 'web', 'gallery', 'manager', 'contentManager', 'admin', 'root', 'salesforce']
      }
    },
    experience: {
      type: 'string',
      "enum": ['field', 'store', 'animator']
    },
    mobileDevices: {
      type: 'array',
      items: {
        title: 'MobileDevices',
        type: 'object'
      }
    },
    role: {
      type: 'string'
    },
    department: {
      type: 'string',
      "enum": ['audit', 'hr', 'it', 'maintenance', 'marketing', 'merchandising', 'operations', 'retail', 'sales', 'training', 'travelRetail']
    },
    storeScope: {
      type: 'object',
      additionalProperties: false,
      required: ['places_ids', 'placesLists_ids'],
      properties: {
        places_ids: {
          $ref: '#/definitions/objectIds'
        },
        placesLists_ids: {
          $ref: '#/definitions/objectIds'
        }
      }
    },
    tags: schemaUtils.tags(),
    params: schemaUtils.params(),
    organisation_id: {
      $ref: '#/definitions/objectId'
    },
    owner_id: {
      $ref: '#/definitions/objectId'
    },
    avatar_id: {
      $ref: '#/definitions/objectId'
    },
    usersGroups_ids: {
      $ref: '#/definitions/objectIds'
    },
    places_ids: {
      $ref: '#/definitions/objectIds'
    },
    subscribers_ids: {
      $ref: '#/definitions/objectIds'
    },
    managedPlacesLists_ids: {
      $ref: '#/definitions/objectIds'
    },
    managedUsersGroups_ids: {
      $ref: '#/definitions/objectIds'
    }
  },
  definitions: {
    objectId: _definitions.objectId,
    objectIds: _definitions.objectIds
  }
};