export const GoogleMapComponent = {
  bindings: {
    position: '<',
    markers: '<',
    onMarkerClick: '&',
    onMapLoaded: '&',
    onMapClick: '&',
  },
  templateUrl: 'components/Map/google-map/google-map.html',
  controller: class GoogleMapController {
    constructor($element, $timeout, googleMapService, $rootScope) {
      'ngInject';
      Object.assign(this, {
        $element,
        $timeout,
        googleMapService,
        $rootScope,
      });
    }

    $onInit() {
      const mapElem = this.$element[0].querySelector('.sf_google_map');
      const position = this.position || { lat: 48.856614, lng: 2.352222 };
      const mapPromise = this.googleMapService.getMap(mapElem, position);

      this.$rootScope.$on('RESET_GOOGLE_MAP_POSITION', () => {
        this.map.setCenter(position);
      });

      return mapPromise.then((map) => {
        if (!map) {
          this.onMapLoaded();
          return;
        }
        this.map = map;
        this.onMapInit(map);
        this.googleMapService.eventListener('MAP_CLICK', map, () =>
          this.onMapClicked()
        );
      });
    }

    $onDestroy() {
      this.googleMapService.clear(this.map, this.cluster);
    }

    onMapInit(map) {
      const markers = this.markers.map((marker) => ({
        _id: marker.id,
        position: marker.position,
        disableAutoPan: true,
        icon: {
          url: this.googleMapService.getMapMarkerPath(),
          size: { width: 20, height: 20 },
        },
        title: marker.title,
        data: marker,
      }));

      this.googleMapService
        .addMarkerCluster(markers, map, (m) => this.onMarkerClicked(m))
        .then((cluster) => {
          this.cluster = cluster;
        });

      this.$timeout(() => {
        this.onMapLoaded();
      }, 0);
    }

    onMarkerClicked(marker) {
      this.$timeout(() => {
        this.onMarkerClick({ marker });
      }, 0);
    }

    onMapClicked() {
      this.googleMapService.resetActiveMarkerIcon();
      this.$timeout(() => {
        this.onMapClick();
      }, 0);
    }
  },
};
