export const COLORS = {
  BASE: '#1B1E21',
  PRIMARY: '#fd4f57',
} as const;
export const SCALE_COLORS = {
  GOOD: '#a5e57f',
  MEDIUM: '#ffba4d',
  BAD: '#e76060',
  GRID: '#6F757C',
} as const;
export const DRAWING_COLORS = {
  WHITE: '#ffffff',
  BLACK: '#222222',
  RED: '#d62d20',
  BLUE: '#0057e7',
  YELLOW: '#ffA700',
  GREEN: '#008744',
} as const;

export default angular
  .module('sfMobile.constants.colors', [])
  .constant('SF_COLORS', COLORS)
  .constant('SF_SCALE_COLORS', SCALE_COLORS)
  .constant('SF_DRAWING_COLORS', DRAWING_COLORS).name;
