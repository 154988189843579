import { FilesService } from '@services/API/files/files.service';
import { PictureTagModel, SelectedTags } from '@services/API/tags/tags';
import { TagsService } from '@services/API/tags/tags.service';
import { ImageSource } from '@services/Utils/ImageSource/image-source.service';
import { PubSubService } from '@services/Utils/PubSub/pubsub.service';
import { TaskCommentsActionsService } from '@tasks/services/task-comments-actions.service';
import { TaskCommentsPopupService } from '@tasks/services/task-comments.popup.service';
import { StateService } from '@uirouter/core';
import { CommentsFileService } from 'app/comments/services/comments-file/comments-file.service';
import { NewsfeedService } from 'app/newsfeed/services/newsfeed-service/newsfeed.service';
import { TagsSelectorService } from 'app/tags-selector/tags-selector.service';
import { ObjectId, User } from '../../..';
import { ActionOptions } from '../../../change-requests/services/change-request-actions.service';
import { Comment } from '../../../comments';
import { CommentsResourceType } from '../../../comments/services/comments-api/comments-api.factory';
import {
  CommentsFactory,
  CommentsService,
} from '../../../comments/services/comments/comments.factory';

const COMMENT_MAX_SIZE = 4400;
type MappedComments = {
  id: ObjectId;
  comment: Comment;
  user: User[];
};

type Image = {
  id: ObjectId;
  answerId: ObjectId;
  image: ImageSource;
  tagsIds?: string[];
  tagsRequired?: boolean;
};

export const PreviewModalComponent: ng.IComponentOptions = {
  bindings: {
    onClose: '&',
    activeSlideId: '<',
    images: '<',
    isLocalMode: '<',
    contentName: '<',
    params: '<',
    canEditTags: '<?',
    mediaCommentAllowed: '<?',
  },
  templateUrl: 'digital-assets/components/preview-modal/preview-modal.html',
  controller: class PreviewModalController implements ng.IComponentController {
    // bindings
    activeSlideId = 0;
    images = [];
    isLocalMode = false;
    params = [];
    contentName: string;
    onClose: () => void;
    closeInputKeyboard: () => void;

    //props
    collapsed = false;
    commentField = '';
    isAddComment = false;
    isEditComment = false;
    commentsMaxSize = COMMENT_MAX_SIZE;
    keyboardModeType = this.KEYBOARD_MODES.BODY;
    profile: User;
    previewElement: HTMLElement;
    canEditTags = false;

    comments: MappedComments[] = [];
    editedCommentId: ObjectId | null;
    commentsService: CommentsService;
    commentsActions: Record<string, ActionOptions>;
    tags: PictureTagModel[];
    mediaCommentAllowed = true;
    fileSourceOption: { [key: string]: boolean };

    constructor(
      private $q: ng.IQService,
      private KEYBOARD_MODES,
      private $state: StateService,
      private commentsFactory: CommentsFactory,
      private taskCommentsActionsService: TaskCommentsActionsService,
      private profileService,
      private $window: ng.IWindowService,
      private tagsSelectorService: TagsSelectorService,
      private tagsService: TagsService,
      private pubSubService: PubSubService,
      private newsfeedService: NewsfeedService,
      private objectIdService,
      private taskCommentsPopupService: TaskCommentsPopupService,
      private filesService: FilesService,
      private commentsFileService: CommentsFileService
    ) {
      'ngInject';

      this.commentsService = this.commentsFactory(CommentsResourceType.ANSWERS);
      this.profileService.getProfile().then((profile) => {
        this.profile = profile;
      });
    }

    private getAnswerId() {
      const activePicture = this.images?.at(
        this.activeSlideId
      ) as unknown as Image;

      return activePicture?.answerId;
    }

    private checkImagePermissions(image): void {
      this.canEditTags = image.canEditTags;
    }

    private getCurrentImage(): Image | undefined {
      return this.images?.at(this.activeSlideId);
    }

    private fetchComments() {
      const answerId = this.getAnswerId();
      this.comments = [];

      const initComments = (comments, users) => {
        this.comments = comments.map((comment) =>
          this.mapComment(comment, users)
        );
        this.commentsActions = this.getCommentsActions();
      };

      if (this.isLocalMode) {
        this.$q
          .when(this.commentsService.getLocalList(answerId))
          .then((comments) => {
            const users = {
              [this.profile._id]: this.profile,
            };

            initComments(comments, users);
            this.comments.reverse();
          });
      } else {
        this.$q
          .when(this.commentsService.list(answerId))
          .then(({ entries, users }) => initComments(entries, users));
      }
    }

    private mapComment(comment, users) {
      return {
        id: comment._id,
        comment,
        user: users[comment.contents.author_id],
      };
    }

    private resetForm(onlyField = false) {
      this.commentField = '';
      this.editedCommentId = null;

      if (!onlyField) {
        this.isAddComment = false;
        this.isEditComment = false;
      }
    }

    $onInit() {
      this.activeSlideId = this.activeSlideId || 0;
      this.fetchComments();
      this.getTags();
      this.checkImagePermissions(this.images[this.activeSlideId]);
      this.fileSourceOption = this.mediaCommentAllowed
        ? { image: true, file: true }
        : { image: false };
    }

    $onChanges(changes: ng.IOnChangesObject): void {
      if (changes.comments) {
        this.commentsActions = this.getCommentsActions();
      }
    }

    addComment() {
      this.resetForm();

      this.isAddComment = true;
    }

    getTags() {
      this.tagsSelectorService.getTags().then((tags) => {
        this.tags = tags;
      });
    }

    imageTagsNames(): PictureTagModel[] {
      const image = this.getCurrentImage();

      if (!image) {
        return [];
      }

      return this.tags.filter(({ _id }) => image.tagsIds?.includes(_id));
    }

    isFullScreen() {
      return this.isAddComment || this.isEditComment;
    }

    hideCommentInput = (): void => {
      this.resetForm();
    };

    nextSlide() {
      if (this.activeSlideId < this.params.length - 1) {
        this.setActiveSlide(this.activeSlideId + 1);
      }
    }

    prevSlide() {
      this.setActiveSlide(this.activeSlideId - 1);
    }

    postMediaComment(fileBlob): ng.IPromise<void> {
      const type = this.newsfeedService.getResourceTypeFromMimeType(
        fileBlob.type
      );

      const validFiles = this.commentsFileService.getValidFromSelectedFiles(
        [fileBlob],
        type
      );

      if (!validFiles.length) {
        return this.$q.resolve();
      }

      const mediaFileId = this.objectIdService.create();
      const cancelPromise = this.$q.defer<void>();
      const onSendCancel = () => {
        cancelPromise.resolve();
        popupSending.clear();
      };

      const popupSending =
        this.taskCommentsPopupService.showPictureCommentSendingProgress(
          onSendCancel
        );

      return this.filesService
        .upload(fileBlob, mediaFileId, {
          canceler: cancelPromise,
          fileNameOverride: type === 'document' ? fileBlob.name : null,
        })
        .then(() => this.postComment(mediaFileId, type))
        .then(() => popupSending.onSuccess())
        .catch(() => popupSending.onError());
    }

    onDragDown(event): void {
      const DRAG_DISTANCE_MAX = 100;
      const { direction, distance } = event.gesture;

      this.previewElement.style.top = '';
      this.previewElement.style.opacity = '';

      if (distance > DRAG_DISTANCE_MAX) {
        if (direction === 'down') {
          if (this.isFullScreen()) {
            this.resetForm();
          } else {
            this.collapsed = true;
          }
        }

        if (direction === 'up') {
          if (this.collapsed) {
            this.collapsed = false;
          } else {
            this.addComment();
          }
        }

        return;
      }
    }

    setActiveSlide(activeIndex) {
      this.activeSlideId = activeIndex;

      this.fetchComments();
      this.resetForm(true);
      this.checkImagePermissions(this.images[this.activeSlideId]);
    }

    selectTags() {
      const image = this.getCurrentImage();

      if (!image) {
        return;
      }

      this.tagsSelectorService
        .openTagSelector([image], image.tagsRequired)
        .then((tags: SelectedTags) => {
          const tagsIds = Object.keys(tags[image.answerId]);

          if (!this.isLocalMode) {
            this.tagsService.editFileTags(image.id, tagsIds);
          }

          this.pubSubService.publish('TAGS_UPDATED', {
            answerId: image.answerId,
            tagsIds,
          });

          image.tagsIds = tagsIds;
          this.imageTagsNames();
        });
    }

    postComment(mediaFileId?: ObjectId, type?: string) {
      const answerId = this.getAnswerId();

      if (this.isEditComment) {
        const commentIndex = this.comments.findIndex(
          ({ id }) => this.editedCommentId === id
        );
        const { comment } = this.comments[commentIndex];

        comment.contents.content = this.commentField;

        this.commentsService
          .edit(answerId, comment, this.isLocalMode)
          .then(() => this.fetchComments());

        this.resetForm(true);
      } else {
        const comment = this.commentsService.buildComment(
          this.profile,
          this.commentField,
          { answer_id: answerId },
          mediaFileId,
          type
        );

        this.commentsService
          .create(answerId, comment, this.isLocalMode)
          .then(() => this.fetchComments());

        if (!mediaFileId) {
          this.resetForm(true);
        }
      }
    }

    swipe(event) {
      this.previewElement = this.$window.document.querySelector(
        `.sf_slide_${this.activeSlideId}`
      )!;

      this.previewElement.style.opacity = '0.7';
      this.previewElement.style.top = `${event.gesture.srcEvent.clientY}px`;
    }

    toggleCollapse() {
      if (this.isFullScreen() || this.collapsed) {
        this.resetForm();
        this.collapsed = false;
        return;
      }

      this.isAddComment = true;
    }

    getCommentsActions(): Record<string, ActionOptions> {
      return this.comments.reduce((acc, { comment }) => {
        const { _id } = comment;

        acc[_id] = this.taskCommentsActionsService.getCommentActions({
          author_id: comment.contents.author_id,
          profile: this.profile,
          isPicture: Boolean(comment.contents.picture_id),
          callbacks: {
            onDelete: () => {
              this.commentsService
                .delete(this.getAnswerId(), _id, this.isLocalMode)
                .then(() => {
                  this.comments = this.comments.filter(({ id }) => _id !== id);
                });
            },
            onEdit: () => {
              this.editedCommentId = _id;
              this.commentField = comment.contents.content;
              this.isAddComment = false;
              this.isEditComment = true;
            },
          },
        });

        return acc;
      }, {});
    }

    goToReport(report_id: ObjectId, campaign_id: ObjectId): void {
      this.onClose();
      this.$state.go('index.menu-more.reactive-campaigns.report', {
        reportId: report_id,
        campaignId: campaign_id,
        online: true,
      });
    }
  },
};
