export default function changeRequestsRoutes($stateProvider) {
  'ngInject';

  $stateProvider
    .state('index.menu-more.change-requests', {
      url: '/change-requests',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('index.menu-more.change-requests.tabs', {
      url: '/lists',
      template: `
          <sf-view sf-tab-bar-hide="true">
            <sf-nav-bar
              display-back-button="true"
              title="{{ ::'CHANGE_REQUESTS_TITLE' | translate }}">
            </sf-nav-bar>
            <sf-change-requests-tabs 
              profile="$ctrl.profile">
            </sf-change-requests-tabs>
          </sf-view>
        </ion-view>
      `,
      controller: ChangeRequestsViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Change requests Tabs',
    });

  function ChangeRequestsViewController(rsvpProfile) {
    'ngInject';
    this.profile = rsvpProfile;
  }
}
