import type { OauthBrowserService } from '../oAuthBrowser/oauth-browser.service';
import type { PlatformService } from '../Platform';

export class SamlService {
  constructor(
    private $window: ng.IWindowService,
    private oAuthBrowserService: OauthBrowserService,
    private platformService: PlatformService
  ) {
    'ngInject';
  }

  connect(url: string): ng.IPromise<Record<string, unknown>> {
    const originUrl = encodeURIComponent(this.$window.location.origin);
    const param = this.platformService.isBrowser()
      ? `redirect_to=${originUrl}`
      : 'saml_redirect=true';

    return this.oAuthBrowserService
      .openConnect(`${url}?${param}`, 'wrappedResponse')
      .then((res) => {
        if (!res) {
          throw new Error('');
        }
        return JSON.parse(decodeURIComponent(res));
      })
      .catch(() => {
        throw new Error('E_SAML2_CONNECT_FAILED');
      });
  }
}
