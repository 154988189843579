import { User } from '../../../app';
import { ErrorMessagesService } from '../../services/Utils/ErrorMessages/error-messages.service';
import { LocalizationService } from '../../services/Utils/Localization/localization.service';
import { NotificationsUtilsService } from '../../services/Utils/Notification/notifications-utils.service';
import { SF_MANAGE_TASK_VALIDATORS } from '../../tasks/task-manage/components/task-manage-form/task-manage-form.constants';
import { GroupTasksService } from '../services/group-tasks.service';
import { GroupTask } from '../types';

export class GroupTaskTemplateController implements ng.IComponentController {
  // Bindings
  onClose: () => void;
  onSave: () => () => void;
  profile: User;

  // Props
  usersSelectConfig = {
    disableUserSelectBtn: true,
  };
  isRTLNeeded = false;
  priorityOptions = {
    priorities: [
      {
        label: 'MANAGE_TASK_PRIORITY_LOW_LABEL',
        value: 0,
      },
      {
        label: 'MANAGE_TASK_PRIORITY_MEDIUM_LABEL',
        value: 10,
      },
      {
        label: 'MANAGE_TASK_PRIORITY_HIGH_LABEL',
        value: 20,
      },
    ],
  };
  validators: unknown;
  groupTaskTemplateForm: ng.IController;
  groupTask: Partial<GroupTask> = {
    template: {
      priority: this.priorityOptions.priorities[0].value,
    },
  } as Partial<GroupTask>;
  HEADER_TITLE = 'GROUP_TASK_TEMPLATE_TITLE';
  titlePattern = /\S(.*\S)?/;

  /* @ngInject */
  constructor(
    private localizationService: LocalizationService,
    private groupTasksService: GroupTasksService,
    private MANAGE_TASK_VALIDATORS: typeof SF_MANAGE_TASK_VALIDATORS,
    private $translate: ng.translate.ITranslateService,
    private $q: ng.IQService,
    private popupRequestService,
    private errorMessagesService: ErrorMessagesService,
    private formValidationsService,
    private helpersService,
    private notificationsUtilsService: NotificationsUtilsService
  ) {}

  $onInit(): void {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.validators = this.MANAGE_TASK_VALIDATORS;
    this.onTargetingUpdated = this.onTargetingUpdated.bind(this);
    if (!this.groupTask.template) {
      return;
    }
    this.groupTask.template.due_date =
      this.helpersService.getDueDateTimezonedHtml5(
        this.profile,
        new Date().toISOString()
      );
  }

  onTargetingUpdated(targeting: {
    links: { type: string; _id: string }[] | null;
  }) {
    if (!targeting?.links) {
      console.warn(
        `No targeting links provided for ${this.onTargetingUpdated.name.substring(
          6
        )}`
      );
      return;
    }
    this.groupTask.entityType =
      targeting.links[0]?.type === 'placesList' ? 'places' : 'users';
    this.groupTask.entityGroups = targeting.links.map((link) => link._id);
  }

  isNotificationInvalid(): boolean {
    if (!this.groupTask.template) {
      return false;
    }

    const isValid = this.notificationsUtilsService.validateNotification(
      this.groupTask.template.due_date,
      this.groupTask.template.due_time,
      this.groupTask.template.notificationReminder
    );

    this.groupTaskTemplateForm.$setValidity('notificationValidation', isValid);

    return !isValid;
  }

  onSubmit(): ng.IPromise<unknown> {
    if (
      !this.groupTaskTemplateForm.$valid ||
      this.groupTaskTemplateForm.$submitting
    ) {
      return this.$q.when(false);
    }

    this.groupTaskTemplateForm.$submitting = true;
    return this.submitForm();
  }

  hasError(fieldName: string): boolean {
    return this.formValidationsService.hasToDisplay(
      this.groupTaskTemplateForm,
      fieldName
    );
  }

  getError(fieldName: string): string {
    return this.formValidationsService.getInputError(
      this.groupTaskTemplateForm,
      fieldName
    );
  }

  getPopupText() {
    return {
      progress: {
        title: this.$translate.instant('GROUP_TASK_CREATION_TITLE_PROGRESS'),
      },
      success: {
        title: this.$translate.instant('GROUP_TASK_CREATION_TITLE_SUCCESS'),
        iconName: 'thumbsup',
      },
      error: {
        title: this.$translate.instant('GROUP_TASK_TITLE_ERROR'),
        desc: this.$translate.instant('GROUP_TASK_CREATION_DESC_ERROR'),
      },
    };
  }

  submitForm(): ng.IPromise<void> {
    const popupText = this.getPopupText();
    const popupRequest = this.popupRequestService.show(popupText);
    const onRetry = () => this.submitForm();
    if (this.groupTask.template) {
      this.groupTask.template.due_date = new Date(
        this.groupTask.template.due_date
      ).toISOString();
    }
    return this.groupTasksService
      .create(this.groupTasksService.completeGroupTasks(this.groupTask))
      .then(() => {
        popupRequest.onSuccess().then(() => {
          this.onSave()();
        });
      })
      .catch((error) => {
        if (this.errorMessagesService.isServerBadRequest(error)) {
          this.groupTaskTemplateForm.$errorMessages = [
            'GROUP_TASK_FORM_ERROR_VALIDATIONS',
          ];
        }
        popupRequest.onError(onRetry);
      })
      .finally(() => (this.groupTaskTemplateForm.$submitting = false));
  }
}
