import previewRouteConfig from './preview.routes';

export default angular
  .module('sfMobile.preview', [
    'sfMobile.missions',
    'sfMobile.services.profile',
    'sfMobile.services.places',
    'sfMobile.services.users',
  ])
  .config(previewRouteConfig).name;
