import { ReportScanStatisticsComponent } from './report-scan-statistics.component';

export default angular
  .module('sfMobile.components.report-scan-statistics', [
    'sfMobile.services.products',
    'sfMobile.services.places',
    'sfMobile.components.progress-circle',
    'sfMobile.components.brand-goal',
    'sfMobile.constants.numbers-scale',
    'sfMobile.constants.currencies',
  ])
  .component('sfReportScanStatistics', ReportScanStatisticsComponent).name;
