"use strict";

/* eslint-disable max-len, max-lines */
var createObjectId = require('../utils/createObjectId');

var statusMap = [{
  key: 'empty',
  label: 'STATUS_REPORT_EMPTY'
}, {
  key: 'done',
  label: 'STATUS_REPORT_DONE'
}];

module.exports = function () {
  return [{
    _id: createObjectId(),
    name: 'marketting1',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Marketting campaign',
      needPlace: false,
      state: 'draft',
      sections: [],
      questions: [],
      statusMap: statusMap,
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('marketting2'),
    name: 'marketting2',
    description: 'Every data that can be set.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'For Sale House',
      description: 'A form to fill when adding a new house for sale.',
      needPlace: false,
      state: 'current',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('section-1'),
        name: 'section-1',
        title: 'Owner',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 5
      }, {
        _id: createObjectId('section-2'),
        name: 'section-1',
        title: 'House',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1
      }],
      questions: [{
        _id: createObjectId('question-1-1'),
        section_id: createObjectId('section-1'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Name',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-1-1-1'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('question-1-2'),
        section_id: createObjectId('section-1'),
        name: 'question-1-2',
        type: 'generic',
        title: 'Email',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-1-2-1'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('question-1-3'),
        section_id: createObjectId('section-1'),
        name: 'question-1-3',
        type: 'generic',
        title: 'Comment',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-1-3-1'),
          type: 'string'
        }]
      }, {
        _id: createObjectId('question-1-4'),
        section_id: createObjectId('section-1'),
        name: 'question-1-4',
        type: 'generic',
        title: 'Visit date',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-1-4-1'),
          type: 'date',
          anterior: true
        }]
      }, {
        _id: createObjectId('question-2-1'),
        section_id: createObjectId('section-2'),
        name: 'question-2-1',
        type: 'generic',
        title: 'Price (Euro)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-2-1-1'),
          type: 'number'
        }]
      }, {
        _id: createObjectId('question-2-2'),
        section_id: createObjectId('section-2'),
        name: 'question-2-2',
        type: 'unit',
        title: 'Garden',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-2-2-1'),
          type: 'number'
        }, {
          _id: createObjectId('field-2-2-2'),
          type: 'string',
          "enum": ['m2', 'km2'],
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('question-2-3'),
        section_id: createObjectId('section-2'),
        name: 'question-2-3',
        type: 'generic',
        title: 'Visit duration',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-2-3-1'),
          type: 'duration'
        }]
      }, {
        _id: createObjectId('question-2-4'),
        section_id: createObjectId('section-2'),
        name: 'question-2-4',
        type: 'image',
        title: 'Photo',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-2-4-1'),
          type: 'uuid',
          sources: ['image']
        }, {
          _id: createObjectId('field-2-4-2'),
          type: 'string',
          "default": '=file.name'
        }, {
          _id: createObjectId('field-2-4-3'),
          type: 'string',
          "default": '=file.mime'
        }]
      }, {
        _id: createObjectId('question-2-5'),
        section_id: createObjectId('section-2'),
        name: 'question-2-5',
        type: 'video',
        title: 'Video',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-2-5-1'),
          type: 'uuid',
          sources: ['video']
        }, {
          _id: createObjectId('field-2-5-2'),
          type: 'string',
          "default": '=file.name'
        }, {
          _id: createObjectId('field-2-5-3'),
          type: 'string',
          "default": '=file.mime'
        }]
      }, {
        _id: createObjectId('question-2-6'),
        section_id: createObjectId('section-2'),
        name: 'question-2-6',
        type: 'rating',
        title: 'Rating',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-2-6-1'),
          type: 'number',
          minimum: 1,
          maximum: 10,
          multipleOf: 1
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('form'),
    name: 'recursive',
    description: 'A recursive form.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'For Sale House',
      needPlace: false,
      state: 'current',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('section-1'),
        name: 'section-1',
        title: 'First',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1
      }, {
        _id: createObjectId('section-1-1'),
        name: 'section-1-1',
        title: 'Child',
        type: 'generic',
        level: 1,
        minimum: 1,
        maximum: 1
      }, {
        _id: createObjectId('section-1-2'),
        name: 'section-1-2',
        title: 'Sibling',
        type: 'generic',
        level: 1,
        minimum: 1,
        maximum: 1
      }, {
        _id: createObjectId('section-1-2-1'),
        name: 'section-1-2-1',
        title: 'Child',
        type: 'generic',
        level: 2,
        minimum: 1,
        maximum: 1
      }, {
        _id: createObjectId('section-1-3'),
        name: 'section-1-3',
        title: 'Parent',
        type: 'generic',
        level: 1,
        minimum: 1,
        maximum: 1
      }, {
        _id: createObjectId('section-1-3-1'),
        name: 'section-1-3-1',
        title: 'Child',
        type: 'generic',
        level: 2,
        minimum: 1,
        maximum: 1
      }, {
        _id: createObjectId('section-2'),
        name: 'section-2',
        title: 'Parent',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1
      }],
      questions: [{
        _id: createObjectId('question-1-1-1'),
        section_id: createObjectId('section-1-1'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Name',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-1-1-1'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('question-1-2-1-1'),
        section_id: createObjectId('section-1-2-1'),
        name: 'question-1-2',
        type: 'generic',
        title: 'Email',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-1-2-1-1'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('question-1-3-1-1'),
        section_id: createObjectId('section-1-3-1'),
        name: 'question-1-3',
        type: 'generic',
        title: 'Comment',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-1-3-1-1'),
          type: 'string'
        }]
      }, {
        _id: createObjectId('question-2-1'),
        section_id: createObjectId('section-2'),
        name: 'question-1-3',
        type: 'generic',
        title: 'Comment',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('field-2-1-1-1'),
          type: 'string'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'unknow',
    description: 'Unknown contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_UNEXPECTED_CONTENT',
        params: ['.wadup']
      }]
    },
    partial: {
      valid: false
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Marketting campaign',
      needPlace: false,
      state: 'draft',
      statusMap: statusMap,
      questions: [],
      sections: [],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: [],
      wadup: {
        Kikoolol: true
      },
      trololol: 'facepalm'
    }
  }, {
    _id: createObjectId(),
    name: 'empty',
    description: 'Empty contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.title']
      }]
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {}
  }, {
    _id: createObjectId(),
    name: 'noowner',
    description: 'No users',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.owner_id']
      }]
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Marketting campaign',
      needPlace: false,
      state: 'draft',
      statusMap: statusMap,
      questions: [],
      sections: [],
      organisation_id: createObjectId('organisation'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('oneq'),
    name: 'onequestion',
    description: 'Form with only one question.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'One question',
      needPlace: false,
      state: 'current',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('oneq-section'),
        name: 'section-1',
        title: '',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1
      }],
      questions: [{
        _id: createObjectId('oneq-question'),
        section_id: createObjectId('oneq-section'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Say "Hello World"',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('oneq-field'),
          type: 'string',
          pattern: 'inline'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'inventory',
    description: 'Inventory form (used per e-mails).',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Inventory',
      description: "Let's check what is in a given place!",
      needPlace: true,
      state: 'current',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('inventory-officies'),
        name: 'officies',
        title: 'Officies',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: Infinity
      }, {
        _id: createObjectId('inventory-rooms'),
        name: 'rooms',
        title: 'Rooms',
        type: 'generic',
        level: 1,
        minimum: 1,
        maximum: Infinity
      }, {
        _id: createObjectId('inventory-furnitures'),
        name: 'furnitures',
        title: 'Furnitures in the room',
        type: 'generic',
        level: 2,
        minimum: 1,
        maximum: Infinity
      }, {
        _id: createObjectId('inventory-employees'),
        name: 'employees',
        title: 'Employees in the room',
        type: 'generic',
        level: 2,
        minimum: 1,
        maximum: Infinity
      }, {
        _id: createObjectId('inventory-corridors'),
        name: 'corridors',
        title: 'Corridors',
        type: 'generic',
        level: 1,
        minimum: 1,
        maximum: Infinity
      }, {
        _id: createObjectId('inventory-furnitures2'),
        name: 'furnitures2',
        title: 'Furnitures in the corridor',
        type: 'generic',
        level: 2,
        minimum: 1,
        maximum: Infinity
      }],
      questions: [{
        _id: createObjectId('inventory-officies-title'),
        section_id: createObjectId('inventory-officies'),
        name: 'title',
        type: 'generic',
        title: 'Title (old inline_text)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-officies-title-field-1'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('inventory-officies-description'),
        section_id: createObjectId('inventory-officies'),
        name: 'description',
        type: 'generic',
        title: 'Description (old comment)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-officies-description-field-1'),
          type: 'string'
        }]
      }, {
        _id: createObjectId('inventory-officies-surface'),
        section_id: createObjectId('inventory-officies'),
        name: 'surface',
        type: 'generic',
        title: 'Surface (old unit)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-officies-surface-field-1'),
          type: 'number',
          pace: 1
        }, {
          _id: createObjectId('inventory-officies-surface-field-2'),
          type: 'string',
          "enum": ['m2', 'km2', 'ares']
        }]
      }, {
        _id: createObjectId('inventory-officies-price'),
        section_id: createObjectId('inventory-officies'),
        name: 'price',
        type: 'generic',
        title: 'Square Meter Price (€) (old number)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-officies-price-field-1'),
          type: 'number',
          pace: 0.01
        }]
      }, {
        _id: createObjectId('inventory-officies-rating'),
        section_id: createObjectId('inventory-officies'),
        name: 'rating',
        type: 'generic',
        title: 'Rating (old rating)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-officies-rating-field-1'),
          type: 'number',
          pace: 1,
          minimum: 1,
          maximum: 10
        }]
      }, {
        _id: createObjectId('inventory-officies-date'),
        section_id: createObjectId('inventory-officies'),
        name: 'date',
        type: 'generic',
        title: 'Date (old day)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-officies-date-field-1'),
          type: 'date',
          anterior: true
        }]
      }, {
        _id: createObjectId('inventory-officies-hour'),
        section_id: createObjectId('inventory-officies'),
        name: 'hour',
        type: 'generic',
        title: 'Hour (old time)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-officies-hour-field-1'),
          type: 'time'
        }]
      }, {
        _id: createObjectId('inventory-officies-duration'),
        section_id: createObjectId('inventory-officies'),
        name: 'duration',
        type: 'generic',
        title: 'Duration (old chrono)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-officies-duration-field-1'),
          type: 'duration'
        }]
      }, {
        _id: createObjectId('inventory-furnitures-barcode'),
        section_id: createObjectId('inventory-furnitures'),
        name: 'barcode',
        type: 'generic',
        title: 'Furniture barcode (old barcode)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-furnitures-barcode-field-1'),
          type: 'number',
          sources: ['barcode']
        }]
      }, {
        _id: createObjectId('inventory-furnitures-state'),
        section_id: createObjectId('inventory-furnitures'),
        name: 'state',
        type: 'generic',
        title: 'Furniture state (old question_radio)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-furnitures-state-field-1'),
          type: 'string',
          "enum": ['Excellent', 'Bon', 'Moyen', 'Mauvais']
        }]
      }, {
        _id: createObjectId('inventory-furnitures-color'),
        section_id: createObjectId('inventory-furnitures'),
        name: 'color',
        type: 'generic',
        title: 'Furniture color (old question_check)',
        minimum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-furnitures-color-field-1'),
          type: 'string',
          "enum": ['Noir', 'Blanc', 'Beige', 'Jaune']
        }]
      }, {
        _id: createObjectId('inventory-furnitures-type'),
        section_id: createObjectId('inventory-furnitures'),
        name: 'type',
        type: 'generic',
        title: 'Furniture type (old question_select)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-furnitures-type-field-1'),
          type: 'string',
          "enum": ['Bureau', 'Écran', 'Siège', 'Tableau']
        }]
      }, {
        _id: createObjectId('inventory-furnitures-image'),
        section_id: createObjectId('inventory-furnitures'),
        name: 'image',
        type: 'generic',
        title: 'Furniture photo (old photo)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-furnitures-image-field-1'),
          type: 'uuid',
          sources: ['image']
        }, {
          _id: createObjectId('inventory-furnitures-image-field-2'),
          type: 'string',
          sources: ['file.mime']
        }, {
          _id: createObjectId('inventory-furnitures-image-field-3'),
          type: 'string',
          sources: ['file.name']
        }]
      }, {
        _id: createObjectId('inventory-employees-audio'),
        section_id: createObjectId('inventory-employees'),
        name: 'audio',
        type: 'generic',
        title: 'Interview (old audio)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-employees-audio-field-1'),
          type: 'string',
          sources: ['microphone']
        }, {
          _id: createObjectId('inventory-employees-audio-field-2'),
          type: 'string',
          sources: ['file.mime']
        }, {
          _id: createObjectId('inventory-employees-audio-field-3'),
          type: 'string',
          sources: ['file.name']
        }, {
          _id: createObjectId('inventory-employees-audio-field-4'),
          type: 'number',
          sources: ['file.duration']
        }]
      }, {
        _id: createObjectId('inventory-rooms-video'),
        section_id: createObjectId('inventory-rooms'),
        name: 'video',
        type: 'generic',
        title: 'Video tour (old video)',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-rooms-video-field-1'),
          type: 'uuid',
          sources: ['video']
        }, {
          _id: createObjectId('inventory-rooms-video-field-2'),
          type: 'string',
          sources: ['file.mime']
        }, {
          _id: createObjectId('inventory-rooms-video-field-3'),
          type: 'string',
          sources: ['file.name']
        }, {
          _id: createObjectId('inventory-rooms-video-field-4'),
          type: 'number',
          sources: ['file.duration']
        }]
      }, {
        _id: createObjectId('inventory-furnitures2-count'),
        section_id: createObjectId('inventory-furnitures2'),
        name: 'count',
        type: 'generic',
        title: 'Furnitures count',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('inventory-furnitures2-count-field-1'),
          type: 'number'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('tricky-form'),
    name: 'tricky',
    description: 'A tricky form for CSV exportation.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'A tricky form',
      needPlace: false,
      state: 'current',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('tricky-section-1'),
        name: 'tricky-section-1',
        title: 'S1',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 10
      }, {
        _id: createObjectId('tricky-section-1.1'),
        name: 'tricky-section-1_1',
        title: 'S1.1',
        type: 'generic',
        level: 1,
        minimum: 1,
        maximum: 10
      }, {
        _id: createObjectId('tricky-section-1.1.1'),
        name: 'tricky-section-1_1_1',
        title: 's1.1.1',
        type: 'generic',
        level: 2,
        minimum: 0,
        maximum: 1
      }, {
        _id: createObjectId('tricky-section-1.1.2'),
        name: 'tricky-section-1_1_2',
        title: 'S1.1.2',
        type: 'generic',
        level: 2,
        minimum: 0,
        maximum: 10
      }, {
        _id: createObjectId('tricky-section-2'),
        name: 'tricky-section-2',
        title: 'S2',
        type: 'generic',
        level: 0,
        minimum: 0,
        maximum: 10
      }, {
        _id: createObjectId('tricky-section-2.1'),
        name: 'tricky-section-2_1',
        title: 'S2.1',
        type: 'generic',
        level: 1,
        minimum: 0,
        maximum: 10
      }, {
        _id: createObjectId('tricky-section-2.2'),
        name: 'tricky-section-2_1',
        title: 'S2.1',
        type: 'generic',
        level: 1,
        minimum: 0,
        maximum: 10
      }],
      questions: [{
        _id: createObjectId('tricky-question-1'),
        section_id: createObjectId('tricky-section-1'),
        name: 'tricky-question-1',
        type: 'generic',
        title: 'Q1',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('tricky-section-field-1'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('tricky-question-1.1.1'),
        section_id: createObjectId('tricky-section-1.1.1'),
        name: 'tricky-question-1_1_1',
        type: 'generic',
        title: 'Q1.1.1',
        minimum: 0,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('tricky-section-field-1.1.1'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('tricky-question-1.1.2'),
        section_id: createObjectId('tricky-section-1.1.2'),
        name: 'tricky-question-1_1_2',
        type: 'generic',
        title: 'Q1.1.2',
        minimum: 1,
        maximum: 3,
        unique: true,
        fields: [{
          _id: createObjectId('tricky-section-field-1.1.2'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('tricky-question-2.1'),
        section_id: createObjectId('tricky-section-2.1'),
        name: 'tricky-question-2_1',
        type: 'generic',
        title: 'Q2.1',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('tricky-section-field-2.1'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('tricky-question-2.2'),
        section_id: createObjectId('tricky-section-2.2'),
        name: 'tricky-question-2_2',
        type: 'generic',
        title: 'Q2.2',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('tricky-section-field-2.2'),
          type: 'string',
          pattern: 'inline'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('oneq'),
    name: 'onequestion',
    description: 'Form with only one question and metadata.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'One question',
      needPlace: false,
      state: 'current',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('oneq-section'),
        name: 'section-1',
        description: 'Hey guy!',
        title: '',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1,
        metadata: {
          subName: 'Roger'
        },
        files: []
      }],
      questions: [{
        _id: createObjectId('oneq-question'),
        section_id: createObjectId('oneq-section'),
        description: 'Hey guy!',
        name: 'question-1-1',
        type: 'generic',
        title: 'Say "Hello World"',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('oneq-field'),
          type: 'string',
          pattern: 'inline'
        }],
        metadata: {
          subName: 'Roger'
        },
        files: []
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('health-metrics'),
    name: 'health-metrics',
    description: 'A very simple form using formulas to compute BMI.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'People metrics',
      needPlace: false,
      state: 'current',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('people-section'),
        name: 'people',
        title: 'Person',
        description: 'Find a person and fill this short survey.',
        type: 'generic',
        level: 0,
        minimum: 1,
        // Using a formula to set infinity since Infinity is not JSON serializable
        maximum: 'Infinity',
        files: []
      }],
      questions: [{
        _id: createObjectId('people-height-question'),
        section_id: createObjectId('people-section'),
        title: 'Height',
        description: 'Enter the person height (in meters).',
        name: 'height',
        type: 'generic',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('people-height-field'),
          type: 'number',
          minimum: 1
        }],
        files: []
      }, {
        _id: createObjectId('people-weight-question'),
        section_id: createObjectId('people-section'),
        title: 'Weight',
        description: 'Enter the person weight (in kilograms).',
        name: 'weight',
        type: 'generic',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('people-weight-field'),
          type: 'number',
          minimum: 1
        }],
        files: []
      }, {
        _id: createObjectId('people-bmi-question'),
        section_id: createObjectId('people-section'),
        title: 'Body mass index',
        description: 'Formula: weight/height^2',
        name: 'bmi',
        type: 'generic',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('people-bmi-field'),
          type: 'number',
          editable: false,
          formula: "getValue('weight') / (" + "getValue('height') * getValue('height')" + ')'
        }],
        files: []
      }, {
        _id: createObjectId('people-sport-underweigthed-question'),
        section_id: createObjectId('people-section'),
        title: 'Sport practice',
        metadata: {
          label: 'Underweighted people sport practice'
        },
        description: 'Wether the person do sport activities.',
        name: 'sport',
        type: 'generic',
        minimum: 'inf("number", getValue(\'bmi\', 0), 25) ? 1 : 0',
        maximum: 'inf("number", getValue(\'bmi\', 0), 25) ? 1 : 0',
        unique: true,
        fields: [{
          _id: createObjectId('people-sport-underweigthed-field'),
          type: 'string',
          set: ['No sport at all', '0-2 hours per week', '2-4 hours per week', 'More than 4 hours per week'],
          "default": '0-2 hours per week'
        }],
        files: []
      }, {
        _id: createObjectId('people-sport-normal-question'),
        section_id: createObjectId('people-section'),
        title: 'Sport practice',
        metadata: {
          label: 'Normal people sport practice'
        },
        description: 'Wether the person do sport activities.',
        name: 'sport',
        type: 'generic',
        minimum: 'supeq("number", getValue(\'bmi\', 0), 25) &&' + ' inf("number", getValue(\'bmi\', 0), 30) ? 1 : 0',
        maximum: 'supeq("number", getValue(\'bmi\', 0), 25) &&' + ' inf("number", getValue(\'bmi\', 0), 30) ? 1 : 0',
        unique: true,
        fields: [{
          _id: createObjectId('people-sport-normal-field'),
          type: 'string',
          set: ['No sport at all', '0-2 hours per week', '2-4 hours per week', 'More than 4 hours per week'],
          "default": '0-2 hours per week'
        }],
        files: []
      }, {
        _id: createObjectId('people-sport-overweigthed-question'),
        section_id: createObjectId('people-section'),
        title: 'Sport practice',
        metadata: {
          label: 'Overweighted people sport practice'
        },
        description: 'Wether the person do sport activities.',
        name: 'sport',
        type: 'generic',
        minimum: 'supeq("number", getValue(\'bmi\', 0), 30) ? 1 : 0',
        maximum: 'supeq("number", getValue(\'bmi\', 0), 30) ? 1 : 0',
        unique: true,
        fields: [{
          _id: createObjectId('people-sport-overweigthed-field'),
          type: 'string',
          set: ['No sport at all', '0-2 hours per week', '2-4 hours per week', 'More than 4 hours per week'],
          "default": '0-2 hours per week'
        }],
        files: []
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'bad-reference',
    description: 'Bad references',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: false,
      errors: [{
        code: 'E_BAD_QUESTION_ID'
      }]
    },
    contents: {
      title: 'Marketting campaign',
      needPlace: false,
      state: 'draft',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('bad-reference-section'),
        name: 'section-1',
        title: '',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1,
        files: []
      }],
      questions: [{
        _id: createObjectId(),
        section_id: createObjectId('bad-reference-section'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Say "Hello World"',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId(),
          type: 'string',
          formula: 'getValue("abbacacaabbacacaabbacaca", 2)',
          pattern: 'inline'
        }],
        files: []
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'bad-reference-2',
    description: 'Bad references in minimum',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: false,
      errors: [{
        code: 'E_BAD_QUESTION_ID'
      }]
    },
    contents: {
      title: 'Marketting campaign',
      needPlace: false,
      state: 'draft',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('bad-reference-2-section'),
        name: 'section-1',
        title: '',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1,
        files: []
      }],
      questions: [{
        _id: createObjectId(),
        section_id: createObjectId('bad-reference-2-section'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Say "Hello World"',
        minimum: 1,
        maximum: 'getValue("abbacacaabbacacaabbacaca", 2)',
        unique: true,
        fields: [{
          _id: createObjectId(),
          type: 'string',
          pattern: 'inline'
        }],
        files: []
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'bad-reference-2',
    description: 'Bad references in minimum',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: false,
      errors: [{
        code: 'E_BAD_QUESTION_ID'
      }]
    },
    contents: {
      title: 'Marketting campaign',
      needPlace: false,
      state: 'draft',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('bad-reference-3-section'),
        name: 'section-1',
        title: '',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 'getValue("abbacacaabbacacaabbacaca", 2) ? 1: 2',
        files: []
      }],
      questions: [{
        _id: createObjectId(),
        section_id: createObjectId('bad-reference-3-section'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Say "Hello World"',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId(),
          type: 'string',
          pattern: 'inline'
        }],
        files: []
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'bad-type-1',
    description: 'Bad type in maximum',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: false,
      errors: [{
        code: 'E_BAD_EXPRESSION'
      }]
    },
    contents: {
      title: 'Marketting campaign',
      needPlace: false,
      state: 'draft',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('bad-type-1-section'),
        name: 'section-1',
        title: '',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1,
        files: []
      }],
      questions: [{
        _id: createObjectId('bad-type-1-question-1'),
        section_id: createObjectId('bad-type-1-section'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Say "Hello World"',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId(),
          type: 'number',
          pattern: 'inline'
        }],
        files: []
      }, {
        _id: createObjectId(),
        section_id: createObjectId('bad-type-1-section'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Say "Hello World"',
        minimum: 1,
        maximum: "eq(\n  \"string\",  getValue(\"".concat(createObjectId('bad-type-1-question-1'), "\", 0),  \"plop\") ? 1: 2"),
        unique: true,
        fields: [{
          _id: createObjectId(),
          type: 'string',
          pattern: 'inline'
        }],
        files: []
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'different-type-1',
    description: 'Different type in maximum',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Hello world',
      needPlace: false,
      state: 'draft',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('different-type-1-section'),
        name: 'section-1',
        title: '',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1,
        files: []
      }],
      questions: [{
        _id: createObjectId('different-type-1-question-1'),
        section_id: createObjectId('different-type-1-section'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Say "Hello World"',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId(),
          type: 'string',
          pattern: 'inline'
        }],
        files: []
      }, {
        _id: createObjectId(),
        section_id: createObjectId('different-type-1-section'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Say "Hello World"',
        minimum: 1,
        maximum: "eq(\n  \"string\",  getValue(\"".concat(createObjectId('different-type-1-question-1'), "\", 0),  \"plop\") ? 1: 2"),
        unique: true,
        fields: [{
          _id: createObjectId(),
          type: 'string',
          pattern: 'inline'
        }],
        files: []
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('conditional-section-form'),
    name: 'conditional-section-form',
    description: 'Form with a conditionnal section.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Safety check',
      needPlace: false,
      state: 'current',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('conditional-section-1'),
        name: 'conditional-section-1',
        description: '',
        title: '',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1,
        files: []
      }, {
        _id: createObjectId('conditional-section-2'),
        name: 'conditional-section-2',
        description: '',
        title: 'Safety issues',
        type: 'generic',
        level: 1,
        minimum: "ne(\n  \"string\",  getValue(\"".concat(createObjectId('conditional-section-question-1'), "\", 0),  \"No\") ? 1: 0"),
        maximum: "ne(\n  \"string\",  getValue(\"".concat(createObjectId('conditional-section-question-1'), "\", 0),  \"No\") ? Infinity: 0"),
        files: []
      }],
      questions: [{
        _id: createObjectId('conditional-section-question-1'),
        section_id: createObjectId('conditional-section-1'),
        description: '',
        name: 'question-1-1',
        type: 'generic',
        title: 'Are there safety issues?',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('conditional-section-field-1'),
          type: 'string',
          set: ['Yes', 'No', 'Maybe']
        }],
        files: []
      }, {
        _id: createObjectId('conditional-section-question-2'),
        section_id: createObjectId('conditional-section-2'),
        description: '',
        name: 'question-2',
        type: 'generic',
        title: 'Issue type',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('conditional-section-field-2'),
          type: 'string',
          set: ['Fire risks', 'Health', 'Body security']
        }],
        files: []
      }, {
        _id: createObjectId('conditional-section-question-3'),
        section_id: createObjectId('conditional-section-2'),
        description: '',
        name: 'question-3',
        type: 'generic',
        title: 'Comment',
        minimum: 0,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('conditional-section-field-3'),
          type: 'string',
          format: 'text'
        }],
        files: []
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, // items order prop
  {
    _id: createObjectId('itemsOrder1'),
    name: 'form with itemsOrder',
    description: 'Form with itemsOrder',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'One question',
      needPlace: false,
      state: 'current',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('oneq-section'),
        name: 'section-1',
        title: 'Section 1',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1,
        itemsOrder: [{
          _id: createObjectId('question1'),
          type: 'question'
        }]
      }],
      questions: [{
        _id: createObjectId('question1'),
        section_id: createObjectId('oneq-section'),
        name: 'question-1-1',
        type: 'generic',
        title: 'Say "Hello World"',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('oneq-field'),
          type: 'string',
          pattern: 'inline'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('itemsOrder2'),
    name: 'form with tree itemsOrder',
    description: 'Form with tree itemsOrder',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'One question',
      needPlace: false,
      state: 'current',
      statusMap: statusMap,
      sections: [{
        _id: createObjectId('s1'),
        name: 'section-1',
        title: 'Section 1',
        type: 'generic',
        level: 0,
        minimum: 1,
        maximum: 1,
        itemsOrder: [{
          type: 'section',
          _id: createObjectId('s3')
        }, {
          type: 'question',
          _id: createObjectId('q3')
        }, {
          type: 'question',
          _id: createObjectId('q2')
        }, {
          type: 'section',
          _id: createObjectId('s2')
        }, {
          type: 'question',
          _id: createObjectId('q1')
        }]
      }, {
        _id: createObjectId('s2'),
        name: 'section-2',
        title: 'Section 2',
        type: 'generic',
        level: 1,
        minimum: 1,
        maximum: 1,
        itemsOrder: [{
          type: 'question',
          _id: createObjectId('q4')
        }]
      }, {
        _id: createObjectId('s3'),
        name: 'section-3',
        title: 'Section 3',
        type: 'generic',
        level: 1,
        minimum: 1,
        maximum: 1,
        itemsOrder: [{
          type: 'question',
          _id: createObjectId('q5')
        }]
      }],
      questions: [{
        _id: createObjectId('q1'),
        section_id: createObjectId('s1'),
        name: 'question1',
        type: 'generic',
        title: 'q1',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('oneq-field1'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('q2'),
        section_id: createObjectId('s1'),
        name: 'question2',
        type: 'generic',
        title: 'q2',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('oneq-field2'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('q3'),
        section_id: createObjectId('s1'),
        name: 'question3',
        type: 'generic',
        title: 'q3',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('oneq-field3'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('q4'),
        section_id: createObjectId('s2'),
        name: 'question4',
        type: 'generic',
        title: 'q4',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('oneq-field4'),
          type: 'string',
          pattern: 'inline'
        }]
      }, {
        _id: createObjectId('q5'),
        section_id: createObjectId('s3'),
        name: 'question5',
        type: 'generic',
        title: 'q5',
        minimum: 1,
        maximum: 1,
        unique: true,
        fields: [{
          _id: createObjectId('oneq-field5'),
          type: 'string',
          pattern: 'inline'
        }]
      }],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('ean-questions'),
    name: 'form with ean questions',
    description: 'Form with ean questions',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'My form with ean',
      needPlace: true,
      state: 'current',
      sections: [],
      questions: [{
        _id: createObjectId('q1'),
        title: 'Scan your product',
        type: 'generic',
        section_id: createObjectId('s1'),
        unique: false,
        maximum: 'Infinity',
        minimum: 1,
        fields: [{
          _id: createObjectId('f1'),
          type: 'ean',
          sources: ['barcode']
        }],
        metadata: {}
      }],
      statusMap: statusMap,
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      subscribers_ids: [],
      users_ids: [],
      places_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'report-template-id',
    description: 'With report template id',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'With report template id',
      needPlace: false,
      state: 'draft',
      sections: [],
      questions: [],
      statusMap: statusMap,
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: [],
      reportTemplate_id: createObjectId()
    }
  }, {
    _id: createObjectId('signature-questions'),
    name: 'form with signature questions',
    description: 'Form with signature questions',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'My form with ean',
      needPlace: true,
      state: 'current',
      sections: [],
      questions: [{
        _id: createObjectId('signature'),
        title: 'Sign here',
        type: 'signature',
        section_id: createObjectId('s1'),
        unique: true,
        maximum: 1,
        minimum: 1,
        fields: [{
          _id: createObjectId('signature-field-1'),
          type: 'uuid',
          sources: ['drawing']
        }, {
          _id: createObjectId('signature-field-2'),
          type: 'string',
          "default": '=file.name'
        }, {
          _id: createObjectId('signature-field-3'),
          type: 'string',
          "default": '=file.mime'
        }],
        metadata: {}
      }],
      statusMap: statusMap,
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      subscribers_ids: [],
      users_ids: [],
      places_ids: []
    }
  }, {
    _id: createObjectId('with-analysts'),
    name: 'form with analysts',
    description: 'Form with analysts',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Form with analysts',
      needPlace: false,
      state: 'draft',
      sections: [],
      questions: [],
      statusMap: statusMap,
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: [],
      analysts_ids: [createObjectId('analyst1'), createObjectId('analyst2')]
    }
  }, {
    _id: createObjectId('with-start-date'),
    name: 'form with analysts',
    description: 'Form with analysts',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Form with analysts',
      needPlace: false,
      state: 'draft',
      start_date: '2019-03-29T22:00:00.000Z',
      sections: [],
      questions: [],
      statusMap: statusMap,
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('with-end-date'),
    name: 'form with analysts',
    description: 'Form with analysts',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Form with analysts',
      needPlace: false,
      state: 'draft',
      end_date: '2019-03-29T22:00:00.000Z',
      sections: [],
      questions: [],
      statusMap: statusMap,
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('reminder'),
    name: 'reminder',
    description: 'Reminder',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Marketting campaign',
      reminder: true,
      reminderContents: [25, 50, 75],
      needPlace: false,
      state: 'draft',
      sections: [],
      questions: [],
      statusMap: statusMap,
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'reportValidation',
    description: 'Form with report validation',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'Marketting campaign',
      needPlace: false,
      state: 'draft',
      sections: [],
      questions: [],
      statusMap: statusMap,
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      places_ids: [],
      subscribers_ids: [],
      reportsValidation: [{
        _id: createObjectId('v1'),
        label: 'step1',
        reviewers_ids: [createObjectId('reviewer1'), createObjectId('reviewer2'), createObjectId('reviewer3')]
      }, {
        _id: createObjectId('v2'),
        label: 'step2',
        reviewers_ids: [createObjectId('reviewer1'), createObjectId('reviewer4')]
      }]
    }
  }, {
    _id: createObjectId('abbacaca6a6afffffffffffc'),
    name: 'form with all plottable questions',
    description: 'Form with all plottable questions',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    referential: {
      valid: true
    },
    contents: {
      title: 'My form with plottable',
      needPlace: false,
      state: 'current',
      sections: [],
      questions: [{
        _id: createObjectId('q1'),
        title: 'Scan your product (sales)',
        type: 'product-sales',
        section_id: createObjectId('53c710453c710453c7104000'),
        unique: false,
        maximum: 'Infinity',
        minimum: 1,
        fields: [{
          _id: createObjectId('q1f1'),
          type: 'ean',
          sources: ['barcode']
        }, {
          _id: createObjectId('q1f2'),
          type: 'number'
        }, {
          _id: createObjectId('q1f3'),
          type: 'number'
        }],
        metadata: {}
      }, {
        _id: createObjectId('q2'),
        title: 'Scan your product (count)',
        type: 'product-count',
        section_id: createObjectId('s1'),
        unique: false,
        maximum: 'Infinity',
        minimum: 1,
        fields: [{
          _id: createObjectId('q2f1'),
          type: 'ean',
          sources: ['barcode']
        }, {
          _id: createObjectId('q2f2'),
          type: 'number'
        }],
        metadata: {}
      }, {
        _id: createObjectId('q3'),
        title: 'Scale 0-10',
        type: 'generic',
        section_id: createObjectId('s1'),
        unique: true,
        maximum: 1,
        minimum: 1,
        fields: [{
          _id: createObjectId('q3f1'),
          type: 'number',
          maximum: 10,
          multipleOf: 1
        }],
        metadata: {}
      }, {
        _id: createObjectId('q4'),
        title: 'Count',
        type: 'generic',
        section_id: createObjectId('s1'),
        unique: true,
        maximum: 1,
        minimum: 1,
        fields: [{
          _id: createObjectId('q4f1'),
          type: 'number'
        }, {
          _id: createObjectId('q4f2'),
          type: 'string',
          set: ['Euros(€)'],
          exclusiveSet: true
        }],
        metadata: {}
      }, {
        _id: createObjectId('q5'),
        title: 'Radio question with free answer',
        type: 'generic',
        section_id: createObjectId('s1'),
        unique: true,
        maximum: 1,
        minimum: 1,
        fields: [{
          _id: createObjectId('q5f1'),
          type: 'string',
          set: ['Answer A', 'Answer B'],
          exclusiveSet: false
        }],
        metadata: {}
      }, {
        _id: createObjectId('q6'),
        title: 'Checkbox question with free answer',
        type: 'checkbox',
        section_id: createObjectId('s1'),
        unique: true,
        maximum: Infinity,
        minimum: 1,
        fields: [{
          _id: createObjectId('q6f1'),
          type: 'string',
          set: ['Answer A', 'Answer B'],
          exclusiveSet: false
        }],
        metadata: {}
      }],
      statusMap: statusMap,
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('owner'),
      subscribers_ids: [],
      users_ids: [createObjectId('babababababababababababa')],
      places_ids: [],
      analysts_ids: [createObjectId('babababababababababababd')]
    }
  }];
};