import { Organisation, User } from '../../..';
import { Capacitor } from '@capacitor/core';
import { LocalizationService } from '../Localization/localization.service';

declare global {
  interface Window {
    satismeter: ((data: Record<string, unknown>) => void) & {
      l: number;
      q: unknown[];
    };
  }
}

const getHigherRole = (profiles) => {
  if (profiles.includes('root')) {
    return 'root';
  }
  if (profiles.includes('admin')) {
    return 'admin';
  }
  if (profiles.includes('contentManager')) {
    return 'contentManager';
  }
  if (profiles.includes('manager')) {
    return 'manager';
  }
  return 'contributor';
};

export class SatismeterService {
  constructor(
    private ConfigServer,
    private localizationService: LocalizationService
  ) {
    'ngInject';
    window.satismeter =
      window.satismeter ||
      ((...args) => {
        (window.satismeter.q = window.satismeter.q || []).push(args);
      });
    window.satismeter.l = Date.now();
    const script = document.createElement('script');
    const parent = document.getElementsByTagName('script')[0].parentNode;

    script.async = true;
    script.src = 'https://app.satismeter.com/js';
    if (parent) {
      parent.appendChild(script);
    }
  }

  trigger(profile: User, organization: Organisation): void {
    if (this.ConfigServer.SATISMETER_APP_ID) {
      const traits = {
        name: [profile.contents.firstName, profile.contents.lastName].join(' '),
        email: profile.contents.email,
        role: getHigherRole(profile.contents.profiles),
        country: profile.contents.country,
        createdAt: profile.created_date,
        language: this.localizationService.getDeviceLang(),
        organizationId: organization._id,
        organizationName: organization.contents.name,
        appVersion: this.ConfigServer.APP_VERSION,
        deviceOS: Capacitor.getPlatform(),
      };

      window.satismeter({
        writeKey: this.ConfigServer.SATISMETER_APP_ID,
        userId: profile._id,
        traits,
      });
    }
  }
}
