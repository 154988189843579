import type { ObjectId } from '../../..';
import type { PlatformService } from '../Platform';
import type { FilesService } from '../../API/files/files.service';
import type { FileSystemService } from '../FileSystem/file-system.service';
import type { ImageSourceService } from '../ImageSource/image-source.service';
import { PopupService } from '../Popup/popup.service';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';

type DocOptions = {
  id: ObjectId;
  url: string;
  folder: string;
  contentType: string;
  name: string;
  requireAuthForDownload?: boolean;
};

export class DocumentViewerService {
  FileOpener = FileOpener;

  // eslint-disable-next-line max-params
  constructor(
    private $window: ng.IWindowService,
    private platformService: PlatformService,
    private filesSystemService: FileSystemService,
    private filesService: FilesService,
    private imageSourceService: ImageSourceService,
    private popupService: PopupService,
    private $translate: ng.translate.ITranslateService
  ) {
    'ngInject';
  }

  openDocument(url: string, contentType = 'application/pdf'): void {
    if (this.platformService.isBrowser()) {
      this.$window.open(url, '_blank');
      return;
    }
    this.openViewer(url, contentType).catch((e) => {
      if (e?.status === 9) {
        this.popupService.showError({
          title: this.$translate.instant('NO_APP_TO_HANDLE_FILE_MESSAGE'),
        });
      }
    });
  }

  openRemoteDocument(
    options: DocOptions
  ): ng.IPromise<unknown> | Window | null {
    if (this.platformService.isBrowser()) {
      return this.$window.open(options.url, '_blank');
    }

    return this.getDestPath(options).then((filePath) =>
      this.filesSystemService
        .checkFile(filePath)
        .then((isExist: boolean) => {
          if (isExist) {
            return null;
          }
          const file = this.imageSourceService.create(
            options,
            true,
            options.requireAuthForDownload
          );
          const fileOptions = {
            directory: this.filesSystemService.getDeviceFilePath(),
            path: `${options.folder}/${options.id}`,
            name: options.name,
          };

          return this.filesService
            .download(fileOptions, file)
            .catch((error) => {
              throw new Error(`unable to download file ${filePath}, ${error}`);
            });
        })
        .then(() => {
          return this.openViewer(filePath, options.contentType);
        })
    );
  }

  getDestPath(options: DocOptions): ng.IPromise<string> {
    const { name: fullName } = options;
    const { name, ext = 'pdf' } =
      this.filesSystemService.getFileNameExt(fullName);

    return this.filesSystemService.computeFileDataPath(
      `${options.folder}/${options.id}`,
      name,
      ext
    );
  }

  openViewer(filePath: string, contentType: string): ng.IPromise<void> {
    return this.FileOpener.openFile({
      path: filePath,
      mimeType: contentType,
    });
  }

  openDocumentInBrowser(
    file: {
      _id: ObjectId;
      mime_type: string;
    },
    blob?: Blob
  ): ng.IPromise<void> {
    const { _id, mime_type } = file;

    if (blob) {
      const tmpFileUrl = this.$window.URL.createObjectURL(blob);
      this.$window.open(tmpFileUrl, '_system');
      return Promise.resolve();
    }

    return this.filesService
      .getFileBlob(
        this.imageSourceService.create(
          {
            id: _id,
            type: mime_type,
          },
          true
        )
      )
      .then((fileBlob) => {
        const blob =
          fileBlob.type !== 'undefined' && mime_type
            ? fileBlob
            : new Blob([fileBlob], { type: mime_type });
        const tmpFileUrl = this.$window.URL.createObjectURL(blob);

        this.$window.open(tmpFileUrl, '_system');
      });
  }
}
