export const SF_TASK_STATUSES = {
  DONE: {
    keyword: 'done',
    i18nKey: 'TASK_STATUS_DONE',
  },
  DONE_ON_TIME: {
    keyword: 'done_on_time',
    i18nKey: 'DATE_FORMAT_DONE_ON_TIME',
  },
  LATE_COMPLETION: {
    keyword: 'late_completion',
    i18nKey: 'DATE_FORMAT_LATE_COMPLETION',
  },
  TODO: {
    keyword: 'todo',
    i18nKey: 'TASK_STATUS_TODO',
  },
  DELETED: {
    keyword: 'deleted',
    i18nKey: 'TASK_STATUS_DELETED',
  },
} as const;

export default angular
  .module('sfMobile.constants.task-statuses', [])
  .constant('TASK_STATUSES', SF_TASK_STATUSES).name;
