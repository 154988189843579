export const PopupRedirectComponent = {
  bindings: {
    title: '@',
    description: '@',
    imageSrc: '@',
    actionButtonText: '@',
    cancelButtonText: '@',
    onAction: '&',
    onCancel: '&',
  },
  templateUrl: 'components/Popup/popup-redirect/popup-redirect.html',
};
