import 'moment/min/locales.min';
import { Device } from '@capacitor/device';
import { PopupService } from '../Popup/popup.service';
import { App } from '@capacitor/app';
import { PlatformService } from '../Platform';
import { ConfigService } from '../Config/config.service';

export type LocalizeLang = {
  label: string;
  value: string;
};

export class LocalizationService {
  // eslint-disable-next-line max-params
  constructor(
    private $window: ng.IWindowService,
    private $document: ng.IDocumentService,
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private dateFormatService,
    private ConfigServer,
    private localStorageService: ng.local.storage.ILocalStorageService,
    private configService: ConfigService,
    private platformService: PlatformService,
    private popupService: PopupService
  ) {
    'ngInject';
  }

  init(): void {
    const lang = this.getPreferredLanguage(this.getDeviceLang());

    const defaultLanguage = this.configService.getDefaultLanguage();
    this.$translate.fallbackLanguage(defaultLanguage);
    this.$translate.preferredLanguage(lang);

    this.setLang(lang);
  }

  getDeviceLang(): string {
    const language = this.getFullDeviceLang();

    return language.split('-')[0];
  }

  getFullDeviceLang(): string {
    return this.$window.navigator?.language ?? '';
  }

  getPreferredLanguage(locale: string): string {
    const defaultLanguage = this.configService.getDefaultLanguage();

    const result = this.isLocaleTranslated(locale) ? locale : defaultLanguage;
    return result;
  }

  getTranslationLocale(locale: string): string {
    const fullDeviceLang = this.getFullDeviceLang();

    if (fullDeviceLang.startsWith('zh')) {
      locale = this.getZhLocale(fullDeviceLang);
    }

    return this.getPreferredLanguage(locale);
  }

  getZhLocale(fullDeviceLang: string): string {
    return this.isLocaleTranslated(fullDeviceLang)
      ? fullDeviceLang
      : this.getDefaultZhLocale(fullDeviceLang);
  }

  getDefaultZhLocale(fullDeviceLang: string): string {
    switch (fullDeviceLang) {
      case 'zh':
      case 'zh-TW':
      case 'zh-MO':
      case 'zh-HK':
        return 'zh'; // Traditional Chinese locale for angularjs.
      default:
        return 'zh-CN'; // Simplified Chinese locale for angularjs. zh-Hans cannot be used
    }
  }

  isLocaleTranslated(locale: string): boolean {
    const { LANGUAGES_AVAILABLES } = this.ConfigServer;
    return LANGUAGES_AVAILABLES.indexOf(locale) !== -1;
  }

  setLang(locale: string): void {
    this.$translate.use(this.getTranslationLocale(locale));
    this.dateFormatService.setLocale(locale);
    this.setDocumentLang(locale);
  }

  setDocumentLang(locale: string): void {
    const document = this.$document[0] as HTMLDocument;
    const attrLang = document.body.lang;

    if (attrLang) {
      document.body.classList.remove(`locale-${attrLang}`);
    }

    document.body.lang = locale;
    document.body.classList.add(`locale-${locale}`);
  }

  shouldActivateRTL(): boolean {
    const RTLLanguages = ['ar']; // can be improved with other RTL languages
    return RTLLanguages.includes(this.getDeviceLang());
  }

  shouldUseChinaConfiguration(): ng.IPromise<boolean> {
    const configurationArea =
      this.localStorageService.get('configuration_area');

    // If explicitly set, return area
    if (configurationArea === 'china') {
      return this.$q.when(true);
    }
    if (configurationArea === 'world') {
      return this.$q.when(false);
    }

    // If not explicitly set, base result on device locale
    const navigatorLang = this.getFullDeviceLang();

    if (navigatorLang.toLowerCase().includes('zh')) {
      return this.$q.when(true);
    }

    return Device.getLanguageCode()
      .then((deviceLang) => {
        if (deviceLang.value.toLowerCase().includes('zh')) {
          return true;
        }

        return false;
      })
      .catch(() => {
        return false;
      });
  }

  displayAgreementIfNeeded(): ng.IPromise<unknown> {
    const agreementNotificationTemplate = `
      <sf-popup-agreement
        on-close="$ctrl.onClose()"
        open-popup="$ctrl.openPopup()"
        has-denied="$ctrl.hasDenied">
      </sf-popup-agreement>
    `;
    const openAgreementPopup = (denied) =>
      this.popupService.showCustomTemplate({
        template: agreementNotificationTemplate,
        bindings: {
          hasDenied: denied,
        },
        actions: [
          {
            text: this.$translate.instant('POPUP_AGREEMENT_DISAGREE'),
            action: () => {
              if (!denied) {
                return openAgreementPopup(true);
              }
              this.localStorageService.set('configuration_area', 'world');
              if (this.platformService.isAndroid()) {
                return App.exitApp();
              }
              return window.location.reload();
            },
          },
          {
            text: this.$translate.instant('POPUP_AGREEMENT_AGREE'),
            action: () => {
              this.localStorageService.set(
                'terms_and_conditions_accepted',
                true
              );
            },
          },
        ],
      });

    return this.shouldUseChinaConfiguration().then((useChinaConfiguration) => {
      if (
        !useChinaConfiguration ||
        this.localStorageService.get('terms_and_conditions_accepted')
      ) {
        return;
      }

      return openAgreementPopup(false);
    });
  }
}
