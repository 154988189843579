import { Loader } from '@googlemaps/js-api-loader';
import type { MarkerClusterer } from '@googlemaps/markerclusterer';
import type { MapsMarkerClusterer } from './google-map-cluster.service';

const GOOGLE_MAP_CONFIG = {
  zoom: 15,
};
const GOOGLE_MAP_MARKER_PATH = 'img/map-marker.png';
const GOOGLE_MAP_MARKER_ACTIVE_PATH = 'img/map-marker-active.png';
const GOOGLE_API_EVENT = {
  MARKER_CLICK: 'click',
  MAP_CLICK: 'click',
  INFO_CLOSE: 'closeclick',
} as const;

// google.maps is a namespace so it's not possible to set one type or interface that equals google.maps
export type GoogleMapProvider = {
  Map: google.maps.Map;
  LatLng: google.maps.LatLng;
  LatLngBounds: typeof google.maps.LatLngBounds;
  event: {
    removeListener: (listener: google.maps.MapsEventListener) => void;
    clearInstanceListeners: (instance: any) => void;
  };
  Size: typeof google.maps.Size;
  Marker: typeof google.maps.Marker;
};

export class GoogleMapService {
  private GmapsLoader = Loader;
  private activeMarker: google.maps.Marker | null;
  private loadPromise: ng.IPromise<void> | null;

  constructor(
    private $q: ng.IQService,
    private $window: ng.IWindowService,
    private MarkerClustererService: MapsMarkerClusterer,
    private ConfigServer
  ) {
    'ngInject';
  }

  getMap(
    elem: HTMLDivElement,
    position: google.maps.LatLng,
    mapOptions?: google.maps.MapOptions
  ): ng.IPromise<google.maps.Map> {
    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
    const options: google.maps.MapOptions = {
      zoom: GOOGLE_MAP_CONFIG.zoom,
      center: position,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      ...(mapOptions || {}),
    };

    return this.getLoadPromise().then(() => new google.maps.Map(elem, options));
  }

  getAutoComplete(
    elem: HTMLInputElement,
    autoCompleteOptions?: google.maps.places.Autocomplete
  ): ng.IPromise<google.maps.places.Autocomplete> {
    // https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions
    const options: google.maps.places.AutocompleteOptions = {
      fields: ['address_components', 'geometry', 'formatted_address'],
    };

    return this.getLoadPromise().then(
      () => new google.maps.places.Autocomplete(elem, options)
    );
  }

  addMarkerCluster(markers, map, onMarkerClicked) {
    const api = this.getGoogleMaps();

    const markersWithPosition = markers.filter(
      (marker) => marker.position.lat && marker.position.lng
    );
    const onClick = (mapMarker) => {
      this.resetActiveMarkerIcon();
      this.activeMarker = mapMarker;
      mapMarker.setIcon(GOOGLE_MAP_MARKER_ACTIVE_PATH);
      const markerData = mapMarker.get('data');

      onMarkerClicked(markerData);
    };

    return this.getLoadPromise().then(() => {
      const mapMarkers = markersWithPosition.map((marker) => {
        const mapMarker = new google.maps.Marker(marker);

        this.eventListener('MARKER_CLICK', mapMarker, () => onClick(mapMarker));
        return mapMarker;
      });

      return this.MarkerClustererService.getClusterer(map, mapMarkers, api);
    });
  }

  resetActiveMarkerIcon(): void {
    this.activeMarker && this.activeMarker.setIcon(GOOGLE_MAP_MARKER_PATH);
  }

  eventListener(
    name: keyof typeof GOOGLE_API_EVENT,
    elem: google.maps.MVCObject,
    cb: () => any
  ): void {
    elem.addListener(GOOGLE_API_EVENT[name], cb);
  }

  clear(map: google.maps.Map, cluster: MarkerClusterer): void {
    this.activeMarker = null;
    map && this.getGoogleMaps().event.clearInstanceListeners(map);
    cluster && cluster.clearMarkers();
  }

  getCenter(
    markers: { position: google.maps.LatLng }[]
  ): ng.IPromise<google.maps.LatLng> {
    return this.getLoadPromise().then(() => {
      const api = this.getGoogleMaps();
      const latLngBounds = new api.LatLngBounds();

      markers.forEach((marker) => {
        latLngBounds.extend(marker.position);
      });

      return latLngBounds.getCenter();
    });
  }

  getMapMarkerPath(): string {
    return GOOGLE_MAP_MARKER_PATH;
  }

  private getGoogleMaps(): GoogleMapProvider {
    return this.$window?.google?.maps;
  }

  load(): ng.IPromise<void> {
    return this.$q((resolve) => {
      new this.GmapsLoader({
        apiKey: this.ConfigServer.GOOGLE_MAP_API_KEY,
        version: 'quarterly',
        libraries: ['places'],
        language: 'en',
      }).loadCallback(resolve);
    });
  }

  getLoadPromise(): ng.IPromise<void> {
    if (this.loadPromise) {
      return this.loadPromise;
    }
    this.loadPromise = this.load().catch(() => {
      this.loadPromise = null;
    });
    return this.loadPromise;
  }
}
