const JWT_EXPIRED_CODES = ['E_JWT_EXPIRED', 'E_NO_REFRESH'];
export const LOGOUT_AFTER_REFRESH_CODES = ['E_NO_REFRESH', 'E_BAD_CREDENTIALS'];

// This is not the right way to know if the url is part of sf-auth
// In any case, this will be replaced by the Auth0 implementation planned in summer 2021
// Nathan Pichon 10/05/2021
const isAuthUrl = (resUrl) => {
  const urlToNotRetry = ['resetpassword', 'authenticate'];

  return urlToNotRetry.some((url) => resUrl.indexOf(url) !== -1);
};

const isAnExpiredTokenCode = (err) =>
  err.data ? JWT_EXPIRED_CODES.includes(err.data.code) : false;

const isMalformedToken = (err) =>
  err.data ? 'E_JWT_MALFORMED' === err.data.code : false;

const getContents = (profile) => profile && profile.contents;
const getJwt = (profile) => getContents(profile) && profile.contents.jwt;

export {
  isAuthUrl,
  isAnExpiredTokenCode,
  getContents,
  getJwt,
  isMalformedToken,
};
