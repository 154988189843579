import { StateService, TransitionPromise } from '@uirouter/angularjs';
import {
  PubSubService,
  UnregisterFn,
} from '../services/Utils/PubSub/pubsub.service';

export class LayoutController implements ng.IComponentController {
  // Bindings
  organisation;

  // Properties
  hasChangeRequests = false;
  hasMerchandising = false;
  hasNewsfeed = false;
  hasReportValidation = false;
  hasUnseenPosts = false;
  menuMoreBadge: string | boolean;
  nav;

  // Badges
  changeRequestsCount: number;
  merchandisingPhotosCount: number;
  toReviseCount: number;
  unseenPostsCount: number | '99+';

  // Listeners
  changeRequestsCountUpdateListener: UnregisterFn;
  dataSyncedDestroyListener: UnregisterFn;
  lastSeenPostInfoUpdateListener: UnregisterFn;
  merchandisingPhotosCountUpdateListener: UnregisterFn;
  organisationSwitchListener: UnregisterFn;
  reportToReviseListener: UnregisterFn;

  constructor(
    private $q: ng.IQService,
    private $state: StateService,
    private chatService,
    private eventChangeRequestsService,
    private merchandisingApiService,
    private organisationsService,
    private pubSubService: PubSubService,
    private reportsValidationAPIService,
    private sfFeatureFlagsService,
    private userExperienceService,
    private CHANGE_REQUESTS_STATES,
    private SF_FEATURE_FLAGS
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<[void, void]> {
    this.registerFlags();
    this.registerListeners();
    this.refreshMenu();

    return this.$q.all([
      this.initMerchandisingCount(),
      this.initChangeRequestCount(),
    ]);
  }

  $onDestroy(): void {
    if (this.dataSyncedDestroyListener) {
      this.dataSyncedDestroyListener();
    }
    if (this.organisationSwitchListener) {
      this.organisationSwitchListener();
    }

    this.unregisterListeners();
  }

  registerFlags(): void {
    this.hasChangeRequests = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.EVENTS_VALIDATION
    );
    this.hasMerchandising = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.MERCHANDISING
    );
    this.hasNewsfeed = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.NEWSFEED
    );
    this.hasReportValidation = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.REPORTS_VALIDATION
    );
  }

  registerListeners(): void {
    this.dataSyncedDestroyListener = this.pubSubService.subscribe(
      'DATA_SYNCED',
      () => {
        this.registerFlags();
        this.unregisterListeners();
        this.registerListeners();
        this.refreshMenu();
      }
    );
    this.organisationSwitchListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.ORGANISATION_SWITCH,
      (data: { profile }) => {
        return this.organisationsService
          .getProfileOrganisation(data.profile)
          .then((organisation) => {
            this.organisation = organisation;
            this.registerFlags();
            this.unregisterListeners();
            this.registerListeners();
            this.refreshMenu();
          });
      }
    );

    if (this.hasChangeRequests) {
      this.changeRequestsCountUpdateListener = this.pubSubService.subscribe(
        'CHANGE_REQUESTS_COUNT_UPDATED',
        (counts) => {
          this.changeRequestsCount =
            this.eventChangeRequestsService.getCountByState({
              state: this.CHANGE_REQUESTS_STATES.PENDING,
              counts,
            });
          this.updateMenuMoreBadge();
        }
      );
    }
    if (this.hasMerchandising) {
      this.merchandisingPhotosCountUpdateListener =
        this.pubSubService.subscribe<{ count: number }>(
          'MERCH_TO_REVIEW_COUNT_UPDATED',
          ({ count }) => {
            this.merchandisingPhotosCount = count;
            this.updateMenuMoreBadge();
          }
        );
    }
    if (this.hasNewsfeed) {
      this.lastSeenPostInfoUpdateListener = this.pubSubService.subscribe<{
        postsCount: number;
      }>('LAST_SEEN_POST_INFO_UPDATED', ({ postsCount }) => {
        this.hasUnseenPosts = postsCount > 0;
        this.unseenPostsCount = postsCount > 100 ? '99+' : postsCount;
        this.updateMenuMoreBadge();
      });
    }
    if (this.hasReportValidation) {
      this.reportToReviseListener = this.pubSubService.subscribe<{
        count: number;
      }>('REPORT_TO_REVISE_COUNT_UPDATED', ({ count }) => {
        this.toReviseCount = count;
        this.updateMenuMoreBadge();
      });
      this.reportsValidationAPIService.updateToReviseCounts().catch(() => null);
    }
  }

  unregisterListeners(): void {
    if (this.changeRequestsCountUpdateListener) {
      this.changeRequestsCountUpdateListener();
    }
    if (this.lastSeenPostInfoUpdateListener) {
      this.lastSeenPostInfoUpdateListener();
    }
    if (this.merchandisingPhotosCountUpdateListener) {
      this.merchandisingPhotosCountUpdateListener();
    }
    if (this.reportToReviseListener) {
      this.reportToReviseListener();
    }
  }

  refreshMenu(): void {
    this.nav = {
      ...this.userExperienceService
        .getNav(this.organisation)
        .reduce((menu, { key }) => ({ ...menu, [key]: true }), {}),
    };
  }

  initMerchandisingCount(): ng.IPromise<void> {
    if (this.hasMerchandising) {
      return this.merchandisingApiService
        .getValidationsNeedReactionCount()
        .then((count) => {
          this.merchandisingPhotosCount = count;
          this.updateMenuMoreBadge();
        });
    }
    return this.$q.when();
  }

  initChangeRequestCount(): ng.IPromise<void> {
    if (this.hasChangeRequests) {
      return this.eventChangeRequestsService
        .getStatesCounts()
        .then((counts) => {
          this.changeRequestsCount =
            this.eventChangeRequestsService.getCountByState({
              status: this.CHANGE_REQUESTS_STATES.PENDING,
              counts,
            });
        })
        .catch(() => {
          this.changeRequestsCount = 0;
        });
    }
    return this.$q.when();
  }

  getNumberOfUnreadMessages(): number {
    return this.chatService.getNbUnreadCount(
      this.chatService.unreadMessageCount
    );
  }

  updateMenuMoreBadge(): void {
    this.menuMoreBadge =
      this.merchandisingPhotosCount > 0 ||
      this.changeRequestsCount > 0 ||
      this.hasUnseenPosts ||
      this.toReviseCount > 0
        ? ' '
        : false;
  }

  goTo(route: string): TransitionPromise {
    return this.$state.go(route);
  }

  goToMenuMore(): TransitionPromise {
    if (
      this.sfFeatureFlagsService.hasFeature(
        this.SF_FEATURE_FLAGS.REPORTS_VALIDATION
      )
    ) {
      this.reportsValidationAPIService.updateToReviseCounts().catch(() => null);
    }
    if (
      this.sfFeatureFlagsService.hasFeature(this.SF_FEATURE_FLAGS.MERCHANDISING)
    ) {
      this.merchandisingApiService
        .getValidationsNeedReactionCountRemote()
        .catch(() => null);
    }

    return this.$state.go('index.menu-more.content');
  }
}
