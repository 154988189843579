export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 15,
    method: updateMethod,
  });

  // @ngInject
  function updateMethod(sqlStorageService, localStorageService, sentryService) {
    const fields = [
      'id NVARCHAR(32) PRIMARY KEY UNIQUE',
      'payload TEXT',
      'name TEXT',
      'distance DOUBLE',
      'isAssigned INTEGER DEFAULT 0',
      'isTemporaryVisible INTEGER DEFAULT 0',
      'isTerritoryScope INTEGER DEFAULT 0',
    ].join(',');

    const dropTableQuery = 'DROP TABLE places';
    const createTableQuery = `CREATE TABLE places(${fields})`;

    const update_date_keys = localStorageService
      .keys()
      .filter((key) => key.indexOf('_update_date') !== -1);

    return sqlStorageService
      .execute(dropTableQuery)
      .then(() => sqlStorageService.execute(createTableQuery))
      .then(() =>
        localStorageService.remove.apply(localStorageService, update_date_keys)
      )
      .then(() => localStorageService.remove('synchronise_date'))
      .catch((error) =>
        sentryService.captureMessage('MIGRATION_SCRIPT_FAIL_15', {
          level: 'error',
          extra: {
            error,
          },
          tags: {
            sendNotification: 'mail',
          },
        })
      );
  }
}
