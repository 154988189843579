export const FormQuestionFieldsComponent = {
  bindings: {
    question: '<',
    form: '<',
    isDone: '<',
    hasError: '<',
    nodeId: '@',
    answers: '=',
    report: '=',
    actionClickedFn: '=?',
    onValueChange: '&',
    place: '<',
    unconfirmedPrefilledQuestions: '=',
    previousAnswers: '<',
    useNewCampaigns: '<',
    isDisabled: '<?',
    isDebug: '<?',
  },
  templateUrl:
    'missions/form/components/form-question-fields/form-question-fields.html',
  controller: function FormQuestionFieldsController(
    reportQuestionsService,
    formsService,
    platformService,
    pubSubService
  ) {
    'ngInject';

    this.$onInit = () => {
      const field = this.question.fields[0];
      const editableField = field.editable;

      this.isAndroid = platformService.isAndroid();
      this.field = field;
      this.fieldIsEditable =
        typeof editableField === 'undefined' || editableField;

      this.questionType = this.getQuestionType(this.question);

      if (this.hasConditions(this.question)) {
        this.answerChangedListener = pubSubService.subscribe(
          'QUESTION_ANSWER_CHANGED',
          () => this.onConditionsAnswersChanges()
        );
      }
    };

    this.getQuestionType = (question) =>
      reportQuestionsService.getQuestionTypeName(
        question,
        this.form,
        this.report,
        { place: this.place }
      );

    this.getQuestionMaximum = (question) => {
      // Be sure to always display the button on debug mode
      if (this.isDebug && !this.isDisabled) {
        return 1;
      }
      return formsService.computeMaximum(
        question,
        this.form,
        this.report.contents.answers,
        { place: this.place }
      );
    };

    this.hasConditions = (question) => {
      return (
        Array.isArray(question.metadata.conditions) &&
        question.metadata.conditions.length > 0
      );
    };

    this.onConditionsAnswersChanges = () => {
      this.questionType = this.getQuestionType(this.question);
    };
  },
};
