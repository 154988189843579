import { Subtask } from '../..';
import { ObjectId, User, UserContents } from '../../..';
import { EntitySelectorService } from '../../../components/entity-selector/services/entity-selector.service';
import { UsersService } from '../../../services/API/users/users.service';
import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';

const SUBTASK_NAME_LENGTH = 150;
const USER_FIELDS_SEARCH_FILTERS = ['user_firstName', 'user_lastName'];
const USER_FIELDS_STATIC_SEARCH_FILTERS = ['firstName', 'lastName'];

export const SubtasksManager: ng.IComponentOptions = {
  bindings: {
    parentForm: '<',
    usersSelectConfig: '<',
    subtasks: '=',
  },
  templateUrl: 'tasks/subtasks/subtasks-manager/subtasks-manager.html',
  controller: class SubTasksManager implements ng.IController {
    // bindings
    subtasks: Subtask[];
    parentForm: ng.IFormController;
    usersSelectConfig: {
      placeId?: ObjectId | null;
      users: User[] | null;
      disableUserSelectBtn: boolean;
    };

    // members
    newSubtask: string;
    name_length = SUBTASK_NAME_LENGTH;

    constructor(
      private entitySelectorService: EntitySelectorService,
      private objectIdService: ObjectIdService,
      private $translate: ng.translate.ITranslateService,
      private profileService,
      private usersService: UsersService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.subtasks = this.subtasks || [];
    }

    get subtypePlaceholder(): string {
      const key = this.subtasks.length
        ? 'SUBTASKS_FORM_PLACEHOLDER'
        : 'SUBTASKS_FORM_PLACEHOLDER_FIRST';

      return this.$translate.instant(key);
    }

    addSubtask(): void {
      const _id = this.objectIdService.create();
      this.subtasks.push({
        _id,
        name: this.newSubtask,
        assignee: null,
        status: 'todo',
      });
      this.newSubtask = '';
    }

    openSelectUserModal(subtask: Subtask) {
      const isPlaceScoped = !!this.usersSelectConfig.placeId;

      return this.entitySelectorService.openSelectEntityModal({
        title: this.$translate.instant(
          'MANAGE_TASK_BIG_LIST_MODAL_TITLE_ASSIGNEE'
        ),
        multiple: false,
        selected: subtask.assignee,
        entityTitleGet: isPlaceScoped
          ? (user: User) => this.profileService.getNameFromUser(user.contents)
          : (user: User) => this.profileService.getNameFromUser(user),
        emptyIcon: 'no_users',
        searchPlaceholder: this.$translate.instant(
          'MANAGE_TASK_ASSIGNEE_LABEL'
        ),
        onSave: ({ result }) => {
          if (result) {
            const _id = (result as unknown as Subtask['assignee'])
              ?._id as ObjectId;
            const contents = (result['contents']
              ? result['contents']
              : result) as unknown as UserContents;
            subtask.assignee = {
              _id,
              contents,
            };
            subtask.assignee_id = _id;
          }
        },
        searchFields: USER_FIELDS_SEARCH_FILTERS,
        staticSearchFields: USER_FIELDS_STATIC_SEARCH_FILTERS,
        // don't blame me - I copypasted this code from resource selector component and need to provide either entities or provider
        entities: isPlaceScoped ? null : this.usersSelectConfig.users,
        entityProvider: isPlaceScoped
          ? (params) =>
              this.usersService.getScopedUsers(
                params,
                this.usersSelectConfig.placeId as ObjectId
              )
          : null,
      });
    }

    deleteSubtask(subtask: Subtask): void {
      this.subtasks = this.subtasks.filter(({ _id }) => _id !== subtask._id);
    }
  },
};
