const SIZE_CLASSES = {
  small: 'sf_avatar__size_small',
  medium: 'sf_avatar__size_medium',
  big: 'sf_avatar__size_big',
  large: 'sf_avatar__size_large',
  'square-big': 'sf_avatar__size_big--square',
} as const;
const TYPE_CLASSES = {
  round: 'sf_avatar__size_round',
  square: 'sf_avatar__size_square',
} as const;

export const AvatarComponent: ng.IComponentOptions = {
  bindings: {
    imageSrc: '<?',
    size: '@?',
    type: '@?',
  },
  templateUrl: 'components/Avatar/avatar/avatar.html',
  transclude: true,
  controller: class AvatarController implements ng.IController {
    /** Bindings */
    public imageSrc: string | null;
    public size: keyof typeof SIZE_CLASSES;
    public type: keyof typeof TYPE_CLASSES;

    /** Fields */
    sizeClass: typeof SIZE_CLASSES[keyof typeof SIZE_CLASSES];
    typeClass: typeof TYPE_CLASSES[keyof typeof TYPE_CLASSES];
    isImgLoading = true;

    $onInit(): void {
      this.sizeClass = SIZE_CLASSES[this.size] || SIZE_CLASSES.medium;
      this.typeClass = TYPE_CLASSES[this.type] || TYPE_CLASSES.round;
    }

    onImgLoaded(): void {
      this.isImgLoading = false;
    }
  },
};
