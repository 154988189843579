import { pipe, prop, filter, map } from 'ramda';

type WeekDayCheckboxModel = {
  label: string;
  checked: boolean;
};

const getCheckedValues: (arg: WeekDayCheckboxModel[]) => string[] = pipe(
  filter<WeekDayCheckboxModel>(prop('checked')), // keep checked
  map<any, string>(prop('value')) // map value
);

export class WeekDaySelectorController implements ng.IComponentController {
  private ngModelCtrl: ng.INgModelController;
  private ngModel: number[];
  private checkboxModels: WeekDayCheckboxModel[];

  /* @ngInject */
  constructor(private dateFormatService) {}

  $onInit(): void {
    const [su, mo, tu, we, th, fr, sa] =
      this.dateFormatService.getWeekdaysFormatted();
    const selectedDays = this.ngModel || [];

    this.checkboxModels = [mo, tu, we, th, fr, sa, su].map((label, value) => ({
      label,
      value,
      checked: selectedDays.includes(value),
    }));
  }

  onChange(): void {
    this.ngModelCtrl.$setViewValue(getCheckedValues(this.checkboxModels));
  }
}
