import { startDate, startTime, endTime } from './calendar-events.filter';
import { CalendarEventsService } from './calendar-events.service';
import { CalendarEventsRecurrenceService } from './calendar-events-recurrence.service';
import { EventChangeRequestsService } from './event-change-requests.service';
import { EventTypesService } from './event-types.service';

export default angular
  .module('sfMobile.services.calendar-events', [
    'simplifield.feature-flags',
    'sfMobile.constants.feature-flags',
    'sfMobile.constants.dates-formating',
    'sfMobile.constants.calendar-events-recurrence-choices',
    'sfMobile.services.crud',
    'sfMobile.services.pov',
    'sfMobile.services.dates',
    'sfMobile.services.app-message',
    'sfMobile.services.pubsub',
  ])
  .service('calendarEventsService', CalendarEventsService)
  .service(
    'calendarEventsRecurrenceApiService',
    CalendarEventsRecurrenceService
  )
  .service('eventChangeRequestsService', EventChangeRequestsService)
  .service('eventTypesService', EventTypesService)
  .filter('calendarEventStartDate', startDate)
  .filter('calendarEventStartTime', startTime)
  .filter('calendarEventEndTime', endTime).name;
