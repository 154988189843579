// Key from push notifications
export type NotificationKey =
  | 'FORM_VIEW'
  | 'CHAT_ROOM_VIEW'
  | 'REPORT_VALIDATION_VIEW'
  | 'TASK_DETAILS_VIEW'
  | 'TASK_LIST_VIEW'
  | 'TO_REPORT_APPROVED'
  | 'TO_REVISE_VIEW'
  | 'NEWSFEED_POST_LIST_VIEW'
  | 'PLACE_VIEW'
  | 'REPORT_COMMENT_VIEW'
  | 'TASK_COMMENT_VIEW'
  | 'CAMPAIGN_ACTIVATED'
  | 'CAMPAIGN_REPORT_COMMENT_VIEW'
  | 'CAMPAIGN_VIEW'
  | 'ANSWER_COMMENT_VIEW'
  | 'ROUTINE_CHECKLIST_ACTIVATION'
  | 'ROUTINE_CHECKLIST_REMINDER';
// In app event
export type NotificationEventName =
  | 'NEW_MISSION'
  | 'NEW_CHAT_MESSAGE'
  | 'NEW_TASK'
  | 'VALIDATION_REJECTED'
  | 'REPORT_APPROVED'
  | 'REPORT_REJECTED'
  | 'NEW_NEWSFEED_POST'
  | 'NEW_PLACE_DOCUMENT'
  | 'REPORT_COMMENT_CREATED'
  | 'TASK_COMMENT_CREATED'
  | 'CAMPAIGN_ACTIVATED'
  | 'ANSWER_COMMENT_VIEW'
  | 'ROUTINE_CHECKLIST_ACTIVATION'
  | 'ROUTINE_CHECKLIST_REMINDER';

export type NotificationConfig = {
  state: string;
  event: NotificationEventName;
  getParams?: (
    params: Record<string, string>
  ) => Record<string, string | boolean>; // Transform notification params to state params
  hideOnForeground?: boolean; // Do not display when the app is open
  reloadData?: boolean; // Synchronize data on notification tap
};

export const NOTIFICATION_METAEVENTS = {
  NOTIFICATION_RECEIVED: 'NOTIFICATION_RECEIVED',
  NOTIFICATION_DISPLAY: 'NOTIFICATION_DISPLAY',
  LAST_SEEN_NOTIFICATIONS_UPDATED: 'LAST_SEEN_NOTIFICATIONS_UPDATED',
} as const;

export type NotificationMetaEvents =
  typeof NOTIFICATION_METAEVENTS[keyof typeof NOTIFICATION_METAEVENTS];

export const NOTIFICATION_EVENTS = {
  MISSIONS_CREATED: 'MISSIONS_CREATED',
  TASK_CREATED: 'TASK_CREATED',
  TASKS_CREATED: 'TASKS_CREATED',
  TASK_REMINDER_NOTIFICATION: 'TASK_REMINDER_NOTIFICATION',
  TASK_STATUS_CHANGED: 'TASK_STATUS_CHANGED',
  NEWSFEED_POST_CREATED: 'NEWSFEED_POST_CREATED',
  REPORT_COMMENT_CREATED: 'REPORT_COMMENT_CREATED',
  TASK_COMMENT_CREATED: 'TASK_COMMENT_CREATED',
  PHOTO_VALIDATION_REJECTED: 'PHOTO_VALIDATION_REJECTED',
  REPORT_APPROVED: 'REPORT_APPROVED',
  REPORT_NEEDS_REVISION: 'REPORT_NEEDS_REVISION',
  TASK_DUE_DATE_REACHED: 'TASK_DUE_DATE_REACHED',
  TASK_ASSIGNED: 'TASK_ASSIGNED',
  SUBTASK_ASSIGNED: 'SUBTASK_ASSIGNED',
  SUBTASK_STATUS_CHANGED: 'SUBTASK_STATUS_CHANGED',
  PLACE_DOCUMENT_UPLOADED: 'PLACE_DOCUMENT_UPLOADED',
  A_IN_STORE_CAMPAIGN_ACTIVATED: 'A_IN_STORE_CAMPAIGN_ACTIVATED',
  A_USER_FEEDBACK_CAMPAIGN_ACTIVATED: 'A_USER_FEEDBACK_CAMPAIGN_ACTIVATED',
  STORE_OBJECTIVE_REMINDERS: 'STORE_OBJECTIVE_REMINDERS',
  USER_OBJECTIVE_REMINDERS: 'USER_OBJECTIVE_REMINDERS',
  ANSWER_COMMENT_CREATED: 'ANSWER_COMMENT_CREATED',
  ROUTINE_CHECKLIST_ACTIVATION: 'ROUTINE_CHECKLIST_ACTIVATION',
  ROUTINE_CHECKLIST_REMINDER: 'ROUTINE_CHECKLIST_REMINDER',
} as const;

export type NotificationEventsType =
  typeof NOTIFICATION_EVENTS[keyof typeof NOTIFICATION_EVENTS];

export const LINKS_CONFIG: Record<NotificationKey, NotificationConfig> = {
  FORM_VIEW: {
    state: 'index.menu-more.missions.lists.missions',
    event: 'NEW_MISSION',
    reloadData: true,
  },
  CHAT_ROOM_VIEW: {
    state: 'index.chat.channel',
    getParams: (notifParams) => ({
      channelUrl: notifParams.channel,
    }),
    hideOnForeground: true,
    event: 'NEW_CHAT_MESSAGE',
  },
  REPORT_VALIDATION_VIEW: {
    state: 'index.menu-more.merchandising.validation-details',
    getParams: (notifParams) => ({
      validationId: notifParams.validation_id,
    }),
    event: 'VALIDATION_REJECTED',
    reloadData: true,
  },
  TASK_DETAILS_VIEW: {
    state: 'index.menu-more.tasks.details',
    getParams: (notifParams) => ({
      taskId: notifParams.taskId,
    }),
    event: 'NEW_TASK',
  },
  TASK_LIST_VIEW: {
    state: 'index.menu-more.tasks.list',
    event: 'NEW_TASK',
    reloadData: true,
  },
  TO_REPORT_APPROVED: {
    state: 'index.menu-more.reactive-campaigns.report',
    event: 'REPORT_APPROVED',
    getParams: ({ campaignId, reportId }) => ({
      campaignId,
      reportId,
      online: true,
    }),
  },
  TO_REVISE_VIEW: {
    state: 'index.menu-more.reactive-campaigns.report',
    event: 'REPORT_REJECTED',
    getParams: ({ campaignId, reportId }) => ({
      campaignId,
      reportId,
      online: true,
    }),
  },
  NEWSFEED_POST_LIST_VIEW: {
    state: 'index.newsfeed.details',
    event: 'NEW_NEWSFEED_POST',
    getParams: ({ postId }) => ({
      postId: postId,
    }),
  },
  PLACE_VIEW: {
    state: 'index.places.details.documents',
    getParams: (notifParams) => ({
      placeId: notifParams.placeId,
    }),
    event: 'NEW_PLACE_DOCUMENT',
  },
  REPORT_COMMENT_VIEW: {
    state: 'index.menu-more.missions.report',
    event: 'REPORT_COMMENT_CREATED',
    getParams: ({ form_id, report_id }) => ({
      campaignId: form_id,
      reportId: report_id,
      online: true,
      targetTab: 'comments',
    }),
  },
  TASK_COMMENT_VIEW: {
    state: 'index.menu-more.tasks.details',
    getParams: (notifParams) => ({
      taskId: notifParams.task_id,
      commentId: notifParams.comment_id,
    }),
    event: 'TASK_COMMENT_CREATED',
  },
  CAMPAIGN_ACTIVATED: {
    state: 'index.menu-more.reactive-campaigns.details',
    event: 'CAMPAIGN_ACTIVATED',
    getParams: ({ campaignId }) => ({
      campaignId,
      reloadData: true,
    }),
  },
  CAMPAIGN_REPORT_COMMENT_VIEW: {
    state: 'index.menu-more.reactive-campaigns.report',
    event: 'REPORT_COMMENT_CREATED',
    getParams: ({ campaign_id, report_id }) => ({
      campaignId: campaign_id,
      reportId: report_id,
      online: true,
      targetTab: 'comments',
    }),
  },
  CAMPAIGN_VIEW: {
    state: 'index.menu-more.reactive-campaigns.details',
    event: 'CAMPAIGN_ACTIVATED',
    getParams: ({ campaignId }) => ({
      campaignId,
      reloadData: true,
    }),
  },
  ANSWER_COMMENT_VIEW: {
    state: 'index.menu-more.reactive-campaigns.activity-details',
    event: 'ANSWER_COMMENT_VIEW',
    getParams: ({ answer_id, campaign_id }) => ({
      campaignId: campaign_id,
      openPreviewWithAnswerId: answer_id,
      targetTab: 'gallery',
    }),
  },
  ROUTINE_CHECKLIST_ACTIVATION: {
    state: 'index.menu-more.reactive-campaigns.details',
    event: 'ROUTINE_CHECKLIST_ACTIVATION',
    getParams: ({ campaign_id: campaignId }) => ({
      campaignId,
      online: true,
    }),
  },
  ROUTINE_CHECKLIST_REMINDER: {
    state: 'index.menu-more.reactive-campaigns.details',
    event: 'ROUTINE_CHECKLIST_REMINDER',
    getParams: ({ campaign_id: campaignId }) => ({
      campaignId,
      online: true,
    }),
  },
};
