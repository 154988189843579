import { InAppBrowserService } from '../../Utils/InAppBrowser/in-app-browser.service';
import { ApiUrlService } from '../apiUrl/api-url.service';
import { LinkPreview } from './types';

export class LinkPreviewService {
  private linkPreviewUrl = '/link/preview';

  constructor(
    private $q: ng.IQService,
    private $http: ng.IHttpService,
    private apiUrlService: ApiUrlService,
    private inAppBrowserService: InAppBrowserService
  ) {
    'ngInject';
  }

  getLinkPreview(link: string): ng.IPromise<LinkPreview> {
    return this.$q
      .when(this.apiUrlService.getApiUrl() + this.linkPreviewUrl)
      .then((url) =>
        this.$http.get<LinkPreview>(url, { params: { url: link } })
      )
      .then((response) => response.data);
  }

  extractUrlsFromText(text: string): string[] {
    // eslint-disable-next-line no-useless-escape
    const regex =
      /((https?):\/\/){1}([\w_-]+\.[\w_-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])*/gim;
    let matches: RegExpExecArray | null;
    const urls: string[] = [];

    while ((matches = regex.exec(text)) !== null) {
      urls.push(matches[0]);
    }

    return urls;
  }

  onLinkPreviewClick(url: string): ($event: Event) => void {
    return ($event) => {
      $event.stopPropagation();
      this.inAppBrowserService.open(url);
    };
  }
}
