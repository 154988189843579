export default function routeConfig($stateProvider) {
  'ngInject';

  $stateProvider
    .state('index.menu-more', {
      url: '/menu',
      abstract: true,
      views: {
        'tab-menu-more': {
          template: '<ion-nav-view></ion-nav-view>',
        },
      },
    })
    .state('index.menu-more.content', {
      url: '',
      template: `
        <ion-view class="sf_view__has_nav_bar">
          <sf-menu-more
            profile="$ctrl.profile"
            organisation="$ctrl.organisation">
          </sf-menu-more>
        </ion-view>
      `,
      controller: MenuMoreViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Menu more',
      resolve: {
        localProfile: getLocalProfile,
        organisation: getProfileOrganisation,
      },
    })
    .state('index.menu-more.profile', {
      url: '/profile',
      template: `
        <ion-view class="sf_profile">
          <sf-view sf-tab-bar-hide="true">
            <sf-nav-bar
              title="{{ ::'PROFILE_TITLE' | translate }}"
              display-back-button="true">
            </sf-nav-bar>
            <sf-profile
              profile="$ctrl.profile"
              organisation="$ctrl.organisation"
            >
            </sf-profile>
          </sf-view>
        </ion-view>
      `,
      controller: ProfileController,
      controllerAs: '$ctrl',
      analyticsName: 'Profile Detail',
      resolve: {
        localProfile: getLocalProfile,
        organisation: getProfileOrganisation,
      },
    })
    .state('index.menu-more.diagnosis', {
      url: '/diagnosis',
      template: `
        <ion-view class="sf_profile">
          <sf-view sf-tab-bar-hide="true">
            <sf-nav-bar
              title="{{ ::'DIAGNOSIS_TITLE' | translate }}"
              display-back-button="true">
            </sf-nav-bar>
            <sf-diagnosis></sf-diagnosis>
          </sf-view>
        </ion-view>
      `,
      controller: function ProfileDiagnosis() {},
      controllerAs: '$ctrl',
      analyticsName: 'Profile Diagnosis',
    });
}

function MenuMoreViewController(localProfile, organisation) {
  'ngInject';
  this.profile = localProfile;
  this.organisation = organisation;
}

function ProfileController(localProfile, organisation) {
  'ngInject';
  this.profile = localProfile;
  this.organisation = organisation;
}

// rsvpProfile contains profile received right after ionic app initialization (could be a potential bug on routes that are using rsvpProfile)
// but here user can update profile, so we always resolve latest local profile every time we visit this route pages
function getLocalProfile(profileService) {
  'ngInject';
  return profileService.getLocalProfile();
}

function getProfileOrganisation(organisationsService, localProfile) {
  'ngInject';
  return organisationsService.getProfileOrganisation(localProfile);
}
