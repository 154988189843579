export class CreateCalendarEventModalController {
  constructor(
    localizationService,
    calendarEventsService,
    popupRequestService,
    $translate
  ) {
    'ngInject';
    this.localizationService = localizationService;
    this.calendarEventsService = calendarEventsService;
    this.popupRequestService = popupRequestService;
    this.$translate = $translate;
  }

  $onInit() {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();

    this.calendarEventsService
      .createDefaultCalendarEvent(this.eventDate, this.place)
      .then((event) => {
        this.event = event;
      });
  }

  saveCalendarEvent(newEvent) {
    const popupRequest = this.popupRequestService.show({
      progress: {
        title: this.$translate.instant('EVENT_CREATE_SAVE_PENDING'),
      },
      error: {
        title: this.$translate.instant('EVENT_SAVE_ERROR_TITLE'),
        desc: this.$translate.instant('EVENT_SAVE_ERROR_DESC'),
      },
    });
    const onRetry = () => this.saveCalendarEvent(newEvent);

    return this.calendarEventsService
      .saveCalendarEvent(newEvent)
      .then((createdEvent) => this.onCreate({ event: createdEvent }))
      .then(() => popupRequest.onSuccess())
      .then(() => this.onClose())
      .catch(() => popupRequest.onError(onRetry));
  }
}
