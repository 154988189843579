export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 4.7,
    method: updateMethod,
  });
}
// @ngInject
export function updateMethod(sqlStorageService) {
  const fields = [
    'id NVARCHAR(32) PRIMARY KEY UNIQUE',
    'assignee_id NVARCHAR(32)',
    'owner_id NVARCHAR(32)',
    'question_id NVARCHAR(32)',
    'form_id NVARCHAR(32)',
    'report_id NVARCHAR(32)',
    'place_id NVARCHAR(32)',
    'payload TEXT',
  ].join(',');
  const addTasksTableQuery = `CREATE TABLE tasks(${fields})`;

  return sqlStorageService.execute(addTasksTableQuery).catch(() => null);
}
