export function MissionsService(crudFactory, databaseSchema) {
  'ngInject';
  var tableConfig = databaseSchema.tables.missions;

  return crudFactory(tableConfig.table_name, {
    path_name: 'reports',
    take_care_of_user_profile: true,
    exclude_offline_params: ['states', 'type', 'user_id'],
    backup: {
      indexed_fields: tableConfig.indexed_fields.map((field) => field.name),
    },
  });
}
