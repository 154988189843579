const MODAL_BOTTOM_MAX_HEIGHT = 90;

export const ModalBottomComponent = {
  bindings: {
    onCloseClick: '&?',
  },
  transclude: true,
  templateUrl: 'components/Modals/modal-bottom.html',
  controller: function ModalBottomController($element, $document) {
    'ngInject';
    this.getModalStyle = () => {
      const heightValue = getHeightValue();

      return {
        height: `${heightValue}%`,
        'min-height': `${heightValue}%`,
      };
    };

    function getHeightValue() {
      const wrapperView = $document[0].querySelector('.wrapper-view') || {};
      const content = $element[0].querySelector('.sf_statusSelector_content');
      const contentHeight = content.clientHeight;
      const heightValue = (contentHeight * 100) / wrapperView.clientHeight;

      return MODAL_BOTTOM_MAX_HEIGHT < heightValue
        ? MODAL_BOTTOM_MAX_HEIGHT
        : heightValue;
    }
  },
};
