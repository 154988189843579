"use strict";

var _errors = _interopRequireDefault(require("../utils/errors"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var productsLibrary = {
  defaults: defaults,
  validate: validate
}; // One liner compilation to avoid lib functions scope pollution

productsLibrary.validateSchema = new (require('ajv'))().compile(require('./product.schema'));

function defaults() {
  var product = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultProduct = {};
  return _objectSpread({}, defaultProduct, {}, product);
}

function validate(product) {
  return _errors["default"].mapAjvErrors(productsLibrary.validateSchema(product) ? [] : productsLibrary.validateSchema.errors);
}

module.exports = productsLibrary;