const INFO_MESSAGE_DURATION = 3000;

export const MerchandisingDetailsComponent = {
  bindings: {
    validationId: '<',
    profile: '<',
  },
  templateUrl:
    'merchandising/views/merchandising-details/merchandising-details.html',
  // eslint-disable-next-line max-params
  controller: function MerchandisingDetailsController(
    $translate,
    $timeout,
    $state,
    merchandisingService,
    merchandisingApiService,
    merchandisingPopupService,
    merchandisingModalService,
    appMessageService,
    keyboardService,
    sfFeatureFlagsService,
    SF_FEATURE_FLAGS
  ) {
    'ngInject';
    this.$onInit = () => {
      this.isLoading = true;
      this.networkError = false;

      return merchandisingApiService
        .getValidationById(this.validationId)
        .then((validation) => {
          this.validation = validation;

          this.canBeValidated = merchandisingService.canBeValidated(
            this.profile,
            this.validation
          );
          this.canBePutInPending = merchandisingService.canBePutInPending(
            this.profile,
            this.validation
          );
          this.canBeRetook = merchandisingService.canBeRetook(
            this.profile,
            this.validation
          );
          this.canPostComment = merchandisingService.isPending(this.validation);
          this.hasDrawFeatureFlag = sfFeatureFlagsService.hasFeature(
            SF_FEATURE_FLAGS.MERCHANDISING_DRAW
          );

          this.hasFullWidthFooter = hasOneFooterAction(
            this.canBePutInPending,
            this.canBeRetook
          );
          this.pictureIds = merchandisingService
            .getValidations(this.validation)
            .map((answer) => answer.values[0].value);
          this.questionTitle = merchandisingService.getQuestionTitle(
            this.validation.contents.question_id,
            this.validation.form.contents.questions
          );

          return merchandisingApiService
            .getComments(this.validation._id)
            .then((comments) => {
              this.comments = comments;
            });
        })
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.reload = () => this.$onInit();

    this.sendComment = () => {
      this.pendingComment = true;

      return merchandisingApiService
        .sendComment(this.validation, this.profile, '', this.newComment)
        .then((newComment) => {
          this.comments.entries = this.comments.entries.concat(newComment);
          this.comments.users = augmentHash(this.comments.users, this.profile);
          this.newComment = '';
        })
        .catch(() => {
          this.errorMessage = $translate.instant(
            'MERCHANDISING_POST_MESSAGE_FAIL'
          );
          $timeout(() => {
            this.errorMessage = '';
          }, INFO_MESSAGE_DURATION);
        })
        .finally(() => {
          this.pendingComment = false;
        });

      function augmentHash(resourcesHash, resource) {
        return {
          ...resourcesHash,
          [resource._id]: resource.contents,
        };
      }
    };

    this.onSaveSuccess = () => {
      appMessageService.display(
        $translate.instant('MERCHANDISING_STATE_CHANGE_SUCCESS'),
        'success'
      );

      return $state.go('index.menu-more.merchandising.tabs');
    };

    this.onSaveFail = () => {
      appMessageService.display(
        $translate.instant('MERCHANDISING_STATE_CHANGE_FAIL'),
        'fail'
      );
    };

    this.validateAnswer = () => {
      this.stateChangePending = true;

      return merchandisingApiService
        .validateAnswer(this.validation, this.profile)
        .then(() => this.onSaveSuccess())
        .catch(() => this.onSaveFail())
        .finally(() => {
          this.stateChangePending = false;
        });
    };

    this.rejectAnswer = () => {
      this.stateChangePending = true;

      return merchandisingPopupService
        .showRejectLongPrompt()
        .promptPromise.then((comment) =>
          merchandisingApiService
            .rejectAnswer(this.validation, this.profile, comment)
            .then(() => this.onSaveSuccess())
            .catch(() => this.onSaveFail())
        )
        .finally(() => {
          this.stateChangePending = false;
        });
    };

    this.putAnswerInPending = () => {
      this.stateChangePending = true;

      return merchandisingApiService
        .putAnswerInPending(this.validation, this.profile)
        .then(() => this.onSaveSuccess())
        .catch(() => this.onSaveFail())
        .finally(() => {
          this.stateChangePending = false;
        });
    };

    this.retakeAnswerPhoto = () => {
      const retakePhotoModal =
        merchandisingModalService.openReplacerSelectorModal(
          this.validation,
          this.profile,
          () => this.onSaveSuccess(),
          () => {
            retakePhotoModal.remove();
          }
        );
    };

    this.drawOnPicture = () => {
      keyboardService.hide();

      return merchandisingModalService
        .openDrawSelectorModal(this.validation)
        .then((selectedAnswer) =>
          merchandisingModalService
            .openDrawOnPictureModal(
              this.validation,
              this.profile,
              selectedAnswer
            )
            .then(() => this.reload())
        )
        .catch(() => null);
    };

    function hasOneFooterAction(canBePutInPending, canBeRetook) {
      return (
        (canBePutInPending || canBeRetook) &&
        !(canBePutInPending && canBeRetook)
      );
    }
  },
};
