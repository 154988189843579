import type { SfFeatureFlags } from '@simplifield/feature-flags';
import {
  defaultTo,
  find,
  isEmpty,
  omit,
  path,
  pick,
  pipe,
  prop,
  propEq,
  reject,
} from 'ramda';
import { AnalyticsApiService } from '../analytics-api/analytics-api.service';
import { User } from '../../..';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import {
  APIStatsFilters,
  AnalyticsAPICount,
  APISeriesFilters,
  AnalyticsAPIResources,
  AnalyticsGroupByValue,
  APISeries,
} from '../../types/analytics';

const removeEmptyArrayFromObject = (
  object: Record<string, unknown[]>
): Record<string, unknown[]> => reject(isEmpty, object);

export class AnalyticsService {
  /* @ngInject */
  // eslint-disable-next-line max-params
  constructor(
    private analyticsApiService: AnalyticsApiService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private $translate: ng.translate.ITranslateService,
    private $q: ng.IQService
  ) {}

  hasFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.ANALYTICS
    );
  }

  hasAlphaFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.ANALYTICS_ALPHA
    );
  }

  getCount(
    entity: string,
    filters: APIStatsFilters
  ): ng.IPromise<AnalyticsAPICount> {
    return this.analyticsApiService
      .getCount(entity, this.formatFilters(filters))
      .then((apiCount) =>
        apiCount.meta.groupBy && apiCount.meta.groupBy !== filters.groupBy
          ? this.$q.reject({
              message: `Wrong groupBy parameter
      Expected: ${filters.groupBy}
      Received: ${apiCount.meta.groupBy}`,
            })
          : apiCount
      )
      .then((apiCount) =>
        apiCount.meta.groupBy ? this.resolveCountGroups(apiCount) : apiCount
      );
  }

  resolveCountGroups(
    apiCount: AnalyticsAPICount,
    formatted = false
  ): AnalyticsAPICount {
    const { meta: metadata, entry } = apiCount;

    if (!entry.groups) {
      return apiCount;
    }

    apiCount.entry.groups = entry.groups.map((group) => {
      const resolvedGroup = metadata.groupBy
        ? (find(
            propEq(group.group, '_id'),
            apiCount.meta[metadata.groupBy]
          ) as AnalyticsAPIResources)
        : (group.group as AnalyticsAPIResources);

      const formattedGroup = formatted
        ? this.formatResource(resolvedGroup, metadata.groupBy)
        : resolvedGroup;

      return {
        count: group.count,
        group: formattedGroup,
      };
    });

    return apiCount;
  }

  formatFilters(filters: APIStatsFilters): APIStatsFilters {
    return {
      ...omit(['forms_ids', 'teams_ids', 'stores_groups_ids'])(filters),
      // An empty array means no filter to apply
      ...removeEmptyArrayFromObject(
        pick(['forms_ids', 'teams_ids', 'stores_groups_ids'])(
          filters
        ) as Record<string, unknown[]>
      ),
    };
  }

  formatResource(
    resource: AnalyticsAPIResources | undefined,
    resourceType: AnalyticsGroupByValue | undefined
  ): string {
    const defaultToString = defaultTo(
      this.$translate.instant('ANALYTICS.DASHBOARDS.NO_RESOURCE')
    ) as () => string;
    const resourceMapper: Record<
      AnalyticsGroupByValue,
      (APIResource) => string
    > = {
      organisations: defaultToString,
      users: pipe(this.formatUser, defaultToString),
      forms: pipe(path(['contents', 'title']), defaultToString),
      stores: pipe(path(['contents', 'name']), defaultToString),
      storesGroups: pipe(path(['contents', 'name']), defaultToString),
      teams: pipe(path(['contents', 'name']), defaultToString),
    };

    if (resourceType && resourceMapper[resourceType]) {
      return prop(resourceType)(resourceMapper)(resource);
    }
    return resource ? resource._id : '';
  }

  formatUser(user: User): string {
    return `${user?.contents?.firstName?.substr(0, 1) ?? ''}. ${
      user?.contents?.lastName ?? ''
    }`;
  }

  getSeries(entity: string, filters: APISeriesFilters): ng.IPromise<APISeries> {
    return this.analyticsApiService.getSeries(
      entity,
      this.formatFilters(filters)
    );
  }
}
