import { PlaceTasksSectionController } from './place-tasks-section.controller';

export const PlaceTasksSectionComponent: ng.IComponentOptions = {
  bindings: {
    place: '<',
    profile: '<',
  },
  templateUrl:
    'places/place/components/place-tasks/place-tasks-section/place-tasks-section.html',
  controller: PlaceTasksSectionController,
};
