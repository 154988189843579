import { hasPath, path } from 'ramda';
import type { TSFixMe, User, ObjectId } from '../../../index';
import type { ChangeRequestPopupService } from '../../services/change-request-popup.service';
import type { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import type { FilesService } from '../../../services/API/files/files.service';

const changeRequestTypesTitleMap = {
  edit: 'CHANGE_REQUEST_MODAL_TITLE_EDIT',
  create: 'CHANGE_REQUEST_MODAL_TITLE_CREATE',
  remove: 'CHANGE_REQUEST_MODAL_TITLE_REMOVE',
};
const COMMENT_MAX_SIZE = 1000;

export class ChangeRequestItemModalController
  implements ng.IComponentController
{
  private changeRequest: TSFixMe;
  private originalEvent: TSFixMe;
  private requestedEvent: TSFixMe;
  private originalEventType: TSFixMe;
  private originalEventPlace: TSFixMe;
  private requestedEventType: TSFixMe;
  private requestedEventPlace: TSFixMe;
  private eventTypeTitle: string;
  private modalTitle: string;
  private originalDateFrameFormatted: TSFixMe;
  private originalTimezoneFormatted: string;
  private requestedDateFrameFormatted: string;
  private isCreation: () => boolean;
  private profile: User;
  private commentsCount: number;
  private commentField: string;
  private commentsMaxSize: number;
  private hasCommentsError: boolean;
  private isCommentsLoading: boolean;
  private isSending: boolean;
  private popupSending: boolean;
  private isAddCommentFieldDisplayed: boolean;
  private isEditCommentFieldDisplayed: boolean;
  private editableComment: TSFixMe;
  private editCommentContent: string;

  closeInputKeyboard: () => void;
  comments: TSFixMe[];
  commentsUser: Record<string, User>;
  keyboardModeType: string;
  onCommentsCountUpdate: (count: number) => void;
  onCancel: (arg: {
    $event: { changeRequestId: ObjectId };
  }) => ng.IPromise<void>;
  onClose: () => void;

  // eslint-disable-next-line max-params
  constructor(
    KEYBOARD_MODES: Record<string, string>,
    private dateFormatService,
    private changeRequestPopupService: ChangeRequestPopupService,
    private eventChangeRequestsService: TSFixMe,
    private $element: ng.IRootElementService,
    private $timeout: ng.ITimeoutService,
    private filesService: FilesService,
    private $q: ng.IQService,
    private objectIdService: ObjectIdService
  ) {
    'ngInject';
    this.commentsCount = 0;
    this.commentField = '';
    this.commentsMaxSize = COMMENT_MAX_SIZE;
    this.hasCommentsError = false;
    this.isCommentsLoading = false;
    this.isSending = false;
    this.popupSending = false;
    this.isAddCommentFieldDisplayed = false;
    this.isEditCommentFieldDisplayed = false;
    this.keyboardModeType = KEYBOARD_MODES.BODY;
  }

  $onInit(): ng.IPromise<void> {
    this.originalEvent = this.changeRequest.contents.originalEvent.contents;
    this.requestedEvent = this.changeRequest.contents.requestedEvent;
    this.originalEventType = this.changeRequest.originalEventType;
    this.originalEventPlace = this.changeRequest.originalEventPlace;
    this.requestedEventType = this.changeRequest.requestedEventType;
    this.requestedEventPlace = this.changeRequest.requestedEventPlace;
    this.eventTypeTitle = this.isCreation()
      ? this.requestedEventType.contents.title
      : this.originalEventType.contents.title;
    this.modalTitle =
      changeRequestTypesTitleMap[this.changeRequest.contents.action];

    if (hasPath(['timezone'], this.originalEvent)) {
      this.originalDateFrameFormatted = this.getDate(this.originalEvent);
      this.originalTimezoneFormatted = this.getTz(this.originalEvent);
    }

    if (hasPath(['timezone'], this.requestedEvent)) {
      this.requestedDateFrameFormatted = this.getDate(this.requestedEvent);
    }

    return this.getComments();
  }

  getComments(): ng.IPromise<void> {
    this.isCommentsLoading = true;
    this.hasCommentsError = false;
    this.comments = [];
    this.commentsUser = {};

    return this.eventChangeRequestsService.commentsService
      .list(this.changeRequest._id)
      .then(({ entries, users, count }) =>
        this.updateComments(entries, users, count)
      )
      .catch((err) => {
        this.hasCommentsError = true;

        throw err;
      })
      .finally(() => {
        this.isCommentsLoading = false;
      });
  }

  updateComments(comments, users, count): TSFixMe[] {
    this.comments = comments.concat(this.comments);
    this.commentsCount = this.commentsCount + count;
    const newCommentUsers = Object.keys(users).reduce((obj, userId) => {
      obj[userId] = {
        _id: userId,
        contents: users[userId],
      };
      return obj;
    }, {});

    this.commentsUser = {
      ...this.commentsUser,
      ...newCommentUsers,
    };
    return comments;
  }

  postComment(pictureId: ObjectId | null = null): ng.IPromise<void> {
    this.isAddCommentFieldDisplayed = false;
    const newComment = this.getNewComment(pictureId);

    this.isSending = true;

    return this.eventChangeRequestsService.commentsService
      .create(this.changeRequest._id, newComment)
      .then((comment) => {
        this.commentField = '';
        this.closeInputKeyboard();
        this.updateComments([comment], comment.users, 1);
        this.onCommentsCountUpdate(this.comments.length);
      })
      .catch((err) => {
        this.hasCommentsError = true;

        throw err;
      })
      .finally(() => {
        this.isSending = false;
      });
  }

  getNewComment(pictureId: ObjectId | null = null): TSFixMe {
    return this.eventChangeRequestsService.commentsService.buildComment(
      this.profile,
      pictureId ? '' : this.commentField,
      { changeRequest_id: this.changeRequest._id },
      pictureId
    );
  }

  postPictureComment(fileBlob): ng.IPromise<void> {
    this.isAddCommentFieldDisplayed = false;
    const pictureId = this.objectIdService.create();
    const cancelPromise = this.$q.defer<void>();
    const onSendCancel = () => {
      cancelPromise.resolve();
      popupSending.clear();
    };

    const popupSending =
      this.changeRequestPopupService.showPictureCommentSendingProgress(
        onSendCancel
      );

    return this.filesService
      .upload(fileBlob, pictureId, {
        canceler: cancelPromise,
      })
      .then(() => this.postComment(pictureId))
      .then(() => popupSending.onSuccess())
      .catch(() => popupSending.onError());
  }

  focusOnCommentInput(): void {
    this.$timeout(() => {
      const input: HTMLTextAreaElement | null = this.$element[0].querySelector(
        '#sf_footer_text_input__input_id'
      );

      if (input) {
        input.focus();
      }
    });
  }

  showAddCommentForm(): void {
    this.isAddCommentFieldDisplayed = true;
    this.focusOnCommentInput();
  }

  onDeleteCommentClick(commentId): ng.IPromise<TSFixMe> {
    const title = 'CHANGE_REQUEST_DELETE_COMMENT_CONFIRM_TITLE';
    const text = 'CHANGE_REQUEST_DELETE_COMMENT_CONFIRM_BUTTON';
    const errorTitle = 'CHANGE_REQUEST_DELETE_ERROR_TITLE';

    if (!this.comments.find((comment) => comment._id === commentId)) {
      return this.changeRequestPopupService.showError(errorTitle);
    }

    const oldComments = [...this.comments];
    const oldCommentsCount = this.commentsCount;

    return this.changeRequestPopupService
      .showDeleteConfirm(title, text)
      .then(() => {
        this.comments = this.comments.filter(
          (comment) => comment._id !== commentId
        );
        this.commentsCount = this.commentsCount - 1;

        return this.eventChangeRequestsService.commentsService
          .delete(this.changeRequest._id, commentId)
          .then(() => this.onCommentsCountUpdate(this.comments.length))
          .catch(() => {
            this.changeRequestPopupService.showError(errorTitle);
            this.comments = oldComments;
            this.commentsCount = oldCommentsCount;
          });
      });
  }

  onEditCommentClick(id: string) {
    this.isAddCommentFieldDisplayed = false;

    this.editableComment = this.comments.find((comment) => comment._id === id);

    if (!this.editableComment) {
      return this.changeRequestPopupService.showError(
        'CHANGE_REQUEST_COMMENT_EDIT_ERROR_TITLE'
      );
    }

    this.isEditCommentFieldDisplayed = true;
    this.editCommentContent =
      path<string>(['contents', 'content'])(this.editableComment) ?? '';

    return this.focusOnCommentInput();
  }

  updateComment() {
    if (!this.editableComment) {
      return this.changeRequestPopupService.showError(
        'CHANGE_REQUEST_COMMENT_EDIT_ERROR_TITLE'
      );
    }

    const index = this.comments.findIndex(
      ({ _id }) => _id === this.editableComment._id
    );

    if (index === -1) {
      return this.changeRequestPopupService.showError(
        'CHANGE_REQUEST_COMMENT_EDIT_ERROR_TITLE'
      );
    }

    this.editableComment.contents = {
      ...this.editableComment.contents,
      content: this.editCommentContent,
    };
    this.comments.splice(index, 1, { ...this.editableComment });
    this.isSending = true;

    return this.eventChangeRequestsService.commentsService
      .edit(this.changeRequest._id, this.editableComment)
      .then(() => this.closeInputKeyboard())
      .then(() => {
        this.editCommentContent = '';
        this.isEditCommentFieldDisplayed = false;
        this.editableComment = null;
      })
      .catch((err) => {
        this.hasCommentsError = true;

        throw err;
      })
      .finally(() => {
        this.isSending = false;
      });
  }

  getDate(event): string {
    return this.dateFormatService.getDateFrameFormatted(
      path(['start_dateTime'], event),
      path(['end_dateTime'], event),
      path(['timezone', 'name'], event)
    );
  }

  getTz(event): string {
    return this.dateFormatService.getTimezoneFormatted(
      path(['timezone', 'name'], event),
      path(['start_dateTime'], event)
    );
  }

  hideCommentInput(): void {
    this.isAddCommentFieldDisplayed = false;
    this.isEditCommentFieldDisplayed = false;
  }

  onCancelChangeRequest(changeRequest) {
    return this.onCancel({
      $event: { changeRequestId: changeRequest._id },
    }).then(() => this.onClose());
  }
}
