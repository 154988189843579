import { ObjectId, User } from '../../../..';
import { APIStore } from '@places/index';
import { Form, FormQuestion } from '@services/API/forms/forms';
import { Answer, Report } from '@services/API/reports/reports';
import {
  PubSubService,
  UnregisterFn,
} from '@services/Utils/PubSub/pubsub.service';
import { ReportQuestionsService } from '@services/Utils/ReportQuestions/report-questions.service';
import { Task } from '@tasks/index';
import { TaskService } from '@tasks/services/task.service';
import { FormTasksService } from '../../services/form-tasks.service';

export const FormQuestionTasksComponent: ng.IComponentOptions = {
  bindings: {
    question: '<',
    report: '<',
    form: '<',
    place: '<',
    profile: '<',
    answers: '<',
  },
  templateUrl:
    'missions/form/components/form-question-tasks/form-question-tasks.html',
  controller: class FormQuestionTasksController implements ng.IController {
    // bindings
    question: FormQuestion;
    report: Report;
    form: Form;
    place: APIStore;
    profile: User;
    answers: Answer[];

    // members
    tasks: Task[];
    pendingTasks: Task[];
    canHaveAutoTask = false;
    questionType: string;
    answersMatched = false;
    hasTasks = false;
    answerChangedListener: UnregisterFn;
    reportTasksChangeListener: UnregisterFn;
    isOffline = true;
    emitTaskChanged = false;

    constructor(
      private taskService: TaskService,
      private reportQuestionsService: ReturnType<typeof ReportQuestionsService>,
      private pubSubService: PubSubService,
      private $timeout: ng.ITimeoutService,
      private $scope: ng.IScope,
      private formTasksService: FormTasksService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.questionType = this.reportQuestionsService.getQuestionTypeName(
        this.question,
        this.form,
        this.report,
        { place: this.place }
      );
      this.canHaveAutoTask = this.taskService.isEligibleForAutotask(
        this.questionType
      );
      this.checkInitAnswer();
      this.resolveTasks();
      if (this.canHaveAutoTask) {
        this.answerChangedListener = this.pubSubService.subscribe(
          'QUESTION_ANSWER_CHANGED',
          (data: FormQuestion) =>
            this.$timeout(() => this.onAnswerChanged(data)) // timeout to allow bindings to update
        );
      }
      this.reportTasksChangeListener = this.pubSubService.subscribe(
        'REPORT_TASKS_CHANGED',
        () => this.$timeout(() => this.resolveTasks())
      );
    }

    $onDestroy(): void {
      this.answerChangedListener && this.answerChangedListener();
      this.reportTasksChangeListener && this.reportTasksChangeListener();
    }

    get filteredPendingTasks() {
      const taskNames = this.tasks.map(({ contents }: Task) => {
        return contents.name;
      });

      return this.pendingTasks.filter((task) => {
        return !taskNames.includes(task.contents.name);
      });
    }

    resolveTasks(): void {
      const { tasks, pendingTasks } = this.formTasksService.getQuestionTasks(
        this.question._id
      );

      this.tasks = tasks;
      this.pendingTasks = pendingTasks;
      this.hasTasks = Boolean(this.tasks?.length);
    }

    onSkipAutoTask(pendingTask: Task): void {
      this.formTasksService.skipAutotask(pendingTask._id, this.emitTaskChanged);
      this.resolveTasks();
    }

    onAutoTaskConfirm(task: Task): void {
      this.formTasksService.addAutoTask(task, this.emitTaskChanged);
      this.resolveTasks();
      this.$scope.$apply();
    }

    onDateChanged(date: Date, task: Task): void {
      task.contents.due_date = this.taskService.prepareTaskDate(date);
      this.updateTasks(task);
    }

    updateTasks(changedTask: Task): void {
      this.formTasksService.updateTasks(changedTask, this.emitTaskChanged);
      this.resolveTasks();
    }

    getQuestionPlannedAutoTasks(): Task[] {
      const { tasks } = this.formTasksService.getQuestionTasks(
        this.question._id
      );
      return tasks.filter(({ autoTask_id }) => !!autoTask_id);
    }

    removeAllQuestionAutoTasks(plannedAutoTasks: Task[]): void {
      [...plannedAutoTasks, ...this.pendingTasks].forEach((task) => {
        const { plannedTask, pendingTask } = this.findCreatedAutotask(
          task.autoTask_id as string,
          plannedAutoTasks
        );
        this.removeAnswerAutoTasks({ plannedTask, pendingTask });
      });
    }

    onAnswerChanged(data: FormQuestion): void {
      if (data._id !== this.question._id) {
        return;
      }
      const answers = this.answers?.filter(
        ({ question_id }) => question_id === data._id
      );

      const plannedAutoTasks = this.getQuestionPlannedAutoTasks();

      if (!answers?.length) {
        this.answersMatched = false;
        this.removeAllQuestionAutoTasks(plannedAutoTasks);
        return;
      }

      const autoTasks = this.question.metadata.autoTasks ?? [];

      this.answersMatched = autoTasks.reduce<boolean>((acc, autoTask) => {
        const matchedAnswers = answers.some(({ values }) => {
          const answerValue = values[0].value;
          return this.taskService.metAutotaskCondition(answerValue, autoTask);
        });

        const { plannedTask, pendingTask } = this.findCreatedAutotask(
          autoTask._id,
          plannedAutoTasks
        );

        if (!matchedAnswers) {
          this.removeAnswerAutoTasks({ plannedTask, pendingTask });
          return acc;
        }

        if (!plannedTask && !pendingTask) {
          this.formTasksService.createAutotask(
            this.form,
            this.report,
            this.question,
            this.place,
            autoTask
          );
        }

        return true;
      }, false);

      if (this.tasks?.length) {
        const promisses = this.tasks.map(({ _id }) =>
          this.removePlannedTask(_id)
        );

        Promise.all(promisses).then(() => {
          return this.resolveTasks();
        });
      } else {
        return this.resolveTasks();
      }
    }

    findCreatedAutotask(
      autoTask_id: ObjectId,
      plannedAutoTasks: Task[]
    ): Record<'plannedTask' | 'pendingTask', Task | undefined> {
      const plannedTask = plannedAutoTasks.find(
        (task) => task.autoTask_id === autoTask_id
      );

      const pendingTask = this.pendingTasks.find(
        (task) => task.autoTask_id === autoTask_id
      );
      return { plannedTask, pendingTask };
    }

    removeAnswerAutoTasks({
      plannedTask,
      pendingTask,
    }: Record<'plannedTask' | 'pendingTask', Task | undefined>): void {
      plannedTask && this.removePlannedTask(plannedTask._id);
      pendingTask && this.removePendingTask(pendingTask);

      this.resolveTasks();
    }

    removePendingTask(pendingTask: Task): void {
      this.formTasksService.removeAutotask(pendingTask, this.question._id);
    }

    removePlannedTask(id: ObjectId): void {
      this.formTasksService.deleteTask(id, this.emitTaskChanged);
    }

    checkInitAnswer(): void {
      const answers =
        this.answers?.filter(
          ({ question_id }) => question_id === this.question._id
        ) ?? [];
      if (!answers.length) {
        return;
      }

      const autoTasks = this.question.metadata.autoTasks ?? [];

      this.answersMatched = autoTasks.reduce<boolean>((acc, autoTask) => {
        const matchedAnswers = answers.some(({ values }) => {
          const answerValue = values[0].value;
          return this.taskService.metAutotaskCondition(answerValue, autoTask);
        });
        return matchedAnswers || acc;
      }, false);
    }

    onTaskAction(action: string, task: Task): void {
      switch (action) {
        case 'delete':
          this.onTaskDeleteAction(task._id);
          break;
        case 'edit':
          this.onTaskEditAction(task);
          break;

        default:
          break;
      }
    }

    onTaskDeleteAction(taskId: ObjectId): void {
      this.formTasksService.deleteTask(taskId, this.emitTaskChanged);
      this.resolveTasks();
    }

    onTaskEditAction(task: Task): void {
      const offlineMode = true;
      const context = {};

      this.taskService
        .openTaskManageModal(task, this.profile, context, offlineMode)
        .then(
          (task) => this.updateTasks(task.managedTask),
          () => {} // close without creation reject
        );
    }
  },
};
