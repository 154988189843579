import { ModalStepperComponent } from './modal-stepper.component';
import { StepCompilerDirective } from './step-compiler.directive';

export default angular
  .module(
    'sfMobile.components.modal-stepper',
    ['sfMobile.services.localization'],
    function ($compileProvider) {
      'ngInject';
      $compileProvider.directive('stepCompiler', StepCompilerDirective);
    }
  )
  .component('sfModalStepper', ModalStepperComponent).name;
