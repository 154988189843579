import { AppUpdate, AppUpdateInfo } from '@capawesome/capacitor-app-update';
import { PlatformService } from '../Platform';
import { PopupService } from '../Popup/popup.service';

const APP_UPGRADE_CHECK_DATE = 'app_upgrade_check_date';
const DAYS_BETWEEN_CHECKS = 5;

// have to copypaste from app-apdatee, since cjs import breaks the app in runtime
enum AppUpdateAvailability {
  UNKNOWN = 0,
  UPDATE_NOT_AVAILABLE = 1,
  UPDATE_AVAILABLE = 2,
  UPDATE_IN_PROGRESS = 3,
}

export class UpgradeService {
  AppUpdate = AppUpdate;

  constructor(
    private $q: ng.IQService,
    private localStorageService: ng.local.storage.ILocalStorageService,
    private platformService: PlatformService,
    private $log: ng.ILogService,
    private popupService: PopupService,
    private $translate: ng.translate.ITranslateService
  ) {
    'ngInject';
  }

  checkForUpgrade(): ng.IPromise<void> {
    if (this.platformService.isBrowser() || this.isCheckDisabled()) {
      return this.$q.resolve();
    }

    return this.$q
      .resolve(this.AppUpdate.getAppUpdateInfo())
      .then((info) => this.hasToBeUpgraded(info))
      .then((hasToBeUpgraded: boolean) => {
        if (!hasToBeUpgraded) {
          return;
        }
        this.localStorageService.set(
          APP_UPGRADE_CHECK_DATE,
          new Date().getTime()
        );

        const options = {
          title: this.$translate.instant('UPGRADE_TITLE'),
          description: this.$translate.instant('UPGRADE_DOWNLOAD_BUTTON'),
          iconName: 'rocket',
          hasCheckbox: false,
          buttonText: this.$translate.instant('UPGRADE_NOW_LINK'),
        };

        return this.popupService
          .showConfirm(options)
          .then(() => this.openAppStore());
      })
      .catch((e) => {
        this.$log.debug('upgrade info unavailable', e);
      });
  }

  private isCheckDisabled() {
    const daysAfterLastCheck = this.getDaysAfterLastCheck();
    return 0 < daysAfterLastCheck && daysAfterLastCheck < DAYS_BETWEEN_CHECKS;
  }

  private hasToBeUpgraded(info: AppUpdateInfo): boolean {
    return info.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
  }

  private getDaysAfterLastCheck(): number {
    const lastCheckTimeStamp = this.localStorageService.get(
      APP_UPGRADE_CHECK_DATE
    );
    if (!lastCheckTimeStamp) {
      return 0;
    }
    return (Date.now() - lastCheckTimeStamp) / (1000 * 3600 * 24);
  }

  private openAppStore(): ng.IPromise<void> {
    return this.$q.resolve(this.AppUpdate.openAppStore());
  }
}
