import { AssigneesModalController } from './assignees-modal.controller';

export const AssigneesModalComponent = {
  bindings: {
    assignees: '<',
    onClose: '&',
  },
  templateUrl:
    'calendar-events/components/calendar/assignees-modal/assignees-modal.html',
  controller: AssigneesModalController,
};
