import { GroupTaskGroupNameController } from './group-task-group-name.controller';

export const GroupTaskGroupNameComponent: ng.IComponentOptions = {
  bindings: {
    task: '<',
  },
  transclude: true,
  templateUrl:
    'tasks/components/group-task-group-name/group-task-group-name.html',
  controller: GroupTaskGroupNameController,
};
