import { CalendarEventsNotificationsService } from './calendar-events-notifications.service';

export default angular
  .module('sfMobile.services.calendar-events-notifications', [
    'sfMobile.services.local-notifications',
    'sfMobile.services.calendar-events',
    'sfMobile.services.dates',
  ])
  .factory(
    'calendarEventsNotificationsService',
    CalendarEventsNotificationsService
  ).name;
