/**
 *
 * This patches the global document and window event listener functions which are added by Capacitor. The Capacitor
 * functions throw an error if their first argument is `undefined` or `null`.
 * Patch from https://github.com/ionic-team/capacitor/issues/4178
 *
 * Cordova code that causes the bug
 * https://github.com/ionic-team/capacitor/blob/acb1da70f3da16cf3e76bd536c0aa39c1207b24c/core/cordova.js#L122-L158
 */
export function boxPreviewPatchCordova(): void {
  const cordovaDocumentAddEventListener = document.addEventListener;
  const cordovaDocumentRemoveEventListener = document.removeEventListener;
  const cordovaWindowAddEventListener = window.addEventListener;
  const cordovaWindowRemoveEventListener = window.removeEventListener;

  document.addEventListener = function (evt: any, handler: any, capture: any) {
    if (!evt) return;
    cordovaDocumentAddEventListener.call(document, evt, handler, capture);
  };

  window.addEventListener = function (evt: any, handler: any, capture: any) {
    if (!evt) return;
    cordovaWindowAddEventListener.call(window, evt, handler, capture);
  };

  document.removeEventListener = function (
    evt: any,
    handler: any,
    capture: any
  ) {
    if (!evt) return;
    cordovaDocumentRemoveEventListener.call(document, evt, handler, capture);
  };

  window.removeEventListener = function (evt: any, handler: any, capture: any) {
    if (!evt) return;
    cordovaWindowRemoveEventListener.call(window, evt, handler, capture);
  };
}
