"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('visit'),
    name: 'visit',
    description: 'Visit',
    upstanding: {
      valid: true
    },
    contents: {
      title: 'Visit',
      dayOff: false,
      requiredFields: ['place_id'],
      multipleAssignees: false,
      "default": true,
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('animation'),
    name: 'animation',
    description: 'Animation',
    upstanding: {
      valid: true
    },
    contents: {
      title: 'Animation',
      dayOff: false,
      requiredFields: [],
      multipleAssignees: true,
      "default": true,
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('multiple-required-fields'),
    name: 'multiple-required-fields',
    description: 'Multiple required fields',
    upstanding: {
      valid: true
    },
    contents: {
      title: 'Multiple required fields',
      dayOff: true,
      requiredFields: ['place_id', 'description'],
      multipleAssignees: false,
      "default": false,
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('calendar-event-type-1'),
    name: 'no-title',
    description: 'No title',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.title']
      }]
    },
    contents: {
      dayOff: false,
      requiredFields: [],
      multipleAssignees: true,
      "default": false,
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('calendar-event-type-2'),
    name: 'no-day-off',
    description: 'No dayOff',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.dayOff']
      }]
    },
    contents: {
      title: 'Title',
      requiredFields: [],
      multipleAssignees: true,
      "default": false,
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('calendar-event-type-3'),
    name: 'no-required-fields',
    description: 'No requiredFields',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.requiredFields']
      }]
    },
    contents: {
      title: 'Title',
      dayOff: false,
      multipleAssignees: true,
      "default": false,
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('calendar-event-type-4'),
    name: 'no-multiple-assignees',
    description: 'No multipleAssignee',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.multipleAssignees']
      }]
    },
    contents: {
      title: 'Title',
      dayOff: false,
      requiredFields: [],
      "default": false,
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('calendar-event-type-5'),
    name: 'no-organisation',
    description: 'No organisation',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.organisation_id']
      }]
    },
    contents: {
      title: 'title',
      dayOff: true,
      requiredFields: [],
      multipleAssignees: true,
      "default": false
    }
  }, {
    _id: createObjectId('calendar-event-type-6'),
    name: 'non-unique-required-field',
    description: 'Non unique required field',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['should NOT have duplicate items (items ## 0 and 2 are identical)']
      }]
    },
    contents: {
      title: 'Title',
      dayOff: false,
      requiredFields: ['place_id', 'description', 'place_id'],
      multipleAssignees: true,
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('calendar-event-type-7'),
    name: 'non-unique-required-field-1',
    description: 'Non string in requried fields',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['.requiredFields[1]', 'should be string']
      }]
    },
    contents: {
      title: 'Title',
      dayOff: false,
      requiredFields: ['string', {
        a: 'a'
      }, 'another string'],
      multipleAssignees: true,
      organisation_id: createObjectId('organisation')
    }
  }, {
    _id: createObjectId('calendar-event-type-8'),
    name: 'no-default',
    description: 'No default',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.default']
      }]
    },
    contents: {
      title: 'title',
      dayOff: true,
      requiredFields: [],
      multipleAssignees: true,
      organisation_id: createObjectId('organisation')
    }
  }];
};