import { PlaceCalendarEventsListController } from './place-calendar-events-list.controller';

export const PlaceCalendarEventsListComponent: ng.IComponentOptions = {
  bindings: {
    place: '<?',
  },
  templateUrl:
    'places/place/components/place-calendar-events/place-calendar-events-list/place-calendar-events-list.html',
  controller: PlaceCalendarEventsListController,
};
