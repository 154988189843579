"use strict";

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Calendar Event Change Request',
  type: 'object',
  additionalProperties: false,
  required: ['originalEvent', 'requestedEvent', 'state', 'action', 'organisation_id'],
  properties: {
    originalEvent: {
      type: 'object',
      additionalProperties: false,
      required: ['_id', 'contents'],
      properties: {
        _id: schemaUtils.oneToOne(),
        contents: {
          type: 'object'
        }
      }
    },
    requestedEvent: {
      type: 'object'
    },
    action: {
      type: 'string',
      "enum": ['create', 'edit', 'remove'],
      "default": 'edit'
    },
    state: {
      type: 'string',
      "enum": ['pending', 'accepted', 'rejected'],
      "default": 'pending'
    },
    requestComment: {
      type: 'string'
    },
    stateChangedBy_id: schemaUtils.oneToOne(),
    organisation_id: schemaUtils.oneToOne()
  }
};