import { ObjectId } from '../../..';
import {
  Dashboard,
  ExternalDashboardApiService,
} from '../../API/external-dashboard/external-dashboard-api.service';
import { LocalizationService } from '../Localization/localization.service';

export type DashboardLink = {
  createdDate?: string;
  id: string;
  name: Record<string, string>;
  description: string;
  isStandardDashboard?: boolean;
};

const CUMUL_LANGUAGES = [
  'en',
  'cs',
  'da',
  'de',
  'es',
  'fi',
  'fr',
  'he',
  'hu',
  'it',
  'ja',
  'ko',
  'mk',
  'nl',
  'pl',
  'pt',
  'ru',
  'sv',
  'zh_cn',
  'zh_tw',
] as const; // https://developer.cumul.io/?javascript#set-language

export type CumulDashboardParams = {
  dashboardId: string;
  apiKey: string;
  apiToken: string;
  containerId: string;
  language: typeof CUMUL_LANGUAGES[number];
};

export class ExternalDashboardService {
  supportedLanguages = CUMUL_LANGUAGES;
  /* @ngInject */
  constructor(
    private $q: ng.IQService,
    private externalDashboardApiService: ExternalDashboardApiService,
    private localizationService: LocalizationService
  ) {}

  init(): void {
    this.$q.resolve();
  }

  getDashboards(params = {}): ng.IPromise<DashboardLink[]> {
    return this.externalDashboardApiService
      .getDashboards(params)
      .then((dashboardsAPILinks) =>
        dashboardsAPILinks.map((dashboardAPILink) => {
          return {
            createdDate: dashboardAPILink.created_date,
            name: dashboardAPILink.contents.dashboard.name,
            description: dashboardAPILink.contents.dashboard.description,
            id: dashboardAPILink._id,
            isStandardDashboard:
              dashboardAPILink.is_standard_dashboard ?? false,
          };
        })
      );
  }

  getDashboard(dashboardId: ObjectId, params = {}): ng.IPromise<Dashboard> {
    return this.externalDashboardApiService.getDashboard(dashboardId, params);
  }

  getSupportedLang(): CumulDashboardParams['language'] {
    const deviceLang = this.localizationService.getDeviceLang();
    const appLanguage =
      this.localizationService.getPreferredLanguage(deviceLang);

    if (appLanguage === 'zh') {
      // Chinese lang diff https://www.programmersought.com/article/88442578855/
      const zhLang = this.localizationService.getFullDeviceLang();

      return zhLang === 'zh-TW' ? 'zh_tw' : 'zh_cn';
    }

    let dashboardLang: CumulDashboardParams['language'] = 'en';

    for (const lang of this.supportedLanguages) {
      if (lang === appLanguage) {
        dashboardLang = lang;
        return dashboardLang;
      }
    }

    return dashboardLang;
  }
}
