import { SegmentService, runSegmentPages } from './segment.service';
import { SEGMENT_EVENTS } from './segment.events';

export default angular
  .module('sfMobile.services.segment', [
    'sfMobile.config.server',
    'sfMobile.services.objectId',
    'sfMobile.services.firebase',
    'sfMobile.services.preferences',
    'LocalStorageModule',
    'simplifield.feature-flags',
    'sfMobile.services.pubsub',
    'sfMobile.services.trackingTransparency',
  ])
  .service('segmentService', SegmentService)
  .constant('SEGMENT_EVENTS', SEGMENT_EVENTS)
  .run(runSegmentPages).name;
