import { uuidv4 } from '../helpers/utils';
export const setEditMode = (dashboardComponent, iframe, editMode) => {
    if (iframe && iframe.contentWindow) {
        const requestId = uuidv4();
        if (dashboardComponent.params.editMode === editMode) {
            return Promise.reject(new Error('editMode parameter is currently already set to ' + editMode));
        }
        // Reject is setting editMode to a non-allowed value
        else if (editMode !== 'editFull'
            && editMode !== 'editLimited'
            && editMode !== 'view') {
            return Promise.reject(new Error('The editMode parameter must be one of the following values: \'editFull\', \'editLimited\' or \'view\'.'));
        }
        iframe.contentWindow.postMessage({
            action: 'setEditMode',
            editMode,
            _version: dashboardComponent.VERSION,
            requestId
        }, '*');
        return new Promise((resolve, reject) => {
            dashboardComponent.promises[requestId] = { resolve, reject };
        });
    }
    return Promise.reject(new Error('Cannot retrieve contentWindow.'));
};
