const MAX_DISPLAYED_CHOICE = 7;

export const QuestionCheckComponent = {
  bindings: {
    question: '<',
    hasError: '<',
    questionOptions: '<',
    questionChoices: '<',
    questionFree: '<',
    answers: '=',
    actionClickedFn: '=?',
    previousAnswers: '<',
    isDisabled: '<?',
    translations: '<',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-check/question-check.html',
  controller: function QuestionCheckController(
    modalService,
    keyboardService,
    FORM_QUESTION_ACTIONS
  ) {
    'ngInject';
    const isDefined = (value) => (!value && value !== 0 ? false : !!value);
    const getAnswerValue = (answer) => answer.values[0].value;
    const hasValue = (value, answers) =>
      answers.some((answer) => getAnswerValue(answer) === value);

    const getAnswers = (answers) => answers || [];
    const getAnswersNumber = (answers) => getAnswers(answers).length;

    const isValue = (value) => (answer) => value === getAnswerValue(answer);

    const formatFreeChoice = (choice) => {
      var answers = getAnswers(this.answers);

      return {
        checked: !!(answers.length && hasValue(choice, answers)),
        value: choice,
      };
    };

    this.$onInit = () => {
      this.MAX_DISPLAYED_CHOICE = MAX_DISPLAYED_CHOICE;
      this.choices = this.questionChoices.map(formatFreeChoice);
      this.isMaxChoicesReached =
        this.choices.length > this.MAX_DISPLAYED_CHOICE;

      if (this.questionFree) {
        // Get values that not in default choices.
        let otherValue = this.answers.filter(this.isNotInChoiceList);

        this.freeChoice = {
          checked: !!otherValue.length,
          value: otherValue.length ? otherValue[0].values[0].value : '',
        };
      }

      this.actionClickedFn = this.actionClicked;
    };

    this.$onChanges = () => {
      if (this.isMaxChoicesReached) {
        this.setFormQuestionValidity(
          this.question.minimum <= getAnswersNumber(this.answers)
        );
      }
    };

    this.onSelect = (value) => {
      const answerExist = this.answers.filter(isValue(value))[0];
      const values = this.getValues(value);

      keyboardService.hide();

      this.answers = answerExist
        ? this.sfQuestionForm.removeAnswer(answerExist._id, this.answers)
        : this.sfQuestionForm.addAnswer(values, this.answers);

      this.setFormQuestionValidity(
        this.question.minimum <= getAnswersNumber(this.answers)
      );
      return this.answers;
    };

    this.onFreeChange = (value) => {
      const answer = this.answers.filter(this.isNotInChoiceList)[0];
      const values = this.getValues(value);

      if (answer) {
        this.answers = this.sfQuestionForm.removeAnswer(
          answer._id,
          this.answers
        );
      }
      if (isDefined(value) && this.freeChoice.checked) {
        this.answers = this.sfQuestionForm.addAnswer(values, this.answers);
      }
      this.setFormQuestionValidity(
        this.question.minimum <= getAnswersNumber(this.answers)
      );
    };

    this.getValues = (value) => [
      {
        field_id: this.question.fields[0]._id,
        value,
      },
    ];

    this.hasInputRadioError = () =>
      this.freeChoice &&
      this.freeChoice.checked &&
      !isDefined(this.freeChoice.value)
        ? false
        : this.hasError;

    this.openChoicesSelectModal = () => {
      this.modal = null;
      const modalTemplate = `
        <sf-check-choices-modal
          on-close="$ctrl.onClose(choices, freeAnswer)"
          choices="$ctrl.choices"
          question="$ctrl.question"
          has-free-answer="$ctrl.hasFreeAnswer"
          free-choice="$ctrl.freeChoice"
          translations="$ctrl.translations">
        </sf-check-choices-modal>
      `;
      let modalBindings = {
        onClose: (choices, freeAnswer) =>
          this.onCloseModal(choices, freeAnswer),
        choices: this.choices,
        question: this.question,
        hasFreeAnswer: this.questionFree,
        freeChoice: this.freeChoice,
        translations: this.translations,
      };

      this.getQuestionForm().$setDirty();
      this.modal = modalService.open(modalTemplate, modalBindings, {
        hardwareBackButtonClose: false,
      });
      return this.modal;
    };

    this.resetAnswers = () => {
      this.choices.forEach((choice) => {
        const answer = this.answers.filter(isValue(choice.value))[0];

        if (answer && choice.checked) {
          this.answers = this.sfQuestionForm.removeAnswer(
            answer._id,
            this.answers
          );
        }

        choice.checked = false;
      });
      if (this.questionFree) {
        const answer = this.answers.filter(isValue(this.freeChoice.value))[0];

        if (answer && this.freeChoice.checked) {
          this.answers = this.sfQuestionForm.removeAnswer(
            answer._id,
            this.answers
          );
        }
        this.freeChoice = { checked: false, value: '' };
      }
      this.setFormQuestionValidity(this.question.minimum === 0);
    };

    this.synchronizeAnswers = (answers) => {
      this.choices.forEach((choice) => {
        const answerExist = this.answers.filter(isValue(choice.value))[0];
        const values = this.getValues(choice.value);

        if (answerExist && !choice.checked) {
          answers = this.sfQuestionForm.removeAnswer(answerExist._id, answers);
        }
        if (!answerExist && choice.checked) {
          answers = this.sfQuestionForm.addAnswer(values, answers);
        }
      });

      return answers;
    };

    this.setFormQuestionValidity = (isValid) => {
      const questionForm = this.getQuestionForm();

      if (!questionForm) {
        return false;
      }

      questionForm.$setValidity('required', isValid);
      return true;
    };

    this.onCloseModal = (choices, freeAnswer) => {
      this.modal.remove();
      if (choices) {
        this.choices = choices;
        this.answers = this.synchronizeAnswers(this.answers);
      }
      if (freeAnswer) {
        this.freeChoice = freeAnswer;
        this.onFreeChange(freeAnswer.value);
      }
      this.setFormQuestionValidity(
        this.question.minimum <= getAnswersNumber(this.answers)
      );
    };

    this.actionClicked = (action) => {
      switch (action) {
        case FORM_QUESTION_ACTIONS.RESET:
          return this.resetAnswers();
        case FORM_QUESTION_ACTIONS.EDIT:
          return this.openChoicesSelectModal();
        case FORM_QUESTION_ACTIONS.RESET_PREFILL:
          return this.resetToPrefillValue();
        default:
          return false;
      }
    };

    this.resetToPrefillValue = () => {
      this.resetAnswers();
      if (this.previousAnswers && this.previousAnswers.length) {
        this.previousAnswers.forEach((answer) => {
          const choice = this.choices.filter(
            (c) => c.value === answer.values[0].value
          )[0];

          if (choice) {
            choice.checked = true;
          } else {
            this.freeChoice = {
              checked: true,
              value: answer.values[0].value,
            };
          }
          this.answers = this.sfQuestionForm.addAnswer(
            answer.values,
            this.answers
          );
        });
      }
      this.setFormQuestionValidity(
        this.question.minimum <= getAnswersNumber(this.answers)
      );
    };

    this.getQuestionForm = () => this[`question_check_${this.question._id}`];

    this.isRequired = () =>
      this.questionOptions.required && !(this.answers || []).length;

    this.isNotInChoiceList = (answer) =>
      this.questionChoices.indexOf(getAnswerValue(answer)) === -1;
  },
};
