import { GeolocationService } from './geolocation.service';
import {
  GOOGLE_GEOCODE_URL,
  GEOLOCATION_PROPERTIES,
  GOOGLE_MAPS_SEARCH_URL,
} from './geolocation.constants';

export default angular
  .module('sfMobile.services.geolocation', [
    'sfMobile.services.places',
    'sfMobile.services.helpers',
  ])
  .service('geolocationService', GeolocationService)
  .constant('SF_GEOLOCATION_PROPERTIES', GEOLOCATION_PROPERTIES)
  .constant('GOOGLE_GEOCODE_URL', GOOGLE_GEOCODE_URL)
  .constant('GOOGLE_MAPS_SEARCH_URL', GOOGLE_MAPS_SEARCH_URL).name;
