import { APIStore } from '../../..';
import { ButtonSelectorOption } from '../../../../components/Buttons/button-selector/button-selector.controller';
import {
  CategorizedParam,
  PlacesParamsService,
} from '../../../../services/API/places-params/places-params.service';
import { PubSubService } from '../../../../services/Utils/PubSub/pubsub.service';

type DisplayedParam = CategorizedParam & { active: boolean };

export const PlaceParamsComponent = {
  bindings: {
    place: '<',
  },
  templateUrl: 'places/place/components/place-params/place-params.html',
  controller: class PlaceParamsController {
    searchField: string;
    place: APIStore;
    paramsDisplayed: DisplayedParam[];
    categorizedParams: CategorizedParam[];
    paramsCategories: ButtonSelectorOption[] = [];
    noSearchResult = true;
    isLoading = false;
    #unsubscribe: () => void;

    constructor(
      private $q: ng.IQService,
      private placesParamsService: PlacesParamsService,
      private searchUtilsService,
      private pubSubService: PubSubService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<void> {
      if (this.categorizedParams) {
        return this.$q.resolve();
      }
      this.#unsubscribe = this.pubSubService.subscribe<APIStore>(
        'PLACE_PARAMS_UPDATED',
        (data) => {
          this.place = data;
          this.buildParams();
        }
      );

      return this.buildParams();
    }

    $onDestroy(): void {
      this.#unsubscribe();
    }

    buildParams(): ng.IPromise<void> {
      this.isLoading = true;
      return this.$q
        .all([
          this.placesParamsService.listPlaceParamsKeys(),
          this.placesParamsService.loadCategories(),
        ])
        .then(([params, categories]) => {
          this.categorizedParams =
            this.placesParamsService.getCategorizedParams(
              params,
              categories,
              this.place.contents.params
            );
          this.showNonEmptyParams();
          this.isLoading = false;
        });
    }

    showNonEmptyParams(): void {
      this.paramsDisplayed = this.categorizedParams
        .map<DisplayedParam>((catParams) => {
          const nonEmptyParams = catParams.params.filter((p) => p.value !== ''); // in future value could be false so no Boolean check here
          return {
            active: true,
            label: catParams.label,
            params: nonEmptyParams,
          };
        })
        .filter((catParams) => catParams.params.length);
      this.assertNoParams();
    }

    assertNoParams(): void {
      if (!this.paramsDisplayed.length) {
        this.noSearchResult = true;
        return;
      }
      this.noSearchResult = true;
      for (const { params } of this.paramsDisplayed) {
        if (params.length) {
          this.noSearchResult = false;
          break;
        }
      }
    }

    toggleCollapse(index: number): void {
      this.paramsDisplayed[index].active = !this.paramsDisplayed[index].active;
    }

    searchParams(): void {
      const search = (this.searchField || '').toLowerCase();
      if (!search) {
        this.showNonEmptyParams();
        return;
      }
      const regStr =
        this.searchUtilsService.getRegexStringWithDiacritics(search);
      const reg = this.searchUtilsService.getSearchRegex(regStr);

      this.paramsDisplayed = this.categorizedParams
        .map(({ label, params }) => ({
          label,
          active: true,
          params: params
            .filter(({ value, name }) => reg.test(value) || reg.test(name))
            .filter(({ value }) => value !== ''),
        }))
        .filter(({ params }) => params.length !== 0);

      this.assertNoParams();
    }
  },
};
