export default function routeConfig($stateProvider) {
  'ngInject';

  return $stateProvider
    .state('index.menu-more.tasks', {
      abstract: true,
      url: '/tasks',
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('index.menu-more.tasks.list', {
      url: '',
      template: `
      <ion-view cache-view="false">
        <sf-view sf-tab-bar-hide="true">
          <sf-tasks
            profile="$ctrl.profile"
            class="sf_tasks">
          </sf-tasks>
        </sf-view>
      </ion-view>
      `,
      controller: TasksListsMainController,
      controllerAs: '$ctrl',
    })
    .state('index.menu-more.tasks.group', {
      url: '/group/:taskId',
      controller: GroupTasksDetailsMainController,
      controllerAs: '$ctrl',
      params: { groupTask: {} },
      template: `
        <ion-view>
          <sf-group-task-detail
            profile="$ctrl.profile"
          >
          </sf-group-task-detail>
        </ion-view>
      `,
    })
    .state('index.menu-more.tasks.details', {
      url: '/:taskId',
      controller: TaskDetailsMainController,
      controllerAs: '$ctrl',
      template: `
        <ion-view>
          <sf-task-details
            profile="$ctrl.profile"
            on-save="$ctrl.onSave()"
            on-close="$ctrl.onClose()"
          ></sf-task-details>
        </ion-view>
      `,
    });
}

// @ngInject
function TasksListsMainController(rsvpProfile) {
  this.profile = rsvpProfile;
}

// @ngInject
function GroupTasksDetailsMainController(rsvpProfile) {
  this.profile = rsvpProfile;
}

// @ngInject
function TaskDetailsMainController(rsvpProfile, routerService) {
  this.profile = rsvpProfile;
  this.onSave = () => (task) => {
    routerService.goBack();
    return task;
  };
  this.onClose = () => {
    routerService.goBack();
  };
}
