import { User, ObjectId } from '../../../..';
import {
  Report,
  ReportFormContents,
} from '../../../../services/API/reports/reports';
import { Mission } from '../../../../services/API/Missions/missions';
import { Form } from '../../../../services/API/forms/forms';
import { PlaceService } from '../../services/place.service';
import { PubSubService } from '../../../../services/Utils/PubSub/pubsub.service';
import { Campaign } from '../../../../services/API/campaigns/campaigns';
import { DateService } from '../../../../services/Utils/Dates/date.service';

type PlaceMissionsData = {
  reports: Report[];
  missions: Mission[];
  forms: Form[];
};

export class PlaceMissionsController {
  // bindings
  place: any;
  profile: User;

  // private props
  formListener: () => void;
  dataListener: () => void;
  formsHash: Record<ObjectId, Form[]>;
  missionsHash: Record<ObjectId, Mission[]>;
  campaigns: Campaign[] = [];
  backups: Report[] = [];
  missionsForms: Form[] = [];
  models: Form[] = [];
  loadingMissions = false;
  isBrowser: boolean;
  MAX_MISSIONS_IN_SECTION = 3;

  // eslint-disable-next-line max-params
  constructor(
    private pubSubService: PubSubService,
    private dateService: DateService,
    private helpersService,
    private formsService,
    private placeService: PlaceService,
    private platformService
  ) {
    'ngInject';
    this.formListener = this.pubSubService.subscribe(
      'FORM_REPORT_UPDATED',
      () => this.callData()
    );
    this.dataListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
      () => this.callData()
    );

    this.isBrowser = this.platformService.isBrowser();
  }

  $onInit(): ng.IPromise<void> {
    this.loadingMissions = true;

    return this.callData();
  }

  $onDestroy(): void {
    this.formListener();
    this.dataListener();
  }

  callData(): ng.IPromise<void> {
    return this.placeService
      .getPlaceMissionsData(this.place._id, this.profile._id)
      .then((data) => this.getFormsHashByIds(data))
      .then((data) => this.updateDatas(data))
      .finally(() => {
        this.loadingMissions = false;
      });
  }

  updateDatas({ reports, missions, forms }: PlaceMissionsData): void {
    const models = this.helpersService
      .getFormsAvailableOnPlace(forms, this.place._id, this.profile._id)
      .sort((a, b) => this.orderByDate(a, b));
    const missionsForms = this.helpersService
      .getAvailableFormsOfMissions(forms, missions, reports)
      .sort((a, b) => this.orderByDate(a, b));

    this.missionsHash = this.helpersService.getMissionsHashByFormId(missions);
    this.backups = reports;
    this.missionsForms = missionsForms;
    this.models = models;
  }

  hasMission(): boolean {
    return [this.backups, this.missionsForms, this.models].some(
      (list) => list && list.length
    );
  }
  getFormMissions(formId: ObjectId): Mission[] {
    return this.missionsHash[formId] || [];
  }

  getFormMissionsLength(formId: ObjectId): number {
    return this.getFormMissions(formId).length;
  }

  getFormsHashByIds(data: PlaceMissionsData): ng.IPromise<PlaceMissionsData> {
    const backups = data.reports;

    return this.formsService
      .getHashWithContentKey('form_id', backups)
      .then((hash) => {
        this.formsHash = hash;
        return data;
      });
  }
  getForm(report: Report): Form[] {
    return this.formsHash[(report.contents as ReportFormContents).form_id];
  }

  orderByDate(entityA: Form | Campaign, entityB: Form | Campaign): number {
    return this.dateService.compareDates(
      entityA.created_date,
      entityB.created_date
    );
  }

  shouldShowSection(entities?: Form[]): boolean {
    return typeof entities === 'undefined' || entities.length !== 0;
  }
}
