import { LocalizationService } from '../../../services/Utils/Localization/localization.service';

export class WarnBannerController {
  isRTLNeeded: boolean;

  constructor(private localizationService: LocalizationService) {
    'ngInject';
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
  }
}
