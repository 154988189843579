'use strict';

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Calendar Event Type',
  type: 'object',
  additionalProperties: false,
  required: ['title', 'dayOff', 'multipleAssignees', 'requiredFields', 'default', 'organisation_id'],
  properties: {
    title: {
      type: 'string'
    },
    dayOff: {
      type: 'boolean'
    },
    multipleAssignees: {
      type: 'boolean'
    },
    requiredFields: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'string'
      }
    },
    "default": {
      type: 'boolean'
    },
    organisation_id: schemaUtils.oneToOne()
  }
};