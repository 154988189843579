import { pathOr } from 'ramda';
import { TSFixMe } from '../../..';
import { FilterSelected } from '../../../components/Search/search';
import {
  PLACESLISTS_FILTER_KEY,
  STANDARD_FILTERS,
} from '../../../constants/place-filters.constant';
import { PlacesParamsService } from '../../../services/API/places-params/places-params.service';
import { LatLng } from '../../../services/Utils/Geolocation/geolocation.service';
import { SF_PLACE_SORTERS } from '../../places.config';

export const buildPlacesParams = (
  search?: string,
  filters?: FilterSelected[],
  placesParamsHash = {},
  placesListsNameHash = {},
  sorting?: typeof SF_PLACE_SORTERS[number],
  userPosition?: LatLng | null
): Record<string, TSFixMe> => {
  const paramFilters: FilterSelected[] = [];
  const nonParamsFilters: FilterSelected[] = [];

  filters?.forEach((filter) => {
    if (STANDARD_FILTERS.includes(filter.id)) {
      nonParamsFilters.push(filter);
    } else {
      paramFilters.push(filter);
    }
  });

  const standardFilters = nonParamsFilters.reduce((output, filter) => {
    if (filter.id === PLACESLISTS_FILTER_KEY) {
      const value = pathOr(
        [],
        [filter.value as string, 'contents', 'places_ids']
      )(placesListsNameHash);

      output.id = { value, operation: 'equality' };

      return output;
    }

    output[filter.id] = {
      value: filter.value as string,
      operation: 'regex',
    };

    return output;
  }, {} as Record<string, { value: string | string[]; operation: string }>);

  const searchParam = {
    ...(search ? { name: { value: search, operation: 'regex' } } : {}),
    ...standardFilters,
  };

  const customParamsFilters = PlacesParamsService.buildCustomParamFilters(
    paramFilters,
    placesParamsHash
  );

  const sortQueryParam =
    sorting?.key === 'place_distance' && userPosition
      ? { near: `${userPosition.lng},${userPosition.lat}` }
      : {};

  return {
    filters: searchParam,
    customParamsFilters: customParamsFilters,
    sort: sorting?.key,
    ...sortQueryParam,
  };
};
