import AccessRightsModule from './AccessRights/access-rights.module';
import ActionSheetModule from './ActionSheet/action-sheet.module';
import AddressFormatModule from './AddressFormat/address-format.module';
import AnalyticsFilterModule from './AnalyticsFilter/analytics-filter.module';
import ApiUtilsModule from './ApiUtils/api-utils.module';
import AppMessageModule from './AppMessage/app-message.module';
import AppSettingsModule from './AppSettings/app-settings.module';
import AppLauncherModule from './AppLauncher/app-launcher.module';
import AppStateModule from './AppState/app-state.module';
import AwakeModule from './Awake/awake.module';
import BarCodeModule from './BarCode/barcode.module';
import DataStoreModule from './DataStore/data-store.module';
import DatadogModule from './Datadog/datadog.module';
import { DebugModule } from './Debug/debug.module';
import CrudModule from './CRUD/crud.module';
import CameraModule from './Camera/camera.module';
import CampaignSelectorModule from './CampaignSelector/campaign-selector.module';
import CanvasEditionModule from './CanvasEdition/canvas-edition.module';
import ChartModule from './Chart/chart.module';
import ChatModule from './Chat/chat.module';
import ClipboardModule from './Clipboard/clipboard.module';
import ConfigModule from './Config/config.module';
import DataSourceAdapterModule from './DataSourceAdapter/data-source-adapter.module';
import DatesModule from './Dates/dates.module';
import DepartmentModule from './Department/department.module';
import DocumentViewerModule from './DocumentViewer/document-viewer.module';
import DocumentsDownloadModule from './DocumentsDownload/documents-download.module';
import ErrorMessagesModule from './ErrorMessages/error-messages.module';
import ExternalDashboardModule from './ExternalDashboard/external-dashboard.module';
import ExternalInjectionModule from './ExternalInjection/external-injection.module';
import UserExperienceModule from './Experience/user-experience.module';
import FileChooserModule from './FileChooser/file-chooser.module';
import FileSystemModule from './FileSystem/file-system.module';
import FirebaseModule from './Firebase/firebase.module';
import FormValidationsModule from './FormValidations/form-validations.module';
import GeolocationModule from './Geolocation/geolocation.module';
import GesturesModule from './Gestures/gestures.module';
import GoogleMapClusterModule from './GoogleMap/google-map-cluster.module';
import GoogleMapModule from './GoogleMap/google-map.module';
import HtmlModule from './HTML/html.module';
import HelpersModule from './Helpers/helpers.module';
import ImageModule from './Image/image.module';
import ImagePickerModule from './ImagePicker/image-picker.module';
import IntercomModule from './Intercom/intercom.module';
import { InAppBrowserModule } from './InAppBrowser/in-app-browser.module';
import KeyboardModule from './Keyboard/keyboard.module';
import LifecycleModule from './Lifecycle/lifecycle.module';
import LocalNotificationsModule from './LocalNotifications/local-notifications.module';
import MarketingModule from './Marketing/marketing.module';
import ModalModule from './Modal/modal.module';
import NetworkModule from './Network/network.module';
import ObjectidModule from './Objectid/objectId.module';
import RemoteStoresModule from './RemoteStores/remote-stores.module';
import PictureSystemModule from './PictureSystem/picture-system.module';
import PlacesSourceAdapter from './PlacesSourceAdapter/places-source-adapter.module';
import PlatformModule from './Platform/platform.module';
import PopupModule from './Popup/popup.module';
import PopupRequestModule from './Popup/popup-request.module';
import PushNotificationsModule from './PushNotifications/push-notifications.module';
import PubSubModule from './PubSub/pubsub.module';
import SatismeterModule from './Satismeter/satismeter.module';
import SentryModule from './Sentry/sentry.module';
import ShareModule from './Share/share.module';
import { SimpleStorageModule } from './SimpleStorage/simple-storage.module';
import TabsModule from './Tabs/tabs.module';
import ReportErrorsModule from './ReportErrors/report-errors.module';
import ReportQuestionsModule from './ReportQuestions/report-questions.module';
import ReportScoringModule from './ReportScoring/report-scoring.module';
import ReportSendingModule from './ReportsSending/report-sending.module';
import ReportValidationModule from './ReportValidation/report-validation.module';
import RequestsPaginateModule from './RequestsPaginate/requests-paginate.module';
import StaticRequestPaginateModule from './RequestsPaginate/static-request-paginate.module';
import NotificationsUtilsModule from './Notification/notifications-utils.module';
import RouterModule from './Router/router.module';
import SamlModule from './Saml/saml.module';
import SearchModule from './Search/search.module';
import ScreenOrientationModule from './ScreenOrientation/screen-orientation.module';
import SegmentModule from './Segment/segment.module';
import SynchronizeModule from './Synchronize/synchronize.module';
import ThemeModule from './Theme/theme.module';
import TrackingTransparencyModule from './TrackingTransparencyService/trackingTransparency.module';
import LocalizationModule from './Localization/localization.module';
import UserDevicesModule from './UserDevices/user-devices.module';
import UpgradeModule from './Upgrade/upgrade.module';
import WeekNavigationModule from './WeekNavigation/week-navigation.module';
import LogModule from './log/log.module';
import OauthBrowserModule from './oAuthBrowser/oauth-browser.module';
import ImageSourceModule from './ImageSource/image-source.module';
import ImageCropperModule from './ImageCropper/image-cropper.module';
import FileValidatorModule from './FileValidator/file-validator.module';
import DigitalAssetsModule from './DigitalAssets/digital-assets.module';
import DocumentLibraryModule from './DocumentLibrary/document-library.module';
import BoxInjectionModule from './BoxInjection/box-injection.module';
import sfTranslationModule from './sfTranslation/sf-translation.module';
import PrintModule from './Print/print.module';
import ForegroundNotificationModule from './ForegroundNotification/foregroundNotification.module';
import TranslateNumbersModule from './TranslateNumbers/translateNumbers.module';
import { FiltersModule } from './Filters/filters.module';
import RPNModule from './Rpn/rpn.module';

export default angular.module('sfMobile.services.utils', [
  AccessRightsModule,
  ActionSheetModule,
  AddressFormatModule,
  AnalyticsFilterModule,
  ApiUtilsModule,
  AppMessageModule,
  AppSettingsModule,
  AppStateModule,
  AppLauncherModule,
  AwakeModule,
  BarCodeModule,
  DataStoreModule,
  DatadogModule,
  DebugModule,
  CrudModule,
  CameraModule,
  CampaignSelectorModule,
  CanvasEditionModule,
  ChartModule,
  ChatModule,
  ClipboardModule,
  ConfigModule,
  DataSourceAdapterModule,
  DatesModule,
  DepartmentModule,
  DocumentViewerModule,
  DocumentsDownloadModule,
  ErrorMessagesModule,
  ExternalDashboardModule,
  ExternalInjectionModule,
  UserExperienceModule,
  FileChooserModule,
  FileSystemModule,
  FirebaseModule,
  FormValidationsModule,
  GeolocationModule,
  GesturesModule,
  GoogleMapClusterModule,
  GoogleMapModule,
  HtmlModule,
  HelpersModule,
  ImageModule,
  ImagePickerModule,
  IntercomModule,
  InAppBrowserModule,
  KeyboardModule,
  LifecycleModule,
  LocalNotificationsModule,
  MarketingModule,
  ModalModule,
  NetworkModule,
  ObjectidModule,
  RemoteStoresModule,
  PictureSystemModule,
  PlacesSourceAdapter,
  PlatformModule,
  PopupModule,
  PopupRequestModule,
  PushNotificationsModule,
  PubSubModule,
  SatismeterModule,
  SentryModule,
  ShareModule,
  SimpleStorageModule,
  TabsModule,
  ReportErrorsModule,
  ReportQuestionsModule,
  ReportScoringModule,
  ReportSendingModule,
  ReportValidationModule,
  RequestsPaginateModule,
  StaticRequestPaginateModule,
  RouterModule,
  SamlModule,
  SearchModule,
  ScreenOrientationModule,
  SegmentModule,
  SynchronizeModule,
  ThemeModule,
  TrackingTransparencyModule,
  LocalizationModule,
  UserDevicesModule,
  UpgradeModule,
  WeekNavigationModule,
  LogModule,
  OauthBrowserModule,
  ImageSourceModule,
  ImageCropperModule,
  FileValidatorModule,
  DigitalAssetsModule,
  DocumentLibraryModule,
  BoxInjectionModule,
  sfTranslationModule,
  PrintModule,
  ForegroundNotificationModule,
  TranslateNumbersModule,
  FiltersModule,
  RPNModule,
  NotificationsUtilsModule,
]).name;
