"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var schemaUtils = require('../utils/schema');

var schema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Missions Group',
  type: 'object',
  anyOf: [{
    needPlace: {
      type: 'boolean',
      "enum": [true]
    },
    useAssociations: {
      type: 'boolean'
    },
    places_ids: schemaUtils.oneToSeveral({
      minItems: 1
    })
  }, {
    needPlace: {
      type: 'boolean',
      "enum": [false]
    },
    useAssociations: {
      type: 'boolean',
      "enum": [false]
    },
    places_ids: schemaUtils.oneToSeveral()
  }].map(function (any) {
    return {
      additionalProperties: false,
      required: ['form_id', 'owner_id', 'organisation_id', 'params', 'type', 'brief', 'needPlace', 'useAssociations', 'places_ids', 'users_ids'],
      properties: _objectSpread({}, any, {
        reminder: {
          type: 'boolean',
          "default": false
        },
        reminderContents: {
          type: 'array',
          uniqueItems: true,
          items: {
            type: 'number',
            minimum: 0,
            maximum: 100
          }
        },
        start_date: {
          type: 'string',
          format: 'datetime'
        },
        end_date: {
          type: 'string',
          format: 'datetime'
        },
        form_id: schemaUtils.oneToOne(),
        owner_id: schemaUtils.oneToOne(),
        organisation_id: schemaUtils.oneToOne(),
        title: {
          type: 'string'
        },
        brief: {
          type: 'string'
        },
        type: {
          type: 'string',
          "enum": ['perpetual', 'ephemeral']
        },
        users_ids: schemaUtils.oneToSeveral({
          minItems: 1
        }),
        params: schemaUtils.params()
      })
    };
  })
};
module.exports = schema;