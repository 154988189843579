"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint no-magic-numbers:0*/
var schemaUtils = {
  formState: function formState() {
    return {
      type: 'string',
      "enum": ['draft', 'current', 'pending', 'ended'],
      "default": 'draft'
    };
  },
  sectionType: function sectionType() {
    return {
      description: 'Allow specific input behaviors (generic, screen...)',
      type: 'string',
      "enum": ['generic', 'screen'],
      "default": 'generic'
    };
  },
  questionType: function questionType() {
    return {
      description: 'Allow specific input behaviors (generic, file, unit, document...)',
      type: 'string',
      "enum": ['generic', 'video', 'audio', 'image', 'unit', 'checkbox', 'gps', 'signature', 'product-count', 'product-sales'],
      "default": 'generic'
    };
  },
  oneToSeveral: function oneToSeveral(base) {
    return _objectSpread({}, base, {
      type: 'array',
      uniqueItems: true,
      items: [schemaUtils.oneToOne()]
    });
  },
  oneToOne: function oneToOne(description) {
    return {
      description: description || '',
      type: 'string',
      pattern: '^([0-9a-f]{24})$',
      minLength: 24,
      maxLength: 24
    };
  },
  tags: function tags() {
    return {
      type: 'array',
      uniqueItems: true,
      items: [{
        type: 'string'
      }]
    };
  },
  param: function param() {
    return {
      type: 'object',
      additionalProperties: false,
      required: ['name', 'value'],
      properties: {
        name: {
          type: 'string'
        },
        value: {
          type: ['string', 'number', 'boolean']
        }
      }
    };
  },
  params: function params() {
    return {
      type: 'array',
      uniqueItems: true,
      items: [schemaUtils.param()]
    };
  },
  latLng: function latLng() {
    return {
      type: 'array',
      minItems: 2,
      maxItems: 2,
      additionalItems: false,
      items: [{
        type: 'number',
        minimum: -90,
        maximum: 90,
        "default": 0
      }, {
        type: 'number',
        minimum: -180,
        maximum: +180,
        "default": 0
      }]
    };
  },
  preferences: function preferences() {
    return {
      type: 'array',
      uniqueItems: true,
      items: [{
        title: 'Preferences',
        type: 'object',
        additionalProperties: false,
        required: ['name', 'value'],
        properties: {
          name: {
            type: 'string'
          },
          value: {
            type: ['string', 'number', 'boolean']
          }
        }
      }]
    };
  },
  rights: function rights() {
    return {
      type: 'array',
      uniqueItems: true,
      minItems: 1,
      items: [{
        type: 'object',
        additionalProperties: false,
        required: ['path', 'methods'],
        properties: {
          path: {
            type: 'string'
          },
          methods: {
            type: 'number'
          }
        }
      }]
    };
  },
  fieldValue: function fieldValue() {
    return {
      title: 'FieldValue',
      description: 'A value for a field',
      type: ['number', 'object', 'null', 'string', 'boolean']
    };
  },
  files: function schemaUtilsFils() {
    return {
      description: 'Files attached',
      type: 'array',
      items: [{
        type: 'object',
        additionalProperties: false,
        required: ['_id'],
        properties: {
          _id: schemaUtils.oneToOne(),
          name: {
            description: 'File name',
            type: 'string'
          },
          mime_type: {
            description: 'Mime type of file to fill in',
            type: 'string'
          },
          size: {
            description: 'Size of the file',
            type: 'number'
          }
        }
      }]
    };
  },
  goals: function goals() {
    return {
      description: 'Goals',
      type: 'array',
      items: [{
        type: 'object',
        additionalProperties: false,
        required: ['_id', 'value', 'start_date', 'recurrence'],
        properties: {
          _id: schemaUtils.oneToOne(),
          name: {
            type: 'string'
          },
          form_id: schemaUtils.oneToOne(),
          start_date: {
            type: 'string',
            format: 'date-time'
          },
          end_date: {
            type: 'string',
            format: 'date-time'
          },
          value: {
            type: 'integer'
          },
          catalogs_ids: schemaUtils.oneToSeveral(),
          recurrence: {
            type: 'string',
            "enum": ['yearly', 'monthly', 'weekly', 'daily']
          }
        }
      }]
    };
  },
  timezone: function timezone() {
    return {
      type: 'object',
      additionalProperties: false,
      required: ['name'],
      properties: {
        name: {
          type: 'string'
        }
      }
    };
  }
};
module.exports = schemaUtils;