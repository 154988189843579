import moment from 'moment';
import { clone, complement, isNil, path, pipe } from 'ramda';

const PLACE_FIELDS_SEARCH_FILTERS = [
  'pos_name',
  'pos_street',
  'pos_zipcode',
  'pos_city',
  'pos_country',
];
const USER_FIELDS_STATIC_SEARCH_FILTERS = [
  'contents.firstName',
  'contents.lastName',
];
const CAMPAIGN_FIELDS_STATIC_SEARCH_FILTERS = ['contents.name'];
const EVENTS_STATIC_SEARCH_FILTERS = ['contents.title'];
const hasRecurrence = pipe(
  path(['contents', 'recurrenceConfig', 'rule']),
  complement(isNil)
);

export class CalendarEventFormController {
  // eslint-disable-next-line max-params
  constructor(
    preferencesService,
    eventTypesService,
    usersService,
    $translate,
    formValidationsService,
    profileService,
    $q,
    placesSourceAdapterService,
    calendarEventsRecurrenceService,
    objectIdService,
    campaignsService,
    corporateEventsApiService,
    sfFeatureFlagsService,
    SF_FEATURE_FLAGS
  ) {
    'ngInject';
    this.$translate = $translate;
    this.moment = moment;
    this.preferencesService = preferencesService;
    this.eventTypesService = eventTypesService;
    this.usersService = usersService;
    this.formValidationsService = formValidationsService;
    this.profileService = profileService;
    this.$q = $q;
    this.objectIdService = objectIdService;
    this.campaignsService = campaignsService;
    this.getPlaceTitle = getPlaceTitle;
    this.getEventTypeTitle = getEventTypeTitle;
    this.getUserName = getUserName;
    this.getCampaignTitle = getCampaignTitle;
    this.getCorporateEventTitle = getCorporateEventTitle;
    this.searchPlaceFields = PLACE_FIELDS_SEARCH_FILTERS;
    this.searchStaticUserFields = USER_FIELDS_STATIC_SEARCH_FILTERS;
    this.searchStaticCampaignFields = CAMPAIGN_FIELDS_STATIC_SEARCH_FILTERS;
    this.searchStaticEventsFields = EVENTS_STATIC_SEARCH_FILTERS;
    this.entityProvider = placesSourceAdapterService.queryFromDataSource.bind(
      placesSourceAdapterService
    );
    this.calendarEventsRecurrenceService = calendarEventsRecurrenceService;
    this.corporateEventsApiService = corporateEventsApiService;

    this.places = [];
    this.eventTypes = [];
    this.users = [];

    this.eventContents = {};
    this.eventPlace = {};
    this.eventType = {};
    this.assignees = [];
    this.assignedCampaigns = [];
    this.corporateEvents = [];
    this.corporateEventsSelected = [];
    this.campaigns = [];

    this.isFullCalendar = sfFeatureFlagsService.hasFeature(
      SF_FEATURE_FLAGS.FULL_CALENDAR
    );
  }

  $onInit() {
    this.loadEventTypes();
    this.loadUsers();
    this.loadCampaigns();
    this.loadCorporateEvents();
    this.originalEvent = clone(this.event);
    this.event.contents.params = this.event.contents.params || [];
    this.eventContents = { ...this.event.contents };
    this.eventPlace = { ...this.event.place };
    this.eventType = { ...this.event.type };
    this.assignees = [...this.event.assignees];

    if (this.eventContents.checklists) {
      this.assignedCampaigns = this.eventContents.checklists.map(
        ({ _id, ...contents }) => ({
          _id,
          contents,
        })
      );
    }

    if (this.eventContents.corporateEvents) {
      this.corporateEventsSelected = this.eventContents.corporateEvents.map(
        ({ _id, ...contents }) => ({
          _id,
          contents,
        })
      );
    }

    this.isRecurrentEvent = Boolean(this.eventContents.recurrenceConfig);

    return this.preferencesService.getTimezone().then((userTimezone) => {
      this.userTimezone = userTimezone;
    });
  }

  loadUsers() {
    return this.$q
      .all([
        this.usersService.crud.listLocal({}),
        this.profileService.getProfile(),
      ])
      .catch(() => [])
      .then(([users = [], profile]) => {
        this.users = users.filter((user) =>
          this.usersService.isManagedUser(user, profile)
        );
      });
  }

  loadCampaigns() {
    return this.campaignsService.getCampaignsList().then((campaigns) => {
      this.campaigns = campaigns;
    });
  }

  loadCorporateEvents() {
    return this.corporateEventsApiService
      .getCorporateEventsList({
        filters: {
          $and: [{ corporate_event_end_date: { $gte: new Date() } }],
        },
      })
      .then((corporateEvents) => {
        this.corporateEvents = corporateEvents;
      });
  }

  loadEventTypes() {
    return this.eventTypesService
      .listLocal()
      .catch(() => [])
      .then((eventTypes) => {
        this.eventTypes = eventTypes;
      });
  }

  selectPlace(place) {
    this.eventPlace = place;
    this.eventContents.place_id = place._id;

    if (place.contents.timezone && place.contents.timezone.name) {
      this.eventContents.timezoneSource = 'inherited_from_place';
      this.eventContents.timezone.name = place.contents.timezone.name;
    } else {
      this.eventContents.timezone.name = this.userTimezone;
    }
  }

  updateParams(params) {
    this.eventContents.params = params;
    this.event.contents.params = params;
  }

  resetPlace() {
    this.eventPlace = {};
    delete this.eventContents.place_id;
    this.resetTimezoneSource();
  }

  selectEventType(eventType) {
    this.eventType = eventType;
    this.eventContents.calendarEventType_id = eventType._id;
  }

  resetEventType() {
    this.eventType = {};
    this.eventContents.calendarEventType_id = null;
  }

  selectAssignees(assignees) {
    this.assignees = [].concat(assignees);
    this.eventContents.assignees_ids = this.assignees.map((a) => a._id);
  }

  selectCampaigns(campaigns) {
    this.assignedCampaigns = [].concat(campaigns);
  }
  deleteCampaign(campaignId) {
    this.assignedCampaigns = this.assignedCampaigns.filter(
      (c) => c._id !== campaignId
    );
  }

  selectCorporateEvent(corporateEvent) {
    this.corporateEventsSelected = [].concat(corporateEvent);
  }

  deleteCorporateEvent(corporateEventId) {
    this.corporateEventsSelected = this.corporateEventsSelected.filter(
      (e) => e._id !== corporateEventId
    );
  }

  resetCorporateEvent() {
    this.corporateEventsSelected = [];
  }

  resetAssignees() {
    this.assignees = [];
    this.eventContents.assignees_ids = [];
  }

  resetCampaigns() {
    this.assignedCampaigns = [];
  }

  resetParams() {
    this.eventContents.params = [];
  }

  resetTimezoneSource() {
    this.eventContents.timezoneSource = 'manually_set';
  }

  mapSelectedCampaigns() {
    return this.assignedCampaigns.map((campaign) => campaign._id);
  }

  saveCalendarEvent() {
    return this.confirmRecurrence().then((editMode) => {
      this.pending = true;
      const event = {
        _id: this.event._id,
        contents: {
          ...this.eventContents,
        },
      };

      if (this.assignedCampaigns.length) {
        event.contents.checklists = this.mapSelectedCampaigns();
      }

      if (this.corporateEventsSelected.length) {
        event.contents.corporateEvents = this.corporateEventsSelected.map(
          ({ _id }) => _id
        );
      }

      if (
        !this.isRequired('assignees_ids') &&
        (!event.contents.assignees_ids ||
          event.contents.assignees_ids.length === 0)
      ) {
        event.contents.assignees_ids = [];
      }

      if (hasRecurrence(event)) {
        event.contents.recurrenceConfig = {
          recurrence_id: this.objectIdService.create(),
          ...event.contents.recurrenceConfig,
        };
      } else {
        delete event.contents.recurrenceConfig;
      }

      return this.onSaveButtonClick({ event, editMode }).then(() => {
        this.pending = false;
      });
    });
  }

  startDateTimezoneChange({ startDate, endDate, timezone }) {
    if (startDate) {
      this.eventContents.start_dateTime = startDate.toDate();
    }
    if (endDate) {
      this.eventContents.end_dateTime = endDate.toDate();
    }

    if (timezone) {
      this.eventContents.timezone.name = timezone;
      this.resetTimezoneSource();
    }

    this.hasError = !this.isValidDateRange();
    // during the initialization child component has't been rendered yet
    // so when this method is called for the 1st time the form doesn't have date range inputs
    if (this.eventContentsForm.sfbDateTimeRangeTimezone__startDate) {
      this.eventContentsForm.sfbDateTimeRangeTimezone__startDate.$setValidity(
        'invalid_date_range',
        !this.hasError
      );
    }
  }

  getError(fieldName) {
    return this.formValidationsService.getInputError(
      this.eventContentsForm,
      fieldName
    );
  }

  isValidDateRange() {
    return this.moment(this.eventContents.start_dateTime).isSameOrBefore(
      this.eventContents.end_dateTime
    );
  }

  isRequired(fieldName) {
    return (
      this.eventType.contents &&
      this.eventType.contents.requiredFields.includes(fieldName)
    );
  }
  getCampaignsValue() {
    const length = this.campaigns.length;

    if (!length) {
      return null;
    }

    if (length === 1) {
      return getCampaignTitle(this.campaigns[0]);
    }

    return this.$translate.instant('EVENT_SELECTED_ASSIGNEES_VALUE', {
      count: length,
    });
  }

  getAssigneesValue() {
    const length = this.assignees.length;

    if (!length) {
      return null;
    }

    if (length === 1) {
      return getUserName(this.assignees[0]);
    }

    return this.$translate.instant('EVENT_SELECTED_ASSIGNEES_VALUE', {
      count: length,
    });
  }

  confirmRecurrence() {
    const isEditMode = Boolean(this.event._id);

    return isEditMode && this.isRecurrentEvent
      ? this.calendarEventsRecurrenceService.displayEditRecurrentEventChoices(
          this.originalEvent.contents,
          this.eventContents
        )
      : this.$q.when();
  }
}

// helpers
function getPlaceTitle(place) {
  return place.contents.name;
}

function getEventTypeTitle(eventType) {
  return eventType.contents.title;
}

function getCampaignTitle(campaign) {
  return campaign.contents.name;
}

function getCorporateEventTitle(corporateEvent) {
  return [
    path(['contents', 'name'])(corporateEvent),
    path(['contents', 'eventType', 'label', 'en'])(corporateEvent),
  ]
    .filter(Boolean)
    .join(' • ');
}

function getUserName(user) {
  return user.contents.fullName
    ? user.contents.fullName
    : `${user.contents.firstName} ${user.contents.lastName}`;
}
