"use strict";

var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('placelist1'),
    name: 'placelist2',
    description: 'Sample list 1',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Sample list 1',
      places_ids: [createObjectId('place2')],
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee'),
      managers_ids: []
    }
  }, {
    _id: createObjectId('placelist2'),
    name: 'placelist2',
    description: 'Sample list 2',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Sample list 2',
      description: 'A list of places (1 & 2)',
      places_ids: [createObjectId('place1'), createObjectId('place2')],
      managers_ids: [],
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee')
    }
  }, {
    _id: createObjectId(),
    name: 'badplacelist',
    description: 'Bad list',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.name']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      description: 'A list of places (1 & 2)',
      places_ids: [createObjectId('place1'), createObjectId('place2')],
      organisation_id: createObjectId('abbacacaabbacacaabbacaca'),
      owner_id: createObjectId('bbbacacaabbacacaabbaceee'),
      managers_ids: []
    }
  }];
};