import EmptyModule from './empty/empty.module';
import EmptySearchModule from './empty-search/empty-search.module';
import InformationDisplayModule from './information-display/information-display.module';
import NetworkErrorModule from './network-error/network-error.module';

export default angular.module('sfMobile.components.scenes', [
  EmptyModule,
  EmptySearchModule,
  InformationDisplayModule,
  NetworkErrorModule,
]).name;
