import { StateService } from '@uirouter/angularjs';
import type { MomentInput } from 'moment';
import momentTZ, { Moment } from 'moment-timezone';
import scrollIntoView from 'scroll-into-view';
import { ObjectId } from '../../..';
import { CalendarEventsService } from '../../../services/API/calendar-events/calendar-events.service';
import { AccessRightsService } from '../../../services/Utils/AccessRights/access-rights.service';
import {
  ActionSheetService,
  IActionSheet,
} from '../../../services/Utils/ActionSheet/action-sheet.service';
import { DateService } from '../../../services/Utils/Dates/date.service';
import { ErrorMessagesService } from '../../../services/Utils/ErrorMessages/error-messages.service';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import { Modal, ModalService } from '../../../services/Utils/Modal';
import {
  PubSubService,
  UnregisterFn,
} from '../../../services/Utils/PubSub/pubsub.service';
import { CorporateEventsService } from '../../services/corporate-events/corporate-events.service';
import {
  CalendarEvent,
  CalendarEventsByDay,
  CorporateEvent,
} from '../../types';

export class CalendarEventsComponentController
  implements ng.IComponentController
{
  organisation;
  place;

  dataListener: UnregisterFn;
  organisationSwitchListener: UnregisterFn;

  momentTZ = momentTZ;
  date: Moment;
  timezone: string;
  translations: {
    cancel: string;
    goToPersonalCalendar: string;
    goToManagerCalendar: string;
  };
  events: CalendarEventsByDay[] = [];
  errorMessage: string;
  canRequestChange: boolean;
  isInNavBar = false;
  isLoading = false;
  isRTLNeeded: boolean;

  constructor(
    private localizationService: LocalizationService,
    private $state: StateService,
    private $translate: ng.translate.ITranslateService,
    private $q: ng.IQService,
    private calendarEventsService: CalendarEventsService,
    private readonly corporateEventsService: CorporateEventsService,
    private preferencesService,
    private dateService: DateService,
    private pubSubService: PubSubService,
    private modalService: ModalService,
    private calendarEventsNotificationsService,
    private errorMessagesService: ErrorMessagesService,
    private eventChangeRequestsService,
    private accessRightsService: AccessRightsService,
    private actionSheetService: ActionSheetService,
    private userExperienceService,
    private organisationsService,
    private $window: ng.IWindowService
  ) {
    'ngInject';
  }
  $onInit(): ng.IPromise<[void, void]> {
    this.dataListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
      () => this.loadCalendarEvents(false)
    );

    this.organisationSwitchListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.ORGANISATION_SWITCH,
      (data: { profile }) => {
        return this.organisationsService
          .getProfileOrganisation(data.profile)
          .then((organisation) => {
            this.organisation = organisation;
            this.setIsInNavBar();
          });
      }
    );
    this.translations = {
      cancel: this.$translate.instant('EVENT_CANCEL'),
      goToPersonalCalendar: this.$translate.instant('EVENT_NAVIGATION'),
      goToManagerCalendar: this.$translate.instant(
        'MANAGER_CALENDAR_NAVIGATION'
      ),
    };

    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.setSelectedDate(this.momentTZ());
    this.setIsInNavBar();

    return this.$q.all([
      this.eventChangeRequestsService
        .canRequestChange()
        .then((canRequestChange) => {
          this.canRequestChange = canRequestChange;
        }),
      this.preferencesService
        .getTimezone()
        .then((timezone) => {
          this.timezone = timezone;
          return this.loadCalendarEvents(true);
        })
        .finally(() => {
          if (!this.events.length && this.$state.params.nextEvents.length) {
            const nextEventDate =
              this.$state.params.nextEvents?.at(0)?.contents.start_date;

            this.date = this.dateService.toMoment(nextEventDate);
            this.onMonthChanged(this.date);
          }
        }),
    ]);
  }

  $onDestroy(): void {
    this.dataListener();
    this.organisationSwitchListener();
  }

  setIsInNavBar(): void {
    this.isInNavBar = this.userExperienceService
      .getNav(this.organisation)
      .find((nav) => nav.key === 'calendarEvents');
  }

  canAccessManagerCalendar(): boolean {
    return (
      this.calendarEventsService.hasManagerCalendarFeatureFlag() &&
      this.accessRightsService.isAtLeastManager()
    );
  }

  scrollTo(index: number): void {
    const key = 'calendarEventsContent';
    const id = `#mc-date-${index}`;

    setTimeout(() => {
      const elm = this.$window.document.querySelector(`[key="${key}"] ${id}`);

      scrollIntoView(elm, {
        time: 300,
        align: {
          top: 0,
        },
      });
    }, 100);
  }

  setSelectedDate(date: Moment): void {
    this.date = this.momentTZ(date);
  }

  onMonthChanged(momentDate: Moment): ng.IPromise<void> {
    const sameDayNewMonth = momentDate.set('date', this.date.get('date'));

    this.setSelectedDate(sameDayNewMonth);
    return this.loadCalendarEvents(true);
  }

  goToToday(): void {
    if (this.dateService.isToday(this.date)) {
      const todayIndex = this.calendarEventsService.closestToToday(this.events);

      return this.scrollTo(todayIndex);
    }

    this.setSelectedDate(this.momentTZ());
    this.loadCalendarEvents(true).then(() => {
      const todayIndex = this.calendarEventsService.closestToToday(this.events);

      this.scrollTo(todayIndex);
    });
  }

  goToPreviousMonth(): ng.IPromise<void> {
    this.setSelectedDate(this.date.subtract(1, 'month'));
    return this.loadCalendarEvents(true);
  }

  goToNextMonth(): ng.IPromise<void> {
    this.setSelectedDate(this.date.add(1, 'month'));
    return this.loadCalendarEvents(true);
  }

  setCalendarDaysWithData(
    individualEvents: CalendarEvent[],
    corporateEvents: CorporateEvent[]
  ): void {
    const momentDate = this.dateService.toMoment(this.date);
    const dateArray = Array.from(
      { length: momentDate.daysInMonth() },
      (_x, i) =>
        this.dateService.toDate(momentDate.startOf('month').add(i, 'days'))
    );
    this.events = this.calendarEventsService.groupAllEventsByDay(
      individualEvents,
      corporateEvents,
      dateArray
    );
  }

  loadCorporateEventsList(): ng.IPromise<CorporateEvent[]> {
    const { start: startDate, end: endDate } =
      this.dateService.getPeriodConstraints(this.date, 'month');

    return this.corporateEventsService
      .getPeriodCorporateEvents(startDate, endDate)
      .catch((error) => {
        this.errorMessagesService.display(error, {
          inlineErrorMessageUpdater: (message) => {
            this.errorMessage = message;
          },
        });
        return [];
      });
  }

  loadIndividualEvents(
    options = { online: false }
  ): ng.IPromise<CalendarEvent[]> {
    const { date, place } = this;
    const api = this.calendarEventsService.getPeriodCalendarEventsList(
      date as MomentInput,
      'month',
      place
    );

    return api
      .offline()
      .then((events) => {
        return options.online
          ? api.online().catch((e) => {
              this.errorMessagesService.display(e, {
                inlineErrorMessageUpdater: (message) => {
                  this.errorMessage = message;
                },
              });
              throw e;
            })
          : this.$q.when(events);
      })
      .catch((error) => {
        this.errorMessagesService.display(error, {
          inlineErrorMessageUpdater: (message) => {
            this.errorMessage = message;
          },
        });
        return [];
      });
  }

  onCalendarEventCreate(event: CalendarEvent): ng.IPromise<void> {
    this.calendarEventsNotificationsService.schedule(event);
    this.setSelectedDate(
      this.momentTZ(
        this.momentTZ
          .tz(event.contents.start_dateTime, event.contents.timezone.name)
          .toArray()
      )
    );

    return this.loadCalendarEvents(true);
  }

  onCalendarEventUpdate(event: CalendarEvent): ng.IPromise<void> {
    this.calendarEventsNotificationsService.unschedule(event);
    this.calendarEventsNotificationsService.schedule(event);

    return this.loadCalendarEvents(true);
  }

  onCalendarEventDelete(eventId: ObjectId): ng.IPromise<void> {
    this.calendarEventsNotificationsService.unschedule({ _id: eventId });

    return this.loadCalendarEvents(true);
  }

  onClickOpenCreateCalendarEventModal(): Modal {
    return this.canRequestChange
      ? this.openRequestEventCreationModal()
      : this.openAddCalendarEventModal();
  }

  openAddCalendarEventModal(): Modal {
    const template = `
      <sf-create-calendar-event-modal
        event-date="$ctrl.eventDate"
        on-create="$ctrl.onCalendarEventCreate(event)"
        on-close="$ctrl.onClose()">
      </sf-create-calendar-event-modal>
    `;

    return this.modalService.open(
      template,
      {
        eventDate: this.date,
        onCalendarEventCreate: (event) => this.onCalendarEventCreate(event),
      },
      { hardwareBackButtonClose: false }
    );
  }

  openRequestEventCreationModal(): Modal {
    const template = `
      <sf-request-event-creation-modal
        event-date="$ctrl.eventDate"
        on-close="$ctrl.onClose()">
      </sf-request-event-creation-modal>
    `;

    return this.modalService.open(
      template,
      {
        eventDate: this.date,
      },
      { hardwareBackButtonClose: false }
    );
  }

  loadCalendarEvents(online?: boolean): ng.IPromise<void> {
    const initialDate = this.momentTZ(this.date);
    this.isLoading = true;

    return this.$q
      .all([
        this.loadIndividualEvents({
          online:
            online ??
            !this.dateService.isSameWeek(initialDate, this.momentTZ(this.date)),
        }),
        this.loadCorporateEventsList(),
      ])
      .then(([individualEvents, corporateEvents]) => {
        return this.setCalendarDaysWithData(individualEvents, corporateEvents);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  goToManagerCalendar(): void {
    this.$state.go('index.calendar-events.manager-list');
  }

  selectCalendarMode(): IActionSheet {
    const actionSheetConfig = {
      cancelText: this.translations.cancel,
    };

    return this.actionSheetService.open(
      [
        {
          text: this.translations.goToPersonalCalendar,
          onClick: () => '',
        },
        {
          text: this.translations.goToManagerCalendar,
          onClick: () => this.goToManagerCalendar(),
        },
      ],
      actionSheetConfig
    );
  }
}
