import { ReportTasksComponent } from './report-tasks.component';

export default angular
  .module('sfMobile.components.report-tasks', [
    'sfMobile.services.tasks',
    'sfMobile.services.task',
    'sfMobile.components.section-loader',
    'sfMobile.components.taskItem',
    'sfMobile.services.pubsub',
    'sfMobile.services.form',
  ])
  .component('sfReportTasks', ReportTasksComponent).name;
