declare let angular: ng.IAngularStatic;

// Services
import { CategoriesApiFactory } from './services/categories-api/categories.api.factory';
import { CategoriesFactory } from './services/categories/categories.factory';

// Components
import { CategoriesModalComponent } from './components/categories-modal/categories-modal.component';
import { CategoriesNavComponent } from './components/categories-nav/categories-nav.component';
// Pages

export default angular
  .module('sfMobile.categories', [
    'pascalprecht.translate',
    'monospaced.elastic',
    'sfMobile.services.modal',
    'sfMobile.services.pov',
    'sfMobile.services.api-utils',
  ])
  .factory('categoriesFactory', CategoriesFactory)
  .service('categoriesApiFactory', CategoriesApiFactory)
  .component('sfCategoriesModal', CategoriesModalComponent)
  .component('sfCategoriesNav', CategoriesNavComponent).name;
