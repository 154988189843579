import {
  Auth0Client,
  BaseLoginOptions,
  GetTokenSilentlyVerboseResponse,
  IdToken,
  LogoutOptions,
  RedirectLoginResult,
  User,
} from '@auth0/auth0-spa-js';

export class Auth0Faker extends Auth0Client {
  protected _email: string;
  protected _orgId = '';
  protected token: string;
  protected apiUrl = 'http://localhost:8080';
  public lastRedirect: string;
  public fake = true;

  constructor(options?: { api?: string }) {
    super({ client_id: '', domain: '' });
    this.email = '';
    if (options?.api) {
      this.apiUrl = options.api;
    }
    console.debug('⚠️ Using fake auth0 client ⚠️');
  }

  get email(): string {
    // There is no login-as on mobile
    const email = this._email;
    const orgId = '' || this._orgId;

    if (!orgId) {
      return email;
    }
    return `${email}:${orgId}`;
  }

  set email(email: string) {
    console.log('Setting email to: ', email);
    this._email = email;
  }

  setOrg(orgId: string): void {
    this._orgId = orgId;
  }

  buildAuthorizeUrl({ redirect_uri }: BaseLoginOptions): Promise<string> {
    console.log('Building authorize url', redirect_uri);
    this.lastRedirect = redirect_uri;
    return Promise.resolve(redirect_uri);
  }

  buildLogoutUrl({ returnTo }: LogoutOptions): string {
    console.log('Building logout url', returnTo);
    if (returnTo) {
      this.lastRedirect = returnTo;
    }
    return returnTo || '';
  }

  checkSession(): Promise<void> {
    return Promise.resolve();
  }

  getIdTokenClaims(): Promise<IdToken> {
    return Promise.resolve({
      __raw: this.email,
      email: this.email,
    });
  }

  getTokenSilently(): Promise<GetTokenSilentlyVerboseResponse>;
  getTokenSilently(): Promise<string>;
  getTokenSilently():
    | Promise<GetTokenSilentlyVerboseResponse>
    | Promise<string> {
    console.log('Getting token silently');
    return Promise.resolve(btoa(this.email));
  }

  getTokenWithPopup(): Promise<string> {
    console.log('getTokenWithPopup');
    return Promise.resolve(btoa(this.email));
  }

  getUser<TUser extends User>(): Promise<TUser | undefined> {
    console.log('Getting user', {
      email: this._email,
      'http://url': this.apiUrl,
      'http://mail': this._email,
    });
    return Promise.resolve({
      email: this._email,
      'http://url': this.apiUrl,
      'http://mail': this._email,
    } as unknown as TUser);
  }

  handleRedirectCallback(): Promise<RedirectLoginResult> {
    return Promise.resolve({});
  }

  isAuthenticated(): Promise<boolean> {
    return Promise.resolve(this._email !== '');
  }

  loginWithPopup({ redirect_uri }: BaseLoginOptions): Promise<void> {
    this.lastRedirect = redirect_uri;
    return Promise.resolve();
  }

  loginWithRedirect({ redirect_uri }: BaseLoginOptions): Promise<void> {
    this.lastRedirect = redirect_uri;
    return Promise.resolve();
  }

  logout({ localOnly, returnTo }: LogoutOptions): Promise<void> | void {
    if (!localOnly && returnTo) {
      this.lastRedirect = returnTo;
    }
    this.email = '';
  }
}
