export function HelpersService(dateService, profileService, dateFormatService) {
  'ngInject';
  const methods = {
    addDividerDays,
    getFormsAvailable,
    getFormsAvailableOnPlace,
    getMissionsHashByFormId,
    getAvailableFormsOfMissions,
    getMissionsNotStarted,
    sortNatural,
    equalsIgnoringCase,
    profilesToString,
    sortByProperty,
    getSorterByGetter,
    getDueDateDisplayValue,
    getDueDateTimezonedHtml5,
  };

  function getDueDateTimezonedHtml5(profile, due_date) {
    const timezoned_date = profileService.transformUtcToUserTimezone(
      profile,
      due_date
    );

    return dateFormatService.getHtml5DateFormatted(timezoned_date);
  }

  function getDueDateDisplayValue(profile, due_date, toNowFormat = false) {
    const userTimezone = profileService.getTimezone(profile);
    const deviceTimezone = dateFormatService.getDeviceTz();

    const timezonedDate = profileService.transformUtcToUserTimezone(
      profile,
      due_date
    );

    const formatMethod = toNowFormat
      ? dateFormatService.getToNowDateFormatted
      : dateFormatService.getIntlDateFormatted;

    if (deviceTimezone !== userTimezone) {
      return formatMethod(new Date(due_date));
    }

    return formatMethod(timezonedDate);
  }

  function addDividerDays(entities, dateKey, descOrder) {
    return entities
      .sort((a, b) => {
        const fDateTime = a[dateKey] ? new Date(a[dateKey]).getTime() : 0;
        const sDateTime = b[dateKey] ? new Date(b[dateKey]).getTime() : null;

        return descOrder ? sDateTime - fDateTime : fDateTime - sDateTime;
      })
      .reduce((output, entity, index) => {
        const prevEntity = entities[index - 1];
        const entityDate = entity[dateKey];
        const prevEntityDate = prevEntity && prevEntity[dateKey];
        const isNewDate =
          !prevEntity || !dateService.isSameDate(entityDate, prevEntityDate);

        output = isNewDate ? output.concat(getDivider(entityDate)) : output;

        return output.concat(entity);
      }, []);

    function getDivider(date) {
      return {
        _id: new Date(date).getTime() || 0,
        isDivider: true,
        date: date,
      };
    }
  }

  function getFormsAvailable(forms, profileId) {
    return forms.filter((form) => form.contents.users_ids.includes(profileId));
  }
  function getFormsAvailableOnPlace(forms, placeId, profileId) {
    return forms.filter(
      (form) =>
        form.contents.needPlace &&
        form.contents.places_ids.includes(placeId) &&
        form.contents.users_ids.includes(profileId)
    );
  }
  function getMissionsHashByFormId(missions) {
    return missions.reduce((output, mission) => {
      const formId = mission.contents.form_id;
      const formMissions = output[formId] || [];

      output[formId] = formMissions.concat(mission);
      return output;
    }, {});
  }

  function getMissionsNotStarted(missions, reports) {
    const reportsMissionId = reports
      .filter((report) => report.contents.type === 'mission')
      .map((report) => report._id);
    const missionsNotStarted = missions.filter(
      (mission) => !reportsMissionId.includes(mission._id)
    );

    return missionsNotStarted;
  }

  function getAvailableFormsOfMissions(forms, missions, reports = []) {
    const formsId = forms.map((form) => form._id);
    const missionsNotStarted = getMissionsNotStarted(missions, reports);
    const missionsFormIds = missionsNotStarted
      .map((mission) => mission.contents.form_id)
      .filter(
        (missionFormId, index, arr) => index === arr.indexOf(missionFormId)
      )
      .filter((missionFormId) => formsId.indexOf(missionFormId) !== -1);
    const missionsForms = forms.reduce((output, form) => {
      const missionsFormIndex = missionsFormIds.indexOf(form._id);
      const missionExist = missionsFormIndex !== -1;

      if (missionExist) {
        output[missionsFormIndex] = form;
      }

      return output;
    }, []);

    return missionsForms;
  }

  /**
   * Compare the strings taking the number in count
   *
   * @param {String} as First string to compare
   * @param {String} bs Second string to compare
   * @return {Number} The comparaison length
   */
  // The eslint is disabled cause the code come from outside
  // eslint-disable-next-line complexity
  function sortNatural(as, bs) {
    const rx = /(\d+)|(\D+)/g;
    const rd = /\d+/;
    const getParts = (text) => String(text).toLowerCase().match(rx);
    const a = getParts(as);
    const b = getParts(bs);

    while (a.length && b.length) {
      let a1 = a.shift();
      let b1 = b.shift();

      if (rd.test(a1) || rd.test(b1)) {
        if (!rd.test(a1)) {
          return 1;
        }
        if (!rd.test(b1)) {
          return -1;
        }
        if (a1 !== b1) {
          return a1 - b1;
        }
      } else if (a1 !== b1) {
        return a1 > b1 ? 1 : -1;
      }
    }
    return a.length - b.length;
  }

  /* eslint-disable-next-line complexity */
  function profilesToString(profiles) {
    if (profiles.includes('root')) {
      return 'Root';
    }
    if (profiles.includes('admin')) {
      return 'Administrator';
    }
    if (profiles.includes('gallery')) {
      return 'Gallery';
    }
    if (profiles.includes('contentManager')) {
      return 'Content Manager';
    }
    if (profiles.includes('manager')) {
      return 'Manager';
    }
    if (profiles.includes('web')) {
      return 'Contributor';
    }
    if (profiles.includes('mobile')) {
      return 'Contributor';
    }
    return 'Unknown Profile';
  }

  return methods;
}

// see https://stackoverflow.com/questions/2140627/javascript-case-insensitive-string-comparison
function equalsIgnoringCase(string1, string2) {
  return (
    string1.localeCompare(string2, {}.undef, { sensitivity: 'base' }) === 0
  );
}

/**
 * To be used in sorting array of objects
 * Ex: sortByProperty(collection, 'sortProperty')
 * @param {Array} collection: the collection of datas
 * @param {String} property: the property to sort by (can also be accessor, e.g: contents.firstName)
 * @returns {Array} sorted collection of data
 */
function sortByProperty(collection, property) {
  return collection.sort((a, b) => {
    let item1String = property
      .split('.')
      .reduce((acc, part) => acc && acc[part], a);
    let item2String = property
      .split('.')
      .reduce((acc, part) => acc && acc[part], b);

    if (!item1String) {
      return 1;
    }

    return item1String.localeCompare(item2String, 'en', {
      sensitivity: 'base',
    });
  });
}

function getSorterByGetter(getter = (a) => a) {
  return (a, b) => {
    if (getter(a) < getter(b)) {
      return -1;
    }
    if (getter(a) > getter(b)) {
      return 1;
    }
    return 0;
  };
}
