import { FormQuestionComponent } from './form-question.component';

export default angular
  .module('sfMobile.component.question', [
    'sfMobile.services.reportQuestions',
    'sfMobile.components.question-fields',
    'sfMobile.components.field-error',
    'sfMobile.components.question-actions',
    'sfMobile.components.inappbrowseropener',
  ])
  .component('sfFormQuestion', FormQuestionComponent).name;
