import { GoogleMapService } from './google-map.service';
import { GoogleAddressService } from './google-address.service';

export default angular
  .module('sfMobile.services.google-map', [
    'sfMobile.services.file-system',
    'sfMobile.services.google-map-cluster',
    'sfMobile.config.server',
  ])
  .service('googleMapService', GoogleMapService)
  .service('googleAddressService', GoogleAddressService).name;
