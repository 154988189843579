import { Modal, ModalService } from '@services/Utils/Modal';
import { CalendarEvent, CorporateEvent } from 'app/calendar-events/types';
import { IAPIEventsList } from '../../../services/API/events/events';
import { EventsAPIService } from '../../../services/API/events/events.service';
import { ObjectId } from '../../../';
import { CalendarEventsModalService } from 'app/calendar-events/services/calendar-events-modal/calendar-events-modal.service';
export class NextEventsService {
  NEXT_EVENTS_LIMIT = 5;

  /* @ngInject */
  constructor(
    private calendarEventsModalService: CalendarEventsModalService,
    private calendarEventsNotificationsService,
    private readonly eventsAPIService: EventsAPIService,
    private readonly modalService: ModalService
  ) {}

  getNextEvents(type: string, placeId?: ObjectId): ng.IPromise<IAPIEventsList> {
    const todayDate = new Date().toISOString();

    const params = {
      todayDate,
      sorts: ['contents.start_date', 'contents.start_dateTime'],
      limit: this.NEXT_EVENTS_LIMIT,
      type,
      placeId,
      unlimitedUsers: true,
    };

    return this.eventsAPIService.getNextEvents(params);
  }

  openCorporateEventDetails(event: CorporateEvent): Modal {
    const eventDetailsTemplate = `
      <sf-corporate-event-details-modal
        event="$ctrl.event"      
        on-close="$ctrl.onClose()"
      </sf-corporate-event-details-modal>
    `;

    const detailsModal = this.modalService.open(eventDetailsTemplate, {
      event,
    });
    return detailsModal;
  }

  openIndividualEventDetails(
    event: CalendarEvent,
    callbacks: {
      onDelete: (eventId?: ObjectId) => void;
      onEdit: (event?: CalendarEvent) => void;
    }
  ): Modal {
    const eventDetailsTemplate = `
      <sf-details-event-modal
        event="$ctrl.event"      
        on-close="$ctrl.onClose()"
        on-delete="$ctrl.onDelete(event)"
        on-edit="$ctrl.onEdit(event)"
        on-request-changes="$ctrl.onRequestChanges()"
        on-request-deletion="$ctrl.onRequestDeletion()"
      >
      </sf-details-event-modal>
    `;

    const detailsModal = this.modalService.open(eventDetailsTemplate, {
      event,
      onDelete: (calendarEvent) =>
        this.deleteCalendarEvent(calendarEvent, callbacks.onDelete).then(() =>
          detailsModal.remove()
        ),
      onEdit: (calendarEvent) =>
        this.editCalendarEvent(calendarEvent, callbacks.onEdit).then(() => {
          detailsModal.remove();
        }),
      onRequestChanges: () =>
        this.requestChanges(event).then(() => detailsModal.remove()),
      onRequestDeletion: () =>
        this.requestDeletion(event).then(() => detailsModal.remove()),
    });
    return detailsModal;
  }

  deleteCalendarEvent(
    event: CalendarEvent,
    onDeleteCallback: (eventId: ObjectId) => void
  ): ng.IPromise<void> {
    return this.calendarEventsModalService.deleteCalendarEvent(
      event,
      ({ eventId }) => {
        this.calendarEventsNotificationsService.unschedule({ _id: eventId });

        return onDeleteCallback(eventId);
      }
    );
  }
  editCalendarEvent(
    event: CalendarEvent,
    onEditCallback: (event: CalendarEvent) => void
  ): ng.IPromise<Modal> {
    return this.calendarEventsModalService.editCalendarEvent(
      event,
      ({ event }) => {
        this.calendarEventsNotificationsService.unschedule(event);
        this.calendarEventsNotificationsService.schedule(event);

        return onEditCallback(event);
      }
    );
  }

  requestChanges(event: CalendarEvent): ng.IPromise<Modal> {
    return this.calendarEventsModalService.requestChanges(event);
  }

  requestDeletion(event: CalendarEvent): ng.IPromise<void> {
    return this.calendarEventsModalService.requestDeletion(event);
  }
}
