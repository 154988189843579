import { NewsfeedPostItemController } from './newsfeed-post-item.controller';

export const NewsfeedPostItemComponent = {
  bindings: {
    post: '<',
    authorType: '@',
    hideActions: '<?',
    disableClick: '<?',
    profile: '<',
    onEditPost: '&',
    onReload: '&',
    isChatLoading: '=',
  },
  templateUrl: 'newsfeed/components/newsfeed-post-item/newsfeed-post-item.html',
  controller: NewsfeedPostItemController,
};
