const EDIT_RECURRENT_EVENT_CHOICES = {
  ONE: 'one',
  ONE_AND_NEXT: 'onennext',
  ALL: 'all',
};

const DELETE_RECURRENT_EVENT_CHOICES = {
  ONE: 'one',
  ONE_AND_NEXT: 'onennext',
  ALL: 'all',
};

export default angular
  .module('sfMobile.constants.calendar-events-recurrence-choices', [])
  .constant('EDIT_RECURRENT_EVENT_CHOICES', EDIT_RECURRENT_EVENT_CHOICES)
  .constant('DELETE_RECURRENT_EVENT_CHOICES', DELETE_RECURRENT_EVENT_CHOICES)
  .name;
