import type { APIStore } from '../../..';
import type { AddressFormat } from '../../../../services/Utils/AddressFormat/address-format.service';

export const PlaceHeaderComponent: ng.IComponentOptions = {
  bindings: {
    place: '<',
  },
  templateUrl: 'places/place/components/place-header/place-header.html',
  controller: class PlaceHeaderController implements ng.IController {
    // bindings
    place: APIStore;
    placeAddress: string;

    constructor(
      private addressFormat: AddressFormat,
      private platformService,
      private $window: ng.IWindowService,
      private GOOGLE_MAPS_SEARCH_URL: string
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.placeAddress = this.addressFormat(this.place.contents);
    }

    openNavigation(): void {
      if (this.platformService.isBrowser()) {
        // Due to https://github.com/apache/cordova-plugin-inappbrowser/blob/master/src/browser/InAppBrowserProxy.js#L91
        // _system will not substitute original window.open. _blank - substitutes and launch iFrame anywhere except localhost
        this.$window.open(
          `${this.GOOGLE_MAPS_SEARCH_URL}${this.placeAddress}`,
          '_system'
        );
      } else {
        this.$window.location.href = this.getItinary(
          this.place,
          this.placeAddress
        );
      }
    }

    getItinary(place: APIStore, placeAddress: string): string {
      const latLngJoin = place.contents.latLng
        ? place.contents.latLng.join(',')
        : '';
      const isIos = this.platformService.isiOS();
      const mapAddress = isIos ? 'maps:' : `geo:${latLngJoin}?`;

      return `${mapAddress}q=${placeAddress}`;
    }
  },
};
