import { SalesDashboardFilterController } from './sales-dashboard-filter.controller';

export const SalesDashboardFilterComponent: ng.IComponentOptions = {
  bindings: {
    filters: '<',
    onFiltersChange: '<',
  },
  templateUrl:
    'analytics/components/sales-dashboard-filter/sales-dashboard-filter.html',
  controller: SalesDashboardFilterController,
};
