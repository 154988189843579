export const SortButtonComponent = {
  bindings: {
    value: '<',
    options: '<',
  },
  templateUrl: 'components/sort/sort-button/sort-button.html',
  controller: function SortButtonController() {
    'ngInject';
    const getValueLabel = (options, value) => {
      const curOption = options.filter((option) => option.key === value.key)[0];

      return curOption ? curOption.label : null;
    };

    this.getLabel = () =>
      this.value
        ? this.value.value
          ? this.value.value
          : getValueLabel(this.options, this.value)
        : null;
  },
};
