"use strict";

/* eslint-disable global-require */
var errorsLibrary = require('../utils/errors');

var lib = {
  validate: validate
}; // One liner compilation to avoid lib functions scope pollution

lib.validateSchema = new (require('ajv'))().compile(require('./calendarEventType.schema'));
module.exports = lib;

function validate(calendarEvenType) {
  return errorsLibrary.mapAjvErrors(lib.validateSchema(calendarEvenType) ? [] : lib.validateSchema.errors);
}