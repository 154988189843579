import calendarEventsRecurrenceChoicesConstants from './calendar-events-recurrence-choices.constants';
import ColorsConstantsModule from './colors.constants';
import CurrenciesConstantsModule from './currencies.constants';
import DatesFormatingConstantsModule from './dates-formating.constants';
import DepartmentTypesConstantsModule from './department-types.constant';
import ErrorCodesConstantsModule from './error-codes.constant';
import FeatureFlagsConstantsModule from './feature-flags.constant';
import { FilesUploadOptions } from './files-upload-options.constant';
import ImageSizesConstantsModule from './image-sizes.constant';
import MissionPreferencesConstantsModule from './mission-preferences.constant';
import NumbersScaleConstantsModule from './numbers-scale.constants';
import OrganisationPreferencesConstantsModule from './organisation-preferences.constant';
import QuestionProductConstantsModule from './question-product.constants';
import { TagLabelLength } from './tags.constant';
import UnitSystemConstantsModule from './unit-system.constants';
import UserExperienceConstantsModule from './user-experience.constant';

export default angular.module('sfMobile.constants', [
  ColorsConstantsModule,
  CurrenciesConstantsModule,
  DatesFormatingConstantsModule,
  DepartmentTypesConstantsModule,
  ErrorCodesConstantsModule,
  FeatureFlagsConstantsModule,
  ImageSizesConstantsModule,
  MissionPreferencesConstantsModule,
  NumbersScaleConstantsModule,
  OrganisationPreferencesConstantsModule,
  QuestionProductConstantsModule,
  UnitSystemConstantsModule,
  UserExperienceConstantsModule,
  FilesUploadOptions,
  calendarEventsRecurrenceChoicesConstants,
  TagLabelLength,
]).name;
