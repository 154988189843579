import { NotificationApiList, NotificationLastSeenInfo } from '../..';
import { PovService } from '../../../services/API/POV/pov.service';

export class NotificationsApiService {
  private baseUrl = '/notifications';

  constructor(
    private $http: ng.IHttpService,
    private sfPOVService: PovService
  ) {
    'ngInject';
  }

  list(params = {}): ng.IPromise<NotificationApiList> {
    return this.sfPOVService
      .pBuildURL(this.baseUrl)
      .then((url) => this.$http.get<NotificationApiList>(url, { params }))
      .then((res) => res.data);
  }

  getLastSeenNotificationsInfo(
    params = {}
  ): ng.IPromise<NotificationLastSeenInfo> {
    return this.sfPOVService
      .pBuildURL(this.baseUrl)
      .then((url) =>
        this.$http.get<NotificationLastSeenInfo>(`${url}/counts`, { params })
      )
      .then((res) => res.data);
  }
}
