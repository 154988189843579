import { openDB } from 'idb';
import { IndexedDBStore } from './indexeddb-store';
import { clearDataStores } from './indexeddb-utils';

const DATABASE_VERSION_KEY = 'database_version';

export class DataStoreService {
  // eslint-disable-next-line max-params
  constructor(
    $q,
    databaseConfig,
    databaseSchema,
    platformService,
    sqlStorageService,
    sqlQueryService,
    localStorageService
  ) {
    'ngInject';
    this.$q = $q;
    this.openDB = openDB;
    this.databaseConfig = databaseConfig;
    this.databaseSchema = databaseSchema;
    this.platformService = platformService;
    this.sqlStorageService = sqlStorageService;
    this.sqlQueryService = sqlQueryService;
    this.IndexedDBStore = IndexedDBStore;
    this.clearDataStores = clearDataStores;
    this.localStorageService = localStorageService;
  }

  init() {
    this.isBrowser = this.platformService.isBrowser();
    const dbName = this.databaseConfig.name;
    const dbVersion = this.databaseConfig.version;
    const dataStores = this.prepareDataStoreSchema();

    this.database = this.initDatabase(
      this.isBrowser,
      dbName,
      dbVersion,
      dataStores
    );
  }

  initDatabase(isBrowser, dbName, dbVersion, dataStores) {
    if (isBrowser) {
      return this.openDB(dbName, dbVersion, {
        // When the database version change
        upgrade(db) {
          // Delete all the db with the data.
          // The web version of the app doesn't store important data locally.
          // For this reason, we're ok to delete everything.
          // We could manage a migration script if needed.

          //Careful here, it's not an array of string, but a DomStringList
          const objectStoreNames = Array.from(db.objectStoreNames);

          objectStoreNames.forEach((objectStoreName) =>
            db.deleteObjectStore(objectStoreName)
          );

          // Re create the database.
          dataStores.forEach((dataStore) => {
            let currentStore = db.createObjectStore(dataStore.storeName);

            dataStore.indexes.forEach((el) =>
              currentStore.createIndex(el, 'contents.' + el)
            );
          });
        },
      });
    }
    return this.sqlStorageService.initTables.bind(this.sqlStorageService);
  }

  prepareDataStoreSchema() {
    return Object.entries(this.databaseSchema.tables).map(([name, schema]) => {
      let indexes = [];

      if (schema.indexed_fields) {
        indexes = schema.indexed_fields.map((field) => field.name);
      }
      return {
        storeName: name,
        indexes: indexes,
      };
    });
  }

  initDataStore(storeName, options) {
    if (!this.database) {
      this.init();
    }
    if (!this.isBrowser) {
      return new this.sqlQueryService(storeName, this.database, options);
    }
    return new IndexedDBStore(this.$q, storeName, this.database);
  }

  // On IndexedDB, we can't execute SQL statement,
  // so we're using the ability to delete by
  // index to mimic this comportment
  deleteByIndex(storeName, indexName, value) {
    const dataStore = this.initDataStore(storeName, {});

    if (!this.isBrowser) {
      return dataStore.execute(
        `DELETE FROM ${storeName} WHERE ${indexName}="${value}"`
      );
    }
    return dataStore.deleteByIndex(indexName, value);
  }

  resetDataStores() {
    if (this.isBrowser) {
      return this.clearDataStores(this.database, this.$q);
    }
    return this.sqlStorageService.deleteDatas.apply(this.sqlStorageService);
  }

  resetLocalDatabase(excludedTableNames = []) {
    if (this.isBrowser) {
      return this.clearDataStores(this.database, this.$q, excludedTableNames);
    }

    // Note: key usually set by angular-sql-storage, we have to manually reset it
    // as we bypass the normal flow of migrations scripts
    this.localStorageService.remove(DATABASE_VERSION_KEY);

    const tables = Object.keys(this.databaseSchema.tables)
      .map((key) => this.databaseSchema.tables[key].table_name)
      .filter((tableName) => !excludedTableNames.includes(tableName));
    const dropPromises = tables.reduce(
      (p, tableName) =>
        p.then(() =>
          this.sqlStorageService.execute('DROP TABLE IF EXISTS ' + tableName)
        ),
      this.$q.when()
    );

    return dropPromises
      .then(() =>
        this.sqlStorageService.createTables(
          this.databaseSchema.defaultFields,
          this.databaseSchema.tables
        )
      )
      .then(() =>
        this.localStorageService.set(
          DATABASE_VERSION_KEY,
          this.databaseConfig.version
        )
      );
  }
}
