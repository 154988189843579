import { PubSubService } from '@services/Utils/PubSub/pubsub.service';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { ImageService } from '../../../services/Utils/Image/image.service';
import type { ImageSourceService } from '../../../services/Utils/ImageSource/image-source.service';
import type { ModalService } from '../../../services/Utils/Modal';

export class ImagePreviewController implements ng.IComponentController {
  pictures: { id: string; url: string; name: string }[];
  firstPictureUrl: string;
  secondPictureUrl: string;
  showOverlay: boolean;
  overlayText: string;
  isCover: boolean;
  isWidget: boolean;

  constructor(
    private modalService: ModalService,
    private imageSourceService: ImageSourceService,
    private imageService: ImageService,
    private readonly SF_IMAGE_SIZES: typeof IMAGE_SIZES,
    private pubSubService: PubSubService
  ) {
    'ngInject';
  }

  $onInit(): void {
    if (this.pictures) {
      this.initComponent();
    }
  }

  $onChanges(changes: ng.IOnChangesObject): void {
    if (changes.pictures) {
      this.initComponent();
    }
  }

  private initComponent() {
    this.firstPictureUrl = this.pictures[0].url;
    this.secondPictureUrl = this.pictures[1] && this.pictures[1].url;
    this.showOverlay = this.pictures.length > 2;
    this.overlayText = '+' + (this.pictures.length - 1);
  }

  onImageLoaded = () => {
    if (this.isWidget) {
      this.pubSubService.publish('SLIDER_MEDIA_LOADED');
    }
  };

  onClick($event: Event): void {
    $event.stopPropagation();
    this.imageService
      .getSizedUrlsFromIds(
        this.pictures.map(({ id }) => id),
        this.SF_IMAGE_SIZES.SQUARE_BIG
      )
      .then((imagesUrl) => {
        const template = `
          <sf-images-viewer
            images="$ctrl.images"
            on-close="$ctrl.onClose()">
          </sf-images-viewer>
        `;
        const bindings = {
          images: this.pictures.map((img) =>
            this.imageSourceService.create(
              {
                ...img,
                url: imagesUrl[img.id],
              },
              false
            )
          ),
        };

        this.modalService.open(template, bindings);
      });
  }
}
