"use strict";

var schemaUtils = require('../utils/schema');

var eventsExchanges = require('../events/event.schema').anyOf.reduce(function (eventsExchangesAccumulator, schemaVariant) {
  return eventsExchangesAccumulator.concat(schemaVariant.properties.exchange["enum"]);
}, []);

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Hook',
  type: 'object',
  additionalProperties: false,
  required: ['name', 'url', 'method', 'headers', 'eventsExchanges', 'organisation_id', 'owner_id'],
  properties: {
    name: {
      type: 'string'
    },
    url: {
      type: 'string',
      format: 'uri'
    },
    method: {
      type: 'string',
      "enum": ['PUT', 'POST']
    },
    headers: {
      type: 'array',
      uniqueItems: true,
      items: [{
        type: 'object',
        additionalProperties: false,
        required: ['name', 'value'],
        properties: {
          name: {
            type: 'string'
          },
          value: {
            type: 'string'
          }
        }
      }]
    },
    expectedStatus: {
      type: 'number'
    },
    eventsExchanges: {
      type: 'array',
      uniqueItems: true,
      minItems: 1,
      items: {
        type: 'string',
        "enum": eventsExchanges
      }
    },
    organisation_id: schemaUtils.oneToOne(),
    owner_id: schemaUtils.oneToOne()
  }
};