export const MerchandisingCommentsComponent = {
  bindings: {
    pictureIds: '<',
    comments: '<',
  },
  templateUrl:
    'merchandising/components/merchandising-comments/merchandising-comments.html',
  controller: function MerchandisingCommentsController() {
    'ngInject';
    this.$onInit = () => {};
  },
};
