export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 4.6,
    method: updateMethod,
  });
}
// @ngInject
export function updateMethod($q, $log, sqlStorageService) {
  const fields = [
    'id NVARCHAR(32) PRIMARY KEY UNIQUE',
    'ean NVARCHAR(32)',
    'payload TEXT',
  ].join(',');
  const addProductsTableQuery = `CREATE TABLE products(${fields})`;

  return sqlStorageService.execute(addProductsTableQuery).catch(() => null);
}
