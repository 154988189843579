"use strict";

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Place',
  type: 'object',
  additionalProperties: false,
  required: ['name', 'street', 'city', 'country', 'params', 'tags', 'organisation_id', 'owner_id', 'users_ids', 'placesLists_ids', 'subscribers_ids'],
  properties: {
    name: {
      type: 'string'
    },
    street: {
      type: 'string'
    },
    zipcode: {
      type: 'string'
    },
    city: {
      type: 'string'
    },
    administrative_area: {
      type: 'string'
    },
    country: {
      type: 'string',
      "default": 'FR'
    },
    description: {
      type: 'string'
    },
    picture_id: schemaUtils.oneToOne(),
    params: schemaUtils.params(),
    tags: schemaUtils.tags(),
    timezone: schemaUtils.timezone(),
    latLng: schemaUtils.latLng(),
    organisation_id: schemaUtils.oneToOne(),
    owner_id: schemaUtils.oneToOne(),
    users_ids: schemaUtils.oneToSeveral(),
    placesLists_ids: schemaUtils.oneToSeveral(),
    subscribers_ids: schemaUtils.oneToSeveral(),
    goals: schemaUtils.goals(),
    documents_ids: schemaUtils.oneToSeveral()
  }
};