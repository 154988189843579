"use strict";

var _definitions = require("../definitions");

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Product',
  type: 'object',
  additionalProperties: false,
  required: ['name', 'ean', 'organisation_id', 'price', 'catalog_id'],
  properties: {
    brand: {
      type: 'string'
    },
    catalog_id: {
      $ref: '/#/definitions/objectId'
    },
    category: {
      type: 'string'
    },
    ean: {
      type: 'string'
    },
    internalId: {
      type: 'string'
    },
    name: {
      type: 'string'
    },
    organisation_id: {
      $ref: '/#/definitions/objectId'
    },
    price: {
      description: 'Price',
      type: 'object',
      additionalProperties: false,
      properties: {
        value: {
          type: 'number'
        },
        currency: {
          type: 'string'
        }
      }
    },
    size: {
      type: 'string'
    },
    tags: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'string'
      }
    },
    version: {
      type: 'string'
    }
  },
  definitions: {
    objectId: _definitions.objectId
  }
};