export const NewsfeedResourcesErrorsPopup: ng.IComponentOptions = {
  bindings: {
    title: '<',
    resourceErrors: '<',
    hideFileNames: '<',
    onClose: '&',
  },
  templateUrl:
    'newsfeed/components/newsfeed-resources-errors-popup/newsfeed-resources-errors-popup.html',
};
