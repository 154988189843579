export default function routeConfig($stateProvider, $urlRouterProvider) {
  'ngInject';
  $stateProvider.state('index', {
    abstract: true,
    url: '',
    template: '<sf-layout organisation="$ctrl.organisation"></sf-layout>',
    resolve: {
      checkLoginState: checkLoginState,
      rsvpProfile: getProfile,
      rsvpOrganisation: getOrganisation,
      initApp,
    },
    controllerAs: '$ctrl',
    controller: function LayoutController(
      rsvpOrganisation,
      rsvpProfile,
      departmentService
    ) {
      'ngInject';
      this.organisation = rsvpOrganisation;

      if (
        !departmentService.hasDepartment(rsvpProfile) &&
        departmentService.hasFeatureFlag()
      ) {
        departmentService.showModalDepartment({ profile: rsvpProfile });
      }
    },
  });

  $urlRouterProvider.otherwise('/activity');
}

function checkLoginState($state, $q, authService) {
  'ngInject';
  // Auth0 flow
  return authService.isAuthenticated().then((isAuth) => {
    if (!isAuth) {
      $state.go('login.page');
      return $q.reject();
    }
  });
}

function getProfile(profileService) {
  'ngInject';
  return profileService.getProfile().catch(() => {});
}

function getOrganisation(
  rsvpProfile,
  organisationsService,
  appStateService,
  authService
) {
  'ngInject';
  if (!rsvpProfile) {
    return null;
  }
  return organisationsService
    .getProfileOrganisation(rsvpProfile)
    .then((org) => {
      authService.registerAutomaticLogoutListener(org);
      return appStateService.initOrganisationServices(org);
    })
    .catch(() => null);
}

function initApp(rsvpProfile, rsvpOrganisation, appStateService) {
  'ngInject';

  if (!rsvpProfile || !rsvpOrganisation) {
    return null;
  }

  return appStateService.initProfileServices(rsvpProfile, rsvpOrganisation);
}
