export default function multiOrganisationRouteConfig($stateProvider) {
  'ngInject';

  // Routes.
  $stateProvider
    .state('index.menu-more.multi-organisation', {
      url: '/multi-organisation',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('index.menu-more.multi-organisation.list', {
      url: '/list',
      template: `
        <ion-view>
          <sf-multi-organisation
            organisation="$ctrl.organisation"
          ></sf-multi-organisation>
        </ion-view>
      `,
      controller: MultiOrganisationViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Multi organisation list',
    });

  // @ngInject
  function MultiOrganisationViewController(rsvpOrganisation) {
    this.organisation = rsvpOrganisation;
  }
}
