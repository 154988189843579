import { CustomRecurrenceModalController as controller } from './custom-recurrence.modal.controller';

export const CustomRecurrenceModal: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    onSave: '&',
    onClose: '&',
  },
  templateUrl:
    'calendar-events/components/recurrence-selector/modal/custom-recurrence.modal.html',
  controller,
};
