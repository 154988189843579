import { Severity } from '@sentry/types';
import { SentryService } from '@services/Utils/Sentry/sentry.service';

export function defaultErrorCatch(
  sentryService: SentryService,
  error: Error,
  version: number
): void {
  return sentryService.captureMessage(`MIGRATION_SCRIPT_FAIL_${version}`, {
    level: Severity.Error,
    extra: {
      error,
    },
    tags: {
      sendNotification: 'mail',
    },
  });
}
