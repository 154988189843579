export const ReportStatsComponent = {
  bindings: {
    form: '<',
    report: '<',
    place: '<',
  },
  templateUrl: 'reports/report/components/report-stats/report-stats.html',
  controller: function ReportStatsController($translate, contentService) {
    'ngInject';

    this.$onInit = () => {
      this.buttonSelectorOptions = [
        {
          id: 1,
          type: 'list',
          label: $translate.instant('REPORT_SCORING_TOGGLE_LIST_VIEW'),
        },
        {
          id: 2,
          type: 'graph',
          label: $translate.instant('REPORT_SCORING_TOGGLE_GRAPH_VIEW'),
        },
      ];
    };

    this.onGraphSelect = (option) => {
      this.activeGraphView = option;
      contentService.resizeById('reportStatsScroll');
    };
  },
};
