import { MerchandisingService } from './merchandising.service';

export default angular
  .module('sfMobile.services.merchandising-api', [
    'sfMobile.services.pov',
    'sfMobile.constants.merchandising',
    'sfMobile.services.api-utils',
    'sfMobile.services.segment',
    'sfMobile.services.pubsub',
  ])
  .factory('merchandisingApiService', MerchandisingService).name;
