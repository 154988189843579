const accentedA = '[a|à|á|ã|ä|å|ă|ą]';
const accentedE = '[e|è|é|ê|ě|ē|ĕ|ė|ę|ë]';
const accentedI = '[i|ì|í|î|ï|ĩ|ī|ĭ|į|ı]';
const accentedO = '[o|ò|ó|ô|õ|ö|ø|ō|ŏ|ő|œ]';
const accentedU = '[u|ù|û|ü|ũ|ū|ŭ|ů|ű|ų]';
const accentedC = '[c|ç|ć|ĉ|ċ|č]';
const accentedY = '[y|ý|ÿ]';

export const DIACRITICS_MAP = new Map([
  ['a', accentedA],
  ['à', accentedA],
  ['á', accentedA],
  ['ã', accentedA],
  ['ä', accentedA],
  ['å', accentedA],
  ['ă', accentedA],
  ['ą', accentedA],

  ['e', accentedE],
  ['è', accentedE],
  ['é', accentedE],
  ['ê', accentedE],
  ['ě', accentedE],
  ['ē', accentedE],
  ['ĕ', accentedE],
  ['ė', accentedE],
  ['ę', accentedE],
  ['ë', accentedE],

  ['i', accentedI],
  ['ì', accentedI],
  ['í', accentedI],
  ['î', accentedI],
  ['ï', accentedI],
  ['ĩ', accentedI],
  ['ī', accentedI],
  ['ĭ', accentedI],
  ['į', accentedI],
  ['ı', accentedI],

  ['o', accentedO],
  ['ò', accentedO],
  ['ó', accentedO],
  ['ô', accentedO],
  ['õ', accentedO],
  ['ö', accentedO],
  ['ø', accentedO],
  ['ō', accentedO],
  ['ŏ', accentedO],
  ['ő', accentedO],
  ['œ', accentedO],

  ['u', accentedU],
  ['ù', accentedU],
  ['û', accentedU],
  ['ü', accentedU],
  ['ũ', accentedU],
  ['ū', accentedU],
  ['ŭ', accentedU],
  ['ů', accentedU],
  ['ű', accentedU],
  ['ų', accentedU],

  ['c', accentedC],
  ['ç', accentedC],
  ['ć', accentedC],
  ['ĉ', accentedC],
  ['ċ', accentedC],
  ['č', accentedC],

  ['y', accentedY],
  ['ý', accentedY],
  ['ÿ', accentedY],
]);
