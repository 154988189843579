import {
  CompletionFilterType,
  DateRange,
  FilterType,
} from '../../../../constants/campaign-completion.constants';
import {
  CampaignCompletionTypes,
  Filter,
  FilterValue,
} from '../../types/campaign-completion';
import { IAPIResource } from '../../../..';
import { PlacesList } from '../../../../services/API/placesLists/placesLists';
import { UsersService } from '../../../../services/API/users/users.service';

const localStorageKey = 'CAMPAIGN_COMPLETION_LOCAL_STORAGE_FILTERS_FOR_';

export class CampaignCompletionFilterService {
  private SF_ALL_STATE = 'ALL';
  /* @ngInject */
  // eslint-disable-next-line max-params
  constructor(
    private $translate: ng.translate.ITranslateService,
    private $q: ng.IQService,
    private SF_COMPLETION_FILTER_TYPE: typeof CompletionFilterType,
    private SF_DATE_RANGE: typeof DateRange,
    private usersService: UsersService,
    private placesListsService,
    private localStorageService: ng.local.storage.ILocalStorageService
  ) {}

  generateFilters(
    resource: CampaignCompletionTypes,
    useNewCampaigns = true
  ): ng.IPromise<Filter[]> {
    const filters: Filter[] = [
      {
        id: this.SF_COMPLETION_FILTER_TYPE.no_report,
        label: this.$translate.instant(
          `CAMPAIGN_COMPLETION_NO_REPORT_FILTER_LABEL_${resource.toUpperCase()}${
            useNewCampaigns ? '_CHECKLISTS' : ''
          }`
        ),
        type: FilterType.toggle,
        initValue: false,
        defaultValue: false,
        values: [
          {
            id: 'false',
            label: this.$translate.instant(
              'CAMPAIGN_COMPLETION_NO_REPORT_FILTER_NO'
            ),
            value: false,
          },
          {
            id: 'true',
            label: this.$translate.instant(
              'CAMPAIGN_COMPLETION_NO_REPORT_FILTER_YES'
            ),
            value: true,
          },
        ],
      },
      {
        id: this.SF_COMPLETION_FILTER_TYPE.date_period,
        label: this.$translate.instant(
          'CAMPAIGN_COMPLETION_DATE_FILTER_LABEL_DATE_PERIOD'
        ),
        type: FilterType.dropdown,
        initValue: this.SF_DATE_RANGE.last_six_months,
        defaultValue: this.SF_DATE_RANGE.last_six_months,
        values: [
          {
            id: this.SF_DATE_RANGE.today,
            label: this.$translate.instant(
              'CAMPAIGN_COMPLETION_DATE_FILTER_TODAY'
            ),
            value: this.SF_DATE_RANGE.today,
          },
          {
            id: this.SF_DATE_RANGE.yesterday,
            label: this.$translate.instant(
              'CAMPAIGN_COMPLETION_DATE_FILTER_YESTERDAY'
            ),
            value: this.SF_DATE_RANGE.yesterday,
          },
          {
            id: this.SF_DATE_RANGE.week_to_date,
            label: this.$translate.instant(
              'CAMPAIGN_COMPLETION_DATE_FILTER_WEEK_TO_DATE'
            ),
            value: this.SF_DATE_RANGE.week_to_date,
          },
          {
            id: this.SF_DATE_RANGE.last_week,
            label: this.$translate.instant(
              'CAMPAIGN_COMPLETION_DATE_FILTER_LAST_WEEK'
            ),
            value: this.SF_DATE_RANGE.last_week,
          },
          {
            id: this.SF_DATE_RANGE.last_six_months,
            label: this.$translate.instant(
              'CAMPAIGN_COMPLETION_DATE_FILTER_LAST_SIX_MONTHS'
            ),
            value: this.SF_DATE_RANGE.last_six_months,
          },
        ],
      },
    ];

    if (resource === 'users') {
      return this.generateTeamFilter().then((teamFilter) => {
        filters.push({
          id: this.SF_COMPLETION_FILTER_TYPE.team,
          label: this.$translate.instant(
            'CAMPAIGN_COMPLETION_DATE_FILTER_LABEL_TEAM'
          ),
          type: FilterType.dropdown,
          initValue: null,
          defaultValue: null,
          values: [
            {
              id: this.SF_ALL_STATE,
              value: null,
              label: this.$translate.instant(
                'CAMPAIGN_COMPLETION_TEAM_FILTER_ALL'
              ),
            },
            ...teamFilter,
          ],
        });
        return filters;
      });
    }

    if (resource === 'stores') {
      return this.generateStoreGroupFilter().then((storeGroupFilter) => {
        filters.push({
          id: this.SF_COMPLETION_FILTER_TYPE.store_group,
          label: this.$translate.instant(
            'CAMPAIGN_COMPLETION_DATE_FILTER_LABEL_STORE_GROUP'
          ),
          type: FilterType.dropdown,
          initValue: null,
          defaultValue: null,
          values: [
            {
              id: this.SF_ALL_STATE,
              value: null,
              label: this.$translate.instant(
                'CAMPAIGN_COMPLETION_STORE_GROUP_FILTER_ALL'
              ),
            },
            ...storeGroupFilter,
          ],
        });
        return filters;
      });
    }

    return this.$q.resolve(filters);
  }

  generateTeamFilter(): ng.IPromise<FilterValue[]> {
    return this.usersService.getAllUsersGroups().then((res) => {
      return res.entries.map((team) => {
        return {
          id: team._id,
          value: team._id,
          label: team.contents.name,
        };
      });
    });
  }

  generateStoreGroupFilter(): ng.IPromise<FilterValue[]> {
    return this.placesListsService
      .apiList()
      .then((res: IAPIResource<PlacesList>[]) => {
        return res.map((placeGroup) => {
          return {
            id: placeGroup._id,
            value: placeGroup._id,
            label: placeGroup.contents.name,
          };
        });
      });
  }

  getFilterValues(
    resource: CampaignCompletionTypes,
    defaultFilterValue: FilterValue[],
    formId: string
  ): FilterValue[] {
    const isFilterValue = (filter: any): filter is FilterValue => {
      return (
        typeof filter.id == 'string' &&
        typeof filter.label === 'string' &&
        typeof filter.value !== 'undefined'
      );
    };
    const storedFilters = this.localStorageService.get(
      `${localStorageKey}${resource.toUpperCase()}_${formId}`
    );

    if (
      storedFilters &&
      Array.isArray(storedFilters) &&
      storedFilters.every((storedFilter) => isFilterValue(storedFilter))
    ) {
      return storedFilters as FilterValue[];
    }

    this.localStorageService.remove(
      `${localStorageKey}${resource.toUpperCase()}_${formId}`
    );

    return defaultFilterValue;
  }

  saveFilterValues(
    resource: CampaignCompletionTypes,
    filters: FilterValue[],
    formId: string
  ): void {
    this.localStorageService.set(
      `${localStorageKey}${resource.toUpperCase()}_${formId}`,
      filters
    );
  }
}
