import { PlatformService } from '../../../services/Utils/Platform';

export const DatePickerComponent: ng.IComponentOptions = {
  bindings: {
    isRequired: '<',
    isDisabled: '<',
    min: '<?',
    max: '<?',
    onChange: '&?',
    asDayBegin: '<?',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  templateUrl: 'components/Inputs/date-picker/date-picker.html',
  controller: class DatePickerController implements ng.IController {
    // bindings
    min: Date;
    max: Date;
    isRequired: boolean;
    isDisabled: boolean;
    onChange?: (arg: { value: string }) => void;

    // class fields
    modelCtrl: ng.INgModelController;
    dateString: string;
    showCustomIcon = true;
    asDayBegin: boolean;

    constructor(private platformService: PlatformService) {
      'ngInject';
    }

    $onInit(): void {
      this.modelCtrl.$render = () => {
        let modelValue = this.modelCtrl.$viewValue;

        if (modelValue) {
          modelValue = new Date(modelValue).toISOString().split('T')[0];
        }

        this.dateString = modelValue || null;
        const viewValue = this.dateString
          ? this.transformValue(this.dateString)
          : this.dateString;
        this.modelCtrl.$setViewValue(viewValue);
      };
      this.modelCtrl.$validators.minDate = this.minDateValidator;
      this.showCustomIcon =
        !this.platformService.isBrowser() || !this.platformService.isWebKit();
    }

    onDateChange({ $event }): void {
      const dateString = $event.detail; // YYYY-MM-DD string
      this.checkValidity(dateString);
      const viewValue = dateString
        ? this.transformValue(dateString)
        : dateString;
      this.modelCtrl.$setViewValue(viewValue);

      if (this.onChange) {
        this.onChange({ value: viewValue });
      }
    }

    private checkValidity(dateString: string): boolean {
      const date = new Date(dateString);
      if (!dateString || !(date instanceof Date) || isNaN(date.getTime())) {
        this.modelCtrl.$setValidity('date', false);
        return false;
      }
      this.modelCtrl.$setValidity('date', true);
      return true;
    }

    private transformValue(dateString: string): string {
      const value = new Date(dateString);
      // date created in a browser timezone, time is not passed and browser set it to 00:00:00
      // so date 2021-05-18T00:00 in -7 timezones become 2021-05-17T17:00
      // set the noon of the UTC day to make it the same Day in timezones to the west and to the east of GMT-0
      this.asDayBegin ? value.setUTCHours(0) : value.setUTCHours(12);
      value.setUTCMinutes(0);
      value.setUTCSeconds(0);

      return value.toISOString();
    }

    private minDateValidator = (modelValue, viewValue): boolean => {
      const value = modelValue || viewValue;

      return this.min ? new Date(value).getTime() > this.min.getTime() : true;
    };
  },
};
