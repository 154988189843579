import { PlacesParamsService } from './places-params.service';

export default angular
  .module('sfMobile.services.places-params', [
    'sfMobile.services.places',
    'sfMobile.services.pov',
    'sfMobile.services.crud',
    'sfMobile.config.database',
  ])
  .service('placesParamsService', PlacesParamsService).name;
