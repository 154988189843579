import ContextualGraphModule from './contextual-graph/contextual-graph.module';
import GraphScoringModule from './graph-scoring/graph-scoring.module';
import ReportScoringModule from './report-scoring/report-scoring.module';
import ScoringPrevStatsModule from './scoring-prev-stats/scoring-prev-stats.module';
import ScoringSectionsModule from './scoring-sections/scoring-sections.module';
import ScoringStatsModule from './scoring-stats/scoring-stats.module';

export default angular.module('sfMobile.components.scoring', [
  ContextualGraphModule,
  GraphScoringModule,
  ReportScoringModule,
  ScoringPrevStatsModule,
  ScoringSectionsModule,
  ScoringStatsModule,
]).name;
