import { ObjectId, User } from '../../..';
import { ApiUrlService } from '../apiUrl/api-url.service';

export class PovService {
  /* @ngInject */
  constructor(private profileService, private apiUrlService: ApiUrlService) {}

  pBuildURL(
    path: string,
    config = { pov: 'user' },
    overrideOrgId?: ObjectId
  ): ng.IPromise<string> {
    let url = this.apiUrlService.getApiUrl();

    return this.profileService.getProfile().then((profile) => {
      url =
        config.pov === 'organisation'
          ? `${url}/organisations/${
              overrideOrgId || profile.contents.organisation_id
            }`
          : `${url}${this.getUserUrl(profile)}`;

      return `${url}${path}`;
    });
  }

  pBuildURLByProfile(path: string): ng.IPromise<string> {
    const apiUrl = this.apiUrlService.getApiUrl();

    return this.profileService.getProfile().then((profile) => {
      const defaultPOVUrl = this.getUserUrl(profile);
      const povsUrl = [
        {
          type: 'admin',
          url: `/organisations/${profile.contents.organisation_id}`,
        },
        {
          type: 'manager',
          url: `/managers/${profile._id}`,
        },
        {
          type: 'user',
          url: defaultPOVUrl,
        },
      ];
      const availableProfiles: string[] = profile.contents.profiles;
      const userUrl = povsUrl.reduce(
        (url: string | null, povUrl: { url: string; type: string }) => {
          const isAuthorized = availableProfiles.indexOf(povUrl.type) !== -1;

          return !url ? (isAuthorized ? povUrl.url : url) : url;
        },
        null
      );

      return `${apiUrl}${userUrl || defaultPOVUrl}${path}`;
    });
  }

  getUserUrl(user: User): string {
    return `/users/${user._id}`;
  }
}
