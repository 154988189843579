export const CHANGE_REQUESTS_STATES = {
  PENDING: 'pending',
  VALIDATED: 'accepted',
  REJECTED: 'rejected',
};

type translation = {
  keyword: string;
  i18nKey: string;
};
export type ChangeRequestActions = Record<string, translation>;
export const CHANGE_REQUESTS_ACTIONS: ChangeRequestActions = {
  EDIT: {
    keyword: 'edit',
    i18nKey: '',
  },
  CREATE: {
    keyword: 'create',
    i18nKey: 'CHANGE_REQUEST_ITEM_ACTION_CREATION',
  },
  REMOVE: {
    keyword: 'remove',
    i18nKey: 'CHANGE_REQUEST_ITEM_ACTION_DELETION',
  },
};
