const NB_HOURS_TO_REFRESH_PROFILE = 12;

const PROFILE_PREFERENCE_TIMEZONE_NAME = 'settings.timeZone';
const DEFAULT_TIMEZONE = 'UTC';

// eslint-disable-next-line max-params
export function ProfileService(
  $q,
  $http,
  dateService,
  localStorageService,
  apiUrlService,
  pingService,
  dataStoreService
) {
  'ngInject';

  const methods = Object.assign(
    dataStoreService.initDataStore('profile', {}),
    {}
  );
  const UPDATE_DATE_KEY = 'profile_update_date';

  let lastGotProfile;

  methods._profilePromise = null;
  methods._localProfilePromise = null;
  methods._syncProfilePromise = null;

  methods.getProfile = getProfile;
  methods.getLocalProfile = getLocalProfile;
  methods.syncProfile = syncProfile;
  methods.getConnectedUserProfile = getConnectedUserProfile;
  methods.getApiProfile = getApiProfile;
  methods.updateProfile = updateProfile;
  methods.getProfilePromise = getProfilePromise;
  methods.getFirstProfile = getFirstProfile;
  methods.getAuthorizationHeader = getAuthorizationHeader;
  methods.reset = reset;
  methods.getNameFromUser = getNameFromUser;
  methods.getTimezone = getTimezone;
  methods.transformUtcToUserTimezone = transformUtcToUserTimezone;
  methods.updateProfilePicture = updateProfilePicture;
  methods.getLastGotProfile = getLastGotProfile;

  // ------------------
  //
  //  Profile Methods
  //
  // ------------------
  /**
   * Get profile datas
   * @return {promise} profile promise
   */
  function getProfile() {
    return getLocalProfile().then((profile) => {
      if (!methods._syncProfilePromise && profileIsOutdated()) {
        syncProfile(profile ? profile._id : '');
      }
      return setProfilePromise(profile);
    });
  }

  function syncProfile(profileId) {
    methods._syncProfilePromise =
      methods._syncProfilePromise ||
      methods
        .getApiProfile(profileId)
        .then((profile) =>
          saveProfile(profile).then((savedProfile) =>
            setProfilePromise(savedProfile).then(() => profile)
          )
        );

    return methods._syncProfilePromise.finally(() => {
      methods._syncProfilePromise = null;
    });
  }

  function setProfilePromise(profile) {
    lastGotProfile = profile;
    methods._profilePromise = $q.when(profile);
    return methods._profilePromise;
  }

  // --------------------------
  //
  //      GET METHODS
  //
  // --------------------------
  /**
   * Get local profile datas
   * @return {promise} profile promise
   */
  function getLocalProfile() {
    return (
      methods._localProfilePromise ||
      methods
        .getFirstProfile()
        .then(validateLocalProfile)
        .then((profile) => {
          methods._localProfilePromise = $q.when(profile);
          return profile;
        })
    );
  }
  function validateLocalProfile(profile) {
    if (!profile) {
      return $q.reject(
        new Error(
          "There's no profiles locally and we try to fetch the first one.",
          { status: 401 }
        )
      );
    }

    return getJwt(profile)
      ? profile
      : $q.reject(
          new Error(
            'Local profile found but without authorisation (no jwt nor token).',
            { status: 403 }
          )
        );
  }
  function getFirstProfile() {
    return methods.listLocal().then((profiles) => {
      const profile = profiles[0] || null;

      lastGotProfile = profile;
      return profile;
    });
  }
  function getProfilePromise() {
    return methods._profilePromise ? methods._profilePromise : $q.reject();
  }

  function getApiProfile(profileId, config = {}) {
    const url = [
      apiUrlService.getApiUrl(),
      'users',
      profileId,
      'users',
      profileId,
    ].join('/');

    config.params = {
      mode: 'compact',
      withPreferences: true,
    };

    return pingService
      .ping()
      .then(() => $http.get(url, config))
      .then((res) => res.data)
      .then((data) => {
        data.id = data._id;
        return data;
      });
  }

  function getConnectedUserProfile(config, apiUrl) {
    const apiUrlSuffixed = apiUrlService.constructApiUrl(apiUrl);

    let url = [apiUrlSuffixed, 'profile'].join('/');

    if (config.headers.Authorization) {
      const token = config.headers.Authorization.split('Bearer ')[1];
      // This query param is usefull only for Safari webview
      // Safari is the only browser stripping out auth header when
      // following 301 requests
      config.params = { token: encodeURI(token) };
    }

    return $http.get(url, config).then(({ data }) => {
      data.id = data._id;
      return data;
    });
  }

  // --------------------------
  //
  //      SAVE METHODS
  //
  // --------------------------
  function saveProfile(apiProfile) {
    return getLocalProfile()
      .then((localProfile) => assignTokens(apiProfile, localProfile))
      .then(updateProfile)
      .catch(() => apiProfile);
  }
  function assignTokens(apiProfile, localProfile) {
    const profileContents = getContents(localProfile);

    return profileContents
      ? setUserTokens(apiProfile, profileContents)
      : apiProfile;
  }
  function updateProfile(profile) {
    return methods.saveLocal(profile._id, profile).then(() => {
      lastGotProfile = profile;
      methods._localProfilePromise = $q.when(profile);
      localStorageService.set(UPDATE_DATE_KEY, new Date().getTime());
      return profile;
    });
  }

  function getAuthorizationHeader(profile) {
    const jwt = getJwt(profile);

    if (jwt) {
      return `Bearer ${jwt}`;
    }
    return '';
  }

  function reset() {
    methods._profilePromise = null;
    methods._localProfilePromise = null;
    methods._syncProfilePromise = null;
  }

  function updateProfilePicture(profile, avatar_id) {
    const profileWithNewPicture = {
      ...profile,
      contents: { ...profile.contents, avatar_id: avatar_id },
    };

    return methods.updateProfile(profileWithNewPicture);
  }

  function getLastGotProfile() {
    return lastGotProfile;
  }

  // ------------------
  //
  //  HELPERS Methods
  //
  // ------------------
  /**
   * Test if last updated date is outdated
   * @return {boolean} Date is outdated or not
   */
  function profileIsOutdated() {
    const lastUpdatedValue = localStorageService.get(UPDATE_DATE_KEY);

    return dateService.isDelayExceeded(
      NB_HOURS_TO_REFRESH_PROFILE,
      lastUpdatedValue
    );
  }

  function getNameFromUser(user) {
    return user ? `${user.firstName || ''} ${user.lastName || ''}` : null;
  }

  function transformUtcToUserTimezone(profile, date) {
    return dateService.getTimezoneDateFromUtc(date, getTimezone(profile));
  }

  function getTimezone(profile) {
    if (!profile || !Array.isArray(profile.preferences)) {
      return DEFAULT_TIMEZONE;
    }
    const timezonePreference = profile.preferences.filter(
      (preference) => preference.name === PROFILE_PREFERENCE_TIMEZONE_NAME
    )[0];

    return (timezonePreference && timezonePreference.value) || DEFAULT_TIMEZONE;
  }

  return methods;
}

function setUserTokens(profileToAssign, tokens) {
  profileToAssign.contents.jwt = tokens.jwt;
  return profileToAssign;
}
function getContents(profile) {
  return profile && profile.contents;
}
function getJwt(profile) {
  return getContents(profile) && profile.contents.jwt;
}
