import { CorporateEventDetailsModalController } from './corporate-event-details-modal.controller';

export const CorporateEventDetailsModalComponent: ng.IComponentOptions = {
  bindings: {
    event: '<',
    onClose: '&',
  },
  transclude: true,
  templateUrl:
    'calendar-events/components/calendar/corporate-events/corporate-event-details-modal/corporate-event-details-modal.html',
  controller: CorporateEventDetailsModalController,
};
