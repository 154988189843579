import { FilterModalComponent } from './filter-modal.component';

export default angular
  .module('sfMobile.components.filter-modal', [
    'sfMobile.services.localization',
    'sfMobile.services.modal',
    'LocalStorageModule',
    'sfMobile.components.input-checkbox',
    'sfMobile.components.select-or-text',
  ])
  .component('sfFilterModal', FilterModalComponent).name;
