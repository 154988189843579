import { defaultErrorCatch } from './helper';

export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  const version = 18;

  sqlStorageServiceProvider.addUpdater({
    version,
    method: (sqlStorageService, localStorageService, sentryService) => {
      'ngInject';
      /* Campaign table creation */
      const campaignsFields = [
        'id NVARCHAR(32) PRIMARY KEY UNIQUE',
        'payload TEXT',
        'name TEXT',
        'deactivation_date TEXT',
      ].join(', ');

      const dropCampaignsTableQuery = 'DROP TABLE IF EXISTS campaigns';
      const createCampaignsTableQuery = `CREATE TABLE campaigns(${campaignsFields})`;

      const update_date_keys = localStorageService
        .keys()
        .filter((key) => key.indexOf('_update_date') !== -1);

      return (
        sqlStorageService
          /* Campaigns migration execution */
          .execute(dropCampaignsTableQuery)
          .then(() => sqlStorageService.execute(createCampaignsTableQuery))
          /* Force update */
          .then(() =>
            localStorageService.remove.apply(
              localStorageService,
              update_date_keys
            )
          )
          .then(() => localStorageService.remove('synchronise_date'))
          .catch((error) => defaultErrorCatch(sentryService, error, version))
      );
    },
  });
}
