import { GroupTaskDetailStoreItemController } from './group-task-detail-store-item.controller';

export const GroupTaskDetailStoreItemComponent: ng.IComponentOptions = {
  bindings: {
    profile: '<',
    store: '<',
    task: '<',
    user: '<',
    entityType: '<',
    onTaskUpdate: '&',
  },
  templateUrl:
    'tasks/components/group-task-detail/components/group-task-detail-store-item/group-task-detail-store-item.html',
  controller: GroupTaskDetailStoreItemController,
};
