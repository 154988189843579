import { Task } from '../../../..';
import { EntityType, TSFixMe, User, UserRef } from '../../../../..';
import { IMAGE_SIZES } from '@constants/image-sizes.constant';
import { TaskService } from '@tasks/services/task.service';
import { ImageService } from '@services/Utils/Image/image.service';
import { APIStoreContents } from '@places/';

const UNASSIGNED_USER = {
  contents: { firstName: '?' },
} as User;

export class GroupTaskDetailStoreItemController
  implements ng.IComponentController
{
  // bindings
  profile: User;
  store: APIStoreContents;
  task: Task;
  user: { contents: UserRef; _id: string };
  entityType: EntityType;
  onTaskUpdate: (data: { task: Task }) => void;

  // attributes
  imageUrl: string;
  name: string;

  constructor(
    private taskService: TaskService,
    private imageService: ImageService,
    private SF_IMAGE_SIZES: typeof IMAGE_SIZES
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.setImage(this.entityType === 'places' ? this.store : this.user);
    this.user = this.user ?? UNASSIGNED_USER;
    this.name =
      this.entityType === 'places'
        ? this.store.name
        : `${this.user.contents.firstName} ${this.user.contents.lastName}`;
  }

  setImage(
    entity: APIStoreContents | { contents: UserRef; _id: string }
  ): void {
    if (
      this.entityType === 'places' &&
      (entity as APIStoreContents).picture_id
    ) {
      const { SQUARE_MEDIUM } = this.SF_IMAGE_SIZES;

      this.imageService
        .getUrl((entity as APIStoreContents).picture_id)
        .then((url) => this.imageService.getSizedUrl(url, SQUARE_MEDIUM))
        .then((url) => {
          this.imageUrl = url;
        });
    } else {
      this.imageUrl = '/img/background_medium.png';
    }
  }

  onClick(event: Event): void {
    event.preventDefault();
    this.taskService.lastActiveTaskId = this.task._id;

    this.taskService
      .openTaskDetailsModal(this.task._id, this.profile, false, false)
      .then((data) => {
        this.onTaskUpdate(data);
      });
  }
}
