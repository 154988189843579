import { File } from '@awesome-cordova-plugins/file';
import { Media, PhotoResponse } from '@capacitor-community/media';
import { Device } from '@capacitor/device';
import { Dialog } from '@capacitor/dialog';
import { Directory, Filesystem, WriteFileResult } from '@capacitor/filesystem';
import * as blobUtil from 'blob-util';

import { PlatformService } from '../Platform';

export class FileSystemService {
  Dialogs = Dialog;
  FileSystem = Filesystem;
  Device = Device;
  Media = Media;
  File = File;
  blobUtil = blobUtil;

  // eslint-disable-next-line max-params
  constructor(
    private $window: ng.IWindowService,
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private segmentService,
    private SF_ERROR_CODES,
    private platformService: PlatformService
  ) {
    'ngInject';
  }

  createDir(folderName: string): ng.IPromise<void | null> {
    const fileDirectory = this.getDeviceFilePath();

    return this.$q
      .resolve(
        this.FileSystem.mkdir({
          directory: fileDirectory,
          path: folderName,
          recursive: true,
        })
      )
      .catch(() => null);
  }

  deleteDir(folder: string): ng.IPromise<void> {
    const fileDirectory = this.getDeviceFilePath();

    try {
      return this.$q.resolve(
        this.FileSystem.rmdir({
          directory: fileDirectory,
          path: folder,
          recursive: true,
        })
      );
    } catch (e) {
      return this.$q.reject(e);
    }
  }
  createFile(
    path: string,
    fileName: string,
    data: string,
    directory?: Directory
  ): ng.IPromise<WriteFileResult> {
    const fileDirectory = directory ? directory : this.getDeviceFilePath();
    const fullPath = `${path}/${fileName}`;

    return this.$q.resolve(
      this.FileSystem.writeFile({
        directory: fileDirectory,
        path: fullPath,
        data,
        recursive: true,
      })
    );
  }
  moveFile(
    path: string,
    fileName: string,
    newPath: string,
    newFileName: string
  ): ng.IPromise<void> {
    const fileDirectory = this.getDeviceFilePath();
    const fullOldPath = `${path}/${fileName}`;
    const fullNewPath = `${newPath}/${newFileName}`;

    return this.$q.resolve(
      this.FileSystem.rename({
        from: fullOldPath,
        to: fullNewPath,
        toDirectory: fileDirectory,
      })
    );
  }
  removeFile(path: string, fileName: string): ng.IPromise<void> {
    const fileDirectory = this.getDeviceFilePath();
    const fullPath = `${path}/${fileName}`;

    return this.$q.resolve(
      this.FileSystem.deleteFile({ directory: fileDirectory, path: fullPath })
    );
  }
  checkFile(filePath: string): ng.IPromise<boolean> {
    const { path, name } = this.getPathInfos(filePath);

    return this.$q
      .resolve(this.FileSystem.stat({ path: `${path}${name}` }))
      .then(() => true)
      .catch(() => false);
  }
  saveInGallery(filePath: string): ng.IPromise<PhotoResponse | null> {
    const APP_ALBUM = 'Simplifield';

    return this.$q
      .resolve()
      .then(() => {
        return this.Media.getAlbums()
          .then(({ albums }) => {
            const mediaAlbum = albums.find((alb) => alb.name === APP_ALBUM);

            if (!mediaAlbum) {
              return this.Media.createAlbum({ name: APP_ALBUM })
                .then(() => this.Media.getAlbums())
                .then(({ albums }) => {
                  const mediaAlbum = albums.find(
                    (alb) => alb.name === APP_ALBUM
                  );

                  return mediaAlbum ? mediaAlbum : this.$q.reject();
                });
            }
            return mediaAlbum;
          })
          .then((mediaAlbum) =>
            this.Media.savePhoto({
              path: filePath,
              albumIdentifier: mediaAlbum.identifier, // no album - error is thrown))
            })
          );
      })
      .catch(() => null);
  }

  checkDeviceFreeSpace(sizeRequiredInBytes: number): ng.IPromise<boolean> {
    return this.$q
      .resolve(this.Device.getInfo())
      .catch((err) => {
        this.$q.reject(err);
        throw err;
      })
      .then(({ realDiskFree }) => {
        if (this.platformService.isBrowser()) {
          return this.$q.resolve(true);
        }
        const freeSpaceInBytes = realDiskFree;

        if (freeSpaceInBytes && freeSpaceInBytes > sizeRequiredInBytes) {
          return this.$q.resolve(true);
        }
        return this.$q.reject({ code: this.SF_ERROR_CODES.OUT_OF_SPACE });
      });
  }
  displayDiskSpaceAlert(): void {
    this.segmentService.track('DIALOG', {
      action: 'open',
      label: 'Disk space alert',
    });
    this.Dialogs.alert({
      title: this.$translate.instant('FILE_SPACE_SATURATED_TITLE'),
      message: this.$translate.instant('FILE_SPACE_SATURATED_MESSAGE'),
    });
  }

  getBlobFile(folderName: string, fileName: string): ng.IPromise<Blob> {
    let path: string;
    return this.computeFileDataPath(folderName)
      .then((dataPath) => {
        path = dataPath;
      })
      .then(() => this.FileSystem.stat({ path: `${path}/${fileName}` }))
      .then(() => this.getUrlToBlobFile(path, fileName))
      .catch((err) => {
        if (typeof err === 'object') {
          err.folderName = folderName;
          err.path = path;
          err.fileName = fileName;
        }
        throw err;
      });
  }
  getUrlToBlobFile(path: string, fileName: string): ng.IPromise<any> {
    return this.File.readAsDataURL(path, fileName)
      .then((data) => this.blobUtil.dataURLToBlob(data))
      .then((blob) => {
        (blob as Blob & { name: string }).name = fileName;
        return blob;
      });
  }

  getPathInfos(
    fullPath: string
  ): Record<'fullPath' | 'path' | 'name' | 'extension', string> {
    const fullPathIndex = fullPath.lastIndexOf('/') + 1;
    const filePath = fullPath.slice(0, fullPathIndex);
    // without decodeURIComponent Android couldn't open files with encoded chars in the name (like %20 instead of space etc.)
    const fileName = this.$window.decodeURIComponent(
      fullPath.slice(fullPathIndex)
    );
    const extension = fileName.slice(fileName.lastIndexOf('.') + 1);

    return {
      fullPath,
      path: filePath,
      name: fileName,
      extension,
    };
  }

  getBlobFromPath(filePath: string): ng.IPromise<Blob> {
    const path = this.fixPath(filePath);
    const pathInfos = this.getPathInfos(path);
    const pathToFile =
      pathInfos.path.slice(-1) === '/'
        ? pathInfos.path.slice(0, -1)
        : pathInfos.path;

    return this.getUrlToBlobFile(pathToFile, pathInfos.name);
  }

  getBlobImageFromBrowser(file: File): Blob {
    // right now in both merchandising and chat we send images as Blob
    const blob = new Blob([file], { type: file.type }) as Blob & {
      name: string;
    };

    blob.name = file.name;
    return blob;
  }

  // -------------------------------
  //        PATHS HELPERS
  // -------------------------------
  computeFileDataPath(
    folderName: string,
    fileName?: string,
    ext?: string
  ): ng.IPromise<string> {
    const fileDirectory = this.getDeviceFilePath();

    return this.getFilePath(fileDirectory, folderName, fileName, ext);
  }
  computeFileCachedPath(
    folderPath: string,
    fileName: string,
    ext: string
  ): ng.IPromise<string> {
    const fileDirectory = this.getDeviceCachedPath();

    return this.getFilePath(fileDirectory, folderPath, fileName, ext);
  }
  getFilePath(
    fileDirectory: Directory,
    folderName: string,
    fileName?: string,
    ext = 'jpg'
  ): ng.IPromise<string> {
    const path = fileName ? `${folderName}/${fileName}.${ext}` : folderName;

    return this.$q
      .resolve(this.FileSystem.getUri({ directory: fileDirectory, path }))
      .then(({ uri }) => uri);
  }
  getDeviceFilePath(): Directory {
    return Directory.Data;
  }
  getDeviceCachedPath(): Directory {
    return Directory.Cache;
  }

  fixPath(path: string): string {
    if (this.platformService.isBrowser()) {
      return path;
    }

    return path.includes('file://') ? path : `file://${path}`;
  }

  // -------------------------------
  //        COMMON HELPERS
  // -------------------------------
  createFileFromBlob(blob, fileName: string): File {
    // creating with File constructor on mobile may produce a corrupted File
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  }

  getMimeTypeByExt(ext: string): string | undefined {
    switch (ext) {
      case 'jpeg':
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'pdf':
        return 'application/pdf';
      case 'mp4':
        return 'video/mp4';
      case 'txt':
        return 'text/plain';
      case 'csv':
        return 'text/csv';
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'zip':
        return 'application/zip';
      case 'rar':
        return 'application/vnd.rar';
      case '7z':
        return 'application/x-7z-compressed';
      case 'apk':
        return 'application/vnd.android.package-archive';
      default:
        return undefined;
    }
  }

  getFileNameExt(fileName: string): { name: string; ext: string | undefined } {
    if (!fileName) {
      return { name: fileName, ext: undefined };
    }

    const dotSeparatorPos = fileName.lastIndexOf('.');

    if (!dotSeparatorPos) {
      return { name: fileName, ext: undefined };
    }
    const name = fileName.slice(0, dotSeparatorPos);
    const ext = fileName.slice(dotSeparatorPos + 1);

    return { name, ext };
  }

  getExtension(name: string): string | undefined | null {
    const extensionRegex = /(?:\.([^.]+))?$/;
    const extensionCheck = extensionRegex.exec(name);
    const extension = extensionCheck && extensionCheck[1];
    return extension;
  }

  trimExtension(name: string): string {
    return name.replace(/\.[^.]+$/, '');
  }
}
