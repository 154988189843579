import { SalesDashboardFilterModalController } from './sales-dashboard-filter-modal.controller';

export const SalesDashboardFilterModalComponent = {
  bindings: {
    selectedValues: '<',
    filters: '<',
    onChange: '<',
    onClose: '<',
  },
  templateUrl:
    'analytics/components/sales-dashboard-filter-modal/sales-dashboard-filter-modal.html',
  controller: SalesDashboardFilterModalController,
};
