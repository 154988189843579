import { GroupTaskItemComponent } from './group-task-item.component';

export const GroupTaskItemModule = angular
  .module('sfMobile.components.groupTaskItem', [
    'sfMobile.services.translateNumbers',
    'sfMobile.services.localization',
    'sfMobile.services.tasks',
    'sfMobile.services.dates',
    'sfMobile.services.profile',
    'sfMobile.services.app-message',
    'sfMobile.constants.task-statuses',
    'sfMobile.services.task',
    'sfMobile.services.segment',
    'sfMobile.components.taskAuthor',
    'sfMobile.components.groupTaskGroupName',
  ])
  .component('sfGroupTaskItem', GroupTaskItemComponent).name;
