import { SfFeatureFlags } from '@simplifield/feature-flags';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import { PovService } from '../../API/POV/pov.service';

export type BoxAccess = {
  accessToken: string;
  resourceId;
};

export class DocumentLibraryService {
  private readonly baseUrl = '/document-library';

  /* @ngInject */
  constructor(
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private $http: ng.IHttpService,
    private sfPOVService: PovService
  ) {}

  hasFeatureFlag() {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.DOCUMENT_LIBRARY
    );
  }

  hasAlphaFeatureFlag() {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.DOCUMENT_LIBRARY_ALPHA
    );
  }

  getBoxToken(): ng.IPromise<BoxAccess> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/token`, { pov: 'organisation' })
      .then((url) => this.$http.get<BoxAccess>(url))
      .then(({ data }) => data);
  }
}
