import { places as placesSchema } from '@simplifield/schema';
import { INgModelController, IFormController } from 'angular';
import { APIStoreContents, PlaceAddress } from '../../..';
import { ObjectId, User } from '../../../..';
import {
  GeolocationService,
  LatLng,
} from '../../../../services/Utils/Geolocation/geolocation.service';
import { GoogleAddressService } from '../../../../services/Utils/GoogleMap/google-address.service';
import { GoogleMapService } from '../../../../services/Utils/GoogleMap/google-map.service';
import { LocalizationService } from '../../../../services/Utils/Localization/localization.service';
import { ObjectIdService } from '../../../../services/Utils/Objectid/objectId.service';
import { PlatformService } from '../../../../services/Utils/Platform';
import { PlacePopupService } from '../../services/place-popup.service';

export class PlaceAddController implements ng.IController {
  // bindings
  profile: User;
  onClose: () => void;
  onSave: () => (obj: Record<string, unknown>) => void;

  // class members
  placesSchema = placesSchema;
  autofillState: 'pending' | 'success' | 'error' | 'idle' = 'idle';
  newPlace: { id: ObjectId; contents: APIStoreContents };
  pending: boolean;
  gpsCoords: LatLng;
  addPlaceForm: ng.IFormController;
  addressAutocomplete: google.maps.places.Autocomplete;
  isAutocompleteLoaded: ng.IDeferred<boolean>;
  autoCompleteElem: HTMLElement | null;
  isAutocompleteUsed = false;
  isRTLNeeded: boolean;
  disableScroll = false;
  // eslint-disable-next-line max-params
  constructor(
    private localizationService: LocalizationService,
    private $timeout: ng.ITimeoutService,
    private placesService,
    private objectIdService: ObjectIdService,
    private geolocationService: GeolocationService,
    private placePopupService: PlacePopupService,
    private googleMapService: GoogleMapService,
    private googleAddressService: GoogleAddressService,
    private $log: ng.ILogService,
    private $element: ng.IRootElementService,
    private $scope: ng.IScope,
    private $q: ng.IQService,
    private $window: ng.IWindowService,
    private platformService: PlatformService
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.isAutocompleteLoaded = this.$q.defer();
    this.newPlace = {
      id: this.objectIdService.create(),
      contents: Object.assign(this.placesSchema.defaults(), {
        organisation_id: this.profile.contents.organisation_id,
        owner_id: this.profile._id,
      }) as APIStoreContents,
    };

    return this.geolocationService.getCurrentPosition().then((gpsCoords) => {
      gpsCoords && (this.gpsCoords = gpsCoords);
    });
  }

  initAddressAutocomplete(): ng.IPromise<google.maps.places.Autocomplete> {
    const addressElem = this.$element[0].querySelector(
      '#place_formatted_address'
    ) as HTMLInputElement;

    return this.googleMapService
      .getAutoComplete(addressElem)
      .then((autocomplete) => {
        this.addressAutocomplete = autocomplete;
        this.addressAutocomplete.addListener('place_changed', () =>
          this.placeHasChange()
        );
        this.isAutocompleteLoaded.resolve(true);
        return this.addressAutocomplete;
      });
  }

  $onDestroy(): void {
    this.autoCompleteElem?.remove();
  }

  $postLink(): void {
    this.$timeout(() => this.initAddressAutocomplete(), 0);
    this.allowClickOnAutocomplete();
  }

  submit(): ng.IPromise<void> {
    this.pending = true;

    return this.prepareAddress()
      .then(() => this.placesService.save(this.newPlace))
      .then(() => {
        this.onSave()({
          placeId: this.newPlace.id,
        });
      })
      .catch((err) => {
        if (err.data && err.data.code === 'E_NAME_IN_USE') {
          this.setInvalidate('posname_field', 'nameInUse');

          throw err;
        }

        return this.showSaveErrorPopup(err);
      })
      .finally(() => {
        this.pending = false;
      });
  }

  prepareAddress(): ng.IPromise<void> {
    if (this.isAutocompleteUsed || this.autofillState === 'success') {
      return this.$q.resolve();
    }

    return this.addressVerification().then((verifiedAddress) => {
      const placeContents = {
        ...this.newPlace.contents,
        ...(verifiedAddress ?? {}),
      };

      // if address was selected from google autocomplete and then some address field was changed - let backend build formatted_address
      Reflect.deleteProperty(placeContents, 'formatted_address');
      this.newPlace.contents = placeContents;
    });
  }

  addressVerification(): ng.IPromise<PlaceAddress | null | void> {
    const inputedAddress = ['street', 'zipcode', 'city', 'country']
      .filter((key) => this.newPlace.contents[key])
      .map((key) => this.newPlace.contents[key])
      .join(' ');

    return this.geolocationService.googleCheck(inputedAddress).then(
      (result) => {
        if (result.unavailable) {
          return this.placePopupService.showServiceUnavailablePopup();
        }
        if (!result.readable || !result.data) {
          return this.placePopupService
            .showAddressErrorPopup(inputedAddress)
            .then(() => null);
        }
        if (!result.match) {
          return this.placePopupService.showSuggestPopup(
            inputedAddress,
            result,
            () => {
              this.pending = false;
            }
          );
        }
        return result.data;
      },
      (err) => this.showSaveErrorPopup(err)
    );
  }

  showSaveErrorPopup(err): ng.IPromise<void> {
    return this.placePopupService
      .showSaveErrorPopup(err)
      .then(() => this.submit());
  }

  hasError(fieldName: string): boolean {
    return (
      this.addPlaceForm[fieldName].$touched &&
      this.addPlaceForm[fieldName].$invalid
    );
  }

  getError(fieldName: string): {
    [validationErrorKey: string]: (INgModelController | IFormController)[];
  } {
    return this.addPlaceForm[fieldName].$error;
  }

  resetValidity(fieldName, errorType): boolean {
    return this.setValidity(fieldName, errorType, true);
  }

  setInvalidate(fieldName, errorType): boolean {
    return this.setValidity(fieldName, errorType, false);
  }

  setValidity(fieldName, errorType, value): boolean {
    const field = this.addPlaceForm[fieldName];

    if (!field) {
      return false;
    }

    field.$setValidity(errorType, value);
    return true;
  }

  autofill(): ng.IPromise<void> {
    this.autofillState = 'pending';

    return this.geolocationService
      .getAddressFromCoordinates(this.gpsCoords)
      .then(
        (googleDatas) => {
          const bestResult = googleDatas.results[0];

          Object.assign(
            this.newPlace.contents,
            this.geolocationService.google2SimplifieldFormatter(bestResult),
            { latLng: [this.gpsCoords.lat, this.gpsCoords.lng] }
          );
          this.autofillState = 'success';
        },
        () => {
          this.autofillState = 'error';
        }
      )
      .then(() => {
        const RESET_DISPLAY_TIME = 5000;

        this.$timeout(() => {
          this.autofillState = 'idle';
        }, RESET_DISPLAY_TIME);
      });
  }

  placeHasChange(): void {
    const placeMapDataModel = this.addressAutocomplete.getPlace();

    if (placeMapDataModel.geometry) {
      const placeAddress = this.googleAddressService.setPlaceAddressProperties(
        this.googleAddressService.formatAddressFromGoogle(placeMapDataModel)
      );

      const newContents = Object.assign(this.newPlace.contents, placeAddress);

      this.newPlace = { ...this.newPlace, contents: newContents };
      this.isAutocompleteUsed = true;
      this.$scope.$apply();
    } else {
      this.$log.debug('Address is not recognised by Google Maps service 😢');
    }
  }

  preventFormSubmit(event: KeyboardEvent): void {
    if (event?.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  allowClickOnAutocomplete(): void {
    this.isAutocompleteLoaded.promise.then(() => {
      this.$timeout(() => {
        this.autoCompleteElem =
          this.$window.document.querySelector('.pac-container');

        if (this.platformService.isiOS()) {
          this.autoCompleteElem?.addEventListener('touchend', (event) => {
            event.stopImmediatePropagation();
          });
        }
      }, 800); // arbitrary timeout cos google doesn't provide an API that allow to find when an autocomplete container is attached to the DOM
    });
  }

  onAddressFieldChange(): void {
    this.isAutocompleteUsed = false;
  }

  toggleScroll() {
    this.disableScroll = !this.disableScroll;
  }
}
