import { PovService } from '../../../services/API/POV/pov.service';
import { DateService } from '../../../services/Utils/Dates/date.service';
import { CalendarEvent, CalendarEventRes } from '../../types';

export class ManagerCalendarApiService {
  private baseUrl = '/calendar-events';

  constructor(
    private $http: ng.IHttpService,
    private sfPOVService: PovService,
    private dateService: DateService
  ) {
    'ngInject';
  }

  getManagerCalendarEvents(
    date: Date,
    duration = 'month',
    dynFilters?: Record<string, unknown>
  ): ng.IPromise<CalendarEvent[]> {
    const utcDate = this.dateService.moment.utc(
      this.dateService.toMoment(date).toArray()
    );
    const startDate = utcDate
      .clone()
      .startOf(duration)
      .subtract(1, 'day')
      .toISOString();
    const endOfDate = utcDate
      .clone()
      .endOf(duration)
      .add(1, 'day')
      .toISOString();
    const filters: { $and: Record<string, unknown>[] } = {
      $and: [
        {
          $or: [
            {
              $and: [
                { event_start_date_time: { $lte: startDate } },
                { event_end_date_time: { $gte: endOfDate } },
              ],
            },
            {
              event_start_date_time: { $between: [startDate, endOfDate] },
            },
            { event_end_date_time: { $between: [startDate, endOfDate] } },
          ],
        },
      ],
    };

    if (dynFilters) {
      const filtersKeys = Object.keys(dynFilters);
      const operations = {
        place_name: '$regex',
        assignee_fullName: '$regex',
        assignee_group: '$eq',
        place_group: '$eq',
        event_type: '$eq',
      };

      // Add every filters from the dynFilters parameter into filters
      // accordingly to the operation hashmap.
      // Example: { place_name: { $regex: 'filterValue' } }
      filters.$and.push(
        ...filtersKeys.map((key: string) => ({
          [key]: { [operations[key]]: dynFilters[key] },
        }))
      );
    }

    return this.sfPOVService
      .pBuildURL(this.baseUrl, { pov: 'organisation' })
      .then((url) =>
        this.$http.get<CalendarEventRes>(url, {
          params: { filters, view: 'manager' },
        })
      )
      .then((response) => {
        if (dynFilters?.['assignee_fullName']) {
          return response.data.entries
            ?.map((entry) => {
              return {
                ...entry,
                assignees: entry.assignees?.filter(({ _id }) => {
                  const users = response.data.users ?? [];
                  return users?.some(({ _id: userId }) => userId === _id);
                }),
              };
            })
            ?.filter(({ assignees }) => {
              return Boolean(assignees?.length);
            });
        } else {
          return response.data.entries;
        }
      });
  }
}
