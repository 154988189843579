const API_VERSION = 'v3';
const API_AUTH_VERSION = 'v0';
const API_AUTH_VERSION_1 = 'v1';

export class ApiUrlService {
  API_URL_KEY = 'api_url';
  apiUrl: string | null = null;

  constructor(
    private ConfigServer: Record<string, any>,
    private localStorageService: ng.local.storage.ILocalStorageService
  ) {
    'ngInject';
  }

  private static removeDupSlash(str: string): string {
    // Regex matching:
    // - No ':' before matching pattern (needed to keep http://)
    // - Exactly 2 '/' or more
    return str.replace(/([^:]\/)\/+/g, '$1');
  }

  private isDevEnv(): boolean {
    return ['development', 'native'].includes(this.ConfigServer.ENV);
  }

  /**
   * Fix the api url depends of the environment to call
   * the right url on the development environment.
   **/
  fixApiUrlDependsOfEnv(url: string): string {
    const { origin, pathname, search } = new URL(url);
    const rightOrigin = this.isDevEnv()
      ? this.ConfigServer.API_BASEURL
      : origin;

    return ApiUrlService.removeDupSlash(`${rightOrigin}/${pathname}${search}`);
  }

  getApiUrl(): string {
    if (!this.apiUrl) {
      // Always use the ip url on native env
      const url = this.constructApiUrl(
        this.getApiUrlStored() || this.ConfigServer.API_BASEURL
      );

      this.apiUrl = this.fixApiUrlDependsOfEnv(url);
    }
    return this.apiUrl;
  }

  setApiUrl(baseUrl: string = this.ConfigServer.API_BASEURL): string {
    const fixedBaseUrl = this.fixApiUrlDependsOfEnv(baseUrl).slice(0, -1);

    this.localStorageService.set(this.API_URL_KEY, fixedBaseUrl);

    this.apiUrl = this.constructApiUrl(fixedBaseUrl);
    return this.apiUrl;
  }

  getApiUrlStored(): string | null {
    const localApiUrl = this.localStorageService.get(this.API_URL_KEY);
    const localApiExist = localApiUrl && '' + localApiUrl !== 'undefined';

    return localApiExist ? localApiUrl : null;
  }

  constructApiUrl(base: string): string {
    return ApiUrlService.removeDupSlash(`${base}/api/${API_VERSION}`);
  }

  getAuthUrl(): string {
    return ApiUrlService.removeDupSlash(
      `${this.ConfigServer.AUTH_BASEURL}/api/${API_AUTH_VERSION}`
    );
  }

  getAuthV1Url(): string {
    return ApiUrlService.removeDupSlash(
      `${this.ConfigServer.AUTH_BASEURL}/api/${API_AUTH_VERSION_1}`
    );
  }

  reset(): boolean {
    this.apiUrl = null;
    return this.localStorageService.remove(this.API_URL_KEY);
  }
}
