import { ObjectId } from '../..';
import { PovService } from '../../services/API/POV/pov.service';
import {
  APIGroupTask,
  ApiGroupTaskMeta,
  APIListGroupTasks,
  GroupTask,
} from '../types';

export class GroupTaskApiService {
  private baseUrl = '/grouptasks';
  constructor(
    private $http: ng.IHttpService,
    private sfPOVService: PovService
  ) {
    'ngInject';
  }

  getGroupTasks(params = {}): ng.IPromise<APIListGroupTasks> {
    return this.sfPOVService
      .pBuildURL(this.baseUrl, { pov: 'organisation' })
      .then((uri) => this.$http.get<APIListGroupTasks>(uri, { params }))
      .then((res) => res.data);
  }

  getGroupTasksById(groupTasksId: ObjectId): ng.IPromise<ApiGroupTaskMeta> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/${groupTasksId}/tasks`, {
        pov: 'organisation',
      })
      .then((uri) => this.$http.get<ApiGroupTaskMeta>(uri))
      .then((res) => res.data);
  }

  createGroupTasks(
    groupTasks: Partial<GroupTask>,
    params = {}
  ): ng.IPromise<APIGroupTask> {
    return this.sfPOVService
      .pBuildURL(this.baseUrl, { pov: 'organisation' })
      .then((uri) =>
        this.$http.post<APIGroupTask>(
          uri,
          { contents: { ...groupTasks } },
          { params }
        )
      )
      .then((res) => res.data);
  }

  deleteGroupTasks(groupTasksId: ObjectId): ng.IPromise<void> {
    return this.sfPOVService
      .pBuildURL(`${this.baseUrl}/${groupTasksId}`, { pov: 'organisation' })
      .then((uri) => this.$http.delete<void>(uri))
      .then((res) => res.data);
  }
}
