import type { StateService } from '@uirouter/core';
import type { APIStore } from '../../../..';
import type { User, ObjectId } from '../../../../..';
import { UsersService } from '../../../../../services/API/users/users.service';
import { PubSubService } from '../../../../../services/Utils/PubSub/pubsub.service';
import type { APITask } from '../../../../../tasks';

const RESULTS_LIMIT = 3;

export class PlaceTasksSectionController implements ng.IComponentController {
  // bindings
  place: APIStore;
  profile: User;
  // fields
  tasks: APITask[];
  isLoading = false;
  networkError = false;
  tasksPageListener: () => void;

  // eslint-disable-next-line max-params
  constructor(
    private $q: ng.IQService,
    private pubSubService: PubSubService,
    private $state: StateService,
    private taskService,
    private tasksService,
    private usersService: UsersService
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    this.tasksPageListener = this.pubSubService.subscribe(
      'TASKS_LIST_DESTROYED',
      () => this.getTasks()
    );
    return this.getTasks();
  }

  $onDestroy(): void {
    this.tasksPageListener();
  }

  getTasks(): ng.IPromise<void> {
    this.isLoading = true;
    this.networkError = false;
    const params = {
      requestFilters: [
        {
          name: 'place_id',
          value: this.place._id,
        },
        {
          name: 'status',
          value: 'todo',
        },
      ],
      limit: RESULTS_LIMIT,
    };

    return this.tasksService
      .getTasks(params)
      .then((response) => this.addRelatedUsers(response.entries))
      .then((tasks) => {
        this.tasks = tasks;
      })
      .catch(() => {
        this.networkError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  addRelatedUsers(tasks: APITask[]): ng.IPromise<any> {
    return this.$q.all(
      tasks.map((task) => {
        if (!task.contents.assignee_id) {
          return this.$q.resolve(task);
        }
        return this.usersService
          .getUser(task.contents.assignee_id)
          .then((user) => ({
            ...task,
            user: user.contents,
          }));
      })
    );
  }

  goToView(stateLink: string): void {
    this.$state.go(stateLink);
  }

  onContentClick(event: Event, taskId: ObjectId): ng.IPromise<void> {
    event.preventDefault();

    return this.taskService
      .openTaskDetailsModal(taskId, this.profile, false)
      .then(() => this.getTasks());
  }
}
