const MAX_DISPLAYED_CHOICE = 7;

export const QuestionRadioComponent = {
  bindings: {
    question: '<',
    hasError: '<',
    questionOptions: '<',
    questionChoices: '<',
    questionFree: '<',
    answers: '=',
    actionClickedFn: '=?',
    previousAnswers: '<',
    isDisabled: '<?',
    translations: '<',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-radio/question-radio.html',
  controller: function QuestionRadioController(
    modalService,
    keyboardService,
    FORM_QUESTION_ACTIONS
  ) {
    'ngInject';

    const isDefined = (value) =>
      typeof value !== 'undefined' && value !== null && value !== '';

    this.$onInit = () => {
      const curValue = this.answers.length
        ? this.answers[0].values[0].value
        : null;

      this.MAX_DISPLAYED_CHOICE = MAX_DISPLAYED_CHOICE;
      this.freeChoice = this.getFreeChoiceValue(curValue);
      this.tempValue = curValue;
      this.isMaxChoicesReached =
        this.questionChoices.length > this.MAX_DISPLAYED_CHOICE;
      this.actionClickedFn = this.actionClicked;
    };

    this.$onChanges = () => {
      if (this.isMaxChoicesReached) {
        this.setFormQuestionValidity(
          this.answers.length || this.question.minimum <= 0
        );
      }
    };

    this.isRequired = () => this.questionOptions.required && !this.hasAnswer();
    this.hasAnswer = () => (this.answers || []).length;
    this.onSelectValue = (value, isFree) => {
      var answer = this.answers[0] || {};
      var values = [
        {
          field_id: this.question.fields[0]._id,
          value: value,
        },
      ];

      if (!isFree) {
        keyboardService.hide();
      }

      this.answers = isDefined(value)
        ? this.sfQuestionForm.saveAnswer(values, this.answers)
        : this.sfQuestionForm.removeAnswer(answer._id, this.answers);
      this.tempValue = value;
    };

    this.resetAnswer = () => {
      this.onSelectValue(null);
      if (this.isMaxChoicesReached) {
        this.setFormQuestionValidity(this.question.minimum <= 0);
      }
    };

    this.onFreeChange = (value) => this.onSelectValue(value, true);

    this.actionClicked = (action) => {
      switch (action) {
        case FORM_QUESTION_ACTIONS.RESET:
          return this.resetAnswer();
        case FORM_QUESTION_ACTIONS.EDIT:
          return this.openChoicesSelectModal();
        case FORM_QUESTION_ACTIONS.RESET_PREFILL:
          return this.resetToPrefillValue();
        default:
          return false;
      }
    };

    this.resetToPrefillValue = () => {
      if (this.previousAnswers && this.previousAnswers.length) {
        this.freeChoice = this.getFreeChoiceValue(
          this.previousAnswers[0].values[0].value
        );
        this.tempValue = this.previousAnswers[0].values[0].value;
        this.onSelectValue(this.tempValue, !!this.freeChoice);
      }
    };

    this.openChoicesSelectModal = () => {
      let modal = null;
      const modalTemplate = `
        <sf-radio-choices-modal
          on-close="$ctrl.onClose(choice)"
          choices="$ctrl.choices"
          question="$ctrl.question"
          has-free-answer="$ctrl.hasFreeAnswer"
          answer="$ctrl.selectedAnswer"
          translations="$ctrl.translations">
        </sf-radio-choices-modal>
      `;
      const modalBindings = {
        onClose: (choice) => {
          modal.remove();

          if (choice) {
            this.onSelectValue(choice);
            this.freeChoice = this.getFreeChoiceValue(choice);
          }
          this.setFormQuestionValidity(
            (this.answers && this.answers.length) || this.question.minimum <= 0
          );
        },
        choices: this.questionChoices,
        question: this.question,
        hasFreeAnswer: this.questionFree,
        selectedAnswer: this.tempValue,
        translations: this.translations,
      };

      this.getQuestionForm().$setDirty();

      modal = modalService.open(modalTemplate, modalBindings, {
        hardwareBackButtonClose: false,
      });

      return modal;
    };

    this.getFreeChoiceValue = (value) =>
      isDefined(value) && this.questionChoices.indexOf(value) === -1
        ? value
        : '';

    this.getQuestionForm = () => this[`question_radio_${this.question._id}`];
    this.setFormQuestionValidity = (isValid) => {
      const questionForm = this.getQuestionForm();

      if (!questionForm) {
        return false;
      }

      questionForm.$setValidity('required', isValid);
      return true;
    };
  },
};
