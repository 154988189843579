import { AccessRightsService } from '@services/Utils/AccessRights/access-rights.service';
import { DateService } from '@services/Utils/Dates/date.service';
import { ModalService } from '@services/Utils/Modal';
import type {
  CumulDashboardParams,
  DashboardLink,
  ExternalDashboardService,
} from '../../../services/Utils/ExternalDashboard/external-dashboard.service';
import { DashboardFilter } from '@services/API/external-dashboard/external-dashboard-api.service';

type DashboardListValue = {
  label: string;
  value: DashboardLink;
};

export class ExternalDashboardController implements ng.IComponentController {
  /** Props */
  filterable = false;
  view: string;
  defaultFilters: DashboardFilter;

  /** Attributes */
  isContributor = false;
  isLoading = true;
  isNetworkError = false;
  locale: CumulDashboardParams['language'] = 'en';
  standartDashboardList: DashboardListValue[] = [];
  customDashboardList: DashboardListValue[] = [];

  constructor(
    private externalDashboardService: ExternalDashboardService,
    private dateService: DateService,
    private modalService: ModalService,
    private accessRightsService: AccessRightsService
  ) {
    'ngInject';

    this.isContributor = this.accessRightsService.isContributor();
  }

  $onInit(): void {
    this.locale = this.externalDashboardService.getSupportedLang();
    this.initDashboardList();
  }

  initDashboardList(): void {
    this.externalDashboardService
      .getDashboards({
        platform: 'mobile',
        view: this.view,
      })
      .then((dashboardLinks) => {
        const links = dashboardLinks.map((dashboardLink) => ({
          label: this.getDashboardLabel(dashboardLink.name),
          value: dashboardLink,
        }));

        this.standartDashboardList = links.filter(
          ({ value }) => value.isStandardDashboard
        );
        this.customDashboardList = links.filter(
          ({ value }) => !value.isStandardDashboard
        );
      })
      .catch(() => {
        this.isNetworkError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getDashboardLabel(dashboardName: Record<string, string>): string {
    return dashboardName[this.locale] || dashboardName.en || dashboardName.fr;
  }

  openDashboardModal(dashboard: DashboardLink): void {
    const template = `
      <sf-dashboard-details-modal
        dashboard="$ctrl.dashboard"
        filterable="$ctrl.filterable"
        on-close="$ctrl.onClose"
        default-filters="$ctrl.defaultFilters"
      </sf-dashboard-details-modal>
    `;

    const bindings = {
      dashboard,
      filterable: this.filterable,
      defaultFilters: this.defaultFilters,
    };

    const options = {
      animation: 'slide-in-top',
    };

    this.modalService.open(template, bindings, options);
  }

  dashboardClick(dashboard: DashboardListValue): void {
    this.openDashboardModal(dashboard.value);
  }

  displayBadge({ value }: DashboardListValue): boolean {
    const diff = Math.abs(
      this.dateService.getTotalDaysBetweenTwoDates(
        value.createdDate,
        new Date()
      )
    );

    return diff <= 30;
  }
}
