import { ViewStatesComponent } from './view-states.component';

export default angular
  .module('sfMobile.components.view-states', [
    'sfMobile.templates',
    'sfMobile.components.empty',
    'sfMobile.components.networkError',
    'sfMobile.components.skeleton-display',
  ])
  .component('sfViewStates', ViewStatesComponent).name;
