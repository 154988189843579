import { SfFeatureFlags } from '@simplifield/feature-flags';
import Analytics from 'analytics-node';
import AnalyticsNode from 'analytics-node';
import { ObjectId, Organisation, User } from '../../..';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';
import { ObjectIdService } from '../Objectid/objectId.service';
import { PubSubService } from '../PubSub/pubsub.service';
import { TrackingTransparencyService } from '../TrackingTransparencyService/trackingTransparency.service';
import { SEGMENT_EVENTS as SF_SEGMENT_EVENTS } from './segment.events';
import { ConfigService } from '../Config/config.service';

const STORAGE_USER_ID_KEY = 'segment_user_id';

type EVT_KEY = keyof typeof SF_SEGMENT_EVENTS;

// eslint-disable-next-line max-params
export class SegmentService {
  requester: Record<string, unknown>;
  analytics: Analytics | null;
  organisation: Record<string, unknown>;
  userId: ObjectId;
  private isSegmentAvailable: boolean;

  constructor(
    private localStorageService: ng.local.storage.ILocalStorageService,
    private helpersService,
    private objectIdService: ObjectIdService,
    private ConfigServer,
    private SEGMENT_EVENTS: typeof SF_SEGMENT_EVENTS,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private trackingTransparency: TrackingTransparencyService,
    private readonly configService: ConfigService,
    private $q: ng.IQService
  ) {
    'ngInject';

    this.isSegmentAvailable = !this.configService.isChinaBuild();
    if (this.isSegmentAvailable) {
      const analyticsConfig = { flushAt: 1 };
      this.analytics = this.ConfigServer.SEGMENT_ID
        ? new AnalyticsNode(this.ConfigServer.SEGMENT_ID, analyticsConfig)
        : null;
    }
  }

  private getUserId(): ObjectId | null {
    if (!this.isSegmentAvailable) {
      return null;
    }

    if (this.userId) {
      return this.userId;
    }
    const result = this.localStorageService.get(STORAGE_USER_ID_KEY);
    if (result) {
      this.userId = result;
      return this.userId;
    }

    return null;
  }

  private setUserId(userId: ObjectId): void {
    this.userId = userId;
    this.localStorageService.set(STORAGE_USER_ID_KEY, userId.toString());
  }

  identify(
    user: User,
    organisation: Organisation
  ): ng.IPromise<Analytics | undefined> {
    if (!this.isSegmentAvailable) {
      return this.$q.resolve(undefined);
    }
    this.setUserId(user._id);
    return this.trackingTransparency
      .getAuthorizationPromise()
      .then((authorization) => {
        if (!authorization || authorization.status !== 'authorized') {
          return undefined;
        }

        this.requester = {
          id: user._id,
          email: user.contents.email,
          creationDate: user.created_date,
          firstName: user.contents.firstName,
          lastName: user.contents.lastName,
          isArchived: !!user.archived,
          experience: user.contents.experience,
          profile: this.helpersService.profilesToString(user.contents.profiles),
          department: user.contents.department,
          fullName: `${user.contents.firstName} ${user.contents.lastName}`,
          organisation_id: user.contents.organisation_id,
          organisation_name: organisation.contents.name,
        };
        this.organisation = {
          id: organisation._id,
          name: organisation.contents.name,
          status: organisation.subscription.subscription.status,
          licensesNumber: organisation.subscription.numberOfLicences,
          module_visit: [
            this.SF_FEATURE_FLAGS.CALENDAR_EVENTS,
            'feature_flag_visits_bo', // frontend flag
          ].some((ffname) => this.sfFeatureFlagsService.hasFeature(ffname)),
          module_task: [
            this.SF_FEATURE_FLAGS.TASKS,
            'feature_flag_bo_task_mgmt', // frontend flag
          ].some((ffname) => this.sfFeatureFlagsService.hasFeature(ffname)),
          module_chat: this.sfFeatureFlagsService.hasFeature(
            this.SF_FEATURE_FLAGS.CHAT
          ),
          module_merchandising: this.sfFeatureFlagsService.hasFeature(
            this.SF_FEATURE_FLAGS.MERCHANDISING
          ),
          module_newsfeed: this.sfFeatureFlagsService.hasFeature(
            this.SF_FEATURE_FLAGS.NEWSFEED
          ),
        };
        this.analytics?.identify({
          userId: user._id,
          traits: this.requester,
        });

        return this.analytics?.group({
          userId: user._id,
          groupId: organisation._id,
          traits: this.organisation,
        });
      });
  }

  track(name: EVT_KEY, properties = {}): ng.IPromise<Analytics | undefined> {
    if (!this.isSegmentAvailable) {
      return this.$q.resolve(undefined);
    }
    const event = this.SEGMENT_EVENTS[name];

    // in case of the user is not identified we don't send the event
    if (!this.getUserId()) {
      return Promise.resolve(undefined);
    }

    return this.trackingTransparency
      .getAuthorizationPromise()
      .then((authorization) => {
        if (authorization?.status === 'authorized') {
          return this.analytics?.track({
            event,
            userId: this.userId,
            properties: {
              segment_source: 'Mobile',
              ...properties,
            },
          });
        }

        return undefined;
      });
  }
  identifiedTrack(
    name: EVT_KEY,
    properties = {}
  ): ng.IPromise<Analytics | undefined> {
    if (!this.isSegmentAvailable) {
      return this.$q.resolve(undefined);
    }
    return this.trackingTransparency
      .getAuthorizationPromise()
      .then((authorization) => {
        if (authorization?.status === 'authorized') {
          return this.track(name, {
            organisation_id: this.organisation.id,
            organisation_name: this.organisation.name,
            requester_email: this.requester.email,
            ...properties,
          });
        }

        return undefined;
      });
  }
  page(name: string): ng.IPromise<Analytics | undefined> {
    if (!this.isSegmentAvailable) {
      return this.$q.resolve(undefined);
    }
    // in case of the user is not identified we don't send the event
    if (!this.getUserId()) {
      return Promise.resolve(undefined);
    }
    return this.trackingTransparency
      .getAuthorizationPromise()
      .then((authorization) => {
        if (authorization?.status === 'authorized') {
          return this.analytics?.page({
            name,
            userId: this.userId,
          });
        }
        return undefined;
      });
  }
}

export function runSegmentPages(
  segmentService: SegmentService,
  pubSubService: PubSubService
): void {
  'ngInject';
  // Log analytics
  const stateListener = pubSubService.subscribe(
    pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
    trackView
  );

  pubSubService.subscribe(pubSubService.GLOBAL_EVENTS.DESTROY, stateListener);
  function trackView(toState) {
    if (toState.analyticsName) {
      segmentService.page(toState.analyticsName);
    }
  }
}
