export const CampaignRemainingComponent = {
  bindings: {
    campaign: '<',
  },
  templateUrl:
    'legacy-campaigns/campaign/components/campaign-remaining/campaign-remaining.html',
  controller: function CampaignRemainingController(formService, dateService) {
    'ngInject';

    this.$onInit = () => {
      this.showRemainingDays = formService.isTodayInDueDateRange(this.campaign);

      if (this.showRemainingDays) {
        this.remainingDays = dateService.formatDifference(
          new Date(),
          this.campaign.contents.end_date
        );
      }
    };
  },
};
