const PDF_TYPE = 'application/pdf';

export const ReportNavigationModalComponent = {
  bindings: {
    title: '@',
    place: '<',
    form: '<',
    sections: '<',
    report: '<',
    profile: '<',
    sectionsError: '<',
    onItemClick: '&',
    onClose: '&',
    onFormFinalize: '&?',
  },
  templateUrl:
    'reports/components/report-navigation/report-navigation-modal/report-navigation-modal.html',
  controller: function ReportNavigationModalController(
    addressFormat,
    localizationService
  ) {
    'ngInject';

    this.isRTLNeeded = localizationService.shouldActivateRTL();
    this.$onInit = () => {
      this.isRTLNeeded = localizationService.shouldActivateRTL();
      const formFiles = this.form.contents.files || [];

      this.documentFile = formFiles.filter(
        (file) => file.mime_type && file.mime_type.indexOf(PDF_TYPE) !== -1
      )[0];
    };
    this.formatAdress = (place) => addressFormat(place.contents);
  },
};
