"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var errorsLibrary = require('../utils/errors');

var usersLibrary = {
  defaults: usersLibraryDefaults,
  validate: usersLibraryValidate,
  buildEmailKey: usersLibraryBuildEmailKey
}; // One liner compilation to avoid lib functions scope pollution

usersLibrary.validateSchema = new (require('ajv'))().compile(require('./user.schema')); // eslint-disable-line

module.exports = usersLibrary;

function usersLibraryDefaults() {
  var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultUser = {
    firstName: '',
    lastName: '',
    params: [],
    experience: 'field',
    profiles: [],
    tags: [],
    places_ids: [],
    subscribers_ids: [],
    usersGroups_ids: [],
    managedPlacesLists_ids: [],
    managedUsersGroups_ids: []
  };
  return _objectSpread({}, defaultUser, {}, user);
}

function usersLibraryValidate(user) {
  return errorsLibrary.mapAjvErrors(usersLibrary.validateSchema(user) ? [] : usersLibrary.validateSchema.errors);
}

function usersLibraryBuildEmailKey(email) {
  return email.trim().toLowerCase();
}