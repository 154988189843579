import { TaskGroupTasksChoiceController } from './task-group-tasks-choice.controller';

export const TaskGroupTasksChoiceComponent: ng.IComponentOptions = {
  bindings: {
    profile: '<',
    onClose: '&',
    onSave: '&',
  },
  templateUrl: 'tasks/tasks-group-tasks-choice/task-group-tasks-choice.html',
  controller: TaskGroupTasksChoiceController,
};
