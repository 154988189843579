export const CampaignItemComponent = {
  bindings: {
    campaign: '<',
  },
  templateUrl: 'legacy-campaigns/campaigns/components/campaign-item.html',
  controller: function CampaignItemController(dateFormatService) {
    'ngInject';

    this.getDateFormatted = dateFormatService.getDateFormatted;
  },
};
