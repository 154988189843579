import { StateService } from '@uirouter/core';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';
import { TabService } from '../../../services/Utils/Tabs';

export function sfNavBarHide(
  pubSubService: PubSubService,
  tabsService: TabService,
  $state: StateService
): ng.IDirective {
  'ngInject';
  return {
    restrict: 'A',
    compile: () => compileDirective,
  };

  function compileDirective(
    $scope: ng.IScope,
    $element,
    $attr: ng.IAttributes
  ) {
    'ngInject';
    const hasToShow = !$scope.$eval($attr.sfTabBarHide);
    const stateName = $state.current.name;

    const showMainTab = (): void => {
      tabsService.showBar('mainTabs', hasToShow);
    };

    const stateListener = pubSubService.subscribe<{ name: string }>(
      pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
      (params): void => {
        if (stateName === params.name) {
          showMainTab();
        }
      }
    );

    showMainTab();

    $scope.$on('$destroy', () => stateListener());
  }
}
