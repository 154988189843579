import { APIStore } from '../../..';
import { ButtonSelectorOption } from '../../../../components/Buttons/button-selector/button-selector.controller';
import { LocalizationService } from '../../../../services/Utils/Localization/localization.service';
import { Modal, ModalService } from '../../../../services/Utils/Modal';
import { SegmentService } from '../../../../services/Utils/Segment/segment.service';

export const PlaceInfosComponent: ng.IComponentOptions = {
  bindings: {
    place: '<',
    onClose: '&',
  },
  templateUrl: 'places/place/components/place-infos/place-infos.html',
  // eslint-disable-next-line max-params
  controller: class PlaceInfosController implements ng.IComponentController {
    // bindings
    place: APIStore;
    onClose: (arg?: { place: APIStore }) => void;

    // members
    hasError = false;
    isEditDisable = false;
    pendingRecording: boolean;
    isLoading: boolean;
    canEditPlaces: boolean;
    paramsCategories: ButtonSelectorOption[] = [];
    modal: Modal;
    isRTLNeeded: boolean;

    constructor(
      private localizationService: LocalizationService,
      private placesService,
      private segmentService: SegmentService,
      private modalService: ModalService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<void> {
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
      this.pendingRecording = false;
      return this.setCanEditPlaces().then(() => this.callData());
    }

    $onDestroy(): void {
      if (this.modal) {
        this.modal.remove();
      }
    }

    callData(): ng.IPromise<void> {
      this.hasError = false;
      this.isLoading = true;

      return this.placesService
        .getRemote(this.place._id)
        .then((place) => (this.place = place))
        .catch((err) => {
          this.hasError = true;
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    openEdit(): Modal {
      const template = `
      <sf-place-infos-edit
        place="$ctrl.place"
        on-close="$ctrl.modalClose(place)">
      </sf-place-infos-edit>`;
      const bindings = {
        place: this.place,
        modalClose: (place) => {
          if (place) {
            this.place = place;
          }
          this.modal.remove();
          this.callData();
        },
      };

      this.modal = this.modalService.open(template, bindings, {
        hardwareBackButtonClose: false,
      });

      this.segmentService.track('PLACE_INFO', {
        action: 'open',
        label: 'Edition',
      });

      return this.modal;
    }

    setCanEditPlaces(): ng.IPromise<void> {
      if (this.isEditDisable) {
        this.canEditPlaces = false;
      }
      return this.placesService.isAllowedToSave().then((isAllowed) => {
        this.canEditPlaces = isAllowed;
      });
    }
  },
};
