import { CampaignCompletionListController } from './campaign-completion-list.controller';

export const CampaignCompletionListComponent = {
  bindings: {
    campaign: '<',
    form: '<',
    resource: '<',
  },
  templateUrl:
    'legacy-campaigns/campaign/components/campaign-completion-list/campaign-completion-list.html',
  controller: CampaignCompletionListController,
};
