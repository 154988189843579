import { APIStore } from '@places/index';
import { IDeferred, IRequestShortcutConfig } from 'angular';
import { IAPIList, ObjectId, TSFixMe, User } from '../../..';
import { CommentsResourceType } from '../../../comments/services/comments-api/comments-api.factory';
import {
  CommentsFactory,
  CommentsService,
} from '../../../comments/services/comments/comments.factory';
import {
  DataAccessLayerService,
  QueryParams,
} from '../../Utils/CRUD/crud-service';
import { CrudFactory } from '../../Utils/CRUD/crud-service.factory';
import { DebugService } from '../../Utils/Debug/debug.service';
import { FileSystemService } from '../../Utils/FileSystem/file-system.service';
import { ObjectIdService } from '../../Utils/Objectid/objectId.service';
import { LogService } from '../../Utils/log/log.service';
import {
  Campaign,
  CampaignForm,
  CampaignStatistics,
  PerStoreObjective,
  PerUserObjective,
} from '../campaigns/campaigns';
import { Form } from '../forms/forms';
import { Template } from '../templates/templates';
import {
  Answer,
  Report,
  ReportCampaignContents,
  ReportFormContents,
  ReportNode,
} from './reports';
import moment from 'moment-timezone';

export class ReportsService {
  basePath = '/reports';
  commentsService: CommentsService;
  crud: DataAccessLayerService<Report>;

  constructor(
    private $http: ng.IHttpService,
    private $q: ng.IQService,
    private commentsFactory: CommentsFactory,
    private crudFactory: CrudFactory<Report>,
    private databaseSchema,
    private filesSystemService: FileSystemService,
    private missionsService,
    private objectIdService: ObjectIdService,
    private debugService: DebugService,
    private logService: LogService
  ) {
    'ngInject';
    const tableConfig = this.databaseSchema.tables.reports;

    this.commentsService = this.commentsFactory(CommentsResourceType.REPORTS);
    this.crud = this.crudFactory(tableConfig.table_name, {
      take_care_of_user_profile: true,
      exclude_offline_params: ['states', 'date_field', 'start_date'],
      backup: {
        indexed_fields: tableConfig.indexed_fields.map((field) => field.name),
      },
    }) as DataAccessLayerService<Report>;
  }

  save(
    formId: ObjectId,
    reportId: ObjectId,
    report: Report,
    params: IRequestShortcutConfig
  ): ng.IPromise<Report> {
    let createUrl = '';
    let promise;
    this.logService.reportLog('[IT-3253] reports.service.ts | save', report);

    if ((report.contents as ReportCampaignContents).campaign_id) {
      createUrl = `/campaigns/${
        (report.contents as ReportCampaignContents).campaign_id
      }${this.basePath}/${reportId}`;
      promise = this.crud.useOrgPOV(createUrl);
    } else {
      createUrl = `/forms/${formId}${this.basePath}/${reportId}`;
      promise = this.crud.useUserPOV(createUrl, true);
    }

    const reportToSend = this.prepareData(report);

    this.debugService.log('Sending report after preparation', {
      reportId,
      report: JSON.stringify(report),
      reportToSend: JSON.stringify(reportToSend),
      formId,
    });

    return promise
      .then((url) => this.$http.patch(url, reportToSend, params || {}))
      .then((response) => this.cleanResponseData(response.data))
      .then((data) => {
        this.logService.reportLog(
          '[IT-3253] reports.service.ts | save: after patch and cleanResponseData => updateLocal for report',
          data
        );
        return this.crud.updateLocal(data);
      });
  }

  create(
    reportId: ObjectId,
    report: Report,
    params: { canceler?: IDeferred<unknown> },
    context: {
      form: { id: string; contents: CampaignForm };
      place: APIStore;
      checklist: Campaign;
      user: User;
    }
  ): ng.IPromise<Report> {
    const timeoutDefault = 10000;
    const formId = (report.contents as ReportFormContents).form_id;
    const requestConfig = {
      timeout: params?.canceler ? params.canceler.promise : timeoutDefault,
    };

    return this.save(formId, report._id, report, requestConfig)
      .then((data) =>
        this.missionsService.dataStore
          .deleteLocal(report._id)
          .catch(() => data)
          .then(() => data)
      )
      .finally(() => {
        this.deleteLocally(report._id);
      });
  }

  deleteLocally(id: ObjectId): ng.IPromise<void | null> {
    return this.crud.dataStore
      .deleteLocal(id)
      .then(() => this.filesSystemService.deleteDir(id).catch(() => null));
  }

  getLocalDrafts(params: QueryParams): ng.IPromise<Report[]> {
    return this.crud.queryLocal({ ...params, localStatus: 'draft' });
  }

  getCampaignReport(id: ObjectId): ng.IPromise<IAPIList<Report>> {
    const url = `/campaigns/${id}/reports`;

    return this.crud.simpleApiList(url, { campaign: true }, true);
  }

  /*
   * Builders
   */

  getNewReport(id: ObjectId, form: Form, profile: User): Report {
    return {
      _id: id,
      id: id,
      localStatus: 'draft',
      contents: {
        organisation_id: form.contents.organisation_id,
        owner_id: form.contents.owner_id,
        form_id: form._id,
        user_id: profile._id,
        nodes: [],
        answers: [],
        users_ids: [],
      },
    };
  }

  getNewReportForTemplate(
    id: ObjectId,
    template: Template,
    profile: User
  ): Report {
    return {
      _id: id,
      id,
      localStatus: 'draft',
      contents: {
        organisation_id: profile.contents.organisation_id,
        owner_id: profile._id,
        campaign_id: template._id,
        user_id: profile._id,
        type: 'share',
        nodes: [],
        answers: [],
        users_ids: [],
      },
    };
  }

  getDefaultAnswer(
    questionId: ObjectId,
    sectionId: ObjectId,
    nodeId: ObjectId,
    values = []
  ): Answer {
    const sectionIds = [sectionId];
    const nodeIds = [nodeId];

    return {
      _id: this.objectIdService.create(),
      question_id: questionId,
      sections_ids: sectionIds,
      nodes_ids: nodeIds,
      type: 'generic',
      values: values,
    };
  }

  getDefaultNode(sectionIds?: ObjectId[], parentsIds?: ObjectId[]): ReportNode {
    const sections_ids = sectionIds ? [...sectionIds] : [];
    const parents_ids = parentsIds ? [...parentsIds] : [];

    return {
      _id: this.objectIdService.create(),
      sections_ids,
      parents_ids,
    };
  }

  /*
   * Forms
   */

  getByFormId(
    formId: ObjectId,
    params: QueryParams = {}
  ): ng.IPromise<IAPIList<Report>> {
    const getUrl = `/forms/${formId}${this.basePath}`;

    return this.crud.simpleApiList(getUrl, params, true);
  }

  getFirstReportByFormIds(formIds: ObjectId[]): ng.IPromise<Report[]> {
    const limitParams = { limit: 1 };
    const reportFormsPromises = formIds.map((formId) =>
      this.crud.queryLocal({ form_id: formId }, limitParams)
    );

    return this.$q
      .all(reportFormsPromises)
      .then((formsReport) => formsReport.map((formReport) => formReport[0]));
  }

  getStatisticsByFormId(
    formId: ObjectId,
    params: QueryParams
  ): ng.IPromise<IAPIList<Report>> {
    const getUrl = `/forms/${formId}${this.basePath}/statistics`;

    return this.crud.simpleApiList(getUrl, params, true);
  }

  /*
   * Medias
   */

  getMedias(params: QueryParams): ng.IPromise<IAPIList<TSFixMe>> {
    const mediasUrl = '/medias';

    return this.crud.simpleApiList(mediasUrl, params, true);
  }

  getImagesMedias(params: QueryParams = {}): ng.IPromise<IAPIList<TSFixMe>> {
    const imagesParams = {
      'types[]': 'image',
      sorts: '-contents.reportSaved_date',
      ...params,
    };

    return this.getMedias(imagesParams);
  }

  getDocumentsMedias(params: QueryParams = {}): ng.IPromise<IAPIList<TSFixMe>> {
    const documentsParams = {
      'types[]': 'document',
      sorts: '-contents.reportSaved_date',
      ...params,
    };

    return this.getMedias(documentsParams);
  }

  /*
   * Helpers
   */

  prepareData(data) {
    delete data.contents.form;
    delete data.contents.place;
    return data;
  }
  cleanResponseData(data) {
    data.id = data._id;

    delete data.forms;
    delete data.places;
    delete data.users;
    return data;
  }

  shouldReportBeAllowedWithObjective(
    report: Report,
    checklist: Campaign,
    userId: ObjectId
  ): boolean {
    if (checklist?.contents.objective) {
      const statistics = this.getCorrectStatistics(report, checklist);

      if (statistics?.objectivesCompletionStatus === 'COMPLETED') {
        return false;
      }

      const perStoreObjective = (
        checklist.contents.objective as PerStoreObjective
      ).perStore;
      const perUserObjective = (
        checklist.contents.objective as PerUserObjective
      ).perUser;

      if (perStoreObjective && report.contents.place_id) {
        const reportsByStore =
          statistics?.data?.reportsByStore[report.contents.place_id];

        if (reportsByStore && reportsByStore >= perStoreObjective) {
          return false;
        }
      }

      if (perUserObjective) {
        const reportsByUser = statistics?.data?.reportsByUser[userId];
        if (reportsByUser && reportsByUser >= perUserObjective) {
          return false;
        }
      }
    }

    return true;
  }

  getCorrectStatistics(
    report: Report,
    checklist: Campaign
  ): CampaignStatistics | undefined {
    const savedDate = report.saved_date
      ? new Date(report.saved_date)
      : undefined;
    return this.getCampaignStatisticsForDate(savedDate, checklist);
  }

  getCampaignStatisticsForDate(
    date: Date | undefined,
    campaign: Campaign
  ): CampaignStatistics | undefined {
    if (!date || !campaign.contents.objective) {
      return campaign.statistics[0];
    }

    return campaign.statistics.find((statistics) => {
      if (statistics.routine_period?.type === 'day') {
        return (
          statistics.routine_period.date === moment(date).format('YYYY-MM-DD')
        );
      } else if (statistics.routine_period?.type === 'month') {
        return (
          statistics.routine_period.date === moment(date).format('YYYY-MM')
        );
      }
    });
  }
}
