export const ButtonMenuBigComponent = {
  bindings: {
    iconName: '<',
    iconHeight: '<',
    buttonTitle: '<',
    isDisabled: '<?',
    onClick: '<',
  },
  transclude: true,
  templateUrl: 'components/Buttons/button-menu-big/button-menu-big.html',
  controller: class ButtonMenuBigController {
    iconPath: string;
    iconName: string;
    constructor() {
      'ngInject';
    }
    $onInit = () => {
      this.iconPath = `img/icons/${this.iconName}.svg`;
    };
  },
};
