import { CalendarEventsMonthListController } from './calendar-events-month-list.controller';

export const CalendarEventsMonthList: ng.IComponentController = {
  bindings: {
    events: '<',
    userTimezone: '<',
    onUpdate: '&',
    onDelete: '&',
  },
  templateUrl:
    'calendar-events/components/calendar/calendar-events-month-list/calendar-events-month-list.html',
  controller: CalendarEventsMonthListController,
};
