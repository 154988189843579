import { DateService } from '@services/Utils/Dates/date.service';
import { Option } from '@simplifield/webcomponents/dist/types/components/multi-select/multi-select';
import { CustomParamType } from '../../..';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import { FilterCustomParam, FilterSelected, FilterStandard } from '../search';

export const FilterModalComponent = {
  bindings: {
    values: '<',
    filters: '<',
    limit: '<',
    onChange: '&',
    onClose: '&',
  },
  templateUrl: 'components/Search/filter-modal/filter-modal.html',
  controller: class FilterModalController implements ng.IController {
    // bindings
    filters: (FilterStandard | FilterCustomParam)[];
    values: FilterSelected[];
    isRTLNeeded: boolean;

    onChange: (arg: { values: FilterSelected[] }) => void;
    onClose: () => void;

    // members
    valuesHash: Record<string, string | string[]>;
    possibleValuesMap: Record<string, any>;

    constructor(
      private localizationService: LocalizationService,
      private dateService: DateService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
      this.filters = this.filters.map((filter) => {
        if (
          (filter as FilterCustomParam).type ||
          (!(filter as FilterCustomParam).type && !filter.values)
        ) {
          return filter;
        }
        // Add empty value to reset filter
        if (!(filter as FilterStandard).values?.includes('')) {
          filter.values = (filter as FilterStandard).values?.concat('');
        }
        (filter as FilterStandard).values?.sort();
        return filter;
      });

      this.valuesHash = this.filters.reduce((hash, filter) => {
        const filterValue = this.values.filter(
          (value) => value.id === filter.id
        )[0];

        hash[filter.id] = filterValue ? filterValue.value : '';
        return hash;
      }, {});

      this.preparePossibleValuesMap();
    }

    preparePossibleValuesMap(): void {
      this.possibleValuesMap = this.filters.reduce((acc, filter) => {
        if ((filter as FilterCustomParam).type === 'MultipleChoice') {
          const selectedValues = this.valuesHash[filter.id];
          const options =
            filter.values?.map((option) => ({
              label: option.value.toString(),
              value: option.id,
              selected: selectedValues?.includes(option.id) || false,
            })) || [];
          acc[filter.id] = options;
        }
        return acc;
      }, {});
    }

    onChangeFilterValue(
      filterChanged: FilterStandard | FilterCustomParam
    ): FilterSelected[] {
      const filterValue = this.valuesHash[filterChanged.id];
      const hasToCheck = this.isDefined(filterValue);
      const newValues = this.updateValues(filterChanged, hasToCheck);

      return this.setValues(newValues);
    }

    onChangeParamFilterValue(
      filterChanged: FilterStandard | FilterCustomParam,
      paramType: CustomParamType
    ): FilterSelected[] {
      const filterValue = this.valuesHash[filterChanged.id];
      const hasToCheck = this.isDefined(filterValue);
      const newValues = this.updateValues(filterChanged, hasToCheck, paramType);

      return this.setValues(newValues);
    }

    onChangeMultiParamFilterValue(
      event: CustomEvent<Option[]>,
      filterChanged: FilterStandard | FilterCustomParam
    ): FilterSelected[] {
      const selectedPredefined = event.detail.map((option) => option.value);
      this.valuesHash[filterChanged.id] = selectedPredefined.length
        ? selectedPredefined
        : '';

      const hasToCheck = Boolean(selectedPredefined.length);

      const newValues = this.updateValues(
        filterChanged,
        hasToCheck,
        'MultipleChoice'
      );

      return this.setValues(newValues);
    }

    updateValues(
      filterChecked: FilterStandard | FilterCustomParam,
      checked: boolean,
      paramType?: CustomParamType
    ): FilterSelected[] {
      const filterValues = this.values.filter(
        (value) => value.id !== filterChecked.id
      );

      if (checked) {
        const filter: FilterSelected = {
          id: filterChecked.id,
          value: this.valuesHash[filterChecked.id] || null,
          name: filterChecked.label,
        };

        if (paramType) {
          filter['paramType'] = paramType;
        }

        return filterValues.concat(filter);
      }

      return filterValues;
    }

    setValues(values: FilterSelected[]): FilterSelected[] {
      this.values = values;
      return values;
    }

    isChecked(filterId: string): boolean {
      const value = this.valuesHash[filterId];

      return value !== '';
    }

    isDefined(data: unknown): boolean {
      return data !== null && typeof data !== 'undefined' && data !== '';
    }

    onSave(): void {
      this.onChange({ values: this.values });
      this.onClose();
    }
  },
};
