const REPORTS_REQ_PARAMS = {
  localStatus: ['draft'],
};

export const MissionsDraftComponent = {
  templateUrl:
    'missions/missions/components/missions-draft/missions-draft.html',
  controller: class MissionsDraftController {
    // eslint-disable-next-line max-params
    constructor(
      $q,
      $state,
      pubSubService,
      dateService,
      placesService,
      formsService,
      reportsService,
      ongoingSheet,
      tasksService,
      placesSourceAdapterService
    ) {
      'ngInject';
      Object.assign(this, {
        $q,
        $state,
        pubSubService,
        dateService,
        placesService,
        formsService,
        reportsService,
        ongoingSheet,
        tasksService,
        placesSourceAdapterService,
      });
    }

    $onInit() {
      this.isLoading = true;
      this.placesHash = {};
      this.formsHash = {};
      this.reportsDraft = [];
      this.draftsSelected = {};
      this.formListener = this.pubSubService.subscribe(
        'FORM_REPORT_UPDATED',
        () => this.callData()
      );

      return this.callData().finally(() => {
        this.isLoading = false;
      });
    }

    $onDestroy() {
      this.formListener();
    }

    callData() {
      const reportsPromises =
        this.reportsService.crud.queryLocal(REPORTS_REQ_PARAMS);

      return reportsPromises
        .then((reports) => reports.filter((report) => report.form_id))
        .then((reports) => this.getPlacesHashByIds(reports))
        .then((reports) => this.getFormsHashByIds(reports))
        .then((reports) => this.updateDatas(reports));
    }

    getPlacesHashByIds(reports) {
      return this.placesSourceAdapterService
        .getReportsPlacesHashByIds(reports)
        .then((placesHash) => {
          this.placesHash = placesHash;
          return reports;
        });
    }

    getFormsHashByIds(reports) {
      return this.formsService
        .getHashWithContentKey('form_id', reports)
        .then((hash) => {
          this.formsHash = hash;
          return reports;
        });
    }

    updateDatas(reports) {
      this.reportsDraft = reports.sort((reportA, reportB) =>
        this.dateService.compareDates(reportA.saved_date, reportB.saved_date)
      );
    }

    openDeleteConfirm(reportsIds) {
      return this.ongoingSheet.openDeleteConfirm().then(() => {
        this.deleteReports(reportsIds);
        return reportsIds;
      });
    }

    deleteReports(reportsId) {
      const promises = reportsId.map((reportId) =>
        this.reportsService
          .deleteLocally(reportId)
          .then(() => this.tasksService.deleteReportTasks(reportId))
          .then(() => {
            this.reportsDraft = this.reportsDraft.filter(
              (report) => reportId !== report._id
            );

            if (!this.reportsDraft.length) {
              this.isDraftDisplay = false;
            }
          })
      );

      this.isDeleting = true;

      return this.$q.all(promises).finally(() => {
        this.isDeleting = false;
        this.draftsSelected = {};
        this.pubSubService.publish('ONGOING_REPORTS_UPDATED');
      });
    }

    getSelectedDraftsId() {
      return Object.keys(this.draftsSelected).reduce((output, reportId) => {
        const isSelected = this.draftsSelected[reportId];

        return isSelected ? output.concat(reportId) : output;
      }, []);
    }

    displayDraftActionsButton() {
      return this.getSelectedDraftsId().length;
    }

    edit(report) {
      this.$state.go('index.menu-more.missions.campaign.form', {
        campaignId: report.contents.form_id,
        reportId: report.id,
      });
    }
  },
};
