import { Share } from '@capacitor/share';

export class ShareService {
  private Share = Share;

  constructor() {
    'ngInject';
  }

  share(url: string): void {
    this.Share.share({ url });
  }
}
