const REPORTS_REQUEST_PARAMS = {
  localStatus: ['ready'],
};

export const MissionComponent = {
  bindings: {
    form: '<',
    profile: '<',
    place: '<',
    isPreview: '<',
  },
  templateUrl: 'missions/mission/mission.html',
  controller: class MissionController {
    // eslint-disable-next-line max-params
    constructor(
      $q,
      $stateParams,
      missionsService,
      formsService,
      reportsService,
      helpersService,
      campaignsSelector,
      dateFormatService
    ) {
      'ngInject';
      Object.assign(this, {
        $q,
        $stateParams,
        missionsService,
        formsService,
        reportsService,
        helpersService,
        campaignsSelector,
        dateFormatService,
      });

      this.getDateAndTimeFormatted = dateFormatService.getDateAndTimeFormatted;
    }

    $onInit() {
      const formFiles = this.form.contents.files || [];

      this.formFilesPath = this.formsService.getFilesPath(this.form._id);
      this.missionButtonState = 'idle';
      this.formStats = null;
      this.selector = this.campaignsSelector(this.form, this.profile);
      this.documentFile = formFiles.filter(
        (file) =>
          file.mime_type && file.mime_type.indexOf('application/pdf') !== -1
      )[0];

      return this.formsService
        .getFormImage(this.form, 'medium')
        .then((imageUrl) => {
          this.imageUrl = imageUrl;
        })
        .then(() => this.callData());
    }

    callData() {
      const missionsQuery = {
        form_id: this.form._id,
      };

      if (this.place && this.place._id) {
        missionsQuery.place_id = this.place._id;
      }

      const promises = [
        this.reportsService.crud.queryLocal(REPORTS_REQUEST_PARAMS),
        this.missionsService.queryLocal(missionsQuery),
      ];

      return this.$q.all(promises).then((data) => this.updateDatas(data));
    }

    updateDatas([reports, missions]) {
      const missionsNotReady = this.helpersService.getMissionsNotStarted(
        missions,
        reports
      );

      this.missionsNb = missionsNotReady.length;
    }

    startMission() {
      if (this.isPreview) {
        return this.selector.redirectToForm(this.$stateParams, true);
      }

      const placeId = this.place ? this.place._id : null;
      const isPonctualMission = this.$stateParams.missionType === 'ponctual';
      const isPermanentMission = this.$stateParams.missionType === 'permanent';
      let promise = this.$q.when();

      if (this.$stateParams.reportId) {
        this.missionButtonState = 'success';
        return this.selector.redirectToForm({
          reportId: this.$stateParams.reportId,
        });
      }

      this.missionButtonState = 'loading';

      if (isPonctualMission) {
        promise = this.selector.startPonctualMission(placeId);
      } else if (isPermanentMission) {
        promise = this.selector.startPermanentMission(placeId);
      }

      return promise
        .then(() => {
          this.missionButtonState = 'success';
        })
        .catch(() => {
          this.missionButtonState = 'error';
        })
        .finally(() => {
          this.missionButtonState = 'idle';
        });
    }
  },
};
