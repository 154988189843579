import ContentHeadeModule from './components/content-header/content-header.module';
import ContentStickModule from './components/content-sticky/content-sticky.module';
import { ContentComponent } from './content.component';
import { ContentService } from './content.service';

export default angular
  .module('sfMobile.components.content', [
    'sfMobile.components.content-header',
    'sfMobile.components.content-sticky',
    'sfMobile.components.parallax',
    'sfMobile.services.platform',
    ContentHeadeModule,
    ContentStickModule,
  ])
  .component('sfContent', ContentComponent)
  .service('contentService', ContentService).name;
