import { hasPath, equals, path } from 'ramda';
import { rrulestr, RRule } from 'rrule';

export class CalendarEventsRecurrenceService {
  // eslint-disable-next-line max-params
  constructor(
    actionSheetService,
    $q,
    $translate,
    calendarEventsService,
    calendarEventsRecurrenceApiService,
    dateService,
    EDIT_RECURRENT_EVENT_CHOICES,
    DELETE_RECURRENT_EVENT_CHOICES
  ) {
    'ngInject';
    this.actionSheetService = actionSheetService;
    this.$q = $q;
    this.$translate = $translate;
    this.calendarEventsApiService = calendarEventsService;
    this.calendarEventsRecurrenceApiService =
      calendarEventsRecurrenceApiService;
    this.dateService = dateService;
    this.EDIT_RECURRENT_EVENT_CHOICES = EDIT_RECURRENT_EVENT_CHOICES;
    this.DELETE_RECURRENT_EVENT_CHOICES = DELETE_RECURRENT_EVENT_CHOICES;
  }

  isRecurrent(event) {
    return hasPath(['contents', 'recurrenceConfig'], event);
  }

  displayEditRecurrentEventChoices(originalEventContents, newEventContents) {
    return this.$q((resolve, reject) => {
      const actions = [
        {
          onClick: () => resolve(this.EDIT_RECURRENT_EVENT_CHOICES.ONE),
          text: this.$translate.instant('CONFIRM_RECURRENT.EDIT_THIS_EVENT'),
          isAvailable: this.isEditChoiceAvailable(
            this.EDIT_RECURRENT_EVENT_CHOICES.ONE
          ),
        },
        {
          onClick: () =>
            resolve(this.EDIT_RECURRENT_EVENT_CHOICES.ONE_AND_NEXT),
          text: this.$translate.instant(
            'CONFIRM_RECURRENT.EDIT_THIS_AND_ALL_NEXT'
          ),
          isAvailable: this.isEditChoiceAvailable(
            this.EDIT_RECURRENT_EVENT_CHOICES.ONE_AND_NEXT
          ),
        },
        {
          onClick: () => resolve(this.EDIT_RECURRENT_EVENT_CHOICES.ALL),
          text: this.$translate.instant('CONFIRM_RECURRENT.EDIT_ALL_EVENTS'),
          isAvailable: this.isEditChoiceAvailable(
            this.EDIT_RECURRENT_EVENT_CHOICES.ALL
          ),
        },
      ].filter((action) =>
        action.isAvailable(originalEventContents, newEventContents)
      );

      this.actionSheetService.open(
        actions,
        {
          cancelText: this.$translate.instant('CONFIRM_RECURRENT.CANCEL'),
        },
        reject
      );
    });
  }

  displayDeleteRecurrentEventChoices() {
    return this.$q((resolve, reject) => {
      const actions = [
        {
          onClick: () => resolve(this.DELETE_RECURRENT_EVENT_CHOICES.ONE),
          text: this.$translate.instant('CONFIRM_RECURRENT.DELETE_THIS_EVENT'),
        },
        {
          onClick: () =>
            resolve(this.DELETE_RECURRENT_EVENT_CHOICES.ONE_AND_NEXT),
          text: this.$translate.instant(
            'CONFIRM_RECURRENT.DELETE_THIS_AND_ALL_NEXT'
          ),
        },
        {
          onClick: () => resolve(this.DELETE_RECURRENT_EVENT_CHOICES.ALL),
          text: this.$translate.instant('CONFIRM_RECURRENT.DELETE_ALL_EVENTS'),
        },
      ];

      this.actionSheetService.open(
        actions,
        {
          cancelText: this.$translate.instant('CONFIRM_RECURRENT.CANCEL'),
        },
        reject
      );
    });
  }

  handleDeleteEventChoice(choice, event) {
    switch (choice) {
      case this.DELETE_RECURRENT_EVENT_CHOICES.ONE:
        return this.deleteOneEvent(event);

      case this.DELETE_RECURRENT_EVENT_CHOICES.ONE_AND_NEXT:
        return this.deleteOneAndNextRecurrentEvents(event);

      case this.DELETE_RECURRENT_EVENT_CHOICES.ALL:
        return this.deleteAllRecurrentEvents(event);

      default:
        throw new Error('Invalid choice', choice);
    }
  }

  deleteOneEvent(event) {
    return this.calendarEventsApiService.delete(
      event._id,
      {},
      { pov: 'organisation' }
    );
  }

  deleteOneAndNextRecurrentEvents(event) {
    const { recurrence_id: recurrenceId, rule } =
      event.contents.recurrenceConfig;
    const startDateTime = event.contents.start_dateTime;
    const { origOptions } = rrulestr(rule);
    const until = this.dateService.subtract(1, 'seconds', startDateTime);

    const contents = {
      recurrenceConfig: {
        rule: new RRule({ ...origOptions, until }).toString(),
      },
    };

    return this.calendarEventsRecurrenceApiService.patch(
      recurrenceId,
      { contents },
      {
        pov: 'organisation',
      }
    );
  }

  deleteAllRecurrentEvents(event) {
    const recurrenceId = path([
      'contents',
      'recurrenceConfig',
      'recurrence_id',
    ])(event);

    return this.calendarEventsRecurrenceApiService.delete(
      recurrenceId,
      {},
      { pov: 'organisation' }
    );
  }

  isEditChoiceAvailable(choice) {
    return (originalEventContents, newEventContents) => {
      if (choice === this.EDIT_RECURRENT_EVENT_CHOICES.ONE) {
        return equals(
          originalEventContents.recurrenceConfig,
          newEventContents.recurrenceConfig
        );
      }
      if (choice === this.EDIT_RECURRENT_EVENT_CHOICES.ONE_AND_NEXT) {
        return (
          equals(
            originalEventContents.recurrenceConfig,
            newEventContents.recurrenceConfig
          ) &&
          this.dateService.isSameDate(
            originalEventContents.start_dateTime,
            newEventContents.start_dateTime
          ) &&
          this.dateService.isSameDate(
            originalEventContents.end_dateTime,
            newEventContents.end_dateTime
          )
        );
      }
      return choice === this.EDIT_RECURRENT_EVENT_CHOICES.ALL;
    };
  }
}
