export const ChipComponent = {
  bindings: {
    color: '@',
    actionIcon: '@?',
    onAction: '&?',
  },
  transclude: true,
  templateUrl: 'components/chip/chip.html',
  controller: function ChipController() {
    'ngInject';
    this.$onInit = () => {
      const color = this.color || 'positive';

      this.chipColor = `chip-${color}`;
    };
  },
};
