import coreRouteConfig from './core.routes';
import databaseConfig from './database.config';
import appConfig from './app.config';
import migrationModule from './migrations/migrations.module';
import {
  versionInterceptor,
  animateConfig,
  runApp,
  removeSplashScreen,
  logStateErrors,
  deprecationInterceptor,
} from './core.config';

export default angular
  .module('sfMobile.core', [
    'ionic',
    'pascalprecht.translate',

    databaseConfig,
    migrationModule,
    'sfMobile.config.server',
    'sfMobile.templates',
    'sfMobile.translations',

    'sfMobile.notifications',

    'sfMobile.services.log',
    'sfMobile.services.intercom',
    'sfMobile.services.satismeter',
    'sfMobile.services.sentry',
    'sfMobile.services.push-notifications',
    'sfMobile.services.lifecycle',
    'sfMobile.services.segment',
    'sfMobile.services.firebase',
    'sfMobile.services.platform',
    'sfMobile.services.localization',

    'sfMobile.services.profile',
    'sfMobile.services.organisations',
    'sfMobile.services.department',
    'sfMobile.services.auth',
    'sfMobile.services.forms',
    'sfMobile.services.places',
    'sfMobile.services.reports',
    'sfMobile.services.app-state',
    'sfMobile.services.errors',

    'sfMobile.directives.tab-bar-hide',
  ])
  .factory('versionInterceptor', versionInterceptor)
  .factory('deprecationInterceptor', deprecationInterceptor)
  .config(appConfig)
  .config(coreRouteConfig)
  .config(animateConfig)
  .run(runApp)
  .run(removeSplashScreen)
  .run(logStateErrors).name;
