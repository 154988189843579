import { PlaceCampaignsListController } from './place-campaigns-list.controller';

export const PlaceCampaignsListComponent: ng.IComponentOptions = {
  bindings: {
    place: '<',
    profile: '<',
  },
  templateUrl:
    'places/place/components/place-campaigns-list/place-campaigns-list.html',
  controller: PlaceCampaignsListController,
};
