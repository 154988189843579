"use strict";

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Token',
  type: 'object',
  additionalProperties: false,
  required: ['endOfLife', 'organisation_id', 'user_id', 'rights'],
  properties: {
    endOfLife: {
      type: 'number'
    },
    organisation_id: schemaUtils.oneToOne(),
    user_id: schemaUtils.oneToOne(),
    rights: schemaUtils.rights()
  }
};