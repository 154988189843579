import { TaskItemComponent } from './task-item.component';

export default angular
  .module('sfMobile.components.taskItem', [
    'sfMobile.services.translateNumbers',
    'sfMobile.services.localization',
    'sfMobile.services.tasks',
    'sfMobile.services.dates',
    'sfMobile.services.profile',
    'sfMobile.services.app-message',
    'sfMobile.constants.task-statuses',
    'sfMobile.services.task',
    'sfMobile.services.segment',
    'sfMobile.components.avatar-user',
    'sfMobile.components.taskAuthor',
  ])
  .component('sfTaskItem', TaskItemComponent).name;
