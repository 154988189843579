import { PlaceReportsListController } from './place-reports-list.controller';

export const PlaceReportsListComponent: ng.IComponentOptions = {
  bindings: {
    place: '<',
  },
  templateUrl:
    'places/place/components/place-reports-list/place-reports-list.html',
  controller: PlaceReportsListController,
};
