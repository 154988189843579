import { StateService, TransitionPromise } from '@uirouter/core';
import { CampaignsUtilsService } from '../../../reactive-campaigns/services/campaigns-utils.service';
import { CAMPAIGN_EVENTS } from '../../../services/API/campaigns/campaign-events';
import {
  Campaign,
  ObjectiveCompletionStatus,
} from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';
import { ActionSheetService } from '../../../services/Utils/ActionSheet/action-sheet.service';
import { AppMessageService } from '../../../services/Utils/AppMessage/app-message.service';
import { QueryParams } from '../../../services/Utils/CRUD/crud-service';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';
import { LogService } from '../../../services/Utils/log/log.service';

export const ReactiveCampaignsComponent: ng.IComponentOptions = {
  bindings: {
    hideActions: '<',
    isActivity: '<',
    onCampaignItemClick: '&',
    filterRespondents: '<',
  },
  templateUrl:
    'reactive-campaigns/components/reactive-campaigns/reactive-campaigns.html',
  controller: class ReactiveCampaignsController implements ng.IController {
    /** Bindings */
    filterRespondents: boolean;
    useNewCampaigns: boolean;
    isActivity: boolean;

    /** Fields */
    allCampaignsNumber: number;
    completedCampaignsNumber: number;
    todoCampaignsNumber: number;

    campaigns: Campaign[];
    displayedCampaigns: Campaign[];
    campaignSearch = '';
    completionFilter: ObjectiveCompletionStatus | '' = 'TODO';
    isEmpty: boolean;
    isLoading: boolean;
    isSearchLoading: boolean;
    networkError: boolean;
    searchError: boolean;
    stateChangeListener: () => void;
    stateName = 'index.menu-more.reactive-campaigns.list';
    isSynchronizing = false;
    profile;

    constructor(
      private $state: StateService,
      private $translate: ng.translate.ITranslateService,
      private actionSheetService: ActionSheetService,
      private pubSubService: PubSubService,
      private campaignsService: CampaignsService,
      private profileService,
      private organisationsService,
      private appMessageService: AppMessageService,
      private synchronizeService,
      private readonly $q,
      private campaignsUtilsService: CampaignsUtilsService,
      private logService: LogService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<void> {
      this.stateChangeListener = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
        this.onStateChange.bind(this)
      );

      return this.profileService.getLocalProfile().then((profile) => {
        this.profile = profile;
        return this.organisationsService
          .getProfileOrganisation(profile)
          .then((organisation) => {
            this.useNewCampaigns = organisation.useNewCampaigns;

            return this.reload();
          });
      });
    }

    $onDestroy(): void {
      this.stateChangeListener();
    }

    reload(): ng.IPromise<void> {
      this.campaignSearch = '';
      this.isLoading = true;
      this.networkError = false;

      return this.loadForms()
        .then(() => {
          this.isEmpty = !this.campaigns.length;
          this.allCampaignsNumber = this.campaigns.length;
          this.todoCampaignsNumber = this.campaigns.filter(
            (c) =>
              this.campaignsService.getCampaignStatistic(c)
                ?.objectivesCompletionStatus === 'TODO'
          ).length;
          this.completedCampaignsNumber = this.campaigns.filter(
            (c) =>
              this.campaignsService.getCampaignStatistic(c)
                ?.objectivesCompletionStatus === 'COMPLETED'
          ).length;
          this.logService.info(
            `[BUGS-2404] reactive-campaigns.component.ts | reloaded`,
            {
              allCampaignsNumber: this.allCampaignsNumber,
              todoCampaignsNumber: this.todoCampaignsNumber,
              completedCampaignsNumber: this.completedCampaignsNumber,
            },
            true
          );
        })
        .catch((e) => {
          console.error(e);
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    onStateChange(params: { name: string }): void {
      if (this.$state.params.shouldReset) {
        this.onCompletionFilterChanged('TODO');
      }

      if (this.stateName === params.name) {
        this.reload();
      }
    }

    onCampaignSearchChange(search: string): ng.IPromise<void> {
      this.campaignSearch = search;
      this.isSearchLoading = true;
      this.searchError = false;

      return this.loadForms()
        .catch(() => {
          this.searchError = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    }

    loadForms(): ng.IPromise<void> {
      const queryParams: QueryParams = {
        'contents.state': 'active',
      };

      if (this.filterRespondents) {
        queryParams['resolved.collector_ids'] = [this.profile._id];
      }

      if (this.campaignSearch) {
        queryParams.name = new RegExp(this.campaignSearch);
      }

      return this.$q.when(
        this.campaignsService.crud
          .queryLocal(queryParams, {}, [{ key: 'name', desc: false }])
          .then((campaigns) => {
            this.logService.info(
              `[BUGS-2404] reactive-campaigns.component.ts | loadForms: after queryLocal`,
              {
                checklistsCount: campaigns ? campaigns.length : 0,
                checklistIds: campaigns
                  ? campaigns.map((checklist) => checklist._id)
                  : [],
              },
              true
            );

            const _campaigns = !this.isActivity
              ? campaigns.filter((campaign) =>
                  this.campaignsUtilsService.isFrequencyActivePeriod(campaign)
                )
              : campaigns;
            this.logService.info(
              `[BUGS-2404] reactive-campaigns.component.ts | loadForms: after isFrequencyActivePeriod`,
              {
                checklistsCount: _campaigns ? _campaigns.length : 0,
                checklistIds: _campaigns
                  ? _campaigns.map((checklist) => checklist._id)
                  : [],
              },
              true
            );

            this.campaigns = _campaigns.filter(
              (campaign) => campaign.contents.state !== 'deactivated'
            );
            this.logService.info(
              `[BUGS-2404] reactive-campaigns.component.ts | loadForms: after deactivated filtering`,
              {
                checklistsCount: this.campaigns ? this.campaigns.length : 0,
                checklistIds: this.campaigns
                  ? this.campaigns.map((checklist) => checklist._id)
                  : [],
              },
              true
            );

            this.displayedCampaigns = this.completionFilter
              ? this.campaigns.filter((campaign) => {
                  const stat =
                    this.campaignsService.getCampaignStatistic(campaign);

                  return stat
                    ? stat.objectivesCompletionStatus === this.completionFilter
                    : false;
                })
              : this.campaigns;
            this.logService.info(
              `[BUGS-2404] reactive-campaigns.component.ts | loadForms: displayedCampaigns`,
              {
                checklistsCount: this.displayedCampaigns
                  ? this.displayedCampaigns.length
                  : 0,
                checklistIds: this.displayedCampaigns
                  ? this.displayedCampaigns.map((checklist) => checklist._id)
                  : [],
              },
              true
            );
          })
      );
    }

    resyncCampaigns(): ng.IPromise<void> {
      this.isSynchronizing = true;
      this.logService.info(
        `[BUGS-2404] reactive-campaigns.component.ts | resyncCampaigns`,
        {},
        true
      );

      return Promise.all([
        this.synchronizeService.synchronizeChecklists(),
        this.synchronizeService.synchronizePlaces(),
      ])
        .then(() => this.reload())
        .then(() => {
          this.appMessageService.display(
            this.$translate.instant('DATA_SYNC_SUCCESS'),
            'success'
          );

          return this.pubSubService.publish(
            CAMPAIGN_EVENTS.CAMPAIGNS_SYNC_SUCCESS
          );
        })
        .catch(() => {
          this.appMessageService.display(
            this.$translate.instant('DATA_SYNC_PARTIAL_SUCCESS'),
            'fail'
          );
          return this.pubSubService.publish(
            CAMPAIGN_EVENTS.CAMPAIGNS_SYNC_FAILED
          );
        })
        .finally(() => {
          this.isSynchronizing = false;
        });
    }

    goToReports(): TransitionPromise {
      return this.$state.go('index.menu-more.reactive-campaigns.reports');
    }

    onCompletionFilterChanged(
      completionFilter: ObjectiveCompletionStatus | ''
    ): ng.IPromise<void> {
      this.completionFilter = completionFilter;
      this.isSearchLoading = true;
      this.searchError = false;

      return this.loadForms()
        .catch(() => {
          this.searchError = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    }

    openCompletionFilterActionSheet(): void {
      const actions = [
        {
          text: this.$translate.instant(
            'REACTIVE_CAMPAIGNS_OBJECTIVE_COMPLETION_ALL',
            { _NB: this.allCampaignsNumber }
          ),
          onClick: () => this.onCompletionFilterChanged(''),
        },
        {
          text: this.$translate.instant(
            'REACTIVE_CAMPAIGNS_OBJECTIVE_COMPLETION_TODO',
            { _NB: this.todoCampaignsNumber }
          ),
          onClick: () => this.onCompletionFilterChanged('TODO'),
        },
        {
          text: this.$translate.instant(
            'REACTIVE_CAMPAIGNS_OBJECTIVE_COMPLETION_COMPLETED',
            { _NB: this.completedCampaignsNumber }
          ),
          onClick: () => this.onCompletionFilterChanged('COMPLETED'),
        },
      ];
      const actionSheetConfig = {
        cancelText: this.$translate.instant(
          'REACTIVE_CAMPAIGNS_OBJECTIVE_COMPLETION_CANCEL'
        ),
      };

      this.actionSheetService.open(actions, actionSheetConfig);
    }

    getCurrentObjectiveCompletionFilterLabel(): string {
      if (this.completionFilter === 'TODO') {
        return this.$translate.instant(
          'REACTIVE_CAMPAIGNS_OBJECTIVE_COMPLETION_TODO',
          { _NB: this.todoCampaignsNumber }
        );
      }
      if (this.completionFilter === 'COMPLETED') {
        return this.$translate.instant(
          'REACTIVE_CAMPAIGNS_OBJECTIVE_COMPLETION_COMPLETED',
          { _NB: this.completedCampaignsNumber }
        );
      }

      return this.$translate.instant(
        'REACTIVE_CAMPAIGNS_OBJECTIVE_COMPLETION_ALL',
        { _NB: this.allCampaignsNumber }
      );
    }
  },
};
