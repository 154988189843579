import { SentryService } from '../Sentry/sentry.service';
import { Severity } from '@sentry/types';
import { SfFeatureFlags } from '@simplifield/feature-flags';
import { FEATURE_FLAGS } from '../../../constants/feature-flags.constant';

export class DebugService {
  /* @ngInject */
  constructor(
    private sentryService: SentryService,
    private $log: ng.ILogService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {}

  log(message: string, context: any): void {
    if (this.sfFeatureFlagsService.hasFeature(this.SF_FEATURE_FLAGS.DEBUG)) {
      this.$log.debug(message, context);

      this.sentryService.captureMessage(message, {
        level: Severity.Info,
        extra: context,
      });
    }
  }
}
