import { ChatCreateComponent } from './chat-create.component';

export default angular
  .module('sfMobile.components.chat-create', [
    'sfMobile.services.localization',
    'pascalprecht.translate',
    'sfMobile.components.content',
    'sfMobile.services.chat',
    'sfMobile.components.button-header-close',
  ])
  .component('sfChatCreate', ChatCreateComponent).name;
