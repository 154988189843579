import QuestionBarcodeModule from './question-barcode/question-barcode.module';
import QuestionCheckModule from './question-check/question-check.module';
import QuestionDateModule from './question-date/question-date.module';
import QuestionPhotoModule from './question-photo/question-photo.module';
import QuestionProductModule from './question-product/question-product.module';
import QuestionProductScanModule from './question-product-scan/question-product-scan.module';
import QuestionRadioModule from './question-radio/question-radio.module';
import QuestionRatingModule from './question-rating/question-rating.module';
import QuestionSignatureModule from './question-signature/question-signature.module';
import QuestionSimpleModule from './question-simple/question-simple.module';
import QuestionTimeModule from './question-time/question-time.module';
import { QuestionFormComponent } from './question-form.component';
import {
  questionsFormService,
  unitSignFormater,
} from './questions-form.service';
import { QuestionFormulaComponent } from './question-formula/question-formula.component';
import { QuestionDocumentModule } from './question-document/question-document.module';

export default angular
  .module('sfMobile.questionsForm', [
    'pascalprecht.translate',
    'sfMobile.templates',
    'sfMobile.constants.form-question-actions',
    'sfMobile.services.reports',
    'sfMobile.services.pubsub',
    QuestionSimpleModule,
    QuestionCheckModule,
    QuestionRadioModule,
    QuestionPhotoModule,
    QuestionSignatureModule,
    QuestionBarcodeModule,
    QuestionDateModule,
    QuestionTimeModule,
    QuestionProductScanModule,
    QuestionRatingModule,
    QuestionProductModule,
    QuestionDocumentModule,
    'sfMobile.components.question-fields',
  ])
  .component('questionFormula', QuestionFormulaComponent)
  .component('sfQuestionForm', QuestionFormComponent)
  .factory('questionsFormService', questionsFormService)
  .filter('unitSign', () => unitSignFormater).name;
