export const PopupPromptComponent = {
  bindings: {
    onSuccess: '&',
    onCancel: '&',
    options: '<',
  },
  templateUrl: 'components/Popup/popup-prompt/popup-prompt.html',
  controller: function PopupPromptControllre($element, $timeout) {
    'ngInject';

    this.$postLink = () => {
      $timeout(() => {
        const input = $element[0].querySelector('[name="popup_prompt_input"]');

        input.focus();
      }, 300);
    };
  },
};
