export const ViewStatesComponent = {
  bindings: {
    isLoading: '<',
    isEmpty: '<',
    hasLoadError: '<',
    emptyConfig: '<',
    errorConfig: '<',
    loadingSkeletonConfig: '<?',
    onReload: '&?',
    onCancel: '&?',
  },
  transclude: true,
  templateUrl: 'components/Layout/view-states/view-states.html',
};
