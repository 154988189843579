import { SF_MANAGE_TASK_VALIDATORS } from './task-manage-form.constants';
import { Subtask, Task } from '../../..';
import { ObjectId, TSFixMe, User } from '../../../..';
import { APIStore, APIStoreContents } from '../../../../places';
import { PlacesService } from '../../../../services/API/places/places.service';
import { TasksService } from '../../../../services/API/tasks/tasks.service';
import { UsersService } from '../../../../services/API/users/users.service';
import { ErrorMessagesService } from '../../../../services/Utils/ErrorMessages/error-messages.service';
import { LocalizationService } from '../../../../services/Utils/Localization/localization.service';
import { NotificationsUtilsService } from '../../../../services/Utils/Notification/notifications-utils.service';
import { ObjectIdService } from '../../../../services/Utils/Objectid/objectId.service';
import { PopupService } from '../../../../services/Utils/Popup/popup.service';
import { SegmentService } from '../../../../services/Utils/Segment/segment.service';
import { TaskService } from '../../../services/task.service';
import { TaskManageService } from '../../services/task-manage.service';

const TASK_MANAGE_TYPE_ENUM = {
  EDIT: 'edit',
  ADD: 'add',
};

const MANAGE_TASK_OPTIONS = {
  priorities: [
    {
      label: 'MANAGE_TASK_PRIORITY_LOW_LABEL',
      value: 0,
    },
    {
      label: 'MANAGE_TASK_PRIORITY_MEDIUM_LABEL',
      value: 10,
    },
    {
      label: 'MANAGE_TASK_PRIORITY_HIGH_LABEL',
      value: 20,
    },
  ],
};

const PLACE_FIELDS_SEARCH_FILTERS = [
  'pos_name',
  'pos_street',
  'pos_zipcode',
  'pos_city',
  'pos_country',
];

const USER_FIELDS_SEARCH_FILTERS = ['user_firstName', 'user_lastName'];
const USER_FIELDS_STATIC_SEARCH_FILTERS = ['firstName', 'lastName'];

const TASK_TYPE_CONTEXTS = ['place', 'assignee'];
const MAX_PHOTOS_ALLOWED = 10;

export const TaskManageFormComponent = {
  bindings: {
    task: '<',
    context: '<',
    question: '<',
    profile: '<',
    offlineMode: '<',
    onDone: '&',
  },
  templateUrl:
    'tasks/task-manage/components/task-manage-form/task-manage-form.html',
  // eslint-disable-next-line max-params
  controller: class TaskManageFormController {
    // Bindings
    task: Task;
    context: Record<string, { name: string; _id: ObjectId } | boolean>;
    question: unknown;
    profile: User;
    offlineMode: boolean;
    onDone: (args?: { managedTask }) => void;

    // Property
    useNewCampaigns: boolean;
    searchPlaceFields: unknown;
    searchUserFields: unknown;
    searchStaticUserFields: unknown;
    isRTLNeeded: boolean;
    maxPhotosAllowed: number;
    isExist: boolean;
    options: typeof MANAGE_TASK_OPTIONS;
    validators: unknown;
    taskManageForm: ng.IController;
    taskContexts: typeof TASK_TYPE_CONTEXTS;
    missionName: string;
    questionName: string;
    users: never[];
    places: never[];
    type: string;
    getAssigneeName: (user: User) => string;
    getPlaceName: (place: APIStore) => string;
    getPlaceAddress: (place: APIStore) => string;
    titlePattern = /\S(.*\S)?/;
    usersSelectConfig: {
      placeId?: ObjectId | null;
      users: User[] | null;
      disableUserSelectBtn: boolean;
    };
    isUserRelatedToPlace: boolean;
    isEditForbidden: boolean;
    disableAssigneeEntitySelector: boolean;

    constructor(
      private localizationService: LocalizationService,
      private MANAGE_TASK_VALIDATORS: typeof SF_MANAGE_TASK_VALIDATORS,
      private taskManageService: TaskManageService,
      private taskService: TaskService,
      private tasksService: ReturnType<typeof TasksService>,
      private formValidationsService,
      private objectIdService: ObjectIdService,
      private profileService,
      private placesService: ReturnType<typeof PlacesService>,
      private usersService: UsersService,
      private segmentService: SegmentService,
      private errorMessagesService: ErrorMessagesService,
      private popupRequestService,
      private $translate: ng.translate.ITranslateService,
      private helpersService,
      private $filter: ng.IFilterService,
      private $q: ng.IQService,
      private popupService: PopupService,
      private notificationsUtilsService: NotificationsUtilsService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<void> {
      if (this.task?.contents?.taskGroup_id) {
        this.isEditForbidden = true;
      }

      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
      this.maxPhotosAllowed = MAX_PHOTOS_ALLOWED;
      this.isExist = Boolean(this.task && this.task._id);
      this.options = MANAGE_TASK_OPTIONS;
      this.validators = this.MANAGE_TASK_VALIDATORS;
      this.isUserRelatedToPlace = this.context.isRelatedToPlace as boolean;

      this.disableAssigneeEntitySelector =
        this.isEditForbidden && !this.task.contents.place_id;

      this.getAssigneeName = (user: User) =>
        this.profileService.getNameFromUser(user);
      this.getPlaceName = (place: APIStore | APIStoreContents) =>
        (place as APIStore).contents
          ? (place as APIStore).contents.name
          : (place as APIStoreContents).name;
      this.getPlaceAddress = (place: APIStore) =>
        place.contents
          ? place.contents.street + ', ' + place.contents.city
          : place.street + ', ' + place.city;

      this._resolveTask();

      this.searchPlaceFields = PLACE_FIELDS_SEARCH_FILTERS;
      this.searchUserFields = USER_FIELDS_SEARCH_FILTERS;
      this.searchStaticUserFields = USER_FIELDS_STATIC_SEARCH_FILTERS;
      this.taskContexts = TASK_TYPE_CONTEXTS;

      this.missionName = '';
      this.questionName = '';

      if (this.task.form) {
        this.missionName = this.$filter<TSFixMe>('sfTranslation')(
          this.task.form.title,
          this.task.form.i18n
        );
        this.questionName = this.$filter<TSFixMe>('sfTranslation')(
          this.task.question?.title,
          this.task.form.i18n
        );
      }
      if (this.task.campaign) {
        this.missionName = this.$filter<TSFixMe>('sfTranslation')(
          this.task.campaign.name,
          this.task.campaign.i18n
        );
        this.questionName = this.$filter<TSFixMe>('sfTranslation')(
          this.task.question?.title,
          this.task.campaign.i18n
        );
      }

      if (this.task.campaign) {
        this.useNewCampaigns = true;
      } else if (this.task.form) {
        this.useNewCampaigns = false;
      }

      if (!this.isExist) {
        this.initTaskContext();
      }

      this.users = [];
      this.places = [];

      const usersAPI = this.task.place?._id
        ? this.usersService
            .getScopedUsers({}, this.task.place._id)
            .then(({ entries }) => entries)
        : this.usersService.crud.listLocal();

      return this.$q
        .all([usersAPI, this.placesService.listLocal()])
        .then(([users, places]) => {
          const usersSorted = this.helpersService.sortByProperty(
            users,
            'contents.firstName'
          );

          this.users = usersSorted.map((user) => ({
            _id: user._id,
            ...user.contents,
          }));

          const placesSorted = this.helpersService.sortByProperty(
            places,
            'contents.name'
          );

          this.places = placesSorted.map((place) => ({
            _id: place._id,
            ...place.contents,
          }));
          this.setUsersSelectConfig();
          return places;
        })
        .catch(() => []);
    }

    initTaskContext() {
      this.taskContexts.forEach((type) => {
        if (this.context && this.context[type]) {
          (this.task[type] as any).label = (
            this.context[type] as { name: string; _id: ObjectId }
          ).name;
          this.task.contents[`${type}_id`] = (
            this.context[type] as { name: string; _id: ObjectId }
          )._id;
        }
      });
    }

    hasError(fieldName: string): boolean {
      return this.formValidationsService.hasToDisplay(
        this.taskManageForm,
        fieldName
      );
    }

    getError(fieldName: string): string {
      return this.formValidationsService.getInputError(
        this.taskManageForm,
        fieldName
      );
    }

    onSubmit(): ng.IPromise<void | boolean> {
      if (!this.taskManageForm.$valid || this.taskManageForm.$submitting) {
        return this.$q.when(false);
      }

      this.taskManageForm.$submitting = true;
      return this.onSave();
    }

    onSave(): ng.IPromise<void> {
      this.taskManageForm.$errorMessages = [];

      const popupText = this.getPopupText();
      const popupRequest = this.popupRequestService.show(popupText);
      const onRetry = () => this.onSave();

      return this.taskManageService
        .saveTask(this.task, this.offlineMode)
        .then((result) => {
          const savedTask = this.offlineMode ? this.task : result;

          popupRequest.onSuccess().then(() => {
            this.onDone({ managedTask: savedTask });
            this.trackSuccess();

            return savedTask;
          });
        })
        .catch((error) => {
          if (this.errorMessagesService.isServerBadRequest(error)) {
            this.taskManageForm.$errorMessages = [
              'MANAGE_TASK_FORM_ERROR_VALIDATIONS',
            ];
          }
          // onError should be above trackFail to prevent unexpected behavior
          popupRequest.onError(onRetry);
          this.trackFail();
        })
        .finally(() => {
          this.taskManageForm.$submitting = false;
        });
    }

    getPopupText() {
      const onlinePopupProgressText = {
        progress: {
          title: this.getPopupTextForCreateEdit(
            'MANAGE_TASK_ONLINE_CREATION_TITLE_PROGRESS',
            'MANAGE_TASK_EDIT_TITLE_PROGRESS'
          ),
        },
        success: {
          title: this.getPopupTextForCreateEdit(
            'MANAGE_TASK_ONLINE_CREATION_TITLE_SUCCESS',
            'MANAGE_TASK_EDIT_TITLE_SUCCESS'
          ),
          iconName: 'thumbsup',
        },
        error: {
          title: this.$translate.instant('MANAGE_TASK_TITLE_ERROR'),
          desc: this.getPopupTextForCreateEdit(
            'MANAGE_TASK_ONLINE_CREATION_DESC_ERROR',
            'MANAGE_TASK_EDIT_DESC_ERROR'
          ),
        },
      };

      const offlinePopupProgressText = {
        progress: {
          title: this.getPopupTextForCreateEdit(
            'MANAGE_TASK_OFFLINE_CREATION_TITLE_PROGRESS',
            'MANAGE_TASK_EDIT_TITLE_PROGRESS'
          ),
        },
        success: {
          title: this.getPopupTextForCreateEdit(
            'MANAGE_TASK_OFFLINE_CREATION_TITLE_SUCCESS',
            'MANAGE_TASK_EDIT_TITLE_SUCCESS'
          ),
          desc: this.getPopupTextForCreateEdit(
            this.useNewCampaigns
              ? 'MANAGE_TASK_OFFLINE_CREATION_DESC_SUCCESS_CHECKLISTS'
              : 'MANAGE_TASK_OFFLINE_CREATION_DESC_SUCCESS',
            ''
          ),
          iconName: 'thumbsup',
        },
        error: {
          title: this.$translate.instant('MANAGE_TASK_TITLE_ERROR'),
          desc: this.getPopupTextForCreateEdit(
            'MANAGE_TASK_OFFLINE_CREATION_DESC_ERROR',
            'MANAGE_TASK_EDIT_DESC_ERROR'
          ),
        },
      };

      return this.offlineMode
        ? offlinePopupProgressText
        : onlinePopupProgressText;
    }

    getPopupTextForCreateEdit(createText: string, editText: string): string {
      return this.type === TASK_MANAGE_TYPE_ENUM.ADD
        ? this.$translate.instant(createText)
        : this.$translate.instant(editText);
    }

    _resolveTask(): void {
      this.task = this.task || {};
      if (!this.task._id) {
        this.type = TASK_MANAGE_TYPE_ENUM.ADD;
        this.task.contents = {
          ...this.task.contents,
          due_date: this.helpersService.getDueDateTimezonedHtml5(
            this.profile,
            new Date().toISOString().split('T')[0]
          ),
          priority: this.options.priorities[0].value,
          owner_id: this.profile._id,
          organisation_id: this.profile.contents.organisation_id,
        };
      } else {
        // edited task due date - preparation for displaying / it's converted back to date in tasksService onSave
        this.task.contents.due_date =
          this.helpersService.getDueDateTimezonedHtml5(
            this.profile,
            this.task.contents.due_date
          );
      }

      this._resolveEntities();
    }

    _resolveEntities(): void {
      this.task.assignee = this.task.assignee || {};
      this.task.place = this.task.place || ({} as APIStore);
      if (this.task.assignee._id) {
        this.onAssigneeChange(this.task.assignee);
      }
      this.onPlaceChange(this.task.place as APIStore);
    }

    isOwner(): boolean {
      return this.taskService.isOwnerOfTask(this.task, this.profile);
    }

    isOwnerOrAssignee(): boolean {
      return (
        this.isOwner() ||
        this.taskService.isAssigneeOfTask(this.task, this.profile)
      );
    }

    /**
     * Should add the type of the form as 'add' and generate a new id for the task.
     * The id is required in order to use PATCH even on creation of the task
     * @returns {string} Expects to return the generated task _id.
     */
    generateTaskId(): ObjectId {
      this.type = TASK_MANAGE_TYPE_ENUM.ADD;
      this.task._id = this.objectIdService.create();
      return this.task._id;
    }

    /**
     * Should add on the task model the assignee and set the task.contents.assignee_id the id from selected option.
     * It also set the label to be used in <sf-entity-selector> component.
     * It is also used to reset the value.
     * @param {Object<Assignee>} assignee - expects an assignee.
     * Assignee must be the same type as received on get task.
     * @returns {string} Expects to return the selected task assignee.
     */
    onAssigneeChange(assignee: Task['assignee']): Task['assignee'] {
      this.task.assignee = assignee;
      this.task.contents.assignee_id = assignee._id || null;
      this.task.assignee.label = assignee._id
        ? this.getAssigneeName(assignee)
        : '';
      this.setUsersSelectConfig();
      return this.task.assignee;
    }

    /**
     * Should add on the task model the place and set the task.contents.place_id the id from selected option.
     * It also set the label to be used in <sf-entity-selector> component.
     * It is also used to reset the value.
     * @param {Object<Place>} place - expects a place.
     * Place must be the same type as received on get task
     * @returns {string} Expects to return the selected task place.
     */
    onPlaceChange(place: APIStore): APIStoreContents {
      this.task.place = place.contents || place;
      this.task.contents.place_id = place._id || null;
      this.task.place['label'] = place._id ? this.getPlaceName(place) : '';
      this.setUsersSelectConfig();
      this.checkSubtaskAssigneesPlaceRelations();
      return this.task.place;
    }

    setUsersSelectConfig(): void {
      const placeId = this.task.contents.place_id;
      const disableUserSelectBtn = !placeId && !this.task.contents.assignee_id;
      this.usersSelectConfig = {
        placeId,
        users: this.users,
        disableUserSelectBtn,
      };
    }

    isNotificationInvalid(): boolean {
      const isValid = this.notificationsUtilsService.validateNotification(
        this.task.contents.due_date,
        this.task.contents.due_time,
        this.task.contents.notificationReminder
      );

      this.taskManageForm.$setValidity('notificationValidation', isValid);

      return !isValid;
    }

    checkSubtaskAssigneesPlaceRelations() {
      const placeId = this.task.contents.place_id;
      const usersIds =
        this.task.contents.subtasks
          ?.map((sub: Subtask) => sub.assignee_id || sub.assignee?._id)
          .filter(Boolean) ?? [];
      if (!usersIds.length) {
        return;
      }

      return this.placesService
        .checkPlaceUsersRelations(placeId, usersIds)
        .then((usersRelationsList) => {
          const nonRelatedSubtasks = (
            this.task.contents.subtasks as Subtask[]
          )?.filter((sub) => {
            const id = sub.assignee_id || sub.assignee?._id;
            return id ? !usersRelationsList[id] : false;
          });
          if (!nonRelatedSubtasks.length) {
            return;
          }
          const removeSubAssignees = () => {
            nonRelatedSubtasks.forEach((sub) => {
              Reflect.deleteProperty(sub, 'assignee');
              Reflect.deleteProperty(sub, 'assignee_id');
            });
          };
          const template = `
            <sf-popup-template
              title="::$ctrl.title"
              icon-name="'empty/no_pos'"
            >
              <p>{{ ::$ctrl.description }}</p>
              <ul>
                <li ng-repeat="subtask in $ctrl.subtasks">
                  <b>{{ subtask.name }}</b> - {{ subtask.assignee.contents.firstName }}
                  {{ subtask.assignee.contents.lastName }}
                </li>
              </ul>
            </sf-popup-template>
          `;
          const bindings = {
            subtasks: nonRelatedSubtasks,
            title: this.$translate.instant(
              'MANAGE_TASK_SUBTASKS_NONRELATED_USERS_TITLE'
            ),
            description: this.$translate.instant(
              'MANAGE_TASK_SUBTASKS_NONRELATED_USERS_DESCRIPTION'
            ),
          };
          const onCancelOptions = {
            text: this.$translate.instant('POPUP_OK'),
            action: removeSubAssignees,
            closeAction: removeSubAssignees,
          };

          this.popupService.showCustomTemplate({
            template,
            bindings,
            onCancelOptions,
          });
        });
    }

    trackSuccess(): void {
      if (!this.offlineMode) {
        this.segmentService.track('TASK_FORM', {
          action: 'send',
          label: 'succeed',
          task_id: this.task._id,
        });
      }
    }

    trackFail(err?): void {
      if (!this.offlineMode) {
        this.segmentService.track('TASK_FORM', {
          action: 'send',
          label: 'failed',
          task_id: this.task._id,
          value: err.status,
        });
      }
    }

    onPhotoSelected({ photos_ids }: { photos_ids: ObjectId[] }): void {
      this.task.contents.photos_ids = photos_ids;
    }
  },
};
