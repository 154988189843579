import { ObjectIdService } from '@services/Utils/Objectid/objectId.service';
import { PlatformService } from '@services/Utils/Platform';
import {
  NewsfeedPostResourceType,
  ResourceFile,
} from '../../../../newsfeed/types';
import { Answer } from '@services/API/reports/reports';
import { FileChooserService } from '@services/Utils/FileChooser/file-chooser.service';
import {
  FileValidatorService,
  NgfFile,
  ValidationError,
} from '@services/Utils/FileValidator/file-validator.service';
import { FilesService } from '@services/API/files/files.service';
import { FileSystemService } from '@services/Utils/FileSystem/file-system.service';
import { Popup, PopupService } from '@services/Utils/Popup/popup.service';
import {
  ActionSheetConfig,
  ActionSheetService,
} from '@services/Utils/ActionSheet/action-sheet.service';
import { FILES_UPLOAD_OPTIONS } from '@constants/files-upload-options.constant';
import { IPromise } from 'angular';
import { PubSubService } from '@services/Utils/PubSub/pubsub.service';

const LIBRARY_MAX_NB = 10;

export const QuestionDocumentComponent: ng.IComponentOptions = {
  bindings: {
    question: '<',
    report: '<',
    hasError: '<',
    questionOptions: '<',
    questionFolderName: '<',
    answers: '=',
    form: '<',
    place: '<',
    isDisabled: '<?',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-document/question-document.html',
  controller: class QuestionDocumentController implements ng.IController {
    isNew: boolean;
    isBrowser: boolean;
    report;
    answers: Answer[];
    question;
    sfQuestionForm;
    questionOptions;
    pattern: string;
    maxSize: string;
    maximumQuantity: number;
    form;
    formFilesPath: string;
    place;
    questionFolderName;
    hasError: boolean;
    processing = false;

    folderName: string;
    reportIsSend: boolean;
    isEditionDisabledByValidation: boolean;

    constructor(
      private objectIdService: ObjectIdService,
      private platformService: PlatformService,
      private $window: ng.IWindowService,
      private $q: ng.IQService,
      private SF_FILES_UPLOAD_OPTIONS: typeof FILES_UPLOAD_OPTIONS,
      private formsService,
      private filesSystemService: FileSystemService,
      private fileChooserService: FileChooserService,
      private fileValidatorService: FileValidatorService,
      private filesService: FilesService,
      private $translate: ng.translate.ITranslateService,
      private popupService: PopupService,
      private keyboardService,
      private actionSheetService: ActionSheetService,
      private pubSubService: PubSubService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.isBrowser = this.platformService.isBrowser();
      this.maxSize = this.SF_FILES_UPLOAD_OPTIONS.DOCUMENT.maxSize;
      this.pattern = this.SF_FILES_UPLOAD_OPTIONS.DOCUMENT.types;
      this.folderName = this.questionFolderName.toString();
      this.reportIsSend = !this.report.localStatus;
      this.isEditionDisabledByValidation =
        this.reportIsSend && this.question.validation;

      this.isNew =
        this.report.localStatus === 'draft' && !this.report.saved_date;

      this.formFilesPath = this.formsService.getFilesPath(this.form._id);

      this.preloadDocuments();
    }

    preloadDocuments(): void {
      this.answers?.forEach((answer) => {
        const [fileId, fileName, fileType, fileSize] = answer.values.map(
          ({ value }) => value
        );

        answer.temp = {
          ...answer.temp,
          fileBlob: {
            _id: fileId,
            name: fileName,
            size: fileSize,
            mime_type: fileType,
          },
          fileId,
          fileType,
        };

        if (this.report.validationState === 'TOREVISE') {
          answer.temp.fileBlob.forceFetch = true;
          answer.temp.fileType = 'blob';

          return;
        }

        this.tranformFileToBlob(answer.temp.fileBlob).then((blob) => {
          this.pubSubService.publish('FILE_PRELOADING_FINISHED', {
            status: true,
          });

          answer.temp.fileBlob = blob;
          answer.temp.fileBlob._id = answer.values[0].value;
          answer.temp.fileBlob.url = this.$window.URL.createObjectURL(blob);
          answer.temp.fileBlob.name = answer.values[1].value;
          answer.temp.fileType = 'blob';
        });
      });
    }

    tranformFileToBlob(file): IPromise<Blob> {
      const { ext = 'pdf' } = this.filesSystemService.getFileNameExt(file.name);
      this.pubSubService.publish('FILE_PRELOADING_FINISHED', { status: false });

      return this.filesSystemService
        .computeFileDataPath(this.formFilesPath, file._id, ext)
        .then((filePath) => this.filesSystemService.getBlobFromPath(filePath));
    }

    getFileId(answer: Answer): string {
      return this.filesSystemService.trimExtension(answer.temp.fileId);
    }

    deleteDocument(answer: Answer): void {
      this.keyboardService.hide();

      const actionSheetConfig: ActionSheetConfig = {
        cancelText: this.$translate.instant('FORM_ACTION_CANCEL'),
        destructiveText: this.$translate.instant('FORM_ACTION_DELETE'),
      };

      this.actionSheetService.open(null, actionSheetConfig, null, () => {
        this.answers = this.sfQuestionForm.removeAnswer(
          answer._id,
          this.answers
        );
      });
    }

    checkDocumentsQuantity(): void {
      this.setFormValid();
      if (this.answers.length > this.question.maximum) {
        this.setFormInvalid();
      }
    }

    showFileErrors(resourceErrors: ValidationError[]): Popup {
      const template = `
      <sf-newsfeed-resources-errors-popup
       title="$ctrl.title"
       resource-errors="$ctrl.resourceErrors" 
       hide-file-names="true"
       on-close="$ctrl.onClose()"
      >
      </sf-newsfeed-resources-errors-popup>
    `;
      const bindings = {
        title: this.$translate.instant('DOCUMENT_REQUIREMENT_ERROR_TITLE'),
        resourceErrors,
      };

      return this.popupService.showCustomTemplate({ template, bindings });
    }

    showErrors(invalidFiles: NgfFile[], maxSize: string): void {
      const parseTypes = (types: string): string => {
        const output = types.replace(/\./g, '').replace(/,/g, '", "');
        return `"${output}"`;
      };

      const errorMessage = {
        maxSize: this.$translate.instant(
          'NEWSFEED_EDIT_POST_RESOURCE_MAX_SIZE',
          {
            nb: maxSize,
          }
        ),
        maxFiles: this.$translate.instant(
          'NEWSFEED_EDIT_POST_RESOURCE_MAX_FILES'
        ),
        pattern: this.$translate.instant('DOCUMENT_ACCEPTED_TYPES_ERROR', {
          nb: parseTypes(this.pattern),
        }),
      };

      const errors = this.fileValidatorService.getFilesGroupedByErrors(
        invalidFiles,
        errorMessage
      );

      this.showFileErrors(errors);
    }

    getMaxDocumentQuantity(): number {
      const maximum = this.formsService.computeMaximum(
        this.question,
        this.form,
        this.report.contents.answers,
        { place: this.place }
      );

      return maximum === 'Infinity'
        ? LIBRARY_MAX_NB
        : this.getNbRemainingDocuments(maximum);
    }

    canAddDocument(): boolean {
      return this.questionOptions.max === 'Infinity'
        ? true
        : this.questionOptions.max > this.answers.length;
    }

    maxSelectedDocuments(): void {
      const max = this.formsService.computeMaximum(
        this.question,
        this.form,
        this.report.contents.answers,
        { place: this.place }
      );

      this.maximumQuantity =
        max === 'Infinity'
          ? LIBRARY_MAX_NB
          : this.getNbRemainingDocuments(this.maximumQuantity);
    }

    getNbRemainingDocuments(maximum: number): number {
      const nbRemaining = maximum - this.answers.length;

      return LIBRARY_MAX_NB <= nbRemaining ? LIBRARY_MAX_NB : nbRemaining;
    }

    selectDocumentOnWeb(
      documents: ResourceFile[],
      invalidFiles: NgfFile[]
    ): void {
      if (invalidFiles.length > 0) {
        this.showErrors(invalidFiles, this.maxSize);
      }

      documents.map((doc: ResourceFile) => {
        const { name } = doc;
        doc._id = this.objectIdService.create();
        doc.url = this.$window.URL.createObjectURL(doc);

        const fileInfos = {
          fileId: doc._id,
          blob: doc,
          path: this.$window.URL.createObjectURL(doc),
          name,
          size: doc.size,
          mimeType: doc.type,
        };
        this.addFileToAnswers(fileInfos, 'blob');

        return true;
      });
    }

    selectDocumentOnMobile(): void {
      this.fileChooserService
        .getDocument()
        .then((blob: Blob | File) =>
          this.getValidFromSelectedFiles([blob], 'document')
        )
        .then((blobs) => this.handleFiles(blobs as ResourceFile[]));
    }

    handleFiles(files: ResourceFile[]): void {
      this.$q.all(
        files.map((file) => {
          return this.handleFile(file)
            .then((fileInfo) => this.addFileToAnswers(fileInfo, 'file'))
            .then((answer) =>
              this.$q.all([answer._id, this.getLocalPath(answer)])
            );
        })
      );
    }

    handleFile(file: ResourceFile) {
      const fileId = this.objectIdService.create();
      const { ext = 'pdf' } = this.filesSystemService.getFileNameExt(file.name);

      return this.filesService
        .saveTempFile(file, {
          path: this.formFilesPath,
          name: `${fileId}.${ext}`,
        })
        .then((fileInfo) => {
          const info = {
            fileId,
            name: file.name,
            path: fileInfo.uri,
            blob: fileInfo.file,
            size: fileInfo.file.size,
            mimeType: fileInfo.file.type,
          };

          return info;
        });
    }

    getLocalPath(answer) {
      const answerFileWithExtension = answer.values[1];
      const extension = this.filesSystemService.getExtension(
        answerFileWithExtension.value
      );
      const ext = extension || '';
      return this.filesSystemService
        .computeFileDataPath(
          this.questionFolderName,
          answer.values[0].value,
          ext
        )
        .then((localPath) => {
          const path = extension ? localPath : localPath.replace(/\.$/, '');
          return path;
        });
    }

    addFileToAnswers(
      { fileId, name, path, blob, size, mimeType },
      fileType
    ): Answer {
      const fields = this.question.fields;
      const values = [
        { value: fileId, field_id: fields[0]._id },
        { value: name, field_id: fields[1]._id },
        { value: mimeType, field_id: fields[2]._id },
        { value: size, field_id: fields[3]._id },
      ];

      this.answers = this.sfQuestionForm.addAnswer(values, this.answers, {
        type: 'document',
        temp: {
          needSync: true,
          filePath: path,
          fileBlob: blob,
          fileType: 'blob',
          fileId,
        },
      });

      const answer = this.answers.filter(
        (answer) => answer.values[0].value === fileId
      )[0];

      this.checkDocumentsQuantity();
      this.setFormDirty();

      return answer;
    }

    setFormDirty(): void {
      const documentForm = this[`document_${this.question._id}`];

      return documentForm ? documentForm.$setDirty(true) : false;
    }

    setFormValid(): void {
      const documentForm = this[`document_${this.question._id}`];

      return documentForm
        ? documentForm.$setValidity('max_documents', true)
        : false;
    }

    setFormInvalid(): void {
      const documentForm = this[`document_${this.question._id}`];

      return documentForm
        ? documentForm.$setValidity('max_documents', false)
        : false;
    }

    private getValidFromSelectedFiles(
      blobs: Blob[],
      type: NewsfeedPostResourceType
    ) {
      const { valid, invalid } = this.fileValidatorService.filterByValidity(
        blobs,
        type,
        this.maximumQuantity
      );

      if (invalid.length > 0) {
        this.showErrors(invalid, this.maxSize);
      }

      return valid;
    }
  },
};
