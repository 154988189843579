export const ContextualGraphComponent = {
  bindings: {
    data: '<',
    id: '<',
  },
  transclude: true,
  templateUrl:
    'missions/components/Scoring/contextual-graph/contextual-graph.html',
  controller: class ContextualGraphController {
    constructor(sfChartService) {
      'ngInject';
      Object.assign(this, {
        sfChartService,
      });
    }

    $onInit() {
      this.data = this.data
        .filter((score) => score.data.denominator)
        .map((score) => {
          if (score.data.numerator < 0) {
            score.data.numerator = 0;
            score.data.percent = 0;
          }
          return score;
        });

      this.graphType = this.sfChartService.defineGraphType(this.data);

      if (this.graphType === 'scatter') {
        this.scatterData = this.sfChartService.dataToOnePointScatter(this.data);
      }
    }
  },
};
