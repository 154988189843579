export const QuestionRatingComponent = {
  bindings: {
    question: '<',
    hasError: '<',
    questionOptions: '<',
    answers: '=',
    actionClickedFn: '=?',
    previousAnswers: '<',
    isDisabled: '<?',
    translations: '<',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-rating/question-rating.html',
  controller: function QuestionRatingController(
    $filter,
    FORM_QUESTION_ACTIONS
  ) {
    'ngInject';

    this.$onInit = () => {
      this.isNA = false;
      this.value = this.getAnswer();
      this.actionClickedFn = this.actionClicked;
      this.legends = {
        lower: $filter('sfTranslation')(
          this.question.metadata.lowerLegend,
          this.translations
        ),
        higher: $filter('sfTranslation')(
          this.question.metadata.higherLegend,
          this.translations
        ),
      };

      this.resetRatingValue = () => {};
    };

    this.getAnswer = () => {
      const firstAnswer = this.getFirstAnswer();

      if (firstAnswer && firstAnswer.values[0].value === null) {
        this.isNA = true;
        this.hasAnswer = true;
      }

      return firstAnswer ? firstAnswer.values[0].value : null;
    };

    this.onValueChange = (value) => {
      const removeValue = value === null || {}.undef === value;

      const remove = () => {
        if (!this.answers || (this.answers && !this.answers.length)) return;

        const firstAnswer = this.getFirstAnswer();

        this.sfQuestionForm.removeAnswer(firstAnswer._id, this.answers || []);
      };

      const save = () =>
        this.sfQuestionForm.saveAnswer(
          this.getValue(value),
          this.answers || []
        );

      this.setFormDirty();

      this.answers = removeValue ? remove() : save();
    };

    this.getValue = (value) => {
      var newValue = typeof value === 'number' ? parseFloat(value) : null;

      return [
        {
          field_id: this.question.fields[0]._id,
          value: newValue,
        },
      ];
    };

    this.getFirstAnswer = () => (this.answers.length ? this.answers[0] : null);

    this.setFormDirty = () => {
      const ratingForm = this[`rating_${this.question._id}`];

      return ratingForm ? ratingForm.$setDirty(true) : false;
    };

    this.actionClicked = (action) => {
      switch (action) {
        case FORM_QUESTION_ACTIONS.RESET:
          return this.resetValue();
        case FORM_QUESTION_ACTIONS.RESET_PREFILL:
          return this.resetToPrefillValue();
        default:
          return false;
      }
    };

    this.resetValue = () => {
      this.value = null;
      this.resetRatingValue();
      this.onValueChange(this.value);
    };

    this.resetToPrefillValue = () => {
      if (this.previousAnswers && this.previousAnswers.length) {
        this.value = this.previousAnswers[0].values[0].value;
        this.onValueChange(this.value);
      }
    };
  },
};
