import { ObjectId, User } from '../../..';
import { APIStore } from '../../../places';
import { Form, FormQuestion } from '../../../services/API/forms/forms';
import { Report } from '../../../services/API/reports/reports';
import { TasksService } from '../../../services/API/tasks/tasks.service';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';
import { AutoTask, Task } from '../../../tasks';
import { TaskService } from '../../../tasks/services/task.service';
import { TaskManageService } from '../../../tasks/task-manage/services/task-manage.service';

export class FormTasksService {
  tasks: Task[];
  pendingTasks: Task[];
  profile: User;
  isOffline = false;

  constructor(
    private tasksService: ReturnType<typeof TasksService>,
    private taskService: TaskService,
    private taskManageService: TaskManageService,
    private pubSubService: PubSubService,
    private pingService,
    private SF_ERROR_CODES
  ) {
    'ngInject';
  }

  init(
    form: Form,
    report: Report,
    profile: User,
    place: APIStore
  ): ng.IPromise<void> {
    this.tasks = [];
    this.pendingTasks = [];
    this.profile = profile;
    return this.pingService
      .ping()
      .catch(({ status }) => {
        this.isOffline =
          this.SF_ERROR_CODES.CODES_SERVER_NO_NETWORK.includes(status);
      })
      .then(() => this.getTasks(report._id))
      .then((tasks) => {
        this.tasks = tasks;
        this.getPendingTasks(form, report, tasks, place);
      });
  }

  clean(): void {
    this.tasks = [];
    this.pendingTasks = [];
  }

  getQuestionTasks(questionId: ObjectId): {
    tasks: Task[];
    pendingTasks: Task[];
  } {
    return {
      tasks: this.tasks.filter(
        (task) => task.contents.question_id === questionId
      ),
      pendingTasks: this.pendingTasks.filter(
        (task) => task.contents.question_id === questionId
      ),
    };
  }

  getReportTasks(): { tasks: Task[]; pendingTasks: Task[] } {
    return {
      tasks: this.tasks,
      pendingTasks: this.pendingTasks,
    };
  }

  private getTasks(reportId: ObjectId): ng.IPromise<Task[]> {
    const requestFilters = [{ name: 'report_id', value: reportId }];

    return this.tasksService
      .getReportTasks(requestFilters, this.isOffline, reportId)
      .then((response) => this.addRelatedUsers(response))
      .then((tasks) => this.tasksService.sortTasks(tasks, this.profile));
  }

  private addRelatedUsers({
    tasks,
    users,
  }: {
    tasks: Task[];
    users: User[];
  }): Task[] {
    return tasks.map((task) => {
      if (!task.contents.assignee_id) {
        return task;
      }
      return {
        ...task,
        user: users[task.contents.assignee_id],
      };
    });
  }

  private getPendingTasks(
    form: Form,
    report: Report,
    tasks: Task[],
    place: APIStore
  ): void {
    form.contents.questions
      .filter((question) => question.metadata.autoTasks)
      .reduce(
        (acc, question) => [
          ...acc,
          ...(question.metadata.autoTasks as AutoTask[]).map((autoTask) => ({
            ...question,
            metadata: {
              ...question.metadata,
              autoTask,
            },
          })),
        ],
        [] as FormQuestion[]
      )
      .forEach((question) => {
        const hasCreatedAutoTask = tasks.find(
          (task) =>
            task.autoTask_id && task.contents.question_id === question._id
        );
        if (hasCreatedAutoTask) {
          return;
        }
        const alreadyExists = tasks.some(
          (t) =>
            t.contents.question_id === question._id &&
            t.contents.report_id === report._id &&
            t.contents.due_date
        );
        if (alreadyExists) {
          return;
        }

        const answer = report.contents.answers.find(
          ({ question_id }) => question_id === question._id
        )?.values[0].value as string | number;
        const answerMatched = this.taskService.metAutotaskCondition(
          answer,
          question.metadata.autoTask as AutoTask
        );
        if (!answerMatched) {
          return;
        }

        place = place || {};
        this.createAutotask(
          form,
          report,
          question,
          place,
          question.metadata.autoTask as AutoTask
        );
      });
  }

  createAutotask(
    form: Form,
    report: Report,
    question: FormQuestion,
    taskPlace: APIStore,
    autoTask: AutoTask
  ): void {
    // place can be null so it's not a case for a default argument
    const place = taskPlace || ({ contents: {} } as APIStore);
    const pendingTask = this.taskManageService.resolveTask({
      profile: this.profile,
      question,
      form,
      report,
      place,
      taskTemplate: autoTask.template,
      autoTask_id: autoTask._id,
      assignee: place._id ? undefined : this.profile, // for users only checklists the place is missing, so the task assignee is mandatory
    });
    this.pendingTasks.push(pendingTask);
  }

  removeAutotask(autotask: Task, questionId: ObjectId): void {
    if (!autotask) {
      this.tasks = this.tasks.filter(
        (task) => !(task.contents.question_id === questionId && task.isAutotask)
      );
      return;
    }
    this.pendingTasks = this.pendingTasks.filter(
      (task) => task._id != autotask._id
    );
  }

  deleteTask(taskId: ObjectId, emit = true): void {
    this.tasks = this.tasks.filter((task) => task._id != taskId);
    this.tasksService.remove(taskId, this.isOffline);
    emit && this.pubSubService.publish('REPORT_TASKS_CHANGED', { taskId });
  }

  addTask(task: Task, emit = true): void {
    this.tasks.push(task);
    emit &&
      this.pubSubService.publish('REPORT_TASKS_CHANGED', { taskId: task._id });
  }

  addAutoTask(task: Task, emit = true): void {
    this.pendingTasks = this.pendingTasks.filter(
      (pTask) => pTask._id !== task._id
    );
    this.taskManageService.saveTask(task, this.isOffline);
    this.tasks.push(task);
    emit &&
      this.pubSubService.publish('REPORT_TASKS_CHANGED', { taskId: task._id });
  }

  skipAutotask(taskId: ObjectId, emit = true): void {
    this.pendingTasks = this.pendingTasks.filter(
      (pTask) => pTask._id !== taskId
    );
    emit && this.pubSubService.publish('REPORT_TASKS_CHANGED', { taskId });
  }

  updateTasks(changedTask: Task, emit = true): void {
    const tasks = this.tasks.map((task) => {
      if (task._id === changedTask._id) {
        this.taskManageService.saveTask(changedTask, this.isOffline);
        return { ...changedTask }; // need to be a new object to run change detection
      }
      return task;
    });

    this.tasks = this.tasksService.sortTasks(tasks, this.profile);
    emit && this.pubSubService.publish('REPORT_TASKS_CHANGED');
  }
}
