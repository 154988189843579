export const DEFAULT_PARAMETERS = {
    appServer: 'https://app.luzmo.com/',
    apiHost: 'https://api.luzmo.com/',
    dashboardId: null,
    dashboardSlug: null,
    container: null,
    key: null,
    token: null,
    language: 'auto',
    qeVersion: null,
    editMode: null,
    screenMode: null,
    switchScreenModeOnResize: true,
    itemId: null,
    mainColor: '',
    accentColor: '',
    itemDimensions: {
        width: 'auto',
        height: 'auto'
    },
    loaderBackground: '#f9f9f9',
    loaderFontColor: '#5a5a5a',
    loaderSpinnerColor: 'rgba(255, 165, 0, 0.7)',
    loaderSpinnerBackground: 'rgba(169, 169, 169, 0.14)',
    timezoneId: null
};
