export const ChatCreateComponent = {
  bindings: {
    profile: '<',
    onClose: '&',
    onChatRoomCreate: '&',
  },
  templateUrl: 'chat/chat/components/chat-create/chat-create.html',
  controller: function ChatCreateController(
    localizationService,
    $q,
    $translate,
    chatPopupService,
    chatService
  ) {
    'ngInject';

    this.$onInit = () => {
      this.isRTLNeeded = localizationService.shouldActivateRTL();
      this.usersIds = new Set();
      this.networkError = false;
      this.selectUsersFilters = [
        { name: 'user_id', value: this.profile._id, operator: '$ne' },
      ];
    };

    this.createChannel = () => {
      const usersIds = [].concat(Array.from(this.usersIds), this.profile._id);

      const createChatPromise =
        this.usersIds.size === 1
          ? { promptPromise: $q.when() }
          : chatPopupService.promptChannelName(
              $translate.instant('CHAT_NAME_CONVERSATION_TITLE'),
              chatService.CONVERSATION_TITLE_MAX_LENGTH
            );

      return createChatPromise.promptPromise.then((groupName) => {
        const progressPopup = chatPopupService.showChannelCreatingProgress();
        const createChatChannel = () =>
          chatService
            .createChannel(usersIds, groupName)
            .then((channel) =>
              progressPopup.onSuccess().then(() => {
                this.onChatRoomCreate({ channel });
                this.onClose();
              })
            )
            .catch((err) => {
              progressPopup.onError(createChatChannel);
              throw err;
            });

        return createChatChannel();
      });
    };

    this.onUserSelect = (userId) => this.usersIds.add(userId);

    this.onUserUnselect = (userId) => this.usersIds.delete(userId);

    this.getUserNumberLimit = () =>
      chatService.CHAT_CHANNEL_USER_NUMBER_LIMIT - 1;

    this.getUserNumber = () => this.usersIds.size;

    this.isUserNumberLimitExceeded = () =>
      this.getUserNumber() > this.getUserNumberLimit();

    this.onUserListLoadingSuccess = () => {
      this.networkError = false;
    };

    this.onUserListLoadingError = () => {
      this.networkError = true;
    };
  },
};
