import { FileSystemService } from '@services/Utils/FileSystem/file-system.service';
import { PlatformService } from '@services/Utils/Platform';
import { PopupService } from '@services/Utils/Popup/popup.service';
import { StateService, TransitionPromise } from '@uirouter/core';
import { User } from '../../../index';
import {
  Campaign,
  SimplifiedCampaignObjectiveCompletion,
} from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';
import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';
import { CampaignsUtilsService } from '../../services/campaigns-utils.service';

const PARALLAX_HEIGHT = 200;
const DISK_SPACE_MINIMAL = 309715200;

export const ReactiveCampaignDetailsComponent: ng.IComponentOptions = {
  bindings: {
    campaign: '<',
    profile: '<',
  },
  templateUrl:
    'reactive-campaigns/components/reactive-campaign-details/reactive-campaign-details.html',
  controller: class ReactiveCampaignDetailsController implements ng.IController {
    /** Bindings */
    campaign: Campaign;
    profile: User;

    /** Fields */
    completion: SimplifiedCampaignObjectiveCompletion;
    coverImageUrl: string;
    currentStickyHandle: string;
    isLoading = false;
    isReportsLoaded = false;
    objectiveLabel: {
      title?: string;
    };
    objectivePercent: number;
    objectiveColorClass: string;
    objectiveLabelColorClass?: string;
    parallaxHeight: number;
    remainingTime = '';
    campaignFilesPath: string;
    requestFilters: {
      name: string;
      value: string;
    }[];

    isObjectiveCompleted = false;
    shouldCompleteButtonBeDisabled = true;
    buttonTranslation = 'REACTIVE_CAMPAIGN_LOADING';
    frequencyDue;
    displayDueTag = false;

    constructor(
      private $state: StateService,
      private $translate: ng.translate.ITranslateService,
      private campaignsService: CampaignsService,
      private campaignsUtilsService: CampaignsUtilsService,
      private filesSystemService: FileSystemService,
      private objectIdService: ObjectIdService,
      private platformService: PlatformService,
      private popupService: PopupService,
      private $q: ng.IQService,
      private SF_ERROR_CODES
    ) {
      'ngInject';

      this.parallaxHeight = PARALLAX_HEIGHT;
    }

    $onInit(): ng.IPromise<[void, void]> {
      this.checkDiskSpace();
      this.requestFilters = [
        {
          name: 'campaign_id',
          value: this.campaign._id,
        },
        {
          name: 'status',
          value: 'todo',
        },
      ];
      this.campaignFilesPath = this.campaignsService.getFilesPath(
        this.campaign
      );
      this.coverImageUrl = 'img/background_medium.png';

      this.remainingTime = this.campaignsUtilsService.getRemainingTime(
        this.campaign
      );

      if (this.campaign.contents.objective?.activationPeriod) {
        this.frequencyDue =
          this.campaignsUtilsService.initFrequencyActivePeriodDates(
            this.campaign,
            !!this.campaign.contents.objective?.perMonth?.length
          ).due;

        this.displayDueTag =
          this.frequencyDue.diff(new Date(), 'hours') < 24 &&
          this.campaignsUtilsService.isFrequencyActivePeriod(this.campaign);
      }

      if (!this.campaign.contents.objective) {
        this.shouldCompleteButtonBeDisabled = false;
        this.buttonTranslation = 'REACTIVE_CAMPAIGN_START';
      }

      return this.$q.all([this.getCampaignCover(), this.getObjectiveData()]);
    }

    getFrequencyDueTime() {
      const { perDay, activationPeriod } = this.campaign.contents.objective!;

      return perDay ? activationPeriod.due : null;
    }

    checkDiskSpace(): ng.IPromise<boolean | void> {
      if (this.platformService.isBrowser()) {
        return this.$q.resolve();
      }

      return this.filesSystemService
        .checkDeviceFreeSpace(DISK_SPACE_MINIMAL)
        .catch(({ code }) => {
          if (code && code === this.SF_ERROR_CODES.OUT_OF_SPACE) {
            this.popupService.alert(
              '',
              this.$translate.instant('REACTIVE_CAMPAIGN_NOT_ENOUGH_SPACE')
            );
          }
        });
    }

    startCampaign(): ng.IPromise<void> | TransitionPromise {
      const stateParams = {
        reportId: this.objectIdService.create(),
        campaignId: this.campaign._id,
        ...(this.$state.params.referer
          ? { referer: this.$state.params.referer }
          : {}),
      };

      if (this.campaign.contents.subjectType === 'user') {
        return this.$state.go(
          'index.menu-more.reactive-campaigns.form',
          stateParams
        );
      }

      if (this.$state.params.placeId) {
        const stateParams = {
          reportId: this.objectIdService.create(),
          campaignId: this.campaign._id,
          locationId: this.$state.params.placeId,
          ...(this.$state.params.referer
            ? { referer: this.$state.params.referer }
            : {}),
        };

        return this.$state.go(
          'index.menu-more.reactive-campaigns.form',
          stateParams
        );
      }

      return this.campaignsUtilsService.openStoreSelectorModal(
        this.campaign,
        this.$state.params.referer
      );
    }

    onHideLoading() {
      this.isLoading = false;
      this.isReportsLoaded = true;
    }

    onTabClick(tabName: string): void {
      this.isLoading = tabName === 'campaignReports' && !this.isReportsLoaded;
      this.currentStickyHandle = tabName;
    }

    getObjectiveData(): ng.IPromise<void> {
      return this.campaignsService
        .getCampaignObjectiveCompletion(this.campaign._id)
        .then((completion) => {
          if (!completion) {
            this.shouldCompleteButtonBeDisabled = false;
            this.buttonTranslation = 'REACTIVE_CAMPAIGN_START';
            return undefined;
          }

          this.completion = completion;
          this.campaign.statistics.unshift(completion);

          this.isObjectiveCompleted =
            this.campaign.statistics.objectivesCompletionStatus === 'COMPLETED';
          this.shouldCompleteButtonBeDisabled = this.isObjectiveCompleted;

          if (this.$state.params.placeId) {
            const campaignObjective =
              this.campaign.contents.objective?.perStore || 1;
            const storeObjective =
              this.campaign.statistics.data?.reportsByStore[
                this.$state.params.placeId
              ] || 0;
            this.shouldCompleteButtonBeDisabled =
              campaignObjective <= storeObjective;
          }

          if (
            this.campaignsUtilsService.isRoutineChecklist(this.campaign) &&
            !this.campaignsUtilsService.isFrequencyActivePeriod(this.campaign)
          ) {
            this.shouldCompleteButtonBeDisabled = true;
          }
          this.buttonTranslation = 'REACTIVE_CAMPAIGN_START';

          this.objectivePercent =
            this.campaignsUtilsService.getObjectiveCompletionPercent(
              completion
            );

          if (
            this.campaignsUtilsService.isPerStoreObjectiveSimplifiedCompletion(
              this.completion
            )
          ) {
            this.objectiveLabel = {
              title: `${this.completion.perStore.storesCovered}/${this.completion.perStore.storesIncluded}`,
            };
          }

          if (
            this.campaignsUtilsService.isPerUserObjectiveSimplifiedCompletion(
              this.completion
            )
          ) {
            this.objectiveLabel = {
              title: `${this.completion.perUser.reportsReceived}/${this.completion.perUser.reportsExpected}`,
            };
          }

          if (this.objectivePercent === 0) {
            this.setEmptyObjectiveDesign();
          } else if (this.objectivePercent === 100) {
            this.setFullObjectiveDesign();
          } else {
            this.setPartialObjectiveDesign();
          }
        });
    }

    getCampaignCover(): ng.IPromise<void> {
      if (!this.campaign.contents.picture) {
        return this.$q.resolve();
      }

      return this.campaignsService
        .getCampaignCoverUrl(this.campaign, 'medium')
        .then((url) => {
          this.coverImageUrl = url;
        });
    }

    setEmptyObjectiveDesign(): void {
      this.objectiveColorClass = 'sf_progressCircle__percentCircle--sfGrey';
      this.objectiveLabelColorClass = 'sf_progressCircle__label--sfGrey';
      // To set the full circle in the same color
      this.objectivePercent = 100;
    }
    setPartialObjectiveDesign(): void {
      this.objectiveColorClass = 'sf_progressCircle__percentCircle--sfRed';
      this.objectiveLabelColorClass = undefined;
    }
    setFullObjectiveDesign(): void {
      this.objectiveColorClass = 'sf_progressCircle__percentCircle--sfGreen';
      this.objectiveLabelColorClass = 'sf_progressCircle__label--sfGreen';
    }
  },
};
