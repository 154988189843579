import { PovService } from '../POV/pov.service';
import { IAPIEventsList } from './events';

export class EventsAPIService {
  /* @ngInject */
  constructor(
    private readonly $http: ng.IHttpService,
    private readonly sfPOVService: PovService
  ) {}

  getNextEvents(params = {}): ng.IPromise<IAPIEventsList> {
    return this.sfPOVService
      .pBuildURL('/next-events', { pov: 'organisation' })
      .then((url) =>
        this.$http
          .get<IAPIEventsList>(url, { params })
          .then((result) => result.data)
      );
  }
}
