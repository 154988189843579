import { hasPath, path } from 'ramda';
import type { User } from '../..';
import { ObjectId } from '../..';
import type { Comment } from '../../comments';
import {
  ActionOptions,
  TaskCommentsActionsService,
} from '../services/task-comments-actions.service';

export const TaskCommentsComponent: ng.IComponentOptions = {
  bindings: {
    profile: '<',
    comments: '<',
    commentsUser: '<',
    isCommentsLoading: '<',
    hasCommentsErrors: '<',
    editableCommentId: '<',
    onDeleteComment: '&',
    onEditComment: '&',
    onReload: '&',
  },
  templateUrl: 'tasks/task-comments/task-comments.html',
  controller: class TaskCommentsController implements ng.IComponentController {
    onDeleteComment: (params: { commentId: ObjectId }) => void;
    onEditComment: (params: { commentId: ObjectId }) => void;
    comments: Comment[];
    profile: User;
    commentsActions: Record<string, ActionOptions>;
    commentsUser: Record<string, User>;
    editableCommentId: ObjectId;

    constructor(
      private taskCommentsActionsService: TaskCommentsActionsService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.commentsActions = this.getCommentsActions();
    }

    $onChanges(changes: ng.IOnChangesObject): void {
      if (changes.comments) {
        this.commentsActions = this.getCommentsActions();
      }
    }

    getCommentsActions(): Record<string, ActionOptions> {
      return this.comments.reduce((acc, comment) => {
        const { _id } = comment;

        acc[_id] = this.taskCommentsActionsService.getCommentActions({
          author_id: path<string>(['contents', 'author_id'])(comment),
          isPicture: hasPath(['contents', 'picture_id'])(comment),
          profile: this.profile,
          callbacks: {
            onDelete: () => this.onDeleteComment({ commentId: _id }),
            onEdit: () => this.onEditComment({ commentId: _id }),
          },
        });

        return acc;
      }, {});
    }

    getUserFromComment(userId: string): User {
      return this.commentsUser[userId];
    }
  },
};
