import { SfFeatureFlags } from '@simplifield/feature-flags';
import moment from 'moment';
import { GroupTaskApiService } from './group-tasks-api.service';
import { FEATURE_FLAGS } from '../../constants/feature-flags.constant';
import { AccessRightsService } from '../../services/Utils/AccessRights/access-rights.service';
import { DateService } from '../../services/Utils/Dates/date.service';
import {
  APIGroupTask,
  ApiGroupTaskMeta,
  APIListGroupTasks,
  GroupTask,
} from '../types';

type RequestFilter = {
  name: string;
  value: string;
};

export class GroupTasksService {
  constructor(
    private groupTasksApiService: GroupTaskApiService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private accessRightsService: AccessRightsService,
    private apiUtilsService: {
      buildFilterParams(filters: Record<string, unknown>[]): {
        filters: { $and: Record<string, unknown>[] };
      };
    },
    private dateService: DateService
  ) {
    'ngInject';
  }

  completeGroupTasks(partialGroupTask: Partial<GroupTask>): GroupTask {
    return {
      entityType: 'places',
      entities: [],
      entityGroups: [],
      ...partialGroupTask,
      template: {
        name: 'defaultName',
        due_date: new Date().toISOString(),
        priority: 0,
        ...partialGroupTask.template,
      },
    };
  }

  get(options: {
    requestFilters: RequestFilter[];
    search?: string;
  }): ng.IPromise<APIListGroupTasks> {
    const params = this.buildRequestParams(options);
    return this.groupTasksApiService.getGroupTasks(params);
  }

  getGroupTasksById(grouptaskId: string): ng.IPromise<ApiGroupTaskMeta> {
    return this.groupTasksApiService.getGroupTasksById(grouptaskId);
  }

  delete(grouptaskId: string): ng.IPromise<void> {
    return this.groupTasksApiService.deleteGroupTasks(grouptaskId);
  }

  create(groupTasks: GroupTask): ng.IPromise<APIGroupTask> {
    return this.groupTasksApiService.createGroupTasks(groupTasks);
  }

  buildRequestParams({ requestFilters, search = '' }) {
    const sortParams = [
      'contents.dateOnly',
      '-contents.priority',
      'contents.lowerName',
    ];
    const groupTasksFilters = this.buildGroupTasksFilters(requestFilters);
    const filters = [
      ...(groupTasksFilters || []),
      ...(search ? [{ name: 'name', value: search, operator: '$regex' }] : []),
    ];

    return {
      sorts: sortParams,
      ...this.apiUtilsService.buildFilterParams(filters),
    };
  }

  buildGroupTasksFilters(filters: Record<string, unknown>[]): any {
    const getFilterValue = (filter: any) => {
      if (filter.name !== 'due_date') {
        return filter;
      }
      const date = new Date(filter.value);
      const startOfTheDay = this.dateService.getUtcStartOfDate(date);
      const endOfTheDay = this.dateService.getUtcEndOfDate(date);

      return {
        name: filter.name,
        operator: '$between',
        value: [startOfTheDay, endOfTheDay],
      };
    };

    return filters && filters.length ? filters.map(getFilterValue) : [];
  }

  hasFeatureFlag(): boolean {
    return this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.GROUP_TASKS
    );
  }

  canUseGroupTasks(): boolean {
    return this.hasFeatureFlag() && this.accessRightsService.isAtLeastManager();
  }
}
