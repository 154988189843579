export const ReportNavigationHeaderComponent = {
  bindings: {
    nb: '<',
    nbMax: '<',
    openNavigation: '&?',
    nextStep: '&?',
    prevStep: '&?',
  },
  templateUrl:
    'reports/components/report-navigation/report-navigation-header/report-navigation-header.html',
};
