"use strict";

var schemaUtils = require('../utils/schema');

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  title: 'Task',
  type: 'object',
  additionalProperties: false,
  required: ['organisation_id', 'owner_id', 'assignee_id', 'due_date', 'name', 'status', 'priority'],
  properties: {
    name: {
      type: 'string',
      minLength: 1,
      maxLength: 120
    },
    description: {
      type: 'string',
      maxLength: 1000
    },
    status: {
      type: 'string',
      "enum": ['todo', 'done']
    },
    priority: {
      type: 'number',
      "enum": [0, 10, 20]
    },
    due_date: {
      type: 'string',
      format: 'date-time'
    },
    organisation_id: schemaUtils.oneToOne(),
    owner_id: schemaUtils.oneToOne(),
    assignee_id: schemaUtils.oneToOne(),
    place_id: schemaUtils.oneToOne(),
    form_id: schemaUtils.oneToOne(),
    question_id: schemaUtils.oneToOne(),
    report_id: schemaUtils.oneToOne()
  }
};