import { PlaceMissionsSectionController } from './place-missions-section.controller';

export const PlaceMissionsSectionComponent = {
  bindings: {
    emptyConfig: '<',
    missionsForms: '<',
    missionType: '@',
    placeId: '<',
    title: '@',
    getMissionsLength: '&?',
  },
  templateUrl:
    'places/place/components/place-missions-section/place-missions-section.html',
  controller: PlaceMissionsSectionController,
};
