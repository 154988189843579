type PopupAction = {
  text: string;
  type?: string;
  action: () => void;
  closeAction?: () => void;
};
type PopupBindings = Record<string, unknown>;
type PopupCtrl = ng.IScope & {
  $ctrl?: PopupBindings;
};
type PopupOptions = {
  btnFirstOption?: string;
  title: string;
  iconName?: string;
  long?: boolean;
};

export interface Popup extends ng.IPromise<void> {
  close: () => void;
}

export type DownloadPopup = {
  state: { loaded: number };
  onProgress: (percent: number) => void;
  onSuccess: (text: string) => ng.IPromise<void | null>;
  onError: (errorOptions: PopupOptions, onclick: () => void) => void;
};

export class PopupService {
  // eslint-disable-next-line max-params
  constructor(
    private $q: ng.IQService,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService,
    private $translate: ng.translate.ITranslateService,
    private $ionicPopup: IonicV1.IonicPopupService,
    private popupConstant
  ) {
    'ngInject';
  }

  /**
   * Display a simple popup with content
   */
  show(options: PopupOptions & { actions: PopupAction[] }): Popup {
    const template = this.computePopupIconTemplate(options);

    return this.computeIonicPopupShow(template, options.actions);
  }

  /**
   * Display a popup with a cancel button
   */
  showError(
    options: PopupOptions & { btnText?: string; desc?: string },
    onCancel?: () => void
  ): Popup {
    const templateOptions = {
      ...options,
      iconName: options.iconName || 'error',
    };
    const template = this.computePopupIconTemplate(templateOptions);
    const popup = this.computeIonicPopupShow(template, [
      {
        text: options.btnText || this.$translate.instant('POPUP_CANCEL'),
        type: ' ',
        action: () => {
          popup.close();
          onCancel ? onCancel() : null;
        },
      },
    ]);

    return popup;
  }

  /**
   * Display a popup with a Ok button
   */
  showInfo(
    options: PopupOptions & { btnText?: string; desc?: string },
    onCancel?: () => void
  ): Popup {
    const templateOptions = {
      ...options,
      iconName: options.iconName || 'item-info',
    };
    const template = this.computePopupIconTemplate(templateOptions);
    const popup = this.computeIonicPopupShow(template, [
      {
        text: options.btnText || this.$translate.instant('POPUP_OK'),
        type: ' ',
        action: () => {
          popup.close();
          onCancel ? onCancel() : null;
        },
      },
    ]);

    return popup;
  }
  /**
   * Display a popup with a custom template and a cancel button
   */
  showCustomTemplate({
    template,
    bindings = {},
    actions = [],
    onCancelOptions,
  }: {
    template: string;
    bindings: Record<string, any>;
    actions?: PopupAction[];
    onCancelOptions?: PopupAction;
  }): Popup {
    const popupActions = [
      ...actions.map((actionCfg) => {
        const newAction = () => {
          popup.close();
          return actionCfg.action();
        };

        return { ...actionCfg, action: newAction };
      }),
    ];

    if (onCancelOptions) {
      const cancelAction = {
        text: onCancelOptions?.text || this.$translate.instant('POPUP_CANCEL'),
        action: () => {
          popup.close();
          return onCancelOptions?.action();
        },
      };

      popupActions.push(cancelAction);
    }

    const popup = this.computeIonicPopupShow(template, popupActions, {
      ...bindings,
      onClose: () => {
        popup.close();
        return onCancelOptions?.closeAction && onCancelOptions.closeAction();
      },
    });

    return popup;
  }
  /**
   * Display a popup with a cancel and retry button
   */
  showSaveError(
    options: PopupOptions,
    onRetry: () => void,
    onCancel: () => void
  ): Popup {
    const newOptions = {
      ...options,
      iconName: options.iconName || 'error',
      btnFirstOption:
        options.btnFirstOption || this.$translate.instant('POPUP_CANCEL'),
      btnSecondOption: this.$translate.instant('POPUP_SAVE_ERROR_RETRY'),
    };

    return this.showOptions(
      newOptions,
      () => (onCancel ? onCancel() : null),
      () => onRetry()
    );
  }
  /**
   * Display a popup with two action buttons
   */
  showOptions(
    options: PopupOptions & {
      btnFirstOption: string;
      btnSecondOption: string;
    },
    onFirstOption: () => void,
    onSecondOption: () => void
  ): Popup {
    const template = this.computePopupIconTemplate(options);
    const popup = this.computeIonicPopupShow(template, [
      {
        text: options.btnFirstOption,
        action: () => {
          onFirstOption();
          popup.close();
        },
      },
      {
        text: options.btnSecondOption,
        action: () => {
          onSecondOption();
          popup.close();
        },
      },
    ]);

    return popup;
  }

  /**
   * Display a popup with an automatic progression bar
   */
  showProgress(
    state: { nb: number; current: number },
    options: PopupOptions & { desc: string },
    onCancel?: () => void
  ): Popup {
    const template = `
      <sf-popup-progress
        title="${options.title || ''}"
        desc="${options.desc || ''}"
        nb-total="$ctrl.state.nb"
        nb-current="$ctrl.state.current">
      </sf-popup-progress>
    `;
    const popup = this.computeIonicPopupShow(
      template,
      onCancel
        ? [
            {
              text: this.$translate.instant('POPUP_PROGRESS_CANCEL'),
              action: () => {
                popup.close();
                return onCancel ? onCancel() : null;
              },
            },
          ]
        : [],
      { state }
    );

    return popup;
  }

  /**
   * Display a popup with a controlable progression bar
   * This instance can display an success or error popup
   * and manage the loading state
   *
   * @param {Object} options Popup content option
   * @param {Function} [onCancel] On cancel button click
   * @returns {Object} Popup actions
   */
  showDownloading(
    options: PopupOptions & { desc?: string },
    onCancel: () => void
  ): DownloadPopup {
    const template = `
      <sf-popup-download
        title="${options.title || ''}"
        desc="${options.desc || ''}"
        percent="$ctrl.state.loaded">
      </sf-popup-download>
    `;
    let hasBeenCanceled = false;
    const state = { loaded: 0 };

    const popup = this.computeIonicPopupShow(
      template,
      onCancel
        ? [
            {
              text: this.$translate.instant('POPUP_DOWNLOAD_CANCEL'),
              action: () => {
                popup.close();
                hasBeenCanceled = true;
                return onCancel ? onCancel() : null;
              },
            },
          ]
        : [],
      { state }
    );

    const onProgress = (percent) =>
      this.$timeout(() => {
        state.loaded = percent;
      }, 0);
    const onSuccess = (text) =>
      this.$timeout(() => {
        state.loaded = 100;
        popup.close();

        if (!text) {
          return null;
        }

        const popupSuccess = this.showSuccess(text);

        return this.$timeout(
          () => popupSuccess.close(),
          this.popupConstant.POPUP_SUCCESS_DISPLAY_DURATION
        );
      }, this.popupConstant.POPUP_ANIMATION_END_DURATION);
    const onError = (errorOptions: PopupOptions, onclick: () => void) => {
      popup.close();
      if (hasBeenCanceled) {
        return false;
      }
      return this.showError(
        {
          ...errorOptions,
          iconName: errorOptions.iconName || 'document_error',
        },
        onclick
      );
    };

    return {
      state,
      onProgress,
      onSuccess,
      onError,
    };
  }

  /**
   * Display a simple popup with a success icon
   * @returns {Object} Popup instance
   */
  showSuccess(title: string): Popup {
    const template = this.computePopupIconTemplate({
      title,
      iconName: 'thumbsup',
    });

    return this.computeIonicPopupShow(template);
  }

  /**
   * Display an alert popup with
   */
  alert(title: string, desc: string): Popup {
    return this.computeIonicPopupAlert(title, desc);
  }

  /**
   * Display a popup with select
   * This popup is used for case when user should choose value from list
   *
   * @param {Object} options - Popup options for the popup content
   */
  showSelect(
    options: PopupOptions & {
      selectLabel: string;
      selectPlaceholder: string;
      value: string;
      listOfValues: { value: string; label: string }[];
      okButton?: string;
      cancelButton?: string;
      required?: boolean;
    }
  ): { selectPromise: ng.IPromise<unknown>; close: () => void } {
    const allOptions = {
      okButton: 'POPUP_ACCEPT',
      cancelButton: 'POPUP_CANCEL',
      required: true,
      ...options,
    };

    const promise = this.$q.defer();
    const bindings = {
      resolvePopup: (result) => {
        promise.resolve(result);
        popup.close();
      },
      rejectPopup: () => {
        promise.reject();
        popup.close();
      },
      options: allOptions,
    };
    const template = `
      <sf-popup-select
        on-success="$ctrl.resolvePopup($event)"
        on-cancel="$ctrl.rejectPopup()"
        options="$ctrl.options">
      </sf-popup-select>
    `;

    const popup = this.computeIonicPopupShow(template, [], bindings);

    return {
      selectPromise: promise.promise,
      close: () => {
        promise.reject();
        popup.close();
      },
    };
  }

  /**
   * Display a confirmation popup for an action
   * It can display a checkbox which unblock the validate button
   * This popup is used for a dangerous action purpose like deletion
   */
  showConfirm(
    options: PopupOptions & {
      description?: string;
      buttonText?: string;
      hasCheckbox?: boolean;
    }
  ): ng.IPromise<void> {
    const {
      title = '',
      description = '',
      iconName,
      buttonText = '',
      hasCheckbox = true,
    } = options;
    const promise = this.$q.defer<void>();
    const template = `
      <sf-popup-confirm
        title="${title}"
        description="${description}"
        icon-name="${iconName}"
        button-text="${buttonText}"
        has-checkbox="$ctrl.hasCheckbox"
        on-submit="$ctrl.submitConfirm()"
        on-cancel="$ctrl.cancelConfirm()">
      </sf-popup-confirm>
    `;
    const popup = this.computeIonicPopupShow(template, [], {
      hasCheckbox,
      submitConfirm: () => {
        promise.resolve();
        popup.close();
      },
      cancelConfirm: () => {
        promise.reject();
        popup.close();
      },
    });

    return promise.promise;
  }

  showRedirectConfirm(
    options: PopupOptions & {
      description?: string;
      actionButtonText?: string;
      cancelButtonText?: string;
      imageSrc?: string;
    }
  ): ng.IPromise<void> {
    const {
      title = '',
      description = '',
      imageSrc,
      actionButtonText = '',
      cancelButtonText = '',
    } = options;
    const promise = this.$q.defer<void>();
    const template = `
      <sf-popup-redirect
        title="${title}"
        description="${description}"
        image-src="${imageSrc}"
        action-button-text="${actionButtonText}"
        cancel-button-text="${cancelButtonText}"
        on-action="$ctrl.submitConfirm()"
        on-cancel="$ctrl.cancelConfirm()">
      </sf-popup-redirect>
    `;

    const popup = this.computeIonicPopupShow(template, [], {
      submitConfirm: () => {
        promise.resolve();
        popup.close();
      },
      cancelConfirm: () => {
        promise.reject();
        popup.close();
      },
    });

    return promise.promise;
  }

  prompt(
    options: PopupOptions & {
      placeholder?: string;
      submit?: string;
      cancel?: string;
      required?: boolean;
    }
  ): { promptPromise: ng.IPromise<string>; close: () => void } {
    const allOptions = {
      subtitle: '',
      placeholder: '',
      submit: this.$translate.instant('POPUP_OK'),
      cancel: this.$translate.instant('POPUP_CANCEL'),
      required: false,
      maxLength: '',
      inputValue: '',
      long: false,
      ...options,
    };
    const promise = this.$q.defer<string>();
    const template = `
      <sf-popup-prompt
        on-cancel="$ctrl.rejectPrompt()"
        on-success="$ctrl.resolvePrompt($event)"
        options="$ctrl.options">
      </sf-popup-prompt>
    `;
    const popup = this.computeIonicPopupShow(template, [], {
      resolvePrompt: (result) => {
        promise.resolve(result.inputValue);
        popup.close();
      },
      rejectPrompt: () => {
        promise.reject();
        popup.close();
      },
      options: allOptions,
    });

    return {
      promptPromise: promise.promise,
      close: () => {
        promise.reject();
        popup.close();
      },
    };
  }

  /**
   * Compute an ionic popup
   */
  private computeIonicPopupShow(
    template: string,
    actions: PopupAction[] = [],
    scopeBindings = {}
  ): Popup {
    return this.$ionicPopup.show({
      template,
      scope: this.getScope(scopeBindings),
      buttons: this.getButtons(actions),
    });
  }

  /**
   * Compute an icon html template
   */
  private computePopupIconTemplate(
    options: PopupOptions & { desc?: string }
  ): string {
    return `
      <sf-popup-icon
        title="${options.title || ''}"
        desc="${options.desc || ''}"
        illustration-name="${options.iconName || ''}">
      </sf-popup-icon>
    `;
  }

  private getScope(bindings: PopupBindings): PopupCtrl {
    const $scope: PopupCtrl = this.$rootScope.$new();

    $scope.$ctrl = bindings;
    return $scope;
  }
  private getButtons(actions: PopupAction[]): IonicV1.IonicPopupButtons[] {
    const getType = (action, index) =>
      action.type ||
      (actions.length === 1 || index === 0
        ? 'button-calm button-outline'
        : 'button-dark');

    return actions.map((action, index) => ({
      text: action.text,
      type: getType(action, index),
      onTap: action.action,
    }));
  }

  /**
   * Compute an ionic alert popup
   */
  private computeIonicPopupAlert(title: string, template: string): Popup {
    return this.$ionicPopup.alert({ title, template });
  }
}
