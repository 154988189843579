import { PlatformService } from '../Platform';
import { ForegroundService } from '@capawesome-team/capacitor-android-foreground-service';

export class ForegroundNotificationService {
  constructor(
    private $translate: ng.translate.ITranslateService,
    private platformService: PlatformService,
    private $log: ng.ILogService
  ) {
    'ngInject';
  }

  startForegroundService(): Promise<void> {
    if (!this.platformService.isAndroid()) {
      return Promise.resolve();
    }

    return ForegroundService.checkPermissions()
      .then((status) => {
        if (status.display !== 'denied') {
          return ForegroundService.requestPermissions();
        }
      })
      .then(() => {
        return ForegroundService.startForegroundService({
          title: this.$translate.instant('FOREGROUND_NOTIFICATION_TITLE'),
          body: this.$translate.instant('FOREGROUND_NOTIFICATION_DESCRIPTION'),
          smallIcon: 'drawable/ic_stat_name',
          id: 1,
        });
      })
      .catch(() => {
        this.$log.debug('foreground start error');
      });
  }

  stopForegroundService(): Promise<void> {
    if (!this.platformService.isAndroid()) {
      return Promise.resolve();
    }

    return ForegroundService.stopForegroundService().catch(() => {
      this.$log.debug('foreground stop error');
    });
  }
}
