import { AngularSqlQueryFactory } from './angular-sql-query-adapter';
import { DataStoreService } from './data-store.service';

export default angular
  .module('sfMobile.services.data-store', [
    'sfMobile.config.database',
    'sfMobile.services.platform',
    'sfMobile.services.angular-sql-query',
    'sf.sqlStorage',
  ])
  .service('sqlQueryService', AngularSqlQueryFactory)
  .service('dataStoreService', DataStoreService).name;
