const COMPONENT_FILE_NAME = 'radio-choices-modal';
const COMPONENT_PATH =
  'missions/components/Form-questions/question-radio/components/';

const isAValidValue = (value) => value && value !== '';

export const RadioChoicesModalComponent = {
  bindings: {
    onClose: '&',
    choices: '<',
    question: '<',
    hasFreeAnswer: '<',
    answer: '<',
    translations: '<',
  },
  templateUrl: `${COMPONENT_PATH}${COMPONENT_FILE_NAME}/${COMPONENT_FILE_NAME}.html`,
  controller: function RadioChoicesModalController(contentService) {
    'ngInject';

    this.CONTENT_KEY = 'checkChoicesModal';

    this.$onInit = () => {
      this.clonedChoices = this.choices.slice(0);
      if (this.answer && !this.choices.includes(this.answer)) {
        this.freeChoice = this.answer;
      }
    };

    this.close = () => {
      this.onClose();
    };

    this.saveAnswers = () => {
      this.onClose({ choice: this.answer });
    };

    this.getAnswersNumber = () => (this.answer ? 1 : 0);

    this.isValidationDisabled = () =>
      this.getAnswersNumber() < this.question.minimum ||
      !isAValidValue(this.answer);

    this.onFreeChange = (value) => {
      this.answer = value;
    };

    this.onSearchFocus = () => {
      contentService.scrollTopById(this.CONTENT_KEY);
    };
  },
};
