import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { Angular as AngularIntegration } from '@sentry/integrations';
import { Device } from '@capacitor/device';

import { User } from '../../..';

const SENTRY_URL = 'https://0d0547edfb3e4801adf89e9984aad518@sentry.io/195381';

export class SentryService {
  Sentry = Sentry;
  Device = Device;

  constructor(private ConfigServer) {
    'ngInject';
  }

  init(): void {
    const { ENV, APP_VERSION, ENABLE_BUG_REPORTS, API_BASEURL } =
      this.ConfigServer;

    const apiUrl = API_BASEURL ? [API_BASEURL] : [];

    this.Sentry.init({
      dsn: SENTRY_URL,
      integrations: [
        new AngularIntegration(),
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', ...apiUrl],
        }),
      ],
      debug: ENV !== 'development',
      release: APP_VERSION,
      environment: ENV,
      enabled: ENABLE_BUG_REPORTS,
      denyUrls: [/\/api\/v3\/ping$/i],
    });
  }

  setUser(user: User): void {
    this.Sentry.configureScope((scope) => {
      scope.setUser({
        id: user._id,
        org_id: user.contents.organisation_id,
        username: `${user.contents.firstName} ${user.contents.lastName}`,
        email: user.contents.email,
      });
      this.Device.getId().then((deviceInfo) => {
        scope.setExtras({
          UUID: deviceInfo.identifier,
        });
      });
    });
  }

  captureException(ex: unknown): string {
    return this.Sentry.captureException(ex);
  }

  captureMessage(
    message: string,
    data?: {
      extra?: Record<string, any>;
      tags?: Record<string, string>;
      level?: Sentry.Severity;
    }
  ): void {
    return this.Sentry.withScope((scope) => {
      if (data) {
        if (data.extra) {
          scope.setExtras(data.extra);
        }
        if (data.tags) {
          scope.setTags(data.tags);
        }
        if (data.level) {
          scope.setLevel(data.level);
        }
      }
      this.Sentry.captureMessage(message);
    });
  }
}
