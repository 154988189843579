export const ReceiptLineCountComponent = {
  bindings: {
    answer: '<',
    onChangeMethod: '&',
    onRemoveMethod: '&',
    isReadOnly: '<',
  },
  templateUrl:
    'missions/components/Receipt/receipt-line-count/receipt-line-count.html',
  controller: function ReceiptLineCountController(
    productsService,
    SF_PRODUCT_QUANTITY_BOUNDARIES
  ) {
    'ngInject';
    const EAN_VALUES_INDEX = 0;
    const QUANTITY_VALUES_INDEX = 1;

    this.$onInit = () => {
      this.quantityField = this.answer.values[QUANTITY_VALUES_INDEX];
      this.MINIMUM_QUANTITY = SF_PRODUCT_QUANTITY_BOUNDARIES.MIN;

      return productsService
        .getByEans([this.answer.values[EAN_VALUES_INDEX].value])
        .then((product) => {
          this.fullImageUrl =
            product && product.imageUrls && product.imageUrls.full;
          this.thumbImageUrl =
            product && product.imageUrls && product.imageUrls.thumb;
        });
    };

    this.removeProduct = () => this.onRemoveMethod({ answer: this.answer });

    this.getAnswerForm = () => this[`count_answer_${this.answer._id}`];
    this.getQuantityError = () => this.getAnswerForm().product_quantity.$error;
  },
};
