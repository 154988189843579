import Chart from 'chart.js';

const SCATTER_CHART_TYPE = 'scatter';
const STEP_NUMBER = 5;
const STYLE_CONSTANTS = {
  WIDTH: {
    SIZE: 211,
    BASE_OFFSET: 44,
    OFFSET_UNIT: 5,
  },
  RIGHT: {
    SIZE: 4,
    OFFSET_UNIT: 2,
  },
};
const SCATTER_CHART_CONFIG = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  elements: {
    point: {
      radius: 5,
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          fontStyle: 'bold',
        },
        ticks: {
          beginAtZero: true,
          fontSize: 10,
        },
      },
    ],
    yAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          fontStyle: 'bold',
        },
        ticks: {
          beginAtZero: true,
          fontSize: 10,
        },
      },
    ],
  },
};

export const ScatterGraphComponent = {
  bindings: {
    data: '<',
  },
  templateUrl: 'components/Graphs/scatter-graph/scatter-graph.html',
  controller: class ScatterGraphController {
    constructor($element, sfChartService) {
      'ngInject';
      Object.assign(this, {
        $element,
        sfChartService,
      });
    }

    $onInit() {
      const data = {
        datasets: [
          {
            data: this.data.points,
            pointBackgroundColor: this.getPointsColor(this.data.points),
            pointBorderColor: this.getPointsColor(this.data.points),
          },
        ],
      };

      this.GRID_COLOR = this.sfChartService.getGridColor();
      this.chartOptions = {
        type: SCATTER_CHART_TYPE,
        ...{ data },
        options: SCATTER_CHART_CONFIG,
      };

      this.backgroundStyle = this.computeBackgroundStyle(
        this.data.axes.x.max,
        this.data.axes.y.max
      );

      this.chartOptions = this.setAxeOption(
        'x',
        this.data.axes,
        this.chartOptions
      );
      this.chartOptions = this.setAxeOption(
        'y',
        this.data.axes,
        this.chartOptions
      );

      this.chart = new Chart(
        this.$element[0].querySelector('#sf_scatter_graph'),
        this.chartOptions
      );
    }

    $onDestroy() {
      this.chart.destroy();
    }

    computeBackgroundStyle(maxX, maxY) {
      const yMaxDigitNumber = this.getDigitNumber(this.getLastStep(maxY));
      const xMaxDigitNumber = this.getDigitNumber(this.getLastStep(maxX));
      const yAxeOffset =
        STYLE_CONSTANTS.WIDTH.BASE_OFFSET +
        yMaxDigitNumber * STYLE_CONSTANTS.WIDTH.OFFSET_UNIT;
      const width =
        STYLE_CONSTANTS.WIDTH.SIZE -
        yAxeOffset -
        xMaxDigitNumber * STYLE_CONSTANTS.RIGHT.OFFSET_UNIT;
      const right =
        STYLE_CONSTANTS.RIGHT.SIZE +
        xMaxDigitNumber * STYLE_CONSTANTS.RIGHT.OFFSET_UNIT;

      return {
        width: width + 'px',
        right: right + 'px',
      };
    }

    setAxeOption(axe, dataAxes, chartOptions) {
      let optionAxe = chartOptions.options.scales[`${axe}Axes`][0];

      optionAxe.scaleLabel.labelString = dataAxes[axe].label;
      optionAxe.ticks.max = dataAxes[axe].max;
      optionAxe.ticks.stepSize = this.getStepSize(dataAxes[axe].max);
      optionAxe.gridLines = {
        display: true,
        color: this.GRID_COLOR,
        zeroLineColor: this.GRID_COLOR,
      };
      optionAxe.afterTickToLabelConversion = (scaleInstance) =>
        this.removeUnfittingStep(scaleInstance, axe);

      return chartOptions;
    }

    removeUnfittingStep(scaleInstance, axe) {
      const index = axe === 'x' ? scaleInstance.ticks.length - 1 : 0;

      if (!this.isLastStepFitting(this.data.axes[axe].max)) {
        scaleInstance.ticks[index] = null;
        scaleInstance.ticksAsNumbers[index] = null;
      }
      return scaleInstance;
    }

    getPointsColor(points) {
      return points.map((point) => this.getPointColor(point));
    }

    getPointColor(point) {
      const DIVIDER_BY_2 = 2;
      const ratioX = point.x / this.data.axes.x.max;
      const ratioY = point.y / this.data.axes.y.max;
      const meanPercent = (ratioX + ratioY) / DIVIDER_BY_2;

      return this.sfChartService.getValueColor(meanPercent * 100);
    }

    isLastStepFitting(max) {
      return max % STEP_NUMBER === 0 || this.getStepSize(max) === 1;
    }

    getLastStep(max) {
      return max % STEP_NUMBER === 0 ? max : max - this.getStepSize(max);
    }

    getDigitNumber(number) {
      return number < 1 ? 1 : Math.floor(Math.log10(number) + 1);
    }

    getStepSize(maxAxis) {
      const step = Math.round(maxAxis / STEP_NUMBER);

      return step > 1 ? step : 1;
    }
  },
};
