import { PictureTagModel, SelectedTags } from '@services/API/tags/tags';
import { TagsService } from '@services/API/tags/tags.service';
import { ModalService } from '@services/Utils/Modal';

export class TagsSelectorService {
  constructor(
    private modalService: ModalService,
    private tagsService: TagsService
  ) {
    'ngInject';
  }

  getTags(): ng.IPromise<PictureTagModel[]> {
    return this.tagsService.getTags().then((tags) => tags);
  }

  getTagsByIds(ids: string[]): ng.IPromise<PictureTagModel[]> {
    return this.getTags().then((tags) =>
      tags.filter(({ _id }) => ids.includes(_id))
    );
  }

  openTagSelector(images, isMandatory = false): ng.IPromise<SelectedTags> {
    const template = `
        <sf-tags-selector
          on-cancel="$ctrl.onClose()"
          on-save="$ctrl.onSave()"
          resolve="$ctrl.resolve"
          is-required="$ctrl.isMandatory"
          is-local="$ctrl.isEditLocal"
          class="tags-selector-modal-wrapper"
          ></sf-tags-selector>`;

    const bindings = {
      isMandatory,
      resolve: { images },
    };

    return this.modalService.openAsPromise(template, bindings, {
      hardwareBackButtonClose: false,
      backdropClickToClose: false,
    });
  }
}
