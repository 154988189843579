import { StateService, TransitionPromise } from '@uirouter/angularjs';
import moment from 'moment-timezone';
import { isNil } from 'ramda';
import { Subtask, Task } from '../..';
import { ObjectId, User } from '../../..';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { TasksService } from '../../../services/API/tasks/tasks.service';
import { DateFormatService } from '../../../services/Utils/Dates/date-format.service';
import { ImageService } from '../../../services/Utils/Image/image.service';
import { TaskService } from '../../services/task.service';

type TaskWithDetails = Task & {
  filesMetadata?: {
    size: number;
    name: string;
    id: ObjectId;
  }[];
};
export class TaskDetailsOverviewController implements ng.IComponentController {
  // bindings
  task: TaskWithDetails;
  profile: User;
  offlineMode: boolean;
  isChecklistTab = false;
  onDetailsClose: () => void;

  // attributes
  dueDateFormatted: string;
  dueTimeFormatted: string;
  completionDateFormatted: string;
  createdDateFormatted: string;
  hasFiles: boolean;
  filesFolderPath: string;
  tasksImages: string[] = [];

  targetName: string;
  assigneeName: string;
  ownerName: string;
  formName?: string;
  campaignName?: string;

  constructor(
    private $state: StateService,
    private $translate: ng.translate.ITranslateService,
    private dateFormatService: DateFormatService,
    private imageService: ImageService,
    private profileService,
    private taskService: TaskService,
    private tasksService: ReturnType<typeof TasksService>,
    private SF_IMAGE_SIZES: typeof IMAGE_SIZES
  ) {
    'ngInject';
  }

  $onChanges(): ng.IPromise<void> {
    this.hasFiles = Boolean(this.task?.filesMetadata?.length);
    this.filesFolderPath = this.taskService.getFilesPath(this.task._id);
    this.assignOverviewParams();

    return this.loadImages();
  }

  // load data
  loadImages = (): ng.IPromise<void> =>
    this.imageService
      .getSizedUrlsFromIds(
        this.task.contents.photos_ids || [],
        this.SF_IMAGE_SIZES.SQUARE_LARGE
      )
      .then((urls) => {
        this.tasksImages = Object.values(urls);
      });

  assignOverviewParams = (): void => {
    this.targetName = this.getTargetName();
    this.assigneeName = this.getAssigneeName();
    this.ownerName = this.getOwnerName();
    this.createdDateFormatted = this.getCreatedDate();
    this.dueDateFormatted = this.getDueDate();
    this.dueTimeFormatted = this.getDueTime();
    this.completionDateFormatted = this.getCompletionDate();

    this.formName = this.getFormName();
    this.campaignName = this.getCampaignName();
  };
  // getters methods
  getTargetName = (): string => {
    if (!this.task.place) {
      return this.getAssigneeName();
    }
    return this.task.contents.place_id
      ? (this.task.place?.name as string) || ''
      : this.$translate.instant('TASK_DETAILS_PLACE_UNASSIGNED');
  };

  getAssigneeName = (): string =>
    this.task.contents.assignee_id
      ? this.profileService.getNameFromUser(this.task.assignee)
      : this.$translate.instant('TASK_DETAILS_ASSIGNEE_UNASSIGNED');

  getOwnerName = (): string =>
    this.profileService.getNameFromUser(this.task.owner);

  getFormName = (): string | undefined =>
    this.task.form && this.task.form.title;

  getCampaignName = (): string | undefined =>
    this.task.campaign && this.task.campaign.name;

  getCreatedDate = (): string => {
    const timezonedDate = this.profileService.transformUtcToUserTimezone(
      this.profile,
      this.task.created_date
    );

    return this.dateFormatService.getDateFormatted(timezonedDate);
  };

  getDueDate = (): string => {
    const DueDate = this.profileService.transformUtcToUserTimezone(
      this.profile,
      this.task.contents.due_date
    );
    return this.dateFormatService.getDateFormatted(DueDate);
  };

  getDueTime = (): string => {
    return !isNil(this.task.contents.due_time)
      ? moment.utc(this.task.contents.due_time).format('HH:mm')
      : '';
  };

  getCompletionDate = (): string => {
    if (!this.task.contents.completion_dateTime) {
      return '--';
    }

    return this.dateFormatService.getCompletionDateFormatted(
      this.task.contents.completion_dateTime
    );
  };

  // user actions handlers
  goToPlace = (): TransitionPromise | null => {
    this.onDetailsClose();
    if (!this.task.place || !this.task.contents.place_id) {
      return null;
    }
    return this.$state.go('index.places.details.main', {
      placeId: this.task.contents.place_id,
    });
  };

  changeSubtaskStatus(subtask: Subtask): void | ng.IPromise<Task> {
    if (
      !this.taskService.isAllowedToChangeSubtaskStatus(
        this.task,
        subtask,
        this.profile
      )
    ) {
      return;
    }
    if (subtask.status === 'todo') {
      subtask.status = 'done';
    } else {
      subtask.status = 'todo';
    }
    return this.tasksService.changeSubtaskStatus(
      this.task,
      subtask,
      this.offlineMode
    );
  }
}
