export const NetworkErrorComponent = {
  bindings: {
    onReload: '&',
    errorConfig: '<',
  },
  templateUrl: 'components/Scenes/network-error/network-error.html',
  controller: class NetworkErrorController {
    constructor($attrs, $translate) {
      'ngInject';
      Object.assign(this, {
        $attrs,
        $translate,
      });
    }

    $onInit() {
      this.errorConfig = this.errorConfig || {};
      this.title =
        this.errorConfig.title ||
        this.$translate.instant('NETWORK_ERROR_TITLE');
      this.desc =
        this.errorConfig.desc || this.$translate.instant('NETWORK_ERROR_DESC');
      this.reloadButtonText =
        this.errorConfig.reloadButtonText ||
        this.$translate.instant('NETWORK_ERROR_BUTTON');
    }

    displayActionButton() {
      return !!this.$attrs.onReload;
    }
  },
};
