export const PopupConfirmComponent = {
  bindings: {
    title: '@',
    description: '@',
    iconName: '@',
    buttonText: '@',
    hasCheckbox: '<',
    onSubmit: '&',
    onCancel: '&',
  },
  transclude: true,
  templateUrl: 'components/Popup/popup-confirm/popup-confirm.html',
};
