import { CalendarEventsParamsService } from '@services/API/calendar-events-params/calendar-events-params.service';
import { CategorizedParam } from '@services/API/places-params/places-params.service';
import { DateService } from '@services/Utils/Dates/date.service';
import { PopupService } from '@services/Utils/Popup/popup.service';
import { Option } from '@simplifield/webcomponents/dist/types/components/multi-select/multi-select';

import {
  CalendarEventsEntity,
  CustomParamAPI,
  CustomParameter,
  IAPIList,
} from 'app';
import { ButtonSelectorOption } from 'app/components/Buttons/button-selector/button-selector.controller';

type DisplayedParam = CategorizedParam & { active: boolean };

export const CustomParamsSelectorModalComponent: ng.IComponentOptions = {
  bindings: {
    searchPlaceholder: '@',
    title: '@',
    onClose: '&',
    selected: '<?',
  },

  templateUrl:
    'components/custom-params-selector/components/custom-params-selector-modal/custom-params-selector-modal.html',
  controller: class CustomParamsSelectorModalController {
    hasError = false;
    isLoading = false;
    paramsDisplayed: any[];
    selected;
    valuesMap: Record<string, CustomParameter['value']> = {};
    possibleMultiChoiceValuesMap: Record<string, Option[] | undefined>;
    categorizedParams: any[];
    onClose: (arg?: { params }) => void;

    constructor(
      private calendarEventsParamsService: CalendarEventsParamsService,
      private popupService: PopupService,
      private $translate: ng.translate.ITranslateService,
      private dateService: DateService,
      private $q: ng.IQService
    ) {
      'ngInject';
    }

    $onInit() {
      this.getParams().then((data) => this.updateData(data));
    }

    save() {
      const paramsEdit = this.valuesMap;
      const newParams = Object.keys(paramsEdit)
        .reduce(
          (output, paramKey) =>
            output.concat({
              value: paramsEdit[paramKey],
              param_id: paramKey,
            }),
          [] as CustomParameter[]
        )
        .filter((param) => !!param.value);

      this.onClose({ params: newParams });
    }

    close() {
      if (!this.hasParamsChanged()) {
        return this.onClose();
      }

      return this.popupService
        .showConfirm({
          title: this.$translate.instant(
            'PLACE_INFOS_EDIT_CANCEL_CONFIRM_TITLE'
          ),
          iconName: 'save-danger',
          buttonText: this.$translate.instant(
            'PLACE_INFOS_EDIT_CANCEL_CONFIRM_YES'
          ),
        })
        .then(() => this.onClose({ params: this.selected }))
        .catch(() => null);
    }

    updateData(
      datas: [
        IAPIList<CustomParamAPI<CalendarEventsEntity>>,
        ButtonSelectorOption[]
      ]
    ): void {
      const [params, categories] = datas;
      this.initValueMap(params.entries, this.selected);
      this.preparePossibleValuesMap(params.entries);
      this.categorizedParams =
        this.calendarEventsParamsService.getCategorizedParams(
          params,
          categories,
          this.selected
        );

      this.paramsDisplayed = this.categorizedParams.map<DisplayedParam>(
        (catParams) => ({
          active: true,
          ...catParams,
        })
      );

      this.valuesMap = this.selected?.reduce((output, param) => {
        output[param.param_id] = param.value;
        return output;
      }, {});
    }

    getParams(): ng.IPromise<
      [IAPIList<CustomParamAPI<'calendarEvents'>>, ButtonSelectorOption[]]
    > {
      this.isLoading = true;

      return this.$q
        .all([
          this.calendarEventsParamsService.listCalendarEventsParamsKeys(),
          this.calendarEventsParamsService.loadCategories(),
        ])
        .then((value) => {
          this.isLoading = false;
          return value;
        });
    }

    updateOptions(event: CustomEvent<Option[]>, id: string): void {
      this.valuesMap[id] = event.detail.map((option) => option.value);
    }

    toggleCollapse(index: number): void {
      this.paramsDisplayed[index].active = !this.paramsDisplayed[index].active;
    }

    initValueMap(
      customParams: CustomParamAPI<CalendarEventsEntity>[],
      eventParams: CustomParameter[]
    ): void {
      this.valuesMap = customParams?.reduce((acc, param) => {
        const value = eventParams?.find(
          (paramValue) => paramValue.param_id === param._id
        )?.value;

        acc[param._id] = value;
        return acc;
      }, {});
    }

    hasParamsChanged(): boolean {
      const paramsValuesHash = this.valuesMap || {};
      const findParam = (id) =>
        this.selected?.filter((param) => param.param_id === id)[0] || {};

      return Object.keys(paramsValuesHash)?.some((paramId) => {
        const paramEdit = paramsValuesHash[paramId];

        return (findParam(paramId)?.value || null) !== (paramEdit || null);
      });
    }

    preparePossibleValuesMap(
      params: CustomParamAPI<CalendarEventsEntity>[]
    ): void {
      this.possibleMultiChoiceValuesMap = params.reduce((acc, param) => {
        if (param.contents.type === 'MultipleChoice' && param._id) {
          const selectedValues = this.valuesMap[param._id] as string[];
          const options =
            param.contents.predefinedValues?.map((option) => ({
              label: option.value.toString(),
              value: option.id,
              selected: selectedValues?.includes(option.id) || false,
            })) || [];
          acc[param._id] = options;
        }
        return acc;
      }, {});
    }
  },
};
