import { User } from '../../../..';
import { APIStore } from '../../../../places';
import { Form, FormQuestion } from '../../../../services/API/forms/forms';
import { Report } from '../../../../services/API/reports/reports';
import {
  ActionSheetAction,
  ActionSheetConfig,
  ActionSheetService,
  IActionSheet,
} from '../../../../services/Utils/ActionSheet/action-sheet.service';
import { APITaskContents, AutoTask, Task } from '../../../../tasks';
import { TaskService } from '../../../../tasks/services/task.service';

type TranslatableTaskContents<T> = T & {
  name: string;
  description?: string;
  subtasks?: {
    name: string;
  }[];
};

export const FormQuestionAutotaskComponent: ng.IComponentOptions = {
  bindings: {
    question: '<',
    report: '<',
    form: '<',
    place: '<',
    profile: '<',
    onSkipped: '&',
    onConfirmed: '&',
    pendingTask: '<',
  },
  templateUrl:
    'missions/form/components/form-question-autotask/form-question-autotask.html',
  controller: class FormQuestionAutotaskController implements ng.IController {
    // bindings
    question: FormQuestion;
    report: Report;
    form: Form;
    place: APIStore;
    profile: User;
    pendingTask: Task;
    onSkipped: () => void;
    onConfirmed: (arg: { task: Task }) => void;

    // members
    taskTemplate: AutoTask['template'];

    constructor(
      private taskService: TaskService,
      private $filter: ng.IFilterService,
      private $translate: ng.translate.ITranslateService,
      private keyboardService,
      private actionSheetService: ActionSheetService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.initTaskTemplate();
    }

    initTaskTemplate(): void {
      const autoTask_id = this.pendingTask.autoTask_id;
      const autoTask = this.question.metadata.autoTasks?.find(
        ({ _id }) => _id === autoTask_id
      );

      if (!autoTask) {
        return;
      }

      this.taskTemplate = this.translateTask(autoTask.template);
    }

    onDateChange(date: Date): void {
      const task = {
        ...this.pendingTask,
        contents: this.translateTask<APITaskContents>(
          this.pendingTask.contents
        ),
      };
      // set task date in UTC midday
      task.contents.due_date = this.taskService.prepareTaskDate(date);

      return this.onConfirmed({ task });
    }

    onTaskManage(): void {
      const task = {
        ...this.pendingTask,
        contents: this.translateTask<APITaskContents>(
          this.pendingTask.contents
        ),
      };
      const offlineMode = true;
      const context = {};

      this.taskService
        .openTaskManageModal(task, this.profile, context, offlineMode)
        .then(
          (task) => this.onConfirmed({ task: task.managedTask as Task }),
          () => {} // rejects on close without creation
        );
    }

    onSkip(): void {
      this.onSkipped();
    }

    openActionsSheet(event: Event): IActionSheet {
      event.stopPropagation();
      this.keyboardService.hide();

      const actionSheetActions = this.getAutotaskActions();
      const actionSheetConfig: ActionSheetConfig = {
        cancelText: this.$translate.instant('PROFILE_ACTION_CANCEL'),
      };

      return this.actionSheetService.open(
        actionSheetActions,
        actionSheetConfig,
        null,
        null
      );
    }

    getAutotaskActions(): ActionSheetAction[] {
      return [
        ...(!this.taskTemplate.isMandatory
          ? [
              {
                text: this.$translate.instant('AUTOTASK_SKIP_CREATION'),
                onClick: () => this.onSkip(),
              },
            ]
          : []),
        {
          text: this.$translate.instant('TASK_ACTIONS_EDIT'),
          onClick: () => this.onTaskManage(),
        },
      ];
    }

    translateTask<T>(
      taskContents: TranslatableTaskContents<T>
    ): TranslatableTaskContents<T> {
      const sfTranslate: (campaignName: string, i18n: string) => string =
        this.$filter('sfTranslation');

      const { name, description, subtasks } = taskContents;
      return {
        ...taskContents,
        original: {
          name,
          ...(description ? { description } : {}),
          ...(subtasks?.length
            ? { subtaskNames: subtasks.map(({ name }) => name) }
            : {}),
        },
        name: sfTranslate(taskContents.name, this.form.i18n),
        ...(description
          ? {
              description: sfTranslate(description, this.form.i18n),
            }
          : {}),
        ...(subtasks?.length
          ? {
              subtasks: subtasks.map((subtask) => ({
                ...subtask,
                name: sfTranslate(subtask.name, this.form.i18n),
              })),
            }
          : {}),
      };
    }
  },
};
