import { User } from '../../../..';
import { APIStore } from '../../..';
import { PlaceService } from '../../services/place.service';
import { Campaign } from '../../../../services/API/campaigns/campaigns';
import { CampaignsUtilsService } from '../../../../reactive-campaigns/services/campaigns-utils.service';

export class PlaceCampaignsListController {
  // bindings
  place: APIStore;
  profile: User;

  // fields
  campaigns: Campaign[];
  campaignSearch = '';
  isSearchLoading = false;
  searchError = false;

  constructor(
    private helpersService,
    private placeService: PlaceService,
    private campaignsUtilsService: CampaignsUtilsService
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    return this.reload();
  }

  reload(): ng.IPromise<void> {
    return this.placeService
      .getPlaceCampaignsData(
        this.place._id,
        this.profile._id,
        this.campaignSearch
      )
      .then(({ campaignsWithGoal, campaignsWithoutGoal }) => {
        this.campaigns = [...campaignsWithGoal, ...campaignsWithoutGoal]
          .sort((a, b) =>
            this.helpersService.sortNatural(a.contents.name, b.contents.name)
          )
          .filter((campaign) =>
            this.campaignsUtilsService.isFrequencyActivePeriod(campaign)
          );
      });
  }

  onCampaignSearchChange(search: string): ng.IPromise<void> {
    this.campaignSearch = search;
    this.isSearchLoading = true;
    this.searchError = false;

    return this.reload()
      .catch(() => {
        this.searchError = true;
      })
      .finally(() => {
        this.isSearchLoading = false;
      });
  }
}
