export default function configMigration(sqlStorageServiceProvider) {
  'ngInject';

  sqlStorageServiceProvider.addUpdater({
    version: 10,
    method: updateMethod,
  });

  // @ngInject
  function updateMethod($q, $log, sqlStorageService, sentryService) {
    const fields = ['id NVARCHAR(32) PRIMARY KEY UNIQUE', 'payload TEXT'].join(
      ','
    );
    const createPlacesListsTable = `CREATE TABLE IF NOT EXISTS places_lists(${fields})`;

    return sqlStorageService.execute(createPlacesListsTable).catch((error) =>
      sentryService.captureMessage('MIGRATION_SCRIPT_FAIL_10', {
        level: 'error',
        extra: {
          error,
        },
        tags: {
          sendNotification: 'mail',
        },
      })
    );
  }
}
