import { StateService } from '@uirouter/core';
import type { ObjectId } from '../../..';
import { FilterCustomParam } from '../../../components/Search/search';
import { Campaign } from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';
import { PlacesParamsService } from '../../../services/API/places-params/places-params.service';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import { ObjectIdService } from '../../../services/Utils/Objectid/objectId.service';

const LAST_REPORT_DATE_DIFF_TRANSLATION_KEYS = {
  years: 'LAST_REPORT_DATE_DIFF_YEARS',
  months: 'LAST_REPORT_DATE_DIFF_MONTHS',
  days: 'LAST_REPORT_DATE_DIFF_DAYS',
  hours: 'LAST_REPORT_DATE_DIFF_HOURS',
  minutes: 'LAST_REPORT_DATE_DIFF_MINUTES',
};

const NB_VALUES_TO_LOAD = 20;

export const ReactiveCampaignParamSelectorComponent: ng.IComponentOptions = {
  bindings: {
    campaign: '<',
    placeId: '<',
    referer: '<',
    onClose: '&',
    onSave: '&',
  },
  templateUrl:
    'reactive-campaigns/components/reactive-campaign-param-selector/reactive-campaign-param-selector.html',
  controller: class ReactiveCampaignParamSelectorController
    implements ng.IController
  {
    /** Bindings */
    campaign: Campaign;
    placeId: ObjectId;
    referer?: string;
    onClose: () => void;
    onSave: () => (value: string) => void;

    /** Fields */
    infiniteLoadError: boolean;
    isLoading: boolean;
    isRTLNeeded: boolean;
    isSearchLoading: boolean;
    networkError: boolean;
    objectiveCompletionHash: Record<
      ObjectId,
      {
        percent: number;
        label: {
          title: string;
        };
      }
    >;
    onScrollComplete: () => void;
    placesParam?: FilterCustomParam;
    selectedParamValueId: string;
    staticPaginate;
    statistics: { paramValue: string; lastReport_date: Date | null }[];

    constructor(
      private $state: StateService,
      private $translate: ng.translate.ITranslateService,
      private campaignsService: CampaignsService,
      private dateService,
      private localizationService: LocalizationService,
      private objectIdService: ObjectIdService,
      private placesParamsService: PlacesParamsService,
      private StaticRequestsPaginate,
      private $scope: ng.IRootScopeService
    ) {
      'ngInject';
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    }
    $onInit(): ng.IPromise<void> {
      this.isLoading = true;
      this.networkError = false;

      this.$scope.$on('closeCampaignParamSelector', () => {
        this.close();
      });

      return this.placesParamsService
        .listFiltersParams()
        .then((placesParams) => {
          this.placesParam = placesParams.find(
            ({ id }) => this.campaign.contents.posAuditParam_id === id
          );

          if (!this.placesParam) {
            this.networkError = true;
            return;
          }

          return this.campaignsService
            .getCampaignPosAuditStatistics(this.campaign._id, this.placeId)
            .then((statistics) => {
              this.staticPaginate = new this.StaticRequestsPaginate(
                Object.keys(statistics)
                  .map((paramId) => {
                    const param = this.placesParam?.values?.find(
                      (p) => p.id === paramId
                    );

                    return param
                      ? {
                          paramId,
                          paramValue: param.value,
                          lastReport_date: statistics[paramId].lastReport_date,
                        }
                      : {};
                  })
                  .filter(({ paramId }) => Boolean(paramId))
                  .sort((a, b) =>
                    this.dateService.isGreaterThan(
                      a.lastReport_date,
                      b.lastReport_date
                    )
                      ? 1
                      : -1
                  ),
                NB_VALUES_TO_LOAD
              );

              return this.load();
            });
        })
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    load(): ng.IPromise<void> {
      this.infiniteLoadError = false;

      return this.staticPaginate
        .load()
        .then(({ entities }) => {
          this.statistics = entities;
        })
        .catch(() => {
          this.infiniteLoadError = true;
        });
    }

    loadMore(): ng.IPromise<void> {
      this.infiniteLoadError = false;

      return this.load()
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        })
        .finally(() => {
          if (typeof this.onScrollComplete === 'function') {
            this.onScrollComplete();
          }
        });
    }

    hideError(): void {
      this.infiniteLoadError = false;
    }

    isToday(date: Date): boolean {
      return this.dateService.isToday(date);
    }

    formatDateDifference(date: Date): string {
      if (!date || this.dateService.isGreaterThan(date, new Date())) {
        return '';
      }

      if (this.isToday(date)) {
        return this.$translate.instant('LAST_REPORT_DATE_TODAY');
      }

      return this.dateService.formatDifference(
        date,
        new Date(),
        LAST_REPORT_DATE_DIFF_TRANSLATION_KEYS
      );
    }

    goToNextStep(): void {
      this.onSave()(this.selectedParamValueId);
    }

    onValueChanged(value: string): void {
      this.selectedParamValueId = value;
    }

    close(): void {
      this.onClose();
    }
  },
};
