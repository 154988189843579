import ContentModule from './content/content.module';
import NavBarModule from './nav-bar/nav-bar.module';
import TabBarHideModule from './tab-bar-hide/tab-bar-hide.module';
import ViewModule from './view/view.module';
import ViewContentStatesModule from './view-content-states/view-content-states.module';
import ViewStatesModule from './view-states/view-states.module';
import ViewStatesSearchModule from './view-states-search/view-states-search.module';

export default angular.module('sfMobile.components.layout', [
  ContentModule,
  NavBarModule,
  TabBarHideModule,
  ViewModule,
  ViewContentStatesModule,
  ViewStatesModule,
  ViewStatesSearchModule,
]).name;
