"use strict";

var DATAPATH_ERRORS = ['minLength', 'maxLength', 'enum', 'type', 'format', 'pattern'];

var YError = require('yerror');

var errorsLibrary = {
  mapAjvErrors: function mapAjvErrors(errors) {
    return errors.map(function (error) {
      if ('required' === error.keyword) {
        return new YError('E_REQUIRED', ".".concat(error.params.missingProperty));
      }

      if ('additionalProperties' === error.keyword) {
        return new YError('E_UNEXPECTED_CONTENT', ".".concat(error.params.additionalProperty));
      }

      if (-1 !== DATAPATH_ERRORS.indexOf(error.keyword)) {
        return new YError('E_VALIDATION', error.dataPath, error.message);
      }

      return new YError('E_VALIDATION', error.path, error.message);
    });
  }
};
module.exports = errorsLibrary;