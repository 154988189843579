import { ViewController } from './view.controller';

export const ViewComponent = {
  bindings: {
    sfTabBarHide: '<',
    hasFixedParralax: '<?',
  },
  transclude: true,
  templateUrl: 'components/Layout/view/view.html',
  controller: ViewController,
};
