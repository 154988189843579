export const SF_TASK_ASSIGNEES = {
  MY_TASKS: {
    keyword: 'mytasks',
    i18nKey: 'TASKS_FILTER_ASSIGNED_TO_ME',
  },
  OTHERS_TASKS: {
    keyword: 'otherstasks',
    i18nKey: 'TASKS_FILTER_ASSIGNED_TO_OTHERS',
  },
} as const;

export default angular
  .module('sfMobile.constants.task-assignees', [])
  .constant('TASK_ASSIGNEES', SF_TASK_ASSIGNEES).name;
