import { CalendarEvent, DateEvents, Timezone } from '../../types';
import { ManagerCalendarService } from '../../services/manager-calendar/manager-calendar.service';
import { ContentService } from '../../../components/Layout/content/content.service';
import {
  ActionSheetConfig,
  ActionSheetService,
} from '../../../services/Utils/ActionSheet/action-sheet.service';
import { StateService } from '@uirouter/angularjs';
import type { ModalService } from '../../../services/Utils/Modal';
import { UsersService } from '../../../services/API/users/users.service';
import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import { ErrorMessagesService } from '../../../services/Utils/ErrorMessages/error-messages.service';
import { DateService } from '../../../services/Utils/Dates/date.service';
import { MomentInput } from 'moment-timezone';
import { CalendarEventsService } from '../../../services/API/calendar-events/calendar-events.service';
import scrollIntoView from 'scroll-into-view';
export class ManagerCalendarController implements ng.IComponentController {
  events: DateEvents[] = [];
  canRequestChange = false;
  date: Date;
  dateAsHTML5Date: string;
  isLoading = false;
  errorMessage: string;
  timezone: {
    name: string;
  };
  private userSearch: string;
  filters: Record<string, string | number>[] = [];
  availableFilters: {
    id: string;
    label: string;
    values?: (string | number)[];
    value?: string | number;
  }[];
  placesListsNameHash: Record<string, string>;
  usersGroupsNameHash: Record<string, string>;
  eventTypesTitleHash: Record<string, string>;
  selectLimit = Number.POSITIVE_INFINITY;
  translations: Record<string, string>;
  isRTLNeeded: boolean;
  // eslint-disable-next-line max-params
  constructor(
    private localizationService: LocalizationService,
    private $q: ng.IQService,
    private managerCalendarService: ManagerCalendarService,
    private calendarEventsService: CalendarEventsService,
    private preferencesService,
    private dateService: DateService,
    private modalService: ModalService,
    private calendarEventsNotificationsService,
    private errorMessagesService: ErrorMessagesService,
    private eventChangeRequestsService,
    private usersService: UsersService,
    private placesListsService,
    private eventTypesService,
    private contentService: ContentService,
    private actionSheetService: ActionSheetService,
    private $state: StateService,
    private $translate: ng.translate.ITranslateService,
    private $window: ng.IWindowService
  ) {
    'ngInject';

    this.translations = {
      cancel: this.$translate.instant('EVENT_CANCEL'),
      goToPersonalCalendar: this.$translate.instant('EVENT_NAVIGATION'),
      goToManagerCalendar: this.$translate.instant(
        'MANAGER_CALENDAR_NAVIGATION'
      ),
    };
  }

  $onInit(): ng.IPromise<unknown> {
    this.isRTLNeeded = this.localizationService.shouldActivateRTL();
    this.availableFilters = [];
    this.setSelectedDate(new Date());

    return this.$q
      .all([
        this.usersService.getAllUsersGroups(),
        this.placesListsService.apiList(),
        this.eventTypesService.listLocal(),
        this.eventChangeRequestsService
          .canRequestChange()
          .then((canRequestChange: boolean) => {
            this.canRequestChange = canRequestChange;
          }),
        this.preferencesService.getTimezone().then((timezone: Timezone) => {
          this.timezone = timezone;
          return this.loadCalendarEvents().then(() => this.goToToday());
        }),
      ])
      .then(([usersGroups, placesLists, eventTypes]) => {
        const getName = (list) => list.contents.name;

        this.availableFilters.push({
          id: 'place_name',
          label: 'PLANNING_SEARCH_STORENAME_TITLE',
          value: '',
        });
        this.availableFilters.push({
          id: 'assignee_group',
          label: 'PLANNING_SEARCH_USERGROUP_TITLE',
          values: usersGroups.entries.map(getName),
        });
        this.availableFilters.push({
          id: 'place_group',
          label: 'PLANNING_SEARCH_PLACELIST_TITLE',
          values: placesLists.map(getName),
        });
        this.availableFilters.push({
          id: 'event_type',
          label: 'PLANNING_SEARCH_EVENTTYPE_TITLE',
          values: eventTypes.map((e) => e.contents.title),
        });

        const reduceToNameHash = (hash, entity) => {
          hash[entity.contents.name] = entity._id;
          return hash;
        };

        this.placesListsNameHash = placesLists.reduce(reduceToNameHash, {});
        this.usersGroupsNameHash = usersGroups.entries.reduce(
          reduceToNameHash,
          {}
        );
        this.eventTypesTitleHash = eventTypes.reduce(
          (hash, event) => ({
            ...hash,
            [event.contents.title]: event._id,
          }),
          {}
        );
      });
  }

  loadCalendarEvents(): ng.IPromise<void> {
    this.contentService.scrollTopById('managerCalendarContent');

    const filters = this.getFilters();

    this.isLoading = true;
    return this.managerCalendarService
      .getManagerCalendarEvents(this.date, filters)
      .then((eventsByDay: DateEvents[]) => {
        this.events = eventsByDay;
      })
      .catch((loadingError: Error) => {
        this.errorMessagesService.display(loadingError, {
          inlineErrorMessageUpdater: (message: string) => {
            this.errorMessage = message;
          },
        });
        throw loadingError;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  setSelectedDate(date: Date): void {
    this.date = date;
  }

  onDateChanged(momentDate: MomentInput): ng.IPromise<unknown> {
    const date = this.dateService.toDate(momentDate);

    this.setSelectedDate(date);
    return this.loadCalendarEvents();
  }

  goToNextMonth(): ng.IPromise<unknown> {
    const momentDate = this.dateService.toMoment(this.date);

    this.setSelectedDate(this.dateService.toDate(momentDate.add(1, 'month')));
    return this.loadCalendarEvents();
  }

  goToPreviousMonth(): ng.IPromise<unknown> {
    const momentDate = this.dateService.toMoment(this.date);

    this.setSelectedDate(
      this.dateService.toDate(momentDate.subtract(1, 'month'))
    );
    return this.loadCalendarEvents();
  }

  goToToday(): ng.IPromise<void> {
    const scroll = () => {
      const todayIndex = this.calendarEventsService.closestToToday(this.events);
      this.scrollTo(todayIndex);
    };

    if (this.dateService.isToday(this.date)) {
      scroll();
      return Promise.resolve();
    }

    this.setSelectedDate(new Date());
    return this.loadCalendarEvents().then(() => {
      scroll();
    });
  }

  onUserSearchChange(search: string): ng.IPromise<unknown> {
    this.userSearch = search;
    return this.loadCalendarEvents();
  }

  onFiltersChange(
    filters: Record<string, string | number>[]
  ): ng.IPromise<unknown> {
    this.filters = filters;
    return this.loadCalendarEvents();
  }

  getFilterValue(id: string | number, value: string | number): string | number {
    switch (id) {
      case 'assignee_group':
        return this.usersGroupsNameHash[value];
      case 'place_group':
        return this.placesListsNameHash[value];
      case 'event_type':
        return this.eventTypesTitleHash[value];
      default:
        return value;
    }
  }

  getFilters(): Record<string, unknown> {
    return this.filters.reduce(
      (obj, filter) =>
        Object.assign(obj, {
          [filter.id]: this.getFilterValue(filter.id, filter.value),
        }),
      {
        ...(this.userSearch ? { assignee_fullName: this.userSearch } : {}),
      }
    );
  }

  scrollTo(index: number): void {
    const key = 'managerCalendarContent';
    const id = `#mc-date-${index}`;

    setTimeout(() => {
      const elm = this.$window.document.querySelector(`[key="${key}"] ${id}`);

      scrollIntoView(elm, {
        time: 300,
        align: {
          top: 0,
        },
      });
    }, 100);
  }

  onClickOpenCreateCalendarEventModal(): void {
    this.canRequestChange
      ? this.openRequestEventCreationModal()
      : this.openAddCalendarEventModal();
  }

  onCalendarEventCreate(event: CalendarEvent): ng.IPromise<void> {
    this.calendarEventsNotificationsService.schedule(event);
    this.setSelectedDate(
      this.dateService
        .toMoment(
          this.dateService.moment
            .tz(event.contents.start_dateTime, event.contents.timezone.name)
            .toArray()
        )
        .toDate()
    );

    return this.loadCalendarEvents();
  }

  openAddCalendarEventModal(): void {
    const template = `
      <sf-create-calendar-event-modal
        event-date="$ctrl.eventDate"
        on-create="$ctrl.onCalendarEventCreate(event)"
        on-close="$ctrl.onClose()">
      </sf-create-calendar-event-modal>
    `;

    this.modalService.open(
      template,
      {
        eventDate: this.date,
        onCalendarEventCreate: (event: CalendarEvent) =>
          this.onCalendarEventCreate(event),
      },
      { hardwareBackButtonClose: false }
    );
  }

  openRequestEventCreationModal(): void {
    const template = `
      <sf-request-event-creation-modal
        event-date="$ctrl.eventDate"
        on-close="$ctrl.onClose()">
      </sf-request-event-creation-modal>
    `;

    this.modalService.open(
      template,
      {
        eventDate: this.date,
      },
      { hardwareBackButtonClose: false }
    );
  }

  goToPersonalCalendar(): void {
    this.$state.go('index.calendar-events.list');
  }

  selectCalendarMode(): () => void {
    const actionSheetConfig: ActionSheetConfig = {
      cancelText: this.translations.cancel,
    };

    return this.actionSheetService.open(
      [
        {
          text: this.translations.goToPersonalCalendar,
          onClick: () => this.goToPersonalCalendar(),
        },
        {
          text: this.translations.goToManagerCalendar,
          onClick: () => '',
        },
      ],
      actionSheetConfig
    );
  }
}
