import { LocalizationService } from '../../../services/Utils/Localization/localization.service';
import { RouterService } from '../../../services/Utils/Router/router.service';
import { ViewController } from '../view/view.controller';

export const NavBarComponent = {
  bindings: {
    title: '@?',
    subTitle: '@?',
    onTitleClick: '&?',
    displayBackButton: '<?',
    fallbackBackLink: '@?',
    isTransparent: '<?',
  },
  require: {
    viewController: '?^sfView',
  },
  transclude: true,
  templateUrl: 'components/Layout/nav-bar/nav-bar.html',
  controller: class NavBarController {
    viewController: ViewController;
    fallbackBackLink: string;
    isRTLNeeded: boolean;

    constructor(
      private $element: ng.IRootElementService,
      private routerService: RouterService,
      private localizationService: LocalizationService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();

      if (!this.viewController) {
        return;
      }

      const bar = this.$element[0].querySelector('.bar-header');

      if (bar) {
        this.viewController.registerNavBar(bar as HTMLElement);
      }
    }
    goBack(): void {
      this.routerService.goBack(this.fallbackBackLink);
    }
  },
};
