export const ItemHeaderComponent = {
  bindings: {
    authorAvatar: '<?',
    authorType: '@',
    headerTitle: '<',
    subtitlesList: '<',
    targeting: '<?',
  },
  templateUrl: 'components/Items/item-header/item-header.html',
};
