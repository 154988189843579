const PROGRESS_CIRCLE_DEFAULT_SIZE = 180;
const DIVIDER_BY_2 = 2;
const CIRCLE_BORDER_SIZE = 5;

export const ProgressCircleComponent = {
  bindings: {
    percent: '<',
    label: '<',
    secondaryLabels: '<?',
    numerator: '<?',
    denominator: '<?',
    size: '<',
    colorClassOverride: '<',
    labelColorClassOverride: '<?',
  },
  templateUrl: 'components/Progress/progress-circle/progress-circle.html',
  controller: class ProgressCircleController {
    constructor(SF_NUMBERS_SCALE) {
      'ngInject';
      Object.assign(this, {
        SF_NUMBERS_SCALE,
      });
    }

    $onInit() {
      const circleSize =
        (this.size || PROGRESS_CIRCLE_DEFAULT_SIZE) / DIVIDER_BY_2;
      const finalCircleSize = circleSize - CIRCLE_BORDER_SIZE;

      this.circleSize = circleSize;
      this.circleStyle = {
        height: `${this.size}px`,
        width: `${this.size}px`,
      };
      this.totalCirconference = Math.round(
        DIVIDER_BY_2 * Math.PI * finalCircleSize
      );
      this.calculProgress();
    }

    $onChanges() {
      this.calculProgress();
    }

    setColorClass() {
      if (this.colorClassOverride) {
        this.colorClass = this.colorClassOverride;
        return;
      }

      const colorClass =
        this.SF_NUMBERS_SCALE.LOW_LIMIT >= Number(this.percent)
          ? 'sf_progressCircle__percentCircle--low'
          : this.SF_NUMBERS_SCALE.HIGH_LIMIT <= Number(this.percent)
          ? 'sf_progressCircle__percentCircle--high'
          : null;

      this.colorClass = colorClass;
    }

    calculProgress() {
      const ratio = this.totalCirconference / 100;
      const percentInLength = Math.round(this.percent * ratio);

      this.dasharrayValue = percentInLength + ' ' + this.totalCirconference;
      this.percentInLength = percentInLength;

      this.setColorClass();
    }
  },
};
