const INFINITE_ERROR_TIME_DISPLAY = 4000;
const INFINITE_TIME_HIDE = 1000;

export const InfiniteScrollComponent = {
  bindings: {
    hasError: '<',
    onInfiniteTrigged: '&',
    scrollCompleteFn: '=?',
    onErrorMessageHide: '&',
    contentKey: '@',
  },
  templateUrl: 'components/Scroll/infinite-scroll/infinite-scroll.html',
  controller: function InfiniteScrollController(
    $timeout,
    contentService,
    $scope
  ) {
    'ngInject';

    this.$onInit = () => {
      this.timeout = null;
      this.isInfiniteDisplay = true;

      this.scrollCompleteFn = this.scrollComplete;
    };

    this.$onChanges = (changes) => {
      if (changes.hasError && changes.hasError.currentValue) {
        if (this.timeout) {
          this.timeout();
        }

        this.isInfiniteDisplay = false;
        this.timeout = $timeout(() => {
          contentService.resizeById(this.contentKey);

          this.onErrorMessageHide();
          this.timeout = $timeout(() => {
            this.isInfiniteDisplay = true;
            this.timeout = null;
          }, INFINITE_TIME_HIDE);
        }, INFINITE_ERROR_TIME_DISPLAY);
      }
    };

    this.scrollComplete = () => {
      $scope.$broadcast('scroll.infiniteScrollComplete');
    };
  },
};
