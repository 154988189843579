import { QuestionPhotoComponent } from './question-photo.component';

export default angular
  .module('sfMobile.components.question-photo', [
    'sfMobile.constants.image-sizes',
    'pascalprecht.translate',
    'sfMobile.services.objectId',
    'sfMobile.services.camera',
    'sfMobile.services.files',
    'sfMobile.services.image-picker',
    'sfMobile.services.modal',
    'sfMobile.services.keyboard',
    'sfMobile.services.sentry',
    'sfMobile.services.action-sheet',
    'sfMobile.services.picture-system',
    'sfMobile.services.lifecycle',
    'sfMobile.components.button-big',
    'sfMobile.components.input-file-upload',
    'sfMobile.services.pubsub',
    'sfMobile.services.tags',
    'sfMobile.tags-selector',
    'sfMobile.services.tags',
  ])
  .component('questionPhoto', QuestionPhotoComponent).name;
