import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { ImageService } from '../../../services/Utils/Image/image.service';

export default class AvatarOrganisationController
  implements ng.IComponentController
{
  organisation;
  imageSrc: string;

  /* @ngInject */
  constructor(
    private $q: ng.IQService,
    private imageService: ImageService,
    private $log: ng.ILogService,
    private SF_IMAGE_SIZES: typeof IMAGE_SIZES
  ) {}

  $onInit(): ng.IPromise<string> {
    return this.computeImageUrl();
  }
  $onChanges(): ng.IPromise<string> {
    return this.computeImageUrl();
  }

  computeImageUrl() {
    if (!this.organisation?.contents?.logo_id) {
      this.imageSrc = '';
      return this.$q.when('');
    }

    return this.imageService
      .getSizedUrlFromId(
        this.organisation.contents.logo_id,
        this.SF_IMAGE_SIZES.SQUARE_SMALL
      )
      .then((url) => {
        this.imageSrc = url;
        return url;
      })
      .catch((error) => {
        this.$log.error(
          'An error occured when retrieving organisation logo',
          error
        );

        throw error;
      });
  }
}
