import { User, ObjectId } from '../../../..';
import { Form } from '../../../../services/API/forms/forms';
import {
  Mission,
  MISSIONS_TYPE,
} from '../../../../services/API/Missions/missions';
import { APIStore } from '../../../';
import { PlaceService } from '../../services/place.service';

export class PlaceMissionsListController {
  // bindings
  place: APIStore;
  profile: User;
  missionsType: MISSIONS_TYPE;
  title: string;

  // fields
  private missionsForms?: Form[];
  private formsMissionsNb?: Record<ObjectId, number>;

  constructor(private helpersService, private placeService: PlaceService) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    return this.getMissionsForms();
  }

  getMissionsForms(): ng.IPromise<void> {
    return this.placeService
      .getPlaceMissionsData(this.place._id, this.profile._id)
      .then(({ reports, missions, forms }) => {
        if (this.missionsType === 'ponctual') {
          const missionsHash =
            this.helpersService.getMissionsHashByFormId(missions);
          const missionsForms = this.helpersService.getAvailableFormsOfMissions(
            forms,
            missions,
            reports
          );

          return { missionsHash, missionsForms };
        }
        return {
          missionsForms: this.helpersService.getFormsAvailableOnPlace(
            forms,
            this.place._id,
            this.profile._id
          ),
        };
      })
      .then(({ missionsHash, missionsForms }) => {
        this.missionsForms = missionsForms.sort((a, b) =>
          this.helpersService.sortNatural(a.contents.title, b.contents.title)
        );
        if (missionsHash) {
          this.formsMissionsNb = Object.entries(missionsHash).reduce(
            (output, data) => {
              const [key, missions] = data;

              output[key] = (missions as Mission[]).length;
              return output;
            },
            {}
          );
        }
      });
  }
}
