const DATE_FORMATTING_STRINGS = {
  time: 'LT',
  date: 'll',
  complete_date: 'dddd LL',
  date_and_time: 'llll',
  day_of_month: 'ddd',
  day_number: 'D',
  month_and_year: 'MMMM YYYY',
  year_and_month: 'YYYY MMMM',
  date_month_and_year: 'DD/MM/YY',
  newsfeed_time: 'hh:mm A [•] D MMM YYYY',
  newsfeed_draft_time: 'MMM DD, YYYY, h:mm A',
  event_params_date: 'MMM DD, YYYY',
};
const INTL_DATE_FORMATTING_OPTS = {
  dateTz: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZoneName: 'short',
  },
};

export default angular
  .module('sfMobile.constants.dates-formating', [])
  .constant('INTL_DATE_FORMATTING_OPTS', INTL_DATE_FORMATTING_OPTS)
  .constant('DATE_FORMATTING_STRINGS', DATE_FORMATTING_STRINGS).name;
