import { ImagePreviewController } from './image-preview.controller';

export const ImagePreviewComponent = {
  bindings: {
    pictures: '<',
    isCover: '<',
    isWidget: '<',
  },
  templateUrl: 'components/Images/image-preview/image-preview.html',
  controller: ImagePreviewController,
};
