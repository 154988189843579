import { PictureSystemService } from './picture-system.service';

export default angular
  .module('sfMobile.services.picture-system', [
    'pascalprecht.translate',
    'sfMobile.services.action-sheet',
    'sfMobile.services.image-picker',
    'sfMobile.services.camera',
    'sfMobile.services.foreground-notification',
    'sfMobile.services.file-system',
    'sfMobile.services.file-chooser',
  ])
  .service('pictureSystemService', PictureSystemService).name;
