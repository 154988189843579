import { PredefinedValue } from '../../..';
import type {
  FilterCustomParam,
  FilterSelected,
  FilterStandard,
} from '../search';

type RetroCompatFilter = { label?: string; value: { label?: string } };

export const SearchFilterListComponent = {
  require: {
    modelCtrl: 'ngModel',
  },
  bindings: {
    filtersList: '<',
    isActionDisabled: '<',
  },
  templateUrl: 'components/Search/search-filter-list/search-filter-list.html',
  controller: class SearchFilterListController implements ng.IController {
    // bindings
    filtersList: (FilterStandard | FilterCustomParam)[];

    // members
    filtersListLabelHash: Record<string, string>;
    filtersPredefinedValuesListHash: Record<string, PredefinedValue[]>;
    modelCtrl: ng.INgModelController;
    filters: FilterSelected[];

    $onChanges(): void {
      this.filtersListLabelHash = this.filtersList.reduce((hash, filter) => {
        hash[filter.id] = filter.label;
        return hash;
      }, {});
      this.filtersPredefinedValuesListHash = this.filtersList.reduce(
        (hash, filter) => {
          if (
            ['SingleChoice', 'MultipleChoice'].includes(
              (filter as FilterCustomParam).type
            )
          ) {
            hash[filter.id] = filter.values;
          }
          return hash;
        },
        {}
      );
      this.modelCtrl.$render = () => {
        this.filters = this.modelCtrl.$viewValue || [];
        this.modelCtrl.$setViewValue(this.filters);
      };
    }

    removeFilter(filter: FilterSelected): FilterSelected[] {
      const newFilters = this.filters.filter((value) => filter.id !== value.id);

      this.filters = newFilters;
      this.modelCtrl.$setViewValue(newFilters);

      return newFilters;
    }

    getFilterName(filterId: string): string {
      return this.filtersListLabelHash[filterId];
    }
    getFilterValue(
      filter: FilterSelected & RetroCompatFilter
    ): string | number | string[] {
      let value = filter.value;

      if (filter.paramType) {
        switch (filter.paramType) {
          case 'SingleChoice':
            value =
              this.filtersPredefinedValuesListHash[filter.id]?.find(
                ({ id }) => id === filter.value
              )?.value || '';
            break;

          case 'MultipleChoice':
            value = (filter.value as string[])
              .map(
                (predefId) =>
                  this.filtersPredefinedValuesListHash[filter.id]?.find(
                    ({ id }) => id === predefId
                  )?.value || ''
              )
              .join(', ');
            break;

          case 'date':
            value = new Date(filter.value as string).toLocaleDateString();
            break;
          default:
            break;
        }
        return value;
      }
      if (filter.value && filter.value.label) {
        value = filter.value.label;
      }
      if (filter.label && !value) {
        value = filter.label;
      }
      return value;
    }
  },
};
