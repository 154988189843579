import { ObjectId, User } from '../../..';
import { DataAccessLayerService } from '../../Utils/CRUD/crud-service';
import { CrudFactory } from '../../Utils/CRUD/crud-service.factory';
import { TranslationDictionnary } from '../../Utils/sfTranslation/sf-translation.filters';
import { FormContents } from '../forms/forms';
import { Report } from '../reports/reports';
import { ReportsService } from '../reports/reports.service';

import { Template } from './templates';

export class TemplatesService {
  crud: DataAccessLayerService<Template>;

  /* @ngInject */
  constructor(
    private crudFactory: CrudFactory<Template>,
    private reportsService: ReportsService
  ) {
    this.crud = this.crudFactory('checklist-templates', {
      default_params: {
        mode: 'compact',
      },
      take_care_of_user_profile: false,
    });
  }

  initReport(
    reportId: ObjectId,
    template: Template,
    profile: User,
    placeId?: ObjectId
  ): Report {
    const report = this.reportsService.getNewReportForTemplate(
      reportId,
      template,
      profile
    );

    if (placeId) {
      report.contents.place_id = placeId;
    }

    return report;
  }

  getFormFromTemplate(template: Template): {
    _id: ObjectId;
    contents: FormContents;
    i18n: TranslationDictionnary;
  } {
    const formContents: FormContents = {
      ...(template.contents.form as unknown as FormContents),
      statusMap: [],
      title: template.contents.name,
    };

    return {
      _id: template._id,
      contents: formContents,
      i18n: {},
    };
  }
}
