export default function authInterceptor($q, $injector) {
  'ngInject';
  return { request: authToken };

  function authToken(config) {
    const authService = $injector.get('authService');
    const apiUrlService = $injector.get('apiUrlService');
    const $state = $injector.get('$state');
    const apiUrl = apiUrlService.getApiUrl();

    const isLoggedView = () => {
      const isAnApiCall = config.url.indexOf(apiUrl) !== -1;
      const isAPingCall = config.url.indexOf('/ping') !== -1;
      const authExist = config.headers && config.headers.Authorization;

      return isAnApiCall && !isAPingCall && !authExist;
    };

    // Add token for Simplifield API url
    return (
      isLoggedView()
        ? authService.setAuthenticationConfig(config)
        : $q.when(config)
    ).catch((err) => {
      if (err === 'E_NO_TOKEN') {
        $state.go('login.page', {}, { reload: true });
        config.cancel.resolve();
      }
    });
  }
}
