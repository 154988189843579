const TIMEOUT_TIME = 10000;

export function PingService(
  $http,
  apiUrlService,
  errorMessagesService,
  SF_ERROR_CODES,
  logService
) {
  'ngInject';
  var methods = {
    ping,
  };

  function ping(displayErrorMessage = true, from = 'N/A') {
    var logUrl = `${apiUrlService.getApiUrl()}/ping`;
    logService.info(`[BUGS-2566] ping.service.js | ping`, { from }, true);

    return pingUrl(logUrl, displayErrorMessage);
  }

  function pingUrl(url, displayErrorMessage) {
    const requestConfig = { timeout: TIMEOUT_TIME };

    return $http
      .get(url, requestConfig)
      .then((response) => response.data)
      .catch((e) => {
        const error = new Error(`No network on ping (${e.message || '--'})`);

        error.status = SF_ERROR_CODES.CODES_SERVER_NO_NETWORK[0];
        displayErrorMessage &&
          errorMessagesService.display(error, {
            inlineErrorMessageUpdater: () => {},
          });
        throw error;
      });
  }

  return methods;
}
