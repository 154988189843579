import { CustomParamsSelectorModalComponent } from './custom-params-selector-modal.component';

export default angular
  .module('sfMobile.components.custom-params-selector-modal', [
    'sfMobile.services.requests-paginate',
    'sfMobile.services.static-requests-paginate',
    'sfMobile.components.button-header-close',
    'sfMobile.services.searchUtils',
  ])
  .component('sfCustomParamsSelectorModal', CustomParamsSelectorModalComponent)
  .name;
