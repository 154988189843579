
// sf-popup-moved-to-draft
export class PopupObjectiveAlreadyCompleteController
  implements ng.IComponentController
{
  // bindings
  errorMessage: string;
  onClose: () => void;
  onSuccess: () => void;

  onSubmit(): void {
    this.onSuccess();
    this.onClose();
  }
}
