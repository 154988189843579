import { VideoPlayerController } from './video-player.controller';

export const sfVideoPlayer: ng.IComponentOptions = {
  bindings: {
    videoId: '<',
    isWidget: '<',
  },
  controller: VideoPlayerController,
  templateUrl: 'components/Video/video-player/video-player.html',
};
