const HEIGHT_LIMIT = 100;
const TEXT_SEE_MORE_SMALL_SPACE = 10;

export const TextSeeMoreComponent = {
  bindings: {},
  templateUrl: 'components/text-see-more/text-see-more.html',
  transclude: true,
  controller: function TextSeeMoreController($element, $timeout) {
    'ngInject';
    this.$onInit = () => {
      this.isFolded = true;

      $timeout(() => {
        this.checkActivation();
      }, 0);
    };

    this.checkActivation = () => {
      const content = $element[0].querySelector('.sf_text_see_more__content');

      this.isActivate = HEIGHT_LIMIT < content.clientHeight;
      this.contentHeight = content.clientHeight + TEXT_SEE_MORE_SMALL_SPACE;
    };

    this.toggle = () => {
      this.isFolded = !this.isFolded;
    };

    this.getContentStyle = () =>
      this.isActivate ? { height: this.getTextHeight() } : {};

    this.getTextHeight = () =>
      this.isFolded ? `${HEIGHT_LIMIT}px` : `${this.contentHeight}px`;
  },
};
