export const GoogleMapModalComponent = {
  bindings: {
    markers: '<',
    onMarkerClick: '&',
    onMapClick: '&',
  },
  templateUrl: 'components/Map/google-map-modal/google-map-modal.html',
  // eslint-disable-next-line max-params
  controller: class GoogleMapModalController {
    constructor($document, geolocationService, googleMapService) {
      'ngInject';
      Object.assign(this, {
        $document,
        geolocationService,
        googleMapService,
      });
    }

    $onInit() {
      const geoloc = this.geolocationService.getCurrentPosition();

      this.isMapLoading = true;

      return geoloc
        .then(
          (position) =>
            position || this.googleMapService.getCenter(this.markers)
        )
        .then((latLng) => {
          this.position = latLng;
        });
    }

    $onDestroy() {
      this.onModalClose();
    }

    onModalClose() {
      this.setBodyClass(false);
    }

    setBodyClass(isOpen) {
      const body = this.$document[0].querySelector('body');
      const googleMapBodyClass = 'sf_google_map_modal--opened';

      if (isOpen) {
        body.classList.add(googleMapBodyClass);
      } else {
        body.classList.remove(googleMapBodyClass);
      }
    }

    onModalMarkerClick(marker) {
      this.onMarkerClick({ marker: marker });
    }

    onModalMapLoaded() {
      this.setBodyClass(true);
      this.isMapLoading = false;
    }
  },
};
