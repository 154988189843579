export const FormSectionComponent = {
  bindings: {
    item: '<',
    form: '<',
    node: '<',
    context: '<',
    sectionNumber: '<',
    subSectionNumber: '<',
    isDone: '<',
    formErrors: '=',
    profile: '<',
    place: '<',
    unconfirmedPrefilledQuestions: '=',
    availablePreviousAnswers: '<',
    isPreview: '<',
    isDisplayed: '<',
    useNewCampaigns: '<',
    isDebug: '<?',
    events: '<?',
    onCalendarEventCreated: '&?',
    onCalendarEventDeleted: '&?',
  },
  templateUrl: 'missions/form/components/form-section/form-section.html',
  controller: class FormSectionController {
    constructor(
      pubSubService,
      formsService,
      formService,
      sfFeatureFlagsService,
      SF_FEATURE_FLAGS
    ) {
      'ngInject';
      Object.assign(this, {
        pubSubService,
        formsService,
        formService,
        sfFeatureFlagsService,
        SF_FEATURE_FLAGS,
      });
    }

    $onInit() {
      this.subSectionsIds = this.item.items.reduce(
        (acc, item) => (item.items ? acc.concat(item._id) : acc),
        []
      );

      this.questionsDisplay = this.item.items.reduce((acc, item) => {
        if (!item.items) {
          acc[item._id] = !!this.displayQuestion(item);
        }
        return acc;
      }, {});

      this.answerChangedListener = this.pubSubService.subscribe(
        'QUESTION_ANSWER_CHANGED',
        (data) => this.onAnswerChanged(data)
      );
    }

    $onDestroy() {
      if (this.answerChangedListener) {
        this.answerChangedListener();
      }
    }

    onAnswerChanged(data) {
      const isConditionSourceQuestion = (question, item) => {
        return (
          item.metadata &&
          item.metadata.conditions &&
          item.metadata.conditions.some(
            (condition) => condition.item === question._id
          )
        );
      };

      this.questionsDisplay = this.item.items.reduce((acc, item) => {
        if (!item.items && isConditionSourceQuestion(data, item)) {
          acc[item._id] = !!this.displayQuestion(item);
        }
        return acc;
      }, this.questionsDisplay);
    }

    hasInfos(item) {
      return item.description || (item.files && item.files.length);
    }

    displayQuestion(item) {
      var answers = this.context.report.contents.answers;

      return this.formsService.displayQuestion(
        item,
        this.form,
        answers,
        this.context
      );
    }

    getSubSectionNumber(sectionId) {
      return this.subSectionsIds.indexOf(sectionId) + 1;
    }

    canDisplayQuestion(question) {
      if (this.isDebug) {
        return true;
      }
      return this.questionsDisplay[question._id];
    }
    getSectionContional() {
      const condition = this.formService.getQuestionCondition(this.item);

      return condition ? condition.name : null;
    }

    calendarEventCreated(questionId, event) {
      this.onCalendarEventCreated({ questionId, event });
    }

    calendarEventDeleted(eventId) {
      this.onCalendarEventDeleted({ eventId });
    }
  },
};
