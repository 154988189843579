import { ReportSendingService } from './report-sending.service';

export default angular
  .module('sfMobile.services.report-sending', [
    'sfMobile.services.reports',
    'sfMobile.services.forms',
    'sfMobile.services.files',
    'sfMobile.services.file-system',
    'sfMobile.services.ping',
    'sfMobile.services.sentry',
    'sfMobile.services.intercom',
    'sfMobile.services.error-messages',
    'sfMobile.services.segment',
    'sfMobile.services.campaigns',
    'sfMobile.services.log',
  ])
  .factory('ReportSending', ReportSendingService).name;
