import { PlaceMissionsListController } from './place-missions-list.controller';

export const PlaceMissionsListComponent: ng.IComponentOptions = {
  bindings: {
    place: '<',
    profile: '<',
    missionsType: '<',
    title: '@',
  },
  templateUrl:
    'places/place/components/place-missions-list/place-missions-list.html',
  controller: PlaceMissionsListController,
};
