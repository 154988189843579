import { LinkPreviewService } from '@services/API/link-preview/link-preview.service';
import { Campaign } from '../../../services/API/campaigns/campaigns';
import { CampaignsUtilsService } from '../../services/campaigns-utils.service';

export const ReactiveCampaignInfoComponent: ng.IComponentOptions = {
  bindings: {
    campaign: '<',
  },
  templateUrl:
    'reactive-campaigns/components/reactive-campaign-info/reactive-campaign-info.html',
  controller: class ReactiveCampaignInfoController implements ng.IController {
    /** Bindings */
    campaign: Campaign;

    /** Fields */
    activationPeriod: string;
    activationDateTime: string;
    dueDateTime: string;
    campaignDescriptionTranslation: string;
    onLinkPreviewClick: ($event: Event) => void;

    constructor(
      private campaignsUtilsService: CampaignsUtilsService,
      private $translate: ng.translate.ITranslateService,
      private linkPreviewService: LinkPreviewService
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.activationPeriod = this.campaignsUtilsService.getActivationPeriod(
        this.campaign
      );

      if (this.campaign.contents?.objective) {
        [this.activationDateTime, this.dueDateTime] =
          this.campaignsUtilsService.getFrequencyPeriods(this.campaign);
      }

      if (this.campaign.contents.linkPreview?.origin) {
        this.onLinkPreviewClick = this.linkPreviewService
          .onLinkPreviewClick(this.campaign.contents.linkPreview?.origin)
          .bind(this);
      }
      this.campaignDescriptionTranslation = this.campaign.contents.description
        ? this.$translate.instant(
            this.campaign.contents.description,
            this.campaign.i18n
          )
        : '--';
    }
  },
};
