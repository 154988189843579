import { StateService, TransitionPromise } from '@uirouter/core';
import { Report, ReportFormContents } from '../../services/API/reports/reports';
import { ReportsService } from '../../services/API/reports/reports.service';
import { LocalizationService } from '../../services/Utils/Localization/localization.service';
import { PlatformService } from '../../services/Utils/Platform';
import { PubSubService } from '../../services/Utils/PubSub/pubsub.service';
import { RouterService } from '../../services/Utils/Router/router.service';
import { TabService } from '../../services/Utils/Tabs';

const TABS_STATES = [
  {
    stateName: 'index.menu-more.missions.lists.missions',
    isShownInBrowser: true,
  },
  {
    stateName: 'index.menu-more.missions.lists.drafts',
    isShownInBrowser: false,
  },
  {
    stateName: 'index.menu-more.missions.lists.ready',
    isShownInBrowser: false,
  },
  {
    stateName: 'index.menu-more.missions.lists.sent',
    isShownInBrowser: true,
  },
];

export const MissionsComponent: ng.IComponentOptions = {
  bindings: {},
  templateUrl: 'missions/missions/missions.html',
  controller: class MissionsController {
    formListener: () => void;
    onGoingFormListener: () => void;
    draftsNumber = 0;
    readyNumber = 0;
    isBrowser: boolean;
    missionsTabs: IonicV1.IonicTab | undefined;
    isRTLNeeded: boolean;

    // eslint-disable-next-line max-params
    constructor(
      private localizationService: LocalizationService,
      private routerService: RouterService,
      private tabsService: TabService,
      private pubSubService: PubSubService,
      private $state: StateService,
      private reportsService: ReportsService,
      private segmentService,
      private platformService: PlatformService,
      private $timeout: ng.ITimeoutService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<Report[]> {
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();

      this.formListener = this.pubSubService.subscribe(
        'FORM_REPORT_UPDATED',
        () => this.fetchTabsNumbers()
      );
      this.onGoingFormListener = this.pubSubService.subscribe(
        'ONGOING_REPORTS_UPDATED',
        () => this.fetchTabsNumbers()
      );

      this.isBrowser = this.platformService.isBrowser();

      const hardwareBackAction = this.platformService.onHardwareBackButton(
        () => {
          hardwareBackAction();
          return this.$state.go('index.menu-more.content');
        }
      );
      return this.fetchTabsNumbers();
    }

    $onDestroy(): void {
      this.formListener();
      this.onGoingFormListener();
    }

    $postLink(): void {
      this.$timeout(() => {
        this.missionsTabs = this.tabsService.getTab('missionsTabs');
      }, 0);
    }

    goForward(): void {
      const selectedTabIndex =
        (this.missionsTabs && this.missionsTabs.selectedIndex()) ?? 0;

      this.segmentService.track('MISSIONS_TAB', {
        action: 'swipe',
        label: 'Swipe forward',
      });
      if (selectedTabIndex !== -1) {
        this.goToTab(selectedTabIndex + 1);
      }
    }

    goBack(): void {
      const selectedTabIndex =
        (this.missionsTabs && this.missionsTabs.selectedIndex()) ?? 0;

      this.segmentService.track('MISSIONS_TAB', {
        action: 'swipe',
        label: 'Swipe backward',
      });
      if (selectedTabIndex !== -1) {
        this.goToTab(selectedTabIndex - 1);
      }
    }

    fetchTabsNumbers(): ng.IPromise<Report[]> {
      return this.reportsService.crud
        .queryLocal({ localStatus: ['draft', 'ready'] })
        .then((reports) => {
          this.draftsNumber = reports.filter(
            (r) =>
              r.localStatus === 'draft' &&
              (r.contents as ReportFormContents).form_id
          ).length;
          this.readyNumber = reports.filter(
            (r) =>
              r.localStatus === 'ready' &&
              (r.contents as ReportFormContents).form_id
          ).length;
          return reports;
        });
    }

    goToTab(selectedTabIndex: number): void {
      const tabStateName = this.getStateNameByIndex(selectedTabIndex);

      tabStateName && this.goToWithoutHistory(tabStateName);
    }

    getStateNameByIndex(index: number): string | undefined {
      const states = this.isBrowser
        ? TABS_STATES.filter(({ isShownInBrowser }) => isShownInBrowser)
        : TABS_STATES;
      const tabIndex = this.getStateIndexByTabIndex(index, states.length);
      const state = states.find((_, i) => i === tabIndex);

      return state && state.stateName;
    }

    getStateIndexByTabIndex(tabIndex: number, statesLength: number): number {
      if (tabIndex === -1) {
        // swipe from left to right on the 1st tab
        return statesLength - 1;
      }
      return tabIndex >= statesLength ? 0 : tabIndex;
    }

    goToWithoutHistory(stateName: string): TransitionPromise {
      this.routerService.removePreviousView();
      return this.$state.go(stateName);
    }
  },
};
