import { GroupEntityListModalController } from './group-entity-list-modal.controller';

export const GroupEntityListModalComponent: ng.IComponentOptions = {
  bindings: {
    groupsIds: '<',
    entityType: '<',
    entities: '<',
    onClose: '&',
  },
  templateUrl:
    'tasks/components/group-task-detail/components/group-entity-list-modal/group-entity-list-modal.html',
  controller: GroupEntityListModalController,
};
