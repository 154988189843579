import SignaturePad from 'signature_pad';

const SCALE = 2;

export const SignatureComponent = {
  bindings: {
    clear: '=?',
    resize: '=?',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  templateUrl: 'components/Inputs/signature/signature.html',
  // eslint-disable-next-line max-params
  controller: class SignatureController {
    constructor($element, $document, $attrs) {
      'ngInject';
      Object.assign(this, {
        $element,
        $document,
        $attrs,
      });
      this.SignaturePad = SignaturePad;
    }

    $onInit() {
      const padOptions = {
        onEnd: () => {
          const signature = this.signaturePad.toDataURL('image/jpg', 1.0);

          this.modelCtrl.$setViewValue(signature);
        },
        dotSize: 5,
        backgroundColor: 'rgb(255,255,255)',
      };

      this.element = this.$element[0];

      this.canvas = this.element.querySelector('canvas');
      this.ctx = this.canvas.getContext('2d');
      this.inMemCanvas = this.$document[0].createElement('canvas');
      this.inMemCtx = this.inMemCanvas.getContext('2d');
      this.parent = this.$element.parent()[0];

      this.signaturePad = new this.SignaturePad(this.canvas, padOptions);

      if (this.$attrs.clear) {
        this.clear = () => this.signaturePad.clear();
      }
      if (this.$attrs.resize) {
        this.resize = () => this.setDimensions();
      }

      // Specify how the model is deemed empty
      this.modelCtrl.$isEmpty = () => this.signaturePad.isEmpty();
    }

    $onDestroy() {
      if (this.signaturePad && angular.isFunction(this.signaturePad.off)) {
        this.signaturePad.off();
      }
    }

    setDimensions() {
      const pHeight = this.parent.clientHeight;
      const pWidth = this.parent.clientWidth;
      const isPortrait = pHeight > pWidth / SCALE;
      const oldHeight = this.ctx.canvas.height;
      const oldWidth = this.ctx.canvas.width;
      const newHeight = isPortrait ? pWidth / SCALE : pHeight;
      const scale = newHeight / oldHeight;

      this.inMemCtx.canvas.width = oldWidth;
      this.inMemCtx.canvas.height = oldHeight;
      if (this.canDraw()) {
        this.inMemCtx.drawImage(this.canvas, 0, 0, oldWidth, oldHeight);

        this.element.style.width = `${scale * oldWidth}px`;
        this.element.style.height = `${scale * oldHeight}px`;

        this.canvas.width = scale * oldWidth;
        this.canvas.height = scale * oldHeight;

        this.ctx.webkitImageSmoothingEnabled = false;
        this.ctx.mozImageSmoothingEnabled = false;
        this.ctx.imageSmoothingEnabled = false;

        this.ctx.drawImage(
          this.inMemCanvas,
          0,
          0,
          scale * oldWidth,
          scale * oldHeight
        );
      }
    }

    canDraw() {
      return this.ctx.canvas.height > 0 && this.ctx.canvas.width > 0;
    }
  },
};
