import { TaskManageFormComponent } from './task-manage-form.component';
import TaskManageConstantModule from './task-manage-form.constants';
import PhotoPreviewModule from '../../../../components/photos-preview/photos-preview.module';

export default angular
  .module('sfMobile.components.task-manage-form', [
    'sfMobile.services.localization',
    'ngMessages',
    TaskManageConstantModule,
    'sfMobile.components.input-radio-buttons-bar',
    'sfMobile.services.profile',
    'sfMobile.services.task-manage',
    'sfMobile.services.form-validations',
    'sfMobile.services.objectId',
    'sfMobile.services.task',
    'sfMobile.services.users',
    'sfMobile.components.entity-selector',
    'sfMobile.components.input-text',
    'sfMobile.components.date-picker',
    'sfMobile.components.restriction-warning',
    PhotoPreviewModule,
    'sfMobile.services.popup-request',
    'sfMobile.services.helpers',
    'sfMobile.services.segment',
  ])
  .component('sfTaskManageForm', TaskManageFormComponent).name;
