import { ChangeRequestItemModalController } from './change-request-item-modal.controller';

export const ChangeRequestItemModalComponent: ng.IComponentOptions = {
  bindings: {
    changeRequest: '<',
    onCancel: '&',
    onApprove: '&',
    onReject: '&',
    onClose: '&',
    isEdit: '<',
    isCreation: '<',
    isDeletion: '<',
    isOwnChangeRequest: '<',
    isPending: '<',
    profile: '<',
    onCommentsCountUpdate: '<',
  },
  templateUrl:
    'change-requests/components/change-request-item-modal/change-request-item-modal.html',
  controller: ChangeRequestItemModalController,
};
