import ReportContentModule from './components/report-content/report-content.module';
import ReportStatsModule from './components/report-stats/report-stats.module';
import ReportCommentsModule from './components/report-comments/report-comments.module';
import { ReportComponent } from './report.component';

export default angular
  .module('sfMobile.components.report', [
    'sfMobile.comments',
    'sfMobile.services.task',
    'sfMobile.services.reports',
    'sfMobile.services.reportScoring',
    'sfMobile.services.forms',
    'sfMobile.services.modal',
    'sfMobile.services.dates',
    'sfMobile.services.tasks',
    'sfMobile.services.segment',
    'sfMobile.services.tabs',
    'sfMobile.services.pubsub',
    ReportContentModule,
    ReportStatsModule,
    ReportCommentsModule,
    'sfMobile.components.report-status',
    'sfMobile.components.report-validation-status',
    'sfMobile.components.tasks-list-simple',
    'sfMobile.components.user',
    'sfMobile.components.pie-chart',
    'sfMobile.reactive-campaigns',
    'sfMobile.services.reportValidation',
    'sfMobile.services.log',
  ])
  .component('sfReport', ReportComponent).name;
