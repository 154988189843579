export class NetworkError<T> extends Error {
  constructor(
    readonly response: ng.IHttpResponse<T>,
    readonly errorCode: number,
    errorMessage: string,
    readonly url?: string
  ) {
    super('NetworkError');
    this.name = `NetworkError: ${errorCode}`;
    this.message = errorMessage;
  }
}
