import { EanProductPickerController } from './ean-product-picker.controller';

export const EanProductPickerComponent = {
  bindings: {
    products: '<',
    ean: '@',
    onClose: '&',
    onSave: '&',
  },
  templateUrl:
    'missions/components/Form-questions/question-product/components/ean-product-picker/ean-product-picker.html',
  controller: EanProductPickerController,
};
