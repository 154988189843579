// @ts-check

/**
 * @typedef {string} ObjectId
 *
 * @typedef Section
 * @property {ObjectId}      [_id]        -
 * @property {string}        [title]      -
 * @property {number}        [level]      -
 * @property {number}        [maximum]    -
 * @property {number}        [minimum]    -
 * @property {any}           [itemsOrder] -
 * @property {any[]}         [sections]   -
 *
 * @typedef ReportErrorsServiceDependencies
 * @property {any} report -
 * @property {any} place -
 * @property {any} form -
 * @property {any} report -
 * @property {any} unconfirmedPrefilledQuestions -
 * @property {any} questionForm -
 *
 * @typedef ReportErrorsService
 * @property {Function} answersAreValid -
 * @property {Function} answersAreValid -
 * @property {Function} getNbReportErrors -
 * @property {Function} getQuestionsWithError -
 * @property {Function} nbRequestedQuestions -
 * @property {Function} nbRequestedQuestionsWithSubsections -
 * @property {Function} requestedByValues -
 * @property {Function} optionalByValues -
 * @property {Function} requestedQuestions -
 * @property {Function} optionalQuestions -
 * @property {any} form -
 * @property {any} place -
 * @property {any} questions -
 * @property {any} report -
 * @property {any} sections -
 * @property {any} unconfirmedPrefilledQuestions -
 * @property {any} user -
 * @property {boolean} forceErrorsDisplay -
 */

// eslint-disable-next-line max-params
export function ReportErrorsService(
  $log,
  formsService,
  reportQuestionsService,
  SF_PRODUCT_DISCOUNT_BOUNDARIES,
  SF_PRODUCT_QUANTITY_BOUNDARIES
) {
  'ngInject';

  /*
   * @param {ReportErrorsServiceDependencies} data -
   * @return {ReportErrorsService} -
   */
  function ReportErrors(data = {}) {
    if (!data.report) {
      return $log.error('You need to specify a report');
    }
    if (!data.form) {
      return $log.error('You need to specify a form');
    }

    this.form = data.form;
    this.place = data.place;
    this.user = data.user;
    this.report = data.report;
    this.questions = data.form.contents.questions || [];
    this.sections = data.form.contents.sections || [];
    this.questionForm = data.questionForm || {};
    this.unconfirmedPrefilledQuestions = data.unconfirmedPrefilledQuestions;
    this.forceErrorsDisplay = false;
    return this;
  }

  ReportErrors.prototype.requestedQuestions = requestedQuestions;
  ReportErrors.prototype.optionalQuestions = optionalQuestions;
  ReportErrors.prototype.requestedByValues = requestedByValues;
  ReportErrors.prototype.optionalByValues = optionalByValues;
  ReportErrors.prototype.nbRequestedQuestions = nbRequestedQuestions;
  ReportErrors.prototype.getQuestionsWithError = getQuestionsWithError;
  ReportErrors.prototype.getNbReportErrors = getNbReportErrors;
  ReportErrors.prototype.answersAreValid = answersAreValid;
  ReportErrors.prototype.nbRequestedQuestionsWithSubsections =
    nbRequestedQuestionsWithSubsections;

  /**
   * Filter the questions that need values
   *
   * @param {array} sections  - Form sections
   * @param {array} questions - Form questions
   * @param {array} answers   - Report answers
   * @param {object} ctx      - Context
   * @return {array} - Form questions
   * @this ReportErrorsService
   */
  function requestedByValues(sections, questions, answers, ctx) {
    const form = this.form;
    const requestQuestions = formsService.getRequestedQuestions(questions);
    const sectionsHide = sections.reduce((output, section, index) => {
      const sectionParent = getParentSection(section, index, sections);
      const isLevelLower = sectionHasALowerLevel(section, sectionParent);
      const isParentHide = isLevelLower
        ? output.indexOf(sectionParent._id) !== -1
        : false;
      const keepSection =
        isParentHide ||
        !formsService.displayQuestion(section, form, answers, ctx);

      return keepSection ? output.concat(section._id) : output;
    }, []);

    return requestQuestions.filter(takeCare);

    function takeCare(question) {
      return (
        sectionsHide.indexOf(question.section_id) === -1 &&
        formsService.displayQuestion(question, form, answers, ctx)
      );
    }
  }
  /**
   * Filter the questions that do not need values
   *
   * @param {array} sections  - Form sections
   * @param {array} questions - Form questions
   * @param {array} answers   - Report answers
   * @param {object} ctx      - Context
   * @return {array} - Form questions
   * @this ReportErrorsService
   */

  function optionalByValues(sections, questions, answers, ctx) {
    const form = this.form;
    const requestQuestions = formsService.getOptionalQuestions(questions);
    const sectionsHide = sections.reduce((output, section, index) => {
      const sectionParent = getParentSection(section, index, sections);
      const isLevelLower = sectionHasALowerLevel(section, sectionParent);
      const isParentHide = isLevelLower
        ? output.indexOf(sectionParent._id) !== -1
        : false;
      const keepSection =
        isParentHide ||
        !formsService.displayQuestion(section, form, answers, ctx);

      return keepSection ? output.concat(section._id) : output;
    }, []);

    return requestQuestions.filter(takeCare);

    function takeCare(question) {
      return (
        sectionsHide.indexOf(question.section_id) === -1 &&
        formsService.displayQuestion(question, form, answers, ctx)
      );
    }
  }
  /**
   * @param {Section} section -
   * @param {number} index -
   * @param {Section[]} sections -
   * @return {Section|null} -
   */
  function getParentSection(section, index, sections) {
    return section.level > 0 ? sections[index - 1] : null;
  }
  /**
   * @param {Section} section -
   * @param {Section} sectionParent -
   * @return {boolean} -
   */
  function sectionHasALowerLevel(section, sectionParent) {
    return sectionParent ? sectionParent.level < section.level : false;
  }
  /**
   * Get questions which are empty and not optional
   *
   * @param {array} sections  - Form sections
   * @param {array} questions - Form questions
   * @param {array} answers   - Report answers
   * @param {object} ctx      - Datas context
   * @return {array} - Questions empty
   * @this ReportErrorsService
   */
  function requestedQuestions(sections, questions, answers, ctx = {}) {
    const requestQuestions = this.requestedByValues(
      sections,
      questions,
      answers,
      ctx
    );

    return requestQuestions.filter((question) => {
      const questionAnswers = answers.filter(
        (answer) => question._id === answer.question_id
      );

      return !this.answersAreValid({
        answers: questionAnswers,
        question,
        unconfirmedPrefilledQuestionsIds:
          this.unconfirmedPrefilledQuestions.ids,
        fullAnswers: answers,
        ctx,
      });
    });
  }

  /**
   * Get optional questions which are not empty
   *
   * @param {array} sections  - Form sections
   * @param {array} questions - Form questions
   * @param {array} answers   - Report answers
   * @param {object} ctx      - Datas context
   * @return {array} - Questions empty
   * @this ReportErrorsService
   */
  function optionalQuestions(sections, questions, answers, ctx = {}) {
    const optionalQuestions = this.optionalByValues(
      sections,
      questions,
      answers,
      ctx
    );

    return optionalQuestions.filter((question) => {
      const questionAnswers = answers.filter(
        (answer) => question._id === answer.question_id
      );

      return (
        questionAnswers.length > 0 &&
        !this.answersAreValid({
          answers: questionAnswers,
          question,
          unconfirmedPrefilledQuestionsIds:
            this.unconfirmedPrefilledQuestions.ids,
          fullAnswers: answers,
          ctx,
        })
      );
    });
  }
  /**
   * Get the number of requested question for a given section
   *
   * @param {ObjectId} sectionId - Id of the section
   * @return {array} - Requested questions
   * @this ReportErrorsService
   */
  function nbRequestedQuestions(sectionId) {
    const answers = this.report.contents.answers;
    const questions = sectionId
      ? this.questions.filter((data) => data.section_id === sectionId)
      : this.questions;
    const sections = sectionId
      ? this.sections.filter((data) => data._id === sectionId)
      : this.sections;

    return this.requestedQuestions(sections, questions, answers, {
      place: this.place,
      user: this.user,
      report: this.report,
    }).length;
  }
  /**
   * @param {object} data -
   * @return {boolean} -
   * @this ReportErrorsService
   */
  function answersAreValid({
    answers,
    question,
    unconfirmedPrefilledQuestionsIds,
    fullAnswers,
    ctx,
  }) {
    const QUANTITY_VALUE_INDEX = 1;
    const DISCOUNT_VALUE_INDEX = 2;
    const questionFieldsHash = (question.fields || []).reduce((hash, field) => {
      hash[field._id] = field;
      return hash;
    }, {});
    const maximum = formsService.computeMaximum(
      question,
      this.form,
      fullAnswers,
      ctx
    );
    const isValidAnswersLength = maximum ? answers.length <= maximum : true;

    return (
      !!answers.length &&
      isValidAnswersLength &&
      answers.every(
        (answer) =>
          answer.values.length &&
          answer.values.every((answerValue, index) => {
            if (isScoreField(questionFieldsHash, answerValue)) {
              return true;
            }

            return (
              isConfirmedQuestion(question, unconfirmedPrefilledQuestionsIds) &&
              isValidAnswerValue(question, index, answerValue)
            );
          })
      )
    );

    function isScoreField(fieldsHash, value) {
      const field = fieldsHash[value.field_id];

      return field && field.scoreMap;
    }
    function isConfirmedQuestion(question, unconfirmedPrefilledQuestionsIds) {
      return !unconfirmedPrefilledQuestionsIds.includes(question._id);
    }
    function isValidAnswerValue(question, index, answerValue) {
      if (reportQuestionsService.isRatingType(question) && question.naAllowed) {
        return true;
      }

      return reportQuestionsService.isProductQuestion(question)
        ? isValidProductAnswer(index, answerValue)
        : isDefined(answerValue.value);
    }
    function isValidProductAnswer(index, answerValue) {
      const isQuantityField = index === QUANTITY_VALUE_INDEX;
      const isDiscountField = index === DISCOUNT_VALUE_INDEX;

      if (!isDefined(answerValue.value)) {
        return false;
      }
      if (isQuantityField) {
        return SF_PRODUCT_QUANTITY_BOUNDARIES.MIN <= answerValue.value;
      }
      if (isDiscountField) {
        return (
          SF_PRODUCT_DISCOUNT_BOUNDARIES.MIN <= answerValue.value &&
          answerValue.value <= SF_PRODUCT_DISCOUNT_BOUNDARIES.MAX
        );
      }
      return true;
    }
  }

  /**
   * Get the number of requested question for a given section with subsections
   *
   * @param {Section} section - Form section
   * @return {number} - The number of question that need to be filled
   * @this ReportErrorsService
   */
  function nbRequestedQuestionsWithSubsections(section) {
    return (section.sections || [])
      .concat(section)
      .reduce(
        (errCount, sec) => errCount + this.nbRequestedQuestions(sec._id),
        0
      );
  }

  /**
   * Get the numbers of errors on the report
   *
   * @return {number} - Get errors count
   * @this ReportErrorsService
   */
  function getNbReportErrors() {
    return this.getQuestionsWithError().length;
  }

  /**
   * Get report errors
   *
   * @return {array} - Errors
   * @this ReportErrorsService
   */
  function getQuestionsWithError() {
    const _this = this;
    const answers = _this.report.contents.answers;
    const mandatoryErrors = _this.requestedQuestions(
      _this.sections,
      _this.questions,
      answers,
      {
        place: this.place,
        report: this.report,
        user: this.user,
      }
    );
    const optionalErrors = _this.optionalQuestions(
      _this.sections,
      _this.questions,
      answers,
      {
        place: this.place,
        report: this.report,
        user: this.user,
      }
    );

    return [].concat(mandatoryErrors).concat(optionalErrors);
  }

  // --------------------
  //      Helpers
  // --------------------
  /**
   * @param {any} value -
   * @return {boolean}
   */
  function isDefined(value) {
    return typeof value !== 'undefined' && value !== null && value !== '';
  }

  return ReportErrors;
}
