const REQUIRED_FIELDS = [
  'contents.state',
  'contents.question_id',
  'form._id',
  'form.contents.title',
  'form.contents.analysts_ids',
  'form.contents.owner_id',
  'form.contents.questions',
  'form.contents.sections',
  'form.contents.preferences',
  'form.i18n',
  'campaign._id',
  'campaign.contents.name',
  'campaign.resolved.analysts_ids',
  'campaign.resolved.owner_ids',
  'campaign.contents.form.questions',
  'campaign.contents.form.sections',
  'campaign.contents.preferences',
  'campaign.i18n',
  'report._id',
  'report.saved.seal_date',
  'reportRespondent._id',
  'reportRespondent.contents.firstName',
  'reportRespondent.contents.lastName',
  'reportRespondent.contents.avatar_id',
  'report.place.name',
  'report.contents.user_id',
  'report.contents.answers',
];
const LIST_REQUIRED_FIELDS = ['comments._id'];
const SORT_FIELD = '-report.modified.seal_date';

// eslint-disable-next-line max-params
export function MerchandisingService(
  $http,
  $q,
  pubSubService,
  sfPOVService,
  apiUtilsService,
  profileService,
  commentsFactory,
  SF_MERCHANDISING_STATES,
  segmentService
) {
  'ngInject';
  const { buildFilterParams } = apiUtilsService;
  const commentsService = commentsFactory('validations');

  let validationsNeedReactionCount = null;

  const methods = {
    commentsService,
    getComments,
    getValidationById,
    getValidations,
    getValidationsRequestOptions,
    getStatesCounts,
    putAnswerInPending,
    rejectAnswer,
    sendComment,
    sendPictureComment,
    validateAnswer,
    getValidationsNeedReactionCount,
    getValidationsNeedReactionCountRemote: loadValidationsNeedReactionCount,
  };

  function getValidations(params = {}) {
    const merchURL = '/validations/';

    return sfPOVService
      .pBuildURL(merchURL, { pov: 'organisation' })
      .then((povUrl) => $http.get(povUrl, { params }))
      .then((res) => {
        loadValidationsNeedReactionCount();

        return res.data;
      })
      .then((res) => {
        return {
          ...res,
          entries: res.entries.map(formatData),
        };
      });
  }

  function getValidationById(validationId) {
    const merchURL = `/validations/${validationId}`;
    const params = {
      fields: REQUIRED_FIELDS,
    };

    return sfPOVService
      .pBuildURL(merchURL, { pov: 'organisation' })
      .then((povUrl) => $http.get(povUrl, { params }))
      .then((res) => res.data)
      .then((res) => formatData(res));
  }

  function validateAnswer(validation, profile) {
    return sendComment(validation, profile, SF_MERCHANDISING_STATES.VALIDATED)
      .then((res) => {
        return segmentService
          .identifiedTrack(
            'PHOTO_ANSWER_APPROVED',
            validation.useNewCampaign
              ? {
                  campaign_id: validation.form._id,
                  campaign_name: validation.form.contents.title,
                }
              : {
                  form_id: validation.form._id,
                  form_name: validation.form.contents.title,
                }
          )
          .then(() => res);
      })
      .then((res) => {
        loadValidationsNeedReactionCount();
        return res;
      });
  }

  function rejectAnswer(validation, profile, commentContent) {
    return sendComment(
      validation,
      profile,
      SF_MERCHANDISING_STATES.REJECTED,
      commentContent
    ).then((res) => {
      loadValidationsNeedReactionCount();
      return res;
    });
  }

  function putAnswerInPending(validation, profile, changedFilesIds) {
    return sendComment(
      validation,
      profile,
      SF_MERCHANDISING_STATES.PENDING,
      '',
      null,
      changedFilesIds
    ).then((res) => {
      loadValidationsNeedReactionCount();
      return res;
    });
  }

  function getComments(validationId) {
    return methods.commentsService.list(validationId, {
      sorts: 'created.seal_date',
    });
  }

  // eslint-disable-next-line max-params
  function sendComment(
    validation,
    profile,
    state,
    content = '',
    pictureId,
    changedFilesIds
  ) {
    const comment = methods.commentsService.buildComment(
      profile,
      content,
      {
        report_id: validation.report._id,
        ...(validation.useNewCampaign
          ? { campaign_id: validation.form._id }
          : { form_id: validation.form._id }),
        validation: {
          _id: validation._id,
          ...(state && { stateSetter: state }),
          ...(changedFilesIds && { changedFiles_ids: changedFilesIds }),
        },
      },
      pictureId
    );

    return methods.commentsService.create(validation._id, comment);
  }

  function sendPictureComment(validation, profile, pictureId) {
    return sendComment(validation, profile, null, '', pictureId);
  }

  function getStatesCounts() {
    const countURL = '/validationsCounts';

    return sfPOVService
      .pBuildURL(countURL, { pov: 'organisation' })
      .then((povUrl) => $http.get(povUrl))
      .then((res) => res.data);
  }

  function getValidationsRequestOptions(state) {
    return {
      fields: [].concat(REQUIRED_FIELDS, LIST_REQUIRED_FIELDS),
      sorts: SORT_FIELD,
      ...buildFilterParams([{ name: 'validation_state', value: state }]),
    };
  }

  function getValidationsNeedReactionCount() {
    return validationsNeedReactionCount !== null
      ? $q.when(validationsNeedReactionCount)
      : loadValidationsNeedReactionCount().then(
          () => validationsNeedReactionCount
        );
  }

  function loadValidationsNeedReactionCount() {
    return $q
      .all([
        profileService.getProfile(),
        sfPOVService.pBuildURL('/validationsCounts/', { pov: 'organisation' }),
      ])
      .then(([profile, povUrl]) =>
        $http.get(povUrl, {
          params: {
            filters: {
              $or: [
                {
                  // waiting an approve from me
                  $and: [
                    {
                      $or: [
                        { form_analyst_id: { $eq: profile._id } },
                        { campaign_analyst_id: { $eq: profile._id } },
                      ],
                    },
                    { validation_state: { $eq: 'pending' } },
                  ],
                },
                {
                  // my rejected photos
                  $and: [
                    { report_creator_id: { $eq: profile._id } },
                    { validation_state: { $eq: 'rejected' } },
                  ],
                },
              ],
            },
          },
        })
      )
      .then(({ data }) => {
        const count = data
          .filter(({ state }) => ['pending', 'rejected'].includes(state))
          .reduce((acc, { total }) => acc + total, 0);

        if (count !== validationsNeedReactionCount) {
          validationsNeedReactionCount = count;
          pubSubService.publish('MERCH_TO_REVIEW_COUNT_UPDATED', { count });
        }
        return count;
      });
  }

  function formatData(validationEntity) {
    // if a validation concerns a new campaign, we change the format
    // of the entity to make it match with the components
    if (validationEntity.campaign) {
      validationEntity.form = {
        _id: validationEntity.campaign._id,
        contents: {
          title: validationEntity.campaign.contents.name,
          analysts_ids: validationEntity.campaign.resolved.analysts_ids,
          owner_ids: validationEntity.campaign.resolved.owner_ids,
          questions: validationEntity.campaign.contents.form.questions,
          sections: validationEntity.campaign.contents.form.sections,
          preferences: validationEntity.campaign.contents.preferences,
        },
        i18n: validationEntity.campaign.i18n,
      };
      validationEntity.useNewCampaign = true;
      delete validationEntity.campaign;
    }
    return validationEntity;
  }

  return methods;
}
