import { TaskAuthorController } from './task-author.controller';

export const TaskAuthorComponent: ng.IComponentOptions = {
  bindings: {
    task: '<',
    author: '<',
  },
  transclude: true,
  templateUrl: 'tasks/components/task-author/task-author.html',
  controller: TaskAuthorController,
};
