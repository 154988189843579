import { PlaceActivityController } from './place-activity.controller';

export const PlaceActivityComponent: ng.IComponentOptions = {
  bindings: {
    place: '<',
    profile: '<',
    organisation: '<',
  },
  templateUrl: 'places/place/components/place-activity/place-activity.html',
  controller: PlaceActivityController,
};
