import { AccessRightsService } from '@services/Utils/AccessRights/access-rights.service';
import { Targeting } from '../../targeting/types';
import { GroupTask } from '../types';

export class GroupTaskOptionsController {
  onTargetingUpdated: (value: Targeting | null) => void;
  groupTask: Partial<GroupTask>;
  groupTaskFormOptions: ng.IFormController;
  isPostBtnDisplayed = true;
  hiddenTargeting: string[] = [];
  previousTargeting: Targeting | null = null;
  isScoped = false;

  constructor(
    private TARGETING_TYPES,
    private accessRightsService: AccessRightsService
  ) {
    'ngInject';
  }

  $onInit(): void {
    this.hiddenTargeting = this.TARGETING_TYPES.map(({ key }) => key).filter(
      (key: string) => !['places-lists', 'users-groups'].includes(key)
    );
    this.isScoped = !this.accessRightsService.isAdmin();
  }

  updateTargeting(targeting): void {
    this.previousTargeting = targeting;
    this.onTargetingUpdated(
      targeting
        ? {
            links: targeting.targeting_ids.map((_id) => ({
              type: targeting.type,
              _id,
            })),
          }
        : null
    );
  }
}
