import { AuthService } from '@services/API/auth/auth.service';
import { ReportsService } from '@services/API/reports/reports.service';
import { AwakeService } from '@services/Utils/Awake/awake.service';
import { DateFormatService } from '@services/Utils/Dates/date-format.service';
import { ErrorMessagesService } from '@services/Utils/ErrorMessages/error-messages.service';
import { ModalService } from '@services/Utils/Modal';
import { PlatformService } from '@services/Utils/Platform';
import { Popup, PopupService } from '@services/Utils/Popup/popup.service';
import { PubSubService } from '@services/Utils/PubSub/pubsub.service';
import { RouterService } from '@services/Utils/Router/router.service';
import type { SfFeatureFlags } from '@simplifield/feature-flags';
import { StateService, TransitionPromise } from '@uirouter/angularjs';
import { Organisation } from 'app';
import { CommentsResourceType } from '../../../../comments/services/comments-api/comments-api.factory';
import {
  CommentsFactory,
  CommentsService,
} from '../../../../comments/services/comments/comments.factory';
import { FEATURE_FLAGS } from '../../../../constants/feature-flags.constant';
import { FormTasksService } from '../../services/form-tasks.service';
import { isNil } from 'ramda';
import { ReportVersionsService } from '@services/API/report-versions/report-versions.service';
import { DraftReportsService } from '@services/API/draft-reports/draft-reports.service';

export const FormEndingComponent = {
  bindings: {
    report: '<',
    campaign: '<',
    form: '<',
    place: '<',
    user: '<',
    profile: '<',
    onSaved: '&',
    referer: '<',
    isPreview: '<',
    onClose: '&',
    template: '<?',
    isAlreadySent: '<',
  },
  templateUrl: 'missions/form/components/form-ending/form-ending.html',
  controller: class FormEndingController implements ng.IComponentController {
    // bindings
    form;
    campaign;
    report;
    profile;
    template: unknown;
    place;
    user;
    referer;
    isAlreadySent: boolean;

    onClose: (data?: Record<string, unknown>) => void;
    onSaved: () => void;

    // attributes
    useNewCampaigns: boolean;
    isBrowser: boolean;
    hasTasksFeature: boolean;
    scoreIsActivate: boolean;
    eanIsActivate: boolean;
    hasProductScan: boolean;
    hasProductSales: boolean;
    isStatusEditable: boolean;
    getDateAndTimeFormatted: () => string;
    organisation: Organisation;
    isSending: boolean;
    isSaving: boolean;
    reportProgress: Record<string, unknown>;
    commentsService: CommentsService;
    isObjectiveCompleted = false;
    isFilesPreloaded = true;
    isDraftReportsFlagEnabled = false;
    canSaveLater = false;

    // eslint-disable-next-line max-params
    constructor(
      private $q: ng.IQService,
      private $rootScope: ng.IRootScopeService,
      private pubSubService: PubSubService,
      private $state: StateService,
      private $translate: ng.translate.ITranslateService,
      private authService: AuthService,
      private routerService: RouterService,
      private reportScoringService,
      private ReportSending,
      private formService,
      private errorMessagesService: ErrorMessagesService,
      private popupRequestService,
      private tasksService,
      private awakeFactory: AwakeService,
      private formsService,
      private dateFormatService: DateFormatService,
      private modalService: ModalService,
      private reportQuestionsService,
      private reportValidationService,
      private platformService: PlatformService,
      private organisationsService,
      private formTasksService: FormTasksService,
      private popupService: PopupService,
      private sfFeatureFlagsService: SfFeatureFlags,
      private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
      private commentsFactory: CommentsFactory,
      private reportsService: ReportsService,
      private SF_ERROR_CODES,
      private reportVersionsService: ReportVersionsService,
      private logService,
      private draftReportsService: DraftReportsService
    ) {
      'ngInject';

      this.commentsService = this.commentsFactory(CommentsResourceType.ANSWERS);
    }

    getPopupConfig(isSendingDraft = false): Record<string, unknown> {
      const FORM_ENDING_POPUP_SAVE_CONFIG = {
        progress: {
          title: this.$translate.instant('FORM_POPUP_SEND_SENDING_TITLE'),
          desc: this.$translate.instant(
            'FORM_POPUP_SEND_SENDING_DESC_CHECKLISTS'
          ),
        },
        success: {
          title: this.$translate.instant(
            'FORM_POPUP_SEND_SUCCEED_TITLE_CHECKLISTS'
          ),
          desc: this.$translate.instant(
            'FORM_POPUP_SEND_SUCCEED_DESC_CHECKLISTS'
          ),
          iconName: 'sync_reports_success',
        },
        error: {
          title: this.$translate.instant('FORM_POPUP_SEND_FORM_ERROR_TITLE'),
          desc: this.$translate.instant(
            'FORM_POPUP_SEND_FORM_ERROR_DESC_CHECKLISTS'
          ),
          btnText: this.$translate.instant('FORM_POPUP_SEND_FORM_ERROR_BUTTON'),
        },
        internetConnectionIssue: {
          title: this.$translate.instant('FORM_POPUP_SEND_FORM_ERROR_TITLE'),
          desc: this.$translate.instant(
            'FORM_POPUP_SEND_FORM_ERROR_DESC_CHECKLISTS'
          ),
          btnText: this.$translate.instant('FORM_POPUP_SEND_FORM_ERROR_BUTTON'),
          btnFirstOption: this.$translate.instant(
            'FORM_POPUP_SEND_FORM_ERROR_SEND_LATER_BUTTON'
          ),
          iconName: 'send-later',
        },
      };
      const FORM_ENDING_POPUP_DRAFT_SAVE_CONFIG = {
        ...FORM_ENDING_POPUP_SAVE_CONFIG,
        success: {
          title: this.$translate.instant(
            'FORM_POPUP_SEND_DRAFT_SUCCEED_TITLE_CHECKLISTS'
          ),
          desc: this.$translate.instant(
            'FORM_POPUP_SEND_DRAFT_SUCCEED_DESC_CHECKLISTS'
          ),
          iconName: 'sync_reports_success',
        },
        internetConnectionIssue: {
          title: this.$translate.instant('FORM_POPUP_SEND_FORM_ERROR_TITLE'),
          desc: this.$translate.instant(
            'FORM_POPUP_SEND_FORM_ERROR_DESC_CHECKLISTS'
          ),
          btnText: this.$translate.instant('FORM_POPUP_SEND_FORM_ERROR_BUTTON'),
          iconName: 'send-later',
        },
      };

      if (isSendingDraft) {
        return FORM_ENDING_POPUP_DRAFT_SAVE_CONFIG;
      }

      const FORM_ENDING_POPUP_SAVE_VALIDATION_CONFIG = {
        success_revise: {
          title: this.$translate.instant(
            'FORM_POPUP_SEND_REVISION_SUCCEED_TITLE_CHECKLISTS'
          ),
          desc: this.$translate.instant(
            'FORM_POPUP_SEND_REVISION_SUCCEED_DESC_CHECKLISTS'
          ),
          iconName: 'sync_reports_success',
          actions: [{ text: 'OK', type: 'button-dark' }],
        },
        success_creation: {
          title: this.$translate.instant(
            'FORM_POPUP_SEND_VALIDATION_SUCCEED_TITLE'
          ),
          desc: this.$translate.instant(
            'FORM_POPUP_SEND_VALIDATION_SUCCEED_DESC_CHECKLISTS'
          ),
          iconName: 'sync_reports_success',
          actions: [{ text: 'OK', type: 'button-dark' }],
        },
      };

      if (this.reportValidationService.isRevision(this.report)) {
        return {
          ...FORM_ENDING_POPUP_SAVE_CONFIG,
          success: FORM_ENDING_POPUP_SAVE_VALIDATION_CONFIG.success_revise,
        };
      }
      // this.form can be a partial of entire form, when the report is under revision, it does
      // not contain validation properties.
      if (this.reportValidationService.isFormSubjectToValidation(this.form)) {
        return {
          ...FORM_ENDING_POPUP_SAVE_CONFIG,
          success: FORM_ENDING_POPUP_SAVE_VALIDATION_CONFIG.success_creation,
        };
      }

      return FORM_ENDING_POPUP_SAVE_CONFIG;
    }

    $onInit(): void {
      const questions = this.form
        ? this.form.contents.questions
        : this.campaign.contents.form.questions;
      this.isBrowser = this.platformService.isBrowser();
      this.hasTasksFeature = this.tasksService.hasFeatureFlag();
      this.scoreIsActivate = this.reportScoringService.scoreIsActivate(
        this.form,
        this.report
      );
      this.eanIsActivate = questions.some(
        (q) =>
          !this.reportQuestionsService.isProductQuestion(q) &&
          this.reportQuestionsService.isProductScan(q)
      );
      this.hasProductScan = questions.some((q) =>
        this.reportQuestionsService.isProductQuestion(q)
      );
      this.hasProductSales = questions.some((q) =>
        this.reportQuestionsService.isProductSales(q)
      );
      this.isStatusEditable = this.reportValidationService.isStatusEditable(
        this.form
      );
      this.getDateAndTimeFormatted =
        this.dateFormatService.getDateAndTimeFormatted;

      if (this.showScore()) {
        this._resolveReportScoringProgress();
      }

      if (this.profile) {
        this.organisationsService
          .getProfileOrganisation(this.profile)
          .then((organisation) => {
            this.organisation = organisation;
            this.useNewCampaigns = organisation.useNewCampaigns;
          });
      }
      if (this.template) {
        this.useNewCampaigns = true;
      }

      this.isObjectiveCompleted =
        !this.reportsService.shouldReportBeAllowedWithObjective(
          this.report,
          this.campaign,
          this.profile._id
        );

      this.pubSubService.subscribe(
        'FILE_PRELOADING_FINISHED',
        (value: { status: boolean }) => {
          this.isFilesPreloaded = value.status;
        }
      );

      this.isDraftReportsFlagEnabled = this.sfFeatureFlagsService.hasFeature(
        this.SF_FEATURE_FLAGS.DRAFT_REPORTS
      );

      this.canSaveLater =
        (!this.isBrowser || this.isDraftReportsFlagEnabled) &&
        !this.isAlreadySent;
    }

    shouldSynchronize(hideAlertMessage = false): Popup {
      const template = `<sf-popup-synchronize
        on-close="$ctrl.onClose()"
        on-success="$ctrl.onSuccess()"
        hide-alert-massage="$ctrl.hideAlertMessage"
      ></sf-popup-synchronize>`;

      const bindings = {
        onSuccess: () => {
          this.onClose({ value: { shouldSaveAsDraft: true } });
        },
        hideAlertMessage,
      };

      return this.popupService.showCustomTemplate({ template, bindings });
    }

    openMovedToDraftPopup(errorMessage: string): Popup {
      const template = `<sf-popup-moved-to-draft
        on-close="$ctrl.onClose()"
        on-success="$ctrl.onSuccess()"
        error-message="$ctrl.errorMessage"
      ></sf-popup-moved-to-draft>`;

      const bindings = {
        onSuccess: () => {
          this.reportsService.deleteLocally(this.report._id);
          this.onClose({ value: { shouldSaveAsDraft: true } });
        },
        errorMessage,
      };

      return this.popupService.showCustomTemplate({ template, bindings });
    }

    openObjectiveAlreadyCompletePopup(errorMessage: string): Popup {
      const template = `<sf-popup-objective-already-complete
        on-close="$ctrl.onClose()"
        on-success="$ctrl.onSuccess()"
        error-message="$ctrl.errorMessage"
      ></sf-objective-already-complete>`;

      const bindings = {
        onSuccess: () => {
          this.reportsService.deleteLocally(this.report._id);
          this.draftReportsService.deleteOne(this.report._id, true);
          this.onClose({ value: { shouldGoBackToChecklistPage: true } });
        },
        errorMessage,
      };

      return this.popupService.showCustomTemplate({ template, bindings });
    }

    trySendWrapper(callback: () => ng.IPromise<void>): ng.IPromise<void> {
      const logoutPeriodPreferenceValue =
        this.authService.getLogoutPreferenceValue(this.organisation);

      if (
        logoutPeriodPreferenceValue &&
        this.authService.isSessionExpired(logoutPeriodPreferenceValue)
      ) {
        return this.authService.showExpirationPopup(
          logoutPeriodPreferenceValue,
          () => callback()
        );
      }

      return callback();
    }

    trySendLater(): ng.IPromise<void> {
      this.logService.reportLog(
        '[IT-3253] form-ending.component.ts | trySendLater',
        this.report
      );
      this.reportVersionsService.create(this.report, 'trySendLater');
      return this.trySendWrapper(() => this.sendLater());
    }

    trySend(): ng.IPromise<void> {
      this.logService.reportLog(
        '[IT-3253] form-ending.component.ts | trySend',
        this.report
      );
      this.reportVersionsService.create(this.report, 'trySend');
      return this.trySendWrapper(() => this.send());
    }

    send(): ng.IPromise<void> {
      const wasToBeSent = this.report.localStatus === 'toBeSent';
      const reportWithDate = wasToBeSent
        ? this.report
        : Object.assign(this.report, {
            saved_date: new Date(),
            localStatus: 'ready',
          });

      if (this.campaign?.contents?.version) {
        this.report.contents.campaign_version = this.campaign.contents.version;
      }

      // Remove fields only used for drafts reports
      delete reportWithDate.contents?.saved_date;
      delete reportWithDate.contents?.hash;

      const ctx = {
        report: reportWithDate,
        place: this.place,
        user: this.user,
        useNewCampaigns: this.useNewCampaigns,
      };
      /* this.logService.reportLog(
        '[IT-3253] form-ending.component.ts | send: building context with reportWithDate',
        this.report
      ); */
      const sendPromise = this.$q.defer();
      const reportSending = new this.ReportSending(this.form, ctx);
      const nbSendingSteps = reportSending.answersFiles?.length + 1;

      this.isSending = true;

      const popupRequest = this.popupRequestService.show(
        this.getPopupConfig(),
        () => sendPromise.resolve(),
        nbSendingSteps
      );

      this.awakeFactory.keepAwake();

      return this.tasksService
        .getOfflineTasks({ report_id: ctx.report._id })
        .then((tasks) => {
          ctx.report.contents.tasks_ids = tasks.map((task) => task._id);

          const reportSendPromise = reportSending.send(
            { canceler: sendPromise },
            () => popupRequest.onTick()
          );
          const reportTasksSendPromise = this.tasksService.saveReportTasks(
            this.report._id,
            {
              canceler: sendPromise,
            }
          );
          const sendCommentsPromise = this.commentsService.sendAllAndClear();
          return this.$q.all([
            reportSendPromise,
            reportTasksSendPromise,
            sendCommentsPromise,
          ]);
        })
        .then(() => {
          this.handleSuccessSend(popupRequest);
        })
        .catch((err) => {
          const hasFeatureFlag = this.sfFeatureFlagsService.hasFeature(
            this.SF_FEATURE_FLAGS.SYNCHRONIZE_REPORTS
          );

          if (
            err?.code === 'ERROR.DRAFTS_REPORTS.SYNC_NEEDED' &&
            hasFeatureFlag
          ) {
            popupRequest.clear();
            return this.shouldSynchronize();
          }

          const messageError = this.errorMessagesService.getMessage(err, {
            customUnknownErrorMessage: 'FORM_UNKNOWN_ERROR',
          });

          if (
            this.errorMessagesService.isValidationError(err) ||
            this.errorMessagesService.isNewCampaignValidationError(err)
          ) {
            popupRequest.onReportValidationFails(
              {
                action: () => this.onClose(),
                label: this.$translate.instant('POPUP_EDIT_REPORT_ANSWERS'),
              },
              {
                action: () => this.save(),
                label: this.$translate.instant('POPUP_CANCEL'),
              },
              messageError
            );

            throw err;
          }

          if (
            err?.response?.data?.code == 'E_CAMPAIGN_IS_DRAFT' ||
            err?.response?.data?.code === 'E_CAMPAIGN_VERSION_MISMATCH'
          ) {
            popupRequest.clear();
            this.openMovedToDraftPopup(err?.response?.data?.code);

            throw err;
          }

          if (
            err?.response?.data?.code ==
            'E_FORBIDDEN_CHECKLIST_ALREADY_ANSWERED'
          ) {
            popupRequest.clear();
            this.openObjectiveAlreadyCompletePopup(err?.response?.data?.code);

            throw err;
          }

          if (err?.status === this.SF_ERROR_CODES.CODES_SERVER_NO_NETWORK[0]) {
            popupRequest.onError(
              () => this.send(),
              () => this.sendLater('toBeSent'),
              messageError,
              false
            );

            throw err;
          }

          popupRequest.onError(
            () => this.send(),
            () => !wasToBeSent && this.save(),
            messageError
          );

          throw err;
        })
        .finally(() => {
          this.isSending = false;
          this.awakeFactory.allowSleepAgain();
        });
    }

    handleSuccessSend(popupRequest, isDraft = false) {
      const hardwareBackAction = this.platformService.onHardwareBackButton(
        () => {
          hardwareBackAction();

          this.formTasksService.clean();
          return this.routerService.goBack();
        }
      );

      this.updateOtherViews();

      if (!isDraft) {
        this.tasksService.deleteReportTasks(this.report._id);
      }

      return popupRequest.onSuccess().then(() => {
        this.redirectBack();
        this.onSaved();
      });
    }

    selectStatus() {
      const template = `
        <sf-status-selector
          current-status="$ctrl.currentStatus"
          status="$ctrl.status"
          on-close="$ctrl.onClose()"
          on-save="$ctrl.onSave()"
          translations="$ctrl.translations">
        </sf-status-selector>
      `;
      const bindings = {
        status: this.formsService.getAvailableStatus(this.form),
        currentStatus: this.report.contents.state,
        translations: this.form.i18n,
      };
      const options = {
        animation: 'slide-in-top',
      };

      return this.modalService
        .openAsPromise<{ key: string }>(template, bindings, options)
        .then((status) => {
          this.report.contents.state = status.key;
          return this.report;
        });
    }

    save() {
      const { report, place, user, campaign } = this;

      if (this.isDraftReportsFlagEnabled && report.localStatus !== 'toBeSent') {
        return this.formService.saveDraft(
          { report, place, user, checklist: campaign }, // ctx
          this.form, //               form
          true, //                    hasToCleanReport
          true //                     hasToRemoveUnconfirmedPrefilled
        );
      }

      return this.formService.saveBackup(
        { report, place, user }, // ctx
        this.form, //               form
        true, //                    hasToCleanReport
        true, //                     hasToRemoveUnconfirmedPrefilled
        false
      );
    }

    sendLater(localStatus = 'draft'): ng.IPromise<void> {
      this.logService.reportLog(
        '[IT-3253] form-ending.component.ts | sendLater',
        this.report
      );
      this.isSaving = true;

      this.report.localStatus = localStatus;
      if (this.campaign?.contents?.version) {
        this.report.contents.campaign_version = this.campaign.contents.version;
      }

      const sendPromise = this.$q.defer();
      const popupRequest = this.popupRequestService.show(
        this.getPopupConfig(true),
        () => sendPromise.resolve()
      );

      this.awakeFactory.keepAwake();

      return this.save()
        .then(() => this.commentsService.sendAllAndClear())
        .then(() => this.handleSuccessSend(popupRequest, true))
        .catch((err) => {
          const messageError = this.errorMessagesService.getMessage(err, {
            customUnknownErrorMessage: 'FORM_UNKNOWN_ERROR',
          });

          if (
            localStatus === 'draft' &&
            this.platformService.isBrowser() &&
            err?.status === this.SF_ERROR_CODES.CODES_SERVER_NO_NETWORK[0]
          ) {
            popupRequest.onError(
              () => this.sendLater(localStatus),
              () => this.$q.resolve(),
              messageError,
              false
            );

            throw err;
          }
        })
        .finally(() => {
          this.isSaving = false;
          this.awakeFactory.allowSleepAgain();
        });
    }

    redirectBack(): TransitionPromise {
      const { referer } = this;

      const defaultState = this.useNewCampaigns
        ? 'index.menu-more.reactive-campaigns.list'
        : 'index.menu-more.missions.lists.missions';

      this.routerService.disableNextBack();

      this.$rootScope.$broadcast('REPORT_FILLED_REDIRECT_BACK');

      return this.$state.go(referer || defaultState, {
        ...(this.place ? { placeId: this.place._id } : {}),
      });
    }

    updateOtherViews(): boolean {
      this.pubSubService.publish('FORM_REPORT_UPDATED');

      return true;
    }

    hasAStatus(): boolean {
      return !!this.report.localStatus;
    }

    showScore(): boolean {
      return !this.eanIsActivate && this.scoreIsActivate;
    }

    // Has this report already been saved (remotely or just locally)
    isSaved(): boolean {
      return !isNil(this.report.created_date);
    }

    openReportScoringModal(): void {
      this.formService.openReportScoringModal({
        form: this.form,
        report: this.report,
        place: this.place,
        eanIsActivate: this.eanIsActivate,
        scoreIsActivate: this.scoreIsActivate,
        campaign: this.campaign,
      });
    }

    _resolveReportScoringProgress(): ng.IPromise<void> {
      this.reportProgress = {};
      this.reportProgress = this.formsService.getReportScoringProgress(
        this.form,
        this.report
      );
      return this.formsService
        .getFormStatsLabel(this.form)
        .then((formStatsLabel) => {
          this.reportProgress.formStatsLabel = formStatsLabel;
        });
    }
  },
};
