const APP_MESSAGE_COLORS = {
  FAIL: 'sf_message--fail',
  SUCCESS: 'sf_message--success',
  PENDING: 'sf_message--pending',
  INFO: 'sf_message--info',
};

export const MessageComponent = {
  bindings: {
    isDisplay: '=',
    type: '<',
    onClose: '&?',
    onButtonClick: '&?',
    buttonLabel: '@',
  },
  transclude: true,
  templateUrl: 'components/Notifications/message/message.html',
  controller: function MessageController() {
    'ngInject';
    this.$onInit = () => {
      this.messageColor = this.getColorClass();
    };
    this.$onChanges = (changes) => {
      if (changes.type) {
        this.messageColor = this.getColorClass();
      }
    };
    this.getColorClass = () => {
      const messageType = this.type ? this.type.toUpperCase() : null;

      return APP_MESSAGE_COLORS[messageType];
    };
    this.close = () => {
      this.isDisplay = false;
      this.onClose();
    };
    this.onActionClick = () => {
      this.isDisplay = false;
      this.onButtonClick();
    };
  },
};
