export default function chatRouteConfig($stateProvider) {
  'ngInject';

  $stateProvider
    .state('index.chat', {
      url: '/chat',
      abstract: true,
      views: {
        'tab-chat': {
          template: '<ion-nav-view></ion-nav-view>',
        },
      },
    })
    .state('index.chat.list', {
      url: '',
      template: `
        <ion-view class="sf_chat sf_view__has_nav_bar">
          <sf-view sf-tab-bar-hide="false">
            <sf-chat
              profile="$ctrl.profile">
            </sf-chat>
          </sf-view>
        </ion-view>
      `,
      controller: ChatController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpRedirect: redirectIfFlagIsDisabled,
      },
      analyticsName: 'Chat',
    })
    .state('index.chat.error', {
      url: '/error/:errorCode',
      template: '',
      controllerAs: '$ctrl',
      resolve: {
        rsvpRedirect: redirectIfFlagIsDisabled,
        rsvpUnauthorized: redirectIfUnauthorizedChannel,
      },
      analyticsName: 'Chat error',
    })
    .state('index.chat.channel', {
      url: '/:channelUrl',
      template: `
        <ion-view class="sf_chat_room"
          cache-view="false">
          <sf-chat-room
            channel="$ctrl.channel"
            profile="$ctrl.profile">
          </sf-chat-room>
        </ion-view>
      `,
      controller: ChannelViewController,
      controllerAs: '$ctrl',
      resolve: {
        rsvpRedirect: redirectIfFlagIsDisabled,
        rsvpChannel: resolveChannel,
      },
      analyticsName: 'Chat channel',
    });

  // @ngInject
  function ChatController(rsvpProfile) {
    this.profile = rsvpProfile;
  }

  // @ngInject
  function ChannelViewController(rsvpProfile, rsvpChannel) {
    this.profile = rsvpProfile;
    this.channel = rsvpChannel;
  }

  // @ngInject
  // eslint-disable-next-line max-params
  function redirectIfUnauthorizedChannel(
    rsvpRedirect,
    $stateParams,
    $state,
    routerService,
    chatService,
    chatPopupService
  ) {
    const errorCode = parseInt($stateParams.errorCode, 10);

    switch (errorCode) {
      case chatService.SENDBIRD_ERRROR_CHANNEL_DOES_NOT_EXIST:
        chatPopupService.showChannelDeletedError(() => {
          routerService.disableNextBack();
          $state.go('index.chat.list');
        });
        break;
      case chatService.SENDBIRD_ERRROR_USER_IS_NOT_AUTHORIZED:
        chatPopupService.showUserLeftError(() => {
          routerService.disableNextBack();
          $state.go('index.chat.list');
        });
        break;
      default:
        routerService.disableNextBack();
        $state.go('index.chat.list');
    }
  }

  // The initApp promise is usefull to be sure that the
  // chat service is init before enter in the view
  // @ngInject
  // eslint-disable-next-line max-params
  function resolveChannel(
    $stateParams,
    initApp,
    rsvpRedirect,
    chatService,
    $state,
    routerService
  ) {
    return chatService.getChannel($stateParams.channelUrl).catch((error) => {
      routerService.disableNextBack();
      $state.go('index.chat.error', { errorCode: error.code });
    });
  }

  // @ngInject
  function redirectIfFlagIsDisabled(chatService, initApp, $state, $timeout) {
    if (!chatService.hasFeatureFlag()) {
      return $timeout(() => $state.go('index.activity.details'), 0);
    }
    return true;
  }
}
