import { Campaign } from '@services/API/campaigns/campaigns';
import { CampaignsService } from '@services/API/campaigns/campaigns.service';
import { StateService } from '@uirouter/angularjs';
import { IMAGE_SIZES } from '../../../../../constants/image-sizes.constant';
import { NewsfeedService } from '../../../../../newsfeed/services/newsfeed-service/newsfeed.service';
import { ResourcePreview } from '../../../../../newsfeed/types';
import { CampaignsUtilsService } from '../../../../../reactive-campaigns/services/campaigns-utils.service';
import { DateFormatService } from '../../../../../services/Utils/Dates/date-format.service';
import {
  CalendarEvent,
  ChecklistLink,
  CorporateEvent,
  CorporateEventAttachedResource,
} from '../../../../types';
import { CalendarEventsService } from '@services/API/calendar-events/calendar-events.service';
import { FEATURE_FLAGS } from '@constants/feature-flags.constant';
import { SfFeatureFlags } from '@simplifield/feature-flags';

export class CorporateEventDetailsModalController
  implements ng.IComponentController
{
  onClose: () => void;
  event: CorporateEvent;
  eventDateRangeMessage: string;
  eventPhotos: ResourcePreview[] = [];
  eventFiles: ResourcePreview[] = [];
  eventVideos: ResourcePreview[] = [];
  checklists: ChecklistLink[] = [];
  campaigns: Campaign[] = [];
  relatedEvents: CalendarEvent[] = [];
  showRelatedEvents = false;

  constructor(
    private readonly dateFormatService: DateFormatService,
    private readonly newsfeedService: NewsfeedService,
    private readonly SF_IMAGE_SIZES: typeof IMAGE_SIZES,
    private readonly campaignsService: CampaignsService,
    private readonly campaignsUtilsService: CampaignsUtilsService,
    private $state: StateService,
    private calendarEventsService: CalendarEventsService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS
  ) {
    'ngInject';
  }

  $onInit(): void {
    const { start_date, end_date, timezone, attachedResources, checklists } =
      this.event.contents;

    this.eventDateRangeMessage = this.dateFormatService.getDateFrameFormatted(
      start_date,
      end_date,
      timezone ? timezone.name : ''
    );

    if (attachedResources?.length > 0) {
      this.getAttachedResources(attachedResources);
    }

    this.checklists = checklists;

    const campaignsIds = this.checklists.map(({ _id }) => _id);

    this.campaignsService
      .getCampaignsListByIds(campaignsIds)
      .then((campaigns) => {
        this.campaigns = campaigns.filter(({ _id }) =>
          campaignsIds.includes(_id)
        );
      });

    this.calendarEventsService
      .list({
        filters: {
          corporateEvents_id: { $in: [this.event._id] },
        },
      })
      .online()
      .then((events: CalendarEvent[]) => {
        const isFullCalendar = this.sfFeatureFlagsService.hasFeature(
          this.SF_FEATURE_FLAGS.FULL_CALENDAR
        );

        this.relatedEvents = events;
        this.showRelatedEvents =
          Boolean(this.relatedEvents?.length) && isFullCalendar;
      });
  }

  openChecklistDetails(campaign) {
    const activationTime =
      this.campaignsUtilsService.getActivationTime(campaign);

    const archived = campaign.contents.state === 'deactivated';
    const outOfScope = campaign.contents.outOfScope;

    if (activationTime || archived || outOfScope) {
      return;
    }

    this.$state.go('index.menu-more.reactive-campaigns.details', {
      campaignId: campaign._id,
      referer: 'index.activity.details',
    });
    this.onClose();
  }

  getAttachedResources(
    attachedResources: CorporateEventAttachedResource[]
  ): ng.IPromise<void> {
    return this.newsfeedService
      .buildAttachedResourcesObjects(
        attachedResources,
        this.SF_IMAGE_SIZES.SQUARE_LARGE
      )
      .then(({ images, documents, videos }) => {
        this.eventPhotos = images;
        this.eventFiles = documents;
        this.eventVideos = videos;
      });
  }
}
