import { ChatComponent } from './chat.component';
import ChatCreateModule from './components/chat-create/chat-create.module';
import ChatRoomModule from './components/chat-room/chat-room.module';

export default angular
  .module('sfMobile.components.chat', [
    'sfMobile.services.chat',
    'sfMobile.services.modal',
    'sfMobile.components.content',
    'sfMobile.components.select-users',
    'sfMobile.components.button-header-add',
    ChatCreateModule,
    ChatRoomModule,
  ])
  .component('sfChat', ChatComponent).name;
