export interface ActionSheetAction {
  text: string;
  onClick: () => void;
  isForBrowserOnly?: boolean;
}

export interface ActionSheetConfig {
  title?: string;
  cancelText?: string;
  destructiveText?: string;
  isValidation?: boolean;
  customClass?: string;
}

export type IActionSheet = IonicV1.IonicActionSheet;

/**
 * Open the action sheet
 *
 * @param {Object[]} [actions] Possible actions to click on
 * @param {String} actions[].text The text of the button action
 * @param {Function} actions[].onClick The function to call for the button action
 * @param {Object} [config] Action sheet config
 * @param {Function} [onCancel] On concel click callback
 * @param {Function} [onDestructive] On destructive click callback
 * @returns {Object} Action sheet object
 */

export class ActionSheetService {
  /* @ngInject */
  constructor(private $ionicActionSheet: IonicV1.IonicActionSheetService) {}

  // eslint-disable-next-line complexity
  open(
    actions: ActionSheetAction[] | null,
    options: ActionSheetConfig = {},
    onCancel?: { (): void } | null,
    onDestructive?: { (): void } | null
  ): IActionSheet {
    const { title, cancelText, destructiveText, isValidation, customClass } =
      options;
    let sourceSheet: () => void = () => ({});
    let hasBeenDestruct = false;
    let cssClass = '';

    if (isValidation) {
      cssClass = 'sf_action_sheet__validate';
    }
    if (customClass) {
      cssClass = cssClass ? `${cssClass} ${customClass}` : customClass;
    }
    const actionSheetActions = actions ?? [];
    const sourceSheetConfig = {
      cssClass,
      titleText: title,
      destructiveText,
      cancelText,
      buttons: actionSheetActions.map(({ text }) => ({ text })),
      buttonClicked: (index: number): void => {
        const option = actionSheetActions[index];

        option.onClick();
        sourceSheet();
      },
      destructiveButtonClicked: () => {
        sourceSheet();
        hasBeenDestruct = true;
        return onDestructive ? onDestructive() : null;
      },
      cancel: () => {
        if (hasBeenDestruct) {
          return false;
        }
        sourceSheet();
        return onCancel ? onCancel() : null;
      },
    };

    sourceSheet = this.$ionicActionSheet.show(sourceSheetConfig);
    return sourceSheet;
  }
}
