import { APIUsersGroup } from '@services/API/users/users';
import { EntityType } from 'app';
import { isEmpty } from 'ramda';
import { APIGroupTask } from '../../../group-tasks/types';
import { APIPlacesList } from '@services/API/placesLists/placesLists';

export class GroupTaskGroupNameController implements ng.IComponentController {
  // bindings
  task: APIGroupTask;

  text?: string;

  constructor(private placesListsService, private usersService) {
    'ngInject';
  }

  $onInit(): void {
    this.setTextMessage();
  }

  setTextMessage(): void {
    const groupsIds = this.task.contents.entityGroups;

    if (!isEmpty(groupsIds)) {
      Promise.all(
        groupsIds.map((groupId) =>
          this.getEntityGroupProvider(this.task.contents.entityType, groupId)
        )
      ).then((groups: APIPlacesList[] | APIUsersGroup[]) => {
        const first = groups.shift();

        this.text = first?.contents.name;

        if (!isEmpty(groups)) {
          this.text = `${this.text} (+ ${groups.length})`;
        }
      });
    }
  }

  getEntityGroupProvider(entityType: EntityType, groupId: string) {
    return entityType === 'places'
      ? this.placesListsService.get(groupId)
      : this.usersService.getUsersGroup(groupId);
  }
}
