import { StateObject, StateService } from '@uirouter/core';
import { ObjectId, TSFixMe, UserRef } from '../../..';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import { FilesService } from '../../../services/API/files/files.service';
import { LinkPreviewService } from '../../../services/API/link-preview/link-preview.service';
import {
  ActionSheetAction,
  ActionSheetService,
} from '../../../services/Utils/ActionSheet/action-sheet.service';
import { PubSubService } from '../../../services/Utils/PubSub/pubsub.service';
import { NewsfeedActionsService } from '../../services/newsfeed-actions/newsfeed-actions.service';
import { NewsfeedApiService } from '../../services/newsfeed-api/newsfeed-api.service';
import { NewsfeedPopupService } from '../../services/newsfeed-popup/newsfeed-popup.service';
import { NewsfeedService } from '../../services/newsfeed-service/newsfeed.service';
import {
  DefaultPostAuthor,
  NewsfeedPost,
  NewsfeedPostResource,
  ResourcePreview,
} from '../../types';

export class NewsfeedPostItemController implements ng.IComponentController {
  //bindings
  post: NewsfeedPost;
  profile: TSFixMe;
  authorType: string;
  hideActions: boolean;
  disableClick: boolean;
  onEditPost: () => void;
  onReload: () => void;
  isChatLoading: boolean;

  pictureUrls: ResourcePreview[] = [];
  documents: ResourcePreview[] = [];
  videos: ResourcePreview[] = [];
  actions: ActionSheetAction[];
  onDataSyncListener: () => void;
  onPostUpdateListener: () => void;
  hasChatFeatureFlag: boolean;
  hasAlphaFlag: boolean;
  onLinkPreviewClick: ($event: Event) => void;
  areCommentsDisabled = true;

  private checkCommentsDisabled() {
    return this.newsfeedService.areCommentsDisabled().then((pref) => {
      this.areCommentsDisabled = pref;

      return this.getAttachedResources();
    });
  }

  // eslint-disable-next-line max-params
  constructor(
    private $state: StateService,
    private $q: ng.IQService,
    private filesService: FilesService,
    private pubSubService: PubSubService,
    private newsfeedApiService: NewsfeedApiService,
    private newsfeedPopupService: NewsfeedPopupService,
    private newsfeedActionsService: NewsfeedActionsService,
    private SF_IMAGE_SIZES: typeof IMAGE_SIZES,
    private newsfeedService: NewsfeedService,
    private actionSheetService: ActionSheetService,
    private $translate: ng.translate.ITranslateService,
    private chatService,
    private chatPopupService,
    private linkPreviewService: LinkPreviewService
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<any> {
    this.hasChatFeatureFlag = this.chatService.hasFeatureFlag();
    this.hasAlphaFlag = this.newsfeedService.hasAlphaFeatureFlag();
    if (this.post.linkPreview?.origin) {
      this.onLinkPreviewClick = this.linkPreviewService
        .onLinkPreviewClick(this.post.linkPreview?.origin)
        .bind(this);
    }

    const { post, profile, authorType } = this;

    if (authorType === 'user' && 'firstName' in post.contents.author) {
      // second condition is TS typeguard
      this.actions = this.newsfeedActionsService.getAvailableActions({
        author_id: post.contents.author._id,
        profile,
        postContent: post.contents.richText || post.contents.text || '',
        callbacks: {
          onEdit: () => this.onEditPost(),
          onDelete: () => this.onDeletePost(),
        },
      });
    }

    this.onPostUpdateListener = this.pubSubService.subscribe<{
      post: NewsfeedPost;
    }>('NEWSFEED_POST_UPDATED', ({ post }) => this.onUpdatePost(post));

    this.onDataSyncListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
      () => this.checkCommentsDisabled()
    );

    return this.checkCommentsDisabled();
  }

  $onDestroy(): void {
    this.onPostUpdateListener();
    this.onDataSyncListener();
  }

  getTruncatedText(message = '') {
    const MESSAGE_CHAR_MAX = 255;

    return MESSAGE_CHAR_MAX < message.length
      ? message.slice(0, MESSAGE_CHAR_MAX) + '...'
      : message;
  }

  isPostAuthor(author: UserRef | DefaultPostAuthor): boolean {
    return 'firstName' in author && author._id === this.profile._id;
  }

  onDeletePost(): ng.IPromise<void | void[]> {
    const title = 'NEWSFEED_DELETE_POST_CONFIRM_TITLE';
    const text = 'NEWSFEED_DELETE_POST_CONFIRM_BUTTON';

    return this.newsfeedPopupService.showDeleteConfirm(title, text).then(() =>
      this.newsfeedApiService
        .deletePost(this.post._id)
        .then(() => this.deletePostFiles(this.post.contents.attachedResources))
        .catch(() => this.newsfeedPopupService.showPostNotExistsError())
        .finally(() => this.onReload())
    );
  }

  deletePostFiles(files: NewsfeedPostResource[] = []): ng.IPromise<void[]> {
    const filesIds = files
      .filter((file) => file.type !== 'video')
      .map((file) => file.file_id);
    const videoFilesIds = files
      .filter((file) => file.type == 'video')
      .map((file) => file.file_id);

    return this.$q.all([
      ...filesIds.map((fileId) => this.filesService.deleteFile(fileId, false)),
      ...videoFilesIds.map((videosIds) =>
        this.filesService.deleteFile(videosIds, true)
      ),
    ]);
  }

  goToPost(isInputFocused = 'false'): ng.IPromise<StateObject | null> {
    if (this.disableClick) {
      return this.$q.resolve(null);
    }

    return this.$state
      .go('index.newsfeed.details', {
        postId: this.post._id,
        isInputFocused,
      })
      .catch((error) => {
        this.onReload();
        throw error;
      });
  }

  onUpdatePost(post: NewsfeedPost): boolean {
    if (this.post._id !== post._id) {
      return false;
    }
    this.post = post;
    this.getAttachedResources();
    return true;
  }

  getAttachedResources(): ng.IPromise<void> {
    if (!(this.post && this.post.contents.attachedResources)) {
      return this.$q.resolve();
    }

    return this.newsfeedService
      .buildAttachedResourcesObjects(
        this.post.contents.attachedResources,
        this.SF_IMAGE_SIZES.SQUARE_LARGE
      )
      .then(({ images, documents, videos }) => {
        this.pictureUrls = images;
        this.documents = documents;
        this.videos = videos;
      });
  }

  onHeaderClick(): void {
    const author = this.post.contents.author;

    if (!this.hasChatFeatureFlag || this.isPostAuthor(author)) {
      this.goToPost();
      return;
    }

    this.startChat(author as UserRef);
  }

  startChat(author: UserRef): void {
    const postAuthorName = this.newsfeedService.getPostAuthorName(author);
    const startChatText =
      this.$translate.instant('NEWSFEED_SEND_CHAT_MESSAGE') +
      ` ${postAuthorName}`;

    const actions = [
      { text: startChatText, onClick: () => this.openChat(author._id) },
    ];
    const actionSheetConfig = {
      cancelText: this.$translate.instant('NEWSFEED_SEND_CHAT_CANCEL_BUTTON'),
      customClass: 'ellipsis-wrapped-actions',
    };

    this.actionSheetService.open(actions, actionSheetConfig);
  }

  openChat(userId: ObjectId): ng.IPromise<void> {
    this.isChatLoading = true;

    return this.chatService
      .getUserChannel(userId)
      .then(
        (channel?: { url: string }) => {
          if (!channel) {
            return this.createChat(userId);
          }
          return this.openChatRoom(channel.url);
        },
        () => this.createChat(userId)
      )
      .finally(() => {
        this.isChatLoading = false;
      });
  }

  createChat(userId: ObjectId): ng.IPromise<void> {
    const usersIds = [userId, this.profile._id];
    const progressPopup = this.chatPopupService.showChannelCreatingProgress();

    // sendbird handles itself the opening of already existed room or creating new one
    return this.chatService
      .createChannel(usersIds)
      .then((channel: { url: string }) =>
        progressPopup.onSuccess().then(() => {
          this.openChatRoom(channel.url);
        })
      )
      .catch((err: unknown) => {
        progressPopup.onError(() => this.openChat(userId));
        throw err;
      });
  }

  openChatRoom(channelUrl: string): void {
    this.$state.go('index.chat.channel', { channelUrl });
  }
}
