import { forms as formsSchema } from '@simplifield/schema';

type scoreQuestion = {
  for_id?: string;
  for_type?: string;
  numerator: number;
  denominator: number;
  percent?: number;
};

type Section = {
  _id: string;
  level: number;
};

type Question = {
  naAllowed: boolean;
  _id: string;
  maximum?: number;
  fields: QuestionField[];
};

type QuestionField = {
  scoreMap: {
    condition: string;
    value: string | number | null;
  }[];
};

type Product = {
  contents: {
    ean: string;
    brand: string;
  };
};

type Answer = {
  _id: string;
  question_id: string;
  sections_ids: string[];
  nodes_ids: string[];
  type: string;
  values: {
    field_id: string;
    value: string | number;
  }[];
};

export class ReportScoringService {
  formsSchema = formsSchema;

  constructor() {
    'ngInject';
  }

  public computeScoreForQuestion(
    questionId,
    form,
    report
  ): null | scoreQuestion {
    const question = this.getQuestion(questionId, form);
    const answers = this.getAnswers(questionId, report);
    let maxValue = 0;

    if (!question) {
      return null;
    }

    const scoringField = this.getScoringField(question);

    if (!scoringField) {
      return null;
    }

    if (!answers || !answers.length) {
      return {
        for_id: questionId,
        for_type: 'question',
        numerator: 0,
        denominator: maxValue,
        percent: 0,
      };
    }

    if (question.naAllowed && !answers[0].values[0]?.value) {
      return {
        for_id: questionId,
        for_type: 'question',
        numerator: 0,
        denominator: maxValue,
        percent: 0,
      };
    }

    maxValue = this.getMaximumPossibleScore(
      scoringField,
      +this.getMaximumFromFormula(question.maximum) > 1
    );

    //
    // Here we compute the sum of all score value for all answers for this question
    //
    const computedNumerator = answers.reduce((accu: number | null, answer) => {
      if (accu === null) {
        return null;
      }

      const scoreValue = this.computeScoreForAnswer(questionId, form, answer);

      if (scoreValue === null) {
        return null;
      }

      accu += scoreValue;
      return accu;
    }, 0);

    return computedNumerator !== null
      ? {
          for_id: questionId,
          for_type: 'question',
          numerator: computedNumerator,
          denominator: maxValue,
          percent: maxValue ? computedNumerator / maxValue : 0,
        }
      : null;
  }

  public computeScore(form, report): null | Record<string, unknown> {
    const topLevelSectionIds = this.getTopLevelSectionIds(form);
    const score = topLevelSectionIds
      .map((id) => this.computeScoreForSection(id, form, report))
      .reduce((totalScore, sectionScore) => {
        if (totalScore === null || sectionScore === null) {
          return totalScore || sectionScore;
        }

        return {
          numerator: sectionScore.numerator + totalScore.numerator,
          denominator: sectionScore.denominator + totalScore.denominator,
        };
      }, null);

    return score
      ? {
          for_id: report._id,
          for_type: 'report',
          numerator: score.numerator > 0 ? score.numerator : 0,
          denominator: score.denominator,
          percent: score.denominator ? score.numerator / score.denominator : 0,
        }
      : null;
  }

  public getQuestion(qId, form): null | Question {
    const contents = form
      ? form.questions
        ? form
        : form.contents
        ? form.contents
        : null
      : null;

    if (!contents) {
      return null;
    }

    return contents
      ? (contents.questions || []).filter(
          (question) => qId && question._id && qId === question._id
        )[0]
      : null;
  }

  public getAnswers(qId, report): Answer[] {
    const contents = report
      ? report.answers
        ? report
        : report.contents
        ? report.contents
        : null
      : null;

    if (!contents) {
      return [];
    }

    return contents
      ? (contents.answers || []).filter(
          (answer) => qId && answer.question_id && qId === answer.question_id
        )
      : [];
  }

  public getScoringField(question): null | QuestionField {
    return question.fields && question.fields.length
      ? question.fields.filter(
          (field) => field.type === 'score' && field.scoreMap
        )[0]
      : null;
  }

  public getMaximumPossibleScore(scoringField, addAll): number {
    const values = scoringField.scoreMap
      .filter((s) => s.value !== null)
      .filter((s) => s.value >= 0)
      .map((s) => parseFloat(s.value));

    return !addAll
      ? Math.max.apply(null, values.length ? values : [0])
      : values.reduce((sum, val) => sum + val, 0);
  }

  public getMaximumFromFormula(formula): number | 'Infinity' {
    // Simple case, a number or Infinity, no formula
    return formula === 'Infinity' || formula > 1
      ? formula
      : // A number inside a formula
      /^.*\?\s*([0-9]+)\s*:\s*[0-9]+\s*$/m.test(formula)
      ? parseInt(
          formula.replace(/^[^]*\?\s*([0-9]+)\s*:\s*[0-9]+\s*$/m, '$1'),
          10
        )
      : // The Infinity inside a formula
      /^.*\?\s*(Infinity)\s*:\s*[0-9]+\s*$/m.test(formula)
      ? 'Infinity'
      : // Default to 1
        1;
  }

  public computeScoreForAnswer(questionId, form, answer): null | number {
    const question = this.getQuestion(questionId, form);
    const answerValue = this.getAnswerValue(answer);

    if (!question) {
      return null;
    }

    const scoringField = this.getScoringField(question);

    if (!scoringField) {
      return null;
    }

    if (question.naAllowed && !answerValue) {
      return null;
    }

    if (answerValue === null) {
      return 0;
    }

    //
    // Here we find an answer matching one condition of the 'scoreMap'
    //
    const scoreValueEntry = scoringField.scoreMap.filter(
      (scoreEntry) =>
        // We want cross types equality
        `${answerValue}` === scoreEntry.condition
    )[0];

    return scoreValueEntry ? this.getScoreValue(scoreValueEntry.value) : 0;
  }

  public getScoreValue(scoreValue): number {
    const parsedValue = parseFloat(scoreValue);

    return isNaN(parsedValue) ? scoreValue : parsedValue;
  }

  public scoreIsActivate(form, report) {
    const scores = this.computeScore(form, report);

    return scores !== null;
  }

  public computeScoring(form, report, section) {
    const headResult = [
      {
        section: section,
        scoring: this.computeSectionScoring(form, report, section),
      },
    ];
    const sectionsResult = (section.sections || []).reduce(
      (acc, subSection) =>
        acc.concat(this.computeScoring(form, report, subSection)),
      []
    );

    return ([] as any[]).concat(headResult, sectionsResult);
  }

  public computeSectionScoring(form, report, section) {
    const questions = this.formsSchema.getSectionQuestions(
      form.contents,
      section._id
    );
    const questionsScoring = questions.map((question) => ({
      title: question.title,
      data: this.computeScoreForQuestion(question._id, form.contents, report),
    }));
    const subSectionsScoring = (section.sections || []).map((subSection) => ({
      title: subSection.title,
      data: this.computeScoreForSection(subSection._id, form.contents, report),
    }));

    return ([] as Array<{ title: string; data: any }>)
      .concat(questionsScoring, subSectionsScoring)
      .filter((item) => !!item.data);
  }

  public getAnswerValue(answer): null | string | number {
    return answer
      ? answer.values
        ? answer.values[0]
          ? answer.values[0].value
          : null
        : null
      : null;
  }

  public getTopLevelSectionIds(form): string[] {
    return form
      ? (form.contents || form).sections
          .filter((s) => s.level === 0)
          .map((s) => s._id)
      : [];
  }

  public computeScoreForSection(sectionId, form, report): null | scoreQuestion {
    const section = this.getSection(sectionId, form);
    const childrenSectionsIds = this.getAllSectionIds(section, form);
    const questionIds = this.getQuestionIdsForSections(
      childrenSectionsIds,
      form
    );
    const score = this.addUpQuestionScores(questionIds, form, report);

    return score !== null
      ? {
          for_id: sectionId,
          for_type: 'section',
          numerator: score.numerator > 0 ? score.numerator : 0,
          denominator: score.denominator,
          percent: score.denominator ? score.numerator / score.denominator : 0,
        }
      : null;
  }

  private getSection(sId, form): null | Section {
    const contents = form
      ? form.sections
        ? form
        : form.contents
        ? form.contents
        : null
      : null;

    if (!contents) {
      return null;
    }

    return contents
      ? (contents.sections || []).filter(
          (section) => sId && section._id && sId === section._id
        )[0]
      : null;
  }

  private getAllSectionIds(section, form): string[] {
    const contents = form
      ? form.sections
        ? form
        : form.contents
        ? form.contents
        : null
      : null;

    if (!contents || !section) {
      return [];
    }

    const childSections = contents.sections.reduce(
      (stateMachine, thisSection) => {
        if (stateMachine.stop === true) {
          return stateMachine;
        }

        if (thisSection._id === section._id) {
          stateMachine.start = true;
          return stateMachine;
        }

        if (stateMachine.start !== true) {
          return stateMachine;
        }

        if (thisSection.level <= section.level) {
          stateMachine.stop = true;
          return stateMachine;
        }

        stateMachine.ids.push(thisSection._id);
        return stateMachine;
      },
      {
        start: false,
        stop: false,
        ids: [],
      }
    );

    return [section._id].concat(childSections.ids);
  }

  private getQuestionIdsForSections(sectionIds, form): string[] {
    const contents = form
      ? form.questions
        ? form
        : form.contents
        ? form.contents
        : null
      : null;

    if (!contents) {
      return [];
    }

    return contents.questions
      .filter((question) => sectionIds.indexOf(question.section_id) !== -1)
      .map((question) => question._id);
  }

  private addUpQuestionScores(
    questionIds,
    form,
    report
  ): { numerator: number; denominator: number } {
    const questionScores = questionIds.reduce((score, questionId) => {
      const scoreForQuestion = this.computeScoreForQuestion(
        questionId,
        form,
        report
      );

      if (scoreForQuestion === null) {
        return score;
      }

      return score === null
        ? {
            numerator: scoreForQuestion.numerator,
            denominator: scoreForQuestion.denominator,
          }
        : {
            numerator: score.numerator + scoreForQuestion.numerator,
            denominator: score.denominator + scoreForQuestion.denominator,
          };
    }, null);

    if (questionScores && questionScores.numerator < 0) {
      questionScores.numerator = 0;
    }

    return questionScores;
  }
}
