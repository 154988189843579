const TYPES = {
  SALES: 'sales',
  COUNT: 'count',
};
const ANSWER_TYPES = {
  SALES: 'product-sales',
  COUNT: 'product-count',
};
const DISCOUNT_BOUNDARIES = {
  MIN: 0,
  MAX: 100,
};
const QUANTITY_BOUNDARIES = {
  MIN: 0,
};

export default angular
  .module('sfMobile.constants.question-product', [])
  .constant('SF_QUESTION_PRODUCT_TYPES', TYPES)
  .constant('SF_PRODUCT_ANSWER_TYPES', ANSWER_TYPES)
  .constant('SF_PRODUCT_DISCOUNT_BOUNDARIES', DISCOUNT_BOUNDARIES)
  .constant('SF_PRODUCT_QUANTITY_BOUNDARIES', QUANTITY_BOUNDARIES).name;
