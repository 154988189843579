import { isEmpty } from 'ramda';

export const InputRadioComponent = {
  bindings: {
    data: '<',
    disabled: '<',
    isRequired: '<',
    hasError: '<',
    label: '@',
    inputName: '@',
    reverseInputDisplay: '<?',
  },
  require: {
    modelCtrl: 'ngModel',
  },
  transclude: true,
  templateUrl: 'components/Inputs/input-radio/input-radio.html',
  controller: class InputRadioController {
    $onInit() {
      this.modelCtrl.$render = () => {
        this.selected = this.modelCtrl.$viewValue;
      };
    }
    isSelected() {
      if (this.selected === null && this.data === null) {
        //an option with null value is chosen
        return true;
      }

      if (!this.selected || !this.data || isEmpty(this.selected)) {
        return false;
      }

      return this.selected && this.selected.key
        ? this.selected.key.trim() === this.data.key.trim()
        : this.selected.trim() === this.data.trim();
    }
    selectSort() {
      this.onSortSelect();
      this.blurInput();
    }
    onSortSelect() {
      if (this.disabled) {
        return false;
      }
      this.selected = this.data;
      return this.modelCtrl.$setViewValue(this.data);
    }
    blurInput() {
      const inputElement = document.activeElement;
      if (inputElement) {
        inputElement.blur();
      }
    }
    /* In the selectSort() function, we added a call to this.blurInput(), 
  which will trigger the blurInput() function. 
  This function retrieves the currently focused element using document.activeElement 
  and then calls the blur() method on it to remove the focus.
  By adding these changes, clicking on a radio button will now cause the cursor to lose focus. */
  },
};
