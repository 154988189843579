import MissionsModule from './Missions/missions.module';
import PovModule from './POV/pov.module';
import ApiUrlModule from './apiUrl/api-url.module';
import AuthModule from './auth/auth.module';
import CalendarEventsParamsModule from './calendar-events-params/calendar-events-params.module';
import CalendarEventsModule from './calendar-events/calendar-events.module';
import CampaignsModule from './campaigns/campaigns.module';
import ChatModule from './chat/chat.module';
import DocumentsModule from './documents/documents.module';
import ErrorsModule from './errors/errors.module';
import EventsService from './events/events.module';
import ExternalDashboardApiModule from './external-dashboard/external-dashboard-api.module';
import DashboardsModule from './field-metrics/dashboards.module';
import FieldMetricsModule from './field-metrics/field-metrics.module';
import MetricsModule from './field-metrics/metrics.module';
import FilesModule from './files/files.module';
import FormsModule from './forms/forms.module';
import LinkPreviewModule from './link-preview/link-preview.module';
import MerchandisingModule from './merchandising/merchandising.module';
import OrganisationsModule from './organisations/organisations.module';
import PingModule from './ping/ping.module';
import PlacesParamsModule from './places-params/places-params.module';
import PlacesModule from './places/places.module';
import PlacesListsModule from './placesLists/placesLists.module';
import PreferencesModule from './preferences/preferences.module';
import ProductsModule from './products/products.module';
import ProfileModule from './profile/profile.module';
import ReportsValidationModule from './reports/reports-validation.module';
import ReportsModule from './reports/reports.module';
import TagsModule from './tags/tags.module';
import TasksModule from './tasks/tasks.module';
import TemplatesModule from './templates/templates.module';
import UsersModule from './users/users.module';
import DraftReportsModule from './draft-reports/draft-reports.module';
import ReportVersionsModule from './report-versions/report-versions.module';

export default angular.module('sfMobile.services.api', [
  ApiUrlModule,
  AuthModule,
  ErrorsModule,
  MissionsModule,
  PovModule,
  CalendarEventsModule,
  CampaignsModule,
  ChatModule,
  DocumentsModule,
  DashboardsModule,
  ExternalDashboardApiModule,
  FieldMetricsModule,
  MetricsModule,
  FilesModule,
  FormsModule,
  MerchandisingModule,
  OrganisationsModule,
  PingModule,
  PlacesModule,
  PlacesListsModule,
  PlacesParamsModule,
  CalendarEventsParamsModule,
  PreferencesModule,
  ProductsModule,
  ProfileModule,
  ReportsValidationModule,
  ReportsModule,
  TasksModule,
  TemplatesModule,
  UsersModule,
  LinkPreviewModule,
  EventsService,
  TagsModule,
  DraftReportsModule,
  ReportVersionsModule,
]).name;
