"use strict";

/* eslint no-magic-numbers:[0] */
var createObjectId = require('../utils/createObjectId');

module.exports = function () {
  return [{
    _id: createObjectId('place1'),
    name: 'home',
    description: 'Minimum data to be valid.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Maison Nico',
      street: '79, rue Alexandre Descatoires',
      zipcode: '59500',
      city: 'Douai',
      country: 'France',
      params: [],
      tags: [],
      timezone: {
        name: 'Europe/Paris'
      },
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      placesLists_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId('place2'),
    name: 'office',
    description: 'Every data that can be set.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Simplifield Office',
      street: '2 allée de la Haye du Temple',
      zipcode: '59000',
      city: 'Lille',
      administrative_area: 'Hauts-de-France',
      country: 'France',
      latLng: [50.6314264, 3.019785],
      description: 'let add a small description of this place',
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      picture_id: createObjectId('picture_id'),
      params: [{
        name: 'param',
        value: 'value'
      }, {
        name: 'param2',
        value: 'value2'
      }],
      tags: ['Hype', 'Dev'],
      timezone: {
        name: 'Europe/Paris'
      },
      users_ids: [createObjectId('user')],
      placesLists_ids: [createObjectId('placelist1'), createObjectId('placelist2')],
      subscribers_ids: [createObjectId('subscriber')]
    }
  }, {
    _id: createObjectId('place-with-goal'),
    name: 'goal',
    description: 'A place with a goal',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'PoS with goal',
      street: '277 rue Saint Honoré',
      zipcode: '75000',
      city: 'Paris',
      country: 'France',
      params: [],
      tags: [],
      timezone: {
        name: 'Europe/Paris'
      },
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      placesLists_ids: [],
      subscribers_ids: [],
      goals: [{
        _id: createObjectId('goal'),
        name: 'My daily goal',
        form_id: createObjectId('form_id'),
        start_date: '2018-01-01T00:00:00Z',
        end_date: '2018-01-01T00:00:00Z',
        recurrence: 'daily',
        value: 1500
      }]
    }
  }, {
    _id: createObjectId('place-with-goal-on-catalog'),
    name: 'goal',
    description: 'A place with a goal on a tag',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'PoS with goal',
      street: '277 rue Saint Honoré',
      zipcode: '75000',
      city: 'Paris',
      country: 'France',
      params: [],
      tags: [],
      timezone: {
        name: 'Europe/Paris'
      },
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      placesLists_ids: [],
      subscribers_ids: [],
      goals: [{
        _id: createObjectId('goal'),
        name: 'My daily goal',
        form_id: createObjectId('form_id'),
        start_date: '2018-01-01T00:00:00Z',
        end_date: '2018-01-01T00:00:00Z',
        recurrence: 'daily',
        value: 1500,
        catalogs_ids: [createObjectId('catalog1')]
      }]
    }
  }, {
    _id: createObjectId('place1'),
    name: 'documents',
    description: 'Place with documents.',
    upstanding: {
      valid: true
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Maison Nico',
      street: '79, rue Alexandre Descatoires',
      zipcode: '59500',
      city: 'Douai',
      country: 'France',
      params: [],
      tags: [],
      timezone: {
        name: 'Europe/Paris'
      },
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      placesLists_ids: [],
      subscribers_ids: [],
      documents_ids: [createObjectId('document1')]
    }
  }, {
    _id: createObjectId(),
    name: 'empty',
    description: 'Empty contents',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.name']
      }]
    },
    partial: {
      valid: true
    },
    contents: {}
  }, {
    _id: createObjectId(),
    name: 'nostreet',
    description: 'No street',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.street']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Kikoolol Corp.'
    }
  }, {
    _id: createObjectId(),
    name: 'badlat',
    description: 'Bad latitude',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['should be >= -90']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Maison Nico',
      street: '79, rue Alexandre Descatoires',
      zipcode: '59500',
      city: 'Douai',
      country: 'France',
      params: [],
      tags: [],
      timezone: {
        name: 'Europe/Paris'
      },
      latLng: [-92, 5],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      placesLists_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'badlng',
    description: 'Bad longitude',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['should be >= -180']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Maison Nico',
      street: '79, rue Alexandre Descatoires',
      zipcode: '59500',
      city: 'Douai',
      country: 'France',
      params: [],
      tags: [],
      timezone: {
        name: 'Europe/Paris'
      },
      latLng: [2, -181],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      placesLists_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'revertedlatlng',
    description: 'Reveterd latitude/longitude',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_VALIDATION',
        params: ['should be >= -90']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Maison Nico',
      street: '79, rue Alexandre Descatoires',
      zipcode: '59500',
      city: 'Douai',
      country: 'France',
      params: [],
      tags: [],
      latLng: [-179, 2],
      timezone: {
        name: 'Europe/Paris'
      },
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      placesLists_ids: [],
      subscribers_ids: []
    }
  }, {
    _id: createObjectId(),
    name: 'no-timezone-name',
    description: 'No timezone name',
    upstanding: {
      valid: false,
      errors: [{
        code: 'E_REQUIRED',
        params: ['.name']
      }]
    },
    partial: {
      valid: true
    },
    contents: {
      name: 'Maison Nico',
      street: '79, rue Alexandre Descatoires',
      zipcode: '59500',
      city: 'Douai',
      country: 'France',
      params: [],
      tags: [],
      timezone: {},
      latLng: [-179, 2],
      organisation_id: createObjectId('organisation'),
      owner_id: createObjectId('owner'),
      users_ids: [],
      placesLists_ids: [],
      subscribers_ids: []
    }
  }];
};